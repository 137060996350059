import React, { useState, useEffect, useRef, createRef, useMemo } from 'react';
import { makeStyles, fade, withTheme, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { useForm, Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

//Text field
import TextField from '@material-ui/core/TextField';

// Select

//Textarea
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

//Icon
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

//Button
import Button from '@material-ui/core/Button'

// Stepper
import StepConnector from '@material-ui/core/StepConnector';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import clsx from 'clsx';

//Add Persona
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom';
import MainLayout from '../../layouts/newMainLayout'
import StepWrapper from './stepWrapper'
import UploadImage from '../../components/UploadImage';
import { InputLabel, MenuItem, Select } from '@material-ui/core';
import { enGB } from 'date-fns/locale'
import { DateRangePicker, START_DATE, END_DATE } from 'react-nice-dates'
import 'react-nice-dates/build/style.css'
import BrandStandings from '../../components/BrandStandings';
import { saveMediaPlanner, createMediaPlanner } from '../../redux/actions/media-planner';

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            backgroundColor: '#0e82f4'
        },
    },
    completed: {
        '& $line': {
            backgroundColor: '#0e82f4'
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector);


const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundColor: '#0e82f4'
        // backgroundImage:'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        // boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundColor: '#0e82f4'
        // backgroundImage:
        // 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    },
});

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons = {
        1: <SettingsIcon />,
        2: <GroupAddIcon />,
        3: <VideoLabelIcon />,
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

const getSteps = () => {
    return ['ABOUT', 'ADD PERSONA', 'ADD INVENTORY', 'CONFIRM'];
}

const useStyles = makeStyles((theme) => ({
    dflex: {
        display: 'flex'
    },
    progressBar: {
        width: '100%',
        height: '15px',
        //marginLeft:'50px',
        borderRadius: '10px',
    },
    awarenessProgress: {
        borderRadius: '10px',
        height: '15px',
        backgroundColor: '#9E9D9D',
    },
    considerationProgress: {
        borderRadius: '10px',
        width: '29%',
        height: '15px',
        backgroundColor: '#369DC6',
    },
    preferenceProgress: {
        borderRadius: '10px',
        width: '29%',
        height: '15px',
        backgroundColor: '#F06D1D',
    },
    activeIntentProgress: {
        borderRadius: '10px',
        width: '29%',
        height: '15px',
        backgroundColor: '#F6B105',
    },
    center_Padding_8px: {
        alignSelf: 'center',
        paddingRight: '8px'
    },
    stepperWrapper: {
        paddingLeft: 0,
        paddingRight: 0,
        background: 'none'
    },
    root: {
        flexGrow: 1,
        padding: '60px'
    },
    pageTitle: {
        color: '#e96941',
        fontWeight: 'normal'
    },
    formTitle: {
        color: "#1d8bf7",
        fontWeight: 'normal'
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    textarea: {
        width: '100%',
        backgroundColor: '#f4f4f8',
        marginTop: '8px',
        borderRadius: '5px',
        padding: '14px'
    },
    marginBottom_10px: {
        marginBottom: '10px'
    },
    customfield: {
        paddingTop: '0',
        marginTop: '0',
        backgroundColor: '#f4f4f8',
        borderRadius: '5px',
        '& .MuiInputLabel-root.Mui-shrink': {
            color: 'red'
        },
        '&:hover': {
            backgroundColor: '#fff',
        },
        '&$focused': {
            backgroundColor: '#fff',
            boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            borderColor: theme.palette.primary.main,
        },
        '& .MuiOutlinedInput-input': {
            padding: '8.5px 14px',
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '5px',

            '& fieldset': {
                borderColor: 'grey',
            },
            '&:hover fieldset': {
            },
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: 'grey'
        },
        "& .MuiInputLabel-root.Mui-focused": {
            color: 'grey'
        },
        "& .MuiInputLabel-root.MuiInputLabel-shrink": {
            margin: '0!important'
        },
        "& .MuiInputLabel-root.MuiInputLabel-animated": {
            marginTop: '-8px'
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: 'grey'
        }
    },
    selectImgBtn: {
        marginTop: '10px',
        display: 'block',
        margin: '0 auto',
        color: '#ba54f5',
        border: '1px solid #ba54f5',
        padding: '5px 30px'
    },
    nextButton: {
        marginLeft: 'auto',
        backgroundColor: "#1d8bf7",
        color: 'white',
        display: 'block',
        padding: '5px 40px'
    },
    radioControl: {
    },
    marginTop: {
        marginTop: "10px"
    },
    checkNRadio: {
        '& .MuiFormLabel-root': {
            color: '#1d8bf7',
            marginBottom: '8px'
        },
    }
}));


const MPAbout = () => {

    const { register, handleSubmit, errors, control } = useForm({ mode: 'onSubmit', reValidateMode: 'onSubmit' })
    const dispatch = useDispatch()

    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();
    const [channel, setChannel] = useState('');

    const [campaignName, setCampaignName] = useState('');
    const [clientName, setClientName] = useState('');
    const [date, setDate] = useState(new Date());
    const [description, setDescription] = useState('');
    const [location, setLocation] = useState('');
    const [spendChannelTelevision, setSpendChannelTelevision] = useState(0);
    const [spendChannelRadio, setSpendChannelRadio] = useState(0);
    const [spendChannelOOH, setSpendChannelOOH] = useState(0);
    const [spendChannelPrint, setSpendChannelPrint] = useState(0);
    const [spendChannelDigital, setSpendChannelDigital] = useState(0);
    const [spendChannelOther, setSpendChannelOther] = useState(0);
    const [industory, setIndustory] = useState('');
    const [display, setDisplay] = useState('');
    const [frequency, setFrequency] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');



    const [channels, setChannels] = useState([]);
    const history = useHistory();
    const avatarUrl = useSelector((state) => state.avatarUrl);
    const mediaPlanner = useSelector((state) => state.mediaPlanner);
    const currentUser = useSelector((state) => state.loggedInSession);

    const classes = useStyles();
    const standings=[
        {"name":"Awareness",     "value":"73%"},
        {"name":"Consideration", "value":"59%",},
        {"name":"Preference",    "value":"39%",},
        {"name":"Action Intent", "value":"33%",},
    ];
    



    const channelListner = (value, selected) => {

        if (selected) {
            channels.push(value)
        } else {
            for (let i = 0; i < channels.length; i++) {
                if (channels[i] === value) {
                    channels.splice(i, 1);
                }
            }
        }
    }
  
    const onSubmit = () => {
        let spendDate = [];
        for (let i = 0; i < channels.length; i++) {
            if (channels[i] === "Television") {
                spendDate.push({ "channel":"television","spend": spendChannelTelevision ? spendChannelTelevision : 0 });
                
            }
            if (channels[i] === "Radio") {
                spendDate.push({ "channel":"radio","spend": spendChannelRadio ? spendChannelRadio : 0 })
                

            }
            if (channels[i] === "OOH") {
                spendDate.push({ "channel":"ooh","spend": spendChannelOOH ? spendChannelOOH : 0 })
               
            }
            if (channels[i] === "Print") {
                spendDate.push({ "channel":"print","spend": spendChannelPrint ? spendChannelPrint : 0 })
                

            }
            if (channels[i] === "Digital / Online") {
                spendDate.push({ "channel":"digital","spend": spendChannelDigital ? spendChannelDigital : 0 })
             

            }
            if (channels[i] === "Other") {
                spendDate.push({ "channel":"other","spend": spendChannelOther ? spendChannelOther : 0 })

            }
        }
        let totalSpend=parseInt(spendChannelTelevision)+parseInt(spendChannelRadio)+parseInt(spendChannelOOH)
        +parseInt(spendChannelPrint)+parseInt(spendChannelDigital)+parseInt(spendChannelOther);

        const mediaPlannerC = {
            "campaignName": campaignName,
            "clientName": clientName,
            "description": description,
            "startDate": startDate,
            "endDate": endDate,
            "frequency": frequency,
            "industory": industory,
            "display": display,
            "amountSpend": spendDate,
            "totalSpend":totalSpend,
            "geo":location,
            "creator":currentUser?._id
        }
        console.log("mediaPlannerYYY", mediaPlannerC)
        dispatch(saveMediaPlanner(mediaPlannerC));
      //  dispatch(createMediaPlanner(mediaPlannerC));
        history.push('/media-planner/personas');
    }

    return (
        <div>
            <StepWrapper step={0} />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <form
                        className={classes.form}
                        onSubmit={handleSubmit(onSubmit)}>
                        <Paper className={classes.paper}>

                            <Grid container spacing={3}>
                                <Grid item md={3} sm={6} xs={12}>
                                    <h3 className={classes.pageTitle}>Campaign Details</h3>
                                    <TextField
                                        value={campaignName}
                                        className={classes.customfield}
                                        onChange={(e) => setCampaignName(e.target.value)}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        id="CampaignName"
                                        label="Campaign Name"
                                        name="profile[title][name]"
                                        // inputRef={register({ required: true })}
                                        error={errors.campaignName?.type === 'required'}
                                    />
                                    <TextField
                                        value={clientName}
                                        className={classes.customfield}
                                        onChange={(e) => setClientName(e.target.value)}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        id="ClientName"
                                        label="Client Name (Brand)"
                                        name="ClientName"
                                        // inputRef={register({ required: true })}
                                        error={errors.name?.type === 'required'}
                                    />
                                    <Grid container className={classes.marginBottom_10px}>
                                        <TextareaAutosize
                                            className={classes.textarea} aria-label="minimum height" rowsMin={10} placeholder="description (brief)"
                                            control={control}
                                            onChange={event => {
                                                const { value } = event.target;
                                                setDescription(value);
                                            }}
                                        />


                                        {/* <FormControl variant="outlined" error={Boolean(errors.description)} fullWidth> */}
                                        {/* <Controller
                                            as={
                                                <TextareaAutosize
                                                onChange={event => {
                                                    const { value } = event.target;
                                                    console.info("hh",value)
                                                    onChangeEvent(value );
                                                  }}
                                                />
                                            }
                                            name="description (brief)"
                                            // rules={{ required: "this is required" }}
                                            control={control}
                                            onChange={(event) => onChangeEvent(event.target.value)}
                                            defaultValue={''}
                                            className={classes.textarea} aria-label="minimum height" rowsMin={10} placeholder="Description"
                                        /> */}
                                        {/* </FormControl> */}

                                    </Grid>
                                   
                                    <DateRangePicker
                                        startDate={startDate}
                                        endDate={endDate}
                                        onStartDateChange={setStartDate}
                                        onEndDateChange={setEndDate}
                                        minimumDate={new Date()}
                                        minimumLength={1}
                                        format='dd MMM yyyy'
                                        locale={enGB}
                                    >

                                        {({ startDateInputProps, endDateInputProps, focus }) => (
                                            <div className='date-range'>
                                                <TextField
                                                    id="startDate"
                                                    label="Start Date"
                                                    variant="outlined"
                                                    margin="normal"
                                                    onChange={(e) => setDate(e.target.value)}
                                                    fullWidth
                                                    className={classes.customfield}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    {...startDateInputProps}
                                                />
                                                <TextField
                                                    id="endDate"
                                                    label="End Date"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    className={classes.customfield}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}

                                                    {...endDateInputProps}
                                                />
                                            </div>


                                        )}
                                    </DateRangePicker>
                                    <FormControl fullWidth component="fieldset" variant="outlined" className={[classes.selectControl, classes.customfield].join(' ')} style={{ marginBottom: '10px' }}>

                                        <InputLabel id="demo-simple-select-label">Industry Sector</InputLabel>
                                        <Select
                                            aria-label="Industry Sector"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={industory}
                                            onChange={(e) => setIndustory(e.target.value)}
                                        >
                                            <MenuItem value={''}></MenuItem>
                                            <MenuItem value={'100x100'}>100x100</MenuItem>
                                            <MenuItem value={'200x200'}>200x200</MenuItem>
                                            <MenuItem value={'300x300'}>300x300</MenuItem>
                                            <MenuItem value={'400x400'}>400x400</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        value={frequency}
                                        className={classes.customfield}
                                        onChange={(e) => setFrequency(e.target.value)}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        id="frequency"
                                        label="Frequency"
                                        name="frequency"
                                        // inputRef={register({ required: true })}
                                        error={errors.name?.type === 'required'}
                                    />
                                    <FormControl fullWidth component="fieldset" variant="outlined" className={[classes.selectControl, classes.customfield].join(' ')}>

                                        <InputLabel id="demo-simple-select-label">Display or Video</InputLabel>
                                        <Select
                                            aria-label="Sizes"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={display}
                                            onChange={(e) => setDisplay(e.target.value)}
                                        >
                                            <MenuItem value={''}></MenuItem>
                                            <MenuItem value={'100x100'}>100x100</MenuItem>
                                            <MenuItem value={'200x200'}>200x200</MenuItem>
                                            <MenuItem value={'300x300'}>300x300</MenuItem>
                                            <MenuItem value={'400x400'}>400x400</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item md={2} sm={6} xs={12}>
                                    <FormControl variant="outlined" className={classes.checkNRadio} style={{ minWidth: 300 }} error={Boolean(errors.channel)}>
                                        <h3 className={classes.pageTitle}>Channel</h3>
                                        {['Television', 'Radio', 'OOH', 'Print', 'Digital / Online', 'Other'].map(val => {
                                            return (
                                                <FormControlLabel
                                                    key={val}
                                                    label={val}
                                                    control={<Controller
                                                        name={`persona[motivations][${val}]`}
                                                        control={control}
                                                        defaultValue={false}
                                                        // rules={{ required: true }}
                                                        render={props =>
                                                            <Checkbox
                                                                onChange={e => { props.onChange(e.target.checked); channelListner(val, e.target.checked) }}
                                                                //  onChange={e=>channelListner(e)}
                                                                checked={props.value}
                                                            />
                                                        } // props contains: onChange, onBlur and value
                                                    />}
                                                >
                                                </FormControlLabel>
                                            )
                                        })}
                                    </FormControl>
                                    <TextField
                                        value={location}
                                        className={classes.customfield}
                                        onChange={(e) => setLocation(e.target.value)}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        id="location"
                                        label="Geo Location"
                                        name="location"
                                        // inputRef={register({ required: true })}
                                        error={errors.name?.type === 'required'}
                                    />
                                </Grid>
                                <Grid item md={2} sm={6} xs={12}>

                                    <h3 className={classes.pageTitle}>Spend</h3>
                                    <div className={classes.dflex}>
                                        <div className={classes.center_Padding_8px}>£</div>
                                        <div>
                                            <TextField
                                                value={spendChannelTelevision}
                                                className={classes.customfield}
                                                onChange={(e) => setSpendChannelTelevision(e.target.value)}
                                                variant="outlined"
                                                margin="normal"
                                                // fullWidth
                                                id="ClientName"
                                                type="number"
                                                label=""
                                                name="ClientName"
                                                // inputRef={register({ required: true })}
                                                error={errors.name?.type === 'required'}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.dflex}>
                                        <div className={classes.center_Padding_8px}>£</div>
                                        <div>
                                            <TextField
                                                value={spendChannelRadio}
                                                className={classes.customfield}
                                                onChange={(e) => setSpendChannelRadio(e.target.value)}
                                                variant="outlined"
                                                margin="normal"
                                                // fullWidth
                                                id="ClientName"
                                                type="number"
                                                label=""
                                                name="ClientName"
                                                // inputRef={register({ required: true })}
                                                error={errors.name?.type === 'required'}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.dflex}>
                                        <div className={classes.center_Padding_8px}>£</div>
                                        <div>
                                            <TextField
                                                value={spendChannelOOH}
                                                className={classes.customfield}
                                                onChange={(e) => setSpendChannelOOH(e.target.value)}
                                                variant="outlined"
                                                margin="normal"
                                                // fullWidth
                                                id="ClientName"
                                                type="number"
                                                label=""
                                                name="ClientName"
                                                // inputRef={register({ required: true })}
                                                error={errors.name?.type === 'required'}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.dflex}>
                                        <div className={classes.center_Padding_8px}>£</div>
                                        <div>
                                            <TextField
                                                value={spendChannelPrint}
                                                className={classes.customfield}
                                                onChange={(e) => setSpendChannelPrint(e.target.value)}
                                                variant="outlined"
                                                margin="normal"
                                                // fullWidth
                                                id="ClientName"
                                                type="number"
                                                label=""
                                                name="ClientName"
                                                // inputRef={register({ required: true })}
                                                error={errors.name?.type === 'required'}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.dflex}>
                                        <div className={classes.center_Padding_8px}>£</div>
                                        <div>
                                            <TextField
                                                value={spendChannelDigital}
                                                className={classes.customfield}
                                                onChange={(e) => setSpendChannelDigital(e.target.value)}
                                                variant="outlined"
                                                margin="normal"
                                                // fullWidth
                                                id="ClientName"
                                                label=""
                                                name="ClientName"
                                                // inputRef={register({ required: true })}
                                                error={errors.name?.type === 'required'}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.dflex}>
                                        <div className={classes.center_Padding_8px}>£</div>
                                        <div>
                                            <TextField
                                                value={spendChannelOther}
                                                className={classes.customfield}
                                                onChange={(e) => setSpendChannelOther(e.target.value)}
                                                variant="outlined"
                                                margin="normal"
                                                // fullWidth
                                                id="ClientName"
                                                type="number"
                                                label=""
                                                name="ClientName"
                                                // inputRef={register({ required: true })}
                                                error={errors.name?.type === 'required'}
                                            />
                                        </div>
                                    </div>

                                </Grid>
                                <Grid style={{ textAlign: 'center' }} item md={2} sm={6} xs={12}>
                                    <UploadImage />
                                </Grid>
                                <Grid style={{ textAlign: 'center',marginLeft:'20px' }} item md={2} sm={6} xs={12}>
                                    <BrandStandings data={standings}/>
                                </Grid>
                            </Grid>
                            <Button className={classes.nextButton} type="submit">Next</Button>
                        </Paper>
                    </form>
                </Grid>
            </Grid>
        </div>

    );
}

export default MainLayout(MPAbout)