import React, { useState, useEffect } from "react";
import { makeStyles, fade } from "@material-ui/core/styles";
import Highcharts, { color } from "highcharts";
import HighchartReact from "highcharts-react-official";
import funnel from "highcharts/modules/funnel.js";

import { useDispatch } from "react-redux";
import * as actions from "../../redux/actions/user";
import ShowAlert from "../../components/alerts";
import FormControl from "@material-ui/core/FormControl";

import TextField from "@material-ui/core/TextField";

import Button from "@material-ui/core/Button";
import { useSelector } from "react-redux";

import CircularProgress from "@material-ui/core/CircularProgress";
import MainLayout from "../../layouts/newMainLayout";
import Q1in5Img from "../../assets/Q1in5.PNG";
import { API } from "../../constants/index";
import axios from "axios";

import useStyles from "../../components/StyleClasses";

import { SECRET_KEY } from "../../constants/index";
funnel(Highcharts);

const Question1 = () => {
  const dispatch = useDispatch();
  const [demographic, setDemographic] = useState(0);
  const [industry, setIndustry] = useState(0);
  const [insight, setInsight] = useState([]);
  const [loading, setLoading] = useState(false);

  const currentUser = useSelector((state) => state.loggedInSession);
  const dropdownData = useSelector((state) => state.adminDropDownData);
  const classes = useStyles();

  const refreshList = () => {
    if (demographic != 0 && industry != 0) {
      setLoading(true);
      console.log(
        "sending ids>>>>>>>>>>>>>>>>>>>>>>>>>>>",
        demographic,
        industry
      );
      const access_token = window.localStorage.getItem(SECRET_KEY);
      axios
        .get(
          `${API}/mediaPlanner/intelligence/OneFiveInsight?demographic=${demographic}&industry=${industry}`,
          {
            headers: { Authorization: `Bearer ${access_token}` },
          }
        )
        .then((res, err) => {
          setLoading(false);
          if (err) {
            console.log(err);
          } else {
            console.log("insight resp >>>>>>>>>>>>>", res);
            if (Object.keys(res.data).length !== 0) {
              /// change condition later when res.data comes as empty array
              setInsight([...res.data.questions]);
            } else {
              setInsight([]);
            }
          }
        })
        .catch(function (error) {
          if (error) {
          }
        });
    }
  };

  useEffect(() => {
    refreshList();
  }, [demographic, industry]);

  const modalToShow = (element) => {
    if (element.chartType === "PYR") {
      return (
        <div>
          <ShowAlert />
          <HighchartReact
            highcharts={Highcharts}
            options={{
              chart: {
                type: "pyramid",
              },
              title: {
                overflow: "auto",
                text: element.question,
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    enabled: true,
                    format: "<b>{point.name}</b> ({point.y:,.0f})",
                    softConnector: true,
                  },
                  width: "90%",
                  height: "90%",
                },
              },
              legend: {
                enabled: false,
              },
              series: [
                {
                  name: element.question,
                  data: element.answers.map((e) => [
                    e.ans,
                    parseInt(e.percent),
                  ]),
                },
              ],

              responsive: {
                rules: [
                  {
                    condition: {
                      maxWidth: 500,
                    },
                    chartOptions: {
                      plotOptions: {
                        series: {
                          dataLabels: {
                            inside: true,
                          },
                          width: "90%%",
                          height: "90%",
                        },
                      },
                    },
                  },
                ],
              },
            }}
          ></HighchartReact>
        </div>
      );
    } else if (element.chartType === "BAR") {
      return (
        <div>
          <HighchartReact
            highcharts={Highcharts}
            options={{
              chart: {
                type: "column",
              },
              title: {
                text: element.question,
              },
              xAxis: {
                categories: element.answers.map((e) => e.ans),
                crosshair: true,
              },
              yAxis: {
                min: 0,
                title: {
                  text: "Percentage of Answeres",
                },
              },
              tooltip: {
                headerFormat:
                  '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                  '<td style="padding:0"><b>{point.y:.1f} %</b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
              },
              plotOptions: {
                column: {
                  pointPadding: 0.2,
                  borderWidth: 0,
                },
              },
              series: [
                {
                  name: "Answers",
                  data: element.answers.map((e) => parseInt(e.percent)),
                },
              ],
            }}
          ></HighchartReact>
        </div>
      );
    } else if (element.chartType === "PIE") {
      let myData = [];
      element.answers.forEach((obj) => {
        myData.push({ name: obj.ans, y: parseInt(obj.percent) });
      });

      return (
        <div>
          <HighchartReact
            highcharts={Highcharts}
            options={{
              chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: "pie",
              },
              title: {
                text: element.question,
              },
              tooltip: {
                pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
              },
              accessibility: {
                point: {
                  valueSuffix: "%",
                },
              },
              plotOptions: {
                pie: {
                  allowPointSelect: true,
                  cursor: "pointer",
                  dataLabels: {
                    enabled: true,
                    format: "<b>{point.name}</b>: {point.percentage:.1f} %",
                  },
                },
              },
              series: [
                {
                  name: "Answer",
                  colorByPoint: true,
                  data: myData,
                },
              ],
            }}
          ></HighchartReact>
        </div>
      );
    }
  };

  const MyChatBox = (element) => {
    return (
      <div
        style={{
          width: "40%",
          marginBottom: "80px",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          padding: "10px",
          margin: "20px",
        }}
      >
        {modalToShow(element)}
      </div>
    );
  };

  return (
    <div className={classes.boxShadow}>
      <div className={classes.paper}>
        <form
          className={classes.form}
          // // ref={useRef()  }
          // onSubmit={handleSubmit(onSubmit)}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              fontSize: "10px",

              justifyContent: "space-between",
            }}
          >
            <img className={classes.logo} src={Q1in5Img} />
            <div style={{ width: "30%", margin: "20px" }}>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
                style={{ marginTop: "10px" }}
              >
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Demographic"
                  onChange={(e) => setDemographic(e.target.value)}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {dropdownData?.demographics?.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </FormControl>
            </div>
            <div style={{ width: "30%", margin: "20px" }}>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
                style={{ marginTop: "10px" }}
              >
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Industry"
                  // value={currency}
                  onChange={(e) => setIndustry(e.target.value)}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {dropdownData?.industries?.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </FormControl>
            </div>
            <div style={{ marginTop: "25px" }}>
              {loading && <CircularProgress />}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            {insight.map((element) => {
              element.id = element._id;
              element.saved = true;
              element.fromBE = true;
              return MyChatBox(element);
            })}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Question1;
