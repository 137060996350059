import React, { useState, useEffect } from "react";
import layout from "../../layouts/newMainLayout";
import useStyles from "../../components/StyleClasses";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { FormLabel } from "@material-ui/core";
import { FormGroup } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";

import TextField from "@material-ui/core/TextField";

import InputAdornment from "@material-ui/core/InputAdornment";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ControlPointTwoToneIcon from "@material-ui/icons/ControlPointTwoTone";
import AssignmentIcon from "@material-ui/icons/Assignment";
import DateRangeIcon from "@material-ui/icons/DateRange";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import DomainIcon from "@material-ui/icons/Domain";
import LocalActivityIcon from "@material-ui/icons/LocalActivity";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import WcIcon from "@material-ui/icons/Wc";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import AccessibleIcon from "@material-ui/icons/Accessible";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import EmojiFlagsIcon from "@material-ui/icons/EmojiFlags";
import FaceIcon from "@material-ui/icons/Face";
import ShowAlert from "../../components/alerts";

import { API, SECRET_KEY } from "../../constants/index";
import axios from "axios";

import * as actions from "../../redux/actions/user";
import { useDispatch, useSelector } from "react-redux";

import CircularProgress from "@material-ui/core/CircularProgress";
import user from "../../assets/user.png";
import { Button } from "@material-ui/core";

const SinglePersona = ({ id, back, istemplate }) => {
  const [loading, setloading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const DropDowns = useSelector((state) => state.personaDropDownData);
  const dispatch = useDispatch();
  const [About, steAbout] = useState({
    name: "",
    description: "",
    occupation: 0,
    status: 0,
    religion: 0,
    gender: 0,
    sexuality: 0,
    disabilities: 0,
    nationality: 0,
    ethnicity: 0,
    bodyType: 0,
  });
  const [Age, setAge] = useState([]);
  const [Income, setIncome] = useState([]);
  const [Personality, setPersonality] = useState([]);
  const [Education, setEducation] = useState([]);
  const [ParentEdu, setParentEdu] = useState([]);

  const [Motivation, setMotivation] = useState([]);
  const [Hobby, setHobby] = useState([]);
  const [Housing, setHousing] = useState([]);
  const [Belief, setBelief] = useState([]);
  const [Col, setCol] = useState("red");
  const classes = useStyles();

  const access_token = window.localStorage.getItem(SECRET_KEY);

  const getDropDown = () => {
    axios
      .get(`${API}/mediaOwner/persona/getAddPersonaData`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          console.log("persona dropdown data >>>", res.data);
          dispatch(actions.personaDropDownData(res.data));
        }
        if (err) {
          console.log(err);
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };

  useEffect(() => getDropDown(), []);

  const getDetails = () => {
    setloading(true);
    axios
      .get(`${API}/mediaOwner/persona/${id}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          console.log("persona details are >>>", res.data);
          const aboutdata = res.data.about;
          aboutdata.disabilities = res.data.about.disabilities[0]._id;
          aboutdata.occupation = res.data.about.occupation._id;
          aboutdata.religion = res.data.about.religion._id;
          aboutdata.nationality = res.data.about.nationality._id;
          aboutdata.ethnicity = res.data.about.ethnicity._id;
          steAbout(aboutdata);
          const agedata = [];
          if (res.data.age.age_18_34) agedata.push("18-34");
          if (res.data.age.age_35_44) agedata.push("35-44");
          if (res.data.age.age_45_54) agedata.push("45-54");
          if (res.data.age.age_55_64) agedata.push("55-64");
          if (res.data.age.age_65_plus) agedata.push("65+");
          setAge(agedata);
          const incomedata = [];
          if (res.data.income.lessThan10K) incomedata.push("less than 10K");
          if (res.data.income.I_14k_18k) incomedata.push("14K-18K");
          if (res.data.income.I_19k_23k) incomedata.push("19K-23K");
          if (res.data.income.I_23k_27k) incomedata.push("23K-27K");
          if (res.data.income.I_28k_31k) incomedata.push("28K-31K");
          if (res.data.income.I_32k_35) incomedata.push("32K-35K");
          if (res.data.income.I_36k_39k) incomedata.push("36K-39K");
          if (res.data.income.I_40k_43k) incomedata.push("40K-43K");
          if (res.data.income.I_44k_47k) incomedata.push("44K-47K");
          if (res.data.income.I_100KPlus) incomedata.push("100K+");
          setIncome(incomedata);
          ////personality
          const personalitydata = [];
          if (res.data.personality.introvert) personalitydata.push("Introvert");
          if (res.data.personality.intuitive) personalitydata.push("Intuitive");
          if (res.data.personality.thinking) personalitydata.push("Thinking");
          if (res.data.personality.judging) personalitydata.push("Judging");
          if (res.data.personality.extrovert) personalitydata.push("Extrovert");
          if (res.data.personality.sensing) personalitydata.push("Sensing");
          if (res.data.personality.feeling) personalitydata.push("Feeling");
          if (res.data.personality.perceiving)
            personalitydata.push("Perceiving");
          setPersonality(personalitydata);
          ////education
          const edudata = [];
          if (res.data.educationLevel.higerEduation)
            edudata.push(
              "Higher Education & professional/vocational equivalents"
            );
          if (res.data.educationLevel.aLevel)
            edudata.push("A levels, vocational level 3 and equivalents");
          if (res.data.educationLevel.oLevel)
            edudata.push(
              "GCSE/O Level grade A*‐C, vocational level 2 and equivalents"
            );
          if (res.data.educationLevel.levelOne)
            edudata.push("Qualifications at level 1 and below");
          if (res.data.educationLevel.other)
            edudata.push(
              "Other qualifications: level unknown (including foreign qualifications)"
            );
          if (res.data.educationLevel.noQualification)
            edudata.push("No qualifications");
          setEducation(edudata);

          ///parental
          const parEdudata = [];
          if (res.data.parentalEducationLevel.One_with_Higher_education)
            parEdudata.push("One parent with higher education qualification");
          if (
            res.data.parentalEducationLevel.More_than_one_with_Higher_education
          )
            parEdudata.push(
              "More than one parent with higher education qualification"
            );
          if (res.data.parentalEducationLevel.No_with_Higher_education)
            parEdudata.push("No parents with higher education qualification");

          setParentEdu(parEdudata);

          ///motivation
          const motivdata = [];
          if (res.data.motivations.price) motivdata.push("Price");
          if (res.data.motivations.savesTime) motivdata.push("Saves Time");
          if (res.data.motivations.easeofUse) motivdata.push("Ease of Use");
          if (res.data.motivations.creativity) motivdata.push("Creativity");
          if (res.data.motivations.uniquencess) motivdata.push("Uniqueness");
          setMotivation(motivdata);
          /////hobby
          const hobbydata = [];
          if (res.data.hobbiesInterests.gamming) hobbydata.push("Gaming");
          if (res.data.hobbiesInterests.sport) hobbydata.push("Sport");
          if (res.data.hobbiesInterests.travelling)
            hobbydata.push("Travelling");
          if (res.data.hobbiesInterests.cinemaThreatre)
            hobbydata.push(" Cinema / Theatre");
          if (res.data.hobbiesInterests.artDesign)
            hobbydata.push("Art / Design");
          if (res.data.hobbiesInterests.blogging) hobbydata.push("Blogging");
          if (res.data.hobbiesInterests.volunteering)
            hobbydata.push("Volunteering");
          if (res.data.hobbiesInterests.yoga) hobbydata.push("Yoga");
          if (res.data.hobbiesInterests.reading) hobbydata.push("Reading");
          setHobby(hobbydata);
          ////housing
          const housindata = [];
          if (res.data.housingUnits.homeOwner) housindata.push("Home Owner");
          if (res.data.housingUnits.privateRenter)
            housindata.push("Private Renter");
          if (res.data.housingUnits.councilTennant)
            housindata.push("Council Tenent");
          if (res.data.housingUnits.housingBenifits)
            housindata.push("Housing Benefit");
          setHousing(housindata);
          ///belief
          const beliefdata = [];
          if (res.data.belief.anarchism) beliefdata.push("Anarchism");
          if (res.data.belief.absolutism) beliefdata.push("Absolutism");
          if (res.data.belief.liberalism) beliefdata.push("Liberalism");
          if (res.data.belief.conservatism) beliefdata.push("Conservatism");
          if (res.data.belief.socialism) beliefdata.push("Socialism");
          if (res.data.belief.capitalism) beliefdata.push("Capitalism");
          if (res.data.belief.other) beliefdata.push("Other");
          if (res.data.belief.prefer_not_to_say)
            beliefdata.push("Prefer not to say");
          setBelief(beliefdata);

          setCol(res.data.avatarColor);

          //add beliefs etc
        }
        if (err) {
          console.log(err);
        }

        setloading(false);
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };
  useEffect(() => getDetails(), []);

  const updatePersona = () => {
    let body = {
      avatarColor: Col,
      about: {
        name: About.name,
        description: About.description,
        occupation: About.occupation,
        status: About.status,
        religion: About.religion,
        gender: About.gender,
        sexuality: About.sexuality,
        disabilities: [About.disabilities],
        nationality: About.nationality,
        ethnicity: About.ethnicity,
      },
      income: {
        lessThan10K: Income.includes("less than 10K"),
        I_14k_18k: Income.includes("14K-18K"),
        I_19k_23k: Income.includes("19K-23K"),
        I_23k_27k: Income.includes("23K-27K"),
        I_28k_31k: Income.includes("28K-31K"),
        I_32k_35: Income.includes("32K-35K"),
        I_36k_39k: Income.includes("36K-39K"),
        I_40k_43k: Income.includes("40K-43K"),
        I_44k_47k: Income.includes("44K-47K"),
        I_100KPlus: Income.includes("100K+"),
      },
      age: {
        age_18_34: Age.includes("18-34"),
        age_35_44: Age.includes("35-44"),
        age_45_54: Age.includes("45-54"),
        age_55_64: Age.includes("55-64"),
        age_65_plus: Age.includes("65+"),
      },
      socialDemographicGroups: {
        A: true,
        B: true,
        C1: true,
        C2: true,
        D: true,
        E: true,
      },
      personality: {
        introvert: Personality.includes("Introvert"),
        intuitive: Personality.includes("Intuitive"),
        thinking: Personality.includes("Thinking"),
        judging: Personality.includes("Judging"),
        extrovert: Personality.includes("Extrovert"),
        sensing: Personality.includes("Sensing"),
        feeling: Personality.includes("Feeling"),
        perceiving: Personality.includes("Perceiving"),
      },
      gender: {
        male: true,
        female: true,
        nonBinary: true,
        genderFluid: true,
        transgender: true,
        intersex: true,
        twoSpirit: true,
        genderNonConfirm: true,
      },
      educationLevel: {
        higerEduation: Education.includes(
          "Higher Education & professional/vocational equivalents"
        ),
        aLevel: Education.includes(
          "A levels, vocational level 3 and equivalents"
        ),
        oLevel: Education.includes(
          "GCSE/O Level grade A*‐C, vocational level 2 and equivalents"
        ),
        levelOne: Education.includes("Qualifications at level 1 and below"),
        other: Education.includes(
          "Other qualifications: level unknown (including foreign qualifications)"
        ),
        noQualification: Education.includes("No qualifications"),
      },
      motivations: {
        price: Motivation.includes("Price"),
        savesTime: Motivation.includes("Saves Time"),
        easeofUse: Motivation.includes("Ease of Use"),
        creativity: Motivation.includes("Creativity"),
        uniquencess: Motivation.includes("Uniqueness"),
      },
      parentalEducationLevel: {
        One_with_Higher_education: ParentEdu.includes(
          "One parent with higher education qualification"
        ),
        More_than_one_with_Higher_education: ParentEdu.includes(
          "More than one parent with higher education qualification"
        ),
        No_with_Higher_education: ParentEdu.includes(
          "No parents with higher education qualification"
        ),
      },
      hobbiesInterests: {
        gamming: Hobby.includes("Gaming"),
        sport: Hobby.includes("Sport"),
        travelling: Hobby.includes("Travelling"),
        cinemaThreatre: Hobby.includes(" Cinema / Theatre"),
        artDesign: Hobby.includes("Art / Design"),
        blogging: Hobby.includes("Blogging"),
        volunteering: Hobby.includes("Volunteering"),
        yoga: Hobby.includes("Yoga"),
        reading: Hobby.includes("Reading"),
      },
      housingUnits: {
        homeOwner: Housing.includes("Home Owner"),
        privateRenter: Housing.includes("Private Renter"),
        councilTennant: Housing.includes("Council Tenent"),
        housingBenifits: Housing.includes("Housing Benefit"),
      },
      parental: {
        children: true,
        noChildren: true,
      },
      belief: {
        anarchism: Belief.includes("Anarchism"),
        absolutism: Belief.includes("Absolutism"),
        liberalism: Belief.includes("Liberalism"),
        conservatism: Belief.includes("Conservatism"),
        socialism: Belief.includes("Socialism"),
        capitalism: Belief.includes("Capitalism"),
        other: Belief.includes("Other"),
        prefer_not_to_say: Belief.includes("Prefer not to say"),
      },
    };

    if (istemplate) {
      body.isTemplate = true;
    } else {
      body.isTemplate = false;
    }

    console.log(body);
    axios
      .put(`${API}/mediaOwner/persona/${id}`, body, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          console.log("persona dropdown data >>>", res.data);
          back();
        }
        if (err) {
          console.log(err);
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };

  return (
    <div
      className={classes.customfield}
      style={{ backgroundColor: "transparent" }}
    >
      <ShowAlert />
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "15%",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "40px",
            }}
          >
            <div className={classes.boxShadow} style={{ width: "22%" }}>
              <FormControl className="select-checkbox" component="fieldset">
                <h2 style={{ color: "#4F74C9", fontWeight: "400" }}>Age</h2>
                <FormControl
                  component="fieldset"
                  className={[classes.selectControl, classes.customfield].join(
                    " "
                  )}
                  style={{ marginTop: "10px" }}
                >
                  <FormGroup>
                    {["18-34", "35-44", "45-54", "55-64", "65+"].map(
                      (option) => (
                        <FormControlLabel
                          disabled={!isUpdating}
                          control={
                            <Checkbox
                              checked={Age.includes(option)}
                              onChange={(e) => {
                                let newArr = [...Age];
                                e.target.checked
                                  ? newArr.push(option)
                                  : (newArr = newArr.filter(
                                      (e) => e != option
                                    ));

                                setAge(newArr);
                              }}
                              name={option}
                            />
                          }
                          label={option}
                        />
                      )
                    )}
                  </FormGroup>
                </FormControl>
                <hr />
                <h2 style={{ color: "#4F74C9", fontWeight: "400" }}>Income</h2>
                <FormControl
                  component="fieldset"
                  className={[classes.selectControl, classes.customfield].join(
                    " "
                  )}
                  style={{ marginTop: "10px" }}
                >
                  <FormGroup>
                    {[
                      "less than 10K",
                      "14K-18K",
                      "19K-23K",
                      "23K-27K",
                      "28K-31K",
                      "32K-35K",
                      "36K-39K",
                      "40K-43K",
                      "44K-47K",
                      "100K+",
                    ].map((option) => (
                      <FormControlLabel
                        disabled={!isUpdating}
                        control={
                          <Checkbox
                            checked={Income.includes(option)}
                            onChange={(e) => {
                              let newArr = [...Income];
                              e.target.checked
                                ? newArr.push(option)
                                : (newArr = newArr.filter((e) => e != option));

                              setIncome(newArr);
                            }}
                            name={option}
                          />
                        }
                        label={option}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
                <hr style={{ width: "100%" }} />
                <h2 style={{ color: "#4F74C9", fontWeight: "400" }}>
                  Personality
                </h2>
                <FormControl
                  component="fieldset"
                  className={[classes.selectControl, classes.customfield].join(
                    " "
                  )}
                  style={{ marginTop: "10px" }}
                >
                  <FormGroup>
                    {[
                      "Introvert",
                      "Intuitive",
                      "Thinking",
                      "Judging",
                      "Extrovert",
                      "Sensing",
                      "Feeling",
                      "Perceiving",
                    ].map((option) => (
                      <FormControlLabel
                        disabled={!isUpdating}
                        control={
                          <Checkbox
                            checked={Personality.includes(option)}
                            onChange={(e) => {
                              let newArr = [...Personality];
                              e.target.checked
                                ? newArr.push(option)
                                : (newArr = newArr.filter((e) => e != option));

                              setPersonality(newArr);
                            }}
                            name={option}
                          />
                        }
                        label={option}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </FormControl>
            </div>
            <div className={classes.boxShadow} style={{ width: "50%" }}>
              <h2 style={{ color: "#4F74C9", fontWeight: "400" }}>About</h2>

              <TextField
                disabled={!isUpdating}
                fullWidth
                id="Name"
                label="Name"
                variant="outlined"
                value={About.name}
                onChange={(e) => steAbout({ ...About, name: e.target.value })}
                style={{ display: "block", marginBottom: "10px" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleIcon color="disabled" />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                disabled={!isUpdating}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DomainIcon color="disabled" />
                    </InputAdornment>
                  ),
                }}
                style={{ display: "block", marginBottom: "10px" }}
                fullWidth
                id="outlined-select-currency-native"
                select
                label="Occupation"
                value={About.occupation}
                onChange={(e) =>
                  steAbout({ ...About, occupation: e.target.value })
                }
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              >
                <option key={0} value={0}>
                  Select...
                </option>
                {DropDowns?.occupations?.map((option) => (
                  <option key={option._id} value={option._id}>
                    {option.name}
                  </option>
                ))}
              </TextField>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <TextField
                  disabled={!isUpdating}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocalActivityIcon color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                  style={{ width: "49%", marginBottom: "10px" }}
                  fullWidth
                  id="outlined-select-currency-native"
                  select
                  label="Status"
                  value={About.status}
                  onChange={(e) =>
                    steAbout({ ...About, status: e.target.value })
                  }
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {DropDowns?.status?.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </TextField>
                <TextField
                  disabled={!isUpdating}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountBalanceIcon color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                  style={{ width: "49%", marginBottom: "10px" }}
                  fullWidth
                  id="outlined-select-currency-native"
                  select
                  label="Religion / Belief System"
                  value={About.religion}
                  onChange={(e) =>
                    steAbout({ ...About, religion: e.target.value })
                  }
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {DropDowns?.religions?.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <TextField
                  disabled={!isUpdating}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <WcIcon color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                  style={{ width: "49%", marginBottom: "10px" }}
                  fullWidth
                  id="outlined-select-currency-native"
                  select
                  label="Gender"
                  value={About.gender}
                  onChange={(e) =>
                    steAbout({ ...About, gender: e.target.value })
                  }
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {DropDowns?.gender?.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </TextField>
                <TextField
                  disabled={!isUpdating}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AcUnitIcon color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                  style={{ width: "49%", marginBottom: "10px" }}
                  fullWidth
                  id="outlined-select-currency-native"
                  select
                  label="Sexuality"
                  value={About.sexuality}
                  onChange={(e) =>
                    steAbout({ ...About, sexuality: e.target.value })
                  }
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {DropDowns?.sexuality?.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </TextField>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <TextField
                  disabled={!isUpdating}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccessibleIcon color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                  style={{ width: "49%", marginBottom: "10px" }}
                  fullWidth
                  id="outlined-select-currency-native"
                  select
                  label="Disability"
                  value={About.disabilities}
                  onChange={(e) =>
                    steAbout({ ...About, disabilities: e.target.value })
                  }
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {DropDowns?.disabilities?.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
                <TextField
                  disabled={!isUpdating}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmojiFlagsIcon color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                  style={{ width: "49%", marginBottom: "10px" }}
                  fullWidth
                  id="outlined-select-currency-native"
                  select
                  label="Nationality"
                  value={About.nationality}
                  onChange={(e) =>
                    steAbout({ ...About, nationality: e.target.value })
                  }
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {DropDowns?.countries?.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
                {/* <TextField
                  disabled={!isUpdating}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccessibilityIcon color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                  style={{ width: "49%", marginBottom: "10px" }}
                  fullWidth
                  id="outlined-select-currency-native"
                  select
                  label="Body Size"
                  value={About.bodyType}
                  onChange={(e) =>
                    steAbout({ ...About, bodyType: e.target.value })
                  }
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {DropDowns?.bodyType?.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </TextField> */}
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <TextField
                  disabled={!isUpdating}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FaceIcon color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                  style={{ width: "49%", marginBottom: "10px" }}
                  fullWidth
                  id="outlined-select-currency-native"
                  select
                  label="Ethnicity"
                  value={About.ethnicity}
                  onChange={(e) =>
                    steAbout({ ...About, ethnicity: e.target.value })
                  }
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {DropDowns?.demographics?.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </div>
              <TextField
                disabled={!isUpdating}
                fullWidth
                id="outlined-multiline-static"
                label="Description"
                multiline
                rows={6}
                //defaultValue="Biography / Description / Notes"
                placeholder="Biography / Description / Notes"
                variant="outlined"
                value={About.description}
                onChange={(e) =>
                  steAbout({ ...About, description: e.target.value })
                }
              />
              <h2 style={{ color: "#4F74C9", fontWeight: "400" }}>
                Education Level
              </h2>
              <FormControl
                component="fieldset"
                className={`select-checkbox ${[classes.selectControl, classes.customfield].join(
                  " "
                )}`}
                style={{ marginTop: "10px" }}
              >
                <FormGroup>
                  {[
                    "Higher Education & professional/vocational equivalents",
                    "A levels, vocational level 3 and equivalents",
                    "GCSE/O Level grade A*‐C, vocational level 2 and equivalents",
                    "Qualifications at level 1 and below",
                    "Other qualifications: level unknown (including foreign qualifications)",
                    "No qualifications",
                  ].map((option) => (
                    <FormControlLabel
                      disabled={!isUpdating}
                      control={
                        <Checkbox
                          checked={Education.includes(option)}
                          onChange={(e) => {
                            let newArr = [...Education];
                            e.target.checked
                              ? newArr.push(option)
                              : (newArr = newArr.filter((e) => e != option));

                            setEducation(newArr);
                          }}
                          name={option}
                        />
                      }
                      label={option}
                    />
                  ))}
                </FormGroup>
              </FormControl>
              <h2 style={{ color: "#4F74C9", fontWeight: "400" }}>
                Parental Education Level
              </h2>
              <FormControl
                component="fieldset"
                className={`select-checkbox ${[classes.selectControl, classes.customfield].join(
                  " "
                )}`}
                style={{ marginTop: "10px" }}
              >
                <FormGroup>
                  {[
                    "One parent with higher education qualification",
                    "More than one parent with higher education qualification",
                    "No parents with higher education qualification",
                  ].map((option) => (
                    <FormControlLabel
                      disabled={!isUpdating}
                      control={
                        <Checkbox
                          checked={ParentEdu.includes(option)}
                          onChange={(e) => {
                            let newArr = [...ParentEdu];
                            e.target.checked
                              ? newArr.push(option)
                              : (newArr = newArr.filter((e) => e != option));

                            setParentEdu(newArr);
                          }}
                          name={option}
                        />
                      }
                      label={option}
                    />
                  ))}
                </FormGroup>
              </FormControl>
              {!istemplate && (
                <h2 style={{ color: "#4F74C9", fontWeight: "400" }}>
                  Select Avatar Color
                </h2>
              )}
              {!istemplate && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  {["red", "green", "orange", "violet", "blue"].map((e) => (
                    <img
                      alt="media.Img"
                      src={user}
                      style={
                        Col === e
                          ? {
                              boxShadow: `0 0 16px black`,
                              backgroundImage: `linear-gradient(${e},${e},${e}, white)`,
                              width: "18%",
                              display: "block",
                              borderRadius: "50%",
                              marginBottom: "20px",
                            }
                          : {
                              backgroundImage: `linear-gradient(${e},${e},${e}, white)`,
                              width: "18%",
                              display: "block",

                              marginBottom: "20px",
                              borderRadius: "50%",
                            }
                      }
                      onClick={() =>
                        isUpdating
                          ? setCol(e)
                          : console.log("click edit to edit")
                      }
                    ></img>
                  ))}
                </div>
              )}
            </div>
            <div className={classes.boxShadow} style={{ width: "25%" }}>
              <h2 style={{ color: "#4F74C9", fontWeight: "400" }}>
                Motivations
              </h2>
              <FormControl
                component="fieldset"
                className={`select-checkbox ${[classes.selectControl, classes.customfield].join(
                  " "
                )}`}
                style={{ marginTop: "10px" }}
              >
                <FormGroup>
                  {[
                    "Price",
                    "Saves Time",
                    "Ease of Use",
                    "Creativity",
                    "Uniqueness",
                  ].map((option) => (
                    <FormControlLabel
                      disabled={!isUpdating}
                      control={
                        <Checkbox
                          checked={Motivation.includes(option)}
                          onChange={(e) => {
                            let newArr = [...Motivation];
                            e.target.checked
                              ? newArr.push(option)
                              : (newArr = newArr.filter((e) => e != option));

                            setMotivation(newArr);
                          }}
                          name={option}
                        />
                      }
                      label={option}
                    />
                  ))}
                </FormGroup>
              </FormControl>
              <h2 style={{ color: "#4F74C9", fontWeight: "400" }}>
                Hobbies & Interests
              </h2>
              <FormControl
                component="fieldset"
                className={`select-checkbox ${[classes.selectControl, classes.customfield].join(
                  " "
                )}`}
                style={{ marginTop: "10px" }}
              >
                <FormGroup>
                  {[
                    "Gaming",
                    "Sport",
                    "Travelling",
                    " Cinema / Theatre",
                    "Art / Design",
                    "Blogging",
                    "Volunteering",
                    "Yoga",
                    "Reading",
                  ].map((option) => (
                    <FormControlLabel
                      disabled={!isUpdating}
                      control={
                        <Checkbox
                          checked={Hobby.includes(option)}
                          onChange={(e) => {
                            let newArr = [...Hobby];
                            e.target.checked
                              ? newArr.push(option)
                              : (newArr = newArr.filter((e) => e != option));

                            setHobby(newArr);
                          }}
                          name={option}
                        />
                      }
                      label={option}
                    />
                  ))}
                </FormGroup>
              </FormControl>
              <h2 style={{ color: "#4F74C9", fontWeight: "400" }}>
                Housing Unit
              </h2>
              <FormControl
                component="fieldset"
                className={`select-checkbox ${[classes.selectControl, classes.customfield].join(
                  " "
                )}`}
                style={{ marginTop: "10px" }}
              >
                <FormGroup>
                  {[
                    "Home Owner",
                    "Private Renter",
                    "Council Tenent",
                    "Housing Benefit",
                  ].map((option) => (
                    <FormControlLabel
                      disabled={!isUpdating}
                      control={
                        <Checkbox
                          checked={Housing.includes(option)}
                          onChange={(e) => {
                            let newArr = [...Housing];
                            e.target.checked
                              ? newArr.push(option)
                              : (newArr = newArr.filter((e) => e != option));

                            setHousing(newArr);
                          }}
                          name={option}
                        />
                      }
                      label={option}
                    />
                  ))}
                </FormGroup>
              </FormControl>
              <h2 style={{ color: "#4F74C9", fontWeight: "400" }}>
                Political Beliefs
              </h2>
              <FormControl
                component="fieldset"
                className={`select-checkbox ${[classes.selectControl, classes.customfield].join(
                  " "
                )}`}
                style={{ marginTop: "10px" }}
              >
                <FormGroup>
                  {[
                    "Anarchism",
                    "Absolutism",
                    "Liberalism",
                    "Conservatism",
                    "Socialism",
                    "Capitalism",
                    "Other",
                    "Prefer not to say",
                  ].map((option) => (
                    <FormControlLabel
                      disabled={!isUpdating}
                      control={
                        <Checkbox
                          checked={Belief.includes(option)}
                          onChange={(e) => {
                            let newArr = [...Belief];
                            e.target.checked
                              ? newArr.push(option)
                              : (newArr = newArr.filter((e) => e != option));

                            setBelief(newArr);
                          }}
                          name={option}
                        />
                      }
                      label={option}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <Button
              className={classes.addActionBtn}
              style={{ color: "black", backgroundColor: "grey" }}
              onClick={() => back()}
            >
              Exit
            </Button>
            {/* {isUpdating ? (
              <Button
                className={classes.addActionBtn}
                style={{ backgroundColor: "green" }}
                onClick={() => updatePersona()}
              >
                Save
              </Button>
            ) : (
              <Button
                className={classes.addActionBtn}
                style={{ backgroundColor: "#4F74C9" }}
                onClick={() => setIsUpdating(true)}
              >
                Edit
              </Button>
            )} */}
          </div>
        </div>
      )}
    </div>
  );
};
export default SinglePersona;
