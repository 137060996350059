import React from "react"

const SimpleTable = ({ columns, data }) => {
    const renderTableHeader = () => {
        return columns.map((column, index) => {
            if (column === "CO2e") {
                return (
                    <th style={{ fontSize: "1.1rem", padding: "2px 12px", background: "#8E11A2", color: "#ffffff" }} key={index}>
                        {column} (kg)
                    </th>
                )
            } else {
                return (
                    <th style={{ fontSize: "1.1rem", padding: "2px 12px", background: "#8E11A2", color: "#ffffff" }} key={index}>
                        {column}
                    </th>
                )
            }
        })
    }

    const renderTableData = () => {
        return data.map((row, rowIndex) => {
            return (
                <tr key={rowIndex}>
                    {columns.map((column, columnIndex) => {
                        return (
                            <td style={{ fontSize: "0.9rem", padding: "4px 8px" }} key={columnIndex}>
                                {row[column]}
                            </td>
                        )
                    })}
                </tr>
            )
        })
    }

    return (
        <div style={{ width: "100%", fontSize: "1.8rem", height: "100%", overflowY: "auto" }}>
            <table style={{ borderRadius: "12px", width: "98%" }}>
                <thead>
                    <tr>{renderTableHeader()}</tr>
                </thead>
                <tbody>{renderTableData()}</tbody>
            </table>
        </div>
    )
}

export default SimpleTable
