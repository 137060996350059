/* eslint-disable react-hooks/rules-of-hooks */
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MailIcon from "@material-ui/icons/Mail";
import MenuIcon from "@material-ui/icons/Menu";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import icon from "../assets/CI-Icon.svg";
import culturalIntellWhite from "../assets/CI-Logo-white.png";

import axios from "axios";
import { useDispatch } from "react-redux";
import { API } from "../constants/index";
import { SaveAdminDropDownData } from "../store/actions/admin/intelligence";

import { green } from "@material-ui/core/colors";
import * as actions from "../redux/actions/user";
import Footer from "./footer";

import { ContactSupportOutlined } from "@material-ui/icons";
import MediaLoginForm from "../components/mediaLoginForm";
import MediaLogin from "../pages/signup/mediaLogin";

const drawerWidth = window.screen.width / 6;

const useStyles = (icon) =>
  makeStyles((theme) => ({
    root: {
      display: "flex",
      // width: "83%",
      // [theme.breakpoints.down("sm")]: {
      //   width: "100%",
      // },
    },
    drawer: {
      backgroundColor: "red",
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      background: "white",
      "& .MuiToolbar-regular": {
        minHeight: "50px",
      },
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      backgroundImage: `url(${icon}), linear-gradient(to right, #9211a2, #4911a2)`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      borderLeft: "10px solid #f64d0c",
      width: drawerWidth,
    },
    logoutBtn: {
      background: "#4F74C9",
      fontWeight: "400",
      color: "white",
      marginLeft: "auto",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    topicon: {
      width: "85%",
      margin: "20px auto",
    },
    bgicon: {
      position: "absolute",
      top: 0,
    },
    sideMenu: {
      "& li": {
        color: "white",
        listStyleType: "none",
        paddingBottom: "10px",
        cursor: "pointer",
        "& a": {
          color: "white",
          textDecoration: "none",
        },
      },
    },
    menuLink: {
      background: "#fff",
      width: "95%",
      borderRadius: "20px",
      fontWeight: "800",
      color: "#8e10a2",
      whiteSpace: "nowrap",
      "&:hover": {
        transition: "0.8s",
        background: "#fff",
        boxShadow:  "3px 3px 6px #f958ec, -3px -3px 6px #914cff"
      }
    },
    menuLinkSelected: {
      background: "#fff",
      width: "95%",
      borderRadius: "20px",
      fontWeight: "800",
      color: "#8e10a2",
      whiteSpace: "nowrap",
      boxShadow:  "3px 3px 6px #f958ec, -3px -3px 6px #914cff",
      "&:hover": {
        background: "#fff",
      }
    },
    loginWrapper: {
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "85%",
      height: "100vh",
      right: "0",
      "@media screen and (max-width: 1024px)": {
        width: "72%"
      },
      "@media screen and (max-width: 600px)": {
        display: "none"
      }
    },
    loginContainer: {
      "@media screen and (max-width: 768px)": {
        width: ""
      }
    }
  }));

const LoginLayout = (WrappedComponent) => (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const loggedInSession = useSelector((state) => state.loggedInSession);
  const classes = useStyles(icon)();
  const [mediaState, setMediaState] = useState("planner");

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const login = () => {
    dispatch(actions.error(null));
    dispatch(actions.loginSuccessfully(null));
    history.push("/login");
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;
  
  const toggleMedia = (type) => {
    setMediaState(type);
  };

  const ToggleMediaSwitch = () => {
    switch (mediaState) {
      case "owner":
        return (
          <MediaLoginForm
            type="Owner"
            colorTheme="#6a5fcd"
            role="media_owner"
          />
        );
      case "planner":
        return (
          <MediaLoginForm
            type="Planner"
            colorTheme="#e96941"
            role="media_agency"
          />
        );
      case "brand":
        return (
          <MediaLoginForm type="Brand" colorTheme="#e502ba" role="admin" />
        );
      case "creativeconsultant":
        return (
          <MediaLoginForm
            type="Consultant"
            colorTheme="#4911a2"
            role="creativeconsultant"
          />
        );
      // case "company":
      //   return (
      //     <MediaLoginForm type="Company" colorTheme="#e502ba" role="company" />
      //   );
      default:
      // code block
    }
  };

  return (
    <div className={classes.root} style={{ width: "100%" }}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>

          <Button
            onClick={() => history.push("/")}
            style={{
              background: "#4F74C9",
              fontWeight: "400",
              color: "white",
              marginLeft: "auto",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            Home
          </Button>
        </Toolbar>
      </AppBar>
      <nav
        className={classes.drawer}
        aria-label="mailbox folders"
        style={{ background: "red" }}
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css" style={{ background: "red" }}>
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <img className={classes.topicon} src={culturalIntellWhite} />
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css" style={{ background: "red" }}>
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <img className={classes.topicon} src={culturalIntellWhite} />
            <ul className={classes.sideMenu}>
              <li 
                onClick={() => {
                history.push({
                  search: `?iam=planner`,
                });
                toggleMedia("planner");
            }}>
                <Button 
                  className={mediaState === "planner" ? classes.menuLinkSelected : classes.menuLink}
                >
                  Media planner
                </Button>
              </li>
              <li
                onClick={() => {
                  history.push({
                    search: `?iam=owner`,
                  });
                  toggleMedia("owner");
                }}
              >
                <Button 
                  className={mediaState === "owner" ? classes.menuLinkSelected : classes.menuLink}
                >
                  Media owner
                </Button>
              </li>
              <li
                onClick={() => {
                  history.push({
                    search: `?iam=creativeconsultant`,
                  });
                  toggleMedia("creativeconsultant");
                }}
              >
                <Button 
                  className={mediaState === "creativeconsultant" ? classes.menuLinkSelected : classes.menuLink}
                >
                  Creative consultants
                </Button>  
              </li>
              <li
                onClick={() => {
                  history.push({
                    search: `?iam=brand`,
                  });
                  toggleMedia("brand");
                }}
              >
                <Button 
                  className={mediaState === "brand" ? classes.menuLinkSelected : classes.menuLink}
                >
                  Administrator
                </Button>
              </li>
            </ul>
          </Drawer>
        </Hidden>
      </nav>
      <div className={classes.toolbar} />
      <div style={{ width: "100%" }}>
        <Grid
          spacing={0}
          style={{ width: "100%", padding: "60px 30px", minHeight: "86vh" }}
        >
          <Grid item md={12}>
            <WrappedComponent {...props} />
          </Grid>
        </Grid>
        {/* <Footer /> */}
      </div>
      <div className={classes.loginWrapper}>
        <Container  maxWidth="sm" className={classes.loginContainer}>
          <ToggleMediaSwitch />
        </Container>
      </div>
    </div>
  );
};

export default LoginLayout;
