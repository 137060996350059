import React from 'react';
import { makeStyles, fade, withTheme, withStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
   
    progressBar: {
        width: '100%',
        height: '15px',
        //marginLeft:'50px',
        borderRadius: '10px',
    },
    progress: {
        borderRadius: '10px',
        height: '15px',
    },
    awarenessProgress: {
        borderRadius: '10px',
        width: '79%',
        height: '15px',
        backgroundColor: '#9E9D9D',
    },
    considerationProgress: {
        borderRadius: '10px',
        width: '29%',
        height: '15px',
        backgroundColor: '#369DC6',
    },
    preferenceProgress: {
        borderRadius: '10px',
        width: '29%',
        height: '15px',
        backgroundColor: '#F06D1D',
    },
    activeIntentProgress: {
        borderRadius: '10px',
        width: '29%',
        height: '15px',
        backgroundColor: '#F6B105',
    },
    center_Padding_8px: {
        alignSelf: 'center',
        paddingRight: '8px'
    },
    stepperWrapper: {
        paddingLeft: 0,
        paddingRight: 0,
        background: 'none'
    },
    
    pageTitle: {
        color: '#e96941',
        fontWeight: 'normal'
    },
   
    
}));
const BrandStandings=({data})=>{

    const classes = useStyles();
    const colors=["#9E9D9D","#369DC6","#F06D1D","#F6B105"];
    

    return(
        <>
        <h3 className={classes.pageTitle}>Brand Standings</h3>
        <div>
            {data && data.map((x,index)=>{
                return(
                <p key={index}>
                <span>{x.name} {x.value}</span>
                <div className={classes.progressBar}>
                    <div className={classes.progress} style={{ width : x.value, backgroundColor : colors[index] }}></div>
                </div>
                </p>)
            })} 
            
           
            {/* <p>
                <span>Preference 73%</span>
                <div className={classes.progressBar}>
                    <div className={classes.activeIntentProgress}></div>
                </div>
            </p> */}

        </div>
    </>
    )
}

export default BrandStandings;