import React, { useState } from "react"
import { Card, CardHeader, TextField, Button, Modal } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { useHistory } from "react-router-dom"
import axios from "axios"

import { API } from "../../constants/index"
import NewAlert from "../../components/NewAlert"
import Layout from "../../layouts/Home"
import Eye from "../../assets/eyePass.svg"
import SideLogo from "../../assets/CI-Icon.svg"
import SideLogoName from "../../assets/CI-Logo-white.png"
import culturalIntell from "../../assets/Cultural-Intelligence.svg"
import { red } from "@material-ui/core/colors"

const useStyless = (props) =>
  makeStyles((theme) => ({
    selectControl: {
      width: "100%",
      marginBottom: "8px",
    },
    paper: {},
    form: {
      width: "100%", // Fix IE 11 issue.
    },
    customfield: {
      paddingTop: "0",
      marginTop: "0",
      "& .MuiInputLabel-root.Mui-shrink": {
        color: "red",
      },
      "& .MuiOutlinedInput-input": {
        padding: "8.5px 14px",
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: "0",

        "& fieldset": {
          borderColor: "grey",
        },
        "&:hover fieldset": {},
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
        color: props.colorTheme,
      },
      "& .MuiInputLabel-root.Mui-focused": {
        color: props.colorTheme,
      },
      "& .MuiInputLabel-root.MuiInputLabel-shrink": {
        margin: "0!important",
      },
      "& .MuiInputLabel-root.MuiInputLabel-animated": {
        marginTop: "-8px",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: props.colorTheme,
      },
    },

    boxShadow: {
      position: "relative",
      padding: "40px 100px 0",
      boxShadow: "0px 0px 20px rgba(0,0,0,0.30), 0 20px 20px rgba(0,0,0,0.22)",
      borderRadius: "30px",
      backgroundColor: "white",
      marginTop: "-30px",
    },

    actionWrapper: {
      textAlign: "right",
      padding: "12px 0 20px",
      "& p": {
        color: props.colorTheme,
        fontSize: "2em",
        margin: 0,
        marginBottom: "5px",
      },
      "& button": {
        marginLeft: "10px",
      },
    },
    joinBtn: {
      backgroundColor: props.colorTheme,
    },
    loginBtn: {
      backgroundColor: props.colorTheme,
      color: "white",
    },
    errorMsg: {
      color: "red",
      marginBottom: "8px",
      marginTop: "-4px",
      display: "block",
      fontSize: ".8em",
    },
    logo: {
      // width: "200px",
      margin: "0 auto",
      display: "block",
    },
    resetPassword: {
      "&:hover": {
        color: "blue",
        cursor: "pointer",
      },
    },
    resetTextField: {
      "& .MuiInputBase-input.MuiOutlinedInput-input": {
        color: "#fff",
      },
    },
  }))

const ResetPassword = () => {
  const props = {
    colorTheme: "#e96941",
    role: "media_agency",
    type: "Planner",
  }
  const queryParameters = new URLSearchParams(window.location.search)
  const token = queryParameters.get("token")
  const email = queryParameters.get("email")
  const history = useHistory()
  const [password, setPassword] = useState("")
  const [ErrSucMsg, setErrSucMesg] = useState("")
  const [ErrSucType, setErrSucType] = useState(true)
  const [toggleEye, setToggleEye] = useState(false)
  const [open, setOpen] = useState(true)

  const useStyles = makeStyles((theme) => ({
    textReset: {
      "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl": {
        marginBottom: "0.5rem",
        color: "#fff",
      },
    },
    btn: {
      "& .MuiButtonBase-root": {
        marginTop: "0.5rem",
      },
    },
    resetPasswordwrapper: {
      "& .MuiOutlinedInput-input": {
        color: red,
        paddingRight: "3rem !important",
      },
    },
    "makeStyles-root-1": "background-color: #fff !important",
  }))

  const classes = useStyles()
  const classess = useStyless(props)()

  const passwordSubmit = async () => {
    if (!password && password === "") {
      setErrSucMesg("Please insert a password!")
      setErrSucType(false)
      return
    }

    await axios
      .post(`${API}/mediaPlanner/resetPassword`, {
        token,
        email,
        password,
      })
      .then((res) => {
        setTimeout(() => {
          history.push("/login")
        }, 1500)
        setErrSucMesg(res.data.message)
        setErrSucType(true)
      })
      .catch((error) => {
        console.log("err")
        setErrSucMesg(
          error.response && error.response.data && error.response.data.message
        )
        setErrSucType(false)
      })
  }

  return (
    <div style={{display:"flex", flexDirection:"row", height:"100vh"}}>
      <div style={{flex:"1", background:"linear-gradient(to right, #9211a2, #4911a2)", display:"flex", flexDirection:"column"}}>
        <div style={{flex:"1", display:"flex", justifyContent:"center", margin:"1rem 0"}}>
          <img src={SideLogoName} alt="logo" width="80%"/>
        </div>
        <div style={{flex:"5", display:"flex", justifyContent:"center"}}>
          <img src={SideLogo} alt="Logo" width="90%"/>
        </div>
      </div>
      <div style={{flex:"4", display:"flex", flexDirection:"column"}}>
        <div style={{flex:"0.5", boxShadow: "0px 4px 8px -4px rgba(0,0,0,0.75)", display:"flex", alignItems:"center", justifyContent:"end"}}>
          <Button style={{marginRight:"1.5rem", background:"rgb(79, 116, 201)"}}>Home</Button>
        </div>
        <div style={{flex:"4", display:"flex", alignItems:"center", justifyContent:"center"}}>
            <div style={{
              width:"550px", 
              height:"400px", 
              display:"flex", 
              flexDirection:"column", 
              justifyContent:"center", 
              // border:"1px solid red",
              borderRadius: "15px",
              padding:"1.5rem",
              boxShadow:  "27px 27px 55px #c7c7c7, -27px -27px 55px #f9f9f9",
              }}>
              <div style={{margin: "0.5rem 0"}}>
                <img src={culturalIntell} alt="Cultural Intelligence"/>
              </div>
              <div style={{textAlign:"center", margin: "0.5rem 0"}}>
                <p style={{fontWeight:"700"}}>Enter new password</p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginLeft: "1.3rem",
                    width: "100%"
                  }}
                  className={classes.resetPasswordwrapper}
                >
                  <TextField
                    type={toggleEye ? "text" : "password"}
                    variant="outlined"
                    minWsidth="100px"
                    name="password"
                    autoComplete="off"
                    label="New password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <img
                    src={Eye}
                    alt="Eye"
                    width="25px"
                    height="23px"
                    style={{
                      position: "relative",
                      right: "2rem",
                      top: "1rem",
                      cursor: "pointer",
                    }}
                    onClick={() => setToggleEye(!toggleEye)}
                  />
                </div>
              </div>
              <div style={{margin:"1rem 0 2rem 0", textAlign:"center"}}>
                <Button
                    variant="contained"
                    style={{
                      color: "black",
                      backgroundColor: "grey",
                      paddingTop: "0.3rem",
                      marginRight: "0.3rem",
                    }}
                    onClick={() => history.push("/login")}
                    className={classes.btn}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ paddingTop: "0.3rem" }}
                    onClick={passwordSubmit}
                    className={classes.btn}
                  >
                    Save
                  </Button>
              </div>
            </div>
        </div>
        <div style={{flex:"1.5", }}>

        </div>
      </div>
      
      {/* <div style={{ position: "absolute", bottom: "20px", width: "38%" }}>
        <h4
          style={{
            fontSize: "20px",
            color: "#F0797B",
            fontWeight: "700",
            lineHeight: "100%",
            padding: "0",
            marginBottom: "5px",
          }}
        >
          Cultural Intelligence
        </h4>
        <span
          style={{
            fontSize: "15px",
            color: "#4F74C9",
            fontWeight: "500",
            lineHeight: "100%",
          }}
        >
          Our cultural marketing specialists will help your brand to scale
          effectively within any of our channels.
        </span>
      </div> */}
      {/* <Modal
        open={open}
        onClose={() => {
          setOpen(true)
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card>
          <NewAlert
            msg={ErrSucMsg}
            isSuccess={ErrSucType}
            close={() => setErrSucMesg("")}
          />
            <div
              style={{
                maxWidth: "400px",
                minWidth: "300px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                color: "#fff",
                background: "linear-gradient(to right, #9211a2, #4911a2)",
                padding: "1rem 0.8rem",
              }}
            >
              <p>Enter new password</p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginLeft: "1.3rem",
                  width: "100%"
                }}
                className={classes.resetPasswordwrapper}
              >
                <TextField
                  type={toggleEye ? "text" : "password"}
                  variant="outlined"
                  minWsidth="100px"
                  name="password"
                  autoComplete="off"
                  label="New password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <img
                  src={Eye}
                  alt="Eye"
                  width="25px"
                  height="23px"
                  style={{
                    position: "relative",
                    right: "2rem",
                    top: "1rem",
                    cursor: "pointer",
                  }}
                  onClick={() => setToggleEye(!toggleEye)}
                />
              </div>
              <div style={{ marginTop: "1.5rem" }}>
                <Button
                  variant="contained"
                  style={{
                    color: "black",
                    backgroundColor: "grey",
                    paddingTop: "0.3rem",
                    marginRight: "0.3rem",
                  }}
                  onClick={() => history.push("/login")}
                  className={classes.btn}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ paddingTop: "0.3rem" }}
                  onClick={passwordSubmit}
                  className={classes.btn}
                >
                  Save
                </Button>
              </div>
            </div>
        </Card>
      </Modal> */}
    </div>
  )
}

export default ResetPassword
