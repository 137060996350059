import React, { useState, useEffect, useRef, createRef, useMemo } from "react";
import { makeStyles, fade, withTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

//Button
import Button from "@material-ui/core/Button";

//Icon
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ControlPointTwoToneIcon from "@material-ui/icons/ControlPointTwoTone";

//TextField
import TextField from "@material-ui/core/TextField";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import AdminMainLayout from "../../layouts/adminMainLayout";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllMediaOwner,
  listMediaOwnerTitles,
} from "../../redux/actions/media-owner";
import Paper from "@material-ui/core/Paper";
import StarBorderRoundedIcon from "@material-ui/icons/StarBorderRounded";
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import BlockIcon from "@material-ui/icons/Block";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import IconButton from "@material-ui/core/IconButton";
import StepWrapper from "../mediaOwner/stepWrapper";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import { DataGrid } from "@material-ui/data-grid";
import {
  Backdrop,
  Fade,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
} from "@material-ui/core";
import { searchUsers } from "../../selectors/search";
import RegisterFormPopUp from "./RegisterFormPopUp";
import { getUsers } from "../../redux/actions/admin";
import UploadCSVs from "../../components/UploadCSVs";
import axios from "axios";
import { API } from "../../constants/index";
import Papa from "papaparse";
import MyTable from "../mediaOwner/mytable";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  addActionBtn: {
    padding: "10px",
    backgroundColor: "#136cc3",
    color: "white",
    fontWeight: "bold",
    borderRadius: "23px",
    textTransform: "none",
    "&:hover": {
      background: "#136cc3",
    },
  },
  customfield: {
    paddingTop: "0",
    marginTop: "10",
    backgroundColor: "#f4f4f8",
    borderRadius: "5px",
    "& .MuiInputLabel-root.Mui-shrink": {
      color: "red",
    },
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&$focused": {
      backgroundColor: "#fff",
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.5px 14px",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",

      "& fieldset": {
        borderColor: "grey",
      },
      "&:hover fieldset": {},
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "grey",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "grey",
    },
    "& .MuiInputLabel-root.MuiInputLabel-shrink": {
      margin: "0!important",
    },
    "& .MuiInputLabel-root.MuiInputLabel-animated": {
      marginTop: "-8px",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",
    },
  },
  gridRoot: {
    height: "400px",
    width: "100%",
    padding: "10px",
    "& .font-tabular-nums": {
      fontVariantNumeric: "tabular-nums",
    },
  },
  selectControl: {
    marginBottom: "8px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
  },
  modalPaper: {
    border: "none",
    width: "500px",
    borderColor: "transparent",
    textAlign: "center",
    "&:focus": {
      outline: "none",
    },
  },
}));

const MediaOwner = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [roleType, setRoleType] = useState("media_owner");
  const [colorForm, setColorForm] = useState("#6a5fcd");

  const [open, setOpen] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [editFormName, setEditFormName] = useState("");
  const [editFormUN, setEditFormUN] = useState("");
  const [editFormEmail, setEditFormEmail] = useState("");
  const [editFormIdex, setEditFormIndex] = useState(null);

  const [DATA, setDATA] = useState(null);

  useEffect(() => {
    if (DATA) {
      let td = [];
      DATA.forEach((e, i) => {
        td.push({
          data: e,
          _id: i,
        });
      });
      setTableData(td);
    }
  }, [DATA]);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item md={6} sm={6} xs={6}>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
                style={{ marginTop: "10px" }}
              >
                {/* <InputLabel id="demo-simple-select-label">Select By Role</InputLabel> */}
                <Select
                  aria-label="Sizes"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={roleType}
                  onChange={(e) => setRoleType(e.target.value)}
                >
                  <MenuItem value={"media_owner"}>Media Owner</MenuItem>
                  <MenuItem value={"media_agency"}>Media Planer</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3} sm={3} xs={3}>
              <Button
                onClick={() =>
                  roleType === "media_owner"
                    ? history.push("/admin/MediaOwnerProfile")
                    : history.push("/admin/MediaPlannerProfile")
                }
                className={classes.addActionBtn}
                variant="outlined"
                color="primary"
              >
                <AddCircleOutlineIcon
                  style={{ marginRight: "10px", color: "#a2e60f" }}
                />
                {roleType === "media_owner"
                  ? "Create Media Owner"
                  : "Create Media Planner"}
              </Button>
            </Grid>
          </Grid>
          <Grid item md={6} sm={6} xs={6}>
            {/* <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ marginRight: "10px" }}>Please Upload CSV</div>
              <UploadCSVs />
            </div> */}
            <input
              type="file"
              accept=".csv,.xlsx,.xls"
              onChange={(e) => {
                const files = e.target.files;
                if (files) {
                  Papa.parse(files[0], {
                    complete: function (results) {
                      setDATA(results.data);
                    },
                  });
                }
              }}
            />
          </Grid>
          <div style={{ marginTop: "20px" }}>
            {tableData && (
              <MyTable
                isEditable={true}
                headings={["Name", "User Name", "Email", "Status"]}
                listdata={tableData}
                redirectLink={"MediaOwnerProfile?id="}
                HandleCSV={(row) => {
                  setEditFormName(row.data[0]);
                  setEditFormUN(row.data[1]);
                  setEditFormEmail(row.data[2]);
                  setEditFormIndex(row._id);
                  setOpen(true);
                }}
                handleDelete={(_id) => {
                  const removed = [...DATA];
                  removed.splice(_id, 1);
                  setDATA(removed);
                }}
              />
            )}
          </div>
        </Grid>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          fullWidth
          onClose={() => setOpen(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.boxShadow}>
              <div
                className={classes.customfield}
                style={{
                  width: "100%",
                  padding: "50px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <TextField
                  fullWidth
                  placeholder="Name"
                  label="Name"
                  variant="outlined"
                  style={{ display: "block", marginBottom: "10px" }}
                  value={editFormName}
                  onChange={(e) => {
                    setEditFormName(e.target.value);
                  }}
                />
                <TextField
                  fullWidth
                  placeholder="User Name"
                  label="User Name"
                  variant="outlined"
                  style={{ display: "block", marginBottom: "10px" }}
                  value={editFormUN}
                  onChange={(e) => {
                    setEditFormUN(e.target.value);
                  }}
                />
                <TextField
                  fullWidth
                  placeholder="Email"
                  label="Email"
                  variant="outlined"
                  style={{ display: "block", marginBottom: "10px" }}
                  value={editFormEmail}
                  onChange={(e) => {
                    setEditFormEmail(e.target.value);
                  }}
                />

                <button
                  fullWidth
                  onClick={() => {
                    let updated = [...DATA];
                    updated.splice(editFormIdex, 1, [
                      editFormName,
                      editFormUN,
                      editFormEmail,
                      "Active",
                    ]);
                    setDATA(updated);
                    setOpen(false);
                  }}
                  style={{
                    backgroundColor: "blue",
                    color: "white",
                    borderRadius: "7px",
                    padding: "10px 25px",
                    border: "none",
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </Fade>
        </Modal>
      </Grid>
    </div>
  );
};

export default AdminMainLayout(MediaOwner);
