import React from "react";
import MainLayout from "../../layouts/newMainLayoutPlanner";
import { SECRET_KEY } from "../../constants/index";
import jwt_decode from "jwt-decode";
import Subscription from "./subscription";

const Talkwalker = () => {
  const access_token = window.localStorage.getItem(SECRET_KEY);
  const getSubscription = window.localStorage.getItem("subscription");
  let decodedSubscription = getSubscription && jwt_decode(getSubscription);

  return (
    <div>
      {decodedSubscription && decodedSubscription.subscription === "free" ? (
        <Subscription />
      ) : (
        <div>
         <iframe src="https://app.talkwalker.com/" width="540" height="450"></iframe>
        </div>
      )}
    </div>
  );
};
export default MainLayout(Talkwalker);
