/* eslint-disable no-unused-expressions */
import { Button, FormControl, FormLabel, Grid, TextField } from "@material-ui/core";
import { Add, Delete } from "@material-ui/icons";
import React from "react";
import useEffectOnce from "../../../../Hooks/useEffectOnce";

export default function TrackingPixel({
    tracking_pixels,
    setFormFields,
    tracking_pixel_errors,
    setTrackingPixelErrors,
}) {
    const addNewTrackingPixel = (index) => {
        const new_tracking_pixel = [...tracking_pixels];
        new_tracking_pixel[index] = {
            id: Date.now().toString(),
            value: "",
            index,
        };
        setFormFields((old_val) => ({
            ...old_val,
            tracking_pixels: new_tracking_pixel,
        }));
    };

    const deleteTrackingPixel = (pixel_id, index) => {
        if (index == 0) return;
        const new_tracking_pixel = [...tracking_pixels].filter((item) => item.id != pixel_id);
        setFormFields((old_val) => ({ ...old_val, tracking_pixels: new_tracking_pixel }));
        setTrackingPixelErrors((errors) => {
            errors[index] = "";
            return errors;
        });
    };

    const handleChange = (value, id) => {
        const new_tracking_pixel = [...tracking_pixels].map((item) => {
            if (item.id == id) {
                return {
                    ...item,
                    value,
                    touched: true
                };
            }
            return item;
        });

        setFormFields((old_val) => ({ ...old_val, tracking_pixels: new_tracking_pixel }));

        new_tracking_pixel?.forEach((pixel, index) => {
            if (
                !/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/.test(
                    pixel?.value
                ) && pixel?.touched
            ) {
                setTrackingPixelErrors((old_errors) => {
                    old_errors[index] = "Invalid URL format";
                    return old_errors;
                });
            } else {
                setTrackingPixelErrors((old_errors) => {
                    old_errors[index] = undefined;
                    return old_errors;
                });
            }
        });
    };

    useEffectOnce(() => {
        // add initial pixel item
        if (tracking_pixels.length == 0) {
            addNewTrackingPixel(tracking_pixels.length);
        }
    });

    return (
        <Grid item xs={12} lg={12} md={12} sm={12}>
            <FormControl component="fieldset">
                <FormLabel component="legend">Tracking Pixel</FormLabel>
            </FormControl>

            <Grid>
                {tracking_pixels?.map((pixel, i) => {
                    return (
                        <>
                            <Grid key={pixel.id} style={{ display: "flex", alignItems: "center" }}>
                                <Grid item style={{ flex: 1 }}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        id={pixel?.id}
                                        label=""
                                        name={pixel?.id}
                                        value={pixel?.value}
                                        onChange={(e) => handleChange(e.target.value, pixel?.id)}
                                        placeholder="Type in pixel..."
                                    />
                                </Grid>
                                {/* <Grid item>
                                    <Button onClick={() => deleteTrackingPixel(pixel.id, i)} style={{background:"none"}}>
                                        <Delete />
                                    </Button>
                                </Grid> */}
                            </Grid>
                            {tracking_pixel_errors[i] && <div style={{ color: "red" }}>{tracking_pixel_errors[i]}</div>}
                        </>
                    );
                })}
            </Grid>

            {/* add button */}
            {/* <Grid>
                <Button
                    onClick={() => addNewTrackingPixel(tracking_pixels?.length)}
                    disabled={tracking_pixels.length >= 5}
                >
                    <Add style={{ marginRight: "10px" }} /> Add Another Tracking Pixel URL
                </Button>
                {tracking_pixels.length >= 5 && <div>Tracking Pixels limited to: 5</div>}
            </Grid> */}
        </Grid>
    );
}
