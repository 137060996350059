import React, { useState, useEffect } from "react";
import { makeStyles, fade } from "@material-ui/core/styles";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

import Autocomplete from "@material-ui/lab/Autocomplete";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

//Text field
import TextField from "@material-ui/core/TextField";

//Button
import Button from "@material-ui/core/Button";
import { useSelector } from "react-redux";

import Checkbox from "@material-ui/core/Checkbox";

import FormGroup from "@material-ui/core/FormGroup";

import { useDispatch } from "react-redux";
import axios from "axios";
import { API } from "../../constants/index";

import CircularProgress from "@material-ui/core/CircularProgress";

// import culturalIntell from "../../assets/Cultural-Intelligence.svg";
import sentmentLogo from "../../assets/mediaOwn.PNG";
import useStyles from "../../components/StyleClasses";

import { SECRET_KEY } from "./../../constants/index";
import NewAlert from "../../components/NewAlert";

const BrandMetrics = ({ onDone }) => {
  const initialData = {
    genderSplit: {
      male: 0,
      female: 0,
      nonBinary: 0,
      genderFluid: 0,
      transgender: 0,
      intersex: 0,
      twoSpirit: 0,
      genderNonConfirm: 0,
    },
    channels: [],
    _id: "",
    countries: [
      { country: 0, percent: 0 },
      { country: 0, percent: 0 },
      { country: 0, percent: 0 },
      { country: 0, percent: 0 },
      { country: 0, percent: 0 },
    ],
    audienceEthnicity: "",
    basedInCountry: 0,
    questions: [],
    id: "",
  };

  const [demographic, setDemographic] = useState(0);
  const [industry, setIndustry] = useState(0);
  const [ageGroup, setAgeGroup] = useState(0);
  const [mediaOwner, setMediaOwner] = useState(0);
  const [mediaOwnerList, setMediaOwnerList] = useState([]);
  const [countries, setCountries] = useState(initialData.countries);
  const [genderSplit, setGenderSplit] = useState(initialData.genderSplit);
  const [basedCountry, setBasedCountry] = useState(initialData.basedInCountry);
  const [ethnicity, setEthnicity] = useState(initialData.audienceEthnicity);
  const [channels, setChannels] = useState(initialData.channels);
  const [questions, setQuestions] = useState(initialData.questions);
  const [metricData, setMetricData] = useState(initialData);
  const [isUpdate, setIsUpdate] = useState(false);

  const [mediaOwnerNameSearch, setMediaOwnerNameSearch] = useState("");

  const [loading, setLoading] = useState(false);

  ///////errors & successs //////////
  const [ErrSucMsg, setErrSucMesg] = useState("");
  const [ErrSucType, setErrSucType] = useState(true);
  ///////////////////////////////////

  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.loggedInSession);
  const dropdownData = useSelector((state) => state.adminDropDownData);
  const classes = useStyles();
  const access_token = window.localStorage.getItem(SECRET_KEY);

  const AddData = () => {
    const body = {};
    body.demographics = demographic;
    body.industry = industry;
    body.ageGroup = ageGroup;
    body.mediaOwner = mediaOwner;
    body.countries = countries;
    body.genderSplit = genderSplit;
    body.audienceEthnicity = ethnicity;
    body.channels = channels;
    body.basedInCountry = basedCountry;
    const toSendQuestions = [...questions];
    toSendQuestions.forEach((e) => {
      delete e.mediaInsight;
      delete e.title;
      delete e._id;
    });
    body.qstn_ans = toSendQuestions;

    axios
      .post(`${API}/admin/intelligence/MediaQuestionaire`, body, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        refresh();

        if (err) console.log(err);
        if (res) {
          setErrSucMesg(res.data.message);
          setErrSucType(true);
        }
      })
      .catch(function (error) {
        if (error) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
        }
      });
  };
  const UpdateData = () => {
    const body = {};
    const countryToSend = countries;
    countryToSend.forEach((e) => delete e.name);
    body.countries = countryToSend;
    body.genderSplit = genderSplit;
    body.audienceEthnicity = ethnicity;
    body.channels = channels;
    body.basedInCountry = basedCountry;
    const toSendQuestions = [...questions];
    toSendQuestions.forEach((e) => {
      delete e.title;
    });
    body.qstn_ans = toSendQuestions;

    axios
      .put(
        `${API}/admin/intelligence/MediaQuestionaire/${metricData._id}`,
        body,
        {
          headers: { Authorization: `Bearer ${access_token}` },
        }
      )
      .then((res, err) => {
        refresh();

        if (err) console.log(err);
        if (res) {
          setErrSucMesg(res.data.message);
          setErrSucType(true);
        }
      })
      .catch(function (error) {
        if (error) {
        }
        setErrSucMesg(error.response.data.message);
        setErrSucType(false);
      });
  };

  const refresh = () => {
    if (demographic != 0 && industry != 0 && ageGroup != 0 && mediaOwner != 0) {
      setIsUpdate(false);
      setLoading(true);

      ///check the url////////
      axios
        .get(
          `${API}/admin/intelligence/MediaOwnerInsight?demographics=${demographic}&ageGroup=${ageGroup}&industry=${industry}&mediaOwner=${mediaOwner}`,
          {
            headers: { Authorization: `Bearer ${access_token}` },
          }
        )
        .then((res, err) => {
          setLoading(false);
          if (err) {
          } else {
            if (Object.keys(res.data).length !== 0) {
              /// change condition later when res.data comes as empty array
              setMetricData(res.data);
              setIsUpdate(true);
            } else {
              setIsUpdate(false);
              setMetricData(initialData);
            }
          }
        })
        .catch(function (error) {
          if (error) {
          }
        });
    }
  };
  useEffect(() => {
    setCountries(metricData.countries);
    setBasedCountry(metricData.basedInCountry);
    setGenderSplit(metricData.genderSplit);
    setEthnicity(metricData.audienceEthnicity);
    setQuestions(metricData.questions);
    const slectedChannels = metricData.channels.map((e) => e._id);
    setChannels(slectedChannels);
  }, [metricData]);

  useEffect(() => {
    refresh();
  }, [demographic, industry, ageGroup, mediaOwner]);

  const handleCountryName = (val, ind) => {
    let cou = [...countries];
    cou[ind].country = val;
    setCountries(cou);
  };
  const handleCountryPercent = (val, ind) => {
    let cou = [...countries];
    cou[ind].percent = val;
    setCountries(cou);
  };

  useEffect(() => {
    axios
      .get(`${API}/admin/intelligence/mediaOwners?name=`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res) {
          setMediaOwnerList(res.data);
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  }, []);

  const updateQuestions = (data) => {
    data.forEach((e) => {
      e.question = e.title;
      e.ans = "";
    });
    let existingQues = questions.map((e) => e.question);
    data = data.filter((e) => !existingQues.includes(e.question));
    let updatedQ = [...questions, ...data];
    updatedQ = updatedQ.filter((e) => channels.includes(e.mediaChannel));
    setQuestions(updatedQ);
  };

  useEffect(() => {
    if (channels.length > 0) {
      let medown = "mediaChannel=";
      channels.forEach((chn, i) => {
        if (i < channels.length - 1) {
          medown += chn + "&mediaChannel=";
        } else {
          medown += chn;
        }
      });
      axios
        .get(`${API}/admin/intelligence/MediaChanelQuestions?${medown}`, {
          headers: { Authorization: `Bearer ${access_token}` },
        })
        .then((res) => {
          if (res) {
            updateQuestions(res.data);
          }
        })
        .catch(function (error) {
          if (error) {
          }
        });
    } else {
      setQuestions([]);
    }
  }, [channels]);

  return (
    <div className={classes.boxShadow}>
      <div className={classes.paper}>
        <NewAlert
          msg={ErrSucMsg}
          isSuccess={ErrSucType}
          close={() => setErrSucMesg("")}
        />
        <form
          className={classes.form}
          // ref={useRef()  }
          //onSubmit={handleSubmit(onSubmit)}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              fontSize: "10px",
              justifyContent: "space-around",
            }}
          >
            <div style={{ width: "18%", margin: "2%" }}>
              <img src={sentmentLogo} style={{ width: "100%" }} />
            </div>

            <div style={{ width: "18%", margin: "2%" }}>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
                style={{ marginTop: "10px" }}
              >
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Demographic"
                  value={demographic}
                  onChange={(e) => setDemographic(e.target.value)}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {dropdownData?.demographics?.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </FormControl>
            </div>
            <div style={{ width: "18%", margin: "2%" }}>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
                style={{ marginTop: "10px" }}
              >
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Age Group"
                  value={ageGroup}
                  onChange={(e) => setAgeGroup(e.target.value)}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {dropdownData?.ageGroups?.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </FormControl>
            </div>
            <div style={{ width: "18%", margin: "2%" }}>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
                style={{ marginTop: "10px" }}
              >
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Industry"
                  value={industry}
                  onChange={(e) => setIndustry(e.target.value)}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {dropdownData?.industries?.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </FormControl>
            </div>

            {
              //////////////media owner///////////////////////////////////////////////////////
            }
          </div>
          <div style={{ width: "18%", margin: "2%" }}>
            <FormControl
              fullWidth
              component="fieldset"
              variant="outlined"
              className={[classes.selectControl, classes.customfield].join(" ")}
              style={{ marginTop: "10px" }}
            >
              <Autocomplete
                id="combo-box-demo"
                options={mediaOwnerList}
                onChange={(e, val) => setMediaOwner(val && val._id)}
                getOptionLabel={(option) => option.name}
                style={{ width: "100%" }}
                renderOption={(option) => (
                  <div>
                    <span>{option.name}</span>
                    <br></br>
                    <span
                      style={{
                        fontSize: "10px",
                        fontWeight: "100",
                      }}
                    >
                      {option.username}
                    </span>
                  </div>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Media Owner"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
          </div>
          <div style={{ marginTop: "25px" }}>
            {loading && <CircularProgress />}
          </div>
          {!loading &&
            demographic != 0 &&
            industry != 0 &&
            ageGroup != 0 &&
            mediaOwner != 0 && (
              <div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    fontSize: "10px",
                    justifyContent: "space-around",
                  }}
                >
                  {
                    /////////////////////////// top 5 countries/////////////////////////////////////////
                  }
                  <div style={{ width: "30%", margin: "2%" }}>
                    <FormControl
                      fullWidth
                      component="fieldset"
                      variant="outlined"
                      className={[
                        classes.selectControl,
                        classes.customfield,
                      ].join(" ")}
                      style={{ marginTop: "10px" }}
                    >
                      <FormLabel>Top 5 Countries</FormLabel>
                      {countries.map((cc, i) => (
                        <div
                          style={{
                            marginTop: "10px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <TextField
                            style={{ width: "65%" }}
                            id="outlined-select-currency-native"
                            select
                            label={`country ${i + 1}`}
                            value={cc.country}
                            onChange={(e) =>
                              handleCountryName(e.target.value, i)
                            }
                            SelectProps={{
                              native: true,
                            }}
                            variant="outlined"
                          >
                            <option key={0} value={"select"}>
                              Select...
                            </option>
                            {dropdownData?.countries?.map((option) => (
                              <option key={option._id} value={option._id}>
                                {option.name}
                              </option>
                            ))}
                          </TextField>
                          <TextField
                            style={{ width: "30%" }}
                            type="number"
                            id="outlined-select-currency-native"
                            label="percentage"
                            value={cc.percent}
                            onChange={(e) =>
                              handleCountryPercent(e.target.value, i)
                            }
                            SelectProps={{
                              native: true,
                            }}
                            variant="outlined"
                          ></TextField>
                        </div>
                      ))}
                    </FormControl>

                    {
                      /////////////////////////// based in country/////////////////////////////////////////
                    }

                    <TextField
                      style={{ width: "100%", marginTop: "25%" }}
                      id="outlined-select-currency-native"
                      select
                      label="Based in country"
                      value={basedCountry}
                      onChange={(e) => setBasedCountry(e.target.value)}
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                    >
                      <option key={0} value={0}>
                        Select...
                      </option>
                      {dropdownData?.countries?.map((option) => (
                        <option key={option._id} value={option._id}>
                          {option.name}
                        </option>
                      ))}
                    </TextField>
                  </div>
                  {
                    /////////////////////////// Gender Split/////////////////////////////////////////
                  }
                  <div style={{ width: "25%", margin: "2%" }}>
                    <FormControl
                      fullWidth
                      component="fieldset"
                      variant="outlined"
                      className={[
                        classes.selectControl,
                        classes.customfield,
                      ].join(" ")}
                      style={{ marginTop: "10px" }}
                    >
                      <FormLabel>Gender Split</FormLabel>
                      {Object.entries(genderSplit).map((gs) => (
                        <div
                          style={{
                            marginTop: "10px",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <TextField
                            style={{ width: "36%", marginRight: "10px" }}
                            type="number"
                            id="outlined-select-currency-native"
                            label="percentage"
                            value={gs[1]}
                            onChange={(e) => {
                              let newdata = { ...genderSplit };
                              newdata[gs[0]] = e.target.value;
                              setGenderSplit(newdata);
                            }}
                            SelectProps={{
                              native: true,
                            }}
                            variant="outlined"
                          ></TextField>
                          {`% ${gs[0]}`}
                        </div>
                      ))}
                    </FormControl>
                  </div>
                  {
                    /////////////////////////// audeince ethnicitty/////////////////////////////////////////
                  }
                  <div style={{ width: "25%", margin: "2%" }}>
                    <FormControl
                      component="fieldset"
                      className={[
                        classes.selectControl,
                        classes.customfield,
                      ].join(" ")}
                      style={{ marginTop: "10px" }}
                    >
                      <FormLabel component="legend">
                        Audience Ethnicity
                      </FormLabel>
                      <RadioGroup
                        aria-label="AudeinecEthnicity"
                        name="AudeinecEthnicity"
                        value={ethnicity}
                        onChange={(e) => setEthnicity(e.target.value)}
                      >
                        {[
                          "Arab",
                          "Black",
                          "White British",
                          "Mediterranean",
                          "Mixed/Bireacial",
                          "South Asian",
                          "White Other",
                          "Black Other",
                          "Other",
                        ].map((opt) => {
                          return (
                            <FormControlLabel
                              value={opt}
                              control={<Radio />}
                              label={opt}
                            />
                          );
                        })}
                      </RadioGroup>
                    </FormControl>
                  </div>
                  {
                    /////////////////////////// media owner channels/////////////////////////////////////////
                  }
                </div>
                <div style={{ width: "100%", margin: "2%" }}>
                  <FormControl
                    component="fieldset"
                    className={[
                      classes.selectControl,
                      classes.customfield,
                    ].join(" ")}
                    style={{ marginTop: "10px" }}
                  >
                    <FormLabel component="legend">Media Channels</FormLabel>
                    <FormGroup row>
                      {dropdownData?.mediaChannels?.map((option) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={channels.includes(option._id)}
                              onChange={(e) => {
                                let newArr = [...channels];
                                e.target.checked
                                  ? newArr.push(option._id)
                                  : (newArr = newArr.filter(
                                      (e) => e != option._id
                                    ));

                                setChannels(newArr);
                              }}
                              name={option.name}
                            />
                          }
                          label={option.name}
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </div>
                {
                  ////////////////////////////Questions///////////////////////////////////////
                }
                <div style={{ width: "96%", margin: "2%" }}>
                  <FormControl
                    component="fieldset"
                    className={[
                      classes.selectControl,
                      classes.customfield,
                    ].join(" ")}
                    style={{ marginTop: "10px" }}
                  >
                    <FormLabel component="legend">Questions</FormLabel>
                    <FormGroup row>
                      {questions.map((e, i) => (
                        <div style={{ width: "100%", margin: "15px" }}>
                          <div style={{ width: "100%", margin: "5px" }}>
                            <FormLabel>
                              Q{i + 1}.{e.question}
                            </FormLabel>
                          </div>
                          <div style={{ width: "100%" }}>
                            <TextField
                              fullWidth
                              id="outlined-select-currency-native"
                              label="answer"
                              value={e.ans}
                              onChange={(e) => {
                                let newdata = [...questions];
                                newdata[i].ans = e.target.value;
                                setQuestions(newdata);
                              }}
                              SelectProps={{
                                native: true,
                              }}
                              variant="outlined"
                            ></TextField>
                          </div>
                        </div>
                      ))}
                    </FormGroup>
                  </FormControl>
                </div>
              </div>
            )}

          {!loading &&
            demographic != 0 &&
            industry != 0 &&
            ageGroup != 0 &&
            mediaOwner != 0 && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <FormControl>
                  <Button
                    className={classes.addActionBtn}
                    variant="outlined"
                    style={
                      isUpdate
                        ? { backgroundColor: "#E96B43" }
                        : { backgroundColor: "#136CC3" }
                    }
                    onClick={() => {
                      isUpdate ? UpdateData() : AddData();
                    }}
                  >
                    {isUpdate ? "UPDATE" : "SUBMIT"}
                  </Button>
                </FormControl>
              </div>
            )}
        </form>
      </div>
    </div>
  );
};

export default BrandMetrics;
