import React, { useState, useEffect } from "react";
import useStyles from "../../../components/StyleClasses";
import "../../mediaPlanner/IntelligenceDashboard/Dashboard.css";
// table
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  Box,
  Button,
} from "@material-ui/core/";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import LinearProgress from "@material-ui/core/LinearProgress";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { Bar } from "react-chartjs-2";
import axios from "axios";
import { API } from "../../../constants/index";
import { SECRET_KEY } from "../../../constants/index";
import CircularProgress from "@material-ui/core/CircularProgress";
import StaticData from "../../mediaPlanner/IntelligenceDashboard/graph.json";
import StaticDeviceData from "../../mediaPlanner/IntelligenceDashboard/device.json";
import StaticQuestionData from "../../mediaPlanner/IntelligenceDashboard/question.json";
import StaticMediaData from "../../mediaPlanner/IntelligenceDashboard/media.json";

import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  registerables,
} from "chart.js";
ChartJS.register(...registerables);
ChartJS.register(
  ArcElement,
  CategoryScale,
  LineController,
  LineElement,
  PointElement,
  LinearScale
);

const backgroundColor = [
  "rgba(255, 99, 132, 0.8)",
  "rgba(255, 159, 64, 0.8)",
  "rgba(255, 205, 86, 0.8)",
  "rgba(75, 192, 192, 0.8)",
  "rgba(54, 162, 235, 0.8)",
  "rgba(153, 102, 255, 0.8)",
  "rgba(201, 203, 207, 0.8)",
];
const borderColor = [
  "rgb(255, 99, 132)",
  "rgb(255, 159, 64)",
  "rgb(255, 205, 86)",
  "rgb(75, 192, 192)",
  "rgb(54, 162, 235)",
  "rgb(153, 102, 255)",
  "rgb(201, 203, 207)",
];

const selectGraphArr = [
  {
    name: "Brand Awareness",
    kpi_type: "brand_awareness",
    color: "rgba(255, 99, 132, 0.8)",
  },
  {
    name: "Brand Consideration",
    kpi_type: "brand_consideration",
    color: "rgba(255, 159, 64, 0.8)",
  },
  {
    name: "Message Association",
    kpi_type: "message_association",
    color: "rgba(255, 205, 86, 0.8)",
  },
  {
    name: "Ad Recall",
    kpi_type: "ad_recall",
    color: "rgba(75, 192, 192, 0.8)",
  },
  {
    name: "Purchase Intent",
    kpi_type: "purchase_intent",
    color: "rgba(54, 162, 235, 0.8)",
  },
];

const Dashboard = () => {
  // graph
  const classes = useStyles();
  const [campaignList, setCampaignList] = useState([]);
  const [questionName, setQuestionName] = useState([]);
  const [campaign, setCampaign] = useState(0);
  const [loading, setLoading] = useState(false);
  const [insights, setInsights] = useState({});
  const [allQuestions, setAllQuestions] = useState([]);
  const [ageData, setAgeData] = useState([]);
  const [gender, setGender] = useState([]);
  const [frequency, setFrequency] = useState([]);
  const [creative, setCreative] = useState([]);
  const [device, setDevice] = useState([]);
  const [ageGroups, setAgeGroups] = useState([]);
  const access_token = window.localStorage.getItem(SECRET_KEY);

  useEffect(() => {
    getCamapaignList();
    getDropdown();
  }, []);

  const getDropdown = () => {
    setLoading(true);
    setAgeGroups([]);
    let endPoint = `/admin/dropdowns`;

    axios
      .get(`${API}${endPoint}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res && res.data && res.data.ageGroups) {
          setAgeGroups(res.data.ageGroups);
        }
        setLoading(false);
      })
      .catch(function (error) {
        if (error) {
          setLoading(false);
        }
      });
  };

  const getCamapaignList = () => {
    setLoading(true);
    setCampaignList([]);
    let endPoint = `/admin/lucidApi`;

    axios
      .get(`${API}${endPoint}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res) {
          setCampaignList(res.data.items);
        }
        setLoading(false);
      })
      .catch(function (error) {
        if (error) {
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    if (campaign === 0) return;
    getLiftInsights();
    getLiftForAllQuestions();
    getLiftLiftByDemoAge();
    getLiftLiftByDemoGender();
    getLiftbyQuestionFrequency();
    getLiftBreakdownByCreative();
    getLiftByDevice();
  }, [campaign]);

  const getLiftInsights = () => {
    setLoading(true);
    setInsights({});
    let endPoint = `/admin/lucidApi/${campaign}/liftInsights?filter_question=${questionName}`;

    axios
      .get(`${API}${endPoint}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (
          res &&
          res.data &&
          res.data.overview &&
          ((res.data.overview.media && res.data.overview.media.length) ||
            (res.data.overview.audience && res.data.overview.audience.length))
        ) {
          setInsights(res.data.overview);
        } else {
          setInsights(StaticMediaData);
        }
        setLoading(false);
      })
      .catch(function (error) {
        if (error) {
          setLoading(false);
        }
      });
  };

  const getLiftForAllQuestions = () => {
    setLoading(true);
    setAllQuestions([]);
    let endPoint = `/admin/lucidApi/${campaign}/campaignData/liftForAllQuestions?filter_question=${questionName}`;

    axios
      .get(`${API}${endPoint}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res && res.data && res.data.items && res.data.items.length) {
          setAllQuestions(res.data.items);
        } else {
          setAllQuestions(StaticQuestionData);
        }
        setLoading(false);
      })
      .catch(function (error) {
        if (error) {
          setLoading(false);
        }
      });
  };

  const getLiftLiftByDemoAge = () => {
    setLoading(true);
    setAgeData([]);
    let ageDatasets = [];

    let endPoint = `/admin/lucidApi/${campaign}/demo_age/liftByDemo?filter_question=${questionName}`;

    axios
      .get(`${API}${endPoint}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (
          res.data &&
          res.data &&
          res.data.data &&
          res.data.data.length &&
          ageGroups &&
          ageGroups.length
        ) {
          ageDatasets = graphDataFunction(
            res.data.data,
            ageGroups
          );
        } else {
          ageDatasets = graphDataFunction(
            res.data.data,
            ageGroups
          );
        }
        setAgeData(ageDatasets);
        setLoading(false);
      })
      .catch(function (error) {
        if (error) {
          setLoading(false);
        }
      });
  };

  const getLiftLiftByDemoGender = () => {
    setLoading(true);
    setGender([]);
    let genderDatasets = [];

    let endPoint = `/admin/lucidApi/${campaign}/demo_gender/liftByDemo?filter_question=${questionName}`;
    axios
      .get(`${API}${endPoint}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (
          res.data &&
          res.data &&
          res.data.data &&
          res.data.data.length &&
          ageGroups &&
          ageGroups.length
        ) {
          genderDatasets = graphDataFunction(
            res.data.data,
            ageGroups
          );
        } else {
          genderDatasets = graphDataFunction(
            res.data.data,
            ageGroups
          );
        }
        setGender(genderDatasets);
        setLoading(false);
      })
      .catch(function (error) {
        if (error) {
          setLoading(false);
        }
      });
  };

  const getLiftbyQuestionFrequency = () => {
    setLoading(true);
    setFrequency([]);
    let frequencyDatasets = [];
    let endPoint = `/admin/lucidApi/${campaign}/campaignData/LiftbyQuestionFrequency?filter_question=${questionName}`;

    axios
      .get(`${API}${endPoint}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (
          res.data &&
          res.data &&
          res.data.data &&
          res.data.data.length &&
          ageGroups &&
          ageGroups.length
        ) {
          frequencyDatasets = graphDataFunction(
            res.data.data,
            ageGroups
          );
        } else {
          frequencyDatasets = graphDataFunction(
            res.data.data,
            ageGroups
          );
        }
        setFrequency(frequencyDatasets);
        setLoading(false);
      })
      .catch(function (error) {
        if (error) {
          setLoading(false);
        }
      });
  };

  const getLiftBreakdownByCreative = () => {
    setLoading(true);
    setCreative([]);
    const liftDatasets = [];
    const creativeFinalData = [];
    const liftLabels = [];
    let endPoint = `/admin/lucidApi/${campaign}/campaignData/liftBreakdownByCreative?filter_question=${questionName}`;

    axios
      .get(`${API}${endPoint}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res && res.data && res.data.items && res.data.items.length) {
          res.data.items.map((item, i) => {
            liftLabels.push(item.creative_name);
            liftDatasets.push({
              label: item.creative_name,
              data: [item.lift_percentage ? item.lift_percentage : 0],
              borderWidth: 1,
              backgroundColor: backgroundColor[i],
              borderColor: borderColor[i],
            });
          });
          creativeFinalData.push({
            labels: liftLabels,
            datasets: liftDatasets,
          });
          setCreative(creativeFinalData);
        } else {
          StaticDeviceData.map((item, i) => {
            liftLabels.push(item.creative_name);
            liftDatasets.push({
              label: item.creative_name,
              data: [item.lift_percentage ? item.lift_percentage : 0],
              borderWidth: 1,
              backgroundColor: backgroundColor[i],
              borderColor: borderColor[i],
            });
          });
          creativeFinalData.push({
            labels: liftLabels,
            datasets: liftDatasets,
          });
          setCreative(creativeFinalData);
        }
        setLoading(false);
      })
      .catch(function (error) {
        if (error) {
          setLoading(false);
        }
      });
  };
  const getLiftByDevice = () => {
    setLoading(true);
    setDevice([]);
    const liftDatasetsArr = [];
    const deviceFinalData = [];
    const liftLabelsArr = [];
    let endPoint = `/admin/lucidApi/${campaign}/liftByDevice?filter_question=${questionName}`;

    axios
      .get(`${API}${endPoint}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res && res.data && res.data.items && res.data.items.length) {
          res.data.items.map((item, i) => {
            liftLabelsArr.push(item.device_name);
            liftDatasetsArr.push({
              label: item.device_name,
              data: [item.lift_percentage ? item.lift_percentage : 0],
              borderWidth: 1,
              backgroundColor: backgroundColor[i],
              borderColor: borderColor[i],
            });
          });
          deviceFinalData.push({
            labels: liftLabelsArr,
            datasets: liftDatasetsArr,
          });
          setDevice(deviceFinalData);
        } else {
          StaticDeviceData.map((item, i) => {
            liftLabelsArr.push(item.device_name);
            liftDatasetsArr.push({
              label: item.device_name,
              data: [item.lift_percentage ? item.lift_percentage : 0],
              borderWidth: 1,
              backgroundColor: backgroundColor[i],
              borderColor: borderColor[i],
            });
          });
          deviceFinalData.push({
            labels: liftLabelsArr,
            datasets: liftDatasetsArr,
          });
          setDevice(deviceFinalData);
        }
        setLoading(false);
      })
      .catch(function (error) {
        if (error) {
          setLoading(false);
        }
      });
  };

  const graphDataFunction = (ageDataArr, ages) => {
    let arr =
      ageDataArr && ageDataArr.length ? [...ageDataArr] : [...StaticData];
    let brand_awareness = [];
    let brand_consideration = [];
    let message_association = [];
    let ad_recall = [];
    let purchase_intent = [];
    let bucket_name = [];
    ages.map((as) => {
      bucket_name.push(as.name);
    });
    const datasets = [];
    let labels = [];
    let datasetLabel = [];
    const finalData = [];
    arr &&
      arr.length &&
      arr.map((ag, i) => {
        switch (ag.question.question_name) {
          case "brand_awareness":
            ag.graphData.filter((a) => {
              ages.map((as) => {
                if (a.bucket_name === as.name) {
                  brand_awareness.push(a.total_count);
                }
              });
            });
            break;
          case "brand_consideration":
            ag.graphData.filter((a) => {
              ages.map((as) => {
                if (a.bucket_name === as.name) {
                  brand_consideration.push(a.total_count);
                }
              });
            });
            break;
          case "message_association":
            ag.graphData.filter((a) => {
              ages.map((as) => {
                if (a.bucket_name === as.name) {
                  message_association.push(a.total_count);
                }
              });
            });
            break;
          case "ad_recall":
            ag.graphData.filter((a) => {
              ages.map((as) => {
                if (a.bucket_name === as.name) {
                  ad_recall.push(a.total_count);
                }
              });
            });
            break;
          case "purchase_intent":
            ag.graphData.filter((a) => {
              ages.map((as) => {
                if (a.bucket_name === as.name) {
                  purchase_intent.push(a.total_count);
                }
              });
            });
            break;
          default:
            return ag;
        }
      });
    selectGraphArr.map((sg) => {
      datasetLabel.push(sg.name);
    });
    datasetLabel &&
      datasetLabel.length &&
      datasetLabel.map((dl, i) => {
        datasets.push({
          label: datasetLabel[i],
          data: [
            brand_awareness && brand_awareness.length ? brand_awareness[i] : 0,
            brand_consideration && brand_consideration.length
              ? brand_consideration[i]
              : 0,
            message_association && message_association.length
              ? message_association[i]
              : 0,
            ad_recall && ad_recall.length ? ad_recall[i] : 0,
            purchase_intent && purchase_intent.length ? purchase_intent[i] : 0,
          ],
          borderWidth: 1,
          backgroundColor: backgroundColor[i],
          borderColor: borderColor[i],
        });
      });
    bucket_name &&
      bucket_name.length &&
      bucket_name.map((bn) => {
        labels.push(bn);
      });
    labels = labels.filter(function (elem, pos) {
      return labels.indexOf(elem) == pos;
    });
    finalData.push({ labels: labels, datasets: datasets });
    return finalData;
  };

  const capitalizeFirstLetter = (string) => {
    let str = string.charAt(0).toUpperCase() + string.slice(1);
    return str.replace('_',' ');
  };

  return (
    <div className={classes.boxShadow}>
      <div className={classes.paper}>
        <div style={{ marginTop: "25px" }}>
          {loading && <CircularProgress />}
        </div>
        <Grid item xs={12} sm={12} md={12}>
          <Box className={classes.adminCardBox}>
            <Card
              style={{
                backgroundImage: "linear-gradient(#4911a2,#9211a2)",
                color: "white",
                height: "420px",
                overflow: "auto",
              }}
            >
              <CardContent>
                <FormControl
                  fullWidth
                  component="fieldset"
                  variant="outlined"
                  className={[classes.selectControl, classes.customfield].join(
                    " "
                  )}
                  style={{
                    marginTop: "5px",
                    width: "24%",
                    float: "right",
                  }}
                >
                  <Select
                    id="outlined-select-currency-native"
                    multiple
                    // label="KPI Type"
                    value={questionName}
                    onChange={(e) => {
                      {
                        setQuestionName(e.target.value);
                      }
                    }}
                    variant="outlined"
                    renderValue={(ep) => {
                      let arrNm = [];
                      questionName.map((e) => {
                        let nm = capitalizeFirstLetter(e);
                        arrNm.push(nm + " , ");
                      });
                      return arrNm;
                    }}
                  >
                    <MenuItem disabled value={0}>
                      <em>select kpi type</em>
                    </MenuItem>
                    {selectGraphArr &&
                      selectGraphArr.length &&
                      selectGraphArr?.map((option) => (
                        <MenuItem key={option.kpi_type} value={option.kpi_type}>
                          <Checkbox
                            checked={questionName.includes(option.kpi_type)}
                          ></Checkbox>
                          {option.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <FormControl
                  component="fieldset"
                  variant="outlined"
                  className={[classes.selectControl, classes.customfield].join(
                    " "
                  )}
                  style={{
                    marginTop: "5px",
                    marginRight: "10px",
                    width: "24%",
                    float: "right",
                  }}
                >
                  <TextField
                    id="outlined-select-currency-native"
                    select
                    value={campaign}
                    onChange={(e) => setCampaign(e.target.value)}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  >
                    <option key={0} value={0}>
                      Select campaign...
                    </option>
                    {campaignList &&
                      campaignList.length &&
                      campaignList?.map((option) => (
                        <option
                          key={option.campaign_id}
                          value={option.campaign_id}
                        >
                          {option.campaign_name}
                        </option>
                      ))}
                  </TextField>
                </FormControl>
                <div
                  style={{
                    marginTop: "20px",
                    //   display: "flex",
                    //   justifyContent: "space-between",
                    //   flexWrap: "wrap",
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                  >
                    {insights && insights.media && insights.media.length ? (
                      <Grid item xs={6} sm={6} md={6}>
                        <span
                          style={{
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          Media Insights
                        </span>
                        <br />
                        <Grid
                          container
                          direction="row"
                          justify="flex-start"
                          alignItems="flex-start"
                        >
                          {insights.media.map((inside) => (
                            <Grid item xs={6} sm={6} md={6}>
                              <Box>
                                <Card className={classes.graphCardBox}>
                                  <CardContent>
                                    <span>
                                      {inside.insight_name
                                        ? inside.insight_name
                                        : ""}
                                    </span>
                                    <br />
                                    <span>
                                      <b>
                                        {inside.lift_percentage
                                          ? inside.lift_percentage
                                          : ""}
                                        %
                                      </b>
                                    </span>
                                    <br />
                                    <span>
                                      <b>Publisher #9</b> has the highest lift
                                    </span>
                                    <br />
                                  </CardContent>
                                </Card>
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    ) : null}
                    {insights &&
                    insights.audience &&
                    insights.audience.length ? (
                      <Grid item xs={6} sm={6} md={6}>
                        <span
                          style={{
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          Audience Insights
                        </span>{" "}
                        <br />
                        <Grid
                          container
                          direction="row"
                          justify="flex-start"
                          alignItems="flex-start"
                        >
                          {insights.audience.map((ia) => (
                            <Grid item xs={6} sm={6} md={6}>
                              <Box>
                                <Card className={classes.graphCardBox}>
                                  <CardContent>
                                    <span>
                                      {ia.insight_name ? ia.insight_name : ""}
                                    </span>
                                    <br />
                                    <span>
                                      <b>
                                        {ia.lift_percentage
                                          ? ia.lift_percentage
                                          : ""}
                                        %
                                      </b>
                                    </span>
                                    <br />
                                    <span>
                                      <b>Publisher #9</b> has the highest lift
                                    </span>
                                    <br />
                                  </CardContent>
                                </Card>
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    ) : null}
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    style={{ marginTop: "10px", marginLeft: "10px" }}
                  >
                    <TableContainer className="graph-table">
                      <Table>
                        {allQuestions && allQuestions.length
                          ? allQuestions.map((aq) => (
                              <TableRow>
                                <TableCell>
                                  {aq.question
                                    ? capitalizeFirstLetter(
                                        aq.question.question_name
                                      )
                                    : ""}
                                </TableCell>
                                <TableCell align="right">
                                  <Button
                                    style={{
                                      float: "right",
                                      color: "black",
                                      backgroundColor: "greenyellow",
                                      fontWeight: 300,
                                      fontSize: "20px",
                                    }}
                                    disabled
                                  >
                                    {aq.lift_percentage
                                      ? aq.lift_percentage
                                      : 0}
                                  </Button>
                                </TableCell>
                                <TableCell align="right">
                                  <span>
                                    <b>Above Average</b>
                                    <br />
                                    KPI Benchmark
                                  </span>
                                </TableCell>
                                <TableCell align="right">
                                  <span>
                                    <b>
                                      {" "}
                                      {aq.statistically_significant
                                        ? "Statistically Significant"
                                        : "Not Statistically Significant"}
                                    </b>
                                    <br />
                                    {aq.margin_of_error
                                      ? aq.margin_of_error
                                      : 0}
                                    % Margin of error
                                  </span>
                                </TableCell>
                                <TableCell>
                                  EXPOSED n=
                                  {aq.exposed_count ? aq.exposed_count : 0}
                                  <LinearProgress
                                    disabled
                                    variant="determinate"
                                    value={
                                      aq.exposed_percentage
                                        ? aq.exposed_percentage
                                        : 0
                                    }
                                  />{" "}
                                  {aq.exposed_percentage
                                    ? aq.exposed_percentage
                                    : 0}
                                  % <br />
                                  CONTROL n=
                                  {aq.control_count ? aq.control_count : 0}{" "}
                                  <LinearProgress
                                    disabled
                                    variant="determinate"
                                    value={
                                      aq.control_percentage
                                        ? aq.control_percentage
                                        : 0
                                    }
                                  />{" "}
                                  {aq.control_percentage
                                    ? aq.control_percentage
                                    : 0}
                                  %
                                </TableCell>
                              </TableRow>
                            ))
                          : null}
                      </Table>
                    </TableContainer>
                  </Grid>

                  <div style={{ marginTop: "10px" }}>
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item xs={6} sm={6} md={6}>
                        <b style={{ marginBottom: "10px" }}>
                          Lift Performance by Age
                        </b>
                        <div
                          style={{
                            width: "70%",
                            marginTop: "5px",
                            height: "250px",
                          }}
                        >
                          <Bar
                            width="30px"
                            height="20px"
                            data={{
                              labels:
                                ageData && ageData.length
                                  ? ageData[0].labels
                                  : [],
                              datasets:
                                ageData && ageData.length
                                  ? ageData[0].datasets
                                  : [],
                            }}
                            options={{
                              plugins: {
                                legend: {
                                  display: false,
                                },
                              },
                              scales: {
                                y: {
                                  ticks: {
                                    color: "white",
                                    font: {
                                      size: 14,
                                    },
                                    // stepSize: 1,
                                    // beginAtZero: true
                                  },
                                },
                                x: {
                                  ticks: {
                                    color: "white",
                                    font: {
                                      size: 14,
                                    },
                                    // stepSize: 1,
                                    // beginAtZero: true
                                  },
                                },
                              },
                            }}
                          />
                        </div>
                      </Grid>

                      <Grid item xs={6} sm={6} md={6}>
                        <b style={{ marginBottom: "10px" }}>
                          Lift Performance by Gender
                        </b>
                        <div
                          style={{
                            width: "70%",
                            marginTop: "5px",
                            height: "250px",
                          }}
                        >
                          <Bar
                            width="30px"
                            height="20px"
                            data={{
                              labels:
                                gender && gender.length ? gender[0].labels : [],
                              datasets:
                                gender && gender.length
                                  ? gender[0].datasets
                                  : [],
                            }}
                            options={{
                              plugins: {
                                legend: {
                                  display: false,
                                },
                              },
                              scales: {
                                y: {
                                  ticks: {
                                    color: "white",
                                    font: {
                                      size: 14,
                                    },
                                    // stepSize: 1,
                                    // beginAtZero: true
                                  },
                                },
                                x: {
                                  ticks: {
                                    color: "white",
                                    font: {
                                      size: 14,
                                    },
                                    // stepSize: 1,
                                    // beginAtZero: true
                                  },
                                },
                              },
                            }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div style={{ marginTop: "10%" }}>
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item xs={6} sm={6} md={6}>
                        <b style={{ marginBottom: "10px" }}>
                          Lift Performance by Frequency
                        </b>
                        <div
                          style={{
                            width: "70%",
                            marginTop: "5px",
                            height: "250px",
                          }}
                        >
                          <Bar
                            width="30px"
                            height="20px"
                            data={{
                              labels:
                                frequency && frequency.length
                                  ? frequency[0].labels
                                  : [],
                              datasets:
                                frequency && frequency.length
                                  ? frequency[0].datasets
                                  : [],
                            }}
                            options={{
                              plugins: {
                                legend: {
                                  display: false,
                                },
                              },
                              scales: {
                                y: {
                                  ticks: {
                                    color: "white",
                                    font: {
                                      size: 14,
                                    },
                                    // stepSize: 1,
                                    // beginAtZero: true
                                  },
                                },
                                x: {
                                  ticks: {
                                    color: "white",
                                    font: {
                                      size: 14,
                                    },
                                    // stepSize: 1,
                                    // beginAtZero: true
                                  },
                                },
                              },
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                        <b style={{ marginBottom: "10px" }}>
                          Lift Performance by Device
                        </b>

                        <div
                          style={{
                            width: "70%",
                            marginTop: "5px",
                            height: "250px",
                          }}
                        >
                          <Bar
                            width="30px"
                            height="20px"
                            data={{
                              labels:
                                device && device.length ? device[0].labels : [],
                              datasets:
                                device && device.length
                                  ? device[0].datasets
                                  : [],
                            }}
                            options={{
                              plugins: {
                                legend: {
                                  display: false,
                                },
                              },
                              scales: {
                                y: {
                                  ticks: {
                                    color: "white",
                                    font: {
                                      size: 14,
                                    },
                                    // stepSize: 1,
                                    // beginAtZero: true
                                  },
                                },
                                x: {
                                  ticks: {
                                    color: "white",
                                    font: {
                                      size: 14,
                                    },
                                    // stepSize: 1,
                                    // beginAtZero: true
                                  },
                                },
                              },
                            }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div
                    style={{
                      marginTop: "10%",
                      marginBottom: "10px",
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item xs={8} sm={8} md={8}>
                        <b style={{ marginBottom: "10px" }}>
                          Lift Performance by Creative
                        </b>
                        <div
                          style={{
                            width: "70%",
                            marginTop: "5px",
                            height: "250px",
                          }}
                        >
                          <Bar
                            width="30px"
                            height="20px"
                            data={{
                              labels:
                                creative && creative.length
                                  ? creative[0].labels
                                  : [],
                              datasets:
                                creative && creative.length
                                  ? creative[0].datasets
                                  : [],
                            }}
                            options={{
                              indexAxis: "y",
                              plugins: {
                                legend: {
                                  display: false,
                                },
                              },
                              scales: {
                                y: {
                                  ticks: {
                                    color: "white",
                                    font: {
                                      size: 14,
                                    },
                                    // stepSize: 1,
                                    // beginAtZero: true
                                  },
                                },
                                x: {
                                  ticks: {
                                    color: "white",
                                    font: {
                                      size: 14,
                                    },
                                    // stepSize: 1,
                                    // beginAtZero: true
                                  },
                                },
                              },
                            }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </div>
    </div>
  );
};

export default Dashboard;
