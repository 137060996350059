import React, { useState, useEffect, useRef } from "react";

import Pagination from "@material-ui/lab/Pagination";
import MyTable from "./mytable";
import InfiniteScroll from "react-infinite-scroll-component";

import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Grid, Card, CardContent, Box } from "@material-ui/core/";

import FormControl from "@material-ui/core/FormControl";

//Text field
import TextField from "@material-ui/core/TextField";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Save from "@material-ui/icons/Save";
// Select
import InputLabel from "@material-ui/core/InputLabel";

//Button
import Button from "@material-ui/core/Button";
import { useSelector } from "react-redux";

import Checkbox from "@material-ui/core/Checkbox";

import { useDispatch } from "react-redux";
import * as actions from "../../redux/actions/user";
import ShowAlert from "../../components/alerts";
import StepWrapper from "./stepWrapper";
import MainLayout from "../../layouts/newMainLayout";
import { useHistory } from "react-router-dom";
import Modal from "../../components/modal";
import { getCsvDropdown } from "../../redux/actions/admin";
import { postMediaSentiment } from "../../redux/actions/media";
import ChatBOxImg from "../../assets/chatBox.png";
import HumanImg from "../../assets/human.png";

import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";

import { API } from "../../constants/index";
import axios from "axios";
import "./costumStyles.css";

import DataGrid from 'react-data-grid';
import 'react-data-grid/lib/styles.css';

import DeleteForeverIcon from "@material-ui/icons/DeleteForever"
import { makeStyles } from '@material-ui/core/styles';

import { SECRET_KEY } from "./../../constants/index";

// import culturalIntell from "../../assets/Cultural-Intelligence.svg";
import sentmentLogo from "../../assets/deca.png";
import { setMonth } from "date-fns";
import { Hidden } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { Axis } from "highcharts";
import useStyles from "../../components/StyleClasses";
import NewAlert from "../../components/NewAlert";
import { connectableObservableDescriptor } from "rxjs/internal/observable/ConnectableObservable";

let qy = 0

const useStyless = makeStyles((theme) => ({
  customTextfield: {
    width: "100%",
    height: "100%",
    "& .MuiOutlinedInput-input": {
      padding: "0 38px 0 10px",
    },
    "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-fullWidth.MuiInputBase-formControl": {
      height: "100%"
    },
  },
  columnHeader: {
    "& .rdg.r104f42s7-0-0-beta-22": {
      borderRadius: "5px",
      fontSize: "18px"
    },
    "& .rdg-cell.c1wupbe7-0-0-beta-22.rdg-cell-resizable.celq7o97-0-0-beta-22": {
      background: "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))",
      color: "#fff"
    }
  },
  columnLoader: {
    "& .rdg.r104f42s7-0-0-beta-22": {
      userSelect: "none",
      pointerEvents: "none",
      borderRadius: "5px",
      fontSize: "18px"
    },
    "& .rdg-cell.c1wupbe7-0-0-beta-22.rdg-cell-resizable.celq7o97-0-0-beta-22": {
      background: "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))",
      color: "#fff"
    }
  },
}));

const Sentiment = ({ onDone }) => {
  const [DATA, setDATA] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [page, setpage] = useState(1);
  const [pagecount, setPageCount] = useState(0);
  const [totalPage, setTotalPage] = useState(0)
  const [tableLoading, setTableloading] = useState(false);
  const [more, setMore] = useState(true)

  const [modalOpen, setModalOpen] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [demographic, setDemographic] = useState(0);
  const [InsiValue, setInsiValue] = useState(0);
  const [country, setCountry] = useState(0);
  const [industry, setIndustry] = useState(0);
  const [newIndustry, setNewIndustry] = useState(0);
  const [ageGroup, setAgeGroup] = useState(0);
  const [Brand, setBrand] = useState(0);
  const [brandList, setBrandList] = useState([]);
  const [insight, setInsight] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [Spinner, setSpinner] = useState(false);

  const [openPopUp, setOpenPopUp] = useState(false);
  const [duplicateToggle, setDuplicateToggle] = useState(false);
  const [duplicateRecord, setDuplicateRecord] = useState({});
  const [editToggle, setEditToggle] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [editData, setEditData] = useState({
    ageGroup: 0,
    demographic: 0,
    brand: 0,
    industry: 0,
    nameOfReport: "",
    question: "",
    question_id: "",
    answer: "",
    insight: 0,
    country: 0,
    id: "",
  });

  const [isEditing, setIsEditing] = useState(false);

  const [ques, setques] = useState({
    question: "",
    answer: "",
    name: "",
    age: "",
    id: Math.random(),
    saved: false,
    fromBE: false,
    updating: false,
  });
  const [answeredQues, setAnsweredQues] = useState([]);
  const [insightsData, setInsightsData] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const currentUser = useSelector((state) => state.loggedInSession);
  const dropdownData = useSelector((state) => state.adminDropDownData);
  const classes = useStyles();
  const classess = useStyless();
  const access_token = window.localStorage.getItem(SECRET_KEY);
  const [file, setFile] = useState(false);
  const fileInputRef = useRef();
  ///////errors & successs //////////
  const [ErrSucMsg, setErrSucMesg] = useState("");
  const [ErrSucType, setErrSucType] = useState(true);
  ///////////////////////////////////
  const [tempData, setTempData] = useState([])
  const [refreshToUpdate, setRefreshToUpdate] = useState(false)
  const [editAns, setEditAns] = useState(null)
  const [editQue, setEditQue] = useState(null)
  const [editReportName, setEditReportName] = useState(null)
  const [deleteRow, setDeleteRow] = useState(false)
  const [toDeleteRow, setToDeleteRow] = useState([])
  const [reportNameValue, setReportNameValue] = useState(null)
  const [editQueValue, setEditQueValue] = useState(null)
  const [answerValue, setAnswerValue] = useState(null)
  const [updateBrand, setUpdateBrand] = useState(false)
  const [infiniteScroll, setInfiniteScroll] = useState(false)

  const usertype = window.localStorage.getItem("usertype");

  console.log("answeredQues", answeredQues)

  //validations
  useEffect(() => {
    if (ques.age >= 140) {
      setques({ ...ques, age: 139 });
    }
    if (ques.age < 0) {
      setques({ ...ques, age: 0 });
    }
  }, [ques]);

  ////////////////lists/////////////////////////
  const refresh = () => {
    setTableloading(true);
    let URLL = `${API}/admin/intelligence/decainsights?`;
    URLL += `&page=${page}`;
    axios
      .get(URLL, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setDATA(res.data.data);
          setPageCount(res.data.pagination ? res.data.pagination.total : 0);
          setpage(res.data.pagination ? res.data.pagination.currentPage : 1);
          setTableloading(false);
        }
      })
      .catch(function (error) {
        if (error) {
          setTableloading(false);
        }
      });
  };
  // useEffect(() => {
  //   refreshList();
  // }, []);

  //////////////////////////////////////////////
  useEffect(() => {
    setBrand(0);
  }, [industry]);

  useEffect(() => {
    getInsights();
  }, []);

  const getInsights = () => {
    setTableloading(true);
    let URLL = `${API}/admin/intelligence/getinsights`;
    axios
      .get(URLL, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (err) {
          setTableloading(false);
        } else {
          setInsightsData(res.data);
          setTableloading(false);
        }
      })
      .catch(function (error) {
        if (error) {
          setTableloading(false);
        }
      });
  };

  const SendList = () => {
    const body = {
      demographic: demographic,
      industry: industry,
      ageGroup: ageGroup,
      insight: InsiValue,
      country: country,
      questions: [...answeredQues],
    };
    if (Brand != 0) {
      body.brand = Brand;
    }
    body.questions.forEach((element) => {
      delete element.id;
      delete element.saved;
      delete element.fromBE;
      delete element.updating;
    });
    axios
      .post(`${API}/admin/intelligence/addDecaInsight`, body, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setAnsweredQues([]);
          setIsAdding(false);
          setErrSucMesg(res.data.message);
          setErrSucType(true);
          refreshList("", false, 1);
          // refresh();
          setIsEditing(false);
          setIsAdding(false);
        }
      })
      .catch(function (error) {
        if (error) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
        }
      });
  };

  const refreshList = (scrollPage, scroll, i) => {
    setLoading(true);
    // let endPoint =
    //   demographic != 0 &&
    //   industry != 0 &&
    //   ageGroup != 0 &&
    //   InsiValue != 0 &&
    //   Brand != 0 &&
    //   country != 0
    //     ? `demographic=${demographic}&industry=${industry}&ageGroup=${ageGroup}&brand=${Brand}&insight=${InsiValue}&country=${country}`
    //     : `page=${page}`;
    let endPoint = "";
    if (demographic != 0) endPoint += `demographic=${demographic}&`;
    if (industry != 0) endPoint += `industry=${industry}&`;
    if (ageGroup != 0) endPoint += `ageGroup=${ageGroup}&`;
    if (InsiValue != 0) endPoint += `insight=${InsiValue}&`;
    if (Brand != 0) endPoint += `brand=${Brand}&`;
    if (country != 0) endPoint += `country=${country}&`;
    if (keyword !== "") endPoint += `keyword=${keyword}`;
    // if (
    //   !demographic &&
    //   !industry &&
    //   !ageGroup &&
    //   !InsiValue &&
    //   !Brand &&
    //   !country &&
    //   !keyword
    // )
      // endPoint += `page=${page}`;

    let URLL = `${API}/admin/intelligence/decainsights?page=${scrollPage !== "" ? scrollPage : page}&infinite=${infiniteScroll}&${endPoint}`;

    axios
      .get(URLL, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res && res.data) {
          setLoading(false);
          /// change condition later when res.data comes as empty array
          // setDATA(res.data.data || []);
          setPageCount(res.data.pagination ? res.data.pagination.total : 0);
          setTotalPage(res.data.pagination ? res.data.pagination.totalPages : 0)
          setpage(res.data.pagination ? res.data.pagination.currentPage : 1);
          setInsight(
            res.data && res.data.data && res.data.data[0].questions
              ? res.data.data[0].questions
              : []
          );

          if (scroll) {
            let dataList = []
            for (let data of res.data.data) {
              dataList.push(data)
            }
            setDATA([...DATA, ...dataList])
          } else {
            setDATA(res.data.data || []);
          }

          setErrSucMesg(res.data.message);
          setErrSucType(true);
        }
      })
      .catch(function (error) {
        if (error) {
          // setErrSucMesg(error.response.data.message);
          // setErrSucType(false);
        }
      });
  };

  const DeleteQuestion = (id) => {
    const payload = { id: id };
    axios
      .delete(`${API}/admin/intelligence/deleteMultipleDeca`, {
        headers: { Authorization: `Bearer ${access_token}` },
        data: payload,
      })
      .then((res, err) => {
        setOpenPopUp(false);
        if (res) {
          setErrSucMesg(res.data.message);
          setErrSucType(true);
          refreshList("", false, 2);
        }
      })
      .catch(function (error) {
        if (error) {
          setErrSucMesg(error?.response?.data?.message);
          setErrSucType(false);
        }
      });
  };

  const updateDemographic = (ids, demographic) => {
    const payload = [];

    ids.map(i =>{
      payload.push({_id: i, demographic})
    });

    axios
      .put(`${API}/admin/intelligence/updateMultipleDeca`, payload, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        setOpenPopUp(false);
        if (res) {
          setErrSucMesg(res.data.message);
          setErrSucType(true);
          refreshList("", false, 3);
        }
      })
      .catch(function (error) {
        if (error) {
          setErrSucMesg(error?.response?.data?.message);
          setErrSucType(false);
        }
      });
  };

  const DuplicateQuestion = (row) => {
    let duplicateData = {};
    let questionArr = [];
    let dataArr = DATA.filter((dt) => {
      if (dt._id === row._id) return dt;
    });
    if (dataArr.length) {
      duplicateData.ageGroup = dataArr[0].ageGroup
        ? dataArr[0].ageGroup._id
        : "";
      duplicateData.demographic = dataArr[0].demographic
        ? dataArr[0].demographic._id
        : "";
      duplicateData.brand = dataArr[0].brand ? dataArr[0].brand._id : "";
      duplicateData.industry = dataArr[0].industry
        ? dataArr[0].industry._id
        : "";
      duplicateData.nameOfReport = dataArr[0].nameOfReport
        ? dataArr[0].nameOfReport
        : "";
      duplicateData.insight = dataArr[0].insight ? dataArr[0].insight : "";
      duplicateData.country = dataArr[0].country ? dataArr[0].country._id : "";
      duplicateData.industry = dataArr[0].industry
        ? dataArr[0].industry._id
        : "";
      row.otherData &&
        row.otherData.length &&
        row.otherData.map((rw) => {
          questionArr.push({
            question: rw.question,
            answer: rw.answer,
            name: rw.name,
            age: rw.age,
          });
        });
      duplicateData.questions = questionArr;
    }
    axios
      .post(`${API}/admin/intelligence/addDecaInsight`, duplicateData, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setErrSucMesg(res.data.message);
          setErrSucType(true);
          refreshList("", false, 4);
          // refresh();
        }
      })
      .catch(function (error) {
        if (error) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
        }
      });
  };

  const UpdateQuestion = () => {
    const body = {};
    body.questions = [{ ...ques }];
    delete body.questions[0].id;
    delete body.questions[0].saved;
    delete body.questions[0].fromBE;
    delete body.questions[0].updating;
    axios
      .put(`${API}/admin/intelligence/DecaQuestions`, body, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (err) {
        } else {
          setques({
            question: "",
            answer: "",
            name: "",
            age: "",
            id: Math.random(),
            saved: false,
            fromBE: false,
            updating: false,
          });
          setErrSucMesg(res.data.message);
          setErrSucType(true);
          setIsEditing(false);
          refreshList("", false, 5);
        }
      })
      .catch(function (error) {
        if (error) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
        }
      });
  };
  const EditQuestion = () => {
    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={true}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <DialogTitle>Edit Question</DialogTitle>
        <div
          style={{ display: "flex", justifyContent: "center", width: "70vw" }}
        >
          {MyChatBox(ques)}
        </div>
      </Dialog>
    );
  };

  useEffect(() => {
    const delayTime = setTimeout(() => {
      refreshList("", false, 6);
      setAnsweredQues([]);
      setIsAdding(false);
    }, 1000);
    return () => clearTimeout(delayTime);
  }, [demographic, Brand, ageGroup, industry, InsiValue, country, keyword, refreshToUpdate]);

  const getBrands = () => {
    if (industry === 0 && newIndustry === 0) return;
    setBrandList([]);
    setSpinner(true);
    axios
      .get(
        `${API}/admin/brands?industry=${industry ? industry : newIndustry}`,
        {
          headers: { Authorization: `Bearer ${access_token}` },
        }
      )
      .then((res, err) => {
        if (res) {
          setBrandList(res.data);
          setSpinner(false);
        }
        if (err) {
          setSpinner(false);
        }
      })
      .catch(function (error) {
        if (error) {
          setSpinner(false);
        }
      });
  };

  useEffect(() => {
    console.log("DATA", DATA)
    if (DATA && DATA.length) {
      let td = [];
      DATA.forEach((e, index) => {
        let KeysToShow = [
          e.nameOfReport ? e.nameOfReport : "",
          e.demographic ? e.demographic.name : "",
          e.ageGroup ? e.ageGroup.name : "",
          e.industry ? e.industry.name : "",
          e.brand ? e.brand.name : "",
          e.insight ? e.insight : "",
          e.country ? e.country.name : e.market,
          e.questions && e.questions.length && e.questions[0]
            ? e.questions[0].question
            : "",
          e.questions && e.questions.length && e.questions[0]
            ? e.questions[0].answer
            : "",
        ];
        td.push({
          data: KeysToShow,
          _id: e._id,
          otherData: e.questions ? e.questions : [],
        });
      });
      setTableData(td);
    } else {
      setTableData([]);
    }

    if (DATA && DATA.length) {
      let td = []
      DATA.forEach(item => {
        let flagQue = 0
        let flagAns = 0
        let KeysToShow = {
          id: item._id,
          nameOfReport: item.nameOfReport ? item.nameOfReport : "",
          demographic: item.demographic ? item.demographic.name : "",
          ageGroup: item.ageGroup ? item.ageGroup.name : "",
          industry: item.industry ? item.industry.name : "",
          brand: item.brand ? item.brand.name : "",
          insight: item.insight ? item.insight : "",
          country: item.country ? item.country.name : "",
          questions: item.questions && item.questions.length && item.questions[0].question, //map(i => {
            // if (i.age === item.ageGroup.name) {
            //   if (flagQue <= 0) {
            //     flagQue++
                // return i.question
            //   }
            // }
          // }).join(""),
          answer: item.questions && item.questions.length && item.questions.map(i => {
            if (i.age === item.ageGroup.name) {
              if (flagAns <= 0) {
                flagAns++
                return i.answer
              }
            }
          }).join("")
        }
        td.push(KeysToShow)
      })
      setTempData(td)
    } else {
      setTempData([])
    }
  }, [DATA]);

  console.log("tempData", tempData)

  useEffect(() => {
    getBrands();
  }, [industry, newIndustry]);

  useEffect(() => {
    getCountryList();
  }, []);

  const getCountryList = () => {
    axios
      .get(`${API}/admin/dropdowns`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res) {
          setCountriesList(res.data.countries);
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };

  const columns = [
    { 
      key: 'nameOfReport', 
      name: 'Name of Report',
      resizable: true,
      editable: true,
      editor: (pros) =>
        <>
          <TextField
            className={classess.customTextfield}
            fullWidth
            id="outlined-select-currency-native"
            value={editReportName ? editReportName?.nameOfReport : pros.row.nameOfReport}
            onChange={(e) => {
              DATA.forEach(item => {
                if (pros.row.id === item._id) {
                  let flagQue = 0
                  let flagQueid = 0
                  let flagAns = 0
                  let d = {
                    ageGroup: item.ageGroup._id,
                    demographic: item.demographic._id,
                    brand: updateBrand ? "" : item.brand._id,
                    industry: item.industry._id,
                    nameOfReport: e.target.value,
                    question: item.questions[0].question,
                    // map(i => {
                    //   if (i.age === item.ageGroup.name) {
                    //     if (flagQue <= 0) {
                    //       flagQue++
                    //       return i.question
                    //     }
                    //   }
                    // }).join(""),
                    question_id: item.questions[0]._id,
                    // map(i => {
                    //   if (i.age === item.ageGroup.name) {
                    //     if (flagQueid <= 0) {
                    //       flagQueid++
                    //       return i._id
                    //     }
                    //   }
                    // }).join(""),
                    answer: item.questions.map(i => {
                      if (i.age === item.ageGroup.name) {
                        if (flagAns <= 0) {
                          flagAns++
                          return i.answer
                        }
                      }
                    }).join(""),
                    insight: pros.row.insight,
                    country: item.country._id,
                    id: pros.row.id,
                  }
                  setEditReportName(d)
                }
              })
            }}
            onBlur={() => {    
              if (editReportName !== null) {
                setReportNameValue(editReportName);
                setEditReportName(null);
              }
              qy = 0
            }}
            onKeyDown={e => {
              if(e.key === "Enter") {
                setReportNameValue(editReportName);
                setEditReportName(null);
              }
              qy = 0
            }}
            variant="outlined"
          />
          { updateBrand ? 
            <>
              {qy === 0 ? <>{setErrSucMesg("Brand selection is required!")}{setErrSucType(false)}{qy++}</> : ""}
            </> : 
            (editReportName || reportNameValue) && 
            <Save 
              style={{ cursor: "pointer", color: "green", position: "absolute", right: "7px", top: "7px"}}
              fontSize="small"
              onClick={() => {
                if (reportNameValue) {
                  const tempDataIndex = tempData.findIndex((data) => data.id == reportNameValue.id)
                  tempData[tempDataIndex].nameOfReport = reportNameValue.nameOfReport;
                  setTempData(tempData)
                  axios
                      .put(`${API}/admin/intelligence/updateDecaInsight/`, reportNameValue, {
                        headers: { Authorization: `Bearer ${access_token}` },
                      })
                      .then((res) => {
                        if (res) {
                          setEditToggle(false);
                          setRefreshToUpdate(!refreshToUpdate)
                        }
                      })
                      .catch(function (error) {
                        if (error) {
                          setErrSucMesg(error.response.data.message);
                          setErrSucType(false);
                          setEditToggle(false);
                          setRefreshToUpdate(!refreshToUpdate)
                        }
                      });
                      refreshList("", false, 7);
                }
              }}
            />
          }
        </>
    },
    { 
      key: 'demographic', 
      name: 'Demographic',
      resizable: true,
      editable: true,
      editor: (pros) => 
        <TextField
          className={classess.customTextfield}
          fullWidth
          id="outlined-select-currency-native"
          select
          onChange={(e) => {
            DATA.forEach(item => {
              if (pros.row.id === item._id) {
                let flagQue = 0
                let flagQueid = 0
                let flagAns = 0
                let d = {
                  ageGroup: item.ageGroup._id,
                  demographic: e.target.value,
                  brand: item.brand._id,
                  industry: item.industry._id,
                  nameOfReport: pros.row.nameOfReport,
                  question: item.questions[0].question,
                  // map(i => {
                  //   if (i.age === item.ageGroup.name) {
                  //     if (flagQue <= 0) {
                  //       flagQue++
                  //       return i.question
                  //     }
                  //   }
                  // }).join(""),
                  question_id: item.questions[0]._id,
                  // map(i => {
                  //   if (i.age === item.ageGroup.name) {
                  //     if (flagQueid <= 0) {
                  //       flagQueid++
                  //       return i._id
                  //     }
                  //   }
                  // }).join(""),
                  answer: item.questions.map(i => {
                    if (i.age === item.ageGroup.name) {
                      if (flagAns <= 0) {
                        flagAns++
                        return i.answer
                      }
                    }
                  }).join(""),
                  insight: pros.row.insight,
                  country: item.country._id,
                  id: pros.row.id,
                }

                axios
                  .put(`${API}/admin/intelligence/updateDecaInsight/`, d, {
                    headers: { Authorization: `Bearer ${access_token}` },
                  })
                  .then((res) => {
                    if (res) {
                      setEditToggle(false);
                      setRefreshToUpdate(!refreshToUpdate)
                    }
                  })
                  .catch(function (error) {
                    if (error) {
                      setErrSucMesg(error.response.data.message);
                      setErrSucType(false);
                      setEditToggle(false);
                      setRefreshToUpdate(!refreshToUpdate)
                    }
                  });
                  refreshList("", false, 8);
              }
            })
          }}
          variant="outlined"
        >
          {dropdownData?.demographics?.map((option) => (
            <option key={option._id} value={option._id}>
              {option.name}
            </option>
          ))}
        </TextField>
    },
    { 
      key: 'ageGroup', 
      name: 'Age Group',
      resizable: true,
      editable: true,
      sortable: true,
      editor: (pros) => 
        <TextField
          className={classess.customTextfield}
          fullWidth
          id="outlined-select-currency-native"
          select
          onChange={(e) => {
            DATA.forEach(item => {
              if (pros.row.id === item._id) {
                let flagQue = 0
                let flagQueid = 0
                let flagAns = 0
                let d = {
                  ageGroup: e.target.value,
                  demographic: item.demographic._id,
                  brand: item.brand._id,
                  industry: item.industry._id,
                  nameOfReport: pros.row.nameOfReport,
                  question: item.questions[0].question,
                  // map(i => {
                  //   if (i.age === item.ageGroup.name) {
                  //     if (flagQue <= 0) {
                  //       flagQue++
                  //       return i.question
                  //     }
                  //   }
                  // }).join(""),
                  question_id: item.questions[0]._id,
                  // map(i => {
                  //   if (i.age === item.ageGroup.name) {
                  //     if (flagQueid <= 0) {
                  //       flagQueid++
                  //       return i._id
                  //     }
                  //   }
                  // }).join(""),
                  answer: item.questions.map(i => {
                    if (i.age === item.ageGroup.name) {
                      if (flagAns <= 0) {
                        flagAns++
                        return i.answer
                      }
                    }
                  }).join(""),
                  insight: pros.row.insight,
                  country: item.country._id,
                  id: pros.row.id,
                }

                axios
                  .put(`${API}/admin/intelligence/updateDecaInsight/`, d, {
                    headers: { Authorization: `Bearer ${access_token}` },
                  })
                  .then((res) => {
                    if (res) {
                      setEditToggle(false);
                      setRefreshToUpdate(!refreshToUpdate)
                    }
                  })
                  .catch(function (error) {
                    if (error) {
                      setErrSucMesg(error.response.data.message);
                      setErrSucType(false);
                      setEditToggle(false);
                      setRefreshToUpdate(!refreshToUpdate)
                    }
                  });
                  refreshList("", false, 9);
              }
            })
          }}
          variant="outlined"
        >
          {dropdownData?.ageGroups?.map((option) => (
            <option key={option._id} value={option._id}>
              {option.name}
            </option>
          ))}
        </TextField>
    },
    { 
      key: 'industry', 
      name: 'Industry',
      resizable: true,
      editable: true,
      editor: (pros) => 
        <TextField
          className={classess.customTextfield}
          fullWidth
          id="outlined-select-currency-native"
          select
          onChange={(e) => {
            DATA.forEach(item => {
              if (pros.row.id === item._id) {
                let flagQue = 0
                let flagQueid = 0
                let flagAns = 0
                let d = {
                  ageGroup: item.ageGroup._id,
                  demographic: item.demographic._id,
                  brand: "",
                  industry: e.target.value,
                  nameOfReport: pros.row.nameOfReport,
                  question: item.questions[0].question,
                  // map(i => {
                  //   if (i.age === item.ageGroup.name) {
                  //     if (flagQue <= 0) {
                  //       flagQue++
                  //       return i.question
                  //     }
                  //   }
                  // }).join(""),
                  question_id: item.questions[0]._id,
                  // map(i => {
                  //   if (i.age === item.ageGroup.name) {
                  //     if (flagQueid <= 0) {
                  //       flagQueid++
                  //       return i._id
                  //     }
                  //   }
                  // }).join(""),
                  answer: item.questions.map(i => {
                    if (i.age === item.ageGroup.name) {
                      if (flagAns <= 0) {
                        flagAns++
                        return i.answer
                      }
                    }
                  }).join(""),
                  insight: pros.row.insight,
                  country: item.country._id,
                  id: pros.row.id,
                }

                axios
                  .put(`${API}/admin/intelligence/updateDecaInsight/`, d, {
                    headers: { Authorization: `Bearer ${access_token}` },
                  })
                  .then((res) => {
                    if (res) {
                      setErrSucMesg("Brand selection is required!");
                      setErrSucType(false);
                      setEditToggle(false);
                      setRefreshToUpdate(!refreshToUpdate)
                      setUpdateBrand(true)
                    }
                    setNewIndustry(e.target.value)
                  })
                  .catch(function (error) {
                    if (error) {
                      setErrSucMesg(error.response.data.message);
                      setErrSucType(false);
                      setEditToggle(false);
                      setRefreshToUpdate(!refreshToUpdate)
                    }
                  });
                  refreshList("", false, 10);
              }
            })
          }}
          variant="outlined"
        >
          {dropdownData?.industries?.map((option) => (
            <option key={option._id} value={option._id}>
              {option.name}
            </option>
          ))}
        </TextField>
    },
    { 
      key: 'brand', 
      name: 'Brand',
      resizable: true,
      editable: true,
      editor: (pros) => 
        <TextField
          className={classess.customTextfield}
          fullWidth
          id="outlined-select-currency-native"
          select
          onChange={(e) => {
            DATA.forEach(item => {
              if (pros.row.id === item._id) {
                let flagQue = 0
                let flagQueid = 0
                let flagAns = 0
                let d = {
                  ageGroup: item.ageGroup._id,
                  demographic: item.demographic._id,
                  brand: e.target.value,
                  industry: item.industry._id,
                  nameOfReport: pros.row.nameOfReport,
                  question: item.questions[0].question,
                  // map(i => {
                  //   if (i.age === item.ageGroup.name) {
                  //     if (flagQue <= 0) {
                  //       flagQue++
                  //       return i.question
                  //     }
                  //   }
                  // }).join(""),
                  question_id: item.questions[0]._id,
                  // map(i => {
                  //   if (i.age === item.ageGroup.name) {
                  //     if (flagQueid <= 0) {
                  //       flagQueid++
                  //       return i._id
                  //     }
                  //   }
                  // }).join(""),
                  answer: item.questions.map(i => {
                    if (i.age === item.ageGroup.name) {
                      if (flagAns <= 0) {
                        flagAns++
                        return i.answer
                      }
                    }
                  }).join(""),
                  insight: pros.row.insight,
                  country: item.country._id,
                  id: pros.row.id,
                }

                axios
                  .put(`${API}/admin/intelligence/updateDecaInsight/`, d, {
                    headers: { Authorization: `Bearer ${access_token}` },
                  })
                  .then((res) => {
                    if (res) {
                      setEditToggle(false);
                      setRefreshToUpdate(!refreshToUpdate)
                      setUpdateBrand(false)
                    }
                  })
                  .catch(function (error) {
                    if (error) {
                      setErrSucMesg(error.response.data.message);
                      setErrSucType(false);
                      setEditToggle(false);
                      setRefreshToUpdate(!refreshToUpdate)
                    }
                  });
                  refreshList("", false, 11);
              }
            })
          }}
          variant="outlined"
        >
          {brandList?.map((option) => (
            <option key={option._id} value={option._id}>
              {option.name}
            </option>
          ))}
        </TextField>
    },
    { 
      key: 'insight', 
      name: 'Insight',
      resizable: true,
      editable: true,
      editor: (pros) => 
        <TextField
          className={classess.customTextfield}
          fullWidth
          id="outlined-select-currency-native"
          select
          onChange={(e) => {
            DATA.forEach(item => {
              if (pros.row.id === item._id) {
                let flagQue = 0
                let flagQueid = 0
                let flagAns = 0
                let d = {
                  ageGroup: item.ageGroup._id,
                  demographic: item.demographic._id,
                  brand: item.brand._id,
                  industry: item.industry._id,
                  nameOfReport: pros.row.nameOfReport,
                  question: item.questions[0].question,
                  // map(i => {
                  //   if (i.age === item.ageGroup.name) {
                  //     if (flagQue <= 0) {
                  //       flagQue++
                  //       return i.question
                  //     }
                  //   }
                  // }).join(""),
                  question_id: item.questions[0]._id,
                  // map(i => {
                  //   if (i.age === item.ageGroup.name) {
                  //     if (flagQueid <= 0) {
                  //       flagQueid++
                  //       return i._id
                  //     }
                  //   }
                  // }).join(""),
                  answer: item.questions.map(i => {
                    if (i.age === item.ageGroup.name) {
                      if (flagAns <= 0) {
                        flagAns++
                        return i.answer
                      }
                    }
                  }).join(""),
                  insight: e.target.value,
                  country: item.country._id,
                  id: pros.row.id,
                }

                axios
                  .put(`${API}/admin/intelligence/updateDecaInsight/`, d, {
                    headers: { Authorization: `Bearer ${access_token}` },
                  })
                  .then((res) => {
                    if (res) {
                      setEditToggle(false);
                      setRefreshToUpdate(!refreshToUpdate)
                    }
                  })
                  .catch(function (error) {
                    if (error) {
                      setErrSucMesg(error.response.data.message);
                      setErrSucType(false);
                      setEditToggle(false);
                      setRefreshToUpdate(!refreshToUpdate)
                    }
                  });
                  refreshList("", false, 12);
              }
            })
          }}
          variant="outlined"
        >
          {[
            "Media",
            "Cultural",
            "Intersectional",
            "Actionable",
            "Perception",
          ].map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </TextField>
    },
    { 
      key: 'country', 
      name: 'Country',
      resizable: true,
      editable: true,
      sortable: true,
      editor: (pros) =>
        <TextField
          className={classess.customTextfield}
          fullWidth
          id="outlined-select-currency-native"
          select
          onChange={(e) => {
            let d = null
            DATA.forEach(item => {
              if (pros.row.id === item._id) {
                let flagQue = 0
                let flagQueid = 0
                let flagAns = 0
                d = {
                  ageGroup: item.ageGroup._id,
                  demographic: item.demographic._id,
                  brand: item.brand._id,
                  industry: item.industry._id,
                  nameOfReport: pros.row.nameOfReport,
                  question: item.questions[0].question,
                  // map(i => {
                  //   if (i.age === item.ageGroup.name) {
                  //     if (flagQue <= 0) {
                  //       flagQue++
                  //       return i.question
                  //     }
                  //   }
                  // }).join(""),
                  question_id: item.questions[0]._id,
                  // map(i => {
                  //   if (i.age === item.ageGroup.name) {
                  //     if (flagQueid <= 0) {
                  //       flagQueid++
                  //       return i._id
                  //     }
                  //   }
                  // }).join(""),
                  answer: item.questions.map(i => {
                    if (i.age === item.ageGroup.name) {
                      if (flagAns <= 0) {
                        flagAns++
                        return i.answer
                      }
                    }
                  }).join(""),
                  insight: pros.row.insight,
                  country: e.target.value,
                  id: pros.row.id,
                }

                axios
                .put(`${API}/admin/intelligence/updateDecaInsight/`, d, {
                  headers: { Authorization: `Bearer ${access_token}` },
                })
                .then((res) => {
                  if (res) {
                    setEditToggle(false);
                    setRefreshToUpdate(!refreshToUpdate)
                  }
                })
                .catch(function (error) {
                  if (error) {
                    setErrSucMesg(error.response.data.message);
                    setErrSucType(false);
                    setEditToggle(false);
                    setRefreshToUpdate(!refreshToUpdate)
                  }
                });
                refreshList("", false, 13);
              }
            })
          }}
          variant="outlined"
        >
          {countriesList &&
            countriesList.length &&
            countriesList?.map((option) => (
            <option key={option._id} value={option._id}>
              {option.name}
            </option>
          ))}
        </TextField>
    },
    { 
      key: 'questions', 
      name: 'Question',
      resizable: true,
      editable: true,
      editor: (pros) => 
        <>
          <TextField
            className={classess.customTextfield}
            fullWidth
            id="outlined-select-currency-native"
            value={editQue ? editQue.question : pros.row.questions}
            onChange={(e) => {
              DATA.forEach(item => {
                if (pros.row.id === item._id) {
                  let flagQue = 0
                  let flagQueid = 0
                  let flagAns = 0
                  let d = {
                    ageGroup: item.ageGroup._id,
                    demographic: item.demographic._id,
                    brand: updateBrand ? "" : item.brand._id,
                    industry: item.industry._id,
                    nameOfReport: pros.row.nameOfReport,
                    question: e.target.value,
                    question_id: item.questions[0].question,
                    // map(i => {
                    //   if (i.age === item.ageGroup.name) {
                    //     if (flagQueid <= 0) {
                    //       flagQueid++
                    //       return i._id
                    //     }
                    //   }
                    // }).join(""),
                    answer: item.questions.map(i => {
                      if (i.age === item.ageGroup.name) {
                        if (flagAns <= 0) {
                          flagAns++
                          return i.answer
                        }
                      }
                    }).join(""),
                    insight: pros.row.insight,
                    country: item.country._id,
                    id: pros.row.id,
                  }
                  setEditQue(d)
                }
              })
            }}
            onBlur={() => {
              if (editQue !== null) {
                setEditQueValue(editQue);
                setEditQue(null);
              }
            }}
            variant="outlined"
          />
          {
            ((editQue && editQue.question !== "") || (editQueValue && editQueValue.question !== "")) &&
            <Save 
              style={{ cursor: "pointer", color: "green", position: "absolute", right: "7px", top: "7px"}}
              fontSize="small"
              onClick={() => {
                if (editQueValue) {
                  const tempDataIndex = tempData.findIndex((data) => data.id == editQueValue.id)
                  tempData[tempDataIndex].questions = editQueValue.question;
                  setTempData(tempData)
                  axios
                      .put(`${API}/admin/intelligence/updateDecaInsight/`, editQueValue, {
                        headers: { Authorization: `Bearer ${access_token}` },
                      })
                      .then((res) => {
                        if (res) {
                          setEditToggle(false);
                          setEditQueValue(null);
                          setRefreshToUpdate(!refreshToUpdate)
                        }
                      })
                      .catch(function (error) {
                        if (error) {
                          setErrSucMesg(error.response.data.message);
                          setErrSucType(false);
                          setEditToggle(false);
                          setRefreshToUpdate(!refreshToUpdate)
                        }
                      });
                      refreshList("", false, 14);
                }
              }}
            />
          }
      </>
    },
    { 
      key: 'answer',
      name: 'Answers',
      resizable: true,
      editable: true,
      editor: (pros) => 
      <>
        <TextField
          className={classess.customTextfield}
          fullWidth
          id="outlined-select-currency-native"
          value={editAns ? editAns.answer : pros.row.answer}
          onChange={(e) => {
            DATA.forEach(item => {
              if (pros.row.id === item._id) {
                let flagQue = 0
                let flagQueid = 0
                let d = {
                  ageGroup: item.ageGroup._id,
                  demographic: item.demographic._id,
                  brand: item.brand._id,
                  industry: item.industry._id,
                  nameOfReport: pros.row.nameOfReport,
                  question: item.questions[0].question,
                  // map(i => {
                  //   if (i.age === item.ageGroup.name) {
                  //     if (flagQue <= 0) {
                  //       flagQue++
                  //       return i.question
                  //     }
                  //   }
                  // }).join(""),
                  question_id: item.questions[0]._id,
                  // map(i => {
                  //   if (i.age === item.ageGroup.name) {
                  //     if (flagQueid <= 0) {
                  //       flagQueid++
                  //       return i._id
                  //     }
                  //   }
                  // }).join(""),
                  answer: e.target.value,
                  insight: pros.row.insight,
                  country: item.country._id,
                  id: pros.row.id,
                }
                setEditAns(d)
              }
            })
          }}
          onBlur={() => {
            if(editAns !== null){
              setAnswerValue(editAns)
              setEditAns(null)
            }
          }}
          variant="outlined"
        />
        {
          ((editAns && editAns.answer !== "") || (answerValue && answerValue.answer !== "")) && 
            <Save 
              style={{ cursor: "pointer", color: "green", position: "absolute", right: "7px", top: "7px"}}
              fontSize="small"
              onClick={() => {
                if (answerValue) {
                  const tempDataIndex = tempData.findIndex((data) => data.id == answerValue.id)
                  tempData[tempDataIndex].answer = answerValue.answer;
                  setTempData(tempData)
                  axios
                      .put(`${API}/admin/intelligence/updateDecaInsight/`, answerValue, {
                        headers: { Authorization: `Bearer ${access_token}` },
                      })
                      .then((res) => {
                        if (res) {
                          setEditToggle(false);
                          setAnswerValue(null);
                          setRefreshToUpdate(!refreshToUpdate)
                        }
                      })
                      .catch(function (error) {
                        if (error) {
                          setErrSucMesg(error.response.data.message);
                          setErrSucType(false);
                          setEditToggle(false);
                          setRefreshToUpdate(!refreshToUpdate)
                        }
                      });
                      refreshList("", false, 15);
                }
              }}
            />
        }
      </>
    },
    {
      key: 'actions',
      name: 'Actions',
      formatter: (props) => (
        <div>
          <DeleteForeverIcon
            style={{ cursor: "pointer", color: "red"}}
            fontSize="small"
            onClick={() => {
              setDeleteRow(true);
              setToDeleteRow([props.row.id])
            }}
          />
        </div>
      )
    }
  ];

  const removeMyChatBox = (id) => {
    const filteredQues = answeredQues.filter((e) => e.id !== id);
    setAnsweredQues(filteredQues);
  };

  const MyChatBox = (element, index) => {
    return (
      <div
        style={{
          position: "relative",
          width: "30%",
          marginBottom: "80px",
          marginRight: "10px",
          marginLeft: "10px",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          padding: "10px",
        }}
      >
        {!element.fromBE && !element.updating && (
          <button
            style={{
              position: "absolute",
              top: "5px",
              right: "5px",
            }}
            onClick={() =>
              element.saved ? removeMyChatBox(element.id) : setIsAdding(false)
            }
          >
            x
          </button>
        )}

        <InputLabel
          style={{
            textAlign: "center",
            marginBottom: "5px",
          }}
        >
          <textarea
            disabled={answeredQues.length > 0 ?? false}
            value={element.question}
            placeholder={"Type Question..."}
            rows={3}
            onChange={(event) =>
              setques({ ...ques, question: event.target.value })
            }
            style={{
              border: "1px solid #858585",
              resize: "none",
              width: "80%",
            }}
          ></textarea>
        </InputLabel>
        <div
          style={{
            backgroundImage: `url(${ChatBOxImg})`,
            width: "100%",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            right: "0",
            justifyContent: "center",
            display: "flex",
            paddingBottom: "40px",
          }}
        >
          <textarea
            disabled={element.saved}
            value={element.answer}
            rows={8}
            placeholder={"answer..."}
            style={{
              paddingTop: "10px",
              textAlign: "center",
              background: "transparent",
              resize: "none",
              border: "0 none",
              fontSize: "10px",
              outline: "none",
            }}
            onChange={(event) =>
              setques({ ...ques, answer: event.target.value })
            }
          ></textarea>
        </div>
        <div style={{ display: "flex" }}>
          <img
            style={{
              width: "40%",
              height: "35%",
              marginTop: "0",
              display: "block",
            }}
            src={HumanImg}
            alt="img"
          ></img>
          <div
            style={{
              marginTop: "40px",
            }}
          >
            {/* <input
              value={element.name}
              disabled={element.saved}
              style={{ width: "80%", marginBottom: "5px" }}
              placeholder="Name..."
              onChange={(event) =>
                setques({ ...ques, name: event.target.value })
              }
            /> */}
            <select
              value={element.name ? element.name : 0}
              disabled={element.saved}
              style={{ width: "80%", marginBottom: "5px" }}
              placeholder="Name..."
              onChange={(event) =>
                setques({ ...ques, name: event.target.value })
              }
            >
              <option key={0} value={0} disabled={true}>
                Gender
              </option>
              {dropdownData?.genders?.map((option) => (
                <option key={option._id} value={option._id}>
                  {option.name}
                </option>
              ))}
            </select>
            {/* <input
              type="number"
              max={140}
              min={5}
              value={element.age}
              disabled={element.saved}
              style={{ width: "80%" }}
              placeholder="Age..."
              onChange={(event) =>
                setques({ ...ques, age: event.target.value })
              }
            /> */}
            <select
              value={element.age ? element.age : 0}
              disabled={element.saved}
              style={{ width: "80%" }}
              placeholder="Age"
              onChange={(event) =>
                setques({ ...ques, age: event.target.value })
              }
            >
              <option key={0} value={0} disabled={true}>
                Age
              </option>
              {dropdownData?.ageGroups?.map((option) => (
                <option key={option.name} value={option.name}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {!element.fromBE && !element.updating && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            {element.saved ? (
              <Button
                className={classes.addActionBtn}
                style={{ width: "80px", height: "40px" }}
                variant="outlined"
              >
                Saved
              </Button>
            ) : (
              <Button
                className={classes.addActionBtn}
                // style={{ width: "80px", height: "40px" }}
                variant="outlined"
                onClick={() => {
                  setAnsweredQues([{ ...ques, saved: true }, ...answeredQues]);
                  setIsAdding(false);

                  setques({
                    question: ques.question,
                    answer: "",
                    name: "",
                    age: "",
                    id: Math.random(),
                    saved: false,
                    fromBE: false,
                    updating: false,
                  });
                }}
                disabled={
                  ques.question.length === 0 ||
                  ques.answer.length === 0 ||
                  ques.name.length === 0 ||
                  ques.age.length === 0
                }
                style={
                  ques.question.length === 0 ||
                  ques.answer.length === 0 ||
                  ques.name.length === 0 ||
                  ques.age.length === 0
                    ? {
                        color: "white",
                        opacity: "0.6",
                      }
                    : { color: "white", opaccity: "1" }
                }
              >
                Save
              </Button>
            )}
          </div>
        )}
        {
          /////////update phase////////////////////////////////////////
          element.updating && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                marginTop: "10px",
              }}
            >
              <Button
                className={classes.addActionBtn}
                style={{
                  width: "80px",
                  height: "40px",
                  backgroundColor: "white",
                  color: "black",
                }}
                variant="outlined"
                onClick={() => {
                  setques({
                    question: "",
                    answer: "",
                    name: "",
                    age: "",
                    id: Math.random(),
                    saved: false,
                    fromBE: false,
                    updating: false,
                  });
                  setIsEditing(false);
                }}
              >
                Cancel
              </Button>
              <Button
                className={classes.addActionBtn}
                style={{
                  width: "80px",
                  height: "40px",
                }}
                variant="outlined"
                onClick={() => UpdateQuestion()}
              >
                Update
              </Button>
            </div>
          )
        }
        {element.fromBE && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: "10px",
            }}
          >
            <Button
              className={classes.addActionBtn}
              style={{
                width: "80px",
                height: "40px",
                backgroundColor: "#E16D17",
              }}
              variant="outlined"
              onClick={() => {
                let toEdit = { ...element };
                toEdit.id = Math.random();
                toEdit.saved = false;
                toEdit.fromBE = false;
                toEdit.updating = true;
                setques(toEdit);
                setIsEditing(true);
              }}
            >
              Edit
            </Button>
            {isEditing && EditQuestion(element)}
            <Button
              className={classes.addActionBtn}
              style={{
                width: "80px",
                height: "40px",
                backgroundColor: "#F44C3C",
              }}
              variant="outlined"
              onClick={() => setOpenPopUp(true)}
            >
              Delete
            </Button>
            {openPopUp && (
              <Dialog open={true}>
                <DialogTitle>Delete Question</DialogTitle>
                <p style={{ paddingRight: "10px", paddingLeft: "10px" }}>
                  Are you sure you want to delete this question permanently?
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    marginBottom: "10px",
                  }}
                >
                  <Button
                    className={classes.addActionBtn}
                    style={{
                      width: "80px",
                      height: "40px",
                      backgroundColor: "white",
                      color: "black",
                    }}
                    variant="outlined"
                    onClick={() => {
                      setOpenPopUp(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={classes.addActionBtn}
                    style={{
                      width: "80px",
                      height: "40px",
                      backgroundColor: "#F44C3C",
                    }}
                    variant="outlined"
                    onClick={() => DeleteQuestion(element.id)}
                  >
                    Delete
                  </Button>
                </div>
              </Dialog>
            )}
          </div>
        )}
      </div>
    );
  };

  const UpdateQuestionData = () => {
    axios
      .put(`${API}/admin/intelligence/updateDecaInsight/`, editData, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res) {
          setErrSucMesg(res.data.message);
          setErrSucType(true);
          setEditToggle(false);
          refreshList("", false, 16);
        }
      })
      .catch(function (error) {
        if (error) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
          setEditToggle(false);
        }
      });
      refreshList("", false);
  };

  const EditQuestionData = () => {
    return (
      <Box className={classes.decaCardBox}>
        <Card
          style={{
            backgroundImage: "linear-gradient(#4911a2,#9211a2)",
            color: "white",
            // height: "400px",
            overflow: "auto",
          }}
        >
          <CardContent>
            <label>Name of Reports</label>
            <FormControl
              fullWidth
              component="fieldset"
              variant="outlined"
              className={[classes.selectControl, classes.customfield].join(" ")}
              style={{ marginTop: "10px" }}
            >
              <TextField
                id="outlined-select-currency-native"
                fullWidth
                // label="Name"
                variant="outlined"
                // style={{ display: "block", marginBottom: "10px" }}
                value={editData.nameOfReport}
                onChange={(e) => {
                  let obj = { ...editData };
                  obj.nameOfReport = e.target.value;
                  setEditData(obj);
                }}
              />
            </FormControl>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <label>Demographic</label>
                <FormControl
                  fullWidth
                  component="fieldset"
                  variant="outlined"
                  className={[classes.selectControl, classes.customfield].join(
                    " "
                  )}
                  style={{ marginTop: "10px" }}
                >
                  <TextField
                    id="outlined-select-currency-native"
                    select
                    // label="Demographic"
                    value={editData.demographic}
                    onChange={(e) => {
                      let obj = { ...editData };
                      obj.demographic = e.target.value;
                      setEditData(obj);
                    }}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  >
                    <option key={0} value={0}>
                      Select...
                    </option>
                    {dropdownData?.demographics?.map((option) => (
                      <option key={option._id} value={option._id}>
                        {option.name}
                      </option>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <label>Age Group</label>

                <FormControl
                  fullWidth
                  component="fieldset"
                  variant="outlined"
                  className={[classes.selectControl, classes.customfield].join(
                    " "
                  )}
                  style={{ marginTop: "10px" }}
                >
                  <TextField
                    id="outlined-select-currency-native"
                    select
                    // label="Age Group"
                    value={editData.ageGroup}
                    onChange={(e) => {
                      let obj = { ...editData };
                      obj.ageGroup = e.target.value;
                      setEditData(obj);
                    }}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  >
                    <option key={0} value={0}>
                      Select...
                    </option>
                    {dropdownData?.ageGroups?.map((option) => (
                      <option key={option._id} value={option._id}>
                        {option.name}
                      </option>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <label>Industry</label>
                <FormControl
                  fullWidth
                  component="fieldset"
                  variant="outlined"
                  className={[classes.selectControl, classes.customfield].join(
                    " "
                  )}
                  style={{ marginTop: "10px" }}
                >
                  <TextField
                    id="outlined-select-currency-native"
                    select
                    // label="Industry"
                    value={editData.industry}
                    onChange={(e) => {
                      let obj = { ...editData };
                      obj.industry = e.target.value;
                      setNewIndustry(e.target.value);
                      setEditData(obj);
                    }}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  >
                    <option key={0} value={0}>
                      Select...
                    </option>
                    {dropdownData?.industries?.map((option) => (
                      <option key={option._id} value={option._id}>
                        {option.name}
                      </option>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <label>Brand</label>
                <FormControl
                  fullWidth
                  component="fieldset"
                  variant="outlined"
                  className={[classes.selectControl, classes.customfield].join(
                    " "
                  )}
                  style={{ marginTop: "10px" }}
                >
                  <TextField
                    id="outlined-select-currency-native"
                    select
                    // label="Brand"
                    value={editData.brand}
                    onChange={(e) => {
                      let obj = { ...editData };
                      obj.brand = e.target.value;
                      setEditData(obj);
                    }}
                    SelectProps={{
                      native: true,
                    }}
                    InputProps={
                      Spinner
                        ? {
                            startAdornment: (
                              <InputAdornment position="start">
                                <CircularProgress color="disabled" size={10} />
                              </InputAdornment>
                            ),
                          }
                        : {}
                    }
                    variant="outlined"
                  >
                    <option key={0} value={0}>
                      Select...
                    </option>
                    {brandList.map((option) => (
                      <option key={option._id} value={option._id}>
                        {option.name}
                      </option>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <label>Insight</label>
                <FormControl
                  fullWidth
                  component="fieldset"
                  variant="outlined"
                  className={[classes.selectControl, classes.customfield].join(
                    " "
                  )}
                  style={{ marginTop: "10px" }}
                >
                  <TextField
                    id="outlined-select-currency-native"
                    select
                    // label="Insight"
                    value={editData.insight}
                    onChange={(e) => {
                      let obj = { ...editData };
                      obj.insight = e.target.value;
                      setEditData(obj);
                    }}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  >
                    <option key={0} value={0}>
                      Select...
                    </option>
                    {[
                      "Media",
                      "Cultural",
                      "Intersectional",
                      "Actionable",
                      "Perception",
                    ].map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <label>Country</label>
                <FormControl
                  fullWidth
                  component="fieldset"
                  variant="outlined"
                  className={[classes.selectControl, classes.customfield].join(
                    " "
                  )}
                  style={{ marginTop: "10px" }}
                >
                  <TextField
                    id="outlined-select-currency-native"
                    select
                    // label="country"
                    value={editData.country}
                    onChange={(e) => {
                      let obj = { ...editData };
                      obj.country = e.target.value;
                      setEditData(obj);
                    }}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  >
                    <option key={0} value={0}>
                      Select...
                    </option>
                    {countriesList &&
                      countriesList.length &&
                      countriesList.map((option) => (
                        <option key={option._id} value={option._id}>
                          {option.name}
                        </option>
                      ))}
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <label>Question</label>
                <FormControl
                  fullWidth
                  component="fieldset"
                  variant="outlined"
                  className={[classes.selectControl, classes.customfield].join(
                    " "
                  )}
                  style={{ marginTop: "10px" }}
                >
                  <textarea
                    value={editData.question}
                    rows={8}
                    style={{
                      paddingTop: "10px",
                      textAlign: "center",
                      background: "transparent",
                      resize: "none",
                      border: "0 none",
                      fontSize: "10px",
                      outline: "none",
                    }}
                    onChange={(e) => {
                      let obj = { ...editData };
                      obj.question = e.target.value;
                      setEditData(obj);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <label>Answer</label>
                <FormControl
                  fullWidth
                  component="fieldset"
                  variant="outlined"
                  className={[classes.selectControl, classes.customfield].join(
                    " "
                  )}
                  style={{ marginTop: "10px" }}
                >
                  <textarea
                    value={editData.answer}
                    rows={8}
                    style={{
                      paddingTop: "10px",
                      textAlign: "center",
                      background: "transparent",
                      resize: "none",
                      border: "0 none",
                      fontSize: "10px",
                      outline: "none",
                    }}
                    onChange={(e) => {
                      let obj = { ...editData };
                      obj.answer = e.target.value;
                      setEditData(obj);
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "10px",
              }}
            >
              <Button
                className={classes.addActionBtn}
                style={{
                  width: "80px",
                  height: "40px",
                }}
                variant="outlined"
                onClick={() => UpdateQuestionData()}
              >
                Update
              </Button>
              <Button
                className={classes.addActionBtn}
                style={{
                  width: "80px",
                  height: "40px",
                  marginLeft: "10px",
                }}
                variant="outlined"
                onClick={() => setEditToggle(false)}
              >
                Cancel
              </Button>
            </div>
          </CardContent>
        </Card>
      </Box>
    );
  };

  const FileUpload = (file) => {
    setFileLoading(true);
    const formData = new FormData();

    formData.append("csv", file);

    axios
      .post(`${API}/admin/intelligence/csvUploadDeca`, formData, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res) {
          setFileLoading(false);
          setErrSucMesg(res.data.message);
          setErrSucType(true);
          // refresh();
          refreshList("", false, 17);
          setFile(true);
        }
      })
      .catch(function (error) {
        setFileLoading(false);
        if (error) {
          setErrSucMesg(error.response.data.message || "File is not uploaded");
          setErrSucType(false);
          setFile(true);
        }
      });
      refreshList("", false, 18);
  };

  useEffect(() => {
    if (fileInputRef) fileInputRef.current.value = null;
  }, [file]);

  const onReset = (e) => {
    e.preventDefault();
    setDemographic(0);
    setAgeGroup(0);
    setIndustry(0);
    setBrand(0);
    setInsiValue(0);
    setCountry(0);
    setKeyword("");
    if (
      !demographic &&
      !industry &&
      !ageGroup &&
      !InsiValue &&
      !Brand &&
      !country &&
      !keyword
    ) {
      refreshList("", false, 19);
    }
  };

  // const scrollHandlers = (event) => {
  //   const scrollTop = document.getElementById("table-wrapper").scrollTop
  //   const scrollHeight = document.getElementById("table-wrapper").scrollHeight
  //   const clientHeight = document.getElementById("table-wrapper").clientHeight

  //   if (scrollHeight - clientHeight <= scrollTop) {
  //     if (totalPage > page && !loading) {
  //       setpage(prev => prev+1)
  //       refreshList(page+1, true, infiniteScroll, 20)
  //     }
  //   }
  // }

  const handleScroll = (e) => {
    const scrollTop = document.querySelector(".rdg.r104f42s7-0-0-beta-22").scrollTop
    const scrollHeight = document.querySelector(".rdg.r104f42s7-0-0-beta-22").scrollHeight
    const clientHeight = document.querySelector(".rdg.r104f42s7-0-0-beta-22").clientHeight

    if (clientHeight + scrollTop >= scrollHeight && e.deltaY > 0) {
      if (totalPage > page && !loading) {
        setpage(prev => prev+1)
        setInfiniteScroll(true)
        refreshList(page+1, true, 21)
      }
    }
  };

  console.log("qy", qy)

  return (
    <div className={classes.boxShadow} style={{ maxWidth: "73vw" }}>
      <div className={classes.paper}>
        <Dialog open={duplicateToggle}>
          <DialogTitle style={{ justifyContent: "center", display: "flex" }}>
            Duplicate Question
          </DialogTitle>
          <p style={{ paddingRight: "10px", paddingLeft: "10px" }}>
            Are you sure, you want to add duplicate question?
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginBottom: "10px",
            }}
          >
            <Button
              className={classes.addActionBtn}
              style={{
                width: "80px",
                height: "40px",
                backgroundColor: "white",
                color: "black",
              }}
              variant="outlined"
              onClick={() => {
                setDuplicateToggle(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className={classes.addActionBtn}
              style={{
                width: "80px",
                height: "40px",
                backgroundColor: "#F44C3C",
              }}
              variant="outlined"
              onClick={() => {
                setDuplicateToggle(false);
                DuplicateQuestion(duplicateRecord);
              }}
            >
              Duplicate
            </Button>
          </div>
        </Dialog>
        <NewAlert
          msg={ErrSucMsg}
          isSuccess={ErrSucType}
          close={() => setErrSucMesg("")}
        />
        <form
          onSubmit={(e)=>e.preventDefault()}
          className={classes.form}
          // ref={useRef()  }
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              fontSize: "10px",

              justifyContent: "space-between",
            }}
          >
            <img
              style={{
                width: "20%",
                marginTop: "-70px",
                marginBottom: "-45px",
              }}
              src={sentmentLogo}
            />
            <div style={{ width: "17%" }}>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
                style={{ marginTop: "10px" }}
              >
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Demographic"
                  value={demographic}
                  onChange={(e) => setDemographic(e.target.value)}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {dropdownData?.demographics?.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </FormControl>
            </div>
            <div style={{ width: "17%" }}>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
                style={{ marginTop: "10px" }}
              >
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Age Group"
                  value={ageGroup}
                  onChange={(e) => setAgeGroup(e.target.value)}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {dropdownData?.ageGroups?.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </FormControl>
            </div>
            <div style={{ width: "17%" }}>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
                style={{ marginTop: "10px" }}
              >
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Industry"
                  value={industry}
                  onChange={(e) => setIndustry(e.target.value)}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {dropdownData?.industries?.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </FormControl>
            </div>
            <div style={{ width: "17%" }}>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
                style={{ marginTop: "10px" }}
              >
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Brand"
                  value={Brand}
                  onChange={(e) => setBrand(e.target.value)}
                  SelectProps={{
                    native: true,
                  }}
                  InputProps={
                    Spinner
                      ? {
                          startAdornment: (
                            <InputAdornment position="start">
                              <CircularProgress color="disabled" size={10} />
                            </InputAdornment>
                          ),
                        }
                      : {}
                  }
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {brandList.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </FormControl>
            </div>
            <div style={{ width: "40%" }}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    component="fieldset"
                    variant="outlined"
                    className={[
                      classes.selectControl,
                      classes.customfield,
                    ].join(" ")}
                    style={{ marginTop: "10px" }}
                  >
                    <TextField
                      id="outlined-select-currency-native"
                      select
                      label="Insight"
                      value={InsiValue}
                      onChange={(e) => setInsiValue(e.target.value)}
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                    >
                      <option key={0} value={0}>
                        Select...
                      </option>
                      {insightsData &&
                        insightsData.length &&
                        insightsData.map((option) => (
                          <option key={option.name} value={option.name}>
                            {option.name}
                          </option>
                        ))}
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    component="fieldset"
                    variant="outlined"
                    className={[
                      classes.selectControl,
                      classes.customfield,
                    ].join(" ")}
                    style={{ marginTop: "10px" }}
                  >
                    <TextField
                      id="outlined-select-currency-native"
                      select
                      label="Country"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                    >
                      <option key={0} value={0}>
                        Select...
                      </option>
                      {countriesList &&
                        countriesList.length &&
                        countriesList.map((option) => (
                          <option key={option._id} value={option._id}>
                            {option.name}
                          </option>
                        ))}
                    </TextField>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
            <div style={{ width: "17%" }}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    component="fieldset"
                    variant="outlined"
                    className={[
                      classes.selectControl,
                      classes.customfield,
                    ].join(" ")}
                    style={{ marginTop: "10px" }}
                  >
                    <TextField
                      id="outlined-select-currency-native"
                      fullWidth
                      // label="Name"
                      variant="outlined"
                      placeholder="Search"
                      // style={{ display: "block", marginBottom: "10px" }}
                      value={keyword}
                      onChange={(e) => {
                        setKeyword(e.target.value);
                        setpage(1)
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </div>

            <div className="upload-csv-file-input" style={{}}>
              <input
                type="file"
                accept=".csv"
                ref={fileInputRef}
                onChange={(e) => {
                  const file = e.target.files[0];
                  FileUpload(file);
                }}
              />
            </div>
          </div>
          <Button
            className={classes.addActionBtn}
            style={{
              float: "right",
              marginBottom: "10px",
            }}
            variant="outlined"
            onClick={(e) => onReset(e)}
          >
            Reset Filter
          </Button>
          <div
            style={{
              display: "flex",

              flexWrap: "wrap",
            }}
          >
            {/* {insight.map((element) => {
              element.id = element._id;
              element.saved = true;
              element.fromBE = true;
              return MyChatBox(element);
            })} */}

            {answeredQues.map((element, index) => MyChatBox(element, index))}
            {isAdding && MyChatBox(ques)}
            {demographic != 0 &&
              industry != 0 &&
              ageGroup != 0 &&
              country != 0 &&
              Brand != 0 &&
              InsiValue != 0 && (
                <div
                  disabled={isAdding}
                  style={
                    isAdding
                      ? {
                          marginTop: "130px",
                          width: "20%",
                          fontSize: "100px",
                          padding: "0",
                          color: "#E5E5E5",
                        }
                      : {
                          marginTop: "130px",
                          width: "20%",
                          fontSize: "100px",
                          padding: "0",
                          color: "#858585",
                        }
                  }
                  onClick={() => {
                    setIsAdding(true);
                  }}
                >
                  <AddCircleOutlineIcon fontSize="inherit" cursor="pointer" />
                </div>
              )}
          </div>

          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <div>
              <InputLabel
                style={{
                  fontSize: "12px",
                }}
              >
                ROI REPORT
              </InputLabel>
              <TextField
                style={{
                  fontSize: "12px",
                }}
                placeholder="KEYWORD"
              />
            </div>
            <div>
              <InputLabel
                style={{
                  fontSize: "12px",
                }}
              >
                AD TECH CONSIDERATIONS
              </InputLabel>
              <TextField
                style={{
                  fontSize: "12px",
                }}
                placeholder="KEYWORD"
              />
            </div>
            <div>
              <InputLabel
                style={{
                  fontSize: "12px",
                }}
              >
                MEDIA INSIGHTS
              </InputLabel>
              <TextField
                style={{
                  fontSize: "12px",
                }}
                placeholder="KEYWORD"
              />
            </div>
            <div>
              <InputLabel
                style={{
                  fontSize: "12px",
                }}
              >
                CREATIVE INSIGHTS
              </InputLabel>
              <TextField
                style={{
                  fontSize: "12px",
                }}
                placeholder="KEYWORD"
              />
            </div>
          </div> */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "5px",
              marginTop: "5px",
            }}
          >
            {answeredQues.length !== 0 && (
              <FormControl>
                <Button
                  className={classes.addActionBtn}
                  variant="outlined"
                  color="primary"
                  onClick={() => SendList()}
                >
                  SUBMIT
                </Button>
              </FormControl>
            )}
          </div>
        </form>
        {
          ////////////////////////////////////////////
        }
        {editToggle && EditQuestionData()}

        <div>
          {(tableLoading || loading) && <CircularProgress />}
          {tableData.length > 0 && !tableLoading && (
            <>
            {/* <div onWheel={scrollHandlers} id="table-wrapper" style={{height: "495px", width: "100%", overflow: "auto"}}>
              <MyTable
                style={{ width: "100%" }}
                headings={[
                  "Name of Report",
                  "Demographic",
                  "Age Group",
                  "Industry",
                  "Brand",
                  "Insight",
                  "Country",
                  "Question",
                  "Answer",
                ]}
                listdata={tableData}
                showCheckbox={true}
                options={["delete","change Demographic"]}
                //redirectLink={"MediaOwnerProfile?id="}
                handleDelete={usertype === "superadmin" ? (_id) => {
                  const idArr = [_id];
                  DeleteQuestion(idArr);
                } : null}
                updateSecondDropdown={(ids, value) => {
                  updateDemographic(ids, value);
                }}
                secondDropdownValues={dropdownData?.demographics}
                onSelectRowsIndexes={(ids) => {
                  DeleteQuestion(ids);
                }}
                handleDuplicate={(row) => {
                  setDuplicateToggle(true);
                  setDuplicateRecord(row);
                }}
                isEditable={true}
                HandleCSV={(row) => {
                  let dataArr = DATA.filter((dt) => {
                    if (dt._id === row._id) return dt;
                  });
                  if (dataArr.length) {
                    editData.ageGroup = dataArr[0].ageGroup
                      ? dataArr[0].ageGroup._id
                      : "";
                    editData.demographic = dataArr[0].demographic
                      ? dataArr[0].demographic._id
                      : "";
                    editData.brand = dataArr[0].brand
                      ? dataArr[0].brand._id
                      : "";
                    editData.industry = dataArr[0].industry
                      ? dataArr[0].industry._id
                      : "";
                    editData.nameOfReport = dataArr[0].nameOfReport
                      ? dataArr[0].nameOfReport
                      : "";
                    editData.question =
                      dataArr[0].questions && dataArr[0].questions.length
                        ? dataArr[0].questions[0].question
                        : "";
                    editData.question_id =
                      dataArr[0].questions && dataArr[0].questions.length
                        ? dataArr[0].questions[0]._id
                        : "";
                    editData.answer =
                      dataArr[0].questions && dataArr[0].questions.length
                        ? dataArr[0].questions[0].answer
                        : "";
                    editData.insight = dataArr[0].insight
                      ? dataArr[0].insight
                      : "";
                    editData.country = dataArr[0].country
                      ? dataArr[0].country._id
                      : "";
                    editData.id = dataArr[0]._id ? dataArr[0]._id : "";
                    if (dataArr[0].industry) {
                      setNewIndustry(dataArr[0].industry._id);
                    }
                    setEditData(editData);
                    setEditToggle(true);
                  }
                }}
                from="decaInsight"
                // selectedBulkAction={(name) => {
                //   setActionName(name);
                // }}
              />

            </div> */}

            <div id="data-table-wrapper" className={ loading ? classess.columnLoader : classess.columnHeader } onWheel={handleScroll} style={{height:"400px", width:"100%", overflow: "auto"}}>
              <DataGrid
                columns={columns}
                rows={tempData}
                pinnedBottomRowData={1}
                enableCellSelect={true}
                onCellFocus={() => {
                  if (editQue !== null) {
                    setEditQue(null);
                  }
                }}
              />
            </div>
            <div>
              <span
                style={{
                  color: "#FB6D6A",
                  fontWeight: "600",
                  margin: "0",
                  fontSize: "20px",
                  marginRight: "20px",
                }}
              >
                <Pagination
                  count={Math.ceil(pagecount / 10)}
                  color="primary"
                  page={page}
                  onChange={(e, page) => {
                    setpage(page)
                    setInfiniteScroll(false)
                    refreshList(page, false, 24)
                  }}
                  size="small"
                />
              </span>
            </div>
            </>
          )}
        </div>
        <Modal
          isModalOpen={modalOpen}
          redirect="/media-owner/personas"
          message="Your new persona has been saved"
        ></Modal>
        <Dialog
          open={deleteRow}
          onClose={() => {
            setDeleteRow(false)
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Are you sure, you want to delete this row?
          </DialogTitle>
          <DialogActions>
            <Button
              style={{ color: "black", backgroundColor: "grey" }}
              onClick={() => {
                setDeleteRow(false)
              }}
            >
              Cancel
            </Button>
            <Button
              style={{ backgroundColor: "#4F74C9", color:"white"}}
              onClick={() => {
                DeleteQuestion(toDeleteRow)
                setDeleteRow(false)
              }}
              autoFocus
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default Sentiment;
