export const ERROR = 'ERROR';
export const UPLOAD_AVATAR = 'UPLOAD_AVATAR';
export const UPLOAD_AVATAR_SUCCESS = 'UPLOAD_AVATAR_SUCCESS';

export const UPLOAD_CSV = 'UPLOAD_CSV';
export const UPLOAD_CSV_SUCCESS = 'UPLOAD_CSV_SUCCESS';

export const AVATAR_URL = 'AVATAR_URL';
export const CSV_URL = 'CSV_URL';
export const CREATE_INVENTORY = 'CREATE_INVENTORY';
export const CREATE_MEDIA_OWNER = 'CREATE_MEDIA_OWNER';
export const LIST_MEDIA_OWNER_TITLE = 'LIST_MEDIA_OWNER_TITLE';
export const GET_MEDIA_OWNER_TITLE = 'GET_MEDIA_OWNER_TITLE';
export const CREATE_MEDIA_OWNER_AND_INVENTORY = 'CREATE_MEDIA_OWNER_AND_INVENTORY';
export const CREATE_MEDIA_OWNER_AND_INVENTORY_SUCCESSFULL = 'CREATE_MEDIA_OWNER_AND_INVENTORY_SUCCESSFULL';
export const CREATE_MEDIA_PLANNER = 'CREATE_MEDIA_PLANNER';
export const GET_ALL_MEDIA_OWNER='GET_ALL_MEDIA_OWNER';

export function upload(avatar) {
  return {
    type: UPLOAD_AVATAR,
    avatar,
  };
}

export function uploadCSV(csv) {
  return {
    type: UPLOAD_CSV,
    csv,
  };
}

export function uploadSuccessfully(avatarUrl) {
  return {
    type: AVATAR_URL,
    avatarUrl,
  };
}

export function uploadCsvSuccessfully(csvUrl) {
  return {
    type: CSV_URL,
    csvUrl,
  };
}

export function createMediaOwner(mediaOwner) {
  return {
    type: CREATE_MEDIA_OWNER,
    mediaOwner,
  };
}

export function createMediaPlanner(mediaPlanner) {
  return {
    type: CREATE_MEDIA_PLANNER,
    mediaPlanner,
  };
}

export function listMediaOwnerTitles(mediaOwnerId) {
  return {
    type: LIST_MEDIA_OWNER_TITLE,
    mediaOwnerId,
  };
}

export function getMediaOwnerTitles(mediaOwnerTitles) {
  return {
    type: GET_MEDIA_OWNER_TITLE,
    mediaOwnerTitles,
  };
}

export function createMediaOwnerAndInventory(mediaOwner) {
  return {
    type: CREATE_MEDIA_OWNER_AND_INVENTORY,
    mediaOwner,
  };
}

export function createMediaOwnerAndInventorySuccessfull(mediaOwnerSuccessfull) {
  return {
    type: CREATE_MEDIA_OWNER_AND_INVENTORY_SUCCESSFULL,
    mediaOwnerSuccessfull,
  };
}

export function getAllMediaOwner(userId) {
  return {
    type: GET_ALL_MEDIA_OWNER,
    userId
  };
}

export function saveAllMediaOwner(allMediaOwners) {
  return {
    type: 'SAVE_ALL_MEDIA_OWNERS',
    allMediaOwners
  };
}

export function error(error) {
  return {
    type: ERROR,
    error
  };
}
