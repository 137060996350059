import React from "react";
import Lock from "../../assets/lock.png";

const Subscription = () => {
  return (
    <div
      className="example"
      style={{
        width: "50%",
        height: "340px",
        overflow: "scroll",
        overflowX: "hidden",
        backgroundImage: "linear-gradient(to right, #9211a2, #4911a2)",
        borderRadius: "20px",
        color: "white",
        marginTop: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h4
        style={{
          marginTop: 0,
          textAlign: "center",
        }}
      >
        <img height={50} width={50} src={Lock} /> <br/>
        {/* Please subscribe to paid version to view this page<br/> */}
        Please contact your administrator for access
        <br />
        <a
          style={{ color: "white" }}
          href="mailto:contact@cultural-intelligence.co.uk"
          target="_blank"
        >
          contact@cultural-intelligence.co.uk
        </a>
      </h4>
    </div>
  );
};
export default Subscription;
