/* eslint-disable no-unused-expressions */
import { Box, Button, Modal } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { useAccessToken } from "../../../../../Hooks/useAccessToken";
import LoaderCircle from "../../../../../components/Loader/Loader";
import { API } from "../../../../../constants";
import { randomColor } from "../../../../../utils/utils";
import "./chart-modal.css";



export default function ChartModal({ open, csv_id, onClose }) {
    const access_token = useAccessToken();
    const [mappedChartData, setMappedChartData] = useState({
        labels: [],
        datasets: [],
        randomColors: [],
    });

    const { data, isFetching, isError, error, isLoading, refetch } = useQuery({
        queryFn: (params) =>
            axios.get(`${API}/admin/csv/domain-result/${csv_id}`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            }),
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            let percentageArr = [];
            let randomBgColors = [];

            data?.data?.match_domains?.forEach?.((csv_detail) => {
                randomBgColors.push(randomColor());
                percentageArr.push(csv_detail?.percentage);

                setMappedChartData((chart_data) => ({
                    ...chart_data,
                    labels: [...chart_data.labels, csv_detail.demographic],
                    datasets: [{ ...chart_data.datasets, data: percentageArr }],
                    randomColors: randomBgColors,
                }));
            });
        },
    });

    useEffect(() => {
        setMappedChartData({
            labels: [],
            datasets: [],
            randomColors: [],
        });
        if (csv_id) {
            refetch();
        }

        return () => {
            setMappedChartData({
                labels: [],
                datasets: [],
                randomColors: [],
            });
        };
    }, [csv_id]);

    
    const chartData = {
        labels: mappedChartData.labels,
        datasets: [
            {
                label: "# of votes",
                borderWidth: 1,
                backgroundColor: mappedChartData.randomColors,
                borderColor: mappedChartData.randomColors,
                data: mappedChartData.datasets[0]?.data,
            },
        ],
    };

    return (
        <Modal
            open={open}
            onClose={() => onClose()}
            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
            <Box className="box">
                <Button className="close-icon" onClick={onClose}>
                    <Close />
                </Button>
                {isLoading && <LoaderCircle style={{ color: "white" }} />}
                {data && !isLoading && (
                    <div>
                        <Pie
                            className="pie-chart"
                            data={chartData}
                            options={{
                                plugins: {
                                    legend: {
                                        display: true,
                                    },
                                },
                                color: true,
                                scales: {
                                    y: {
                                        ticks: {
                                            display: false,
                                            color: "white",
                                            font: {
                                                size: 14,
                                            },
                                        },
                                    },
                                    x: {
                                        ticks: {
                                            display: false,
                                            color: "white",
                                            font: {
                                                size: 14,
                                            },
                                        },
                                    },
                                },
                            }}
                        />
                    </div>
                )}
            </Box>
        </Modal>
    );
}

// template
// data={{
//     labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
//     datasets: [
//         {
//             label: "# of Votes",
//             data: [12, 19, 3, 5, 2, 3],
//             borderWidth: 1,
//             backgroundColor: "purple",
//         },
//     ],
// }}



// const barAnsData = {
    //     labels: mappedChartData.labels,
    //     datasets: [
    //         {
    //             label: "# of votes",
    //             borderWidth: 1,
    //             backgroundColor: randomBgColors,
    //             borderColor: randomBgColors,
    //             data: percentageArr,
    //         },
    //     ],
    // };