import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  Fade,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@material-ui/core"
import { fade, makeStyles } from "@material-ui/core/styles"
import DoneAll from "@material-ui/icons/DoneAll"
import Copy from "@material-ui/icons/FileCopy"
import axios from "axios"
import { EditorState } from "draft-js"
import React, { useEffect, useRef, useState } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"

import { API, SECRET_KEY } from "../../../constants/index"
import { RenderBarGraphData } from "../createDecaReport"
import { getChartData } from "../helperDecaReport/charts"

import { findLastIndex } from "lodash"
import useStyles from "../../../components/StyleClasses"

const useStyless = makeStyles(() => ({
  newSectionWrapper: {
    textAlign: "left",
  },
  uploadImageWrapper: {
    margin: "1rem 0",
  },
  newSectionBtn: {
    whiteSpace: "nowrap",
    padding: "6px 8px",
    marginRight: "0.3rem",
    fontSize: "13px",
    color: "white",
    fontWeight: "bold",
    borderRadius: "8px",
    textTransform: "none",
    border: "none",
    background: "#808080",
    "&:hover": {
      background: "#808080",
      cursor: "pointer",
    },
  },
  newSectionBtnSelected: {
    whiteSpace: "nowrap",
    padding: "6px 8px",
    marginRight: "0.3rem",
    fontSize: "13px",
    backgroundColor: "#136cc3",
    color: "white",
    fontWeight: "bold",
    borderRadius: "8px",
    textTransform: "none",
    border: "none",
    "&:hover": {
      background: "#136cc3",
      cursor: "pointer",
    },
  },
  copyUrl: {
    cursor: "pointer",
    fontWeight: "500",
    marginLeft: "0.8em",
    color: "#fff",
    padding: "0.4rem 0.8rem",
    borderRadius: "15px",
    background: "#2b6cc3",
  },
  copiedUrl: {
    cursor: "pointer",
    fontWeight: "500",
    marginLeft: "0.8em",
    color: "#fff",
    padding: "0.4rem 0.8rem",
    borderRadius: "15px",
    background: "#09eb1a",
  },
  urlString: {
    borderBottom: "1px solid grey",
    padding: "0.3em",
    fontWeight: "500",
  },
  sectionBody: {
    marginBottom: "1rem",
  },
  titleWrapper: {
    marginTop: "0.5rem",
  },
}))

const NewSection = ({ ques, keyFindExpanded, setques, editSecData }) => {
  const newQue = ques
  const classes = useStyles()
  const classess = useStyless()
  const [title, setTitle] = useState("")
  const [newTitle, setNewTitle] = useState([])
  const [newTitleFormatted, setNewTitleFormatted] = useState([])
  const [newSection, setNewSection] = useState([])
  const [imgUpload, setImgUpload] = useState("")
  const [urlCopy, setUrlCopy] = useState(false)
  const [confirmBtn, setConfirmBtn] = useState([])
  const [loading, setLoading] = useState(false)
  const [newSecData, setNewSecData] = useState(null)
  const toUploadImg = useRef("")
  const existData = []

  const access_token = window.localStorage.getItem(SECRET_KEY)

  useEffect(() => {
    setLoading(true)
    let index = 0
    const data = []
    const nsd = []
    for (let i in editSecData) {
      if (!["_id", "demographics"].includes(editSecData[i])) {
        const name = editSecData[i].replace(/([a-z])([A-Z])/g, "$1 $2")
        const nameSplit = name.split(" ")
        const capitalizeName = nameSplit.map((item) => {
          return item.charAt(0).toUpperCase() + item.slice(1)
        })

        const formatName = capitalizeName.join(" ")
        let d = {
          sectionName: formatName,
          key: editSecData[i],
          data: newQue[editSecData[i]],
        }
        existData.push(d)
        nsd.push(editSecData[i])
        index++
      }
    }

    if (nsd.length > 0) {
      setNewSecData(nsd)
    }

    if (existData.length > 0) {
      let initialSection = []
      let initialTitles = []
      let initialTitlesFormatted = []
      let initialCancelBtn = []

      existData.forEach((item, index) => {
        initialSection.push({
          id: Date.now() + index,
        })

        initialTitles.push({
          title: item.sectionName,
        })

        initialTitlesFormatted.push({
          formatTitle: item.key,
        })

        initialCancelBtn.push({
          status: false,
        })
      })

      setNewSection(initialSection)
      setNewTitle(initialTitles)
      setNewTitleFormatted(initialTitlesFormatted)
      setConfirmBtn(initialCancelBtn)
    }
    setLoading(false)
  }, [])

  const newSectionHandler = () => {
    if (newSection.length > 0) {
      // Initialised new section added
      const d = [...newSection]
      d.push({
        id: Date.now(),
      })
      setNewSection(d)
      // Initialised default new section title
      const data = [...newTitle]
      data.push({
        title: "",
      })
      setNewTitle(data)
      // Initialised default new section formatted title to be used as key
      const nt = [...newTitleFormatted]
      nt.push({
        formatTitle: "",
      })
      setNewTitleFormatted(nt)
      // Initialised default toggle of button i.e Cancel and Confirm
      const c = [...confirmBtn]
      c.push({
        status: false,
      })
      setConfirmBtn(c)
    } else {
      const d = [
        {
          id: Date.now(),
        },
      ]
      setNewSection(d)

      const data = [
        {
          title: "",
        },
      ]
      setNewTitle(data)

      const nt = [
        {
          formatTitle: "",
        },
      ]
      setNewTitleFormatted(nt)

      const c = [
        {
          status: false,
        },
      ]
      setConfirmBtn(c)
    }
  }

  const newBtnHandler = (e, index) => {
    const toSplitTitle = title.split(" ")
    const casingTitle = toSplitTitle.map((item, ind) => {
      if (ind === 0) {
        return item.toLowerCase()
      } else {
        return item.charAt(0).toUpperCase() + item.slice(1)
      }
    })
    const formattedTempTitle = casingTitle
    const formattedTitle = formattedTempTitle.join("")

    const nt = [...newTitleFormatted]
    nt[index].formatTitle = formattedTitle

    setNewTitleFormatted(nt)

    newQue[formattedTitle] = [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ]

    const toSplitTitleAccor = title.split(" ")
    const casingTitleAccor = toSplitTitleAccor.map((item) => {
      return item.charAt(0).toUpperCase() + item.slice(1)
    })
    const formattedTempTitleAccor = casingTitleAccor
    const formattedTitleAccor = formattedTempTitleAccor.join(" ")
    setques(newQue)
    const data = [...newTitle]
    data[index].title = formattedTitleAccor
    setNewTitle(data)

    const c = [...confirmBtn]
    c[index].status = false
    setConfirmBtn(c)
  }

  const removeBtnHandler = (e, index) => {
    const d = [...newSection]
    d.splice(index, 1)
    setNewSection(d)

    const deleteSection = [...newTitle]
    deleteSection.splice(index, 1)
    setNewTitle(deleteSection)

    if (newSecData !== null) {
      const ns = [...newSecData]
      const deleteNS = ns.splice(index, 1)
      if (deleteNS.length > 0 && deleteNS) {
        for (let k of deleteNS) {
          delete newQue[k]
          const fd = [...newTitleFormatted]
          for (let j in fd) {
            if (k === fd[j].formatTitle) {
              fd.splice(j,1)
              setNewTitleFormatted(fd)
            }
          }
        }
      }
      localStorage.setItem("newSectionData", JSON.stringify(deleteNS))
    }
  }

  const titleHandler = (e, index) => {
    const data = [...newTitle]
    data[index].title = e.target.value
    setTitle(e.target.value)

    if (newTitle[index].title === "") {
      const c = [...confirmBtn]
      c[index].status = false
      setConfirmBtn(c)
    } else {
      const c = [...confirmBtn]
      c[index].status = true
      setConfirmBtn(c)
    }
  }

  const uploadHandler = async () => {
    const formData = new FormData()
    formData.append("reportImage", toUploadImg.current.files[0])
    await axios
      .post(`${API}/admin/report/uploadImageReport`, formData, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        setImgUpload(res.data.imageURL)
      })
      .catch((err) => {
        console.log("err", err)
      })
  }

  const uploadInputHandler = () => {
    setUrlCopy(false)
    setImgUpload("")
  }

  if (loading) {
    return <h3>Loading</h3>
  }

  return (
    <>
      <div className={classess.newSectionWrapper}>
        <Button
          className={classess.newSectionBtnSelected}
          variant={"outlined"}
          onClick={newSectionHandler}
        >
          New Section
        </Button>
        <div className={classess.uploadImageWrapper}>
          <FormControl
            component="fieldset"
            variant="outlined"
            style={{ marginRight: "0.3em" }}
            className={`${[classes.selectControl, classes.customfield].join(
              " "
            )}`}
          >
            <input
              type="file"
              ref={toUploadImg}
              placeholder="Upload .jpeg image"
              variant="outlined"
              name="reportImage"
              image="image/jpg, image/jpeg, image/png"
              onChange={uploadInputHandler}
            />
            {imgUpload !== "" ? (
              <p>
                <span className={classess.urlString}>{imgUpload}</span>
                <CopyToClipboard text={imgUpload}>
                  <span
                    className={urlCopy ? classess.copiedUrl : classess.copyUrl}
                    onClick={() => setUrlCopy(true)}
                  >
                    {urlCopy ? "Copied" : "Copy"}
                  </span>
                </CopyToClipboard>
              </p>
            ) : (
              ""
            )}
          </FormControl>
          <Button
            className={classess.newSectionBtnSelected}
            variant={"outlined"}
            onClick={uploadHandler}
          >
            Upload
          </Button>
        </div>
      </div>

      {newSection.length > 0 &&
        newSection.map((item, index) => (
          <div key={item.id} className={classess.sectionBody}>
            <div className={classess.titleWrapper}>
              <FormControl
                component="fieldset"
                variant="outlined"
                style={{ marginRight: "0.3em" }}
                className={`${[classes.selectControl, classes.customfield].join(
                  " "
                )}`}
              >
                <TextField
                  placeholder="Enter section title first"
                  variant="outlined"
                  name="title"
                  value={newTitle.length > 0 ? newTitle[index].title : ""}
                  onChange={(e) => titleHandler(e, index)}
                />
              </FormControl>
              <Button
                className={classess.newSectionBtnSelected}
                variant={"outlined"}
                onClick={(e) =>
                  confirmBtn[index].status
                    ? newBtnHandler(e, index)
                    : removeBtnHandler(e, index)
                }
              >
                {confirmBtn[index].status && newTitle[index].title !== ""
                  ? "Confirm"
                  : "Cancel"}
              </Button>
            </div>
            {!confirmBtn[index].status && newTitle[index].title !== "" ? (
              <RenderBarGraphData
                quests={newQue}
                accorName={newTitle[index].title}
                elementName={newQue[newTitleFormatted[index].formatTitle]}
                disableField={newQue.saved}
                type={newTitleFormatted[index].formatTitle}
                callback={(values) => {
                  setques(values)
                }}
                keyFindExpanded={keyFindExpanded}
                getChartData={getChartData}
              />
            ) : (
              ""
            )}
          </div>
        ))}
    </>
  )
}

export default NewSection
