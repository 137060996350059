import { get, post } from "./axios";

export const mediaQuestion = async (data) => {
  return await post("/mediaowner/questions", data);
};

export const mediaSentiments = async (data) => {
  return await post("/mediaowner/sentiments", data);
};

export const mediaConsultancy = async (data) => {
  return await post("/mediaowner/consultancy", data);
};

export const mediaQuestion1out5 = async (data) => {
  return await post("/mediaowner/question1out5", data);
};
