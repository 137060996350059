import React, {useEffect, useRef, useState} from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";


const CustomSelect = ({errors, label, responseTotalPages, stateTotalData, apiCallback, onChangeCallback, selectedValues, renderValues, data, type, initialSelectedData  }) => {
  const dropdownRef = useRef();

  const [initialRenderValues, setInitialRenderValues] = useState([])

  const onScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
       if (bottom) { 
          if(responseTotalPages == stateTotalData) return;
           apiCallback();
       }
  };

  useEffect(()=>{
    if(renderValues.length) setInitialRenderValues(renderValues);
  },[renderValues])

  useEffect(() => {
    if (initialSelectedData.length > 0 && selectedValues.length > 0) {
      let a = initialSelectedData.filter((o) => {
        if (o) {
          selectedValues.includes(o._id)
        }
      });
      let b = a.map((e) => e[type] + " , ");
      setInitialRenderValues(b.slice(0, 30));
    }
  }, []);

  return (
    <Select
      error={errors}
      id="outlined-select-currency-native"
      multiple
      label={label}
      value={selectedValues}
      onChange={onChangeCallback}
      onScroll={(e) => onScroll(e)}
      ref={dropdownRef}
      variant="outlined"
      renderValue={(ep) => initialRenderValues}
    >
      <MenuItem disabled value={0}>
        <em>Select...</em>
      </MenuItem>
      {data &&
        data.length &&
        data.map((option) => (
          <MenuItem key={option._id} value={option._id}>
            <Checkbox
            //   checked={selectedValues.length > 0 && selectedValues.includes(option._id)}
            ></Checkbox>
            {option[type]}
          </MenuItem>
        ))}
    </Select>
  );
};

export default CustomSelect;
