/* eslint-disable react-hooks/rules-of-hooks */
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MailIcon from "@material-ui/icons/Mail";
import MenuIcon from "@material-ui/icons/Menu";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import icon from "../assets/CI-Icon.svg";
import culturalIntellWhite from "../assets/CI-Logo-white.png";
import NewAlert from "../components/NewAlert";
import { API } from "../constants/index";
import { SaveAdminDropDownData } from "../store/actions/admin/intelligence";

import { SECRET_KEY } from "../constants/index";
import * as actions from "../redux/actions/user";
import Footer from "./footer";
import LoginCheck from "./loginCheck";

import AccountMenu from "./avatar.js";

const drawerWidth = window.screen.width / 6;

const useStyles = (icon) =>
  makeStyles((theme) => ({
    root: {
      display: "flex",
      // width: "83%",
      // [theme.breakpoints.down("sm")]: {
      //   width: "100%",
      // },
    },
    drawer: {
      backgroundColor: "red",
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      background: "white",
      "& .MuiToolbar-regular": {
        minHeight: "50px",
      },
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      backgroundImage: `url(${icon}), linear-gradient(to right, #9211a2, #4911a2)`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      borderLeft: "10px solid #f64d0c",
      width: drawerWidth,
    },
    content: {},
    logoutBtn: {
      background: "#e96941",
      fontWeight: "400",
      color: "white",
      marginLeft: "auto",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    topicon: {
      width: "85%",
      margin: "20px auto",
    },
    bgicon: {
      position: "absolute",
      top: 0,
    },
    sideMenu: {
      "& li": {
        color: "white",
        listStyleType: "none",
        paddingBottom: "10px",
        cursor: "pointer",
        "& a": {
          color: "white",
          textDecoration: "none",
        },
      },
    },
  }));

const MainLayout = (WrappedComponent) => (props) => {
  const history = useHistory();
  //const { window } = props;
  // if (
  //   window.localStorage.getItem("role") !== "media_owner" ||
  //   window.localStorage.getItem(SECRET_KEY) === null
  // ) {
  //   history.push("/");
  // }

  const username = window.localStorage.getItem("username");
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  const loggedInSession = useSelector((state) => state.loggedInSession);
  const error = useSelector((state) => state.error);
  const classes = useStyles(icon)();
  const access_token = window.localStorage.getItem(SECRET_KEY);
  const [showPayouyPanel, setShowPayoutPanel] = useState(false);

  LoginCheck("/mediaOwner/mediaPublication?name=g", null, history);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const logout = () => {
    window.localStorage.setItem(SECRET_KEY, null);
    window.localStorage.setItem("role", null);
    dispatch(actions.error(null));
    dispatch(actions.loginSuccessfully(null));
    setTimeout(() => {
      history.push("/");
    }, 500);
  };

  useEffect(() => {
    axios
      .get(`${API}/mediaOwner/dropdowns`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res, err) => {
        if (res) {
          dispatch({ type: "SAVE_CSV_DROPDWON", dropdownData: res.data });
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  });
  useEffect(() => {
    axios
      .get(`${API}/mediaOwner/PaybackCampaigns`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          let list = res.data.map((e) => e.campaignId);
          dispatch({ type: "Pass_Back_List", payload: list });
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${API}/mediaOwner/profile`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          dispatch({ type: "MO_Profile_OBJ", payload: res.data });
          if (res.data.publisherId) {
            if (
              res.data.publisherId.length > 0 &&
              res.data.cpmVideo > 0 &&
              res.data.cpmDisplay > 0 &&
              res.data.currency
            ) {
              setShowPayoutPanel(true);
            }
          }
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  }, []);

  // const container =
  //   window !== undefined ? () => window().document.body : undefined;
  let page = "home";
  let loc = history.location.pathname;
  if (loc.includes("FAQ")) {
    page = "FAQ";
  }
  if (loc.includes("ersona")) {
    page = "personas";
  }
  if (loc.includes("intelligence")) {
    page = "intelligence";
  }
  if (loc.includes("reports") || loc.includes("Invoice")) {
    page = "home";
  }

  if (loc.includes("publications") || loc.includes("Media")) {
    page = "publications";
  }

  return (
    <div className={classes.root} style={{ backgroundColor: "#F4F4F8" }}>
      <NewAlert />
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <div
          style={{
            display: "flex",
            padding: "2px",
            justifyContent: "flex-end",
          }}
        >
          <AccountMenu handleLogout={logout} name={username} />
        </div>
      </AppBar>
      <nav
        className={classes.drawer}
        aria-label="mailbox folders"
        style={{ background: "red" }}
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css" style={{ background: "red" }}>
          <Drawer
            //container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <img className={classes.topicon} src={culturalIntellWhite} />
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css" style={{ background: "red" }}>
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <img className={classes.topicon} src={culturalIntellWhite} />

            <ul className={classes.sideMenu}>
              <li>
                <Link
                  style={
                    page === "publications"
                      ? { fontWeight: "700", color: "white" }
                      : { fontWeight: "300", color: "#E2DEEA" }
                  }
                  to="/media-owner/publications"
                >
                  Publications
                </Link>
              </li>
              <li>
                <Link
                  style={
                    page === "personas"
                      ? { fontWeight: "700", color: "white" }
                      : { fontWeight: "300", color: "#E2DEEA" }
                  }
                  to="/media-owner/personas"
                >
                  Personas
                </Link>
              </li>
              {showPayouyPanel && (
                <li>
                  <Link
                    style={
                      page === "home"
                        ? { fontWeight: "700", color: "white" }
                        : { fontWeight: "300", color: "#E2DEEA" }
                    }
                    to="/media-owner"
                  >
                    Payout/Reports
                  </Link>
                </li>
              )}
              {/* <li>
                <Link to="/media-owner/intelligence">intelligence</Link>
              </li> */}

              <li>
                <Link
                  style={
                    page === "FAQ"
                      ? { fontWeight: "700", color: "white" }
                      : { fontWeight: "300", color: "#E2DEEA" }
                  }
                  to="/media-owner/FAQ"
                >
                  FAQ
                </Link>
              </li>
            </ul>
          </Drawer>
        </Hidden>
      </nav>
      <div className={classes.toolbar} />
      <div style={{ width: "100%" }}>
        <Grid
          spacing={0}
          style={{ width: "100%", padding: "60px 30px", minHeight: "86vh" }}
        >
          <Grid item md={12}>
            <WrappedComponent {...props} />
          </Grid>
        </Grid>
        <Footer />
      </div>
    </div>
  );
};

export default MainLayout;
