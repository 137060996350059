import React, { useState, useEffect } from "react";
import { makeStyles, fade } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Grid, Card, CardContent, Box } from "@material-ui/core/";

import Pagination from "@material-ui/lab/Pagination";
import { useForm, Controller } from "react-hook-form";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

//Text field
import TextField from "@material-ui/core/TextField";

// Select
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

//Textarea
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

//Button
import Button from "@material-ui/core/Button";
import { useSelector } from "react-redux";

import Checkbox from "@material-ui/core/Checkbox";
import MyTable from "./mytable";

import { useDispatch } from "react-redux";
import * as actions from "../../redux/actions/user";
//import ShowAlert from "../../components/alerts";
import NewAlert from "../../components/NewAlert";
import StepWrapper from "./stepWrapper";
import MainLayout from "../../layouts/newMainLayout";
import { useHistory } from "react-router-dom";
import Modal from "../../components/modal";
import { getCsvDropdown } from "../../redux/actions/admin";
import { postMediaSentiment } from "../../redux/actions/media";
import axios from "axios";
import { API } from "../../constants/index";

import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";

import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import AssignmentIcon from "@material-ui/icons/Assignment";

// import culturalIntell from "../../assets/Cultural-Intelligence.svg";
import sentmentLogo from "../../assets/sentimentLogo.PNG";
import { setMonth, setYear } from "date-fns";
import useStyles from "../../components/StyleClasses";

import { SECRET_KEY } from "./../../constants/index";

const Sentiment = ({ onDone }) => {
  const { register, handleSubmit, errors, control } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const [DATA, setDATA] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [page, setpage] = useState(1);
  const [pagecount, setPageCount] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [demographic, setDemographic] = useState(0);
  const [industry, setIndustry] = useState(0);
  const [age, setAge] = useState(0);
  const [topic, setTopic] = useState(0);
  const [country, setCountry] = useState(0);
  const [brand, setBrand] = useState(0);
  const [pubYear, setPubYear] = useState(0);
  const [pubMonth, setPubMonth] = useState(0);
  const [sentimentScore, setSentimentScore] = useState(0);
  const [BrandList, setBrandList] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [editToggle, setEditToggle] = useState(false);
  const [editData, setEditData] = useState({
    ageGroup: 0,
    demographics: 0,
    brand: 0,
    industry: 0,
    publishYear: 0,
    publishMonth: 0,
    score: 0,
    topic: 0,
    country: 0,
    _id: "",
  });
  const [duplicateToggle, setDuplicateToggle] = useState(false);
  const [duplicateRecord, setDuplicateRecord] = useState({});
  const [loading, setLoading] = useState(false);
  const [Spinner, setSpinner] = useState(false);
  const [keyword, setKeyword] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();

  ///////errors & successs //////////
  const [ErrSucMsg, setErrSucMesg] = useState("");
  const [ErrSucType, setErrSucType] = useState(true);
  ///////////////////////////////////

  const currentUser = useSelector((state) => state.loggedInSession);
  const dropdownData = useSelector((state) => state.adminDropDownData);
  const classes = useStyles();
  const access_token = window.localStorage.getItem(SECRET_KEY);
  const usertype = window.localStorage.getItem("usertype");

  const SendList = () => {
    const body = {
      country: country,
      demographics: demographic,
      topic: topic,
      publishYear: pubYear,
      publishMonth: pubMonth,
      score: sentimentScore,
    };
    if (industry.length > 0) {
      body.industry = industry;
    }
    if (brand.length > 0) {
      body.brand = brand;
    }
    if (age != 0) {
      body.ageGroup = age;
    }

    axios
      .post(`${API}/admin/intelligence/addSentiment`, body, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        refresh();

        if (err)
          if (res) {
            setErrSucMesg(res.data.message);
            setErrSucType(true);
          }
      })
      .catch(function (error) {
        if (error) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
        }
      });
  };

  const refresh = (isDirectRefresh) => {
    setIsUpdate(false);
    setLoading(true);

    let URLL = `${API}/admin/intelligence/sentiments?`;
    if (country != 0) URLL += `&country=${country}`;
    if (brand != 0) URLL += `&brand=${brand}`;
    if (demographic != 0) URLL += `&demographics=${demographic}`;
    if (industry != 0) URLL += `&industry=${industry}`;
    if (age != 0) URLL += `&ageGroup=${age}`;
    if (topic != 0) URLL += `&topic=${topic}`;
    if (pubYear != 0) URLL += `&publishYear=${pubYear}`;
    if (pubMonth != 0) URLL += `&publishMonth=${pubMonth}`;
    if (keyword !== "") URLL += `keyword=${keyword}`;
    URLL += `&page=${page}`;

    axios
      .get(URLL, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        setLoading(false);
        if (res && res.data) {
          setDATA(res.data.data);
          setPageCount(res.data.pagination ? res.data.pagination.total : 0);
          setpage(res.data.pagination ? res.data.pagination.currentPage : 1);
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });

    if (
      demographic != 0 &&
      // age != 0 &&
      country != 0 &&
      topic != 0 &&
      pubMonth != 0 &&
      pubYear != 0 &&
      keyword != ""
    ) {
      let NewUrl = `${API}/admin/intelligence/Sentiment?`;
      if (country != 0) NewUrl += `&country=${country}`;
      if (brand != 0) NewUrl += `&brand=${brand}`;
      if (demographic != 0) NewUrl += `&demographics=${demographic}`;
      if (industry != 0) NewUrl += `&industry=${industry}`;
      if (age != 0) NewUrl += `&ageGroup=${age}`;
      if (topic != 0) NewUrl += `&topic=${topic}`;
      if (pubYear != 0) NewUrl += `&publishYear=${pubYear}`;
      if (pubMonth != 0) NewUrl += `&publishMonth=${pubMonth}`;
      if (keyword !== "") NewUrl += `keyword=${keyword}`;

      //NewUrl += `&page=${page}`;
      axios
        .get(NewUrl, {
          headers: { Authorization: `Bearer ${access_token}` },
        })
        .then((res, err) => {
          setLoading(false);
          if (Object.keys(res.data).length !== 0) {
            /// change condition later when res.data comes as empty array
            setSentimentScore(res.data.score);
            setIsUpdate(true);
          } else {
            setIsUpdate(false);
            setSentimentScore(0);
          }
        })
        .catch(function (error) {
          if (error) {
          }
        });
    }
  };

  useEffect(() => {
    const delayTime = setTimeout(() => {
      refresh();
    }, 1000);
    return () => clearTimeout(delayTime);
  }, [
    demographic,
    brand,
    age,
    industry,
    pubMonth,
    pubYear,
    country,
    topic,
    page,
    keyword,
  ]);

  useEffect(() => {
    setpage(1);
  }, [
    demographic,
    brand,
    age,
    industry,
    pubMonth,
    pubYear,
    country,
    topic,
    keyword,
  ]);

  const DeleteSentiment = (_id) => {
    axios
      .delete(`${API}/admin/intelligence/sentiment/${_id}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        setLoading(false);
        if (err) {
        } else {
          refresh();
          setErrSucMesg(res.data.message);
          setErrSucType(true);
        }
      })
      .catch(function (error) {
        if (error) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
          refresh();
        }
      });
  };

  const getBrands = () => {
    if (industry === 0) return;
    setBrandList([]);
    setSpinner(true);

    axios
      .get(`${API}/admin/brands?industry=${industry}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setBrandList(res.data);
          setSpinner(false);
        }
        if (err) {
          setSpinner(false);
        }
      })
      .catch(function (error) {
        if (error) {
          setSpinner(false);
        }
      });
  };
  useEffect(() => {
    getBrands();
  }, [industry]);

  useEffect(() => {
    if (DATA) {
      let td = [];
      let otherArr = {};
      DATA.forEach((e) => {
        let obj = {};
        otherArr = {
          ageGroup: e.ageGroup ? e.ageGroup._id : 0,
          demographics: e.demographics ? e.demographics._id : 0,
          brand: e.brand ? e.brand._id : 0,
          industry: e.industry ? e.industry._id : 0,
          score: e.score ? e.score : 0,
          publishYear: e.publishYear ? e.publishYear : 0,
          publishMonth: e.publishMonth ? e.publishMonth : 0,
          topic: e.topic ? e.topic._id : 0,
          country: e.country ? e.country._id : 0,
          _id: e._id,
        };
        obj = {
          country: e.country ? e.country.name : "NA",
          demographics: e.demographics ? e.demographics.name : "NA",
          topic: e.topic ? e.topic.name : "NA",
          publishYear: e ? e.publishYear : "NA",
          publishMonth: e ? e.publishMonth : "NA",
          score: e ? e.score : "NA",
        };
        let KeysToShow = Object.values(obj);

        td.push({
          data: KeysToShow,
          _id: e._id,
          otherData: otherArr,
        });
      });
      setTableData(td);
    }
  }, [DATA]);

  ////sentiment score validation///////
  useEffect(() => {
    if (sentimentScore > 1) {
      setSentimentScore(1);
    }
    if (sentimentScore < -1) {
      setSentimentScore(-1);
    }
  }, [sentimentScore]);

  const DuplicateQuestion = (row) => {
    let duplicateData = { ...row.otherData };
    delete duplicateData._id;
    axios
      .post(`${API}/admin/intelligence/addSentiment`, duplicateData, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setErrSucMesg(res.data.message);
          setErrSucType(true);
          refresh();
        }
      })
      .catch(function (error) {
        if (error) {
          refresh();
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
        }
      });
  };

  const EditSentiment = () => {
    return (
      <Box className={classes.decaCardBox}>
        <Card
          style={{
            backgroundImage: "linear-gradient(#4911a2,#9211a2)",
            color: "white",
            // height: "400px",
            overflow: "auto",
          }}
        >
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <label>Demographic</label>
                <FormControl
                  fullWidth
                  component="fieldset"
                  variant="outlined"
                  className={[classes.selectControl, classes.customfield].join(
                    " "
                  )}
                  style={{ marginTop: "10px" }}
                >
                  <TextField
                    id="outlined-select-currency-native"
                    select
                    // label="Demographic"
                    value={editData.demographics}
                    onChange={(e) => {
                      let obj = { ...editData };
                      obj.demographics = e.target.value;
                      setEditData(obj);
                    }}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  >
                    <option key={0} value={0}>
                      Select...
                    </option>
                    {dropdownData?.demographics?.map((option) => (
                      <option key={option._id} value={option._id}>
                        {option.name}
                      </option>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <label>Age Group</label>

                <FormControl
                  fullWidth
                  component="fieldset"
                  variant="outlined"
                  className={[classes.selectControl, classes.customfield].join(
                    " "
                  )}
                  style={{ marginTop: "10px" }}
                >
                  <TextField
                    id="outlined-select-currency-native"
                    select
                    // label="Age Group"
                    value={editData.ageGroup}
                    onChange={(e) => {
                      let obj = { ...editData };
                      obj.ageGroup = e.target.value;
                      setEditData(obj);
                    }}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  >
                    <option key={0} value={0}>
                      Select...
                    </option>
                    {dropdownData?.ageGroups?.map((option) => (
                      <option key={option._id} value={option._id}>
                        {option.name}
                      </option>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <label>Topic</label>
                <FormControl
                  fullWidth
                  component="fieldset"
                  variant="outlined"
                  className={[classes.selectControl, classes.customfield].join(
                    " "
                  )}
                  style={{ marginTop: "10px" }}
                >
                  <TextField
                    id="outlined-select-currency-native"
                    select
                    // label="Topic"
                    value={editData.topic}
                    onChange={(e) => {
                      let obj = { ...editData };
                      obj.topic = e.target.value;
                      setEditData(obj);
                    }}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  >
                    <option key={0} value={0}>
                      Select...
                    </option>
                    {dropdownData?.topics?.map((option) => (
                      <option key={option._id} value={option._id}>
                        {option.name}
                      </option>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <label>Country</label>
                <FormControl
                  fullWidth
                  component="fieldset"
                  variant="outlined"
                  className={[classes.selectControl, classes.customfield].join(
                    " "
                  )}
                  style={{ marginTop: "10px" }}
                >
                  <TextField
                    id="outlined-select-currency-native"
                    select
                    // label="Country"
                    value={editData.country}
                    onChange={(e) => {
                      let obj = { ...editData };
                      obj.country = e.target.value;
                      setEditData(obj);
                    }}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  >
                    <option key={0} value={0}>
                      Select...
                    </option>
                    {dropdownData?.countries?.map((option) => (
                      <option key={option._id} value={option._id}>
                        {option.name}
                      </option>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <label>Industry</label>
                <FormControl
                  fullWidth
                  component="fieldset"
                  variant="outlined"
                  className={[classes.selectControl, classes.customfield].join(
                    " "
                  )}
                  style={{ marginTop: "10px" }}
                >
                  <TextField
                    id="outlined-select-currency-native"
                    select
                    // label="Industry"
                    value={editData.industry}
                    onChange={(e) => {
                      let obj = { ...editData };
                      obj.industry = e.target.value;
                      setEditData(obj);
                    }}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  >
                    <option key={0} value={0}>
                      Select...
                    </option>
                    {dropdownData?.industries?.map((option) => (
                      <option key={option._id} value={option._id}>
                        {option.name}
                      </option>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <label>Brand</label>
                <FormControl
                  fullWidth
                  component="fieldset"
                  variant="outlined"
                  className={[classes.selectControl, classes.customfield].join(
                    " "
                  )}
                  style={{ marginTop: "10px" }}
                >
                  <TextField
                    id="outlined-select-currency-native"
                    select
                    // label="Brand"
                    value={editData.brand}
                    onChange={(e) => {
                      let obj = { ...editData };
                      obj.brand = e.target.value;
                      setEditData(obj);
                    }}
                    SelectProps={{
                      native: true,
                    }}
                    InputProps={
                      Spinner
                        ? {
                            startAdornment: (
                              <InputAdornment position="start">
                                <CircularProgress color="disabled" size={10} />
                              </InputAdornment>
                            ),
                          }
                        : {}
                    }
                    variant="outlined"
                  >
                    <option key={0} value={0}>
                      Select...
                    </option>
                    {BrandList &&
                      BrandList?.map((option) => (
                        <option key={option._id} value={option._id}>
                          {option.name}
                        </option>
                      ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <label>Year Published</label>
                <FormControl
                  fullWidth
                  component="fieldset"
                  variant="outlined"
                  className={[classes.selectControl, classes.customfield].join(
                    " "
                  )}
                  style={{ marginTop: "10px" }}
                >
                  <TextField
                    id="outlined-select-currency-native"
                    select
                    // label="Year Published"
                    value={editData.publishYear}
                    onChange={(e) => {
                      let obj = { ...editData };
                      obj.publishYear = e.target.value;
                      setEditData(obj);
                    }}
                    variant="outlined"
                  >
                    <option key={0} value={0}>
                      Select...
                    </option>
                    {dropdownData?.publishYears?.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <label>Month Published</label>
                <FormControl
                  fullWidth
                  component="fieldset"
                  variant="outlined"
                  className={[classes.selectControl, classes.customfield].join(
                    " "
                  )}
                  style={{ marginTop: "10px" }}
                >
                  <TextField
                    id="outlined-select-currency-native"
                    select
                    // label="Month Published"
                    value={editData.publishMonth}
                    onChange={(e) => {
                      let obj = { ...editData };
                      obj.publishMonth = e.target.value;
                      setEditData(obj);
                    }}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  >
                    <option key={0} value={0} disabled={true}>
                      Select...
                    </option>
                    {[
                      { name: "january", val: 1 },
                      { name: "february", val: 2 },
                      { name: "march", val: 3 },
                      { name: "april", val: 4 },
                      { name: "may", val: 5 },
                      { name: "june", val: 6 },
                      { name: "july", val: 7 },
                      { name: "august", val: 8 },
                      { name: "september", val: 9 },
                      { name: "october", val: 10 },
                      { name: "november", val: 11 },
                      { name: "december", val: 12 },
                    ].map((option) => (
                      <option key={option.val} value={option.val}>
                        {option.name}
                      </option>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <label>Sentiment Score</label>
                <FormControl
                  fullWidth
                  component="fieldset"
                  variant="outlined"
                  className={[classes.selectControl, classes.customfield].join(
                    " "
                  )}
                  style={{ marginTop: "10px" }}
                >
                  <TextField
                    type="number"
                    id="outlined-select-currency-native"
                    // label="Sentiment Score"
                    value={editData.score}
                    onChange={(e) => {
                      let obj = { ...editData };
                      obj.score = e.target.value;
                      setEditData(obj);
                    }}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "10px",
              }}
            >
              <Button
                className={classes.addActionBtn}
                style={{
                  width: "80px",
                  height: "40px",
                }}
                variant="outlined"
                onClick={() => updateSentiment()}
              >
                Update
              </Button>
              <Button
                className={classes.addActionBtn}
                style={{
                  width: "80px",
                  height: "40px",
                  marginLeft: "10px",
                }}
                variant="outlined"
                onClick={() => setEditToggle(false)}
              >
                Cancel
              </Button>
            </div>
          </CardContent>
        </Card>
      </Box>
    );
  };

  const updateSentiment = () => {
    axios
      .put(`${API}/admin/intelligence/updateSentiment`, editData, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res && res.data) {
          refresh();
          setErrSucMesg(res.data.message);
          setErrSucType(true);
          setEditToggle(false);
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.data) {
          refresh();
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
          setEditToggle(false);
        }
      });
  };

  return (
    <div className={classes.boxShadow}>
      <Dialog open={duplicateToggle}>
        <DialogTitle style={{ justifyContent: "center", display: "flex" }}>
          Duplicate Sentiment
        </DialogTitle>
        <p style={{ paddingRight: "10px", paddingLeft: "10px" }}>
          Are you sure, you want to add duplicate sentiment?
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginBottom: "10px",
          }}
        >
          <Button
            className={classes.addActionBtn}
            style={{
              width: "80px",
              height: "40px",
              backgroundColor: "white",
              color: "black",
            }}
            variant="outlined"
            onClick={() => {
              setDuplicateToggle(false);
            }}
          >
            Cancel
          </Button>
          <Button
            className={classes.addActionBtn}
            style={{
              width: "80px",
              height: "40px",
              backgroundColor: "#F44C3C",
            }}
            variant="outlined"
            onClick={() => {
              setDuplicateToggle(false);
              DuplicateQuestion(duplicateRecord);
            }}
          >
            Duplicate
          </Button>
        </div>
      </Dialog>

      <div className={classes.paper}>
        <NewAlert
          msg={ErrSucMsg}
          isSuccess={ErrSucType}
          close={() => setErrSucMesg("")}
        />
        <form
          className={classes.form}
          // ref={useRef()  }
          //onSubmit={handleSubmit(onSubmit)}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              fontSize: "10px",
            }}
          >
            <img className={classes.logo} src={sentmentLogo} />
            <div style={{ width: "30%", margin: "4%" }}>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
                style={{ marginTop: "10px" }}
              >
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Demographic"
                  value={demographic}
                  onChange={(e) => setDemographic(e.target.value)}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {dropdownData?.demographics?.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </FormControl>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
                style={{ marginTop: "10px" }}
              >
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Age Group"
                  value={age}
                  onChange={(e) => setAge(e.target.value)}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {dropdownData?.ageGroups?.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </FormControl>

              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
                style={{ marginTop: "10px" }}
              >
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Topic"
                  value={topic}
                  onChange={(e) => setTopic(e.target.value)}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {dropdownData?.topics?.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </FormControl>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
                style={{ marginTop: "10px" }}
              >
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Country"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {dropdownData?.countries?.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </FormControl>
            </div>
            <div style={{ width: "30%", margin: "4%" }}>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
                style={{ marginTop: "10px" }}
              >
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Industry"
                  value={industry}
                  onChange={(e) => setIndustry(e.target.value)}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {dropdownData?.industries?.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </FormControl>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
                style={{ marginTop: "10px" }}
              >
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Brand"
                  value={brand}
                  onChange={(e) => setBrand(e.target.value)}
                  SelectProps={{
                    native: true,
                  }}
                  InputProps={
                    Spinner
                      ? {
                          startAdornment: (
                            <InputAdornment position="start">
                              <CircularProgress color="disabled" size={10} />
                            </InputAdornment>
                          ),
                        }
                      : {}
                  }
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {BrandList.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </FormControl>

              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
                style={{ marginTop: "10px" }}
              >
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Year Published"
                  value={pubYear}
                  onChange={(e) => setPubYear(e.target.value)}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {dropdownData?.publishYears?.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </TextField>
              </FormControl>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
                style={{ marginTop: "10px" }}
              >
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Month Published"
                  value={pubMonth}
                  onChange={(e) => setPubMonth(e.target.value)}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0} disabled={true}>
                    Select...
                  </option>
                  {[
                    { name: "january", val: 1 },
                    { name: "february", val: 2 },
                    { name: "march", val: 3 },
                    { name: "april", val: 4 },
                    { name: "may", val: 5 },
                    { name: "june", val: 6 },
                    { name: "july", val: 7 },
                    { name: "august", val: 8 },
                    { name: "september", val: 9 },
                    { name: "october", val: 10 },
                    { name: "november", val: 11 },
                    { name: "december", val: 12 },
                  ].map((option) => (
                    <option key={option.val} value={option.val}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </FormControl>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
                style={{ marginTop: "10px" }}
              >
                <TextField
                  id="outlined-select-currency-native"
                  fullWidth
                  // label="Name"
                  variant="outlined"
                  placeholder="Search"
                  // style={{ display: "block", marginBottom: "10px" }}
                  value={keyword}
                  onChange={(e) => {
                    setKeyword(e.target.value);
                  }}
                />
              </FormControl>
            </div>
            <div style={{ width: "100%" }}>
              {loading ? (
                <CircularProgress />
              ) : (
                <div style={{ width: "100%" }}>
                  <FormControl
                    fullWidth
                    component="fieldset"
                    variant="outlined"
                    className={[
                      classes.selectControl,
                      classes.customfield,
                    ].join(" ")}
                    style={{ marginTop: "10px" }}
                  >
                    <TextField
                      type="number"
                      id="outlined-select-currency-native"
                      label="Sentiment Score"
                      value={sentimentScore}
                      onChange={(e) => setSentimentScore(e.target.value)}
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                    ></TextField>
                    {!loading &&
                      demographic != 0 &&
                      // industry != 0 &&
                      //age != 0 &&
                      // brand != 0 &&
                      country != 0 &&
                      topic != 0 &&
                      pubYear != 0 &&
                      pubMonth != 0 && (
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <FormControl>
                            <Button
                              className={classes.addActionBtn}
                              variant="outlined"
                              style={
                                isUpdate
                                  ? {
                                      backgroundColor: "#E96B43",
                                      margin: "15px",
                                    }
                                  : {
                                      backgroundColor: "#136CC3",
                                      margin: "15px",
                                    }
                              }
                              onClick={() => SendList()}
                            >
                              {isUpdate ? "UPDATE" : "SUBMIT"}
                            </Button>
                          </FormControl>
                        </div>
                      )}
                  </FormControl>
                  <Button
                    className={classes.addActionBtn}
                    variant="outlined"
                    style={{
                      backgroundColor: "#136CC3",
                      marginTop: "10px",
                      marginBottom: "10px",
                      float: "right",
                    }}
                    onClick={() => {
                      history.go(0);
                    }}
                  >
                    REFRESH
                  </Button>
                  {editToggle && EditSentiment()}
                  {tableData.length > 0 && (
                    <MyTable
                      style={{ width: "100%" }}
                      headings={[
                        "Country",
                        "Demographic",
                        "Topic",
                        "Year",
                        "Month",
                        "Score",
                      ]}
                      listdata={tableData}
                      //redirectLink={"MediaOwnerProfile?id="}
                      handleDelete={usertype === "superadmin" ? (_id) => {
                        DeleteSentiment(_id)} : null }
                      isEditable={true}
                      HandleCSV={(row) => {
                        if (row && row.otherData) {
                          setIndustry(row.otherData.industry);
                          editData.ageGroup = row.otherData.ageGroup;
                          editData.demographics = row.otherData.demographics;
                          editData.brand = row.otherData.brand;
                          editData.industry = row.otherData.industry;
                          editData.score = row.otherData.score;
                          editData.publishYear = row.otherData.publishYear;
                          editData.publishMonth = row.otherData.publishMonth;
                          editData.topic = row.otherData.topic;
                          editData.country = row.otherData.country;
                          editData._id = row.otherData._id;
                          setEditData(editData);
                          setEditToggle(true);
                        }
                      }}
                      handleDuplicate={(row) => {
                        setDuplicateToggle(true);
                        setDuplicateRecord(row);
                      }}
                    />
                  )}
                </div>
              )}

              {tableData.length > 0 && (
                <div>
                  <span
                    style={{
                      color: "#FB6D6A",
                      fontWeight: "600",
                      margin: "0",
                      fontSize: "20px",
                      marginRight: "20px",
                    }}
                  >
                    <Pagination
                      count={Math.ceil(pagecount / 10)}
                      color="primary"
                      page={page}
                      onChange={(e, page) => setpage(page)}
                      size="small"
                    />
                  </span>
                </div>
              )}
            </div>
          </div>
        </form>
        <Modal
          isModalOpen={modalOpen}
          redirect="/media-owner/personas"
          message="Your new persona has been saved"
        ></Modal>
      </div>
    </div>
  );
};

export default Sentiment;
