import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import About from "./pages/mediaOwner/about";
import Confirmation from "./pages/mediaOwner/confirmation";
import MediaOwner from "./pages/mediaOwner/dashboard";
import Inventory from "./pages/mediaOwner/inventory";
import InventoryCreate from "./pages/mediaOwner/inventory-create";
import InventorySelect from "./pages/mediaOwner/inventory-select";
import MediaConsultency from "./pages/mediaOwner/mediaConsultency";
import OverviewIndex from "./pages/mediaOwner/overview/index";
import MediaOwnerintelligence from "./pages/mediaOwner/overview/intelligencePage";
import Personas from "./pages/mediaOwner/personaView";
import PersonasAdd from "./pages/mediaOwner/personasAdd";
import MediaOwnerPublication from "./pages/mediaOwner/publications";
import Questionneries from "./pages/mediaOwner/questionneries";
import QuestionneriesStats from "./pages/mediaOwner/questionneriesStats";
import MediaPlannerintelligence from "./pages/mediaPlanner/IntelligenceDashboard/dashboard.component";
import MediaLogin from "./pages/signup/mediaLogin";
// import MediaPlannerintelligence from "./pages/mediaPlanner/IntelligenceDashboard/Dashboard";
import app from "./App";
import Admin from "./pages/admin";
import AdminFaq from "./pages/admin/FOQ";
import AdminUpdateMedia from "./pages/admin/UpdateMediaPage/UpdateMedia";
import AdminCreateInventory from "./pages/admin/createInventory";
import AdminCreateMedia from "./pages/admin/createMedia";
import AdminIntelligence from "./pages/admin/intelligence/index";
import AdminManageInventory from "./pages/admin/manageInventory";
import AdminManageMedia from "./pages/admin/manageMedia";
import AdminsingleMediaView from "./pages/admin/singleMediaView";
import BrandUplift from "./pages/branduplift";
import ForecastBrandUplift from "./pages/branduplift/forecastUplift";
import MediaOwnerFaq from "./pages/mediaOwner/FOQ";
import createPersona from "./pages/mediaOwner/craetePersona";
import MOcreateMedia from "./pages/mediaOwner/createMedia";
import createPersonaTemplate from "./pages/mediaOwner/createPersonaTemplate";
import AdvertiserCampaignReport from "./pages/mediaPlanner/AdvertiserSelectedCampaign/AdvertiserSelectedCampaign";
import MediaPlannerFaq from "./pages/mediaPlanner/FOQ";
import Talkwalker from "./pages/mediaPlanner/Talkwalker";
import MP_About from "./pages/mediaPlanner/about";
import CampaignReport from "./pages/mediaPlanner/campaign/CampaignReport";
import plannerCreatePersona from "./pages/mediaPlanner/createPersona";
import CreativeConsultancy from "./pages/mediaPlanner/creativeConsultancy/creativeConsultancy";
import Forecast from "./pages/mediaPlanner/forecast";
import HeatMap from "./pages/mediaPlanner/heatMap/HeatMap";
import MediaPlanner from "./pages/mediaPlanner/index";
import MP_Inventry from "./pages/mediaPlanner/inventory";
import MP_Personas from "./pages/mediaPlanner/personas";
import MP_PersonasAdd from "./pages/mediaPlanner/personasAdd";
import login from "./pages/signup/mediaLogin";
// import adminCreatePersona from "./pages/admin/craetePersonaNew";
import MOFORM from "./pages/MOform/MOFORM";
import ChannelAssignmentPage from "./pages/admin/ChannelAssignment/ChannelAssignmentPage";
import CsvUploadation from "./pages/admin/CsvUplodation/CsvUploadation";
import GreenAdCampaigns from "./pages/admin/GreenAdCampaigns";
import KeywordsListComponent from "./pages/admin/Keywords";
import BaInfluencer from "./pages/admin/ba-influencer";
import influencer_campaign from "./pages/admin/ba-influencer/influencer_campaign";
import ChatGPT from "./pages/admin/chatGPT/chatGPT";
import Company from "./pages/admin/company";
import ConsultantSurveys from "./pages/admin/consultants/consultantSurveys";
import Consultants from "./pages/admin/consultants/consultants";
import CreateConsultant from "./pages/admin/consultants/createConsultant";
import PreviewSurveys from "./pages/admin/consultants/previewSurvey";
import adminCreatePersona from "./pages/admin/craetePersona";
import AdminCreateCampaign from "./pages/admin/createCamapaignNew";
import CreateCompany from "./pages/admin/createCompany";
import CreateDecaReport from "./pages/admin/createDecaReport";
import adminCreatePersonaTemplate from "./pages/admin/createPersonaTemplate";
import DecaReport from "./pages/admin/decaReport";
import dropDowns from "./pages/admin/dropDowns";
import Industries from "./pages/admin/industries";
import IndustryProfile from "./pages/admin/industryProfile";
import InteractiveMapUpdate from "./pages/admin/interactiveMapUpdate";
import ManageCampaign from "./pages/admin/manageCampaign";
import mediaOwnerProfile from "./pages/admin/mediaOwnerProfile";
import mediaPlannerProfile from "./pages/admin/mediaPlannerProfile";
import Payback from "./pages/admin/payBack";
import adminPersonas from "./pages/admin/personaView";
import ShowMediaOwners from "./pages/admin/showMediaOnwers";
import ShowMediaPlanners from "./pages/admin/showMediaPlanner";
import ShowSubAdmin from "./pages/admin/showSubAdmin";
import AdminSingleMediaView from "./pages/admin/singleMediaView";
import SubAdminProfile from "./pages/admin/subAdminProfile";
import CompanyFaq from "./pages/company/FOQ";
import Brand from "./pages/company/brand";
import CompanyCreatePersona from "./pages/company/craetePersona";
import CreateBrand from "./pages/company/createBrand";
import CompanyCreateCampaign from "./pages/company/createCamapaign";
import CompanyCreateInventory from "./pages/company/createInventory";
import CompanyCreateMedia from "./pages/company/createMedia";
import CompanyCreatePersonaTemplate from "./pages/company/createPersonaTemplate";
import CompanyDropDowns from "./pages/company/dropDowns";
import CompanyManageCampaign from "./pages/company/manageCampaign";
import CompanyManageInventory from "./pages/company/manageInventory";
import CompanyManageMedia from "./pages/company/manageMedia";
import CompanyPersonas from "./pages/company/personaView";
import Accounts from "./pages/consultant/accounts";
import CompletedSurveys from "./pages/consultant/completedSurveys";
import PendingSurveys from "./pages/consultant/pendingSurveys";
import ConsultantProfile from "./pages/consultant/profile";
import SurveyQuestions from "./pages/consultant/surveyQuestions";
import MoInvoice from "./pages/mediaOwner/MoComponents/pdfComp";
import Reports from "./pages/mediaOwner/Reports";
import createInventory from "./pages/mediaOwner/createInventory";
import singleMediaView from "./pages/mediaOwner/singleMediaView";
import Influencer from "./pages/mediaPlanner/Influencer";
import influencer_campaignMP from "./pages/mediaPlanner/Influencer/influencer_campaign";
import AddZone from "./pages/mediaPlanner/campaign/AddZone/AddZone";
import CreateCampaign from "./pages/mediaPlanner/createCampaign";
import plannerCreatePersonaTemplate from "./pages/mediaPlanner/createPersonaTemplate";
import Campaign from "./pages/mediaPlanner/indexcampaign";
import plannerPersonas from "./pages/mediaPlanner/personaView";
import ResetPassword from "./pages/mediaPlanner/resetPassword";
// import ChatGptQuestionBuilder from "./pages/admin/ChatGptQuestionBuilder/ChatGptQuestionBuilder";

export default () => (
  <Switch>
    <Route path="/" exact={true} component={app} />
    <Route path="/login" exact={true} component={login} />
    <Route path="/MOQform" exact={true} component={MOFORM} />
    <Route path="/brandUplift" exact component={BrandUplift} />
    <Route path="/brandUplift/forecast" exact component={ForecastBrandUplift} />

    <Route path="/media-planner" exact component={MediaPlanner} />
    <Route path="/media-planner/about" exact component={MP_About} />
    {/* <Route path="/media-planner/personas" exact component={MP_Personas} />
    <Route
      path="/media-planner/personas/create"
      exact
      component={MP_PersonasAdd}
    /> */}
    <Route path="/media-planner/campaign" exact component={Campaign} />
    <Route path="/media-planner/forecast" exact component={Forecast} />
    <Route path="/media-planner/inventory" exact component={MP_Inventry} />
    <Route path="/media-planner/FAQ" exact component={MediaPlannerFaq} />
    <Route path="/media-planner/creative-consultancy" exact component={CreativeConsultancy} />
    <Route path="/media-planner/influencer" exact component={Influencer} />
    <Route path="/media-planner/influencer/:campaign_id" exact component={influencer_campaignMP} />
    <Route path="/media-planner/talkwalker" exact component={Talkwalker} />

    <Route path="/admin" exact component={Admin} />
    <Route path="/admin/mediaOwners" exact component={ShowMediaOwners} />
    <Route path="/admin/MediaOwnerProfile" component={mediaOwnerProfile} />
    <Route path="/admin/subAdmin" exact component={ShowSubAdmin} />
    <Route path="/admin/subAdminProfile" component={SubAdminProfile} />
    <Route path="/admin/mediaPlanners" exact component={ShowMediaPlanners} />
    <Route path="/admin/Passback" exact component={Payback} />
    <Route path="/admin/Industries" exact component={Industries} />
    <Route path="/admin/IndustryProfile" component={IndustryProfile} />
    <Route path="/admin/dropDowns" exact component={dropDowns} />
    <Route path="/admin/MediaPlannerProfile" component={mediaPlannerProfile} />
    <Route path="/admin/intelligence" exact component={AdminIntelligence} />
    <Route path="/admin/FAQ" exact component={AdminFaq} />
    <Route path="/admin/ba-influencer" exact component={BaInfluencer} />
    <Route path="/admin/ba-influencer/:id" exact component={influencer_campaign} />
    <Route path="/admin/report" exact component={DecaReport} />
    <Route path="/admin/csv-uploadation" exact component={CsvUploadation} />
    <Route path="/admin/green-ad-campaigns" exact component={GreenAdCampaigns} />
    <Route path="/admin/keywords" exact component={KeywordsListComponent} />

    <Route path="/media-owner" exact component={MediaOwner} />
    <Route path="/media-owner/Invoice" exact component={MoInvoice} />
    <Route
      path="/media-owner/publications"
      exact
      component={MediaOwnerPublication}
    />
    <Route path="/media-owner/createMedia" exact component={MOcreateMedia} />
    <Route path="/admin/createMedia" exact component={AdminCreateMedia} />
    <Route path="/admin/updateMedia/:media_id/:creator_id" exact component={AdminUpdateMedia} />
    <Route path="/admin/manageCampaign" exact component={ManageCampaign} />
    <Route path="/admin/createCampaign" exact component={AdminCreateCampaign} />
    <Route path="/admin/editCampaign" exact component={AdminCreateCampaign} />
    <Route
      path="/admin/singleMediaView"
      exact
      component={AdminsingleMediaView}
    />
    <Route path="/admin/manageMedia" exact component={AdminManageMedia} />
    <Route
      path="/admin/createInventory"
      exact
      component={AdminCreateInventory}
    />
    <Route
      path="/admin/manageInventory"
      exact
      component={AdminManageInventory}
    />
    <Route
      path="/admin/company"
      exact
      component={Company}
    />
    <Route
      path="/admin/createcompany"
      exact
      component={CreateCompany}
    />
    <Route
      path="/admin/createReport"
      exact
      component={CreateDecaReport}
    />
    <Route
      path="/media-planner/createCampaign"
      exact
      component={CreateCampaign}
    />

    <Route path="/admin/consultants" exact component={Consultants} />
    <Route path="/admin/createConsultant" exact component={CreateConsultant} />
    
    <Route path="/admin/chatGPT" exact component={ChatGPT} />

    <Route path="/media-owner/about" exact component={About} />
    <Route path="/media-owner/personas" exact component={Personas} />
    <Route path="/media-planner/personas" exact component={plannerPersonas} />
    <Route path="/media-planner/campaign/report" exact component={CampaignReport}/>
    <Route path="/media-planner/campaign/advertiser/:advertiserId/:campaignId/:campaignType/:campaignName/:advertiser_name" exact component={AdvertiserCampaignReport}/>
    <Route path="/media-planner/campaign/advertiser/channel-assignment/:selectedChannelName/:selectedChannelId/:advertiserId/:campaignId/:campaignType/:campaignName/:advertiser_name" exact component={ChannelAssignmentPage}/>
    <Route path="/media-planner/heatMap" component={HeatMap}/>
    <Route path="/admin/personas" exact component={adminPersonas} />
    <Route path="/media-owner/createPersona" exact component={createPersona} />
    <Route
      path="/media-planner/createPersona"
      exact
      component={plannerCreatePersona}
    />
    <Route path="/admin/createPersona" exact component={adminCreatePersona} />

    <Route
      path="/media-owner/createPersonaTemplate"
      exact
      component={createPersonaTemplate}
    />
    <Route
      path="/media-planner/createPersonaTemplate"
      exact
      component={plannerCreatePersonaTemplate}
    />
    <Route
      path="/admin/createPersonaTemplate"
      exact
      component={adminCreatePersonaTemplate}
    />
    <Route path="/media-owner/personas/create" exact component={PersonasAdd} />
    <Route path="/media-owner/inventory" exact component={Inventory} />
    <Route
      path="/media-owner/inventory-create"
      exact
      component={InventoryCreate}
    />
    <Route
      path="/media-owner/inventory-select"
      exact
      component={InventorySelect}
    />
    <Route path="/media-owner/confirmation" exact component={Confirmation} />
    <Route path="/media-owner/overview" exact component={OverviewIndex} />
    <Route path="/media-owner/question" exact component={Questionneries} />
    <Route
      path="/media-owner/question-stats"
      exact
      component={QuestionneriesStats}
    />

    <Route path="/media-owner/FAQ" exact component={MediaOwnerFaq} />
    <Route path="/media-owner/reports" exact component={Reports} />
    <Route path="/media-owner/consultency" exact component={MediaConsultency} />
    <Route
      path="/media-owner/intelligence"
      exact
      component={MediaOwnerintelligence}
    />
    <Route
      path="/media-planner/intelligence"
      component={MediaPlannerintelligence}
    />
    <Route path="/media-owner/singleMediaView" component={singleMediaView} />
    <Route path="/admin/singleMediaView" component={AdminSingleMediaView} />

    <Route path="/media-owner/CreateInventory" component={createInventory} />

    <Route path="/company/FAQ" exact component={CompanyFaq} />
    <Route path="/company/manageCampaign" exact component={CompanyManageCampaign} />
    <Route path="/company/createCampaign" exact component={CompanyCreateCampaign} />
    <Route
      path="/company/createInventory"
      exact
      component={CompanyCreateInventory}
    />
    <Route
      path="/company/manageInventory"
      exact
      component={CompanyManageInventory}
    />
       <Route path="/company/createMedia" exact component={CompanyCreateMedia} />
    <Route path="/company/manageMedia" exact component={CompanyManageMedia} />
    <Route path="/company/dropDowns" exact component={CompanyDropDowns} />
    <Route path="/company/personas" exact component={CompanyPersonas} />
    <Route
      path="/company/createPersonaTemplate"
      exact
      component={CompanyCreatePersonaTemplate}
    />
    <Route path="/company/createPersona" exact component={CompanyCreatePersona} />
    <Route
      path="/company/Brands"
      exact
      component={Brand}
    />
    <Route
      path="/company/createBrand"
      exact
      component={CreateBrand}
    />
    <Route path="/consultant/profile" exact component={ConsultantProfile} />
    <Route path="/consultant/pending-surveys" exact component={PendingSurveys} />
    <Route path="/consultant/completed-surveys" exact component={CompletedSurveys} />
    <Route path="/consultant/pending-survey" exact component={SurveyQuestions} />
    <Route path="/consultant/completed-survey" exact component={SurveyQuestions} />
    <Route path="/consultant/accounts" exact component={Accounts} />

    <Route path="/admin/survey" exact component={PreviewSurveys} />
    <Route path="/admin/consultant-surveys" exact component={ConsultantSurveys} />
    <Route path="/admin/interactive-map-update" exact component={InteractiveMapUpdate} />

    <Route path="/media-planner/forgotpassword" component={ResetPassword}/>
    <Route path="/media-planner/addZone" component={AddZone}/>
    {/* <Route
        path="/media-owner/1in5Questionnaire"
        exact
        component={Q1in5View}
      /> */}
  </Switch>
);
