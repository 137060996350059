import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "@material-ui/core/Select";
import { fade, makeStyles, withTheme } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import personaImg from "../../assets/user.png";
import MainLayout from "../../layouts/companyMainLayout";

import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";

//Button
import Button from "@material-ui/core/Button";

//Icon

import InputAdornment from "@material-ui/core/InputAdornment";
import AssignmentIcon from "@material-ui/icons/Assignment";
import DateRangeIcon from "@material-ui/icons/DateRange";

//TextField
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useStyles from "../../components/StyleClasses";
import UploadImage from "../../components/UploadImage";
import { API, SECRET_KEY } from "../../constants/index";
import StepWrapper from "../mediaOwner/stepWrapper";
import Step2 from "./step2";

import { file } from "@babel/types";
import { enGB } from "date-fns/locale";
import { DateRangePicker, END_DATE, START_DATE } from "react-nice-dates";
import "react-nice-dates/build/style.css";

import ShowAlert from "../../components/alerts";
import * as actions from "../../redux/actions/user";

import { KeysToSend } from "../../constants/Inventories";

function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

function unCamelCase(str) {
  return (
    str
      // insert a space between lower & upper
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      // space before last upper in a sequence followed by lower
      .replace(/\b([A-Z]+)([A-Z])([a-z])/, "$1 $2$3")
      // uppercase the first character
      .replace(/^./, function (str) {
        return str.toUpperCase();
      })
  );
}

function paramsToObject(entries) {
  const result = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}

const CreateMedia = () => {
  const history = useHistory();
  const urlparams = new URLSearchParams(history.location.search);
  const paramEntries = urlparams.entries();
  const params = paramsToObject(paramEntries);
  const demographics = useSelector((state) => state.dropdownData?.demographics);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [Step, setStep] = useState(0);
  const [Avtar, setAvtar] = useState(null);
  const [AvtarFile, setAvtarFile] = useState(null);
  const [InventoryAvtar, setInventoryAvtar] = useState(null);
  const [InventoryAvtarFile, setInventoryAvtarFile] = useState(null);
  const [channel, setChannel] = useState(null);
  const [frequency, setFrequency] = useState(0);
  const [name, setName] = useState("");
  const [description, setdescription] = useState("");
  const [selectedPersonas, setSelectedPersonas] = useState([]);
  const [audienceQues, setAudeinceQues] = useState([]);
  const [audienceAns, setAudeinceAns] = useState([]);
  const [unitPrice, setUnitPrice] = useState("");
  const [AdType, setAdType] = useState(0);
  const [Iname, setIname] = useState("");
  const [Idesc, setIdesc] = useState("");
  const [Ifreq, setIfreq] = useState(0);
  const [frequencyCount, setfrequencyCount] = useState("");

  const [ChanAdTypeData, setChanAdTypeData] = useState([]);
  const [IndustriesList, setIndustriesList] = useState([]);
  const [country, setCountry] = useState([]);
  const [renderCountry, setrenderCountry] = useState([]);
  const [Industry, setIndustry] = useState(0);
  const [CountriesList, setCountriesList] = useState([]);
  const [channelName, setChannelName] = useState("");
  const [adTypeList, setAdTypeList] = useState([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [ownerPlannerID, setOwnerPlannerID] = useState(null);
  const [mediaOwnerList, setMediaOwnerList] = useState([]);

  /////new flow//////////
  const [NewBody, setNewBody] = useState(null);
  const [NewQues, setNewQues] = useState([]);
  const [NewAns, setNewAns] = useState([]);
  const [NewChanUri, setNewChanUri] = useState("");
  const [NewTypes, setNewTypes] = useState([]);
  ///////////////////////
  const [processing, setprocessing] = useState("");

  const [errors, setErrors] = useState({
    ID: { error: false, txt: "" },
    channel: { error: false, txt: "" },
    country: { error: false, txt: "" },
    Industry: { error: false, txt: "" },
    name: { error: false, txt: "" },
    frequency: { error: false, txt: "" },
    description: { error: false, txt: "" },
    cover: { error: false, txt: "" },
    Icover: { error: false, txt: "" },
    Iname: { error: false, txt: "" },
    Idescription: { error: false, txt: "" },
    Ifrequency: { error: false, txt: "" },
    AdType: { error: false, txt: "" },
    unitPrice: { error: false, txt: "" },
    frequencyCount: { error: false, txt: "" },
    questions: [],
    dates: { error: false, txt: "" },
    NewInvErrors: [],
  });

  const access_token = window.localStorage.getItem(SECRET_KEY);
  //const creator = window.localStorage.getItem("ID");
  useEffect(() => {
    if (CountriesList) {
      let a = CountriesList.filter((o) => country.includes(o._id));
      let b = a.map((e) => e.name + " , ");
      if (a.length <= 3) setrenderCountry(b);
    }
  }, [country]);
  useEffect(
    () =>
      setErrors({
        ...errors,
        cover: { error: false, txt: "" },
      }),
    [Avtar]
  );
  useEffect(
    () =>
      setErrors({
        ...errors,
        Icover: { error: false, txt: "" },
      }),
    [InventoryAvtar]
  );
  useEffect(
    () =>
      setErrors({
        ...errors,
        dates: { error: false, txt: "" },
      }),
    [endDate]
  );

  useEffect(() => {
    ChanAdTypeData.forEach((element) => {
      if (element._id === channel) {
        setAudeinceQues(element.questions);
        let ansarr = [];
        element.questions.forEach((e) => ansarr.push(""));
        let anserr = [];
        element.questions.forEach((e) =>
          anserr.push({ error: false, txt: "" })
        );
        setErrors({
          ...errors,
          questions: [...anserr],
        });
        setAudeinceAns(ansarr);
        setChannelName(element.channelName);
        setAdTypeList(element.AdTypes);
      }
    });
  }, [channel]);

  useEffect(() => {
    if (channelName.includes("igital")) {
      setNewBody(KeysToSend.Digital);
      setNewChanUri("digital");
    }
    if (channelName === "Print") {
      setNewBody(KeysToSend.Print);
      setNewChanUri("print");
    }
    if (channelName === "Radio") {
      setNewBody(KeysToSend.Radio);
      setNewChanUri("Radio");
    }
    if (channelName === "OOH") {
      setNewBody(KeysToSend.OOH);
      setNewChanUri("ooh");
    }
    if (channelName === "Television") {
      setNewBody(KeysToSend.Television);
      setNewChanUri("television");
    }
  }, [channelName]);

  useEffect(() => {
    if (NewBody == null) return;
    let typearr = Object.values(NewBody);
    typearr.splice(0, 5);
    let tr = typearr.map((e) => (typeof e === "number" ? "number" : "text"));
    setNewTypes(tr);

    let erarr = [];
    tr.forEach((e) => erarr.push(false));
    setErrors({
      ...errors,
      NewInvErrors: [...erarr],
    });

    let qarr = Object.keys(NewBody);
    qarr.splice(0, 5);
    let Qr = qarr.map((e) => unCamelCase(e));
    setNewQues(Qr);
    let aarr = [];
    qarr.forEach((e) => aarr.push(""));
    setNewAns(aarr);
  }, [NewBody]);

  const SaveInventory = (mediaID) => {
    setprocessing("Registering Inventory...");
    let que = Object.keys(NewBody);
    que.splice(0, 5);
    let FinalizedArray = [];
    que.forEach((e, i) => FinalizedArray.push([e, NewAns[i]]));
    let obj = Object.fromEntries(FinalizedArray);
    obj.startDate = new Date(convert(startDate)).getTime();
    obj.endDate = new Date(convert(endDate)).getTime();
    //obj.mediaType = AdType;
    obj.media = mediaID;
    obj.creator = params.creator;
    obj.name = Iname;

    axios
      .post(`${API}/admin/mediaInventory/${NewChanUri}`, obj, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res, err) => {
        if (res) {
          history.push(`/admin/manageMedia?owner=${params.creator}`);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };

  const save = () => {
    setprocessing("Creating Media...");
    const formData = new FormData();
    formData.append("MediaLogos", AvtarFile);
    //formData.append("inventoryImage", InventoryAvtarFile);
    //formData.append("industry", Industry);
    country.forEach((e, i) => {
      formData.append(`countries[]`, e);
    });

    formData.append("name", name);
    formData.append("channel", channel);
    formData.append("frequency", frequency);
    formData.append("description", description);
    formData.append("creator", params.creator);
    formData.append("frequencyCount", 999); //remove it later when udated on backend
    audienceQues.forEach((e, i) => {
      formData.append(`audience[${i}][qstn]`, e);
      formData.append(`audience[${i}][ans]`, audienceAns[i]);
    });

    const stringPersomnas = selectedPersonas.map((e) => e._id);
    selectedPersonas.forEach((per, i) => {
      formData.append(`personas[]`, stringPersomnas[i]);
    });
    for (var pair of formData.entries()) {
      console.dir(pair[0] + ", " + pair[1]);
    }
    axios
      .post(`${API}/admin/mediaPublication`, formData, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res, err) => {
        if (res) {
          SaveInventory(res.data.mediaId);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };

  const getAdTypes = () => {
    axios
      .get(`${API}/admin/mediaPublication/getMediaChannelsWithQuestions`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res, err) => {
        if (res) {
          setChanAdTypeData(res.data.channelsWithQuestions);
          setCountriesList(res.data.countries);
          setIndustriesList(res.data.industries);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };
  useEffect(() => {
    getAdTypes();
  }, []);

  const Step1 = () => {
    return (
      <div>
        <div
          className={classes.customfield}
          style={{
            padding: "16px",
            boxShadow:
              "0px 0px 10px rgba(0,0,0,0.20), 0 0 10px rgba(0,0,0,0.15)",
            borderRadius: "30px",
            backgroundColor: "white",
            marginTop: "20px",
          }}
        >
          <div>
            <span
              style={{
                color: "#FB6D6A",
                fontWeight: "400",
                margin: "0",
                fontSize: "20px",
                marginLeft: "10px",
              }}
            >
              Create Media
            </span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginLeft: "10px",
              marginRight: "30px",
            }}
          >
            <FormControl component="fieldset">
              <h2 style={{ color: "#4F74C9", fontWeight: "400" }}>Channel</h2>
              {errors.channel.error && (
                <span style={{ color: "red" }}>{errors.channel.txt}</span>
              )}
              <RadioGroup
                aria-label="Chennel"
                name="channel"
                value={channel}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    channel: { error: false, txt: "" },
                  });
                  setChannel(e.target.value);
                }}
              >
                {ChanAdTypeData?.map((chan) => (
                  <FormControlLabel
                    value={chan._id}
                    control={<Radio />}
                    label={chan.channelName}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <div style={{ width: "30%" }}>
              <h2 style={{ color: "#4F74C9", fontWeight: "400" }}>Title</h2>
              <FormControl>
                <TextField
                  error={errors.name.error}
                  helperText={errors.name.txt}
                  fullWidth
                  label="Name"
                  variant="outlined"
                  style={{ display: "block", marginBottom: "10px" }}
                  value={name}
                  onChange={(e) => {
                    setErrors({
                      ...errors,
                      name: { error: false, txt: "" },
                    });
                    setName(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AssignmentIcon color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  style={{ marginBottom: "10px" }}
                  error={errors.description.error}
                  helperText={errors.description.txt}
                  fullWidth
                  label="Description"
                  multiline
                  rows={4}
                  placeholder="Description"
                  variant="outlined"
                  value={description}
                  onChange={(e) => {
                    setErrors({
                      ...errors,
                      description: { error: false, txt: "" },
                    });
                    setdescription(e.target.value);
                  }}
                />
                <span>Countries</span>
                <Select
                  id="outlined-select-currency-native"
                  multiple
                  label="Countries"
                  value={country}
                  onChange={(e) => {
                    setErrors({
                      ...errors,
                      country: { error: false, txt: "" },
                    });
                    setCountry(e.target.value);
                  }}
                  variant="outlined"
                  renderValue={(ep) => renderCountry}
                >
                  <MenuItem disabled value={0}>
                    <em>Countries</em>
                  </MenuItem>
                  <MenuItem key="all">
                    <ListItemText
                      primary="Select all"
                      onClick={(event) => {
                        event.stopPropagation();
                        setCountry(CountriesList?.map((e) => e._id));
                      }}
                    />
                  </MenuItem>

                  {CountriesList.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      <Checkbox
                        checked={country.includes(option._id)}
                      ></Checkbox>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div style={{ width: "25%" }}>
              <h2
                style={{
                  color: "#4F74C9",
                  fontWeight: "400",
                  fontSize: "18px",
                }}
              >
                Audience {`(${channelName})`}
              </h2>
              {
                //////////////////////////
              }
              {audienceQues.map((e, i) => (
                <TextField
                  error={errors.questions[i].error}
                  helperText={errors.questions[i].txt}
                  type="number"
                  fullWidth
                  label={e}
                  placeholder={e}
                  variant="outlined"
                  style={{ marginBottom: "10px" }}
                  value={audienceAns[i]}
                  onChange={(e) => {
                    let anserr = [...errors.questions];
                    anserr[i].error = false;
                    anserr[i].txt = "";
                    setErrors({
                      ...errors,
                      questions: [...anserr],
                    });
                    const ndata = [...audienceAns];
                    ndata[i] = e.target.value;
                    setAudeinceAns(ndata);
                  }}
                />
              ))}
              {
                ////////////////////////
              }
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DateRangeIcon color="disabled" />
                    </InputAdornment>
                  ),
                }}
                error={errors.frequency.error}
                helperText={errors.frequency.txt}
                style={{ display: "block", marginBottom: "10px" }}
                fullWidth
                id="outlined-select-currency-native"
                select
                label="Frequency"
                value={frequency}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    frequency: { error: false, txt: "" },
                  });
                  setFrequency(e.target.value);
                }}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              >
                <option disabled key={0} value={0}>
                  Select...
                </option>
                {[
                  "Daily",
                  "Weekly",
                  "Monthly",
                  "Quarterly",
                  "Half-Yearly",
                  "Annualy",
                ].map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </TextField>
            </div>
            <div style={{ width: "20%" }}>
              <h2 style={{ color: "#4F74C9", fontWeight: "400" }}>
                Cover Thumb
              </h2>
              <UploadImage
                setAvt={setAvtar}
                Avt={Avtar}
                setAvtarFile={setAvtarFile}
              />
              <span style={{ color: "red" }}>
                {errors.cover.error ? errors.cover.txt : ""}
              </span>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <button
              className={classes.addActionBtn}
              onClick={() => history.push("/admin")}
            >
              Cancel
            </button>
            <button
              className={classes.addActionBtn}
              onClick={() => {
                if (name === "")
                  setErrors({
                    ...errors,
                    name: { error: true, txt: "please enter name" },
                  });
                if (frequency === 0)
                  setErrors({
                    ...errors,
                    frequency: {
                      error: true,
                      txt: "please select frequency",
                    },
                  });
                if (description === "")
                  setErrors({
                    ...errors,
                    description: {
                      error: true,
                      txt: "please type description",
                    },
                  });
                if (Avtar === null)
                  setErrors({
                    ...errors,
                    cover: {
                      error: true,
                      txt: "please upload cover",
                    },
                  });
                if (channel === null)
                  setErrors({
                    ...errors,
                    channel: {
                      error: true,
                      txt: "please select channel",
                    },
                  });
                if (country === 0)
                  setErrors({
                    ...errors,
                    country: {
                      error: true,
                      txt: "please select a country",
                    },
                  });

                if (audienceAns.includes("")) {
                  let anserr = [...errors.questions];
                  anserr[audienceAns.indexOf("")] = {
                    error: true,
                    txt: "please answer this question",
                  };
                  setErrors({
                    ...errors,
                    questions: anserr,
                  });
                }

                if (
                  name !== "" &&
                  frequency !== 0 &&
                  description !== "" &&
                  Avtar !== null &&
                  channel !== null &&
                  country !== 0 &&
                  !audienceAns.includes("")
                ) {
                  setStep(1);
                }
              }}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    );
  };
  const Step3 = () => {
    return (
      <div
        className={classes.customfield}
        style={{
          padding: "16px",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.20), 0 0 10px rgba(0,0,0,0.15)",
          borderRadius: "30px",
          backgroundColor: "white",
          marginTop: "20px",
        }}
      >
        <ShowAlert />
        <div
          style={{
            marginBottom: "15px",
          }}
        >
          <span
            style={{
              color: "#FB6D6A",
              fontWeight: "400",
              margin: "0",
              fontSize: "20px",
              marginLeft: "10px",
            }}
          >
            Inventory / Ad Details
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginLeft: "10px",
            marginRight: "30px",
          }}
        >
          {/* <div style={{ width: "15%" }}>
            <FormControl component="fieldset">
              <h2 style={{ color: "#4F74C9", fontWeight: "400" }}>Channel</h2>
              {errors.channel.error && (
                <span style={{ color: "red" }}>{errors.channel.txt}</span>
              )}
              <RadioGroup
                aria-label="Chennel"
                name="channel"
                value={channel}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    channel: { error: false, txt: "" },
                  });
                  setChannel(e.target.value);
                }}
              >
                {ChanAdTypeData?.map((chan) => (
                  <FormControlLabel
                    value={chan._id}
                    control={<Radio />}
                    label={chan.channelName}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div> */}
          <div style={{ width: "25%", marginTop: "20px" }}>
            <TextField
              error={errors.Iname.error}
              helperText={errors.Iname.txt}
              fullWidth
              id="Name"
              label="Name"
              variant="outlined"
              style={{ display: "block", marginBottom: "10px" }}
              value={Iname}
              onChange={(e) => {
                setErrors({
                  ...errors,
                  Iname: { error: false, txt: "" },
                });
                setIname(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AssignmentIcon color="disabled" />
                  </InputAdornment>
                ),
              }}
            />

            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onStartDateChange={setStartDate}
              onEndDateChange={setEndDate}
              minimumDate={new Date(2020, 11, 24, 10, 33, 30, 0)}
              minimumLength={1}
              format="dd MMM yyyy"
              locale={enGB}
            >
              {({ startDateInputProps, endDateInputProps, focus }) => (
                <div className="date-range">
                  <TextField
                    error={errors.dates.error}
                    helperText={errors.dates.txt}
                    fullWidth
                    id="startDate"
                    label="Available From"
                    variant="outlined"
                    margin="normal"
                    onChange={(e) => {
                      setStartDate(e.target.value);
                    }}
                    className={classes.customfield}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...startDateInputProps}
                  />
                  <TextField
                    fullWidth
                    id="endDate"
                    label="Available Upto"
                    variant="outlined"
                    margin="normal"
                    className={classes.customfield}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...endDateInputProps}
                  />
                </div>
              )}
            </DateRangePicker>

            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <DateRangeIcon color="disabled" />
                  </InputAdornment>
                ),
              }}
              error={errors.AdType.error}
              helperText={errors.AdType.txt}
              style={{ display: "block", marginBottom: "10px" }}
              fullWidth
              id="outlined-select-currency-native"
              select
              label="Ad Type"
              value={AdType}
              onChange={(e) => {
                setErrors({
                  ...errors,
                  AdType: { error: false, txt: "" },
                });
                setAdType(e.target.value);
              }}
              SelectProps={{
                native: true,
              }}
              variant="outlined"
            >
              <option disabled key={0} value={0}>
                Select...
              </option>
              {adTypeList.map((option) => (
                <option key={option._id} value={option._id}>
                  {option.name}
                </option>
              ))}
            </TextField>
            {/* <TextField
                error={errors.Ifrequency.error}
                helperText={errors.Ifrequency.txt}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DateRangeIcon color="disabled" />
                    </InputAdornment>
                  ),
                }}
                style={{ display: "block", marginBottom: "10px" }}
                fullWidth
                id="outlined-select-currency-native"
                select
                label="Frequency"
                value={Ifreq}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    Ifrequency: { error: false, txt: "" },
                  });
                  setIfreq(e.target.value);
                }}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              >
                <option disabled key={0} value={0}>
                  Select...
                </option>
                {[
                  "Daily",
                  "Weekly",
                  "Monthly",
                  "Quarterly",
                  "Half-Yearly",
                  "Annualy",
                ].map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </TextField>
              <TextField
                error={errors.unitPrice.error}
                helperText={errors.unitPrice.txt}
                fullWidth
                id="UnitPrice"
                label="UnitPrice"
                variant="outlined"
                style={{ display: "block", marginBottom: "10px" }}
                value={unitPrice}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AssignmentIcon color="disabled" />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    unitPrice: { error: false, txt: "" },
                  });
                  setUnitPrice(e.target.value);
                }}
              /> */}
            {/* <TextField
                error={errors.Idescription.error}
                helperText={errors.Idescription.txt}
                fullWidth
                id="outlined-multiline-static"
                label="Description"
                multiline
                rows={4}
                placeholder="Description"
                variant="outlined"
                value={Idesc}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    Idescription: { error: false, txt: "" },
                  });
                  setIdesc(e.target.value);
                }}
              /> */}
          </div>
          <div
            style={{
              width: "50%",
              marginTop: "20px",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-around",
            }}
          >
            {NewQues.map((e, i) => (
              <>
                {e != "Country" &&
                  e != "Geo Country" &&
                  e != "Frequency" &&
                  e !== "Subchannel Type" &&
                  e !== "Audience" &&
                  e !== "Sell Type" && (
                    <TextField
                      error={errors.NewInvErrors[i]}
                      helperText={
                        errors.NewInvErrors[i] ? "this field is required" : ""
                      }
                      type={NewTypes[i]}
                      fullWidth
                      id={e}
                      label={e}
                      variant="outlined"
                      style={{
                        display: "block",
                        margin: "0 5px 5px 5px",
                        width: "45%",
                      }}
                      value={NewAns[i]}
                      onChange={(e) => {
                        let newErrArr = [...errors.NewInvErrors];
                        newErrArr[i] = false;
                        setErrors({
                          ...errors,
                          NewInvErrors: newErrArr,
                        });
                        let Toset = [...NewAns];
                        Toset[i] = e.target.value;
                        setNewAns(Toset);
                      }}
                    />
                  )}
                {(e == "Country" || e == "Geo Country") && (
                  <TextField
                    error={errors.NewInvErrors[i]}
                    helperText={
                      errors.NewInvErrors[i] ? "this field is required" : ""
                    }
                    style={{
                      display: "block",
                      margin: "0 5px 5px 5px",
                      width: "45%",
                    }}
                    fullWidth
                    select
                    id={e}
                    label={e}
                    value={NewAns[i]}
                    onChange={(e) => {
                      let newErrArr = [...errors.NewInvErrors];
                      newErrArr[i] = false;
                      setErrors({
                        ...errors,
                        NewInvErrors: newErrArr,
                      });
                      let Toset = [...NewAns];
                      Toset[i] = e.target.value;
                      setNewAns(Toset);
                    }}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  >
                    <option disabled key={0} value={""}>
                      {""}
                    </option>
                    {CountriesList?.map((option) => (
                      <option key={option._id} value={option._id}>
                        {option.name}
                      </option>
                    ))}
                  </TextField>
                )}
                {e == "Audience" && (
                  <TextField
                    error={errors.NewInvErrors[i]}
                    helperText={
                      errors.NewInvErrors[i] ? "this field is required" : ""
                    }
                    style={{
                      display: "block",
                      margin: "0 5px 5px 5px",
                      width: "45%",
                    }}
                    fullWidth
                    select
                    id={e}
                    label={e}
                    value={NewAns[i]}
                    onChange={(e) => {
                      let newErrArr = [...errors.NewInvErrors];
                      newErrArr[i] = false;
                      setErrors({
                        ...errors,
                        NewInvErrors: newErrArr,
                      });
                      let Toset = [...NewAns];
                      Toset[i] = e.target.value;
                      setNewAns(Toset);
                    }}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  >
                    <option disabled key={0} value={""}>
                      {""}
                    </option>
                    {demographics?.map((option) => (
                      <option key={option.name} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                  </TextField>
                )}
                {e == "Frequency" && (
                  <TextField
                    error={errors.NewInvErrors[i]}
                    helperText={
                      errors.NewInvErrors[i] ? "this field is required" : ""
                    }
                    style={{
                      display: "block",
                      margin: "0 5px 5px 5px",
                      width: "45%",
                    }}
                    fullWidth
                    select
                    id={e}
                    label={e}
                    value={NewAns[i]}
                    onChange={(e) => {
                      let newErrArr = [...errors.NewInvErrors];
                      newErrArr[i] = false;
                      setErrors({
                        ...errors,
                        NewInvErrors: newErrArr,
                      });
                      let Toset = [...NewAns];
                      Toset[i] = e.target.value;
                      setNewAns(Toset);
                    }}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  >
                    <option disabled key={0} value={""}>
                      {""}
                    </option>
                    {[
                      "Daily",
                      "Weekly",
                      "Monthly",
                      "Quarterly",
                      "Half-Yearly",
                      "Annualy",
                    ].map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </TextField>
                )}
                {e == "Subchannel Type" && (
                  <TextField
                    error={errors.NewInvErrors[i]}
                    helperText={
                      errors.NewInvErrors[i] ? "this field is required" : ""
                    }
                    style={{
                      display: "block",
                      margin: "0 5px 5px 5px",
                      width: "45%",
                    }}
                    fullWidth
                    select
                    id={e}
                    label={e}
                    value={NewAns[i]}
                    onChange={(e) => {
                      let newErrArr = [...errors.NewInvErrors];
                      newErrArr[i] = false;
                      setErrors({
                        ...errors,
                        NewInvErrors: newErrArr,
                      });
                      let Toset = [...NewAns];
                      Toset[i] = e.target.value;
                      setNewAns(Toset);
                    }}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  >
                    <option disabled key={0} value={""}>
                      {""}
                    </option>
                    {["Video", "Display", "RichMedia", "SkinWallpaper"].map(
                      (option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      )
                    )}
                  </TextField>
                )}
                {e == "Sell Type" && (
                  <TextField
                    error={errors.NewInvErrors[i]}
                    helperText={
                      errors.NewInvErrors[i] ? "this field is required" : ""
                    }
                    style={{
                      display: "block",
                      margin: "0 5px 5px 5px",
                      width: "45%",
                    }}
                    fullWidth
                    select
                    id={e}
                    label={e}
                    value={NewAns[i]}
                    onChange={(e) => {
                      let newErrArr = [...errors.NewInvErrors];
                      newErrArr[i] = false;
                      setErrors({
                        ...errors,
                        NewInvErrors: newErrArr,
                      });
                      let Toset = [...NewAns];
                      Toset[i] = e.target.value;
                      setNewAns(Toset);
                    }}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  >
                    <option disabled key={0} value={""}>
                      {""}
                    </option>
                    {["CPM", "CPC", "NET COST"].map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </TextField>
                )}
              </>
            ))}
          </div>
          {/* <div style={{ width: "25%" }}>
              <h2 style={{ color: "#4F74C9", fontWeight: "400" }}>
                Cover Thumb
              </h2>
              {errors.avatar.error && (
                <span style={{ color: "red" }}>please select cover image</span>
              )}
              <UploadImage
                setAvt={setInventoryAvtar}
                Avt={InventoryAvtar}
                setAvtarFile={setInventoryAvtarFile}
              />
            </div> */}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <button className={classes.addActionBtn} onClick={() => setStep(1)}>
            Previous
          </button>
          <button
            className={classes.addActionBtn}
            onClick={() => {
              if (Iname === "")
                setErrors({
                  ...errors,
                  Iname: {
                    error: true,
                    txt: "please enter a name",
                  },
                });
              if (AdType === 0)
                setErrors({
                  ...errors,
                  AdType: {
                    error: true,
                    txt: "please select Ad Type",
                  },
                });
              if (endDate === "")
                setErrors({
                  ...errors,
                  dates: {
                    error: true,
                    txt: "please select a date range",
                  },
                });
              NewAns.forEach((e, i) => {
                if (e === "" || e === 0) {
                  let newErrArr = [...errors.NewInvErrors];
                  newErrArr[i] = true;
                  setErrors({
                    ...errors,
                    NewInvErrors: newErrArr,
                  });
                }
              });

              if (
                !errors.NewInvErrors.includes(true) &&
                !NewAns.includes("") &&
                AdType !== 0 &&
                endDate !== ""
              ) {
                setStep(3);
              }
            }}
          >
            Next
          </button>
        </div>
      </div>
    );
  };
  const Step4 = () => {
    return (
      <div>
        <div
          className={classes.customfield}
          style={{
            padding: "16px",
            boxShadow:
              "0px 0px 10px rgba(0,0,0,0.20), 0 0 10px rgba(0,0,0,0.15)",
            borderRadius: "30px",
            backgroundColor: "white",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              marginBottom: "15px",
            }}
          >
            <span
              style={{
                color: "#FB6D6A",
                fontWeight: "400",
                margin: "0",
                fontSize: "20px",
                marginLeft: "10px",
              }}
            >
              {name}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginLeft: "10px",
              marginRight: "30px",
            }}
          >
            <div style={{ width: "15%" }}>
              <img style={{ width: "90%" }} src={Avtar} alt="avatar.img"></img>
            </div>
            <div style={{ width: "40%" }}>
              <span
                style={{ marginTop: "0px", color: "red", fontSize: "15px" }}
              >
                Description
              </span>
              <p
                style={{
                  wordBreak: "break-all",
                  whiteSpace: "normal",
                  width: "90%",

                  marginBottom: "30px",
                }}
              >
                {description}
              </p>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DateRangeIcon color="disabled" />
                    </InputAdornment>
                  ),
                }}
                style={{ display: "block", marginBottom: "10px" }}
                id="outlined-select-currency-native"
                label="Frequency"
                value={frequency}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              ></TextField>
            </div>
            <div style={{ width: "25%" }}>
              {audienceQues.map((e, i) => (
                <TextField
                  fullWidth
                  label={e}
                  placeholder={e}
                  variant="outlined"
                  style={{ marginBottom: "10px" }}
                  value={audienceAns[i]}
                />
              ))}
              {/* <TextField
                fullWidth
                id="Unit Price"
                label="Unit Price"
                variant="outlined"
                style={{ display: "block", marginBottom: "10px" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AssignmentIcon color="disabled" />
                    </InputAdornment>
                  ),
                }}
                value={unitPrice}
              /> */}

              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DateRangeIcon color="disabled" />
                    </InputAdornment>
                  ),
                }}
                style={{ display: "block", marginBottom: "10px" }}
                fullWidth
                id="outlined-select-currency-native"
                label="Channel"
                value={channelName}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              ></TextField>
            </div>

            <div style={{ width: "15%", maxHeight: "400px", overflow: "auto" }}>
              <span
                style={{
                  marginTop: "0px",
                  color: "red",
                  fontSize: "15px",
                  marginRight: "10px",
                }}
              >
                Personas
              </span>
              {selectedPersonas.map((per) => (
                <div
                  className={classes.boxShadow}
                  style={{
                    marginTop: "10px",
                    marginRight: "10%",
                    marginLeft: "10%",
                    textAlign: "center",
                    borderRadius: "6px",
                  }}
                >
                  <img
                    alt="media.Img"
                    src={personaImg}
                    style={{
                      width: "100%",
                      display: "block",
                      backgroundColor: `${per.col}`,
                      borderRadius: "50%",
                    }}
                  ></img>
                  <span
                    style={{
                      color: "blue",
                      fontSize: "10px",
                    }}
                  >
                    {per.name}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "80px",
            }}
          >
            <button className={classes.addActionBtn} onClick={() => setStep(2)}>
              Previous
            </button>
            {processing === "" && (
              <button
                className={classes.addActionBtn}
                style={{ backgroundColor: "#7CB60E" }}
                onClick={() => save()}
              >
                Save
              </button>
            )}
            {processing !== "" && <span>{processing}</span>}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <ShowAlert />
      {Step === 0 && <StepWrapper step={0} />}
      {Step === 1 && <StepWrapper step={1} />}
      {Step === 2 && <StepWrapper step={2} />}
      {Step === 3 && <StepWrapper step={3} />}
      {Step === 0 && Step1()}
      {Step === 1 && (
        <Step2
          setStep={setStep}
          selectedPersonas={selectedPersonas}
          setSelectedPersonas={setSelectedPersonas}
          creator={params.creator}
        />
      )}
      {Step === 2 && Step3()}
      {Step === 3 && Step4()}
    </div>
  );
};
export default MainLayout(CreateMedia);
