import React, { useState, useEffect } from "react";
import Pagination from "@material-ui/lab/Pagination";
import MainLayout from "../../../layouts/adminMainLayout";

import CircularProgress from "@material-ui/core/CircularProgress";
import NewAlert from "../../../components/NewAlert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
//Icon
import useStyles from "../../../components/StyleClasses";
import CustomizedTables from "../../mediaOwner/mytable";
import { API, SECRET_KEY } from "../../../constants/index";
import axios from "axios";
import CustomDropdown from "../../../components/CustomDropdown";
import CustomSelect from "../../../components/CustomSelect";
import {
  Backdrop,
  Fade,
  Modal,
  Button
} from "@material-ui/core/";
import { useHistory } from "react-router-dom";
import PreviewReport from "./previewReport";

const ConsultantSurveys = ({ callback }) => {
  const classes = useStyles();
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [DATA, setDATA] = useState(null);
  const [loading, setloading] = useState(false);
  const [page, setpage] = useState(1);
  const [pagecount, setPageCount] = useState(0);
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [mediaplanners, setMediaplanners] = useState([]);
  const [mediaplanner, setMediaplanner] = useState(null);
  const [openEmailPopup, setOpenEmailPopup] = useState(false);
  const [consultants, setConsultants] = useState([]);
  const [selectedConsultants, setSelectedConsultants] = useState([]);
  // const [renderConsultants, setRenderConsultants] = useState([]);
  // const [totalConsultants, setTotalConsultants] = useState(1);
  const [consultantCurrentPage, setConsultantCurrentPage] = useState(1);
  const [currentConsultant, setCurrentConsultant] = useState(null);
  const [selectedListID, setSelectedListID] = useState(null);
  const [showPage, setShowPage] = useState("list");
  const [allQuestions, setAllQuestions] = useState([])

  ///////errors & successs //////////
  const [ErrSucMsg, setErrSucMesg] = useState("");
  const [ErrSucType, setErrSucType] = useState(true);

  const access_token = window.localStorage.getItem(SECRET_KEY);

  const getSurveyList = () => {
    setloading(true);
    setTableData([]);
    let url = `${API}/admin/creativeconsultant`;
    if (mediaplanner) {
      url += `/getCreativeConsultancy/survey?page=${page}&mediaplanner=${mediaplanner._id}`;
    } else {
      url += `/getCreativeConsultancy/survey?page=${page}`;
    }
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setloading(false);
          setDATA(res.data.data);
          setPageCount(res.data.pagination.total);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };

  useEffect(() => getSurveyList(), [page]);
  useEffect(() => getSurveyList(), [mediaplanner]);

  useEffect(() => {
    if (DATA) {
      let td = [];
      DATA.forEach((e) => {
        td.push({
          data: [
            e.title,
            e.completedSurveys,
            e.samplesize,
          ],
          _id: e._id,
        });
      });
      setTableData(td);
    }
  }, [DATA]);

  const deleteSurvey = () => {
    axios
      .delete(`${API}/admin/creativeconsultant/ConsultancyById/${deleteId}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setErrSucMesg(res.data.message);
          setErrSucType(true);
          getSurveyList();
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.data) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
          getSurveyList();
        }
      });
  };

  const getSurveyQuestions = () => {
    setloading(true);
    axios
      .get(`${API}/admin/questions/getQuestions`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setAllQuestions(res.data);
          setloading(false);
        }
      })
      .catch(function (error) {
        if (error) {
          setloading(false);
        }
      });
  }

  const getPlannersList = () => {
    setloading(true);
    setTableData([]);
    axios
      .get(`${API}/admin/mediaPlanner`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setloading(false);
          setMediaplanners(res.data.data);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };
  useEffect(() => {
    getSurveyQuestions();
    getPlannersList();
    getConsultantList();
  }, []);
  // useEffect(() => getPlannersList(), [page]);

  var myVar;

  function myFunction() {
    myVar = setTimeout(function () {
      getPlannersList();
    }, 200);
  }

  function myStopFunction() {
    clearTimeout(myVar);
  }
  // useEffect(() => myFunction(), [search]);

  const getConsultantList = () => {
    setloading(true);
    axios
      .get(`${API}/admin/creativeconsultant/getCreativeConsultantsEmailList`, {
        // .get(`${API}/admin/creativeconsultant?page=${consultantCurrentPage}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setloading(false);
          // let dt = [...consultants, ...res.data.data].sort((a,b)=> {
          //   if(a.name < b.name) { return -1; }
          //   if(a.name > b.name) { return 1; }
          //   return 0;
          // })
          setConsultants(res.data);
          // setConsultantCurrentPage(res.data.pagination.currentPage+1);
          // setTotalConsultants(res.data.pagination.total);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };

  // useEffect(() => {
  //   if (consultants.length > 0 && selectedConsultants.length > 0) {
  //     let a = consultants.filter((o) => selectedConsultants.includes(o._id));
  //     let b = a.map((e) => e.name + " , ");

  //     setRenderConsultants(b.slice(0, 30));
  //   }
  // }, [consultants, selectedConsultants]);

  const sendEmails = () => {
    if (selectedConsultants.length == 0) return;
    let allEmails = [];
    selectedConsultants.map(c => {
      // if (selectedConsultants.includes(c._id)) {
      allEmails.push(c._id);
      // }
    })
    const body = {
      surveyId: currentConsultant?._id,
      emails: allEmails
    };
    axios
      .post(`${API}/admin/creativeconsultant/emailInvitation`, body, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setCurrentConsultant(null);
          setOpenEmailPopup(false);
          setErrSucType(true);
          setErrSucMesg(res.data.message);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
        }
      });
  };

  return (
    <div>
      <NewAlert
        msg={ErrSucMsg}
        isSuccess={ErrSucType}
        close={() => setErrSucMesg("")}
      />
      {deleteFlag && (
        <Dialog
          open={deleteFlag}
          onClose={() => {
            setDeleteFlag(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Are you sure, you want to delete Survey? If you delete the survey, it will delete all related consultant surveys.
          </DialogTitle>
          <DialogActions>
            <Button
              style={{ color: "black", backgroundColor: "grey" }}
              onClick={() => {
                setDeleteFlag(false);
              }}
            >
              Cancel
            </Button>
            <Button
              style={{ backgroundColor: "#4F74C9", color: "white" }}
              onClick={() => {
                setDeleteFlag(false);
                deleteSurvey();
              }}
              autoFocus
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h3>{showPage == "list" ? 'Consultant Surveys' : 'Preview Survey Report'}</h3>
        {showPage == "Preview Report" ? <Button color="primary" size="small" onClick={() => {
          setShowPage('list');
          setSelectedListID(null);
        }}>Back</Button> : null}
      </div>

      {showPage == "list" ?
        <div className={classes.boxShadow} style={{ marginTop: "0px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          > <div style={{ width: '242px' }}>
              <CustomDropdown
                isSearchable={true}
                containerClassName="mt-3 dropdown-color"
                onChange={async (e) => {
                  setMediaplanner(e);
                }}
                value={mediaplanner}
                placeholder="Media Planners"
                options={mediaplanners?.map((i) => {
                  i.label = i.name;
                  i.value = i.name;
                  return i;
                })}
                isClearable={true}
              />
            </div>
            <span
              style={{
                color: "#FB6D6A",
                fontWeight: "600",
                margin: "0",
                fontSize: "20px",
                marginLeft: "20px",
              }}
            >
            </span>
            <span
              style={{
                color: "#FB6D6A",
                fontWeight: "600",
                margin: "0",
                fontSize: "20px",
                marginRight: "20px",
              }}
            >
              <Pagination
                count={Math.ceil(pagecount / 10)}
                color="primary"
                onChange={(e, page) => setpage(page)}
                size="small"
              />
            </span>
          </div>
          <CustomizedTables
            isEditable={false}
            isViewable={true}
            isButtonText="Send Email"
            sendEmailCallback={(value) => {
              setCurrentConsultant(value);
              setSelectedConsultants([]);
              setOpenEmailPopup(true);
            }}
            headings={[
              "Title",
              "Completed Surveys",
              "Sample Size",
              "Preview Survey",
              "Preview Report",
            ]}
            listdata={tableData}
            redirectLink={(e, type) => {
              if (type == 'Preview Survey') {
                history.push('survey?id=' + e);
              } else {
                setShowPage(type);
                setSelectedListID(e);
              }
            }}
            handleDelete={(_id) => {
              setDeleteFlag(true);
              setDeleteId(_id);
            }}

          />
          <div style={{ display: "flex", justifyContent: "center" }}>
            {loading && <CircularProgress />}
          </div>
        </div> : null}

      {showPage == "Preview Report" && selectedListID ? (
        <PreviewReport selectedListID={selectedListID} questions={allQuestions} callback={() => setShowPage("list")} />
      ) : null}

      <Modal
        open={openEmailPopup}
        onClose={() => setOpenEmailPopup(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openEmailPopup}>
          <div className={classes.boxShadow}>
            <div
              className={classes.customfield}
              style={{
                width: "35%",
                padding: "50px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                position: "absolute",
                left: "35%",
                marginTop: "15%",
                outline: 'none',
                borderColor: 'transparent'
              }}
            >
              <h3>{currentConsultant?.data[0]}</h3>
              <label>Select consultants to send an Email</label>
              <CustomDropdown
                isMulti
                isSearchable={true}
                containerClassName="mt-2"
                value={selectedConsultants.length ? selectedConsultants : []}
                onChange={(e) => {
                  setSelectedConsultants(e);
                }}
                placeholder=""
                options={consultants.map(c => {
                  c.label = c.name;
                  c.value = c.name;
                  return c;
                })}
                isClearable={true}
              />
              {/* <CustomSelect
                label="Consultants"
                responseTotalPages={totalConsultants}
                stateTotalData={consultants.length}
                apiCallback={() => getConsultantList()}
                onChangeCallback={(e) => {
                  setSelectedConsultants(e.target.value);
                }}
                selectedValues={selectedConsultants}
                renderValues={renderConsultants}
                data={consultants}
                type="name"
                initialSelectedData={selectedConsultants}
              /> */}
              <div style={{
                textAlign: 'center'
              }}>
                <button
                  onClick={() => {
                    sendEmails();
                  }}
                  style={{
                    backgroundColor: "blue",
                    color: "white",
                    borderRadius: "7px",
                    padding: "10px 25px",
                    border: "none",
                    marginTop: '20px',
                    width: '150px',
                    cursor: 'pointer'
                  }}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>

    </div>

  );
};
export default MainLayout(ConsultantSurveys);
