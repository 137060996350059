import FormControl from "@material-ui/core/FormControl";
import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/adminMainLayout";

import CircularProgress from "@material-ui/core/CircularProgress";

//Icon

import Autocomplete from "@material-ui/lab/Autocomplete";

//TextField
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useStyles from "../../components/StyleClasses";
import ShowAlert from "../../components/alerts";
import { API, SECRET_KEY } from "../../constants/index";

import EditIcon from "@material-ui/icons/Edit";

import { Delete } from "@material-ui/icons";
import Pagination from "@material-ui/lab/Pagination";
import { useAccessToken } from "../../Hooks/useAccessToken";
import ToastMessage from "../../components/Toast/Toast";

function paramsToObject(entries) {
  const result = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}

const CreateInventory = () => {
  const history = useHistory();
  const urlparams = new URLSearchParams(history.location.search);
  const paramEntries = urlparams.entries();
  const params = paramsToObject(paramEntries);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [mediaOwnerID, setMediaOwnerID] = useState(
    params.owner ? params.owner : null
  );
  const [mediaID, setMediaID] = useState(null);
  const [mediaOwnerList, setMediaOwnerList] = useState([]);
  const [mediaList, setMediaList] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [namesearch, setNamesearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [Spinner, setSpinner] = useState(false);

  const [showMediaDeletedMsg, setShowMediaDeleteMsg] = useState(false)

  const [SelectedName, setSelectedName] = useState("");

  const access_token = window.localStorage.getItem(SECRET_KEY);

  const handleMediaDelete = (media_id, cb) => {
    axios.delete(`${API}//admin/mediaPublication/${media_id}`,{
      headers: { Authorization: `Bearer ${access_token}` },
    }).then(res => {
      getTabledata()
    }).catch(err => {
      console.log(err)
    }).finally(cb)
  }

  useEffect(() => {
    // if (namesearch == null) return;
    setSpinner(true);

    axios
      .get(`${API}/admin/mediaOwner?name=${namesearch}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res) {
          setMediaOwnerList(res.data.data);
          if (mediaOwnerID?.length > 10) {
            let moval = res.data.data.find((e) => e._id === mediaOwnerID);
            setSelectedName(moval.name);
          }
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  }, [namesearch, mediaOwnerID]);

  const getTabledata = () => {
    if (mediaOwnerID === null) return;
    setLoading(true);
    setMediaList([]);
    //if (typeof mediaOwnerID === undefined) return;
    // setMediaList([]);
    axios
      .get(
        `${API}/admin/mediaPublication?creator=${mediaOwnerID}&page=${page}`,
        {
          headers: { Authorization: `Bearer ${access_token}` },
        }
      )
      .then((res) => {
        if (res) {
          setMediaList(res.data.data);
          setPageCount(res.data.total);
        }
        setLoading(false);
      })
      .catch(function (error) {
        if (error) {
          setLoading(false);
        }
      });
  };
  useEffect(() => {
    getTabledata();
  }, [mediaOwnerID, page]);

  useEffect(() => {
    setSpinner(false);
  }, [mediaOwnerList]);


  return (
    <div>
      <div
        className={classes.customfield}
        style={{
          padding: "16px",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.20), 0 0 10px rgba(0,0,0,0.15)",
          borderRadius: "30px",
          backgroundColor: "white",
          marginTop: "20px",
        }}
      >
        <ShowAlert />
        <div
          style={{
            marginBottom: "15px",
          }}
        >
          <span
            style={{
              color: "#FB6D6A",
              fontWeight: "400",
              margin: "0",
              fontSize: "20px",
              marginLeft: "10px",
            }}
          >
            Media
          </span>
        </div>
        <div style={{ width: "40%", marginBottom: "80px" }}>
          {!mediaOwnerID && (
            <div style={{ display: "flex" }}>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                style={{ marginTop: "10px" }}
              >
                <Autocomplete
                  value={mediaOwnerList?.find((e) => e._id === params.owner)}
                  options={mediaOwnerList}
                  onChange={(e, val) => {
                    setMediaOwnerID(val?._id);
                  }}
                  getOptionLabel={(option) => option?.name}
                  style={{ marginTop: "10px", padding: "0" }}
                  getOptionSelected={(option, value) =>
                    option._id === value?._id
                  }
                  renderOption={(option) => (
                    <div style={{ padding: "0" }}>
                      <span>{option.name}</span>
                      <br></br>
                      <span
                        style={{
                          fontSize: "10px",
                          fontWeight: "100",
                          padding: "0",
                        }}
                      >
                        {option.username}
                      </span>
                    </div>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Media Owner"
                      onChange={(e) => setNamesearch(e.target.value)}
                      style={{ padding: "0" }}
                    />
                  )}
                />
              </FormControl>
              <span>
                {Spinner && (
                  <CircularProgress
                    style={{ marginTop: "30px" }}
                    color="disabled"
                    size={20}
                  />
                )}
              </span>
            </div>
          )}

          {mediaOwnerID && SelectedName.length > 0 && (
            <div>
              <span
                style={{ color: "blue", marginRight: "15px", fontSize: "25px" }}
              >
                {SelectedName}
              </span>
              {SelectedName.length > 0 && (
                <button
                  onClick={() => {
                    setPageCount(0);
                    setMediaOwnerID(null);
                    history.push("manageMedia");
                  }}
                >
                  x
                </button>
              )}
            </div>
          )}
        </div>
        {mediaOwnerID && !loading && (
          <button
            className={classes.addActionBtn}
            style={{ marginBottom: "10px" }}
            onClick={() =>
              history.push(`createMedia?creator=${mediaOwnerID}&id=${mediaID}`)
            }
          >
            Create Media
          </button>
        )}
        {loading && <CircularProgress size={40} />}
        {mediaOwnerID && !loading && (
          <div style={{ display: "flex", padding: "30px", flexWrap: "wrap" }}>
            {mediaList?.map((e, i) => (
              <MediaTab
                key={e._id}
                title={e.name}
                img={e.mediaImage}
                _id={e._id}
                timer={i}
                mediaOwnerID={mediaOwnerID}
                onDelete={handleMediaDelete}
                getTabledata={getTabledata}
              />
            ))}
            {mediaList.length == 0 && <span>No Media Created</span>}
          </div>
        )}
        {pageCount > 0 && (
          <div
            style={{
              display: "flex",
              marginTop: "20px",
              justifyContent: "center",
            }}
          >
            <span
              style={{
                color: "#FB6D6A",
                fontWeight: "600",
                margin: "0",
                fontSize: "20px",
                marginRight: "20px",
              }}
            >
              <Pagination
                page={page}
                count={Math.ceil(pageCount / 10)}
                color="primary"
                onChange={(e, page) => setPage(page)}
                size="small"
              />
            </span>
          </div>
        )}
        {/* <div
          style={{
            marginTop: "20px",
          }}
        >
          <button
            className={classes.addActionBtn}
            onClick={() => history.goBack()}
          >
            Cancel
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default MainLayout(CreateInventory);



const MediaTab = ({ title, img, _id, timer, mediaOwnerID,getTabledata }) => {
  const [show, setshow] = useState(false);
  const [isDeletingMedia, setIsDeletingMedia] = useState(false)
  const [deleteError,setDeleteError] = useState(null)

  const access_token = useAccessToken()

  const history = useHistory()

  const handleDelete = () => {
    setIsDeletingMedia(true)
    axios.delete(`${API}//admin/mediaPublication/${_id}`,{
      headers: { Authorization: `Bearer ${access_token}` },
    }).then(res => {
      getTabledata()
    }).catch(err => {
      if(err?.response?.data?.message){
        setDeleteError(err.response.data.message)
      }
    }).finally(() => {
      setIsDeletingMedia(false)
    })
  }
  
  setTimeout(() => {
    setshow(true);
  }, timer * 100);

  let fixedTitle = title;
  if (fixedTitle && fixedTitle.length > 15) {
    fixedTitle = fixedTitle.substring(0, 15) + "...";
  }

  return (
    <>
    <div
      style={
        show
          ? {
              boxShadow:
                "0px 0px 10px rgba(0,0,0,0.20), 0 0 10px rgba(0,0,0,0.15)",
              borderTopLeftRadius: "16px",
              borderTopRightRadius: "16px",
              marginRight: "30px",
              marginTop: "30px",
              transform: "scale(1)",
              transition: "all 0.15s",
            }
          : { transform: "scale(0)" }
      }
    >
      <div
        style={{
          padding: "7px",
          backgroundColor: "blue",
          borderTopLeftRadius: "16px",
          borderTopRightRadius: "16px",
          color: "white",
          textAlign: "center",
          background: "linear-gradient(to right, #9211a2, #4911a2)",
        }}
      >
        <span
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems:"center",
            textOverflow: "hidden",
          }}
        >
          {fixedTitle}{" "}
          <div style={{display:"flex",alignItems:"center",gap:"5px"}}>
            <EditIcon
              fontSize="small"
              style={{cursor:"pointer"}}
              onClick={() => history.push(`/admin/updateMedia/${_id}/${mediaOwnerID}`)}
              />
              {isDeletingMedia ? <CircularProgress style={{color:"white", position:"unset", width:"20px", height:"20px"}}/>
              :
            <Delete style={{cursor:"pointer"}} onClick={handleDelete} />
              }
          </div>
        </span>
      </div>
      <div
        style={{
          padding: "10px",
          width: "200px",
          height: "250",
          display:"flex",
          justifyContent:"center"
        }}
        onClick={() =>
          history.push(
            `/admin/singleMediaView?creator=${mediaOwnerID}&id=${_id}`
          )
        }
      >
        {/* <img
          alt="media.Img"
          src={img}
          style={{
            boxShadow:
              "0px 0px 10px rgba(0,0,0,0.20), 0 0 10px rgba(0,0,0,0.15)",
            width: "140px",
            display: "block",
          }}
        ></img> */}
        <div
          style={{
            boxShadow:
              "0px 0px 10px rgba(0,0,0,0.20), 0 0 10px rgba(0,0,0,0.15)",
            width: "170px",
            height: "170px",
            display: "block",
            backgroundImage: `url(${img})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            position: "relative",
            cursor: "pointer",
          }}
          //onClick={() => setOpen(true)}
        ></div>
      </div>
    </div>
    <ToastMessage msg={deleteError} close={() => setDeleteError(null)} isSuccess={false} />
    </>
  );
};