import React, { useState, useEffect } from 'react'
import { makeStyles, fade } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

const demographic = [
    {
        name: 'asian',
        value: 'Asian'
    },
    {
        name: 'black',
        value: 'Black'
    },
    {
        name: 'disability',
        value: 'Disability'
    },
    {
        name: 'lgbtq',
        value: 'LGBTQ+'
    },
    {
        name: 'multiEthnic',
        value: 'Multi-Ethnic'
    },
    {
        name: 'gender',
        value: 'Gender'
    },
    {
        name: 'age50',
        value: 'Age 50+'
    },
    {
        name: 'religion',
        value: 'Religion'
    },
    {
        name: 'pakistani',
        value: 'Pakistani'
    },
    {
        name: 'indian',
        value: 'Indian'
    },
    {
        name: 'latinx',
        value: 'LatinX'
    },
]

const useStyles = makeStyles((theme) => ({
    boxShadow: {
        position: 'relative',
        padding: '16px',
        boxShadow: '0px 0px 20px rgba(0,0,0,0.30), 0 20px 20px rgba(0,0,0,0.22)',
        borderRadius: '30px',
        backgroundColor: 'white',
        marginTop: '-30px'
    },
    pageTitle: {
        color: '#e96941',
        fontWeight: 'normal'
    },
    formTitle: {
        color: "#1d8bf7",
        fontWeight: 'normal'
    },
    paper: {
        padding: '16px',
        color: theme.palette.text.secondary,
    },
    textarea: {
        width: '100%',
        backgroundColor: '#f4f4f8',
        marginTop: '8px',
        borderRadius: '5px',
        padding: '14px',
        marginBottom: '20px'
    },
    '.MuiFormControl-root': {
        border: '1px solid red'
    },
    customfield: {
        paddingTop: '0',
        marginTop: '0',
        backgroundColor: '#f4f4f8',
        borderRadius: '5px',
        '& .MuiInputLabel-root .MuiTypography-root': {
            color: 'red'
        },
        '& .MuiInputLabel-root.Mui-shrink': {
            color: 'red'
            // "& .Mui-shrink": {
            // 	color: 'red'
            // },
        },
        '&:hover': {
            backgroundColor: '#fff',
        },
        '&$focused': {
            backgroundColor: '#fff',
            boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            borderColor: theme.palette.primary.main,
        },
        '& .MuiOutlinedInput-input': {
            padding: '8.5px 14px',
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '5px',

            '& fieldset': {
                borderColor: 'grey',
            },
            '&:hover fieldset': {
            },
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: 'grey'
        },
        "& .MuiInputLabel-root.Mui-focused": {
            color: 'grey'
        },
        "& .MuiInputLabel-root.MuiInputLabel-shrink": {
            margin: '0!important'
        },
        "& .MuiInputLabel-root.MuiInputLabel-animated": {
            marginTop: '-8px'
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: 'grey'
        }
    },
    selectImgBtn: {
        marginTop: '10px',
        display: 'block',
        margin: '0 auto',
        color: '#ba54f5',
        border: '1px solid #ba54f5',
        padding: '5px 30px'
    },
    nextButton: {
        marginLeft: 'auto',
        backgroundColor: "#1d8bf7",
        color: 'white',
        display: 'block',
        padding: '5px 40px'

    },
    smallTextBox: {
        width: '40px',
        border: '1px solid #0000008a',
        padding: '0 5px',
        background: '#fafafa'
    },
    smallBoxLabel: {
        display: 'block',
        padding: 0,
        marginBottom: '10px',
        '& .MuiFormLabel-root': {
            color: '#0000008a',
            marginBottom: '0px',
            display: 'inline-block',
            verticalAlign: 'middle',
            width: '150px',
            fontSize: '0.8em'
        },
        '& .MuiFormControl-root': {
            display: 'inline-block',
            verticalAlign: 'middle',
        },

    },
    checkNRadio: {
        padding: 0,
        '& .MuiFormLabel-root': {
            color: '#1d8bf7',
            marginBottom: '8px',
        },
        '& .MuiFormControlLabel-root': {
            '& span': {
                paddingTop: '1px',
                fontSize: '0.8em',
                paddingBottom: '1px',
            }
        }
    },

    formSectionTitleWrapper: {
        color: '#1d8bf7',
        height: '60px',
        '& .agetext': {
            display: 'inline-block',
            width: '163px',
        }

    }


}))

export default function Insight() {
    const { register, handleSubmit, errors, control } = useForm({ mode: 'onSubmit', reValidateMode: 'onSubmit' })
    const classes = useStyles()
    const userLoggedIn = useSelector((state) => state.loggedInSession);
    const mediaOwner = useSelector((state) => state.mediaOwner);

    const [age16, setAge16] = useState('')
    const [age17, setAge17] = useState('')
    const [age21, setAge21] = useState('')
    const [age26, setAge26] = useState('')
    const [age31, setAge31] = useState('')
    const [age41, setAge41] = useState('')
    const [age51, setAge51] = useState('')
    const [age60, setAge60] = useState('')

    const [male, setMale] = useState('')
    const [female, setFemale] = useState('')
    const [demo, setDemo] = useState([
        {
            'asian': '',
            'black': '',
            'disability': '',
            'lgbtq': '',
            'multiEthnic': '',
            'gender': '',
            'age50': '',
            'religion': '',
            'pakistani': '',
            'indian': '',
            'latinx': '',
        },
    ])

    useEffect(() => {

    }, [])

    const onSubmit = insight => {
        mediaOwner.insight = insight

        dispatch({
            type: 'UPDATE_INSIGHT',
            mediaOwner: mediaOwner
        })

    }

    const handleDemoChange = (e) => {
        setDemo({
            ...demo,  //take existing key-value pairs and use them in our new state,
            [e.target.name]: e.target.value   //define new key-value pair with new uuid and [].
        })
    }

    const dispatch = useDispatch()

    return (
        <div className={classes.boxShadow}>
            <div className={classes.paper}>
                <form
                    className={classes.form}
                    // ref={useRef()  }
                    onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <Grid container spacing={3}>
                            <Grid item md={3} sm={3} xs={3}>
                                <div className={classes.formSectionTitleWrapper}>
                                    <span class="agetext">Age</span>
                                    <span>%</span>
                                </div>
                                <FormControl component="fieldset" error={Boolean()} className={classes.smallBoxLabel}>
                                    <FormLabel for="age16">-16</FormLabel>
                                    {/* <label for="email">Email address</label> */}
                                    <TextField
                                        className={classes.smallTextBox}
                                        onChange={(e) => setAge16(e.target.value)}
                                        value={age16}
                                        name="age[age16]"
                                        inputRef={register()}
                                        // label="Bootstrap"
                                        id="age16"
                                        InputProps={{
                                            disableUnderline: true
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </FormControl>
                                <FormControl component="fieldset" error={Boolean()} className={classes.smallBoxLabel}>
                                    <FormLabel for="email">17-20</FormLabel>
                                    {/* <label for="email">Email address</label> */}
                                    <TextField
                                        className={classes.smallTextBox}
                                        onChange={(e) => setAge17(e.target.value)}
                                        value={age17}
                                        name="age[age17_20]"
                                        inputRef={register()}
                                        // label="Bootstrap"
                                        id="bootstrap-input"
                                        InputProps={{
                                            disableUnderline: true
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </FormControl>
                                <FormControl component="fieldset" error={Boolean()} className={classes.smallBoxLabel}>
                                    <FormLabel for="email">21-25</FormLabel>
                                    {/* <label for="email">Email address</label> */}
                                    <TextField
                                        className={classes.smallTextBox}
                                        onChange={(e) => setAge21(e.target.value)}
                                        value={age21}
                                        name="age[age21_25]"
                                        inputRef={register()}
                                        // label="Bootstrap"
                                        id="bootstrap-input"
                                        InputProps={{
                                            disableUnderline: true
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </FormControl>
                                <FormControl component="fieldset" error={Boolean()} className={classes.smallBoxLabel}>
                                    <FormLabel for="email">26-30</FormLabel>
                                    {/* <label for="email">Email address</label> */}
                                    <TextField
                                        className={classes.smallTextBox}
                                        onChange={(e) => setAge26(e.target.value)}
                                        value={age26}
                                        name="age[age26_30]"
                                        inputRef={register()}
                                        // label="Bootstrap"
                                        id="bootstrap-input"
                                        InputProps={{
                                            disableUnderline: true
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </FormControl>
                                <FormControl component="fieldset" error={Boolean()} className={classes.smallBoxLabel}>
                                    <FormLabel for="email">31-40</FormLabel>
                                    {/* <label for="email">Email address</label> */}
                                    <TextField
                                        className={classes.smallTextBox}
                                        onChange={(e) => setAge31(e.target.value)}
                                        value={age31}
                                        name="age[age31_40]"
                                        inputRef={register()}
                                        // label="Bootstrap"
                                        id="bootstrap-input"
                                        InputProps={{
                                            disableUnderline: true
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </FormControl>
                                <FormControl component="fieldset" error={Boolean()} className={classes.smallBoxLabel}>
                                    <FormLabel for="email">41-50</FormLabel>
                                    {/* <label for="email">Email address</label> */}
                                    <TextField
                                        className={classes.smallTextBox}
                                        onChange={(e) => setAge41(e.target.value)}
                                        value={age41}
                                        name="age[age41_50]"
                                        inputRef={register()}
                                        // label="Bootstrap"
                                        id="bootstrap-input"
                                        InputProps={{
                                            disableUnderline: true
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </FormControl>
                                <FormControl component="fieldset" error={Boolean()} className={classes.smallBoxLabel}>
                                    <FormLabel for="email">51-60</FormLabel>
                                    {/* <label for="email">Email address</label> */}
                                    <TextField
                                        className={classes.smallTextBox}
                                        onChange={(e) => setAge51(e.target.value)}
                                        value={age51}
                                        name="age[age51_60]"
                                        inputRef={register()}
                                        // label="Bootstrap"
                                        id="bootstrap-input"
                                        InputProps={{
                                            disableUnderline: true
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </FormControl>
                                <FormControl component="fieldset" error={Boolean()} className={classes.smallBoxLabel}>
                                    <FormLabel for="email">60+</FormLabel>
                                    {/* <label for="email">Email address</label> */}
                                    <TextField
                                        className={classes.smallTextBox}
                                        onChange={(e) => setAge60(e.target.value)}
                                        value={age60}
                                        name="age[age60]"
                                        inputRef={register()}
                                        // label="Bootstrap"
                                        id="bootstrap-input"
                                        InputProps={{
                                            disableUnderline: true
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={3} sm={3} xs={3}>
                                <div className={classes.formSectionTitleWrapper}>
                                    <span>Gender (Based on Google Analytics demographic cat)</span>
                                </div>
                                <FormControl component="fieldset" error={Boolean()} className={classes.smallBoxLabel}>
                                    <FormLabel for="male">Male</FormLabel>
                                    {/* <label for="email">Email address</label> */}
                                    <TextField
                                        className={classes.smallTextBox}
                                        onChange={(e) => setMale(e.target.value)}
                                        value={male}
                                        name="gender[male]"
                                        inputRef={register()}
                                        // label="Bootstrap"
                                        id="male"
                                        InputProps={{
                                            disableUnderline: true
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </FormControl>
                                <FormControl component="fieldset" error={Boolean()} className={classes.smallBoxLabel}>
                                    <FormLabel for="female">Female</FormLabel>
                                    {/* <label for="email">Email address</label> */}
                                    <TextField
                                        className={classes.smallTextBox}
                                        onChange={(e) => setFemale(e.target.value)}
                                        value={female}
                                        name="gender[female]"
                                        inputRef={register()}
                                        // label="Bootstrap"
                                        id="female"
                                        InputProps={{
                                            disableUnderline: true
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </FormControl>

                            </Grid>
                            <Grid item md={3} sm={3} xs={3}>
                                <div className={classes.formSectionTitleWrapper}>
                                    <span class="agetext">Demographic</span>
                                    <span>%</span>
                                </div>
                                {demographic.map((val, i) => {
                                    return (
                                        <FormControl key={i} component="fieldset" error={Boolean()} className={classes.smallBoxLabel}>
                                            <FormLabel for={val.name}>{val.value}</FormLabel>
                                            {/* <label for="email">Email address</label> */}
                                            <TextField
                                                className={classes.smallTextBox}
                                                onChange={(e) => handleDemoChange(e)}
                                                defaultValue={demo[0][val.name]}
                                                name={`demographic[${val.name}]`}
                                                inputRef={register()}
                                                // label="Bootstrap"
                                                id={val.name}
                                                InputProps={{
                                                    disableUnderline: true
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </FormControl>
                                    )
                                })}
                            </Grid>
                            <Grid item md={3} sm={3} xs={3}>
                                <div className={classes.formSectionTitleWrapper}>
                                    <span class="agetext">Hobbies & Interest</span>
                                    <span>%</span>
                                </div>
                                {['Television', 'Radio', 'Print', 'DigitalOnline', 'CinemaThreatre', 'ArtDesign', 'Blogging', 'Volunteering', 'Yoga', 'Reading'].map((val, i) => {
                                    return (
                                        <FormControl key={i} component="fieldset" error={Boolean()} className={classes.smallBoxLabel}>
                                            <FormLabel for={val}>{val}</FormLabel>
                                            {/* <label for="email">Email address</label> */}
                                            <TextField
                                                className={classes.smallTextBox}
                                                // onChange={(e) => handleDemoChange(e)}
                                                defaultValue={''}
                                                name={`hobbiesInterest[${val}]`}
                                                inputRef={register()}
                                                // label="Bootstrap"
                                                id={val}
                                                InputProps={{
                                                    disableUnderline: true
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </FormControl>
                                    )
                                })}
                            </Grid>
                        </Grid>
                    </div>
                    <Button className={classes.nextButton} type="submit">Next</Button>
                </form>
            </div>
        </div >
    )
}

