import React, { Component, useState, useEffect } from "react";
import axios from "axios";
import FormData from "form-data";
import fs from "fs";
import MainLayout from "../../../layouts/newMainLayout";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../../constants";
import "./pdfComp.css";
import Button from "@material-ui/core/Button";
import useStyles from "../../../components/StyleClasses";
import { useHistory } from "react-router-dom";

const currencyList = [
  { type: "GBP", symbol: "£" },
  { type: "USD", symbol: "$" },
  { type: "INR", symbol: "₹" },
  { type: "EUR", symbol: "€" },
];

const Export = () => {
  const access_token = window.localStorage.getItem("secret");
  const classes = useStyles();

  const dispatch = useDispatch();
  const MoProfileObj = useSelector((state) => state.MOProfileOBJ);
  const invoiceTableData = useSelector((state) => state.invoiceTableData);
  const refrence = useSelector((state) => state.invoiceRefrence);
  const [thePdf, setThePdf] = useState(null);
  const history = useHistory();
  const [subToatal, setSubtotal] = useState(0);
  const [showSubmitButton, setShowSubmitButton] = useState(true);

  function imagedata_to_image(imagedata) {
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    // canvas.width = imagedata.width;
    // canvas.height = imagedata.height;
    ctx.putImageData(imagedata, 0, 0);
    var image = new Image();
    image.src = canvas.toDataURL();
    return image;
  }

  function printDocument() {
    const input = document.getElementById("divToPrint");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      canvas.save();
      const pdf = new jsPDF("p", "pt", "a4", false);
      pdf.addImage(imgData, "PNG", 0, 0, 600, 0, undefined, false);
      // pdf.output('dataurlnewwindow');
      pdf.save("download.pdf");
      //setThePdf(pdf);
    });
  }

  var download = function () {
    const input = document.getElementById("divToPrint");
    html2canvas(input).then((canvas) => {
      var link = document.createElement("a");
      link.download = "Invoice.png";
      link.href = canvas.toDataURL();
      link.click();
    });
  };
  useEffect(() => {
    console.log("thepdf", thePdf);
  }, [thePdf]);
  var utc = new Date().toJSON().slice(0, 10).replace(/-/g, "/");
  var ddate = new Date();
  ddate.setDate(ddate.getDate() + 45);
  var duedate = ddate.toJSON().slice(0, 10).replace(/-/g, "/");

  useEffect(() => {
    axios
      .get(`${API}/mediaOwner/profile`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          console.log("profile data", res.data);
          dispatch({ type: "MO_Profile_OBJ", payload: res.data });
        }
        if (err) {
          console.log("adbutler error", err);
        }
      })
      .catch(function (error) {
        if (error) {
          console.log("adbutler error", error);
        }
      });
  }, []);

  useEffect(() => {
    const input = document.getElementById("divToPrint");
    html2canvas(input).then((canvas) => {
      canvas.toBlob((blob) => setThePdf(blob));
    });
  }, []);

  const sendInMAil = () => {
    const formData = new FormData();
    formData.append("name", MoProfileObj.name);

    formData.append("invoices", thePdf, "Invoice.png");

    formData.append("email", MoProfileObj.email);
    formData.append("publisherId", MoProfileObj.publisherId[0]);
    formData.append("username", MoProfileObj.username);
    console.log("formdata is>>>>>>>>>>>>>>>>>>", formData);
    axios
      .post(`${API}/mediaOwner/invoice`, formData, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res, err) => {
        if (res) {
          console.log("response sending pdf mail >>>>", res.data);
          setShowSubmitButton(false);
        }
        if (err) {
          console.log(err);
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };

  useEffect(() => {
    if (invoiceTableData) {
      let sbtotal = 0;
      invoiceTableData.forEach((e) => {
        if (
          parseFloat(e.data[4]?.replace(MoProfileObj.currency.symbol, "")) > 0
        )
          sbtotal += parseFloat(
            e.data[4].replace(MoProfileObj.currency.symbol, "")
          );
      });
      setSubtotal(Math.ceil(sbtotal * 100) / 100);
    }
  }, [invoiceTableData]);

  return (
    <div>
      <div>
        <Button
          className={classes.addActionBtn}
          variant="outlined"
          color="primary"
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            backgroundColor: "grey",
          }}
          onClick={() => history.push("/media-owner")}
        >
          Cancel
        </Button>
        <Button
          className={classes.addActionBtn}
          variant="outlined"
          color="primary"
          style={{ margin: "10px 20px" }}
          onClick={download}
          disabled={MoProfileObj?.vatPercent < 0}
        >
          Download
        </Button>
        {showSubmitButton && (
          <Button
            className={classes.addActionBtn}
            variant="outlined"
            color="primary"
            style={{ marginTop: "10px", marginBottom: "10px" }}
            onClick={sendInMAil}
            disabled={MoProfileObj?.vatPercent < 0}
          >
            Submit
          </Button>
        )}
        {!showSubmitButton && <sapn>Submitted!</sapn>}
      </div>
      {MoProfileObj?.vatPercent >= 0 && MoProfileObj?.address && (
        <div
          id="divToPrint"
          style={{
            backgroundColor: "#fff",
            width: "700px",
            // height: "1000px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              paddingTop: "90px",
            }}
          >
            <div style={{ width: "40%" }}>
              <span
                style={{ display: "block", fontSize: "30px", fontWeight: 700 }}
              >
                TAX INVOICE
              </span>
              <span style={{ display: "block", fontSize: "10px" }}>
                Brand Advance Ltd
              </span>
              <span style={{ display: "block", fontSize: "10px" }}>
                Tobacco Dock
              </span>
              <span style={{ display: "block", fontSize: "10px" }}>London</span>
              <span style={{ display: "block", fontSize: "10px" }}>
                E1W 2SF
              </span>
              <span style={{ display: "block", fontSize: "10px" }}>GBR</span>
            </div>
            <div style={{ width: "20%" }}>
              {/* <img src="https://wearebrandadvance.com/wp-content/uploads/2020/11/Brandadvancelogo.jpg" /> */}
              <span
                style={{ display: "block", fontSize: "12px", fontWeight: 500 }}
              >
                Invoice Date
              </span>
              <span style={{ display: "block", fontSize: "10px" }}>{utc}</span>
              <span
                style={{ display: "block", fontSize: "12px", fontWeight: 500 }}
              >
                Invoice Number
              </span>
              <span style={{ display: "block", fontSize: "10px" }}>
                INV-00001
              </span>

              <span
                style={{ display: "block", fontSize: "12px", fontWeight: 500 }}
              >
                VAT Number
              </span>
              <span style={{ display: "block", fontSize: "10px" }}>
                {MoProfileObj?.vatNumber}
              </span>
            </div>

            <div style={{ width: "20%" }}>
              <span style={{ display: "block", fontSize: "10px" }}>
                {MoProfileObj?.address}
              </span>
            </div>
          </div>
          <div style={{ marginLeft: "50%" }}>
            <span
              style={{ display: "block", fontSize: "12px", fontWeight: 500 }}
            >
              Reference
            </span>
            <span style={{ display: "block", fontSize: "10px" }}>
              {refrence}
            </span>
          </div>

          <div style={{ margin: "40px 20px" }}>
            <table>
              <tr>
                <th>Description</th>
                <th>Impressions</th>
                <th>CPM</th>
                <th>VAT %</th>
                <th>Net {MoProfileObj?.currency?.type}</th>
                <th>Gross {MoProfileObj?.currency?.type}</th>
              </tr>

              {invoiceTableData?.map((element) => {
                if (element?.data[4]) {
                  let str = element.data[4];
                  let numberVAl;
                  currencyList.forEach((e) => {
                    if (str.includes(e.symbol)) {
                      numberVAl = parseFloat(str.replace(e.symbol, ""));
                    }
                  });

                  if (numberVAl > 0)
                    return (
                      <tr>
                        <td>campaign id : {element.data[0]}</td>
                        <td>{element.data[1]}</td>
                        <td>{element.cpm}</td>
                        <td>
                          {MoProfileObj.vatPercent
                            ? MoProfileObj.vatPercent
                            : "0"}
                          %
                        </td>
                        <td>
                          {MoProfileObj.currency.symbol}
                          {numberVAl}
                        </td>
                        <td>
                          {MoProfileObj.currency.symbol}
                          {(numberVAl * (100 + MoProfileObj.vatPercent)) / 100}
                        </td>
                      </tr>
                    );
                }
              })}
            </table>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              <div
                style={{
                  width: "38%",
                  fontSize: "12px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div style={{ width: "40%" }}>
                    <span style={{ display: "block", marginBottom: "20px" }}>
                      Subtotal
                    </span>
                    <span style={{ display: "block" }}>
                      Total VAT{" "}
                      {MoProfileObj?.vatPercent
                        ? MoProfileObj?.vatPercent
                        : "0"}
                      %
                    </span>
                  </div>
                  <div style={{ width: "45%" }}>
                    <span style={{ display: "block", marginBottom: "20px" }}>
                      {MoProfileObj.currency.symbol}
                      {subToatal}
                    </span>
                    <span style={{ display: "block" }}>
                      {MoProfileObj.currency.symbol}
                      {(subToatal * MoProfileObj?.vatPercent) / 100}
                    </span>
                  </div>
                </div>
                <hr
                  style={{
                    color: "grey",
                    backgroundColor: "grey",
                    height: 0.5,
                    width: "100%",
                  }}
                />
                <div style={{ display: "flex" }}>
                  <div style={{ width: "40%" }}>
                    <span style={{ display: "block", marginBottom: "20px" }}>
                      Total {MoProfileObj.currency.symbol}
                    </span>
                  </div>
                  <div style={{ width: "45%" }}>
                    <span style={{ display: "block", marginBottom: "20px" }}>
                      {MoProfileObj.currency.symbol}
                      {(subToatal * (100 + MoProfileObj?.vatPercent)) / 100}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              width: "80%",
              margin: "auto",
              fontSize: "12px",
              marginBottom: "30px",
            }}
          >
            <span
              style={{ display: "block", fontSize: "14px", fontWeight: 500 }}
            >
              Due Date: {duedate}
            </span>
            When paying by cheque, please complete this payment advice, detach
            and post to the address provided. Online payment preferred - use our
            account 99-04-04 987654321 or use the 'Pay online now' link to pay
            via PayPal with your credit card.
          </div>
          <hr style={{ borderTop: "dotted 1px" }} />
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              paddingTop: "40px",
            }}
          >
            <div style={{ width: "60%" }}>
              <span
                style={{
                  display: "block",
                  fontSize: "30px",
                  fontWeight: 400,
                  marginBottom: "40px",
                }}
              >
                PAYMENT ADVICE
              </span>
              <div style={{ display: "flex" }}>
                <div
                  style={{ width: "10%", textAlign: "end", fontWeight: 500 }}
                >
                  To :{" "}
                </div>
                <div
                  style={{
                    width: "40%",
                    wordWrap: "break-word",
                    paddingLeft: "8px",
                  }}
                >
                  {MoProfileObj?.address}
                </div>
              </div>
            </div>
            <div style={{ width: "38%" }}>
              <div style={{ display: "flex" }}>
                <div style={{ width: "48%" }}>
                  {/* <img src="https://wearebrandadvance.com/wp-content/uploads/2020/11/Brandadvancelogo.jpg" /> */}
                  <span
                    style={{
                      display: "block",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    Customer
                  </span>
                  <span
                    style={{
                      display: "block",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    Invoice Number
                  </span>
                  <span
                    style={{
                      display: "block",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    Amount Due
                  </span>
                  <span
                    style={{
                      display: "block",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    Due Date
                  </span>
                </div>
                <div style={{ width: "48%" }}>
                  <span style={{ display: "block", fontSize: "12px" }}>
                    Brand Advance Ltd
                  </span>
                  <span style={{ display: "block", fontSize: "12px" }}>
                    INV-00001
                  </span>
                  <span style={{ display: "block", fontSize: "12px" }}>
                    {MoProfileObj.currency.symbol}
                    {(subToatal * (100 + MoProfileObj?.vatPercent)) / 100}
                  </span>
                  <span style={{ display: "block", fontSize: "12px" }}>
                    {duedate}
                  </span>
                </div>
              </div>
              <hr
                style={{
                  color: "grey",
                  backgroundColor: "grey",
                  height: 0.5,
                  width: "100%",
                }}
              />
              <div style={{ display: "flex" }}>
                <span
                  style={{
                    display: "block",
                    fontSize: "12px",
                    fontWeight: 500,
                    width: "40%",
                  }}
                >
                  Account Enclosed
                </span>
                <div style={{ width: "55%" }}>
                  <hr
                    style={{
                      color: "black",
                      backgroundColor: "black",
                      height: 0.3,
                      width: "100%",
                      marginTop: "25px",
                    }}
                  />
                  <span style={{ display: "block", fontSize: "8px" }}>
                    Enter the amount you are paying above
                  </span>
                </div>
              </div>
            </div>
          </div>
          <span
            style={{
              display: "block",
              fontSize: "10px",
              marginTop: "70px",
              marginLeft: "20px",
              marginBottom: "20px",
            }}
          >
            Company Registration No: 02181833. Registered Office: 23 Main
            Street, Central City, Marineville, MV12 3CC.
          </span>
        </div>
      )}
      {(!MoProfileObj?.vatPercent >= 0 || !MoProfileObj.address) && (
        <h3>
          Your Details are not set in the database. please contact Brand Advance
          to update your details
        </h3>
      )}
    </div>
  );
};

export default MainLayout(Export);

//const NewComponent = () => {
//   return (
// <div style={{ display: "none" }} id="capture">
//   <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
//   <style
//     type="text/css"
//     dangerouslySetInnerHTML={{
//       __html:
//         '\n<!--\nspan.cls_004{font-family:"Calibri Bold",serif;font-size:9.1px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none}\ndiv.cls_004{font-family:"Calibri Bold",serif;font-size:9.1px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none}\nspan.cls_003{font-family:"Calibri",serif;font-size:9.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}\ndiv.cls_003{font-family:"Calibri",serif;font-size:9.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}\nspan.cls_002{font-family:"Calibri",serif;font-size:24.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}\ndiv.cls_002{font-family:"Calibri",serif;font-size:24.1px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}\nspan.cls_005{font-family:"Calibri Bold",serif;font-size:10.9px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none}\ndiv.cls_005{font-family:"Calibri Bold",serif;font-size:10.9px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none}\nspan.cls_009{font-family:"Calibri Bold",serif;font-size:10.9px;color:rgb(0,136,179);font-weight:bold;font-style:normal;text-decoration: underline}\ndiv.cls_009{font-family:"Calibri Bold",serif;font-size:10.9px;color:rgb(0,136,179);font-weight:bold;font-style:normal;text-decoration: none}\nspan.cls_007{font-family:"Calibri",serif;font-size:8.1px;color:rgb(51,51,51);font-weight:normal;font-style:normal;text-decoration: none}\ndiv.cls_007{font-family:"Calibri",serif;font-size:8.1px;color:rgb(51,51,51);font-weight:normal;font-style:normal;text-decoration: none}\nspan.cls_008{font-family:"Calibri",serif;font-size:7.2px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}\ndiv.cls_008{font-family:"Calibri",serif;font-size:7.2px;color:rgb(0,0,0);font-weight:normal;font-style:normal;text-decoration: none}\n-->\n',
//     }}
//   />
//   <div
//     style={{
//       position: "absolute",
//       left: "50%",
//       marginLeft: "-297px",
//       top: "0px",
//       width: "595px",
//       height: "841px",
//       borderStyle: "outset",
//       overflow: "hidden",
//     }}
//   >
//     <div style={{ position: "absolute", left: "0px", top: "0px" }}>
//       <img
//         src="2f51d550-5827-11ec-a980-0cc47a792c0a_id_2f51d550-5827-11ec-a980-0cc47a792c0a_files/background1.jpg"
//         width={595}
//         height={841}
//       />
//     </div>
//     <div
//       style={{ position: "absolute", left: "351.47px", top: "114.41px" }}
//       className="cls_004"
//     >
//       <span className="cls_004">Invoice Date</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "459.18px", top: "115.51px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">Orange Demo Co</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "28.34px", top: "112.16px" }}
//       className="cls_002"
//     >
//       <span className="cls_002">TAX INVOICE</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "351.47px", top: "125.21px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">18 Nov 2021</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "459.18px", top: "128.51px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">23 Main Street</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "351.47px", top: "143.10px" }}
//       className="cls_004"
//     >
//       <span className="cls_004">Invoice Number</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "459.18px", top: "141.51px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">Central City</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "56.69px", top: "147.14px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">Brand Advance Ltd</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "351.47px", top: "153.90px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">INV-0046</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "459.18px", top: "154.51px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">Marineville</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "56.69px", top: "160.14px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">Tobacco Dock</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "459.18px", top: "167.51px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">MV12 3CC</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "56.69px", top: "173.14px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">London</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "351.47px", top: "171.78px" }}
//       className="cls_004"
//     >
//       <span className="cls_004">Reference</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "351.47px", top: "182.58px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">October 2021</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "56.69px", top: "186.14px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">E1W 2SF</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "56.69px", top: "199.14px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">GBR</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "351.47px", top: "200.47px" }}
//       className="cls_004"
//     >
//       <span className="cls_004">VAT Number</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "351.47px", top: "211.27px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">GB 123456789</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "31.18px", top: "304.32px" }}
//       className="cls_004"
//     >
//       <span className="cls_004">Description</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "315.73px", top: "304.32px" }}
//       className="cls_004"
//     >
//       <span className="cls_004">Quantity</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "392.86px", top: "304.32px" }}
//       className="cls_004"
//     >
//       <span className="cls_004">Unit Price</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "469.22px", top: "304.32px" }}
//       className="cls_004"
//     >
//       <span className="cls_004">VAT</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "516.15px", top: "304.32px" }}
//       className="cls_004"
//     >
//       <span className="cls_004">Amount GBP</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "31.18px", top: "327.20px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">Campaign 1</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "332.68px", top: "327.20px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">1.00</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "413.47px", top: "327.20px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">2.40</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "467.72px", top: "327.20px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">20%</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "548.10px", top: "327.20px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">2.40</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "31.18px", top: "338.00px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">600 impressions</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "31.18px", top: "348.80px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">£4 cpm</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "31.18px", top: "371.69px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">Campaign 2</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "332.68px", top: "371.69px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">1.00</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "392.98px", top: "371.69px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">56,000.00</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "467.72px", top: "371.69px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">20%</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "527.61px", top: "371.69px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">56,000.00</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "31.18px", top: "382.49px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">8,000,000 impressions</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "31.18px", top: "393.29px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">£7 cpm</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "452.76px", top: "416.17px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">Subtotal</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "527.61px", top: "416.17px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">56,002.40</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "423.47px", top: "438.31px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">TOTAL VAT 20%</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "527.61px", top: "438.31px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">11,200.48</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "442.54px", top: "461.20px" }}
//       className="cls_004"
//     >
//       <span className="cls_004">TOTAL GBP</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "527.41px", top: "461.20px" }}
//       className="cls_004"
//     >
//       <span className="cls_004">67,202.88</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "28.34px", top: "505.74px" }}
//       className="cls_005"
//     >
//       <span className="cls_005">Due Date: 18 Dec 2021</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "28.34px", top: "518.97px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">
//         When paying by cheque, please complete this payment advice, detach
//         and post to the address provided.
//       </span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "28.34px", top: "529.77px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">
//         Online payment preferred - use our account 99-04-04 987654321 or use
//         the 'Pay online now' link to pay via PayPal with your credit card.
//       </span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "28.34px", top: "583.29px" }}
//       className="cls_009"
//     >
//       <span className="cls_009"> </span>
//       <a href="https://in.xero.com/x8WY11vKU8NGdDOh1mnIeD2xP7kOUdDSX8V3hmSi?utm_source=pdf">
//         View and pay online now
//       </a>{" "}
//     </div>
//     <div
//       style={{ position: "absolute", left: "342.97px", top: "648.71px" }}
//       className="cls_004"
//     >
//       <span className="cls_004">Customer</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "428.00px", top: "648.71px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">Brand Advance Ltd</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "31.18px", top: "646.46px" }}
//       className="cls_002"
//     >
//       <span className="cls_002">PAYMENT ADVICE</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "342.97px", top: "662.34px" }}
//       className="cls_004"
//     >
//       <span className="cls_004">Invoice Number</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "428.00px", top: "662.34px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">INV-0046</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "342.97px", top: "679.56px" }}
//       className="cls_004"
//     >
//       <span className="cls_004">Amount Due</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "428.00px", top: "679.56px" }}
//       className="cls_004"
//     >
//       <span className="cls_004">67,202.88</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "342.97px", top: "693.19px" }}
//       className="cls_004"
//     >
//       <span className="cls_004">Due Date</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "428.00px", top: "693.19px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">18 Dec 2021</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "59.52px", top: "700.83px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">To: Orange Demo Co</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "342.97px", top: "710.41px" }}
//       className="cls_004"
//     >
//       <span className="cls_004">Amount Enclosed</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "76.53px", top: "714.00px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">23 Main Street</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "76.53px", top: "727.00px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">Central City</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "438.82px", top: "727.78px" }}
//       className="cls_007"
//     >
//       <span className="cls_007">Enter the amount you are paying above</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "76.53px", top: "740.00px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">Marineville</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "76.53px", top: "753.00px" }}
//       className="cls_003"
//     >
//       <span className="cls_003">MV12 3CC</span>
//     </div>
//     <div
//       style={{ position: "absolute", left: "28.34px", top: "797.40px" }}
//       className="cls_008"
//     >
//       <span className="cls_008">
//         Company Registration No: 02181833. Registered Office: 23 Main
//         Street, Central City, Marineville, MV12 3CC.
//       </span>
//     </div>
//   </div>
// </div>
//   );
// };

// export default MainLayout(NewComponent);
