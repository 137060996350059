import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter as Router } from "react-router-dom";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Reducer from "./store/reducer";
import { createEpicMiddleware } from "redux-observable";
import createSagaMiddleware from "redux-saga";
import { rootEpic } from "./store/epics";
import rootSaga from "./sagas";
import Route from "./Routes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SnackbarProvider } from 'notistack';


const sagaMiddleware = createSagaMiddleware();
const observableMiddleware = createEpicMiddleware();
const store = createStore(Reducer, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(rootSaga);

let search = window.location.search;
let secret = localStorage.getItem('secret');
if(window.location.pathname == '/consultant/pending-survey' && search.includes('id')){
  localStorage.setItem('pendingSurvey', '/consultant/pending-survey'+search);
  if(secret == 'null' || !secret){
    window.location.href = '/login?iam=creativeconsultant';
  }
}

// Create a client
const queryClient = new QueryClient()

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <SnackbarProvider>
      <Provider store={store}>
        <Router>
          <Route />
        </Router>
      </Provider>
    </SnackbarProvider>
  </QueryClientProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
