import React from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { API, SECRET_KEY } from "../constants/index";

const LoginCheck = (testurl, redirectTo, history) => {
  const access_token = window.localStorage.getItem(SECRET_KEY);
  //const history = useHistory();
  axios
    .get(`${API}${testurl}`, {
      headers: { Authorization: `Bearer ${access_token}` },
    })
    .then((res, err) => {
      if (res) {
        if (redirectTo) history.push(redirectTo);
      }
    })
    .catch(function (error) {
      if (error && error.response) {
        if (error.response.status === 401) {
          window.localStorage.setItem(SECRET_KEY, null);
          window.localStorage.setItem("role", null);
          history.push("/login");
        }
      } else {
      }
    });
};

export default LoginCheck;
