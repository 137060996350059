import React from 'react';
import { useHistory } from "react-router-dom";

import { Box, Card, CardContent, CardHeader, CardMedia, Grid, Tooltip, Typography } from "@material-ui/core/";
import { fade, makeStyles, withStyles } from "@material-ui/core/styles";

import MainLayout from "../../layouts/newMainLayoutPlanner";

// import SSP from "../../assets/campaignButton-New.png"
// import CreativeChecker from "../../assets/Creative-Checker-New.png"
// import HeatMap from "../../assets/Heat-Button-New.png"
// import CulturalIntelligence from "../../assets/Cultural-Intelligence-New.png"
// import PersonasImg from "../../assets/personas.png"
// import CampaignImg from "../../assets/campaign.png"

//logos
import CTV from "../../assets/logos/brandAdvanceCTVNew.png";
import CreativeChecker from "../../assets/logos/brandAdvanceCreative.png";
import Influencer from "../../assets/logos/brandAdvanceInfluencer.png";
import SSP from "../../assets/logos/brandAdvanceSSPNew.png";
import IntelligenceImg from "../../assets/logos/culturalIntelligenceLogo.png";
import HeatMap from "../../assets/logos/heatMapLogo.png";
import CampaignImg from "../../assets/logos/mediaPlanLogo.png";
import PersonasImg from "../../assets/logos/personasLogo.png";
import QnA from "../../assets/logos/qnaLogo.png";

const useStyles = makeStyles(theme => ({
    cardHeaderTitle: {
        textAlign: "center",
        "& .MuiTypography-root.MuiCardHeader-title.MuiTypography-h5" : {
            fontWeight: 700,
            fontSize: "40px !important",
            color: "#fff"
        }
    },
    cardWrapper: {
        // background: "linear-gradient(to right, #9211a2, #4911a2)",
        background: "#d8dadb",
        height: "240px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "0 1rem",
        cursor: "pointer",
        transition: "0.5s",
        boxShadow: "linear-gradient(to right, #9211a2, #4911a2)",
        "&:hover": {
            boxShadow: "0px 15px 20px rgba(204, 49, 223, 0.4)",
            color: "#fff",
            transform: "translateY(-7px)"
        }
    },
    imgSize : {
        // width: "27vh"
        "&.MuiCardMedia-media": {
            width: "200px",
            marginBottom: "5rem",
            cursor: "pointer",
            borderRadius: "24px",
            border: "6px solid #fff", 
            backgroundColor: "#ffffff"
        }
    },
    containerCard: {
        position: "relative",
        top: "72px",
        left: "21px"
    },
    tooltipCustomize: {
        "&.makeStyles-tooltipCustomize-43": {
            backgroundColor: "green",
            color: "red",
            fontSize: "20px",
            fontWeight: "500"
        }
    }
}))

const styles = (theme) => ({
    tooltip: {
      backgroundColor: '#222831',
      color: '#fff',
      fontSize: 14,
      borderRadius: "10px"
    },
    arrow: {
      color: '#222831',
    },
  });

const navItem = [
    {
        image: CampaignImg,
        component: "Campaigns",
        alt:"Campaigns",
        toolText: "Creative and Book Media Plans across Minority Owned Digital // TV // Radio and OOH  with our Media Plan Creator." 
    },
    {
        image: HeatMap,
        component: "Heatmap",
        alt:"Heatmap",
        toolText: "Works by marring location data based on ad impressions registered across the Brand Advance SSP network to the CONTEXT of the articles with which the ads are served."
    },
    {
        image: IntelligenceImg,
        component: "Intelligence",
        alt:"Intelligence",
        toolText: "Access to Global Data & Insights around Underrepresented Consumer Habits // Trends and Topics //  Diversity Media Landscape // Tailored Insights."
    },
    {
        image: SSP,
        component: "Brand Advance SSP",
        alt:"Brand Advance SSP",
        toolText: "Funding the growth of USA Minority Owned and Operated Media through Brand Advance SSP // DSP."
    },
    {
        image: PersonasImg,
        component: "Personas",
        alt:"Personas",
        toolText: "Custom create Consumer Personas to match with media relevant to your target audience."
    },
    {
        image: CreativeChecker,
        component: "Creative Checker",
        alt:"Creative Checker",
        toolText: "Check your creative against the community you are representing and//or trying to reach - 24hr results."
    },
    {
        image: CTV,
        component: "CTV",
        alt:"CTV",
        toolText: "Reaching Minority Owned and Operated CTV & Digital Entertainment Platforms through Brand Advance CTV."
    },
    {
        image: Influencer,
        component: "Influencer",
        alt:"Influencer",
        toolText: "Access to BA Influencer platform giving insights and campaigns management across diverse Influencer globally."
    }
]

const MediaPlanner = () => {
  const classes = useStyles()
  const history = useHistory()

  const CustomTooltip = withStyles(styles)(Tooltip);

  const moduleHandler = (item) => {
    switch(item.component) {
        case ("Campaigns") : history.push("/media-planner/campaign"); break;
        case ("Personas") : history.push("/media-planner/personas"); break;
        case ("Intelligence") : history.push("/media-planner/intelligence"); break;
        case ("Creative Checker") : history.push("/media-planner/creative-consultancy"); break;
        case ("Brand Advance SSP") : history.push("/media-planner/campaign/report"); break;
        case ("Heatmap") : history.push("/media-planner/heatMap"); break;
        case ("Influencer") : history.push("/media-planner/influencer"); break;
        case ("FAQ") : history.push("/media-planner/FAQ"); break;
        default: return
    }
  }

  return (
    <Grid container spacing={2} className={classes.containerCard}>
        {
            navItem.map(item => 
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <div onClick={() => moduleHandler(item)}>
                        {
                            item.image ? 
                            <CustomTooltip arrow={true} title={item.toolText} placement="top">
                                <CardMedia
                                    square
                                    component="img"
                                    image={item.image}
                                    alt={item.alt}
                                    className={ classes.imgSize }
                                /> 
                            </CustomTooltip> : <CardHeader className={classes.cardHeaderTitle} title={item.component} />
                        }
                    </div>
                </Grid>
            )
        }
    </Grid>
  )
}

export default MainLayout(MediaPlanner)