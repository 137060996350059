/* eslint-disable no-unused-expressions */
import {
    Box,
    CardContent,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
} from "@material-ui/core/";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useStyles from "../../../components/StyleClasses";
import { API, SECRET_KEY } from "../../../constants/index";
import "../../consultant/consultant.css";

const PreviewSurveyQuestions = ({ selectedListID }) => {
    const access_token = window.localStorage.getItem(SECRET_KEY);
    const history = useHistory();
    const classes = useStyles();
    const [loading, setloading] = useState(false);
    const [videos, setVideo] = useState([]);
    const [images, setImage] = useState([]);
    const [title, setTitle] = useState("");
    const [sizeImg, setSizeImg] = useState("minimize");
    const [sizeVid, setSizeVid] = useState("minimize");
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const [index, setIndex] = useState('');
    const [extraQuestions, setExtraQuestions] = useState([
        {
            name: 'Please provide further comments around any question you rated to less than 4?',
            type: 'text',
            value: ''
        },
        {
            name: 'Please provide any suggestions to improve this creative?',
            type: 'text',
            value: ''
        },
        {
            name: 'Please provide and overall score between 1-10',
            type: 'text',
            value: ''
        }]);

    const getSurveyQuestions = async () => {
        setloading(true);
        await axios
            .get(`${API}/mediaPlanner/questions/getQuestions`, {
                headers: { Authorization: `Bearer ${access_token}` },
            })
            .then((res, err) => {
                if (res) {
                    setloading(false);
                    getConsultancyData(res.data)
                }
            })
            .catch(function (error) {
                if (error) {
                    setloading(false);
                }
            });
    };

    const getConsultancyData = async (questions) => {
        setloading(true);
        await axios
            .get(`${API}/mediaPlanner/creativeconsultancy/${selectedListID}`, {
                headers: { Authorization: `Bearer ${access_token}` },
            })
            .then((res) => {
                setloading(false);
                if (res && res.data) {
                    let result = res.data;
                    setTitle(result.title);
                    // let que = [];
                    // let ids = [];

                    // result?.questions.map(i=>{
                    //     if(i._id) {
                    //         ids.push(i._id);
                    //     }else{
                    //         ids.push(i);
                    //     }
                    // });
                    // let ress = ["6377862637a3d30fab563845",
                    // "6377866837a3d30fab563846", "6377867a37a3d30fab563847", ...ids]
                    // let createdQueIds = [];
                    let createdQues = [];
                    // && !result.questions.map(i=> i.hasOwnProperty('name'))[0]
                    if (result?.createdSurvey) {
                        createdQues = [];
                        questions?.map((dt, i)=> {
                            let v = result?.createdSurvey?.questions?.filter(j => j.qid == dt._id || j._id == dt._id);
                            if(v.length){
                              createdQues.push(v[0])
                            }
                          });
                        // ["6377862637a3d30fab563845",
                        //     "6377866837a3d30fab563846", "6377867a37a3d30fab563847"].map(i => {
                        //         if ((result?.createdSurvey?.questions.findIndex(j => i == j._id || i == j.qid) == -1)) {
                        //             createdQueIds.push(i);
                        //         }
                        //     })
                        setSelectedQuestions(createdQues);
                    }

                    // questions.map(i => {
                    //     if (ress.includes(i._id)) {
                    //         que.push(i);
                    //     }
                    //     if (createdQueIds.includes(i._id)) {
                    //         createdQues.push(i);
                    //     }
                    // });
                    setImage(result.picture);
                    setVideo(result.video);
                    // let q = createdQues.length ? createdQues : que
                }
            });
    };


    useEffect(() => {
        getSurveyQuestions();
    }, []);

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h3>Preview Survey</h3>
            </div>
            <div className={classes.boxShadow} style={{ marginTop: "10px" }}>
                <Grid item xs={12}>
                    <Box className={classes.cardBox}>
                        <CardContent>
                            <Grid container spacing={2} className={classes.cardspace}>
                                <Grid item md={12} sm={12} xs={12}>
                                    <h3>
                                        <u>Questionnaire: </u> {title}
                                    </h3>
                                </Grid>
                                <Grid container md={12} sm={12} xs={12}>
                                    {sizeImg !== "maximize" && videos.length ? videos.map((v, idx) => (
                                        <Grid
                                            item
                                            md={(sizeVid == "minimize" && index !== 'v-' + idx) ? 6 : index == 'v-' + idx ? 12 : 6}
                                            sm={(sizeVid == "minimize" && index !== 'v-' + idx) ? 6 : index == 'v-' + idx ? 12 : 6}
                                            xs={(sizeVid == "minimize" && index !== 'v-' + idx) ? 6 : index == 'v-' + idx ? 12 : 6}
                                            style={{
                                                position: (sizeVid == "minimize" && index !== 'v-' + idx) ? "relative" : index == 'v-' + idx ? "absolute" : "relative",
                                                top: (sizeVid == "minimize" && index !== 'v-' + idx) ? "" : index == 'v-' + idx ? "-8px" : "",
                                                left: (sizeVid == "minimize" && index !== 'v-' + idx) ? "" : index == 'v-' + idx ? "-8px" : "",
                                                zIndex: index == 'v-' + idx ? 99 : 1,
                                            }}
                                        >
                                            {v ? (
                                                <video
                                                    width={sizeVid == "minimize" && index !== 'v-' + idx ? "450" : index == 'v-' + idx ? "1010" : "450"}
                                                    height={sizeVid == "minimize" && index !== 'v-' + idx ? "250" : index == 'v-' + idx ? "475" : "250"}
                                                    controls
                                                    style={{ background: "#fff" }}
                                                >
                                                    <source src={v} type="video/mp4" />
                                                </video>
                                            ) : null}
                                            {v ? (
                                                <div className="maxMin">
                                                    {sizeVid == "maximize" ? (
                                                        <span
                                                            className="min"
                                                            onClick={() => {
                                                                setSizeVid("minimize");
                                                                setIndex('');
                                                            }}
                                                        >
                                                            -
                                                        </span>
                                                    ) : null}
                                                    {sizeVid == "minimize" ? (
                                                        <span
                                                            className="max"
                                                            onClick={() => {
                                                                setSizeVid("maximize");
                                                                setSizeImg("minimize");
                                                                setIndex('v-' + idx);
                                                            }}
                                                        >
                                                            +
                                                        </span>
                                                    ) : null}
                                                </div>
                                            ) : null}
                                        </Grid>
                                    )) : null}
                                    {sizeVid !== "maximize" && images.length ? images.map((image, idx) => (
                                        <Grid
                                            item
                                            md={(sizeImg == 'minimize' && index !== 'i-' + idx) ? 6 : index === 'i-' + idx ? 12 : 6}
                                            sm={(sizeImg == 'minimize' && index !== 'i-' + idx) ? 6 : index === 'i-' + idx ? 12 : 6}
                                            xs={(sizeImg == 'minimize' && index !== 'i-' + idx) ? 6 : index === 'i-' + idx ? 12 : 6}
                                            style={{
                                                position: (sizeImg == 'minimize' && index !== 'i-' + idx) ? "relative" : index === 'i-' + idx ? "absolute" : "relative",
                                                top: (sizeImg == 'minimize' && index !== 'i-' + idx) ? "" : index === 'i-' + idx ? "-8px" : "",
                                                left: (sizeImg == 'minimize' && index !== 'i-' + idx) ? "" : index === 'i-' + idx ? "-8px" : "",
                                                zIndex: index === 'i-' + idx ? 99 : 1,
                                            }}
                                        >
                                            {image ? (
                                                <img
                                                    src={image}
                                                    // width={sizeImg == "minimize" && index !== 'i-' + idx ? "450" : index === 'i-' + idx ? "1010" : "450"}
                                                    height={sizeImg == "minimize" && index !== 'i-' + idx ? "250" : index === 'i-' + idx ? "475" : "250"}
                                                />
                                            ) : null}
                                            {image ? (
                                                <div className="maxMin">
                                                    {sizeImg == "maximize" ? (
                                                        <span
                                                            className="min"
                                                            onClick={() => {
                                                                setIndex('');
                                                                setSizeImg("minimize");
                                                            }}
                                                        >
                                                            -
                                                        </span>
                                                    ) : null}
                                                    {sizeImg == "minimize" ? (
                                                        <span
                                                            className="max"
                                                            onClick={() => {
                                                                setSizeImg("maximize");
                                                                setSizeVid("minimize");
                                                                setIndex('i-' + idx);
                                                            }}
                                                        >
                                                            +
                                                        </span>
                                                    ) : null}
                                                </div>
                                            ) : null}
                                        </Grid>
                                    )) : null}
                                </Grid>
                                {selectedQuestions?.filter(i => !["6377862637a3d30fab563845", "6377866837a3d30fab563846", "6377867a37a3d30fab563847"].includes(i.qid || i._id)).map((quest, id) => {
                                    if (quest.type == 'radio') {
                                        return (
                                            <Grid item md={12} sm={12} xs={12}>
                                                <h4>{quest.name}</h4>
                                                <Grid container md={12} sm={12} xs={12}>
                                                    <Grid item md={2} sm={2} xs={2} />
                                                    <Grid item md={3} sm={3} xs={3}>
                                                        <label style={{ padding: "0 39px 0 2px" }}>1</label>
                                                        <label style={{ padding: "0 39px 0 2px" }}>2</label>
                                                        <label style={{ padding: "0 39px 0 2px" }}>3</label>
                                                        <label style={{ padding: "0 39px 0 2px" }}>4</label>
                                                        <label style={{ padding: "0 39px 0 2px" }}>5</label>
                                                    </Grid>
                                                </Grid>
                                                <Grid container md={12} sm={12} xs={12}>
                                                    <Grid item md={2} sm={2} xs={2}>
                                                        <label style={{ lineHeight: 4, fontSize: "11px" }}>
                                                            Strongly Disagree
                                                        </label>
                                                    </Grid>
                                                    <Grid item md={4} sm={4} xs={4}>
                                                        <RadioGroup
                                                            style={{ flexDirection: "row" }}
                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                            defaultValue={quest.value}
                                                            name={`radio-buttons-group-${id}`}
                                                        >
                                                            <FormControlLabel
                                                                value="1"
                                                                control={
                                                                    <Radio
                                                                        disabled={true}
                                                                        checked={quest.value == 1 ? true : false}
                                                                    />
                                                                }
                                                            />
                                                            <FormControlLabel
                                                                value={2}
                                                                control={
                                                                    <Radio
                                                                        disabled={true}
                                                                        checked={quest.value == 2 ? true : false}
                                                                    />
                                                                }
                                                            />
                                                            <FormControlLabel
                                                                value={3}
                                                                control={
                                                                    <Radio
                                                                        disabled={true}
                                                                        checked={quest.value == 3 ? true : false}
                                                                    />
                                                                }
                                                            />
                                                            <FormControlLabel
                                                                value={4}
                                                                control={
                                                                    <Radio
                                                                        disabled={true}
                                                                        checked={quest.value == 4 ? true : false}
                                                                    />
                                                                }
                                                            />
                                                            <FormControlLabel
                                                                value={5}
                                                                control={
                                                                    <Radio
                                                                        disabled={true}
                                                                        checked={quest.value == 5 ? true : false}
                                                                    />
                                                                }
                                                            />
                                                        </RadioGroup>
                                                    </Grid>
                                                    <Grid item md={4} sm={4} xs={4}>
                                                        <label style={{ lineHeight: 4, fontSize: "11px" }}>
                                                            Strongly Agree
                                                        </label>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )
                                    }
                                    if (quest.type == 'text') {
                                        return (<Grid item md={12} sm={12} xs={12}>
                                            <h4>{quest.name}</h4>
                                            <Grid container md={8} sm={8} xs={8}>
                                                <TextField
                                                    className={classes.customfield}
                                                    disabled="true"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    id="search"
                                                    label=""
                                                    value={quest.value}
                                                />
                                            </Grid>
                                        </Grid>)
                                    }
                                })}
                                {selectedQuestions?.filter(i => ["6377862637a3d30fab563845", "6377866837a3d30fab563846", "6377867a37a3d30fab563847"].includes(i.qid || i._id)).map((quest, id) => {
                                   if (quest.type == 'text') {
                                        return (<Grid item md={12} sm={12} xs={12}>
                                            <h4>{quest.name}</h4>
                                            <Grid container md={8} sm={8} xs={8}>
                                                <TextField
                                                    className={classes.customfield}
                                                    disabled="true"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    id="search"
                                                    label=""
                                                    value={quest.value}
                                                />
                                            </Grid>
                                        </Grid>)
                                    }
                                })}
                                {/* {extraQuestions.map(eq =>(
                                    <Grid item md={12} sm={12} xs={12}>
                                        <h4>{eq.name}</h4>
                                        <Grid container md={8} sm={8} xs={8}>
                                            <TextField
                                                className={classes.customfield}
                                                disabled="true"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="search"
                                                label=""
                                                value={eq.value}
                                            />
                                        </Grid>
                                    </Grid>
                                ))} */}
                            </Grid>
                        </CardContent>
                    </Box>
                </Grid>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    {loading && <CircularProgress />}
                </div>
            </div>
        </div>
    );
};
export default PreviewSurveyQuestions;
