import React, { memo, useEffect, useMemo, useState } from "react";
import useStyles from "../../components/StyleClasses";
import "./Step5.css";
// import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

function unCamelCase(str) {
  return (
    str
      // insert a space between lower & upper
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      // space before last upper in a sequence followed by lower
      .replace(/\b([A-Z]+)([A-Z])([a-z])/, "$1 $2$3")
      // uppercase the first character
      .replace(/^./, function (str) {
        return str.toUpperCase();
      })
  );
}

const Step5 = ({ data, setStep, downloadPdfCallback, CHANNELS, savefn, saveLiftfn }) => {
  const classes = useStyles();
  const Data = useMemo(() => data, [data]);
  const [Model, setModel] = useState(Data[0]);
  const [client_cost, setClientCost] = useState(0);
  const [active_id, setActiveId] = useState(0)
  const [SaveLoader, setSaveLoader] = useState(false);
  const [showLowerImpressionWarning, setShowLowerImpressionWarning] = useState(Model?.mainTable?.Impressions < 999999)

  
  useEffect(() => {
    console.log("data_test", data)
    console.log("setStep", setStep)
    console.log("downloadPdfCallback", downloadPdfCallback)
    console.log("CHANNELS", CHANNELS)
    setClientCost(() => {
      let cost = 0
      Data.forEach((channel_details) => {
        cost += Number(channel_details.Budget);
      });
      return cost
    });
  }, []);

  useEffect(() => {
    if(Model?.mainTable?.Impressions < 999999){
      setShowLowerImpressionWarning(true)
    }
    console.log("Model", Model)
  }, [Model])

  // useEffect(() => {
  //   if (CHANNELS.Formats.length > 0) {
  //     setModel(model => {
  //       return {
  //         ...model,
  //         mainTable: {
  //           ...model.mainTable,
  //           Formats: CHANNELS.Formats
  //         }
  //       }
      
  //     })
  //   }
  // }, [CHANNELS])


  if (!Data) return null;

  return (
    <div className={classes.boxShadow} style={{marginTop: "0px"}}>
      {showLowerImpressionWarning && (
        <Dialog
          open={showLowerImpressionWarning}
          onBackdropClick="false"
          onClose={() => {
            setShowLowerImpressionWarning(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Insufficient impression for Brand Uplift
          </DialogTitle>
          <DialogActions>
            <button
              className={classes.addActionBtn}
              onClick={() => {
                setShowLowerImpressionWarning(false);
                setStep(0);
              }}
            >
              Go to details
            </button>
            <button
              className={classes.addActionBtn}
              onClick={() => {
                setShowLowerImpressionWarning(false);
              }}
            >
              Continue
            </button>
          </DialogActions>
        </Dialog>
      )}
      <div>
        {Data.map((channel_table_data, i) => {
          return (
            <button
              key={i}
              onClick={() => {setModel(Data[i]);setActiveId(i)}}
              className={classes.addActionBtn}
              style={active_id === i ? {margin: "20px", backgroundColor: "blue", color: "white"} : {margin: "20px", backgroundColor: "grey", color: "black"}}
            >
              {channel_table_data.mainTable.MediaType}
            </button>
          );
        })}
      </div>
      <div style={{ display: "flex" }}>
        {Model && (
          <div>
            <table>
              <tr>
                <td>Camapaign : </td>
                <td>{Model.Campaign}</td>
              </tr>
              <tr>
                <td>Client : </td>
                <td>{Model.Client}</td>
              </tr>
              <tr>
                <td>Dates : </td>
                <td>{Model.Dates}</td>
              </tr>
              <tr>
                <td>Budget : </td>
                <td>{Model.currency_symbol}{Model.Budget}</td>
              </tr>
              <tr>
                <td>Campaign Type : </td>
                <td>{Model.CampaignType}</td>
              </tr>
              <tr>
                <td>Client Lead : </td>
                <td>{Model.ClientLead}</td>
              </tr>
              <tr>
                <td>Campaign Manager : </td>
                <td>{Model.CampaignManager}</td>
              </tr>
            </table>
          </div>
        )}
        <div
          style={{
            // backgroundImage:
            //   "url(" +
            //   "https://wearebrandadvance.com/wp-content/uploads/2020/11/Brandadvancelogo.jpg" +
            //   ")",
            // backgroundPosition: "center",
            // backgroundSize: "contain",
            // backgroundRepeat: "no-repeat",
            width: "200px",
            marginLeft: "200px",
          }}
        ></div>
      </div>
      <div className="maintable">
        {Model && (
          <table>
            <tr>
              {Object.keys(Model.mainTable).map((e) => (
                <th>{unCamelCase(e)}</th>
              ))}
            </tr>

            <tr>
              {Object.values(Model.mainTable).map((e) => (
                <td>{`${e}`}</td>
              ))}
            </tr>
          </table>
        )}
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {Model && (
          <>
            <table className="belowtable">
              <tr>
                <th>Overview</th>
                <th>Total Costs</th>
              </tr>
              {/* <tr>
            <td>OLV Cost </td>
            <td>£18323.36</td>
          </tr> */}
              <tr>
                <td>Client Cost</td>
                <td>{Model.currency_symbol}{client_cost}</td>
              </tr>
              <tr>
                <th>Total Client Cost to be billed</th>
                <th>{Model.currency_symbol}{client_cost}</th>
              </tr>
            </table>
            <table style={{ marginTop: "20px" }}>
              <tr>
                <td>Media Plan Created By :</td>
                <td>{Model.CreatedBy}</td>
              </tr>
              <tr>
                <td>Campaign Manager Check :</td>
                <td> </td>
              </tr>
              <tr>
                <td>Brand Uplift Check :</td>
                <td> </td>
              </tr>
              <tr>
                <td>Final Check :</td>
                <td> </td>
              </tr>
            </table>
          </>
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <button
          className={classes.addActionBtn}
          onClick={() => {
            let chanArr =
              CHANNELS &&
              CHANNELS.length &&
              CHANNELS.filter((chan) => {
                if (chan.channelName.toLowerCase() === "digital/online") {
                  return chan;
                }
              });
            if (chanArr.length >= 1) {
              setStep(4);
            } else {
              setStep(2);
            }
          }}
        >
          Previous
        </button>
        {/* <button
          className={classes.addActionBtn}
          onClick={() => {
            setStep(6);
          }}
        >
          Next
        </button> */}
        {!SaveLoader && (
            <button
              className={classes.addActionBtn}
              onClick={() => {
                savefn();
                let chanArr =
                  CHANNELS &&
                  CHANNELS.length &&
                  CHANNELS.filter((chan) => {
                    if (chan.channelName.toLowerCase() === "digital") {
                      return chan;
                    }
                  });
                if (chanArr.length >= 1) {
                  saveLiftfn();
                }
              }}
              style={{ backgroundColor: "green" }}
            >
              Save
            </button>
          )}
      </div>
    </div>
  );
};
export default Step5;


// const StepOld = ({ data, setStep, downloadPdfCallback, CHANNELS, MockData2 }) => {
//   const imp =
//     data.DigitalVideo &&
//     data.DigitalVideo.mainTable &&
//     data.DigitalVideo.mainTable.Impressions;
//   const classes = useStyles();
//   const Data = useMemo(() => data, [data]);
//   const [flag, setFlag] = useState(false);
//   const [Model, setModel] = useState(Data[Object.keys(Data)[0]]);
//   const [client_cost, setClientCost] = useState(0);


//   useEffect(() => {
//     setClientCost(() => {
//       let cost = 0;
//       Object.keys(Data).forEach((key) => (cost += Number(Data[key].Budget)));
//       return cost;
//     }); 
//     console.log({MockData2: MockData2()})
//   }, []);
//   console.log({Data})

  
//   useEffect(() => {
//     if (imp <= 999999) {
//       setFlag(true);
//     } else {
//       setFlag(false);
//     }
//   }, []);
  
//   if(!Data) return null

//   return (
//     <div className={classes.boxShadow}>
//       {flag && (
//         <Dialog
//           open={flag}
//           onBackdropClick="false"
//           onClose={() => {
//             setFlag(false);
//           }}
//           aria-labelledby="alert-dialog-title"
//           aria-describedby="alert-dialog-description"
//         >
//           <DialogTitle id="alert-dialog-title">
//             Insufficient impression for Brand Uplift
//           </DialogTitle>
//           <DialogActions>
//             <button
//               className={classes.addActionBtn}
//               onClick={() => {
//                 setFlag(false);
//                 setStep(0);
//               }}
//             >
//               Go to details
//             </button>
//             <button
//               className={classes.addActionBtn}
//               onClick={() => {
//                 setFlag(false);
//               }}
//             >
//               Continue
//             </button>
//           </DialogActions>
//         </Dialog>
//       )}
//       <div>
//         {/* <button
//         style={{
//           padding: "0",
//           backgroundColor: "transparent",
//           border: "none",
//         }}
//         onClick={downloadPdfCallback}
//       >
//         <span style={{ marginBottom: "10px" }}>PDF</span>{" "}
//         <CloudDownloadIcon width="30px" />
//       </button> */}


//         {Object.keys(Data).map((element, index) => {
//           if (!element.includes("setStep"))
//             return (
//               <button
//                 key={index}
//                 className={classes.addActionBtn}
//                 style={
//                   Model.id === index
//                     ? {
//                         margin: "20px",
//                         backgroundColor: "blue",
//                         color: "white",
//                       }
//                     : {
//                         margin: "20px",
//                         backgroundColor: "grey",
//                         color: "black",
//                       }
//                 }
//                 onClick={(e) => setModel(Data[element])}
//               >
//                 {unCamelCase(element)}
//               </button>
//             );
//         })}
//       </div>
//       <div style={{ display: "flex" }}>
//         {Model && (
//           <div>
//             <table>
//               <tr>
//                 <td>Camapaign : </td>
//                 <td>{Model.Campaign}</td>
//               </tr>
//               <tr>
//                 <td>Client : </td>
//                 <td>{Model.Client}</td>
//               </tr>
//               <tr>
//                 <td>Dates : </td>
//                 <td>{Model.Dates}</td>
//               </tr>
//               <tr>
//                 <td>Budget : </td>
//                 <td>{Model.Budget}</td>
//               </tr>
//               <tr>
//                 <td>Campaign Type : </td>
//                 <td>{Model.CampaignType}</td>
//               </tr>
//               <tr>
//                 <td>Client Lead : </td>
//                 <td>{Model.ClientLead}</td>
//               </tr>
//               <tr>
//                 <td>Campaign Manager : </td>
//                 <td>{Model.CampaignManager}</td>
//               </tr>
//             </table>
//           </div>
//         )}
//         <div
//           style={{
//             backgroundImage:
//               "url(" +
//               "https://wearebrandadvance.com/wp-content/uploads/2020/11/Brandadvancelogo.jpg" +
//               ")",
//             backgroundPosition: "center",
//             backgroundSize: "contain",
//             backgroundRepeat: "no-repeat",
//             width: "200px",
//             marginLeft: "200px",
//           }}
//         ></div>
//       </div>
//       <div className="maintable">
//         {Model && (
//           <table>
//             <tr>
//               {Object.keys(Model.mainTable).map((e) => (
//                 <th>{unCamelCase(e)}</th>
//               ))}
//             </tr>
//             <tr>
//               {Object.keys(Model).forEach((e) => (
//                 <td> </td>
//               ))}
//             </tr>
//             <tr>
//               {Object.values(Model.mainTable).map((e) => (
//                 <td>{`${e}`}</td>
//               ))}
//             </tr>
//           </table>
//         )}
//         {/* {Object.keys(Data).map((key, i) => {
//           return (
//             <table key={i} style={{ margin: "10px 0 10px 0" }}>
//               <tr>
//                 {Object.keys(Data[key].mainTable).map((e, index) => (
//                   <th key={index}>{unCamelCase(e)}</th>
//                 ))}
//               </tr>
//               <tr>
//                 {Object.keys(Model).forEach((e) => (
//                   <td> </td>
//                 ))}
//               </tr>
//               <tr>
//                 {Object.values(Data[key].mainTable).map((e, i) => (
//                   <td key={i}>{e}</td>
//                 ))}
//               </tr>
//             </table>
//           );
//         })} */}
//       </div>
//       <div style={{ display: "flex", justifyContent: "space-between" }}>
//         {Model && (
//           <>
//             <table className="belowtable">
//               <tr>
//                 <th>Overview</th>
//                 <th>Total Costs</th>
//               </tr>
//               {/* <tr>
//             <td>OLV Cost </td>
//             <td>£18323.36</td>
//           </tr> */}
//               <tr>
//                 <td>Client Cost</td>
//                 <td>£{client_cost}</td>
//               </tr>
//               <tr>
//                 <th>Total Client Cost to be billed</th>
//                 <th>£{client_cost}</th>
//               </tr>
//             </table>
//             <table style={{ marginTop: "20px" }}>
//               <tr>
//                 <td>Media Plan Created By :</td>
//                 <td>{Model.CreatedBy}</td>
//               </tr>
//               <tr>
//                 <td>Campaign Manager Check :</td>
//                 <td> </td>
//               </tr>
//               <tr>
//                 <td>Brand Uplift Check :</td>
//                 <td> </td>
//               </tr>
//               <tr>
//                 <td>Final Check :</td>
//                 <td> </td>
//               </tr>
//             </table>
//           </>
//         )}
//       </div>
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "space-between",
//           marginTop: "20px",
//         }}
//       >
//         <button
//           className={classes.addActionBtn}
//           onClick={() => {
//             let chanArr =
//               CHANNELS &&
//               CHANNELS.length &&
//               CHANNELS.filter((chan) => {
//                 if (chan.channelName.toLowerCase() === "digital") {
//                   return chan;
//                 }
//               });
//             if (chanArr.length >= 1) {
//               setStep(4);
//             } else {
//               setStep(2);
//             }
//           }}
//         >
//           Previous
//         </button>
//         <button
//           className={classes.addActionBtn}
//           onClick={() => {
//             setStep(6);
//           }}
//         >
//           Next
//         </button>
//       </div>
//     </div>
//   );
// };

