import { Button, CircularProgress } from "@material-ui/core"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"
import MenuItem from "@material-ui/core/MenuItem"
import Modal from "@material-ui/core/Modal"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"
import CloudUploadIcon from "@material-ui/icons/CloudUpload"
import SaveIcon from "@material-ui/icons/Save"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min"
import { API, SECRET_KEY } from "../../../constants/index"
import MainLayout from "../../../layouts/adminMainLayout"
import "./influencer.css"

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: "10px",
        width: "580px",
    },
    button: {
        margin: theme.spacing(1),
    },
}))

const getCampaign = (campaign_id) => {
    const access_token = window.localStorage.getItem(SECRET_KEY)
    return axios.get(`${API}/admin/influencer/get-campaign/${campaign_id}`, {
        headers: { Authorization: `Bearer ${access_token}` },
    })
}

const getContents = (campaign_id) => {
    const access_token = window.localStorage.getItem(SECRET_KEY)
    return axios.get(`${API}/admin/influencer/get-contents/${campaign_id}`, {
        headers: { Authorization: `Bearer ${access_token}` },
    })
}

const Influencer_campaign = () => {
    const history = useHistory()
    const classes = useStyles()
    const campaign_id = useParams().id
    const [loading, setLoading] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [campaignData, setCampaignData] = useState({})
    const [uploadData, setUploadData] = useState({
        title: "",
        description: "",
        video_uri: "",
    })
    const [contentList, setContentList] = useState([])
    const [commentContentId, setCommentContentId] = useState("")
    const [commentList, setCommentList] = useState([])
    const [comment, setComment] = useState("")
    const [commentModalOpen, setCommentModalOpen] = useState(false)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [deleteContentId, setDeleteContentId] = useState("")
    const [deleteContentModalTitle, setDeleteContentModalTitle] = useState("")
    const [editModalOpen, setEditModalOpen] = useState(false)
    const [editContentDetails, setEditContentDetails] = useState({
        _id: "",
        title: "",
        description: "",
        video_uri: "",
    })

    const handleModalOpen = () => {
        setModalOpen(true)
    }

    const handleModalClose = () => {
        setModalOpen(false)
    }

    const handleCommentModalClose = () => {
        setCommentModalOpen(false)
    }

    const handleModalChange = (e, fn, entity) => {
        fn({ ...uploadData, [entity]: e.target.value })
    }

    const getContentComments = (content_id) => {
        const access_token = window.localStorage.getItem(SECRET_KEY)
        axios
            .get(`${API}/admin/influencer/get-content-comments/${content_id}`, {
                headers: { Authorization: `Bearer ${access_token}` },
            })
            .then((res) => {
                console.log(res)
                setCommentList(res.data.comments)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const handleCommentsModalOpen = (contentId) => {
        setCommentContentId(contentId)
        getContentComments(contentId)
        setCommentModalOpen(true)
    }

    const handleAddComment = () => {
        const access_token = window.localStorage.getItem(SECRET_KEY)
        const userId = localStorage.getItem("ID")
        axios
            .post(
                `${API}/admin/influencer/add-content-comment/${commentContentId}`,
                { comment, user: userId },
                {
                    headers: { Authorization: `Bearer ${access_token}` },
                }
            )
            .then((res) => {
                console.log(res)
                getContentComments(commentContentId)
                setComment("")
            })
            .catch((err) => {
                console.log(err)
                setComment("")
            })
    }

    const handleDeleteModalOpen = (id) => {
        setDeleteContentModalTitle("")
        setDeleteContentId(id)
        setDeleteModalOpen(true)
    }

    const handleDeleteCampaignContent = () => {
        const access_token = window.localStorage.getItem(SECRET_KEY)
        axios
            .delete(`${API}/admin/influencer/delete-content/${deleteContentId}`, {
                headers: { Authorization: `Bearer ${access_token}` },
            })
            .then((res) => {
                console.log(res)
                setContentList(contentList.filter((item) => item._id !== deleteContentId))
                setDeleteModalOpen(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const handleEditModalOpen = (id, title, description, video_uri) => {
        setEditContentDetails({
            _id: id,
            title: title,
            description: description,
            video_uri: video_uri,
        })
        setEditModalOpen(true)
    }

    const handleEditContent = () => {
        const access_token = window.localStorage.getItem(SECRET_KEY)
        axios
            .put(
                `${API}/admin/influencer/edit-content/${editContentDetails._id}`,
                {
                    title: editContentDetails.title,
                    description: editContentDetails.description,
                    video_uri: editContentDetails.video_uri,
                },
                {
                    headers: { Authorization: `Bearer ${access_token}` },
                }
            )
            .then((res) => {
                console.log(res)
                getContents(campaign_id)
                    .then((res) => {
                        console.log(res)
                        setLoading(true)
                        setContentList(res.data.influencerContents)
                        setLoading(false)
                    })
                    .catch((err) => {
                        console.log(err)
                        setLoading(false)
                    })

                setEditModalOpen(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const uploadVideo = () => {
        // const access_token = window.localStorage.getItem(SECRET_KEY)
        // const formData = new FormData()
        // formData.append("title", uploadData.title)
        // formData.append("description", uploadData.description)
        // formData.append("influencer_campaign", campaign_id)
        // formData.append("file", uploadData.file)
        // axios
        //     .post(`${API}/admin/influencer/file-upload`, formData, {
        //         headers: { Authorization: `Bearer ${access_token}`, "Content-Type": "multipart/form-data" },
        //     })
        //     .then((res) => {
        //         console.log(res)
        //         setModalOpen(false)
        //     })
        //     .catch((err) => {
        //         console.log(err)
        //     })

        const access_token = window.localStorage.getItem(SECRET_KEY)
        axios
            .post(
                `${API}/admin/influencer/file-upload`,
                {
                    title: uploadData.title,
                    description: uploadData.description,
                    influencer_campaign: campaign_id,
                    video_uri: uploadData.video_uri,
                },
                {
                    headers: { Authorization: `Bearer ${access_token}` },
                }
            )
            .then((res) => {
                console.log(res)
                getContents(campaign_id)
                    .then((res) => {
                        console.log(res)
                        setLoading(true)
                        setContentList(res.data.influencerContents)
                        setLoading(false)
                    })
                    .catch((err) => {
                        console.log(err)
                        setLoading(false)
                    })
                setModalOpen(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        setLoading(true)
        getCampaign(campaign_id)
            .then((res) => {
                console.log(res.data.influencerCampaign)
                setCampaignData(res.data.influencerCampaign)
                getContents(campaign_id)
                    .then((res) => {
                        console.log(res)
                        setContentList(res.data.influencerContents)
                        setLoading(false)
                    })
                    .catch((err) => {
                        setLoading(false)
                        console.log(err)
                    })
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                console.log(err)
            })
    }, [campaign_id])

    useEffect(() => {
        if (modalOpen === false) {
            setUploadData({
                title: "",
                description: "",
                file: "",
            })
        }
    }, [modalOpen])

    return (
        <div>
            <div className="head-section">
                {/* back button */}
                <div className="back_btn_cont">
                    <Button variant="contained" className="back_btn" onClick={() => history.push('/admin/ba-influencer')}>
                    &#8592;
                    </Button>
                    <h1 className="title">{campaignData.title}</h1>
                </div>
                <h3>
                    Description: <span className="card_contents">{campaignData.description}</span>
                </h3>
                <h3>
                    Companies:{" "}
                    <span className="card_contents">
                        {campaignData.companies?.map((item) => item.companyName).join(", ")}
                    </span>
                </h3>
                <p>
                    <strong>Note: </strong>Only Video files are supported now.
                </p>
                <Button variant="contained" className="add_campaign" onClick={handleModalOpen}>
                    + Add Content
                </Button>
            </div>
            <div className="influencer-body">
                <div className="campaigns-list">
                    {contentList.map((item) => {
                        return (
                            <div key={item._id} className="video_cards">
                                <iframe
                                    title="vimeo-player"
                                    src={item.video_uri}
                                    width="100%"
                                    height="60%"
                                    allowFullScreen
                                    allow="autoplay; fullscreen; picture-in-picture"
                                ></iframe>
                                <div className="video_content">
                                    <h3 className="campaign_title">
                                        Title:&nbsp;&nbsp;<span className="card_contents">{item.title}</span>
                                    </h3>
                                    <h3 className="campaign_desc">
                                        Description:&nbsp;&nbsp;
                                        <span className="card_contents">{item.description}</span>
                                    </h3>
                                    <h3 className="campaign_desc">
                                        Status:&nbsp;&nbsp;
                                        <span className="card_contents">{item.status}</span>
                                    </h3>
                                    {item.status === "rejected" ? (
                                        <h3 className="campaign_desc">
                                            Rejection Note:&nbsp;&nbsp;
                                            <span className="card_contents">{item.rejection_note}</span>
                                        </h3>
                                    ) : (
                                        <h3 className="campaign_desc">
                                            Note:&nbsp;&nbsp;<span className="card_contents">-</span>
                                        </h3>
                                    )}
                                    <div className="video_action_btn_cont">
                                        <Button
                                            className="campaign_edit_btn"
                                            variant="contained"
                                            onClick={() =>
                                                handleEditModalOpen(
                                                    item._id,
                                                    item.title,
                                                    item.description,
                                                    item.video_uri
                                                )
                                            }
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            className="campaign_view_btn content_comment_btn"
                                            variant="contained"
                                            onClick={() => {
                                                // setCommentContentId(item._id)
                                                handleCommentsModalOpen(item._id)
                                            }}
                                        >
                                            Comments
                                        </Button>
                                        <Button
                                            className="campaign_delete_btn"
                                            variant="contained"
                                            onClick={() => handleDeleteModalOpen(item._id)}
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>

            {/* Create Campaign Modal */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modalOpen}
                onClose={handleModalClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={modalOpen}>
                    <div className={classes.paper}>
                        <h2 className="campaign_modal_title">Upload Video</h2>
                        <div className="campaign-modal-input-cont">
                            <TextField
                                id="campaign_title"
                                label="Title"
                                variant="outlined"
                                onChange={(e) => handleModalChange(e, setUploadData, "title")}
                            />
                            <TextField
                                id="campaign_description"
                                label="Description"
                                variant="outlined"
                                onChange={(e) => handleModalChange(e, setUploadData, "description")}
                            />
                            <TextField
                                id="campaign_file"
                                label="Video URL"
                                variant="outlined"
                                // value={uploadData.file.name || " "}
                                style={{ color: "black" }}
                                onChange={(e) => handleModalChange(e, setUploadData, "video_uri")}
                            />
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                {/* <div>
                                    <input
                                        accept="video/*"
                                        className={classes.input}
                                        id="video-influencer-upload"
                                        multiple
                                        name="video"
                                        type="file"
                                        style={{ display: "none" }}
                                        onChange={(e) => handleModalChange(e, setUploadData, "file")}
                                    />
                                    <label htmlFor="video-influencer-upload">
                                        <Button
                                            variant="contained"
                                            color="default"
                                            component="span"
                                            className={`${classes.button} upload_btn`}
                                            startIcon={<CloudUploadIcon />}
                                        >
                                            Upload
                                        </Button>
                                    </label>
                                </div> */}
                                <div className="modal-submit-campaign">
                                    <Button
                                        variant="contained"
                                        color="default"
                                        component="span"
                                        className={`${classes.button} submit_campaign_btn`}
                                        startIcon={<SaveIcon />}
                                        onClick={() => uploadVideo()}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>

            {/* Comments Modal */}

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={commentModalOpen}
                onClose={handleCommentModalClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={commentModalOpen}>
                    <div className={classes.paper}>
                        <h2 className="campaign_modal_title">Comments</h2>
                        <div className="comment-list-cont">
                            {commentList.map((item, index) => {
                                return (
                                    <div key={index} className="comment-list-item">
                                        <h3 className="comment-list-item-user">
                                            {item.user?.name || "Admin"}:{" "}
                                            <span className="comment-list-item-comment">{item.comment}</span>{" "}
                                        </h3>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="campaign-modal-input-cont">
                            <TextField
                                id="campaign_title"
                                label="Add Comment"
                                variant="outlined"
                                onChange={(e) => {
                                    setComment(e.target.value)
                                }}
                                value={comment}
                            />
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <div className="modal-submit-comment">
                                    <Button
                                        variant="contained"
                                        color="default"
                                        component="span"
                                        className={`${classes.button} submit_campaign_btn modal-submit-btn`}
                                        onClick={() => {
                                            handleAddComment()
                                        }}
                                    >
                                        Add Comment
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>

            {/* Delete content Modal */}

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={deleteModalOpen}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title">Are you sure you want to delete this content?</h2>
                        <p id="transition-modal-description">
                            This action cannot be undone.
                            <br />
                            <br /> Write the content title{" "}
                            <span className="delete_modal_campaign_name">
                                {contentList.filter((item) => item._id === deleteContentId)[0]?.title}
                            </span>{" "}
                            to confirm.
                        </p>
                        <div className="campaign-modal-input-cont">
                            <TextField
                                id="campaign_name_confirmation"
                                label="Campaign Name"
                                variant="outlined"
                                onChange={(e) => setDeleteContentModalTitle(e.target.value)}
                                value={deleteContentModalTitle}
                            />
                            <div className="modal-submit-campaign">
                                <Button
                                    variant="contained"
                                    className="campaign_delete_btn"
                                    onClick={handleDeleteCampaignContent}
                                    disabled={
                                        contentList.filter((item) => item._id === deleteContentId)[0]?.title !==
                                        deleteContentModalTitle
                                    }
                                >
                                    Delete
                                </Button>
                                <Button
                                    variant="contained"
                                    className="submit_campaign_btn"
                                    onClick={() => setDeleteModalOpen(false)}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>

            {/* Edit content Modal */}

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={editModalOpen}
                onClose={() => setEditModalOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={editModalOpen}>
                    <div className={classes.paper}>
                        <h2 className="campaign_modal_title">Edit Campaign</h2>
                        <div className="campaign-modal-input-cont">
                            <TextField
                                id="campaign_title"
                                label="Title"
                                variant="outlined"
                                value={editContentDetails.title}
                                onChange={(e) =>
                                    setEditContentDetails({ ...editContentDetails, title: e.target.value })
                                }
                            />
                            <TextField
                                id="campaign_description"
                                label="Description"
                                variant="outlined"
                                value={editContentDetails.description}
                                onChange={(e) =>
                                    setEditContentDetails({ ...editContentDetails, description: e.target.value })
                                }
                            />
                            <TextField
                                id="content_video_url"
                                label="Video URL"
                                variant="outlined"
                                onChange={(e) =>
                                    setEditContentDetails({ ...editContentDetails, video_uri: e.target.value })
                                }
                                value={editContentDetails.video_uri}
                            />
                            <div className="modal-submit-campaign">
                                <Button variant="contained" className="submit_campaign_btn" onClick={handleEditContent}>
                                    Update
                                </Button>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>

            <div style={{ display: "flex", justifyContent: "center" }}>{loading && <CircularProgress />}</div>
        </div>
    )
}

export default MainLayout(Influencer_campaign)
