import React, { useState, useEffect } from "react";
import Layout from "../../layouts/moform";
import useStyles from "../../components/StyleClasses";
import StepWraper from "./stepWrapper";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import Checkbox from "@material-ui/core/Checkbox";

import FormGroup from "@material-ui/core/FormGroup";
import LinearProgress from "@material-ui/core/LinearProgress";

import TextareaAutosize from "@material-ui/core/TextareaAutosize";

//Button
import Button from "@material-ui/core/Button";

//Icon
import SearchIcon from "@material-ui/icons/Search";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import InputAdornment from "@material-ui/core/InputAdornment";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ControlPointTwoToneIcon from "@material-ui/icons/ControlPointTwoTone";
import AssignmentIcon from "@material-ui/icons/Assignment";
import DateRangeIcon from "@material-ui/icons/DateRange";
import BusinessIcon from "@material-ui/icons/Business";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ExploreIcon from "@material-ui/icons/Explore";
import EuroIcon from "@material-ui/icons/Euro";

//TextField
import TextField from "@material-ui/core/TextField";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Input from "@material-ui/core/Input";

import Paper from "@material-ui/core/Paper";
import StarBorderRoundedIcon from "@material-ui/icons/StarBorderRounded";
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import IconButton from "@material-ui/core/IconButton";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import searchMediaOwners from "../../selectors/search";
import EditIcon from "@material-ui/icons/Edit";
import { API, SECRET_KEY } from "../../constants/index";
import axios from "axios";
import { Avatar } from "@material-ui/core";

import { enGB } from "date-fns/locale";
import { DateRangePicker, START_DATE, END_DATE } from "react-nice-dates";
import "react-nice-dates/build/style.css";
import { file } from "@babel/types";

import * as actions from "../../redux/actions/user";
import ShowAlert from "../../components/alerts";
import { channel } from "redux-saga";

import { makeStyles, withStyles } from "@material-ui/core/styles";

const MOFORM = () => {
  const [step, setStep] = useState(0);
  const [channels, setChannels] = useState([]);
  const [Age, setAge] = useState([]);
  const [Relationshiop, setRelationshiop] = useState([]);
  const [Income, setIncome] = useState([]);
  const [Personality, setPersonality] = useState([]);
  const [Motivations, setMotivations] = useState([]);
  const [RecieveSpend, setRecieveSpend] = useState([]);
  const [incStaffLevel, setIncStaffLevel] = useState(null);
  const classes = useStyles();
  const history = useHistory();

  const sendData = () => {
    console.log("sending data");
  };

  const myImg = () => {
    return (
      <img
        src={
          "https://lh4.googleusercontent.com/bcD-1R0JL5r8wdLz0gJtBHbXqh4bCYj3wSFdeO4ec734UeuXfKKvazwiG-rNolOC9kAy2tf2ANHIDjRcpo7sLqW8opNYpSFHEisOwLB9WIJz1lKlY7u-FIpywUUroyCc=w2238"
        }
        style={{ width: "100%", marginBottom: "30px" }}
      ></img>
    );
  };
  const myFooter = (prev, next) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <button
          className={classes.addActionBtn}
          onClick={() => {
            if (step !== 0) {
              setStep(step - 1);
            } else {
              history.push("/");
            }
          }}
        >
          {step !== 0 ? "Previous" : "Exit"}
        </button>
        <button
          className={classes.addActionBtn}
          onClick={() => {
            if (step !== 6) {
              setStep(step + 1);
            } else {
              sendData();
            }
          }}
        >
          {step === 6 ? "Save" : "Next"}
        </button>
      </div>
    );
  };
  const myRadio = (title, value, handleClick, options, is_id) => {
    return (
      <FormControl component="fieldset" style={{ display: "block" }}>
        <h3 style={{ fontWeight: "600", marginBottom: "0" }}>{title}</h3>
        {/* {errors.channel.error && (
          <span style={{ color: "red" }}>{errors.channel.txt}</span>
        )} */}
        <RadioGroup
          aria-label={title}
          name={title}
          //value={value}
          onChange={(e) => {
            // setErrors({
            //   ...errors,
            //   channel: { error: false, txt: "" },
            // });
            handleClick(e.target.value);
          }}
        >
          {options?.map((opt) => (
            <FormControlLabel
              value={is_id ? opt._id : opt}
              control={<Radio />}
              label={is_id ? opt.name : opt}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  };
  const myMultiRadio = (title, selected, handleClick, options, is_id) => {
    const idsList = [];
    if (is_id) {
      idsList = selected.map((e) => e._id);
    }
    return (
      <div style={{ marginBottom: "30px" }}>
        <h3 style={{ fontWeight: "600", marginBottom: "0" }}>{title}</h3>
        <FormGroup>
          {options.map((option) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    is_id
                      ? idsList.includes(option._id)
                      : selected.includes(option)
                  }
                  onChange={(e) => {
                    let newArr = [...selected];
                    if (is_id) {
                      e.target.checked
                        ? newArr.push(option)
                        : (newArr = newArr.filter((e) => e._id != option._id));
                    } else {
                      e.target.checked
                        ? newArr.push(option)
                        : (newArr = newArr.filter((e) => e != option));
                    }

                    handleClick(newArr);
                    console.log(newArr);
                  }}
                  name={is_id ? option.name : option}
                />
              }
              label={is_id ? option.name : option}
            />
          ))}
        </FormGroup>
      </div>
    );
  };
  const myTextField = (title, value, handleClick) => {
    return (
      <div>
        <TextField
          //   error={errors.name.error}
          //   helperText={errors.name.txt}
          fullWidth
          label={title}
          variant="outlined"
          style={{ display: "block", marginBottom: "10px" }}
          //value={value}
          onChange={(e) => {
            // setErrors({
            //   ...errors,
            //   name: { error: false, txt: "" },
            // });
            handleClick(e.target.value);
          }}
        />
      </div>
    );
  };

  const myDropDown = (title, value, handleClick, options, is_id) => {
    return (
      <div>
        <TextField
          //   error={errors.Ifrequency.error}
          //   helperText={errors.Ifrequency.txt}

          style={{ display: "block", marginBottom: "10px" }}
          id={title}
          select
          fullWidth
          label={title}
          value={value}
          onChange={(e) => {
            // setErrors({
            //   ...errors,
            //   Ifrequency: { error: false, txt: "" },
            // });
            handleClick(e.target.value);
          }}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
        >
          <option disabled key={0} value={0}>
            Select...
          </option>
          {options.map((option) => (
            <option
              key={is_id ? option._id : option}
              value={is_id ? option.name : option}
            >
              {is_id ? option._id : option}
            </option>
          ))}
        </TextField>
      </div>
    );
  };

  const step0 = () => {
    return (
      <div className={classes.boxShadow} style={{ marginTop: "20px" }}>
        <div className={classes.customfield}>
          {myImg()}
          {myTextField("Name", "", () => console.log("sss"))}
          {myTextField("Email", "", () => console.log("sss"))}
        </div>
      </div>
    );
  };
  const step1 = () => {
    return (
      <div className={classes.boxShadow} style={{ marginTop: "20px" }}>
        <div className={classes.customfield}>
          {myImg()}
          {myDropDown(
            "When was your organisation established?",
            0,
            () => console.log("sss"),
            ["2017", "2018", "2019", "2020"],
            false
          )}
          {myTextField("How many people work at your organisation? ", "", () =>
            console.log("sss")
          )}
          {myTextField("How many titles do you own?", "", () =>
            console.log("sss")
          )}
          {myTextField("Which country are you based in?", "", () =>
            console.log("sss")
          )}
          {myRadio(
            "Has your company increased staff levels in 2020?",
            incStaffLevel,
            setIncStaffLevel,
            ["yes", "No"],
            false
          )}
          {myMultiRadio(
            "Channels",
            channels,
            setChannels,
            [
              "Print",
              "TV",
              "Radio",
              "OOH",
              "Digital/Online",
              "Social Media",
              "Partnership",
            ],
            false
          )}
          {[
            "What is the circulation of your print and total readership?",
            "What is the cover price of the Publication",
            "TV Reach",
            "Radio Reach",
            "OOH Reach",
            "Social Media Reach",
          ].map((e) => myTextField(e, "", () => console.log("sss")))}
        </div>
      </div>
    );
  };
  const step2 = () => {
    return (
      <div className={classes.boxShadow} style={{ marginTop: "20px" }}>
        <div className={classes.customfield}>
          {myImg()}
          {myDropDown(
            "Please list the top 5 countries that you see traffic from",
            0,
            () => console.log("sss"),
            ["testData", "England", "India", "USA", "country1", "country2"],
            false
          )}

          {[
            "What percentage of your audience views your website on Desktop?",
            "What percentage of your audience views your website on Mobile devices?",
            "What percentage of your audience views your website on a Tablet?",
            "Split across age groups (Total users + 18-24, 25-34, 35-44, 45-54, 55-64, 65+)",
            "What's your gender split between male and female?",
            "What is the average session duration for users?",
            "On average what's the number of pages visited?",
          ].map((e) => myTextField(e, "", () => console.log("sss")))}
        </div>
      </div>
    );
  };
  const step3 = () => {
    return (
      <div className={classes.boxShadow} style={{ marginTop: "20px" }}>
        <div className={classes.customfield}>
          {myImg()}
          {myMultiRadio(
            "Age of your audience ",
            Age,
            setAge,
            [
              "-16",
              "17-20",
              "21-25",
              "26-30",
              "31-40",
              "41-50",
              "51-60",
              "60+",
            ],
            false
          )}
          {myRadio(
            "Gender",
            0,
            () => console.log("sss"),
            ["Male", "Female", "Non-Binary"],
            false
          )}
          {myRadio(
            "Ethnicity",
            0,
            () => console.log("sss"),
            [
              "Arab",
              "Black (African/Caribbean/Latin)",
              "White British",
              "Mediterranean",
              "Mixed/Biracial",
              "South Asian",
              "White Other",
              "Asian Other",
            ],
            false
          )}
          {myRadio(
            "Disabilities",
            0,
            () => console.log("sss"),
            ["Cognitive", "Visual", "Auditory", "Motor", "Speech", "Unknown"],
            false
          )}
          {myRadio(
            "Sexuality",
            0,
            () => console.log("sss"),
            [
              "A Sexual",
              "Bisexual",
              "Heterosexual",
              "Homosexual",
              "Pan - Sexual",
              "Queer",
            ],
            false
          )}
          {myMultiRadio(
            "Audience Income",
            Income,
            setIncome,
            [
              "Less than 10K",
              "14-18K",
              "22-26K",
              "26-30K",
              "30-36K",
              "40-46K",
              "50-60K",
              "60-70K",
              "70-80K",
              "80-100K",
              "100K+",
            ],
            false
          )}
          {myMultiRadio(
            "Relationship Status ",
            Relationshiop,
            setRelationshiop,
            [
              "Married",
              "Divorced",
              "Separated",
              "Widowed",
              "Single",
              "Boyfriend/Girlfriend/Partner",
            ],
            false
          )}
        </div>
      </div>
    );
  };
  const step4 = () => {
    return (
      <div className={classes.boxShadow} style={{ marginTop: "20px" }}>
        <div className={classes.customfield}>
          {myImg()}
          {myRadio(
            "Education Level",
            0,
            () => console.log("sss"),
            [
              "Some high school",
              "High School",
              "A Levels/Diploma",
              "Bachelor's Degree",
              "Master's Degree",
              "Ph.D. or higher",
              "Trade School",
              "No qualification",
            ],
            false
          )}
          {myRadio(
            "Occupation Role",
            0,
            () => console.log("sss"),
            [
              "Unemployed",
              "Student",
              "Apprentice",
              "Internship",
              "Assistant",
              "Support Staff",
              "Administrator",
              "Trained Professional",
              "Technician",
              "Consultant",
              "Researcher",
              "Freelancer/Contractor",
              "Management",
              "Senior Management",
              "Self-Employed",
              "Skilled Labourer",
            ],
            false
          )}
          {myRadio(
            "Occupation Industry",
            0,
            () => console.log("sss"),
            [
              "Architecture, Design & Construction",
              "Advertising & Marketing",
              "Education",
              "Health & Health Services",
              "Hospitality & Tourism",
              "Media & Communications",
              "Non- Profit",
              "Technology",
              "Arts & Creative",
              "Financial Services",
              "Retail & Trade",
              "Agriculture & Environment",
              "Energy",
              "Pharmaceuticals & Medical Devices",
              "Transportation & Logistics",
              "Professional Services",
              "Government, Law and Politics",
              "Other",
            ],
            false
          )}
          {myRadio(
            "Hobbies and Interests",
            0,
            () => console.log("sss"),
            [
              "Sports",
              "Reading",
              "Films",
              "TV Series",
              "Outdoors activities",
              "Fashion",
              "Art - Museums - Galleries",
              "Travel",
              "Dancing",
              "Music",
              "Events",
              "Walking and hacking",
              "Extreme Sports",
              "Gaming",
              "Photography",
              "Beauty",
              "Politics",
              "Techonology",
              "Theatre",
              "Science",
            ],
            false
          )}
          {myMultiRadio(
            "Personality Types of your Audience",
            Personality,
            setPersonality,
            [
              "Introvert",
              "Intuitive",
              "Conscientiousness",
              "Extraversion",
              "Openness",
              "Anxious",
              "Agreeable",
              "Adventurous",
              "Controversial",
            ],
            false
          )}
          {myMultiRadio(
            "Media Consumption Motivations",
            Motivations,
            setMotivations,
            ["Price", "Saves Time", "Ease of use", "Creativity", "Uniqueness"],
            false
          )}
          {myRadio(
            "Sources of Media Consumption",
            0,
            () => console.log("sss"),
            [
              "TV",
              "Radio",
              "Print- Newspapers/Magazine",
              "Online",
              "Youtube",
              "Podcast",
              "Facebook/Instagram/Twitter",
            ],
            false
          )}
        </div>
      </div>
    );
  };
  const step5 = () => {
    return (
      <div className={classes.boxShadow} style={{ marginTop: "20px" }}>
        <div className={classes.customfield}>
          {myImg()}
          {[
            "Who were your top 5 spending brands of 2020? *please include £ amount*",
            "Who were your top 5 spending agencies of 2020? *please include £ amount*",
            "How much was spent on TV?",
            "How much was spent on Digital?",
            "How much was spent in Print?",
            "How much was spent in OOH?",
            "How much was spent in Radio?",
          ].map((e) => myTextField(e, "", () => console.log("sss")))}
          {myMultiRadio(
            "Please select the following sectors from which you received spend in 2020:",
            RecieveSpend,
            setRecieveSpend,
            [
              "Restaurant",
              "Tech/Computer",
              "Supermarket/Food",
              "Travel",
              "Automobile",
              "Retail",
              "Sports",
              "Cinema/Movie",
              "Publishing",
              "Household utilities",
              "Education",
              "TV and Radio",
              "Banking",
              "Healthcare/Pharmaceutical",
              "Government",
              "Gaming",
              "Insurance",
            ],
            false
          )}
          {[
            "Of the above sectors, please specify the spend in each sector. (Please specify the sector(s) that fall under 'Other'.)",
            "Please specify the top 5 brands that spent with you in 2020 and how much they spent:",
          ].map((e) => myTextField(e, "", () => console.log("sss")))}
        </div>
      </div>
    );
  };
  const step6 = () => {
    return (
      <div className={classes.boxShadow} style={{ marginTop: "20px" }}>
        <div className={classes.customfield}>
          {myImg()}
          {myRadio(
            "What is the primary demographic of your audience?",
            0,
            () => console.log("sss"),
            [
              "South Asian",
              "Arab",
              "Black",
              "Disabled",
              "LGBTQ",
              "Muslim",
              "Over 50s",
            ],
            false
          )}

          {[
            "How many employees from the primary audience demographic work at the publication?",
            "Do you have any other diverse people working at the publication? Please state",
            "How many known diverse contractors/freelancers have  worked for publication in the last 12 months?",
            "How many diverse community based initiatives has the publication established in the last 12 months? (Please name examples)",
            "How many diverse community based initiative has the publication supported  in the last 12 months? (Please name examples)",
            "Any other community activities from the publication which result in a positive social impact for the primary demographic?",
          ].map((e) => myTextField(e, "", () => console.log("sss")))}
        </div>
      </div>
    );
  };

  return (
    <div>
      {step === 0 && <StepWraper step={0} />}
      {step === 1 && <StepWraper step={1} />}
      {step === 2 && <StepWraper step={2} />}
      {step === 3 && <StepWraper step={3} />}
      {step === 4 && <StepWraper step={4} />}
      {step === 5 && <StepWraper step={5} />}
      {step === 6 && <StepWraper step={6} />}
      {step === 0 && step0()}
      {step === 1 && step1()}
      {step === 2 && step2()}
      {step === 3 && step3()}
      {step === 4 && step4()}
      {step === 5 && step5()}
      {step === 6 && step6()}
      {myFooter()}
    </div>
  );
};

export default Layout(MOFORM);
