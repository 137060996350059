import { Button, CircularProgress } from "@material-ui/core"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { API, SECRET_KEY } from "../../../constants/index"
import MainLayout from "../../../layouts/newMainLayoutPlanner"
import "./influencer.css"

function Influencer() {
    const company_id = localStorage.getItem("companyId")
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [campaignList, setCampaignList] = useState([])

    const getCampaigns = () => {
        setLoading(true)
        const access_token = window.localStorage.getItem(SECRET_KEY)
        axios
            .get(`${API}/mediaPlanner/influencer/get-campaigns/${company_id}`, {
                headers: { Authorization: `Bearer ${access_token}` },
            })
            .then((res) => {
                setCampaignList(res.data.influencerCampaigns)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setLoading(false)
            })
    }

    useEffect(() => {
        getCampaigns()
    }, [])

    return (
        <div>
            <div className="head-section">
                <h1 className="title">Influencer Campaigns</h1>
            </div>
            <div className="influencer-body">
                <div className="campaigns-list">
                    {campaignList.map((item) => {
                        return (
                            <div key={item._id} className="mp_campaign_cards">
                                <h3 className="campaign_title">
                                    Title:&nbsp;&nbsp;<span className="card_contents">{item.title}</span>
                                </h3>
                                <h3 className="campaign_desc">
                                    Description:&nbsp;&nbsp;<span className="card_contents">{item.description}</span>
                                </h3>
                                <h3 className="campaign_company">
                                    Companies:&nbsp;&nbsp;
                                    <span className="card_contents">{item.companies?.map((company) => company.companyName).join(', ')}</span>
                                </h3>
                                <h3 className="campaign_content_count">
                                    Contents:&nbsp;&nbsp;<span className="card_contents">{item.contentsCount}</span>
                                </h3>
                                <div className="c_view_btn_cont">
                                    <Button
                                        className="campaign_view_btn"
                                        variant="contained"
                                        onClick={() => history.push(`/media-planner/influencer/${item._id}`)}
                                    >
                                        View
                                    </Button>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>{loading && <CircularProgress />}</div>
        </div>
    )
}

export default MainLayout(Influencer)
