import React, { useState, useRef } from "react";
import { Avatar, Button, Grid, Modal, Card, CardActions, CardContent, CardHeader } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import AddModal from "./AddModal";

import CubeImg from "../../../../assets/cube.png";
import VastImg from "../../../../assets/vast.png";

const useStyle = makeStyles({
    campaignOption: {
        cursor: "pointer",
    },
});

const AdItemInputModal = ({ show, onClose, adsCampaign, refetchAdItems }) => {
    const classes = useStyle();
    const selectCampaign = useRef();

    //   const [category, setPopParticularCampaign] = useState(false)
      const [selectedAdItem, setselectedAdItem] = useState({choose:"", type:""})
    const [category, setPopParticularCampaign] = useState(false);
    // const [selectedAdItem, setselectedAdItem] = useState({ choose: "Image Ad", type: "image" });
    //   const [selectedAdItem, setselectedAdItem] = useState({choose:"Custom HTML", type:"Custom_HTML"})
    //   const [selectedAdItem, setselectedAdItem] = useState({choose:"Native", type:"Native"})

    const selectCampaignHandler = (choose, type) => {
        onClose(false);
        setPopParticularCampaign(true);
        setselectedAdItem({ choose, type });
    };

    return (
        <>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                open={show}
            >
                <Card
                    style={{
                        width: 500,
                        backgroundColor: "primary.dark",
                        "&:hover": {
                            backgroundColor: "primary.main",
                            opacity: [0.9, 0.8, 0.7],
                        },
                    }}
                >
                    <CardHeader
                        avatar={
                            <Avatar sx={{ bgcolor: "red" }} aria-label="recipe">
                                +
                            </Avatar>
                        }
                        title="Create AdItem"
                    />
                    <CardContent>
                        <CardHeader subheader="Select AdItem type:" />
                        <Grid container spacing={2}>
                            <Grid item xs={12} lg={12} md={12} sm={12}>
                                <Card
                                    className={classes.campaignOption}
                                    onClick={() => selectCampaignHandler("Image Ad", "image")}
                                >
                                    <CardHeader
                                        ref={selectCampaign}
                                        avatar={
                                            <Avatar sx={{ bgcolor: "red" }} aria-label="recipe">
                                                <img src={CubeImg} alt="" width="20px" height="20px" />
                                            </Avatar>
                                        }
                                        title="Image"
                                        subheader="Serve a basic image ad item of type GIF, JPEG or PNG"
                                    />
                                </Card>
                            </Grid>
                            <Grid item xs={12} lg={12} md={12} sm={12}>
                                <Card
                                    className={classes.campaignOption}
                                    onClick={() => selectCampaignHandler("HTML5 (Rich Media)", "html5")}
                                >
                                    <CardHeader
                                        ref={selectCampaign}
                                        avatar={
                                            <Avatar sx={{ bgcolor: "red" }} aria-label="recipe">
                                                <img src={VastImg} alt="" width="25px" height="25px" />
                                            </Avatar>
                                        }
                                        title="HTML5 (Rich Media)"
                                        subheader="C Serve IAB compatible HTML ZIP archive"
                                    />
                                </Card>
                            </Grid>
                            <Grid item xs={12} lg={12} md={12} sm={12}>
                                <Card
                                    className={classes.campaignOption}
                                    onClick={() => selectCampaignHandler("Custom HTML", "Custom_HTML")}
                                >
                                    <CardHeader
                                        ref={selectCampaign}
                                        avatar={
                                            <Avatar sx={{ bgcolor: "red" }} aria-label="recipe">
                                                <img src={VastImg} alt="" width="25px" height="25px" />
                                            </Avatar>
                                        }
                                        title="Custom HTML or 3rd Party Tag"
                                        subheader="Serve custom HTML or script-based advertisement"
                                    />
                                </Card>
                            </Grid>
                            <Grid item xs={12} lg={12} md={12} sm={12}>
                                <Card
                                    className={classes.campaignOption}
                                    onClick={() => selectCampaignHandler("Native", "Native")}
                                >
                                    <CardHeader
                                        ref={selectCampaign}
                                        avatar={
                                            <Avatar sx={{ bgcolor: "red" }} aria-label="recipe">
                                                <img src={VastImg} alt="" width="25px" height="25px" />
                                            </Avatar>
                                        }
                                        title="Native (Custom Rendering / API)"
                                        subheader="Serve ad content using variables from a native ad template"
                                    />
                                </Card>
                            </Grid>
                            <Grid item xs={12} lg={12} md={12} sm={12}>
                                <Card
                                    className={classes.campaignOption}
                                    onClick={() => selectCampaignHandler("Native (Styled)", "Native_(Styled)")}
                                >
                                    <CardHeader
                                        ref={selectCampaign}
                                        avatar={
                                            <Avatar sx={{ bgcolor: "red" }} aria-label="recipe">
                                                <img src={VastImg} alt="" width="25px" height="25px" />
                                            </Avatar>
                                        }
                                        title="Native (Styled)"
                                        subheader="Serve safe ad content inline using variables from a native ad template"
                                    />
                                </Card>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions style={{ float: "right" }}>
                        <Button variant="contained" color="secondary" onClick={() => onClose(false)}>
                            Close
                        </Button>
                    </CardActions>
                </Card>
            </Modal>
            <AddModal
                popParticularCampaign={category}
                setPopParticularCampaign={setPopParticularCampaign}
                setPopAddCampaign={onClose}
                campaignType={selectedAdItem}
                adsCampaign={adsCampaign}
                refetchAdItems={refetchAdItems}
            />
        </>
    );
};

export default AdItemInputModal;
