import React, { useState, useEffect } from "react";
import MainLayout from "../../../layouts/adminMainLayout";

import Pagination from "@material-ui/lab/Pagination";

import CircularProgress from "@material-ui/core/CircularProgress";
//Button
import NewAlert from "../../../components/NewAlert";
import {
  Card,
  Dialog,
  Modal,
  Button,
  TextField,
  withStyles
} from "@material-ui/core"
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import parse from "html-react-parser";
//Icon

import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { useHistory } from "react-router-dom";
import useStyles from "../../../components/StyleClasses";
import CustomizedTables from "../../mediaOwner/mytable";
import { API, SECRET_KEY } from "../../../constants/index";
import axios from "axios";

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'white',
    },
    '& label.MuiFormLabel-root': {
      color: 'white',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
      color: 'white',
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
    },
  },
})(TextField)

const ChatGPT = () => {
  const classes = useStyles();
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [DATA, setDATA] = useState(null);
  const [loading, setloading] = useState(false);
  const [page, setpage] = useState(1);
  const [search, setSearch] = useState("");
  const access_token = window.localStorage.getItem(SECRET_KEY);
  const [pagecount, setPageCount] = useState(0);
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [question, setQuestion] = useState([]);
  const [open, setOpen] = useState(false)
  const [creteQuestion, setCreteQuestion] = useState("")

 ///////errors & successs //////////
 const [ErrSucMsg, setErrSucMesg] = useState("");
 const [ErrSucType, setErrSucType] = useState(true);

  const getQuestionList = () => {
    setloading(true);
    setTableData([]);
    axios
      .get(`${API}/admin/chatGPTQuestions?page=${page}`, {  
      // .get(`${API}/admin/chatGPTQuestions?page=${page}${search ? '&keyword='+search : ''}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setloading(false);
          setDATA(res.data.data);
          setPageCount(res.data.pagination.total);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };
  useEffect(() => getQuestionList(), []);
  useEffect(() => getQuestionList(), [page]);

  var myVar;

  function myFunction() {
    myVar = setTimeout(function () {
      getQuestionList();
    }, 200);
  }

  function saveQuestion(){
    const body = {
      question
    };
    if(question.length){
      axios.post(`${API}/admin/chatGPTQuestions`, body, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setTimeout(() => {
            history.push("/admin/chatGPT");
          }, 1500);
          setErrSucMesg(res.data.message);
          setErrSucType(true);
          setQuestion([]);
          getQuestionList();
        }
        if (err) {
          setErrSucMesg(err);
          setErrSucType(false);
        }
      })
      .catch(function (error) {
        if (error) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
        }
      });
    } else {
      setErrSucMesg("please select at least field or enter question");
      setErrSucType(false);
    }
  }

  function myStopFunction() {
    clearTimeout(myVar);
  }

  function addField(name, value) {
    setQuestion([...question,{type:"field", value: value, name: name}])
  }

  function addText(value) {
    setQuestion([...question,{type:"text", value: value}])
  }


  const submitCountryMap = async () => {
    addText(creteQuestion)
    setTimeout(() => setOpen(false), 100)
  }

  useEffect(() => myFunction(), [search]);
  useEffect(() => {
    if (DATA) {
      let td = [];
      DATA.forEach((e) => {
        let questionString = ""
        e.question.map((questionItem) => {
          console.log(questionItem.name, 'questionItem.value')
          if(questionItem.name){
            questionString += ` <span style="color:${questionItem.name};font-weight: bold;">${questionItem.value}</span>`
          } else{
            questionString += ` ${questionItem.value}`
          }
        })
        td.push({
          data: [
            parse(questionString)
          ],
          _id: e._id,
        });
      });
      console.log(td, "setTableData")
      setTableData(td);
    }
  }, [DATA]);

  const deleteConsultant = () => {
    axios
      .delete(`${API}/admin/chatGPTQuestions/${deleteId}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setErrSucMesg(res.data.message);
          setErrSucType(true);
          getQuestionList();
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.data) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
          getQuestionList();
        }
      });
  };

  return (
    <div>
       <NewAlert
          msg={ErrSucMsg}
          isSuccess={ErrSucType}
          close={() => setErrSucMesg("")}
        />
          {deleteFlag && (
          <Dialog
            open={deleteFlag}
            onClose={() => {
              setDeleteFlag(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Are you sure, you want to delete question?
            </DialogTitle>
            <DialogActions>
              <Button
                style={{ color: "black", backgroundColor: "grey" }}
                onClick={() => {
                  setDeleteFlag(false);
                }}
              >
                Cancel
              </Button>
              <Button
                style={{ backgroundColor: "#4F74C9", color:"white"}}
                onClick={() => {
                  setDeleteFlag(false);
                  deleteConsultant();
                }}
                autoFocus
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        )}
      <h2 style={{ color: "#4F74C9", fontWeight: "400" }}>ChatGPT Question Builder</h2>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          onClick={() => setOpen(!open)}
          className={classes.addActionBtn}
          variant="outlined"
          color="primary"
          style={{ width: "20%", borderRadius: "50px" }}
        >
          <AddCircleOutlineIcon
            style={{ marginRight: "5px", color: "white" }}
          />
          Create new Questions
        </Button>
        <div
          className={classes.addActionBtn}
          style={{
            backgroundColor: "white",
            paddingRight: "7px",
            paddingLeft: "7px",
            width: "78%",
            borderRadius: "50px",
            boxShadow:
              "0px 0px 10px rgba(0,0,0,0.20), 0 0 10px rgba(0,0,0,0.15)",
          }}
        >
          <input
            placeholder="Search questions"
            //className={classes.addActionBtn}
            style={{
              outline: "none",
              backgroundColor: "white",
              border: "solid 2px #E5E5E5",
              color: "black",
              borderRadius: "23px",
              padding: "7px",
              width: "100%",
            }}
            onChange={(event) => {
              myStopFunction();
              setSearch(event.target.value);
            }}
          ></input>
        </div>
      </div>
      <div className={classes.boxShadow} style={{ marginTop: "40px" }}>
        <div className={`${classes.flexBoxDiv}`}>
          <div style={{ display: "flex", width: "30%", margin: "auto", justifyContent: "center" }}>  
            <Button
              onClick={() => setOpen(!open)}
              className={classes.addActionBtn}
              variant="outlined"
              color="primary"
              style={{ width: "50%", borderRadius: "500px" }}
            >
              QUESTION TEXT
            </Button>
          </div>
          <div style={{ width: "70%" }}>
            <div style={{ display: "flex", justifyContent: "space-evenly", margin: "10px 0"}}>
              <Button
                onClick={() => addField("#5f11a7","Gender")}
                className={classes.addActionBtn}
                variant="outlined"
                color="primary"
                style={{ width: "20%", borderRadius: "500px" }}
              >
                GENDER
              </Button>
              <Button
                onClick={() => addField("#a70000","Demographic")}
                className={classes.addActionBtn}
                variant="outlined"
                color="primary"
                style={{ width: "20%", borderRadius: "500px" }}
              >
                DEMOGRAPHIC
              </Button>
              <Button
                onClick={() => addField("#00ce2b","Country")}
                className={classes.addActionBtn}
                variant="outlined"
                color="primary"
                style={{ width: "20%", borderRadius: "500px" }}
              >
                COUNTRY
              </Button>
            </div>
            <div style={{ display: "flex", justifyContent: "space-evenly", margin: "10px 0"}}>
              <Button
                onClick={() => addField("#b800ac","Brand")}
                className={classes.addActionBtn}
                variant="outlined"
                color="primary"
                style={{ width: "20%", borderRadius: "500px" }}
              >
                BRAND
              </Button>
              <Button
                onClick={() => addField("#e3a106","Age")}
                className={classes.addActionBtn}
                variant="outlined"
                color="primary"
                style={{ width: "20%", borderRadius: "500px" }}
              >
                AGE
              </Button>
              <Button
                onClick={() => addField("#00e3c9","Industry")}
                className={classes.addActionBtn}
                variant="outlined"
                color="primary"
                style={{ width: "20%", borderRadius: "500px" }}
              >
                INDUSTRY
              </Button>
            </div>
          </div>
        </div>
        <div style={{ borderTop:"10px dashed", margin: "1rem 0", padding:"1rem 0" }}>
          <div>
            {
              question.map((question) => {
                if(question.type == "text"){
                  return (<Button
                    className={classes.textTag}
                    // variant="outlined"
                    // color="secondary"
                    style={{ width: "20%", borderRadius: "500px", margin: "10px" }}
                  >
                    {question.value}
                  </Button>);
                } else {
                  return (<Button
                    className={classes.addActionBtn}
                    variant="outlined"
                    color="danger"
                    style={{ width: "20%", borderRadius: "500px", margin: "10px" }}
                  >
                    {question.value}
                  </Button>)
                }
              })
            }
          </div>
          <div className={classes.saveQuestionDiv}>
            <Button
              onClick={() => saveQuestion()}
              className={classes.saveQuestion}
              variant="outlined"
              color="danger"
              style={{ width: "20%", borderRadius: "500px" }}
            >
              SAVE QUESTION
            </Button>
          </div>
        </div>
        <div><span
          style={{
            color: "#FB6D6A",
            fontWeight: "600",
            margin: "0",
            fontSize: "20px",
            marginLeft: "20px",
          }}
        >
          Saved Questions
        </span>
        </div>
        <CustomizedTables
          // isEditable={true}
          headings={[
            "Questions"
          ]}
          listdata={tableData}
          redirectLink={"createConsultant?id="}
          handleDelete={(_id) => {
            setDeleteFlag(true);
            setDeleteId(_id);
          }}
        />
        <Modal
          open={open}
          onClose={() => {
            setOpen(!open)
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Card
            style={{
              padding: "2.5em",
              border: "none",
              color: "#fff",
              width: "50%",
              overflowY: "auto",
              backgroundColor: 'rgb(156, 6, 149)'
            }}
          >
            <span
              style={{
                color: "white",
                fontWeight: "600",
                margin: "0",
                fontSize: "20px",
              }}
            >
              Add Question Text
            </span>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{ flex: "2", display: "flex", flexDirection: "column" }}
              >
                <CssTextField
                  defaultValue={""}
                  onChange={(e) => {
                    setCreteQuestion(e.target.value)
                  }}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Question"
                  style={{ marginRight: "0 0.3rem" }}
                  placeholder="Question"
                />
              </div>
            </div>
            <Button
              variant="contained"
              style={{
                color: "white",
                backgroundColor: "grey",
                marginTop: "0.5rem",
                marginRight: "0.3rem",
              }}
              onClick={() => {
                setOpen(!open)
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={submitCountryMap}
              style={{ marginTop: "0.5rem" }}
            >
              Add
            </Button>
          </Card>
        </Modal>
      </div>
    </div>
  );
};
export default MainLayout(ChatGPT);