import "./channel-assign.css"

import {
    Box,
    Button,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core"
import Close from "@material-ui/icons/Close"
import { useMutation, useQuery } from "@tanstack/react-query"
import axios from "axios"
import React, { useRef, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { useAccessToken } from "../../../../../Hooks/useAccessToken"
import LoaderCircle from "../../../../../components/Loader/Loader"
import { API } from "../../../../../constants"
import { AuthHeader } from "../../../../../constants/AuthHeader"
import getCssStyles from "../../../../../utils/getCssStyles"
import { useAppToastify } from "../../../../../utils/useAppToastify"
import {
    ChannelAssignmentPageContextProvider,
    useChannelAssignmentPageCtx,
} from "../../../../admin/ChannelAssignment/ChannelAssignmentPage"
import ChannelAssignmentUpdateForm from "../../../../admin/ChannelAssignment/components/step2/Step2"
import useSaveChannelAssignFeature from "../../../../admin/ChannelAssignment/hooks/useSaveChannelAssign"

export default function ChannelAssign() {
    const history = useHistory()
    const { showAppToast } = useAppToastify()
    const params = useParams()
    const { advertiserId, campaignId, campaignType, campaignName, advertiser_name } = params

    const access_token = useAccessToken()
    const [channelSelectionModal, setChannelSelectionModal] = useState(false)
    const [activeUnassigningChannelId, setActiveUnassigningChannelId] = useState(null)
    const [modalActiveChannelId, setModalActiveChannelId] = useState(null)
    // const [modalActiveChannelId, setModalActiveChannelId] = useState(2112282)
    const modalActiveChannelIdRef = useRef(modalActiveChannelId)
    modalActiveChannelIdRef.current = modalActiveChannelId
    const formStateRef = useRef({})

    // fetch all of the channel list
    const { data: channelsList, error, isLoading, isFetching, isInitialLoading, mutate: fetchChannels } = useMutation({
        mutationFn: (params) => {
            // console.log({ params })
            return axios.post(
                `${API}/mediaPlanner/AdButler/channels`,
                { channel_list: params.idsList },
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
        },
        enabled: false,
        mutationKey: ["getChannels"],
        onSuccess: (data) => {},
    })

    // fetch all of the assigned-channel list
    const {
        data: assignedChannelsList,
        isLoading: isAssignedListLoading,
        isFetching: isRefetchingAssignedList,
        refetch: refetchAssignedChannelsList,
    } = useQuery({
        queryFn: (p) => {
            return axios.get(`${API}/mediaPlanner/AdButler/assignedChannels?campaign=${params.campaignId}`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
        },
        queryKey: ["getAssignedChannels"],
        onSuccess: (data) => {
            fetchChannels({
                idsList: data.data.data.reduce((acc, value) => {
                    acc = [...acc, value.channel_id]
                    return acc
                }, []),
            })
        },
        refetchOnWindowFocus: false,
    })

    // unassign channel api
    const { isLoading: isUnAssigningChannel, mutate: unassignChannel } = useMutation({
        mutationFn: (params) => {
            return axios.delete(`${API}/mediaPlanner/AdButler/placement/${params}`, {
                ...AuthHeader,
            })
        },
        enabled: false,
        mutationKey: ["unAssignChannel"],
        onSuccess: (res) => {
            showAppToast(res.data.message, { variant: "success" })
            refetchAssignedChannelsList()
        },
        onError: (err) => {
            if (err?.response?.data?.message) {
                showAppToast(err?.response?.data?.message, { variant: "error" })
            } else {
                err?.message && showAppToast(err?.message, { variant: "error" })
            }
        },
        onSettled: () => {
            setActiveUnassigningChannelId(null)
        },
    })

    // get details of assigned-channel for update
    const {
        data: assignedChannelsDetails,
        isLoading: isAssignedChannelDetailsLoading,
        isFetching: isFetchingAssignedChannelDetails,
        refetch: refetchAssignedChannelDetails,
    } = useQuery({
        queryFn: (p) => {
            // console.log({ modalActiveChannelId: modalActiveChannelIdRef.current })
            return axios.get(`${API}/mediaPlanner/AdButler/placement/${modalActiveChannelIdRef.current}`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
        },
        enabled: true,
        queryKey: ["getAssignedChannelsDetailsWithId"],
        onSuccess: (data) => {},
        refetchOnWindowFocus: false,
    })

    const handleClickRedirection = (channel) => {
        history.push(
            `/media-planner/campaign/advertiser/channel-assignment/${channel.name}/${channel.id}/${advertiserId}/${campaignId}/${campaignType}/${campaignName}/${advertiser_name}`
        )
    }

    const handleUnAssignChannel = (channel_id) => {
        setActiveUnassigningChannelId(channel_id)
        unassignChannel(channel_id)
    }

    const handleEditChannelModal = (channel_id) => {
        modalActiveChannelIdRef.current = channel_id
        setModalActiveChannelId(channel_id)
        refetchAssignedChannelDetails()
    }

    const tableCellStyles = getCssStyles({
        background: "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))",
        color: "white",
        border: "1px solid #d0d0d0",
    })

    return (
        <div>
            <Box component={"div"} className="flex justify-between align-center mb-20 mt-20">
                <span className="heading">Channels</span>
                <div className="actions">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setChannelSelectionModal(true)
                        }}
                    >
                        Assign To Channel
                    </Button>
                </div>
            </Box>

            <TableContainer component={Paper} style={{ maxHeight: "400px" }}>
                <Table className="channel-list-table" stickyHeader>
                    <TableHead className="channel-table-head">
                        <TableRow>
                            <TableCell style={tableCellStyles}>Name</TableCell>
                            <TableCell align="center" style={tableCellStyles}>
                                Date
                            </TableCell>
                            <TableCell align="center" style={tableCellStyles}>
                                Total Impr.
                            </TableCell>
                            <TableCell align="center" style={tableCellStyles}>
                                Unassign
                            </TableCell>
                            <TableCell align="center" style={tableCellStyles}>
                                Status
                            </TableCell>
                            <TableCell align="center" style={tableCellStyles}>
                                Edit
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody
                        className="channel-list-body"
                        style={{ ...((isAssignedListLoading || isRefetchingAssignedList) && { height: "400px" }) }}
                    >
                        {(isAssignedListLoading || isRefetchingAssignedList) && (
                            <LoaderCircle style={{ position: "absolute", width: "30px", height: "30px" }} />
                        )}
                        {!isRefetchingAssignedList &&
                            assignedChannelsList?.data?.data?.map((channel) => {
                                return (
                                    <TableRow key={channel.id} className="channel-row">
                                        <TableCell style={{ border: "1px solid #d0d0d0" }}>{channel.name}</TableCell>
                                        <TableCell align="center" style={{ border: "1px solid #d0d0d0" }}>
                                            {!channel.start_date && !channel.end_data && (
                                                <>
                                                    <span>No Date</span>
                                                    <br />
                                                    <span>Restriction</span>
                                                </>
                                            )}
                                            {channel.start_date && <span>S: {channel.start_date}</span>}
                                            <br />
                                            {channel.end_date && <span>E: {channel.end_date}</span>}
                                            {/* {moment(channel.start_date).utc().format("Y/MM/DD hh:mm:ss a") ?? "No Date Restriction"} */}
                                            {/* {moment(channel.end_data).utc().format("Y/MM/DD hh:mm:ss a") ?? "No Date Restriction"} */}
                                            {/* {moment(channel.start_date).format("YYYY-MM-DD a") ?? "No Date Restriction"} */}
                                        </TableCell>
                                        <TableCell align="center" style={{ border: "1px solid #d0d0d0" }}>
                                            {channel.impr ?? "-"}
                                        </TableCell>
                                        <TableCell align="center" style={{ border: "1px solid #d0d0d0" }}>
                                            {/* showing loader untill unassigning is done */}
                                            {isUnAssigningChannel && activeUnassigningChannelId == channel.id && (
                                                <span>
                                                    <LoaderCircle
                                                        style={{ position: "unset", width: "20px", height: "20px" }}
                                                    />
                                                </span>
                                            )}
                                            {/* show unassign for other rows */}
                                            {activeUnassigningChannelId != channel.id && (
                                                <a
                                                    href="#"
                                                    style={{ textDecoration: "none" }}
                                                    onClick={() => handleUnAssignChannel(channel.id)}
                                                >
                                                    unassign
                                                </a>
                                            )}
                                        </TableCell>
                                        <TableCell align="center" style={{ border: "1px solid #d0d0d0" }}>
                                            {channel.status ? "Active" : "Paused"}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            style={{ border: "1px solid #d0d0d0" }}
                                            onClick={() => handleEditChannelModal(channel.id)}
                                        >
                                            <a href="#" style={{ textDecoration: "none" }}>
                                                edit
                                            </a>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* show the list of channels */}
            <Modal
                open={channelSelectionModal}
                onClose={() => setChannelSelectionModal(false)}
                style={{ display: "flex", alignItems: "center", justifyContent: "center", overflowY: "auto" }}
            >
                <Box className="channel-selection-modal">
                    <div className="flex justify-between">
                        <h3>Channel Assignment</h3>
                        <Button className="close-button" onClick={() => setChannelSelectionModal(false)}>
                            <Close />
                        </Button>
                    </div>
                    <p>Choose a channel which you would like to assign this campaign to.</p>

                    <TableContainer component={Paper}>
                        <Table className="channel-list-table">
                            <TableHead className="channel-table-head">
                                <TableRow>
                                    <TableCell style={tableCellStyles}>Name</TableCell>
                                    <TableCell style={tableCellStyles}>Included Zones</TableCell>
                                    <TableCell style={tableCellStyles}>Assigned</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody
                                className="channel-list-body"
                                style={{
                                    ...((isLoading || isAssignedListLoading || isRefetchingAssignedList) && {
                                        height: "400px",
                                    }),
                                }}
                            >
                                {(isLoading || isAssignedListLoading || isRefetchingAssignedList) && (
                                    <LoaderCircle style={{ position: "absolute", width: "30px", height: "30px" }} />
                                )}
                                {channelsList?.data?.data?.map((channel) => {
                                    return (
                                        <TableRow
                                            key={channel.id}
                                            className="channel-row"
                                            onClick={() => handleClickRedirection(channel)}
                                        >
                                            <TableCell style={{ border: "1px solid #d0d0d0" }}>
                                                {channel.name}
                                            </TableCell>
                                            <TableCell style={{ border: "1px solid #d0d0d0" }}>
                                                {channel.includedZone}
                                            </TableCell>
                                            <TableCell style={{ border: "1px solid #d0d0d0" }}>
                                                {channel.assigned}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Modal>

            <ChannelAssignmentPageContextProvider
                step2StatesRef={formStateRef}
                selectedServeType={assignedChannelsDetails?.data?.Placement?.serve_method}
                assignedChannelDetailsForUpdate={assignedChannelsDetails?.data?.Placement}
                assignChannelUpdateMode={true}
            >
                {/* update modal for ChannelAssign */}
                <Modal
                    // open={true}
                    open={!!modalActiveChannelId}
                    onClose={() => setModalActiveChannelId(null)}
                    style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                >
                    <UpdateModalContent
                        onClose={() => setModalActiveChannelId(null)}
                        isFetching={isFetchingAssignedChannelDetails}
                        active_modal_assigned_channel_id={modalActiveChannelId}
                    />
                </Modal>
            </ChannelAssignmentPageContextProvider>
        </div>
    )
}

function UpdateModalContent({ onClose, isFetching,active_modal_assigned_channel_id }) {
    const { assignedChannelDetailsForUpdate, step2StatesRef: formStates } = useChannelAssignmentPageCtx()
    const { saveCampaign, isUpdatingAssignChannel } = useSaveChannelAssignFeature({
        states: formStates,
        updateMode: true,
        updateModeOnSuccessCb: onClose,
        channel_id: assignedChannelDetailsForUpdate?.channel?.id,
        assigned_channel_id: 2112282,
        // assigned_channel_id: active_modal_assigned_channel_id
    })

    return (
        <Box bgcolor="#fff" style={{ inset: "40px" }} overflow="auto" position="fixed">
            {isFetching && <LoaderCircle />}
            {!isFetching && assignedChannelDetailsForUpdate && (
                <>
                    <Box className="flex" justifyContent="space-between" paddingX="20px" paddingY="10px">
                        <h3 style={{ margin: 0 }}>Update Details of Assigned Channel</h3>
                        <Button className="close-button" onClick={onClose} style={{ padding: 0, minWidth: "unset" }}>
                            <Close />
                        </Button>
                    </Box>
                    <Box width="100%" overflow="auto" padding="10px 20px">
                        <ChannelAssignmentUpdateForm />
                    </Box>
                    <Box width="100%" overflow="auto" padding="10px 20px" textAlign="end">
                        <Button variant="contained" color="primary" onClick={saveCampaign}>
                            {isUpdatingAssignChannel && (
                                <>
                                    <LoaderCircle
                                        style={{
                                            position: "unset",
                                            width: "16px",
                                            height: "16px",
                                            marginRight: "10px",
                                        }}
                                    />
                                    <span>Updating Campaign</span>
                                </>
                            )}
                            {!isUpdatingAssignChannel && <span>Update Campaign</span>}
                        </Button>
                    </Box>
                </>
            )}
        </Box>
    )
}
{
    /* <Button  variant="contained" color="primary" onClick={() =>  {
    history.push(`/media-planner/campaign/advertiser/channel-assignment/${advertiserId}/${campaignId}/${campaignType}`)
    // history.push(`/media-planner/campaign/advertiser/channel-assignment/${selectedchannelId}/${advertiserId}/${campaignId}/${campaignType}`)
}}>
    Assign To Channel
</Button> */
}
