import React from "react";

export const ConvertDigital = (inv) => {
  if (!inv) return;
  let sdx = new Date(inv.startDate);
  let sd = sdx.toISOString().split("T")[0];
  let edx = new Date(inv.endDate);
  let ed = edx.toISOString().split("T")[0];

  return {
    Sub_Channel: inv.subchannelType,
    Start_Date: sd,
    End_Date: ed,
    Media_Type: inv.mediaType,
    Format: inv.format,
    Unit_Cost: inv.unitCost,
    Impressions: inv.estimatedImpression,
    Sell_Type: inv.sellType,
  };
};

export const ConvertPrint = (inv) => {
  if (!inv) return;
  let sdx = new Date(inv.startDate);
  let sd = sdx.toISOString().split("T")[0];
  let edx = new Date(inv.endDate);
  let ed = edx.toISOString().split("T")[0];

  return {
    Audience: inv.audience,
    Circulation: inv.circulation,
    Start_Date: sd,
    End_Date: ed,
    Readership: inv.readership,
    Format_Type: inv.formatType,
    Height: inv.heightCm,
    Width: inv.widthCm,
    Position: inv.position,
    Unit_Cost: inv.unitCost,
  };
};

export const ConvertRadio = (inv) => {
  if (!inv) return;
  let sdx = new Date(inv.startDate);
  let sd = sdx.toISOString().split("T")[0];
  let edx = new Date(inv.endDate);
  let ed = edx.toISOString().split("T")[0];

  return {
    Start_Date: sd,
    End_Date: ed,
    Media_Type: inv.mediaType,
    Audience: inv.audience,
    Ad_Type: inv.adType,
    Listners: inv.listenerss,
    Daytime_Parting: inv.daytimeParting,
    Rate: inv.Rate,
  };
};
export const ConvertOOH = (inv) => {
  if (!inv) return;
  let sdx = new Date(inv.startDate);
  let sd = sdx.toISOString().split("T")[0];
  let edx = new Date(inv.endDate);
  let ed = edx.toISOString().split("T")[0];

  return {
    Start_Date: sd,
    End_Date: ed,
    Media_Type: inv.mediaType,
    Audience: inv.audience,
    Product: inv.product,
    Format_Size: inv.formatSize,
    Impacts: inv.impacts,
    Rate: inv.Rate,
    Geo_Country: inv.geoCountry,
    Geo_City: inv.geoCity,
  };
};

export const ConvertTelevision = (inv) => {
  if (!inv) return;
  let sdx = new Date(inv?.startDate);
  let sd = sdx.toISOString().split("T")[0];
  let edx = new Date(inv?.endDate);
  let ed = edx.toISOString().split("T")[0];

  return {
    Start_Date: sd,
    End_Date: ed,
    Media_Type: inv.mediaType,
    Audience: inv.audience,
    Ad_Type: inv.adType,
    Ad_Unit: inv.adUnit,
    Audience_Age_Group: inv.audienceAgegroup,
    Rate: inv.rate,
    Channel: inv.channel,
    Viewership: inv.viewership,
    Daytime_Parting: inv.daytimeParting,
  };
};

export const KeysToSend = {
  Digital: {
    startDate: 1633509168965,
    endDate: 1665081000000,
    extra: "Standard IAB",
    media: "612dd1e6574f5cf95873794d",
    creator: "60d4785917c38425a07d7086",
    subchannelType: "Display",
    audience: "yu",
    mediaType: '10" slot',
    format: "Daily",
    estimatedImpression: 2000,
    country: "611cf767b316eb38124295e3",
    sellType: "CPM",
    unitCost: 15,
  },
  Print: {
    startDate: 1633509168965,
    endDate: 1665081000000,
    extra: "Standard IAB",
    media: "6138c04a43004aa8dcd68bd8",
    creator: "60d4785917c38425a07d7086",
    audience: "hj",
    circulation: 100,
    readership: 1000,
    formatType: "Full Page",
    heightCm: 90,
    widthCm: 30,
    position: "Right Hand Side",
    unitCost: 15,
  },
  Radio: {
    startDate: 1633509168965,
    endDate: 1665081000000,
    extra: "10 Slot",   // not coming
    media: "612dd1e6574f5cf958737a15",
    creator: "60d4785917c38425a07d7086",
    mediaType: '10" slot',
    audience: "LGBTQ",
    adType: "Ad Types",
    listenerss: 300,
    daytimeParting: "Afternoon",
    rate: 300,
  },
  OOH: {
    startDate: 1633509168965,
    endDate: 1665081000000,
    extra: "Digital",
    media: "612dd1e6574f5cf95873799d",
    creator: "60d4785917c38425a07d7086",
    mediaType: '10" slot',
    audience: "hgj",
    product: "Underground",
    formatSize: "6 sheet",
    impacts: 200,
    rate: 100,
    geoCountry: "india",
    geoCity: "agra",
  },
  Television: {
    startDate: 1633509168965,
    endDate: 1665081000000,
    extra: "30 Ad",
    media: "612dd1e6574f5cf958737a17",
    creator: "60d4785917c38425a07d7086",
    mediaType: '10" slot',
    audience: "gc",
    adType: "Ad Type 1",
    adUnit: "AD Unit",
    audienceAgegroup: "(Drop DOwn)",
    rate: 300,
    channel: "channel 1",
    viewership: 200,
    daytimeParting: "Afternoon",
  },
};

export const DigitalQuesAns = (e) => {
  return [
    e.subchannelType,
    e.audience,
    e.mediaType,
    e.format,
    e.estimatedImpression,
    e.country._id,
    e.sellType,
    e.unitCost,
  ];
};

export const PrintQuesAns = (e) => {
  return [
    e.audience,
    e.circulation,
    e.readership,
    e.formatType,
    e.heightCm,
    e.widthCm,
    e.position,
    e.unitCost,
  ];
};

export const RadioQuesAns = (e) => {
  return [
    e.mediaType,
    e.audience,
    e.adType,
    e.listenerss,
    e.daytimeParting,
    e.Rate,
  ];
};
export const OOHQuesAns = (e) => {
  return [
    e.mediaType,
    e.audience,
    e.product,
    e.formatSize,
    e.impacts,
    e.Rate,
    e.geoCountry,
    e.geoCity,
  ];
};

export const TelevisionQuesAns = (e) => {
  return [
    e.mediaType,
    e.audience,
    e.adType,
    e.adUnit,
    e.audienceAgegroup,
    e.rate,
    e.channel,
    e.viewership,
    e.daytimeParting,
  ];
};
