import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
  Modal,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { fade, makeStyles, withStyles } from "@material-ui/core/styles";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
// import Select from "@material-ui/core/Select"
import CircularProgress from "@material-ui/core/CircularProgress";
import CropSquareOutlined from "@material-ui/icons/CropSquareOutlined";
import MinimizeOutlined from "@material-ui/icons/MinimizeOutlined";
import axios from "axios";
import { ca, enGB } from "date-fns/locale";
import jwt_decode from "jwt-decode";
import moment from "moment";
import { Bar } from "react-chartjs-2";
import { Chart } from "react-google-charts";
import { DateRangePicker, END_DATE, START_DATE } from "react-nice-dates";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import { API, SECRET_KEY } from "../../../constants/index";
import MainLayout from "../../../layouts/newMainLayoutPlanner";
import AddCampaign from "./AddCampaign/AddCampaign";
import AddZone from "./AddZone/AddZone";

import { SaveAdminDropDownData } from "../../../store/actions/admin/intelligence";
import StaticDeviceData from "../IntelligenceDashboard/device.json";
import StaticData from "../IntelligenceDashboard/graph.json";
import StaticMediaData from "../IntelligenceDashboard/media.json";
import StaticQuestionData from "../IntelligenceDashboard/question.json";

import { Alert } from "@material-ui/lab";
import "react-nice-dates/build/style.css";
import useFetch, { useLazyFetch } from "../../../Hooks/useFetch";
import useStyles from "../../../components/StyleClasses";
import Subscription from "../subscription";

const useTableStyles = makeStyles({
  table: {
    minWidth: 700,
    maxWidth: 2000,
  },
});

const StyledTableRow = withStyles((theme) => ({
  root: {},
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.white,
    fontSize: 12,
    paddingBottom: "2px",
    paddingTop: "2px",
  },
  body: {
    fontSize: 12,
    border: "1px solid #D2D2D2",
    color: "#4F74C9",
    paddingBottom: "2px",
    paddingTop: "2px",
  },
}))(TableCell);

const useStyle = makeStyles((theme) => ({
  chartWrapperMin: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    "@media screen and (max-width: 768px)": {
      flexDirection: "column",
    },
  },
  chartWrapperMax: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  displayWrapperMin: {
    width: "50%",
    margin: "1rem 0.5em",
    background: "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))",
    padding: "0.5em",
    borderRadius: "5px",
    "@media screen and (max-width: 768px)": {
      width: "98%",
      margin: "1rem 1rem",
    },
  },
  displayWrapperMax: {
    width: "98%",
    margin: "1rem 1rem",
    background: "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))",
    padding: "0.5em",
    borderRadius: "5px",
  },
  videoWrapperMin: {
    width: "50%",
    margin: "1rem 0.5rem",
    background: "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))",
    padding: "0.5em",
    borderRadius: "5px",
    "@media screen and (max-width: 768px)": {
      width: "98%",
      margin: "1rem 1rem",
    },
  },
  videoWrapperMax: {
    width: "98%",
    margin: "1rem 1rem",
    background: "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))",
    padding: "0.5em",
    borderRadius: "5px",
  },
  header: {
    textAlign: "center",
    color: "#fff",
  },
  minmax: {
    color: "#fff",
    textAlign: "end",
    cursor: "pointer",
  },
  graphText: {
    color: "#fff",
    textAlign: "center",
    borderTop: "1px solid grey",
    paddingTop: "0.5em",
  },
  customfield: {
    paddingTop: "0",
    margin: "0.5rem 0.5rem 0 0",
    backgroundColor: "#ffffff",
    borderRadius: "5px",
    maxWidth: "230px",
    width: "230px",
    "& .MuiInputLabel-root.Mui-shrink": {
      color: "red",
      // "& .Mui-shrink": {
      // 	color: 'red'
      // },
    },
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&$focused": {
      backgroundColor: "#fff",
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.5px 14px",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",

      "& fieldset": {
        borderColor: "grey",
      },
      "&:hover fieldset": {},
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "grey",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "grey",
    },
    "& .MuiInputLabel-root.MuiInputLabel-shrink": {
      margin: "0!important",
    },
    "& .MuiInputLabel-root.MuiInputLabel-animated": {
      marginTop: "-8px",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",
    },
  },
  campaignNavBtn: {
    display: "flex",
    flexDirection: "row",
  },
  campaignAds: {
    width: "230px",
    margin: "0 0.2rem 0 0",
    maxWidth: "230px",
    paddingTop: "0",
    borderRadius: "5px",
    backgroundColor: "#ffffff",
  },
}));

const optionsPeriod = [
  { value: "day", label: "Day" },
  { value: "week", label: "Week" },
  { value: "month", label: "Month" },
  { value: "year", label: "Year" },
];

const staticData = [
  {
    type: "OOH",
    campaign: "ZeeTV",
    impressions: "10000560",
    clicks: "5000000",
    CTR: "0.4",
    VTR: "0.5",
    view: "Completed",
  },
  {
    type: "Digtal",
    campaign: "Jio Pvt.Ltd",
    impressions: "51230000",
    clicks: "49000000",
    CTR: "0.6",
    VTR: "0.8",
    view: "Not completed",
  },
  {
    type: "Radio",
    campaign: "New Car",
    impressions: "3000560",
    clicks: "250000",
    CTR: "0.2",
    VTR: "0.3",
    view: "Completed",
  },
  {
    type: "Online",
    campaign: "Youtube",
    impressions: "350001270",
    clicks: "300456001",
    CTR: "0.6",
    VTR: "0.3",
    view: "Not completed",
  },
  {
    type: "Online",
    campaign: "Twitch",
    impressions: "240567000",
    clicks: "150234007",
    CTR: "0.8",
    VTR: "0.6",
    view: "Completed",
  },
  {
    type: "Online",
    campaign: "LinkedIn",
    impressions: "500130",
    clicks: "250000",
    CTR: "0.2",
    VTR: "0.3",
    view: "Completed",
  },
  {
    type: "OOH",
    campaign: "ZeeTV",
    impressions: "10000560",
    clicks: "5000000",
    CTR: "0.4",
    VTR: "0.5",
    view: "Completed",
  },
  {
    type: "Digtal",
    campaign: "Jio Pvt.Ltd",
    impressions: "51230000",
    clicks: "49000000",
    CTR: "0.6",
    VTR: "0.8",
    view: "Not completed",
  },
  {
    type: "Radio",
    campaign: "New Car",
    impressions: "3000560",
    clicks: "250000",
    CTR: "0.2",
    VTR: "0.3",
    view: "Completed",
  },
  {
    type: "Online",
    campaign: "Youtube",
    impressions: "350001270",
    clicks: "300456001",
    CTR: "0.6",
    VTR: "0.3",
    view: "Not completed",
  },
  {
    type: "Online",
    campaign: "Twitch",
    impressions: "240567000",
    clicks: "150234007",
    CTR: "0.8",
    VTR: "0.6",
    view: "Completed",
  },
  {
    type: "Online",
    campaign: "LinkedIn",
    impressions: "500130",
    clicks: "250000",
    CTR: "0.2",
    VTR: "0.3",
    view: "Completed",
  },
];

export const dataDisplay = [
  ["Year", "Impressions", "Clicks", "Views"],
  ["2004", 1000, 500, 150],
  ["2005", 1170, 400, 350],
  ["2006", 660, 300, 250],
  ["2007", 1030, 250, 100],
];

export const optionsDisplay = {
  title: "Campaign Display Report",
  curveType: "function",
  legend: { position: "bottom" },
  tooltip: { isHtml: true, trigger: "visible" },
};

export const dataVideo = [
  ["Year", "Impressions", "Clicks"],
  ["2004", 1500, 800],
  ["2005", 1200, 500],
  ["2006", 1700, 110],
  ["2007", 500, 800],
];

export const optionsVideo = {
  title: "Campaign video Report",
  curveType: "function",
  legend: { position: "bottom" },
  tooltip: { isHtml: true, trigger: "visible" },
};

const backgroundColor = [
  "rgba(255, 99, 132, 0.8)",
  "rgba(255, 159, 64, 0.8)",
  "rgba(255, 205, 86, 0.8)",
  "rgba(75, 192, 192, 0.8)",
  "rgba(54, 162, 235, 0.8)",
  "rgba(153, 102, 255, 0.8)",
  "rgba(201, 203, 207, 0.8)",
];
const borderColor = [
  "rgb(255, 99, 132)",
  "rgb(255, 159, 64)",
  "rgb(255, 205, 86)",
  "rgb(75, 192, 192)",
  "rgb(54, 162, 235)",
  "rgb(153, 102, 255)",
  "rgb(201, 203, 207)",
];

const selectGraphArr = [
  {
    name: "Brand Awareness",
    kpi_type: "brand_awareness",
    color: "rgba(255, 99, 132, 0.8)",
  },
  {
    name: "Brand Consideration",
    kpi_type: "brand_consideration",
    color: "rgba(255, 159, 64, 0.8)",
  },
  {
    name: "Message Association",
    kpi_type: "message_association",
    color: "rgba(255, 205, 86, 0.8)",
  },
  {
    name: "Ad Recall",
    kpi_type: "ad_recall",
    color: "rgba(75, 192, 192, 0.8)",
  },
  {
    name: "Purchase Intent",
    kpi_type: "purchase_intent",
    color: "rgba(54, 162, 235, 0.8)",
  },
];

const CampaignReport = () => {
  const dispatch = useDispatch();
  const classesTable = useTableStyles();
  const classess = useStyle();
  const classes = useStyles();
  const dropdownData = useSelector((state) => state.adminDropDownData);
  const addCampaign = useRef();
  const history = useHistory();

  const access_token = window.localStorage.getItem(SECRET_KEY);

  const [loading, setLoading] = useState(false);
  const [metricLoading, setMetricLoading] = useState(false);
  // change this to loading state when fetching the campaing
  const [dateError, setDateError] = useState(false);
  const [loadingGraph, setLoadingGraph] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [campaignList, setCampaignList] = useState(null);
  const [advertiserId, setAdvertiserId] = useState([]);
  const [periodChoice, setPeriodChoice] = useState(null);
  const [min, setMin] = useState(true);
  const [options, setOptions] = useState([]);
  const [displayData, setDisplayData] = useState(null);
  const [videoData, setVideoData] = useState(null);
  const [apiCall, setApiCall] = useState(false);
  const [questionName, setQuestionName] = useState([]);
  const [campaign, setCampaign] = useState(0);
  const [campaignMetricList, setCampaignMetricList] = useState(null);
  const [insights, setInsights] = useState({});
  const [allQuestions, setAllQuestions] = useState([]);
  const [ageData, setAgeData] = useState([]);
  const [gender, setGender] = useState([]);
  const [frequency, setFrequency] = useState([]);
  const [device, setDevice] = useState([]);
  const [creative, setCreative] = useState([]);
  const [expandMetric, setExpandMetric] = useState(false);
  const [campaignId, setCampaignId] = useState([]);
  const [getCampaignLoading, setGetCampaignLoading] = useState(false);
  const [campaignDropList, setCampaignDropList] = useState([]);
  const [adsCampaign, setAdsCampaign] = useState(null);
  const [adsCampaignLabel, setAdsCampaignLabel] = useState(null);
  const [adsCampaignList, setAdsCampaignList] = useState([]);

  // Manage campaign section start
  const [popAddCampaign, setPopAddCampaign] = useState(false);
  // const [popAddZone, setPopAddZone] = useState(false)
  // Manage campaign section end

  const getSubscription = window.localStorage.getItem("subscription");
  let decodedSubscription = getSubscription && jwt_decode(getSubscription);


  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API}/mediaPlanner/AdButler/getAdvertiserList`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        // setCampaignList(res.data)
        let ad = [];
        res.data.advertisers.forEach((element) => {
          let temp = {
            value: "",
            label: "",
          };
          temp.value = element.advertiser.id;
          temp.label = element.advertiser.name;
          ad.push(temp);
        });
        setOptions(ad);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (
      startDate &&
      endDate &&
      advertiserId.length > 0 &&
      periodChoice &&
      campaignId.length > 0 && moment(endDate).diff(moment(startDate), "days") < 365
      ) {
    setLoadingGraph(true);
      let advertisers = "";
      advertiserId.forEach((item) => {
        if (advertisers === "") {
          advertisers = `${item.value}`;
        } else {
          advertisers = `${advertisers}, ${item.value}`;
        }
      });
      const campaignIds = [];
      campaignId.forEach((item) => {
        campaignIds.push(item.value);
      });
      const endDates = moment(endDate).format();
      const startDates = moment(startDate).format();
      const data = {
        startDate: startDates,
        endDate: endDates,
        advertisers,
        period: periodChoice.value,
        campaigns: campaignIds,
      };

      // axios
      //   .post(`${API}/mediaPlanner/AdButler/getAllAdvertiserReport`, data, {
      //     headers: {
      //       Authorization: `Bearer ${access_token}`,
      //     },
      //   })
      //   .then((res) => {
      //     if (res.data.VASTGraphReport.length > 0) {
      //       setVideoData(res.data.VASTGraphReport[0].data)
      //     }
      //     if (res.data.reportGraph.length > 0) {
      //       setDisplayData(res.data.reportGraph[0].data)
      //     }
      //     setLoadingGraph(false)
      //   })
      //   .catch((err) => {
      //     console.log("err", err)
      //     setLoadingGraph(false)
      //   })

      const datum = {
        startDate: startDates,
        endDate: endDates,
        period: periodChoice.value,
        campaigns: campaignIds,
      };

      axios
        .post(`${API}/mediaPlanner/AdButler/getCampaignReport`, datum, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then((res) => {
        setCampaignList(res.data.campaignReport);
          setLoadingGraph(false);
        })
        .catch((err) => {
          console.log("err", err);
          setLoadingGraph(false);
        
        });
      setApiCall(true);
    } else {
      setApiCall(false);
    }
  }, [startDate, endDate, advertiserId, campaignId, periodChoice]);

  useEffect(() => {
    const access_token = window.localStorage.getItem(SECRET_KEY);
    axios
      .get(`${API}/mediaPlanner/dropdowns`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res) {
          dispatch(SaveAdminDropDownData(res.data));
        }
      });
  }, []);

  const getCamapaignList = () => {
    setMetricLoading(true);
    setCampaignMetricList([]);
    let endPoint = `/mediaPlanner/lucidApi/`;

    axios
      .get(`${API}${endPoint}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res) {
          setCampaignMetricList(res.data.items);
        }
        setMetricLoading(false);
      })
      .catch(function (error) {
        if (error) {
          setMetricLoading(false);
        }
      });
  };

  useEffect(() => {
    getCamapaignList();
  }, []);
  useEffect(() => {
    if (moment(endDate).diff(moment(startDate), "days") > 365) {
      setDateError(true);
    } else {
      setDateError(false);
    }
  }, [startDate, endDate]);

  const graphDataFunction = (ageDataArr, ages, type) => {
    let arr =
      ageDataArr && ageDataArr.length ? [...ageDataArr] : [...StaticData];

    let brand_awareness = [];
    let brand_consideration = [];
    let message_association = [];
    let ad_recall = [];
    let purchase_intent = [];
    let bucket_name = [];
    if (type === "frequency") {
      arr[0].items.map((as) => {
        bucket_name.push(as.frequency_bucket_name);
      });
    } else if (type === "device") {
      arr[0].items.map((as) => {
        bucket_name.push(as.device);
      });
    } else if (type === "creative") {
      arr[0].items.map((as, i) => {
        bucket_name.push(
          as.creative_name ?? as.creative_id ?? `CreativeTemplate${i + 1}`
        );
      });
    } else {
      arr[0].items.map((as) => {
        bucket_name.push(as.bucket_name);
      });
    }

    const datasets = [];
    let labels = [];
    let datasetLabel = [];
    const finalData = [];
    arr &&
      arr.length &&
      arr.map((ag, i) => {
        switch (ag.question.question_name) {
          case "brand_awareness":
            ag.items.filter((a) => {
              if (type === "device") {
                bucket_name.map((as) => {
                  if (a.device === as) {
                    brand_awareness.push(a.lift_percentage);
                  }
                });
              } else if (type === "creative") {
                bucket_name.map((as, i) => {
                  if (
                    a.creative_id === as ||
                    a.creative_name === as ||
                    `CreativeTemplate${i + 1}` === as
                  ) {
                    brand_awareness.push(a.lift_percentage);
                  }
                });
              } else {
                ages.map((as) => {
                  if (type === "frequency") {
                    if (a.frequency_bucket_order === as) {
                      brand_awareness.push(a.total_count);
                    }
                  } else {
                    if (a.bucket_name === as.name) {
                      // brand_awareness.push(a.total_count);
                      brand_consideration.push({
                        key: as.name,
                        value: a.total_count,
                      });
                    }
                  }
                });
              }
            });
            break;
          case "brand_consideration":
            ag.items.filter((a) => {
              if (type === "device") {
                bucket_name.map((as) => {
                  if (a.device === as) {
                    brand_consideration.push(a.lift_percentage);
                  }
                });
              } else if (type === "creative") {
                bucket_name.map((as) => {
                  if (
                    a.creative_id === as ||
                    a.creative_name === as ||
                    `CreativeTemplate${i + 1}` === as
                  ) {
                    brand_consideration.push(a.lift_percentage);
                  }
                });
              } else {
                ages.map((as) => {
                  if (type === "frequency") {
                    if (a.frequency_bucket_order === as) {
                      brand_consideration.push({
                        key: as,
                        value: a.total_count,
                      });
                    }
                  } else {
                    if (a.bucket_name === as.name) {
                      // brand_consideration.push(a.total_count);
                      brand_consideration.push({
                        key: as.name,
                        value: a.total_count,
                      });
                    }
                  }
                });
              }
            });
            break;
          case "message_association":
            ag.items.filter((a) => {
              if (type === "device") {
                bucket_name.map((as) => {
                  if (a.device === as) {
                    message_association.push(a.lift_percentage);
                  }
                });
              } else if (type === "creative") {
                bucket_name.map((as) => {
                  if (
                    a.creative_id === as ||
                    a.creative_name === as ||
                    `CreativeTemplate${i + 1}` === as
                  ) {
                    message_association.push(a.lift_percentage);
                  }
                });
              } else {
                ages.map((as) => {
                  if (type === "frequency") {
                    if (a.frequency_bucket_order === as) {
                      message_association.push(a.total_count);
                    }
                  } else {
                    if (a.bucket_name === as.name) {
                      message_association.push(a.total_count);
                    }
                  }
                });
              }
            });
            break;
          case "ad_recall":
            ag.items.filter((a) => {
              if (type === "device") {
                bucket_name.map((as) => {
                  if (a.device === as) {
                    ad_recall.push(a.lift_percentage);
                  }
                });
              } else if (type === "creative") {
                bucket_name.map((as) => {
                  if (
                    a.creative_id === as ||
                    a.creative_name === as ||
                    `CreativeTemplate${i + 1}` === as
                  ) {
                    ad_recall.push(a.lift_percentage);
                  }
                });
              } else {
                ages.map((as) => {
                  if (type === "frequency") {
                    if (a.frequency_bucket_order === as) {
                      ad_recall.push(a.total_count);
                    }
                  } else {
                    if (a.bucket_name === as.name) {
                      ad_recall.push(a.total_count);
                    }
                  }
                });
              }
            });
            break;
          case "purchase_intent":
            ag.items.filter((a) => {
              if (type === "device") {
                bucket_name.map((as) => {
                  if (a.device === as) {
                    purchase_intent.push(a.lift_percentage);
                  }
                });
              } else if (type === "creative") {
                bucket_name.map((as) => {
                  if (
                    a.creative_id === as ||
                    a.creative_name === as ||
                    `CreativeTemplate${i + 1}` === as
                  ) {
                    brand_consideration.push(a.lift_percentage);
                  }
                });
              } else {
                ages.map((as) => {
                  if (type === "frequency") {
                    if (a.frequency_bucket_order === as) {
                      purchase_intent.push(a.total_count);
                    }
                  } else {
                    if (a.bucket_name === as.name) {
                      purchase_intent.push(a.total_count);
                    }
                  }
                });
              }
            });
            break;
          default:
            return ag;
        }
      });
    arr.map((sg) => {
      datasetLabel.push(sg.question.question_name);
    });

    const dataTemp = [];

    if (type === "device" || type === "creative") {
      datasetLabel.forEach((data) => {
        arr.forEach((item) => {
          const gd = [];
          item.items.forEach((d) => {
            if (d.question.question_name === data) {
              gd.push(d.lift_percentage);
            }
          });
          const d = {
            key: data,
            value: gd,
          };
          if (d.value.length > 0) {
            dataTemp.push(d);
          }
        });
      });
    } else {
      datasetLabel.forEach((data) => {
        arr.forEach((item) => {
          const gd = [];
          item.items.forEach((d) => {
            if (d.question.question_name === data) {
              gd.push(d.total_count);
            }
          });
          const d = {
            key: data,
            value: gd,
          };
          if (d.value.length > 0) {
            dataTemp.push(d);
          }
        });
      });
    }

    datasetLabel &&
      datasetLabel.length &&
      datasetLabel.map((dl, i) => {
        if (dataTemp[i].key === dl) {
          datasets.push({
            label: dl,
            borderWidth: 1,
            data: dataTemp[i].value,
            backgroundColor: backgroundColor[i],
            borderColor: borderColor[i],
          });
        }
      });
    bucket_name &&
      bucket_name.length &&
      bucket_name.map((bn) => {
        labels.push(bn);
      });
    labels = labels.filter(function (elem, pos) {
      return labels.indexOf(elem) == pos;
    });

    finalData.push({ labels: labels, datasets: datasets });
    return finalData;
  };

  const capitalizeFirstLetter = (string) => {
    // let str = string.replace(/_/g, " ");
    if (string == "participantDemography") string = "participantDemographics";
    let str = string.replace(/[A-Z]/g, " $&").trim();
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const getLiftInsights = () => {
    setMetricLoading(true);
    setInsights({});
    let endPoint = `/mediaPlanner/lucidApi/${campaign}/liftInsights?filter_question=${questionName}`;

    axios
      .get(`${API}${endPoint}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (
          res &&
          res.data &&
          res.data.overview &&
          ((res.data.overview.media && res.data.overview.media.length) ||
            (res.data.overview.audience && res.data.overview.audience.length))
        ) {
          setInsights(res.data.overview);
        } else {
          setInsights(StaticMediaData);
        }
        setMetricLoading(false);
      })
      .catch(function (error) {
        if (error) {
          setMetricLoading(false);
        }
      });
  };

  const getLiftForAllQuestions = () => {
    setMetricLoading(true);
    setAllQuestions([]);
    let endPoint = `/mediaPlanner/lucidApi/${campaign}/campaignData/liftForAllQuestions?filter_question=${questionName}`;

    axios
      .get(`${API}${endPoint}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res && res.data && res.data.items && res.data.items.length) {
          setAllQuestions(res.data.items);
        } else {
          setAllQuestions(StaticQuestionData);
        }
        setMetricLoading(false);
      })
      .catch(function (error) {
        if (error) {
          setMetricLoading(false);
        }
      });
  };

  const getLiftLiftByDemoAge = () => {
    setMetricLoading(true);
    setAgeData([]);
    let ageDatasets = [];

    let endPoint = `/mediaPlanner/lucidApi/${campaign}/demo_age/liftByDemo?filter_question=${questionName}`;

    axios
      .get(`${API}${endPoint}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (
          res.data &&
          res.data.length &&
          dropdownData &&
          dropdownData.ageGroups &&
          dropdownData.ageGroups.length
        ) {
          ageDatasets = graphDataFunction(
            res.data,
            dropdownData.ageGroups,
            "age"
          );
        }
        setAgeData(ageDatasets);
        setMetricLoading(false);
      })
      .catch(function (error) {
        if (error) {
          setMetricLoading(false);
        }
      });
  };

  const getLiftLiftByDemoGender = () => {
    setMetricLoading(true);
    setGender([]);
    let genderDatasets = [];

    let endPoint = `/mediaPlanner/lucidApi/${campaign}/demo_gender/liftByDemo?filter_question=${questionName}`;
    axios
      .get(`${API}${endPoint}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (
          res.data &&
          res.data.length &&
          dropdownData &&
          dropdownData.genders &&
          dropdownData.genders.length
        ) {
          genderDatasets = graphDataFunction(
            res.data,
            dropdownData.genders,
            "genders"
          );
        }
        setGender(genderDatasets);
        setMetricLoading(false);
      })
      .catch(function (error) {
        if (error) {
          setMetricLoading(false);
        }
      });
  };

  const getLiftbyQuestionFrequency = () => {
    setMetricLoading(true);
    setFrequency([]);
    let frequencyDatasets = [];
    let endPoint = `/mediaPlanner/lucidApi/${campaign}/campaignData/LiftbyQuestionFrequency?filter_question=${questionName}`;

    axios
      .get(`${API}${endPoint}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (
          res.data &&
          res.data.length &&
          dropdownData &&
          dropdownData.publishMonths &&
          dropdownData.publishMonths.length
        ) {
          frequencyDatasets = graphDataFunction(
            res.data,
            dropdownData.publishMonths,
            "frequency"
          );
        }
        setFrequency(frequencyDatasets);
        setMetricLoading(false);
      })
      .catch(function (error) {
        if (error) {
          setMetricLoading(false);
        }
      });
  };

  const getLiftByDevice = () => {
    setMetricLoading(true);
    setDevice([]);
    let deviceDatasets = [];
    const liftDatasetsArr = [];
    const deviceFinalData = [];
    const liftLabelsArr = [];
    let endPoint = `/mediaPlanner/lucidApi/${campaign}/liftByDevice?filter_question=${questionName}`;

    axios
      .get(`${API}${endPoint}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res && res.data && res.data.length) {
          deviceDatasets = graphDataFunction(res.data, [], "device");
          setDevice(deviceDatasets);
          // res.data[0].items.map((item, i) => {
          //   liftLabelsArr.push(item.device);
          //   liftDatasetsArr.push({
          //     label: item.device,
          //     data: [item.lift_percentage ? item.lift_percentage : 0],
          //     borderWidth: 1,
          //     backgroundColor: backgroundColor[i],
          //     borderColor: borderColor[i],
          //   });
          // });
          // deviceFinalData.push({
          //   labels: liftLabelsArr,
          //   datasets: liftDatasetsArr,
          // });
          // setDevice(deviceFinalData);
        }
        // else {
        //   StaticDeviceData.map((item, i) => {
        //     liftLabelsArr.push(item.device_name);
        //     liftDatasetsArr.push({
        //       label: item.device_name,
        //       data: [item.lift_percentage ? item.lift_percentage : 0],
        //       borderWidth: 1,
        //       backgroundColor: backgroundColor[i],
        //       borderColor: borderColor[i],
        //     });
        //   });
        //   deviceFinalData.push({
        //     labels: liftLabelsArr,
        //     datasets: liftDatasetsArr,
        //   });
        //   setDevice(deviceFinalData);
        // }
        setMetricLoading(false);
      })
      .catch(function (error) {
        if (error) {
          setMetricLoading(false);
        }
      });
  };

  const getLiftBreakdownByCreative = () => {
    setMetricLoading(true);
    setCreative([]);
    let deviceDatasets = [];
    const liftDatasets = [];
    const creativeFinalData = [];
    const liftLabels = [];
    let endPoint = `/mediaPlanner/lucidApi/${campaign}/campaignData/liftBreakdownByCreative?filter_question=${questionName}`;

    axios
      .get(`${API}${endPoint}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res && res.data && res.data.length) {
          deviceDatasets = graphDataFunction(res.data, [], "creative");
          setCreative(deviceDatasets);

          // res.data.items.map((item, i) => {
          //   liftLabels.push(item.creative_name);
          //   liftDatasets.push({
          //     label: item.creative_name,
          //     data: [item.lift_percentage ? item.lift_percentage : 0],
          //     borderWidth: 1,
          //     backgroundColor: backgroundColor[i],
          //     borderColor: borderColor[i],
          //   });
          // });
          // creativeFinalData.push({
          //   labels: liftLabels,
          //   datasets: liftDatasets,
          // });
          // setCreative(creativeFinalData);
        }
        // else {
        //   StaticDeviceData.map((item, i) => {
        //     liftLabels.push(item.creative_name);
        //     liftDatasets.push({
        //       label: item.creative_name,
        //       data: [item.lift_percentage ? item.lift_percentage : 0],
        //       borderWidth: 1,
        //       backgroundColor: backgroundColor[i],
        //       borderColor: borderColor[i],
        //     });
        //   });
        //   creativeFinalData.push({
        //     labels: liftLabels,
        //     datasets: liftDatasets,
        //   });
        //   setCreative(creativeFinalData);
        // }
        setMetricLoading(false);
      })
      .catch(function (error) {
        if (error) {
          setMetricLoading(false);
        }
      });
  };

  useEffect(() => {
    if (campaign === 0) return;
    getLiftInsights();
    getLiftForAllQuestions();
    getLiftLiftByDemoAge();
    getLiftLiftByDemoGender();
    getLiftbyQuestionFrequency();
    getLiftByDevice();
    getLiftBreakdownByCreative();
  }, [campaign, questionName]);

  const minmaxHandler = () => {
    setMin(!min);
  };
  // http://192.168.29.172:5000/api/v1/mediaPlanner/AdButler/getCampaignGraphReport
  const advertiseHandler = (e) => {
    setAdvertiserId(e);
    setGetCampaignLoading(true);
    setCampaignDropList([]);
    e.map(async (item) => {
      await axios
        .get(
          `${API}/mediaPlanner/AdButler/getAssignedCampaigns/${item.value}`,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        )
        .then((res) => {
          res.data.campaigns.forEach((item) => {
            const dd = {
              value: item.campaign.id,
              label: item.campaign.name,
            };
            setCampaignDropList((prev) => prev.concat(dd));
          });
          setGetCampaignLoading(false);
        })
        .catch((err) => {
          console.log("err", err);
          setGetCampaignLoading(false);
        });
    });
    setPeriodChoice(null);

    if (advertiserId.length - 1 <= 0) {
      setCampaignId([]);
    }
    setGetCampaignLoading(false);
  };

  const rowHandler = async (row) => {
    setLoadingGraph(true);
    const rowName = row.name.split(" ").join("");
    campaignMetricList.forEach((item) => {
      if (rowName === item.campaign_name.split(" ").join("")) {
        setCampaign(item.campaign_id);
      }
    });
    const data = {
      startDate: `${moment(startDate).format("YYYY-MM-DD")}T00:00:00+05:30`,
      endDate: `${moment(endDate).format("YYYY-MM-DD")}T00:00:00+05:30`,
      period: periodChoice.value,
      campaigns: [row.id],
    };

    await axios
      .post(`${API}/mediaPlanner/AdButler/getCampaignGraphReport`, data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        if (res.data.type === "display") {
          setDisplayData(res.data?.reportGraph[0].data);
        } else {
          setVideoData(res.data?.VASTGraphReport[0].data);
        }
        setLoadingGraph(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoadingGraph(false);
      });
  };

  const addCampaignHandler = () => {
    setPopAddCampaign(true);
  };

  const {data,fetchData: fetchAdsCampaign,fetching: fetchAdsCampaignList,error,clearData} = useLazyFetch()

  const adsCampaignHandler = async (e) => {
    setAdsCampaignLabel(e.label)
    setAdsCampaign(e.value);
    fetchAdsCampaign(`${API}/mediaPlanner/AdButler/getAllCampaignsList?advertiser=${e.value}`).then(res => {
      if(res?.data){
        setAdsCampaignList(res.data)
      }
    }).catch(err => console.log(err))

    // old implementation
    // const access_token = localStorage.getItem(SECRET_KEY);
    // await axios
    //   .get(
    //     `${API}/mediaPlanner/AdButler/getAllCampaignsList?advertiser=${e.value}`,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${access_token}`,
    //       },
    //     }
    //   )
    //   .then((res) => {
    //     // console.log("res.data123", res.data.data);
    //     setAdsCampaignList(res.data.data);
    //   })
    //   .catch((err) => {
    //     console.log("[ERROR]", err.response.data.message);
    //   });
  };

  const addZoneHandler = () => {
    // setPopAddZone(true)
    history.push("/media-planner/addZone");
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <div style={{ marginBottom: "20px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: "20px 0 10px 0",
          }}
        >
          <span
            style={{
              color: "#FB6D6A",
              fontWeight: "600",
              margin: "0",
              fontSize: "20px",
              marginLeft: "20px",
            }}
          >
            Campaign List
          </span>
          <div className={classess.campaignNavBtn}>
            <Select
              placeholder="Advertise"
              defaultValue={adsCampaign}
              onChange={adsCampaignHandler}
              className={classess.campaignAds}
              options={options}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={addCampaignHandler}
              style={{ marginRight: "0.2rem" }}
              disabled={adsCampaign === null ?? false}
            >
              Create campaign
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={addZoneHandler}
            >
              Assign to Zone
            </Button>
          </div>
        </div>
        <TableContainer component={Paper} style={{ maxHeight: "400px" }}>
          <Table
            stickyHeader
            className={classesTable.table}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                {/* {publisherHeaderTable.map((item) => ( */}
                <TableCell
                  style={{
                    color: "white",
                    background:
                      "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))",
                  }}
                >
                  Type
                </TableCell>
                <TableCell
                  style={{
                    color: "white",
                    background:
                      "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))",
                  }}
                >
                  Campaign Name
                </TableCell>
                <TableCell
                  style={{
                    color: "white",
                    background:
                      "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))",
                  }}
                >
                  Ads
                </TableCell>
                <TableCell
                  style={{
                    color: "white",
                    background:
                      "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))",
                  }}
                >
                  Included In Zones
                </TableCell>
                <TableCell
                  style={{
                    color: "white",
                    background:
                      "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))",
                  }}
                >
                  Targeting Level
                </TableCell>
                {/* <TableCell style={{ color: "white", background: "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))" }}>VTR</TableCell> */}
                {/* <TableCell style={{ color: "white", background: "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))" }}>Completed View</TableCell> */}
                {/* ))} */}
                {/* <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              { fetchAdsCampaignList && <CircularProgress /> }
              {(adsCampaignList.length == 0 || !adsCampaignList) &&  <StyledTableRow>
                <StyledTableCell> No records found.</StyledTableCell>
              </StyledTableRow>}
              {
                adsCampaignList?.map((campaign) => {
                  return (
                    <TableRow key={campaign.id} style={{cursor: "pointer"}} onClick={() => history.push(`/media-planner/campaign/advertiser/${adsCampaign}/${campaign.id}/${campaign.object}/${campaign.name}/${adsCampaignLabel}`)}>
                      <TableCell>{campaign.object == "standard_campaign" ? "Standard" : 'Vast'}</TableCell>
                      <TableCell>{campaign.name}</TableCell>
                      <TableCell>-</TableCell>
                      <TableCell>-</TableCell>
                      <TableCell>{campaign?.targeting_source ? "Campaign" : "-"}</TableCell>
                    </TableRow>      
                  )
                })
              }
              {/* <TableRow>
                <TableCell>Standard</TableCell>
                <TableCell>Hindsight-Black</TableCell>
                <TableCell>2</TableCell>
                <TableCell>3</TableCell>
                <TableCell>Campaign</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Vast</TableCell>
                <TableCell>Amex</TableCell>
                <TableCell>4</TableCell>
                <TableCell>5</TableCell>
                <TableCell>-</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Standard</TableCell>
                <TableCell>GCNYC-Display</TableCell>
                <TableCell>5</TableCell>
                <TableCell>16</TableCell>
                <TableCell>Campaign</TableCell>
              </TableRow> */}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <TextField
        id="advertisers"
        label="Report"
        variant="outlined"
        margin="normal"
        defaultValue="Advertisers"
        disabled
        className={classess.customfield}
      />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
            // minimumDate={
            //   new Date(new Date().setFullYear(new Date().getFullYear() - 1))
            // }
            maximumDate={new Date()}
            minimumLength={1}
            format="dd MMM yyyy"
            locale={enGB}
          >
            {({ startDateInputProps, endDateInputProps, focus }) => (
              <div style={{ display: "flex" }}>
                <TextField
                  id="startDate"
                  label="Start From"
                  variant="outlined"
                  margin="normal"
                  onChange={(e) => setStartDate(e.target.value)}
                  className={classess.customfield}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...startDateInputProps}
                />
                <TextField
                  id="endDate"
                  label="End At"
                  variant="outlined"
                  margin="normal"
                  className={classess.customfield}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...endDateInputProps}
                />
              </div>
            )}
          </DateRangePicker>
        </div>
        <div>
          <Select
            isMulti
            placeholder="Advertise"
            defaultValue={advertiserId}
            onChange={advertiseHandler}
            className={classess.customfield}
            options={options}
          />
        </div>
        <div>
          <Select
            isMulti
            placeholder="Campaign"
            value={campaignId}
            onChange={setCampaignId}
            className={classess.customfield}
            options={campaignDropList}
          />
          {getCampaignLoading && (
            <div
              style={{ position: "relative", right: "130px", bottom: "10px" }}
            >
              <CircularProgress size={20} />
            </div>
          )}
        </div>
        <div>
          <Select
            placeholder="Period"
            value={periodChoice}
            onChange={setPeriodChoice}
            className={classess.customfield}
            options={optionsPeriod}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "20px 0 10px 0",
        }}
      >
        <span
          style={{
            color: "#FB6D6A",
            fontWeight: "600",
            margin: "0",
            fontSize: "20px",
            marginLeft: "20px",
          }}
        >
          All Campaign Summary
        </span>
        {/* <div>
          <Button 
            variant="contained"
            color="primary"
            onClick={addCampaignHandler}
            style={{marginRight:"0.2rem"}}
          >
            Create campaign
          </Button>
          <Button 
            variant="contained"
            color="primary"
            onClick={addZoneHandler}
          >
            Assign to Zone
          </Button>
        </div> */}
      </div>
      <TableContainer
        component={Paper}
        style={{ marginBottom: "20px", maxHeight: "400px" }}
      >
        <Table
          stickyHeader
          className={classesTable.table}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              {/* {publisherHeaderTable.map((item) => ( */}
              <TableCell
                style={{
                  color: "white",
                  background:
                    "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))",
                }}
              >
                Type
              </TableCell>
              <TableCell
                style={{
                  color: "white",
                  background:
                    "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))",
                }}
              >
                Campaign Name
              </TableCell>
              <TableCell
                style={{
                  color: "white",
                  background:
                    "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))",
                }}
              >
                Impressions
              </TableCell>
              <TableCell
                style={{
                  color: "white",
                  background:
                    "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))",
                }}
              >
                Viewable Impressions
              </TableCell>
              <TableCell
                style={{
                  color: "white",
                  background:
                    "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))",
                }}
              >
                Clicks
              </TableCell>
              <TableCell
                style={{
                  color: "white",
                  background:
                    "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))",
                }}
              >
                CTR
              </TableCell>
              <TableCell
                style={{
                  color: "white",
                  background:
                    "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))",
                }}
              >
                VTR
              </TableCell>
              {/* <TableCell style={{ color: "white", background: "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))" }}>VTR</TableCell> */}
              {/* <TableCell style={{ color: "white", background: "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))" }}>Completed View</TableCell> */}
              {/* ))} */}
              {/* <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell> */}
            </TableRow>
          </TableHead>
          {campaignList === null ? (
            <TableBody>
              <StyledTableRow>
                <StyledTableCell> No records found.</StyledTableCell>
              </StyledTableRow>
            </TableBody>
          ) : (
            <TableBody style={{ cursor: "pointer" }}>
              {campaignList.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  onClick={() => rowHandler(row)}
                >
                  {loadingGraph === true ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <TableCell component="th" scope="row">
                        {row.type ? row.type : "-"}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.name ? row.name : "-"}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.summary.impressions
                          ? row.summary.impressions
                          : "-"}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.summary.viewable_impressions
                          ? row.summary.viewable_impressions
                          : "-"}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.summary.clicks ? row.summary.clicks : "-"}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.isVASTCampaign &&
                        row.summary.impressions &&
                        row.summary.clicks
                          ? (
                              (row.summary.clicks / row.summary.impressions) *
                              100
                            ).toFixed(2)
                          : "-"}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.isVASTCampaign &&
                        row.summary.impressions &&
                        row.summary.clicks
                          ? (
                              (row.summary.complete / row.summary.impressions) *
                              100
                            ).toFixed(2)
                          : "-"}
                      </TableCell>
                      {/* <TableCell component="th" scope="row">
                    {row.VTR ? row.VTR : "-"}
                  </TableCell> */}
                      {/* <TableCell component="th" scope="row">
                    {row.view ? row.view : "-"}
                  </TableCell> */}
                    </>
                  )}
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      {displayData && (
        <div className={classess.chartWrapperMax}>
          <div className={classess.displayWrapperMax}>
            <h2 className={classess.header}>Display Report</h2>
            <Chart
              chartType="LineChart"
              width="100%"
              height="400px"
              data={displayData}
              options={optionsDisplay}
            />
          </div>
        </div>
      )}
      {videoData && (
        <div className={classess.chartWrapperMax}>
          <div className={classess.displayWrapperMax}>
            <h2 className={classess.header}>Video Report</h2>
            <Chart
              chartType="LineChart"
              width="100%"
              height="400px"
              data={videoData}
              options={optionsVideo}
            />
          </div>
        </div>
      )}
      {/* <div className={min ? classess.chartWrapperMin : classess.chartWrapperMax}>
        <div
          className={
            min ? classess.displayWrapperMin : classess.displayWrapperMax
          }
        >
          <div className={classess.minmax}>
            <span onClick={minmaxHandler}>
              {min ? <CropSquareOutlined /> : <MinimizeOutlined />}
            </span>
          </div>
          <h2 className={classess.header}>Display Report</h2>
          {loadingGraph ? (
            <CircularProgress />
          ) : displayData ? (
            <Chart
              chartType="LineChart"
              width="100%"
              height="400px"
              data={displayData}
              options={optionsDisplay}
            />
          ) : (
            <h3 className={classess.graphText}>
              {apiCall
                ? "No data found"
                : "Select all fields above to display chart report"}
            </h3>
          )}
        </div>
        <div
          className={min ? classess.videoWrapperMin : classess.videoWrapperMax}
        >
          <div className={classess.minmax}>
            <span onClick={minmaxHandler}>
              {min ? <CropSquareOutlined /> : <MinimizeOutlined />}
            </span>
          </div>
          <h2 className={classess.header}>Video Report</h2>
          {loadingGraph ? (
            <CircularProgress />
          ) : videoData ? (
            <Chart
              chartType="LineChart"
              width="100%"
              height="400px"
              data={videoData}
              options={optionsVideo}
            />
          ) : (
            <h3 className={classess.graphText}>
              {apiCall
                ? "No data found"
                : "Select all fields above to display chart report"}
            </h3>
          )}
        </div>
      </div> */}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "20px 0 10px 0",
        }}
      >
        <span
          style={{
            color: "#FB6D6A",
            fontWeight: "600",
            margin: "0",
            fontSize: "20px",
            marginLeft: "20px",
          }}
        >
          Campaign Metrics
        </span>
      </div>
      {metricLoading && <CircularProgress color="colorSecondary" size={40} />}
      <Grid item xs={12} sm={12} md={12}>
        <Box className={!expandMetric ? classes.cardBox : ""}>
          <Card
            style={{
              backgroundImage: "linear-gradient(#4911a2,#9211a2)",
              color: "white",
              height: !expandMetric ? "420px" : "100%",
              overflow: "auto",
            }}
          >
            <CardContent>
              {!expandMetric ? (
                <div
                  style={{
                    float: "right",
                    color: "white",
                    marginTop: "10px",
                    cursor: "pointer",
                  }}
                  title="Maximize"
                >
                  <CropSquareOutlined
                    onClick={() => setExpandMetric(!expandMetric)}
                  />
                </div>
              ) : (
                <div
                  style={{
                    float: "right",
                    color: "white",
                    marginBottom: "10px",
                    cursor: "pointer",
                  }}
                  title="Manimize"
                >
                  <MinimizeOutlined
                    onClick={() => setExpandMetric(!expandMetric)}
                  />
                </div>
              )}
              {decodedSubscription &&
              decodedSubscription.subscription === "free" ? (
                <Subscription />
              ) : (
                <>
                  {/* <FormControl
                    fullWidth
                    component="fieldset"
                    variant="outlined"
                    className={[
                      classes.selectControl,
                      classes.customfield,
                    ].join(" ")}
                    style={{
                      marginTop: "5px",
                      width: "24%",
                      float: "right",
                    }}
                  >
                    <Select
                      id="outlined-select-currency-native"
                      multiple
                      // label="KPI Type"
                      value={questionName}
                      onChange={(e) => {
                        {
                          setQuestionName(e.target.value)
                        }
                      }}
                      variant="outlined"
                      renderValue={(ep) => {
                        let arrNm = []
                        questionName.map((e) => {
                          let nm = capitalizeFirstLetter(e)
                          arrNm.push(nm + " , ")
                        })
                        return arrNm
                      }}
                    > 
                      <MenuItem disabled value={0}>
                        <em>select kpi type</em>
                      </MenuItem>
                      {selectGraphArr &&
                        selectGraphArr.length &&
                        selectGraphArr?.map((option) => (
                          <MenuItem
                            key={option.kpi_type}
                            value={option.kpi_type}
                          >
                            <Checkbox
                              checked={option.kpi_type}
                            ></Checkbox>
                            <ListItemText primary={option.name}/>
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl> */}

                  <FormControl
                    component="fieldset"
                    variant="outlined"
                    className={[
                      classes.selectControl,
                      classes.customfield,
                    ].join(" ")}
                    style={{
                      marginTop: "5px",
                      marginRight: "10px",
                      width: "24%",
                      float: "right",
                    }}
                  >
                    <TextField
                      id="outlined-select-currency-native"
                      select
                      value={campaign}
                      onChange={(e) => setCampaign(e.target.value)}
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                    >
                      <option key={0} value={0}>
                        Select campaign...
                      </option>
                      {campaignMetricList &&
                        campaignMetricList.length &&
                        campaignMetricList?.map((option) => (
                          <option
                            key={option.campaign_id}
                            value={option.campaign_id}
                          >
                            {option.campaign_name}
                          </option>
                        ))}
                    </TextField>
                  </FormControl>

                  {campaign !== 0 ? (
                    <div
                      style={{
                        marginTop: "20px",
                        //   display: "flex",
                        //   justifyContent: "space-between",
                        //   flexWrap: "wrap",
                      }}
                    >
                      <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                      >
                        {insights && insights.media && insights.media.length ? (
                          <Grid item xs={6} sm={6} md={6}>
                            <span
                              style={{
                                justifyContent: "center",
                                display: "flex",
                              }}
                            >
                              Media Insights
                            </span>
                            <br />
                            <Grid
                              container
                              direction="row"
                              justify="flex-start"
                              alignItems="flex-start"
                            >
                              {insights.media?.map((inside) => (
                                <Grid item xs={6} sm={6} md={6}>
                                  <Box>
                                    <Card className={classes.graphCardBox}>
                                      <CardContent>
                                        <span>
                                          {inside.insight_name
                                            ? inside.insight_name
                                            : ""}
                                        </span>
                                        <br />
                                        <span>
                                          <b>
                                            {inside.lift_percentage
                                              ? inside.lift_percentage
                                              : ""}
                                            %
                                          </b>
                                        </span>
                                        <br />
                                        <span>
                                          <b>Publisher #9</b> has the highest
                                          lift
                                        </span>
                                        <br />
                                      </CardContent>
                                    </Card>
                                  </Box>
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>
                        ) : null}
                        {insights &&
                        insights.audience &&
                        insights.audience.length ? (
                          <Grid item xs={6} sm={6} md={6}>
                            <span
                              style={{
                                justifyContent: "center",
                                display: "flex",
                              }}
                            >
                              Audience Insights
                            </span>{" "}
                            <br />
                            <Grid
                              container
                              direction="row"
                              justify="flex-start"
                              alignItems="flex-start"
                            >
                              {insights.audience?.map((ia) => (
                                <Grid item xs={6} sm={6} md={6}>
                                  <Box>
                                    <Card className={classes.graphCardBox}>
                                      <CardContent>
                                        <span>
                                          {ia.insight_name
                                            ? ia.insight_name
                                            : ""}
                                        </span>
                                        <br />
                                        <span>
                                          <b>
                                            {ia.lift_percentage
                                              ? ia.lift_percentage
                                              : ""}
                                            %
                                          </b>
                                        </span>
                                        <br />
                                        <span>
                                          <b>Publisher #9</b> has the highest
                                          lift
                                        </span>
                                        <br />
                                      </CardContent>
                                    </Card>
                                  </Box>
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>
                        ) : null}
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                        style={{ marginTop: "10px", marginLeft: "10px" }}
                      >
                        <TableContainer className="graph-table">
                          <Table>
                            {allQuestions && allQuestions.length
                              ? allQuestions.map((aq) => (
                                  <TableRow>
                                    <TableCell>
                                      {aq.question
                                        ? capitalizeFirstLetter(
                                            aq.question.question_name
                                          )
                                        : ""}
                                    </TableCell>
                                    <TableCell align="right">
                                      <Button
                                        style={{
                                          float: "right",
                                          color: "black",
                                          backgroundColor: "greenyellow",
                                          fontWeight: 300,
                                          fontSize: "20px",
                                        }}
                                        disabled
                                      >
                                        {aq.lift_percentage
                                          ? aq.lift_percentage
                                          : 0}
                                      </Button>
                                    </TableCell>
                                    <TableCell align="right">
                                      <span>
                                        <b>Above Average</b>
                                        <br />
                                        KPI Benchmark
                                      </span>
                                    </TableCell>
                                    <TableCell align="right">
                                      <span>
                                        <b>
                                          {" "}
                                          {aq.statistically_significant
                                            ? "Statistically Significant"
                                            : "Not Statistically Significant"}
                                        </b>
                                        <br />
                                        {aq.margin_of_error
                                          ? aq.margin_of_error
                                          : 0}
                                        % Margin of error
                                      </span>
                                    </TableCell>
                                    <TableCell>
                                      EXPOSED n=
                                      {aq.exposed_count ? aq.exposed_count : 0}
                                      <LinearProgress
                                        disabled
                                        variant="determinate"
                                        value={
                                          aq.exposed_percentage
                                            ? aq.exposed_percentage
                                            : 0
                                        }
                                      />{" "}
                                      {aq.exposed_percentage
                                        ? aq.exposed_percentage
                                        : 0}
                                      % <br />
                                      CONTROL n=
                                      {aq.control_count
                                        ? aq.control_count
                                        : 0}{" "}
                                      <LinearProgress
                                        disabled
                                        variant="determinate"
                                        value={
                                          aq.control_percentage
                                            ? aq.control_percentage
                                            : 0
                                        }
                                      />{" "}
                                      {aq.control_percentage
                                        ? aq.control_percentage
                                        : 0}
                                      %
                                    </TableCell>
                                  </TableRow>
                                ))
                              : null}
                          </Table>
                        </TableContainer>
                      </Grid>

                      <div style={{ marginTop: "10px" }}>
                        <Grid
                          container
                          direction="row"
                          justify="flex-start"
                          alignItems="flex-start"
                        >
                          <Grid item xs={6} sm={6} md={6}>
                            <b style={{ marginBottom: "10px" }}>
                              Lift Performance by Age
                            </b>

                            {ageData && ageData?.length > 0 ? (
                              <div
                                style={{
                                  width: "70%",
                                  marginTop: "5px",
                                  height: "250px",
                                }}
                              >
                                <Bar
                                  width="30px"
                                  height="20px"
                                  data={{
                                    labels:
                                      ageData && ageData.length
                                        ? ageData[0].labels
                                        : [],
                                    datasets:
                                      ageData && ageData.length
                                        ? ageData[0].datasets
                                        : [],
                                  }}
                                  options={{
                                    plugins: {
                                      legend: {
                                        display: false,
                                      },
                                    },
                                    scales: {
                                      y: {
                                        ticks: {
                                          color: "white",
                                          font: {
                                            size: 14,
                                          },
                                          // stepSize: 1,
                                          // beginAtZero: true
                                        },
                                      },
                                      x: {
                                        ticks: {
                                          color: "white",
                                          font: {
                                            size: 14,
                                          },
                                          // stepSize: 1,
                                          // beginAtZero: true
                                        },
                                      },
                                    },
                                  }}
                                />
                              </div>
                            ) : (
                              <div
                                style={{
                                  alignItems: "center",
                                  fontWeight: "500",
                                }}
                              >
                                <p>No data found</p>
                              </div>
                            )}
                          </Grid>

                          <Grid item xs={6} sm={6} md={6}>
                            <b style={{ marginBottom: "10px" }}>
                              Lift Performance by Gender
                            </b>

                            {gender && gender?.length > 0 ? (
                              <div
                                style={{
                                  width: "70%",
                                  marginTop: "5px",
                                  height: "250px",
                                }}
                              >
                                <Bar
                                  width="30px"
                                  height="20px"
                                  data={{
                                    labels:
                                      gender && gender.length
                                        ? gender[0].labels
                                        : [],
                                    datasets:
                                      gender && gender.length
                                        ? gender[0].datasets
                                        : [],
                                  }}
                                  options={{
                                    plugins: {
                                      legend: {
                                        display: false,
                                      },
                                    },
                                    scales: {
                                      y: {
                                        ticks: {
                                          color: "white",
                                          font: {
                                            size: 14,
                                          },
                                          // stepSize: 1,
                                          // beginAtZero: true
                                        },
                                      },
                                      x: {
                                        ticks: {
                                          color: "white",
                                          font: {
                                            size: 14,
                                          },
                                          // stepSize: 1,
                                          // beginAtZero: true
                                        },
                                      },
                                    },
                                  }}
                                />
                              </div>
                            ) : (
                              <div
                                style={{
                                  alignItems: "center",
                                  fontWeight: "500",
                                }}
                              >
                                <p>No data found</p>
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      </div>
                      <div style={{ marginTop: "10%" }}>
                        <Grid
                          container
                          direction="row"
                          justify="flex-start"
                          alignItems="flex-start"
                        >
                          <Grid item xs={6} sm={6} md={6}>
                            <b style={{ marginBottom: "10px" }}>
                              Lift Performance by Frequency
                            </b>

                            {frequency && frequency?.length > 0 ? (
                              <div
                                style={{
                                  width: "70%",
                                  marginTop: "5px",
                                  height: "250px",
                                }}
                              >
                                <Bar
                                  width="30px"
                                  height="20px"
                                  data={{
                                    labels:
                                      frequency && frequency.length
                                        ? frequency[0].labels
                                        : [],
                                    datasets:
                                      frequency && frequency.length
                                        ? frequency[0].datasets
                                        : [],
                                  }}
                                  options={{
                                    plugins: {
                                      legend: {
                                        display: false,
                                      },
                                    },
                                    scales: {
                                      y: {
                                        ticks: {
                                          color: "white",
                                          font: {
                                            size: 14,
                                          },
                                          // stepSize: 1,d
                                          // beginAtZero: true
                                        },
                                      },
                                      x: {
                                        ticks: {
                                          color: "white",
                                          font: {
                                            size: 14,
                                          },
                                          // stepSize: 1,
                                          // beginAtZero: true
                                        },
                                      },
                                    },
                                  }}
                                />
                              </div>
                            ) : (
                              <div
                                style={{
                                  alignItems: "center",
                                  fontWeight: "500",
                                }}
                              >
                                <p>No data found</p>
                              </div>
                            )}
                          </Grid>
                          <Grid item xs={6} sm={6} md={6}>
                            <b style={{ marginBottom: "10px" }}>
                              Lift Performance by Device
                            </b>
                            {device && device?.length > 0 ? (
                              <div
                                style={{
                                  width: "70%",
                                  marginTop: "5px",
                                  height: "250px",
                                }}
                              >
                                <Bar
                                  width="30px"
                                  height="20px"
                                  data={{
                                    labels:
                                      device && device?.length
                                        ? device[0].labels
                                        : [],
                                    datasets:
                                      device && device?.length
                                        ? device[0].datasets
                                        : ["No data found!"],
                                  }}
                                  options={{
                                    plugins: {
                                      legend: {
                                        display: false,
                                      },
                                    },
                                    scales: {
                                      y: {
                                        ticks: {
                                          color: "white",
                                          font: {
                                            size: 14,
                                          },
                                          // stepSize: 1,
                                          // beginAtZero: true
                                        },
                                      },
                                      x: {
                                        ticks: {
                                          color: "white",
                                          font: {
                                            size: 14,
                                          },
                                          // stepSize: 1,
                                          // beginAtZero: true
                                        },
                                      },
                                    },
                                  }}
                                />
                              </div>
                            ) : (
                              <div
                                style={{
                                  alignItems: "center",
                                  fontWeight: "500",
                                }}
                              >
                                <p>No data found</p>
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      </div>
                      <div
                        style={{
                          marginTop: "10%",
                          marginBottom: "10px",
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Grid
                          container
                          direction="row"
                          justify="flex-start"
                          alignItems="flex-start"
                        >
                          <Grid item xs={8} sm={8} md={8}>
                            <b style={{ marginBottom: "10px" }}>
                              Lift Performance by Creative
                            </b>

                            {creative && creative?.length > 0 ? (
                              <div
                                style={{
                                  width: "70%",
                                  marginTop: "5px",
                                  height: "250px",
                                }}
                              >
                                <Bar
                                  width="30px"
                                  height="20px"
                                  data={{
                                    labels:
                                      creative && creative.length
                                        ? creative[0].labels
                                        : [],
                                    datasets:
                                      creative && creative.length
                                        ? creative[0].datasets
                                        : ["No data found!"],
                                  }}
                                  options={{
                                    // indexAxis: "y",
                                    plugins: {
                                      legend: {
                                        display: false,
                                      },
                                    },
                                    scales: {
                                      y: {
                                        ticks: {
                                          color: "white",
                                          font: {
                                            size: 14,
                                          },
                                          // stepSize: 1,
                                          // beginAtZero: true
                                        },
                                      },
                                      x: {
                                        ticks: {
                                          color: "white",
                                          font: {
                                            size: 14,
                                          },
                                          // stepSize: 1,
                                          // beginAtZero: true
                                        },
                                      },
                                    },
                                  }}
                                />
                              </div>
                            ) : (
                              <div
                                style={{
                                  alignItems: "center",
                                  fontWeight: "500",
                                }}
                              >
                                <p>No data found</p>
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  ) : (
                    <div style={{ textAlign: "center", fontWeight: "500" }}>
                      <p>Select campaign to display campaign metrics!</p>
                    </div>
                  )}
                </>
              )}
            </CardContent>
          </Card>
        </Box>
      </Grid>
      <AddCampaign
        popAddCampaign={popAddCampaign}
        setPopAddCampaign={setPopAddCampaign}
        adsCampaign={adsCampaign}
      />
      {/* <AddZone popAddZone={popAddZone} setPopAddZone={setPopAddZone}/> */}
      <Snackbar
        open={dateError}
        autoHideDuration={6000}
        onClose={() => {
          setDateError(false);
        }}
      >
        <Alert
          onClose={() => {
            setDateError(false);
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          You cannot select a period that is over a year !
        </Alert>
      </Snackbar>
    </>
  );
};

export default MainLayout(CampaignReport);
