import { makeStyles, fade } from "@material-ui/core/styles";

import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "40px",
  },
  pageTitle: {
    color: "#e96941",
    fontWeight: "normal",
  },
  formTitle: {
    color: "#e96941",
    fontWeight: "normal",
  },
  boxShadow: {
    position: "relative",
    padding: "16px",
    boxShadow: "0px 0px 10px rgba(0,0,0,0.20), 0 0 10px rgba(0,0,0,0.15)",
    borderRadius: "30px",
    backgroundColor: "white",
    marginTop: "-45px",
    maxWidth: "100%",
  },
  paper: {
    padding: "16px",
    color: theme.palette.text.secondary,
  },
  textarea: {
    width: "100%",
    backgroundColor: "#f4f4f8",
    marginTop: "8px",
    borderRadius: "5px",
    padding: "14px",
    marginBottom: "20px",
  },
  selectControl: {
    marginBottom: "8px",
    fontSize: "0.875rem",
  },
  customfield: {
    paddingTop: "0",
    marginTop: "0",
    backgroundColor: "#ffffff",
    borderRadius: "5px",
    color: "#000",
    "& .MuiInputLabel-root.Mui-shrink": {
      color: "red",
      // "& .Mui-shrink": {
      // 	color: 'red'
      // },
    },
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&$focused": {
      backgroundColor: "#fff",
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.5px 14px",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",

      "& fieldset": {
        borderColor: "grey",
      },
      "&:hover fieldset": {},
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "grey",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "grey",
    },
    "& .MuiInputLabel-root.MuiInputLabel-shrink": {
      margin: "0!important",
    },
    "& .MuiInputLabel-root.MuiInputLabel-animated": {
      marginTop: "-8px",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",
    },
  },
  selectImgBtn: {
    marginTop: "10px",
    display: "block",
    margin: "0 auto",
    color: "#ba54f5",
    border: "1px solid #ba54f5",
    padding: "5px 30px",
  },
  addActionBtn: {
    padding: "8px",
    fontSize: "13px",
    backgroundColor: "#136cc3",
    color: "white",
    fontWeight: "bold",
    borderRadius: "8px",
    textTransform: "none",
    border: "none",
    "&:hover": {
      background: "#136cc3",
      cursor: "pointer",
    },
  },
  textarea: {
    width: "100%",
    backgroundColor: "#f4f4f8",
    marginTop: "8px",
    borderRadius: "5px",
    padding: "14px",
  },
  checkNRadio: {
    padding: 0,
    "& .MuiFormLabel-root": {
      color: "#1d8bf7",
      marginBottom: "8px",
    },
    "& .MuiFormControlLabel-root": {
      "& span": {
        paddingTop: "1px",
        paddingBottom: "1px",
      },
    },
  },
  checkNRadio2: {
    padding: 0,
    "& .MuiFormLabel-root": {
      color: "#1d8bf7",
      marginBottom: "8px",
    },
    "& .MuiFormControlLabel-root": {
      "& span": {
        paddingTop: "1px",
        paddingBottom: "1px",
      },
    },
  },
  logo: {
    width: "200px",
    height: "70px",
    marginTop: "30px",
    display: "block",
    marginBottom: "30px",
  },
  customRenderfield: {
    padding: "0",
    margin: "10px",
    width: "50px",
    height: "50px",
  },
  floatingLabelFocusStyle: {
    color: "grey",
  },
  tab1: {
    color: red[500],
    "&.selected": {
      zIndex: 10,
    },
  },

  tab2: {
    color: "#6a5fcd",
    "&.selected": {
      zIndex: 10,
    },
  },

  tab3: {
    color: green[500],
    "&.selected": {
      zIndex: 10,
    },
  },
  redHead: {
    fontSize: "20px",
    fontWeight: "400",
    color: "#FD7C35",
    marginTop: "0",
  },

  tabWrapper: {
    display: "flex",
    marginTop: "20px",
    marginBottom: "20px",
    "& span": {
      "border-top-right-radius": "15px",
      "border-top-left-radius": "15px",
      fontWeight: "bold",
      display: "inline-block",
      padding: "10px 20px 40px 20px",
      backgroundColor: "white",
      position: "relative",
      cursor: "pointer",
      marginRight: "20px",
    },
  },
  cardStyleMedia: {
    transitionDuration: "0.3s",
    height: "20vw",
  },
  ansfield: {
    width: "50%",
    marginTop: "10px",
  },
  cardBox: {
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
    },
    // overflow: "auto",
    // maxHeight: "430px",
    scrollBehavior: "smooth",
    marginBottom: "10px",
  },
  cardBoxIntelligence: {
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
    },
    overflow: "auto",
    // maxHeight: "430px",
    scrollBehavior: "smooth",
    marginBottom: "10px",
  },
  cardBoxHeatmap: {
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
    },
    overflow: "auto",
    scrollBehavior: "smooth",
    marginBottom: "10px",
  },
  adminCardBox: {
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
    },
    overflow: "auto",
    scrollBehavior: "smooth",
    marginBottom: "10px",
  },
  decaCardBox: {
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
    },
    overflow: "auto",
    maxHeight: "560px",
    scrollBehavior: "smooth",
    marginBottom: "10px",
  },
  queCardBox: {
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
    },
    maxWidth: "170px",
    maxHeight: "90px",
    minWidth: "170px",
    minHeight: "90px",
    marginBottom: "10px",
    overflow: "auto !important",
    scrollBehavior: "smooth",
    color: "white",
    backgroundColor: "rgb(135 17 162)",
    cursor: "pointer",
    marginLeft: "10px",
  },
  graphCardBox: {
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
    },
    overflow: "auto !important",
    scrollBehavior: "smooth",
    color: "white",
    backgroundColor: "rgb(135 17 162)",
    cursor: "pointer",
    marginTop: "10px",
    marginLeft: "10px",
  },
  cardStyle: {
    backgroundColor: "rgb(135 17 162)",
    maxHeight: "220px",
    maxWidth: "270px",
    overflow: "auto !important",
    scrollBehavior: "smooth",
    marginBottom: "5px",
    height: "350px",
  },
  cardHeader: {
    backgroundColor: "#9211a2",
    fontSize: "14px !important",
    // color: "white",
    color: "black",
  },
  cardContent: {
    backgroundColor: "#4911a2",
    fontSize: "12px !important",
    color: "white",
    textAlign: "center",
    justifyContent: "center",
    marginRight: "5px",
    marginLeft: "5px",
    marginBottom: "5px",
    marginTop: "5px",
    maxWidth: "200px",
  },
  boxModal: {
    backgroundColor: "#4911a2",
    fontSize: "12px !important",
    color: "white",
    textAlign: "center",
    justifyContent: "center",
    marginRight: "5px",
    marginLeft: "5px",
    marginBottom: "5px",
    marginTop: "5px",
    top: "15%",
    left: "35%",
    position: "absolute",
    padding: "15px",
    width: "30%",
  },
  reportBoxModal: {
    backgroundColor: "#4911a2",
    fontSize: "12px !important",
    color: "black",
    textAlign: "center",
    justifyContent: "center",
    marginRight: "5px",
    marginLeft: "5px",
    marginBottom: "5px",
    marginTop: "5px",
    top: "10%",
    left: "21%",
    position: "absolute",
    padding: "15px",
    width: "74%",
    overflow: "auto",
    maxHeight: "85vh",
    scrollBehavior: "smooth",
  },
  showPassword: {
    position: "relative",
  },
  showImg: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    right: "10px",
  },
  popupContent: {
    marginTop: "10px",
  },
  popupHead: {
    fontWeight: "bold",
    fontSize: "20px",
  },
  popupText: {
    fontSize: "12px",
    marginBottom: "10px",
    wordWrap: "break-word",
  },
  popupRightText: {
    fontSize: "12px",
    marginBottom: "10px",
    color: "#ffffff",
    fontWeight: 700,
  },
  accordion: {
    minHeight: 150, //ugly but works
    height: "100%",
    marginTop: "10px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  details: {
    alignItems: "center",
    border: "1px solid rgba(0,0,0,0.1)",
    borderRadius: 4,
  },
  column: {
    flexBasis: "33.33%",
  },
  helper: {
    padding: theme.spacing(1, 2),
  },
  expand: {
    margin: "0 0.3rem",
    fontWeight: 700,
    cursor: "pointer",
  },
  flexBoxDiv: {
    display: "flex",
    justifyContent: "space-around",
    // cursor: "pointer"
  },
  saveQuestion: {
    color: "white",
    backgroundColor: "#5f11a7",
    "&:hover": {
      background: "#5f11a7",
      cursor: "pointer",
    },
  },
  saveQuestionDiv: {
    display: "flex",
    justifyContent: "flex-end",
  },
  textTag: {
    padding: "8px",
    fontSize: "13px",
    backgroundColor: "#b2b1b1",
    color: "black",
    fontWeight: "bold",
    borderRadius: "8px",
    textTransform: "none",
    border: "none",
    "&:hover": {
      background: "#b2b1b1",
      cursor: "pointer",
    },
  },
  gptLoadWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiCircularProgress-root": {
      position: "unset",
      top: "0"
    }
  },
  daypartingTr:{
    "&:hover":{
      backgroundColor:"unset"
    }
  },
  daypartingTCell:{
    "&:hover":{
      background:theme.palette.primary.main,
      cursor:"pointer"
    }
  }
}));

export default useStyles;

