export const POST_BRAND_UPLIFT = 'POST_BRAND_UPLIFT';
export const SAVE_BRAND_UPLIFT_FORECAST = 'SAVE_BRAND_UPLIFT_FORECAST';
export const GET_BRAND_UPLIFT_FORECAST = 'GET_BRAND_UPLIFT_FORECAST';

export function postBrandUplift(data) {
  return {
    type: POST_BRAND_UPLIFT,
    data,
  };
}

export function getForecast(data) {
  return {
    type: GET_BRAND_UPLIFT_FORECAST,
    data,
  };
}

export function saveBrandUpliftForecast(brandUpLiftForecast) {
  return {
    type: SAVE_BRAND_UPLIFT_FORECAST,
    brandUpLiftForecast,
  };
}
