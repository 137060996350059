import { Button, CircularProgress, Select } from "@material-ui/core"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Modal from "@material-ui/core/Modal"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { API, SECRET_KEY } from "../../../constants/index"
import MainLayout from "../../../layouts/adminMainLayout"
import "./influencer.css"

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "10px",
    width: "580px",
  },
}))

const getCompanyList = () => {
  const access_token = window.localStorage.getItem(SECRET_KEY)
  return axios.get(`${API}/admin/admin-company`, {
    headers: { Authorization: `Bearer ${access_token}` },
  })
}

const BaInfluencer = () => {
  const history = useHistory()
  const classes = useStyles()
  const [modalOpen, setModalOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [deleteCampaignId, setDeleteCampaignId] = useState("")
  const [deleteCampaignModalTitle, setDeleteCampaignModalTitle] = useState("")
  const [companyList, setCompanyList] = useState([])
  const [campaignList, setCampaignList] = useState([])
  const [campaignName, setCampaignName] = useState("")
  const [campaignDescription, setCampaignDescription] = useState("")
  const [campaignCompany, setCampaignCompany] = useState([])
  const [loading, setLoading] = useState(false)
  const [editCampaignDetails, setEditCampaignDetails] = useState({
    _id: "",
    title: "",
    description: "",
    companies: [],
  })

  const handleModalOpen = () => {
    setModalOpen(true)
  }

  const handleModalClose = () => {
    setModalOpen(false)
  }

  const handleModalChange = (e, fn) => {
    fn(e.target.value)
  }

  const handleCompanyChange = (e) => {
    setCampaignCompany(e.target.value)
  }

  const handleEditModalOpen = (id, title, description, companies) => {
    setEditCampaignDetails({
      _id: id,
      title: title,
      description: description,
      companies: companies,
    })
    setEditModalOpen(true)
  }

  const handleDeleteModalOpen = (id) => {
    setDeleteCampaignModalTitle("")
    setDeleteCampaignId(id)
    setDeleteModalOpen(true)
  }

  const handleCreateCampaign = () => {
    const access_token = window.localStorage.getItem(SECRET_KEY)
    axios
      .post(
        `${API}/admin/influencer/create-campaign`,
        {
          title: campaignName,
          description: campaignDescription,
          companies: campaignCompany,
        },
        {
          headers: { Authorization: `Bearer ${access_token}` },
        }
      )
      .then((res) => {
        console.log(res)
        getCampaigns()
        setModalOpen(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleEditCampaign = () => {
    const access_token = window.localStorage.getItem(SECRET_KEY)
    axios
      .put(
        `${API}/admin/influencer/edit-campaign/${editCampaignDetails._id}`,
        {
          title: editCampaignDetails.title,
          description: editCampaignDetails.description,
          companies: editCampaignDetails.companies,
        },
        {
          headers: { Authorization: `Bearer ${access_token}` },
        }
      )
      .then((res) => {
        console.log(res)
        getCampaigns()
        setEditModalOpen(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleDeleteCampaign = () => {
    const access_token = window.localStorage.getItem(SECRET_KEY)
    axios
      .delete(`${API}/admin/influencer/delete-campaign/${deleteCampaignId}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        console.log(res)
        getCampaigns()
        setDeleteModalOpen(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }



  const getCampaigns = () => {
    setLoading(true)
    const access_token = window.localStorage.getItem(SECRET_KEY)
    axios
      .get(`${API}/admin/influencer/get-campaigns`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        setCampaignList(res.data.influencerCampaigns)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  useEffect(() => {
    getCampaigns()
    getCompanyList().then((res) => {
      let companyData = res.data.data.map((item) => {
        return {
          value: item._id,
          label: item.companyName,
        }
      })
      setCompanyList(companyData)
    })
  }, [])

  return (
    <div>
      <div className="head-section">
        <h1 className="title">BA Influencer</h1>
        <Button variant="contained" className="add_campaign" onClick={handleModalOpen}>
          + Create Campaign
        </Button>
      </div>
      <div className="influencer-body">
        <div className="campaigns-list">
          {campaignList.map((item) => {
            return (
              <div key={item._id} className="campaign_cards">
                <h3 className="campaign_title">Title:&nbsp;&nbsp;<span className="card_contents">{item.title}</span></h3>
                <h3 className="campaign_desc">Description:&nbsp;&nbsp;<span className="card_contents">{item.description}</span></h3>
                <h3 className="campaign_company">
                  Companies:&nbsp;&nbsp;
                  {/* companyList.filter((company) => item.company === company.value)[0]["label"] */}
                  <span className="card_contents">{companyList.length !== 0 &&
                    item.companies.map((company) => companyList.filter((companyItem) => companyItem.value === company).map((item) => item.label)).join(", ")
                    }</span>
                </h3>
                <h3 className="campaign_content_count">Contents:&nbsp;&nbsp;<span className="card_contents">{item.contentsCount}</span></h3>
                <div className="c_view_btn_cont">
                  <Button className="campaign_view_btn" variant="contained" onClick={() => history.push(`/admin/ba-influencer/${item._id}`)}>
                    View
                  </Button>
                  {/* add edit button and delete button */}
                  <Button className="campaign_edit_btn" variant="contained" onClick={() => handleEditModalOpen(item._id, item.title, item.description, item.companies ) }>
                    Edit
                  </Button>
                  <Button className="campaign_delete_btn" variant="contained" onClick={() => handleDeleteModalOpen(item._id) }>
                    Delete
                  </Button>
                </div>
              </div>
            )
          })}
        </div>
      </div>

      {/* Create Campaign Modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modalOpen}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalOpen}>
          <div className={classes.paper}>
            <h2 className="campaign_modal_title">Create Campaign</h2>
            <div className="campaign-modal-input-cont">
              <TextField
                id="campaign_title"
                label="Title"
                variant="outlined"
                onChange={(e) => handleModalChange(e, setCampaignName)}
              />
              <TextField
                id="campaign_description"
                label="Description"
                variant="outlined"
                onChange={(e) => handleModalChange(e, setCampaignDescription)}
              />
              <InputLabel htmlFor="outlined-select-company" className="company_label">Companies</InputLabel>
              <Select
                labelId="outlined-select-company"
                id="outlined-select-company"
                multiple
                label="Company"
                onChange={(e) => handleCompanyChange(e)}
                value={campaignCompany}
                helperText="Please select the Company for this campaign"
                variant="standard"
              >
                {companyList.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              <div className="modal-submit-campaign">
                <Button
                  variant="contained"
                  className="submit_campaign_btn"
                  onClick={handleCreateCampaign}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>

      {/* Edit Campaign Modal */}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={editModalOpen}>
          <div className={classes.paper}>
            <h2 className="campaign_modal_title">Edit Campaign</h2>
            <div className="campaign-modal-input-cont">
              <TextField
                id="campaign_title"
                label="Title"
                variant="outlined"
                value={editCampaignDetails.title}
                onChange={(e) => setEditCampaignDetails({...editCampaignDetails, title: e.target.value})}
              />
              <TextField
                id="campaign_description"
                label="Description"
                variant="outlined"
                value={editCampaignDetails.description}
                onChange={(e) => setEditCampaignDetails({...editCampaignDetails, description: e.target.value})}
              />
              <InputLabel htmlFor="outlined-select-company" className="company_label">Companies</InputLabel>
              <Select
                labelId="outlined-select-company"
                id="outlined-select-company"
                multiple
                label="Company"
                onChange={(e) => setEditCampaignDetails({...editCampaignDetails, companies: e.target.value})}
                value={editCampaignDetails.companies}
                helperText="Please select the Company for this campaign"
                variant="standard"
              >
                {companyList.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              <div className="modal-submit-campaign">
                <Button
                  variant="contained"
                  className="submit_campaign_btn"
                  onClick={handleEditCampaign}
                >
                  Update
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>

      {/* Delete modal with campaign name confirmation */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={deleteModalOpen}>
        <div className={classes.paper}>
          <h2 id="transition-modal-title">Are you sure you want to delete this campaign?</h2>
          <p id="transition-modal-description">
            This action cannot be undone.<br /><br /> Write the campaign name <span className="delete_modal_campaign_name">{campaignList.filter((item) => item._id === deleteCampaignId)[0]?.title}</span> to confirm.
          </p>
          <div className="campaign-modal-input-cont">
            <TextField
              id="campaign_name_confirmation"
              label="Campaign Name"
              variant="outlined"
              onChange={(e) => setDeleteCampaignModalTitle(e.target.value)}
              value={deleteCampaignModalTitle}
            />
            <div className="modal-submit-campaign">
              <Button
                variant="contained"
                className="campaign_delete_btn"
                onClick={handleDeleteCampaign}
                disabled={campaignList.filter((item) => item._id === deleteCampaignId)[0]?.title !== deleteCampaignModalTitle}
              >
                Delete
              </Button>
              <Button
                variant="contained"
                className="submit_campaign_btn"
                onClick={() => setDeleteModalOpen(false)}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
        </Fade>
      </Modal>


      <div style={{ display: "flex", justifyContent: "center" }}>{loading && <CircularProgress />}</div>
    </div>
  )
}

export default MainLayout(BaInfluencer)
