import React from "react";
import Layout from "../layouts/Home";
import LandingImage from "../assets/logo/landingImage.png";

const Home = () => {

  const useStyles = {
    homeDiv: {
      // height: "100vh",
      height: "85vh",
      backgroundSize: "70vw",
      backgroundPositionX: "right"
    }
  }
  return (
    <div>
      <div
        className="homeDiv"
        style={useStyles.homeDiv}
      >
        <div style={{ width: "35%" }}>
          <span
            style={{
              fontSize: "40px",
              // color: "#4F74C9",
              color: "#8710a2",
              fontWeight: "800",
              letterSpacing: "-1px",
              lineHeight: "100%",
              zIndex: 109
            }}
          >
            {/* Helping brands and agencies with INSTANT access to CULTURAL INSIGHTS globally. */}
            Cultural Insights powering Media & Creative Consultancy.
          </span>
        </div>
        <div className="homeImage">
          <img src={LandingImage} alt={"Cultural Intelligence Services"} />
        </div>
        <div style={{ position: "absolute", bottom: "20px", width: "38%" }}>
          <h4
            style={{
              fontSize: "20px",
              color: "#F0797B",
              fontWeight: "700",
              lineHeight: "100%",
              padding: "0",
              marginBottom: "5px",
            }}
          >
            Cultural Intelligence
          </h4>
          <span
            style={{
              fontSize: "15px",
              color: "#4F74C9",
              fontWeight: "500",
              lineHeight: "100%",
            }}
          >
            Our cultural marketing specialists will help your brand to scale
            effectively within any of our channels.
          </span>
        </div>
      </div>
    </div>
  );
};
export default Layout(Home);
