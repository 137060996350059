import { put, call, takeLatest } from "redux-saga/effects";
import * as actions from "../redux/actions/user";
import { loginApi, registerApi } from "../services/api/user";

export function* callLoginApi(param) {
  console.log("param is >>>>>>>>>>>", param);
  try {
    const result = yield call(loginApi, param && param.login);
    yield console.log("gfgghfgfghf>>>>>>>>>>>>>>", result);
    yield put(actions.loginSuccessfully(result));
  } catch (error) {
    yield console.log("error login >>>>>>>>>>>>>>", error);
    yield put(actions.error(error));
  }
}

export function* callRegisterApi(param) {
  console.log("regisre param is >>>>>>>>>>", param);
  try {
    const result = yield call(registerApi, param && param.register);
    yield put(actions.loginSuccessfully(result));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* login() {
  yield takeLatest(actions.LOGIN, callLoginApi);
}

export function* registerSaga() {
  yield takeLatest(actions.REGISTER, callRegisterApi);
}
