import React from "react"
import clsx from "clsx"
import { Grid, Typography } from "@material-ui/core"
import { useParams } from "react-router-dom"
import { SERVE_TYPES, useChannelAssignmentPageCtx } from "../ChannelAssignmentPage"

export default function ChannelAssignmentStep1({ setSelectedServeType }) {
    const params = useParams()
    const { selectedServeType } = useChannelAssignmentPageCtx()

    const { advertiserId, campaignId, campaignType, selectedChannelName, campaignName, advertiser_name } = params

    return (
        <div>
            <div style={{ marginTop: "20px" }} className="form-section-heading">
                <Typography>Placement Details</Typography>
            </div>

            <div style={{ padding: "10px" }}>
                <Grid container>
                    <Grid item xs={4} md={2}>
                        <Typography style={{ fontWeight: "600" }}>Campaign</Typography>
                    </Grid>
                    <Grid item>
                        <Typography>{`${campaignName}  (${advertiser_name})`}</Typography>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={4} md={2}>
                        <Typography style={{ fontWeight: "600" }}>Channel</Typography>
                    </Grid>
                    <Grid item>
                        <Typography>{selectedChannelName}</Typography>
                    </Grid>
                </Grid>

                <div>
                    <h3 style={{ marginTop: "30px", marginBottom: "10px" }}>Choose how you want these ads to serve:</h3>
                    <div className="flex flex-col" style={{ gap: "10px" }}>
                        <div
                            role="button"
                            className={clsx(
                                "serve-type",
                                selectedServeType == SERVE_TYPES["Fixed Delivery"] && "selected-serve-type"
                            )}
                            onClick={() => setSelectedServeType(SERVE_TYPES["Fixed Delivery"])}
                        >
                            <p style={{ fontWeight: "600" }}>Fixed Delivery</p>
                            <p>
                                This Ad will serve based on weight, quotas, schedule, targets and other rules without
                                considering price.
                            </p>
                        </div>

                        <div
                            role="button"
                            className={clsx(
                                "serve-type",
                                selectedServeType == SERVE_TYPES["Auction"] && "selected-serve-type"
                            )}
                            onClick={() => setSelectedServeType(SERVE_TYPES.Auction)}
                        >
                            <p style={{ fontWeight: "600" }}>Auction</p>
                            <p>
                                After considering quotas, schedule, targets and other rules, the decision engine will
                                conduct an auction and serve this Ad if it yields the highest price.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
