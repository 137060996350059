import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
  Modal,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { fade, makeStyles, withStyles } from "@material-ui/core/styles";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
// import Select from "@material-ui/core/Select"
import CircularProgress from "@material-ui/core/CircularProgress";
import CropSquareOutlined from "@material-ui/icons/CropSquareOutlined";
import MinimizeOutlined from "@material-ui/icons/MinimizeOutlined";
import axios from "axios";
import { ca, enGB } from "date-fns/locale";
import jwt_decode from "jwt-decode";
import moment from "moment";
import { Bar } from "react-chartjs-2";
import { Chart } from "react-google-charts";
import { DateRangePicker, END_DATE, START_DATE } from "react-nice-dates";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import { API, SECRET_KEY } from "../../../constants/index";
import MainLayout from "../../../layouts/newMainLayoutPlanner";

import { SaveAdminDropDownData } from "../../../store/actions/admin/intelligence";
import StaticDeviceData from "../IntelligenceDashboard/device.json";
import StaticData from "../IntelligenceDashboard/graph.json";
import StaticMediaData from "../IntelligenceDashboard/media.json";
import StaticQuestionData from "../IntelligenceDashboard/question.json";

import { Alert } from "@material-ui/lab";
import "react-nice-dates/build/style.css";
import useFetch from "../../../Hooks/useFetch";
import LoaderCircle from "../../../components/Loader/Loader";
import useStyles from "../../../components/StyleClasses";
import AdItemInputModal from "./components/AdItemInputModal";
import ChannelAssign from "./components/ChannelAssign/ChannelAssign";

const useTableStyles = makeStyles({
  table: {
    minWidth: 700,
    maxWidth: 2000,
  },
});

const StyledTableRow = withStyles((theme) => ({
  root: {},
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.white,
    fontSize: 12,
    paddingBottom: "2px",
    paddingTop: "2px",
  },
  body: {
    fontSize: 12,
    border: "1px solid #D2D2D2",
    color: "#4F74C9",
    paddingBottom: "2px",
    paddingTop: "2px",
  },
}))(TableCell);

const useStyle = makeStyles((theme) => ({
  chartWrapperMin: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    "@media screen and (max-width: 768px)": {
      flexDirection: "column",
    },
  },
  chartWrapperMax: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  displayWrapperMin: {
    width: "50%",
    margin: "1rem 0.5em",
    background: "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))",
    padding: "0.5em",
    borderRadius: "5px",
    "@media screen and (max-width: 768px)": {
      width: "98%",
      margin: "1rem 1rem",
    },
  },
  displayWrapperMax: {
    width: "98%",
    margin: "1rem 1rem",
    background: "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))",
    padding: "0.5em",
    borderRadius: "5px",
  },
  videoWrapperMin: {
    width: "50%",
    margin: "1rem 0.5rem",
    background: "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))",
    padding: "0.5em",
    borderRadius: "5px",
    "@media screen and (max-width: 768px)": {
      width: "98%",
      margin: "1rem 1rem",
    },
  },
  videoWrapperMax: {
    width: "98%",
    margin: "1rem 1rem",
    background: "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))",
    padding: "0.5em",
    borderRadius: "5px",
  },
  header: {
    textAlign: "center",
    color: "#fff",
  },
  minmax: {
    color: "#fff",
    textAlign: "end",
    cursor: "pointer",
  },
  graphText: {
    color: "#fff",
    textAlign: "center",
    borderTop: "1px solid grey",
    paddingTop: "0.5em",
  },
  customfield: {
    paddingTop: "0",
    margin: "0.5rem 0.5rem 0 0",
    backgroundColor: "#ffffff",
    borderRadius: "5px",
    maxWidth: "230px",
    width: "230px",
    "& .MuiInputLabel-root.Mui-shrink": {
      color: "red",
      // "& .Mui-shrink": {
      // 	color: 'red'
      // },
    },
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&$focused": {
      backgroundColor: "#fff",
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.5px 14px",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",

      "& fieldset": {
        borderColor: "grey",
      },
      "&:hover fieldset": {},
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "grey",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "grey",
    },
    "& .MuiInputLabel-root.MuiInputLabel-shrink": {
      margin: "0!important",
    },
    "& .MuiInputLabel-root.MuiInputLabel-animated": {
      marginTop: "-8px",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",
    },
  },
  campaignNavBtn: {
    display: "flex",
    flexDirection: "row",
  },
  campaignAds: {
    width: "230px",
    margin: "0 0.2rem 0 0",
    maxWidth: "230px",
    paddingTop: "0",
    borderRadius: "5px",
    backgroundColor: "#ffffff",
  },
}));

const optionsPeriod = [
  { value: "day", label: "Day" },
  { value: "week", label: "Week" },
  { value: "month", label: "Month" },
  { value: "year", label: "Year" },
];

const staticData = [
  {
    type: "OOH",
    campaign: "ZeeTV",
    impressions: "10000560",
    clicks: "5000000",
    CTR: "0.4",
    VTR: "0.5",
    view: "Completed",
  },
  {
    type: "Digtal",
    campaign: "Jio Pvt.Ltd",
    impressions: "51230000",
    clicks: "49000000",
    CTR: "0.6",
    VTR: "0.8",
    view: "Not completed",
  },
  {
    type: "Radio",
    campaign: "New Car",
    impressions: "3000560",
    clicks: "250000",
    CTR: "0.2",
    VTR: "0.3",
    view: "Completed",
  },
  {
    type: "Online",
    campaign: "Youtube",
    impressions: "350001270",
    clicks: "300456001",
    CTR: "0.6",
    VTR: "0.3",
    view: "Not completed",
  },
  {
    type: "Online",
    campaign: "Twitch",
    impressions: "240567000",
    clicks: "150234007",
    CTR: "0.8",
    VTR: "0.6",
    view: "Completed",
  },
  {
    type: "Online",
    campaign: "LinkedIn",
    impressions: "500130",
    clicks: "250000",
    CTR: "0.2",
    VTR: "0.3",
    view: "Completed",
  },
  {
    type: "OOH",
    campaign: "ZeeTV",
    impressions: "10000560",
    clicks: "5000000",
    CTR: "0.4",
    VTR: "0.5",
    view: "Completed",
  },
  {
    type: "Digtal",
    campaign: "Jio Pvt.Ltd",
    impressions: "51230000",
    clicks: "49000000",
    CTR: "0.6",
    VTR: "0.8",
    view: "Not completed",
  },
  {
    type: "Radio",
    campaign: "New Car",
    impressions: "3000560",
    clicks: "250000",
    CTR: "0.2",
    VTR: "0.3",
    view: "Completed",
  },
  {
    type: "Online",
    campaign: "Youtube",
    impressions: "350001270",
    clicks: "300456001",
    CTR: "0.6",
    VTR: "0.3",
    view: "Not completed",
  },
  {
    type: "Online",
    campaign: "Twitch",
    impressions: "240567000",
    clicks: "150234007",
    CTR: "0.8",
    VTR: "0.6",
    view: "Completed",
  },
  {
    type: "Online",
    campaign: "LinkedIn",
    impressions: "500130",
    clicks: "250000",
    CTR: "0.2",
    VTR: "0.3",
    view: "Completed",
  },
];

export const dataDisplay = [
  ["Year", "Impressions", "Clicks", "Views"],
  ["2004", 1000, 500, 150],
  ["2005", 1170, 400, 350],
  ["2006", 660, 300, 250],
  ["2007", 1030, 250, 100],
];

export const optionsDisplay = {
  title: "Campaign Display Report",
  curveType: "function",
  legend: { position: "bottom" },
  tooltip: { isHtml: true, trigger: "visible" },
};

export const dataVideo = [
  ["Year", "Impressions", "Clicks"],
  ["2004", 1500, 800],
  ["2005", 1200, 500],
  ["2006", 1700, 110],
  ["2007", 500, 800],
];

export const optionsVideo = {
  title: "Campaign video Report",
  curveType: "function",
  legend: { position: "bottom" },
  tooltip: { isHtml: true, trigger: "visible" },
};

const backgroundColor = [
  "rgba(255, 99, 132, 0.8)",
  "rgba(255, 159, 64, 0.8)",
  "rgba(255, 205, 86, 0.8)",
  "rgba(75, 192, 192, 0.8)",
  "rgba(54, 162, 235, 0.8)",
  "rgba(153, 102, 255, 0.8)",
  "rgba(201, 203, 207, 0.8)",
];
const borderColor = [
  "rgb(255, 99, 132)",
  "rgb(255, 159, 64)",
  "rgb(255, 205, 86)",
  "rgb(75, 192, 192)",
  "rgb(54, 162, 235)",
  "rgb(153, 102, 255)",
  "rgb(201, 203, 207)",
];

const selectGraphArr = [
  {
    name: "Brand Awareness",
    kpi_type: "brand_awareness",
    color: "rgba(255, 99, 132, 0.8)",
  },
  {
    name: "Brand Consideration",
    kpi_type: "brand_consideration",
    color: "rgba(255, 159, 64, 0.8)",
  },
  {
    name: "Message Association",
    kpi_type: "message_association",
    color: "rgba(255, 205, 86, 0.8)",
  },
  {
    name: "Ad Recall",
    kpi_type: "ad_recall",
    color: "rgba(75, 192, 192, 0.8)",
  },
  {
    name: "Purchase Intent",
    kpi_type: "purchase_intent",
    color: "rgba(54, 162, 235, 0.8)",
  },
];

const AdvertiserCampaignReport = () => {
  const dispatch = useDispatch();
  const classesTable = useTableStyles();
  const classess = useStyle();
  const classes = useStyles();
  const dropdownData = useSelector((state) => state.adminDropDownData);
  const addCampaign = useRef();
  const history = useHistory();

  const access_token = window.localStorage.getItem(SECRET_KEY);

  const [loading, setLoading] = useState(false);
  const [metricLoading, setMetricLoading] = useState(false);
  // change this to loading state when fetching the campaing
  const [dateError, setDateError] = useState(false);
  const [loadingGraph, setLoadingGraph] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [campaignList, setCampaignList] = useState(null);
  const [advertiserId, setAdvertiserId] = useState([]);
  const [periodChoice, setPeriodChoice] = useState(null);
  const [min, setMin] = useState(true);
  const [options, setOptions] = useState([]);
  const [displayData, setDisplayData] = useState(null);
  const [videoData, setVideoData] = useState(null);
  const [apiCall, setApiCall] = useState(false);
  const [questionName, setQuestionName] = useState([]);
  const [campaign, setCampaign] = useState(0);
  const [campaignMetricList, setCampaignMetricList] = useState(null);
  const [insights, setInsights] = useState({});
  const [allQuestions, setAllQuestions] = useState([]);
  const [ageData, setAgeData] = useState([]);
  const [gender, setGender] = useState([]);
  const [frequency, setFrequency] = useState([]);
  const [device, setDevice] = useState([]);
  const [creative, setCreative] = useState([]);
  const [expandMetric, setExpandMetric] = useState(false);
  const [campaignId, setCampaignId] = useState([]);
  const [getCampaignLoading, setGetCampaignLoading] = useState(false);
  const [campaignDropList, setCampaignDropList] = useState([]);
  const [adsCampaign, setAdsCampaign] = useState(null);
  const [adsCampaignList, setAdsCampaignList] = useState([]);

  // Manage campaign section start
  const [popAddCampaign, setPopAddCampaign] = useState(false);
  // const [popAddZone, setPopAddZone] = useState(false)
  // Manage campaign section end

  const getSubscription = window.localStorage.getItem("subscription");
  let decodedSubscription = getSubscription && jwt_decode(getSubscription);

  const params = useParams()
  console.log({params})

  const {data: adItems, fetching: fetchingAdItems, error: adItemsFetchError, refetch: refetchAdItems} = useFetch(`${API}/mediaPlanner/AdButler/aditems?campaign=${params.campaignId}`)

//   const {data: campaignDetails, error} = useFetch(`${API}/mediaPlanner/hivestack/campaigns`)



  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API}/mediaPlanner/AdButler/getAdvertiserList`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        // setCampaignList(res.data)
        let ad = [];
        res.data.advertisers.forEach((element) => {
          let temp = {
            value: "",
            label: "",
          };
          temp.value = element.advertiser.id;
          temp.label = element.advertiser.name;
          ad.push(temp);
        });
        setOptions(ad);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (
      startDate &&
      endDate &&
      advertiserId.length > 0 &&
      periodChoice &&
      campaignId.length > 0 && moment(endDate).diff(moment(startDate), "days") < 365
      ) {
    setLoadingGraph(true);
      let advertisers = "";
      advertiserId.forEach((item) => {
        if (advertisers === "") {
          advertisers = `${item.value}`;
        } else {
          advertisers = `${advertisers}, ${item.value}`;
        }
      });
      const campaignIds = [];
      campaignId.forEach((item) => {
        campaignIds.push(item.value);
      });
      const endDates = moment(endDate).format();
      const startDates = moment(startDate).format();
      const data = {
        startDate: startDates,
        endDate: endDates,
        advertisers,
        period: periodChoice.value,
        campaigns: campaignIds,
      };

      // axios
      //   .post(`${API}/mediaPlanner/AdButler/getAllAdvertiserReport`, data, {
      //     headers: {
      //       Authorization: `Bearer ${access_token}`,
      //     },
      //   })
      //   .then((res) => {
      //     if (res.data.VASTGraphReport.length > 0) {
      //       setVideoData(res.data.VASTGraphReport[0].data)
      //     }
      //     if (res.data.reportGraph.length > 0) {
      //       setDisplayData(res.data.reportGraph[0].data)
      //     }
      //     setLoadingGraph(false)
      //   })
      //   .catch((err) => {
      //     console.log("err", err)
      //     setLoadingGraph(false)
      //   })

      const datum = {
        startDate: startDates,
        endDate: endDates,
        period: periodChoice.value,
        campaigns: campaignIds,
      };

      axios
        .post(`${API}/mediaPlanner/AdButler/getCampaignReport`, datum, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then((res) => {
        setCampaignList(res.data.campaignReport);
          setLoadingGraph(false);
        })
        .catch((err) => {
          console.log("err", err);
          setLoadingGraph(false);
        
        });
      setApiCall(true);
    } else {
      setApiCall(false);
    }
  }, [startDate, endDate, advertiserId, campaignId, periodChoice]);

  useEffect(() => {
    const access_token = window.localStorage.getItem(SECRET_KEY);
    axios
      .get(`${API}/mediaPlanner/dropdowns`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res) {
          dispatch(SaveAdminDropDownData(res.data));
        }
      });
  }, []);

  const getCamapaignList = () => {
    setMetricLoading(true);
    setCampaignMetricList([]);
    let endPoint = `/mediaPlanner/lucidApi/`;

    axios
      .get(`${API}${endPoint}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res) {
          setCampaignMetricList(res.data.items);
        }
        setMetricLoading(false);
      })
      .catch(function (error) {
        if (error) {
          setMetricLoading(false);
        }
      });
  };

  useEffect(() => {
    getCamapaignList();
  }, []);
  useEffect(() => {
    if (moment(endDate).diff(moment(startDate), "days") > 365) {
      setDateError(true);
    } else {
      setDateError(false);
    }
  }, [startDate, endDate]);

  const graphDataFunction = (ageDataArr, ages, type) => {
    let arr =
      ageDataArr && ageDataArr.length ? [...ageDataArr] : [...StaticData];

    let brand_awareness = [];
    let brand_consideration = [];
    let message_association = [];
    let ad_recall = [];
    let purchase_intent = [];
    let bucket_name = [];
    if (type === "frequency") {
      arr[0].items.map((as) => {
        bucket_name.push(as.frequency_bucket_name);
      });
    } else if (type === "device") {
      arr[0].items.map((as) => {
        bucket_name.push(as.device);
      });
    } else if (type === "creative") {
      arr[0].items.map((as, i) => {
        bucket_name.push(
          as.creative_name ?? as.creative_id ?? `CreativeTemplate${i + 1}`
        );
      });
    } else {
      arr[0].items.map((as) => {
        bucket_name.push(as.bucket_name);
      });
    }

    const datasets = [];
    let labels = [];
    let datasetLabel = [];
    const finalData = [];
    arr &&
      arr.length &&
      arr.map((ag, i) => {
        switch (ag.question.question_name) {
          case "brand_awareness":
            ag.items.filter((a) => {
              if (type === "device") {
                bucket_name.map((as) => {
                  if (a.device === as) {
                    brand_awareness.push(a.lift_percentage);
                  }
                });
              } else if (type === "creative") {
                bucket_name.map((as, i) => {
                  if (
                    a.creative_id === as ||
                    a.creative_name === as ||
                    `CreativeTemplate${i + 1}` === as
                  ) {
                    brand_awareness.push(a.lift_percentage);
                  }
                });
              } else {
                ages.map((as) => {
                  if (type === "frequency") {
                    if (a.frequency_bucket_order === as) {
                      brand_awareness.push(a.total_count);
                    }
                  } else {
                    if (a.bucket_name === as.name) {
                      // brand_awareness.push(a.total_count);
                      brand_consideration.push({
                        key: as.name,
                        value: a.total_count,
                      });
                    }
                  }
                });
              }
            });
            break;
          case "brand_consideration":
            ag.items.filter((a) => {
              if (type === "device") {
                bucket_name.map((as) => {
                  if (a.device === as) {
                    brand_consideration.push(a.lift_percentage);
                  }
                });
              } else if (type === "creative") {
                bucket_name.map((as) => {
                  if (
                    a.creative_id === as ||
                    a.creative_name === as ||
                    `CreativeTemplate${i + 1}` === as
                  ) {
                    brand_consideration.push(a.lift_percentage);
                  }
                });
              } else {
                ages.map((as) => {
                  if (type === "frequency") {
                    if (a.frequency_bucket_order === as) {
                      brand_consideration.push({
                        key: as,
                        value: a.total_count,
                      });
                    }
                  } else {
                    if (a.bucket_name === as.name) {
                      // brand_consideration.push(a.total_count);
                      brand_consideration.push({
                        key: as.name,
                        value: a.total_count,
                      });
                    }
                  }
                });
              }
            });
            break;
          case "message_association":
            ag.items.filter((a) => {
              if (type === "device") {
                bucket_name.map((as) => {
                  if (a.device === as) {
                    message_association.push(a.lift_percentage);
                  }
                });
              } else if (type === "creative") {
                bucket_name.map((as) => {
                  if (
                    a.creative_id === as ||
                    a.creative_name === as ||
                    `CreativeTemplate${i + 1}` === as
                  ) {
                    message_association.push(a.lift_percentage);
                  }
                });
              } else {
                ages.map((as) => {
                  if (type === "frequency") {
                    if (a.frequency_bucket_order === as) {
                      message_association.push(a.total_count);
                    }
                  } else {
                    if (a.bucket_name === as.name) {
                      message_association.push(a.total_count);
                    }
                  }
                });
              }
            });
            break;
          case "ad_recall":
            ag.items.filter((a) => {
              if (type === "device") {
                bucket_name.map((as) => {
                  if (a.device === as) {
                    ad_recall.push(a.lift_percentage);
                  }
                });
              } else if (type === "creative") {
                bucket_name.map((as) => {
                  if (
                    a.creative_id === as ||
                    a.creative_name === as ||
                    `CreativeTemplate${i + 1}` === as
                  ) {
                    ad_recall.push(a.lift_percentage);
                  }
                });
              } else {
                ages.map((as) => {
                  if (type === "frequency") {
                    if (a.frequency_bucket_order === as) {
                      ad_recall.push(a.total_count);
                    }
                  } else {
                    if (a.bucket_name === as.name) {
                      ad_recall.push(a.total_count);
                    }
                  }
                });
              }
            });
            break;
          case "purchase_intent":
            ag.items.filter((a) => {
              if (type === "device") {
                bucket_name.map((as) => {
                  if (a.device === as) {
                    purchase_intent.push(a.lift_percentage);
                  }
                });
              } else if (type === "creative") {
                bucket_name.map((as) => {
                  if (
                    a.creative_id === as ||
                    a.creative_name === as ||
                    `CreativeTemplate${i + 1}` === as
                  ) {
                    brand_consideration.push(a.lift_percentage);
                  }
                });
              } else {
                ages.map((as) => {
                  if (type === "frequency") {
                    if (a.frequency_bucket_order === as) {
                      purchase_intent.push(a.total_count);
                    }
                  } else {
                    if (a.bucket_name === as.name) {
                      purchase_intent.push(a.total_count);
                    }
                  }
                });
              }
            });
            break;
          default:
            return ag;
        }
      });
    arr.map((sg) => {
      datasetLabel.push(sg.question.question_name);
    });

    const dataTemp = [];

    if (type === "device" || type === "creative") {
      datasetLabel.forEach((data) => {
        arr.forEach((item) => {
          const gd = [];
          item.items.forEach((d) => {
            if (d.question.question_name === data) {
              gd.push(d.lift_percentage);
            }
          });
          const d = {
            key: data,
            value: gd,
          };
          if (d.value.length > 0) {
            dataTemp.push(d);
          }
        });
      });
    } else {
      datasetLabel.forEach((data) => {
        arr.forEach((item) => {
          const gd = [];
          item.items.forEach((d) => {
            if (d.question.question_name === data) {
              gd.push(d.total_count);
            }
          });
          const d = {
            key: data,
            value: gd,
          };
          if (d.value.length > 0) {
            dataTemp.push(d);
          }
        });
      });
    }

    datasetLabel &&
      datasetLabel.length &&
      datasetLabel.map((dl, i) => {
        if (dataTemp[i].key === dl) {
          datasets.push({
            label: dl,
            borderWidth: 1,
            data: dataTemp[i].value,
            backgroundColor: backgroundColor[i],
            borderColor: borderColor[i],
          });
        }
      });
    bucket_name &&
      bucket_name.length &&
      bucket_name.map((bn) => {
        labels.push(bn);
      });
    labels = labels.filter(function (elem, pos) {
      return labels.indexOf(elem) == pos;
    });

    finalData.push({ labels: labels, datasets: datasets });
    return finalData;
  };

  const capitalizeFirstLetter = (string) => {
    // let str = string.replace(/_/g, " ");
    if (string == "participantDemography") string = "participantDemographics";
    let str = string.replace(/[A-Z]/g, " $&").trim();
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const getLiftInsights = () => {
    setMetricLoading(true);
    setInsights({});
    let endPoint = `/mediaPlanner/lucidApi/${campaign}/liftInsights?filter_question=${questionName}`;

    axios
      .get(`${API}${endPoint}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (
          res &&
          res.data &&
          res.data.overview &&
          ((res.data.overview.media && res.data.overview.media.length) ||
            (res.data.overview.audience && res.data.overview.audience.length))
        ) {
          setInsights(res.data.overview);
        } else {
          setInsights(StaticMediaData);
        }
        setMetricLoading(false);
      })
      .catch(function (error) {
        if (error) {
          setMetricLoading(false);
        }
      });
  };

  const getLiftForAllQuestions = () => {
    setMetricLoading(true);
    setAllQuestions([]);
    let endPoint = `/mediaPlanner/lucidApi/${campaign}/campaignData/liftForAllQuestions?filter_question=${questionName}`;

    axios
      .get(`${API}${endPoint}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res && res.data && res.data.items && res.data.items.length) {
          setAllQuestions(res.data.items);
        } else {
          setAllQuestions(StaticQuestionData);
        }
        setMetricLoading(false);
      })
      .catch(function (error) {
        if (error) {
          setMetricLoading(false);
        }
      });
  };

  const getLiftLiftByDemoAge = () => {
    setMetricLoading(true);
    setAgeData([]);
    let ageDatasets = [];

    let endPoint = `/mediaPlanner/lucidApi/${campaign}/demo_age/liftByDemo?filter_question=${questionName}`;

    axios
      .get(`${API}${endPoint}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (
          res.data &&
          res.data.length &&
          dropdownData &&
          dropdownData.ageGroups &&
          dropdownData.ageGroups.length
        ) {
          ageDatasets = graphDataFunction(
            res.data,
            dropdownData.ageGroups,
            "age"
          );
        }
        setAgeData(ageDatasets);
        setMetricLoading(false);
      })
      .catch(function (error) {
        if (error) {
          setMetricLoading(false);
        }
      });
  };

  const getLiftLiftByDemoGender = () => {
    setMetricLoading(true);
    setGender([]);
    let genderDatasets = [];

    let endPoint = `/mediaPlanner/lucidApi/${campaign}/demo_gender/liftByDemo?filter_question=${questionName}`;
    axios
      .get(`${API}${endPoint}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (
          res.data &&
          res.data.length &&
          dropdownData &&
          dropdownData.genders &&
          dropdownData.genders.length
        ) {
          genderDatasets = graphDataFunction(
            res.data,
            dropdownData.genders,
            "genders"
          );
        }
        setGender(genderDatasets);
        setMetricLoading(false);
      })
      .catch(function (error) {
        if (error) {
          setMetricLoading(false);
        }
      });
  };

  const getLiftbyQuestionFrequency = () => {
    setMetricLoading(true);
    setFrequency([]);
    let frequencyDatasets = [];
    let endPoint = `/mediaPlanner/lucidApi/${campaign}/campaignData/LiftbyQuestionFrequency?filter_question=${questionName}`;

    axios
      .get(`${API}${endPoint}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (
          res.data &&
          res.data.length &&
          dropdownData &&
          dropdownData.publishMonths &&
          dropdownData.publishMonths.length
        ) {
          frequencyDatasets = graphDataFunction(
            res.data,
            dropdownData.publishMonths,
            "frequency"
          );
        }
        setFrequency(frequencyDatasets);
        setMetricLoading(false);
      })
      .catch(function (error) {
        if (error) {
          setMetricLoading(false);
        }
      });
  };

  const getLiftByDevice = () => {
    setMetricLoading(true);
    setDevice([]);
    let deviceDatasets = [];
    const liftDatasetsArr = [];
    const deviceFinalData = [];
    const liftLabelsArr = [];
    let endPoint = `/mediaPlanner/lucidApi/${campaign}/liftByDevice?filter_question=${questionName}`;

    axios
      .get(`${API}${endPoint}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res && res.data && res.data.length) {
          deviceDatasets = graphDataFunction(res.data, [], "device");
          setDevice(deviceDatasets);
          // res.data[0].items.map((item, i) => {
          //   liftLabelsArr.push(item.device);
          //   liftDatasetsArr.push({
          //     label: item.device,
          //     data: [item.lift_percentage ? item.lift_percentage : 0],
          //     borderWidth: 1,
          //     backgroundColor: backgroundColor[i],
          //     borderColor: borderColor[i],
          //   });
          // });
          // deviceFinalData.push({
          //   labels: liftLabelsArr,
          //   datasets: liftDatasetsArr,
          // });
          // setDevice(deviceFinalData);
        }
        // else {
        //   StaticDeviceData.map((item, i) => {
        //     liftLabelsArr.push(item.device_name);
        //     liftDatasetsArr.push({
        //       label: item.device_name,
        //       data: [item.lift_percentage ? item.lift_percentage : 0],
        //       borderWidth: 1,
        //       backgroundColor: backgroundColor[i],
        //       borderColor: borderColor[i],
        //     });
        //   });
        //   deviceFinalData.push({
        //     labels: liftLabelsArr,
        //     datasets: liftDatasetsArr,
        //   });
        //   setDevice(deviceFinalData);
        // }
        setMetricLoading(false);
      })
      .catch(function (error) {
        if (error) {
          setMetricLoading(false);
        }
      });
  };

  const getLiftBreakdownByCreative = () => {
    setMetricLoading(true);
    setCreative([]);
    let deviceDatasets = [];
    const liftDatasets = [];
    const creativeFinalData = [];
    const liftLabels = [];
    let endPoint = `/mediaPlanner/lucidApi/${campaign}/campaignData/liftBreakdownByCreative?filter_question=${questionName}`;

    axios
      .get(`${API}${endPoint}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res && res.data && res.data.length) {
          deviceDatasets = graphDataFunction(res.data, [], "creative");
          setCreative(deviceDatasets);

          // res.data.items.map((item, i) => {
          //   liftLabels.push(item.creative_name);
          //   liftDatasets.push({
          //     label: item.creative_name,
          //     data: [item.lift_percentage ? item.lift_percentage : 0],
          //     borderWidth: 1,
          //     backgroundColor: backgroundColor[i],
          //     borderColor: borderColor[i],
          //   });
          // });
          // creativeFinalData.push({
          //   labels: liftLabels,
          //   datasets: liftDatasets,
          // });
          // setCreative(creativeFinalData);
        }
        // else {
        //   StaticDeviceData.map((item, i) => {
        //     liftLabels.push(item.creative_name);
        //     liftDatasets.push({
        //       label: item.creative_name,
        //       data: [item.lift_percentage ? item.lift_percentage : 0],
        //       borderWidth: 1,
        //       backgroundColor: backgroundColor[i],
        //       borderColor: borderColor[i],
        //     });
        //   });
        //   creativeFinalData.push({
        //     labels: liftLabels,
        //     datasets: liftDatasets,
        //   });
        //   setCreative(creativeFinalData);
        // }
        setMetricLoading(false);
      })
      .catch(function (error) {
        if (error) {
          setMetricLoading(false);
        }
      });
  };

  useEffect(() => {
    if (campaign === 0) return;
    getLiftInsights();
    getLiftForAllQuestions();
    getLiftLiftByDemoAge();
    getLiftLiftByDemoGender();
    getLiftbyQuestionFrequency();
    getLiftByDevice();
    getLiftBreakdownByCreative();
  }, [campaign, questionName]);

  const minmaxHandler = () => {
    setMin(!min);
  };
  // http://192.168.29.172:5000/api/v1/mediaPlanner/AdButler/getCampaignGraphReport
  const advertiseHandler = (e) => {
    setAdvertiserId(e);
    setGetCampaignLoading(true);
    setCampaignDropList([]);
    e.map(async (item) => {
      await axios
        .get(
          `${API}/mediaPlanner/AdButler/getAssignedCampaigns/${item.value}`,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        )
        .then((res) => {
          res.data.campaigns.forEach((item) => {
            const dd = {
              value: item.campaign.id,
              label: item.campaign.name,
            };
            setCampaignDropList((prev) => prev.concat(dd));
          });
          setGetCampaignLoading(false);
        })
        .catch((err) => {
          console.log("err", err);
          setGetCampaignLoading(false);
        });
    });
    setPeriodChoice(null);

    if (advertiserId.length - 1 <= 0) {
      setCampaignId([]);
    }
    setGetCampaignLoading(false);
  };

  const rowHandler = async (row) => {
    setLoadingGraph(true);
    const rowName = row.name.split(" ").join("");
    campaignMetricList.forEach((item) => {
      if (rowName === item.campaign_name.split(" ").join("")) {
        setCampaign(item.campaign_id);
      }
    });
    const data = {
      startDate: `${moment(startDate).format("YYYY-MM-DD")}T00:00:00+05:30`,
      endDate: `${moment(endDate).format("YYYY-MM-DD")}T00:00:00+05:30`,
      period: periodChoice.value,
      campaigns: [row.id],
    };

    await axios
      .post(`${API}/mediaPlanner/AdButler/getCampaignGraphReport`, data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        if (res.data.type === "display") {
          setDisplayData(res.data?.reportGraph[0].data);
        } else {
          setVideoData(res.data?.VASTGraphReport[0].data);
        }
        setLoadingGraph(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoadingGraph(false);
      });
  };
  const [showAdItemModal, setShowAdItemModal] = useState(false)

  const addCampaignHandler = () => {
    setPopAddCampaign(true);
  };
  const showModalAdItem = () => {
    setShowAdItemModal(true)
  }

  const adsCampaignHandler = async (e) => {
    setAdsCampaign(e.value);
    const access_token = localStorage.getItem(SECRET_KEY);
    await axios
      .get(
        `${API}/mediaPlanner/AdButler/getAllCampaignsList?advertiser=${e.value}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      .then((res) => {
        console.log("res.data123", res.data.data);
        setAdsCampaignList(res.data.data);
      })
      .catch((err) => {
        console.log("[ERROR]", err.response.data.message);
      });
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <div style={{ marginBottom: "20px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: "20px 0 10px 0",
          }}
        >
          <span
            style={{
              color: "#FB6D6A",
              fontWeight: "600",
              margin: "0",
              fontSize: "20px",
              // marginLeft: "20px",
            }}
          >
            Creatives List
          </span>
          <div className={classess.campaignNavBtn}>
            {/* <Select
              placeholder="Advertise"
              defaultValue={adsCampaign}
              onChange={adsCampaignHandler}
              className={classess.campaignAds}
              options={options}
            /> */}
            <Button
              variant="contained"
              color="primary"
              onClick={showModalAdItem}
              style={{ marginRight: "0.2rem" }}
            >
              Create AdItem
            </Button>
          </div>
        </div>
        {/* adItems listing table */}
        <TableContainer component={Paper} style={{ maxHeight: "400px" }}>
          <Table
            stickyHeader
            className={classesTable.table}
            aria-label="customized table"
          >
            <AdItemTableHeaderColumns />
            <TableBody style={{...(fetchingAdItems && {height:"400px",position:"relative"})}}>
              {fetchingAdItems && <LoaderCircle  style={{position:"absolute",width:"30px",height:"30px"}}/>}
              {((adItems?.data?.length == 0 || !adItems?.data) && !fetchingAdItems) &&  <StyledTableRow>
                <StyledTableCell style={{border:"none"}}> No records found.</StyledTableCell>
              </StyledTableRow>}
              {
                adItems?.data?.map((adItem) => {
                  return (
                    <TableRow key={adItem.id} style={{cursor: "pointer"}} 
                    // onClick={() => history.push(`/media-planner/campaign/advertiser/${adsCampaign}/${adItem.id}`)}
                    >
                      {/* <TableCell>{adItem.object == "standard_campaign" ? "Standard" : 'Vast'}</TableCell> */}
                      <TableCell style={{border:"1px solid #d0d0d0"}}>{adItem.name}</TableCell>
                      <TableCell style={{border:"1px solid #d0d0d0"}}>{adItem.created_date}</TableCell>
                      <TableCell style={{border:"1px solid #d0d0d0"}}>{adItem.width}x{adItem.height}</TableCell>
                      <TableCell style={{border:"1px solid #d0d0d0"}}>{adItem.weight}</TableCell>
                      <TableCell style={{border:"1px solid #d0d0d0"}}>{adItem.weight}</TableCell>
                      <TableCell style={{border:"1px solid #d0d0d0"}}>{adItem.weight}</TableCell>
                      <TableCell style={{border:"1px solid #d0d0d0"}}>{adItem.weight}</TableCell>
                      <TableCell style={{border:"1px solid #d0d0d0"}}>{adItem.weight}</TableCell>
                      <TableCell style={{border:"1px solid #d0d0d0"}}>{adItem.weight}</TableCell>
                      <TableCell style={{border:"1px solid #d0d0d0"}}>{adItem.active ? "Active" : "Paused"}</TableCell>
                      {/* <TableCell>{adItem?.targeting_source ? "Campaign" : "-"}</TableCell> */}
                    </TableRow>      
                  )
                })
              } 
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <AdItemInputModal 
        show={showAdItemModal}
        onClose={setShowAdItemModal}
        refetchAdItems={refetchAdItems}
      />
      <ChannelAssign />

      {/* alert box */}
      <Snackbar
        open={dateError}
        autoHideDuration={6000}
        onClose={() => {
          setDateError(false);
        }}
      >
        <Alert
          onClose={() => {
            setDateError(false);
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          You cannot select a period that is over a year !
        </Alert>
      </Snackbar>
    </>
  );
};

export default MainLayout(AdvertiserCampaignReport);



const AdItemTableColumns_Data = ["Name", "Created", "Size", "Weight", "Eligible", "Viewable", "Rate", "Total Impr.", "Clicks", "Status"]

const AdItemTableHeaderColumns = () => {
    return (
        <TableHead>
            <TableRow>
                {AdItemTableColumns_Data.map((column) => {
                    return (
                        <TableCell
                            key={column}
                            style={{
                                color: "white",
                                background: "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))",
                                border:"1px solid #d0d0d0",
                            }}
                        >
                            {column}
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
};

{/* <TableRow>
                <TableCell
                  style={{
                    color: "white",
                    background:
                      "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))",
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  style={{
                    color: "white",
                    background:
                      "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))",
                  }}
                >
                  Created
                </TableCell>
                <TableCell
                  style={{
                    color: "white",
                    background:
                      "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))",
                  }}
                >
                  Size
                </TableCell>
                <TableCell
                  style={{
                    color: "white",
                    background:
                      "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))",
                  }}
                >
                  Weight
                </TableCell>
                <TableCell
                  style={{
                    color: "white",
                    background:
                      "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))",
                  }}
                >
                  Eligible
                </TableCell>
              </TableRow> */}