import { put, call, takeLatest } from 'redux-saga/effects';
import * as actions from '../redux/actions/inventory';
import * as mediaOwneractions from '../redux/actions/media-owner';

import { getMediaWithAllInventry,putMediaInventry } from '../services/api/media-owner';

export function* callAddNewMediaOwnerInventryAPI(param) {
    console.log('sagaaaaaaaaaaaaaaaaaaaaa_callAddNewMediaInventryAPI_',param);
  
  try {
    const result = yield call(putMediaInventry,param && param.mediaOwnerId,param && param.inventoriesList);
    console.info(' MMMMMMMediaWithAllInventry', result.data); 
    yield put(mediaOwneractions.createMediaOwnerAndInventorySuccessfull(true));
  } catch(error) {
    yield put(actions.error(error));
  }
}

export function* addNewMediaOwnerInventrySaga() {
  yield takeLatest(actions.ADD_NEW_MEDIA_OWNER_INVENTRY, callAddNewMediaOwnerInventryAPI);
}

export function* callGetMediaOwnerWithAllInventryAPI(param) {
    console.log('saga_callGetMediaWithAllInventryAPI_',param);
  try {
    const result = yield call(getMediaWithAllInventry,param && param.mediaOwnerId);
    console.info(' GGGGGGGGETMediaWithAllInventry', result.data); 
    yield put(actions.saveMediaOwnerWithAllInventry(result.data));
  } catch(error) {
    yield put(actions.error(error));
  }
}

export function* getMediaOwnerWithAllInventrySaga() {
  yield takeLatest(actions.GET_MEDIA_OWNER_WITH_ALL_INVENTORY, callGetMediaOwnerWithAllInventryAPI);
}

