import React from "react"
import {useSnackbar} from 'notistack'
import { Close } from "@material-ui/icons";


export const useAppToastify = () =>{
    const anchorOrigin = { horizontal: "center", vertical: "bottom" }
    const { enqueueSnackbar,closeSnackbar } = useSnackbar();

  
    /**
     * @type {import("notistack").EnqueueSnackbar}
    */
    const showAppToast = (message, options) => {
        enqueueSnackbar(message || "", {...options, anchorOrigin,action:(key) => {
            return (
                <>
                    <Close className="pointer" onClick={() => closeSnackbar(key)}/>
                </>
            )
        }})
    }
    return {showAppToast}
}