import axios from "axios";
import { API } from "../../constants";
import { getToken } from "./user";

export const post = async (relativeUrl, data) => {
  console.log(data, "!!!!!!!!!!!!AAAAAA");
  try {
    const url = API + relativeUrl;
    const token = getToken();

    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return await axios.post(url, data);
  } catch (error) {
    throw error.response.data.error;
  }
};

export const put = async (relativeUrl, data) => {
  const mediaOwner = {
    mediaOwner: data,
  };
  try {
    const url = API + relativeUrl;
    const token = getToken();
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return await axios.put(url, mediaOwner);
  } catch (error) {
    throw error.response.data.error;
  }
};

export const postUpload = async (relativeUrl, data) => {
  try {
    const url = API + relativeUrl;
    const token = getToken();
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

    let formData = new FormData();
    formData.append("avatar", data.avatar);

    return axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    throw error.response.data.error;
  }
};

export const postUploadCsv = async (relativeUrl, data) => {
  try {
    const url = API + relativeUrl;
    const token = getToken();
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

    let formData = new FormData();
    formData.append("csv", data.csv);

    return axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    throw error.response.data.error;
  }
};

export const get = async (relativeUrl, isBearer) => {
  try {
    const url = API + relativeUrl;
    const token = getToken();

    // if(isBearer) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    // }

    return await axios.get(url);
  } catch (error) {
    throw error;
  }
};

export const putAddNewMediaInventry = async (relativeUrl, data) => {
  console.log("axios_putAddNewMediaInventry_data", data);
  try {
    const url = API + relativeUrl;
    const token = getToken();
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return await axios.put(url, data);
  } catch (error) {
    throw error.response.data.error;
  }
};
