/* eslint-disable no-unused-expressions */
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core"
import { cloneDeep } from "lodash"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import useStyles from "../../../../../components/StyleClasses"
import { useChannelAssignmentPageCtx } from "../../ChannelAssignmentPage"

export default function DayPartingTimeSelection({ step2StatesRef }) {
    const tableStyles = useStyles()
    const { assignedChannelDetailsForUpdate, assignChannelUpdateMode } = useChannelAssignmentPageCtx()

    const initialDaypartingStates = cloneDeep({
        Mon: {
            fullName: "monday",
            slots: !assignChannelUpdateMode
                ? MappedSlotes()
                : MappedSlotes().map((slot) => {
                      const new_slot = slot
                      assignedChannelDetailsForUpdate?.scheduleData?.day_parting?.ranges?.monday?.forEach((time) => {
                          if (time == slot.duration) {
                              new_slot.checked = true
                          }
                      })
                      return new_slot
                  }),
        },
        Tue: {
            fullName: "tuesday",
            slots: !assignChannelUpdateMode
                ? MappedSlotes()
                : MappedSlotes().map((slot) => {
                      const new_slot = slot
                      assignedChannelDetailsForUpdate?.scheduleData?.day_parting?.ranges?.tuesday?.forEach((time) => {
                          if (time == slot.duration) {
                              new_slot.checked = true
                          }
                      })
                      return new_slot
                  }),
        },
        Wed: {
            fullName: "wednesday",
            slots: !assignChannelUpdateMode
                ? MappedSlotes()
                : MappedSlotes().map((slot) => {
                      const new_slot = slot
                      assignedChannelDetailsForUpdate?.scheduleData?.day_parting?.ranges?.wednesday?.forEach((time) => {
                          if (time == slot.duration) {
                              new_slot.checked = true
                          }
                      })
                      return new_slot
                  }),
        },
        Thu: {
            fullName: "thursday",
            slots: !assignChannelUpdateMode
                ? MappedSlotes()
                : MappedSlotes().map((slot) => {
                      const new_slot = slot
                      assignedChannelDetailsForUpdate?.scheduleData?.day_parting?.ranges?.thursday?.forEach((time) => {
                          if (time == slot.duration) {
                              new_slot.checked = true
                          }
                      })
                      return new_slot
                  }),
        },
        Fri: {
            fullName: "friday",
            slots: !assignChannelUpdateMode
                ? MappedSlotes()
                : MappedSlotes().map((slot) => {
                      const new_slot = slot
                      assignedChannelDetailsForUpdate?.scheduleData?.day_parting?.ranges?.friday?.forEach((time) => {
                          if (time == slot.duration) {
                              new_slot.checked = true
                          }
                      })
                      return new_slot
                  }),
        },
        Sat: {
            fullName: "saturday",
            slots: !assignChannelUpdateMode
                ? MappedSlotes()
                : MappedSlotes().map((slot) => {
                      const new_slot = slot
                      assignedChannelDetailsForUpdate?.scheduleData?.day_parting?.ranges?.saturday?.forEach((time) => {
                          if (time == slot.duration) {
                              new_slot.checked = true
                          }
                      })
                      return new_slot
                  }),
        },
        Sun: {
            fullName: "sunday",
            slots: !assignChannelUpdateMode
                ? MappedSlotes()
                : MappedSlotes().map((slot) => {
                      const new_slot = slot
                      assignedChannelDetailsForUpdate?.scheduleData?.day_parting?.ranges?.sunday?.forEach((time) => {
                          if (time == slot.duration) {
                              new_slot.checked = true
                          }
                      })
                      return new_slot
                  }),
            // slots: HoursArr24HFormat.map(h => ({checked: false, time: h}))
        },
    })
    const [daypartingTrackIndexes, setDaypartingTrackIndexes] = useState(() =>
        JSON.parse(JSON.stringify(initialDaypartingStates))
    )
    // const [daypartingTrackIndexes, setDaypartingTrackIndexes] = useState({
    //     "Mon":new Array(Arr_24.length),
    //     "Tue":new Array(Arr_24.length),
    //     "Wed":new Array(Arr_24.length),
    //     "Thu":new Array(Arr_24.length),
    //     "Fri":new Array(Arr_24.length),
    //     "Sat":new Array(Arr_24.length),
    //     "Sun":new Array(Arr_24.length),
    //     });

    const selectAllSlots = () => {
        setDaypartingTrackIndexes({
            Mon: {
                fullName: "monday",
                slots: AllCheckedMappedSlotes(),
            },
            Tue: {
                fullName: "tuesday",
                slots: AllCheckedMappedSlotes(),
            },
            Wed: {
                fullName: "wednesday",
                slots: AllCheckedMappedSlotes(),
            },
            Thu: {
                fullName: "thursday",
                slots: AllCheckedMappedSlotes(),
            },
            Fri: {
                fullName: "friday",
                slots: AllCheckedMappedSlotes(),
            },
            Sat: {
                fullName: "satuday",
                slots: AllCheckedMappedSlotes(),
            },
            Sun: {
                fullName: "sunday",
                slots: AllCheckedMappedSlotes(),
                // slots: HoursArr24HFormat.map(h => ({checked: false, time: h}))
            },
        })
    }

    const deselectAllSlots = () => {
        setDaypartingTrackIndexes({
            Mon: {
                fullName: "monday",
                slots: MappedSlotes(),
            },
            Tue: {
                fullName: "tuesday",
                slots: MappedSlotes(),
            },
            Wed: {
                fullName: "wednesday",
                slots: MappedSlotes(),
            },
            Thu: {
                fullName: "thursday",
                slots: MappedSlotes(),
            },
            Fri: {
                fullName: "friday",
                slots: MappedSlotes(),
            },
            Sat: {
                fullName: "satuday",
                slots: MappedSlotes(),
            },
            Sun: {
                fullName: "sunday",
                slots: MappedSlotes(),
            },
        })
    }

    useEffect(() => {
        step2StatesRef.current = { ...step2StatesRef.current }
    }, [])

    useEffect(() => {
        const checkedDays = Object.values(daypartingTrackIndexes).filter(
            (parting) => parting.slots.filter((x) => x.checked).length > 0
        )
        const filterSlotsDays = checkedDays
            .map((day) => {
                const filterSlotes = day.slots.filter((parting) => parting.checked)
                if (filterSlotes.length > 0) {
                    return { ...day, slots: [...filterSlotes] }
                }
            })
            .filter(Boolean)

        let mappedPartingDays = {}
        filterSlotsDays.forEach((slots) => {
            let arr = []
            slots.slots.forEach((s) => arr.push(s.duration))
            mappedPartingDays[slots.fullName] = arr
        })
        // console.log({ mappedPartingDays })
        if (step2StatesRef?.current) {
            step2StatesRef.current.partingSlots = mappedPartingDays
        }
    }, [daypartingTrackIndexes])

    return (
        <Grid container>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow className={tableStyles.daypartingTr}>
                            <TableCell style={{ padding: "0", border: "0", width: "18px", height: "20px" }}></TableCell>
                            {HoursArr.map((h, i) => (
                                <TableCell
                                    key={i}
                                    style={{
                                        padding: "0",
                                        border: "0",
                                        width: "40px",
                                        // height: "20px",
                                        verticalAlign: "bottom",
                                        textAlign: "center",
                                        fontSize: "10px",
                                        ...((i == 0 || i == 12) && { borderLeft: "2px solid black" }),
                                    }}
                                >
                                    <span>
                                        {i == 0 && (
                                            <>
                                                {" "}
                                                <span className="font-bold-600">AM</span>
                                                <br />
                                            </>
                                        )}
                                        {i == 12 && (
                                            <>
                                                <span className="font-bold-600">PM</span>
                                                <br />
                                            </>
                                        )}
                                        {h}
                                    </span>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {WeeksName.map((week_name, week_i) => {
                            return (
                                <TableRow key={week_i} className={tableStyles.daypartingTr}>
                                    <TableCell style={{ padding: "0", border: "0", fontSize: "10px" }}>
                                        <span>{week_name}</span>
                                    </TableCell>

                                    {HoursArr.map((item, index) => {
                                        return (
                                            <TableCell
                                                key={index}
                                                className={tableStyles.daypartingTCell}
                                                style={{
                                                    padding: "0",
                                                    border: "0",
                                                    width: "18px",
                                                    height: "20px",
                                                    border: "1px solid #afafaf",
                                                    textAlign: "center",
                                                    ...((index == 0 || index == 12) && {
                                                        borderLeft: "2px solid black",
                                                    }),
                                                    ...(daypartingTrackIndexes?.[week_name]?.slots[index].checked && {
                                                        background: "purple",
                                                    }),
                                                }}
                                                onClick={() =>
                                                    setDaypartingTrackIndexes((indexes) => {
                                                        const cloned = { ...indexes }
                                                        cloned[week_name].slots[index].checked = !!!cloned?.[week_name]
                                                            ?.slots[index].checked
                                                        return cloned
                                                    })
                                                }
                                            ></TableCell>
                                        )
                                    })}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="flex flex-row" style={{ gap: "10px", marginTop: "10px" }}>
                <Link to={"#"} onClick={selectAllSlots}>
                    Select All Slots
                </Link>
                <Link to="#" onClick={deselectAllSlots}>
                    Deselect All Slots
                </Link>
            </div>
        </Grid>
    )
}

// data needed for managing the dates
const WeeksName = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
// const WeeksFullName = ["monday", "tuesday", "wednesday", "thurseday", "friday", "saturday", "sunday"];

const HoursArr = [
    "12:00",
    "1:00",
    "2:00",
    "3:00",
    "4:00",
    "5:00",
    "6:00",
    "7:00",
    "8:00",
    "9:00",
    "10:00",
    "11:00",
    "12:00",
    "1:00",
    "2:00",
    "3:00",
    "4:00",
    "5:00",
    "6:00",
    "7:00",
    "8:00",
    "9:00",
    "10:00",
    "11:00",
]
const HoursArr24HFormat = [
    "00:00",
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00",
]

const MappedSlotes = () =>
    HoursArr24HFormat.map((h, i) => {
        if (i == 0) {
            return { checked: false, time: h, duration: `${h}:00-0${1}:00:00` }
        } else {
            const time = Number(h.toString().split(":")[0]) + 1
            return { checked: false, time: h, duration: `${h}:00-${time < 10 ? "0" + time : time}:00:00` }
        }
    })
const AllCheckedMappedSlotes = () =>
    HoursArr24HFormat.map((h, i) => {
        if (i == 0) {
            return { checked: true, time: h, duration: `${h}:00-0${1}:00:00` }
        } else {
            const time = Number(h.toString().split(":")[0]) + 1
            return { checked: true, time: h, duration: `${h}:00-${time < 10 ? "0" + time : time}:00:00` }
        }
    })
