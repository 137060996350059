/* eslint-disable no-unused-expressions */
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import Paper from "@material-ui/core/Paper"
import Select from "@material-ui/core/Select"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import DeleteForeverIcon from "@material-ui/icons/DeleteForever"
import EditIcon from "@material-ui/icons/Edit"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"
import RemoveCircleOutline from "@material-ui/icons/RemoveCircleOutline"
import Pagination from "@material-ui/lab/Pagination"
import axios from "axios"
import React, { useEffect, useState } from "react"
import NewAlert from "../../components/NewAlert"
import MainLayout from "../../layouts/adminMainLayout"

import useStyles from "../../components/StyleClasses"
import { API, SECRET_KEY } from "../../constants/index"

const useTableStyles = makeStyles({
  table: {
    minWidth: 700,
    maxWidth: 2000,
  },
})

const previewImageStyles = makeStyles({
  previewWrapper: {
    display: "flex",
    flexDirection: "row"
  }
})

const StyledTableRow = withStyles((theme) => ({
  root: {},
}))(TableRow)

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.white,
    fontSize: 12,
    paddingBottom: "2px",
    paddingTop: "2px",
  },
  body: {
    fontSize: 12,
    border: "1px solid #D2D2D2",
    color: "#4F74C9",
    paddingBottom: "2px",
    paddingTop: "2px",
  },
}))(TableCell)

const InteractiveMapUpdate = () => {
  const access_token = window.localStorage.getItem(SECRET_KEY)
  ///////errors & successs //////////
  const [ErrSucMsg, setErrSucMesg] = useState("")
  const [ErrSucType, setErrSucType] = useState(true)
  ///////////////////////////////////
  const [tableLoading, setTableLoading] = useState(false)
  const [listDATA, setListDATA] = useState([])
  const [listTableData, setListTableData] = useState([])
  const [listPublisherDATA, setPublisherListDATA] = useState([])
  const [listPublisherTableData, setPublisherListTableData] = useState([])
  const [listPage, setListPage] = useState(1)
  const [listPageCount, setListPageCount] = useState(0)
  const [open, setOpen] = useState(false)
  const [openPublisher, setOpenPublisher] = useState(false)
  const [country, setCountry] = useState("")
  const [editMap, setEditMap] = useState(null)
  const [editPublisher, setEditPublisher] = useState(null)
  const [fields, setFields] = useState([])
  const [countryDetails, setCountryDetails] = useState([
    {
      id: Date.now(),
      key: null,
      value: null,
    },
  ])
  const [dropData, setDropData] = useState()
  const [headerTable, setHeaderTable] = useState([])
  const [publisherHeaderTable, setPublisherHeaderTable] = useState([])
  const [updateDelete, setUpdateDelete] = useState(false) // To refresh page on delete function success using below state

  // New changes state
  const [digitalPublisher, setDigitalPublisher] = useState([])
  const [interactiveMap, setInteractiveMap] = useState([])
  const [digitalPublisherDemographics, setDigitalPublisherDemographics] = useState([])
  const [mapDemographics, setMapDemographics] = useState([])
  const [previewImg, setPreviewImg] = useState([])
  
  const classes = useStyles()
  const classesTable = useTableStyles()
  const classesPreview = previewImageStyles()
  const [old_saved_images, setOldSavedImages] = useState([])


  useEffect(() => {
    setTableLoading(true)
    axios
      .get(`${API}/admin/intelligence/mapDataList`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        setListDATA(res?.data?.mapData)
        let k = []
        let td = []
        res?.data?.mapData?.map((item) => {
          Object.keys(item).map((data) => {
            if (data !== "_id" && data !== "") {
              k.push(data)
            }
          })
        })

        setHeaderTable([...new Set(k)])
        setListTableData(res?.data?.mapData)
        setMapDemographics(res?.data?.mapDemographics)
        if(editMap == null){
          const d = {}
          res?.data?.mapDemographics.map(item => {
            d[item] = ""
          })
          setInteractiveMap(d)
        }
        setTableLoading(false)
        if (editMap && Object.keys(editMap).length >= 3) {
          let arr = []
          let index = 1
          Object.entries(editMap).map(([key, value]) => {
            if (["Impression"].includes(key)) {
              let d = {
                id: index,
                key: "Impression",
                value: null,
              }

              d.key = key
              d.value = value
              arr.push(d)
              index++
            }
            if (!["_id", "Country", "Impression"].includes(key)) {
              let d = {
                id: index,
                key: null,
                value: null,
              }

              d.key = key
              d.value = value
              arr.push(d)
              index++
            }
          })
          setCountryDetails(arr)
          setFields(arr)
        }
      })
      .catch((err) => {
        console.log(err)
        setTableLoading(false)
      })
  }, [open, updateDelete])

  useEffect(() => {
    axios
      .get(`${API}/admin/intelligence/digitalPublisherList`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        setPublisherListDATA(res?.data?.mapData)
        setPublisherListTableData(res?.data?.mapData)
        setDigitalPublisherDemographics(res?.data?.demographics)
        setOldSavedImages((old_images) => {
          let details = []
          res?.data?.mapData?.forEach((item, i)=> {
            Object.values(item).forEach((o) => {
              o?.images?.forEach((img_path) => {
                details.push(img_path)
              })
            })
          })
          return details
        })
        if(editPublisher == null){
          const d = {}
          res?.data?.demographics.map(item => {
            d[item] = ""
          })
          setDigitalPublisher(d)
        }
        let k = []
        let td = []
        res?.data?.mapData?.map((item) => {
          Object.keys(item).map((data) => {
            if (data !== "_id" && data !== "") {
              k.push(data)
            }
          })
        })
        setPublisherHeaderTable([...new Set(k)])

        if (editPublisher && Object.keys(editPublisher).length >= 2) {
          let arr = []

          let index = 0
          Object.entries(editPublisher).map(([key, value]) => {
            if (!["_id", "country"].includes(key)) {
              let d = {
                id: index,
                key: null,
                value: null,
              }

              d.key = key
              d.value = value
              arr.push(d)
              index++
            }
          })

          setFields(arr)
          setCountryDetails(arr)
        }
      })
      .catch((err) => console.log("err", err))
  }, [openPublisher, updateDelete])

  useEffect(() => {
    axios
      .get(`${API}/admin/dropdowns`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (err) console.log("error", err)
        if (res) {
          setDropData(res.data)
          const d = {}
          res.data.demographics.map(item => {
            d[item.name] = ""
          })
          // setDigitalPublisher(d)
        }
      })
  }, [])

  const AddFieldHandler = () => {
    let data = []

    countryDetails.forEach((item) => {
      data.push(item)
    })
    data.push({
      id: Date.now(),
      key: "",
      value: "",
    })
    setFields(data)
    setCountryDetails(data)
  }

  const RemoveFieldHandler = (index) => {
    let fieldTemp = fields;

    if (fieldTemp.length > 1) {
      let arr = []
      fieldTemp.map((item) => {
        if(item.id !== index) {
          arr.push(item)
        }
      })

      setFields(arr)
      setCountryDetails(arr)
    } else {
      setFields([])
    }
  }

  const countryDetailsHandler = (e, index) => {
    if (index === "map") {
      setCountryDetails((prev) => {
        prev.map((item) => {
          // if (item.id === 1) {
            item.key = "Impression"
            item.value = e.target.value
            return item
          // }
        })
        return prev
      })
    } else {
      setCountryDetails((prev) => {
        prev.map((item) => {
          if (item.id === index) {
            if (e.target.name === "name") {
              return (item.key = e.target.value)
            } else {
              return (item.value = e.target.value)
            }
          }
        })

        return prev
      })
    }
  }

  const submitCountryMap = async () => {
    let flag = false
    countryDetails.forEach((item) => {
      if (item.key === "" || item.value === "" || !item.key || !item.value) {
        setErrSucMesg("Field cannot be empty!")
        setErrSucType(false)
        flag = true
      }
    })
    if (flag) return

    await axios
      .post(
        `${API}/admin/intelligence/addMapData`,
        {
          country,
          countryDetails,
        },
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setErrSucMesg(res.data.message)
        setErrSucType(true)
        setFields([])
        setCountry("")
        setCountryDetails([
          {
            id: Date.now(),
            key: null,
            value: null,
          },
        ])
        setTimeout(() => setOpen(false), 500)
      })
      .catch((err) => {
        setErrSucMesg(err.response.data.message)
        setErrSucType(false)
      })
  }

  const submitUpdateMap = async (id) => {
    let flag = false
    countryDetails.forEach((item) => {
      if (item.key === "" || item.value === "") {
        setErrSucMesg("Field cannot be empty!")
        setErrSucType(false)
        flag = true
      }
    })
    if (flag) return

    await axios
      .put(
        `${API}/admin/intelligence/updateMapData/${id}`,
        {
          country: `${editMap?.Country}`,
          countryDetails,
        },
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setErrSucMesg(res.data.message)
        setErrSucType(true)
        setFields([])
        setCountryDetails([
          {
            id: Date.now(),
            key: null,
            value: null,
          },
        ])
        setEditMap(null)
        setTimeout(() => setOpen(false), 500)
      })
      .catch((err) => {
        setErrSucMesg(err.response.data.message)
        setErrSucType(false)
      })
  }

  const submitPublisher = async () => {
    let flag = true
    for(const publisher in digitalPublisher){
      if(digitalPublisher[publisher] && digitalPublisher[publisher] !== 0 && digitalPublisher[publisher] !== "0"){
        flag = false
      }
    }
    if (flag){
      setErrSucMesg("Field cannot be empty!")
      setErrSucType(false)
      return
    }

    const formData = new FormData()
    let digitalPublishers = {}
    Object.keys(digitalPublisher).forEach(item => {
      if (digitalPublisher[`${item}`] && 
          digitalPublisher[`${item}`]?.images && 
          digitalPublisher[`${item}`].images.length > 0) {
            digitalPublisher[`${item}`].images.forEach((items, index) => {
              formData.append(`${item}`, digitalPublisher[`${item}`].images[index])
            })
      }
      digitalPublishers[item] = digitalPublisher[`${item}`].value ?? ""
    })
    
    formData.append("country", country)
    formData.append("digitalPublisher", JSON.stringify(digitalPublishers))

    // console.log(JSON.stringify(formData))
    // return
    await axios
      .post(
        `${API}/admin/intelligence/addDigitalPublisher`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      .then((res) => {
        setErrSucMesg(res.data.message)
        setErrSucType(true)
        setFields([])
        setCountry("")
        setPreviewImg([])
        setCountryDetails([
          {
            id: Date.now(),
            key: null,
            value: null,
          },
        ])
        setTimeout(() => setOpenPublisher(false), 500)
      })
      .catch((err) => {
        setErrSucMesg(err.response.data.message)
        setErrSucType(false)
      })
  }
  const submitUpdatePublisher = async (id) => {
    let flag = true
    for(const publisher in digitalPublisher){
      if(digitalPublisher[publisher] && digitalPublisher[publisher] !== 0 && digitalPublisher[publisher] !== "0"){
        flag = false
      }
    }
    if (flag){
      setErrSucMesg("Field cannot be empty!")
      setErrSucType(false)
      return
    }
    const formData = new FormData()
    let digitalPublishers = {}

    Object.keys(digitalPublisher).forEach(item => {
      if (digitalPublisher[item] && 
          digitalPublisher[item]?.images && 
          digitalPublisher[item].images.length > 0) {
            digitalPublisher[item].images.forEach((items, index) => {
              console.log(digitalPublisher[item].images[index])
              if(!old_saved_images.includes(digitalPublisher[item].images[index])){
                formData.append(item, digitalPublisher[item].images[index])
                delete digitalPublisher[item].images[index]
              }
            })
            if((digitalPublisher[item]?.images?.filter(Boolean)).length < 1){
              delete digitalPublisher[item].images
            }
      }
      digitalPublishers[item] = digitalPublisher[item]?.value ?? ""
    })
    
    formData.append("country", editPublisher?.country)
    
    // deleting the keys to work the update api, they are not valid.
    delete editPublisher.country
    delete editPublisher._id

    formData.append("digitalPublisher", JSON.stringify({...digitalPublisher, ...editPublisher}))
    // return 
    await axios
      .put(
        `${API}/admin/intelligence/updateDigitalPublisher/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setErrSucMesg(res.data.message)
        setErrSucType(true)
        setFields([])
        setPreviewImg([])
        setCountryDetails([
          {
            id: Date.now(),
            key: null,
            value: null,
          },
        ])
        setEditPublisher(null)
        setTimeout(() => setOpenPublisher(false), 500)
      })
      .catch((err) => {
        setErrSucMesg(err.response.data.message)
        setErrSucType(false)
      })
  }

  const deleteMapHandler = async (id) => {
    await axios
      .delete(`${API}/admin/intelligence/deleteMapData/${id}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        setErrSucMesg(res.data.message)
        setErrSucType(true)
        setUpdateDelete(!updateDelete)
      })
      .catch((err) => {
        console.log("err", err)
        // setErrSucMesg(res.data.message)
        setErrSucType(false)
      })
  }

  const deletePublisherHandler = async (id) => {
    await axios
      .delete(`${API}/admin/intelligence/deleteDigitalPublisher/${id}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        setErrSucMesg(res.data.message)
        setErrSucType(true)
        setUpdateDelete(!updateDelete)
      })
      .catch((err) => {
        console.log("err", err)
        // setErrSucMesg(res.data.message)
        setErrSucType(false)
      })
  }

  const digitalPublisherHandler = async (e, type) => {
    if (type === "file") {
      const images = Object.keys(e.target.files).map(item => {
        return e.target.files[item]
      })
      const files = e.target.files
      const imagePreviews = [];
      const name = e.target.name

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();

        reader.onload = function(event) {
          const dataURL = event.target.result;
          imagePreviews.push(dataURL);
          let d = {}
          d[`${name}`] = imagePreviews
          if(!old_saved_images.includes(d)){
            setPreviewImg([...previewImg,d])
          }
        };
        reader.readAsDataURL(file);
      }
      setDigitalPublisher({...digitalPublisher, [e.target.name]: {...digitalPublisher[e.target.name], "images": images }})
    } else {
      if(editPublisher){
        setEditPublisher({...editPublisher, [e.target.name]: {...editPublisher[e.target.name], "value": e.target.value}})
      }else{
        setDigitalPublisher({...digitalPublisher, [e.target.name]: {...digitalPublisher[e.target.name], "value": e.target.value}})
      }
    }
  }
  
  const interactiveMapHandler = (e) => {
    setInteractiveMap({...interactiveMap, [e.target.name]: e.target.value})
  }

  const deletePreviewImage = (demo, index) => {
    const d = {...editPublisher}
    d[`${demo}`].images.splice(index, 1)
    setEditPublisher(d)
  }

  // const replaceKeyHandler = async (e, key) => {
  //   const newKey = e.target.value
  //   const oldKey = key

  //   await axios.post(``, {data:data},{})
  //   .then
  // }

  return (
    <div>
      <NewAlert
        msg={ErrSucMsg}
        isSuccess={ErrSucType}
        close={() => setErrSucMesg("")}
      />
      <h2 style={{ color: "#4F74C9", fontWeight: "400" }}>
        Interactive Map and Digital Publisher Update
      </h2>
      <div style={{ display: "flex" }}>
        <Button
          onClick={() => setOpen(!open)}
          className={classes.addActionBtn}
          variant="outlined"
          color="primary"
          style={{ width: "20%", borderRadius: "50px", marginRight: "0.5em" }}
        >
          <AddCircleOutlineIcon
            style={{ marginRight: "5px", color: "white" }}
          />
          Add Country Map
        </Button>
        <Button
          onClick={() => {
            setOpenPublisher(true)
            let data = []

            countryDetails.forEach((item) => {
              data.push(item)
            })
            setFields(data)
            setCountryDetails(data)

          }}
          className={classes.addActionBtn}
          variant="outlined"
          color="primary"
          style={{ width: "20%", borderRadius: "50px" }}
        >
          <AddCircleOutlineIcon
            style={{ marginRight: "5px", color: "white", marginRight: "0.5em" }}
          />
          Add Digital Publication
        </Button>
      </div>
      <div className={classes.boxShadow} style={{ marginTop: "40px", maxWidth: "79vw" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <span
            style={{
              color: "#FB6D6A",
              fontWeight: "600",
              margin: "0",
              fontSize: "20px",
              marginLeft: "20px",
            }}
          >
            Interactive Map
          </span>
          <span
            style={{
              color: "#FB6D6A",
              fontWeight: "600",
              margin: "0",
              fontSize: "20px",
              marginRight: "20px",
            }}
          >
            <Pagination
              count={Math.ceil(listPageCount / 10)}
              color="primary"
              page={listPage}
              onChange={(e, page) => setListPage(page)}
              size="small"
            />
          </span>
        </div>
        {listTableData?.length > 0 && !tableLoading && (
          <TableContainer
            component={Paper}
            style={{ marginBottom: "20px", maxHeight: "400px" }}
          >
            <Table stickyHeader className={classesTable.table} aria-label="customized table">
              <TableHead>
                <TableRow
                  style={{
                    background: "linear-gradient(to right, #9211a2, #4911a2)"
                  }}
                >
                  {headerTable.map((item) => (
                    <TableCell style={{ color: "white", background: "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))"}}>{item}</TableCell>
                  ))}
                  <StyledTableCell style={{ color: "white", background: "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))"}}></StyledTableCell>
                  <StyledTableCell style={{ color: "white", background: "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))"}}></StyledTableCell>
                </TableRow>
              </TableHead>
              {listTableData.length <= 0 ? (
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell> No records found.</StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {listDATA.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {headerTable.map((data) => (
                        <>
                          <TableCell component="th" scope="row">
                            {row[`${data}`] ? row[`${data}`] : "-"}
                          </TableCell>
                        </>
                      ))}
                      <StyledTableCell width="20px">
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          <EditIcon
                            fontSize="small"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setOpen(!open)
                              const newInteractiveMap = interactiveMap
                              for(const demographic in newInteractiveMap){
                                newInteractiveMap[demographic] = row[demographic]
                              }
                              setInteractiveMap(newInteractiveMap)
                              setEditMap(row)
                            }}
                          />
                        </span>
                      </StyledTableCell>
                      <StyledTableCell width="20px">
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            color: "#F64336",
                          }}
                        >
                          <DeleteForeverIcon
                            style={{ cursor: "pointer" }}
                            fontSize="small"
                            onClick={() => {
                              deleteMapHandler(row._id)
                            }}
                          />
                        </span>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        )}
        <div style={{ display: "flex", justifyContent: "center" }}>
          {tableLoading && <CircularProgress />}
        </div>
      </div>
      {/* digital publisher table start */}
      <div className={classes.boxShadow} style={{ marginTop: "40px", maxWidth: "79vw" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <span
            style={{
              color: "#FB6D6A",
              fontWeight: "600",
              margin: "0",
              fontSize: "20px",
              marginLeft: "20px",
            }}
          >
            Digital Publisher
          </span>
          <span
            style={{
              color: "#FB6D6A",
              fontWeight: "600",
              margin: "0",
              fontSize: "20px",
              marginRight: "20px",
            }}
          >
            <Pagination
              count={Math.ceil(listPageCount / 10)}
              color="primary"
              page={listPage}
              onChange={(e, page) => setListPage(page)}
              size="small"
            />
          </span>
        </div>
        {listPublisherTableData?.length > 0 && !tableLoading && (
          <TableContainer
            component={Paper}
            style={{ marginBottom: "20px", maxHeight: "400px" }}
          >
            <Table stickyHeader className={classesTable.table} aria-label="customized table">
              <TableHead>
                <TableRow
                  style={{
                    background: "linear-gradient(to right, #9211a2, #4911a2)",
                  }}
                >
                  {publisherHeaderTable.map((item) => (
                    <TableCell style={{ color: "white", background: "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))"}}>{item}</TableCell>
                  ))}
                  <StyledTableCell style={{ color: "white", background: "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))"}}></StyledTableCell>
                  <StyledTableCell style={{ color: "white", background: "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))"}}></StyledTableCell>
                </TableRow>
              </TableHead>
              {listPublisherTableData.length <= 0 ? (
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell> No records found.</StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {listPublisherDATA.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {publisherHeaderTable.map((data) => (
                        <>
                          <TableCell component="th" scope="row">
                            {
                              data === "country" ? 
                                row[`${data}`] ? row[`${data}`] : "-" 
                                : 
                                row[`${data}`] ? row[`${data}`].value ?? "-" : "-"
                            }
                          </TableCell>
                        </>
                      ))}
                      <StyledTableCell width="20px">
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          <EditIcon
                            fontSize="small"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setOpenPublisher(true)
                              const newDigitalPublisher = digitalPublisher
                              for(const demographic in newDigitalPublisher){
                                newDigitalPublisher[demographic] = row[demographic]
                              }
                              setDigitalPublisher(newDigitalPublisher)
                              setEditPublisher(row)
                            }}
                          />
                        </span>
                      </StyledTableCell>
                      <StyledTableCell width="20px">
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            color: "#F64336",
                          }}
                        >
                          <DeleteForeverIcon
                            style={{ cursor: "pointer" }}
                            fontSize="small"
                            onClick={() => {
                              deletePublisherHandler(row._id)
                            }}
                          />
                        </span>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        )}
        <div style={{ display: "flex", justifyContent: "center" }}>
          {tableLoading && <CircularProgress />}
        </div>
      </div>
      {/* digital publisher table end */}
      <Modal
        open={open}
        onClose={() => {
          setOpen(!open)
          setEditMap(null)
          setCountryDetails([
            {
              id: Date.now(),
              key: null,
              value: null,
            },
          ])
          setFields([])
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card
          style={{
            padding: "2.5em",
            border: "none",
            color: "#fff",
            width: "70%",
            height: "70vh",
            overflowY: "auto",
          }}
        >
          <span
            style={{
              color: "#FB6D6A",
              fontWeight: "600",
              margin: "0",
              fontSize: "20px",
              marginLeft: "20px",
            }}
          >
            {editMap ? "Edit Country Map" : "Add Country Map"}
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ flex: "1" }}>
              <FormControl
                sx={{ m: 1, minWidth: 120 }}
                style={{ width: "80%", marginTop: "1rem" }}
              >
                <InputLabel id="demo-select-small">Country</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  variant="outlined"
                  defaultValue={editMap ? editMap.Country : country}
                  label="Country"
                  onChange={(e) => setCountry(e.target.value)}
                  disabled={editMap ? true : false}
                >
                  {dropData?.mapCountry?.map((item, index) => (
                    <MenuItem value={item} key={index}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div
              style={{ flex: "2", display: "flex", flexDirection: "column" }}
            >
              {/* Default textField for impressions start */}
              {!editMap && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "0.3em",
                  }}
                >
                  <TextField
                    defaultValue="Impression"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="name"
                    id="name"
                    label="Key"
                    style={{ marginRight: "0.3rem" }}
                    disabled
                  />
                  <TextField
                    type="number"
                    defaultValue=""
                    onChange={(e) => countryDetailsHandler(e, "map")}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="values"
                    id="values"
                    label="Value"
                    style={{ marginRight: "0 0.3rem" }}
                    placeholder="100000000"
                  />
                  <div>
                    <span style={{ visibility: "hidden" }}>
                      <RemoveCircleOutline
                        style={{
                          marginRight: "5px",
                          color: "red",
                          cursor: "pointer",
                        }}
                      />
                    </span>
                  </div>
                </div>
              )}
              {/* Default textfield for impressions end */}
              {fields.map((item, index) =>
                !editMap ? (
                  index !== 0 ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginRight: "0.3em",
                      }}
                      key={item.id}
                    >
                      <TextField
                        defaultValue={editMap ? item.key : ""}
                        onChange={(e) => countryDetailsHandler(e, item.id)}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        name="name"
                        id="name"
                        label="Key"
                        style={{ marginRight: "0.3rem" }}
                        placeholder="Impression"
                        disabled={item.key === "Impression" ? true : false}
                      />
                      <TextField
                        defaultValue={editMap ? item.value : ""}
                        onChange={(e) => countryDetailsHandler(e, item.id)}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        name="values"
                        id="values"
                        label="Value"
                        style={{ marginRight: "0 0.3rem" }}
                        placeholder="100,000,000"
                      />
                      <div
                        style={{
                          visibility: item.key === "Impression" ? "hidden" : "",
                        }}
                      >
                        <span onClick={() => RemoveFieldHandler(item.id)}>
                          <RemoveCircleOutline
                            style={{
                              marginRight: "5px",
                              color: "red",
                              cursor: "pointer",
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginRight: "0.3em",
                    }}
                    key={item.id}
                  >
                    <TextField
                      defaultValue={editMap ? item.key : ""}
                      onChange={(e) => countryDetailsHandler(e, item.id)}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="name"
                      id="name"
                      label="Key"
                      style={{ marginRight: "0.3rem" }}
                      placeholder="Impression"
                      disabled={item.key === "Impression" ? true : false}
                    />
                    <TextField
                      defaultValue={editMap ? item.value : ""}
                      onChange={(e) => countryDetailsHandler(e, item.id)}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="values"
                      id="values"
                      label="Value"
                      style={{ marginRight: "0 0.3rem" }}
                      placeholder="100,000,000"
                    />
                    <div
                      style={{
                        visibility: item.key === "Impression" ? "hidden" : "",
                      }}
                    >
                      <span onClick={() => RemoveFieldHandler(item.id)}>
                        <RemoveCircleOutline
                          style={{
                            marginRight: "5px",
                            color: "red",
                            cursor: "pointer",
                          }}
                        />
                      </span>
                    </div>
                  </div>
                )
              )}
              <div>
                <span onClick={AddFieldHandler}>
                  <AddCircleOutlineIcon
                    style={{
                      marginRight: "5px",
                      color: "blue",
                      cursor: "pointer",
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
          <Button
            variant="contained"
            style={{
              color: "black",
              backgroundColor: "grey",
              marginTop: "0.5rem",
              marginRight: "0.3rem",
            }}
            onClick={() => {
              setOpen(!open)
              setFields([])
              setEditMap(null)
              setCountryDetails([
                {
                  id: Date.now(),
                  key: null,
                  value: null,
                },
              ])
            }}
          >
            Cancel
          </Button>
          {editMap ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => submitUpdateMap(editMap._id)}
              style={{ marginTop: "0.5rem" }}
            >
              Update
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={submitCountryMap}
              style={{ marginTop: "0.5rem" }}
            >
              Add
            </Button>
          )}
        </Card>
      </Modal>

      <Modal
        open={openPublisher}
        onClose={() => {
          setOpenPublisher(false)
          setEditPublisher(null)
          setCountryDetails([
            {
              id: Date.now(),
              key: null,
              value: null,
            },
          ])
          setFields([])
          setPreviewImg([])
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card
          style={{
            padding: "2.5em",
            border: "none",
            color: "#fff",
            width: "70%",
            height: "70vh",
            overflowY: "auto",
          }}
        >
          <span
            style={{
              color: "#FB6D6A",
              fontWeight: "600",
              margin: "0",
              fontSize: "20px",
              marginLeft: "20px",
            }}
          >
            {editPublisher
              ? "Edit Digital Publication"
              : "Add Digital Publication"}
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ flex: "1" }}>
              <FormControl
                sx={{ m: 1, minWidth: 120 }}
                style={{ width: "80%", marginTop: "1rem" }}
              >
                <InputLabel id="demo-select-small">Country</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  variant="outlined"
                  defaultValue={editPublisher?.country || ""}
                  label="Country"
                  onChange={(e) => setCountry(e.target.value)}
                  disabled={editPublisher ? true : false}
                >
                  {dropData?.mapCountry?.map((item, index) => (
                    <MenuItem value={item} key={index}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div
              style={{ flex: "2", display: "flex", flexDirection: "column" }}
            >
              {
                digitalPublisherDemographics?.map(item =>
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginRight: "0.3em",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        name="name"
                        id="name"
                        defaultValue={item}
                        style={{ marginRight: "0.3rem" }}
                        disabled
                      />
                      <TextField
                        type="text"
                        defaultValue={ editPublisher ? editPublisher[`${item}`]?.value ?? "-" : ""}
                        onChange={(e) => {
                          // if(editPublisher){
                          //   editPublisherHandler(e, "value")
                          // }else{
                          // }
                          digitalPublisherHandler(e, "value")
                        }}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        name={item}
                        id={item}
                        label="No. of publisher"
                        style={{ marginRight: "0.3rem" }}
                        placeholder="6"
                      />
                      <TextField
                        type="file"
                        inputProps={{ multiple: true }}
                        // defaultValue={ editPublisher ? editPublisher[`${item}`]?.images ?? "-" : ""}
                        onChange={(e) => {
                          // if(editPublisher){
                          //   editPublisherHandler(e, "file")
                          // }else{
                          // }
                          digitalPublisherHandler(e, "file")
                        }}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        name={item}
                        // id={item}
                        // label="No. of publisher"
                        style={{ marginRight: "0 0.3rem" }}
                        placeholder="6"
                      />
                    </div>
                    <div className={classesPreview.previewWrapper}>
                      {
                        editPublisher && editPublisher[`${item}`]?.images && editPublisher[`${item}`]?.images.map((itemImg, index) =>
                          <>
                            <img src={itemImg} width="50px" height="50px" style={{marginRight: "0.5rem"}}/>
                            <HighlightOffIcon
                              style={{ cursor: "pointer", position: "relative", cursor: "pointer", right: "15px", color: "red" }}
                              fontSize="small"
                              onClick={() => {
                                deletePreviewImage(item, index)
                              }}
                            />
                          </>
                        )
                      }
                    </div>
                    <div className={classesPreview.previewWrapper}>
                      {
                        previewImg && previewImg.map((img, index) =>
                        img[item] && img[item].map((k, ind) => 
                           { 
                            return<>
                              <img key={ind} src={k} width="50px" height="50px" style={{marginRight: "0.5rem"}}/>
                              <HighlightOffIcon
                                style={{ cursor: "pointer", position: "relative", cursor: "pointer", right: "15px", color: "red" }}
                                fontSize="small"
                                onClick={() => {
                                  let images = [...previewImg]
                                  images[index][item].splice(ind, 1)
                                  setPreviewImg(images)
                                  let d = digitalPublisher
                                  d[item].images.splice(ind, 1)
                                  setDigitalPublisher(d)
                                }}
                              />
                            </>
                            }
                          )
                        )
                      }
                    </div>
                  </>
                )
              } 
              {/* {fields.map((item, index) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "0.3em",
                  }}
                  key={item.id}
                >
                  <TextField
                    defaultValue={editPublisher ? item.key : ""}
                    onChange={(e) => countryDetailsHandler(e, item.id)}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="name"
                    id="name"
                    label="Demographic"
                    style={{ marginRight: "0.3rem" }}
                    placeholder="Black"
                  />
                  <TextField
                    type="number"
                    defaultValue={editPublisher ? item.value : ""}
                    onChange={(e) => countryDetailsHandler(e, item.id)}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="values"
                    id="values"
                    label="No. of publisher"
                    style={{ marginRight: "0 0.3rem" }}
                    placeholder="6"
                  />
                  {index !== 0 ? (
                    <div>
                      <span onClick={() => RemoveFieldHandler(item.id)}>
                        <RemoveCircleOutline
                          style={{
                            marginRight: "5px",
                            color: "red",
                            cursor: "pointer",
                          }}
                        />
                      </span>
                    </div>
                  ) : (
                    <div>
                      <span style={{ visibility: "hidden" }}>
                        <RemoveCircleOutline
                          style={{
                            marginRight: "5px",
                            color: "red",
                            cursor: "pointer",
                          }}
                        />
                      </span>
                    </div>
                  )}
                </div>
              ))}
              <div>
                <span onClick={AddFieldHandler}>
                  <AddCircleOutlineIcon
                    style={{
                      marginRight: "5px",
                      color: "blue",
                      cursor: "pointer",
                    }}
                  />
                </span>
              </div> */}
            </div>
          </div>
          <Button
            variant="contained"
            style={{
              color: "black",
              backgroundColor: "grey",
              marginTop: "0.5rem",
              marginRight: "0.3rem",
            }}
            onClick={() => {
              setOpenPublisher(!openPublisher)
              setFields([])
              setEditPublisher(null)
              setCountryDetails([
                {
                  id: Date.now(),
                  key: null,
                  value: null,
                },
              ])
              setFields([])
              setPreviewImg([])
            }}
          >
            Cancel
          </Button>
          {editPublisher ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => submitUpdatePublisher(editPublisher._id)}
              style={{ marginTop: "0.5rem" }}
            >
              Update
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={submitPublisher}
              style={{ marginTop: "0.5rem" }}
            >
              Add
            </Button>
          )}
        </Card>
      </Modal>
    </div>
  )
}

export default MainLayout(InteractiveMapUpdate)
