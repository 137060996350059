import { Box, Button, CardContent, Grid, TextField } from "@material-ui/core/";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import axios from "axios";
import React, { useEffect, useState } from "react";
import CustomDropdown from "../../components/CustomDropdown";
import NewAlert from "../../components/NewAlert";
import useStyles from "../../components/StyleClasses";
import { API, SECRET_KEY } from "../../constants/index";
import MainLayout from "../../layouts/consultantMainLayout";
import "./consultant.css";

const ConsultantProfile = () => {
  const classes = useStyles();
  const [loading, setloading] = useState(false);
  const [name, setName] = useState("");
  const [country, setCountry] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [demographic, setDemographic] = useState([]);
  const [demographicList, setDemographicList] = useState([]);
  const [age, setAge] = useState([]);
  const [email, setEmail] = useState('');
  const [username, setUserName] = useState('');
  const [ageGroup, setAgeGroup] = useState([]);
  const [gender, setGender] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [avatarData, setAvatarData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [openAvatarDropdown, setOpenAvatarDropdown] = useState(false);
  const [genderList, setGenderList] = useState([]);
  const [sexualOrientation, setSexualOrientation] = useState([]);
  const [selectedSexuality, setSelectedSexuality] = useState([]);

  ///////errors & successs //////////
  const [ErrSucMsg, setErrSucMesg] = useState("");
  const [ErrSucType, setErrSucType] = useState(true);
  ///////////////////////////////////

  const access_token = window.localStorage.getItem(SECRET_KEY);
  
  const getProfileData = () => {
    setloading(true);
    const id = window.localStorage.getItem('ID');
    axios
      .get(`${API}/creativeconsultant/${id}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res) {
          setName(res.data.name);
          setEmail(res.data.name);
          setUserName(res.data.name);
          setGender({label: res.data.gender, value: res.data.gender});
          setAvatar({label: res.data.avatar, value: res.data.avatar});
          setAge({label: res.data.ageGroup.name, value: res.data.ageGroup._id, _id: res.data.ageGroup._id});
          setDemographic({label: res.data.demographic.name, value: res.data.demographic._id, _id: res.data.demographic._id});
          setCountry({label: res.data.country.name, value: res.data.country._id, _id: res.data.country._id});
          setSelectedSexuality({ label: res.data.sexualOrientation, value: res.data.sexualOrientation });
          setloading(false);
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };

  const getDropDownData = () => {
    axios
      .get(`${API}/creativeconsultant/dropdowns`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res) {
          setCountryList(res.data.countries);
          setDemographicList(res.data?.demographics);
          setAgeGroup(res.data?.ageGroups);
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };

  useEffect(() => {
    getDropDownData();
    getSexualOrientationData();
    getAvatar();
    getProfileData();
  }, []);

  const submit = () => {
    if (
      name == "" ||
      country.length == 0 ||
      demographic.length == 0 ||
      age.length == 0
    ) {
      setErrSucMesg("Please fill required fields!");
      setErrSucType(false);
      return;
    }
    setloading(true);
    const id = window.localStorage.getItem("ID");
    let body = {
      name,
      demographic: demographic._id,
      country: country._id,
      ageGroup: age._id,
      avatar: avatar?.value,
      gender: gender?.value,
      email,
      username,
      sexualOrientation: selectedSexuality?.value 
    };

    axios
      .put(`${API}/creativeconsultant/${id}`, body, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res.data) {
          setErrSucMesg(res.data.message);
          setErrSucType(true);
          setloading(false);
          setIsEdit(false);
        }
      })
      .catch((error) => {
        if (error && error.response) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
          setloading(false);
          setIsEdit(false);
        }
      });
  };

  const getAvatar = () => {
    const avatarArr = [];
    for (let i = 1; i <= 100; i++) {
      const avatar = require(`../../assets/avatars/${i}.svg`);
      avatarArr.push({
        label: `Avatar${i}`,
        value: avatar,
      });
    }
    setAvatarData(avatarArr);
  };

  const getSexualOrientationData = () => {
    setloading(true);
    axios
      .get(`${API}/creativeconsultant/getCreativeDropdown`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        setloading(false);
        if(res && res.data){
          let ar = [];
          let gen = [];
          res.data.sexuality.map(i =>{
            ar.push({ label: i, value: i })
          });
          res.data.gender.map(i =>{
            gen.push({ label: i, value: i })
          });
          setSexualOrientation(ar);
          setGenderList(gen);
        }
      })
      .catch(function (error) {
        if (error) {
          setloading(false);
        }
      });
};

  return (
    <div>
      <NewAlert
        msg={ErrSucMsg}
        isSuccess={ErrSucType}
        close={() => setErrSucMesg("")}
      />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
       <h3> Profile</h3>
      </div>
      <div className={classes.boxShadow} style={{ marginTop: "10px" }}>
        <Grid item xs={12}>
          <Box className={classes.cardBox}>
            <CardContent>
              <div
                style={{
                  display: "flex",
                  // marginTop: "40px",
                  justifyContent: "space-between",
                }}
              >
                <Grid item xs={4} style={{ position: "relative" }}>
                  {/* <label>Avatar</label> */}
                  <img
                    src={
                      avatar?.value
                        ? avatar.value
                        : "/static/media/1.f5b62dce.svg"
                    }
                    width="60"
                    height="60"
                    style={{ borderRadius: "50%", cursor: "pointer" }}
                    onClick={() => {
                      if (isEdit) setOpenAvatarDropdown(true);
                    }}
                  />
                  {/* {openAvatarDropdown && (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        width: "150px",
                      }}
                    >
                      <CustomDropdown
                        isSearchable={true}
                        containerClassName="mt-3"
                        onChange={async (e) => {
                          setAvatar(e);
                          setOpenAvatarDropdown(false);
                        }}
                        placeholder="Avatars"
                        options={avatarData}
                        isClearable={true}
                        imageData={avatarData}
                      />
                    </div>
                  )} */}
                </Grid>
                <Grid item>
                  {!isEdit ? (
                    <Button
                      onClick={() => setIsEdit(true)}
                      className={classes.addActionBtn}
                      variant="outlined"
                      color="white"
                      style={{ width: "100px", borderRadius: "50px", backgroundColor: '#5e11a2' }}
                    >
                      Edit
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        setIsEdit(false);
                        setOpenAvatarDropdown(false);
                      }}
                      className={classes.addActionBtn}
                      variant="outlined"
                      color="white"
                      style={{ width: "100px", borderRadius: "50px", backgroundColor: '#5e11a2' }}
                    >
                      Cancel
                    </Button>
                  )}
                </Grid>
              </div>

              <Grid
                container
                spacing={2}
                style={{ marginTop: "20px" }}
                className={classes.cardspace}
              >
                <Grid item md={6} sm={6} xs={6} style={{ marginTop: "0!important" }}>
                <label className="labelcolor">Name</label>
                  <TextField
                    className={classes.customfield}
                    onChange={(e) => setName(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    disabled={!isEdit}
                    id="search"
                    label=""
                    value={name}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={6}>
                <label className="labelcolor">Demographic</label>
                  <CustomDropdown
                    // isMulti={true}
                    isSearchable={true}
                    onChange={async (e) => {
                      setDemographic(e);
                    }}
                    value={demographic}
                    isDisabled={!isEdit}
                    placeholder="Select..."
                    options={demographicList.map((i) => {
                      i.label = i.name;
                      i.value = i.name;
                      return i;
                    })}
                    isClearable={true}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={6}>
                <label className="labelcolor">Country</label>
                  <CustomDropdown
                    // isMulti={true}
                    isSearchable={true}
                    onChange={async (e) => {
                      setCountry(e);
                    }}
                    value={country}
                    isDisabled={!isEdit}
                    placeholder="Select..."
                    options={countryList.map((i) => {
                      i.label = i.name;
                      i.value = i.name;
                      return i;
                    })}
                    isClearable={true}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={6}>
                <label className="labelcolor">Age</label>
                  <CustomDropdown
                    isSearchable={true}
                    onChange={async (e) => {
                      setAge(e);
                    }}
                    value={age}
                    isDisabled={!isEdit}
                    placeholder="Select..."
                    options={ageGroup.map((i) => {
                      i.label = i.name;
                      i.value = i.name;
                      return i;
                    })}
                    isClearable={true}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={6}>
                <label className="labelcolor">Gender</label>
                  <CustomDropdown
                    isSearchable={true}
                    onChange={async (e) => {
                      setGender(e);
                    }}
                    value={gender}
                    placeholder="Select..."
                    isDisabled={!isEdit}
                    options={genderList}
                    isClearable={true}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={6}>
                <label className="labelcolor">Sexual Orientation</label>
                  <CustomDropdown
                    isSearchable={true}
                    onChange={async (e) => {
                      setSelectedSexuality(e);
                    }}
                    value={selectedSexuality}
                    placeholder="Select..."
                    isDisabled={!isEdit}
                    options={sexualOrientation}
                    isClearable={true}
                  />
                </Grid>
              </Grid>
              {isEdit ? (
                <div
                  style={{
                    display: "flex",
                    marginTop: "40px",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid item xs={4}></Grid>
                  <Button
                    onClick={() => submit()}
                    className={classes.addActionBtn}
                    variant="outlined"
                    color="white"
                    style={{ width: "100px", borderRadius: "50px", backgroundColor: '#5e11a2' }}
                  >
                    Submit
                  </Button>
                </div>
              ) : null}
            </CardContent>
          </Box>
        </Grid>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {loading && <CircularProgress />}
        </div>
      </div>
      <Modal
        open={openAvatarDropdown}
        onClose={() => setOpenAvatarDropdown(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openAvatarDropdown}>
          <div className={classes.boxShadow}>
            <div
              className={classes.customfield}
              style={{
                width: "47%",
                // padding: "50px",
                // display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                position: "absolute",
                left: "32%",
                marginTop: "7%",
                height: "400px",
                overflow: "auto",
              }}
            >
              <Grid
                container
                spacing={2}
                style={{ marginTop: "20px" }}
                className={classes.cardspace}
              >
                {avatarData &&
                  avatarData.map((i) => (
                    <Grid item md={4} sm={4} xs={4} className="mt-3">
                      <img style={{cursor: 'pointer', width:"100%", height:"100%"}} src={i.value} onClick={()=>{
                         setAvatar(i);
                         setOpenAvatarDropdown(false)
                      }}/>
                    </Grid>
                  ))}
              </Grid>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
export default MainLayout(ConsultantProfile);
