export const ERROR = 'ERROR';
export const CREATE_PERSONA = 'CREATE_PERSONA';
export const PERSONA_SAVED = 'PERSONA_SAVED';
export const GET_PERSONAS = 'GET_PERSONAS';
export const PERSONAS_RETRIEVED = 'PERSONAS_RETRIEVED';
export const PERSONA_SUCCESS = 'PERSONA_SUCCESS';

export function createPersona(persona) {
  return {
    type: CREATE_PERSONA,
    persona,
  };
}

export function personaSaved(personaSaved) {
  return {
    type: PERSONA_SAVED,
    personaSaved,
  };
}

export function getPersonas(userId) {
  return {
    type: GET_PERSONAS,
    userId
  };
}

export function personasRetrieved(personas) {
  return {
    type: PERSONAS_RETRIEVED,
    personas
  };
}

export function error(error) {
  return {
    type: ERROR,
    error
  };
}
