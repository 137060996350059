import React, { useState, useEffect } from "react";
import MainLayout from "../../layouts/companyMainLayout";

import Pagination from "@material-ui/lab/Pagination";

import CircularProgress from "@material-ui/core/CircularProgress";
//Button
import Button from "@material-ui/core/Button";

//Icon

import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { useHistory } from "react-router-dom";
import useStyles from "../../components/StyleClasses";
import CustomizedTables from "../mediaOwner/mytable";
import { API, SECRET_KEY } from "../../constants/index";
import axios from "axios";

const Brand = () => {
  const classes = useStyles();
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [DATA, setDATA] = useState(null);
  const [loading, setloading] = useState(false);
  const [page, setpage] = useState(1);
  const [search, setSearch] = useState("");
  const access_token = window.localStorage.getItem(SECRET_KEY);
  const [pagecount, setPageCount] = useState(0);

  const getBrandList = () => {
    setloading(true);
    setTableData([]);
    axios
      .get(`${API}/admin/admin-brand?page=${page}&name=${search}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setloading(false);
          setDATA(res.data.data);
          setPageCount(res.data.total);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };
  useEffect(() => getBrandList(), []);
  useEffect(() => getBrandList(), [page]);

  var myVar;

  function myFunction() {
    myVar = setTimeout(function () {
      getBrandList();
    }, 200);
  }

  function myStopFunction() {
    clearTimeout(myVar);
  }
  useEffect(() => myFunction(), [search]);
  useEffect(() => {
    if (DATA) {
      let td = [];
      DATA.forEach((e) => {
        td.push({
          data: [
            e.email,
            e.BrandName,
          ],
          _id: e._id,
        });
      });
      setTableData(td);
    }
  }, [DATA]);

  return (
    <div>
      <h2 style={{ color: "#4F74C9", fontWeight: "400" }}>Brand</h2>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          onClick={() => history.push("/company/createBrand")}
          className={classes.addActionBtn}
          variant="outlined"
          color="primary"
          style={{ width: "20%", borderRadius: "50px" }}
        >
          <AddCircleOutlineIcon
            style={{ marginRight: "5px", color: "white" }}
          />
          Create Brand
        </Button>
        <div
          className={classes.addActionBtn}
          style={{
            backgroundColor: "white",
            paddingRight: "7px",
            paddingLeft: "7px",
            width: "82%",
            borderRadius: "50px",
            boxShadow:
              "0px 0px 10px rgba(0,0,0,0.20), 0 0 10px rgba(0,0,0,0.15)",
          }}
        >
          <input
            placeholder="Search Brand"
            //className={classes.addActionBtn}
            style={{
              outline: "none",
              backgroundColor: "white",
              border: "solid 2px #E5E5E5",
              color: "black",
              borderRadius: "23px",
              padding: "7px",
              width: "50%",
            }}
            onChange={(event) => {
              myStopFunction();
              setSearch(event.target.value);
            }}
          ></input>
        </div>
      </div>
      <div className={classes.boxShadow} style={{ marginTop: "40px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <span
            style={{
              color: "#FB6D6A",
              fontWeight: "600",
              margin: "0",
              fontSize: "20px",
              marginLeft: "20px",
            }}
          >
            Brand
          </span>
          <span
            style={{
              color: "#FB6D6A",
              fontWeight: "600",
              margin: "0",
              fontSize: "20px",
              marginRight: "20px",
            }}
          >
            <Pagination
              count={Math.ceil(pagecount / 10)}
              color="primary"
              onChange={(e, page) => setpage(page)}
              size="small"
            />
          </span>
        </div>
        <CustomizedTables
          isEditable={true}
          headings={[
            "Brand Email",
            "Brand Name",
          ]}
          listdata={tableData}
          redirectLink={"createBrand?id="}
          handleDelete={(_id) => {
            const removed = tableData.filter((e) => e._id !== _id);
            setTableData(removed);
          }}
        />
        <div style={{ display: "flex", justifyContent: "center" }}>
          {loading && <CircularProgress />}
        </div>
      </div>
    </div>
  );
};
export default MainLayout(Brand);
