import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    Modal,
    Radio,
    RadioGroup,
    Typography
} from "@material-ui/core"
import Close from "@material-ui/icons/Close"
import React, { useEffect, useState } from "react"
import Selection from "../../../../../../components/Select/Selection"
import { useChannelAssignmentPageCtx } from "../../../ChannelAssignmentPage"
import BrowserOsSection, { BrowserOsSectionModal } from "../sections/Targeting/BrowserOsSection"
import DataTargetSection from "../sections/Targeting/DataTargetSection"
import GeographicTargetAddModal from "../sections/Targeting/GeographicTargetAdd.modal"
import "../sections/Targeting/targeting.css"

export default function TargetingSection({ handleStatesChanges, states, setStates }) {
    const { geoGraphicListData, targeting__keywords_select } = useChannelAssignmentPageCtx()
    const mappedGeoGraphicListData = geoGraphicListData?.map((item) => ({ label: item.name, value: item.id }))
    const initialModalStates = {
        geograhpic_modal: false,
        postal_code_modal: false,
        browser_os_modal: false,
    }
    const [modals, setModals] = useState({ ...initialModalStates })

    const handleModalButtonClicks = (key) => {
        setModals((modals) => ({ ...modals, [key]: !modals[key] }))
    }

    // for merging the mulitple selected keywords category to show in the textarea for Keywords section
    useEffect(() => {
        handleStatesChanges(
            "keywords",
            states.targeting__keywords__select_value?.reduce?.(
                (acc, val) => (acc = acc ? acc + "\n" + val.keywords : acc + val.keywords),
                ""
            )
        )
    }, [states.targeting__keywords__select_value])

    return (
        <>
            <div style={{ marginTop: "20px" }} className="form-section-heading">
                <Typography>Targeting</Typography>
            </div>
            <div className="flex flex-col gap-15">
                {/* Geographic */}
                <Grid container className="flex algin-center" style={{ gap: "40px", paddingInline: "10px" }}>
                    <Grid item style={{ width: "20%" }}>
                        <label>Geographic</label>
                    </Grid>
                    <Grid item style={{ width: "30%" }}>
                        <Selection
                            options={[
                                { label: "No geo target", value: "default value" },
                                ...(mappedGeoGraphicListData ? mappedGeoGraphicListData : []),
                            ]}
                            value={states.selected_geo_graphic}
                            defaultValue={states.selected_geo_graphic}
                            onChange={(e) => handleStatesChanges("selected_geo_graphic", e)}
                            isClearable={false}
                            style={{ margin: 0 }}
                        />
                    </Grid>
                    <Grid item className="flex align-center">
                        <Button
                            variant="outlined"
                            style={{ height: "38px", background: "purple", color: "white" }}
                            onClick={() => handleModalButtonClicks("geograhpic_modal")}
                        >
                            <span style={{ marginRight: "5px" }}>+</span>
                            <span>Add Geographic Target</span>
                        </Button>
                    </Grid>
                </Grid>

                {/* <Grid container className="flex algin-center" style={{ gap: "40px", paddingInline: "10px" }}>
                <Grid item style={{ width: "20%" }}>
                    <label>Postal Code</label>
                </Grid>
                <Grid item style={{ width: "30%" }}>
                    <Selection options={[{ label: "a", value: "a" }]} isClearable={false} />
                </Grid>
                <Grid item className="flex align-center">
                    <Button
                        variant="outlined"
                        style={{ height: "38px", background: "purple", color: "white" }}
                        onClick={() => handleModalButtonClicks("postal_code_modal")}
                    >
                        <span style={{ marginRight: "5px" }}>+</span>
                        <span>Add Postal Code Target</span>
                    </Button>
                </Grid>
            </Grid> */}

                {/* Browser & Os */}
                <BrowserOsSection
                    handleModalButtonClicks={handleModalButtonClicks}
                    handleStatesChanges={handleStatesChanges}
                    states={states}
                />
                {/* <Grid container className="flex algin-center" style={{ gap: "40px", paddingInline: "10px" }}>
                    <Grid item style={{ width: "20%" }}>
                        <label>Browser & OS</label>
                    </Grid>
                    <Grid item style={{ width: "30%" }}>
                        <Selection
                            options={[
                                { label: "No platform target", value: "" },
                                ...(platformTargetList ? platformTargetList.map((target) => ({
                                    ...target,
                                    label: target.name,
                                    value: target.id,
                                })) : []),
                            ]}
                            value={states.platform_target}
                            onChange={(e) => handleStatesChanges("platform_target",e)}
                            isClearable={false}
                        />
                    </Grid>
                    <Grid item className="flex align-center">
                        <Button
                            variant="outlined"
                            style={{ height: "38px", background: "purple", color: "white" }}
                            onClick={() => handleModalButtonClicks("browser_os_modal")}
                        >
                            <span style={{ marginRight: "5px" }}>+</span>
                            <span>Add Platform Target</span>
                        </Button>
                    </Grid>
                </Grid> */}

                {/* Keywords */}
                <Grid container className="flex algin-center" style={{ gap: "40px", paddingInline: "10px" }}>
                    <Grid item style={{ width: "20%" }}>
                        <label>Keywords</label>
                    </Grid>
                    <Grid item style={{ width: "30%" }}>
                        <textarea
                            placeholder=""
                            style={{ width: "100%", padding: "5px" }}
                            value={states.keywords}
                            onChange={(e) => handleStatesChanges("keywords", e.target.value)}
                            rows={3}
                        />
                    </Grid>
                    <Grid item className="flex">
                        <Selection
                            options={targeting__keywords_select}
                            value={states.targeting__keywords__select_value}
                            onChange={(e) => handleStatesChanges("targeting__keywords__select_value", e)}
                            isMulti
                            isClearable={false}
                            style={{ margin: "0" }}
                        />
                    </Grid>
                </Grid>
                <Grid container className="flex algin-center" style={{ gap: "40px", paddingInline: "10px" }}>
                    <Grid item style={{ width: "20%" }}></Grid>
                    <Grid item style={{ width: "60%" }}>
                        <div className="flex flex-col">
                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label="gender"
                                    name="gender1"
                                    value={states.keywords_match_method}
                                    onChange={(e) => handleStatesChanges("keywords_match_method", e.target.value)}
                                    className="gap-10"
                                >
                                    <FormControlLabel
                                        value="preferred"
                                        control={<Radio />}
                                        label="Preferred - A keyword match will increase the ad's likelihood of being served."
                                    />
                                    <FormControlLabel
                                        value="required"
                                        control={<Radio />}
                                        label="Required - The ad will only be eligible to serve if there is a match, and its likelihood of being served will be increased."
                                    />
                                    <FormControlLabel
                                        value="filtered"
                                        control={<Radio />}
                                        label="Filtered - The ad will only be eligible to serve if there is a match, but its likelihood of being served will not be affected."
                                    />
                                </RadioGroup>
                            </FormControl>
                            <Box bgcolor={"#eee"} p="10px" mt="10px" className="notice">
                                <p style={{ margin: "unset" }}>
                                    Keyword matching increases the priority of this assignment based on the number of
                                    matches detected.
                                </p>
                                <p>A basic example (matching "artist,paint*"):</p>
                                <p>
                                    <span style={{ background: "#d1d1d1" }}>
                                        <span>{"<script>"}</span> window.abkw = 'artist,painter';{" "}
                                        <span>{"</script>"}</span>
                                    </span>
                                </p>
                                <p style={{ marginBottom: "unset" }}>
                                    <span style={{ fontWeight: "bold" }}>NOTE</span>: Negative keywords are created by
                                    adding a negative sign to the beginning of a keyword. An assignment will not be
                                    served when a negative match is made.
                                </p>
                            </Box>
                        </div>
                    </Grid>
                </Grid>

                {/* Data Keys */}
                <DataTargetSection states={states} setStates={setStates} />

                {/* Contextual Segments */}
                <Grid container className="flex align-center" style={{ gap: "40px", paddingInline: "10px" }}>
                    <Grid item style={{ width: "20%" }}>
                        <label>Contextual Segments</label>
                    </Grid>
                    <Grid item style={{ width: "60%" }}>
                        <Selection
                            options={targeting__keywords_select}
                            value={states.contextual_segments}
                            onChange={(e) => handleStatesChanges("contextual_segments", e)}
                            isMulti
                            isClearable={false}
                            style={{ margin: 0 }}
                        />
                    </Grid>
                </Grid>
            </div>

            <Modal
                open={modals.browser_os_modal || modals.geograhpic_modal || modals.postal_code_modal}
                onClose={() => setModals({ ...initialModalStates })}
                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
                <Box
                    className="targeting-section-modal"
                    style={{
                        ...(modals.geograhpic_modal && {
                            width: "90%",
                            maxWidth: "600px",
                            margin: "auto",
                            padding: 0,
                            overflowY: "hidden",
                            inset: "40px",
                        }),
                    }}
                >
                    <div
                        className="flex justify-between"
                        style={{
                            marginBottom: "20px",
                            position: "sticky",
                            top: 0,
                            background: "#fff",
                            zIndex: 100,
                            padding: "10px 20px",
                            ...(modals.geograhpic_modal && { padding: "10px 20px" }),
                        }}
                    >
                        <h3>
                            {modals.geograhpic_modal && "New Geographic Target"}
                            {modals.postal_code_modal && "New Postal Code Target"}
                            {modals.browser_os_modal && "Create Profile Platform"}
                        </h3>
                        <Button
                            className="close-button"
                            onClick={() => setModals({ ...initialModalStates })}
                            style={{ padding: 0 }}
                        >
                            <Close />
                        </Button>
                    </div>
                    {modals.geograhpic_modal && (
                        <GeographicTargetAddModal
                            closeModal={() => setModals({ ...initialModalStates })}
                            handleStatesChanges={handleStatesChanges}
                        />
                    )}
                    {modals.postal_code_modal && <GeographicModalContent />}
                    {modals.browser_os_modal && (
                        <BrowserOsSectionModal
                            handleStatesChanges={handleStatesChanges}
                            states={states}
                            closeTargetingModal={() => setModals({ ...initialModalStates })}
                        />
                    )}
                </Box>
            </Modal>
        </>
    )
}

function GeographicModalContent() {
    return <div></div>
}
