import { Box, Button, ButtonBase, FormGroup, Grid, NativeSelect, TextField } from "@material-ui/core"
import { useMutation } from "@tanstack/react-query"
import axios from "axios"
import clsx from "clsx"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useAccessToken } from "../../../../../../../Hooks/useAccessToken"
import LoaderCircle from "../../../../../../../components/Loader/Loader"
import { API } from "../../../../../../../constants"
import { useAppToastify } from "../../../../../../../utils/useAppToastify"
import { useChannelAssignmentPageCtx } from "../../../../ChannelAssignmentPage"

export default function GeographicTargetAddModal({ closeModal, handleStatesChanges }) {
    const [inclusive, setInclusive] = useState(true)
    const { refetchGeoGraphicListData } = useChannelAssignmentPageCtx()
    const access_token = useAccessToken()
    const { showAppToast } = useAppToastify()
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {
            name: "",
            range: 0,
            unit: "kilometers",
        },
    })
    const { data, mutate: addGeographicTarget, isLoading: isAddingGeoTarget } = useMutation({
        mutationFn: (params) => {
            return axios.post(`${API}/mediaPlanner/AdButler/geographic`, params, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
        },
        mutationKey: ["addGeoTarget"],
        onSuccess: (res) => {
            showAppToast(res.data.message, { variant: "success" })
            refetchGeoGraphicListData()
            // make new added target as selected option in Geographic selection
            handleStatesChanges("selected_geo_graphic", {
                label: res.data.responseData.name,
                value: res.data.responseData.id,
            })
            closeModal()
        },
        onError: (err) => {
            err?.response?.data?.message && showAppToast(err?.response?.data?.message, { variant: "error" })
            err?.message && showAppToast(err?.message, { variant: "error" })
        },
        enabled: false,
        refetchOnWindowFocus: false,
    })

    const onSubmit = (data) => {
        addGeographicTarget({
            ...data,
            range: Number(data.range),
            inclusive,
            // areas is static
            areas: [{ continent: "Africa", country: "CA", region: "Ontario", city: "Toronto" }],
        })
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} style={{ overflow: "auto", height: "100%" }}>
                <Grid container style={{ padding: "10px 20px" }}>
                    <Grid item xs={12} md={6} style={{ height: "400px", paddingRight: "5px" }}>
                        <Box display={"flex"} flexDirection="column" gridGap={"15px"}>
                            <TextField
                                label="Name"
                                aria-label="Name"
                                placeholder="Type in target name..."
                                name="name"
                                inputRef={register({ required: true })}
                                error={!!errors?.name}
                            />
                            {errors?.name?.type && <p style={{ color: "red", margin: 0 }}>Name is required</p>}
                            <FormGroup>
                                <label>Selected Areas</label>
                                <Box display="flex" marginTop={"5px"}>
                                    <ButtonBase
                                        className={clsx(inclusive && "active-operation", "base-button-adder")}
                                        onClick={() => setInclusive(true)}
                                    >
                                        Include
                                    </ButtonBase>
                                    <ButtonBase
                                        className={clsx(!inclusive && "active-operation", "base-button-adder")}
                                        onClick={() => setInclusive(false)}
                                    >
                                        Exclude
                                    </ButtonBase>
                                </Box>
                            </FormGroup>
                        </Box>
                        <Box marginTop={"30px"}>
                            <p>Optional</p>
                            <Box marginTop={"10px"}>
                                <Box display="flex" alignItems="end">
                                    <TextField
                                        label="Target Radius"
                                        type="number"
                                        name="range"
                                        inputRef={register({
                                            validate: (value) => {
                                                return value === "" ? false : !Number.isNaN(value)
                                            },
                                        })}
                                        error={!!errors?.range}
                                    />
                                    <NativeSelect
                                        name="unit"
                                        {...register("unit")}
                                        style={{ height: "100%", paddingLeft: "10px", width: "50%" }}
                                        defaultValue={getValues("unit")}
                                        onChange={(e) => {
                                            setValue("unit", e.target.value)
                                        }}
                                    >
                                        <option value="kilometers">kilometers</option>
                                        <option value="miles">miles</option>
                                    </NativeSelect>
                                </Box>
                                {errors?.range?.type && (
                                    <p style={{ color: "red", margin: 0 }}>
                                        Only numbers are allowed in Target Radius.
                                    </p>
                                )}
                            </Box>
                        </Box>
                    </Grid>

                    {/* right */}
                    <Grid item xs={12} md={6} style={{ height: "400px", paddingLeft: "5px" }}>
                        <Box display={"flex"} flexDirection="column" gridGap={"15px"}>
                            <TextField label="Locations" placeholder="Type in to search..." type="search" />
                        </Box>
                        <Box marginTop={"30px"} bgcolor="#f2f2f2" height="100%" overflow={"auto"}>
                            {/* ///////////////////////////////////////////////// */}
                        </Box>
                    </Grid>
                </Grid>
            </form>
            <Box
                style={{ background: "#F7F8FA", position: "sticky", bottom: 0, padding: "10px 20px", textAlign: "end" }}
            >
                <Button variant="outlined" style={{ marginRight: "10px" }} onClick={closeModal}>
                    Cancel
                </Button>
                <Button variant="contained" color="primary" type="submit" onClick={handleSubmit(onSubmit)}>
                    {isAddingGeoTarget && (
                        <>
                            <LoaderCircle
                                style={{
                                    position: "unset",
                                    width: "16px",
                                    height: "16px",
                                    color: "white",
                                    marginRight: "10px",
                                }}
                            />
                            <span>Saving</span>
                        </>
                    )}
                    {!isAddingGeoTarget && <span>Saving</span>}
                </Button>
            </Box>
        </>
    )
}
