/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/newMainLayout";

import CircularProgress from "@material-ui/core/CircularProgress";

//Icon

import InputAdornment from "@material-ui/core/InputAdornment";
import AssignmentIcon from "@material-ui/icons/Assignment";
import DateRangeIcon from "@material-ui/icons/DateRange";

//TextField
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useStyles from "../../components/StyleClasses";
import { API, SECRET_KEY } from "../../constants/index";

import ShowAlert from "../../components/alerts";

import { file } from "@babel/types";
import { enGB } from "date-fns/locale";
import { DateRangePicker, END_DATE, START_DATE } from "react-nice-dates";
import "react-nice-dates/build/style.css";
import {
  DigitalQuesAns,
  KeysToSend,
  OOHQuesAns,
  PrintQuesAns,
  RadioQuesAns,
  TelevisionQuesAns,
} from "../../constants/Inventories";

import * as actions from "../../redux/actions/user";
function paramsToObject(entries) {
  const result = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}
function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

function unCamelCase(str) {
  return (
    str
      // insert a space between lower & upper
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      // space before last upper in a sequence followed by lower
      .replace(/\b([A-Z]+)([A-Z])([a-z])/, "$1 $2$3")
      // uppercase the first character
      .replace(/^./, function (str) {
        return str.toUpperCase();
      })
  );
}
const CreateInventory = () => {
  const history = useHistory();
  const urlparams = new URLSearchParams(history.location.search);
  const paramEntries = urlparams.entries();
  const params = paramsToObject(paramEntries);
  const classes = useStyles();
  const dispatch = useDispatch();
  const demographics = useSelector((state) => state.dropdownData?.demographics);
  const [Model, setModel] = useState(params?.model ? params.model : "");
  const [channel, setChannel] = useState(params?.channel ? params.channel : "");
  const [AdType, setAdType] = useState(0);
  const [Iname, setIname] = useState("");

  const [CountriesList, setCountriesList] = useState([]);

  const [unitPrice, setUnitPrice] = useState("");

  const [ChanAdTypeData, setChanAdTypeData] = useState([]);
  const [channelName, setChannelName] = useState(
    params?.model ? params.model : ""
  );
  const [adTypeList, setAdTypeList] = useState([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [loading, setLoading] = useState(false);

  /////new flow//////////
  const [NewBody, setNewBody] = useState(null);
  const [NewQues, setNewQues] = useState([]);
  const [NewAns, setNewAns] = useState([]);
  const [NewChanUri, setNewChanUri] = useState("");
  const [NewTypes, setNewTypes] = useState([]);
  ///////////////////////

  const access_token = window.localStorage.getItem(SECRET_KEY);
  const creator = window.localStorage.getItem("ID");

  const [errors, setErrors] = useState({
    channel: { error: false, txt: "" },
    Iname: { error: false, txt: "" },
    Idescription: { error: false, txt: "" },
    Ifrequency: { error: false, txt: "" },
    AdType: { error: false, txt: "" },
    unitPrice: { error: false, txt: "" },
    avatar: { error: false, txt: "" },
    dates: { error: false, txt: "" },
    Count: { error: false, txt: "" },
    dates: { error: false, txt: "" },
    NewInvErrors: [],
  });

  // useEffect(
  //   () =>
  //     setErrors({
  //       ...errors,
  //       avatar: { error: false, txt: "" },
  //     }),
  //   [InventoryAvtar]
  // );
  useEffect(
    () =>
      setErrors({
        ...errors,
        dates: { error: false, txt: "" },
      }),
    [endDate]
  );

  useEffect(() => {
    ChanAdTypeData?.forEach((element) => {
      if (element.channelName === Model) {
        setChannelName(element.channelName);
        setAdTypeList(element.AdTypes);
      }
    });
  }, [channel, ChanAdTypeData]);

  useEffect(() => {
    if (channelName.includes("igital")) {
      setNewBody(KeysToSend.Digital);
      setNewChanUri("digital");
    }
    if (channelName === "Print") {
      setNewBody(KeysToSend.Print);
      setNewChanUri("print");
    }
    if (channelName === "Radio") {
      setNewBody(KeysToSend.Radio);
      setNewChanUri("Radio");
    }
    if (channelName === "OOH") {
      setNewBody(KeysToSend.OOH);
      setNewChanUri("ooh");
    }
    if (channelName === "Television") {
      setNewBody(KeysToSend.Television);
      setNewChanUri("television");
    }
  }, [channelName]);

  useEffect(() => {
    if (NewBody == null) return;
    let typearr = Object.values(NewBody);
    typearr.splice(0, 5);
    let tr = typearr.map((e) => (typeof e === "number" ? "number" : "text"));
    setNewTypes(tr);

    let erarr = [];
    tr.forEach((e) => erarr.push(false));
    setErrors({
      ...errors,
      NewInvErrors: [...erarr],
    });

    let qarr = Object.keys(NewBody);
    qarr.splice(0, 5);
    let Qr = qarr.map((e) => unCamelCase(e));
    setNewQues(Qr);
    let aarr = [];
    qarr.forEach((e) => aarr.push(""));
    setNewAns(aarr);
  }, [NewBody]);

  const getAdTypes = () => {
    axios
      .get(`${API}/mediaOwner/mediaPublication/getMediaChannelsWithQuestions`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res, err) => {
        if (res) {
          setChanAdTypeData(res.data.channelsWithQuestions);
          setCountriesList(res.data.countries);

          //setIndustriesList(res.data.industries);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };
  useEffect(() => {
    getAdTypes();
  }, []);
  useEffect(() => {
    NewQues.forEach((e, i) => {
      if (e === "Subchannel Type") {
        let Toset = [...NewAns];
        Toset[i] = channelName.includes("Vid")
          ? "Video"
          : channelName.includes("Ski")
          ? "SkinWallpaper"
          : channelName.includes("Ric")
          ? "RichMedia"
          : "Display";
        setNewAns(Toset);
      }
    });
  }, [NewQues]);

  const save = () => {
    //setprocessing("Registering Inventory...");
    let que = Object.keys(NewBody);
    que.splice(0, 5);
    let FinalizedArray = [];
    que.forEach((e, i) => FinalizedArray.push([e, NewAns[i]]));
    let obj = Object.fromEntries(FinalizedArray);
    obj.startDate = new Date(convert(startDate)).getTime();
    obj.endDate = new Date(convert(endDate)).getTime();
    obj.media = params?.id;
    obj.creator = creator;
    obj.name = Iname;
    obj.extra = "extra";

    axios
      .post(`${API}/mediaOwner/mediaInventory/${NewChanUri}`, obj, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res, err) => {
        if (res) {
          history.goBack();
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };
  const update = () => {
    let que = Object.keys(NewBody);
    que.splice(0, 5);
    let FinalizedArray = [];
    que.forEach((e, i) => FinalizedArray.push([e, NewAns[i]]));
    let obj = Object.fromEntries(FinalizedArray);
    obj.startDate = new Date(convert(startDate)).getTime();
    obj.endDate = new Date(convert(endDate)).getTime();
    obj.media = params?.id;
    obj.creator = creator;
    obj.name = Iname;
    obj.extra = "extra";

    ////fix later////
    // if (channelName === "Print") {
    //   obj.insertionDate = 1633509168965;
    //   obj.country = obj.country._id;
    // }
    // if (channelName === "Digital") {
    //   obj.country = obj.country._id;
    // }
    axios
      .put(
        `${API}/mediaOwner/mediaInventory/${NewChanUri}/${
          params.invID ? params.invID : ""
        }`,
        obj,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      .then((res, err) => {
        if (res) {
          history.goBack();
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };

  const deleteInventory = () => {};

  const getData = () => {
    // if (channel === "") return;
    if (params?.invID?.length > 0) {
      setLoading(true);
      let staticModel = Model.includes("igital") ? "Digital" : Model;
      axios
        .get(
          `${API}/mediaOwner/mediaInventory/${params.invID}?model=${staticModel}`,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        )
        .then((res, err) => {
          if (res) {
            setLoading(false);
            setIname(res.data.name);
            setStartDate(res.data.startDate);
            setEndDate(res.data.endDate);
            setAdType(res.data.mediaType);

            if (Model.includes("Digital")) {
              let newans = DigitalQuesAns(res.data);
              setNewAns(newans);
            }
            if (Model === "Print") {
              let newans = PrintQuesAns(res.data);
              setNewAns(newans);
            }
            if (Model === "Radio") {
              let newans = RadioQuesAns(res.data);
              setNewAns(newans);
            }
            if (Model === "OOH") {
              let newans = OOHQuesAns(res.data);
              setNewAns(newans);
            }
            if (Model === "Television") {
              let newans = TelevisionQuesAns(res.data);
              setNewAns(newans);
            }
          }
          if (err) {
          }
        })
        .catch(function (error) {
          if (error) {
          }
        });
    }
  };
  useEffect(() => getData(), [channel]);

  return (
    <div>
      {loading ? (
        <div>
          <CircularProgress />
        </div>
      ) : (
        <div
          className={classes.customfield}
          style={{
            padding: "16px",
            boxShadow:
              "0px 0px 10px rgba(0,0,0,0.20), 0 0 10px rgba(0,0,0,0.15)",
            borderRadius: "30px",
            backgroundColor: "white",
            marginTop: "20px",
          }}
        >
          <ShowAlert />
          <div
            style={{
              marginBottom: "15px",
            }}
          >
            <span
              style={{
                color: "#FB6D6A",
                fontWeight: "400",
                margin: "0",
                fontSize: "20px",
                marginLeft: "10px",
              }}
            >
              Inventory / Ad Details
            </span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginLeft: "10px",
              marginRight: "30px",
            }}
          >
            {/* <div style={{ width: "15%" }}>
            <FormControl component="fieldset">
              <h2 style={{ color: "#4F74C9", fontWeight: "400" }}>Channel</h2>
              {errors.channel.error && (
                <span style={{ color: "red" }}>{errors.channel.txt}</span>
              )}
              <RadioGroup
                aria-label="Chennel"
                name="channel"
                value={channel}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    channel: { error: false, txt: "" },
                  });
                  setChannel(e.target.value);
                }}
              >
                {ChanAdTypeData?.map((chan) => (
                  <FormControlLabel
                    value={chan._id}
                    control={<Radio />}
                    label={chan.channelName}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div> */}
            <div style={{ width: "25%", marginTop: "20px" }}>
              <TextField
                error={errors.Iname.error}
                helperText={errors.Iname.txt}
                fullWidth
                id="Name"
                label="Name"
                variant="outlined"
                style={{ display: "block", marginBottom: "10px" }}
                value={Iname}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    Iname: { error: false, txt: "" },
                  });
                  setIname(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AssignmentIcon color="disabled" />
                    </InputAdornment>
                  ),
                }}
              />

              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                onStartDateChange={setStartDate}
                onEndDateChange={setEndDate}
                minimumDate={new Date(2020, 11, 24, 10, 33, 30, 0)}
                minimumLength={1}
                format="dd MMM yyyy"
                locale={enGB}
              >
                {({ startDateInputProps, endDateInputProps, focus }) => (
                  <div className="date-range">
                    <TextField
                      error={errors.dates.error}
                      helperText={errors.dates.txt}
                      fullWidth
                      id="startDate"
                      label="Available From"
                      variant="outlined"
                      margin="normal"
                      onChange={(e) => {
                        setStartDate(e.target.value);
                      }}
                      className={classes.customfield}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...startDateInputProps}
                    />
                    <TextField
                      fullWidth
                      id="endDate"
                      label="Available Upto"
                      variant="outlined"
                      margin="normal"
                      className={classes.customfield}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...endDateInputProps}
                    />
                  </div>
                )}
              </DateRangePicker>

              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DateRangeIcon color="disabled" />
                    </InputAdornment>
                  ),
                }}
                error={errors.AdType.error}
                helperText={errors.AdType.txt}
                style={{ display: "block", marginBottom: "10px" }}
                fullWidth
                id="outlined-select-currency-native"
                select
                label="Ad Type"
                value={AdType}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    AdType: { error: false, txt: "" },
                  });
                  setAdType(e.target.value);
                }}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              >
                <option disabled key={0} value={0}>
                  Select...
                </option>
                {adTypeList.map((option) => (
                  <option key={option._id} value={option._id}>
                    {option.name}
                  </option>
                ))}
              </TextField>
              {/* <TextField
                error={errors.Ifrequency.error}
                helperText={errors.Ifrequency.txt}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DateRangeIcon color="disabled" />
                    </InputAdornment>
                  ),
                }}
                style={{ display: "block", marginBottom: "10px" }}
                fullWidth
                id="outlined-select-currency-native"
                select
                label="Frequency"
                value={Ifreq}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    Ifrequency: { error: false, txt: "" },
                  });
                  setIfreq(e.target.value);
                }}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              >
                <option disabled key={0} value={0}>
                  Select...
                </option>
                {[
                  "Daily",
                  "Weekly",
                  "Monthly",
                  "Quarterly",
                  "Half-Yearly",
                  "Annualy",
                ].map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </TextField>
              <TextField
                error={errors.unitPrice.error}
                helperText={errors.unitPrice.txt}
                fullWidth
                id="UnitPrice"
                label="UnitPrice"
                variant="outlined"
                style={{ display: "block", marginBottom: "10px" }}
                value={unitPrice}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AssignmentIcon color="disabled" />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    unitPrice: { error: false, txt: "" },
                  });
                  setUnitPrice(e.target.value);
                }}
              /> */}
              {/* <TextField
                error={errors.Idescription.error}
                helperText={errors.Idescription.txt}
                fullWidth
                id="outlined-multiline-static"
                label="Description"
                multiline
                rows={4}
                placeholder="Description"
                variant="outlined"
                value={Idesc}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    Idescription: { error: false, txt: "" },
                  });
                  setIdesc(e.target.value);
                }}
              /> */}
            </div>
            <div
              style={{
                width: "50%",
                marginTop: "20px",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-around",
              }}
            >
              {NewQues.map((e, i) => (
                <>
                  {e != "Country" &&
                    e != "Frequency" &&
                    e !== "Subchannel Type" &&
                    e !== "Audience" &&
                    e !== "Sell Type" && (
                      <TextField
                        error={errors.NewInvErrors[i]}
                        helperText={
                          errors.NewInvErrors[i] ? "this field is required" : ""
                        }
                        type={NewTypes[i]}
                        fullWidth
                        id={e}
                        label={e}
                        variant="outlined"
                        style={{
                          display: "block",
                          margin: "0 5px 5px 5px",
                          width: "45%",
                        }}
                        value={NewAns[i]}
                        onChange={(e) => {
                          let newErrArr = [...errors.NewInvErrors];
                          newErrArr[i] = false;
                          setErrors({
                            ...errors,
                            NewInvErrors: newErrArr,
                          });
                          let Toset = [...NewAns];
                          Toset[i] = e.target.value;
                          setNewAns(Toset);
                        }}
                      />
                    )}
                  {e == "Country" && (
                    <TextField
                      error={errors.NewInvErrors[i]}
                      helperText={
                        errors.NewInvErrors[i] ? "this field is required" : ""
                      }
                      style={{
                        display: "block",
                        margin: "0 5px 5px 5px",
                        width: "45%",
                      }}
                      fullWidth
                      select
                      id={e}
                      label={e}
                      value={NewAns[i]}
                      onChange={(e) => {
                        let newErrArr = [...errors.NewInvErrors];
                        newErrArr[i] = false;
                        setErrors({
                          ...errors,
                          NewInvErrors: newErrArr,
                        });
                        let Toset = [...NewAns];
                        Toset[i] = e.target.value;
                        setNewAns(Toset);
                      }}
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                    >
                      <option disabled key={0} value={""}>
                        {""}
                      </option>
                      {CountriesList?.map((option) => (
                        <option key={option._id} value={option._id}>
                          {option.name}
                        </option>
                      ))}
                    </TextField>
                  )}
                  {e == "Audience" && (
                    <TextField
                      error={errors.NewInvErrors[i]}
                      helperText={
                        errors.NewInvErrors[i] ? "this field is required" : ""
                      }
                      style={{
                        display: "block",
                        margin: "0 5px 5px 5px",
                        width: "45%",
                      }}
                      fullWidth
                      select
                      id={e}
                      label={e}
                      value={NewAns[i]}
                      onChange={(e) => {
                        let newErrArr = [...errors.NewInvErrors];
                        newErrArr[i] = false;
                        setErrors({
                          ...errors,
                          NewInvErrors: newErrArr,
                        });
                        let Toset = [...NewAns];
                        Toset[i] = e.target.value;
                        setNewAns(Toset);
                      }}
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                    >
                      <option disabled key={0} value={""}>
                        {""}
                      </option>
                      {demographics?.map((option) => (
                        <option key={option._id} value={option._id}>
                          {option.name}
                        </option>
                      ))}
                    </TextField>
                  )}
                  {e == "Frequency" && (
                    <TextField
                      error={errors.NewInvErrors[i]}
                      helperText={
                        errors.NewInvErrors[i] ? "this field is required" : ""
                      }
                      style={{
                        display: "block",
                        margin: "0 5px 5px 5px",
                        width: "45%",
                      }}
                      fullWidth
                      select
                      id={e}
                      label={e}
                      value={NewAns[i]}
                      onChange={(e) => {
                        let newErrArr = [...errors.NewInvErrors];
                        newErrArr[i] = false;
                        setErrors({
                          ...errors,
                          NewInvErrors: newErrArr,
                        });
                        let Toset = [...NewAns];
                        Toset[i] = e.target.value;
                        setNewAns(Toset);
                      }}
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                    >
                      <option disabled key={0} value={""}>
                        {""}
                      </option>
                      {[
                        "Daily",
                        "Weekly",
                        "Monthly",
                        "Quarterly",
                        "Half-Yearly",
                        "Annualy",
                      ].map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </TextField>
                  )}
                  {e == "Subchannel Type" && (
                    <TextField
                      error={errors.NewInvErrors[i]}
                      helperText={
                        errors.NewInvErrors[i] ? "this field is required" : ""
                      }
                      style={{
                        display: "block",
                        margin: "0 5px 5px 5px",
                        width: "45%",
                      }}
                      disabled
                      fullWidth
                      select
                      id={e}
                      label={e}
                      value={NewAns[i]}
                      onChange={(e) => {
                        let newErrArr = [...errors.NewInvErrors];
                        newErrArr[i] = false;
                        setErrors({
                          ...errors,
                          NewInvErrors: newErrArr,
                        });
                        let Toset = [...NewAns];
                        Toset[i] = e.target.value;
                        setNewAns(Toset);
                      }}
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                    >
                      <option disabled key={0} value={""}>
                        {""}
                      </option>
                      {["Video", "Display", "RichMedia", "SkinWallpaper"].map(
                        (option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        )
                      )}
                    </TextField>
                  )}
                  {e == "Sell Type" && (
                    <TextField
                      error={errors.NewInvErrors[i]}
                      helperText={
                        errors.NewInvErrors[i] ? "this field is required" : ""
                      }
                      style={{
                        display: "block",
                        margin: "0 5px 5px 5px",
                        width: "45%",
                      }}
                      fullWidth
                      select
                      id={e}
                      label={e}
                      value={NewAns[i]}
                      onChange={(e) => {
                        let newErrArr = [...errors.NewInvErrors];
                        newErrArr[i] = false;
                        setErrors({
                          ...errors,
                          NewInvErrors: newErrArr,
                        });
                        let Toset = [...NewAns];
                        Toset[i] = e.target.value;
                        setNewAns(Toset);
                      }}
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                    >
                      <option disabled key={0} value={""}>
                        {""}
                      </option>
                      {["CPM", "CPC", "NET COST"].map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </TextField>
                  )}
                </>
              ))}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <button
              className={classes.addActionBtn}
              onClick={() => history.goBack()}
            >
              Cancel
            </button>
            <button
              className={classes.addActionBtn}
              style={
                params.invID
                  ? { backgroundColor: "orange" }
                  : { backgroundColor: "blue" }
              }
              onClick={() => {
                if (Iname === "")
                  setErrors({
                    ...errors,
                    Iname: {
                      error: true,
                      txt: "please enter a name",
                    },
                  });
                if (AdType === 0)
                  setErrors({
                    ...errors,
                    AdType: {
                      error: true,
                      txt: "please select Ad Type",
                    },
                  });
                if (endDate === "")
                  setErrors({
                    ...errors,
                    dates: {
                      error: true,
                      txt: "please select a date range",
                    },
                  });
                NewAns.forEach((e, i) => {
                  if (e === "" || e === 0) {
                    let newErrArr = [...errors.NewInvErrors];
                    newErrArr[i] = true;
                    setErrors({
                      ...errors,
                      NewInvErrors: newErrArr,
                    });
                  }
                });

                if (
                  !errors.NewInvErrors.includes(true) &&
                  !NewAns.includes("") &&
                  AdType !== 0 &&
                  endDate !== ""
                ) {
                  params.invID ? update() : save();
                }
              }}
            >
              {params.invID ? "Update" : "Save"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MainLayout(CreateInventory);
