import { put, call, takeLatest } from 'redux-saga/effects';
import * as actions from '../redux/actions/media-owner';
import * as adminActions from '../redux/actions/admin';
import { processBrandStandingCsv, getUsersByRole, adminUploadCsv , getlastUploadedDropdown} from '../services/api/admin';
import { act } from '@testing-library/react';

export function* callProcessCsvApi() {
  try {
    console.info('rrrrr')
    const result = yield call(processBrandStandingCsv);
    yield put(adminActions.processCsvSuccessfully(true));
  } catch(error) {
    yield put(actions.error(error));
  }
}

export function* processCsv() {
  yield takeLatest(adminActions.PROCESS_CSV, callProcessCsvApi);
}

export function* callUploadCsvApi(param) {
  try {
    const result = yield call(adminUploadCsv, param && param.csv);
   // yield put(actions.uploadCsvSuccessfully(result.data.id));
   console.log('heeeeeeeeeeeeeeeeeeeeeeeeeeeee')
    yield put(adminActions.saveDropdown(result.data));

  } catch(error) {
    yield put(actions.error(error));
  }
}

export function* adminUploadCsvSaga() {
  yield takeLatest(actions.UPLOAD_CSV, callUploadCsvApi);
}

export function* callGetUploadedCsvDropsApi(param) {
  try {
    const result = yield call(getlastUploadedDropdown);
    yield put(adminActions.saveDropdown(result.data));

  } catch(error) {
    yield put(actions.error(error));
  }
}

export function* adminGetUploadedDropsSaga() {
  yield takeLatest(adminActions.GET_CSV_DROPDWON, callGetUploadedCsvDropsApi);
}


export function* callAllUsersByRoleApi(param) {
  try {
    console.info("USERS_PARAMS",param)
    const result = yield call(getUsersByRole,param && param.role || 'media_owner');
    console.info("USERS_result",result)
    yield put(adminActions.saveUsers(result.data));
  } catch(error) {
    yield put(actions.error(error));
  }
}

export function* getAllUsers() {
  yield takeLatest(adminActions.GET_USERS, callAllUsersByRoleApi);
}