/* eslint-disable no-unused-expressions */
import { Box, Button, Grid, Modal } from "@material-ui/core"
import Checkbox from "@material-ui/core/Checkbox"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogTitle from "@material-ui/core/DialogTitle"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Paper from "@material-ui/core/Paper"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TextField from "@material-ui/core/TextField"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import Check from "@material-ui/icons/Check"
import ControlPointDuplicate from "@material-ui/icons/ControlPointDuplicate"
import DeleteForeverIcon from "@material-ui/icons/DeleteForever"
import EditIcon from "@material-ui/icons/Edit"
import axios from "axios"
import Highcharts, { color } from "highcharts"
import HighchartReact from "highcharts-react-official"
import parse from "html-react-parser"
import React, { useEffect, useRef, useState } from "react"
import { Bar, Pie } from "react-chartjs-2"
import { useHistory } from "react-router-dom"

import { API, SECRET_KEY } from "../../constants/index"

import Close from "../../assets/Close.svg"
import CloseRed from "../../assets/CloseRed.png"
import EyeIcon from "../../assets/eyePass.svg"

import CircularProgress from "@material-ui/core/CircularProgress"
import Appendices from "../../assets/appendices.png"
import Background from "../../assets/background.png"
import benchmarkMediaSpendAnalysis from "../../assets/benchmark.png"
import Conclusions from "../../assets/conclusions.png"
import ConsumerPersonas from "../../assets/consumerPersonas.png"
import DecaMediaInvestmentPlan from "../../assets/decaMediaInvestmentPlan.png"
import DiversityDividendAnalysis from "../../assets/diversityDividendAnalysis.png"
import Forward from "../../assets/forward.png"
import Introduction from "../../assets/introduction.png"
import KeyInsights from "../../assets/keyInsights.png"
import Media from "../../assets/media.png"
import MediaConsumptionbyDemographic from "../../assets/mediaConsumptionbyDemographic.png"
import MediaSpendAnalysis from "../../assets/mediaSpendAnalysis.png"
import Message from "../../assets/message.png"
import People from "../../assets/people.png"
import Recommendations from "../../assets/recommendations.png"
import RoiBreakdown from "../../assets/roiBreakdown.png"
import Search from "../../assets/search.png"
import Setting from "../../assets/setting.png"
import Summary from "../../assets/summary.png"
import TopLineOverview from "../../assets/topLineOverview.png"

import Save from "@material-ui/icons/Save"
import { useMutation } from "@tanstack/react-query"
import { useAccessToken } from "../../Hooks/useAccessToken"
import LoaderCircle from "../../components/Loader/Loader"
import "../mediaPlanner/IntelligenceDashboard/Dashboard.css"


// createData(
//   "Gay Times",
//   "Brand 1",
//   "Gay pride-parade sponsorship",
//   "social",
//   "$1500"
// ),
// createData("Gay Times", "Brand 2", "campaign 2", "social", "$800"),
// createData("Gay Times", "Brand 1", "campaign 2", "print", "$700"),
// createData("Gay Times", "Brand 1", "campaign 2", "print", "$500"),
// createData("Gay Times", "Brand 3", "campaign 2", "print", "$50"),

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.white,
    fontSize: 12,
    paddingBottom: "2px",
    paddingTop: "2px",
    maxWidth: '200px', // Adjust this width as needed
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    whiteSpace: 'pre-line',
  },
  body: {
    fontSize: 12,
    border: "1px solid #D2D2D2",
    color: "#4F74C9",
    paddingBottom: "2px",
    paddingTop: "2px",
    maxWidth: '200px', // Adjust this width as needed
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    whiteSpace: 'pre-line',
  },
}))(TableCell)

const StyledTableRow = withStyles((theme) => ({
  root: {},
}))(TableRow)

const gridKeys = [
  "demographics",
  "report_date",
  "report_by",
  "report_name",
  "_id",
];

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein }
}

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    maxWidth: 2000,
  },
})

export default function CustomizedTables({
  isEditable,
  headings,
  listdata,
  redirectLink,
  HandleCSV,
  handleDelete,
  updateSecondDropdown,
  secondDropdownValues,
  handleDuplicate, // create duplicate record
  isIconInRow, // ['tableHEading name', true]
  radioList, // radiobutton name in array eg.['Free', 'Paid']
  radioHandleChange, // callback response
  showCheckbox, //  to show checkboxes pass true
  onSelectRowsIndexes, // bulk delete indexes callback functionality
  selectedBulkAction, // selected action name callback
  options, // dropdown data for perform action eg. ['delete','active']
  rowClick, // click on row to edit and view
  isViewable,
  isButtonText,
  sendEmailCallback,
  from,
  mediaOwners,
  refetchOwnersList
}) {
  let deleteIds = []
  const access_token = useAccessToken()
  const [selectedRows, setSelectedRows] = useState(
    deleteIds.filter((row) => {
      return selectedRowIndexes.includes(Number(row.id))
    })
  )
  const [selectedRowIndexes, setSelectedRowIndexes] = useState([...deleteIds])
  const [selectedOptions, setSelectedOptions] = useState(0)
  const [
    selectedSecondDropdownOptions,
    setSelectedSecondDropdownOptions,
  ] = useState(0)
  const [modalOpen, setModalOpen] = useState(false)
  // Report Preview state start
  const [preReport, setPreReport] = useState(false)
  const [graphModal, setGraphModal] = useState(false)
  const [singleGraphData, setSingleGraphData] = useState([])
  const [singleGraphColor, setSingleGraphColor] = useState([])
  const [reportData, setReportData] = useState({})
  const [reportsName, setReportsName] = useState([])
  const [openReportModal, setOpenReportModal] = useState(false)
  const [openReport, setOpenReport] = useState()
  const [spinner, setSpinner] = useState(false)
  const [urlEditArray, setUrlEditArray] = useState({_id:"", url: ""})

  const {mutate: updateMediaOwnerUrl,isLoading,error,isError,isSuccess,} = useMutation({
    mutationFn: (params) => {
      return axios
      .put(`${API}/admin/mediaOwner/${urlEditArray?._id}`, params, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
    },
    onSuccess: (data) => {
      refetchOwnersList()
    },
    onSettled:() => {
      setUrlEditArray({_id:"",url:""})
    }
  })


  const updateUrlField = (e) => {
    e.preventDefault()

    const mediaOwnerToUpdate = mediaOwners?.filter(media => media._id == urlEditArray._id)[0]
    const {email, username, name, status, publisherId,publisherType,companyName,cpmDisplay,cpmVideo,demographics,currency,address,vatNumber,vatPercent,mainContactName,mainContactPhoneNumber,bankDetails } = mediaOwnerToUpdate


    const body = {
      email,
      username,
      name,
      status,
      publisherId,
      publisherType,
      companyName,
      cpmDisplay,
      cpmVideo,
      demographics,
      currency,
      address,
      vatNumber,
      vatPercent,
      mainContactName,
      mainContactPhoneNumber,
      bankDetails,
      url: document.getElementById("url_value").value
    };
    updateMediaOwnerUrl(body)
  }

  // const [openReport, setOpenReport] = useState({
  //   executiveSummary: false,
  //   methodology: false,
  //   discussionAndImplications: false,
  //   demographics: false,
  //   appendices: false,
  //   conclusions: false,
  //   foreword: false,
  //   introduction: false,
  //   background: false,
  //   benchmarkMediaSpendAnalysis: false,
  //   consumerPersonas: false,
  //   diversityDividendAnalysis: false,
  //   keyFinding: false,
  //   keyInsights: false,
  //   media: false,
  //   mediaConsumptionbyDemographic: false,
  //   recommendations: false,
  //   roiBreakdown: false,
  //   topLineOverview: false,
  //   decaMediaInvestmentPlan: false,
  //   aimsAndObjectives: false,
  //   creativeAudit: false,
  //   processReviews: false,
  //   steeringCommittee: false,
  //   bestPractice: false,
  //   sentimentResults: false,
  //   participantDemography: false,
  //   summary: false,
  // })
  // const violationRef = useRef(null)
  // Report Preview state end
  const history = useHistory()
  const classes = useStyles()
  let rows = null
  if (listdata) {
    rows = [...listdata]
  } else {
    rows = [
      // {
      //   data: ["Gay Times", "Brand 2", "campaign 2", "social", "$800"],
      //   __id: "nope",
      // },
      // {
      //   data: ["Gay Times", "Brand 2", "campaign 2", "social", "$800"],
      //   __id: "nope",
      // },
      // {
      //   data: ["Gay Times", "Brand 2", "campaign 2", "social", "$800"],
      //   __id: "nope",
      // },
      // {
      //   data: ["Gay Times", "Brand 2", "campaign 2", "social", "$800"],
      //   __id: "nope",
      // },
      // {
      //   data: ["Gay Times", "Brand 2", "campaign 2", "social", "$800"],
      //   __id: "nope",
      // },
    ]
  }
  let heads = null
  if (headings) {
    heads = [...headings]
  } else {
    heads = ["Publication", "Brand", "Campaign Name", "Channel", "Unit Cost"]
  }

  const onSelectRow = (isChecked, rowIndex) => {
    let selectedItems = [...selectedRows]
    const selectedIndexes = []
    if (isChecked) {
      selectedItems.push(rows[rowIndex])
    } else {
      selectedItems = selectedItems.filter((j) => j._id !== rows[rowIndex]._id)
    }
    selectedItems.filter((item) => {
      selectedIndexes.push(item._id)
      return item
    })
    setSelectedRows(selectedItems)
    setSelectedRowIndexes([...selectedIndexes])
  }

  const handleRequestSort = (event, property) => {
    // const isAsc = orderBy === property && order === "asc"
    // setOrder(isAsc ? "desc" : "asc")
  }

  // ***** Report functions start *****
  const getDMYDate = (dateTime) => {
    const newdate = new Date(dateTime)
    const d = newdate.getDate()
    const m = newdate.getMonth() + 1
    const y = newdate.getFullYear()
    const formattedate =
      (d <= 9 ? "0" + d : d) + "/" + (m <= 9 ? "0" + m : m) + "/" + y
    return formattedate
  }

  const capitalizeFirstLetter = (string) => {
    // let str = string.replace(/_/g, " ");
    if (string == "participantDemography") string = "participantDemographics"
    let str = string.replace(/[A-Z]/g, " $&").trim()
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  const series = (i) => {
    let temp = []
    let step = 0
    if (i % 2 == 0) {
      if (step % 2 !== 0) {
        step += i + 1
        return null
      }
      temp = i + 1
      step += i + 1
      return temp
    } else {
      if (step % 2 == 0) return null
      temp = i + 1
      step += i + 1
      return temp
    }
  }

  const getImgUrl = (name) => {
    switch (name) {
      case "executiveSummary":
        return Summary
      case "methodology":
        return Setting
      case "discussionAndImplications":
        return Message
      case "demographics":
        return People
      case "appendices":
        return Appendices
      case "conclusions":
        return Conclusions
      case "foreword":
        return Forward
      case "introduction":
        return Introduction
      case "background":
        return Background
      case "benchmarkMediaSpendAnalysis":
        return benchmarkMediaSpendAnalysis
      case "consumerPersonas":
        return ConsumerPersonas
      case "diversityDividendAnalysis":
        return DiversityDividendAnalysis
      case "keyFinding":
        return Search
      case "keyInsights":
        return KeyInsights
      case "media":
        return Media
      case "mediaConsumptionByDemographic":
        return MediaConsumptionbyDemographic
      case "recommendations":
        return Recommendations
      case "roiBreakdown":
        return RoiBreakdown
      case "topLineOverview":
        return TopLineOverview
      case "decaMediaInvestmentPlan":
        return DecaMediaInvestmentPlan
      case "aimsAndObjectives":
        return DecaMediaInvestmentPlan
      default:
        return Setting
    }
  }

  const getRenderGraph = (
    data,
    type,
    mediaName = "",
    renderGraphlength = 0
  ) => {
    const ansArr = []
    const percentageArr = []
    const bgColorCode = []
    const pyramidData = []
    let queLabel = ""
    let chartType = ""

    if (data && type !== "media") {
      queLabel = type === "media" ? data.questionanswer[0].question : data.question 
      chartType = type === "media" ? data.questionanswer[0].chartType : data.chartType
      data.answers &&
        data.answers.length &&
        data.answers.map((a) => {
          let randomColor = Math.floor(Math.random() * 16777215).toString(16)
          bgColorCode.push(`#${randomColor}`)
          percentageArr.push(a.percent)
          ansArr.push(a.ans)
          pyramidData.push([a.ans, parseFloat(a.percent)])
        })
    } else {
      queLabel = data.questionanswer[0].question
      chartType = data.questionanswer[0].chartType
      data.questionanswer[0].answers &&
        data.questionanswer[0].answers.length &&
        data.questionanswer[0].answers.map((a) => {
          let randomColor = Math.floor(Math.random() * 16777215).toString(16)
          bgColorCode.push(`#${randomColor}`)
          percentageArr.push(a.percent)
          ansArr.push(a.ans)
          pyramidData.push([a.ans, parseFloat(a.percent)])
        })
    }

    const barAnsData = {
      labels: ansArr,
      datasets: [
        {
          label: queLabel,
          backgroundColor: bgColorCode,
          borderColor: bgColorCode,
          borderWidth: 1,
          data: percentageArr,
        },
      ],
    }

    if (type === "media") {
      return (
        <>
          <li
            className={
              renderGraphlength == 1 && data.questionanswer[0].editorText !== "<p><br></p>"
                ? "full-width"
                : "" || (chartType == "" && queLabel === "")
                ? "full-width-content"
                : ""
            }
          >
            <div className="graph-grid-wrapper">
              {data.questionanswer[0].answers &&
              data.questionanswer[0].answers.length &&
              data.questionanswer[0].answers[0].ans !== "" ? (
                <div
                  className={`${
                    chartType !== ""
                      ? " graph-wrapper"
                      : chartType === "PYR"
                      ? "pyramid-wrapper"
                      : ""
                  } `}
                  style={{
                    margin: data.questionanswer[0].editorText == "<p><br></p>" ? "auto" : "",
                    width:
                      data.questionanswer[0].editorText !== "<p><br></p>" && chartType === ""
                        ? ""
                        : data.questionanswer[0].editorText == "<p><br></p>" && chartType
                        ? "55%"
                        : renderGraphlength == 1
                        ? "55%"
                        : "",
                    overflowX:
                      data.questionanswer[0].editorText == "<p><br></p>" && chartType === ""
                        ? "auto"
                        : "",
                    maxHeight:
                      data.questionanswer[0].editorText == "<p><br></p>" && chartType === ""
                        ? "336px"
                        : "",
                    padding: chartType === "PYR" ? "" : "15px",
                  }}
                >
                  {type == "Media" ? <h2>{data.mediaName}</h2> : null}
                  {chartType && <h4 className="graph-title">{queLabel}</h4>}
                  {chartType === "PYR" && (
                    <div
                      style={{
                        width:
                          renderGraphlength == 1 &&
                          data.questionanswer[0].editorText !== "<p><br></p>"
                            ? "55%"
                            : "",
                      }}
                      onClick={() => {
                        setGraphModal(true)
                        setSingleGraphData(data.questionanswer[0])
                        setSingleGraphColor(bgColorCode)
                      }}
                    >
                      <HighchartReact
                        highcharts={Highcharts}
                        options={{
                          chart: {
                            type: "pyramid",
                            backgroundColor: null,
                            height: "100%",
                          },
                          title: {
                            text: "",
                          },
                          plotOptions: {
                            series: {
                              dataLabels: {
                                enabled: false,
                                format: "<b>{point.name}</b> ({point.y:,.0f})",
                                softConnector: true,
                              },
                            },
                          },
                          legend: {
                            enabled: false,
                          },
                          series: [
                            {
                              name: queLabel,
                              data: [...pyramidData],
                            },
                          ],
                        }}
                      ></HighchartReact>
                    </div>
                  )}
                  {chartType === "BAR" && (
                    <div
                      style={{
                        width:
                          renderGraphlength == 1 &&
                          data.questionanswer[0].editorText !== "<p><br></p>"
                            ? "55%"
                            : "",
                      }}
                      onClick={() => {
                        setGraphModal(true)
                        setSingleGraphData(data.questionanswer[0])
                        setSingleGraphColor(bgColorCode)
                      }}
                    >
                      <Bar
                        width="250px"
                        height="250px"
                        data={barAnsData}
                        options={{
                          plugins: {
                            legend: {
                              display: false,
                            },
                          },
                          scales: {
                            y: {
                              ticks: {
                                display: false,
                                color: "white",
                                font: {
                                  size: 14,
                                },
                              },
                            },
                            x: {
                              ticks: {
                                display: false,
                                color: "white",
                                font: {
                                  size: 14,
                                },
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  )}
                  {chartType === "PIE" && (
                    <div
                      style={{
                        width:
                          renderGraphlength == 1 &&
                          data.questionanswer[0].editorText !== "<p><br></p>"
                            ? "55%"
                            : "",
                      }}
                      onClick={() => {
                        setGraphModal(true)
                        setSingleGraphData(data.questionanswer[0])
                        setSingleGraphColor(bgColorCode)
                      }}
                    >
                      <Pie
                        width="250px"
                        height="250px"
                        data={barAnsData}
                        options={{
                          plugins: {
                            legend: {
                              display: false,
                            },
                          },
                          scales: {
                            y: {
                              ticks: {
                                display: false,
                                color: "white",
                                font: {
                                  size: 14,
                                },
                                // stepSize: 1,
                                // beginAtZero: true
                              },
                            },
                            x: {
                              ticks: {
                                display: false,
                                color: "white",
                                font: {
                                  size: 14,
                                },
                                // stepSize: 1,
                                // beginAtZero: true
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  )}
                  {chartType == "" && queLabel !== "" && (
                    <ul style={{ padding: "15px" }}>
                      <h4>{data.questionanswer[0].question}</h4>
                      {data.questionanswer[0] &&
                        data.questionanswer[0].answers &&
                        data.questionanswer[0].answers.length > 0 &&
                        data.questionanswer[0].answers.map((item) => (
                          <li
                            style={{
                              border: "0px",
                              width: "100%",
                              textAlign: "left",
                            }}
                          >
                            {" "}
                            {item.ans}
                          </li>
                        ))}
                    </ul>
                  )}
                </div>
              ) : null}
  
              {data.questionanswer[0]?.editorText !== "<p><br></p>" ? (
                <div
                  style={{
                    width:
                      renderGraphlength == 1 &&
                      data.questionanswer[0].answers &&
                      data.questionanswer[0].answers.length &&
                      data.questionanswer[0].answers[0].ans === ""
                        ? "100%"
                        : "",
                  }}
                  className={`${
                    (data.questionanswer[0].answers &&
                      data.questionanswer[0].answers.length &&
                      data.questionanswer[0].answers[0].ans !== "") ||
                    (data.questionanswer[0].answers &&
                      data.questionanswer[0].answers.length &&
                      data.questionanswer[0].answers[0].ans === "" &&
                      renderGraphlength == 1)
                      ? "graph-content graph-content-normal"
                      : ""
                  } `}
                >
                  {parse(data.questionanswer[0]?.editorText)}
                </div>
              ) : null}
            </div>
          </li>
        </>
      )
    } else {
      return (
        <>
          <li
            className={
              renderGraphlength == 1 && data.editorText !== "<p><br></p>"
                ? "full-width"
                : "" || (chartType == "" && queLabel === "")
                ? "full-width-content"
                : ""
            }
          >
            <div className="graph-grid-wrapper">
              {data.answers &&
              data.answers.length &&
              data.answers[0].ans !== "" ? (
                <div
                  className={`${
                    chartType !== ""
                      ? " graph-wrapper"
                      : chartType === "PYR"
                      ? "pyramid-wrapper"
                      : ""
                  } `}
                  style={{
                    margin: data.editorText == "<p><br></p>" ? "auto" : "",
                    width:
                      data.editorText !== "<p><br></p>" && chartType === ""
                        ? ""
                        : data.editorText == "<p><br></p>" && chartType
                        ? "55%"
                        : renderGraphlength == 1
                        ? "55%"
                        : "",
                    overflowX:
                      data.editorText == "<p><br></p>" && chartType === ""
                        ? "auto"
                        : "",
                    maxHeight:
                      data.editorText == "<p><br></p>" && chartType === ""
                        ? "336px"
                        : "",
                    padding: chartType === "PYR" ? "" : "15px",
                  }}
                >
                  {type == "Media" ? <h2>{data.mediaName}</h2> : null}
                  {chartType && <h4 className="graph-title">{queLabel}</h4>}
                  {chartType === "PYR" && (
                    <div
                      style={{
                        width:
                          renderGraphlength == 1 &&
                          data.editorText !== "<p><br></p>"
                            ? "55%"
                            : "",
                      }}
                      onClick={() => {
                        setGraphModal(true)
                        setSingleGraphData(data)
                        setSingleGraphColor(bgColorCode)
                      }}
                    >
                      <HighchartReact
                        highcharts={Highcharts}
                        options={{
                          chart: {
                            type: "pyramid",
                            backgroundColor: null,
                            height: "100%",
                          },
                          title: {
                            text: "",
                          },
                          plotOptions: {
                            series: {
                              dataLabels: {
                                enabled: false,
                                format: "<b>{point.name}</b> ({point.y:,.0f})",
                                softConnector: true,
                              },
                            },
                          },
                          legend: {
                            enabled: false,
                          },
                          series: [
                            {
                              name: queLabel,
                              data: [...pyramidData],
                            },
                          ],
                        }}
                      ></HighchartReact>
                    </div>
                  )}
                  {chartType === "BAR" && (
                    <div
                      style={{
                        width:
                          renderGraphlength == 1 &&
                          data.editorText !== "<p><br></p>"
                            ? "55%"
                            : "",
                      }}
                      onClick={() => {
                        setGraphModal(true)
                        setSingleGraphData(data)
                        setSingleGraphColor(bgColorCode)
                      }}
                    >
                      <Bar
                        width="250px"
                        height="250px"
                        data={barAnsData}
                        options={{
                          plugins: {
                            legend: {
                              display: false,
                            },
                          },
                          scales: {
                            y: {
                              ticks: {
                                display: false,
                                color: "white",
                                font: {
                                  size: 14,
                                },
                              },
                            },
                            x: {
                              ticks: {
                                display: false,
                                color: "white",
                                font: {
                                  size: 14,
                                },
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  )}
                  {chartType === "PIE" && (
                    <div
                      style={{
                        width:
                          renderGraphlength == 1 &&
                          data.editorText !== "<p><br></p>"
                            ? "55%"
                            : "",
                      }}
                      onClick={() => {
                        setGraphModal(true)
                        setSingleGraphData(data)
                        setSingleGraphColor(bgColorCode)
                      }}
                    >
                      <Pie
                        width="250px"
                        height="250px"
                        data={barAnsData}
                        options={{
                          plugins: {
                            legend: {
                              display: false,
                            },
                          },
                          scales: {
                            y: {
                              ticks: {
                                display: false,
                                color: "white",
                                font: {
                                  size: 14,
                                },
                                // stepSize: 1,
                                // beginAtZero: true
                              },
                            },
                            x: {
                              ticks: {
                                display: false,
                                color: "white",
                                font: {
                                  size: 14,
                                },
                                // stepSize: 1,
                                // beginAtZero: true
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  )}
                  {chartType == "" && queLabel !== "" && (
                    <ul style={{ padding: "15px" }}>
                      <h4>{data.question}</h4>
                      {data &&
                        data.answers &&
                        data.answers.length > 0 &&
                        data.answers.map((item) => (
                          <li
                            style={{
                              border: "0px",
                              width: "100%",
                              textAlign: "left",
                            }}
                          >
                            {" "}
                            {item.ans}
                          </li>
                        ))}
                    </ul>
                  )}
                </div>
              ) : null}
  
              {data?.editorText !== "<p><br></p>" ? (
                <div
                  style={{
                    width:
                      renderGraphlength == 1 &&
                      data.answers &&
                      data.answers.length &&
                      data.answers[0].ans === ""
                        ? "100%"
                        : "",
                  }}
                  className={`${
                    (data.answers &&
                      data.answers.length &&
                      data.answers[0].ans !== "") ||
                    (data.answers &&
                      data.answers.length &&
                      data.answers[0].ans === "" &&
                      renderGraphlength == 1)
                      ? "graph-content graph-content-normal"
                      : ""
                  } `}
                >
                  {parse(data?.editorText)}
                </div>
              ) : null}
            </div>
          </li>
        </>
      )
    }
  }

  const setGrid = (index) => {
    switch (index) {
      case 1:
        return { class: "main-title bg-light-purple reportby-date", grid: 6 }
      case 2:
        return { class: "main-title bg-dark-purple reportby-date", grid: 6 }
      case 3:
        return {
          class:
            "main-title reportby-date d-block text-center title-icon bg-dark-purple",
          grid: 4,
        }
      case 4:
        return {
          class:
            "main-title bg-purple reportby-date d-block text-center title-icon",
          grid: 4,
        }
      case 5:
        return {
          class:
            "main-title reportby-date d-block text-center title-icon bg-light-purple",
          grid: 4,
        }
      case 6:
        return { class: "main-title bg-light-purple reportby-date", grid: 6 }
      case 7:
        return { class: "main-title bg-dark-purple reportby-date", grid: 6 }
      case 8:
        return {
          class:
            "main-title reportby-date d-block text-center title-icon bg-dark-purple",
          grid: 4,
        }
      case 9:
        return {
          class:
            "main-title bg-purple reportby-date d-block text-center title-icon",
          grid: 4,
        }
      case 10:
        return {
          class:
            "main-title reportby-date d-block text-center title-icon bg-light-purple",
          grid: 4,
        }
      case 11:
        return { class: "main-title bg-light-purple reportby-date", grid: 6 }
      case 12:
        return { class: "main-title bg-dark-purple reportby-date", grid: 6 }
      case 13:
        return {
          class:
            "main-title reportby-date d-block text-center title-icon bg-dark-purple",
          grid: 4,
        }
      case 14:
        return {
          class:
            "main-title bg-purple reportby-date d-block text-center title-icon",
          grid: 4,
        }
      case 15:
        return {
          class:
            "main-title reportby-date d-block text-center title-icon bg-light-purple",
          grid: 4,
        }
      case 16:
        return { class: "main-title bg-light-purple reportby-date", grid: 6 }
      case 17:
        return { class: "main-title bg-dark-purple reportby-date", grid: 6 }
      case 18:
        return {
          class:
            "main-title reportby-date d-block text-center title-icon bg-dark-purple",
          grid: 4,
        }
      case 19:
        return {
          class:
            "main-title bg-purple reportby-date d-block text-center title-icon",
          grid: 4,
        }
      case 20:
        return {
          class:
            "main-title reportby-date d-block text-center title-icon bg-light-purple",
          grid: 4,
        }
      case 21:
        return { class: "main-title bg-light-purple reportby-date", grid: 6 }
      case 22:
        return { class: "main-title bg-dark-purple reportby-date", grid: 6 }
      case 23:
        return {
          class:
            "main-title reportby-date d-block text-center title-icon bg-dark-purple",
          grid: 4,
        }
      case 24:
        return {
          class:
            "main-title reportby-date d-block text-center title-icon bg-purple",
          grid: 4,
        }
      case 25:
        return {
          class:
            "main-title reportby-date d-block text-center title-icon bg-dark-purple",
          grid: 4,
        }
      case 26:
        return { class: "main-title bg-light-purple reportby-date", grid: 6 }
      case 27:
        return { class: "main-title bg-dark-purple reportby-date", grid: 6 }
      default:
        return { class: "main-title bg-dark-purple reportby-date", grid: 12 }
    }
  }

  const getReportsByData = (reportId) => {
    setSpinner(true);
    const access_token = window.localStorage.getItem(SECRET_KEY)
    axios
      .get(`${API}/admin/report/${reportId}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res && res.data) {
          let keysArr = Object.entries(res.data);
          let reportNameArr = [];
          keysArr?.map((key) => {
            if (
              !gridKeys.includes(key[0]) &&
              key[1].length &&
              !key[1].includes("<p><br></p>")
            ) {
              reportNameArr.push({
                label: capitalizeFirstLetter(key[0]),
                value: key[0],
              });
            }
          });
          if (
            Object.keys(res.data).includes("participantDemographyQA") &&
            res.data["participantDemographyQA"].length &&
            res.data["participantDemography"].length === 0
          ) {
            reportNameArr.push({
              label: capitalizeFirstLetter("participantDemography"),
              value: "participantDemography",
            });
          }

          reportNameArr?.map((repts, index) => {
            repts.htmlObj = setGrid(index + 1);
            repts.imgUrl = getImgUrl(repts?.value);
          });
          let len = res.data["participantDemography"].length;
          if (len) {
            for (let i = 0; i < len; i++) {
              let val = series(i);
              if (val && val > -1 && val < len) {
                res.data["participantDemography"][val].step =
                  "badge-shape-round";
              }
            }
          }
          setReportData(res.data)
          setReportsName(reportNameArr)
          
          // get all keys and set values to false
          let allKeys = {}
          Object.keys(res.data).forEach(key => {
            allKeys[key] = false
          })
          setOpenReport(allKeys)
          setSpinner(false);
        }
      })
      .catch((error) => {
        setSpinner(false);
      })
  }

  const renderGridBox = () => {
    return (
      <>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          {reportsName
            ?.filter((i) => i.value !== "participantDemographyQA")
            ?.map((rept) => (
              <Grid
                item
                xs={rept?.htmlObj?.grid}
                sm={rept?.htmlObj?.grid}
                md={rept?.htmlObj?.grid}
              >
                <div
                  className={rept?.htmlObj?.class}
                  onClick={() => {

                    let allKeys = {}
                    Object.keys(reportData).forEach(key => {
                      allKeys[key] = rept?.value === key ? true : false
                      if (rept?.value === key) {
                        setOpenReportModal(true)
                      }
                    })

                    setOpenReport(allKeys)
                    // setOpenReport({
                    //   executiveSummary:
                    //     rept?.value === "executiveSummary" ? true : false,
                    //   methodology: rept?.value === "methodology" ? true : false,
                    //   discussionAndImplications:
                    //     rept?.value === "discussionAndImplications"
                    //       ? true
                    //       : false,
                    //   demographics:
                    //     rept?.value === "demographics" ? true : false,
                    //   appendices: rept?.value === "appendices" ? true : false,
                    //   conclusions: rept?.value === "conclusions" ? true : false,
                    //   foreword: rept?.value === "foreword" ? true : false,
                    //   introduction:
                    //     rept?.value === "introduction" ? true : false,
                    //   background: rept?.value === "background" ? true : false,
                    //   benchmarkMediaSpendAnalysis:
                    //     rept?.value === "benchmarkMediaSpendAnalysis"
                    //       ? true
                    //       : false,
                    //   consumerPersonas:
                    //     rept?.value === "consumerPersonas" ? true : false,
                    //   diversityDividendAnalysis:
                    //     rept?.value === "diversityDividendAnalysis"
                    //       ? true
                    //       : false,
                    //   keyFinding: rept?.value === "keyFinding" ? true : false,
                    //   keyInsights: rept?.value === "keyInsights" ? true : false,
                    //   media: rept?.value === "media" ? true : false,
                    //   mediaConsumptionByDemographic:
                    //     rept?.value === "mediaConsumptionByDemographic"
                    //       ? true
                    //       : false,
                    //   recommendations:
                    //     rept?.value === "recommendations" ? true : false,
                    //   roiBreakdown:
                    //     rept?.value === "roiBreakdown" ? true : false,
                    //   topLineOverview:
                    //     rept?.value === "topLineOverview" ? true : false,
                    //   decaMediaInvestmentPlan:
                    //     rept?.value === "decaMediaInvestmentPlan"
                    //       ? true
                    //       : false,
                    //   aimsAndObjectives:
                    //     rept?.value === "aimsAndObjectives" ? true : false,
                    //   creativeAudit:
                    //     rept?.value === "creativeAudit" ? true : false,
                    //   processReviews:
                    //     rept?.value === "processReviews" ? true : false,
                    //   steeringCommittee:
                    //     rept?.value === "steeringCommittee" ? true : false,
                    //   bestPractice:
                    //     rept?.value === "bestPractice" ? true : false,
                    //   sentimentResults:
                    //     rept?.value === "sentimentResults" ? true : false,
                    //   participantDemography:
                    //     rept?.value === "participantDemography" ? true : false,
                    //   summary: rept?.value === "summary" ? true : false,
                    // })
                  }}
                >
                  <span className="badge text-left">{rept?.label}</span>
                  <div className="icon">
                    <img height={50} width={50} src={rept?.imgUrl} />
                  </div>
                </div>
              </Grid>
            ))}
        </Grid>
      </>
    )
  }

  const getRenderData = () => {
    let keys = Object.keys(openReport)

    const filtered = keys.filter(function (key) {
      return openReport[key]
    })

    for (let keys of Object.keys(reportData)) {
      if ("participantDemography" === filtered.toString()) {
        return (
          <>
            {reportData?.participantDemography?.length ? (
              <div className="title-text" style={{ width: "100%" }}>
                {/* <h2>Participant Demographics</h2> */}
                <div className="modal-boxes">
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    className="text-center demographics-boxes"
                  >
                    {reportData?.participantDemography?.map((rep, id) => {
                      return (
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={6}
                          className="mb2"
                          style={{ marginBottom: "25px" }}
                        >
                          {/* ${rep.step}  bg-green*/}
                          <div
                            style={{ margin: "5%!important", backgroundColor: '#f59533' }}
                            className={`card message-content badge-shape2 mt2`}
                          >
                            <div className="card-body">
                              <div className="icon">
                                <img src={rep.imageurl} />
                              </div>
                              <p>{rep.message}</p>
                            </div>
                          </div>
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              </div>
            ) : null}

            {reportData?.participantDemographyQA?.map((rep) =>
              getRenderGraph(
                rep,
                "Participant Demography",
                "",
                reportData.participantDemographyQA.length
              )
            )}
          </>
        );
      } else if (keys === filtered.toString()){
        return (
          <>
            {reportData[keys]?.map((rep) =>
              getRenderGraph(
                rep,
                keys,
                "",
                reportData[keys].length
              )
            )}
          </>
        );
      }
    }

    // switch (filtered.toString()) {
    //   case "methodology":
    //     return (
    //       <>
    //         {reportData.methodology?.map((rep) =>
    //           getRenderGraph(
    //             rep,
    //             "Methodology",
    //             "",
    //             reportData.methodology.length
    //           )
    //         )}
    //       </>
    //     )
    //   case "executiveSummary":
    //     return (
    //       <>
    //         {reportData.executiveSummary?.map((rep) =>
    //           getRenderGraph(
    //             rep,
    //             "Executive Summary",
    //             "",
    //             reportData.executiveSummary.length
    //           )
    //         )}
    //       </>
    //     )
    //   case "discussionAndImplications":
    //     return (
    //       <>
    //         {reportData.discussionAndImplications?.map((rep) =>
    //           getRenderGraph(
    //             rep,
    //             "Discussion & Implications",
    //             "",
    //             reportData.discussionAndImplications.length
    //           )
    //         )}
    //       </>
    //     )
    //   case "participantDemography":
    //     return (
    //       <>
    //         {reportData?.participantDemography?.length ? (
    //           <div className="title-text" style={{ width: "100%" }}>
    //             {/* <h2>Participant Demographics</h2> */}
    //             <div className="modal-boxes">
    //               <Grid
    //                 container
    //                 direction="row"
    //                 justify="flex-start"
    //                 alignItems="flex-start"
    //                 className="text-center demographics-boxes"
    //               >
    //                 {reportData?.participantDemography?.map((rep, id) => {
    //                   return (
    //                     <Grid
    //                       item
    //                       xs={6}
    //                       sm={6}
    //                       md={6}
    //                       className="mb2"
    //                       style={{ marginBottom: "25px" }}
    //                     >
    //                       {/* ${rep.step}  bg-green*/}
    //                       <div
    //                         style={{
    //                           margin: "5%!important",
    //                           backgroundColor: "#f59533",
    //                         }}
    //                         className={`card message-content badge-shape2 mt2`}
    //                       >
    //                         <div className="card-body">
    //                           <div className="icon">
    //                             <img src={rep.imageurl} />
    //                           </div>
    //                           <p>{rep.message}</p>
    //                         </div>
    //                       </div>
    //                     </Grid>
    //                   )
    //                 })}
    //               </Grid>
    //             </div>
    //           </div>
    //         ) : null}

    //         {reportData?.participantDemographyQA?.map((rep) =>
    //           getRenderGraph(
    //             rep,
    //             "Participant Demography",
    //             "",
    //             reportData.participantDemographyQA.length
    //           )
    //         )}
    //       </>
    //     )
    //   case "keyFinding":
    //     return (
    //       <>
    //         {reportData.keyFinding?.map((rep) =>
    //           getRenderGraph(
    //             rep,
    //             "Key Finding",
    //             "",
    //             reportData.keyFinding.length
    //           )
    //         )}
    //       </>
    //     )
    //   case "appendices":
    //     return (
    //       <>
    //         {reportData.appendices?.map((rep) =>
    //           getRenderGraph(
    //             rep,
    //             "Appendices",
    //             "",
    //             reportData.appendices.length
    //           )
    //         )}
    //       </>
    //     )
    //   case "foreword":
    //     return (
    //       <>
    //         {reportData.foreword?.map((rep) =>
    //           getRenderGraph(rep, "Forward", "", reportData.foreword.length)
    //         )}
    //       </>
    //     )
    //   case "introduction":
    //     return (
    //       <>
    //         {reportData.introduction?.map((rep) =>
    //           getRenderGraph(
    //             rep,
    //             "Introduction",
    //             "",
    //             reportData.introduction.length
    //           )
    //         )}
    //       </>
    //     )
    //   case "conclusions":
    //     return (
    //       <>
    //         {reportData.conclusions?.map((rep) =>
    //           getRenderGraph(
    //             rep,
    //             "Conclusions",
    //             "",
    //             reportData.conclusions.length
    //           )
    //         )}
    //       </>
    //     )
    //   case "background":
    //     return (
    //       <>
    //         {reportData.background?.map((rep) =>
    //           getRenderGraph(
    //             rep,
    //             "Background",
    //             "",
    //             reportData.background.length
    //           )
    //         )}
    //       </>
    //     )
    //   case "benchmarkMediaSpendAnalysis":
    //     return (
    //       <>
    //         {reportData.benchmarkMediaSpendAnalysis?.map((rep) =>
    //           getRenderGraph(
    //             rep,
    //             "Benchmark Media Spend Analysis",
    //             "",
    //             reportData.benchmarkMediaSpendAnalysis.length
    //           )
    //         )}
    //       </>
    //     )
    //   case "consumerPersonas":
    //     return (
    //       <>
    //         {reportData.consumerPersonas?.map((rep) =>
    //           getRenderGraph(
    //             rep,
    //             "Consumer Personas",
    //             "",
    //             reportData.consumerPersonas.length
    //           )
    //         )}
    //       </>
    //     )
    //   case "diversityDividendAnalysis":
    //     return (
    //       <>
    //         {reportData.diversityDividendAnalysis?.map((rep) =>
    //           getRenderGraph(
    //             rep,
    //             "Diversity Dividend Analysis",
    //             "",
    //             reportData.diversityDividendAnalysis.length
    //           )
    //         )}
    //       </>
    //     )
    //   case "keyInsights":
    //     return (
    //       <>
    //         {reportData.keyInsights?.map((rep) =>
    //           getRenderGraph(
    //             rep,
    //             "Key Insights",
    //             "",
    //             reportData.keyInsights.length
    //           )
    //         )}
    //       </>
    //     )
    //   case "media":
    //     return (
    //       <>
    //         {reportData.media?.map((rep) =>
    //           rep.questionanswer.map((re) =>
    //             getRenderGraph(
    //               re,
    //               "Media",
    //               rep.mediaName,
    //               reportData.media.length
    //             )
    //           )
    //         )}
    //       </>
    //     )
    //   case "mediaConsumptionByDemographic":
    //     return (
    //       <>
    //         {reportData.mediaConsumptionByDemographic?.map((rep) =>
    //           getRenderGraph(
    //             rep,
    //             "Media Consumption by Demographic",
    //             "",
    //             reportData.mediaConsumptionByDemographic.length
    //           )
    //         )}
    //       </>
    //     )
    //   case "recommendations":
    //     return (
    //       <>
    //         {reportData.recommendations?.map((rep) =>
    //           getRenderGraph(
    //             rep,
    //             "Recommendations",
    //             "",
    //             reportData.recommendations.length
    //           )
    //         )}
    //       </>
    //     )
    //   case "roiBreakdown":
    //     return (
    //       <>
    //         {reportData.roiBreakdown?.map((rep) =>
    //           getRenderGraph(
    //             rep,
    //             "ROI Breakdown",
    //             "",
    //             reportData.roiBreakdown.length
    //           )
    //         )}
    //       </>
    //     )
    //   case "topLineOverview":
    //     return (
    //       <>
    //         {reportData.topLineOverview?.map((rep) =>
    //           getRenderGraph(
    //             rep,
    //             "Top Line Overview",
    //             "",
    //             reportData.topLineOverview.length
    //           )
    //         )}
    //       </>
    //     )
    //   case "decaMediaInvestmentPlan":
    //     return (
    //       <>
    //         {reportData.decaMediaInvestmentPlan?.map((rep) =>
    //           getRenderGraph(
    //             rep,
    //             "Deca Media Investment Plan",
    //             "",
    //             reportData.decaMediaInvestmentPlan.length
    //           )
    //         )}
    //       </>
    //     )
    //   case "aimsAndObjectives":
    //     return (
    //       <>
    //         {reportData.aimsAndObjectives?.map((rep) =>
    //           getRenderGraph(
    //             rep,
    //             "Aims And Objectives",
    //             "",
    //             reportData.aimsAndObjectives.length
    //           )
    //         )}
    //       </>
    //     )
    //   case "creativeAudit":
    //     return (
    //       <>
    //         {reportData.creativeAudit?.map((rep) =>
    //           getRenderGraph(
    //             rep,
    //             "Creative Audit",
    //             "",
    //             reportData.creativeAudit.length
    //           )
    //         )}
    //       </>
    //     )
    //   case "processReviews":
    //     return (
    //       <>
    //         {reportData.processReviews?.map((rep) =>
    //           getRenderGraph(
    //             rep,
    //             "Process Reviews",
    //             "",
    //             reportData.processReviews.length
    //           )
    //         )}
    //       </>
    //     )
    //   case "steeringCommittee":
    //     return (
    //       <>
    //         {reportData.steeringCommittee?.map((rep) =>
    //           getRenderGraph(
    //             rep,
    //             "Steering Committee",
    //             "",
    //             reportData.steeringCommittee.length
    //           )
    //         )}
    //       </>
    //     )
    //   case "bestPractice":
    //     return (
    //       <>
    //         {reportData.bestPractice?.map((rep) =>
    //           getRenderGraph(
    //             rep,
    //             "Best Practice",
    //             "",
    //             reportData.bestPractice.length
    //           )
    //         )}
    //       </>
    //     )
    //   case "sentimentResults":
    //     return (
    //       <>
    //         {reportData.sentimentResults?.map((rep) =>
    //           getRenderGraph(
    //             rep,
    //             "Sentiment Results",
    //             "",
    //             reportData.sentimentResults.length
    //           )
    //         )}
    //       </>
    //     )
    //   case "summary":
    //     return (
    //       <>
    //         {reportData.summary?.map((rep) =>
    //           getRenderGraph(rep, "Summary", "", reportData.summary.length)
    //         )}
    //       </>
    //     )
    // }
  }

  const renderSingleGraph = () => {
    let data = singleGraphData
    const ansArr = []
    const percentageArr = []
    const bgColorCode = singleGraphColor
    const pyramidData = []
    let queLabel = ""
    let chartType = ""
    const lineData = []

    let myData = []

    if (data) {
      queLabel = data.question
      chartType = data.chartType
      data.answers &&
        data.answers.length &&
        data.answers.map((a, i) => {
          let randomColor = Math.floor(Math.random() * 16777215).toString(16)
          percentageArr.push(a.percent)
          ansArr.push(a.ans)
          lineData.push(a.ans)
          pyramidData.push([a.ans, parseFloat(a.percent)])
          // myData.push({
          //   name: a.ans,
          //   y: parseFloat(a.percent),
          //   color: getColorRandom(a.ans, i),
          // });
          myData.push([a.ans, parseFloat(a.percent)])
        })
    }
    const barAnsData = {
      labels: ansArr,
      datasets: [
        {
          name: queLabel,
          color: bgColorCode,
          borderColor: bgColorCode,
          borderWidth: 1,
          data: percentageArr,
        },
      ],
    }

    if (percentageArr && percentageArr.length === 0) return null
    return (
      <>
        {/* bar-graph-answer */}
        <h2 style={{ textAlign: "center", width: "100%" }}>{queLabel}</h2>
        <div
          className={`custom-popup-new ${chartType === "PYR" ? "" : ""} `}
          style={{ margin: data.editorText == `<p><br></p>` ? "auto" : "" }}
        >
          {chartType === "PYR" && (
            <div>
              <HighchartReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    backgroundColor: null,
                    plotBorderColor: "#0574AC",
                    type: "pyramid3d",
                    options3d: {
                      enabled: true,
                      alpha: 10,
                      depth: 50,
                      viewDistance: 50,
                    },
                  },
                  title: {
                    text: "",
                  },
                  plotOptions: {
                    series: {
                      dataLabels: {
                        enabled: true,
                        format: "<b>{point.name}</b> ({point.y:,.0f})",
                        allowOverlap: true,
                        x: 10,
                        y: -5,
                        style: {
                          textShadow: false,
                          textOutline: false,
                          color: "white",
                        },
                      },
                      width: "60%",
                      height: "80%",
                      center: ["50%", "45%"],
                    },
                  },
                  series: [
                    {
                      name: queLabel,
                      data: [...pyramidData],
                    },
                  ],
                }}
              ></HighchartReact>
            </div>
          )}
          {chartType === "BAR" && (
            <div>
              <HighchartReact
                style={{ height: "100%" }}
                highcharts={Highcharts}
                options={{
                  chart: {
                    type: "column",
                    backgroundColor: "transparent",
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    borderColor: null,
                    plotShadow: false,
                    options3d: {
                      enabled: true,
                      alpha: 15,
                      beta: 15,
                      depth: 50,
                      viewDistance: 25,
                    },
                  },
                  title: {
                    text: "",
                  },
                  plotOptions: {
                    series: {
                      dataLabels: {
                        enabled: true,
                        style: {
                          textShadow: false,
                          textOutline: false,
                          color: "white",
                        },
                      },
                      depth: 25,
                    },
                  },

                  xAxis: {
                    categories: barAnsData.labels,
                    labels: {
                      style: {
                        color: "white",
                      },
                    },
                    gridLineColor: "transparent",
                  },
                  yAxis: {
                    gridLineColor: "transparent",
                    title: {
                      text: "",
                    },
                    labels: {
                      style: {
                        color: "white",
                      },
                    },
                  },
                  series: [
                    {
                      showInLegend: false,
                      data: barAnsData.datasets[0].data,
                      colorByPoint: true,
                    },
                  ],
                  responsive: {
                    rules: [
                      {
                        condition: {
                          maxWidth: 2000,
                        },
                      },
                    ],
                  },
                }}
              />
            </div>
          )}
          {chartType === "PIE" && (
            <div className="chart-wrapper-new">
              <HighchartReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    borderColor: null,
                    plotShadow: false,
                    type: "pie",
                    backgroundColor: "transparent",
                    options3d: {
                      enabled: true,
                      alpha: 60,
                      beta: 0,
                    },
                  },
                  title: {
                    text: "",
                  },
                  tooltip: {
                    pointFormat: "{series.name}: <b>{point.y:.1f}%</b>",
                  },
                  accessibility: {
                    point: {
                      valueSuffix: "%",
                    },
                  },
                  plotOptions: {
                    pie: {
                      allowPointSelect: true,
                      cursor: "pointer",
                      depth: 35,
                      dataLabels: {
                        style: {
                          textShadow: false,
                          textOutline: false,
                          color: "white",
                        },
                        enabled: true,
                        format: "{point.name}",
                      },
                    },
                  },
                  series: [
                    {
                      type: "pie",
                      name: "Answer",
                      colorByPoint: true,
                      data: myData,
                    },
                  ],
                }}
              ></HighchartReact>
            </div>
          )}
          {/* style={{ maxWidth: "175px", height: "260px", overflow: "auto" }} */}
          <div className="content-wrapper-new">{parse(data?.editorText)}</div>
        </div>
      </>
    )
  }
  // ***** Report functions end *****

  if (spinner) {
    return <CircularProgress />
  }

  return (
    <div>
      {modalOpen && (
        <Dialog
          open={modalOpen}
          onClose={() => {
            // setModal(false)
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {selectedRowIndexes.length && !selectedOptions
              ? "Please select atleast one action?"
              : "Please select atleast one record?"}
          </DialogTitle>
          <DialogActions>
            <Button
              style={{ color: "black", backgroundColor: "grey" }}
              onClick={() => {
                setModalOpen(false)
              }}
            >
              Cancel
            </Button>
            <Button
              style={{ backgroundColor: "#4F74C9", color: "white" }}
              onClick={() => {
                setModalOpen(false)
              }}
              autoFocus
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {selectedRowIndexes.length > 0 && (
        <>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <div className="select-box">
                <FormControl
                  fullWidth
                  component="fieldset"
                  variant="outlined"
                  className={[classes.selectControl, classes.customfield].join(
                    " "
                  )}
                >
                  <select
                    style={{ display: "block", marginBottom: "10px" }}
                    fullWidth
                    id="outlined-select-currency-native"
                    select
                    // SelectProps={{
                    //   native: true,
                    // }}
                    value={selectedOptions}
                    onChange={(e) => {
                      setSelectedOptions(e.target.value)
                      setSelectedSecondDropdownOptions(0)
                    }}
                    variant="outlined"
                  >
                    <option disabled key={0} value={0}>
                      Select...
                    </option>
                    {options.map((op) => (
                      <option key={op} value={op}>
                        {op.charAt(0).toUpperCase() + op.slice(1)}
                      </option>
                    ))}
                  </select>
                </FormControl>
              </div>
            </Grid>
            {selectedOptions == "change Demographic" ? (
              <Grid item xs={4}>
                <div className="select-box">
                  <FormControl
                    fullWidth
                    component="fieldset"
                    variant="outlined"
                    className={[
                      classes.selectControl,
                      classes.customfield,
                    ].join(" ")}
                  >
                    <select
                      style={{ display: "block", marginBottom: "10px" }}
                      fullWidth
                      id="outlined-select-currency-native"
                      select
                      value={selectedSecondDropdownOptions}
                      onChange={(e) => {
                        setSelectedSecondDropdownOptions(e.target.value)
                      }}
                      variant="outlined"
                    >
                      <option disabled key={0} value={0}>
                        Select demographics
                      </option>
                      {secondDropdownValues.map((option) => (
                        <option key={option._id} value={option._id}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                  </FormControl>
                </div>
              </Grid>
            ) : null}
            <Grid item xs={4}>
              <Button
                className={classes.addActionBtn}
                variant="outlined"
                color="primary"
                onClick={() => {
                  if (
                    selectedRowIndexes.length === 0 ||
                    selectedOptions === 0
                  ) {
                    setModalOpen(true)
                  } else {
                    selectedBulkAction && selectedBulkAction(selectedOptions)
                    if (selectedOptions == "delete" && onSelectRowsIndexes) {
                      onSelectRowsIndexes(selectedRowIndexes)
                      setSelectedOptions(0)
                      setSelectedRowIndexes([])
                    }
                    if (
                      selectedOptions == "change Demographic" &&
                      updateSecondDropdown &&
                      selectedSecondDropdownOptions
                    ) {
                      updateSecondDropdown(
                        selectedRowIndexes,
                        selectedSecondDropdownOptions
                      )
                      setSelectedOptions(0)
                      setSelectedRowIndexes([])
                    }
                  }
                }}
              >
                Apply
              </Button>
            </Grid>
          </Grid>
        </>
      )}
      <TableContainer component={Paper} style={from === "decaInsight" ? {marginBottom: "20px"} : {marginBottom: "20px"}}>
        <Table className={classes.table} aria-label="customized table" style={{maxWidth: "30vw !important",}}>
          <TableHead onRequestSort={handleRequestSort}>
            <TableRow
              style={{
                background: "linear-gradient(to right, #9211a2, #4911a2)",
              }}
            >
              {showCheckbox && <StyledTableCell></StyledTableCell>}
              {heads.map((e) => {
                if(e == "Url"){
                  return <StyledTableCell width={"300px"}>{e}</StyledTableCell>
                }
                return <StyledTableCell>{e}</StyledTableCell>
                })}
              {isIconInRow && isIconInRow.length > 0 && isIconInRow[1] && (
                <StyledTableCell>{isIconInRow[0]}</StyledTableCell>
              )}
              {isEditable && !heads.includes("Edit Survey") && (
                <StyledTableCell></StyledTableCell>
              )}
              {isViewable && !heads.includes("Preview Survey") && (
                <StyledTableCell></StyledTableCell>
              )}
              {handleDuplicate && <StyledTableCell></StyledTableCell>}
              {handleDelete && <StyledTableCell></StyledTableCell>}
              {isButtonText && <StyledTableCell></StyledTableCell>}
            </TableRow>
          </TableHead>
          {!rows.length ? (
            <TableBody>
              <StyledTableRow>
                <StyledTableCell> No records found.</StyledTableCell>
              </StyledTableRow>
            </TableBody>
          ) : (
            <TableBody>
              {rows.map((row, index) => (
                <StyledTableRow key={Math.random()}>
                  {showCheckbox && (
                    <StyledTableCell>
                      <FormControlLabel
                        control={
                          <Checkbox
                            // checked={true}
                            id={`chk${row._id}`}
                            name={`chk${row._id}`}
                            value={row._id}
                            checked={selectedRowIndexes.includes(row._id)}
                            onChange={(e) => {
                              onSelectRow(e.target.checked, index)
                            }}
                          />
                        }
                        //  label={option}
                      />
                    </StyledTableCell>
                  )}
                  {row.data.map((e,i) => {
                    if(i == 4) return (
                        <StyledTableCell
                            width={"20px"}
                            style={{
                                // width: "300px",
                                width: "100%",
                                overflow: "auto",
                                display:"flex",
                                alignItems:"center",
                                ...(urlEditArray._id &&
                                    urlEditArray._id == row._id && { background:"#d7d7d7" }),
                            }}
                            onDoubleClick={() => {
                                setUrlEditArray({ _id: row._id, url: e });
                            }}
                        >
                            {isLoading && urlEditArray._id == row._id && (
                                <LoaderCircle style={{ position: "unset", width: "20px", height: "20px" }} />
                            )}
                            {urlEditArray._id && urlEditArray._id == row._id ? (
                                <p>
                                  <form onSubmit={updateUrlField} className="flex align-center" style={{ width: "100%",flexDirection:"row" }}>
                                    <input
                                        style={{ border: "none", outline: "none",background:"#d7d7d7" }}
                                        id="url_value"
                                        defaultValue={urlEditArray.url}
                                        autoFocus={urlEditArray._id}
                                    />
                                    <Save onClick={updateUrlField} style={{cursor:"pointer",height:"16px",width:"16px",color:"green"}}/>
                                </form>
                                </p>
                            ) : (
                                <p style={{maxWidth:"200px"}}>
                                  {e ?? "----"}
                                  </p>
                            )}
                        </StyledTableCell>
                    );
                    return <StyledTableCell>
                      {e == "surveyCompleted" ? (
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            color: "#F64336",
                          }}
                        >
                          <Check
                            style={{ cursor: "pointer" }}
                            fontSize="small"
                          />
                        </span>
                      ) : (
                        e
                      )}
                    </StyledTableCell>
                  })}
                  {isIconInRow && isIconInRow.length > 0 && isIconInRow[1] && (
                    <StyledTableCell width="20px">
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={row.radioData.subscription}
                            name="radio-buttons-group"
                          >
                            {radioList &&
                              radioList.length > 0 &&
                              radioList.map((r, i) => (
                                <FormControlLabel
                                  value={r}
                                  control={
                                    <Radio
                                      checked={
                                        r === row.radioData.subscription
                                          ? true
                                          : false
                                      }
                                      onChange={(e) =>
                                        radioHandleChange(e, rows[index])
                                      }
                                    />
                                  }
                                  label={r}
                                />
                              ))}
                          </RadioGroup>
                        </FormControl>
                      </span>
                    </StyledTableCell>
                  )}
                  {isEditable && (
                    <StyledTableCell width="20px">
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <EditIcon
                          fontSize="small"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (typeof redirectLink == "function") {
                              redirectLink(row._id, "edit")
                            } else {
                              HandleCSV
                                ? HandleCSV(row)
                                : history.push(redirectLink + row._id)
                            }
                          }}
                        />
                      </span>
                    </StyledTableCell>
                  )}
                  {isViewable && (
                    <StyledTableCell width="20px">
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <img
                          style={{ cursor: "pointer" }}
                          src={EyeIcon}
                          onClick={() => {
                            if (typeof redirectLink == "function") {
                              redirectLink(row._id, "Preview Survey")
                            } else {
                              getReportsByData(row._id)
                              setPreReport(true)
                            }
                          }}
                        />
                      </span>
                    </StyledTableCell>
                  )}
                  {isViewable && heads.includes("Preview Report") && (
                    <StyledTableCell width="20px">
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <img
                          style={{ cursor: "pointer" }}
                          src={EyeIcon}
                          onClick={() => {
                            if (typeof redirectLink == "function") {
                              redirectLink(row._id, "Preview Report")
                            } else {
                              HandleCSV
                                ? HandleCSV(row)
                                : history.push(redirectLink + row._id)
                            }
                          }}
                        />
                      </span>
                    </StyledTableCell>
                  )}
                  {handleDuplicate && (
                    <StyledTableCell width="20px">
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          color: "#F64336",
                        }}
                      >
                        <ControlPointDuplicate
                          style={{ cursor: "pointer" }}
                          fontSize="small"
                          onClick={() => {
                            handleDuplicate
                              ? handleDuplicate(row)
                              : console.log("want to duplicate")
                          }}
                        />
                      </span>
                    </StyledTableCell>
                  )}
                  {handleDelete && (
                    <StyledTableCell width="20px">
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          color: "#F64336",
                        }}
                      >
                        <DeleteForeverIcon
                          style={{ cursor: "pointer" }}
                          fontSize="small"
                          onClick={() => {
                            handleDelete
                              ? handleDelete(row._id, row)
                              : console.log("want to delete")
                          }}
                        />
                      </span>
                    </StyledTableCell>
                  )}
                  {isButtonText ? (
                    <StyledTableCell width="20px">
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          cursor: "pointer",
                        }}
                        onClick={() => sendEmailCallback(row)}
                      >
                        Send Email
                      </span>
                    </StyledTableCell>
                  ) : null}
                </StyledTableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {
        preReport ? 
        <>
          <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", width:"95.8%"}}>
            <span
              style={{
                color: "#FB6D6A",
                fontWeight: "600",
                margin: "0",
                fontSize: "20px",
                marginLeft: "20px",
              }}
            >
              Preview Report
            </span>
            <span style={{cursor:"pointer"}} onClick={() => {
              setPreReport(false)
              window.scrollTo({
                top: 0,
                behavior: 'smooth'
              });
              }}>
              <img src={CloseRed} alt="Close" width="25px" height="25px"/>
            </span>
          </div>
          <Grid style={{marginTop: "20px"}}>
            <Box>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                <div className="main-title bg-purple reportby-date w-100">
                  <span className="badge text-left">
                    {reportData ? reportData.report_name : ""}
                  </span>
                  <span className="badge text-right">
                    {reportData && reportData.report_date
                      ? getDMYDate(reportData.report_date)
                      : ""}

                    <br />
                    {reportData ? reportData.report_by : ""}
                  </span>
                </div>
              </Grid>
            </Box>
          </Grid>
          <Grid direction="col" justify="flex-start" alignItems="flex-start">
            <Box>{renderGridBox()}</Box>
          </Grid>

          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={
              openReportModal
              // openReport.executiveSummary ||
              // openReport.methodology ||
              // openReport.discussionAndImplications ||
              // openReport.demographics ||
              // openReport.appendices ||
              // openReport.conclusions ||
              // openReport.foreword ||
              // openReport.introduction ||
              // openReport.background ||
              // openReport.benchmarkMediaSpendAnalysis ||
              // openReport.consumerPersonas ||
              // openReport.diversityDividendAnalysis ||
              // openReport.keyFinding ||
              // openReport.keyInsights ||
              // openReport.media ||
              // openReport.mediaConsumptionByDemographic ||
              // openReport.recommendations ||
              // openReport.roiBreakdown ||
              // openReport.topLineOverview ||
              // openReport.decaMediaInvestmentPlan ||
              // openReport.aimsAndObjectives ||
              // openReport.creativeAudit ||
              // openReport.processReviews ||
              // openReport.steeringCommittee ||
              // openReport.bestPractice ||
              // openReport.sentimentResults ||
              // openReport.participantDemography ||
              // openReport.summary
            }
            onClose={() => {
              let allKeys = {}
              Object.keys(reportData).forEach(key => {
                allKeys[key] = false
              })

              setOpenReport(allKeys)
              setOpenReportModal(false)
              // setOpenReport({
              //   executiveSummary: false,
              //   methodology: false,
              //   discussionAndImplications: false,
              //   demographics: false,
              //   appendices: false,
              //   conclusions: false,
              //   foreword: false,
              //   introduction: false,
              //   background: false,
              //   benchmarkMediaSpendAnalysis: false,
              //   consumerPersonas: false,
              //   diversityDividendAnalysis: false,
              //   keyFinding: false,
              //   keyInsights: false,
              //   media: false,
              //   mediaConsumptionByDemographic: false,
              //   recommendations: false,
              //   roiBreakdown: false,
              //   topLineOverview: false,
              //   decaMediaInvestmentPlan: false,
              //   aimsAndObjectives: false,
              //   creativeAudit: false,
              //   processReviews: false,
              //   steeringCommittee: false,
              //   bestPractice: false,
              //   sentimentResults: false,
              //   participantDemography: false,
              //   summary: false,
              // })
            }}
          >
            <Box
              className="details-popup-graph"
              style={{
                height: "70% !important",
                width: "70% !important",
                backgroundColor: openReport?.participantDemography ? "#5b288d" : "",
              }}
            >
              <Button
                style={{
                  float: "right",
                  color: "white",
                  fontWeight: 900,
                  fontSize: "20px",
                }}
                onClick={() => {
                  let allKeys = {}
                  Object.keys(reportData).forEach(key => {
                    allKeys[key] = false
                  })

                  setOpenReport(allKeys)
                  setOpenReportModal(false)
                  // setOpenReport({
                  //   executiveSummary: false,
                  //   methodology: false,
                  //   discussionAndImplications: false,
                  //   demographics: false,
                  //   appendices: false,
                  //   conclusions: false,
                  //   foreword: false,
                  //   introduction: false,
                  //   background: false,
                  //   benchmarkMediaSpendAnalysis: false,
                  //   consumerPersonas: false,
                  //   diversityDividendAnalysis: false,
                  //   keyFinding: false,
                  //   keyInsights: false,
                  //   media: false,
                  //   mediaConsumptionByDemographic: false,
                  //   recommendations: false,
                  //   roiBreakdown: false,
                  //   topLineOverview: false,
                  //   decaMediaInvestmentPlan: false,
                  //   aimsAndObjectives: false,
                  //   creativeAudit: false,
                  //   processReviews: false,
                  //   steeringCommittee: false,
                  //   bestPractice: false,
                  //   sentimentResults: false,
                  //   participantDemography: false,
                  //   summary: false,
                  // })
                  setGraphModal(false)
                }}
              >
                X
              </Button>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                className="text-center"
              >
                <ul className="details-listing">{getRenderData()}</ul>
              </Grid>
            </Box>
          </Modal>

          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={graphModal}
            onClose={() => setGraphModal(false)}
          >
            <Box className="details-popup-graph">
              <Button className="clos-icon" onClick={() => setGraphModal(false)}>
                X
              </Button>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                className="text-center"
              >
                {renderSingleGraph()}
              </Grid>
            </Box>
          </Modal>
        </> : ""
      }
    </div>
  )
}
