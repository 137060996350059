import React, { useState, useEffect } from "react";
import MainLayout from "../../layouts/companyMainLayout";

import CircularProgress from "@material-ui/core/CircularProgress";

//TextField
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";
import useStyles from "../../components/StyleClasses";
import { API, SECRET_KEY } from "../../constants/index";
import axios from "axios";

import NewAlert from "../../components/NewAlert";

// import * as actions from "../../redux/actions/user";

function paramsToObject(entries) {
  const result = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}
const CreateBrand = () => {
  const classes = useStyles();
  const history = useHistory();
  // const dispatch = useDispatch();
  const urlparams = new URLSearchParams(history.location.search);
  const paramEntries = urlparams.entries();
  const params = paramsToObject(paramEntries);
  // const [moID, setmoID] = useState(params?.id ? params.id : "");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  ///////errors & successs //////////
  const [ErrSucMsg, setErrSucMesg] = useState("");
  const [ErrSucType, setErrSucType] = useState(true);
  ///////////////////////////////////

  const [loading, setloading] = useState(false);
  const [errors, setErrors] = useState({
    email: { error: false, txt: "" },
    name: { error: false, txt: "" },
    password: { error: false, txt: "" },
  });

  const access_token = window.localStorage.getItem(SECRET_KEY);

  const saveBrand = () => {
    const body = {
      email: email,
      password: password,
      BrandName: name,
    };
    axios
      .post(`${API}/admin/admin-Brand`, body, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setTimeout(() => {
            history.push("/company/Brands");
          }, 1500);
          setErrSucMesg(res.data.message);
          setErrSucType(true);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
        }
      });
  };
  const updateBrand = () => {
    const body = {
      email: email,
      // password: password,
      BrandName: name,
    };
    axios
      .put(`${API}/admin/admin-Brand/${params?.id}`, body, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setTimeout(() => {
            history.push("/company/Brands");
          }, 1500);
          setErrSucMesg(res.data.message);
          setErrSucType(true);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
        }
      });
  };

  const getBrandData = () => {
    if (params.id) {
      setloading(true);
      axios
        .get(`${API}/admin/admin-Brand/${params.id}`, {
          headers: { Authorization: `Bearer ${access_token}` },
        })
        .then((res, err) => {
          if (res) {
            setloading(false);
            setName(res.data.BrandName);
            setEmail(res.data.email);
            // setPassword(res.data.password);
          }
          if (err) {
          }
        })
        .catch(function (error) {
          if (error) {
          }
        });
    }
  };
  useEffect(() => getBrandData(), []);

  return (
    <div className={classes.boxShadow} style={{ marginTop: "40px" }}>
      <NewAlert
        msg={ErrSucMsg}
        isSuccess={ErrSucType}
        close={() => setErrSucMesg("")}
      />
      <div className={classes.customfield}>
        <span
          style={{
            color: "#FB6D6A",
            fontWeight: "600",
            margin: "0",
            fontSize: "20px",
            marginLeft: "20px",
          }}
        >
          {params.id ? "Update Brand" : "Create Brand"}
        </span>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "20px",
            }}
          >
            <div style={{ width: "50%" }}>
              <TextField
                type="email"
                error={errors.email.error}
                helperText={errors.email.txt}
                fullWidth
                placeholder="Brand Email"
                label="Brand Email"
                variant="outlined"
                style={{ display: "block", marginBottom: "10px" }}
                value={email}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    email: { error: false, txt: "" },
                  });
                  setEmail(e.target.value);
                }}
              />
              {!params.id && (
                <TextField
                  type="password"
                  error={errors.password.error}
                  helperText={errors.password.txt}
                  fullWidth
                  placeholder="Password"
                  label="Password"
                  variant="outlined"
                  style={{ display: "block", marginBottom: "10px" }}
                  value={password}
                  onChange={(e) => {
                    setErrors({
                      ...errors,
                      password: { error: false, txt: "" },
                    });
                    setPassword(e.target.value);
                  }}
                />
              )}
              <TextField
                error={errors.name.error}
                helperText={errors.name.txt}
                fullWidth
                placeholder="Brand Name"
                label="Brand Name"
                variant="outlined"
                style={{ display: "block", marginBottom: "10px" }}
                value={name}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    name: { error: false, txt: "" },
                  });
                  setName(e.target.value);
                }}
              />
            
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <button
                  className={classes.addActionBtn}
                  onClick={() => history.push("/company/Brands")}
                  style={{ backgroundColor: "grey" }}
                >
                  Cancel
                </button>
                <button
                  className={classes.addActionBtn}
                  onClick={() => {
                    if (email === "")
                      setErrors({
                        ...errors,
                        email: { error: true, txt: "please enter Brand email" },
                      });
                    if (!params.id && password === "")
                      setErrors({
                        ...errors,
                        password: {
                          error: true,
                          txt: "please enter password",
                        },
                      });
                    if (name === "")
                      setErrors({
                        ...errors,
                        name: {
                          error: true,
                          txt: "please enter Brand name",
                        },
                      });

                    if (
                      (!params.id && name !== "" &&
                      email !== "" &&
                      password !== "") || (params.id && name !== "" &&
                      email !== "") 
                    ) {
                      params.id ? updateBrand() : saveBrand();
                    }
                  }}
                >
                  {params.id ? "Update" : "Create Brand"}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default MainLayout(CreateBrand);
