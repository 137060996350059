import React, { useState, useEffect } from "react";
import "./App.css";
import "./styles/utils.css";
import { withRouter } from "react-router-dom";
import Routes from "./Routes";
import Login from "./pages/signup/mediaLogin";
import Home from "./pages/Home";
import axios from "axios";
import { useHistory } from "react-router";


const App = () => {
  return <Home />;
};

export default App;
