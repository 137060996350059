import React from "react";
import "./ballsLoading.css";

const ballsLoading = ({ color, size }) => {
  return (
    <div className="balls">
      <div
        style={{
          width: `${size}em`,
          height: `${size}em`,
          backgroundColor: color,
        }}
      ></div>
      <div
        style={{
          width: `${size}em`,
          height: `${size}em`,
          backgroundColor: color,
        }}
      ></div>
      <div
        style={{
          width: `${size}em`,
          height: `${size}em`,
          backgroundColor: color,
        }}
      ></div>
    </div>
  );
};
export default ballsLoading;
