import { get, postUploadCsv } from './axios';

export const processBrandStandingCsv = async () => {
	return await get('/admin/process-brand-standing-csv');
};

export const adminUploadCsv = async (data) => {
	return await postUploadCsv('/admin/uploadCsv', data);
};

export const getUsersByRole = async (role) => {
	return await get(`/users/${role}`);
};

export const getlastUploadedDropdown = async () => {
	return await get('/admin/dropdowns');
};