import { FormControl, Grid, InputAdornment, TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import NewAlert from "../../../components/NewAlert";
import useStyles from "../../../components/StyleClasses";
//Icon
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import BusinessIcon from "@material-ui/icons/Business";
import { DateRangePicker } from "react-nice-dates";


export const FourthStep = ({
  channesWithAdTypesList,
  setCampChannel,
  setErrSucMesg,
  setDemographic,
  setCampErrors,
  setStep,
  ErrSucMsg,
  ErrSucType,
  campErrors,
  setFlag,
  setExpectedImpressions,
  demographic,
  demographicData,
  industryId,
  industryListArr,
  brandName,
  countryId,
  countryListArr,
  expectedImpressions,
  flag,
  campChannel,
  description,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  enGB,
  errors,
  numberMasking,
  setName,
  setBrandName,
  campName,
  setCountryId,
  setIndustryId,
  setChangeToggle,
  onResetKpi,
  setCountryChange,
  setdescription,
  channel,
  Country
}) => {
  const classes = useStyles();
  useEffect(() => {
    channesWithAdTypesList.forEach((option) => {
      if (option._id == channel) {
        setCampChannel(option.channelName);
      }
    });
  }, []);

  return (
    <div className={classes.boxShadow} style={{ marginTop: "0px" }}>
      <NewAlert
        msg={ErrSucMsg}
        isSuccess={ErrSucType}
        close={() => setErrSucMesg("")}
      />
      <div
        className={classes.customfield}
        // style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div>
          <h4 className={classes.redHead}>Brand Uplift Campaign Details</h4>
          <Grid container direction="row" spacing={2}>
            <Grid item md={4} sm={4} xs={4}>
              <TextField
                // id="outlined-select-currency-native"
                error={campErrors.demographic.error}
                helperText={campErrors.demographic.txt}
                select
                fullWidth
                label="Demographic"
                value={demographic}
                onChange={(e) => {
                  setDemographic(e.target.value);
                  setCampErrors({
                    ...campErrors,
                    demographic: { error: false, txt: "" },
                  });
                }}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              >
                <option key={0} value={0}>
                  Select...
                </option>
                {demographicData?.map((option) => (
                  <option key={option._id} value={option.name}>
                    {option.name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item md={4} sm={4} xs={4}>
              <TextField
                error={campErrors.name.error}
                helperText={campErrors.name.txt}
                fullWidth
                label="Name"
                variant="outlined"
                style={{ display: "block", marginBottom: "10px" }}
                value={campName}
                onChange={(e) => {
                  setName(e.target.value);
                  setCampErrors({
                    ...campErrors,
                    name: { error: false, txt: "" },
                  });
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleIcon color="disabled" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item md={4} sm={4} xs={4}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <BusinessIcon color="disabled" />
                    </InputAdornment>
                  ),
                }}
                error={campErrors.industryId.error}
                helperText={campErrors.industryId.txt}
                style={{ display: "block", marginBottom: "10px" }}
                fullWidth
                select
                label="Industry"
                value={industryId}
                onChange={(e) => {
                  setCampErrors({
                    ...campErrors,
                    industryId: { error: false, txt: "" },
                  });
                  setIndustryId(e.target.value);
                  setChangeToggle(true);
                  onResetKpi(e);
                }}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              >
                <option disabled key={0} value={0}>
                  Select...
                </option>
                {industryListArr &&
                  industryListArr?.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item md={4} sm={4} xs={4}>
              <TextField
                error={campErrors.brand.error}
                helperText={campErrors.brand.txt}
                fullWidth
                label="Brand"
                variant="outlined"
                style={{ display: "block", marginBottom: "10px" }}
                value={brandName}
                onChange={(e) => {
                  setCampErrors({
                    ...campErrors,
                    brand: { error: false, txt: "" },
                  });
                  setBrandName(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <BusinessIcon color="disabled" size={10} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item md={4} sm={4} xs={4}>
              <TextField
                error={campErrors.countryId.error}
                helperText={campErrors.countryId.txt}
                style={{ display: "block", marginBottom: "10px" }}
                fullWidth
                select
                label="Country"
                value={countryId}
                onChange={(e) => {
                  setCampErrors({
                    ...campErrors,
                    countryId: { error: false, txt: "" },
                  });
                  setCountryId(e.target.value);
                  setCountryChange(true);
                  onResetKpi(e);
                }}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              >
                <option disabled key={0} value={0}>
                  Select...
                </option>
                {countryListArr &&
                  countryListArr?.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}-{option.language_name}
                    </option>
                  ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item md={6} sm={6} xs={6}>
              <TextField
                error={campErrors.expectedImpressions.error}
                helperText={campErrors.expectedImpressions.txt}
                fullWidth
                label="Expected Impressions"
                variant="outlined"
                style={{ display: "block", marginBottom: "10px" }}
                value={expectedImpressions}
                onChange={(e) => {
                  let event = e;
                  if (
                    event.target.value.length >= 1 &&
                    event.target.value <= 999999
                  ) {
                    setFlag(true);
                  } else {
                    setFlag(false);
                  }
                  event.target.value = numberMasking(e.target.value);
                  setCampErrors({
                    ...campErrors,
                    expectedImpressions: { error: false, txt: "" },
                  });
                  setExpectedImpressions(e.target.value);
                }}
              />
              {flag && (
                <span style={{ color: "red", marginBottom: "0px" }}>
                  Please enter value above 1 Million
                </span>
              )}
              <h4 className={classes.redHead}>Channel</h4>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
              >
                <TextField
                  // error={errors.Country.error}
                  // helperText={errors.Country.txt}
                  style={{ display: "block", marginBottom: "10px" }}
                  fullWidth
                  select
                  label="Channel"
                  value={campChannel}
                  onChange={(e) => {
                    // setErrors({
                    //   ...errors,
                    //   Country: { error: false, txt: "" },
                    // });
                    setCampChannel(e.target.value);
                  }}
                  // SelectProps={{
                  //   native: true,
                  // }}
                  variant="outlined"
                >
                  <option disabled key={0} value={0}>
                    Select...
                  </option>
                  {channesWithAdTypesList.map((option) => (
                    <option key={option._id} value={option.channelName}>
                      {option.channelName}
                    </option>
                  ))}
                </TextField>
              </FormControl>
              {/* 
                <FormControl
                  fullWidth
                  component="fieldset"
                  variant="outlined"
                  className={[classes.selectControl, classes.customfield].join(
                    " "
                  )}
                >
                  <InputLabel id="demo-mutiple-checkbox-label">
                    Channel
                  </InputLabel>
                  <Select
                    id="outlined-select-currency-native"
                    multiple
                    label="Channel"
                    value={channel}
                    onChange={(e) => {
                      {
                        setChannel(e.target.value);
                      }
                    }}
                    variant="outlined"
                    renderValue={(ep) => renderChannels}
                  >
                    <MenuItem disabled value={0}>
                      <em>Select...</em>
                    </MenuItem>
                    {channesWithAdTypesList.map((option) => (
                      <MenuItem key={option._id} value={option.channelName}>
                        <Checkbox
                          checked={channel.includes(option.channelName)}
                        ></Checkbox>
                        {option.channelName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <TextField
                // error={errors.description.error}
                // helperText={errors.description.txt}
                fullWidth
                label="Description"
                multiline
                rows={4}
                placeholder="Description"
                variant="outlined"
                value={description}
                onChange={(e) => {
                  // setErrors({
                  //   ...errors,
                  //   description: { error: false, txt: "" },
                  // });
                  setdescription(e.target.value);
                }}
              />
            </Grid>
          </Grid>

          <h4 className={classes.redHead}>Duration</h4>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
            minimumDate={new Date()}
            minimumLength={1}
            format="dd MMM yyyy"
            locale={enGB}
          >
            {({ startDateInputProps, endDateInputProps, focus }) => (
              <Grid container direction="row" spacing={2}>
                <Grid item md={6} sm={6} xs={6}>
                  <TextField
                    error={errors.dates.error}
                    helperText={errors.dates.txt}
                    fullWidth
                    id="startDate"
                    label="Available From"
                    variant="outlined"
                    margin="normal"
                    onChange={(e) => setStartDate(e.target.value)}
                    className={classes.customfield}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...startDateInputProps}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={6}>
                  <TextField
                    error={errors.dates.error}
                    helperText={errors.dates.txt}
                    fullWidth
                    id="endDate"
                    label="Available Upto"
                    variant="outlined"
                    margin="normal"
                    className={classes.customfield}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...endDateInputProps}
                  />
                </Grid>
              </Grid>
            )}
          </DateRangePicker>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <button className={classes.addActionBtn} onClick={() => setStep(2)}>
          Previous
        </button>
        <button
          className={classes.addActionBtn}
          // onClick={()=>{
          //   setStep(4);
          // }}
          onClick={() => {
            if (demographic === 0) {
              setCampErrors({
                ...campErrors,
                demographic: {
                  error: true,
                  txt: "please select an demographic",
                },
              });
            } else if (campName === "") {
              setCampErrors({
                ...campErrors,
                name: { error: true, txt: "please enter a name" },
              });
            } else if (brandName === "") {
              setCampErrors({
                ...campErrors,
                brand: { error: true, txt: "please enter a brand" },
              });
            } else if (industryId === 0) {
              setCampErrors({
                ...campErrors,
                industryId: { error: true, txt: "please select an Industry" },
              });
            } else if (Country === 0) {
              setCampErrors({
                ...campErrors,
                countryId: { error: true, txt: "please select countries" },
              });
            } else if (expectedImpressions === null) {
              setCampErrors({
                ...campErrors,
                expectedImpressions: {
                  error: true,
                  txt: "please enter a expected impressions",
                },
              });
            }
            // else if (description === "") {
            //   setCampErrors({
            //     ...campErrors,
            //     description: {
            //       error: true,
            //       txt: "please Enter a Description",
            //     },
            //   });
            // }
            else if (startDate === "") {
              setCampErrors({
                ...campErrors,
                dates: { error: true, txt: "please select dates" },
              });
            } else {
              setStep(4);
              // getUplift();
              // getBasline();
            }
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};
