import { put, call, takeLatest } from 'redux-saga/effects';
import * as actions from '../redux/actions/media-owner';
import * as mediaActions from '../redux/actions/media';
import { mediaQuestion, mediaSentiments, mediaConsultancy , mediaQuestion1out5} from '../services/api/media';
import { act } from '@testing-library/react';


export function* callPostQuestionApi(param) {
  try {
    const result = yield call(mediaQuestion, param && param.data);
    console.log("Result_QUESTION",result.data)
       
  } catch(error) {
    yield put(actions.error(error));
  }
}

export function* mediaQuestionSaga() {
  yield takeLatest(mediaActions.POST_QUESTION, callPostQuestionApi);
}

export function* callPostSentimentApi(param) {
  try {
    const result = yield call(mediaSentiments, param && param.data);
    console.log("Result_SENTIMENT",result.data)
       
  } catch(error) {
    yield put(actions.error(error));
  }
}

export function* mediaSentimentSaga() {
  yield takeLatest(mediaActions.POST_MEDIA_SENTIMENTS, callPostSentimentApi);
}

export function* callPostConsultancyApi(param) {
  try {
    const result = yield call(mediaConsultancy, param && param.data);
    console.log("Result_Consultancy",result.data)
       
  } catch(error) {
    yield put(actions.error(error));
  }
}

export function* mediaConsultancySaga() {
  yield takeLatest(mediaActions.POST_MEDIA_CONSULTANCY, callPostConsultancyApi);
}

export function* callPostQuestion1Out5Api(param) {
  try {
    const result = yield call(mediaQuestion1out5, param && param.data);
    console.log("Result_Question1Out5",result.data)
       
  } catch(error) {
    yield put(actions.error(error));
  }
}

export function* mediaQuestion1Out5Saga() {
  yield takeLatest(mediaActions.POST_QUESTION1_OUT_5, callPostQuestion1Out5Api);
}

