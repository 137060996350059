import Grid from "@material-ui/core/Grid";
import { fade, makeStyles, withTheme } from "@material-ui/core/styles";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import "../../components/mystyle.css";

import Papa from "papaparse";
//Button
import Button from "@material-ui/core/Button";
import jwt_decode from "jwt-decode";

import {
  Backdrop,
  Fade,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
} from "@material-ui/core";

import TextField from "@material-ui/core/TextField";

//Icon
import LeftArrow from "../../assets/tag.png";
import RightArrow from "../../assets/tag2.png";
// import campaignButton from "../../assets/campaignButton.svg";

import Pagination from "@material-ui/lab/Pagination";

import CircularProgress from "@material-ui/core/CircularProgress";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import EditIcon from "@material-ui/icons/Edit";

import axios from "axios";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { API, SECRET_KEY } from "../../constants/index";
import MainLayout from "../../layouts/newMainLayoutPlanner";

import CustomizedTables from "../mediaOwner/mytable";

import Highcharts, { color } from "highcharts";
import HighchartReact from "highcharts-react-official";
import funnel from "highcharts/modules/funnel.js";
import useStyles from "../../components/StyleClasses";
import {
  ConvertDigital,
  ConvertOOH,
  ConvertPrint,
  ConvertRadio,
  ConvertTelevision,
} from "../../constants/Inventories";

import jsPDF from "jspdf";
import "jspdf-autotable";
import NewAlert from "../../components/NewAlert";
import Subscription from "./subscription";
funnel(Highcharts);

function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

const useStyless = () =>
  makeStyles(() => ({
    campaignBtn: {
      width: "150px",
      borderRadius: "50px",
      paddingTop: "0.7em",
      cursor: "pointer",
      boxShadow: "5px 5px 10px #bebebe,-5px -5px 10px #ffffff",
      "&:hover": {
        boxShadow: "5px 5px 10px #78adf3,-5px -5px 10px #ffffff",
      },
    },
  }));

const MediaPlanner = () => {
  const classes = useStyles();
  const classess = useStyless()();
  const history = useHistory();
  const [campaigns, setcampaigns] = useState([]);
  const [filteredList, setfilterdList] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setloading] = useState(false);
  const [open, setOpen] = useState(false);
  const [slectedCamapaign, setSelectedCampaign] = useState(null);
  const [loadingCampaign, setLoadingCamapaign] = useState(false);
  const access_token = window.localStorage.getItem(SECRET_KEY);

  //invs
  const [tableData, setTableData] = useState([]);
  const [tableHeads, setTableHeads] = useState([]);
  const [DATA, setDATA] = useState(null);
  const [InvAll, setInvAll] = useState([]);
  const [InvChannel, setInvChannel] = useState("Digital");
  const [ChanBtns, setChanBtns] = useState([]);

  const [editFormEmail, setEditFormEmail] = useState("");
  const [validatedEmail, setvalidatedEmail] = useState(false);
  const [isBook, setIsBook] = useState(false);
  const subscription = useSelector((state) => state.subscription);
  const getSubscription = window.localStorage.getItem("subscription");
  let decodedSubscription = getSubscription && jwt_decode(getSubscription);

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  useEffect(() => {
    if (validateEmail(editFormEmail)) {
      setvalidatedEmail(true);
    } else {
      setvalidatedEmail(false);
    }
  }, [editFormEmail]);

  const handleClick = (e) => {
    history.push("/media-planner/createCampaign");
  };

  const getCampaigns = () => {
    setloading(true);
    axios
      .get(`${API}/mediaPlanner/campaign?name=${search}&page=${page}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setloading(false);
          console.log("campaign list>>>", res.data.data);
          setcampaigns(res.data.data);
          setPageCount(res.data.total);
        }
        if (err) {
          console.log(err);
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };

  // useEffect(() => {
  //   setfilterdList(campaigns);
  // }, [campaigns]);

  useEffect(() => {
    if (page !== 1) setPage(1);
    getCampaigns();
  }, [search]);
  useEffect(() => {
    getCampaigns();
  }, [page]);

  const getSingleCamapaign = (_id) => {
    setLoadingCamapaign(true);
    axios
      .get(`${API}/mediaPlanner/campaign/${_id}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          //setloading(false);
          console.log("single campaign>>>>>>>>>>>>>>>", res.data);

          setSelectedCampaign(res.data);
          setInvAll(res.data.inventories);

          // setPageCount(res.data.total);
          setLoadingCamapaign(false);
        }
        if (err) {
          console.log(err);
          setLoadingCamapaign(false);
        }
      })
      .catch(function (error) {
        if (error) {
          setLoadingCamapaign(false);
        }
      });
  };

  const BookCall = () => {
    const formData = new FormData();
    formData.append("campaignName", slectedCamapaign.campaignName);
    formData.append("brand", slectedCamapaign.brand);
    formData.append("description", slectedCamapaign.description);
    formData.append("status", 1);
    formData.append("toEmail", editFormEmail);
    formData.append("industry", slectedCamapaign.industry);
    formData.append("currency", slectedCamapaign.currency);
    slectedCamapaign.countries.forEach((e, i) => {
      formData.append("countries[]", e);
    });
    formData.append("startDate", slectedCamapaign.startDate);
    formData.append("endDate", slectedCamapaign.endDate);
    formData.append("frequencyUplift", slectedCamapaign.frequencyUplift);
    formData.append("spendAmount", slectedCamapaign.spendAmount);
    slectedCamapaign.addTypes.forEach((e, i) => {
      formData.append("addTypes[]", e);
    });
    slectedCamapaign.channels.forEach((e, i) => {
      formData.append("channels[]", e);
    });
    slectedCamapaign.personas.forEach((e, i) => {
      formData.append("personas[]", e);
    });
    // const invIds = slectedCamapaign.inventories.map((e) => e._id);
    // invIds.forEach((e, i) => {
    //   formData.append(`inventories[]`, e);
    // });
    // let InvInOne = [];
    // InvAll.Digital.forEach((e) => {
    //   InvInOne.push({ _id: e._id, type: "DigitaL" });
    // });
    // InvAll.Print.forEach((e) => {
    //   InvInOne.push({ _id: e._id, type: "Print" });
    // });
    // InvAll.Radio.forEach((e) => {
    //   InvInOne.push({ _id: e._id, type: "Radio" });
    // });
    // InvAll.OOH.forEach((e) => {
    //   InvInOne.push({ _id: e._id, type: "OOH" });
    // });
    // InvAll.Television.forEach((e) => {
    //   InvInOne.push({ _id: e._id, type: "Television" });
    // });

    // InvInOne.forEach((e, i) => {
    //   formData.append(`inventories[${i}][channelId]`, e._id);
    //   formData.append(`inventories[${i}][channelType]`, e.type);
    // });
    axios
      .put(`${API}/mediaPlanner/campaign/${slectedCamapaign._id}`, formData, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res, err) => {
        if (res) {
          //setloading(false);
          console.log("single campaign>>>>>>>>>>>>>>>", res);
          setOpen(false);
          getCampaigns();
        }
        if (err) {
          console.log(err);
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };
  const [ShowSendEmailSuccessMsg,setShowSendEmailSuccessMsg] = useState(null)
  const [ShowPdfDownloadMsg,setShowPdfDownloadMsg] = useState({msg: "", isSuccess: false})
  const sendEmail = (campaign_id) => {
    axios
      .get(`${API}/mediaPlanner/campaign/shareCampaign/${campaign_id}?email=${editFormEmail}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        if (res) {
          setShowSendEmailSuccessMsg("Email send successfully.")
          setOpen(false)
          setEditFormEmail("")
        }
      })
      .catch(function (error) {
        if (error) {
          setShowSendEmailSuccessMsg("Email send failed.")
          setIsBook(false);
          setOpen(false)
          setEditFormEmail("")
        }
      });
  };
  // const sendEmail = () => {
  //   const payload = [];

  //   axios
  //     .post(`${API}/mediaPlanner/mailcampaign`, payload, {
  //       headers: {
  //         Authorization: `Bearer ${access_token}`,
  //       },
  //     })
  //     .then((res) => {
  //       if (res) {
  //         setIsBook(true);
  //         //setloading(false);
  //         // getCampaigns();
  //       }
  //     })
  //     .catch(function (error) {
  //       if (error) {
  //         setIsBook(false);
  //       }
  //     });
  // };

  const MyChart = ({ demographics }) => {
    let mydata = [];
    demographics.forEach((element) => {
      mydata.push({ name: element, y: 100 / demographics.length });
    });
    return (
      <div>
        <HighchartReact
          containerProps={{
            style: {
              height: "180px",
              padding: "0",
              backgroundColor: "transparent",
            },
          }}
          highcharts={Highcharts}
          options={{
            chart: {
              backgroundColor: "rgba(0,0,0,0)",
              plotBackgroundColor: null,
              plotBorderWidth: null,
              plotShadow: false,
              type: "pie",
            },
            title: {
              text: "DEMOGRAPHICS",
              style: { fontSize: "10px", margin: "0" },
            },

            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                  enabled: false,
                  format: "<b>{point.name}",
                  distance: -100,
                  filter: {
                    property: "percentage",
                    operator: ">",
                    value: 4,
                  },
                },
              },
            },
            series: [
              {
                name: "Percent",
                colorByPoint: true,
                data: mydata,
              },
            ],
          }}
        ></HighchartReact>
      </div>
    );
  };

  const CampaignTab = (
    title,
    img,
    _id,
    Spend,
    channels,
    startDate,
    endDate,
    brandMetrics,
    demographics,
    status
  ) => {
    let sdx = new Date(startDate);
    let edx = new Date(endDate);
    let sd =
      sdx.getUTCDate().toString() +
      "/" +
      sdx.getUTCMonth().toString() +
      "/" +
      sdx.getUTCFullYear().toString();
    let ed =
      edx.getUTCDate().toString() +
      "/" +
      edx.getUTCMonth().toString() +
      "/" +
      edx.getUTCFullYear().toString();

    console.log("demographics", demographics)
    return (
      <div
        style={{
          boxShadow: "0px 0px 10px rgba(0,0,0,0.20), 0 0 10px rgba(0,0,0,0.15)",
          borderRadius: "16px",
          marginRight: "30px",
          marginTop: "30px",
          border: "10px solid #ffffff",
          transition: "all 0.15s",
        }}
      >
        <div
          style={{
            padding: "7px 20px",
            backgroundColor: "blue",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            color: "white",
            display: "flex",
            justifyContent: "space-between",
            background: "linear-gradient(to right, #9211a2, #4911a2)",
          }}
        >
          <span title={`${title}`} className="mb-0 task-name">
            {title.substring(0, 20).concat("...")}
          </span>
          <span>
            {" "}
            {status === 0 ? "Pending" : "Booked"}{" "}
            <span
              style={status === 0 ? { color: "orange" } : { color: "#04E606" }}
            >
              &#9679;
            </span>{" "}
          </span>
        </div>
        <div

        // onClick={() => history.push(`/media-owner/singleMediaView?id=${_id}`)}
        >
          <div
            style={{
              boxShadow:
                "0px 0px 10px rgba(0,0,0,0.20), 0 0 10px rgba(0,0,0,0.15)",
              width: "280px",
              height: "100px",
              display: "block",
              backgroundImage: `url(${img})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              position: "relative",
              cursor: "pointer",
              backgroundColor: "#ffffff",
            }}
            onClick={() => {
              getSingleCamapaign(_id);
              setOpen(true);
            }}
          >
            <div
              style={{
                backgroundImage: `url(${LeftArrow})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                width: "35%",
                textAlign: "center",
                color: "blue",
              }}
            >
              &#x20AC;{Spend}
            </div>
            <div
              style={{
                position: "absolute",
                bottom: "0",
                right: "0",
                backgroundImage: `url(${RightArrow})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                width: "55%",
                textAlign: "center",
                color: "blue",
                padding: "0 5px",
              }}
            >
              {sd}-{ed}
            </div>
          </div>
          <div
            style={{
              width: "280px",
              display: "block",
              backgroundColor: "orange",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-around",
              height: "45px",
              alignItems: "center",
            }}
          >
            {channels.map((e) => (
              <span style={{ padding: "0 5px", color: "white" }}>{e.name}</span>
            ))}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            background: "#ffffff",
            borderRadius: "0 0 5px 5px",
          }}
        >
          <div style={{ width: "48%", height: "170px" }}>
            <MyChart demographics={demographics} />
          </div>
          <div style={{ width: "48%", margin: "10px" }}>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              {/* <div style={{ width: "45%", textAlign: "center" }}>
                <span style={{ display: "block", fontWeight: "700" }}>
                  Uplift
                </span>
                <span style={{ fontWeight: "800", color: "green" }}>
                  {parseFloat(brandMetrics?.brandUplift).toFixed(2)}%
                </span>
              </div> */}
              <div style={{ width: "45%", textAlign: "center" }}>
                <span style={{ display: "block", fontWeight: "700" }}>
                  Uplift
                </span>
                <span style={{ fontWeight: "800", color: "green" }}>
                  {brandMetrics?.brandUplift
                    ? parseFloat(brandMetrics?.brandUplift).toFixed(2)
                    : "0.0"}
                </span>
              </div>
            </div>

            {[
              {
                name: "AWARENESS",
                val: parseFloat(brandMetrics?.awareness || 0).toFixed(2),
              },
              {
                name: "CONSIDERATION",
                val: parseFloat(brandMetrics?.consideration || 0).toFixed(2),
              },
              {
                name: "PREFERENCE",
                val: parseFloat(brandMetrics?.preference || 0).toFixed(2),
              },
              {
                name: "ACTION INTENT",
                val: parseFloat(brandMetrics?.actionIntent || 0).toFixed(2),
              },
            ].map((e) => (
              <div style={{ marginTop: "5px" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span
                    style={{
                      marginBottom: "0",
                      color: "grey",
                      fontSize: "8px",
                    }}
                  >
                    {" "}
                    {e.name}
                  </span>
                  <span
                    style={{
                      marginBottom: "0",
                      color: "grey",
                      fontSize: "8px",
                    }}
                  >
                    {" "}
                    {e.val} % &#9650;
                  </span>
                </div>

                <div
                  style={{
                    backgroundColor: `rgb(${Math.random() * 255} ${
                        Math.random() * 255
                      } ${Math.random() * 255})`,
                    height: "10px",
                    width: "100%",
                    borderRadius: "5px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: `rgb(${Math.random() * 255} ${
                        Math.random() * 255
                      } ${Math.random() * 255})`,
                      height: "10px",
                      width: `${e.val}%`,
                      borderRadius: "5px",
                    }}
                  ></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  function downloadDigital() {
    if (InvAll.Digital.length <= 0) return;
    var array = [];

    InvAll.Digital.forEach((e) => {
      let toPush = { ...e };
      let sdx = new Date(e.startDate);
      let sd =
        sdx.getUTCDate().toString() +
        "-" +
        sdx.getUTCMonth().toString() +
        "-" +
        sdx.getUTCFullYear().toString();
      let edx = new Date(e.endDate);
      let ed =
        edx.getUTCDate().toString() +
        "-" +
        edx.getUTCMonth().toString() +
        "-" +
        edx.getUTCFullYear().toString();
      toPush.startDate = sd;
      toPush.endDate = ed;
      array.push(toPush);
    });

    var csv = Papa.unparse(array);

    var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    var csvURL = null;
    if (navigator.msSaveBlob) {
      csvURL = navigator.msSaveBlob(csvData, "Digital_Inventories_Report.csv");
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }

    var tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", "Digital_Inventories_Report.csv");
    tempLink.click();
  }
  function downloadPrint() {
    if (InvAll.Print.length <= 0) return;
    var array = [];

    InvAll.Print.forEach((e) => {
      let toPush = { ...e };
      let sdx = new Date(e.startDate);
      let sd =
        sdx.getUTCDate().toString() +
        "-" +
        sdx.getUTCMonth().toString() +
        "-" +
        sdx.getUTCFullYear().toString();
      let edx = new Date(e.endDate);
      let ed =
        edx.getUTCDate().toString() +
        "-" +
        edx.getUTCMonth().toString() +
        "-" +
        edx.getUTCFullYear().toString();
      toPush.startDate = sd;
      toPush.endDate = ed;
      array.push(toPush);
    });

    var csv = Papa.unparse(array);

    var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    var csvURL = null;
    if (navigator.msSaveBlob) {
      csvURL = navigator.msSaveBlob(csvData, "Print_Inventories_Report.csv");
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }

    var tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", "Print_Inventories_Report.csv");
    tempLink.click();
  }
  function downloadRadio() {
    if (InvAll.Radio.length <= 0) return;
    var array = [];

    InvAll.Radio.forEach((e) => {
      let toPush = { ...e };
      let sdx = new Date(e.startDate);
      let sd =
        sdx.getUTCDate().toString() +
        "-" +
        sdx.getUTCMonth().toString() +
        "-" +
        sdx.getUTCFullYear().toString();
      let edx = new Date(e.endDate);
      let ed =
        edx.getUTCDate().toString() +
        "-" +
        edx.getUTCMonth().toString() +
        "-" +
        edx.getUTCFullYear().toString();
      toPush.startDate = sd;
      toPush.endDate = ed;
      array.push(toPush);
    });

    var csv = Papa.unparse(array);

    var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    var csvURL = null;
    if (navigator.msSaveBlob) {
      csvURL = navigator.msSaveBlob(csvData, "Radio_Inventories_Report.csv");
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }

    var tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", "Radio_Inventories_Report.csv");
    tempLink.click();
  }
  function downloadOOH() {
    if (InvAll.OOH.length <= 0) return;
    var array = [];

    InvAll.OOH.forEach((e) => {
      let toPush = { ...e };
      let sdx = new Date(e.startDate);
      let sd =
        sdx.getUTCDate().toString() +
        "-" +
        sdx.getUTCMonth().toString() +
        "-" +
        sdx.getUTCFullYear().toString();
      let edx = new Date(e.endDate);
      let ed =
        edx.getUTCDate().toString() +
        "-" +
        edx.getUTCMonth().toString() +
        "-" +
        edx.getUTCFullYear().toString();
      toPush.startDate = sd;
      toPush.endDate = ed;
      array.push(toPush);
    });

    var csv = Papa.unparse(array);

    var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    var csvURL = null;
    if (navigator.msSaveBlob) {
      csvURL = navigator.msSaveBlob(csvData, "OOH_Inventories_Report.csv");
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }

    var tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", "OOH_Inventories_Report.csv");
    tempLink.click();
  }
  function downloadTelevision() {
    if (InvAll.Television.length <= 0) return;
    var array = [];

    InvAll.Television.forEach((e) => {
      let toPush = { ...e };
      let sdx = new Date(e.startDate);
      let sd =
        sdx.getUTCDate().toString() +
        "-" +
        sdx.getUTCMonth().toString() +
        "-" +
        sdx.getUTCFullYear().toString();
      let edx = new Date(e.endDate);
      let ed =
        edx.getUTCDate().toString() +
        "-" +
        edx.getUTCMonth().toString() +
        "-" +
        edx.getUTCFullYear().toString();
      toPush.startDate = sd;
      toPush.endDate = ed;
      array.push(toPush);
    });

    var csv = Papa.unparse(array);

    var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    var csvURL = null;
    if (navigator.msSaveBlob) {
      csvURL = navigator.msSaveBlob(
        csvData,
        "Television_Inventories_Report.csv"
      );
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }

    var tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", "Television_Inventories_Report.csv");
    tempLink.click();
  }

  const downloadCSV = () => {
    downloadDigital();
    downloadPrint();
    downloadRadio();
    downloadOOH();
    downloadTelevision();
  };

  const downloadPdf = (arrayBuffer, fileName) => {
    const blobvalue = Uint8Array.from(arrayBuffer).buffer;
    const blob = new Blob([blobvalue], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(url);
    setTimeout(() => setShowPdfDownloadMsg({isSuccess: true, msg: "Pdf has been downloaded."}))
  };


  const downloadCampaignReport = async ({campaign_id}) => {
    console.log("selected campaign id", slectedCamapaign)
    try{
      const res = await axios
      .get(`${API}/mediaPlanner/campaign/downloadReport/${campaign_id}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      const data = await res?.data?.data?.PDFBuffer?.data
      if(data){
        setShowPdfDownloadMsg({isSuccess: true, msg: "Pdf is being downloaded."})
        downloadPdf(data, "CampaignReport.pdf")
      }
    }catch(err){
      console.log(err)
      setShowPdfDownloadMsg({isSuccess: false, msg: "Something went wrong."})
    }        
  };
  const downloadAllPdf = () => {
    var doc = new jsPDF("l", "pt", "a1");

    if (InvAll.Digital.length > 0) {
      doc.text("Digital", 50, 20);
      let headings = Object.keys(InvAll.Digital[0]);
      let values = [];
      InvAll.Digital.forEach((e) => values.push(Object.values(e)));
      doc.autoTable({
        head: [headings],
        body: values,
      });
    }

    if (InvAll.Print.length > 0) {
      let finalY = doc.lastAutoTable.finalY; // The y position on the page
      doc.text("Print", 50, finalY !== undefined ? finalY + 20 : 20);
      let headings = Object.keys(InvAll.Print[0]);
      let values = [];
      InvAll.Print.forEach((e) => values.push(Object.values(e)));
      doc.autoTable({
        head: [headings],
        body: values,
      });
    }
    if (InvAll.Radio.length > 0) {
      let finalY = doc.lastAutoTable.finalY; // The y position on the page
      doc.text("Radio", 50, finalY !== undefined ? finalY + 20 : 20);
      let headings = Object.keys(InvAll.Radio[0]);
      let values = [];
      InvAll.Radio.forEach((e) => values.push(Object.values(e)));
      doc.autoTable({
        head: [headings],
        body: values,
      });
    }
    if (InvAll.OOH.length > 0) {
      let finalY = doc.lastAutoTable.finalY; // The y position on the page
      doc.text("OOH", 50, finalY !== undefined ? finalY + 20 : 20);
      let headings = Object.keys(InvAll.OOH[0]);
      let values = [];
      InvAll.OOH.forEach((e) => values.push(Object.values(e)));
      doc.autoTable({
        head: [headings],
        body: values,
      });
    }
    if (InvAll.Television.length > 0) {
      let finalY = doc.lastAutoTable.finalY; // The y position on the page
      doc.text("Television", 50, finalY !== undefined ? finalY + 20 : 20);
      let headings = Object.keys(InvAll.Television[0]);
      let values = [];
      InvAll.Television.forEach((e) => values.push(Object.values(e)));
      doc.autoTable({
        head: [headings],
        body: values,
      });
    }
    if(InvAll.Television.length > 0 || InvAll.OOH.length > 0 || InvAll.Radio.length > 0 || InvAll.Print.length > 0 || InvAll.Digital.length > 0){
      doc.save("Inventories_Report.pdf");
    }
  };

  useEffect(() => {
    if (DATA) {
      if (InvChannel === "Digital") {
        let td = [];
        DATA.forEach((e) => {
          const converted = ConvertDigital(e);
          td.push({
            data: Object.values(converted),
            _id: e._id,
          });
        });
        setTableData(td);
        let yyy = ConvertDigital(DATA[0]);
        if (yyy) {
          let zzz = Object.keys(yyy);
          let xxx = zzz.map((e) => e.replaceAll("_", " "));
          setTableHeads(xxx);
        }
      }
      if (InvChannel === "Print") {
        let td = [];
        DATA.forEach((e) => {
          const converted = ConvertPrint(e);
          td.push({
            data: Object.values(converted),
            _id: e._id,
          });
        });
        setTableData(td);
        let yyy = ConvertPrint(DATA[0]);
        if (yyy) {
          let zzz = Object.keys(yyy);
          let xxx = zzz.map((e) => e.replaceAll("_", " "));
          setTableHeads(xxx);
        }
      }
      if (InvChannel === "Radio") {
        let td = [];
        DATA.forEach((e) => {
          const converted = ConvertRadio(e);
          td.push({
            data: Object.values(converted),
            _id: e._id,
          });
        });
        setTableData(td);
        let yyy = ConvertRadio(DATA[0]);
        if (yyy) {
          let zzz = Object.keys(yyy);
          let xxx = zzz.map((e) => e.replaceAll("_", " "));
          setTableHeads(xxx);
        }
      }
      if (InvChannel === "OOH") {
        let td = [];
        DATA.forEach((e) => {
          const converted = ConvertOOH(e);
          td.push({
            data: Object.values(converted),
            _id: e._id,
          });
        });
        setTableData(td);
        let yyy = ConvertOOH(DATA[0]);
        if (yyy) {
          let zzz = Object.keys(yyy);
          let xxx = zzz.map((e) => e.replaceAll("_", " "));
          setTableHeads(xxx);
        }
      }
      if (InvChannel === "Television") {
        let td = [];
        DATA.forEach((e) => {
          const converted = ConvertTelevision(e);
          td.push({
            data: Object.values(converted),
            _id: e._id,
          });
        });
        setTableData(td);
        let yyy = ConvertTelevision(DATA[0]);
        if (yyy) {
          let zzz = Object.keys(yyy);
          let xxx = zzz.map((e) => e.replaceAll("_", " "));
          setTableHeads(xxx);
        }
      }
    }
  }, [DATA]);

  useEffect(() => {
    if (InvAll) {
      if (InvChannel === "Digital") {
        setDATA(InvAll.Digital);
      }
      if (InvChannel === "Print") {
        setDATA(InvAll.Print);
      }
      if (InvChannel === "Radio") {
        setDATA(InvAll.Radio);
      }
      if (InvChannel === "OOH") {
        setDATA(InvAll.OOH);
      }
      if (InvChannel === "Television") {
        setDATA(InvAll.Television);
      }
      let btns = [];
      if (InvAll?.Digital?.length > 0) {
        btns.push("Digital");
      }
      if (InvAll?.Print?.length > 0) {
        btns.push("Print");
      }
      if (InvAll?.Radio?.length > 0) {
        btns.push("Radio");
      }
      if (InvAll?.OOH?.length > 0) {
        btns.push("OOH");
      }
      if (InvAll?.Television?.length > 0) {
        btns.push("Television");
      }
      console.log(btns);
      setChanBtns(btns);
    }
  }, [InvAll, InvChannel]);

  const BookCampaign = () => {
    return (
      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "none",
        }}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        //className={classes.modal}
        open={open}
        fullWidth
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          {
            <div className={classes.boxShadow} style={{ minWidth: "70vw" }}>
              {!loadingCampaign && (
                <div
                  className={classes.customfield}
                  style={{
                    width: "100%",
                    padding: "20px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          boxShadow:
                            "0px 0px 10px rgba(0,0,0,0.20), 0 0 10px rgba(0,0,0,0.15)",
                          width: "150px",
                          height: "100px",
                          display: "block",
                          backgroundImage: `url(${slectedCamapaign?.image})`,
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          position: "relative",
                          cursor: "pointer",
                        }}
                      ></div>
                      <div style={{ margin: "0 20px", width: "20%" }}>
                        <h2>{slectedCamapaign?.campaignName}</h2>
                        <p>{slectedCamapaign?.description}</p>
                      </div>
                    </div>
                    <div>
                      {ChanBtns.map((e) => {
                        return (
                          <Button
                            style={
                              InvChannel === e
                                ? {
                                    backgroundColor: "blue",
                                    color: "white",
                                    margin: "10px",
                                  }
                                : {
                                    backgroundColor: "#f1f1f1",
                                    color: "black",
                                    margin: "10px",
                                  }
                            }
                            onClick={() => setInvChannel(e)}
                          >
                            {e}
                          </Button>
                        );
                      })}
                    </div>
                    <div>
                      <h3>Campaign Report</h3>
                      <button
                        style={{
                          padding: "0",
                          backgroundColor: "transparent",
                          border: "none",
                          cursor: "pointer",
                          display: "flex", alignItems: "center"
                        }}
                        onClick={() => downloadCampaignReport({campaign_id: slectedCamapaign?._id})}
                      >
                        <span style={{ marginBottom: "0px", marginRight: "5px" }}>PDF</span>{" "}
                        <CloudDownloadIcon width="30px" />
                      </button>
                      {/* <button
                        style={{
                          padding: "0",
                          backgroundColor: "transparent",
                          border: "none",
                          cursor: "pointer",
                        }}
                        onClick={() => downloadCSV()}
                      >
                        <span style={{ marginBottom: "10px" }}>CSV</span>{" "}
                        <CloudDownloadIcon width="30px" />
                      </button> */}
                    </div>
                    
                    <div>
                      <h3>Inventory Report</h3>
                      <button
                        style={{
                          padding: "0",
                          backgroundColor: "transparent",
                          border: "none",
                          cursor: "pointer",
                        }}
                        onClick={() => downloadAllPdf()}
                      >
                        <span style={{ marginBottom: "10px" }}>PDF</span>{" "}
                        <CloudDownloadIcon width="30px" />
                      </button>
                      <button
                        style={{
                          padding: "0",
                          backgroundColor: "transparent",
                          border: "none",
                          cursor: "pointer",
                        }}
                        onClick={() => downloadCSV()}
                      >
                        <span style={{ marginBottom: "10px" }}>CSV</span>{" "}
                        <CloudDownloadIcon width="30px" />
                      </button>
                    </div>
                  </div>

                  {/* <div style={{ maxWidth: "800px", maxHeight: "400px" }}>
                    <CustomizedTables
                      isEditable={false}
                      headings={tableHeads}
                      listdata={tableData}
                    />
                  </div> */}

                  {slectedCamapaign?.status === 0 && (
                    <TextField
                      fullWidth
                      placeholder="Email"
                      label="Email"
                      variant="outlined"
                      style={{
                        display: "block",
                        marginBottom: "10px",
                        marginTop: "20px",
                      }}
                      value={editFormEmail}
                      onChange={(e) => {
                        setEditFormEmail(e.target.value);
                      }}
                    />
                  )}

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {slectedCamapaign?.status === 0 && (
                      <button
                        disabled={!validatedEmail}
                        fullWidth
                        onClick={() => {
                          //setOpen(false);
                          sendEmail(slectedCamapaign?._id);
                          // console.log(slectedCamapaign)
                        }}
                        style={
                          validatedEmail
                            ? {
                                backgroundColor: "blue",
                                color: "white",
                                borderRadius: "7px",
                                padding: "10px 25px",
                                border: "none",
                                width: "20%",
                                cursor: "pointer"
                              }
                            : {
                                backgroundColor: "#f1f1f1",
                                color: "white",
                                borderRadius: "7px",
                                padding: "10px 25px",
                                border: "none",
                                width: "20%",
                              }
                        }
                      >
                        Share Campaign Report 
                        {/* Authorise */}
                      </button>
                    )}
                    {isBook && (
                      <button
                        fullWidth
                        onClick={() => {
                          //setOpen(false);
                          BookCall();
                        }}
                        style={{
                          backgroundColor: "blue",
                          color: "white",
                          borderRadius: "7px",
                          padding: "10px 25px",
                          border: "none",
                          width: "20%",
                          marginLeft: "10px",
                        }}
                      >
                        Book
                      </button>
                    )}
                  </div>
                </div>
              )}

              {loadingCampaign && <CircularProgress />}
            </div>
          }
        </Fade>
      </Modal>
    );
  };

  return (
    <div>
      {decodedSubscription && decodedSubscription.subscription === "free" ? (
        <Subscription />
      ) : (
        <div>
          {BookCampaign()}
          <h2 style={{ color: "#4F74C9", fontWeight: "400" }}>Campaigns</h2>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              onClick={() => handleClick()}
              className={classes.addActionBtn}
              style={{ borderRadius: "50px" }}
            >
              <AddCircleOutlineIcon
                style={{ marginRight: "5px", color: "white" }}
              />
              Create Campaign
            </Button>
            <div
              className={classes.addActionBtn}
              style={{
                borderRadius: "50px",
                backgroundColor: "white",
                paddingRight: "7px",
                paddingLeft: "7px",
                width: "84%",

                boxShadow:
                  "0px 0px 10px rgba(0,0,0,0.20), 0 0 10px rgba(0,0,0,0.15)",
              }}
            >
              <input
                placeholder="Search Campaign"
                //className={classes.addActionBtn}
                style={{
                  outline: "none",
                  backgroundColor: "white",
                  border: "solid 2px #E5E5E5",
                  color: "black",
                  borderRadius: "23px",
                  padding: "7px",
                  width: "50%",
                }}
                onChange={(event) => {
                  // console.log(event.target.value);
                  // let toshow = campaigns.filter((e) =>
                  //   e.campaignName
                  //     .toLowerCase()
                  //     .includes(event.target.value.toLowerCase())
                  // );
                  // setfilterdList(toshow);
                  setSearch(event.target.value);
                }}
              ></input>
            </div>
          </div>
          {/* <div style={{marginTop: "1.5rem"}}>
              <img 
                src={campaignButton} 
                alt={"Campaign report"} 
                onClick={() => history.push("/media-planner/campaign/report")}
                className={classess.campaignBtn}
              />
          </div> */}
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "15%",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div>
              <div
                style={{ display: "flex", padding: "30px", flexWrap: "wrap" }}
              >
                {campaigns.length > 0 &&
                  campaigns?.map((e) =>
                    CampaignTab(
                      e.campaignName,
                      e.image,
                      e._id,
                      e.spendAmount,
                      e.channels,
                      e.startDate,
                      e.endDate,
                      e.brandMetrics,
                      e.demographics,
                      e.status
                    )
                  )}
                {campaigns.length <= 0 && <h2>No Campaign Found!</h2>}
              </div>
              {pageCount > 0 && (
                <div
                  style={{
                    display: "flex",
                    marginTop: "20px",
                    justifyContent: "center",
                  }}
                >
                  <span
                    style={{
                      color: "#FB6D6A",
                      fontWeight: "600",
                      margin: "0",
                      fontSize: "20px",
                      marginRight: "20px",
                    }}
                  >
                    <Pagination
                      page={page}
                      count={Math.ceil(pageCount / 10)}
                      color="primary"
                      onChange={(e, page) => setPage(page)}
                      size="small"
                    />
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
      )}
      <NewAlert
        close={() => setShowSendEmailSuccessMsg(null)}
        msg={ShowSendEmailSuccessMsg}
        isSuccess={ShowSendEmailSuccessMsg}
      />
      <NewAlert
        close={() => setShowPdfDownloadMsg({isSuccess: false, msg: ""})}
        msg={ShowPdfDownloadMsg.msg}
        isSuccess={ShowPdfDownloadMsg.isSuccess}
      />
    </div>
  );
};

export default MainLayout(MediaPlanner);
