import React, { useEffect, useRef, useState } from "react";

import MainLayout from "../../../layouts/adminMainLayout";

import Pagination from "@material-ui/lab/Pagination";

// import CircularProgress from "@material-ui/core/CircularProgress";
//Button
// import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
//Icon

import { Button, CircularProgress, Select } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import MenuItem from "@material-ui/core/MenuItem";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import axios from "axios";
import { useHistory } from "react-router-dom";
import NewAlert from "../../../components/NewAlert";
import useStyles from "../../../components/StyleClasses";
import { API, SECRET_KEY } from "../../../constants/index";
import CustomizedTables from "../../mediaOwner/mytable";


const useStylesModal = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "10px",
    width: "580px",
  },
}))

const getCompanyList = () => {
  const access_token = window.localStorage.getItem(SECRET_KEY)
  return axios.get(`${API}/admin/admin-company`, {
    headers: { Authorization: `Bearer ${access_token}` },
  })
}

function GreenAdCampaigns() {
  const classes = useStyles();
  const classesModal = useStylesModal();
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setpage] = useState(1);
  const access_token = window.localStorage.getItem(SECRET_KEY);
  const [pagecount, setPageCount] = useState(0);
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [file, setFile] = useState(false);
  const fileInputRef = useRef();
  const [ErrSucMsg, setErrSucMesg] = useState("");
  const [ErrSucType, setErrSucType] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [companyList, setCompanyList] = useState([])
  const [campaignCompany, setCampaignCompany] = useState("")
  const [campaignName, setCampaignName] = useState("")
  const [campaignGreenTagId, setCampaignGreenTagId] = useState("")
  const [campaignList, setCampaignList] = useState([])
  const [campaignId, setCampaignId] = useState("")

  

  const handleCompanyChange = (e) => {
    setCampaignCompany(e.target.value)
  }
  
  const handleModalClose = () => {
    setCampaignName("")
    setCampaignGreenTagId("")
    setCampaignCompany("")
    setCampaignId("")
    setModalOpen(false)
  }

  const getCampaigns = () => {
    setLoading(true)
    const access_token = window.localStorage.getItem(SECRET_KEY)
    axios
      .get(`${API}/admin/greenAdCampaigns/campaigns`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        console.log(res.data.campaigns)
        // setCampaignList(res.data.campaigns)
        let td = res.data.campaigns?.map((item) => {
          // return [item.name, item.greenTagId, item.company.companyName]
          return {
            data: [item.name, item.campaignId, item.company.companyName], _id: item._id
          }
        })
        console.log(td, "td")
        setTableData(td)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  const createCampaign = () => {
    const access_token = window.localStorage.getItem(SECRET_KEY)
    axios
      .post(
        `${API}/admin/greenAdCampaigns/create-campaign`,
        {
          name: campaignName,
          // greenTagId: campaignGreenTagId,
          campaignId: campaignId,
          company: campaignCompany,
        },
        {
          headers: { Authorization: `Bearer ${access_token}` },
        }
      )
      .then((res) => {
        console.log(res)
        getCampaigns()
        setModalOpen(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    getCampaigns()
    getCompanyList().then((res) => {
      let companyData = res.data.data.map((item) => {
        return {
          value: item._id,
          label: item.companyName,
        }
      })
      setCompanyList(companyData)
    })
  }, [])

  return (
    <div>
      <NewAlert
          msg={ErrSucMsg}
          isSuccess={ErrSucType}
          close={() => setErrSucMesg("")}
        />
         {deleteFlag && (
          <Dialog
            open={deleteFlag}
            onClose={() => {
              setDeleteFlag(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Are you sure, you want to delete Green Ad Campaign?
            </DialogTitle>
            <DialogActions>
              <Button
                style={{ color: "black", backgroundColor: "grey" }}
                onClick={() => {
                  setDeleteFlag(false);
                }}
              >
                Cancel
              </Button>
              <Button
                style={{ backgroundColor: "#4F74C9", color:"white"}}
                onClick={() => {
                  setDeleteFlag(false);
                  // DeletePlanner();
                }}
                autoFocus
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        )}
      <h2 style={{ color: "#4F74C9", fontWeight: "700" }}>Green Ad Campaigns</h2>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          onClick={() => setModalOpen(true)}
          className={classes.addActionBtn}
          variant="outlined"
          color="primary"
          style={{ width: "20%", borderRadius: "50px" }}
        >
          <AddCircleOutlineIcon
            style={{ marginRight: "5px", color: "white" }}
          />
          Add Green Ad Campaign
        </Button>
      </div>
      <div style={{marginLeft: "8px"}}>
        <p><strong>Note:</strong> The Green Tag Id is from the Good Loop dashboard</p>
      </div>
      <div className={classes.boxShadow} style={{ marginTop: "40px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <span
            style={{
              color: "#FB6D6A",
              fontWeight: "600",
              margin: "0",
              fontSize: "20px",
              marginLeft: "20px",
            }}
          >
            Campaigns
          </span>
          <span
            style={{
              color: "#FB6D6A",
              fontWeight: "600",
              margin: "0",
              fontSize: "20px",
              marginRight: "20px",
            }}
          >
            <Pagination
              count={Math.ceil(pagecount / 10)}
              color="primary"
              onChange={(e, page) => setpage(page)}
              size="small"
            />
          </span>
        </div>
        <CustomizedTables
          radioList={["Free","Paid"]}
          // isEditable={true}
          headings={["Name", "Campaign ID", "Company"]}
          listdata={tableData}
          redirectLink={"MediaPlannerProfile?id="}
          handleDelete={(_id) => {
            setDeleteFlag(true);
            setDeleteId(_id);
          }}
        />
        <div style={{ display: "flex", justifyContent: "center" }}>
          {loading && <CircularProgress />}
        </div>
      </div>

      {/* Create Campaign */}
      <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classesModal.modal}
          open={modalOpen}
          onClose={handleModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
              timeout: 500,
          }}
      >
          <Fade in={modalOpen}>
              <div className={classesModal.paper}>
                  <h2 className="campaign_modal_title">Create Campaign</h2>
                  <div className="campaign-modal-input-cont">
                      <TextField
                          id="campaign_title"
                          label="Campaign Name"
                          variant="outlined"
                          value={campaignName}
                          onChange={(e) => setCampaignName(e.target.value)}
                      />
                      {/* <TextField
                          id="campaign_campaign_id"
                          label="Green Tag Id"
                          variant="outlined"
                          value={campaignGreenTagId}
                          onChange={(e) => setCampaignGreenTagId(e.target.value)}
                      /> */}
                      <TextField
                          id="campaign_campaign_id"
                          label="Campaign Id"
                          variant="outlined"
                          value={campaignId}
                          onChange={(e) => setCampaignId(e.target.value)}
                      />
                      <Select
                        labelId="outlined-select-company"
                        id="outlined-select-company"
                        label="Company"
                        onChange={(e) => handleCompanyChange(e)}
                        value={campaignCompany}
                        helperText="Please select the Company for this campaign"
                        variant="standard"
                      >
                        {companyList.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                          <div className="modal-submit-campaign">
                              <Button
                                  variant="contained"
                                  color="default"
                                  component="span"
                                  className={`${classesModal.button} submit_campaign_btn`}
                                  onClick={() => createCampaign()}
                              >
                                  Submit
                              </Button>
                          </div>
                      </div>
                  </div>
              </div>
          </Fade>
      </Modal>
      <div style={{ display: "flex", justifyContent: "center" }}>{loading && <CircularProgress />}</div>
    </div>
  )
}

export default MainLayout(GreenAdCampaigns)