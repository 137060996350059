import React, { useState, useRef } from 'react'
import { Avatar, Button, Grid, Modal, Card, CardActions, CardContent, CardHeader } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

import AddModal from "./AddModal"

import CubeImg from "../../../../assets/cube.png"
import AImg from "../../../../assets/letter-A.png"
import VastImg from "../../../../assets/vast.png"

const useStyle = makeStyles(({
    campaignOption: {
        cursor: "pointer",
    }
}))

const AddCampaign = ({popAddCampaign, setPopAddCampaign, adsCampaign}) => {
  const classes = useStyle();
  const selectCampaign = useRef() 

  const [popParticularCampaign, setPopParticularCampaign] = useState(false)
  const [campaignType, setCampaignType] = useState(null)
  
  const selectCampaignHandler = (choose, type) => {
    setPopAddCampaign(false)
    setPopParticularCampaign(true)
    setCampaignType({
        chosen: choose,
        type: type
    })
  }

  return (
      <>
        <Modal 
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{display:"flex", alignItems:"center", justifyContent:"center"}}
            open={popAddCampaign}
        >
            <Card style={{
                width: 500,
                backgroundColor: 'primary.dark',
                '&:hover': {
                backgroundColor: 'primary.main',
                opacity: [0.9, 0.8, 0.7]
                }
            }}>
                <CardHeader
                    avatar={
                    <Avatar sx={{ bgcolor: "red" }} aria-label="recipe">
                        +
                    </Avatar>
                    }
                    title="Create campaign"
                />
                <CardContent>
                    <CardHeader
                        subheader="Select campaign type:"
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={12} md={12} sm={12}>
                            <Card className={classes.campaignOption} onClick={() => selectCampaignHandler("Standard Campaign", "standard")}>
                                <CardHeader
                                    ref={selectCampaign}
                                    avatar={
                                        <Avatar sx={{ bgcolor: "red" }} aria-label="recipe">
                                            <img src={CubeImg} alt="" width="20px" height="20px" />
                                        </Avatar>
                                        }
                                    title="Standard Campaign"
                                    subheader="Create a new standard campaign under Brand Advance"
                                />
                            </Card>
                        </Grid>
                        {/* <Grid item xs={12} lg={12} md={12} sm={12}>
                            <Card className={classes.campaignOption} onClick={() => selectCampaignHandler("Text Campaign", "text")}>
                                <CardHeader
                                    ref={selectCampaign}
                                    avatar={
                                        <Avatar sx={{ bgcolor: "red" }} aria-label="recipe">
                                            <img src={AImg} alt="" width="20px" height="20px" />
                                        </Avatar>
                                        }
                                    title="Text Campaign"
                                    subheader="Create a new Text Campaign under Brand Advance"
                                />
                            </Card>
                        </Grid> */}
                        <Grid item xs={12} lg={12} md={12} sm={12}>
                            <Card className={classes.campaignOption} onClick={() => selectCampaignHandler("VAST Campaign", "vast")}>
                                <CardHeader
                                    ref={selectCampaign}
                                    avatar={
                                        <Avatar sx={{ bgcolor: "red" }} aria-label="recipe">
                                            <img src={VastImg} alt="" width="25px" height="25px" />
                                        </Avatar>
                                        }
                                    title="VAST Campaign"
                                    subheader="Create a new VAST Campaign under Brand Advance"
                                />
                            </Card>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions style={{float:"right"}}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setPopAddCampaign(false)}
                    >
                        Close
                    </Button>
                </CardActions>
            </Card>
        </Modal>
        <AddModal 
            popParticularCampaign={popParticularCampaign} 
            setPopParticularCampaign={setPopParticularCampaign}
            setPopAddCampaign={setPopAddCampaign}
            campaignType={campaignType}
            adsCampaign={adsCampaign}
        />
      </>
  )
}



export default AddCampaign