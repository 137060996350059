import React from "react"
import { FormControl } from "@material-ui/core";
import CustomDropdown from "../../components/CustomDropdown";
import useStyles from "../../components/StyleClasses";

export default function Selection({ value, onChange, options, isLocallyStored, placeholder,isClearable = true,style = {},defaultValue,isMulti }) {
  const classes = useStyles();

  return (
    <FormControl
      fullWidth
      component="fieldset"
      variant="outlined"
      className={[classes.selectControl, classes.customfield].join(" ")}
      style={{
        marginTop: "5px",
        float: "left",
        ...style
      }}
    >
      <CustomDropdown
        isSearchable={true}
        isDisabled={isLocallyStored}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        options={options}
        isClearable={isClearable}
        defaultValue={defaultValue}
        isMulti={isMulti}
      />
    </FormControl>
  );
}
