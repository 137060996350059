import React, { useState, useEffect } from "react";
import MainLayout from "../../layouts/adminMainLayout";
//Button
import Button from "@material-ui/core/Button";

import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { useHistory } from "react-router-dom";
import useStyles from "../../components/StyleClasses";

import Pagination from "@material-ui/lab/Pagination";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import CircularProgress from "@material-ui/core/CircularProgress";
import user from "../../assets/user.png";
import templateImg from "../../assets/template.png";
import axios from "axios";
import { API, SECRET_KEY } from "../../constants/index";
import SinglePersona from "./singlePersona";
import NewAlert from "../../components/NewAlert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

const MoPublications = () => {
  const history = useHistory();
  const classes = useStyles();
  const [channel, setChannel] = useState("Television");
  const [frequency, setFrequency] = useState(0);
  const [personas, setPersonas] = useState([]);
  //const [filteredList, setfilterdList] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [showPersona, setshowPersona] = useState(false);
  const [personaId, setPersonId] = useState("");
  const [loading, setLoading] = useState(false);
  const [isTemp, setIsTemp] = useState(false);
  const [isUseTemp, setIsUseTemp] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");

 ///////errors & successs //////////
 const [ErrSucMsg, setErrSucMesg] = useState("");
 const [ErrSucType, setErrSucType] = useState(true);

  const access_token = window.localStorage.getItem(SECRET_KEY);

  const getPersonas = () => {
    setLoading(true);
    axios
      .get(`${API}/admin/persona?name=${search}&page=${page}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setPersonas(res.data.data);
          setPageCount(res.data.total);
        }
        if (err) {
        }
        setLoading(false);
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };

  const getPersonaTemplates = () => {
    setLoading(true);
    axios
      .get(
        `${API}/admin/persona/PersonaTemplates?name=${search}&page=${page}`,
        {
          headers: { Authorization: `Bearer ${access_token}` },
        }
      )
      .then((res, err) => {
        if (res) {
          setPersonas(res.data.data);
          setPageCount(res.data.total);
        }
        if (err) {
        }
        setLoading(false);
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };

  // useEffect(() => {
  //   setfilterdList(personas);
  // }, [personas]);

  useEffect(() => {
    if (page !== 1) setPage(1);
    getPersonas();
  }, [search]);
  useEffect(() => {
    getPersonas();
  }, [page]);

  const deletePersona = () => {
    // const payload = { id: id };
    axios
      .delete(`${API}/admin/persona/${deleteId}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setErrSucMesg(res.data.message);
          setErrSucType(true);
          setIsDelete(false);
          getPersonas();
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.data) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
          setIsDelete(false);
          getPersonas();
        }
      });
  };

  const PersonaCard = ({
    name,
    position,
    col,
    handleSelect,
    HandleVuew,
    _id,
    istemplate,
  }) => {
    let colo = "";
    if (istemplate) {
      colo = "blue";
    } else {
      colo = col;
    }

    let fixedTitle = name;
    if (fixedTitle.length > 15) {
      fixedTitle = fixedTitle.substring(0, 15) + "...";
    }
    return (
      <div
        style={{
          boxShadow: "0px 0px 10px rgba(0,0,0,0.20), 0 0 10px rgba(0,0,0,0.15)",
          borderRadius: "16px",
          marginRight: "30px",
          marginTop: "30px",
          width: "200px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
            height: "250",
            width: "150",
          }}
        >
          <DeleteForeverIcon
            style={{ cursor: "pointer", color: "red", float: "right", marginLeft:"85%" }}
            fontSize="small"
            onClick={() => {
              setIsDelete(true);
              setDeleteId(_id);
            }}
          />
          <img
            alt="media.Img"
            src={istemplate ? templateImg : user}
            style={{
              backgroundImage: `linear-gradient(${colo},${colo},${colo}, ${
                istemplate ? "blue" : "white"
              })`,
              width: "140px",
              height: "140px",
              display: "block",
            }}
          ></img>
          <span
            style={{ fontSize: "15px", fontWeight: "700", color: "#136CC3" }}
          >
            {fixedTitle}
          </span>
          {/* <span
            style={{ fontSize: "13px", fontWeight: "400", color: "#136CC3" }}
          >
            {name}
          </span> */}
          <Button
            style={{ backgroundColor: "#136CC3", color: "white", width: "90%" }}
            onClick={() => {
              if (istemplate) setIsTemp(true);
              setPersonId(_id);
              setshowPersona(true);
            }}
          >
            {istemplate ? "Show Template" : "Show Persona"}
          </Button>
          {istemplate && (
            <Button
              style={{
                backgroundColor: "green",
                color: "white",
                marginTop: "10px",
                width: "90%",
              }}
              onClick={() => {
                history.push(`/admin/createPersona?__id=${_id}`);
              }}
            >
              Use Template
            </Button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <NewAlert
        msg={ErrSucMsg}
        isSuccess={ErrSucType}
        close={() => setErrSucMesg("")}
      />

{isDelete && (
        <Dialog
          open={isDelete}
          onClose={() => {
            setIsDelete(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Are you sure, you want to delete Persona?
          </DialogTitle>
          <DialogActions>
            <Button
              style={{ color: "black", backgroundColor: "grey" }}
              onClick={() => {
                setIsDelete(false);
              }}
            >
              Cancel
            </Button>
            <Button
              style={{ backgroundColor: "#4F74C9", color:"white"}}
              onClick={() => {
                setIsDelete(false);
                deletePersona();
              }}
              autoFocus
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {showPersona ? (
        <div>
          <SinglePersona
            id={personaId}
            back={() => setshowPersona(false)}
            istemplate={isTemp}
          />
        </div>
      ) : (
        <div>
          <div>
            <h2 style={{ color: "#4F74C9", fontWeight: "400" }}>
              Media Personas
            </h2>
            <div
              style={{
                display: "flex",
                outline: "none",
                backgroundColor: "white",
                border: "solid 2px #E5E5E5",
                color: "black",
                borderRadius: "23px",
                width: "100%",
              }}
            >
              <Button
                onClick={() => history.push("/admin/createPersona")}
                className={classes.addActionBtn}
                variant="outlined"
                color="primary"
                style={{
                  borderRadius: "23px",
                }}
              >
                <AddCircleOutlineIcon
                  style={{ marginRight: "5px", color: "white" }}
                />
                Create Persona
              </Button>

              <input
                placeholder="Search Personas"
                //className={classes.addActionBtn}
                style={{
                  outline: "none",
                  backgroundColor: "white",
                  border: "solid 2px #E5E5E5",
                  color: "black",
                  borderRadius: "23px",
                  padding: "7px",
                  paddingLeft: "22px",
                  margin: "7px",
                  marginLeft: "-20px",
                  width: "30%",
                }}
                onFocus={() => {
                  if (isTemp) {
                    setIsTemp(false);
                    getPersonas();
                  }
                }}
                onChange={(event) => {
                  setSearch(event.target.value);
                }}
                ////////////////////////////////////////////////////////////////////////////////////////
              ></input>
              <Button
                onClick={() => history.push("/admin/createPersonaTemplate")}
                className={classes.addActionBtn}
                variant="outlined"
                color="primary"
                style={{
                  borderRadius: "23px",
                }}
              >
                <AddCircleOutlineIcon
                  style={{ marginRight: "5px", color: "white" }}
                />
                Create Persona Template
              </Button>
              <input
                placeholder="Search Persona Templates"
                //className={classes.addActionBtn}
                style={{
                  outline: "none",
                  backgroundColor: "white",
                  border: "solid 2px #E5E5E5",
                  color: "black",
                  borderRadius: "23px",
                  padding: "7px",
                  paddingLeft: "22px",
                  margin: "7px",
                  marginLeft: "-20px",
                  width: "35%",
                }}
                onFocus={() => {
                  if (!isTemp) {
                    setIsTemp(true);
                    getPersonaTemplates();
                  }
                }}
                onChange={(event) => {
                  setSearch(event.target.value);
                }}
              ></input>
            </div>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "15%",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  marginTop: "30px",
                  flexWrap: "wrap",
                }}
              >
                {personas.length > 0 &&
                  personas.map((e) => (
                    <PersonaCard
                      name={e.about.name}
                      col={e.avatarColor}
                      _id={e._id}
                      istemplate={isTemp}
                    />
                  ))}

                {personas.length <= 0 && <h2>No Persona Found!</h2>}
              </div>
            )}
          </div>
          {pageCount > 0 && (
            <div
              style={{
                display: "flex",
                marginTop: "20px",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  color: "#FB6D6A",
                  fontWeight: "600",
                  margin: "0",
                  fontSize: "20px",
                  marginRight: "20px",
                }}
              >
                <Pagination
                  page={page}
                  count={Math.ceil(pageCount / 10)}
                  color="primary"
                  onChange={(e, page) => setPage(page)}
                  size="small"
                />
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default MainLayout(MoPublications);
