import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
} from "@material-ui/core/";
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Chart } from "react-google-charts";
import "./Globalmap.css";

import { API, SECRET_KEY } from "../../../constants/index";

export const options = {
  colorAxis: { colors: ["#aacc54", "#8ca954", "#6e8753", "#4f6453", "#314152"] },
  width: "500px",
  legend: "none",
  backgroundColor: "transparent",
  showZoomOut: false,
  tooltip: { isHtml: true, trigger: "visible" },
};

export const genderOptions = {
  title: "Minority Owned Media - No. Digital Publications",
  chartArea: { width: "80%" },
  isStacked: true,
  legend: "none",
  backgroundColor: "transparent",
  titleTextStyle: {
    color: '#fff',
  },
  hAxis: {
    textStyle: {
        color: "#fff"
    },
    titleTextStyle: {
        color: '#fff'
    },
    slantedText: true, 
    slantedTextAngle: 90
  },
  vAxis: {
      textStyle: {
          color: '#fff'
      },
      titleTextStyle: {
          color: '#fff'
      }
  },
  tooltip: {
    isHtml: true
  }
};

const Globalmap = ({ viewWidgets, globalMax }) => {

  const [data, setData] = useState(null)
  const [genderData, setGenderData] = useState(null)

  useEffect(() => {
    const access_token = window.localStorage.getItem(SECRET_KEY)
    axios.get(`${API}/mediaPlanner/intelligence/mapData`,{
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    })
    .then(res => {
      setData(res.data)
    })
  }, [])

  useEffect(() => {
    const access_token = window.localStorage.getItem(SECRET_KEY)
    axios.get(`${API}/mediaPlanner/intelligence/digitalPublisher`,{
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    })
    .then(res => {
      setGenderData(res.data)
    })
  }, [])
  return (
    <>
      {/* <Grid item xs={12} sm={12} md={6}> */}
        <h1 className="header-text">Media landscape</h1>
        <Card
          className={viewWidgets==="global_map" && globalMax ? "global-map-wrapper-full" : "global-map-wrapper"}
          style={{color:"#ffffff"}}
        >
          <h3 style={{display:"flex", justifyContent:"center", padding:"0 0.3em", color: "#ffffff"}}>Minority Owned Media - Global Landscape</h3>
          {
            data && data.length > 0 ?
              <div className="geo-legend">
                <Chart
                  chartType="GeoChart"
                  width="100%"
                  height="400px"
                  data={data}
                  options={options}
                  style={{backgroundColor:"linear-gradient(#4911a2,#9211a2)", flex:2}}
                />
                <ul className="global-table">
                  <h3>Impressions</h3>
                  <li className="global-table-data"><button style={{background:"#aacc54"}}></button> {"< 70.1M"}</li>
                  <li className="global-table-data"><button style={{background:"#8ca954"}}></button> {"70.1M - 140.1M"}</li>
                  <li className="global-table-data"><button style={{background:"#6e8753"}}></button> {"140.1M - 210.2M"}</li>
                  <li className="global-table-data"><button style={{background:"#4f6453"}}></button> {"210.2M - 280.2M"}</li>
                  <li className="global-table-data"><button style={{background:"#314152"}}></button> {"> 280.2M"}</li>
                </ul>
              </div> : 
              <p style={{textAlign:"center", padding:"0 0.3em"}}>No Minority Owned Media found</p>
          }
          {
            genderData && genderData.length > 0 ?
            <Chart
              chartType="ColumnChart"
              width="100%"
              height="700px"
              data={genderData}
              options={genderOptions}
            />
            :
            <div style={{borderTop:"1px solid grey", paddingTop:"0.3em", marginTop:"0.5em"}}>
              <h3 style={{textAlign:"center", padding:"0 0.3em"}}>Minority Owned Media - No. Digital Publications</h3>
              <p style={{textAlign:"center", padding:"0 0.3em"}}>No Digital Publisher found</p>
            </div>
          }
        </Card>
      {/* </Grid>     */}
    </>
  )
}

export default Globalmap