import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useForm } from "react-hook-form";
import MainLayout from "../../layouts/companyMainLayout";
import axios from "axios";
import { useDispatch } from "react-redux";
import NewAlert from "../../components/NewAlert";
import * as actions from "../../redux/actions/user";
import useStyles from "../../components/StyleClasses";
import { useHistory } from "react-router-dom";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import Button from "@material-ui/core/Button";
import Pagination from "@material-ui/lab/Pagination";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomizedTables from "../mediaOwner/mytable";

import TextField from "@material-ui/core/TextField";

import { SECRET_KEY, API } from "../../constants/index";
import {
  Backdrop,
  Fade,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
} from "@material-ui/core";

const DropDowns = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [mediaState, setMediaState] = useState("General");
  const [Model, setModel] = useState(0);
  const [DATA, setDATA] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tableData, settableData] = useState(null);
  const [ElementID, setElementID] = useState(null);
  const [ElementName, setElementName] = useState("");
  const [open, setOpen] = useState(false);
  const [openAddGen, setOpenAddGen] = useState(false);
  const [action, setaction] = useState("none");
  //////////brands////////////////
  const [Industry, setIndustry] = useState(0);
  const [IndustryList, setIndustryList] = useState([]);
  const [BrandDATA, setBrandDATA] = useState(null);
  const [brandTableData, setbrandTableData] = useState(null);
  ///////////adTypes///////////
  const [Channel, setChannel] = useState(0);
  const [ChannelList, setChannelList] = useState([]);
  const [AdTypeDATA, setAdTypeDATA] = useState([]);
  const [AdTypeTableData, setAdTypeTableData] = useState(null);

  ///////errors & successs //////////
  const [ErrSucMsg, setErrSucMesg] = useState("");
  const [ErrSucType, setErrSucType] = useState(true);
  ///////////////////////////////////

  const toggleMedia = (type) => {
    setMediaState(type);
  };

  const ToggleMediaSwitch = () => {
    switch (mediaState) {
      case "General":
        return GeneralPage();
      case "Brands":
        return BrandsPage();
      case "AdTypes":
        return AdTypesPage();
      default:
      // code block
    }
  };

  const getListOfType = () => {
    setLoading(true);
    if (Model === 0) {
      return;
    }
    const access_token = window.localStorage.getItem(SECRET_KEY);
    axios
      .get(`${API}/admin/getSingleDropdownData?&model=${Model}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res, err) => {
        if (res) {
          setDATA(res.data.data);
          setLoading(false);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
          setLoading(false);
        }
      });
  };
  useEffect(() => getListOfType(), [Model]);
  useEffect(() => {
    if (DATA) {
      let td = [];
      DATA.forEach((element) => {
        td.push({ data: [element.name], _id: element._id });
      });
      settableData(td);
    }
  }, [DATA]);

  const addGen = () => {
    setLoading(true);
    const access_token = window.localStorage.getItem(SECRET_KEY);
    const body = { name: ElementName, model: Model };
    axios
      .post(`${API}/admin/addData`, body, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res, err) => {
        if (res) {
          getListOfType();
          getIndustries();
          getChannels();
          setErrSucMesg(res.data.message);
          setErrSucType(true);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
          getListOfType();
          getIndustries();
          getChannels();
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
        }
      });
  };
  const addBrand = () => {
    setLoading(true);
    const access_token = window.localStorage.getItem(SECRET_KEY);
    const body = { name: ElementName, industry: Industry };
    axios
      .post(`${API}/admin/AddBrand`, body, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res, err) => {
        if (res) {
          getBrandsByIndustry();
          setErrSucMesg(res.data.message);
          setErrSucType(true);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
          getBrandsByIndustry();
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
        }
      });
  };
  const updateGEN = () => {
    setLoading(true);
    const access_token = window.localStorage.getItem(SECRET_KEY);
    const body = { name: ElementName, model: Model };
    axios
      .put(`${API}/admin/updateDropdownData/${ElementID}`, body, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res, err) => {
        if (res) {
          getListOfType();
          getIndustries();
          getChannels();
          setErrSucMesg(res.data.message);
          setErrSucType(true);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
          getListOfType();
          getIndustries();
          getChannels();
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
        }
      });
  };
  const updateBrand = () => {
    setLoading(true);
    const access_token = window.localStorage.getItem(SECRET_KEY);
    const body = { name: ElementName, industry: Industry };
    axios
      .put(`${API}/admin/updateBrand/${ElementID}`, body, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res, err) => {
        if (res) {
          getBrandsByIndustry();
          setErrSucMesg(res.data.message);
          setErrSucType(true);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
          getBrandsByIndustry();
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
        }
      });
  };

  const addAdType = () => {
    setLoading(true);
    const access_token = window.localStorage.getItem(SECRET_KEY);
    const body = { name: ElementName, channel: Channel };
    axios
      .post(`${API}/admin/addType`, body, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res, err) => {
        if (res) {
          getAdTypeByChannel();
          setErrSucMesg(res.data.message);
          setErrSucType(true);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
          getAdTypeByChannel();
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
        }
      });
  };
  const updateAdType = () => {
    setLoading(true);
    const access_token = window.localStorage.getItem(SECRET_KEY);
    const body = { name: ElementName, channel: Channel };
    axios
      .put(`${API}/admin/updateadType/${ElementID}`, body, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res, err) => {
        if (res) {
          getAdTypeByChannel();
          setErrSucMesg(res.data.message);
          setErrSucType(true);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
          getAdTypeByChannel();
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
        }
      });
  };

  const getIndustries = () => {
    const access_token = window.localStorage.getItem(SECRET_KEY);
    axios
      .get(`${API}/admin/getSingleDropdownData?&model=industry`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res, err) => {
        if (res) {
          setIndustryList(res.data.data);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };
  useEffect(() => getIndustries(), []);

  const getBrandsByIndustry = () => {
    if (Industry === 0) return;
    setLoading(true);
    const access_token = window.localStorage.getItem(SECRET_KEY);
    axios
      .get(`${API}/admin/brands?industry=${Industry}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res, err) => {
        if (res) {
          setBrandDATA(res.data);
          setLoading(false);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
          setLoading(false);
        }
      });
  };
  useEffect(() => getBrandsByIndustry(), [Industry]);
  useEffect(() => {
    if (BrandDATA) {
      let td = [];
      BrandDATA.forEach((element) => {
        td.push({ data: [element.name], _id: element._id });
      });
      setbrandTableData(td);
    }
  }, [BrandDATA]);

  const getChannels = () => {
    const access_token = window.localStorage.getItem(SECRET_KEY);
    axios
      .get(`${API}/admin/getSingleDropdownData?&model=mediaChannels`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res, err) => {
        if (res) {
          setChannelList(res.data.data);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };
  useEffect(() => getChannels(), []);

  const getAdTypeByChannel = () => {
    setLoading(true);
    const access_token = window.localStorage.getItem(SECRET_KEY);
    axios
      .get(`${API}/admin/getSingleDropdownData?&model=adTypes`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res, err) => {
        if (res) {
          setAdTypeDATA(res.data.data);
          setLoading(false);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };
  useEffect(() => getAdTypeByChannel(), [Channel]);
  useEffect(() => {
    if (AdTypeDATA.length > 0) {
      let td = [];
      if (AdTypeDATA.length > 0) {
        const newList = AdTypeDATA.filter((e) => e?.channel?._id == Channel);
        newList.forEach((element) => {
          td.push({ data: [element.name], _id: element._id });
        });
        setAdTypeTableData(td);
      }
    }
  }, [AdTypeDATA]);

  const uppercaseToNormal = (txt) => {
    const text = txt;
    const result = text.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  };

  const GeneralPage = () => {
    return (
      <div
        className={classes.boxShadow}
        style={{
          marginTop: "-50px",
          paddingTop: "50px",
          paddingBottom: "50px",
        }}
      >
        <div className={classes.customfield}>
          <TextField
            style={{ display: "block", marginBottom: "10px" }}
            id="Model"
            select
            fullWidth
            label="Dropdown Type"
            value={Model}
            onChange={(e) => {
              setModel(e.target.value);
            }}
            SelectProps={{
              native: true,
            }}
            variant="outlined"
          >
            <option disabled key={0} value={0}>
              Select...
            </option>
            {[
              "country",
              "ageGroup",
              "demographics",
              "industry",
              "sentimentsTopic",
              "religion",
              "occupation",
              "disability",
              "mediaChannels",
              "gender",
              "maritalStatus",            
              "sexuality",
              "bodyType"
            ].map((option) => (
              <option key={option} value={option}>
                {uppercaseToNormal(option)}
              </option>
            ))}
          </TextField>
          {Model !== 0 && (
            <button
              fullWidth
              onClick={() => {
                setElementName("");
                setOpenAddGen(true);
              }}
              style={{
                backgroundColor: "blue",
                color: "white",
                borderRadius: "7px",
                padding: "10px 25px",
                border: "none",
                marginBottom: "20px",
                cursor: "pointer",
              }}
            >
              Add
            </button>
          )}
          {Model !== 0 && (
            <>
              {loading ? (
                <div>
                  <CircularProgress />
                </div>
              ) : (
                <CustomizedTables
                  isEditable={true}
                  listdata={tableData}
                  headings={[Model]}
                  HandleCSV={(row) => {
                    setElementID(row._id);
                    setOpen(true);
                    setElementName(row.data[0]);
                  }}
                />
              )}
            </>
          )}
        </div>
      </div>
    );
  };
  const BrandsPage = () => {
    return (
      <div
        className={classes.boxShadow}
        style={{
          marginTop: "-50px",
          paddingTop: "50px",
          paddingBottom: "50px",
        }}
      >
        <div className={classes.customfield}>
          <TextField
            style={{ display: "block", marginBottom: "10px" }}
            id="Industry"
            select
            fullWidth
            label="Industry"
            value={Industry}
            onChange={(e) => {
              setIndustry(e.target.value);
            }}
            SelectProps={{
              native: true,
            }}
            variant="outlined"
          >
            <option disabled key={0} value={0}>
              Select...
            </option>
            {IndustryList.map((option) => (
              <option key={option._id} value={option._id}>
                {option.name}
              </option>
            ))}
          </TextField>
          {Industry !== 0 && (
            <button
              fullWidth
              onClick={() => {
                setElementName("");
                setOpenAddGen(true);
              }}
              style={{
                backgroundColor: "blue",
                color: "white",
                borderRadius: "7px",
                padding: "10px 25px",
                border: "none",
                marginBottom: "20px",
                cursor: "pointer",
              }}
            >
              Add
            </button>
          )}
          {Industry !== 0 && (
            <>
              {loading ? (
                <div>
                  <CircularProgress />
                </div>
              ) : (
                <CustomizedTables
                  isEditable={true}
                  listdata={brandTableData}
                  headings={["Brands"]}
                  HandleCSV={(row) => {
                    setElementID(row._id);
                    setOpen(true);
                    setElementName(row.data[0]);
                  }}
                />
              )}
            </>
          )}
        </div>
      </div>
    );
  };
  const AdTypesPage = () => {
    return (
      <div
        className={classes.boxShadow}
        style={{
          marginTop: "-50px",
          paddingTop: "50px",
          paddingBottom: "50px",
        }}
      >
        <div className={classes.customfield}>
          <TextField
            style={{ display: "block", marginBottom: "10px" }}
            id="Channel"
            select
            fullWidth
            label="Channel"
            value={Channel}
            onChange={(e) => {
              setChannel(e.target.value);
            }}
            SelectProps={{
              native: true,
            }}
            variant="outlined"
          >
            <option disabled key={0} value={0}>
              Select...
            </option>
            {ChannelList.map((option) => (
              <option key={option._id} value={option._id}>
                {option.name}
              </option>
            ))}
          </TextField>
          {Channel !== 0 && (
            <button
              fullWidth
              onClick={() => {
                setElementName("");
                setOpenAddGen(true);
              }}
              style={{
                backgroundColor: "blue",
                color: "white",
                borderRadius: "7px",
                padding: "10px 25px",
                border: "none",
                marginBottom: "20px",
                cursor: "pointer",
              }}
            >
              Add
            </button>
          )}
          {Channel !== 0 && (
            <>
              {loading ? (
                <div>
                  <CircularProgress />
                </div>
              ) : (
                <CustomizedTables
                  isEditable={true}
                  listdata={AdTypeTableData}
                  headings={["Ad Types"]}
                  HandleCSV={(row) => {
                    setElementID(row._id);
                    setOpen(true);
                    setElementName(row.data[0]);
                  }}
                />
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <NewAlert
        msg={ErrSucMsg}
        isSuccess={ErrSucType}
        close={() => setErrSucMesg("")}
      />
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.boxShadow}>
            <div
              className={classes.customfield}
              style={{
                width: "30%",
                padding: "50px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                position: "absolute",
                left: "35%",
                marginTop: "15%",
              }}
            >
              <TextField
                fullWidth
                label={
                  mediaState === "General"
                    ? Model
                    : mediaState === "Brands"
                    ? "Brand Name"
                    : "Ad Type"
                }
                variant="outlined"
                style={{ display: "block", marginBottom: "10px" }}
                value={ElementName}
                onChange={(e) => {
                  setElementName(e.target.value);
                }}
              />

              <button
                fullWidth
                onClick={() => {
                  if (mediaState === "General") updateGEN();
                  if (mediaState === "Brands") updateBrand();
                  if (mediaState === "AdTypes") updateAdType();
                  setOpen(false);
                }}
                style={{
                  backgroundColor: "blue",
                  color: "white",
                  borderRadius: "7px",
                  padding: "10px 25px",
                  border: "none",
                }}
              >
                UPDATE
              </button>
            </div>
          </div>
        </Fade>
      </Modal>
      <Modal
        open={openAddGen}
        onClose={() => setOpenAddGen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openAddGen}>
          <div className={classes.boxShadow}>
            <div
              className={classes.customfield}
              style={{
                width: "30%",
                padding: "50px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                position: "absolute",
                left: "35%",
                marginTop: "15%",
              }}
            >
              <TextField
                fullWidth
                label={
                  mediaState === "General"
                    ? Model
                    : mediaState === "Brands"
                    ? "Brand Name"
                    : "Ad Type"
                }
                variant="outlined"
                style={{ display: "block", marginBottom: "10px" }}
                value={ElementName}
                onChange={(e) => {
                  setElementName(e.target.value);
                }}
              />

              <button
                fullWidth
                onClick={() => {
                  if (mediaState === "General") addGen();
                  if (mediaState === "Brands") addBrand();
                  if (mediaState === "AdTypes") addAdType();
                  setOpenAddGen(false);
                }}
                style={{
                  backgroundColor: "blue",
                  color: "white",
                  borderRadius: "7px",
                  padding: "10px 25px",
                  border: "none",
                }}
              >
                ADD
              </button>
            </div>
          </div>
        </Fade>
      </Modal>
      <Container maxWidth="g">
        <div className={classes.tabWrapper}>
          <span
            onClick={() => toggleMedia("General")}
            className={[
              classes.tab1,
              mediaState === "General" ? "selected" : "",
            ].join(" ")}
          >
            General
          </span>
          <span
            onClick={() => toggleMedia("Brands")}
            className={[
              classes.tab1,
              mediaState === "Brands" ? "selected" : "",
            ].join(" ")}
          >
            Brands
          </span>
          <span
            onClick={() => toggleMedia("AdTypes")}
            className={[
              classes.tab1,
              mediaState === "AdTypes" ? "selected" : "",
            ].join(" ")}
          >
            Ad Types
          </span>
        </div>
        <ToggleMediaSwitch />
      </Container>
    </div>
  );
};
export default MainLayout(DropDowns);
