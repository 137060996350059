/* eslint-disable no-unused-expressions */
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core/";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import axios from "axios";
import { ContentState, EditorState, convertFromHTML } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import Highcharts, { color, keys } from "highcharts";
import HighchartReact from "highcharts-react-official";
import React, { useEffect, useRef, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useHistory } from "react-router-dom";
import NewAlert from "../../components/NewAlert";
import useStyles from "../../components/StyleClasses";
import { API } from "../../constants/index";
import MainLayout from "../../layouts/adminMainLayout";
import { SECRET_KEY } from "./../../constants/index";

import JoditEditor from 'jodit-react';

// import ReactQuill, { Quill } from "react-quill";
// import "react-quill/dist/quill.snow.css";
// import ImageResize from 'quill-image-resize-module-react';
// import parse from 'html-react-parser';
// import { CustomToolbar } from "./helperDecaReport/CustomToolbar";
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  Fade,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import { enGB } from "date-fns/locale";
import moment from "moment";
import { DatePicker } from "react-nice-dates";
import "react-nice-dates/build/style.css";
import { useDispatch, useSelector } from "react-redux";

import NewSection from "./newSectionDecaReport/newSection";

const GraphChildComponent = ({
  elementName,
  disableField,
  type,
  quests,
  callback,
  getChartData,
}) => {
  const [ques, setques] = useState({
    keyFinding: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    background: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    topLineOverview: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    aimsAndObjectives: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    benchmarkMediaSpendAnalysis: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    // mediaSpendAnalysis: [
    //   {
    //     question: "",
    //     answers: [{ ans: "", percent: 0 }],
    //     chartType: "",
    //     answerText: "",
    //     filter: "question",
    //     editorText: "<p><br></p>",
    //   },
    // ],
    mediaConsumptionByDemographic: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    media: [
      {
        mediaName: "Media",
        questionanswer: [
          {
            question: "",
            answers: [{ ans: "", percent: 0 }],
            chartType: "",
            answerText: "",
            filter: "question",
            editorText: "<p><br></p>",
          },
        ],
      },
    ],
    decaMediaInvestmentPlan: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    roiBreakdown: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        filter: "question",
        answerText: "",
        editorText: "<p><br></p>",
      },
    ],
    diversityDividendAnalysis: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    consumerPersonas: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    keyInsights: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    recommendations: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    processReviews: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    creativeAudit: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    summary: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    sentimentResults: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    introduction: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    appendices: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    executiveSummary: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    foreword: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    conclusions: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    methodology: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    discussionAndImplications: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    participantDemographyQA: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    saved: false,
    fromBE: false,
    updating: false,
    report_name: "",
    report_by: "",
    report_date: null,
    demographic: [],
    steeringCommittee: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    bestPractice: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
  });
  const [content, setContent]= useState('');
  const classes = useStyles();
  let keyType = quests[type];
  useEffect(() => {
    setques(quests);
  }, []);

  const setToLocal = (data) => {
    // localStorage.setItem("reportData", JSON.stringify(data));
  };

  const editorStateChange = (e) => {
    return stateToHTML(e.getCurrentContent());
  };

  // Quill.register('modules/imageResize', ImageResize);

  // const Image = Quill.import("formats/image");
  // const Link = Quill.import("formats/link");

  const config = {
    buttons: [
      "bold",
      "italic",
      "underline",
      "ul",
      "ol",
      "outdent",
      "indent",
      "fontsize",
      "font",
      "color",
      "paragraph",
      "table",
      "link",
      "align",
      "undo",
      "redo",
      "image"
    ]
  };

  // const modulesRef = {
  //   toolbar: [
  //       [{ header: [1, 2, 3, 4, 5, 6] }],
  //       [{ 'size': ['small', false, 'large', 'huge'] }],
  //       ["bold", "italic", "underline", "strike", "blockquote"],
  //       [
  //         { list: "ordered" },
  //         { list: "bullet" },
  //         { indent: "-1" },
  //         { indent: "+1" }
  //       ],
  //       [{ 'align': [] }],
  //       ['link', 'image'],
  //       ["clean"]
  //     ,
  //   ],
  //   imageResize: {
  //     parchment: Quill.import('parchment'),
  //     modules: ['Resize', 'DisplaySize']
  //  }
  // };

  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      className="text-center"
      style={{ justifyContent: "center" }}
    >
      {elementName &&
        elementName.length &&
        elementName.map((key, index) => (
          <>
            <FormControl
              component="fieldset"
              variant="outlined"
              className={[classes.selectControl, classes.customfield].join(" ")}
              style={{
                width: "40%",
              }}
            >
              <TextField
                id="outlined-select-currency-native"
                select
                // label="Demographic"
                value={key.filter}
                onChange={(event) => {
                  let newAnsList = [...keyType];
                  newAnsList[index].filter = event.target.value;
                  // setques({ ...ques, type: newAnsList });
                  let data = { ...ques, type: newAnsList };
                  setques(data);
                  setToLocal(data)
                }}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              >
                <option key={0} value={0}>
                  Select...
                </option>
                {[
                  { label: "Question and answer", value: "question" },
                  { label: "Summary", value: "summary" },
                ].map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </FormControl>
            {key.filter === "summary" || key.filter == "" ? (
              <>
                <div className="editor-description-box">
                  <b>Summary</b>
                  <JoditEditor
                    value={key.editorText}
                    config={config}
                    tabIndex={1}
                    onBlur={(e) => {
                      let newAnsList = [...keyType];
                      newAnsList[index].editorText = e;
                      // setques({ ...ques, type: newAnsList });
                      let data = { ...ques, type: newAnsList };
                      setques(data);
                      setToLocal(data);
                    }}
                    // onChange={() => {
                    //   let newAnsList = [...keyType];
                    //   newAnsList[index].editorText = e;
                    //   // setques({ ...ques, type: newAnsList });
                    //   let data = { ...ques, type: newAnsList };
                    //   setques(data);
                    //   setToLocal(data);
                    // }}
                  />
                  {/* <Editor
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    editorState={key.editorText}
                    disabled={disableField}
                    onEditorStateChange={(e) => {
                      let newAnsList = [...keyType];
                      newAnsList[index].editorText = e;
                      let data = { ...ques, type: newAnsList };
                      setques(data);
                      setToLocal(data);
                    }}
                    toolbar={{
                      options: [
                        "inline",
                        "fontSize",
                        "fontFamily",
                        "list",
                        "textAlign",
                        "link",
                        "history",
                        "image",
                      ],
                      fontSize: {
                        options: [
                          7,
                          8,
                          9,
                          10,
                          11,
                          12,
                          13,
                          14,
                          15,
                          16,
                          17,
                          18,
                          19,
                          20,
                          21,
                          22,
                          23,
                          24,
                          25,
                          26,
                          27,
                          28,
                          29,
                          30,
                          31,
                          32,
                          33,
                          34,
                          35,
                          36,
                          37,
                          38,
                          39,
                          40,
                          41,
                          42,
                          43,
                          44,
                          45,
                          46,
                          47,
                          48,
                          49,
                          50,
                          52,
                          54,
                          56,
                          58,
                          60,
                          62,
                          64,
                          66,
                          68,
                          70,
                          72,
                          74,
                          76,
                          78,
                          80,
                          82,
                          84,
                          86,
                          88,
                          90,
                          92,
                          94,
                          96,
                          98,
                          100,
                        ],
                      },
                      list: {
                        options: ["unordered", "ordered"],
                      },
                    }}
                  /> */}
                </div>
              </>
            ) : null}
            <FormControl
              component="fieldset"
              variant="outlined"
              className={[classes.selectControl, classes.customfield].join(" ")}
              style={{ width: "100%", marginTop: "10px" }}
            >
              <textarea
                style={{ overflow: "auto", width: "100%" }}
                disabled={disableField}
                value={key.question}
                placeholder={"Type Question..."}
                rows={2}
                onChange={(event) => {
                  let newAnsList = [...keyType];
                  newAnsList[index].question = event.target.value;
                  let data = { ...ques, type: newAnsList };
                  setques(data);
                  setToLocal(data)
                }}
              />
            </FormControl>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <TextField
                disabled={disableField}
                select
                // label="Chart Type"
                value={key.chartType}
                onChange={(event) => {
                  let newAnsList = [...keyType];
                  newAnsList[index].chartType = event.target.value;
                  let data = { ...ques, type: newAnsList };
                  setques(data);
                  setToLocal(data)
                }}
                SelectProps={{
                  native: true,
                }}
                variant="standard"
              >
                <option key="" value="">
                  No Chart
                </option>
                <option key="BAR" value="BAR">
                  BAR
                </option>
                <option key="PIE" value="PIE">
                  PIE
                </option>
                <option key="PYR" value="PYR">
                  PYRAMID
                </option>
              </TextField>
            </div>
            {key.answers &&
              key.answers?.map((a, i) => (
                <>
                  <div
                    style={{
                      width: "100%",
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      right: "0",
                      justifyContent: "center",
                      display: "flex",
                      paddingBottom: "5px",
                    }}
                  >
                    <textarea
                      disabled={disableField}
                      value={a.ans}
                      placeholder={`answers${i}...`}
                      style={{
                        width: "55%",
                        paddingTop: "10px",
                        textAlign: "center",
                        fontSize: "10px",
                        outline: "none",
                      }}
                      onChange={(event) => {
                        let newAnsList = [...keyType];
                        newAnsList[index].answers[i].ans = event.target.value;
                        //   setques({
                        //   ...ques,
                        //   type: newAnsList,
                        // });
                        let data = { ...ques, type: newAnsList };
                        setques(data);
                        setToLocal(data)
                      }}
                    ></textarea>
                    {key.chartType ? (
                      <input
                        disabled={disableField}
                        type="number"
                        value={a.percent}
                        style={{ width: "15%" }}
                        placeholder="xx%"
                        onChange={(event) => {
                          let newAnsList = [...keyType];
                          newAnsList[index].answers[i].percent =
                            event.target.value;
                          //   setques({
                          //   ...ques,
                          //   type: newAnsList,
                          // });
                          let data = { ...ques, type: newAnsList };
                          setques(data);
                          setToLocal(data)
                        }}
                      />
                    ) : null}
                    <div style={{ width: "30%" }}>
                      {key.answers.length > 1 && (
                        <Button
                          className={classes.addActionBtn}
                          style={{
                            height: "35px",
                            marginRight: "10px",
                          }}
                          onClick={() => {
                            let newAnsList = [...keyType];
                            newAnsList[index].answers.splice(i, 1);
                            // setques({
                            //   ...ques,
                            //   type: newAnsList,
                            // });
                            callback({
                              ...ques,
                              type: newAnsList,
                            });
                            let data = { ...ques, type: newAnsList };
                            setques(data);
                            setToLocal(data)
                          }}
                        >
                          -
                        </Button>
                      )}
                      {i === key.answers.length - 1 && (
                        <Button
                          className={classes.addActionBtn}
                          style={{ height: "35px" }}
                          onClick={() => {
                            let newAnsList = [...keyType];
                            let length = newAnsList[index].answers.length;
                            if (
                              length > 0 &&
                              (newAnsList[index].answers[
                                length - 1
                              ].ans.trim() == "" ||
                                (key.chartType &&
                                  newAnsList[index].answers[length - 1]
                                    .percent == 0))
                            ) {
                              return;
                            } else {
                              newAnsList[index].answers.push({
                                ans: "",
                                percent: 0,
                              });
                            }
                            // setques({
                            //   ...ques,
                            //   type: newAnsList,
                            // });
                            callback({
                              ...ques,
                              type: newAnsList,
                            });
                            let data = { ...ques, type: newAnsList };
                            setques(data);
                            setToLocal(data)
                          }}
                        >
                          +
                        </Button>
                      )}
                    </div>
                  </div>
                </>
              ))}
            <br />
            {key.chartType && getChartData(elementName, key, index, type, ques)}
            <FormControl
              component="fieldset"
              variant="outlined"
              className={[classes.selectControl, classes.customfield].join(" ")}
              style={{ width: "100%", marginTop: "10px" }}
            >
              <TextField
                fullWidth
                size="small"
                placeholder="Description"
                variant="outlined"
                style={{
                  marginBottom: "10px",
                }}
                disabled={disableField}
                value={key.answerText}
                onChange={(event) => {
                  let newAnsList = [...keyType];
                  newAnsList[index].answerText = event.target.value;
                  // setques({ ...ques, type: newAnsList });
                  let data = { ...ques, type: newAnsList };
                  setques(data);
                  setToLocal(data)
                }}
              />
            </FormControl>
            {key.filter === "question" ? (
              <>
                {/* <FormControl
                        component="fieldset"
                        variant="outlined"
                        className={[
                          classes.selectControl,
                          classes.customfield,
                        ].join(" ")}
                        style={{ width: "100%", marginTop: "10px" }}
                      >
                        <TextField
                          fullWidth
                          size="small"
                          placeholder="description"
                          variant="outlined"
                          style={{
                            marginBottom: "10px",
                          }}
                          disabled={disableField}
                          value={key.answerText}
                          onChange={(event) => {
                            let newAnsList = [...keyType];
                            newAnsList[index].answerText = event.target.value;
                            setques({ ...ques, type: newAnsList });
                          }}
                        />
                      </FormControl> */}
                <div className="editor-description-box">
                  <b>Summary</b>
                  <JoditEditor
                    value={key.editorText}
                    config={config}
                    tabIndex={1}
                    onBlur={(e) => {
                      let newAnsList = [...keyType];
                      newAnsList[index].editorText = e;
                      // setques({ ...ques, type: newAnsList });
                      let data = { ...ques, type: newAnsList };
                      setques(data);
                      setToLocal(data);
                    }}
                  />


                  {/* <ReactQuill
                    modules={modulesRef}
                    value={key.editorText}
                    theme={"snow"}
                    onImageUpload={handleImageUpload}
                    onChange={(e) => {
                      let newAnsList = [...keyType];
                      newAnsList[index].editorText = e;
                      // setques({ ...ques, type: newAnsList });
                      let data = { ...ques, type: newAnsList };
                      setques(data);
                      setToLocal(data);
                    }}
                  /> */}
                  {/* <Editor
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    editorState={key.editorText}
                    disabled={disableField}
                    onEditorStateChange={(e) => {
                      let newAnsList = [...keyType];
                      newAnsList[index].editorText = e;
                      // setques({ ...ques, type: newAnsList });
                      let data = { ...ques, type: newAnsList };
                      setques(data);
                      setToLocal(data);
                    }}
                    toolbar={{
                      options: [
                        "inline",
                        "fontSize",
                        "fontFamily",
                        "list",
                        "textAlign",
                        "link",
                        "history",
                        "image",
                      ],
                      fontSize: {
                        options: [
                          7,
                          8,
                          9,
                          10,
                          11,
                          12,
                          13,
                          14,
                          15,
                          16,
                          17,
                          18,
                          19,
                          20,
                          21,
                          22,
                          23,
                          24,
                          25,
                          26,
                          27,
                          28,
                          29,
                          30,
                          31,
                          32,
                          33,
                          34,
                          35,
                          36,
                          37,
                          38,
                          39,
                          40,
                          41,
                          42,
                          43,
                          44,
                          45,
                          46,
                          47,
                          48,
                          49,
                          50,
                          52,
                          54,
                          56,
                          58,
                          60,
                          62,
                          64,
                          66,
                          68,
                          70,
                          72,
                          74,
                          76,
                          78,
                          80,
                          82,
                          84,
                          86,
                          88,
                          90,
                          92,
                          94,
                          96,
                          98,
                          100,
                        ],
                      },
                      list: {
                        options: ["unordered", "ordered"],
                      },
                    }}
                  /> */}
                </div>
              </>
            ) : null}
            {elementName.length > 1 && (
              <Button
                className={classes.addActionBtn}
                style={{
                  width: "80px",
                  height: "40px",
                  marginRight: "10px",
                }}
                variant="outlined"
                onClick={() => {
                  let newAnsList = [...keyType];
                  newAnsList.splice(index, 1);
                  let typeObj = { [type]: newAnsList };
                  // setques({ ...ques, ...typeObj });
                  callback({ ...ques, ...typeObj });
                  let data = { ...ques, ...typeObj };
                  setques(data);
                  setToLocal(data)
                }}
              >
                -
              </Button>
            )}
            {index === elementName.length - 1 && (
              <Button
                className={classes.addActionBtn}
                style={{ width: "80px", height: "40px" }}
                variant="outlined"
                onClick={() => {
                  let newAnsList = [...keyType];
                  newAnsList.push({
                    question: "",
                    answers: [{ ans: "", percent: 0 }],
                    chartType: "",
                    answerText: "",
                    filter: "question",
                    editorText: "<p><br></p>",
                  });
                  let typeObj = { [type]: newAnsList };
                  // setques({ ...ques, ...typeObj });
                  callback({ ...ques, ...typeObj });
                  let data = { ...ques, ...typeObj };
                  setques(data);
                  setToLocal(data)
                }}
              >
                +
              </Button>
            )}
          </>
        ))}
    </Grid>
  );
};

export const RenderBarGraphData = ({
  accorName,
  elementName,
  disableField,
  type,
  quests,
  callback,
  // isCalledSubmit,
  getChartData,
  fromComponent,
}) => {
  const [keyFindExpanded, setKeyFindExpanded] = useState(true);
  const classes = useStyles();



  return (
    <>
      {fromComponent == "avatar" ? (
        <GraphChildComponent
          elementName={elementName}
          disableField={disableField}
          type={type}
          quests={quests}
          callback={callback}
          // isCalledSubmit,
          getChartData={getChartData}
        />
      ) : (
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className="text-center bar-graph-accordian"
          style={{ justifyContent: "center", marginTop: "5px" }}
        >
          <Accordion
            elevation={3}
            className={`accordion-box ${classes.accordion}`}
          >
            <AccordionSummary
              expandIcon={
                keyFindExpanded ? <ArrowDropDown /> : <ArrowDropDown />
              }
              onClick={(event) => {
                setKeyFindExpanded(!keyFindExpanded);
              }}
            >
              <b>{accorName}</b>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <GraphChildComponent
                accorName={accorName}
                elementName={elementName}
                disableField={disableField}
                type={type}
                quests={quests}
                callback={callback}
                // isCalledSubmit,
                getChartData={getChartData}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}
    </>
  );
};

const RenderMediaBarGraphData = ({
  elementName,
  disableField,
  quests,
  callback,
  getChartData,
  // isCalledSubmit,
}) => {
  const [ques, setques] = useState({
    keyFinding: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    background: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    topLineOverview: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    aimsAndObjectives: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    benchmarkMediaSpendAnalysis: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    // mediaSpendAnalysis: [
    //   {
    //     question: "",
    //     answers: [{ ans: "", percent: 0 }],
    //     chartType: "",
    //     answerText: "",
    //     filter: "question",
    //     editorText: "<p><br></p>",
    //   },
    // ],
    mediaConsumptionByDemographic: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    media: [
      {
        mediaName: "Media",
        questionanswer: [
          {
            question: "",
            answers: [{ ans: "", percent: 0 }],
            chartType: "",
            answerText: "",
            filter: "question",
            editorText: "<p><br></p>",
          },
        ],
      },
    ],
    decaMediaInvestmentPlan: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    roiBreakdown: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        filter: "question",
        answerText: "",
        editorText: "<p><br></p>",
      },
    ],
    diversityDividendAnalysis: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    consumerPersonas: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    keyInsights: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    recommendations: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    processReviews: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    creativeAudit: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    summary: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    sentimentResults: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    introduction: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    appendices: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    executiveSummary: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    foreword: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    conclusions: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    methodology: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    discussionAndImplications: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    participantDemographyQA: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    saved: false,
    fromBE: false,
    updating: false,
    report_name: "",
    report_by: "",
    report_date: null,
    demographic: [],
    steeringCommittee: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    bestPractice: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
  });
  const [keyFindExpanded, setKeyFindExpanded] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    setques(quests);
  }, []);

  const config = {
    buttons: [
      "bold",
      "italic",
      "underline",
      "ul",
      "ol",
      "outdent",
      "indent",
      "fontsize",
      "font",
      "color",
      "paragraph",
      "table",
      "link",
      "align",
      "undo",
      "redo",
      "image"
    ]
  };

  // useEffect(()=>{
  //   if(isCalledSubmit == true) callback(ques)
  // },[isCalledSubmit]);

  const setToLocal = (data) => {
    // localStorage.setItem("reportData", JSON.stringify(data));
  };

  const editorStateChange = (e) => {
    return stateToHTML(e.getCurrentContent());
  };

  return elementName?.map((ele, index) => (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      className="text-center bar-graph-accordian"
      style={{ justifyContent: "center", marginTop: "5px" }}
    >
      <Accordion elevation={3} className={`accordion-box ${classes.accordion}`}>
        <AccordionSummary
          className="accordion-box-media"
          expandIcon={keyFindExpanded ? <ArrowDropDown /> : <ArrowDropDown />}
          onClick={(event) => {
            setKeyFindExpanded(!keyFindExpanded);
            //event.stopPropagation();
          }}
        >
          <FormControl
            component="fieldset"
            variant="outlined"
            className={`MuiFormControl-fullWidth ${[
              classes.selectControl,
              classes.customfield,
            ].join(" ")}`}
            style={{ width: "50%" }}
          >
            <TextField
              fullWidth
              variant="outlined"
              value={ele.mediaName}
              onChange={(event) => {
                let newAnsList = [...elementName];
                newAnsList[index].mediaName = event.target.value;
                let data = { ...ques, media: newAnsList };
                setques(data);
                setToLocal(data)
              }}
            />
          </FormControl>
          <div>
            {elementName.length > 1 && (
              <Button
                style={{
                  width: "60px",
                  height: "30px",
                  marginRight: "10px",
                }}
                className={classes.addActionBtn}
                variant="outlined"
                onClick={() => {
                  let newAnsList = [...elementName];
                  newAnsList.splice(index, 1);
                  callback({ ...ques, media: newAnsList });
                  let data = { ...ques, media: newAnsList };
                  setques(data);
                  setToLocal(data)
                }}
              >
                -
              </Button>
            )}
            {index === elementName.length - 1 && (
              <Button
                style={{
                  width: "60px",
                  height: "30px",
                }}
                className={classes.addActionBtn}
                variant="outlined"
                onClick={() => {
                  let newAnsList = [...elementName];
                  newAnsList.push({
                    mediaName: "Media",
                    questionanswer: [
                      {
                        question: "",
                        answers: [{ ans: "", percent: 0 }],
                        chartType: "",
                        answerText: "",
                        filter: "question",
                        editorText: "<p><br></p>",
                      },
                    ],
                  });
                  // setques({
                  //   ...ques,
                  //   media: newAnsList,
                  // });
                  callback({
                    ...ques,
                    media: newAnsList,
                  });
                  let data = { ...ques, media: newAnsList };
                  setques(data);
                  setToLocal(data)
                }}
              >
                +
              </Button>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            className="text-center"
            style={{ justifyContent: "center" }}
          >
            {ele?.questionanswer?.map((key, ind) => (
              <>
                <FormControl
                  component="fieldset"
                  variant="outlined"
                  className={[classes.selectControl, classes.customfield].join(
                    " "
                  )}
                  style={{
                    width: "40%",
                  }}
                >
                  <TextField
                    id="outlined-select-currency-native"
                    select
                    // label="Demographic"
                    value={key.filter}
                    onChange={(event) => {
                      let newAnsList = [...elementName];
                      newAnsList[index].questionanswer[ind].filter =
                        event.target.value;
                      let data = { ...ques, media: newAnsList };
                      setques(data);
                      setToLocal(data)
                    }}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  >
                    <option key={0} value={0}>
                      Select...
                    </option>
                    {[
                      { label: "Question and answer", value: "question" },
                      { label: "Summary", value: "summary" },
                    ].map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </FormControl>
                {key.filter === "summary" ? (
                  <>
                    {/* <FormControl
                      component="fieldset"
                      variant="outlined"
                      className={[
                        classes.selectControl,
                        classes.customfield,
                      ].join(" ")}
                      style={{ width: "100%", marginTop: "10px" }}
                    >
                      <TextField
                        fullWidth
                        size="small"
                        placeholder="Description"
                        variant="outlined"
                        style={{
                          marginBottom: "10px",
                        }}
                        disabled={disableField}
                        value={key.answerText}
                        onChange={(event) => {
                          let newAnsList = [...elementName];
                          newAnsList[index].questionanswer[ind].answerText =
                            event.target.value;
                          setques({ ...ques, media: newAnsList });
                        }}
                      />
                    </FormControl> */}
                    <div className="editor-description-box">
                      <b>Summary</b>
                      <JoditEditor
                        value={key.editorText}
                        config={config}
                        tabIndex={1}
                        onBlur={(e) => {
                          let newAnsList = [];
                          newAnsList[index].editorText = e;
                          // setques({ ...ques, type: newAnsList });
                          let data = { ...ques, type: newAnsList };
                          setques(data);
                          setToLocal(data);
                        }}
                      />
                      {/* <Editor
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        editorState={key.editorText}
                        disabled={disableField}
                        onEditorStateChange={(e) => {
                          let newAnsList = [...elementName];
                          newAnsList[index].questionanswer[ind].editorText = e;
                          // setques({ ...ques, media: newAnsList });
                          let data = { ...ques, media: newAnsList };
                          setques(data);
                          setToLocal(data)
                        }}
                        toolbar={{
                          options: [
                            "inline",
                            "fontSize",
                            "fontFamily",
                            "list",
                            "textAlign",
                            "link",
                            "history",
                            "image",
                          ],
                          fontSize: {
                            options: [
                              7,
                              8,
                              9,
                              10,
                              11,
                              12,
                              13,
                              14,
                              15,
                              16,
                              17,
                              18,
                              19,
                              20,
                              21,
                              22,
                              23,
                              24,
                              25,
                              26,
                              27,
                              28,
                              29,
                              30,
                              31,
                              32,
                              33,
                              34,
                              35,
                              36,
                              37,
                              38,
                              39,
                              40,
                              41,
                              42,
                              43,
                              44,
                              45,
                              46,
                              47,
                              48,
                              49,
                              50,
                              52,
                              54,
                              56,
                              58,
                              60,
                              62,
                              64,
                              66,
                              68,
                              70,
                              72,
                              74,
                              76,
                              78,
                              80,
                              82,
                              84,
                              86,
                              88,
                              90,
                              92,
                              94,
                              96,
                              98,
                              100,
                            ],
                          },
                          list: {
                            options: ["unordered", "ordered"],
                          },
                        }}
                      /> */}
                    </div>
                  </>
                ) : null}
                <FormControl
                  component="fieldset"
                  variant="outlined"
                  className={[classes.selectControl, classes.customfield].join(
                    " "
                  )}
                  style={{ width: "100%", marginTop: "10px" }}
                >
                  <textarea
                    style={{ overflow: "auto", width: "100%" }}
                    disabled={disableField}
                    value={key.question}
                    placeholder={"Type Question..."}
                    rows={2}
                    onChange={(event) => {
                      let newAnsList = [...elementName];
                      newAnsList[index].questionanswer[ind].question =
                        event.target.value;
                      // setques({ ...ques, media: newAnsList });
                      let data = { ...ques, media: newAnsList };
                      setques(data);
                      setToLocal(data)
                    }}
                  />
                </FormControl>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <TextField
                    disabled={disableField}
                    select
                    // label="Chart Type"
                    value={key.chartType}
                    onChange={(event) => {
                      let newAnsList = [...elementName];
                      newAnsList[index].questionanswer[ind].chartType =
                        event.target.value;
                      // setques({ ...ques, media: newAnsList });
                      let data = { ...ques, media: newAnsList };
                      setques(data);
                      setToLocal(data)
                    }}
                    SelectProps={{
                      native: true,
                    }}
                    variant="standard"
                  >
                    <option key="" value="">
                      No Chart
                    </option>
                    <option key="BAR" value="BAR">
                      BAR
                    </option>
                    <option key="PIE" value="PIE">
                      PIE
                    </option>
                    <option key="PYR" value="PYR">
                      PYRAMID
                    </option>
                  </TextField>
                </div>
                {key?.answers?.map((a, i) => (
                  <>
                    <div
                      style={{
                        width: "100%",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        right: "0",
                        justifyContent: "center",
                        display: "flex",
                        paddingBottom: "5px",
                      }}
                    >
                      <textarea
                        disabled={disableField}
                        value={a.ans}
                        placeholder={`answers${i}...`}
                        style={{
                          width: "55%",
                          paddingTop: "10px",
                          textAlign: "center",
                          fontSize: "10px",
                          outline: "none",
                        }}
                        onChange={(event) => {
                          let newAnsList = [...elementName];
                          newAnsList[index].questionanswer[ind].answers[i].ans =
                            event.target.value;
                          // setques({ ...ques, media: newAnsList });
                          let data = { ...ques, media: newAnsList };
                          setques(data);
                          setToLocal(data)
                        }}
                      ></textarea>
                      {key.chartType ? (
                        <input
                          disabled={disableField}
                          type="number"
                          value={a.percent}
                          style={{ width: "15%" }}
                          placeholder="xx%"
                          onChange={(event) => {
                            let newAnsList = [...elementName];
                            newAnsList[index].questionanswer[ind].answers[
                              i
                            ].percent = event.target.value;
                            // setques({ ...ques, media: newAnsList });
                            let data = { ...ques, media: newAnsList };
                            setques(data);
                            setToLocal(data)
                          }}
                        />
                      ) : null}
                      <div style={{ width: "30%" }}>
                        {key?.answers?.length > 1 && (
                          <Button
                            className={classes.addActionBtn}
                            style={{
                              height: "35px",
                              marginRight: "10px",
                            }}
                            onClick={() => {
                              let newAnsList = [...elementName];
                              newAnsList[index].questionanswer[
                                ind
                              ].answers.splice(i, 1);
                              // setques({
                              //   ...ques,
                              //   media: newAnsList,
                              // });
                              let data = { ...ques, media: newAnsList };
                              setques(data);
                              setToLocal(data)
                            }}
                          >
                            -
                          </Button>
                        )}
                        {i === key?.answers?.length - 1 && (
                          <Button
                            className={classes.addActionBtn}
                            style={{ height: "35px" }}
                            onClick={() => {
                              let newAnsList = [...elementName];
                              newAnsList[index]?.questionanswer[
                                ind
                              ]?.answers.push({
                                ans: "",
                                percent: 0,
                              });
                              // setques({
                              //   ...ques,
                              //   media: newAnsList,
                              // });
                              callback({
                                ...ques,
                                media: newAnsList,
                              });
                              let data = { ...ques, media: newAnsList };
                              setques(data);
                              setToLocal(data)
                            }}
                          >
                            +
                          </Button>
                        )}
                      </div>
                    </div>
                  </>
                ))}
                <br />
                {key.chartType &&
                  getChartData(elementName, key, index, "media", ques)}
                <FormControl
                  component="fieldset"
                  variant="outlined"
                  className={[classes.selectControl, classes.customfield].join(
                    " "
                  )}
                  style={{ width: "100%", marginTop: "10px" }}
                >
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Description"
                    variant="outlined"
                    style={{
                      marginBottom: "10px",
                    }}
                    disabled={disableField}
                    value={key.answerText}
                    onChange={(event) => {
                      let newAnsList = [...elementName];
                      newAnsList[index].questionanswer[ind].answerText =
                        event.target.value;
                      // setques({ ...ques, media: newAnsList });
                      let data = { ...ques, media: newAnsList };
                      setques(data);
                      setToLocal(data)
                    }}
                  />
                </FormControl>
                {key.filter === "question" ? (
                  <>
                    <div className="editor-description-box">
                      <b>Summary</b>
                      <JoditEditor
                        value={key.editorText}
                        config={config}
                        tabIndex={1}
                        onBlur={(e) => {
                          let newAnsList = [...elementName];
                          newAnsList[index].questionanswer[ind].editorText = e;
                          // setques({ ...ques, type: newAnsList });
                          let data = { ...ques, type: newAnsList };
                          setques(data);
                          setToLocal(data);
                        }}
                      />
                      {/* <Editor
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        editorState={key.editorText}
                        disabled={disableField}
                        onEditorStateChange={(e) => {
                          let newAnsList = [...elementName];
                          newAnsList[index].questionanswer[ind].editorText = e;
                          let data = { ...ques, media: newAnsList };
                          setques(data);
                          setToLocal(data)
                        }}
                        toolbar={{
                          options: [
                            "inline",
                            "fontSize",
                            "fontFamily",
                            "list",
                            "textAlign",
                            "link",
                            "history",
                            "image",
                          ],
                          fontSize: {
                            options: [
                              7,
                              8,
                              9,
                              10,
                              11,
                              12,
                              13,
                              14,
                              15,
                              16,
                              17,
                              18,
                              19,
                              20,
                              21,
                              22,
                              23,
                              24,
                              25,
                              26,
                              27,
                              28,
                              29,
                              30,
                              31,
                              32,
                              33,
                              34,
                              35,
                              36,
                              37,
                              38,
                              39,
                              40,
                              41,
                              42,
                              43,
                              44,
                              45,
                              46,
                              47,
                              48,
                              49,
                              50,
                              52,
                              54,
                              56,
                              58,
                              60,
                              62,
                              64,
                              66,
                              68,
                              70,
                              72,
                              74,
                              76,
                              78,
                              80,
                              82,
                              84,
                              86,
                              88,
                              90,
                              92,
                              94,
                              96,
                              98,
                              100,
                            ],
                          },
                          list: {
                            options: ["unordered", "ordered"],
                          },
                        }}
                      /> */}
                    </div>
                  </>
                ) : null}
                {elementName[index]?.questionanswer?.length > 1 && (
                  <Button
                    className={classes.addActionBtn}
                    style={{
                      width: "80px",
                      height: "40px",
                      marginRight: "10px",
                    }}
                    variant="outlined"
                    onClick={() => {
                      let newAnsList = [...elementName];
                      newAnsList[index].questionanswer.splice(ind, 1);
                      let data = { ...ques, media: newAnsList };
                      setques(data);
                      setToLocal(data)
                    }}
                  >
                    -
                  </Button>
                )}
                {ind === elementName[index]?.questionanswer?.length - 1 && (
                  <Button
                    className={classes.addActionBtn}
                    style={{ width: "80px", height: "40px" }}
                    variant="outlined"
                    onClick={() => {
                      let newAnsList = [...elementName];
                      newAnsList[index].questionanswer.push({
                        question: "",
                        answers: [{ ans: "", percent: 0 }],
                        chartType: "",
                        answerText: "",
                        filter: "question",
                        editorText: "<p><br></p>",
                      });
                      // callback({ ...ques, media: newAnsList });
                      let data = { ...ques, media: newAnsList };
                      setques(data);
                      setToLocal(data)
                    }}
                  >
                    +
                  </Button>
                )}
              </>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  ));
};

const RenderTopSection = ({ element }) => {
  const [ques, setques] = useState({
    keyFinding: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    background: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    topLineOverview: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    aimsAndObjectives: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    benchmarkMediaSpendAnalysis: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    // mediaSpendAnalysis: [
    //   {
    //     question: "",
    //     answers: [{ ans: "", percent: 0 }],
    //     chartType: "",
    //     answerText: "",
    //     filter: "question",
    //     editorText: "<p><br></p>",
    //   },
    // ],
    mediaConsumptionByDemographic: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    media: [
      {
        mediaName: "Media",
        questionanswer: [
          {
            question: "",
            answers: [{ ans: "", percent: 0 }],
            chartType: "",
            answerText: "",
            filter: "question",
            editorText: "<p><br></p>",
          },
        ],
      },
    ],
    decaMediaInvestmentPlan: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    roiBreakdown: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        filter: "question",
        answerText: "",
        editorText: "<p><br></p>",
      },
    ],
    diversityDividendAnalysis: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    consumerPersonas: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    keyInsights: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    recommendations: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    processReviews: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    creativeAudit: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    summary: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    sentimentResults: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    introduction: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    appendices: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    executiveSummary: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    foreword: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    conclusions: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    methodology: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    discussionAndImplications: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    participantDemographyQA: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    saved: false,
    fromBE: false,
    updating: false,
    report_name: "",
    report_by: "",
    report_date: null,
    demographic: [],
    countries: [],
    steeringCommittee: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    bestPractice: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
  });
  const [demographicList, setDemographicList] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const classes = useStyles();

  const [tempReportUser, setTempReportUser] = useState(JSON.parse(localStorage.getItem("reportUserData")) ?? {
    demographics: [],
    report_by: "",
    report_date: null,
    report_name: ""
  })
  // const dispatch = useDispatch();

  // const reportData = useSelector((state) => state.reportData);

  useEffect(() => {
    setques(element);
    getDemographicData();
    getCountriesList()
    localStorage.setItem("reportUserData", JSON.stringify(tempReportUser));

    return () => {
      localStorage.removeItem("reportUserData");
    }
  }, []);

  const setToLocal = (data) => {
    // localStorage.setItem("reportData", JSON.stringify(data));
  };

  const setToLocalUserData = (data) => {
    localStorage.setItem("reportUserData", JSON.stringify(data));
  };
  
  const getDemographicData = () => {
    const access_token = window.localStorage.getItem(SECRET_KEY);
    axios
    .get(`${API}/admin/dropdowns`, {
      headers: { Authorization: `Bearer ${access_token}` },
    })
    .then((res) => {
      if (res && res.data) {
        setDemographicList(res.data.demographics);
      }
    });
  };

  const getCountriesList = () => {
    const access_token = window.localStorage.getItem(SECRET_KEY);
    axios
      .get(`${API}/admin/dropdowns`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res && res.data) {
          setCountriesList(res.data.countries);
        }
      });
  };
  
  const capitalizeFirstLetter = (string) => {
    let str = string.replace("_", " ");
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  
  const inputHandler = (event) => {
    let data = { ...ques, [event.target.name]: event.target.value };
    setques(data);
    setToLocal(data);
    let reportUserData = {
      demographics: data.demographic,
      report_by: data.report_by,
      report_date: data.report_date,
      report_name: data.report_name,
      countries: data.countries
    }
    setToLocalUserData(reportUserData)
    setTempReportUser({...tempReportUser, [event.target.name]: event.target.value})
  }
  
  if (!tempReportUser.demographics && 
    tempReportUser.report_by !== "" &&
    tempReportUser.report_name !== "" &&
    tempReportUser.report_date !== null
    ) {
      tempReportUser['demographics'] = tempReportUser.demographic
      localStorage.setItem("reportUserData", JSON.stringify(tempReportUser));
    }

  return (
    <>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        className="text-center"
        style={{ justifyContent: "center" }}
      >
        <Grid item xs={5} sm={5} md={5} style={{ margin: "5px" }}>
          <FormControl
            component="fieldset"
            variant="outlined"
            className={`MuiFormControl-fullWidth ${[
              classes.selectControl,
              classes.customfield,
            ].join(" ")}`}
          >
            <TextField
              fullWidth
              placeholder="Report Name"
              variant="outlined"
              name="report_name"
              value={ques.report_name}
              disabled={ques.saved}
              onChange={inputHandler}
            />
          </FormControl>
        </Grid>
        <Grid item xs={5} sm={5} md={5} style={{ margin: "5px" }}>
          <FormControl
            component="fieldset"
            variant="outlined"
            className={`MuiFormControl-fullWidth ${[
              classes.selectControl,
              classes.customfield,
            ].join(" ")}`}
          >
            <TextField
              fullWidth
              placeholder="REPORT BY"
              variant="outlined"
              name="report_by"
              value={ques.report_by}
              disabled={ques.saved}
              onChange={inputHandler}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        className="text-center"
        style={{ justifyContent: "center" }}
      >
        <Grid item xs={5} sm={5} md={5} style={{ margin: "5px" }}>
          <DatePicker
            date={ques.report_date}
            format="dd MMM yyyy"
            onDateChange={(e) => {
              setques({ ...ques, report_date: e })
              setToLocal({ ...ques, report_date: e })
              setToLocalUserData({...tempReportUser, report_date: e})
              setTempReportUser({...tempReportUser, report_date: e})
            }}
            locale={enGB}
          >
            {({ inputProps }) => (
              <div className="date-range">
                <TextField
                  fullWidth
                  label="Report Date"
                  disabled={ques.saved}
                  variant="outlined"
                  margin="normal"
                  className={classes.customfield}
                  {...inputProps}
                />
              </div>
            )}
          </DatePicker>
        </Grid>
        <Grid item xs={5} sm={5} md={5} style={{ margin: "5px" }}>
          <FormControl
            fullWidth
            component="fieldset"
            variant="outlined"
            className={[classes.selectControl, classes.customfield].join(" ")}
          >
            {ques.demographic && ques.demographic.length === 0 && (
              <InputLabel>Select demographic...</InputLabel>
            )}
            <Select
              id="outlined-select-currency-native"
              multiple
              // label="Reports"
              value={ques.demographic}
              name="demographic"
              onChange={inputHandler}
              disabled={ques.saved}
              variant="outlined"
              renderValue={() => {
                let arrNm = [];
                demographicList.length &&
                  demographicList?.map((dem) => {
                    if (ques.demographic.includes(dem._id)) {
                      let nm = capitalizeFirstLetter(dem.name);
                      arrNm.push(nm + " , ");
                    }
                  });
                return arrNm;
              }}
            >
              <MenuItem disabled value={0} key={0}>
                <em>Select demographic</em>
              </MenuItem>
              {demographicList.length &&
                demographicList?.map((option) => (
                  <MenuItem
                    primaryText={"Select demographic"}
                    key={option._id}
                    value={option._id}
                  >
                    {ques.demographic && ques.demographic.length === 0 && (
                      <Checkbox
                        checked={ques.demographic.includes(option._id)}
                      ></Checkbox>
                    )}
                    {option.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={5} sm={5} md={5} style={{ margin: "5px" }}>
          <FormControl
            fullWidth
            component="fieldset"
            variant="outlined"
            className={[classes.selectControl, classes.customfield].join(" ")}
          >
            {ques.countries && ques.countries.length === 0 && (
              <InputLabel>Select Countries</InputLabel>
            )}
            <Select
              id="outlined-select-currency-native"
              multiple
              // label="Reports"
              value={ques.countries || []}
              name="countries"
              onChange={inputHandler}
              disabled={ques.saved}
              variant="outlined"
              renderValue={() => {
                let arrNm = [];
                countriesList.length &&
                  countriesList?.map((dem) => {
                    if (ques.countries.includes(dem._id)) {
                      let nm = capitalizeFirstLetter(dem.name);
                      arrNm.push(nm + " , ");
                    }
                  });
                return arrNm;
              }}
            >
              <MenuItem disabled value={0} key={0}>
                <em>Select countries</em>
              </MenuItem>
              {countriesList.length &&
                countriesList?.map((option) => (
                  <MenuItem
                    primaryText={"Select countries"}
                    key={option._id}
                    value={option._id}
                  >
                    {ques.countries && ques.countries.length === 0 && (
                      <Checkbox
                        checked={ques.countries.includes(option._id)}
                      ></Checkbox>
                    )}
                    {option.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

const RenderAvatar = ({
  data,
  ques,
  callback,
  keyFindExpanded,
  getChartData,
  elementName,
  disableField
}) => {
  const [summaryExpanded, setSummaryExpanded] = useState(true);
  const [avatar, setAvatar] = useState([]);
  const [avatarData, setAvatarData] = useState([
    {
      imageurl: "",
      message: "",
      bgColor: "",
    },
  ]);
  const classes = useStyles();

  useEffect(() => {
    setAvatarData(data);
    getAvatar();
  }, []);

  const setToLocal = (data) => {
    // localStorage.setItem("reportData", JSON.stringify(data));
  };

  const getAvatar = () => {
    const avatarArr = [];
    for (let i = 1; i <= 50; i++) {
      const avatar = require(`../../assets/avatars_new/${i}.svg`);
      avatarArr.push({
        name: `Avatar${i}`,
        imageurl: avatar,
      });
    }
    setAvatar(avatarArr);
  };

  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      className="text-center bar-graph-accordian"
      style={{ justifyContent: "center", marginTop: "5px" }}
    >
      <Accordion elevation={3} className={`accordion-box ${classes.accordion}`}>
        <AccordionSummary
          expandIcon={summaryExpanded ? <ArrowDropDown /> : <ArrowDropDown />}
          onClick={(event) => {
            setSummaryExpanded(!summaryExpanded);
            //If I wanted to expand only on IconClick I would use the below
            //event.stopPropagation();
          }}
        >
          <b>Participant Demographics</b>
        </AccordionSummary>
        <AccordionDetails className={`main-participant ${classes.details}`}>
          {avatarData?.map((avt, index) => (
            <div className="accordion-demographics">
              <Grid
                container
                direction="row"
                justify="flex-center"
                alignItems="flex-center"
                className="text-center"
              >
                <FormControl
                  component="fieldset"
                  variant="outlined"
                  className={[classes.selectControl, classes.customfield].join(
                    " "
                  )}
                  style={{
                    width: "80%",
                    marginBottom: "50px",
                  }}
                >
                  <TextField
                    id="outlined-select-currency-native"
                    select
                    label="Avatar"
                    value={avt.imageurl}
                    onChange={(event) => {
                      const avatarDataArr = [...avatarData];
                      avatarDataArr[index].imageurl = event.target.value;
                      setAvatarData(avatarDataArr);
                      // let newAnsList = [...keyType];
                      // newAnsList[index].imageurl = event.target.value;
                      let data = ques;
                      data['participantDemography'] = avatarDataArr;
                      setToLocal(data);
                    }}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  >
                    <option key={0} value="">
                      Select avatar...
                    </option>
                    {avatar?.map((avt) => (
                      <option key={avt.imageurl} value={avt.imageurl}>
                        {avt.name}
                      </option>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              {avatarData[index].imageurl !== 0 &&
              avatarData[index].imageurl !== "" &&
              avatarData[index].imageurl ? (
                <Grid
                  container
                  direction="row"
                  justify="flex-center"
                  alignItems="flex-start"
                  className="text-center demographics-boxes"
                >
                  {Number(index + 1) % 2 === 0 ? (
                    <Grid item xs={12} sm={12} md={12}>
                      <div
                        className="card message-content bg-green mt2"
                        style={{ blackgroundColor: "red !o" }}
                      >
                        <div className="card-body">
                          <div className="icon">
                            <img src={avt.imageurl} />
                          </div>
                          <FormControl
                            component="fieldset"
                            variant="outlined"
                            className={[
                              classes.selectControl,
                              classes.customfield,
                            ].join(" ")}
                            style={{ width: "100%", marginTop: "10px" }}
                          >
                            <textarea
                              style={{ overflow: "auto", width: "100%" }}
                              value={avt.message}
                              placeholder={"Type here..."}
                              rows={8}
                              onChange={(event) => {
                                const avatarDataArr = [...avatarData];
                                avatarDataArr[index].message =
                                  event.target.value;
                                setAvatarData(avatarDataArr);
                                // let newAnsList = [...keyType];
                                // newAnsList[index].message = event.target.value;
                                let data = ques;
                                data['participantDemography'] = avatarDataArr;
                                setToLocal(data);
                              }}
                            />
                          </FormControl>
                        </div>
                      </div>
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={12} md={12}>
                      <div className="card message-content bg-greenlight badge-shape2 badge-shape-round mt2">
                        <div className="card-body">
                          <div className="icon">
                            <img src={avt.imageurl} />
                          </div>
                          <FormControl
                            component="fieldset"
                            variant="outlined"
                            className={[
                              classes.selectControl,
                              classes.customfield,
                            ].join(" ")}
                            style={{ width: "100%", marginTop: "10px" }}
                          >
                            <textarea
                              style={{ overflow: "auto", width: "100%" }}
                              value={avt.message}
                              placeholder={"Type here..."}
                              rows={3}
                              onChange={(event) => {
                                const avatarDataArr = [...avatarData];
                                avatarDataArr[index].message =
                                  event.target.value;
                                setAvatarData(avatarDataArr);
                                // let newAnsList = [...keyType];
                                // newAnsList[index].message = event.target.value;
                                let data = ques;
                                data['participantDemography'] = avatarDataArr;
                                setToLocal(data);
                              }}
                            />
                          </FormControl>
                        </div>
                      </div>
                    </Grid>
                  )}
                </Grid>
              ) : null}

              <div>
                {avatarData.length > 1 && (
                  <Button
                    className={classes.addActionBtn}
                    style={{
                      height: "35px",
                      marginRight: "10px",
                    }}
                    onClick={() => {
                      let avatarList = [...avatarData];
                      avatarList.splice(index, 1);
                      setAvatarData(avatarList);
                      let data = ques;
                      data['participantDemography'] = avatarList;
                      setToLocal(data);
                    }}
                  >
                    -
                  </Button>
                )}
                {index === avatarData.length - 1 && (
                  <Button
                    className={classes.addActionBtn}
                    style={{ height: "35px" }}
                    onClick={() => {
                      setAvatarData([
                        ...avatarData,
                        {
                          imageurl: "",
                          message: "",
                        },
                      ]);
                      let data = ques;
                      data['participantDemography'] = [
                        ...avatarData,
                        {
                          imageurl: "",
                          message: "",
                        },
                      ];
                      setToLocal(data);
                    }}
                  >
                    +
                  </Button>
                )}
              </div>
            </div>
          ))}
        </AccordionDetails>
        <AccordionDetails className={`main-participant-graphs ${classes.details}`}>
          <RenderBarGraphData
            quests={ques}
            accorName=""
            elementName={elementName}
            disableField={disableField}
            type="participantDemographyQA"
            callback={callback}
            keyFindExpanded={keyFindExpanded}
            getChartData={getChartData}
            fromComponent="avatar"
          />
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

const CreateDecaReport = (props) => {
  const { location } = props;
  const [ques, setques] = useState({
    keyFinding: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    background: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    topLineOverview: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    aimsAndObjectives: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    benchmarkMediaSpendAnalysis: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    // mediaSpendAnalysis: [
    //   {
    //     question: "",
    //     answers: [{ ans: "", percent: 0 }],
    //     chartType: "",
    //     answerText: "",
    //     filter: "question",
    //     editorText: "<p><br></p>",
    //   },
    // ],
    mediaConsumptionByDemographic: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    media: [
      {
        mediaName: "Media",
        questionanswer: [
          {
            question: "",
            answers: [{ ans: "", percent: 0 }],
            chartType: "",
            answerText: "",
            filter: "question",
            editorText: "<p><br></p>",
          },
        ],
      },
    ],
    decaMediaInvestmentPlan: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    roiBreakdown: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        filter: "question",
        answerText: "",
        editorText: "<p><br></p>",
      },
    ],
    diversityDividendAnalysis: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    consumerPersonas: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    keyInsights: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    recommendations: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    processReviews: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    creativeAudit: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    summary: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    sentimentResults: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    introduction: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    appendices: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    executiveSummary: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    foreword: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    conclusions: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    methodology: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    discussionAndImplications: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    participantDemographyQA: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    saved: false,
    fromBE: false,
    updating: false,
    report_name: "",
    report_by: "",
    report_date: null,
    demographic: [],
    steeringCommittee: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
    bestPractice: [
      {
        question: "",
        answers: [{ ans: "", percent: 0 }],
        chartType: "",
        answerText: "",
        filter: "question",
        editorText: "<p><br></p>",
      },
    ],
  });
  ///////errors & successs //////////
  const [ErrSucMsg, setErrSucMesg] = useState("");
  const [ErrSucType, setErrSucType] = useState(true);
  ///////////////////////////////////
  const classes = useStyles();
  const history = useHistory();

  const access_token = window.localStorage.getItem(SECRET_KEY);
  // const [demographicList, setDemographicList] = useState([]);
  const [reportId, setReportId] = useState("");
  const [summaryExpanded, setSummaryExpanded] = useState(true);
  const [keyFindExpanded, setKeyFindExpanded] = useState(true);
  const [openDeciaModal, setOpenDeciaModal] = useState(false);
  const [avatar, setAvatar] = useState([]);
  const [avatarData, setAvatarData] = useState([
    {
      imageurl: "",
      message: "",
      bgColor: "",
    },
  ]);
  const [isCallSubmit, setIsCalledSubmit] = useState(false);

  const [editSecData, setEditSecData] = useState(null);

  const reportData = useSelector((state) => state.reportData);
  const dispatch = useDispatch();

  // const {state} = props;
  useEffect(() => {
    // getDemographicData();
    // getAvatar();
  }, []);

  // const getDemographicData = () => {
  //   const access_token = window.localStorage.getItem(SECRET_KEY);
  //   axios
  //     .get(`${API}/admin/dropdowns`, {
  //       headers: { Authorization: `Bearer ${access_token}` },
  //     })
  //     .then((res) => {
  //       if (res && res.data) {
  //         setDemographicList(res.data.demographics);
  //       }
  //     });
  // };

  const getChartData = (ele, key, pId, type, ques) => {
    let keyType = ques[type];
    let myData = [];
    const getColor = (str, index) => {
      let stringUniqueHash = [...str].reduce((acc, char) => {
        return char.charCodeAt(0) + ((acc << 5) - index);
      }, 0);
      let newColor = `hsl(${stringUniqueHash % 360}, 95%, 35%)`;
      return newColor;
    };

    key?.answers?.forEach((obj, i) => {
      myData.push({
        name: obj.ans,
        y: parseFloat(obj.percent),
        color: getColor(obj.ans, i),
      });
    });
    return (
      <div style={{ width: "100%" }}>
        {key?.chartType === "PYR" ? (
          <HighchartReact
            highcharts={Highcharts}
            options={{
              chart: {
                type: "pyramid",
              },
              title: {
                overflow: "auto",
                text: key.question,
              },
              colors: [
                "#99f546",
                "#B5927B",
                "#6a1c1c",
                "#f53232",
                "#1ddfa9",
                "#1f639f",
                "#851f9f",
                "#e74513",
                "#e94b0f",
                "#3988e9",
                "#dd1f3a",
              ],
              plotOptions: {
                series: {
                  dataLabels: {
                    enabled: true,
                    format: "<b>{point.name}</b> ({point.y:,.0f})",
                    softConnector: true,
                  },
                  width: "90%",
                  height: "90%",
                },
              },
              legend: {
                enabled: false,
              },
              series: [
                {
                  name: key.question,
                  data: key.answers.map((e) => [e.ans, parseFloat(e.percent)]),
                },
              ],

              responsive: {
                rules: [
                  {
                    condition: {
                      maxWidth: 500,
                    },
                    chartOptions: {
                      plotOptions: {
                        series: {
                          dataLabels: {
                            inside: true,
                          },
                          width: "90%%",
                          height: "90%",
                        },
                      },
                    },
                  },
                ],
              },
            }}
          ></HighchartReact>
        ) : null}
        {key?.chartType === "BAR" ? (
          <HighchartReact
            highcharts={Highcharts}
            options={{
              chart: {
                type: "column",
              },
              title: {
                text: key.question,
              },
              xAxis: {
                categories: key.answers.map((e) => e.ans),
                crosshair: true,
              },
              yAxis: {
                min: 0,
                title: {
                  text: type,
                },
              },
              tooltip: {
                headerFormat:
                  '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                  '<td style="padding:0"><b>{point.y:.1f} %</b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
              },
              plotOptions: {
                column: {
                  pointPadding: 0.2,
                  borderWidth: 0,
                },
              },
              series: [
                {
                  name: "Answers",
                  data: key.answers.map((e) => parseFloat(e.percent)),
                  color: {
                    linearGradient: [0, 0, 500, 0],
                    stops: [
                      [0, "#99f546"],
                      [1, "#1ddfa9"],
                    ],
                  },
                },
              ],
            }}
          ></HighchartReact>
        ) : null}
        {key?.chartType === "PIE" ? (
          <HighchartReact
            highcharts={Highcharts}
            options={{
              chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: "pie",
              },
              title: {
                text: key.question,
              },
              tooltip: {
                pointFormat: "{series.name}: <b>{point.y:.1f}%</b>",
              },
              accessibility: {
                point: {
                  valueSuffix: "%",
                },
              },
              plotOptions: {
                pie: {
                  allowPointSelect: true,
                  cursor: "pointer",
                  dataLabels: {
                    enabled: true,
                    format: "<b>{point.name}</b>: {point.y:.1f} %",
                  },
                },
              },
              series: [
                {
                  name: "Answer",
                  colorByPoint: true,
                  data: myData,
                  color: {
                    linearGradient: [0, 0, 500, 0],
                    stops: [
                      [0, "#99f546"],
                      [1, "#1ddfa9"],
                    ],
                  },
                },
              ],
            }}
          ></HighchartReact>
        ) : null}
      </div>
    );
  };

  const removeId = (arr) => {
    let newArr = [];
    if (arr && arr.length) {
      newArr = arr?.map(({ ...rest }) => {
        // rest.editorText = EditorState.createWithContent(
        //   ContentState.createFromBlockArray(convertFromHTML(rest.editorText))
        // );
        return rest;
      });
    } else {
      newArr = [
        {
          question: "",
          answers: [{ ans: "", percent: 0 }],
          chartType: "",
          answerText: "",
          filter: "question",
          editorText: "<p><br></p>",
        },
      ];
    }
    return newArr;
  };

  const removeMediaId = (arr) => {
    let newArr = [];
    if (arr.length) {
      newArr = arr?.map(({ ...rest }) => {
        if (rest?.questionanswer.length) {
          // rest?.questionanswer?.map((que) => {
          //   que.editorText = EditorState.createWithContent(
          //     ContentState.createFromBlockArray(convertFromHTML(que.editorText))
          //   );
          // });
          return rest;
        } else {
          return {
            mediaName: "Media",
            questionanswer: [
              {
                question: "",
                answers: [{ ans: "", percent: 0 }],
                chartType: "",
                answerText: "",
                filter: "question",
                editorText: "<p><br></p>",
              },
            ],
          };
        }
      });
    } else {
      newArr = [
        {
          mediaName: "Media",
          questionanswer: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
        },
      ];
    }
    return newArr;
  };

  const setToLocal = (data) => {
    const {demographics, report_by, report_date, report_name} = data
    localStorage.setItem("reportUserData", JSON.stringify({
      report_by:report_by,
      report_date:report_date,
      report_name:report_name,
      demographic:demographics,
      demographics:demographics
    }))
    // localStorage.setItem("reportData", JSON.stringify(data));
  };

  useEffect(() => {
    async function fetchData() {
      setOpenDeciaModal(location?.state?.openDeciaModal);
      if (
        location &&
        location.state &&
        location.state.dataArr &&
        location.state.dataArr.length
      ) {
        const recordData = location.state.dataArr[0];
        setToLocal(recordData);
        setReportId(recordData._id);
        const demoArr = [];
        if (recordData.demographics && recordData.demographics.length) {
          recordData.demographics?.map((d) => {
            demoArr.push(d._id);
          });
        }
        let countriesArr = recordData.countries;
        // if (recordData.countries && recordData.countries.length) {
        //   recordData.countries?.map((d) => {
        //     countriesArr.push(d._id);
        //   });
        // }

        let existData = []

        for (let k in recordData) {
          if (
            ![
              "saved",
              "fromBE",
              "updating",
              "report_name",
              "report_by",
              "report_date",
              "demographic",
              "countries",
              "executiveSummary",
              "sentimentResults",
              "summary",
              "methodology",
              "discussionAndImplications",
              "introduction",
              "foreword",
              "conclusions",
              "appendices",
              "keyFinding",
              "background",
              "topLineOverview",
              "benchmarkMediaSpendAnalysis",
              "mediaConsumptionByDemographic",
              "aimsAndObjectives",
              "media",
              "decaMediaInvestmentPlan",
              "roiBreakdown",
              "diversityDividendAnalysis",
              "consumerPersonas",
              "processReviews",
              "keyInsights",
              "recommendations",
              "creativeAudit",
              "bestPractice",
              "steeringCommittee",
              "participantDemography",
              "participantDemographyQA",
            ].includes(k)
          ) {
            existData.push(k)
          }
        }

        setEditSecData(existData)

        let reportData = {
          saved: false,
          fromBE: false,
          updating: true,
          report_name: recordData.report_name,
          report_by: recordData.report_by,
          report_date: new Date(recordData.report_date),
          demographic: demoArr,
          countries: countriesArr,
          executiveSummary: removeId(recordData.executiveSummary),
          sentimentResults: removeId(recordData.sentimentResults),
          summary: removeId(recordData.summary),
          methodology: removeId(recordData.methodology),
          discussionAndImplications: removeId(
            recordData.discussionAndImplications
          ),
          introduction: removeId(recordData.introduction),
          foreword: removeId(recordData.foreword),
          conclusions: removeId(recordData.conclusions),
          appendices: removeId(recordData.appendices),
          keyFinding: removeId(recordData.keyFinding),
          background: removeId(recordData.background),
          topLineOverview: removeId(recordData.topLineOverview),
          benchmarkMediaSpendAnalysis: removeId(
            recordData.benchmarkMediaSpendAnalysis
          ),
          // mediaSpendAnalysis: removeId(recordData.mediaSpendAnalysis),
          mediaConsumptionByDemographic: removeId(
            recordData.mediaConsumptionByDemographic
          ),
          aimsAndObjectives: removeId(recordData.aimsAndObjectives),
          media: removeMediaId(recordData.media),
          decaMediaInvestmentPlan: removeId(recordData.decaMediaInvestmentPlan),
          roiBreakdown: removeId(recordData.roiBreakdown),
          diversityDividendAnalysis: removeId(
            recordData.diversityDividendAnalysis
          ),
          consumerPersonas: removeId(recordData.consumerPersonas),
          processReviews: removeId(recordData.processReviews),
          keyInsights: removeId(recordData.keyInsights),
          recommendations: removeId(recordData.recommendations),
          creativeAudit: removeId(recordData.creativeAudit),
          bestPractice: removeId(recordData.bestPractice),
          steeringCommittee: removeId(recordData.steeringCommittee),
          participantDemography: recordData.participantDemography,
          participantDemographyQA: removeId(recordData.participantDemographyQA),
        };

        console.log("reportData:::", reportData)

        if (existData.length > 0 ) {
          for (let k of existData) {
            if (!["_id", "demographics"].includes(k)) {
              reportData[k] = recordData[k]
            }
          }
        }

        setques(reportData);
        if (recordData.participantDemography.length)
          setAvatarData(recordData.participantDemography);
      }
    }
    fetchData();
  }, [location]);

  const getColor = (str, index) => {
    let stringUniqueHash = [...str].reduce((acc, char) => {
      return char.charCodeAt(0) + ((acc << 5) - index);
    }, 0);
    let newColor = `hsl(${stringUniqueHash % 360}, 95%, 35%)`;
    return newColor;
  };

  const renderMediaBarGraphData = (elementName, disableField) => {
    return elementName?.map((ele, index) => (
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        className="text-center bar-graph-accordian"
        style={{ justifyContent: "center", marginTop: "5px" }}
      >
        <Accordion
          elevation={3}
          className={`accordion-box ${classes.accordion}`}
        >
          <AccordionSummary
            className="accordion-box-media"
            expandIcon={keyFindExpanded ? <ArrowDropDown /> : <ArrowDropDown />}
            onClick={(event) => {
              setKeyFindExpanded(!keyFindExpanded);
              //event.stopPropagation();
            }}
          >
            <FormControl
              component="fieldset"
              variant="outlined"
              className={`MuiFormControl-fullWidth ${[
                classes.selectControl,
                classes.customfield,
              ].join(" ")}`}
              style={{ width: "50%" }}
            >
              <TextField
                fullWidth
                variant="outlined"
                value={ele.mediaName}
                onChange={(event) => {
                  let newAnsList = [...elementName];
                  newAnsList[index].mediaName = event.target.value;
                  setques({ ...ques, media: newAnsList });
                }}
              />
            </FormControl>
            <div>
              {elementName.length > 1 && (
                <Button
                  style={{
                    width: "60px",
                    height: "30px",
                    marginRight: "10px",
                  }}
                  className={classes.addActionBtn}
                  variant="outlined"
                  onClick={() => {
                    let newAnsList = [...elementName];
                    newAnsList.splice(index, 1);
                    setques({ ...ques, media: newAnsList });
                  }}
                >
                  -
                </Button>
              )}
              {index === elementName.length - 1 && (
                <Button
                  style={{
                    width: "60px",
                    height: "30px",
                  }}
                  className={classes.addActionBtn}
                  variant="outlined"
                  onClick={() => {
                    let newAnsList = [...elementName];
                    newAnsList.push({
                      mediaName: "Media",
                      questionanswer: [
                        {
                          question: "",
                          answers: [{ ans: "", percent: 0 }],
                          chartType: "",
                          answerText: "",
                          filter: "question",
                          editorText: "<p><br></p>",
                        },
                      ],
                    });
                    setques({
                      ...ques,
                      media: newAnsList,
                    });
                    // callback({
                    //   ...ques,
                    //   media: newAnsList,
                    // });
                  }}
                >
                  +
                </Button>
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              className="text-center"
              style={{ justifyContent: "center" }}
            >
              {ele?.questionanswer?.map((key, ind) => (
                <>
                  <FormControl
                    component="fieldset"
                    variant="outlined"
                    className={[
                      classes.selectControl,
                      classes.customfield,
                    ].join(" ")}
                    style={{
                      width: "40%",
                    }}
                  >
                    <TextField
                      id="outlined-select-currency-native"
                      select
                      // label="Demographic"
                      value={key.filter}
                      onChange={(event) => {
                        let newAnsList = [...elementName];
                        newAnsList[index].questionanswer[ind].filter =
                          event.target.value;
                        setques({ ...ques, media: newAnsList });
                      }}
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                    >
                      <option key={0} value={0}>
                        Select...
                      </option>
                      {[
                        { label: "Question and answer", value: "question" },
                        { label: "Summary", value: "summary" },
                      ].map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </FormControl>
                  {key.filter === "summary" ? (
                    <>
                      {/* <FormControl
                        component="fieldset"
                        variant="outlined"
                        className={[
                          classes.selectControl,
                          classes.customfield,
                        ].join(" ")}
                        style={{ width: "100%", marginTop: "10px" }}
                      >
                        <TextField
                          fullWidth
                          size="small"
                          placeholder="Description"
                          variant="outlined"
                          style={{
                            marginBottom: "10px",
                          }}
                          disabled={disableField}
                          value={key.answerText}
                          onChange={(event) => {
                            let newAnsList = [...elementName];
                            newAnsList[index].questionanswer[ind].answerText =
                              event.target.value;
                            setques({ ...ques, media: newAnsList });
                          }}
                        />
                      </FormControl> */}
                      <div className="editor-description-box">
                        <b>Summary</b>
                        <JoditEditor
                          value={key.editorText}
                          // config={config}
                          tabIndex={1}
                          onBlur={(e) => {
                            let newAnsList = [];
                            newAnsList[index].editorText = e;
                            // setques({ ...ques, type: newAnsList });
                            let data = { ...ques, type: newAnsList };
                            setques(data);
                            setToLocal(data);
                          }}
                        />
                        {/* <Editor
                          wrapperClassName="demo-wrapper"
                          editorClassName="demo-editor"
                          editorState={key.editorText}
                          disabled={disableField}
                          onEditorStateChange={(e) => {
                            let newAnsList = [...elementName];
                            newAnsList[index].questionanswer[
                              ind
                            ].editorText = e;
                            setques({ ...ques, media: newAnsList });
                          }}
                          toolbar={{
                            options: [
                              "inline",
                              "fontSize",
                              "fontFamily",
                              "list",
                              "textAlign",
                              "link",
                              "history",
                              "image",
                            ],
                            fontSize: {
                              options: [
                                7,
                                8,
                                9,
                                10,
                                11,
                                12,
                                13,
                                14,
                                15,
                                16,
                                17,
                                18,
                                19,
                                20,
                                21,
                                22,
                                23,
                                24,
                                25,
                                26,
                                27,
                                28,
                                29,
                                30,
                                31,
                                32,
                                33,
                                34,
                                35,
                                36,
                                37,
                                38,
                                39,
                                40,
                                41,
                                42,
                                43,
                                44,
                                45,
                                46,
                                47,
                                48,
                                49,
                                50,
                                52,
                                54,
                                56,
                                58,
                                60,
                                62,
                                64,
                                66,
                                68,
                                70,
                                72,
                                74,
                                76,
                                78,
                                80,
                                82,
                                84,
                                86,
                                88,
                                90,
                                92,
                                94,
                                96,
                                98,
                                100,
                              ],
                            },
                            list: {
                              options: ["unordered", "ordered"],
                            },
                          }}
                        /> */}
                      </div>
                    </>
                  ) : null}
                  <FormControl
                    component="fieldset"
                    variant="outlined"
                    className={[
                      classes.selectControl,
                      classes.customfield,
                    ].join(" ")}
                    style={{ width: "100%", marginTop: "10px" }}
                  >
                    <textarea
                      style={{ overflow: "auto", width: "100%" }}
                      disabled={disableField}
                      value={key.question}
                      placeholder={"Type Question..."}
                      rows={2}
                      onChange={(event) => {
                        let newAnsList = [...elementName];
                        newAnsList[index].questionanswer[ind].question =
                          event.target.value;
                        setques({ ...ques, media: newAnsList });
                      }}
                    />
                  </FormControl>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <TextField
                      disabled={disableField}
                      select
                      // label="Chart Type"
                      value={key.chartType}
                      onChange={(event) => {
                        let newAnsList = [...elementName];
                        newAnsList[index].questionanswer[ind].chartType =
                          event.target.value;
                        setques({ ...ques, media: newAnsList });
                      }}
                      SelectProps={{
                        native: true,
                      }}
                      variant="standard"
                    >
                      <option key="" value="">
                        No Chart
                      </option>
                      <option key="BAR" value="BAR">
                        BAR
                      </option>
                      <option key="PIE" value="PIE">
                        PIE
                      </option>
                      <option key="PYR" value="PYR">
                        PYRAMID
                      </option>
                    </TextField>
                  </div>
                  {key?.answers?.map((a, i) => (
                    <>
                      <div
                        style={{
                          width: "100%",
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          right: "0",
                          justifyContent: "center",
                          display: "flex",
                          paddingBottom: "5px",
                        }}
                      >
                        <textarea
                          disabled={disableField}
                          value={a.ans}
                          placeholder={`answers${i}...`}
                          style={{
                            width: "55%",
                            paddingTop: "10px",
                            textAlign: "center",
                            fontSize: "10px",
                            outline: "none",
                          }}
                          onChange={(event) => {
                            let newAnsList = [...elementName];
                            newAnsList[index].questionanswer[ind].answers[
                              i
                            ].ans = event.target.value;
                            setques({ ...ques, media: newAnsList });
                          }}
                        ></textarea>
                        {key.chartType ? (
                          <input
                            disabled={disableField}
                            type="number"
                            value={a.percent}
                            style={{ width: "15%" }}
                            placeholder="xx%"
                            onChange={(event) => {
                              let newAnsList = [...elementName];
                              newAnsList[index].questionanswer[ind].answers[
                                i
                              ].percent = event.target.value;
                              setques({ ...ques, media: newAnsList });
                            }}
                          />
                        ) : null}
                        <div style={{ width: "30%" }}>
                          {key?.answers?.length > 1 && (
                            <Button
                              className={classes.addActionBtn}
                              style={{
                                height: "35px",
                                marginRight: "10px",
                              }}
                              onClick={() => {
                                let newAnsList = [...elementName];
                                newAnsList[index].questionanswer[
                                  ind
                                ].answers.splice(i, 1);
                                setques({
                                  ...ques,
                                  media: newAnsList,
                                });
                              }}
                            >
                              -
                            </Button>
                          )}
                          {i === key?.answers?.length - 1 && (
                            <Button
                              className={classes.addActionBtn}
                              style={{ height: "35px" }}
                              onClick={() => {
                                let newAnsList = [...elementName];
                                newAnsList[index]?.questionanswer[
                                  ind
                                ]?.answers.push({
                                  ans: "",
                                  percent: 0,
                                });
                                setques({
                                  ...ques,
                                  media: newAnsList,
                                });
                                // callback({
                                //   ...ques,
                                //   media: newAnsList,
                                // });
                              }}
                            >
                              +
                            </Button>
                          )}
                        </div>
                      </div>
                    </>
                  ))}
                  <br />
                  {key.chartType &&
                    getChartData(elementName, key, index, "media", ques)}
                  <FormControl
                    component="fieldset"
                    variant="outlined"
                    className={[
                      classes.selectControl,
                      classes.customfield,
                    ].join(" ")}
                    style={{ width: "100%", marginTop: "10px" }}
                  >
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="Description"
                      variant="outlined"
                      style={{
                        marginBottom: "10px",
                      }}
                      disabled={disableField}
                      value={key.answerText}
                      onChange={(event) => {
                        let newAnsList = [...elementName];
                        newAnsList[index].questionanswer[ind].answerText =
                          event.target.value;
                        setques({ ...ques, media: newAnsList });
                      }}
                    />
                  </FormControl>
                  {key.filter === "question" ? (
                    <>
                      <div className="editor-description-box">
                        <b>Summary</b>
                        <JoditEditor
                          value={key.editorText}
                          // config={config}
                          tabIndex={1}
                          onBlur={(e) => {
                            let newAnsList = [];
                            newAnsList[index].editorText = e;
                            // setques({ ...ques, type: newAnsList });
                            let data = { ...ques, type: newAnsList };
                            setques(data);
                            setToLocal(data);
                          }}
                        />
                        {/* <Editor
                          wrapperClassName="demo-wrapper"
                          editorClassName="demo-editor"
                          editorState={key.editorText}
                          disabled={disableField}
                          onEditorStateChange={(e) => {
                            let newAnsList = [...elementName];
                            newAnsList[index].questionanswer[
                              ind
                            ].editorText = e;
                            setques({ ...ques, media: newAnsList });
                          }}
                          toolbar={{
                            options: [
                              "inline",
                              "fontSize",
                              "fontFamily",
                              "list",
                              "textAlign",
                              "link",
                              "history",
                              "image",
                            ],
                            fontSize: {
                              options: [
                                7,
                                8,
                                9,
                                10,
                                11,
                                12,
                                13,
                                14,
                                15,
                                16,
                                17,
                                18,
                                19,
                                20,
                                21,
                                22,
                                23,
                                24,
                                25,
                                26,
                                27,
                                28,
                                29,
                                30,
                                31,
                                32,
                                33,
                                34,
                                35,
                                36,
                                37,
                                38,
                                39,
                                40,
                                41,
                                42,
                                43,
                                44,
                                45,
                                46,
                                47,
                                48,
                                49,
                                50,
                                52,
                                54,
                                56,
                                58,
                                60,
                                62,
                                64,
                                66,
                                68,
                                70,
                                72,
                                74,
                                76,
                                78,
                                80,
                                82,
                                84,
                                86,
                                88,
                                90,
                                92,
                                94,
                                96,
                                98,
                                100,
                              ],
                            },
                            list: {
                              options: ["unordered", "ordered"],
                            },
                          }}
                        /> */}
                      </div>
                    </>
                  ) : null}
                  {elementName[index]?.questionanswer?.length > 1 && (
                    <Button
                      className={classes.addActionBtn}
                      style={{
                        width: "80px",
                        height: "40px",
                        marginRight: "10px",
                      }}
                      variant="outlined"
                      onClick={() => {
                        let newAnsList = [...elementName];
                        newAnsList[index].questionanswer.splice(ind, 1);
                        setques({ ...ques, media: newAnsList });
                      }}
                    >
                      -
                    </Button>
                  )}
                  {ind === elementName[index]?.questionanswer?.length - 1 && (
                    <Button
                      className={classes.addActionBtn}
                      style={{ width: "80px", height: "40px" }}
                      variant="outlined"
                      onClick={() => {
                        let newAnsList = [...elementName];
                        newAnsList[index].questionanswer.push({
                          question: "",
                          answers: [{ ans: "", percent: 0 }],
                          chartType: "",
                          answerText: "",
                          filter: "question",
                          editorText: "<p><br></p>",
                        });
                        setques({ ...ques, media: newAnsList });
                      }}
                    >
                      +
                    </Button>
                  )}
                </>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    ));
  };

  const MyChatBox = (element, editSecData) => {
    return (
      <>
        <RenderTopSection
          element={element}
          callback={(values) => {
            setques(values);
          }}
          // isCalledSubmit={isCallSubmit}
        />
        <NewSection ques={element} keyFindExpanded={keyFindExpanded} setques={setques} editSecData={editSecData}/>
        <RenderBarGraphData
          quests={ques}
          accorName="Foreword"
          elementName={element.foreword}
          disableField={element.saved}
          type="foreword"
          callback={(values) => {
            setques(values);
          }}
          keyFindExpanded={keyFindExpanded}
          // isCalledSubmit={isCallSubmit}
          getChartData={getChartData}
        />

        <RenderBarGraphData
          quests={ques}
          accorName="Introduction"
          elementName={element.introduction}
          disableField={element.saved}
          type="introduction"
          callback={(values) => setques(values)}
          keyFindExpanded={keyFindExpanded}
          // isCalledSubmit={isCallSubmit}
          getChartData={getChartData}
        />

        <RenderBarGraphData
          accorName="Executive Summary"
          elementName={element.executiveSummary}
          type="executiveSummary"
          quests={ques}
          disableField={element.saved}
          callback={(values) => setques(values)}
          keyFindExpanded={keyFindExpanded}
          // isCalledSubmit={isCallSubmit}
          getChartData={getChartData}
        />
        <RenderBarGraphData
          accorName="Background"
          elementName={element.background}
          type="background"
          quests={ques}
          disableField={element.saved}
          callback={(values) => setques(values)}
          keyFindExpanded={keyFindExpanded}
          // isCalledSubmit={isCallSubmit}
          getChartData={getChartData}
        />
        <RenderBarGraphData
          accorName="Aims and Objectives"
          elementName={element.aimsAndObjectives}
          type="aimsAndObjectives"
          quests={ques}
          disableField={element.saved}
          callback={(values) => setques(values)}
          keyFindExpanded={keyFindExpanded}
          // isCalledSubmit={isCallSubmit}
          getChartData={getChartData}
        />
        <RenderBarGraphData
          accorName="Steering Committee"
          elementName={element.steeringCommittee}
          type="steeringCommittee"
          quests={ques}
          disableField={element.saved}
          callback={(values) => setques(values)}
          keyFindExpanded={keyFindExpanded}
          // isCalledSubmit={isCallSubmit}
          getChartData={getChartData}
        />
        <RenderBarGraphData
          accorName="Top line Overview"
          elementName={element.topLineOverview}
          type="topLineOverview"
          quests={ques}
          disableField={element.saved}
          callback={(values) => setques(values)}
          keyFindExpanded={keyFindExpanded}
          // isCalledSubmit={isCallSubmit}
          getChartData={getChartData}
        />
        <RenderBarGraphData
          accorName="Key Insights"
          elementName={element.keyInsights}
          type="keyInsights"
          quests={ques}
          disableField={element.saved}
          callback={(values) => setques(values)}
          keyFindExpanded={keyFindExpanded}
          // isCalledSubmit={isCallSubmit}
          getChartData={getChartData}
        />
        <RenderBarGraphData
          accorName="Recommendations"
          elementName={element.recommendations}
          type="recommendations"
          quests={ques}
          disableField={element.saved}
          callback={(values) => setques(values)}
          keyFindExpanded={keyFindExpanded}
          // isCalledSubmit={isCallSubmit}
          getChartData={getChartData}
        />
        <RenderBarGraphData
          accorName="Best Practice"
          elementName={element.bestPractice}
          type="bestPractice"
          quests={ques}
          disableField={element.saved}
          callback={(values) => setques(values)}
          keyFindExpanded={keyFindExpanded}
          // isCalledSubmit={isCallSubmit}
          getChartData={getChartData}
        />
        <RenderBarGraphData
          accorName="Sentiment Results"
          elementName={element.sentimentResults}
          type="sentimentResults"
          quests={ques}
          disableField={element.saved}
          callback={(values) => setques(values)}
          keyFindExpanded={keyFindExpanded}
          // isCalledSubmit={isCallSubmit}
          getChartData={getChartData}
        />
        <RenderBarGraphData
          accorName="Summary"
          elementName={element.summary}
          type="summary"
          quests={ques}
          disableField={element.saved}
          callback={(values) => setques(values)}
          keyFindExpanded={keyFindExpanded}
          // isCalledSubmit={isCallSubmit}
          getChartData={getChartData}
        />
        <RenderBarGraphData
          accorName="Conclusions"
          elementName={element.conclusions}
          type="conclusions"
          quests={ques}
          disableField={element.saved}
          callback={(values) => setques(values)}
          keyFindExpanded={keyFindExpanded}
          // isCalledSubmit={isCallSubmit}
          getChartData={getChartData}
        />
        <RenderBarGraphData
          accorName="Appendices"
          elementName={element.appendices}
          type="appendices"
          quests={ques}
          disableField={element.saved}
          callback={(values) => setques(values)}
          keyFindExpanded={keyFindExpanded}
          // isCalledSubmit={isCallSubmit}
          getChartData={getChartData}
        />
        <RenderBarGraphData
          accorName="Benchmark Media Spend Analysis"
          elementName={element.benchmarkMediaSpendAnalysis}
          type="benchmarkMediaSpendAnalysis"
          quests={ques}
          disableField={element.saved}
          callback={(values) => setques(values)}
          keyFindExpanded={keyFindExpanded}
          // isCalledSubmit={isCallSubmit}
          getChartData={getChartData}
        />
        <RenderBarGraphData
          accorName="Media Consumption by Demographic"
          elementName={element.mediaConsumptionByDemographic}
          type="mediaConsumptionByDemographic"
          quests={ques}
          disableField={element.saved}
          callback={(values) => setques(values)}
          keyFindExpanded={keyFindExpanded}
          // isCalledSubmit={isCallSubmit}
          getChartData={getChartData}
        />
        {/* <RenderBarGraphData
          accorName="Media Spend Analysis"
          elementName={element.mediaSpendAnalysis}
          type="mediaSpendAnalysis"
          quests={ques}
          disableField={element.saved}
          callback={(values) => setques(values)}
          keyFindExpanded={keyFindExpanded}
          // isCalledSubmit={isCallSubmit}
          getChartData={getChartData}
        /> */}
        <RenderMediaBarGraphData
          elementName={element.media}
          quests={ques}
          disableField={element.saved}
          callback={(values) => setques(values)}
          keyFindExpanded={keyFindExpanded}
          // isCalledSubmit={isCallSubmit}
          getChartData={getChartData}
        />

        <RenderBarGraphData
          accorName="DECA Media Investment Plan"
          elementName={element.decaMediaInvestmentPlan}
          type="decaMediaInvestmentPlan"
          quests={ques}
          disableField={element.saved}
          callback={(values) => setques(values)}
          keyFindExpanded={keyFindExpanded}
          // isCalledSubmit={isCallSubmit}
          getChartData={getChartData}
        />
        <RenderBarGraphData
          accorName="ROI Breakdown"
          elementName={element.roiBreakdown}
          type="roiBreakdown"
          quests={ques}
          disableField={element.saved}
          callback={(values) => setques(values)}
          keyFindExpanded={keyFindExpanded}
          // isCalledSubmit={isCallSubmit}
          getChartData={getChartData}
        />
        <RenderBarGraphData
          accorName="Diversity Dividend Analysis"
          elementName={element.diversityDividendAnalysis}
          type="diversityDividendAnalysis"
          quests={ques}
          disableField={element.saved}
          callback={(values) => setques(values)}
          keyFindExpanded={keyFindExpanded}
          // isCalledSubmit={isCallSubmit}
          getChartData={getChartData}
        />
        <RenderBarGraphData
          accorName="Consumer Personas"
          elementName={element.consumerPersonas}
          type="consumerPersonas"
          quests={ques}
          disableField={element.saved}
          callback={(values) => setques(values)}
          keyFindExpanded={keyFindExpanded}
          // isCalledSubmit={isCallSubmit}
          getChartData={getChartData}
        />
        <RenderBarGraphData
          accorName="Creative Audit"
          elementName={element.creativeAudit}
          type="creativeAudit"
          quests={ques}
          disableField={element.saved}
          callback={(values) => setques(values)}
          keyFindExpanded={keyFindExpanded}
          // isCalledSubmit={isCallSubmit}
          getChartData={getChartData}
        />
        <RenderBarGraphData
          accorName="Process Review"
          elementName={element.processReviews}
          type="processReviews"
          quests={ques}
          disableField={element.saved}
          callback={(values) => setques(values)}
          keyFindExpanded={keyFindExpanded}
          // isCalledSubmit={isCallSubmit}
          getChartData={getChartData}
        />
        <RenderBarGraphData
          accorName="Methodology"
          elementName={element.methodology}
          type="methodology"
          quests={ques}
          disableField={element.saved}
          callback={(values) => setques(values)}
          keyFindExpanded={keyFindExpanded}
          // isCalledSubmit={isCallSubmit}
          getChartData={getChartData}
        />
        <RenderBarGraphData
          accorName="Key Finding"
          elementName={element.keyFinding}
          type="keyFinding"
          quests={ques}
          disableField={element.saved}
          callback={(values) => setques(values)}
          keyFindExpanded={keyFindExpanded}
          // isCalledSubmit={isCallSubmit}
          getChartData={getChartData}
        />
        <RenderBarGraphData
          accorName="Discussion And Implications"
          elementName={element.discussionAndImplications}
          type="discussionAndImplications"
          quests={ques}
          disableField={element.saved}
          callback={(values) => setques(values)}
          keyFindExpanded={keyFindExpanded}
          // isCalledSubmit={isCallSubmit}
          getChartData={getChartData}
        />

        <RenderAvatar
          accorName="Participant Demographics"
          elementName={element.participantDemographyQA}
          ques={ques}
          disableField={element.saved}
          callback={(values) =>  setques(values)}
          keyFindExpanded={keyFindExpanded}
          getChartData={getChartData}
          data={avatarData}
        />

        {/* {renderBarGraphData(
           "Foreword",
           element.foreword,
           element.saved,
           "foreword"
         )}  */}
        {/* {renderBarGraphData(
          "Introduction",
          element.introduction,
          element.saved,
          "introduction"
        )}
        {renderBarGraphData(
          "Executive Summary",
          element.executiveSummary,
          element.saved,
          "executiveSummary"
        )}

        {renderBarGraphData(
          "Background",
          element.background,
          element.saved,
          "background"
        )}
        {renderBarGraphData(
          "Aims and Objectives",
          element.aimsAndObjectives,
          element.saved,
          "aimsAndObjectives"
        )}
        {renderBarGraphData(
          "Steering Committee",
          element.steeringCommittee,
          element.saved,
          "steeringCommittee"
        )}
        {renderBarGraphData(
          "Top line Overview",
          element.topLineOverview,
          element.saved,
          "topLineOverview"
        )}
        {renderBarGraphData(
          "Key Insights",
          element.keyInsights,
          element.saved,
          "keyInsights"
        )}
        {renderBarGraphData(
          "Recommendations",
          element.recommendations,
          element.saved,
          "recommendations"
        )}
        {renderBarGraphData(
          "Best Practice",
          element.bestPractice,
          element.saved,
          "bestPractice"
        )}

        {renderBarGraphData(
          "Sentiment Results",
          element.sentimentResults,
          element.saved,
          "sentimentResults"
        )}
        {renderBarGraphData(
          "Summary",
          element.summary,
          element.saved,
          "summary"
        )}
     
        {renderBarGraphData(
          "Conclusions",
          element.conclusions,
          element.saved,
          "conclusions"
        )}
        {renderBarGraphData(
          "Appendices",
          element.appendices,
          element.saved,
          "appendices"
        )}
        {renderBarGraphData(
          "Benchmark Media Spend Analysis",
          element.benchmarkMediaSpendAnalysis,
          element.saved,
          "benchmarkMediaSpendAnalysis"
        )}
        {renderBarGraphData(
          "Media Consumption by Demographic",
          element.mediaConsumptionByDemographic,
          element.saved,
          "mediaConsumptionByDemographic"
        )} */}
        {/* {renderBarGraphData(
          "Media Spend Analysis",
          element.mediaSpendAnalysis,
          element.saved,
          "mediaSpendAnalysis"
        )} */}
        {/* {renderMediaBarGraphData(element.media, element.saved)} */}
        {/* 
        {renderBarGraphData(
          "DECA Media Investment Plan",
          element.decaMediaInvestmentPlan,
          element.saved,
          "decaMediaInvestmentPlan"
        )}
        {renderBarGraphData(
          "ROI Breakdown",
          element.roiBreakdown,
          element.saved,
          "roiBreakdown"
        )}
        {renderBarGraphData(
          "Diversity Dividend Analysis",
          element.diversityDividendAnalysis,
          element.saved,
          "diversityDividendAnalysis"
        )}
        {renderBarGraphData(
          "Consumer Personas",
          element.consumerPersonas,
          element.saved,
          "consumerPersonas"
        )}
        {renderBarGraphData(
          "Creative Audit",
          element.creativeAudit,
          element.saved,
          "creativeAudit"
        )}
        {renderBarGraphData(
          "Process Review",
          element.processReviews,
          element.saved,
          "processReviews"
        )}
        {renderBarGraphData(
          "Methodology",
          element.methodology,
          element.saved,
          "methodology"
        )}
        {renderBarGraphData(
          "Key Finding",
          element.keyFinding,
          element.saved,
          "keyFinding"
        )}
        {renderBarGraphData(
          "Discussion And Implications",
          element.discussionAndImplications,
          element.saved,
          "discussionAndImplications"
        )} */}
      </>
    );
  };

  const checkIfEmpty = (ob, type = "") => {
    let dummyData = [];
    dummyData = ob?.map((o, i) => {
      ob[i].editorText =
        typeof o.editorText == "string"
          ? o.editorText
          : stateToHTML(o.editorText?.getCurrentContent());

      if (o.answers[0]?.ans == "") {
        ob[i].answers = [{ ans: "", percent: 0 }];
      }

      return o;
    });

    if (
      dummyData?.length &&
      dummyData[0]?.answers[0]?.ans == "" &&
      dummyData[0]?.chartType == "" &&
      dummyData[0]?.question == "" &&
      dummyData[0]?.editorText == "<p><br></p>"
    ) {
      dummyData = [];
    }

    return dummyData.includes(undefined)
      ? dummyData.filter((i) => i)
      : dummyData;
  };

  const checkIfAvatarEmpty = (ob) => {
    let dummyData = [];
    dummyData =
      ob && ob.length
        ? ob.map((o, i) => {
            if (o.imageurl !== "" && o.message !== "") {
              return o;
            }
          })
        : [];
    return dummyData.includes(undefined)
      ? dummyData.filter((i) => i)
      : dummyData;
  };

  const checkMediaIsEmpty = (array) => {
    let dummyArrData = [];
    dummyArrData =
      array && array.length
        ? array.map((ar, index) => {
            let dummyData = [];
            dummyData = ar.questionanswer?.map((o, i) => {
              array[index].questionanswer[i].editorText =
                typeof o.editorText == "string"
                  ? o.editorText
                  : stateToHTML(o.editorText?.getCurrentContent());
              if (
                array[index].questionanswer[i].question !== "" &&
                array[index].questionanswer[0].ans == ""
              ) {
                array[index].questionanswer[i].answers = [
                  { ans: "", percent: 0 },
                ];
              }
              return o;
            });
            return dummyData.includes(undefined)
              ? {
                  mediaName: ar.mediaName,
                  questionanswer: dummyData.filter((i) => i),
                }
              : ar;
          })
        : [];

    if (
      dummyArrData?.length &&
      dummyArrData[0]?.questionanswer[0]?.answers[0]?.ans == "" &&
      dummyArrData[0]?.questionanswer[0]?.chartType == "" &&
      dummyArrData[0]?.questionanswer[0]?.question == "" &&
      dummyArrData[0]?.questionanswer[0]?.editorText == "<p><br></p>"
    ) {
      dummyArrData = [];
    }

    return dummyArrData.flat();
  };

  const addReport = async () => {
    // let data = JSON.parse(localStorage.getItem("reportData"));
    const datadetails = JSON.parse(localStorage.getItem("reportUserData"))
    let data = {};
    console.log("datadetails", datadetails)
    data.report_by = datadetails.report_by;
    data.report_date = datadetails.report_date;
    data.report_name = datadetails.report_name;
    data.demographic = datadetails.demographics;
    console.log("countries", datadetails.countries)
    data.countries = datadetails.countries;
    if (!(checkProperties(data))) {
      setErrSucMesg("Please enter above fields !");
      return;
    }
    setErrSucMesg("");
    const payload = { ...ques };

    let existData = []

    for (let k in payload) {
      if (
        ![
          "saved",
          "fromBE",
          "updating",
          "report_name",
          "report_by",
          "report_date",
          "demographic",
          "countries",
          "executiveSummary",
          "sentimentResults",
          "summary",
          "methodology",
          "discussionAndImplications",
          "introduction",
          "foreword",
          "conclusions",
          "appendices",
          "keyFinding",
          "background",
          "topLineOverview",
          "benchmarkMediaSpendAnalysis",
          "mediaConsumptionByDemographic",
          "aimsAndObjectives",
          "media",
          "decaMediaInvestmentPlan",
          "roiBreakdown",
          "diversityDividendAnalysis",
          "consumerPersonas",
          "processReviews",
          "keyInsights",
          "recommendations",
          "creativeAudit",
          "bestPractice",
          "steeringCommittee",
          "participantDemography",
          "participantDemographyQA",
        ].includes(k)
      ) {
        existData.push(k)
      }
    }

    if (existData.length > 0) {
      for (let v of existData) {
        payload[v] = checkIfEmpty(payload[v])
      }
    }

    const demo = [];
    data?.demographic &&
      data?.demographic.length &&
      data?.demographic?.map((de) => demo.push({ demographics: de }));
    payload.demographics = demo;
    payload.countries = data?.countries
    payload.methodology = checkIfEmpty(payload.methodology);
    payload.executiveSummary = checkIfEmpty(payload.executiveSummary);
    payload.summary = checkIfEmpty(payload.summary);
    payload.discussionAndImplications = checkIfEmpty(
      payload.discussionAndImplications
    );
    payload.report_date = payload.report_date
      ? moment(payload.report_date).format("YYYY-MM-DD hh:mm:ss.ssssss")
      : "";
    payload.introduction = checkIfEmpty(payload.introduction);
    payload.conclusions = checkIfEmpty(payload.conclusions);
    payload.appendices = checkIfEmpty(payload.appendices);
    // payload.participant_demography = payload.keyFinding;
    payload.keyFinding = checkIfEmpty(payload.keyFinding);
    payload.background = checkIfEmpty(payload.background);
    payload.foreword = checkIfEmpty(payload.foreword);
    payload.benchmarkMediaSpendAnalysis = checkIfEmpty(
      payload.benchmarkMediaSpendAnalysis
    );
    payload.processReviews = checkIfEmpty(payload.processReviews);
    payload.creativeAudit = checkIfEmpty(payload.creativeAudit);
    payload.sentimentResults = checkIfEmpty(
      payload.sentimentResults,
      "sentiment"
    );

    // payload.mediaSpendAnalysis = checkIfEmpty(payload.mediaSpendAnalysis);
    payload.decaMediaInvestmentPlan = checkIfEmpty(
      payload.decaMediaInvestmentPlan
    );
    payload.topLineOverview = checkIfEmpty(payload.topLineOverview);
    payload.mediaConsumptionByDemographic = checkIfEmpty(
      payload.mediaConsumptionByDemographic
    );
    payload.aimsAndObjectives = checkIfEmpty(payload.aimsAndObjectives);
    payload.media = checkMediaIsEmpty(payload.media);
    payload.roiBreakdown = checkIfEmpty(payload.roiBreakdown);
    payload.diversityDividendAnalysis = checkIfEmpty(
      payload.diversityDividendAnalysis
    );
    payload.consumerPersonas = checkIfEmpty(payload.consumerPersonas);
    payload.keyInsights = checkIfEmpty(payload.keyInsights);
    payload.recommendations = checkIfEmpty(payload.recommendations);
    payload.steeringCommittee = checkIfEmpty(payload.steeringCommittee);
    payload.bestPractice = checkIfEmpty(payload.bestPractice);
    payload.participantDemographyQA = checkIfEmpty(payload.participantDemographyQA);
    if (data?.participantDemography)
      payload.participantDemography = checkIfAvatarEmpty(
        data.participantDemography
      );

    // if(payload.participant_demography.length){
    //   delete payload.participant_demography[0].answerText;
    // }
  
    const toDeleteNewSection = JSON.parse(localStorage.getItem("newSectionData"))
    if (toDeleteNewSection !== null && toDeleteNewSection !== undefined && toDeleteNewSection.length > 0) {
      for (let i of toDeleteNewSection) {
        delete payload[i]
      }
    }

    delete payload.id;
    delete payload.saved;
    delete payload.fromBE;
    delete payload.updating;
    delete payload.chartType;
    delete payload.demographic;
    delete payload.question;
    delete payload.answers;
    delete payload.type;
    delete payload.report_by;
    delete payload.report_date;
    delete payload.report_name;

    if (data) {
      payload.report_by = data?.report_by;
      payload.report_date = data?.report_date;
      payload.report_name = data?.report_name;
    }

    let apiMethod = reportId ? axios.put : axios.post;
    let endPoint = reportId
      ? `${API}/admin/report/${reportId}`
      : `${API}/admin/report/`;
    await apiMethod(endPoint, payload, {
      headers: { Authorization: `Bearer ${access_token}` },
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
    })
      .then((res) => {
        // localStorage.removeItem("reportData");
        setques({
          keyFinding: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          background: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          topLineOverview: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          aimsAndObjectives: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          benchmarkMediaSpendAnalysis: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          // mediaSpendAnalysis: [
          //   {
          //     question: "",
          //     answers: [{ ans: "", percent: 0 }],
          //     chartType: "",
          //     answerText: "",
          //     filter: "question",
          //     editorText: "<p><br></p>",
          //   },
          // ],
          mediaConsumptionByDemographic: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          media: [
            {
              mediaName: "Media",
              questionanswer: [
                {
                  question: "",
                  answers: [{ ans: "", percent: 0 }],
                  chartType: "",
                  answerText: "",
                  filter: "question",
                  editorText: "<p><br></p>",
                },
              ],
            },
          ],
          decaMediaInvestmentPlan: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          roiBreakdown: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              filter: "question",
              answerText: "",
              editorText: "<p><br></p>",
            },
          ],
          diversityDividendAnalysis: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          consumerPersonas: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          keyInsights: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          recommendations: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          processReviews: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          creativeAudit: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          summary: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          sentimentResults: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          introduction: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          appendices: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          executiveSummary: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          foreword: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          conclusions: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          methodology: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          discussionAndImplications: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          participantDemographyQA: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          saved: false,
          fromBE: false,
          updating: false,
          report_name: "",
          report_by: "",
          report_date: null,
          demographic: [],
        });
        if (res) {
          setErrSucMesg(res.data.message);
          setErrSucType(true);
          setOpenDeciaModal(false);
          setTimeout(() => {
            history.push("/admin/report");
          }, 0);
        }
      })
      .catch(function (error) {
        setques({
          keyFinding: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          background: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          topLineOverview: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          aimsAndObjectives: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          benchmarkMediaSpendAnalysis: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          // mediaSpendAnalysis: [
          //   {
          //     question: "",
          //     answers: [{ ans: "", percent: 0 }],
          //     chartType: "",
          //     answerText: "",
          //     filter: "question",
          //     editorText: "<p><br></p>",
          //   },
          // ],
          mediaConsumptionByDemographic: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          media: [
            {
              mediaName: "Media",
              questionanswer: [
                {
                  question: "",
                  answers: [{ ans: "", percent: 0 }],
                  chartType: "",
                  answerText: "",
                  filter: "question",
                  editorText: "<p><br></p>",
                },
              ],
            },
          ],
          decaMediaInvestmentPlan: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          roiBreakdown: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              filter: "question",
              answerText: "",
              editorText: "<p><br></p>",
            },
          ],
          diversityDividendAnalysis: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          consumerPersonas: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          keyInsights: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          recommendations: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          processReviews: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          creativeAudit: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          summary: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          sentimentResults: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          introduction: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          appendices: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          executiveSummary: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          foreword: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          conclusions: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          methodology: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          discussionAndImplications: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          participantDemographyQA: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          saved: false,
          fromBE: false,
          updating: false,
          report_name: "",
          report_by: "",
          report_date: null,
          demographic: [],
          steeringCommittee: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
          bestPractice: [
            {
              question: "",
              answers: [{ ans: "", percent: 0 }],
              chartType: "",
              answerText: "",
              filter: "question",
              editorText: "<p><br></p>",
            },
          ],
        });
        if (error?.response?.data) {
          setErrSucMesg(error?.response?.data.message);
          setErrSucType(false);
          setOpenDeciaModal(false);
          setques({
            keyFinding: [
              {
                question: "",
                answers: [{ ans: "", percent: 0 }],
                chartType: "",
                answerText: "",
                filter: "question",
                editorText: "<p><br></p>",
              },
            ],
            background: [
              {
                question: "",
                answers: [{ ans: "", percent: 0 }],
                chartType: "",
                answerText: "",
                filter: "question",
                editorText: "<p><br></p>",
              },
            ],
            topLineOverview: [
              {
                question: "",
                answers: [{ ans: "", percent: 0 }],
                chartType: "",
                answerText: "",
                filter: "question",
                editorText: "<p><br></p>",
              },
            ],
            benchmarkMediaSpendAnalysis: [
              {
                question: "",
                answers: [{ ans: "", percent: 0 }],
                chartType: "",
                answerText: "",
                filter: "question",
                editorText: "<p><br></p>",
              },
            ],
            // mediaSpendAnalysis: [
            //   {
            //     question: "",
            //     answers: [{ ans: "", percent: 0 }],
            //     chartType: "",
            //     answerText: "",
            //     editorText: "<p><br></p>",
            //   },
            // ],
            mediaConsumptionByDemographic: [
              {
                question: "",
                answers: [{ ans: "", percent: 0 }],
                chartType: "",
                answerText: "",
                filter: "question",
                editorText: "<p><br></p>",
              },
            ],
            aimsAndObjectives: [
              {
                question: "",
                answers: [{ ans: "", percent: 0 }],
                chartType: "",
                answerText: "",
                filter: "question",
                editorText: "<p><br></p>",
              },
            ],
            media: [
              {
                mediaName: "Media",
                questionanswer: [
                  {
                    question: "",
                    answers: [{ ans: "", percent: 0 }],
                    chartType: "",
                    answerText: "",
                    filter: "question",
                    editorText: "<p><br></p>",
                  },
                ],
              },
            ],
            decaMediaInvestmentPlan: [
              {
                question: "",
                answers: [{ ans: "", percent: 0 }],
                chartType: "",
                answerText: "",
                filter: "question",
                editorText: "<p><br></p>",
              },
            ],
            roiBreakdown: [
              {
                question: "",
                answers: [{ ans: "", percent: 0 }],
                chartType: "",
                answerText: "",
                filter: "question",
                editorText: "<p><br></p>",
              },
            ],
            diversityDividendAnalysis: [
              {
                question: "",
                answers: [{ ans: "", percent: 0 }],
                chartType: "",
                answerText: "",
                filter: "question",
                editorText: "<p><br></p>",
              },
            ],
            consumerPersonas: [
              {
                question: "",
                answers: [{ ans: "", percent: 0 }],
                chartType: "",
                answerText: "",
                filter: "question",
                editorText: "<p><br></p>",
              },
            ],
            keyInsights: [
              {
                question: "",
                answers: [{ ans: "", percent: 0 }],
                chartType: "",
                answerText: "",
                filter: "question",
                editorText: "<p><br></p>",
              },
            ],
            recommendations: [
              {
                question: "",
                answers: [{ ans: "", percent: 0 }],
                chartType: "",
                answerText: "",
                filter: "question",
                editorText: "<p><br></p>",
              },
            ],
            saved: false,
            fromBE: false,
            updating: false,
            report_name: "",
            report_by: "",
            report_date: "",
            demographic: [],
            appendices: "<p><br></p>",
            introduction: "<p><br></p>",
            foreword: "<p><br></p>",
            conclusions: "<p><br></p>",
            executiveSummary: "<p><br></p>",
            methodology: "<p><br></p>",
            discussionAndImplications: "<p><br></p>",
            steeringCommittee: [
              {
                question: "",
                answers: [{ ans: "", percent: 0 }],
                chartType: "",
                answerText: "",
                filter: "question",
                editorText: "<p><br></p>",
              },
            ],
            bestPractice: [
              {
                question: "",
                answers: [{ ans: "", percent: 0 }],
                chartType: "",
                answerText: "",
                filter: "question",
                editorText: "<p><br></p>",
              },
            ],
            participantDemographyQA: [
              {
                question: "",
                answers: [{ ans: "", percent: 0 }],
                chartType: "",
                answerText: "",
                filter: "question",
                editorText: "<p><br></p>",
              },
            ],
          });
          setTimeout(() => {
            history.push("/admin/report");
          }, 1000);
        }
      });
  };

  const checkProperties = (obj) => {
    const queObj = { ...obj };
    let valid = true;
    if (
      queObj.report_name == "" ||
      queObj.report_by == "" ||
      typeof queObj.report_date === "object" ||
      queObj?.demographic?.length == 0
    ) {
      valid = false;
    } else {
      valid = true;
    }
    return valid;
    // if (!reportId) {
    //   delete queObj._id;
    //   delete ques._id;
    // }
    // const cleanedObject = Object.entries(queObj).reduce(
    //   (a, [k, v]) => (v || v === false || v === 0 ? ((a[k] = v), a) : a),
    //   {}
    // );
    // if (Object.keys(cleanedObject).length === Object.keys(ques).length) {
    //   return false;
    // } else {
    //   return true;
    // }
  };

  return (
    <div>
      <Modal
        open={openDeciaModal}
        onClose={() => {
          history.push("/admin/report");
          setOpenDeciaModal(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
      >
        <Fade in={true}>
          <Box className={classes.reportBoxModal}>
            <FormControl component="fieldset">
              {/* {tableLoading && <CircularProgress />} */}
              <div className={classes.boxShadow} style={{ marginTop: "10px" }}>
                <Close
                  style={{ float: "right" }}
                  onClick={() =>{
                    // localStorage.removeItem("reportData");
                    history.push("/admin/report")}}
                />
                <span
                  style={{
                    color: "#FB6D6A",
                    fontWeight: "600",
                    margin: "0",
                    fontSize: "20px",
                    marginLeft: "20px",
                  }}
                  onClick={() => setOpenDeciaModal(true)}
                >
                  Create DECiA Report
                </span>
                {MyChatBox(ques, editSecData)}
                <NewAlert
                  msg={ErrSucMsg}
                  isSuccess={ErrSucType}
                  close={() => setErrSucMesg("")}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "30px",
                    marginTop: "50px",
                  }}
                >
                  <FormControl>
                    <Button
                      className={classes.addActionBtn}
                      variant="outlined"
                      color="primary"
                      // disabled={checkProperties()}
                      onClick={() => {
                        addReport();
                      }}
                    >
                      Submit
                    </Button>
                  </FormControl>
                  <Button
                    className={classes.addActionBtn}
                    variant="outlined"
                    color="primary"
                    style={{ marginLeft: "10px" }}
                    onClick={() => {
                      history.push("/admin/report");
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </FormControl>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default MainLayout(CreateDecaReport);
