import { post, get, postUpload, postUploadCsv, put ,putAddNewMediaInventry} from './axios';

export const postCreate = async (data) => {
	return await post('/mediaPlanners', data);
};

export const getforecast = async (data) => {
	return await get('/mediaPlanners/forecast', data);
};

export const getInventories = async (id) => {
	return await get(`/mediaPlanners/inventory/${id}`);
};


