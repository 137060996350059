import React, { useState, useEffect } from "react";
import { makeStyles, fade } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { useForm, Controller } from "react-hook-form";

import CircularProgress from "@material-ui/core/CircularProgress";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

//Text field
import TextField from "@material-ui/core/TextField";

// Select
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

//Textarea
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

//Button
import Button from "@material-ui/core/Button";
import { useSelector } from "react-redux";

import Checkbox from "@material-ui/core/Checkbox";

import { useDispatch } from "react-redux";

import * as actions from "../../redux/actions/user";
import ShowAlert from "../../components/alerts";
import StepWrapper from "./stepWrapper";
import MainLayout from "../../layouts/newMainLayout";
import { useHistory } from "react-router-dom";
import Modal from "../../components/modal";
import { getCsvDropdown } from "../../redux/actions/admin";
import { postMediaSentiment } from "../../redux/actions/media";
import ChatBOxImg from "../../assets/chatBox.png";
import HumanImg from "../../assets/human.png";

import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";

import { API } from "../../constants/index";
import axios from "axios";
import "./costumStyles.css";

// import culturalIntell from "../../assets/Cultural-Intelligence.svg";
import sentmentLogo from "../../assets/sentimentLogo.PNG";
import { setMonth } from "date-fns";
import { Hidden } from "@material-ui/core";
import { green } from "@material-ui/core/colors";

import { SECRET_KEY } from "../../constants/index";
import useStyles from "../../components/StyleClasses";

const Sentiment = ({ onDone }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [demographic, setDemographic] = useState(0);
  const [industry, setIndustry] = useState(0);
  const [ageGroup, setAgeGroup] = useState(0);
  const [Brand, setBrand] = useState(0);
  const [brandList, setBrandList] = useState([]);
  const [insight, setInsight] = useState([]);

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const currentUser = useSelector((state) => state.loggedInSession);
  const dropdownData = useSelector((state) => state.adminDropDownData);
  const classes = useStyles();

  const refreshList = () => {
    if (demographic != 0 && industry != 0 && ageGroup != 0 && Brand != 0) {
      setLoading(true);

      const access_token = window.localStorage.getItem(SECRET_KEY);
      axios
        .get(
          `${API}/mediaPlanner/intelligence/decainsight?demographic=${demographic}&industry=${industry}&ageGroup=${ageGroup}&brand=${Brand}`,
          {
            headers: { Authorization: `Bearer ${access_token}` },
          }
        )
        .then((res, err) => {
          setLoading(false);
          if (err) {
          } else {
            if (Object.keys(res.data).length !== 0) {
              /// change condition later when res.data comes as empty array
              setInsight([...res.data.questions]);
            } else {
              setInsight([]);
            }
          }
        })
        .catch(function (error) {
          if (error) {
          }
        });
    }
  };

  useEffect(() => {
    refreshList();
  }, [demographic, industry, ageGroup, Brand]);

  const getBrands = () => {
    const access_token = window.localStorage.getItem(SECRET_KEY);
    axios
      .get(`${API}/mediaPlanner/brands?industry=${industry}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setBrandList(res.data);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };
  useEffect(() => {
    getBrands();
  }, [industry]);

  const MyChatBox = (element) => {
    return (
      <div
        style={{
          width: "30%",
          marginBottom: "80px",
          marginRight: "10px",
          marginLeft: "10px",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          padding: "10px",
        }}
      >
        <InputLabel
          style={{
            textAlign: "center",
            marginBottom: "5px",
          }}
        >
          <textarea
            disabled={true}
            value={element.question}
            rows={2}
            style={{ border: "1px solid #858585" }}
          ></textarea>
        </InputLabel>
        <div
          style={{
            backgroundImage: `url(${ChatBOxImg})`,
            width: "100%",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            right: "0",
            justifyContent: "center",
            display: "flex",
            paddingBottom: "40px",
          }}
        >
          <textarea
            disabled={true}
            value={element.answer}
            rows={8}
            style={{
              paddingTop: "10px",
              textAlign: "center",
              background: "transparent",
              resize: "none",
              border: "0 none",
              fontSize: "10px",
              outline: "none",
            }}
          ></textarea>
        </div>
        <div style={{ display: "flex" }}>
          <img
            style={{
              width: "40%",
              height: "35%",
              marginTop: "0",
              display: "block",
            }}
            src={HumanImg}
            alt="img"
          ></img>
          <div
            style={{
              marginTop: "40px",
            }}
          >
            <input
              value={element.name}
              disabled={true}
              style={{ width: "80%", marginBottom: "5px" }}
            />
            <input
              type="number"
              value={element.age}
              disabled={true}
              style={{ width: "80%" }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.boxShadow}>
      <div className={classes.paper}>
        <ShowAlert />
        <form
          className={classes.form}
          // ref={useRef()  }
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              fontSize: "10px",

              justifyContent: "space-between",
            }}
          >
            <img
              className={classes.logo}
              src="https://decaconsultancy.com/wp-content/uploads/2020/08/DECA-logo.png"
            />
            <div style={{ width: "18%", margin: "10px" }}>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
                style={{ marginTop: "10px" }}
              >
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Demographic"
                  // value={currency}
                  onChange={(e) => setDemographic(e.target.value)}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {dropdownData?.demographics?.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </FormControl>
            </div>
            <div style={{ width: "18%", margin: "10px" }}>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
                style={{ marginTop: "10px" }}
              >
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Age Group"
                  // value={currency}
                  onChange={(e) => setAgeGroup(e.target.value)}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {dropdownData?.ageGroups?.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </FormControl>
            </div>
            <div style={{ width: "18%", margin: "10px" }}>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
                style={{ marginTop: "10px" }}
              >
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Industry"
                  // value={currency}
                  onChange={(e) => setIndustry(e.target.value)}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {dropdownData?.industries?.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </FormControl>
            </div>
            <div style={{ width: "18%", margin: "10px" }}>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
                style={{ marginTop: "10px" }}
              >
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Brand"
                  // value={currency}
                  onChange={(e) => setBrand(e.target.value)}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {brandList.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </FormControl>
            </div>
            <div style={{ marginTop: "25px" }}>
              {loading && <CircularProgress />}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            {insight.map((element) => {
              element.id = element._id;
              element.saved = true;
              element.fromBE = true;
              return MyChatBox(element);
            })}
          </div>

          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <div>
              <InputLabel
                style={{
                  fontSize: "12px",
                }}
              >
                ROI REPORT
              </InputLabel>
              <TextField
                style={{
                  fontSize: "12px",
                }}
                placeholder="KEYWORD"
              />
            </div>
            <div>
              <InputLabel
                style={{
                  fontSize: "12px",
                }}
              >
                AD TECH CONSIDERATIONS
              </InputLabel>
              <TextField
                style={{
                  fontSize: "12px",
                }}
                placeholder="KEYWORD"
              />
            </div>
            <div>
              <InputLabel
                style={{
                  fontSize: "12px",
                }}
              >
                MEDIA INSIGHTS
              </InputLabel>
              <TextField
                style={{
                  fontSize: "12px",
                }}
                placeholder="KEYWORD"
              />
            </div>
            <div>
              <InputLabel
                style={{
                  fontSize: "12px",
                }}
              >
                CREATIVE INSIGHTS
              </InputLabel>
              <TextField
                style={{
                  fontSize: "12px",
                }}
                placeholder="KEYWORD"
              />
            </div>
          </div> */}
        </form>
      </div>
    </div>
  );
};

export default Sentiment;
