export default function searchMediaOwners(listMediaOwner,searchText) {
  const allMediaOwners = listMediaOwner  || [];

  const filteredMediaOwnerName = allMediaOwners.filter(owner => {
    return owner && owner.title && owner.title.name && owner.title.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
  })

  // const filteredMediaOwnerChanel = allMediaOwners.filter(owner => {
  //   return owner && owner.title && owner.title.name && owner.title.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
  // })
  // const allFilteredMediaOwners=filteredMediaOwnerChanel.concat(filteredMediaOwnerName);

  console.log("SEARCHED_MEDIA_OWNERS",filteredMediaOwnerName);
  return filteredMediaOwnerName;
}

export function searchPersonas(listPersons,searchText) {
  const allPersons = listPersons  || [];

  const filteredByName = allPersons.filter(item => {
    return item && item.about && item.about.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
  })

  console.log("SEARCHED_PERSONAS",filteredByName);
  return filteredByName;
}

export function searchUsers(userListData,searchText) {
  const all = userListData  || [];

  const filteredByName = all.filter(item => {
    return item && item.userName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
  })

  console.log("SEARCHED_USERS",filteredByName);
  return filteredByName;
}
