import { put, call, takeLatest } from 'redux-saga/effects';
import * as actions from '../redux/actions/brandUplift';
import * as actionsPersona from '../redux/actions/persona';
import { postBrandUplift, getBrandUpliftForecast } from '../services/api/brandUplift';

export function* callCreateBrandUpliftApi(param) {
  try {
    console.info("BRAND_PARAMS",param)
    const result = yield call(postBrandUplift,param && param.data);
    console.info("BRAND_result",result)
  } catch(error) {
    yield put(actionsPersona.error(error));
  }
}

export function* postBrandUpliftSaga() {
  yield takeLatest(actions.POST_BRAND_UPLIFT, callCreateBrandUpliftApi);
}

export function* callGetBrandUpliftForecastApi(param) {
  try {
    console.info("FORECAST_PARAMS",param)
    const result = yield call(getBrandUpliftForecast,param?.data?.demographic,param?.data?.industry);
    console.info("BRAND_FORECAST_result",result.data);
    yield put(actions.saveBrandUpliftForecast(result.data));
  } catch(error) {
    yield put(actionsPersona.error(error));
  }
}

export function* getBrandUpliftForecastSaga() {
  yield takeLatest(actions.GET_BRAND_UPLIFT_FORECAST, callGetBrandUpliftForecastApi);
}