import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useForm } from "react-hook-form";
import MainLayout from "../../layouts/adminMainLayout";
import axios from "axios";
import { useDispatch } from "react-redux";
import useStyles from "../../components/StyleClasses";
import { useHistory } from "react-router-dom";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import Button from "@material-ui/core/Button";
import Pagination from "@material-ui/lab/Pagination";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomizedTables from "../mediaOwner/mytable";

import { SECRET_KEY, API } from "../../constants/index";

import {
  ConvertDigital,
  ConvertPrint,
  ConvertRadio,
  ConvertOOH,
  ConvertTelevision,
} from "../../constants/Inventories";

import Highcharts, { color } from "highcharts";
import HighchartReact from "highcharts-react-official";
import funnel from "highcharts/modules/funnel.js";
funnel(Highcharts);

function paramsToObject(entries) {
  const result = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}

const SingleMediaView = () => {
  const history = useHistory();
  const urlparams = new URLSearchParams(history.location.search);
  const paramEntries = urlparams.entries();
  const params = paramsToObject(paramEntries);
  const dispatch = useDispatch();

  const classes = useStyles();
  const [mediaState, setMediaState] = useState("Overview");
  const [mediaData, setMediaData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [inventoryList, setInventoryList] = useState([]);
  const [inventoryCount, setInventoryCount] = useState(0);
  const [inventoryPage, setinventoryPage] = useState(1);
  const [inventorySearch, setinventorySearch] = useState("");
  const [tableHeads, setTableHeads] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableIds, setTableIds] = useState([]);
  const [titles, settitles] = useState(null);
  const [ChartDatas, setChartDatas] = useState(null);

  const [channelTYpe, setChannelType] = useState("");
  const access_token = window.localStorage.getItem(SECRET_KEY);
  const creator = window.localStorage.getItem("ID");

  const getdetails = () => {
    setLoading(true);

    axios
      .get(`${API}/admin/mediaPublication/${params?.id}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res, err) => {
        if (res) {
          setMediaData(res.data);
          const titles = Object.keys(res.data.AvgOfPersonas);
          const datas = Object.values(res.data.AvgOfPersonas);
          setChartDatas(datas);
          settitles(titles);
          setChannelType(res.data.channel.name);
          setLoading(false);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };
  useEffect(() => getdetails(), []);

  const getInventoryList = () => {
    if (channelTYpe === "") return;
    setLoading(true);
    axios
      .get(
        `${API}/admin/mediaInventory?page=${inventoryPage}&media=${params?.id}&model=${channelTYpe}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      .then((res, err) => {
        if (res) {
          setInventoryList(res.data.data);
          setInventoryCount(Math.ceil(res.data.total / 10));
          setLoading(false);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
          setLoading(false);
        }
      });
  };
  //useEffect(() => getInventoryList(), []);
  useEffect(() => getInventoryList(), [
    channelTYpe,
    inventoryPage,
    inventorySearch,
  ]);

  useEffect(() => {
    if (inventoryList.length <= 0) return;
    // let td = [];
    // inventoryList.forEach((e) => {
    //   td.push({
    //     data: [e.media.name, e.addType.name, e.channel.name, e.unitCost],
    //     _id: e._id,
    //   });
    // });
    if (channelTYpe === "Digital") {
      let td = [];
      inventoryList.forEach((e) => {
        const converted = ConvertDigital(e);
        td.push({
          data: Object.values(converted),
          _id: e._id,
        });
      });
      setTableData(td);
      let yyy = ConvertDigital(inventoryList[0]);
      if (yyy) {
        let zzz = Object.keys(yyy);
        let xxx = zzz.map((e) => e.replaceAll("_", " "));
        setTableHeads(xxx);
      }
    }
    if (channelTYpe === "Print") {
      let td = [];
      inventoryList.forEach((e) => {
        const converted = ConvertPrint(e);
        td.push({
          data: Object.values(converted),
          _id: e._id,
        });
      });
      setTableData(td);
      let yyy = ConvertPrint(inventoryList[0]);
      if (yyy) {
        let zzz = Object.keys(yyy);
        let xxx = zzz.map((e) => e.replaceAll("_", " "));
        setTableHeads(xxx);
      }
    }
    if (channelTYpe === "Radio") {
      let td = [];
      inventoryList.forEach((e) => {
        const converted = ConvertRadio(e);
        td.push({
          data: Object.values(converted),
          _id: e._id,
        });
      });
      setTableData(td);
      let yyy = ConvertRadio(inventoryList[0]);
      if (yyy) {
        let zzz = Object.keys(yyy);
        let xxx = zzz.map((e) => e.replaceAll("_", " "));
        setTableHeads(xxx);
      }
    }
    if (channelTYpe === "OOH") {
      let td = [];
      inventoryList.forEach((e) => {
        const converted = ConvertOOH(e);
        td.push({
          data: Object.values(converted),
          _id: e._id,
        });
      });
      setTableData(td);
      let yyy = ConvertOOH(inventoryList[0]);
      if (yyy) {
        let zzz = Object.keys(yyy);
        let xxx = zzz.map((e) => e.replaceAll("_", " "));
        setTableHeads(xxx);
      }
    }
    if (channelTYpe === "Television") {
      let td = [];
      inventoryList.forEach((e) => {
        const converted = ConvertTelevision(e);
        td.push({
          data: Object.values(converted),
          _id: e._id,
        });
      });
      setTableData(td);
      let yyy = ConvertTelevision(inventoryList[0]);
      if (yyy) {
        let zzz = Object.keys(yyy);
        let xxx = zzz.map((e) => e.replaceAll("_", " "));
        setTableHeads(xxx);
      }
    }
  }, [inventoryList]);

  const toggleMedia = (type) => {
    setMediaState(type);
  };

  const ToggleMediaSwitch = () => {
    switch (mediaState) {
      case "Insights":
        return Insights();
      case "Inventory":
        return <Inventory />;
      case "Overview":
        return Overview();
      default:
      // code block
    }
  };

  const MyChart = (title, objdata) => {
    const Xnames = Object.keys(objdata);
    const names = Xnames.map((e) => {
      let res = e.replaceAll("age_", "");
      res = res.replaceAll("I_", "");
      res = res.replaceAll("_", "-");
      return res;
    });

    const ys = Object.values(objdata);
    let newdata = [];
    names.forEach((e, i) => newdata.push({ name: e, y: ys[i] }));
    return (
      <div style={{ width: "33%", marginTop: "30px" }}>
        <HighchartReact
          highcharts={Highcharts}
          options={{
            chart: {
              plotBackgroundColor: null,
              plotBorderWidth: null,
              plotShadow: false,
              type: "pie",
            },
            title: {
              text: title,
            },
            tooltip: {
              pointFormat: "<b>{point.percentage:.1f}%</b>",
            },
            accessibility: {
              point: {
                valueSuffix: "%",
              },
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: "pointer",
                color: "white",
                dataLabels: {
                  enabled: true,
                  format: "<b>{point.name}</b><br>{point.percentage:.1f} %",
                  distance: -80,
                  filter: {
                    property: "percentage",
                    operator: ">",
                    value: 4,
                  },
                },
              },
            },
            series: [
              {
                name: "Percent",
                colorByPoint: true,
                data: newdata,
              },
            ],
          }}
        ></HighchartReact>
      </div>
    );
  };

  const Overview = () => {
    return (
      <div
        className={classes.boxShadow}
        style={{
          marginTop: "-50px",
          paddingTop: "50px",
          paddingBottom: "50px",
        }}
      >
        {loading ? (
          <div>
            <CircularProgress />
          </div>
        ) : (
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ width: "15%" }}>
                <img
                  style={{
                    width: "90%",
                    marginTop: "20px",
                    boxShadow:
                      "0px 0px 10px rgba(0,0,0,0.20), 0 0 10px rgba(0,0,0,0.15)",
                  }}
                  src={`${mediaData?.mediaImage}`}
                  alt="avatar.img"
                ></img>
              </div>
              <div style={{ width: "33%" }}>
                <h3
                  style={{
                    color: "#F44336",
                    fontWeight: "400",
                    margin: "0",
                    marginBottom: "0",
                    marginTop: "15px",
                  }}
                >
                  Name
                </h3>
                <span style={{ color: "#4F74C9" }}>{mediaData?.name}</span>
                <h3
                  style={{
                    color: "#F44336",
                    fontWeight: "400",
                    margin: "0",
                    marginBottom: "0",
                    marginTop: "15px",
                  }}
                >
                  Description
                </h3>
                <span style={{ color: "#4F74C9" }}>
                  {mediaData?.description}
                </span>
              </div>
              <div style={{ width: "24%" }}>
                {mediaData?.audience.map((e) => (
                  <>
                    <h3
                      style={{
                        color: "#F44336",
                        fontWeight: "400",
                        margin: "0",
                        marginBottom: "0",
                        marginTop: "15px",
                      }}
                    >
                      {e.qstn}
                    </h3>
                    <span style={{ color: "#4F74C9" }}>{e.ans}</span>
                  </>
                ))}
              </div>
              <div style={{ width: "24%" }}>
                <h3
                  style={{
                    color: "#F44336",
                    fontWeight: "400",
                    margin: "0",
                    marginBottom: "0",
                    marginTop: "15px",
                  }}
                >
                  Channel
                </h3>
                <span style={{ color: "#4F74C9" }}>
                  {mediaData?.channel.name}
                </span>
                <h3
                  style={{
                    color: "#F44336",
                    fontWeight: "400",
                    margin: "0",
                    marginBottom: "0",
                    marginTop: "15px",
                  }}
                >
                  Frequency
                </h3>
                <span style={{ color: "#4F74C9" }}>{mediaData?.frequency}</span>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              {titles && titles.map((e, i) => MyChart(e, ChartDatas[i]))}
            </div>
          </div>
        )}
      </div>
    );
  };
  const Inventory = () => {
    return (
      <div
        className={classes.boxShadow}
        style={{
          marginTop: "-50px",
          paddingTop: "50px",
          paddingBottom: "50px",
        }}
      >
        <div>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <Button
              onClick={() =>
                history.push(
                  `/admin/CreateInventory?creator=${params.creator}&id=${params?.id}&channel=${channelTYpe}&model=${channelTYpe}`
                )
              }
              className={classes.addActionBtn}
              variant="outlined"
              color="primary"
              style={{ borderRadius: "50px" }}
            >
              <AddCircleOutlineIcon
                style={{ marginRight: "10px", color: "white" }}
              />
              Create Inventory
            </Button>
            <div
              className={classes.addActionBtn}
              style={{
                backgroundColor: "white",
                paddingRight: "7px",
                paddingLeft: "7px",
                width: "50%",
              }}
            >
              <input
                placeholder="Search Campaigns"
                autoFocus
                //className={classes.addActionBtn}
                style={{
                  outline: "none",
                  backgroundColor: "white",
                  border: "solid 2px #E5E5E5",
                  color: "black",
                  borderRadius: "23px",
                  padding: "7px",
                  width: "90%",
                }}
                value={inventorySearch}
                onChange={(event) => {
                  setinventorySearch(event.target.value);
                }}
              ></input>
            </div>
            <span
              style={{
                color: "#FB6D6A",
                fontWeight: "600",
                margin: "0",
                fontSize: "20px",
                marginRight: "20px",
              }}
            >
              <Pagination
                count={inventoryCount}
                color="primary"
                onChange={(e, page) => setinventoryPage(page)}
                size="small"
              />
            </span>
          </div>

          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "50px",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                padding: "30px",
                justifyContent: "center",
              }}
            >
              <CustomizedTables
                isEditable={true}
                listdata={tableData}
                headings={tableHeads}
                invidsList={tableIds}
                redirectLink={`/admin/CreateInventory?id=${params.id}&creator=${creator}&model=${channelTYpe}&invID=`}
              />
            </div>
          )}
        </div>
      </div>
    );
  };
  const Insights = () => {
    return (
      <div
        className={classes.boxShadow}
        style={{
          marginTop: "-50px",
          paddingTop: "50px",
          paddingBottom: "50px",
        }}
      >
        Insights
      </div>
    );
  };

  return (
    <div>
      <Container style={{ maxWidth: "76vw" }}>
        <div className={classes.tabWrapper}>
          <span
            onClick={() => toggleMedia("Overview")}
            className={[
              classes.tab1,
              mediaState === "Overview" ? "selected" : "",
            ].join(" ")}
          >
            Overview
          </span>
          <span
            onClick={() => toggleMedia("Insights")}
            className={[
              classes.tab1,
              mediaState === "Insights" ? "selected" : "",
            ].join(" ")}
          >
            Insights
          </span>
          <span
            onClick={() => toggleMedia("Inventory")}
            className={[
              classes.tab1,
              mediaState === "Inventory" ? "selected" : "",
            ].join(" ")}
          >
            Inventory
          </span>
        </div>
        <ToggleMediaSwitch />
      </Container>
    </div>
  );
};

export default MainLayout(SingleMediaView);
