import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useForm } from "react-hook-form";
import MainLayout from "../../layouts/newMainLayout";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "../../components/StyleClasses";
import { useHistory } from "react-router-dom";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import Button from "@material-ui/core/Button";
import Pagination from "@material-ui/lab/Pagination";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomizedTables from "./mytable";

import { SECRET_KEY, API } from "../../constants/index";
import { TextField } from "@material-ui/core";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";

///icons
import AssignmentIcon from "@material-ui/icons/Assignment";
import FilterListIcon from "@material-ui/icons/FilterList";
import EventIcon from "@material-ui/icons/Event";
function paramsToObject(entries) {
  const result = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}

const dropdownData = {
  ReportTypes: [
    "overview",
    "publisher",
    "advertiser",
    "zone",
    "campaign",
    "ad-item",
  ],
  Publishers: [
    "Publisher 1",
    "Publisher 2",
    "Publisher 3",
    "Publisher 4",
    "Publisher 5",
  ],
  Zones: ["Zone 1", "Zone 2", "Zone 3", "Zone 4", "Zone 5"],
  Advertisers: [
    "Advertiser 1",
    "Advertiser 2",
    "Advertiser 3",
    "Advertiser 4",
    "Advertiser 5",
  ],
  Campaigns: [
    "Camapaign 1",
    "Camapaign 2",
    "Camapaign 3",
    "Camapaign 4",
    "Camapaign 5",
  ],
  AdItems: ["Ad Item 1", "Ad Item 2", "Ad Item 3", "Ad Item 4", "Ad Item 5"],
  Dates1: ["month", "year"],
  Dates2: ["last-month", "last-3-months", "last-6-months", "last-12-months"],
};

const tableStructure = {
  eligible_impressions: 2093,
  viewable_impressions: 1263,
  clicks: 0,
  payout: 7.46,
};
const ConvertToReport = (e) => {
  let result = {
    clicks: e.summary.clicks,
    payout: e.summary.payout,
  };
  if (e.summary.eligible_impressions) {
    result.eligible_impressions = e.summary.eligible_impressions;
  }
  if (e.summary.viewable_impressions) {
    result.viewable_impressions = e.summary.viewable_impressions;
  }
  return result;
};
const ConvertToVastReport = (e) => {
  let result = {
    impressions: e.summary.impressions,
    clicks: e.summary.clicks,
    payout: e.summary.payout,
  };
  return result;
};

const vastTableStructure = {
  impressions: 2,
  clicks: 0,
  payout: 0,
};

const Reports = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const adbutlerReportsData = useSelector((state) => state.adbutlerReportsData);
  const adbutlerVastReportsData = useSelector(
    (state) => state.adbutlerVastReportsData
  );
  const MOProfile = useSelector((state) => state.MOProfileOBJ);
  const [mediaState, setMediaState] = useState("Statistic Reports");
  const [ReportTypes, setReportTypes] = useState("campaign");
  const [Publishers, setPublishers] = useState([]);
  const [Zones, setZones] = useState([]);
  const [Advertisers, setAdvertisers] = useState([]);
  const [Campaigns, setCampaigns] = useState([]);
  const [AdItems, setAdItems] = useState([]);
  const [Dates1, setDates1] = useState(0);
  const [Dates2, setDates2] = useState(0);

  ///Data
  const [DATA, setDATA] = useState(
    adbutlerReportsData ? adbutlerReportsData : []
  );
  const [tableData, setTableData] = useState([]);
  const [tableHeads, settableHeads] = useState([]);
  const [page, setpage] = useState(1);
  const [pagecount, setPageCount] = useState(0);

  const [Spinner, setSpinner] = useState(false);
  const [noData, setNoData] = useState(false);

  const access_token = window.localStorage.getItem(SECRET_KEY);

  /////
  const [ReportTypesList, setReportTypesList] = useState(
    dropdownData?.ReportTypes
  );

  const ToggleMediaSwitch = () => {
    switch (mediaState) {
      case "Statistic Reports":
        return statisticReports();
      case "Vast Statistic Reports":
        return statisticReports();
      default:
      // code block
    }
  };
  const toggleMedia = (type) => {
    // setDATA([]);
    if (type == "Statistic Reports") {
      setDATA(adbutlerReportsData ? adbutlerReportsData : []);
    }
    if (type == "Vast Statistic Reports") {
      setDATA(adbutlerVastReportsData ? adbutlerVastReportsData : []);
    }
    settableHeads([]);
    setTableData([]);
    setPageCount(0);
    setpage(1);
    setMediaState(type);
  };
  useEffect(() => {
    let Dynamicheads = { ...tableStructure };
    if (MOProfile?.publisherType === "eligible") {
      delete Dynamicheads.viewable_impressions;
    } else {
      delete Dynamicheads.eligible_impressions;
    }

    const heads = Object.keys(
      mediaState === "Statistic Reports" ? Dynamicheads : vastTableStructure
    );
    settableHeads(heads);
    if (DATA.length > 0) {
      let td = [];
      for (let i = (page - 1) * 10; i < page * 10; i++) {
        if (i < DATA.length) {
          let e = DATA[i];
          let converted;

          if (mediaState === "Statistic Reports") {
            if (MOProfile.publisherType === "eligible") {
              delete e.summary.viewable_impressions;
            } else {
              delete e.summary.eligible_impressions;
            }
            converted = ConvertToReport(e);
          } else {
            converted = ConvertToVastReport(e);
          }
          let KeysToShow = Object.values(converted);
          td.push({
            data: KeysToShow,
            _id: i,
          });
        }
      }
      setTableData(td);
      let invoiceTD = [];
      DATA.forEach((dd) => {
        invoiceTD.push({
          impressions: dd.summary.impressions,
          cost: dd.summary.cost,
        });
      });
      dispatch({ type: "Invoice_Table_Data", payload: invoiceTD });
    }
  }, [DATA, page]);

  const getdata = () => {
    setDATA([]);
    setTableData([]);
    settableHeads([]);
    setSpinner(true);
    setNoData(false);
    let URLL = `${API}/mediaOwner/AdButler`;

    if (mediaState === "Statistic Reports") {
      URLL += `/getReports`;
    } else {
      URLL += `/getVASTReports`;
    }
    if (ReportTypes !== 0) URLL += `?type=${ReportTypes}`;
    if (Dates1 !== 0) URLL += `&period=${Dates1}`;
    if (Dates2 !== 0) URLL += `&preset=${Dates2}`;

    axios
      .get(URLL, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (err) {
          console.log(err);
        } else {
          if ("data" in res.data) {
            console.log("report  resp >>>>>>>>>>>>>", res.data.data);
            setDATA(res.data.data);
            setPageCount(res.data.data.length);
          } else {
            setNoData(true);
          }
        }
        setSpinner(false);
      })
      .catch(function (error) {
        if (error) {
          setSpinner(false);
          console.log(error);
        }
      });
  };

  const statisticReports = () => {
    return (
      <div
        className={classes.boxShadow}
        style={{
          marginTop: "-50px",
          paddingTop: "50px",
          paddingBottom: "50px",
        }}
      >
        <div className={[classes.selectControl, classes.customfield].join(" ")}>
          <h4 style={{ marginTop: "-20px", color: "#4B11A2" }}>{mediaState}</h4>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              margin: "10px 0",
            }}
          >
            <EventIcon />
            <span style={{ marginRight: "14px" }}>Dates</span>
            <TextField
              style={{ width: "20vw", marginRight: "10px" }}
              id="Dates1"
              select
              label="Preset"
              value={Dates1}
              onChange={(e) => setDates1(e.target.value)}
              SelectProps={{
                native: true,
              }}
              variant="outlined"
            >
              <option key={0} value={0} disabled>
                Select...
              </option>
              {dropdownData?.Dates1.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </TextField>
            <TextField
              style={{ width: "20vw" }}
              id="Dates2"
              select
              label="Period"
              value={Dates2}
              onChange={(e) => setDates2(e.target.value)}
              SelectProps={{
                native: true,
              }}
              variant="outlined"
            >
              <option key={0} value={0} disabled>
                Select...
              </option>
              {dropdownData?.Dates2.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </TextField>
          </div>

          <Button
            className={classes.addActionBtn}
            variant="outlined"
            color="primary"
            onClick={() => {
              getdata();
            }}
            style={
              Dates2 == 0 || Dates1 == 0 || ReportTypes == 0
                ? { backgroundColor: "grey", marginTop: "20px", display: "block" }
                : {marginTop: "20px", display: "block"}
            }
            disabled={Dates2 == 0 || Dates1 == 0 || ReportTypes == 0}
          >
            Generate Report
            {Spinner && (
              <CircularProgress
                size={10}
                style={{ margin: "0 20px", color: "white" }}
              />
            )}
          </Button>
        </div>
        {tableData.length > 0 && (
          <Button
            className={classes.addActionBtn}
            variant="outlined"
            color="primary"
            style={{ marginTop: "10px", marginBottom: "10px" }}
            onClick={() => history.push("/media-owner/Invoice")}
          >
            Generate Invoice
          </Button>
        )}
        {tableData.length > 0 && (
          <CustomizedTables
            style={{ width: "100%" }}
            isEditable={false}
            headings={tableHeads}
            listdata={tableData}
            //redirectLink={"MediaOwnerProfile?id="}
          />
        )}
        {tableData.length > 0 && (
          <div>
            <span
              style={{
                color: "#FB6D6A",
                fontWeight: "600",
                margin: "0",
                fontSize: "20px",
                marginRight: "20px",
              }}
            >
              <Pagination
                page={page}
                count={Math.ceil(pagecount / 10)}
                color="primary"
                onChange={(e, page) => setpage(page)}
                size="small"
              />
            </span>
          </div>
        )}
        {noData && <h5>NO DATA!</h5>}
      </div>
    );
  };

  return (
    <div>
      <Container style={{ maxWidth: "76vw" }}>
        <div className={classes.tabWrapper}>
          <span
            onClick={() => toggleMedia("Statistic Reports")}
            className={[
              classes.tab1,
              mediaState === "Statistic Reports" ? "selected" : "",
            ].join(" ")}
          >
            Statistic Reports
          </span>
          <span
            onClick={() => toggleMedia("Vast Statistic Reports")}
            className={[
              classes.tab1,
              mediaState === "Vast Statistic Reports" ? "selected" : "",
            ].join(" ")}
          >
            Vast Statistic Reports
          </span>
        </div>
        <ToggleMediaSwitch />
      </Container>
    </div>
  );
};
export default MainLayout(Reports);
