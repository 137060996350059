import React, { useState, useEffect } from "react";
import MainLayout from "../../layouts/adminMainLayout";
import FormControl from "@material-ui/core/FormControl";

import CircularProgress from "@material-ui/core/CircularProgress";
//Button
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
//Icon

import Autocomplete from "@material-ui/lab/Autocomplete";

import NewAlert from "../../components/NewAlert";
//TextField
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useStyles from "../../components/StyleClasses";
import { API, SECRET_KEY } from "../../constants/index";
import axios from "axios";
import MyTable from "../mediaOwner/mytable";
import ShowAlert from "../../components/alerts";

import {
  ConvertDigital,
  ConvertPrint,
  ConvertRadio,
  ConvertOOH,
  ConvertTelevision,
} from "../../constants/Inventories";

import * as actions from "../../redux/actions/user";
function paramsToObject(entries) {
  const result = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}

const CreateInventory = () => {
  const history = useHistory();
  const urlparams = new URLSearchParams(history.location.search);
  const paramEntries = urlparams.entries();
  const params = paramsToObject(paramEntries);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [mediaOwnerID, setMediaOwnerID] = useState(
    params.owner ? params.owner : null
  );
  const [mediaID, setMediaID] = useState(params.media ? params.media : null);
  const [mediaOwnerList, setMediaOwnerList] = useState([]);
  const [mediaList, setMediaList] = useState(null);
  const [DATA, setDATA] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [tableHeads, setTableHeads] = useState([]);
  const [namesearch, setNamesearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [loading, setLoading] = useState(false);
  const [Spinner, setSpinner] = useState(false);
  const [invSpinner, setInvSpinner] = useState(false);
  const [ErrSucMsg, setErrSucMesg] = useState("");
  const [ErrSucType, setErrSucType] = useState(true);
  const [duplicateToggle, setDuplicateToggle] = useState(false);
  const [duplicateRecord, setDuplicateRecord] = useState({});
  ///new flow///////////
  const [Model, setModel] = useState(params.model ? params.model : "");
  const [SelectedName, setSelectedName] = useState("");
  const [SelectedMediaName, setSelectedMediaName] = useState("");
  const [deleteModal, setDeleteModal] = useState("")

  const access_token = window.localStorage.getItem(SECRET_KEY);

  useEffect(() => {
    setSpinner(true);
    axios
      .get(`${API}/admin/mediaOwner?name=${namesearch}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res) {
          setMediaOwnerList(res.data.data);
          if (mediaOwnerID?.length > 10 || params?.owner?.length > 10) {
            let moval = res.data.data.find((e) => e._id === mediaOwnerID);
            setSelectedName(moval?.name);
          }
        }
        // setLoading(false);
      })
      .catch(function (error) {
        if (error) {
        }
      });
  }, [namesearch, mediaOwnerID]);

  useEffect(() => {
    setSpinner(false);
  }, [mediaOwnerList]);
  useEffect(() => {
    setInvSpinner(false);
  }, [mediaList]);

  useEffect(() => {
    if (!mediaOwnerID) return;

    setInvSpinner(true);
    setMediaList([]);
    axios
      .get(`${API}/admin/mediaPublication?creator=${mediaOwnerID}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res) {
          setMediaList(res.data.data);
          if (mediaID?.length > 10) {
            let moval = res.data.data.find((e) => e._id === mediaID);
            setSelectedMediaName(moval.name);
            setDeleteModal(moval?.channel?.name)
          }
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  }, [mediaOwnerID, mediaID]);

  const getTabledata = () => {
    if (mediaID === null) return;
    if (Model === "") return;
    setLoading(true);
    axios
      .get(`${API}/admin/mediaInventory?media=${mediaID}&model=${Model}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res) {
          setDATA(res.data.data);

          setLoading(false);
        }
      })
      .catch(function (error) {
        if (error) {
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    getTabledata();
  }, [mediaID]);

  useEffect(() => {
    if (DATA) {
      if (Model === "Digital") {
        let td = [];
        DATA.forEach((e) => {
          const converted = ConvertDigital(e);
          td.push({
            data: Object.values(converted),
            _id: e._id,
            otherData: e,
          });
        });
        setTableData(td);
        let yyy = ConvertDigital(DATA[0]);
        if (yyy) {
          let zzz = Object.keys(yyy);
          let xxx = zzz.map((e) => e.replaceAll("_", " "));
          setTableHeads(xxx);
        }
      }
      if (Model === "Print") {
        let td = [];
        DATA.forEach((e) => {
          const converted = ConvertPrint(e);
          td.push({
            data: Object.values(converted),
            _id: e._id,
            otherData: e,
          });
        });
        setTableData(td);
        let yyy = ConvertPrint(DATA[0]);
        if (yyy) {
          let zzz = Object.keys(yyy);
          let xxx = zzz.map((e) => e.replaceAll("_", " "));
          setTableHeads(xxx);
        }
      }
      if (Model === "Radio") {
        let td = [];
        DATA.forEach((e) => {
          const converted = ConvertRadio(e);
          td.push({
            data: Object.values(converted),
            _id: e._id,
            otherData: e,
          });
        });
        setTableData(td);
        let yyy = ConvertRadio(DATA[0]);
        if (yyy) {
          let zzz = Object.keys(yyy);
          let xxx = zzz.map((e) => e.replaceAll("_", " "));
          setTableHeads(xxx);
        }
      }
      if (Model === "OOH // DOOH") {
        let td = [];
        DATA.forEach((e) => {
          const converted = ConvertOOH(e);
          td.push({
            data: Object.values(converted),
            _id: e._id,
            otherData: e,
          });
        });
        setTableData(td);
        let yyy = ConvertOOH(DATA[0]);
        if (yyy) {
          let zzz = Object.keys(yyy);
          let xxx = zzz.map((e) => e.replaceAll("_", " "));
          setTableHeads(xxx);
        }
      }
      if (Model === "Television") {
        let td = [];
        DATA.forEach((e) => {
          const converted = ConvertTelevision(e);
          td.push({
            data: Object.values(converted),
            _id: e._id,
            otherData: e,
          });
        });
        setTableData(td);
        let yyy = ConvertTelevision(DATA[0]);
        if (yyy) {
          let zzz = Object.keys(yyy);
          let xxx = zzz.map((e) => e.replaceAll("_", " "));
          setTableHeads(xxx);
        }
      }
    }
  }, [DATA]);

  const DeleteInventory = (id) => {
    let delete_modal = deleteModal
    if(deleteModal == "OOH // DOOH"){
      delete_modal = "OOH"
    }
    axios
      .delete(`${API}/admin/mediaInventory/${deleteId}?model=${delete_modal}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res) {
          getTabledata();
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };

  // useEffect(() => {
  //   setMediaOwnerID(params.owner ? params.owner : null);
  // }, []);


  const DuplicateInventory = (row) => {
    let duplicateData = {...row.otherData};
    delete duplicateData._id;
    duplicateData.creator = duplicateData.creator ? duplicateData.creator._id:'';
    duplicateData.media = duplicateData.media ? duplicateData.media._id:'';
    axios
      .post(`${API}/admin/mediaInventory/digital`, duplicateData, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setErrSucMesg(res.data.message);
          setErrSucType(true);
          getTabledata();
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.data) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
          getTabledata();
        }
      });
  };

  return (
    <div style={{ maxWidth: "76vw" }}>
       <NewAlert
          msg={ErrSucMsg}
          isSuccess={ErrSucType}
          close={() => setErrSucMesg("")}
        />
       {(deleteFlag || duplicateToggle) && (
          <Dialog
            open={deleteFlag || duplicateToggle}
            onClose={() => {
              setDeleteFlag(false);
              setDuplicateToggle(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
            { deleteFlag ? 'Are you sure, you want to delete inventory?' :' Are you sure, you want to add duplicate inventory?'}
            </DialogTitle>
            <DialogActions>
              <Button
                style={{ color: "black", backgroundColor: "grey" }}
                onClick={() => {
                  setDeleteFlag(false);
                  setDuplicateToggle(false);
                }}
              >
                Cancel
              </Button>
              <Button
                style={{ backgroundColor: "#4F74C9", color:"white"}}
                onClick={() => {
                  if(deleteFlag){
                    setDeleteFlag(false);
                    DeleteInventory();
                  }
                  if(duplicateToggle){
                    setDuplicateToggle(false);
                    DuplicateInventory(duplicateRecord);
                  }
                }}
                autoFocus
              >
                {deleteFlag ? 'Ok':'Duplicate'}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      <div
        className={classes.customfield}
        style={{
          padding: "16px",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.20), 0 0 10px rgba(0,0,0,0.15)",
          borderRadius: "30px",
          backgroundColor: "white",
          marginTop: "20px",
        }}
      >
        <ShowAlert />
        <div
          style={{
            marginBottom: "15px",
          }}
        >
          <span
            style={{
              color: "#FB6D6A",
              fontWeight: "400",
              margin: "0",
              fontSize: "20px",
              marginLeft: "10px",
            }}
          >
            Inventory
          </span>
        </div>
        <div style={{ width: "40%", marginBottom: "80px" }}>
          <FormControl
            fullWidth
            component="fieldset"
            variant="outlined"
            style={{ marginTop: "10px" }}
          >
            {!mediaOwnerID && (
              <div style={{ display: "flex" }}>
                <Autocomplete
                  fullWidth
                  value={mediaOwnerList?.find((e) => e._id === params.owner)}
                  options={mediaOwnerList ?? []}
                  onChange={(e, val) => setMediaOwnerID(val?._id)}
                  getOptionLabel={(option) => option?.name}
                  style={{ marginTop: "10px", padding: "0" }}
                  getOptionSelected={(option, value) =>
                    option._id === value?._id
                  }
                  renderOption={(option) => (
                    <div style={{ padding: "0" }}>
                      <span>{option.name}</span>
                      <br></br>
                      <span
                        style={{
                          fontSize: "10px",
                          fontWeight: "100",
                          padding: "0",
                        }}
                      >
                        {option.username}
                      </span>
                    </div>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Media Owner"
                      onChange={(e) => setNamesearch(e.target.value)}
                      style={{ padding: "0" }}
                    />
                  )}
                />
                <span>
                  {Spinner && (
                    <CircularProgress
                      style={{ marginTop: "30px" }}
                      color="disabled"
                      size={20}
                    />
                  )}
                </span>
              </div>
            )}
            {mediaOwnerID && (
              <div>
                <span
                  style={{
                    color: "blue",
                    marginRight: "15px",
                    fontSize: "25px",
                  }}
                >
                  {SelectedName}
                </span>
                {SelectedName?.length > 0 && (
                  <button
                    onClick={() => {
                      setPageCount(0);
                      setMediaOwnerID(null);
                      setMediaID(null);
                      history.push("manageInventory");
                    }}
                  >
                    x
                  </button>
                )}
              </div>
            )}
            {mediaOwnerID && !mediaID && (
              <div style={{ display: "flex" }}>
                <Autocomplete
                  fullWidth
                  value={mediaList?.find((e) => e._id === params.media)}
                  options={mediaList}
                  onChange={(e, val) => {
                    setModel(val?.channel?.name);
                    setMediaID(val?._id);
                  }}
                  getOptionLabel={(option) => option?.name}
                  style={{ marginTop: "10px", padding: "0" }}
                  getOptionSelected={(option, value) =>
                    option._id === value?._id
                  }
                  renderOption={(option) => (
                    <div style={{ padding: "0" }}>
                      <span>{option.name}</span>
                      <br></br>
                      <span
                        style={{
                          fontSize: "10px",
                          fontWeight: "100",
                          padding: "0",
                        }}
                      >
                        {option.name}
                      </span>
                    </div>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Media"
                      style={{ padding: "0" }}
                    />
                  )}
                />
                <span>
                  {invSpinner && (
                    <CircularProgress
                      style={{ marginTop: "30px" }}
                      color="disabled"
                      size={20}
                    />
                  )}
                </span>
              </div>
            )}
            {mediaID && (
              <div style={{ marginTop: "20px" }}>
                <span
                  style={{
                    color: "#8811A2",
                    marginRight: "15px",
                    fontSize: "15px",
                  }}
                >
                  {SelectedMediaName}
                </span>
                {SelectedMediaName?.length > 0 && (
                  <button
                    onClick={() => {
                      setPageCount(0);
                      setMediaID(null);
                      if (params?.media) {
                        setMediaOwnerID(null);
                      }
                      history.push("manageInventory");
                    }}
                  >
                    x
                  </button>
                )}
              </div>
            )}
          </FormControl>
        </div>
        {mediaID && tableData !== null && !loading && (
          <button
            className={classes.addActionBtn}
            style={{ marginBottom: "10px" }}
            onClick={() =>
              history.push(
                `createInventory?creator=${mediaOwnerID}&id=${mediaID}&model=${Model}&channel=${Model}`
              )
            }
          >
            Create Inventory
          </button>
        )}
        {mediaID && tableData !== null && !loading && (
          <MyTable
            isEditable={true}
            headings={tableHeads}
            listdata={tableData}
            redirectLink={`createInventory?creator=${mediaOwnerID}&model=${Model}&channel=${Model}&id=${mediaID}&invID=`}
            handleDelete={(_id) =>{ 
              setDeleteFlag(true);
              setDeleteId(_id);
            }}
            handleDuplicate={(row) => {
              setDuplicateToggle(true);
              setDuplicateRecord(row);
            }}
          />
        )}
        {loading && <CircularProgress size={40} />}
        {/* {DATA.length == 0 && <span>No Inventories Created</span>} */}
        {/* <div
          style={{
            marginTop: "20px",
          }}
        >
          <button
            className={classes.addActionBtn}
            onClick={() => history.goBack()}
          >
            Cancel
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default MainLayout(CreateInventory);
