import React, { useState, useEffect } from "react";
import { SECRET_KEY, API } from "../constants/index";
import axios from "axios";

// import Button from "@material-ui/core/Button";

// import culturalIntellWhite from "../assets/Cultural-Intelligence.svg";

const Footer = () => {
  const [demographicList, setDemographicList] = useState([]);

  useEffect(()=>{
    const access_token = window.localStorage.getItem(SECRET_KEY);
    if(access_token) getDemographicData(access_token);
  },[]);

  const getDemographicData = (access_token) => {
    let dropdown = ''
    if (window.localStorage.getItem("role") === "media_agency") {
      dropdown = 'mediaPlanner'
    }
    if (window.localStorage.getItem("role") === "admin") {
      dropdown = 'admin'
    }
    if (window.localStorage.getItem("role") === "media_owner") {
      dropdown = 'mediaOwner'
    }
    if (window.localStorage.getItem("role") === "creativeconsultant") {
      dropdown = 'creativeconsultant'
    }
    axios
      .get(`${API}/${dropdown}/dropdowns`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res && res.data) {
          let dem = res.data.demographics.filter(i =>i.name !== 'Syrian' && i.name !== 'Saudi Arabians')
          setDemographicList(dem);
        }
      });
  };

  return (
    <div style={{marginTop: "auto"}}>
      <div
        style={{
          backgroundColor: "#4F74C9",
          textAlign: "center",
          color: "white",
          padding: "10px",
        }}
      >
        {/* LGBTQ+ • Black, Asian, Minority Ethnic • GenZ • Disability • Gender •
        Religion • Age */}
        {demographicList && demographicList.map((demo, i)=>{
          return <span key={i}>{demo.name} {demographicList.length - 1 !== i ? ' • ':''}</span>
        })}
      </div>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: "55%", padding: "10px", color: "#4F74C9" }}>
          <span style={{ fontSize: "30px", fontWeight: "600" }}>
            Get in Touch: +44 (0) 203 962 7179
          </span>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "30%" }}>
              <h3>London</h3>
              <h5>Tobbaco Dock</h5>
              <h5>Wapping Lane</h5>
              <h5>contact@brandadvance.co.uk</h5>
            </div>
            <div style={{ width: "30%" }}>
              <h3>Manchester</h3>
              <h5>Northern Quarter</h5>
              <h5>Dale Street</h5>
              <h5>mcr@brandadvance.co.uk</h5>
            </div>
            <div style={{ width: "30%" }}>
              <h3>New York</h3>
              <h5>Union Square</h5>
              <h5>Manhattan</h5>
              <h5>nyc@brandadvance.co.uk</h5>
            </div>
          </div>
        </div>

        <div
          style={{
            marginTop: "20px",
            width: "20%",
            padding: "10px",
            color: "#4F74C9",
          }}
        >
          <input style={{ margin: "5px", width: "100%" }} placeholder="Email" />
          <textarea
            rows={5}
            style={{ width: "100%", margin: "5px" }}
            placeholder="Message"
          ></textarea>
          <div style={{ display: "flex", flexFlow: "row-reverse" }}>
            <Button
              style={{
                backgroundColor: "#4F74C9",
                color: "white",
              }}
            >
              Send
            </Button>
          </div>
        </div>
        <div
          style={{
            width: "20%",
            padding: "10px",
            color: "#4F74C9",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <h3>Terms</h3>
            <h3>Policy</h3>
          </div>
          <img style={{ width: "100%" }} src={culturalIntellWhite} />
          <div
            style={{
              marginTop: "40px",
              display: "flex",
              flexFlow: "row-reverse",
            }}
          >
            <span>{"Copyright © 2021"}</span>
          </div>
        </div>
      </div> */}
    </div>
  );
};
export default Footer;
