export const CREATE_MEDIA_PLANNER = 'CREATE_MEDIA_PLANNER';
export const SAVE_MEDIA_PLANNER='SAVE_MEDIA_PLANNER';
export const SAVE_MEDIA_PLANNER_FORECAST='SAVE_MEDIA_PLANNER_FORECAST';
export const GET_MEDIA_PLANNER_FORECAST='GET_MEDIA_PLANNER_FORECAST';
export const SAVE_MEDIA_PLANNER_ID='SAVE_MEDIA_PLANNER_ID';
export const SAVE_MEDIA_PLANNER_INVENTRY='SAVE_MEDIA_PLANNER_INVENTRY';
export const GET_MEDIA_PLANNER_INVENTRY='GET_MEDIA_PLANNER_INVENTRY';
export const ERROR = 'ERROR';


export function createMediaPlanner(mediaPlanner) {
  return {
    type: CREATE_MEDIA_PLANNER,
    mediaPlanner,
  };
}

export function saveMediaPlanner(mediaPlanner) {
  return {
    type: SAVE_MEDIA_PLANNER,
    mediaPlanner,
  };
}

export function saveMediaPlannerID(savedMediaPlannerId) {
  return {
    type: SAVE_MEDIA_PLANNER_ID,
    savedMediaPlannerId,
  };
}

export function saveForecast(forecast) {
  return {
    type: SAVE_MEDIA_PLANNER_FORECAST,
    forecast,
  };
}

export function getForecast() {
  return {
    type: GET_MEDIA_PLANNER_FORECAST,
  };
}

export function saveMediaPlannerInventry(mediaPlannerInventory) {
  return {
    type: SAVE_MEDIA_PLANNER_INVENTRY,
    mediaPlannerInventory,
  };
}

export function getMediaPlannerInventry(mediaPlannerId) {
  return {
    type: GET_MEDIA_PLANNER_INVENTRY,
    mediaPlannerId
  };
}

export function error(error) {
  return {
    type: ERROR,
    error
  };
}
