import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useForm } from "react-hook-form";
import MediaLoginForm from "../../components/mediaLoginForm";
import MainLayout from "../../layouts/loginLayout";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  tab1: {
    marginRight: "10px",
    color: "#e96941",
    "&.selected": {
      zIndex: 10,
    },
  },

  tab2: {
    marginRight: "10px",
    color: "#6a5fcd",
    "&.selected": {
      zIndex: 10,
    },
  },

  tab3: {
    color: "#e502ba",
    "&.selected": {
      zIndex: 10,
    },
  },

  tab4: {
    color: "#4911a2",
    "&.selected": {
      zIndex: 10,
    },
  },
  
  tabWrapper: {
    display: "none",
    "@media screen and (max-width: 600px)" : {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "50px",
      "& span": {
        "border-top-right-radius": "25px",
        "border-top-left-radius": "25px",
        fontWeight: "bold",
        display: "inline-block",
        padding: "20px 30px 50px",
        backgroundColor: "white",
        position: "relative",
        cursor: "pointer",
      }
    }
  },
  loginContainer: {
    display: "none",
    "@media screen and (max-width: 600px)" : {
      display: "block",
    }
  }
}));

function paramsToObject(entries) {
  const result = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}

const MediaLogin = () => {
  const history = useHistory();
  const urlparams = new URLSearchParams(history.location.search);
  const paramEntries = urlparams.entries();
  const params = paramsToObject(paramEntries);
  const { register, handleSubmit, errors } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });
  const classes = useStyles();
  const [mediaState, setMediaState] = useState(
    params?.iam ? params.iam : "planner"
  );

  const toggleMedia = (type) => {
    setMediaState(type);
  };

  const ToggleMediaSwitch = () => {
    switch (mediaState) {
      case "owner":
        return (
          <MediaLoginForm
            type="Owner"
            colorTheme="#6a5fcd"
            role="media_owner"
          />
        );
      case "planner":
        return (
          <MediaLoginForm
            type="Planner"
            colorTheme="#e96941"
            role="media_agency"
          />
        );
      case "brand":
        return (
          <MediaLoginForm type="Brand" colorTheme="#e502ba" role="admin" />
        );
      case "creativeconsultant":
        return (
          <MediaLoginForm
            type="Consultant"
            colorTheme="#4911a2"
            role="creativeconsultant"
          />
        );
      // case "company":
      //   return (
      //     <MediaLoginForm type="Company" colorTheme="#e502ba" role="company" />
      //   );
      default:
      // code block
    }
  };
  // useEffect(() => {
  //   toggleMedia("planner");
  // }, []);

  return (
    <div>
      <Container maxWidth="sm" className={classes.loginContainer}>
        <div className={classes.tabWrapper}>
          <span
            onClick={() => {
              history.push({
                search: `?iam=planner`,
              });
              toggleMedia("planner");
            }}
            className={[
              classes.tab1,
              mediaState === "planner" ? "selected" : "",
            ].join(" ")}
          >
            Media Planner
          </span>
          <span
            onClick={() => {
              history.push({
                search: `?iam=owner`,
              });
              toggleMedia("owner");
            }}
            className={[
              classes.tab2,
              mediaState === "owner" ? "selected" : "",
            ].join(" ")}
          >
            Media Owner
          </span>
          <span
            onClick={() => {
              history.push({
                search: `?iam=brand`,
              });
              toggleMedia("brand");
            }}
            className={[
              classes.tab3,
              mediaState === "brand" ? "selected" : "",
            ].join(" ")}
          >
            Brand Advance
          </span>
          <span
            onClick={() => {
              history.push({
                search: `?iam=creativeconsultant`,
              });
              toggleMedia("creativeconsultant");
            }}
            className={[
              classes.tab4,
              mediaState === "creativeconsultant" ? "selected" : "",
            ].join(" ")}
          >
            Consultant
          </span>
          {/* <span
            onClick={() => {
              history.push({
                search: `?iam=company`,
              });
              toggleMedia("company");
            }}
            className={[
              classes.tab3,
              mediaState === "company" ? "selected" : "",
            ].join(" ")}
          >
            Company
          </span> */}
        </div>
        <ToggleMediaSwitch />
      </Container>
    </div>
  );
};

export default MainLayout(MediaLogin);
