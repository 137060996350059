/* eslint-disable no-unused-expressions */
import { InputLabel, Modal } from "@material-ui/core"
import { Box, Button, Card, CardContent, CardHeader, Grid } from "@material-ui/core/"
import Checkbox from "@material-ui/core/Checkbox"
import CircularProgress from "@material-ui/core/CircularProgress"
import FormControl from "@material-ui/core/FormControl"
import InputAdornment from "@material-ui/core/InputAdornment"
import LinearProgress from "@material-ui/core/LinearProgress"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import Table from "@material-ui/core/Table"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableRow from "@material-ui/core/TableRow"
import TextField from "@material-ui/core/TextField"
import CropSquareOutlined from "@material-ui/icons/CropSquareOutlined"
import MinimizeOutlined from "@material-ui/icons/MinimizeOutlined"
import axios from "axios"
import Highcharts, { color } from "highcharts"
import HighchartReact from "highcharts-react-official"
import Highcharts3d from "highcharts/highcharts-3d.js"
import highchartsMore from "highcharts/highcharts-more.js"
import Cylinder from "highcharts/modules/cylinder.js"
import Funnel from "highcharts/modules/funnel3d"
import Pyramid from "highcharts/modules/pyramid3d"
import solidGauge from "highcharts/modules/solid-gauge.js"
import variablePie from "highcharts/modules/variable-pie.js"
import parse from "html-react-parser"
import jwt_decode from "jwt-decode"
import React, { useEffect, useRef, useState } from "react"
import { Bar, Pie } from "react-chartjs-2"
import { Chart } from "react-google-charts"
import { useDispatch, useSelector } from "react-redux"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.css"
import culturalIntellWhite from "../../../assets/CI-Logo-white.png"
import CulturalIntelligenceLogo from "../../../assets/Cultural-Intelligence-New.png"
import GeneralAI from "../../../assets/General_AI.png"
import AngleLeft from "../../../assets/angle-left.svg"
import AngleRight from "../../../assets/angle-right.svg"
import Appendices from "../../../assets/appendices.png"
import Background from "../../../assets/background.png"
import benchmarkMediaSpendAnalysis from "../../../assets/benchmark.png"
import ChatGPTLogo from "../../../assets/chatgpt_icon.png"
import Conclusions from "../../../assets/conclusions.png"
import ConsumerPersonas from "../../../assets/consumerPersonas.png"
import DecaMediaInvestmentPlan from "../../../assets/decaMediaInvestmentPlan.png"
import DiversityDividendAnalysis from "../../../assets/diversityDividendAnalysis.png"
import ExpandIcon from "../../../assets/expand-icon.svg"
import Forward from "../../../assets/forward.png"
import Introduction from "../../../assets/introduction.png"
import KeyInsights from "../../../assets/keyInsights.png"
import Media from "../../../assets/media.png"
import MediaConsumptionbyDemographic from "../../../assets/mediaConsumptionbyDemographic.png"
import MediaSpendAnalysis from "../../../assets/mediaSpendAnalysis.png"
import Message from "../../../assets/message.png"
import People from "../../../assets/people.png"
import Recommendations from "../../../assets/recommendations.png"
import RoiBreakdown from "../../../assets/roiBreakdown.png"
import Search from "../../../assets/search.png"
import Setting from "../../../assets/setting.png"
import Summary from "../../../assets/summary.png"
import TopLineOverview from "../../../assets/topLineOverview.png"
import useStyles from "../../../components/StyleClasses"
import { API, SECRET_KEY } from "../../../constants/index"
import MainLayout from "../../../layouts/newMainLayoutPlanner"
import { SaveAdminDropDownData } from "../../../store/actions/admin/intelligence"
import Subscription from "../subscription"
import "./Dashboard.css"
import Globalmap from "./Globalmap"
import StaticDeviceData from "./device.json"
import StaticData from "./graph.json"
import HeatMap from "./heatmap"
import IntelligenceModal from "./intelligenceModal"
import StaticMediaData from "./media.json"
import StaticQuestionData from "./question.json"
// import DeciaReportLogo from "../../../assets/logos/deciaReportLogo.png";
import DeciaReportLogo from "../../../assets/Deca_Report_Icon.png"

import { Block } from "@material-ui/icons"
import {
    ArcElement,
    CategoryScale,
    Chart as ChartJS,
    LineController,
    LineElement,
    LinearScale,
    PointElement,
    registerables,
} from "chart.js"
import { connectableObservableDescriptor } from "rxjs/internal/observable/ConnectableObservable"

import moment from "moment"
import {
    BarChart,
    CartesianGrid,
    Cell,
    Legend,
    Line,
    LineChart,
    PieChart,
    Bar as ReBar,
    Pie as RePie,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts"

import Car_icon from "../../../assets/Car_icon.png"
import Electronics_icon from "../../../assets/Electronics_icon.png"
import Plane_icon from "../../../assets/Plane_icon.png"
import CsvUploadIcon from "../../../assets/upload-icon.png"
import LoaderCircle from "../../../components/Loader/Loader"
import GreenAdCampaigns from "../../admin/GreenAdCampaigns"
import EmissionMapComponent from "./emission_map"
import SimpleTable from "./simpleTable"


const adtechBreakdown = [
    { name: "creative", value: 23 },
    { name: "publisher", value: 24 },
    { name: "supply path", value: 56 },
]

const tier1spendData = [
    { name: "Black", value: 96 },
    { name: "Asian", value: 27 },
    { name: "Hispanic", value: 4 },
    { name: "LGBTQ+", value: 2 },
    { name: "Ability", value: 7 },
    { name: "Gen Z", value: 2 },
    { name: "other", value: 4 },
]

const tier2spendData = [
    { name: "Black", value: 66 },
    { name: "Asian", value: 54 },
    { name: "Hispanic", value: 24 },
    { name: "Ability", value: 12 },
    { name: "other", value: 4 },
]

const tier3spendData = [
    { name: "Black", value: 42 },
    { name: "Asian", value: 23 },
    { name: "Hispanic", value: 27 },
    { name: "Ability", value: 12 },
    { name: "other", value: 6 },
]

const mapOptions = {
    colorAxis: { colors: ["#E7BCDE", "#D8B4F8", "#8E8FFA", "#E5CFF7", "#ACBCFF", "#FFAACF"] },
    width: "400px",
    legend: "none",
    backgroundColor: "transparent",
    showZoomOut: false,
    tooltip: { isHtml: true, trigger: "visible" },
  };

const COLORS = ["#E7BCDE", "#D8B4F8", "#8E8FFA", "#E5CFF7", "#ACBCFF", "#FFAACF"]

const RADIAN = Math.PI / 180

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.1
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
        // <g transform={`translate(${x}, ${y})`} textAnchor={(midAngle < -90 || midAngle >= 90) ? 'end' : 'start'}>
        //   <text x={x} y={y}>{`${(percent * 100).toFixed(0)}%`}</text>
        // </g>
    )
}
const renderCustomizedLabelBlack = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.1
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
        <text x={x} y={y} fill="black" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
        // <g transform={`translate(${x}, ${y})`} textAnchor={(midAngle < -90 || midAngle >= 90) ? 'end' : 'start'}>
        //   <text x={x} y={y}>{`${(percent * 100).toFixed(0)}%`}</text>
        // </g>
    )
}

ChartJS.register(...registerables)
ChartJS.register(ArcElement, CategoryScale, LineController, LineElement, PointElement, LinearScale)

Highcharts3d(Highcharts)
Cylinder(Highcharts)
Funnel(Highcharts)
Pyramid(Highcharts)

const gridKeys = ["countries", "demographics", "report_date", "report_by", "report_name", "_id"]

const backgroundColor = [
    "rgba(255, 99, 132, 0.8)",
    "rgba(255, 159, 64, 0.8)",
    "rgba(255, 205, 86, 0.8)",
    "rgba(75, 192, 192, 0.8)",
    "rgba(54, 162, 235, 0.8)",
    "rgba(153, 102, 255, 0.8)",
    "rgba(201, 203, 207, 0.8)",
]
const borderColor = [
    "rgb(255, 99, 132)",
    "rgb(255, 159, 64)",
    "rgb(255, 205, 86)",
    "rgb(75, 192, 192)",
    "rgb(54, 162, 235)",
    "rgb(153, 102, 255)",
    "rgb(201, 203, 207)",
]

const genders = ["Male", "Female", "Others"]

const selectGraphArr = [
    {
        name: "Brand Awareness",
        kpi_type: "brand_awareness",
        color: "rgba(255, 99, 132, 0.8)",
    },
    {
        name: "Brand Consideration",
        kpi_type: "brand_consideration",
        color: "rgba(255, 159, 64, 0.8)",
    },
    {
        name: "Message Association",
        kpi_type: "message_association",
        color: "rgba(255, 205, 86, 0.8)",
    },
    {
        name: "Ad Recall",
        kpi_type: "ad_recall",
        color: "rgba(75, 192, 192, 0.8)",
    },
    {
        name: "Purchase Intent",
        kpi_type: "purchase_intent",
        color: "rgba(54, 162, 235, 0.8)",
    },
]

const Dashboard = (props) => {
    const dispatch = useDispatch()
    const divRef = useRef(null)
    const classes = useStyles()
    const [demographic, setDemographic] = useState(0)
    const [industry, setIndustry] = useState(0)
    const [age, setAge] = useState(0)
    const [topic, setTopic] = useState(0)
    const [country, setCountry] = useState(0)
    const [brand, setBrand] = useState(0)
    const [pubYear, setPubYear] = useState(0)
    const [BrandList, setBrandList] = useState([])
    const [selectedSection, setSelectedSection] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const [viewWidgets, setViewWidgets] = useState("")
    const [Spinner, setSpinner] = useState(false)
    const [section, setSection] = useState("")
    const [sectionType, setSectionType] = useState("")
    const [selectedReports, setSelectedReports] = useState(0)
    const [reportsList, setReportsList] = useState([])
    const [orgReportsList, setOrgReportsList] = useState([])
    const [reportData, setReportData] = useState({})
    const [reportsName, setReportsName] = useState([])
    const [graphModal, setGraphModal] = useState(false)
    const [selectedCountry, setSelectedCountry] = useState([])
    const [dropGender, setDropGender] = useState(0)
    const [reportModal, setReportModal] = useState(false)
    const [reportModalLoading, setReportModalLoading] = useState(true)
    /////sentiments section//////
    const [Sentimentloading, setSentimentLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [openReportModal, setOpenReportModal] = useState(false)
    const [openReport, setOpenReport] = useState()
    const [SentimentsGraphData, setSentimentsGraphData] = useState(null)
    const [renderDemographic, setrenderDemographic] = useState([])
    // const [renderAge, setRenderAge] = useState([]);
    const [topicName, setTopicname] = useState("")
    const [countryName, setCountryname] = useState("")
    const [allDemoGraphics, setAllDemoGraphics] = useState([])
    const [industriesList, setIndustriesList] = useState([])
    const [allAges, setAllAges] = useState([])

    //////deca section////////////
    const [Decaloading, setDecaLoading] = useState(false)
    const [DecaData, setDecaData] = useState([])
    const [stackDecaData, setStackDecaData] = useState([])
    const [allDecaData, setAllDecaData] = useState([])
    const [decaTotalPages, setDecaTotalPages] = useState(0)
    const [decaCurrentPage, setDecaCurrentPage] = useState(1)
    const [tableData, setTableData] = useState(null)
    const [InsiValue, setInsiValue] = useState("Media")
    const [insiteName, setInsiteName] = useState("")
    const [search, setSearch] = useState("")
    const [searchQuant, setSearchQuant] = useState("")

    /////brand metrics section//////////
    const [BrandMetricData, setBrandMetricdata] = useState(null)
    const [BrandMetricloading, setBrandMetricLoading] = useState(false)

    ///// que 1 in 5 section/////////////
    const [que1in5Data, setque1in5data] = useState([])
    const [quantTotalPages, setQuantTotalPages] = useState(0)
    const [quantCurrentPage, setQuantCurrentPage] = useState(1)
    const [allQue1in5Data, setAllQue1in5Data] = useState([])
    // const [que1in5OrgData, setque1in5Orgdata] = useState([]);
    const [que1in5loading, setque1in5Loading] = useState(false)

    const currentUser = useSelector((state) => state.loggedInSession)
    const dropdownData = useSelector((state) => state.adminDropDownData)
    const subscription = useSelector((state) => state.subscription)
    const access_token = window.localStorage.getItem(SECRET_KEY)
    const getSubscription = window.localStorage.getItem("subscription")
    let decodedSubscription = getSubscription && jwt_decode(getSubscription)
    const [ansArr, setAnsArr] = useState([])
    const [perArr, setPerArr] = useState([])
    // graph
    const [campaignList, setCampaignList] = useState([])
    const [campaign, setCampaign] = useState(0)
    const [questionName, setQuestionName] = useState([])
    const [loading, setLoading] = useState(false)
    const [insights, setInsights] = useState({})
    const [allQuestions, setAllQuestions] = useState([])
    const [ageData, setAgeData] = useState([])
    const [gender, setGender] = useState([])
    const [frequency, setFrequency] = useState([])
    const [creative, setCreative] = useState([])
    const [device, setDevice] = useState([])
    const [quantName, setQuantName] = useState("")
    const [insightsData, setInsightsData] = useState([])
    const [workoutsClicked, setWorkoutsClicked] = useState(false)
    const [reportModalOpen, setReportModalOpen] = useState(false)
    const [categoryArr, setCategoryArr] = useState([])

    const [singleGraphData, setSingleGraphData] = useState([])
    const [singleGraphColor, setSingleGraphColor] = useState([])
    const [count, forceUpdate] = useState(0)
    const [countQ, forceUpdateQ] = useState(0)
    const [globalMax, setGlobalMax] = useState(false)
    const [csvfile, setCsvfile] = useState(null) 
    const [keywordsFile, setKeywordsFile] = useState(null)

    const dummyQueries = [
        "What does the <span style='color:red;font-weight:bold'>Black</span> <span style='color:purple;font-weight:bold'>Male</span> consumer aged <span style='color:orange; font-weight:bold'>18-24</span> within the <span style='color:green'; font-weight:bold>UK</span> think about <span style='color:pink; font-weight:bold'>Apple</span>?",
        "What is the media consumption of an <span style='color:orange';font-weight:bold>18-24</span> year old <span style='color:red; font-weight:bold'>Black</span> <span style='color:purple; font-weight:bold'>Male</span> in the <span style='color:green; font-weight:bold'>UK</span>?",
        "What do <span style='color:red; font-weight:bold'>Black</span> <span style='color:purple; font-weight:bold'>Male</span> think?",
        "How big is the Hispanic community in the <span style='color:green; font-weight:bold'>UK</span>?",
    ]
    const [gptQueries, setGptQueries] = useState(dummyQueries)
    const [gptResponse, setGptResponse] = useState("")
    const [ciIntelligenceResponse, setCiIntelligenceResponse] = useState("")
    const [chatDemographic, setChatDemographic] = useState("")
    const [chatAge, setChatAge] = useState("")
    const [chatCountry, setChatCountry] = useState("")
    const [chatIndustry, setChatIndustry] = useState("")
    const [chatBrand, setChatBrand] = useState("")
    const [chatGender, setChatGender] = useState("")
    const [chatLoading, setChatLoading] = useState(false)
    const [ciChatLoading, setCiChatLoading] = useState(false)
    const [sidebarFilterPanel, setSidebarFilterPanel] = useState(false)
    const [em_br_btn, setEm_br_btn] = useState(0)
    const [greenCampaigns, setGreenCampaigns] = useState([])
    const [selectedGreenCampaign, setSelectedGreenCampaign] = useState("")
    const [goodLoopCampaignData, setGoodLoopCampaignData] = useState([])
    const [bar_data, setBar_data] = useState([
        {
            name: "Q1 2023",
            emission: 0.4,
        },
        {
            name: "Q2 2023",
            emission: 0.6,
        },
        {
            name: "Q3 2023",
            emission: 1.2,
        },
    ])
    const [device_bar_data, setDevice_bar_data] = useState([
        {
            name: "Android",
            emission: 0.4,
        },
        {
            name: "iOS",
            emission: 0.6,
        },
        {
            name: "Web",
            emission: 1.2,
        },
        {
            name: "Windows",
            emission: 0.7,
        },
        {
            name: "Mac OS X",
            emission: 0.8,
        },
        {
            name: "Other",
            emission: 0.3,
        },
    ])
    const [csvUploadLoading, setCsvUploadLoading] = useState(false)
    const [csvKeywordUploadLoading, setKeywordCsvUploadLoading] = useState(false)
    const [campaignCsvData, setCampaignCsvData] = useState([])

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p className="label">{`${payload[0].name} : ${payload[0].value}`}</p>
                    {/* <p className="desc">Anything you want can be displayed here.</p> */}
                </div>
            )
        }

        return null
    }

    const getGreenCampaigns = () => {
        const access_token = window.localStorage.getItem(SECRET_KEY)
        const company_id = window.localStorage.getItem("companyId")

        axios
            .get(`${API}/mediaPlanner/greenAdCampaigns/campaigns/${company_id}`, {
                headers: { Authorization: `Bearer ${access_token}` },
            })
            .then((res) => {
                if (res) {
                    setGreenCampaigns(res.data.campaigns)
                    setSelectedGreenCampaign(res.data.campaigns[0]._id)
                }
            })
    }

    const getCSVData = () => {
        const access_token = window.localStorage.getItem(SECRET_KEY)
        const campaignId = selectedGreenCampaign
        axios.get(`${API}/mediaPlanner/csvUpload/getCSVDataForCampaign/${campaignId}`, {
            headers: { Authorization: `Bearer ${access_token}` },
        }).then((res) => {
            console.log(res.data)
            setCampaignCsvData(res.data.data)
        }).catch((err) => {
            setCampaignCsvData([])
            console.log(err)
        })
    }

    const handleCsvUpload = (e) => {
        setCsvfile(e.target.files[0])
    }

    const handleKeywordsUpload = (e) => {
        setKeywordsFile(e.target.files[0])
        console.log(e.target.files[0], "keywords file")
    }

    const uploadCSV = () => {
        setCsvUploadLoading(true)
        const access_token = window.localStorage.getItem(SECRET_KEY)
        const name = greenCampaigns.filter((e) => e._id.toString() === selectedGreenCampaign)[0].name + " - Campaign CSV"
        const id = 1421
        const formData = new FormData()
        formData.append("csv", csvfile)
        formData.append("name", name)
        formData.append("id", Math.floor(Math.random() * 1000000))
        formData.append("campaignId", selectedGreenCampaign)
        axios.post(`${API}/mediaPlanner/csvUpload/matchDomains`, formData, {
            headers: { Authorization: `Bearer ${access_token}` },
        }).then((res) => {
            setCsvUploadLoading(false)
            console.log(res.data)
            getCSVData()
            setCsvfile(null)
        }).catch((err) => {
            setCsvUploadLoading(false)
            console.log(err)
        })
    }

    const uploadKeywordsCSV = () => {
        setKeywordCsvUploadLoading(true)
        const access_token = window.localStorage.getItem(SECRET_KEY)
        const campaignId = selectedGreenCampaign
        const formData = new FormData()
        formData.append("csv", keywordsFile)
        formData.append("campaignId", campaignId)
        axios.post(`${API}/mediaPlanner/keywords/create-campaign-keywords`, formData, {
            headers: { Authorization: `Bearer ${access_token}` },
        }).then((res) => {
            setKeywordCsvUploadLoading(false)
            console.log(res.data)
            setKeywordsFile(null)
        }).catch((err) => {
            setKeywordCsvUploadLoading(false)
            console.log(err)
        })
    }

    const getKeywordsCSV = () => {
        const access_token = window.localStorage.getItem(SECRET_KEY);
        const campaignId = selectedGreenCampaign;
        axios.get(`${API}/mediaPlanner/keywords/get-campaign-keywords/${campaignId}`, {
          headers: { Authorization: `Bearer ${access_token}` },
          responseType: 'blob', // set responseType to 'blob'
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = 'Keywords_output.csv';
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        }).catch((err) => {
          console.log(err);
        });
      };

    useEffect(() => {
        if (selectedGreenCampaign) {
            setGoodLoopCampaignData([])
            const access_token = window.localStorage.getItem(SECRET_KEY)

            axios
                .get(`${API}/mediaPlanner/goodloop/dashboard_data/${selectedGreenCampaign}`, {
                    headers: { Authorization: `Bearer ${access_token}` },
                })
                .then((res) => {
                    // console.log(res.data)
                    setGoodLoopCampaignData(res.data.data)
                })
        }
        getCSVData()
    }, [selectedGreenCampaign])

    useEffect(() => {
        let sectArr = ["talkwalker", "deca_reports", "global_map", "intelligence"]
        if (sectArr.length) {
            // getSections && getSections.length // Conditional operation for modal pop-up
            setModalOpen(false)
            setSelectedSection(sectArr)
        } else {
            setModalOpen(true)
        }
        getGreenCampaigns()

        // let localdata = localStorage.getItem("heatmapData");
        // if (localdata) {
        //   setViewWidgets("heatmap");
        //   let dt = JSON.parse(localdata);
        //   setCategoryArr(dt.categoryArr);
        //   setSelectedCountry(dt.selectedCountry);
        // }
    }, [])

    useEffect(() => {
        const access_token = window.localStorage.getItem(SECRET_KEY)
    }, [industry])

    useEffect(() => {
        const access_token = window.localStorage.getItem(SECRET_KEY)
        if (industry !== 0) {
            axios
                .get(`${API}/mediaPlanner/brands?industry=${industry}`, {
                    headers: { Authorization: `Bearer ${access_token}` },
                })
                .then((res) => {
                    setBrandList(res.data)
                })
        }
    }, [industry])

    useEffect(() => {
        getPersonasById()
    }, [])

    const getPersonasById = () => {
        setLoading(true)
        const id = window.localStorage.getItem("ID")
        // let endPoint = `${API}/mediaPlanner/persona?name=&page=1`;
        axios
            .get(`${API}/mediaPlanner/report/${id}`, {
                headers: { Authorization: `Bearer ${access_token}` },
            })
            .then((res) => {
                if (res && res.data && res.data.companyId) {
                    setIndustriesList(res.data.companyId.industries ? res.data.companyId.industries : [])
                }
                setLoading(false)
            })
            .catch(function (error) {
                if (error) {
                    setLoading(false)
                }
            })
    }

    const reportFilter = (reportList) => {
        // console.log(reportList, "reportList");
        if (demographic) {
            reportList = reportList.filter((item) => {
                if (!item.reports?.demographics || item.reports?.demographics?.length === 0) {
                    return false
                }
                let demo = item.reports.demographics.map((e) => e.demographics)
                return demo.includes(demographic)
            })
        }
        if (country) {
            reportList = reportList.filter((item) => {
                return item.reports.countries?.includes(country)
            })
        }
        console.log(reportList, "reportList")
        return reportList
    }

    const Reset = () => {
        setSentimentsGraphData(null)
        setDecaData([])
        setBrandMetricdata(null)
        setque1in5data([])
        setDemographic(0)
        setIndustry(0)
        setAge(0)
        setDropGender(0)
        setTopic(0)
        setCountry(0)
        setBrand(0)
        setPubYear(0)
        setInsiteName("")
        setSearch("")
        setSearchQuant("")
        setAllDecaData([])
        setAllQue1in5Data([])
    }

    const SentimentRefresh = () => {
        if (demographic != 0 && age != 0 && country != 0 && industry != 0) {
            setSentimentLoading(true)

            const body = {
                demographics: demographic,
            }
            if (age != 0 && age != "") {
                body.ageGroup = age
            }
            if (topicName != "") {
                body.topicName = topicName
            }
            // if (countryName != "") {
            //   body.countryName = countryName;
            // }
            if (pubYear != 0) {
                body.publishYear = parseInt(pubYear.toString())
            }
            if (topic != 0) {
                body.topic = topic
            }
            if (country != 0) {
                body.country = country
            }
            if (industry != 0) {
                body.industry = industry
            }
            if (brand != 0) {
                body.brand = brand
            }

            axios
                .post(`${API}/mediaPlanner/intelligence/sentiments?`, body, {
                    headers: { Authorization: `Bearer ${access_token}` },
                })
                .then((res) => {
                    setSentimentLoading(false)
                    if (res.data) {
                        /// change condition later when res.data comes as empty array
                        setSentimentsGraphData(res.data)
                    } else {
                        setSentimentsGraphData(null)
                    }
                })
                .catch((error) => setSentimentLoading(false))
        }
    }

    useEffect(() => {
        if (dropdownData && topic != 0) {
            const selected = dropdownData.topics.filter((e) => e._id === topic)
            if (selected.length > 0) {
                setTopicname(selected[0].name)
            }
        }
    }, [topic])

    useEffect(() => {
        if (dropdownData && country != 0) {
            const selected = dropdownData.countries.filter((e) => e._id === country)
            setCountryname(selected[0].name)
        }
    }, [country])

    useEffect(() => {
        SentimentRefresh()
    }, [demographic, brand, age, industry, pubYear, country, topic])

    useEffect(() => {
        que1in5Refresh()
    }, [demographic, age, industry, country, brand])

    useEffect(() => {
        if (dropdownData && demographic) {
            let a = dropdownData?.demographics?.filter((o) => demographic.includes(o._id))
            let b = a.map((e) => e.name + " , ")

            setrenderDemographic(b)
        }
    }, [demographic])

    useEffect(() => {
        let allD = dropdownData?.demographics?.map((e) => e._id)
        setAllDemoGraphics(allD)
        let allA = dropdownData?.ageGroups?.map((e) => e._id)
        setAllAges(allA)
    }, [dropdownData])

    useEffect(() => {
        getInsights()
    }, [])

    const getInsights = () => {
        setDecaLoading(true)
        let URLL = `${API}/mediaPlanner/getinsights`
        axios
            .get(URLL, {
                headers: { Authorization: `Bearer ${access_token}` },
            })
            .then((res, err) => {
                if (res) {
                    setInsightsData(res.data)
                    setDecaLoading(false)
                }
            })
            .catch(function (error) {
                if (error) {
                    setDecaLoading(false)
                }
            })
    }

    useEffect(() => {
        const access_token = window.localStorage.getItem(SECRET_KEY)
        axios
            .get(`${API}/mediaPlanner/dropdowns`, {
                headers: { Authorization: `Bearer ${access_token}` },
            })
            .then((res) => {
                if (res) {
                    dispatch(SaveAdminDropDownData(res.data))
                }
            })
    }, [])

    /////DECA///////
    const Decarefresh = () => {
        if (demographic != 0 && age != 0 && industry != 0 && country != 0) {
            setDecaLoading(true)
            let urll = `${API}/mediaPlanner/intelligence/decainsight?page=${decaCurrentPage}&demographic=${demographic}`
            if (industry != 0) {
                urll += `&industry=${industry}`
            }
            if (age != 0) {
                urll += `&ageGroup=${age}`
            }
            if (country != 0) {
                urll += `&country=${country}`
            }
            if (search !== "") {
                urll += `&keyword=${search}`
            }
            axios
                .get(urll, {
                    headers: { Authorization: `Bearer ${access_token}` },
                })
                .then((res) => {
                    setDecaLoading(false)
                    if (Object.keys(res.data).length !== 0) {
                        let pp = []
                        res.data.data.forEach((element) => {
                            element.questions.forEach((e) => {
                                e.insight = element.insight
                                pp.push(e)
                            })
                        })
                        if (pp.length <= 0) {
                            setDecaData(["false"])
                        } else {
                            setDecaData([...DecaData, ...pp])
                        }
                        let qq = []
                        setStackDecaData(res.data.questions)
                        setDecaTotalPages(res.data.total)
                        setDecaCurrentPage(res.data.current_page + 1)
                        setAllDecaData([...allDecaData, ...res.data.data])
                    } else {
                        setDecaData([])
                        setAllDecaData([])
                    }
                })
        }
    }

    useEffect(() => {
        Decarefresh()
    }, [count, industry, demographic, age, country])

    useEffect(() => {
        que1in5Refresh()
    }, [countQ])

    useEffect(() => {
        if (DecaData && DecaData[0] !== "false") {
            let td = []
            DecaData.forEach((e) => {
                td.push({
                    data: [e.question, e.answer, e.age, e.name],
                    _id: e._id,
                })
            })
            setTableData(td)
        }
    }, [DecaData])

    useEffect(() => {
        setDecaTotalPages(0)
        setDecaCurrentPage(1)
    }, [demographic, industry, age, InsiValue, country, dropGender])

    ////////////Brand Metric//////////////

    const BrandMetricRefresh = () => {
        if (demographic != 0 && age != 0 && dropGender != 0 && industry != 0) {
            setBrandMetricLoading(true)
            let URLL = `${API}/mediaPlanner/intelligence/BrandMetric?demographic=${demographic}`
            if (brand) URLL += `&brand=${brand}`
            if (industry != 0) URLL += `&industry=${industry}`
            if (country != 0) URLL += `&country=${country}`
            axios
                .get(URLL, {
                    headers: { Authorization: `Bearer ${access_token}` },
                })
                .then((res) => {
                    setBrandMetricLoading(false)
                    if (Object.keys(res.data).length !== 0) {
                        /// change condition later when res.data comes as empty array
                        setBrandMetricdata(res.data)
                    } else {
                        setBrandMetricdata(null)
                    }
                })
        }
    }

    useEffect(() => {
        BrandMetricRefresh()
    }, [demographic, industry, brand, country])

    //////////que 1 in 5 /////////////////
    const que1in5Refresh = () => {
        if (!selectedSection.includes("quant")) return
        if (demographic != 0 && age != 0 && industry != 0) {
            setque1in5Loading(true)
            let urll = `${API}/mediaPlanner/intelligence/OneFiveInsight?page=${quantCurrentPage}&demographic=${demographic}`
            if (industry != 0) urll += `&industry=${industry}`
            // if (brand) urll += `&brand=${brand}`;
            if (country != 0) urll += `&country=${country}`
            if (age != 0) urll += `&ageGroup=${age}`
            if (section != "") urll += `&type=${section.toLowerCase()}`
            if (searchQuant !== "") {
                urll += `&keyword=${searchQuant}`
            }
            axios
                .get(urll, {
                    headers: { Authorization: `Bearer ${access_token}` },
                })
                .then((res, err) => {
                    setque1in5Loading(false)
                    if (err) {
                    } else {
                        if (Object.keys(res.data).length !== 0) {
                            /// change condition later when res.data comes as empty array

                            let pp = []
                            res.data.data.forEach((e) => {
                                e.questions.forEach((element) => {
                                    element.type = element.One_In_Five === e._id ? e.type : ""
                                    pp.push(element)
                                })
                            })
                            setque1in5data([...que1in5Data, ...pp])
                            // setque1in5Orgdata(pp);
                            filterSectionData([...que1in5Data, ...pp])
                            setQuantTotalPages(res.data.total)
                            setQuantCurrentPage(res.data.current_page + 1)
                            setAllQue1in5Data([...allQue1in5Data, ...res.data.data])
                        } else {
                            setque1in5data([])
                        }
                    }
                })
        }
    }

    const getBarGraphData = () => {
        const nameArr = []
        const percentageData = []
        const bgColor = []
        const sentiColors = []
        const initialArr = []

        SentimentsGraphData &&
            SentimentsGraphData.map((e, i) => {
                let randomColor = Math.floor(Math.random() * 16777215).toString(16)
                bgColor.push(`#${randomColor}`)
                percentageData.push(e.score)
                dropdownData.topics.map((el) => {
                    if (el._id === e.topic) {
                        nameArr.push(el.name)
                    }
                })
            })
        const barStateData = {
            labels: nameArr,
            datasets: [
                {
                    label: "Brand Sentiment",
                    backgroundColor: bgColor,
                    borderColor: bgColor,
                    borderWidth: 1,
                    data: percentageData,
                },
            ],
        }
        if (percentageData && percentageData.length === 0) return null
        return (
            <Bar
                width="30px"
                height="20px"
                data={barStateData}
                options={{
                    plugins: {
                        legend: {
                            labels: {
                                color: "white",
                                text: "Brand Sentiment",
                                font: {
                                    size: 16,
                                },
                            },
                        },
                    },
                    scales: {
                        y: {
                            ticks: {
                                color: "white",
                                font: {
                                    size: 14,
                                },
                            },
                        },
                        x: {
                            ticks: {
                                color: "white",
                                font: {
                                    size: 14,
                                },
                            },
                        },
                    },
                }}
            />
        )
    }

    const MyChart = ({ demographics }) => {
        let mydata = []
        demographics &&
            demographics.forEach((element) => {
                mydata.push({ name: element, y: 100 / demographics.length })
            })
        return (
            <div>
                <HighchartReact
                    containerProps={{
                        style: {
                            height: "180px",
                            padding: "0",
                            backgroundColor: "transparent",
                        },
                    }}
                    highcharts={Highcharts}
                    options={{
                        chart: {
                            backgroundColor: "rgba(0,0,0,0)",
                            plotBackgroundColor: null,
                            plotBorderWidth: null,
                            plotShadow: false,
                            type: "pie",
                        },
                        title: {
                            text: "DEMOGRAPHICS",
                            style: { color: "white", fontSize: "10px", margin: "0" },
                        },

                        plotOptions: {
                            pie: {
                                allowPointSelect: true,
                                cursor: "pointer",
                                dataLabels: {
                                    enabled: false,
                                    format: "<b>{point.name}",
                                    distance: -100,
                                    filter: {
                                        property: "percentage",
                                        operator: ">",
                                        value: 4,
                                    },
                                },
                            },
                        },
                        series: [
                            {
                                name: "Percent",
                                colorByPoint: true,
                                // data: mydata,
                                data: [10, 20],
                            },
                        ],
                    }}
                ></HighchartReact>
            </div>
        )
    }

    const categories = (selectedData) => {
        setSelectedSection(selectedData)
    }

    useEffect(() => {
        if (selectedSection.includes("cultural_insights")) {
            Decarefresh()
        }
    }, [selectedSection])

    useEffect(() => {
        getCamapaignList()
    }, [])

    const getCamapaignList = () => {
        setLoading(true)
        setCampaignList([])
        // let endPoint = `/mediaPlanner/campaign`;
        let endPoint = `/mediaPlanner/lucidApi/`
        // let endPoint = `/mediaPlanner/campaign/${mid}/getCampaignByMediaPlanner`;

        axios
            .get(`${API}${endPoint}`, {
                headers: { Authorization: `Bearer ${access_token}` },
            })
            .then((res) => {
                if (res) {
                    setCampaignList(res.data.items)
                }
                setLoading(false)
            })
            .catch(function (error) {
                if (error) {
                    setLoading(false)
                }
            })
    }

    useEffect(() => {
        if (campaign === 0) return
        getLiftInsights()
        getLiftForAllQuestions()
        getLiftLiftByDemoAge()
        getLiftLiftByDemoGender()
        getLiftbyQuestionFrequency()
        getLiftBreakdownByCreative()
        getLiftByDevice()
    }, [campaign, questionName])

    const getLiftInsights = () => {
        setLoading(true)
        setInsights({})
        let endPoint = `/mediaPlanner/lucidApi/${campaign}/liftInsights?filter_question=${questionName}`

        axios
            .get(`${API}${endPoint}`, {
                headers: { Authorization: `Bearer ${access_token}` },
            })
            .then((res) => {
                if (
                    res &&
                    res.data &&
                    res.data.overview &&
                    ((res.data.overview.media && res.data.overview.media.length) ||
                        (res.data.overview.audience && res.data.overview.audience.length))
                ) {
                    setInsights(res.data.overview)
                } else {
                    setInsights(StaticMediaData)
                }
                setLoading(false)
            })
            .catch(function (error) {
                if (error) {
                    setLoading(false)
                }
            })
    }

    const getLiftForAllQuestions = () => {
        setLoading(true)
        setAllQuestions([])
        let endPoint = `/mediaPlanner/lucidApi/${campaign}/campaignData/liftForAllQuestions?filter_question=${questionName}`

        axios
            .get(`${API}${endPoint}`, {
                headers: { Authorization: `Bearer ${access_token}` },
            })
            .then((res) => {
                if (res && res.data && res.data.items && res.data.items.length) {
                    setAllQuestions(res.data.items)
                } else {
                    setAllQuestions(StaticQuestionData)
                }
                setLoading(false)
            })
            .catch(function (error) {
                if (error) {
                    setLoading(false)
                }
            })
    }

    const getLiftLiftByDemoAge = () => {
        setLoading(true)
        setAgeData([])
        let ageDatasets = []

        let endPoint = `/mediaPlanner/lucidApi/${campaign}/demo_age/liftByDemo?filter_question=${questionName}`

        axios
            .get(`${API}${endPoint}`, {
                headers: { Authorization: `Bearer ${access_token}` },
            })
            .then((res) => {
                if (
                    res.data &&
                    res.data &&
                    res.data.data &&
                    res.data.data.length &&
                    dropdownData &&
                    dropdownData.ageGroups &&
                    dropdownData.ageGroups.length
                ) {
                    ageDatasets = graphDataFunction(res.data.data, dropdownData.ageGroups)
                } else {
                    ageDatasets = graphDataFunction(res.data.data, dropdownData.ageGroups)
                }
                setAgeData(ageDatasets)
                setLoading(false)
            })
            .catch(function (error) {
                if (error) {
                    setLoading(false)
                }
            })
    }

    const getLiftLiftByDemoGender = () => {
        setLoading(true)
        setGender([])
        let genderDatasets = []

        let endPoint = `/mediaPlanner/lucidApi/${campaign}/demo_gender/liftByDemo?filter_question=${questionName}`
        axios
            .get(`${API}${endPoint}`, {
                headers: { Authorization: `Bearer ${access_token}` },
            })
            .then((res) => {
                if (
                    res.data &&
                    res.data &&
                    res.data.data &&
                    res.data.data.length &&
                    dropdownData &&
                    dropdownData.ageGroups &&
                    dropdownData.ageGroups.length
                ) {
                    genderDatasets = graphDataFunction(res.data.data, dropdownData.ageGroups)
                } else {
                    genderDatasets = graphDataFunction(res.data.data, dropdownData.ageGroups)
                }
                setGender(genderDatasets)
                setLoading(false)
            })
            .catch(function (error) {
                if (error) {
                    setLoading(false)
                }
            })
    }

    const getLiftbyQuestionFrequency = () => {
        setLoading(true)
        setFrequency([])
        let frequencyDatasets = []
        let endPoint = `/mediaPlanner/lucidApi/${campaign}/campaignData/LiftbyQuestionFrequency?filter_question=${questionName}`

        axios
            .get(`${API}${endPoint}`, {
                headers: { Authorization: `Bearer ${access_token}` },
            })
            .then((res) => {
                if (
                    res.data &&
                    res.data &&
                    res.data.data &&
                    res.data.data.length &&
                    dropdownData &&
                    dropdownData.ageGroups &&
                    dropdownData.ageGroups.length
                ) {
                    frequencyDatasets = graphDataFunction(res.data.data, dropdownData.ageGroups)
                } else {
                    frequencyDatasets = graphDataFunction(res.data.data, dropdownData.ageGroups)
                }
                setFrequency(frequencyDatasets)
                setLoading(false)
            })
            .catch(function (error) {
                if (error) {
                    setLoading(false)
                }
            })
    }

    const getLiftBreakdownByCreative = () => {
        setLoading(true)
        setCreative([])
        const liftDatasets = []
        const creativeFinalData = []
        const liftLabels = []
        let endPoint = `/mediaPlanner/lucidApi/${campaign}/campaignData/liftBreakdownByCreative?filter_question=${questionName}`

        axios
            .get(`${API}${endPoint}`, {
                headers: { Authorization: `Bearer ${access_token}` },
            })
            .then((res) => {
                if (res && res.data && res.data.items && res.data.items.length) {
                    res.data.items.map((item, i) => {
                        liftLabels.push(item.creative_name)
                        liftDatasets.push({
                            label: item.creative_name,
                            data: [item.lift_percentage ? item.lift_percentage : 0],
                            borderWidth: 1,
                            backgroundColor: backgroundColor[i],
                            borderColor: borderColor[i],
                        })
                    })
                    creativeFinalData.push({
                        labels: liftLabels,
                        datasets: liftDatasets,
                    })
                    setCreative(creativeFinalData)
                } else {
                    StaticDeviceData.map((item, i) => {
                        liftLabels.push(item.creative_name)
                        liftDatasets.push({
                            label: item.creative_name,
                            data: [item.lift_percentage ? item.lift_percentage : 0],
                            borderWidth: 1,
                            backgroundColor: backgroundColor[i],
                            borderColor: borderColor[i],
                        })
                    })
                    creativeFinalData.push({
                        labels: liftLabels,
                        datasets: liftDatasets,
                    })
                    setCreative(creativeFinalData)
                }
                setLoading(false)
            })
            .catch(function (error) {
                if (error) {
                    setLoading(false)
                }
            })
    }

    const getLiftByDevice = () => {
        setLoading(true)
        setDevice([])
        const liftDatasetsArr = []
        const deviceFinalData = []
        const liftLabelsArr = []
        let endPoint = `/mediaPlanner/lucidApi/${campaign}/liftByDevice?filter_question=${questionName}`

        axios
            .get(`${API}${endPoint}`, {
                headers: { Authorization: `Bearer ${access_token}` },
            })
            .then((res) => {
                if (res && res.data && res.data.items && res.data.items.length) {
                    res.data.items.map((item, i) => {
                        liftLabelsArr.push(item.device_name)
                        liftDatasetsArr.push({
                            label: item.device_name,
                            data: [item.lift_percentage ? item.lift_percentage : 0],
                            borderWidth: 1,
                            backgroundColor: backgroundColor[i],
                            borderColor: borderColor[i],
                        })
                    })
                    deviceFinalData.push({
                        labels: liftLabelsArr,
                        datasets: liftDatasetsArr,
                    })
                    setDevice(deviceFinalData)
                } else {
                    StaticDeviceData.map((item, i) => {
                        liftLabelsArr.push(item.device_name)
                        liftDatasetsArr.push({
                            label: item.device_name,
                            data: [item.lift_percentage ? item.lift_percentage : 0],
                            borderWidth: 1,
                            backgroundColor: backgroundColor[i],
                            borderColor: borderColor[i],
                        })
                    })
                    deviceFinalData.push({
                        labels: liftLabelsArr,
                        datasets: liftDatasetsArr,
                    })
                    setDevice(deviceFinalData)
                }
                setLoading(false)
            })
            .catch(function (error) {
                if (error) {
                    setLoading(false)
                }
            })
    }
    const getDMYDate = (dateTime) => {
        const newdate = new Date(dateTime)
        const d = newdate.getDate()
        const m = newdate.getMonth() + 1
        const y = newdate.getFullYear()
        const formattedate = (d <= 9 ? "0" + d : d) + "/" + (m <= 9 ? "0" + m : m) + "/" + y
        return formattedate
    }

    const graphDataFunction = (ageDataArr, ages) => {
        let arr = ageDataArr && ageDataArr.length ? [...ageDataArr] : [...StaticData]
        let brand_awareness = []
        let brand_consideration = []
        let message_association = []
        let ad_recall = []
        let purchase_intent = []
        let bucket_name = []
        ages.map((as) => {
            bucket_name.push(as.name)
        })
        const datasets = []
        let labels = []
        let datasetLabel = []
        const finalData = []
        arr &&
            arr.length &&
            arr.map((ag, i) => {
                switch (ag.question.question_name) {
                    case "brand_awareness":
                        ag.mapData.filter((a) => {
                            ages.map((as) => {
                                if (a.bucket_name === as.name) {
                                    brand_awareness.push(a.total_count)
                                }
                            })
                        })
                        break
                    case "brand_consideration":
                        ag.mapData.filter((a) => {
                            ages.map((as) => {
                                if (a.bucket_name === as.name) {
                                    brand_consideration.push(a.total_count)
                                }
                            })
                        })
                        break
                    case "message_association":
                        ag.mapData.filter((a) => {
                            ages.map((as) => {
                                if (a.bucket_name === as.name) {
                                    message_association.push(a.total_count)
                                }
                            })
                        })
                        break
                    case "ad_recall":
                        ag.mapData.filter((a) => {
                            ages.map((as) => {
                                if (a.bucket_name === as.name) {
                                    ad_recall.push(a.total_count)
                                }
                            })
                        })
                        break
                    case "purchase_intent":
                        ag.mapData.filter((a) => {
                            ages.map((as) => {
                                if (a.bucket_name === as.name) {
                                    purchase_intent.push(a.total_count)
                                }
                            })
                        })
                        break
                    default:
                        return ag
                }
            })
        selectGraphArr.map((sg) => {
            datasetLabel.push(sg.name)
        })
        datasetLabel &&
            datasetLabel.length &&
            datasetLabel.map((dl, i) => {
                datasets.push({
                    label: datasetLabel[i],
                    data: [
                        brand_awareness && brand_awareness.length ? brand_awareness[i] : 0,
                        brand_consideration && brand_consideration.length ? brand_consideration[i] : 0,
                        message_association && message_association.length ? message_association[i] : 0,
                        ad_recall && ad_recall.length ? ad_recall[i] : 0,
                        purchase_intent && purchase_intent.length ? purchase_intent[i] : 0,
                    ],
                    borderWidth: 1,
                    backgroundColor: backgroundColor[i],
                    borderColor: borderColor[i],
                })
            })
        bucket_name &&
            bucket_name.length &&
            bucket_name.map((bn) => {
                labels.push(bn)
            })
        labels = labels.filter(function (elem, pos) {
            return labels.indexOf(elem) == pos
        })
        finalData.push({ labels: labels, datasets: datasets })
        return finalData
    }

    const capitalizeFirstLetter = (string) => {
        // let str = string.replace(/_/g, " ");
        if (string == "participantDemography") string = "participantDemographics"
        let str = string.replace(/[A-Z]/g, " $&").trim()
        return str.charAt(0).toUpperCase() + str.slice(1)
    }

    useEffect(() => {
        que1in5Refresh()
    }, [section])

    const filterSectionData = (data) => {
        const que1in5DataArr = [...data]
        let queArr = []
        if (section !== "") {
            que1in5DataArr?.map((ad) => {
                if (ad.type === section.toLowerCase()) {
                    queArr.push(ad)
                }
            })
            setque1in5data(queArr.length ? queArr : [])
        } else {
            setque1in5data(que1in5DataArr)
        }
    }

    const getColorRandom = (str, index) => {
        let stringUniqueHash = [...str].reduce((acc, char) => {
            return char.charCodeAt(0) + ((acc << 5) - index)
        }, 0)
        let newColor = `hsl(${stringUniqueHash % 360}, 95%, 35%)`
        return newColor
    }

    const getColor = (str, index, clr) => {
        let stringUniqueHash = [...str].reduce((acc, char) => {
            return char.charCodeAt(0) + ((acc << 5) - index)
        }, 0)
        let newColor =
            `hsl(${stringUniqueHash % 360}, 95%, 35%)` === clr
                ? `hsl(${(stringUniqueHash * 5) % 360}, 95%, 35%)`
                : `hsl(${stringUniqueHash % 360}, 95%, 35%)`
        return newColor
    }

    useEffect(() => {
        getReportsData()
    }, [])

    const getReportsData = () => {
        setSpinner(true)
        const access_token = window.localStorage.getItem(SECRET_KEY)
        const id = window.localStorage.getItem("ID")
        axios
            .get(`${API}/mediaPlanner/report/${id}`, {
                headers: { Authorization: `Bearer ${access_token}` },
            })
            .then((res) => {
                if (res && res.data && res.data.companyId) {
                    setReportsList(res.data.companyId.reports ? res.data.companyId.reports : [])
                    setOrgReportsList(res.data.companyId.reports ? res.data.companyId.reports : [])
                    setSpinner(false)
                }
            })
            .catch((error) => {
                setSpinner(false)
            })
    }

    useEffect(() => {
        if (Number(selectedReports) === 0) {
            let allKeys = {}
            Object.keys(reportData).forEach((key) => {
                allKeys[key] = false
            })
            setOpenReport(allKeys)
            setSelectedReports(0)
            setOpenReportModal(false)
        } else {
            getReportsByData(selectedReports)
        }
    }, [selectedReports])

    const setGrid = (index) => {
        switch (index) {
            case 1:
                return { class: "main-title bg-light-purple reportby-date", grid: 6 }
            case 2:
                return { class: "main-title bg-dark-purple reportby-date", grid: 6 }
            case 3:
                return {
                    class: "main-title reportby-date text-center bg-dark-purple",
                    grid: 4,
                }
            case 4:
                return {
                    class: "main-title bg-purple reportby-date text-center ",
                    grid: 4,
                }
            case 5:
                return {
                    class: "main-title reportby-date text-center bg-light-purple",
                    grid: 4,
                }
            case 6:
                return { class: "main-title bg-light-purple reportby-date", grid: 6 }
            case 7:
                return { class: "main-title bg-dark-purple reportby-date", grid: 6 }
            case 8:
                return {
                    class: "main-title reportby-date text-center bg-dark-purple",
                    grid: 4,
                }
            case 9:
                return {
                    class: "main-title bg-purple reportby-date text-center",
                    grid: 4,
                }
            case 10:
                return {
                    class: "main-title reportby-date text-center bg-light-purple",
                    grid: 4,
                }
            case 11:
                return { class: "main-title bg-light-purple reportby-date", grid: 6 }
            case 12:
                return { class: "main-title bg-dark-purple reportby-date", grid: 6 }
            case 13:
                return {
                    class: "main-title reportby-date text-center bg-dark-purple",
                    grid: 4,
                }
            case 14:
                return {
                    class: "main-title bg-purple reportby-date text-center",
                    grid: 4,
                }
            case 15:
                return {
                    class: "main-title reportby-date text-center bg-light-purple",
                    grid: 4,
                }
            case 16:
                return { class: "main-title bg-light-purple reportby-date", grid: 6 }
            case 17:
                return { class: "main-title bg-dark-purple reportby-date", grid: 6 }
            case 18:
                return {
                    class: "main-title reportby-date text-center bg-dark-purple",
                    grid: 4,
                }
            case 19:
                return {
                    class: "main-title bg-purple reportby-date text-center",
                    grid: 4,
                }
            case 20:
                return {
                    class: "main-title reportby-date text-center bg-light-purple",
                    grid: 4,
                }
            case 21:
                return { class: "main-title bg-light-purple reportby-date", grid: 6 }
            case 22:
                return { class: "main-title bg-dark-purple reportby-date", grid: 6 }
            case 23:
                return {
                    class: "main-title reportby-date text-center bg-dark-purple",
                    grid: 4,
                }
            case 24:
                return {
                    class: "main-title reportby-date text-center bg-purple",
                    grid: 4,
                }
            case 25:
                return {
                    class: "main-title reportby-date text-center bg-dark-purple",
                    grid: 4,
                }
            case 26:
                return { class: "main-title bg-light-purple reportby-date", grid: 6 }
            case 27:
                return { class: "main-title bg-dark-purple reportby-date", grid: 6 }
            default:
                return { class: "main-title bg-dark-purple reportby-date", grid: 12 }
        }
    }

    const getImgUrl = (name) => {
        switch (name) {
            case "executiveSummary":
                return Summary
            case "methodology":
                return Setting
            case "discussionAndImplications":
                return Message
            case "demographics":
                return People
            case "appendices":
                return Appendices
            case "conclusions":
                return Conclusions
            case "foreword":
                return Forward
            case "introduction":
                return Introduction
            case "background":
                return Background
            case "benchmarkMediaSpendAnalysis":
                return benchmarkMediaSpendAnalysis
            case "consumerPersonas":
                return ConsumerPersonas
            case "diversityDividendAnalysis":
                return DiversityDividendAnalysis
            case "keyFinding":
                return Search
            case "keyInsights":
                return KeyInsights
            case "media":
                return Media
            case "mediaConsumptionByDemographic":
                return MediaConsumptionbyDemographic
            case "recommendations":
                return Recommendations
            case "roiBreakdown":
                return RoiBreakdown
            case "topLineOverview":
                return TopLineOverview
            case "decaMediaInvestmentPlan":
                return DecaMediaInvestmentPlan
            case "aimsAndObjectives":
                return DecaMediaInvestmentPlan
            default:
                return Setting
        }
    }

    const getReportsByData = (reportId) => {
        setSpinner(true)
        const access_token = window.localStorage.getItem(SECRET_KEY)
        axios
            .get(`${API}/mediaPlanner/report/getReportByID/${reportId}`, {
                headers: { Authorization: `Bearer ${access_token}` },
            })
            .then((res) => {
                if (res && res.data) {
                    let keysArr = Object.entries(res.data)
                    let reportNameArr = []
                    keysArr?.map((key) => {
                        if (!gridKeys.includes(key[0]) && key[1].length && !key[1].includes("<p><br></p>")) {
                            reportNameArr.push({
                                label: capitalizeFirstLetter(key[0]),
                                value: key[0],
                            })
                        }
                    })
                    if (
                        Object.keys(res.data).includes("participantDemographyQA") &&
                        res.data["participantDemographyQA"].length &&
                        res.data["participantDemography"].length === 0
                    ) {
                        reportNameArr.push({
                            label: capitalizeFirstLetter("participantDemography"),
                            value: "participantDemography",
                        })
                    }

                    reportNameArr?.map((repts, index) => {
                        repts.htmlObj = setGrid(index + 1)
                        repts.imgUrl = getImgUrl(repts?.value)
                    })
                    let len = res.data["participantDemography"].length
                    if (len) {
                        for (let i = 0; i < len; i++) {
                            let val = series(i)
                            if (val && val > -1 && val < len) {
                                res.data["participantDemography"][val].step = "badge-shape-round"
                            }
                        }
                    }
                    setReportData(res.data)
                    setReportsName(reportNameArr)

                    // get all keys and set values to false
                    let allKeys = {}
                    Object.keys(res.data).forEach((key) => {
                        allKeys[key] = false
                    })
                    setOpenReport(allKeys)
                    setSpinner(false)
                }
            })
            .catch((error) => {
                setSpinner(false)
            })
    }

    let temp = ""
    let step = 0

    const series = (i) => {
        if (i % 2 == 0) {
            if (step % 2 !== 0) {
                step += i + 1
                return null
            }
            temp = i + 1
            step += i + 1
            return temp
        } else {
            if (step % 2 == 0) return null
            temp = i + 1
            step += i + 1
            return temp
        }
    }

    const renderGridBox = () => {
        return (
            <>
                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                    {reportsName
                        ?.filter((i) => i.value !== "participantDemographyQA")
                        ?.map((rept) => (
                            <Grid item xs={rept?.htmlObj?.grid} sm={rept?.htmlObj?.grid} md={rept?.htmlObj?.grid}>
                                <div
                                    className={rept?.htmlObj?.class}
                                    onClick={() => {
                                        let allKeys = {}
                                        Object.keys(reportData).forEach((key) => {
                                            allKeys[key] = rept?.value === key ? true : false
                                            if (rept?.value === key) {
                                                setOpenReportModal(true)
                                            }
                                        })

                                        setOpenReport(allKeys)
                                    }}
                                >
                                    <span className="badge text-left">{rept?.label}</span>
                                    <div className="icon">
                                        <img height={50} width={50} src={rept?.imgUrl} />
                                    </div>
                                </div>
                            </Grid>
                        ))}
                </Grid>
            </>
        )
    }

    const getRenderData = () => {
        let keys = Object.keys(openReport)

        const filtered = keys.filter(function (key) {
            return openReport[key]
        })

        for (let keys of Object.keys(reportData)) {
            if ("participantDemography" === filtered.toString()) {
                return (
                    <>
                        {reportData?.participantDemography?.length ? (
                            <div className="title-text" style={{ width: "100%" }}>
                                {/* <h2>Participant Demographics</h2> */}
                                <div className="modal-boxes">
                                    <Grid
                                        container
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="flex-start"
                                        className="text-center demographics-boxes"
                                    >
                                        {reportData?.participantDemography?.map((rep, id) => {
                                            return (
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    className="mb2"
                                                    style={{ marginBottom: "25px" }}
                                                >
                                                    {/* ${rep.step}  bg-green*/}
                                                    <div
                                                        style={{
                                                            margin: "5%!important",
                                                            backgroundColor: "#f59533",
                                                        }}
                                                        className={`card message-content badge-shape2 mt2`}
                                                    >
                                                        <div className="card-body">
                                                            <div className="icon">
                                                                <img src={rep.imageurl} />
                                                            </div>
                                                            <p>{rep.message}</p>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </div>
                            </div>
                        ) : null}

                        {reportData?.participantDemographyQA?.map((rep) =>
                            getRenderGraph(rep, "Participant Demography", "", reportData.participantDemographyQA.length)
                        )}
                    </>
                )
            } else if (keys === filtered.toString()) {
                return <>{reportData[keys]?.map((rep) => getRenderGraph(rep, keys, "", reportData[keys].length))}</>
            }
        }
    }
    const getSelectedInsightReportDetails = ({ openReport }) => {
        let keys = Object.keys(openReport())

        const filtered = keys.filter(function (key) {
            return openReport()[key]
        })

        for (let keys of Object.keys(reportData)) {
            if ("participantDemography" === filtered.toString()) {
                return (
                    <>
                        {reportData?.participantDemography?.length ? (
                            <div className="title-text" style={{ width: "100%" }}>
                                {/* <h2>Participant Demographics</h2> */}
                                <div className="modal-boxes">
                                    <Grid
                                        container
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="flex-start"
                                        className="text-center demographics-boxes"
                                    >
                                        {reportData?.participantDemography?.map((rep, id) => {
                                            return (
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    className="mb2"
                                                    style={{ marginBottom: "25px" }}
                                                >
                                                    {/* ${rep.step}  bg-green*/}
                                                    <div
                                                        style={{
                                                            margin: "5%!important",
                                                            backgroundColor: "#f59533",
                                                        }}
                                                        className={`card message-content badge-shape2 mt2`}
                                                    >
                                                        <div className="card-body">
                                                            <div className="icon">
                                                                <img src={rep.imageurl} />
                                                            </div>
                                                            <p>{rep.message}</p>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </div>
                            </div>
                        ) : null}

                        <Grid container spacing={3}>
                            {reportData?.participantDemographyQA?.map((rep) =>
                                getRenderGraph(
                                    rep,
                                    "Participant Demography",
                                    "",
                                    reportData.participantDemographyQA.length
                                )
                            )}
                        </Grid>
                    </>
                )
            } else if (keys === filtered.toString()) {
                return (
                    <>
                        <Grid container spacing={3}>
                            {reportData[keys]?.map((rep) => getRenderGraph(rep, keys, "", reportData[keys].length))}
                        </Grid>
                    </>
                )
            }
        }
    }

    const renderGraphModal = () => {
        return (
            <>
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={graphModal}
                    onClose={() => setGraphModal(false)}
                >
                    <Box className="details-popup-graph">
                        <Button className="clos-icon" onClick={() => setGraphModal(false)}>
                            X
                        </Button>
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                            className="text-center"
                        >
                            {renderSingleGraph()}
                        </Grid>
                    </Box>
                </Modal>
            </>
        )
    }

    useEffect(() => {
        if (!graphModal) return
        renderGraphModal()
    }, [graphModal])

    const getRenderGraph = (data, type, mediaName = "", renderGraphlength = 0) => {
        const ansArr = []
        const percentageArr = []
        const bgColorCode = []
        const pyramidData = []
        let queLabel = ""
        let chartType = ""

        if (data && type !== "media") {
            queLabel = type === "media" ? data.questionanswer[0].question : data.question
            chartType = type === "media" ? data.questionanswer[0].chartType : data.chartType
            data.answers &&
                data.answers.length &&
                data.answers.map((a) => {
                    let randomColor = Math.floor(Math.random() * 16777215).toString(16)
                    bgColorCode.push(`#${randomColor}`)
                    percentageArr.push(a.percent)
                    ansArr.push(a.ans)
                    pyramidData.push([a.ans, parseFloat(a.percent)])
                })
        } else {
            queLabel = data.questionanswer[0].question
            chartType = data.questionanswer[0].chartType
            data.questionanswer[0].answers &&
                data.questionanswer[0].answers.length &&
                data.questionanswer[0].answers.map((a) => {
                    let randomColor = Math.floor(Math.random() * 16777215).toString(16)
                    bgColorCode.push(`#${randomColor}`)
                    percentageArr.push(a.percent)
                    ansArr.push(a.ans)
                    pyramidData.push([a.ans, parseFloat(a.percent)])
                })
        }

        const barAnsData = {
            labels: ansArr,
            datasets: [
                {
                    label: queLabel,
                    backgroundColor: bgColorCode,
                    borderColor: bgColorCode,
                    borderWidth: 1,
                    data: percentageArr,
                },
            ],
        }

        if (type === "media") {
            return (
                <>
                    <Grid
                        item
                        // md={6}
                        md={
                            renderGraphlength == 1 && data.questionanswer[0].editorText !== "<p><br></p>"
                                ? 12
                                : 6 || (chartType == "" && queLabel === "")
                                ? 6
                                : ""
                        }
                        // className={
                        //   renderGraphlength == 1 &&
                        //   data.questionanswer[0].editorText !== "<p><br></p>"
                        //     ? "full-width"
                        //     : "" || (chartType == "" && queLabel === "")
                        //     ? "full-width-content"
                        //     : ""
                        // }
                    >
                        <div className="graph-grid-wrapper">
                            {data.questionanswer[0].answers &&
                            data.questionanswer[0].answers.length &&
                            data.questionanswer[0].answers[0].ans !== "" ? (
                                <div
                                    className={`${
                                        chartType !== ""
                                            ? " graph-wrapper"
                                            : chartType === "PYR"
                                            ? "pyramid-wrapper"
                                            : ""
                                    } `}
                                    style={{
                                        margin: data.questionanswer[0].editorText == "<p><br></p>" ? "auto" : "",
                                        width:
                                            data.questionanswer[0].editorText !== "<p><br></p>" && chartType === ""
                                                ? ""
                                                : data.questionanswer[0].editorText == "<p><br></p>" && chartType
                                                ? "55%"
                                                : renderGraphlength == 1
                                                ? "55%"
                                                : "",
                                        overflowX:
                                            data.questionanswer[0].editorText == "<p><br></p>" && chartType === ""
                                                ? "auto"
                                                : "",
                                        maxHeight:
                                            data.questionanswer[0].editorText == "<p><br></p>" && chartType === ""
                                                ? "" //"336px"
                                                : "",
                                        padding: chartType === "PYR" ? "" : "15px",
                                    }}
                                >
                                    {type == "Media" ? <h2>{data.mediaName}</h2> : null}
                                    {chartType && <h4 className="graph-title">{queLabel}</h4>}
                                    {chartType === "PYR" && (
                                        <div
                                            style={{
                                                width:
                                                    renderGraphlength == 1 &&
                                                    data.questionanswer[0].editorText !== "<p><br></p>"
                                                        ? "55%"
                                                        : "",
                                            }}
                                            // onClick={() => {
                                            //   setGraphModal(true);
                                            //   setSingleGraphData(data.questionanswer[0]);
                                            //   setSingleGraphColor(bgColorCode);
                                            // }}
                                        >
                                            <HighchartReact
                                                highcharts={Highcharts}
                                                options={{
                                                    chart: {
                                                        type: "pyramid",
                                                        backgroundColor: null,
                                                        height: "100%",
                                                    },
                                                    title: {
                                                        text: "",
                                                    },
                                                    plotOptions: {
                                                        series: {
                                                            dataLabels: {
                                                                enabled: false,
                                                                format: "<b>{point.name}</b> ({point.y:,.0f})",
                                                                softConnector: true,
                                                            },
                                                        },
                                                    },
                                                    legend: {
                                                        enabled: false,
                                                    },
                                                    series: [
                                                        {
                                                            name: queLabel,
                                                            data: [...pyramidData],
                                                        },
                                                    ],
                                                }}
                                            ></HighchartReact>
                                        </div>
                                    )}
                                    {chartType === "BAR" && (
                                        <div
                                            style={{
                                                width:
                                                    renderGraphlength == 1 &&
                                                    data.questionanswer[0].editorText !== "<p><br></p>"
                                                        ? "55%"
                                                        : "",
                                            }}
                                            // onClick={() => {
                                            //   setGraphModal(true);
                                            //   setSingleGraphData(data.questionanswer[0]);
                                            //   setSingleGraphColor(bgColorCode);
                                            // }}
                                        >
                                            <Bar
                                                width="250px"
                                                height="250px"
                                                data={barAnsData}
                                                options={{
                                                    plugins: {
                                                        legend: {
                                                            display: false,
                                                        },
                                                    },
                                                    scales: {
                                                        y: {
                                                            ticks: {
                                                                display: false,
                                                                color: "white",
                                                                font: {
                                                                    size: 14,
                                                                },
                                                            },
                                                        },
                                                        x: {
                                                            ticks: {
                                                                display: false,
                                                                color: "white",
                                                                font: {
                                                                    size: 14,
                                                                },
                                                            },
                                                        },
                                                    },
                                                }}
                                            />
                                        </div>
                                    )}
                                    {chartType === "PIE" && (
                                        <div
                                            style={{
                                                width:
                                                    renderGraphlength == 1 &&
                                                    data.questionanswer[0].editorText !== "<p><br></p>"
                                                        ? "55%"
                                                        : "",
                                            }}
                                            // onClick={() => {
                                            //   setGraphModal(true);
                                            //   setSingleGraphData(data.questionanswer[0]);
                                            //   setSingleGraphColor(bgColorCode);
                                            // }}
                                        >
                                            <Pie
                                                width="250px"
                                                height="250px"
                                                data={barAnsData}
                                                options={{
                                                    plugins: {
                                                        legend: {
                                                            display: false,
                                                        },
                                                    },
                                                    scales: {
                                                        y: {
                                                            ticks: {
                                                                display: false,
                                                                color: "white",
                                                                font: {
                                                                    size: 14,
                                                                },
                                                            },
                                                        },
                                                        x: {
                                                            ticks: {
                                                                display: false,
                                                                color: "white",
                                                                font: {
                                                                    size: 14,
                                                                },
                                                            },
                                                        },
                                                    },
                                                }}
                                            />
                                        </div>
                                    )}
                                    {chartType == "" && queLabel !== "" && (
                                        <ul style={{ padding: "15px" }}>
                                            <h4>{data.questionanswer[0].question}</h4>
                                            {data.questionanswer[0] &&
                                                data.questionanswer[0].answers &&
                                                data.questionanswer[0].answers.length > 0 &&
                                                data.questionanswer[0].answers.map((item) => (
                                                    <li
                                                        style={{
                                                            border: "0px",
                                                            width: "100%",
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        {" "}
                                                        {item.ans}
                                                    </li>
                                                ))}
                                        </ul>
                                    )}
                                </div>
                            ) : null}

                            {data.questionanswer[0]?.editorText !== "<p><br></p>" ? (
                                <div
                                    style={{
                                        // maxHeight: "336px",
                                        width:
                                            renderGraphlength == 1 &&
                                            data.questionanswer[0].answers &&
                                            data.questionanswer[0].answers.length &&
                                            data.questionanswer[0].answers[0].ans === ""
                                                ? "100%"
                                                : "",
                                    }}
                                    className={`${
                                        (data.questionanswer[0].answers &&
                                            data.questionanswer[0].answers.length &&
                                            data.questionanswer[0].answers[0].ans !== "") ||
                                        (data.questionanswer[0].answers &&
                                            data.questionanswer[0].answers.length &&
                                            data.questionanswer[0].answers[0].ans === "" &&
                                            renderGraphlength == 1)
                                            ? "graph-content graph-content-normal"
                                            : ""
                                    } `}
                                >
                                    {parse(data.questionanswer[0]?.editorText)}
                                </div>
                            ) : null}
                        </div>
                    </Grid>
                </>
            )
        } else {
            return (
                <>
                    <Grid
                        item
                        md={
                            renderGraphlength == 1 && data.editorText !== "<p><br></p>"
                                ? 12
                                : 6 || (chartType == "" && queLabel === "")
                                ? 6
                                : ""
                        }
                        // className={
                        //   renderGraphlength == 1 && data.editorText !== "<p><br></p>"
                        //     ? "full-width"
                        //     : "" || (chartType == "" && queLabel === "")
                        //     ? "full-width-content"
                        //     : ""
                        // }
                    >
                        <div className="graph-grid-wrapper">
                            {data.answers && data.answers.length && data.answers[0].ans !== "" ? (
                                <div
                                    className={`${
                                        chartType !== ""
                                            ? " graph-wrapper"
                                            : chartType === "PYR"
                                            ? "pyramid-wrapper"
                                            : ""
                                    } `}
                                    style={{
                                        margin: data.editorText == "<p><br></p>" ? "auto" : "",
                                        width:
                                            data.editorText !== "<p><br></p>" && chartType === ""
                                                ? ""
                                                : data.editorText == "<p><br></p>" && chartType
                                                ? "55%"
                                                : renderGraphlength == 1
                                                ? "55%"
                                                : "",
                                        overflowX: data.editorText == "<p><br></p>" && chartType === "" ? "auto" : "",
                                        maxHeight:
                                            data.editorText == "<p><br></p>" && chartType === ""
                                                ? "" //"336px"
                                                : "",
                                        padding: chartType === "PYR" ? "" : "15px",
                                    }}
                                >
                                    {type == "Media" ? <h2>{data.mediaName}</h2> : null}
                                    {chartType && <h4 className="graph-title">{queLabel}</h4>}
                                    {chartType === "PYR" && (
                                        <div
                                            style={{
                                                width:
                                                    renderGraphlength == 1 && data.editorText !== "<p><br></p>"
                                                        ? "55%"
                                                        : "",
                                            }}
                                            // onClick={() => {
                                            //   setGraphModal(true);
                                            //   setSingleGraphData(data);
                                            //   setSingleGraphColor(bgColorCode);
                                            // }}
                                        >
                                            <HighchartReact
                                                highcharts={Highcharts}
                                                options={{
                                                    chart: {
                                                        type: "pyramid",
                                                        backgroundColor: null,
                                                        height: "100%",
                                                    },
                                                    title: {
                                                        text: "",
                                                    },
                                                    plotOptions: {
                                                        series: {
                                                            dataLabels: {
                                                                enabled: false,
                                                                format: "<b>{point.name}</b> ({point.y:,.0f})",
                                                                softConnector: true,
                                                            },
                                                        },
                                                    },
                                                    legend: {
                                                        enabled: false,
                                                    },
                                                    series: [
                                                        {
                                                            name: queLabel,
                                                            data: [...pyramidData],
                                                        },
                                                    ],
                                                }}
                                            ></HighchartReact>
                                        </div>
                                    )}
                                    {chartType === "BAR" && (
                                        <div
                                            style={{
                                                width:
                                                    renderGraphlength == 1 && data.editorText !== "<p><br></p>"
                                                        ? "55%"
                                                        : "",
                                            }}
                                            // onClick={() => {
                                            //   setGraphModal(true);
                                            //   setSingleGraphData(data);
                                            //   setSingleGraphColor(bgColorCode);
                                            // }}
                                        >
                                            <Bar
                                                width="250px"
                                                height="250px"
                                                data={barAnsData}
                                                options={{
                                                    plugins: {
                                                        legend: {
                                                            display: false,
                                                        },
                                                    },
                                                    scales: {
                                                        y: {
                                                            ticks: {
                                                                display: false,
                                                                color: "white",
                                                                font: {
                                                                    size: 14,
                                                                },
                                                            },
                                                        },
                                                        x: {
                                                            ticks: {
                                                                display: false,
                                                                color: "white",
                                                                font: {
                                                                    size: 14,
                                                                },
                                                            },
                                                        },
                                                    },
                                                }}
                                            />
                                        </div>
                                    )}
                                    {chartType === "PIE" && (
                                        <div
                                            style={{
                                                width:
                                                    renderGraphlength == 1 && data.editorText !== "<p><br></p>"
                                                        ? "55%"
                                                        : "",
                                            }}
                                            // onClick={() => {
                                            //   setGraphModal(true);
                                            //   setSingleGraphData(data);
                                            //   setSingleGraphColor(bgColorCode);
                                            // }}
                                        >
                                            <Pie
                                                width="250px"
                                                height="250px"
                                                data={barAnsData}
                                                options={{
                                                    plugins: {
                                                        legend: {
                                                            display: false,
                                                        },
                                                    },
                                                    scales: {
                                                        y: {
                                                            ticks: {
                                                                display: false,
                                                                color: "white",
                                                                font: {
                                                                    size: 14,
                                                                },
                                                            },
                                                        },
                                                        x: {
                                                            ticks: {
                                                                display: false,
                                                                color: "white",
                                                                font: {
                                                                    size: 14,
                                                                },
                                                            },
                                                        },
                                                    },
                                                }}
                                            />
                                        </div>
                                    )}
                                    {chartType == "" && queLabel !== "" && (
                                        <ul style={{ padding: "15px" }}>
                                            <h4>{data.question}</h4>
                                            {data &&
                                                data.answers &&
                                                data.answers.length > 0 &&
                                                data.answers.map((item) => (
                                                    <li
                                                        style={{
                                                            border: "0px",
                                                            width: "100%",
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        {" "}
                                                        {item.ans}
                                                    </li>
                                                ))}
                                        </ul>
                                    )}
                                </div>
                            ) : null}

                            {data?.editorText !== "<p><br></p>" ? (
                                <div
                                    style={{
                                        width:
                                            renderGraphlength == 1 &&
                                            data.answers &&
                                            data.answers.length &&
                                            data.answers[0].ans === ""
                                                ? "100%"
                                                : "",
                                    }}
                                    className={`${
                                        (data.answers && data.answers.length && data.answers[0].ans !== "") ||
                                        (data.answers &&
                                            data.answers.length &&
                                            data.answers[0].ans === "" &&
                                            renderGraphlength == 1)
                                            ? "graph-content graph-content-normal"
                                            : ""
                                    } `}
                                >
                                    {console.log("data - editor", data)}
                                    {parse(data?.editorText)}
                                </div>
                            ) : null}
                        </div>
                    </Grid>
                </>
            )
        }
    }

    const renderSingleGraph = () => {
        let data = singleGraphData
        const ansArr = []
        const percentageArr = []
        const bgColorCode = singleGraphColor
        const pyramidData = []
        let queLabel = ""
        let chartType = ""
        const lineData = []

        let myData = []

        if (data) {
            queLabel = data.question
            chartType = data.chartType
            data.answers &&
                data.answers.length &&
                data.answers.map((a, i) => {
                    let randomColor = Math.floor(Math.random() * 16777215).toString(16)
                    percentageArr.push(a.percent)
                    ansArr.push(a.ans)
                    lineData.push(a.ans)
                    pyramidData.push([a.ans, parseFloat(a.percent)])
                    myData.push([a.ans, parseFloat(a.percent)])
                })
        }
        const barAnsData = {
            labels: ansArr,
            datasets: [
                {
                    name: queLabel,
                    color: bgColorCode,
                    borderColor: bgColorCode,
                    borderWidth: 1,
                    data: percentageArr,
                },
            ],
        }

        if (percentageArr && percentageArr.length === 0) return null
        return (
            <>
                {/* bar-graph-answer */}
                <h2 style={{ textAlign: "center", width: "100%" }}>{queLabel}</h2>
                <div
                    className={`custom-popup-new ${chartType === "PYR" ? "" : ""} `}
                    style={{ margin: data.editorText == `<p><br></p>` ? "auto" : "" }}
                >
                    {chartType === "PYR" && (
                        <div>
                            <HighchartReact
                                highcharts={Highcharts}
                                options={{
                                    chart: {
                                        backgroundColor: null,
                                        plotBorderColor: "#0574AC",
                                        type: "pyramid3d",
                                        options3d: {
                                            enabled: true,
                                            alpha: 10,
                                            depth: 50,
                                            viewDistance: 50,
                                        },
                                    },
                                    title: {
                                        text: "",
                                    },
                                    plotOptions: {
                                        series: {
                                            dataLabels: {
                                                enabled: true,
                                                format: "<b>{point.name}</b> ({point.y:,.0f})",
                                                allowOverlap: true,
                                                x: 10,
                                                y: -5,
                                                style: {
                                                    textShadow: false,
                                                    textOutline: false,
                                                    color: "white",
                                                },
                                            },
                                            width: "60%",
                                            height: "80%",
                                            center: ["50%", "45%"],
                                        },
                                    },
                                    series: [
                                        {
                                            name: queLabel,
                                            data: [...pyramidData],
                                        },
                                    ],
                                }}
                            ></HighchartReact>
                        </div>
                    )}
                    {chartType === "BAR" && (
                        <div>
                            <HighchartReact
                                style={{ height: "100%" }}
                                highcharts={Highcharts}
                                options={{
                                    chart: {
                                        type: "column",
                                        backgroundColor: "transparent",
                                        plotBackgroundColor: null,
                                        plotBorderWidth: null,
                                        borderColor: null,
                                        plotShadow: false,
                                        options3d: {
                                            enabled: true,
                                            alpha: 15,
                                            beta: 15,
                                            depth: 50,
                                            viewDistance: 25,
                                        },
                                    },
                                    title: {
                                        text: "",
                                    },
                                    plotOptions: {
                                        series: {
                                            dataLabels: {
                                                enabled: true,
                                                style: {
                                                    textShadow: false,
                                                    textOutline: false,
                                                    color: "white",
                                                },
                                            },
                                            depth: 25,
                                        },
                                    },

                                    xAxis: {
                                        categories: barAnsData.labels,
                                        labels: {
                                            style: {
                                                color: "white",
                                            },
                                        },
                                        gridLineColor: "transparent",
                                    },
                                    yAxis: {
                                        gridLineColor: "transparent",
                                        title: {
                                            text: "",
                                        },
                                        labels: {
                                            style: {
                                                color: "white",
                                            },
                                        },
                                    },
                                    series: [
                                        {
                                            showInLegend: false,
                                            data: barAnsData.datasets[0].data,
                                            colorByPoint: true,
                                        },
                                    ],
                                    responsive: {
                                        rules: [
                                            {
                                                condition: {
                                                    maxWidth: 2000,
                                                },
                                            },
                                        ],
                                    },
                                }}
                            />
                        </div>
                    )}
                    {chartType === "PIE" && (
                        <div className="chart-wrapper-new">
                            <HighchartReact
                                highcharts={Highcharts}
                                options={{
                                    chart: {
                                        plotBackgroundColor: null,
                                        plotBorderWidth: null,
                                        borderColor: null,
                                        plotShadow: false,
                                        type: "pie",
                                        backgroundColor: "transparent",
                                        options3d: {
                                            enabled: true,
                                            alpha: 60,
                                            beta: 0,
                                        },
                                    },
                                    title: {
                                        text: "",
                                    },
                                    tooltip: {
                                        pointFormat: "{series.name}: <b>{point.y:.1f}%</b>",
                                    },
                                    accessibility: {
                                        point: {
                                            valueSuffix: "%",
                                        },
                                    },
                                    plotOptions: {
                                        pie: {
                                            allowPointSelect: true,
                                            cursor: "pointer",
                                            depth: 35,
                                            dataLabels: {
                                                style: {
                                                    textShadow: false,
                                                    textOutline: false,
                                                    color: "white",
                                                },
                                                enabled: true,
                                                format: "{point.name}",
                                            },
                                        },
                                    },
                                    series: [
                                        {
                                            type: "pie",
                                            name: "Answer",
                                            colorByPoint: true,
                                            data: myData,
                                        },
                                    ],
                                }}
                            ></HighchartReact>
                        </div>
                    )}
                    <div className="content-wrapper-new">{parse(data?.editorText)}</div>
                </div>
            </>
        )
    }

    let colorArr = [
        { name: "Media", color: "#c1282e" },
        { name: "Cultural", color: "#eb5b29" },
        { name: "Intersectional", color: "#51b0d0" },
        { name: "Actionable", color: "#ffde59" },
        { name: "Perception", color: "#008037" },
        { name: "Behavioural", color: "#254b99" },
    ]

    let colorQuantArr = [
        { name: "Quantitative", color: "#c1282e" },
        { name: "Qualitative", color: "#008037" },
    ]

    const onScroll = (e, type = "") => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
        if (bottom) {
            if (type == "insight") {
                if (decaTotalPages == allDecaData.length || decaTotalPages == 0) return
                Decarefresh()
            }
            if (type == "quant") {
                if (quantTotalPages == allQue1in5Data.length || quantTotalPages == 0) return
                que1in5Refresh()
            }
        }
    }

    /////// ChatGPT section start ///////
    useEffect(() => {
        if (
            chatDemographic !== "" &&
            chatAge !== "" &&
            chatGender !== "" &&
            chatIndustry !== "" &&
            chatBrand !== "" &&
            chatCountry !== ""
        ) {
            axios
                .get(
                    `${API}/mediaPlanner/chatGPTQuestions?Demographic=${chatDemographic ?? ""}&Industry=${
                        chatIndustry ?? ""
                    }&Age=${chatAge ?? ""}&Brand=${chatBrand ?? ""}&Country=${chatCountry ?? ""}&Gender=${
                        chatGender ?? ""
                    }
        `,
                    {
                        headers: { Authorization: `Bearer ${access_token}` },
                    }
                )
                .then((res) => {
                    setGptQueries(res.data)
                })
                .catch((err) => {
                    console.log("err", err)
                })
        }
    }, [chatDemographic, chatIndustry, chatAge, chatBrand, chatCountry, chatGender])

    const gptQuestionHandler = async (query) => {
        const removedTag = query.replace(/<[^>]*>/g, "")
        setChatLoading(true)
        await axios
            .post(
                `${API}/mediaPlanner/chatGPTQuestions`,
                { question: removedTag },
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then((res) => {
                setGptResponse(res.data.data.message.content)
                setChatLoading(false)
            })
            .catch((err) => {
                setGptResponse(err.response.data.error.message)
                setChatLoading(false)
            })
        setChatLoading(false)
    }

    const ciQuestionHandler = async (query) => {
        const removedTag = query.replace(/<[^>]*>/g, "")
        setCiChatLoading(true)
        await axios
            .post(
                `${API}/mediaPlanner/intelligence/ci_intelligence_query`,
                { query: removedTag },
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then((res) => {
                setCiIntelligenceResponse(res.data)
                setCiChatLoading(false)
            })
            .catch((err) => {
                console.log(err)
                // setCiIntelligenceResponse(e)
                setCiChatLoading(false)
            })
        setCiChatLoading(false)
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
    }

    /////// ChatGPT section end ///////
    return (
        <div>
            {(Decaloading || que1in5loading) && <CircularProgress color="colorSecondary" size={40} />}
            {modalOpen ? (
                <IntelligenceModal callback={(data) => categories(data)} onClose={() => setModalOpen(false)} />
            ) : (
                <div>
                    {loading && <CircularProgress color="colorSecondary" size={40} />}
                    {true && (
                        <>
                            <button
                                className="float-form-btn"
                                onClick={() => setSidebarFilterPanel(!sidebarFilterPanel)}
                            >
                                {sidebarFilterPanel === true ? (
                                    <img src={AngleRight} alt="Right" />
                                ) : (
                                    <img src={AngleLeft} alt="Left" />
                                )}
                                Filters
                            </button>
                            <div
                                className={classes.selectControl}
                                // style={{ display: "flex", flexWrap: "wrap" }}
                                style={
                                    sidebarFilterPanel === true
                                        ? {
                                              position: "fixed",
                                              top: "0",
                                              left: "0",
                                              zIndex: "999999",
                                              background: "white",
                                              display: "flex",
                                              flexDirection: "column",
                                              width: "16.7%",
                                              height: "100%",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              borderRight: "2px solid #4A11A2",
                                              backgroundImage: "linear-gradient(to right, #9211a2, #4911a2)",
                                          }
                                        : {
                                              position: "fixed",
                                              top: "0",
                                              left: "0",
                                              zIndex: "999999",
                                              background: "white",
                                              display: "flex",
                                              flexDirection: "column",
                                              width: "16.7%",
                                              height: "100%",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              visibility: "hidden",
                                          }
                                }
                            >
                                {/* <button className="float-form-btn-exit" onClick={() => setSidebarFilterPanel(false)}>
                Filters
              </button> */}
                                <img className="ci-icon-filterbar" src={culturalIntellWhite} alt="logo" />
                                <h2>
                                    <span style={{ color: "#ffffff" }}>Filters</span>
                                </h2>
                                <div
                                    style={{
                                        background: "#ffffff",
                                        width: "90%",
                                        padding: "16px 16px 16px 0px",
                                        borderRadius: "8px",
                                    }}
                                >
                                    <FormControl
                                        component="fieldset"
                                        variant="outlined"
                                        className={[classes.selectControl, classes.customfield].join(" ")}
                                        style={{
                                            marginTop: "10px",
                                            width: "90%",
                                            margin: "5px 20px",
                                        }}
                                    >
                                        <TextField
                                            id="outlined-select-currency-native"
                                            select
                                            label="Demographic"
                                            value={demographic}
                                            onChange={(e) => {
                                                setDemographic(e.target.value)
                                                setChatDemographic(e.target.options[e.target.selectedIndex].text)
                                                setInsiteName("")
                                                setDecaData([])
                                                setAllDecaData([])
                                                setAllQue1in5Data([])
                                                setque1in5data([])
                                                setDecaTotalPages(0)
                                                setDecaCurrentPage(1)
                                                setQuantTotalPages(0)
                                                setQuantCurrentPage(1)
                                            }}
                                            SelectProps={{
                                                native: true,
                                            }}
                                            variant="outlined"
                                        >
                                            <option key={0} value={0}>
                                                Select...
                                            </option>
                                            {dropdownData?.demographics?.map((option) => (
                                                <option key={option._id} value={option._id}>
                                                    {option.name}
                                                </option>
                                            ))}
                                        </TextField>
                                        {/* <InputLabel id="demo-multiple-name-label">Demographic</InputLabel>
                                        <Select
                                            labelId="demo-multiple-name-label"
                                            id="demo-multiple-name"
                                            multiple
                                            value={demographic}
                                            onChange={(e) => {
                                            setDemographic([e.target.value]);
                                            setChatDemographic(e.target.options[e.target.selectedIndex].text)
                                            setInsiteName("")
                                            setDecaData([])
                                            setAllDecaData([])
                                            setAllQue1in5Data([])
                                            setque1in5data([])
                                            setDecaTotalPages(0)
                                            setDecaCurrentPage(1)
                                            setQuantTotalPages(0)
                                            setQuantCurrentPage(1)
                                            // Add your other state updates here
                                            }}
                                            label="Demographic"
                                        >
                                            <MenuItem key={0} value={0}>
                                            Select...
                                            </MenuItem>
                                            {dropdownData?.demographics?.map((option) => (
                                            <MenuItem key={option._id} value={option._id}>
                                                {option.name}
                                            </MenuItem>
                                            ))}
                                        </Select> */}
                                    </FormControl>
                                    {demographic !== 0 && (
                                        <FormControl
                                            component="fieldset"
                                            variant="outlined"
                                            className={[classes.selectControl, classes.customfield].join(" ")}
                                            style={{
                                                marginTop: "10px",
                                                width: "90%",
                                                margin: "5px 20px",
                                            }}
                                        >
                                            <TextField
                                                id="outlined-select-currency-native"
                                                select
                                                label="Age Group"
                                                value={age}
                                                onChange={(e) => {
                                                    setDecaData([])
                                                    setAllDecaData([])
                                                    setAllQue1in5Data([])
                                                    setque1in5data([])
                                                    setDecaTotalPages(0)
                                                    setDecaCurrentPage(1)
                                                    setQuantTotalPages(0)
                                                    setQuantCurrentPage(1)
                                                    setAge(e.target.value)
                                                    setChatAge(e.target.options[e.target.selectedIndex].text)
                                                }}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                variant="outlined"
                                            >
                                                <option key={0} value={0}>
                                                    Select...
                                                </option>
                                                {dropdownData?.ageGroups?.map((option) => (
                                                    <option key={option._id} value={option._id}>
                                                        {option.name}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </FormControl>
                                    )}

                                    {demographic != 0 && (
                                        <FormControl
                                            component="fieldset"
                                            variant="outlined"
                                            className={[classes.selectControl, classes.customfield].join(" ")}
                                            style={{
                                                marginTop: "10px",
                                                width: "90%",
                                                margin: "5px 20px",
                                            }}
                                        >
                                            <TextField
                                                id="outlined-select-currency-native"
                                                select
                                                label="Country"
                                                value={country}
                                                onChange={(e) => {
                                                    setDecaData([])
                                                    setAllDecaData([])
                                                    setAllQue1in5Data([])
                                                    setque1in5data([])
                                                    setDecaTotalPages(0)
                                                    setDecaCurrentPage(1)
                                                    setQuantTotalPages(0)
                                                    setQuantCurrentPage(1)
                                                    setCountry(e.target.value)
                                                    setChatCountry(e.target.options[e.target.selectedIndex].text)
                                                }}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                variant="outlined"
                                            >
                                                <option key={0} value={0}>
                                                    Select...
                                                </option>
                                                {dropdownData?.countries?.map((option) => (
                                                    <option key={option._id} value={option._id}>
                                                        {option.name}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </FormControl>
                                    )}
                                    {demographic != 0 && (
                                        <FormControl
                                            component="fieldset"
                                            variant="outlined"
                                            className={[classes.selectControl, classes.customfield].join(" ")}
                                            style={{
                                                marginTop: "10px",
                                                width: "90%",
                                                margin: "5px 20px",
                                            }}
                                        >
                                            <TextField
                                                id="outlined-select-currency-native"
                                                select
                                                label="Industry"
                                                value={industry}
                                                onChange={(e) => {
                                                    setDecaData([])
                                                    setAllDecaData([])
                                                    setAllQue1in5Data([])
                                                    setque1in5data([])
                                                    setDecaTotalPages(0)
                                                    setDecaCurrentPage(1)
                                                    setQuantTotalPages(0)
                                                    setQuantCurrentPage(1)
                                                    setIndustry(e.target.value)
                                                    setChatIndustry(e.target.options[e.target.selectedIndex].text)
                                                }}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                variant="outlined"
                                            >
                                                <option key={0} value={0}>
                                                    Select...
                                                </option>
                                                {dropdownData?.industries?.map((option) => (
                                                    <option key={option._id} value={option._id}>
                                                        {option.name}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </FormControl>
                                    )}
                                    {industry !== 0 &&
                                        (!selectedSection.includes("quant") || selectedSection.length > 1) && (
                                            <FormControl
                                                component="fieldset"
                                                variant="outlined"
                                                className={[classes.selectControl, classes.customfield].join(" ")}
                                                style={{
                                                    marginTop: "10px",
                                                    width: "90%",
                                                    margin: "5px 20px",
                                                }}
                                            >
                                                <TextField
                                                    id="outlined-select-currency-native"
                                                    select
                                                    label="Brand"
                                                    value={brand}
                                                    onChange={(e) => {
                                                        setDecaData([])
                                                        setAllDecaData([])
                                                        setAllQue1in5Data([])
                                                        setque1in5data([])
                                                        setDecaTotalPages(0)
                                                        setDecaCurrentPage(1)
                                                        setQuantTotalPages(0)
                                                        setQuantCurrentPage(1)
                                                        setBrand(e.target.value)
                                                        setChatBrand(e.target.options[e.target.selectedIndex].text)
                                                    }}
                                                    SelectProps={{
                                                        native: true,
                                                    }}
                                                    variant="outlined"
                                                >
                                                    <option key={0} value={0}>
                                                        Select...
                                                    </option>
                                                    {BrandList.filter(Boolean)?.map((option) => (
                                                        <option key={option._id} value={option._id}>
                                                            {option.name}
                                                        </option>
                                                    ))}
                                                </TextField>
                                            </FormControl>
                                        )}

                                    {demographic != 0 &&
                                        (!selectedSection.includes("quant") || selectedSection.length > 1) && (
                                            <FormControl
                                                component="fieldset"
                                                variant="outlined"
                                                className={[classes.selectControl, classes.customfield].join(" ")}
                                                style={{
                                                    marginTop: "10px",
                                                    width: "90%",
                                                    margin: "5px 20px",
                                                }}
                                            >
                                                <TextField
                                                    id="outlined-select-currency-native"
                                                    select
                                                    label="Gender"
                                                    value={dropGender}
                                                    onChange={(e) => {
                                                        setDecaData([])
                                                        setAllDecaData([])
                                                        setAllQue1in5Data([])
                                                        setque1in5data([])
                                                        setDecaTotalPages(0)
                                                        setDecaCurrentPage(1)
                                                        setQuantTotalPages(0)
                                                        setQuantCurrentPage(1)
                                                        setDropGender(e.target.value)
                                                        setChatGender(e.target.options[e.target.selectedIndex].text)
                                                    }}
                                                    SelectProps={{
                                                        native: true,
                                                    }}
                                                    variant="outlined"
                                                >
                                                    <option key={0} value={0}>
                                                        Select...
                                                    </option>
                                                    {dropdownData?.genders?.map((option) => (
                                                        <option key={option._id} value={option._id}>
                                                            {option.name}
                                                        </option>
                                                    ))}
                                                </TextField>
                                            </FormControl>
                                        )}
                                    <button
                                        className={classes.addActionBtn}
                                        // style={{ marginLeft: "20px", marginBottom: "10px" }}
                                        style={{ marginTop: "10px", width: "90%", margin: "5px 20px" }}
                                        onClick={() => Reset()}
                                    >
                                        Reset Filter
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                    <Grid style={{ marginTop: viewWidgets && "10px", marginBottom: "10px" }} container spacing={1}>
                        {((selectedSection.includes("global_map") && !viewWidgets) || viewWidgets === "global_map") && (
                            <>
                                <Grid item xs={12} sm={12} md={viewWidgets === "global_map" ? 12 : 6}>
                                    <div
                                        className={classes.boxShadow}
                                        style={{ marginTop: "0px", position: "relative" }}
                                    >
                                        <div style={{ position: "relative" }}>
                                            {!viewWidgets ? (
                                                <div
                                                    style={{
                                                        color: "white",
                                                        cursor: "pointer",
                                                    }}
                                                    title="Maximize"
                                                    className="max-min-box"
                                                >
                                                    <div
                                                        onClick={() => {
                                                            setViewWidgets("global_map")
                                                            setGlobalMax(true)
                                                        }}
                                                    >
                                                        <img
                                                            src={ExpandIcon}
                                                            alt="maximize"
                                                            width={18}
                                                            style={{ color: "#ffffff" }}
                                                        />
                                                    </div>
                                                </div>
                                            ) : (
                                                <div
                                                    style={{
                                                        color: "white",
                                                        cursor: "pointer",
                                                    }}
                                                    title="Manimize"
                                                    className="max-min-box"
                                                >
                                                    <MinimizeOutlined
                                                        onClick={() => {
                                                            setViewWidgets("")
                                                            setGlobalMax(false)
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            <Globalmap viewWidgets={viewWidgets} globalMax={globalMax} />
                                        </div>
                                    </div>
                                </Grid>
                            </>
                        )}
                        {((selectedSection.includes("brand_sentiment") && !viewWidgets) ||
                            viewWidgets === "brand_sentiment") && (
                            <Grid
                                item
                                xs={
                                    selectedSection.includes("cultural_insights") &&
                                    selectedSection.includes("brand_sentiment") &&
                                    selectedSection.length !== 2 &&
                                    !viewWidgets
                                        ? 6
                                        : 12
                                }
                            >
                                <Box className={!viewWidgets ? classes.cardBoxIntelligence : ""}>
                                    <Card
                                        style={{
                                            backgroundImage: "linear-gradient(#4911a2,#9211a2)",
                                            color: "white",
                                            height: viewWidgets === "brand_sentiment" ? "" : "410px",
                                            overflow: "auto",
                                            minHeight: "410px",
                                        }}
                                    >
                                        <CardContent>
                                            {!viewWidgets ? (
                                                <div
                                                    style={{
                                                        float: "right",
                                                        color: "white",
                                                        marginBottom: "10px",
                                                        cursor: "pointer",
                                                    }}
                                                    title="Maximize"
                                                >
                                                    <CropSquareOutlined
                                                        onClick={() => setViewWidgets("brand_sentiment")}
                                                    />
                                                </div>
                                            ) : (
                                                <div
                                                    style={{
                                                        float: "right",
                                                        color: "white",
                                                        marginBottom: "10px",
                                                        cursor: "pointer",
                                                    }}
                                                    title="Manimize"
                                                >
                                                    <MinimizeOutlined onClick={() => setViewWidgets("")} />
                                                </div>
                                            )}
                                            <div
                                                style={{
                                                    marginTop: "20px",
                                                }}
                                            >
                                                {decodedSubscription && decodedSubscription.subscription === "free" ? (
                                                    <Subscription />
                                                ) : (
                                                    <>
                                                        <h4
                                                            style={{
                                                                marginTop: 0,
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            Brand Sentiment
                                                        </h4>
                                                        <div style={{ marginTop: "10px" }}>
                                                            <Grid
                                                                container
                                                                spacing={1}
                                                                direction="row"
                                                                justify="flex-start"
                                                                alignItems="flex-start"
                                                            >
                                                                {getBarGraphData()}
                                                            </Grid>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Box>
                            </Grid>
                        )}
                        {((selectedSection.includes("cultural_insights") && !viewWidgets) ||
                            viewWidgets === "cultural_insights") && (
                            <Grid
                                item
                                xs={
                                    selectedSection.includes("cultural_insights") &&
                                    selectedSection.includes("brand_sentiment") &&
                                    selectedSection.length !== 2 &&
                                    !viewWidgets
                                        ? 6
                                        : 12
                                }
                            >
                                <Box className={!viewWidgets ? classes.cardBoxIntelligence : ""}>
                                    <Card
                                        style={{
                                            backgroundImage: "linear-gradient(#4911a2,#9211a2)",
                                            color: "white",
                                            height: viewWidgets === "cultural_insights" ? "" : "410px",
                                            overflow: "auto",
                                            minHeight: "410px",
                                        }}
                                        onScroll={(e) => onScroll(e, "insight")}
                                    >
                                        <CardContent style={{ position: "relative", textAlign: "right" }}>
                                            <div className="search-total-bar">
                                                <h4
                                                    style={{
                                                        marginTop: 0,
                                                        display: "inline",
                                                        textAlign:
                                                            viewWidgets === "cultural_insights" ? "center" : "left",
                                                    }}
                                                >
                                                    Cultural Insights
                                                </h4>
                                                <div className="search-select">
                                                    <input
                                                        placeholder="Search..."
                                                        style={{
                                                            outline: "none",
                                                            backgroundColor: "white",
                                                            border: "solid 2px #E5E5E5",
                                                            color: "black",
                                                            borderRadius: "23px",
                                                            padding: "7px",
                                                            width: "25%",
                                                        }}
                                                        value={search}
                                                        onChange={(event) => {
                                                            setSearch(event.target.value)
                                                        }}
                                                    ></input>
                                                    <button
                                                        className={classes.addActionBtn}
                                                        onClick={() => {
                                                            setDecaTotalPages(0)
                                                            setDecaCurrentPage(1)
                                                            setAllDecaData([])
                                                            setDecaData([])
                                                            setInsiteName("")
                                                            forceUpdate(count + 1)
                                                        }}
                                                    >
                                                        Search
                                                    </button>
                                                    {!viewWidgets ? (
                                                        <div
                                                            className="select-dot"
                                                            style={{
                                                                float: "right",
                                                                color: "white",
                                                                marginBottom: "0px",
                                                                cursor: "pointer",
                                                            }}
                                                            title="Maximize"
                                                        >
                                                            <CropSquareOutlined
                                                                onClick={() => setViewWidgets("cultural_insights")}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div
                                                            style={{
                                                                color: "white",
                                                                marginBottom: "10px",
                                                                cursor: "pointer",
                                                                display: "inline",
                                                            }}
                                                            title="Manimize"
                                                        >
                                                            <MinimizeOutlined onClick={() => setViewWidgets("")} />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    marginTop: "20px",
                                                }}
                                            >
                                                {decodedSubscription && decodedSubscription.subscription === "free" ? (
                                                    <Subscription />
                                                ) : (
                                                    <>
                                                        <div style={{ marginTop: "44px" }}>
                                                            <Grid
                                                                container
                                                                spacing={1}
                                                                direction="row"
                                                                className="color-dot-wrapper"
                                                            >
                                                                <div className="radio-dots">
                                                                    {insightsData && insightsData.length
                                                                        ? insightsData.map((option) => (
                                                                              <ul
                                                                                  key={option.name}
                                                                                  value={option.name}
                                                                                  className="color-dot"
                                                                              >
                                                                                  <li
                                                                                      onClick={() => {
                                                                                          setInsiteName(option.name)
                                                                                      }}
                                                                                  >
                                                                                      <span
                                                                                          className="media-dot"
                                                                                          style={{
                                                                                              background: colorArr.filter(
                                                                                                  (i) =>
                                                                                                      i.name ===
                                                                                                      option.name
                                                                                              )[0].color,
                                                                                          }}
                                                                                      ></span>
                                                                                      <span className="media-name">
                                                                                          {option.name}
                                                                                      </span>
                                                                                  </li>
                                                                              </ul>
                                                                          ))
                                                                        : null}
                                                                </div>
                                                                {stackDecaData.length > 0 ? (
                                                                    stackDecaData.map((ele) => (
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                selectedSection.includes(
                                                                                    "cultural_insights"
                                                                                ) &&
                                                                                selectedSection.includes(
                                                                                    "brand_sentiment"
                                                                                ) &&
                                                                                !viewWidgets
                                                                                    ? 6
                                                                                    : 4
                                                                            }
                                                                        >
                                                                            <Card className={classes.cardStyle}>
                                                                                <CardHeader
                                                                                    className={classes.cardHeader}
                                                                                    title={ele[0].question}
                                                                                />
                                                                                <hr />
                                                                                {ele.map((item) => (
                                                                                    <CardContent
                                                                                        className={classes.cardContent}
                                                                                        style={{
                                                                                            maxWidth: "100%",
                                                                                            background: colorArr.filter(
                                                                                                (i) =>
                                                                                                    i.name ==
                                                                                                    item.insights
                                                                                            )[0].color,
                                                                                            color: "#000",
                                                                                        }}
                                                                                    >
                                                                                        <b>Answer : </b>{" "}
                                                                                        <i>{item.answer}</i>
                                                                                        <br />
                                                                                        <b>Age group : </b>{" "}
                                                                                        <i>{item.age}</i>
                                                                                    </CardContent>
                                                                                ))}
                                                                            </Card>
                                                                        </Grid>
                                                                    ))
                                                                ) : (
                                                                    <p
                                                                        style={{
                                                                            width: "100%",
                                                                            textAlign: "center",
                                                                        }}
                                                                    >
                                                                        No data found!
                                                                    </p>
                                                                )}
                                                            </Grid>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Box>
                            </Grid>
                        )}
                        {((selectedSection.includes("deca_reports") && !viewWidgets) ||
                            viewWidgets === "deca_reports") && (
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={viewWidgets === "deca_reports" ? 12 : 6}
                                className={`report-box ${viewWidgets === "deca_reports" ? "full-block" : ""}`}
                            >
                                {/* <Box className={!viewWidgets ? classes.cardBoxIntelligence : ""}> */}
                                <Box className={classes.boxShadow} style={{ marginTop: "0px" }}>
                                    <Card
                                        style={{
                                            backgroundImage: "linear-gradient(#4911a2,#9211a2)",
                                            color: "white",
                                            height: !viewWidgets ? "100%" : "100%",
                                            borderTopLeftRadius: "0px",
                                            borderTopRightRadius: "0px",
                                        }}
                                    >
                                        <CardContent style={{ padding: "0", position: "relative" }}>
                                            {!viewWidgets ? (
                                                <div
                                                    style={{
                                                        color: "white",
                                                        cursor: "pointer",
                                                    }}
                                                    title="Maximize"
                                                    className="max-min-box"
                                                >
                                                    <div onClick={() => setViewWidgets("deca_reports")}>
                                                        <img
                                                            src={ExpandIcon}
                                                            alt="maximize"
                                                            width={18}
                                                            style={{ color: "#ffffff" }}
                                                        />
                                                    </div>
                                                </div>
                                            ) : (
                                                <div
                                                    style={{
                                                        color: "white",
                                                        cursor: "pointer",
                                                    }}
                                                    title="Manimize"
                                                    className="max-min-box"
                                                >
                                                    <MinimizeOutlined onClick={() => setViewWidgets("")} />
                                                </div>
                                            )}
                                            <div className="report-height-100">
                                                {decodedSubscription && decodedSubscription.subscription === "free" ? (
                                                    <Subscription />
                                                ) : (
                                                    <>
                                                        <div>
                                                            <h1 className="box-title">Insights</h1>
                                                            {/* <FormControl
                                fullWidth
                                component="fieldset"
                                variant="outlined"
                                className={[
                                  classes.selectControl,
                                  classes.customfield,
                                ].join(" ")}
                                style={{
                                  marginTop: "5px",
                                  width: "30%",
                                  float: "right",
                                  opacity: "0",
                                }}
                              ></FormControl> */}
                                                        </div>
                                                        <Grid spacing={2} className="deciaReport" direction="column">
                                                            {orgReportsList && orgReportsList.length
                                                                ? reportFilter(orgReportsList)?.map((option) => (
                                                                      <Grid
                                                                          className="report report-list-item"
                                                                          onClick={() => {
                                                                              setSelectedReports(option.reports._id)
                                                                              setTimeout(() => {
                                                                                  setReportModal(true)
                                                                              }, 500)
                                                                          }}
                                                                      >
                                                                          <img
                                                                              src={DeciaReportLogo}
                                                                              alt="Decia Report Logo"
                                                                          />
                                                                          <p>{option?.reports?.report_name}</p>
                                                                      </Grid>
                                                                  ))
                                                                : "No report to display!"}
                                                        </Grid>
                                                    </>
                                                )}
                                            </div>
                                            <Modal
                                                aria-labelledby="simple-modal-title"
                                                aria-describedby="simple-modal-description"
                                                open={reportModal}
                                                style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    zIndex: "9999999999",
                                                }}
                                            >
                                                <Box
                                                    style={{
                                                        width: "86%",
                                                        maxWidth: "1246px",
                                                        background: "rgb(156, 6, 149)",
                                                        padding: "1rem",
                                                        borderRadius: "3px",
                                                    }}
                                                >
                                                    <Button
                                                        style={{
                                                            float: "right",
                                                            color: "white",
                                                            fontWeight: 900,
                                                            fontSize: "20px",
                                                        }}
                                                        onClick={() => {
                                                            setReportModal(false)
                                                            setSelectedReports(0)
                                                        }}
                                                    >
                                                        X
                                                    </Button>
                                                    <div className="decia-report-box" style={{ marginTop: "10px" }}>
                                                        {Number(selectedReports) !== 0 && (
                                                            <>
                                                                {/* <Grid>
                                  <Box>
                                    <Grid
                                      container
                                      direction="row"
                                      justify="flex-start"
                                      alignItems="flex-start"
                                    >
                                      <div className="main-title bg-purple reportby-date w-100">
                                        <span className="badge text-left">
                                          {reportData
                                            ? reportData.report_name
                                            : ""}
                                        </span>
                                        <span className="badge text-right">
                                          {reportData && reportData.report_date
                                            ? getDMYDate(reportData.report_date)
                                            : ""}

                                          <br />
                                          {reportData
                                            ? reportData.report_by
                                            : ""}
                                        </span>
                                      </div>
                                    </Grid>
                                  </Box>
                                </Grid> */}
                                                                <Grid
                                                                    direction="col"
                                                                    justify="flex-start"
                                                                    alignItems="flex-start"
                                                                    // style={{height: "500px", overflowY: "scroll"}}
                                                                >
                                                                    {/* <Box>{renderGridBox()}</Box> */}
                                                                    <Box>
                                                                        <RenderGridBox2
                                                                            reportsName={reportsName}
                                                                            getSelectedInsightReportDetails={
                                                                                getSelectedInsightReportDetails
                                                                            }
                                                                            reportData={reportData}
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                            </>
                                                        )}
                                                    </div>
                                                </Box>
                                            </Modal>
                                            <Modal
                                                aria-labelledby="simple-modal-title"
                                                aria-describedby="simple-modal-description"
                                                open={openReportModal}
                                                onClose={() => {
                                                    let allKeys = {}
                                                    Object.keys(reportData).forEach((key) => {
                                                        allKeys[key] = false
                                                    })

                                                    setOpenReport(allKeys)
                                                    setOpenReportModal(false)
                                                }}
                                            >
                                                <Box
                                                    className="details-popup-graph"
                                                    style={{
                                                        height: "70% !important",
                                                        width: "70% !important",
                                                        backgroundColor: openReport.participantDemography
                                                            ? "#5b288d"
                                                            : "",
                                                    }}
                                                >
                                                    <Button
                                                        style={{
                                                            float: "right",
                                                            color: "white",
                                                            fontWeight: 900,
                                                            fontSize: "20px",
                                                        }}
                                                        onClick={() => {
                                                            let allKeys = {}
                                                            Object.keys(reportData).forEach((key) => {
                                                                allKeys[key] = false
                                                            })

                                                            setOpenReport(allKeys)
                                                            setOpenReportModal(false)
                                                            setGraphModal(false)
                                                        }}
                                                    >
                                                        X
                                                    </Button>
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justify="flex-start"
                                                        alignItems="flex-start"
                                                        className="text-center"
                                                    >
                                                        <ul className="details-listing">{getRenderData()}</ul>
                                                    </Grid>
                                                </Box>
                                            </Modal>
                                        </CardContent>
                                    </Card>
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                    <Grid style={{ marginTop: viewWidgets && "0px" }} container spacing={1}>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={viewWidgets === "talkwalker" ? 12 : 6}
                            className={`${viewWidgets === "deca_reports" ? "full-block" : ""}`}
                        >
                            {((selectedSection.includes("talkwalker") && !viewWidgets) ||
                                viewWidgets === "talkwalker") && (
                                <Box
                                    // style={{ marginTop: viewWidgets && "10px" }}
                                    // className={!viewWidgets ? classes.cardBoxIntelligence : ""}
                                    className={classes.boxShadow}
                                    style={{ marginTop: "0px" }}
                                >
                                    <Card
                                        style={{
                                            backgroundImage: "linear-gradient(#4911a2,#9211a2)",
                                            color: "white",
                                            height: viewWidgets === "talkwalker" ? "" : "410px",
                                            minHeight: "455px",
                                            borderTopLeftRadius: "0px",
                                            borderTopRightRadius: "0px",
                                        }}
                                    >
                                        <CardContent style={{ padding: "0", position: "relative", height: "auto" }}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    {!viewWidgets ? (
                                                        <div
                                                            style={{
                                                                float: "right",
                                                                color: "white",
                                                                marginBottom: "10px",
                                                                marginTop: "4px",
                                                                cursor: "pointer",
                                                            }}
                                                            title="Maximize"
                                                            className="max-min-box"
                                                        >
                                                            <div onClick={() => setViewWidgets("talkwalker")}>
                                                                <img
                                                                    src={ExpandIcon}
                                                                    alt="maximize"
                                                                    width={18}
                                                                    style={{ color: "#ffffff" }}
                                                                />
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div
                                                            style={{
                                                                float: "right",
                                                                color: "white",
                                                                marginBottom: "10px",
                                                                margin: "4px",
                                                                cursor: "pointer",
                                                            }}
                                                            title="Manimize"
                                                            className="max-min-box"
                                                        >
                                                            <MinimizeOutlined onClick={() => setViewWidgets("")} />
                                                        </div>
                                                    )}
                                                    <div
                                                        style={{
                                                            marginTop: "20px",
                                                            marginBottom: "0",
                                                        }}
                                                    >
                                                        <>
                                                            {/* <div className="diversity_dividend"> */}
                                                            <div style={{ padding: "0 8px", marginTop: "16px" }}>
                                                                <h1
                                                                    style={{
                                                                        textAlign: "center",
                                                                        textTransform: "uppercase",
                                                                        margin: "0",
                                                                    }}
                                                                >
                                                                    Diversity Dividend | Social Impact
                                                                </h1>
                                                                {/* </div> */}
                                                                <label
                                                                    htmlFor="diversity_campigns_btn"
                                                                    style={{ display: "none" }}
                                                                >
                                                                    Select Campaign
                                                                </label>
                                                                <select
                                                                    id="diversity_campaigns_btn"
                                                                    className="diversity_campigns_btn"
                                                                    onChange={(e) => {
                                                                        setSelectedGreenCampaign(e.target.value)
                                                                    }}
                                                                >
                                                                    {greenCampaigns.length &&
                                                                        greenCampaigns.map((item) => (
                                                                            <option
                                                                                className="greenAd_options"
                                                                                value={item._id}
                                                                            >
                                                                                {item.name}
                                                                            </option>
                                                                        ))}
                                                                </select>
                                                            </div>
                                                            {goodLoopCampaignData.length === 0 ? (
                                                                <LoaderCircle style={{color: "#FFFFFF", position: "relative", top: "100%"}} />
                                                                ) : (
                                                                    <div
                                                                className={
                                                                    viewWidgets === "talkwalker"
                                                                        ? "gl-dash-wrapper-full"
                                                                        : "gl-dash-wrapper"
                                                                }
                                                                style={{
                                                                    marginTop: "1.5rem",
                                                                    marginBottom: "0",
                                                                    padding: "8px 12px",
                                                                    // display: "flex",
                                                                    // justifyContent: "center",
                                                                    // maxWidth: "100%",
                                                                }}
                                                            >
                                                            <div style={{maxWidth: "1072px", margin: "auto"}}>
                                                                <p
                                                                    style={{
                                                                        marginTop: "0.3rem",
                                                                        fontSize: "0.8rem",
                                                                        color: "#ffffff",
                                                                    }}
                                                                >
                                                                    Carbon Emitted by your Digital Advertising.
                                                                </p>
                                                                <div className="diversity_dividend_container">
                                                                    <div className="emission_graph_cont">
                                                                        <div
                                                                            style={
                                                                                viewWidgets === "talkwalker"
                                                                                    ? {
                                                                                          width: "100%",
                                                                                          height: "284px",
                                                                                          marginTop: "8px",
                                                                                      }
                                                                                    : {
                                                                                          width: "100%",
                                                                                          height: "200px",
                                                                                          marginTop: "8px",
                                                                                      }
                                                                            }
                                                                        >
                                                                            <ResponsiveContainer>
                                                                                <LineChart
                                                                                    data={
                                                                                        goodLoopCampaignData["byTime"]
                                                                                    }
                                                                                    margin={{
                                                                                        top: 5,
                                                                                        right: 5,
                                                                                        bottom: 5,
                                                                                        left: 0,
                                                                                    }}
                                                                                >
                                                                                    <Line
                                                                                        type="monotone"
                                                                                        dataKey="co2"
                                                                                        stroke="#8884d8"
                                                                                        unit=" kg"
                                                                                    />
                                                                                    <CartesianGrid
                                                                                        stroke="#ccc"
                                                                                        strokeDasharray="5 5"
                                                                                    />
                                                                                    {/* xaxis date into different format */}
                                                                                    <XAxis
                                                                                        dataKey="key_as_string"
                                                                                        tickFormatter={(unixTime) =>
                                                                                            moment(unixTime).format(
                                                                                                "D MMM"
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                    <YAxis unit=" kg" />
                                                                                    <Tooltip />
                                                                                </LineChart>
                                                                            </ResponsiveContainer>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="gl-data">
                                                                    <div
                                                                        className={
                                                                            viewWidgets === "talkwalker"
                                                                                ? "emission_card"
                                                                                : "add_card_margin emission_card mini_width_full"
                                                                        }
                                                                    >
                                                                        {/* <div> */}
                                                                        <h2>2023 - 4th Quarter</h2>
                                                                        <h1
                                                                            style={{
                                                                                fontSize: "6rem",
                                                                                fontWeight: "bolder",
                                                                            }}
                                                                        >
                                                                            {goodLoopCampaignData[
                                                                                "campaignBreakdown"
                                                                            ]?.co2.toFixed(0) >= 1000
                                                                                ? parseFloat(
                                                                                      goodLoopCampaignData[
                                                                                          "campaignBreakdown"
                                                                                      ]?.co2.toFixed(0) / 1000
                                                                                  ).toFixed(1)
                                                                                : goodLoopCampaignData[
                                                                                      "campaignBreakdown"
                                                                                  ]?.co2.toFixed(0) || 0}
                                                                        </h1>
                                                                        <h1>
                                                                            {goodLoopCampaignData[
                                                                                "campaignBreakdown"
                                                                            ]?.co2.toFixed(0) >= 1000
                                                                                ? "Tonnes"
                                                                                : "KG"}
                                                                        </h1>
                                                                        <h3>
                                                                            CO<sub>2</sub>e emitted
                                                                        </h3>
                                                                        <div className="electronics_emission_card">
                                                                            <div className="electronic_img">
                                                                                <img
                                                                                    src={Plane_icon}
                                                                                    alt="Plane"
                                                                                    width="24px"
                                                                                />
                                                                            </div>
                                                                            <div className="electronic_img">
                                                                                <img
                                                                                    src={Electronics_icon}
                                                                                    alt="Electronics"
                                                                                    width="24px"
                                                                                />
                                                                            </div>
                                                                            <div className="electronic_img">
                                                                                <img
                                                                                    src={Car_icon}
                                                                                    alt="Car"
                                                                                    width="24px"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        {/* </div> */}
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            viewWidgets === "talkwalker"
                                                                                ? "em_breakdown_card"
                                                                                : "em_breakdown_card mini_width_full"
                                                                        }
                                                                    >
                                                                        {/* <div> */}
                                                                        <h2>Adtech Emission Breakdown.</h2>
                                                                        <div
                                                                            className={
                                                                                viewWidgets === "talkwalker"
                                                                                    ? "pie_chart_card_maximized"
                                                                                    : "pie_chart_card_minimized"
                                                                            }
                                                                            style={
                                                                                viewWidgets === "talkwalker"
                                                                                    ? { width: "400px" }
                                                                                    : { width: "400px" }
                                                                            }
                                                                        >
                                                                            <ResponsiveContainer width="100%">
                                                                                <PieChart>
                                                                                    <RePie
                                                                                        data={
                                                                                            goodLoopCampaignData[
                                                                                                "campaignBreakdownChart"
                                                                                            ]
                                                                                        }
                                                                                        cx="50%"
                                                                                        cy="50%"
                                                                                        labelLine={false}
                                                                                        label={
                                                                                            renderCustomizedLabelBlack
                                                                                        }
                                                                                        outerRadius={
                                                                                            viewWidgets === "talkwalker"
                                                                                                ? 80
                                                                                                : 50
                                                                                        }
                                                                                        fill="#8884d8"
                                                                                        dataKey="value"
                                                                                    >
                                                                                        {adtechBreakdown.map(
                                                                                            (entry, index) => (
                                                                                                <Cell
                                                                                                    key={`cell-${index}`}
                                                                                                    fill={
                                                                                                        COLORS[
                                                                                                            index %
                                                                                                                COLORS.length
                                                                                                        ]
                                                                                                    }
                                                                                                />
                                                                                            )
                                                                                        )}
                                                                                    </RePie>
                                                                                    <Legend
                                                                                        layout="vertical"
                                                                                        verticalAlign="middle"
                                                                                        align="right"
                                                                                    />
                                                                                    <Tooltip
                                                                                        content={<CustomTooltip />}
                                                                                    />
                                                                                </PieChart>
                                                                            </ResponsiveContainer>
                                                                        </div>
                                                                        <p style={{ color: "#000000" }}>
                                                                            breakdown of Adtech in creative, publisher,
                                                                            and supply path
                                                                        </p>
                                                                        {/* </div> */}
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            viewWidgets === "talkwalker"
                                                                                ? "em_breakdown_card"
                                                                                : "em_breakdown_card mini_width_full"
                                                                        }
                                                                    >
                                                                        <div>
                                                                            <h2>Campaign Emissions across the year</h2>
                                                                            <div
                                                                                className={
                                                                                    viewWidgets === "talkwalker"
                                                                                        ? "bar_graph_card_maximized"
                                                                                        : "bar_graph_card_minimized"
                                                                                }
                                                                                style={
                                                                                    viewWidgets === "talkwalker"
                                                                                        ? { width: "400px" }
                                                                                        : { width: "400px" }
                                                                                }
                                                                            >
                                                                                <ResponsiveContainer width="100%">
                                                                                    <BarChart
                                                                                        width={500}
                                                                                        height={300}
                                                                                        data={[...bar_data, {
                                                                                            name: "Q4 2023",
                                                                                            emission: parseFloat(
                                                                                                goodLoopCampaignData[
                                                                                                    "campaignBreakdown"
                                                                                                ]?.co2.toFixed(0) / 1000
                                                                                            ).toFixed(1)
                                                                                        }]}
                                                                                        layout="horizontal"
                                                                                        margin={{
                                                                                            left: -20,
                                                                                            top: 20,
                                                                                            right: 10,
                                                                                        }}
                                                                                    >
                                                                                        <CartesianGrid strokeDasharray="2 2" />
                                                                                        <XAxis
                                                                                            dataKey="name"
                                                                                            type="category"
                                                                                        />
                                                                                        <YAxis
                                                                                            dataKey="emission"
                                                                                            unit=" t"
                                                                                            type="number"
                                                                                        />
                                                                                        <Tooltip />
                                                                                        {/* <Legend /> */}
                                                                                        <ReBar
                                                                                            dataKey="emission"
                                                                                            unit=" t"
                                                                                            fill="#8884d8"
                                                                                        />
                                                                                    </BarChart>
                                                                                </ResponsiveContainer>
                                                                            </div>
                                                                            {/* <p style={{ color: "#000000" }}>
                                                                                breakdown of Adtech in creative,
                                                                                publisher,
                                                                                <br /> and supply path
                                                                            </p> */}
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            viewWidgets === "talkwalker"
                                                                                ? "sp_em_breakdown_card"
                                                                                : "sp_em_breakdown_card mini_width_full"
                                                                        }
                                                                    >
                                                                        <div>
                                                                            <h3>Emission Breakdown</h3>
                                                                            <div
                                                                                className={
                                                                                    viewWidgets === "talkwalker"
                                                                                        ? "sp_bar_graph_card_maximized"
                                                                                        : "sp_bar_graph_card_minimized"
                                                                                }
                                                                                style={
                                                                                    viewWidgets === "talkwalker"
                                                                                        ? { width: "420px" }
                                                                                        : { width: "420px" }
                                                                                }
                                                                            >
                                                                                {em_br_btn === 0 ? (
                                                                                    <ResponsiveContainer width="100%">
                                                                                        <BarChart
                                                                                            width={560}
                                                                                            height={240}
                                                                                            data={
                                                                                                goodLoopCampaignData[
                                                                                                    "byOs"
                                                                                                ]
                                                                                            }
                                                                                            layout="horizontal"
                                                                                            // margin={{
                                                                                            //     top: 5,
                                                                                            //     right: 30,
                                                                                            //     left: 20,
                                                                                            //     bottom: 5,
                                                                                            // }}
                                                                                            margin={{
                                                                                                left: 0,
                                                                                                top: 20,
                                                                                                right: 10,
                                                                                            }}
                                                                                        >
                                                                                            <CartesianGrid strokeDasharray="2 2" />
                                                                                            <XAxis
                                                                                                dataKey="key"
                                                                                                type="category"
                                                                                            />
                                                                                            <YAxis
                                                                                                dataKey="co2"
                                                                                                unit=" kg"
                                                                                                type="number"
                                                                                            />
                                                                                            <Tooltip />
                                                                                            {/* <Legend /> */}
                                                                                            <ReBar
                                                                                                dataKey="co2"
                                                                                                unit=" kg"
                                                                                                type="number"
                                                                                                fill="#8884d8"
                                                                                            />
                                                                                        </BarChart>
                                                                                    </ResponsiveContainer>
                                                                                ) : em_br_btn === 3 ? (
                                                                                    <ResponsiveContainer width="100%">
                                                                                        <PieChart>
                                                                                            <RePie
                                                                                                data={
                                                                                                    goodLoopCampaignData[
                                                                                                        "byFormat"
                                                                                                    ]
                                                                                                }
                                                                                                cx="50%"
                                                                                                cy="50%"
                                                                                                labelLine={false}
                                                                                                label={
                                                                                                    renderCustomizedLabelBlack
                                                                                                }
                                                                                                outerRadius={
                                                                                                    viewWidgets ===
                                                                                                    "talkwalker"
                                                                                                        ? 90
                                                                                                        : 70
                                                                                                }
                                                                                                fill="#8884d8"
                                                                                                dataKey="co2"
                                                                                            >
                                                                                                {adtechBreakdown.map(
                                                                                                    (entry, index) => (
                                                                                                        <Cell
                                                                                                            key={`cell-${index}`}
                                                                                                            fill={
                                                                                                                COLORS[
                                                                                                                    index %
                                                                                                                        COLORS.length
                                                                                                                ]
                                                                                                            }
                                                                                                        />
                                                                                                    )
                                                                                                )}
                                                                                            </RePie>
                                                                                            <Legend
                                                                                                layout="vertical"
                                                                                                verticalAlign="middle"
                                                                                                align="right"
                                                                                            />
                                                                                            <Tooltip
                                                                                                content={
                                                                                                    <CustomTooltip />
                                                                                                }
                                                                                            />
                                                                                        </PieChart>
                                                                                    </ResponsiveContainer>
                                                                                ) : (
                                                                                    <SimpleTable
                                                                                        columns={[
                                                                                            "Domain",
                                                                                            "Impressions",
                                                                                            "CO2e",
                                                                                        ]}
                                                                                        data={
                                                                                            goodLoopCampaignData[
                                                                                                "byDomain"
                                                                                            ]
                                                                                        }
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                            {/* <p style={{ color: "#000000" }}>
                                                                                breakdown of Adtech in creative,
                                                                                publisher,
                                                                                <br /> and supply path
                                                                            </p> */}
                                                                            <div className="em_br_btns">
                                                                                <button
                                                                                    className={
                                                                                        em_br_btn === 0
                                                                                            ? "em_br_btns_active"
                                                                                            : ""
                                                                                    }
                                                                                    onClick={() => setEm_br_btn(0)}
                                                                                >
                                                                                    DEVICE TYPE
                                                                                </button>
                                                                                {/* <button className={em_br_btn === 1 ? "em_br_btns_active" : "" } onClick={() => setEm_br_btn(1)}>TAG</button> */}
                                                                                <button
                                                                                    className={
                                                                                        em_br_btn === 2
                                                                                            ? "em_br_btns_active"
                                                                                            : ""
                                                                                    }
                                                                                    onClick={() => setEm_br_btn(2)}
                                                                                >
                                                                                    DOMAIN
                                                                                </button>
                                                                                <button
                                                                                    className={
                                                                                        em_br_btn === 3
                                                                                            ? "em_br_btns_active"
                                                                                            : ""
                                                                                    }
                                                                                    onClick={() => setEm_br_btn(3)}
                                                                                >
                                                                                    FORMAT
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            viewWidgets === "talkwalker"
                                                                                ? "sp_em_breakdown_card"
                                                                                : "sp_em_breakdown_card mini_width_full"
                                                                        }
                                                                        style={{
                                                                            maxWidth: "100%",
                                                                            width: "620px !important",
                                                                        }}
                                                                    >
                                                                        <div>
                                                                            <h3>Emissions Produced</h3>
                                                                            {/* <div
                                                                                className={
                                                                                    viewWidgets === "talkwalker"
                                                                                        ? "sp_bar_graph_card_maximized"
                                                                                        : "sp_bar_graph_card_minimized"
                                                                                }
                                                                                style={
                                                                                    viewWidgets === "talkwalker"
                                                                                        ? { width: "400px" }
                                                                                        : { width: "400px" }
                                                                                }
                                                                            >
                                                                                <ResponsiveContainer width="100%">
                                                                                    <BarChart
                                                                                        width={500}
                                                                                        height={240}
                                                                                        data={bar_data}
                                                                                        layout="horizontal"
                                                                                        // margin={{
                                                                                        //     top: 5,
                                                                                        //     right: 30,
                                                                                        //     left: 20,
                                                                                        //     bottom: 5,
                                                                                        // }}
                                                                                        margin={{
                                                                                            left: -20,
                                                                                            top: 20,
                                                                                            right: 10,
                                                                                        }}
                                                                                    >
                                                                                        <CartesianGrid strokeDasharray="2 2" />
                                                                                        <XAxis
                                                                                            dataKey="name"
                                                                                            type="category"
                                                                                        />
                                                                                        <YAxis
                                                                                            dataKey="emission"
                                                                                            unit=" t"
                                                                                            type="number"
                                                                                        />
                                                                                        <Tooltip />
                                                                                        <ReBar
                                                                                            dataKey="emission"
                                                                                            unit=" t"
                                                                                            fill="#8884d8"
                                                                                        />
                                                                                    </BarChart>
                                                                                </ResponsiveContainer>
                                                                            </div> */}
                                                                            <div className="map_container_em">
                                                                                {/* <EmissionMapComponent /> */}
                                                                                <Chart
                                                                                    chartType="GeoChart"
                                                                                    width="100%"
                                                                                    height="280px"
                                                                                    data={goodLoopCampaignData['countryData']}
                                                                                    // options={mapOptions}
                                                                                    style={{backgroundColor:"linear-gradient(#4911a2,#9211a2)", flex:2}}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="emission_data_card">
                                                                    {/* {viewWidgets === "talkwalker" && (
                                                                        <div className="impact_score_card">
                                                                            <h1 className="impact_score">7/10</h1>
                                                                            <h3>COMMUNITY IMPACT SCORE</h3>
                                                                        </div>
                                                                    )} */}
                                                                    {/* {viewWidgets === "talkwalker" && ( */}
                                                                        <div className="csv_upload_card">
                                                                            <label
                                                                                htmlFor="csv_upload"
                                                                                className="csv_upload_label"
                                                                            >
                                                                                <img
                                                                                    src={CsvUploadIcon}
                                                                                    width="100px"
                                                                                    alt="Csv Upload"
                                                                                />
                                                                            </label>
                                                                            <input
                                                                                type="file"
                                                                                id="csv_upload"
                                                                                name="filename"
                                                                                accept=".csv"
                                                                                hidden
                                                                                onChange={(e) => {
                                                                                    handleCsvUpload(e)
                                                                                }
                                                                                }
                                                                            />
                                                                            {csvfile === null ? (<h3>Upload Domains CSV</h3>) : (
                                                                                <>
                                                                                <h3>{csvfile.name}</h3>
                                                                                <button
                                                                                    className="csv_upload_btn"
                                                                                    // onClick={() => {
                                                                                    //     setDomainCsvData([])
                                                                                    //     setDomainCsvData(
                                                                                    //         csvfile
                                                                                    //     )
                                                                                    //     setDomainCsvFile(
                                                                                    //         csvfile.name
                                                                                    //     )
                                                                                    //     setDomainCsvFileError(
                                                                                    //         ""
                                                                                    //     )
                                                                                    // }}
                                                                                    disabled={csvUploadLoading}
                                                                                    onClick={uploadCSV}
                                                                                >
                                                                                    {csvUploadLoading ? (
                                                                                        "Loading..."
                                                                                    ) : (
                                                                                        "Upload"
                                                                                    )}
                                                                                </button>
                                                                                </>
                                                                            )}
                                                                            <div>
                                                                                <h4>{campaignCsvData?.status === "processing" ? "(CSV Data Processing)" : campaignCsvData?.status === "completed" ? "(CSV Uploaded)" : "(CSV Not Uploaded)"}</h4>
                                                                                {campaignCsvData?.status === "processing" && (<h4>Check Later</h4>)}
                                                                            </div>
                                                                        </div>
                                                                    {/* )} */}
                                                                    {/* {viewWidgets !== "talkwalker" && (
                                                                        <div
                                                                            className={
                                                                                viewWidgets === "talkwalker"
                                                                                    ? "pie_chart_card_maximized"
                                                                                    : "pie_chart_card_minimized"
                                                                            }
                                                                            style={
                                                                                viewWidgets === "talkwalker"
                                                                                    ? { width: "400px" }
                                                                                    : { width: "300px" }
                                                                            }
                                                                        >
                                                                            <ResponsiveContainer width="100%">
                                                                                <PieChart>
                                                                                    <RePie
                                                                                        data={communitiesReachedData}
                                                                                        cx="50%"
                                                                                        cy="50%"
                                                                                        labelLine={false}
                                                                                        label={renderCustomizedLabel}
                                                                                        outerRadius={
                                                                                            viewWidgets === "talkwalker"
                                                                                                ? 80
                                                                                                : 50
                                                                                        }
                                                                                        fill="#8884d8"
                                                                                        dataKey="value"
                                                                                    >
                                                                                        {communitiesReachedData.map(
                                                                                            (entry, index) => (
                                                                                                <Cell
                                                                                                    key={`cell-${index}`}
                                                                                                    fill={
                                                                                                        COLORS[
                                                                                                            index %
                                                                                                                COLORS.length
                                                                                                        ]
                                                                                                    }
                                                                                                />
                                                                                            )
                                                                                        )}
                                                                                    </RePie>
                                                                                    <Legend
                                                                                        layout="vertical"
                                                                                        verticalAlign="middle"
                                                                                        align="right"
                                                                                    />
                                                                                    <Tooltip
                                                                                        content={<CustomTooltip />}
                                                                                    />
                                                                                </PieChart>
                                                                            </ResponsiveContainer>
                                                                            <h4>
                                                                                COMMUNITIES REACHED
                                                                                {viewWidgets !== "talkwalker" && (
                                                                                    <>
                                                                                        <br />
                                                                                        &nbsp;
                                                                                    </>
                                                                                )}
                                                                            </h4>
                                                                        </div>
                                                                    )}
                                                                    <div
                                                                        className={
                                                                            viewWidgets === "talkwalker"
                                                                                ? "pie_chart_card_maximized"
                                                                                : "pie_chart_card_minimized"
                                                                        }
                                                                        style={
                                                                            viewWidgets === "talkwalker"
                                                                                ? { width: "400px" }
                                                                                : { width: "300px" }
                                                                        }
                                                                    >
                                                                        <ResponsiveContainer width="100%">
                                                                            <PieChart>
                                                                                <RePie
                                                                                    data={communityEmploymentData}
                                                                                    cx="50%"
                                                                                    cy="50%"
                                                                                    labelLine={false}
                                                                                    label={renderCustomizedLabel}
                                                                                    outerRadius={
                                                                                        viewWidgets === "talkwalker"
                                                                                            ? 80
                                                                                            : 50
                                                                                    }
                                                                                    fill="#8884d8"
                                                                                    dataKey="value"
                                                                                >
                                                                                    {communityEmploymentData.map(
                                                                                        (entry, index) => (
                                                                                            <Cell
                                                                                                key={`cell-${index}`}
                                                                                                fill={
                                                                                                    COLORS[
                                                                                                        index %
                                                                                                            COLORS.length
                                                                                                    ]
                                                                                                }
                                                                                            />
                                                                                        )
                                                                                    )}
                                                                                </RePie>
                                                                                <Legend
                                                                                    layout="vertical"
                                                                                    verticalAlign="middle"
                                                                                    align="right"
                                                                                />
                                                                            </PieChart>
                                                                        </ResponsiveContainer>
                                                                        <h4>
                                                                            COMMUNITY EMPLOYMENT
                                                                            {viewWidgets !== "talkwalker" && (
                                                                                <>
                                                                                    <br />
                                                                                    &nbsp;
                                                                                </>
                                                                            )}
                                                                        </h4>
                                                                    </div> */}
                                                                    <div className="csv_pie_card">
                                                                        <div
                                                                            className={
                                                                                viewWidgets === "talkwalker"
                                                                                    ? "pie_chart_card_maximized"
                                                                                    : "pie_chart_card_minimized"
                                                                            }
                                                                            style={
                                                                                viewWidgets === "talkwalker"
                                                                                    ? {
                                                                                          width: "240px",
                                                                                          height: "220px",
                                                                                      }
                                                                                    : { width: "240px" }
                                                                            }
                                                                        >
                                                                            <ResponsiveContainer
                                                                                width="100%"
                                                                                height="100%"
                                                                            >
                                                                                <PieChart>
                                                                                    <RePie
                                                                                        data={campaignCsvData?.match_domains?.map((domain) => {
                                                                                            return {
                                                                                                name: domain?.demographic,
                                                                                                value: domain?.percentage
                                                                                            }}) || tier1spendData
                                                                                        }
                                                                                        cx="50%"
                                                                                        cy="50%"
                                                                                        labelLine={false}
                                                                                        label={
                                                                                            renderCustomizedLabelBlack
                                                                                        }
                                                                                        outerRadius={
                                                                                            viewWidgets === "talkwalker"
                                                                                                ? 80
                                                                                                : 50
                                                                                        }
                                                                                        fill="#8884d8"
                                                                                        dataKey="value"
                                                                                    >
                                                                                        {tier1spendData.map(
                                                                                            (entry, index) => (
                                                                                                <Cell
                                                                                                    key={`cell-${index}`}
                                                                                                    fill={
                                                                                                        COLORS[
                                                                                                            index %
                                                                                                                COLORS.length
                                                                                                        ]
                                                                                                    }
                                                                                                />
                                                                                            )
                                                                                        )}
                                                                                    </RePie>
                                                                                    {/* <Legend
                                                                                      layout="vertical"
                                                                                      verticalAlign="middle"
                                                                                      align="right"
                                                                                  /> */}
                                                                                    <Tooltip
                                                                                        content={<CustomTooltip />}
                                                                                    />
                                                                                </PieChart>
                                                                            </ResponsiveContainer>
                                                                        </div>
                                                                        <h4>TIER 1 SPEND: MINORITY O&O</h4>
                                                                    </div>
                                                                    <div className="csv_pie_card">
                                                                        <div
                                                                            className={
                                                                                viewWidgets === "talkwalker"
                                                                                    ? "pie_chart_card_maximized"
                                                                                    : "pie_chart_card_minimized"
                                                                            }
                                                                            style={
                                                                                viewWidgets === "talkwalker"
                                                                                    ? {
                                                                                          width: "240px",
                                                                                          height: "220px",
                                                                                      }
                                                                                    : { width: "240px" }
                                                                            }
                                                                        >
                                                                            <ResponsiveContainer
                                                                                width="100%"
                                                                                height="100%"
                                                                            >
                                                                                <PieChart>
                                                                                    <RePie
                                                                                        data={campaignCsvData?.match_domains_tier2?.map((domain) => {
                                                                                            return {
                                                                                                name: domain?.demographic || domain?.demographic_t2,
                                                                                                value: domain?.percentage || domain?.percentage_t2
                                                                                            }}) || tier2spendData
                                                                                        }
                                                                                        cx="50%"
                                                                                        cy="50%"
                                                                                        labelLine={false}
                                                                                        label={
                                                                                            renderCustomizedLabelBlack
                                                                                        }
                                                                                        outerRadius={
                                                                                            viewWidgets === "talkwalker"
                                                                                                ? 80
                                                                                                : 50
                                                                                        }
                                                                                        fill="#8884d8"
                                                                                        dataKey="value"
                                                                                    >
                                                                                        {tier2spendData.map(
                                                                                            (entry, index) => (
                                                                                                <Cell
                                                                                                    key={`cell-${index}`}
                                                                                                    fill={
                                                                                                        COLORS[
                                                                                                            index %
                                                                                                                COLORS.length
                                                                                                        ]
                                                                                                    }
                                                                                                />
                                                                                            )
                                                                                        )}
                                                                                    </RePie>
                                                                                    <Tooltip
                                                                                        content={<CustomTooltip />}
                                                                                    />
                                                                                </PieChart>
                                                                            </ResponsiveContainer>
                                                                        </div>
                                                                        <h4>TIER 2 SPEND: MINORITY REACHING</h4>
                                                                    </div>
                                                                    <div className="csv_pie_card">
                                                                        <div
                                                                            className={
                                                                                viewWidgets === "talkwalker"
                                                                                    ? "pie_chart_card_maximized"
                                                                                    : "pie_chart_card_minimized"
                                                                            }
                                                                            style={
                                                                                viewWidgets === "talkwalker"
                                                                                    ? {
                                                                                          width: "240px",
                                                                                          height: "220px",
                                                                                      }
                                                                                    : { width: "240px" }
                                                                            }
                                                                        >
                                                                            <ResponsiveContainer
                                                                                width="100%"
                                                                                height="100%"
                                                                            >
                                                                                <PieChart>
                                                                                    <RePie
                                                                                        data={tier3spendData}
                                                                                        cx="50%"
                                                                                        cy="50%"
                                                                                        labelLine={false}
                                                                                        label={
                                                                                            renderCustomizedLabelBlack
                                                                                        }
                                                                                        outerRadius={
                                                                                            viewWidgets === "talkwalker"
                                                                                                ? 80
                                                                                                : 50
                                                                                        }
                                                                                        fill="#8884d8"
                                                                                        dataKey="value"
                                                                                    >
                                                                                        {tier3spendData.map(
                                                                                            (entry, index) => (
                                                                                                <Cell
                                                                                                    key={`cell-${index}`}
                                                                                                    fill={
                                                                                                        COLORS[
                                                                                                            index %
                                                                                                                COLORS.length
                                                                                                        ]
                                                                                                    }
                                                                                                />
                                                                                            )
                                                                                        )}
                                                                                    </RePie>
                                                                                    <Tooltip
                                                                                        content={<CustomTooltip />}
                                                                                    />
                                                                                </PieChart>
                                                                            </ResponsiveContainer>
                                                                        </div>
                                                                        <h4>TIER 3 SPEND: MINORITY JOURNALISTS</h4>
                                                                    </div>
                                                                    {/* <div className="haleon_data_card">
                                                                        <div className="impact_question" style={viewWidgets !== "talkwalker" ? {margin: "auto", maxWidth: "100%"} : {}}>
                                                                            IMPACT QUESTION
                                                                            <br />
                                                                            (dynamic)
                                                                        </div>
                                                                        <div
                                                                            className={
                                                                                viewWidgets === "talkwalker"
                                                                                    ? "sp_haleon_card_maximized"
                                                                                    : "sp_haleon_card_minimized"
                                                                            }
                                                                            style={
                                                                                viewWidgets === "talkwalker"
                                                                                    ? { width: "400px" }
                                                                                    : { width: "256px" }
                                                                            }
                                                                        >
                                                                            <ResponsiveContainer width="100%">
                                                                                <PieChart>
                                                                                    <RePie
                                                                                        data={pie_data}
                                                                                        cx="50%"
                                                                                        cy="50%"
                                                                                        labelLine={false}
                                                                                        label={
                                                                                            renderCustomizedLabelBlack
                                                                                        }
                                                                                        outerRadius={
                                                                                            viewWidgets === "talkwalker"
                                                                                                ? 110
                                                                                                : 90
                                                                                        }
                                                                                        fill="#8884d8"
                                                                                        dataKey="value"
                                                                                    >
                                                                                        {pie_data.map(
                                                                                            (entry, index) => (
                                                                                                <Cell
                                                                                                    key={`cell-${index}`}
                                                                                                    fill={
                                                                                                        COLORS[
                                                                                                            index %
                                                                                                                COLORS.length
                                                                                                        ]
                                                                                                    }
                                                                                                />
                                                                                            )
                                                                                        )}
                                                                                    </RePie>
                                                                                    {viewWidgets === "talkwalker" && (
                                                                                        <Legend
                                                                                            layout="vertical"
                                                                                            verticalAlign="middle"
                                                                                            align="right"
                                                                                        />
                                                                                    )}
                                                                                    <Tooltip
                                                                                        content={<CustomTooltip />}
                                                                                    />
                                                                                </PieChart>
                                                                            </ResponsiveContainer>
                                                                        </div>
                                                                        <div className="haleon_si" style={viewWidgets !== "talkwalker" ? {margin: "auto", maxWidth: "100%"} : {}}>
                                                                            {viewWidgets === "talkwalker" ? (
                                                                                <h2>HALEON SOCIAL IMPACT</h2>
                                                                            ) : (
                                                                                <h3>HALEON SOCIAL IMPACT</h3>
                                                                            )}
                                                                        </div>
                                                                    </div> */}
                                                                    {/* <div className="cpm_breakdown">
                                                                        <h3>CPM BREAKDOWN</h3>
                                                                        <div className="cpm_tags_cont">
                                                                            <div className="cpm_tag_outer">
                                                                                <div className="cpm_tag_inner">
                                                                                    To Media Owner
                                                                                </div>
                                                                                <p>5.5</p>
                                                                            </div>
                                                                            <div className="cpm_tag_outer">
                                                                                <div className="cpm_tag_inner">
                                                                                    To Tech Vendor
                                                                                </div>
                                                                                <p>3.6</p>
                                                                            </div>
                                                                        </div>
                                                                    </div> */}
                                                                </div>
                                                                <div className="emission_data_card">
                                                                    {/* {viewWidgets === "talkwalker" && (
                                                                        <div className="impact_score_card">
                                                                            <h1 className="impact_score">7/10</h1>
                                                                            <h3>COMMUNITY IMPACT SCORE</h3>
                                                                        </div>
                                                                    )} */}
                                                                    {/* {viewWidgets === "talkwalker" && ( */}
                                                                        <div className="csv_upload_card">
                                                                            <label
                                                                                htmlFor="csv_upload_keyword"
                                                                                className="csv_upload_label"
                                                                            >
                                                                                <img
                                                                                    src={CsvUploadIcon}
                                                                                    width="100px"
                                                                                    alt="Csv Upload"
                                                                                />
                                                                            </label>
                                                                            <input
                                                                                type="file"
                                                                                id="csv_upload_keyword"
                                                                                name="filename"
                                                                                accept=".csv"
                                                                                hidden
                                                                                onChange={(e) => {
                                                                                    handleKeywordsUpload(e)
                                                                                }
                                                                                }
                                                                            />
                                                                            {keywordsFile === null ? (<h3>Upload Keywords CSV</h3>) : (
                                                                                <>
                                                                                <h3>{keywordsFile?.name}</h3>
                                                                                <button
                                                                                    className="csv_upload_btn"
                                                                                    // onClick={() => {
                                                                                    //     setDomainCsvData([])
                                                                                    //     setDomainCsvData(
                                                                                    //         csvfile
                                                                                    //     )
                                                                                    //     setDomainCsvFile(
                                                                                    //         csvfile.name
                                                                                    //     )
                                                                                    //     setDomainCsvFileError(
                                                                                    //         ""
                                                                                    //     )
                                                                                    // }}
                                                                                    disabled={csvUploadLoading}
                                                                                    onClick={uploadKeywordsCSV}
                                                                                >
                                                                                    {csvKeywordUploadLoading ? (
                                                                                        "Loading..."
                                                                                    ) : (
                                                                                        "Upload"
                                                                                    )}
                                                                                </button>
                                                                                </>
                                                                            )}
                                                                            {/* <div>
                                                                                <h4>{campaignCsvData?.status === "processing" ? "(CSV Data Processing)" : campaignCsvData?.status === "completed" ? "(CSV Uploaded)" : "(CSV Not Uploaded)"}</h4>
                                                                                {campaignCsvData?.status === "processing" && (<h4>Check Later</h4>)}
                                                                            </div> */}
                                                                        </div>
                                                                    {/* )} */}
                                                                    {/* {viewWidgets !== "talkwalker" && (
                                                                        <div
                                                                            className={
                                                                                viewWidgets === "talkwalker"
                                                                                    ? "pie_chart_card_maximized"
                                                                                    : "pie_chart_card_minimized"
                                                                            }
                                                                            style={
                                                                                viewWidgets === "talkwalker"
                                                                                    ? { width: "400px" }
                                                                                    : { width: "300px" }
                                                                            }
                                                                        >
                                                                            <ResponsiveContainer width="100%">
                                                                                <PieChart>
                                                                                    <RePie
                                                                                        data={communitiesReachedData}
                                                                                        cx="50%"
                                                                                        cy="50%"
                                                                                        labelLine={false}
                                                                                        label={renderCustomizedLabel}
                                                                                        outerRadius={
                                                                                            viewWidgets === "talkwalker"
                                                                                                ? 80
                                                                                                : 50
                                                                                        }
                                                                                        fill="#8884d8"
                                                                                        dataKey="value"
                                                                                    >
                                                                                        {communitiesReachedData.map(
                                                                                            (entry, index) => (
                                                                                                <Cell
                                                                                                    key={`cell-${index}`}
                                                                                                    fill={
                                                                                                        COLORS[
                                                                                                            index %
                                                                                                                COLORS.length
                                                                                                        ]
                                                                                                    }
                                                                                                />
                                                                                            )
                                                                                        )}
                                                                                    </RePie>
                                                                                    <Legend
                                                                                        layout="vertical"
                                                                                        verticalAlign="middle"
                                                                                        align="right"
                                                                                    />
                                                                                    <Tooltip
                                                                                        content={<CustomTooltip />}
                                                                                    />
                                                                                </PieChart>
                                                                            </ResponsiveContainer>
                                                                            <h4>
                                                                                COMMUNITIES REACHED
                                                                                {viewWidgets !== "talkwalker" && (
                                                                                    <>
                                                                                        <br />
                                                                                        &nbsp;
                                                                                    </>
                                                                                )}
                                                                            </h4>
                                                                        </div>
                                                                    )}
                                                                    <div
                                                                        className={
                                                                            viewWidgets === "talkwalker"
                                                                                ? "pie_chart_card_maximized"
                                                                                : "pie_chart_card_minimized"
                                                                        }
                                                                        style={
                                                                            viewWidgets === "talkwalker"
                                                                                ? { width: "400px" }
                                                                                : { width: "300px" }
                                                                        }
                                                                    >
                                                                        <ResponsiveContainer width="100%">
                                                                            <PieChart>
                                                                                <RePie
                                                                                    data={communityEmploymentData}
                                                                                    cx="50%"
                                                                                    cy="50%"
                                                                                    labelLine={false}
                                                                                    label={renderCustomizedLabel}
                                                                                    outerRadius={
                                                                                        viewWidgets === "talkwalker"
                                                                                            ? 80
                                                                                            : 50
                                                                                    }
                                                                                    fill="#8884d8"
                                                                                    dataKey="value"
                                                                                >
                                                                                    {communityEmploymentData.map(
                                                                                        (entry, index) => (
                                                                                            <Cell
                                                                                                key={`cell-${index}`}
                                                                                                fill={
                                                                                                    COLORS[
                                                                                                        index %
                                                                                                            COLORS.length
                                                                                                    ]
                                                                                                }
                                                                                            />
                                                                                        )
                                                                                    )}
                                                                                </RePie>
                                                                                <Legend
                                                                                    layout="vertical"
                                                                                    verticalAlign="middle"
                                                                                    align="right"
                                                                                />
                                                                            </PieChart>
                                                                        </ResponsiveContainer>
                                                                        <h4>
                                                                            COMMUNITY EMPLOYMENT
                                                                            {viewWidgets !== "talkwalker" && (
                                                                                <>
                                                                                    <br />
                                                                                    &nbsp;
                                                                                </>
                                                                            )}
                                                                        </h4>
                                                                    </div> */}
                                                                    <div className="csv_pie_card">
                                                                        <div
                                                                            className={
                                                                                viewWidgets === "talkwalker"
                                                                                    ? "pie_chart_card_maximized"
                                                                                    : "pie_chart_card_minimized"
                                                                            }
                                                                            style={
                                                                                viewWidgets === "talkwalker"
                                                                                    ? {
                                                                                          width: "240px",
                                                                                          height: "220px",
                                                                                      }
                                                                                    : { width: "240px" }
                                                                            }
                                                                        >
                                                                            <ResponsiveContainer
                                                                                width="100%"
                                                                                height="100%"
                                                                            >
                                                                                <PieChart>
                                                                                    <RePie
                                                                                        data={tier1spendData}
                                                                                        cx="50%"
                                                                                        cy="50%"
                                                                                        labelLine={false}
                                                                                        label={
                                                                                            renderCustomizedLabelBlack
                                                                                        }
                                                                                        outerRadius={
                                                                                            viewWidgets === "talkwalker"
                                                                                                ? 80
                                                                                                : 50
                                                                                        }
                                                                                        fill="#8884d8"
                                                                                        dataKey="value"
                                                                                    >
                                                                                        {tier1spendData.map(
                                                                                            (entry, index) => (
                                                                                                <Cell
                                                                                                    key={`cell-${index}`}
                                                                                                    fill={
                                                                                                        COLORS[
                                                                                                            index %
                                                                                                                COLORS.length
                                                                                                        ]
                                                                                                    }
                                                                                                />
                                                                                            )
                                                                                        )}
                                                                                    </RePie>
                                                                                    {/* <Legend
                                                                                      layout="vertical"
                                                                                      verticalAlign="middle"
                                                                                      align="right"
                                                                                  /> */}
                                                                                    <Tooltip
                                                                                        content={<CustomTooltip />}
                                                                                    />
                                                                                </PieChart>
                                                                            </ResponsiveContainer>
                                                                        </div>
                                                                        <h4>Keyword Priority 1</h4>
                                                                    </div>
                                                                    <div className="csv_pie_card">
                                                                        <div
                                                                            className={
                                                                                viewWidgets === "talkwalker"
                                                                                    ? "pie_chart_card_maximized"
                                                                                    : "pie_chart_card_minimized"
                                                                            }
                                                                            style={
                                                                                viewWidgets === "talkwalker"
                                                                                    ? {
                                                                                          width: "240px",
                                                                                          height: "220px",
                                                                                      }
                                                                                    : { width: "240px" }
                                                                            }
                                                                        >
                                                                            <ResponsiveContainer
                                                                                width="100%"
                                                                                height="100%"
                                                                            >
                                                                                <PieChart>
                                                                                    <RePie
                                                                                        data={tier2spendData}
                                                                                        cx="50%"
                                                                                        cy="50%"
                                                                                        labelLine={false}
                                                                                        label={
                                                                                            renderCustomizedLabelBlack
                                                                                        }
                                                                                        outerRadius={
                                                                                            viewWidgets === "talkwalker"
                                                                                                ? 80
                                                                                                : 50
                                                                                        }
                                                                                        fill="#8884d8"
                                                                                        dataKey="value"
                                                                                    >
                                                                                        {tier2spendData.map(
                                                                                            (entry, index) => (
                                                                                                <Cell
                                                                                                    key={`cell-${index}`}
                                                                                                    fill={
                                                                                                        COLORS[
                                                                                                            index %
                                                                                                                COLORS.length
                                                                                                        ]
                                                                                                    }
                                                                                                />
                                                                                            )
                                                                                        )}
                                                                                    </RePie>
                                                                                    <Tooltip
                                                                                        content={<CustomTooltip />}
                                                                                    />
                                                                                </PieChart>
                                                                            </ResponsiveContainer>
                                                                        </div>
                                                                        <h4>Keyword Priority 2</h4>
                                                                    </div>
                                                                    {/* <div className="csv_pie_card">
                                                                        <div
                                                                            className={
                                                                                viewWidgets === "talkwalker"
                                                                                    ? "pie_chart_card_maximized"
                                                                                    : "pie_chart_card_minimized"
                                                                            }
                                                                            style={
                                                                                viewWidgets === "talkwalker"
                                                                                    ? {
                                                                                          width: "240px",
                                                                                          height: "220px",
                                                                                      }
                                                                                    : { width: "240px" }
                                                                            }
                                                                        >
                                                                            <ResponsiveContainer
                                                                                width="100%"
                                                                                height="100%"
                                                                            >
                                                                                <PieChart>
                                                                                    <RePie
                                                                                        data={tier3spendData}
                                                                                        cx="50%"
                                                                                        cy="50%"
                                                                                        labelLine={false}
                                                                                        label={
                                                                                            renderCustomizedLabelBlack
                                                                                        }
                                                                                        outerRadius={
                                                                                            viewWidgets === "talkwalker"
                                                                                                ? 80
                                                                                                : 50
                                                                                        }
                                                                                        fill="#8884d8"
                                                                                        dataKey="value"
                                                                                    >
                                                                                        {tier3spendData.map(
                                                                                            (entry, index) => (
                                                                                                <Cell
                                                                                                    key={`cell-${index}`}
                                                                                                    fill={
                                                                                                        COLORS[
                                                                                                            index %
                                                                                                                COLORS.length
                                                                                                        ]
                                                                                                    }
                                                                                                />
                                                                                            )
                                                                                        )}
                                                                                    </RePie>
                                                                                    <Tooltip
                                                                                        content={<CustomTooltip />}
                                                                                    />
                                                                                </PieChart>
                                                                            </ResponsiveContainer>
                                                                        </div>
                                                                        <h4>TIER 3 SPEND: MINORITY JOURNALISTS</h4>
                                                                    </div> */}
                                                                    <div className="csv_upload_card">
                                                                            {/* <label
                                                                                htmlFor="csv_upload"
                                                                                className="csv_upload_label"
                                                                            > */}
                                                                                <img
                                                                                    src={CsvUploadIcon}
                                                                                    width="100px"
                                                                                    alt="Csv Upload"
                                                                                    className="rotate180"
                                                                                    style={{cursor: "pointer"}}
                                                                                    onClick={() => {
                                                                                        getKeywordsCSV()
                                                                                    }
                                                                                    }
                                                                                />
                                                                            {/* </label>
                                                                            <input
                                                                                type="file"
                                                                                id="csv_upload"
                                                                                name="filename"
                                                                                accept=".csv"
                                                                                hidden
                                                                                onChange={(e) => {
                                                                                    handleCsvUpload(e)
                                                                                }
                                                                                }
                                                                            /> */}
                                                                            {csvfile === null ? (<h3>Download Keywords CSV</h3>) : (
                                                                                <>
                                                                                <h3>{csvfile.name}</h3>
                                                                                <button
                                                                                    className="csv_upload_btn"
                                                                                    // onClick={() => {
                                                                                    //     setDomainCsvData([])
                                                                                    //     setDomainCsvData(
                                                                                    //         csvfile
                                                                                    //     )
                                                                                    //     setDomainCsvFile(
                                                                                    //         csvfile.name
                                                                                    //     )
                                                                                    //     setDomainCsvFileError(
                                                                                    //         ""
                                                                                    //     )
                                                                                    // }}
                                                                                    disabled={csvUploadLoading}
                                                                                    onClick={uploadCSV}
                                                                                >
                                                                                    {csvUploadLoading ? (
                                                                                        "Loading..."
                                                                                    ) : (
                                                                                        "Upload"
                                                                                    )}
                                                                                </button>
                                                                                </>
                                                                            )}
                                                                            {/* <div>
                                                                                <h4>{campaignCsvData?.status === "processing" ? "(CSV Data Processing)" : campaignCsvData?.status === "completed" ? "(CSV Uploaded)" : "(CSV Not Uploaded)"}</h4>
                                                                                {campaignCsvData?.status === "processing" && (<h4>Check Later</h4>)}
                                                                            </div> */}
                                                                        </div>
                                                                    {/* <div className="haleon_data_card">
                                                                        <div className="impact_question" style={viewWidgets !== "talkwalker" ? {margin: "auto", maxWidth: "100%"} : {}}>
                                                                            IMPACT QUESTION
                                                                            <br />
                                                                            (dynamic)
                                                                        </div>
                                                                        <div
                                                                            className={
                                                                                viewWidgets === "talkwalker"
                                                                                    ? "sp_haleon_card_maximized"
                                                                                    : "sp_haleon_card_minimized"
                                                                            }
                                                                            style={
                                                                                viewWidgets === "talkwalker"
                                                                                    ? { width: "400px" }
                                                                                    : { width: "256px" }
                                                                            }
                                                                        >
                                                                            <ResponsiveContainer width="100%">
                                                                                <PieChart>
                                                                                    <RePie
                                                                                        data={pie_data}
                                                                                        cx="50%"
                                                                                        cy="50%"
                                                                                        labelLine={false}
                                                                                        label={
                                                                                            renderCustomizedLabelBlack
                                                                                        }
                                                                                        outerRadius={
                                                                                            viewWidgets === "talkwalker"
                                                                                                ? 110
                                                                                                : 90
                                                                                        }
                                                                                        fill="#8884d8"
                                                                                        dataKey="value"
                                                                                    >
                                                                                        {pie_data.map(
                                                                                            (entry, index) => (
                                                                                                <Cell
                                                                                                    key={`cell-${index}`}
                                                                                                    fill={
                                                                                                        COLORS[
                                                                                                            index %
                                                                                                                COLORS.length
                                                                                                        ]
                                                                                                    }
                                                                                                />
                                                                                            )
                                                                                        )}
                                                                                    </RePie>
                                                                                    {viewWidgets === "talkwalker" && (
                                                                                        <Legend
                                                                                            layout="vertical"
                                                                                            verticalAlign="middle"
                                                                                            align="right"
                                                                                        />
                                                                                    )}
                                                                                    <Tooltip
                                                                                        content={<CustomTooltip />}
                                                                                    />
                                                                                </PieChart>
                                                                            </ResponsiveContainer>
                                                                        </div>
                                                                        <div className="haleon_si" style={viewWidgets !== "talkwalker" ? {margin: "auto", maxWidth: "100%"} : {}}>
                                                                            {viewWidgets === "talkwalker" ? (
                                                                                <h2>HALEON SOCIAL IMPACT</h2>
                                                                            ) : (
                                                                                <h3>HALEON SOCIAL IMPACT</h3>
                                                                            )}
                                                                        </div>
                                                                    </div> */}
                                                                    {/* <div className="cpm_breakdown">
                                                                        <h3>CPM BREAKDOWN</h3>
                                                                        <div className="cpm_tags_cont">
                                                                            <div className="cpm_tag_outer">
                                                                                <div className="cpm_tag_inner">
                                                                                    To Media Owner
                                                                                </div>
                                                                                <p>5.5</p>
                                                                            </div>
                                                                            <div className="cpm_tag_outer">
                                                                                <div className="cpm_tag_inner">
                                                                                    To Tech Vendor
                                                                                </div>
                                                                                <p>3.6</p>
                                                                            </div>
                                                                        </div>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                            </div>
                                                                )    
                                                            }
                                                        </>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Box>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={viewWidgets === "intelligence" ? 12 : 6}>
                            {((selectedSection.includes("intelligence") && !viewWidgets) ||
                                viewWidgets === "intelligence") && (
                                <Box
                                    // style={{ marginTop: viewWidgets && "10px" }}
                                    // className={!viewWidgets ? classes.cardBoxIntelligence : ""}
                                    className={classes.boxShadow}
                                    style={{ marginTop: "0px" }}
                                >
                                    <Card
                                        style={{
                                            backgroundImage: "linear-gradient(#4911a2,#9211a2)",
                                            color: "white",
                                            height: viewWidgets === "intelligence" ? "80vh" : "410px",
                                            minHeight: "455px",
                                            borderTopLeftRadius: "0px",
                                            borderTopRightRadius: "0px",
                                        }}
                                    >
                                        <CardContent>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    {!viewWidgets ? (
                                                        <div
                                                            style={{
                                                                float: "right",
                                                                color: "white",
                                                                marginBottom: "10px",
                                                                cursor: "pointer",
                                                            }}
                                                            title="Maximize"
                                                        >
                                                            <div onClick={() => setViewWidgets("intelligence")}>
                                                                <img
                                                                    src={ExpandIcon}
                                                                    alt="maximize"
                                                                    width={18}
                                                                    style={{ color: "#ffffff" }}
                                                                />
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div
                                                            style={{
                                                                float: "right",
                                                                color: "white",
                                                                marginBottom: "10px",
                                                                cursor: "pointer",
                                                            }}
                                                            title="Manimize"
                                                        >
                                                            <MinimizeOutlined onClick={() => setViewWidgets("")} />
                                                        </div>
                                                    )}
                                                    <div
                                                        style={{
                                                            marginTop: "20px",
                                                        }}
                                                    >
                                                        {decodedSubscription &&
                                                        decodedSubscription.subscription === "free" ? (
                                                            <Subscription />
                                                        ) : (
                                                            <>
                                                                {viewWidgets !== "intelligence" ? (
                                                                    <div>
                                                                        <h1
                                                                            style={{
                                                                                textAlign: "center",
                                                                                textTransform: "uppercase",
                                                                                margin: "0 0 2rem 0",
                                                                            }}
                                                                        >
                                                                            CI INTELLIGENCE
                                                                        </h1>
                                                                        {chatDemographic !== "" &&
                                                                        chatIndustry !== "" &&
                                                                        chatAge !== "" &&
                                                                        chatBrand !== "" &&
                                                                        chatCountry !== "" &&
                                                                        chatGender !== "" ? (
                                                                            <>
                                                                                <div
                                                                                    style={{
                                                                                        padding: "0 30px",
                                                                                        fontSize:
                                                                                            viewWidgets ===
                                                                                            "intelligence"
                                                                                                ? "inherit"
                                                                                                : "10px",
                                                                                    }}
                                                                                >
                                                                                    <Slider {...settings}>
                                                                                        {gptQueries.map((item) => (
                                                                                            <div>
                                                                                                <div
                                                                                                    style={{
                                                                                                        background:
                                                                                                            "#d8dadb",
                                                                                                        textAlign:
                                                                                                            "center",
                                                                                                        borderRadius:
                                                                                                            "10px",
                                                                                                        marginRight:
                                                                                                            "1em",
                                                                                                        color: "#000",
                                                                                                        fontWeight:
                                                                                                            "700",
                                                                                                        padding:
                                                                                                            "0.5em",
                                                                                                        cursor:
                                                                                                            "pointer",
                                                                                                    }}
                                                                                                    onClick={() =>
                                                                                                        {gptQuestionHandler(
                                                                                                            item
                                                                                                        )
                                                                                                        ciQuestionHandler(
                                                                                                            item
                                                                                                        )
                                                                                                        }
                                                                                                    }
                                                                                                >
                                                                                                    <p>{parse(item)}</p>
                                                                                                </div>
                                                                                                <div
                                                                                                    style={{
                                                                                                        display: "flex",
                                                                                                        flexDirection:
                                                                                                            "row",
                                                                                                        justifyContent:
                                                                                                            "end",
                                                                                                    }}
                                                                                                >
                                                                                                    <div
                                                                                                        style={{
                                                                                                            width:
                                                                                                                "74px",
                                                                                                            height:
                                                                                                                "39px",
                                                                                                            background:
                                                                                                                "linear-gradient(#6411a2, #6911a2)",
                                                                                                            position:
                                                                                                                "relative",
                                                                                                            right:
                                                                                                                "15px",
                                                                                                            zIndex: "1",
                                                                                                            borderRadius:
                                                                                                                "59px 0px",
                                                                                                        }}
                                                                                                    ></div>
                                                                                                    <div
                                                                                                        style={{
                                                                                                            background:
                                                                                                                "rgb(216, 218, 219)",
                                                                                                            position:
                                                                                                                "relative",
                                                                                                            width:
                                                                                                                "43px",
                                                                                                            height:
                                                                                                                viewWidgets ===
                                                                                                                "intelligence"
                                                                                                                    ? "34px"
                                                                                                                    : "28px",
                                                                                                            right:
                                                                                                                "32px",
                                                                                                            bottom:
                                                                                                                "5px",
                                                                                                            float:
                                                                                                                "right",
                                                                                                            borderBottomRightRadius:
                                                                                                                "50px",
                                                                                                        }}
                                                                                                    ></div>
                                                                                                </div>
                                                                                            </div>
                                                                                        ))}
                                                                                    </Slider>
                                                                                </div>
                                                                                <div
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        height: "180px",
                                                                                        background: "#d8dadb",
                                                                                        borderRadius: "10px",
                                                                                    }}
                                                                                >
                                                                                    {chatLoading ? (
                                                                                        <div
                                                                                            className={
                                                                                                classes.gptLoadWrapper
                                                                                            }
                                                                                        >
                                                                                            <CircularProgress
                                                                                                color="primary"
                                                                                                size={40}
                                                                                            />
                                                                                        </div>
                                                                                    ) : (
                                                                                        <p
                                                                                            style={{
                                                                                                padding: "2em",
                                                                                                color: "#000",
                                                                                                overflowY: "auto",
                                                                                                maxHeight: "100%",
                                                                                            }}
                                                                                        >
                                                                                            {gptResponse === ""
                                                                                                ? "Here is the answer for you!"
                                                                                                : gptResponse.split('\n').map((line, i) => <span key={i}>{line}<br /></span>)}
                                                                                        </p>
                                                                                    )}
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <h3>
                                                                                Select from the Filters to use
                                                                                Intelligence.
                                                                            </h3>
                                                                        )}
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        {/* <div className="intelligence_heading"> */}
                                                                            <h1
                                                                                className=""
                                                                                style={{
                                                                                    textAlign: "center",
                                                                                    textTransform: "uppercase",
                                                                                    margin: "0 0 2rem 0",
                                                                                }}
                                                                            >
                                                                                INTELLIGENCE
                                                                            </h1>
                                                                        {/* </div> */}
                                                                        {/* <div className="intelligence_wrap_cont"> */}
                                                                            <div className="">
                                                                                {chatDemographic !== "" &&
                                                                                chatIndustry !== "" &&
                                                                                chatAge !== "" &&
                                                                                chatBrand !== "" &&
                                                                                chatCountry !== "" &&
                                                                                chatGender !== "" ? (
                                                                                    <>
                                                                                        <div
                                                                                            style={{
                                                                                                padding: "0 30px",
                                                                                                fontSize:
                                                                                                    viewWidgets ===
                                                                                                    "intelligence"
                                                                                                        ? "inherit"
                                                                                                        : "10px",
                                                                                            }}
                                                                                        >
                                                                                            <Slider {...settings}>
                                                                                                {gptQueries.map(
                                                                                                    (item) => (
                                                                                                        <div>
                                                                                                            <div
                                                                                                                style={{
                                                                                                                    background:
                                                                                                                        "#d8dadb",
                                                                                                                    textAlign:
                                                                                                                        "center",
                                                                                                                    borderRadius:
                                                                                                                        "10px",
                                                                                                                    marginRight:
                                                                                                                        "1em",
                                                                                                                    color:
                                                                                                                        "#000",
                                                                                                                    fontWeight:
                                                                                                                        "700",
                                                                                                                    padding:
                                                                                                                        "0.5em",
                                                                                                                    cursor:
                                                                                                                        "pointer",
                                                                                                                }}
                                                                                                                onClick={() =>
                                                                                                                    {gptQuestionHandler(
                                                                                                                        item
                                                                                                                    )
                                                                                                                    ciQuestionHandler(
                                                                                                                        item
                                                                                                                        )
                                                                                                                        }
                                                                                                                }
                                                                                                            >
                                                                                                                <p>
                                                                                                                    {parse(
                                                                                                                        item
                                                                                                                    )}
                                                                                                                </p>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                style={{
                                                                                                                    display:
                                                                                                                        "flex",
                                                                                                                    flexDirection:
                                                                                                                        "row",
                                                                                                                    justifyContent:
                                                                                                                        "end",
                                                                                                                }}
                                                                                                            >
                                                                                                                <div
                                                                                                                    style={{
                                                                                                                        width:
                                                                                                                            "74px",
                                                                                                                        height:
                                                                                                                            "39px",
                                                                                                                        background:
                                                                                                                            "linear-gradient(#6411a2, #6911a2)",
                                                                                                                        position:
                                                                                                                            "relative",
                                                                                                                        right:
                                                                                                                            "15px",
                                                                                                                        zIndex:
                                                                                                                            "1",
                                                                                                                        borderRadius:
                                                                                                                            "59px 0px",
                                                                                                                    }}
                                                                                                                ></div>
                                                                                                                <div
                                                                                                                    style={{
                                                                                                                        background:
                                                                                                                            "rgb(216, 218, 219)",
                                                                                                                        position:
                                                                                                                            "relative",
                                                                                                                        width:
                                                                                                                            "43px",
                                                                                                                        height:
                                                                                                                            viewWidgets ===
                                                                                                                            "intelligence"
                                                                                                                                ? "34px"
                                                                                                                                : "28px",
                                                                                                                        right:
                                                                                                                            "32px",
                                                                                                                        bottom:
                                                                                                                            "5px",
                                                                                                                        float:
                                                                                                                            "right",
                                                                                                                        borderBottomRightRadius:
                                                                                                                            "50px",
                                                                                                                    }}
                                                                                                                ></div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )
                                                                                                )}
                                                                                            </Slider>
                                                                                        </div>
                                                                                        <div className="intelligence_conts">
                                                                                            <div className="ci_intelligence_cont">
                                                                                                <div>
                                                                                                    <img src={CulturalIntelligenceLogo} alt="Cultural Intelligence" className="intelligence_logos" />
                                                                                                </div>
                                                                                            <div
                                                                                            className="ai_response_cont"
                                                                                            style={{
                                                                                                width: "100%",
                                                                                                // height: "360px",
                                                                                                background: "#d8dadb",
                                                                                                borderRadius: "10px",
                                                                                            }}
                                                                                        >
                                                                                            {ciChatLoading ? (
                                                                                                <div
                                                                                                    className={
                                                                                                        classes.gptLoadWrapper
                                                                                                    }
                                                                                                >
                                                                                                    <CircularProgress
                                                                                                        color="primary"
                                                                                                        size={40}
                                                                                                    />
                                                                                                </div>
                                                                                            ) : (
                                                                                                <p
                                                                                                    style={{
                                                                                                        padding: "2em",
                                                                                                        color: "#000",
                                                                                                        overflowY:
                                                                                                            "auto",
                                                                                                        maxHeight:
                                                                                                            "100%",
                                                                                                    }}
                                                                                                >
                                                                                                    {ciIntelligenceResponse?.answer ? ciIntelligenceResponse?.answer?.split('\n').map((line, i) => <span key={i}>{line}<br /></span>)
                                                                                                        : "Here is the answer for you!"}
                                                                                                </p>
                                                                                            )}
                                                                                        </div>
                                                                                            </div>
                                                                                            <div className="gpt_intelligence_cont">
                                                                                                <div>
                                                                                                    <img src={GeneralAI} height={"74.42px"} alt="GPT3" className="intelligence_logos" />
                                                                                                </div>
                                                                                            <div
                                                                                            className="ai_response_cont"
                                                                                            style={{
                                                                                                width: "100%",
                                                                                                // height: "320px",
                                                                                                background: "#d8dadb",
                                                                                                borderRadius: "10px",
                                                                                            }}
                                                                                        >
                                                                                            {chatLoading ? (
                                                                                                <div
                                                                                                    className={
                                                                                                        classes.gptLoadWrapper
                                                                                                    }
                                                                                                >
                                                                                                    <CircularProgress
                                                                                                        color="primary"
                                                                                                        size={40}
                                                                                                    />
                                                                                                </div>
                                                                                            ) : (
                                                                                                <p
                                                                                                    style={{
                                                                                                        padding: "2em",
                                                                                                        color: "#000",
                                                                                                        overflowY:
                                                                                                            "auto",
                                                                                                        maxHeight:
                                                                                                            "100%",
                                                                                                    }}
                                                                                                >
                                                                                                    {gptResponse === ""
                                                                                                        ? "Here is the answer for you!"
                                                                                                        : gptResponse.split('\n').map((line, i) => <span key={i}>{line}<br /></span>)}
                                                                                                </p>
                                                                                            )}
                                                                                        </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                ) : (
                                                                                    <h3>
                                                                                        Select from the Filters to use
                                                                                        Intelligence.
                                                                                    </h3>
                                                                                )}
                                                                            </div>
                                                                        {/* </div> */}
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Box>
                            )}
                        </Grid>
                        {((selectedSection.includes("quant") && !viewWidgets) || viewWidgets === "quant") && (
                            <Grid item xs={12} sm={12} md={12}>
                                <Box className={!viewWidgets ? classes.cardBoxIntelligence : ""}>
                                    <Card
                                        style={{
                                            backgroundImage: "linear-gradient(#4911a2,#9211a2)",
                                            color: "white",
                                            height: !viewWidgets ? "410px" : "100%",
                                            overflow: "auto",
                                            minHeight: "410px",
                                        }}
                                        onScroll={(e) => onScroll(e, "quant")}
                                    >
                                        <CardContent style={{ position: "relative", textAlign: "right" }}>
                                            <div className="search-total-bar">
                                                <h4
                                                    style={{
                                                        marginTop: 0,
                                                        display: "inline",
                                                    }}
                                                >
                                                    Quant/Qual Research
                                                </h4>
                                                <div className="search-select">
                                                    <input
                                                        placeholder="Search..."
                                                        style={{
                                                            outline: "none",
                                                            backgroundColor: "white",
                                                            border: "solid 2px #E5E5E5",
                                                            color: "black",
                                                            borderRadius: "23px",
                                                            padding: "7px",
                                                            width: "25%",
                                                        }}
                                                        onChange={(event) => {
                                                            setSearchQuant(event.target.value)
                                                        }}
                                                    ></input>
                                                    <button
                                                        className={classes.addActionBtn}
                                                        onClick={() => {
                                                            setAllQue1in5Data([])
                                                            setque1in5data([])
                                                            setQuantTotalPages(0)
                                                            setQuantCurrentPage(1)
                                                            forceUpdateQ(countQ + 1)
                                                        }}
                                                    >
                                                        Search
                                                    </button>
                                                    {!viewWidgets ? (
                                                        <div
                                                            style={{
                                                                float: "right",
                                                                color: "white",
                                                                marginBottom: "0px",
                                                                cursor: "pointer",
                                                            }}
                                                            title="Maximize"
                                                        >
                                                            <CropSquareOutlined
                                                                onClick={() => setViewWidgets("quant")}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div
                                                            style={{
                                                                color: "white",
                                                                marginBottom: "10px",
                                                                cursor: "pointer",
                                                                display: "inline",
                                                            }}
                                                            title="Manimize"
                                                        >
                                                            <MinimizeOutlined onClick={() => setViewWidgets("")} />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            {decodedSubscription && decodedSubscription.subscription === "free" ? (
                                                <Subscription />
                                            ) : (
                                                <div style={{ marginTop: "44px" }}>
                                                    <Grid
                                                        container
                                                        spacing={1}
                                                        direction="row"
                                                        className="color-dot-wrapper"
                                                    >
                                                        <div className="radio-dots">
                                                            {colorQuantArr.map((option) => (
                                                                <ul
                                                                    key={option.name}
                                                                    value={option.name}
                                                                    className="color-dot"
                                                                >
                                                                    <li
                                                                        onClick={() => {
                                                                            setSection(option.name)
                                                                        }}
                                                                    >
                                                                        <span
                                                                            className="media-dot"
                                                                            style={{ background: option.color }}
                                                                        ></span>
                                                                        <span className="media-name">
                                                                            {option.name}
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                            ))}
                                                        </div>

                                                        {que1in5Data && que1in5Data.length
                                                            ? que1in5Data?.map((element, index) => {
                                                                  let ans1Arr = []
                                                                  let per1Arr = []
                                                                  return (
                                                                      <Box>
                                                                          <Card
                                                                              className={classes.queCardBox}
                                                                              style={{
                                                                                  background: colorQuantArr.filter(
                                                                                      (i) =>
                                                                                          i.name.toLowerCase() ===
                                                                                          "quantitative" // element.type
                                                                                  )[0].color,
                                                                                  minHeight: "137px",
                                                                                  minWidth: "180px",
                                                                              }}
                                                                              onClick={() => {
                                                                                  element.answers.filter((e) =>
                                                                                      ans1Arr.push(e.ans)
                                                                                  )
                                                                                  element.answers.filter((e) =>
                                                                                      per1Arr.push(e.percent)
                                                                                  )
                                                                                  setAnsArr(ans1Arr)
                                                                                  setPerArr(per1Arr)
                                                                                  setQuantName(element.question)
                                                                                  setSectionType(
                                                                                      element.type ? element.type : ""
                                                                                  )
                                                                                  setOpen(true)
                                                                              }}
                                                                          >
                                                                              <CardContent>
                                                                                  {element.question}
                                                                              </CardContent>
                                                                          </Card>
                                                                      </Box>
                                                                  )
                                                              })
                                                            : null}
                                                    </Grid>
                                                    <Modal
                                                        aria-labelledby="simple-modal-title"
                                                        aria-describedby="simple-modal-description"
                                                        open={open}
                                                    >
                                                        {section.toLowerCase() === "qualitative" ||
                                                        sectionType.toLowerCase() === "qualitative" ? (
                                                            <div className="message-popup">
                                                                <span
                                                                    style={{
                                                                        padding: "8px 0",
                                                                    }}
                                                                >
                                                                    {quantName}
                                                                    <Button
                                                                        style={{
                                                                            color: "white",
                                                                            fontWeight: 900,
                                                                            fontSize: "20px",
                                                                        }}
                                                                        onClick={() => setOpen(false)}
                                                                    >
                                                                        X
                                                                    </Button>
                                                                </span>
                                                                <hr />
                                                                <p style={{ marginLeft: "10px" }}>Answers:</p>
                                                                <ul
                                                                    style={{
                                                                        padding: "0 0 0 15px",
                                                                        margin: "0",
                                                                        maxHeight: "75px",
                                                                        overflow: "auto",
                                                                    }}
                                                                >
                                                                    {ansArr &&
                                                                        ansArr.length &&
                                                                        ansArr.map((as) => (
                                                                            <li style={{ marginBottom: "5px" }}>
                                                                                {as}
                                                                            </li>
                                                                        ))}
                                                                </ul>
                                                            </div>
                                                        ) : (
                                                            <Box
                                                                style={{
                                                                    backgroundImage: "linear-gradient(#4911a2,#9211a2)",
                                                                    color: "white",
                                                                    height: "350px",
                                                                    overflow: "auto",
                                                                    position: "absolute",
                                                                    top: "50%",
                                                                    left: "50%",
                                                                    transform: "translate(-50%, -50%)",
                                                                    width: 400,
                                                                    bgcolor: "background.paper",
                                                                    border: "1px #000",
                                                                    boxShadow: 24,
                                                                    p: 4,
                                                                }}
                                                            >
                                                                <Button
                                                                    style={{
                                                                        float: "right",
                                                                        color: "white",
                                                                        fontWeight: 900,
                                                                        fontSize: "20px",
                                                                    }}
                                                                    onClick={() => setOpen(false)}
                                                                >
                                                                    X
                                                                </Button>
                                                                <div className="pie-canvas">
                                                                    <span
                                                                        style={{
                                                                            justifyContent: "center",
                                                                            display: "flex",
                                                                            padding: "8px",
                                                                        }}
                                                                    >
                                                                        {quantName}
                                                                    </span>
                                                                    <Bar
                                                                        data={{
                                                                            labels: ansArr,
                                                                            datasets: [
                                                                                {
                                                                                    backgroundColor: [
                                                                                        "#B21F00",
                                                                                        "#C9DE00",
                                                                                        "#2FDE00",
                                                                                        "#00A6B4",
                                                                                        "#6800B4",
                                                                                    ],
                                                                                    hoverBackgroundColor: [
                                                                                        "#501800",
                                                                                        "#4B5000",
                                                                                        "#175000",
                                                                                        "#003350",
                                                                                        "#35014F",
                                                                                    ],
                                                                                    data: perArr,
                                                                                },
                                                                            ],
                                                                        }}
                                                                        options={{
                                                                            plugins: {
                                                                                legend: {
                                                                                    display: false,
                                                                                },
                                                                            },
                                                                            scales: {
                                                                                y: {
                                                                                    ticks: {
                                                                                        color: "white",
                                                                                        font: {
                                                                                            size: 14,
                                                                                        },
                                                                                    },
                                                                                },
                                                                                x: {
                                                                                    ticks: {
                                                                                        color: "white",
                                                                                        font: {
                                                                                            size: 14,
                                                                                        },
                                                                                    },
                                                                                },
                                                                            },
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Box>
                                                        )}
                                                    </Modal>
                                                </div>
                                            )}
                                        </CardContent>
                                    </Card>
                                </Box>
                            </Grid>
                        )}
                        {((selectedSection.includes("campaign_metrics") && !viewWidgets) ||
                            viewWidgets === "campaign_metrics") && (
                            <Grid item xs={12} sm={12} md={12}>
                                <Box className={!viewWidgets ? classes.cardBoxIntelligence : ""}>
                                    <Card
                                        style={{
                                            backgroundImage: "linear-gradient(#4911a2,#9211a2)",
                                            color: "white",
                                            height: !viewWidgets ? "420px" : "100%",
                                            overflow: "auto",
                                        }}
                                    >
                                        <CardContent>
                                            {!viewWidgets ? (
                                                <div
                                                    style={{
                                                        float: "right",
                                                        color: "white",
                                                        marginTop: "10px",
                                                        cursor: "pointer",
                                                    }}
                                                    title="Maximize"
                                                >
                                                    <CropSquareOutlined
                                                        onClick={() => setViewWidgets("campaign_metrics")}
                                                    />
                                                </div>
                                            ) : (
                                                <div
                                                    style={{
                                                        float: "right",
                                                        color: "white",
                                                        marginBottom: "10px",
                                                        cursor: "pointer",
                                                    }}
                                                    title="Manimize"
                                                >
                                                    <MinimizeOutlined onClick={() => setViewWidgets("")} />
                                                </div>
                                            )}
                                            {decodedSubscription && decodedSubscription.subscription === "free" ? (
                                                <Subscription />
                                            ) : (
                                                <>
                                                    <FormControl
                                                        fullWidth
                                                        component="fieldset"
                                                        variant="outlined"
                                                        className={[classes.selectControl, classes.customfield].join(
                                                            " "
                                                        )}
                                                        style={{
                                                            marginTop: "5px",
                                                            width: "24%",
                                                            float: "right",
                                                        }}
                                                    >
                                                        <Select
                                                            id="outlined-select-currency-native"
                                                            multiple
                                                            value={questionName}
                                                            onChange={(e) => {
                                                                {
                                                                    setQuestionName(e.target.value)
                                                                }
                                                            }}
                                                            variant="outlined"
                                                            renderValue={(ep) => {
                                                                let arrNm = []
                                                                questionName.map((e) => {
                                                                    let nm = capitalizeFirstLetter(e)
                                                                    arrNm.push(nm + " , ")
                                                                })
                                                                return arrNm
                                                            }}
                                                        >
                                                            <MenuItem disabled value={0}>
                                                                <em>select kpi type</em>
                                                            </MenuItem>
                                                            {selectGraphArr &&
                                                                selectGraphArr.length &&
                                                                selectGraphArr?.map((option) => (
                                                                    <MenuItem
                                                                        key={option.kpi_type}
                                                                        value={option.kpi_type}
                                                                    >
                                                                        <Checkbox
                                                                            checked={questionName.includes(
                                                                                option.kpi_type
                                                                            )}
                                                                        ></Checkbox>
                                                                        {option.name}
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                    </FormControl>

                                                    <FormControl
                                                        component="fieldset"
                                                        variant="outlined"
                                                        className={[classes.selectControl, classes.customfield].join(
                                                            " "
                                                        )}
                                                        style={{
                                                            marginTop: "5px",
                                                            marginRight: "10px",
                                                            width: "24%",
                                                            float: "right",
                                                        }}
                                                    >
                                                        <TextField
                                                            id="outlined-select-currency-native"
                                                            select
                                                            value={campaign}
                                                            onChange={(e) => setCampaign(e.target.value)}
                                                            SelectProps={{
                                                                native: true,
                                                            }}
                                                            variant="outlined"
                                                        >
                                                            <option key={0} value={0}>
                                                                Select campaign...
                                                            </option>
                                                            {campaignList &&
                                                                campaignList.length &&
                                                                campaignList?.map((option) => (
                                                                    <option
                                                                        key={option.campaign_id}
                                                                        value={option.campaign_id}
                                                                    >
                                                                        {option.campaign_name}
                                                                    </option>
                                                                ))}
                                                        </TextField>
                                                    </FormControl>
                                                    <div
                                                        style={{
                                                            marginTop: "20px",
                                                        }}
                                                    >
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justify="flex-start"
                                                            alignItems="flex-start"
                                                        >
                                                            {insights && insights.media && insights.media.length ? (
                                                                <Grid item xs={6} sm={6} md={6}>
                                                                    <span
                                                                        style={{
                                                                            justifyContent: "center",
                                                                            display: "flex",
                                                                        }}
                                                                    >
                                                                        Media Insights
                                                                    </span>
                                                                    <br />
                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justify="flex-start"
                                                                        alignItems="flex-start"
                                                                    >
                                                                        {insights.media?.map((inside) => (
                                                                            <Grid item xs={6} sm={6} md={6}>
                                                                                <Box>
                                                                                    <Card
                                                                                        className={classes.graphCardBox}
                                                                                    >
                                                                                        <CardContent>
                                                                                            <span>
                                                                                                {inside.insight_name
                                                                                                    ? inside.insight_name
                                                                                                    : ""}
                                                                                            </span>
                                                                                            <br />
                                                                                            <span>
                                                                                                <b>
                                                                                                    {inside.lift_percentage
                                                                                                        ? inside.lift_percentage
                                                                                                        : ""}
                                                                                                    %
                                                                                                </b>
                                                                                            </span>
                                                                                            <br />
                                                                                            <span>
                                                                                                <b>Publisher #9</b> has
                                                                                                the highest lift
                                                                                            </span>
                                                                                            <br />
                                                                                        </CardContent>
                                                                                    </Card>
                                                                                </Box>
                                                                            </Grid>
                                                                        ))}
                                                                    </Grid>
                                                                </Grid>
                                                            ) : null}
                                                            {insights &&
                                                            insights.audience &&
                                                            insights.audience.length ? (
                                                                <Grid item xs={6} sm={6} md={6}>
                                                                    <span
                                                                        style={{
                                                                            justifyContent: "center",
                                                                            display: "flex",
                                                                        }}
                                                                    >
                                                                        Audience Insights
                                                                    </span>{" "}
                                                                    <br />
                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        justify="flex-start"
                                                                        alignItems="flex-start"
                                                                    >
                                                                        {insights.audience?.map((ia) => (
                                                                            <Grid item xs={6} sm={6} md={6}>
                                                                                <Box>
                                                                                    <Card
                                                                                        className={classes.graphCardBox}
                                                                                    >
                                                                                        <CardContent>
                                                                                            <span>
                                                                                                {ia.insight_name
                                                                                                    ? ia.insight_name
                                                                                                    : ""}
                                                                                            </span>
                                                                                            <br />
                                                                                            <span>
                                                                                                <b>
                                                                                                    {ia.lift_percentage
                                                                                                        ? ia.lift_percentage
                                                                                                        : ""}
                                                                                                    %
                                                                                                </b>
                                                                                            </span>
                                                                                            <br />
                                                                                            <span>
                                                                                                <b>Publisher #9</b> has
                                                                                                the highest lift
                                                                                            </span>
                                                                                            <br />
                                                                                        </CardContent>
                                                                                    </Card>
                                                                                </Box>
                                                                            </Grid>
                                                                        ))}
                                                                    </Grid>
                                                                </Grid>
                                                            ) : null}
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justify="flex-start"
                                                            alignItems="flex-start"
                                                            style={{ marginTop: "10px", marginLeft: "10px" }}
                                                        >
                                                            <TableContainer className="graph-table">
                                                                <Table>
                                                                    {allQuestions && allQuestions.length
                                                                        ? allQuestions.map((aq) => (
                                                                              <TableRow>
                                                                                  <TableCell>
                                                                                      {aq.question
                                                                                          ? capitalizeFirstLetter(
                                                                                                aq.question
                                                                                                    .question_name
                                                                                            )
                                                                                          : ""}
                                                                                  </TableCell>
                                                                                  <TableCell align="right">
                                                                                      <Button
                                                                                          style={{
                                                                                              float: "right",
                                                                                              color: "black",
                                                                                              backgroundColor:
                                                                                                  "greenyellow",
                                                                                              fontWeight: 300,
                                                                                              fontSize: "20px",
                                                                                          }}
                                                                                          disabled
                                                                                      >
                                                                                          {aq.lift_percentage
                                                                                              ? aq.lift_percentage
                                                                                              : 0}
                                                                                      </Button>
                                                                                  </TableCell>
                                                                                  <TableCell align="right">
                                                                                      <span>
                                                                                          <b>Above Average</b>
                                                                                          <br />
                                                                                          KPI Benchmark
                                                                                      </span>
                                                                                  </TableCell>
                                                                                  <TableCell align="right">
                                                                                      <span>
                                                                                          <b>
                                                                                              {" "}
                                                                                              {aq.statistically_significant
                                                                                                  ? "Statistically Significant"
                                                                                                  : "Not Statistically Significant"}
                                                                                          </b>
                                                                                          <br />
                                                                                          {aq.margin_of_error
                                                                                              ? aq.margin_of_error
                                                                                              : 0}
                                                                                          % Margin of error
                                                                                      </span>
                                                                                  </TableCell>
                                                                                  <TableCell>
                                                                                      EXPOSED n=
                                                                                      {aq.exposed_count
                                                                                          ? aq.exposed_count
                                                                                          : 0}
                                                                                      <LinearProgress
                                                                                          disabled
                                                                                          variant="determinate"
                                                                                          value={
                                                                                              aq.exposed_percentage
                                                                                                  ? aq.exposed_percentage
                                                                                                  : 0
                                                                                          }
                                                                                      />{" "}
                                                                                      {aq.exposed_percentage
                                                                                          ? aq.exposed_percentage
                                                                                          : 0}
                                                                                      % <br />
                                                                                      CONTROL n=
                                                                                      {aq.control_count
                                                                                          ? aq.control_count
                                                                                          : 0}{" "}
                                                                                      <LinearProgress
                                                                                          disabled
                                                                                          variant="determinate"
                                                                                          value={
                                                                                              aq.control_percentage
                                                                                                  ? aq.control_percentage
                                                                                                  : 0
                                                                                          }
                                                                                      />{" "}
                                                                                      {aq.control_percentage
                                                                                          ? aq.control_percentage
                                                                                          : 0}
                                                                                      %
                                                                                  </TableCell>
                                                                              </TableRow>
                                                                          ))
                                                                        : null}
                                                                </Table>
                                                            </TableContainer>
                                                        </Grid>

                                                        <div style={{ marginTop: "10px" }}>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justify="flex-start"
                                                                alignItems="flex-start"
                                                            >
                                                                <Grid item xs={6} sm={6} md={6}>
                                                                    <b style={{ marginBottom: "10px" }}>
                                                                        Lift Performance by Age
                                                                    </b>
                                                                    <div
                                                                        style={{
                                                                            width: "70%",
                                                                            marginTop: "5px",
                                                                            height: "250px",
                                                                        }}
                                                                    >
                                                                        <Bar
                                                                            width="30px"
                                                                            height="20px"
                                                                            data={{
                                                                                labels:
                                                                                    ageData && ageData.length
                                                                                        ? ageData[0].labels
                                                                                        : [],
                                                                                datasets:
                                                                                    ageData && ageData.length
                                                                                        ? ageData[0].datasets
                                                                                        : [],
                                                                            }}
                                                                            options={{
                                                                                plugins: {
                                                                                    legend: {
                                                                                        display: false,
                                                                                    },
                                                                                },
                                                                                scales: {
                                                                                    y: {
                                                                                        ticks: {
                                                                                            color: "white",
                                                                                            font: {
                                                                                                size: 14,
                                                                                            },
                                                                                        },
                                                                                    },
                                                                                    x: {
                                                                                        ticks: {
                                                                                            color: "white",
                                                                                            font: {
                                                                                                size: 14,
                                                                                            },
                                                                                        },
                                                                                    },
                                                                                },
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </Grid>

                                                                <Grid item xs={6} sm={6} md={6}>
                                                                    <b style={{ marginBottom: "10px" }}>
                                                                        Lift Performance by Gender
                                                                    </b>
                                                                    <div
                                                                        style={{
                                                                            width: "70%",
                                                                            marginTop: "5px",
                                                                            height: "250px",
                                                                        }}
                                                                    >
                                                                        <Bar
                                                                            width="30px"
                                                                            height="20px"
                                                                            data={{
                                                                                labels:
                                                                                    gender && gender.length
                                                                                        ? gender[0].labels
                                                                                        : [],
                                                                                datasets:
                                                                                    gender && gender.length
                                                                                        ? gender[0].datasets
                                                                                        : [],
                                                                            }}
                                                                            options={{
                                                                                plugins: {
                                                                                    legend: {
                                                                                        display: false,
                                                                                    },
                                                                                },
                                                                                scales: {
                                                                                    y: {
                                                                                        ticks: {
                                                                                            color: "white",
                                                                                            font: {
                                                                                                size: 14,
                                                                                            },
                                                                                        },
                                                                                    },
                                                                                    x: {
                                                                                        ticks: {
                                                                                            color: "white",
                                                                                            font: {
                                                                                                size: 14,
                                                                                            },
                                                                                        },
                                                                                    },
                                                                                },
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                        <div style={{ marginTop: "10%" }}>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justify="flex-start"
                                                                alignItems="flex-start"
                                                            >
                                                                <Grid item xs={6} sm={6} md={6}>
                                                                    <b style={{ marginBottom: "10px" }}>
                                                                        Lift Performance by Frequency
                                                                    </b>
                                                                    <div
                                                                        style={{
                                                                            width: "70%",
                                                                            marginTop: "5px",
                                                                            height: "250px",
                                                                        }}
                                                                    >
                                                                        <Bar
                                                                            width="30px"
                                                                            height="20px"
                                                                            data={{
                                                                                labels:
                                                                                    frequency && frequency.length
                                                                                        ? frequency[0].labels
                                                                                        : [],
                                                                                datasets:
                                                                                    frequency && frequency.length
                                                                                        ? frequency[0].datasets
                                                                                        : [],
                                                                            }}
                                                                            options={{
                                                                                plugins: {
                                                                                    legend: {
                                                                                        display: false,
                                                                                    },
                                                                                },
                                                                                scales: {
                                                                                    y: {
                                                                                        ticks: {
                                                                                            color: "white",
                                                                                            font: {
                                                                                                size: 14,
                                                                                            },
                                                                                        },
                                                                                    },
                                                                                    x: {
                                                                                        ticks: {
                                                                                            color: "white",
                                                                                            font: {
                                                                                                size: 14,
                                                                                            },
                                                                                        },
                                                                                    },
                                                                                },
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={6} sm={6} md={6}>
                                                                    <b style={{ marginBottom: "10px" }}>
                                                                        Lift Performance by Device
                                                                    </b>

                                                                    <div
                                                                        style={{
                                                                            width: "70%",
                                                                            marginTop: "5px",
                                                                            height: "250px",
                                                                        }}
                                                                    >
                                                                        <Bar
                                                                            width="30px"
                                                                            height="20px"
                                                                            data={{
                                                                                labels:
                                                                                    device && device.length
                                                                                        ? device[0].labels
                                                                                        : [],
                                                                                datasets:
                                                                                    device && device.length
                                                                                        ? device[0].datasets
                                                                                        : [],
                                                                            }}
                                                                            options={{
                                                                                plugins: {
                                                                                    legend: {
                                                                                        display: false,
                                                                                    },
                                                                                },
                                                                                scales: {
                                                                                    y: {
                                                                                        ticks: {
                                                                                            color: "white",
                                                                                            font: {
                                                                                                size: 14,
                                                                                            },
                                                                                        },
                                                                                    },
                                                                                    x: {
                                                                                        ticks: {
                                                                                            color: "white",
                                                                                            font: {
                                                                                                size: 14,
                                                                                            },
                                                                                        },
                                                                                    },
                                                                                },
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                        <div
                                                            style={{
                                                                marginTop: "10%",
                                                                marginBottom: "10px",
                                                                justifyContent: "center",
                                                                display: "flex",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justify="flex-start"
                                                                alignItems="flex-start"
                                                            >
                                                                <Grid item xs={8} sm={8} md={8}>
                                                                    <b style={{ marginBottom: "10px" }}>
                                                                        Lift Performance by Creative
                                                                    </b>
                                                                    <div
                                                                        style={{
                                                                            width: "70%",
                                                                            marginTop: "5px",
                                                                            height: "250px",
                                                                        }}
                                                                    >
                                                                        <Bar
                                                                            width="30px"
                                                                            height="20px"
                                                                            data={{
                                                                                labels:
                                                                                    creative && creative.length
                                                                                        ? creative[0].labels
                                                                                        : [],
                                                                                datasets:
                                                                                    creative && creative.length
                                                                                        ? creative[0].datasets
                                                                                        : [],
                                                                            }}
                                                                            options={{
                                                                                indexAxis: "y",
                                                                                plugins: {
                                                                                    legend: {
                                                                                        display: false,
                                                                                    },
                                                                                },
                                                                                scales: {
                                                                                    y: {
                                                                                        ticks: {
                                                                                            color: "white",
                                                                                            font: {
                                                                                                size: 14,
                                                                                            },
                                                                                        },
                                                                                    },
                                                                                    x: {
                                                                                        ticks: {
                                                                                            color: "white",
                                                                                            font: {
                                                                                                size: 14,
                                                                                            },
                                                                                        },
                                                                                    },
                                                                                },
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </CardContent>
                                    </Card>
                                </Box>
                            </Grid>
                        )}
                        {((selectedSection.includes("heatmap") && !viewWidgets) || viewWidgets === "heatmap") && (
                            <>
                                <HeatMap
                                    viewWidgets={viewWidgets}
                                    callbackViewWidget={(value) => {
                                        if (value == "max") {
                                            setViewWidgets("heatmap")
                                        } else {
                                            setViewWidgets("")
                                        }
                                    }}
                                />
                            </>
                        )}
                    </Grid>
                </div>
            )}
            {graphModal && renderGraphModal()}
        </div>
    )
}

export default MainLayout(Dashboard)

const RenderGridBox2 = ({ reportsName: reportsList, reportData, getSelectedInsightReportDetails }) => {
    // let allKeys = {};
    // useLayoutEffect(() => {
    //   Object.keys(reportData).forEach((key) => {
    //     console.log({key})
    //     allKeys[key] = rept?.value === key ? true : false;
    //   });
    // }, []);

    return (
        <>
            <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                <Swiper modules={[Navigation, Pagination]} navigation autoHeight={true}>
                    <SwiperSlide>
                        <div style={{ padding: "0 50px" }}>
                            <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                <div
                                    className=" bg-purple reportby-date title-icon d-block w-100"
                                    style={{ padding: "60px 0" }}
                                >
                                    <span className="badge text-center">
                                        {reportData ? reportData.report_name : ""}
                                    </span>
                                    <span className="badge text-center" style={{ marginBottom: "0" }}>
                                        {reportData && reportData.report_date ? getDMYDate(reportData.report_date) : ""}

                                        <br />
                                        {reportData ? reportData.report_by : ""}
                                    </span>
                                </div>
                            </Grid>
                        </div>
                    </SwiperSlide>
                    {reportsList
                        ?.filter((report) => report.value !== "participantDemographyQA")
                        ?.map((report) => (
                            <SwiperSlide>
                                <Grid item xs={report?.htmlObj?.grid} sm={report?.htmlObj?.grid} md={12}>
                                    <div className={report?.htmlObj?.class}>
                                        <span className="badge text-left">{report?.label}</span>
                                        <div className="icon">
                                            <img height={50} width={50} src={report?.imgUrl} />
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            height: "500px",
                                            overflow: "auto",
                                            color: "#fff",
                                            padding: "16px 50px",
                                        }}
                                    >
                                        {getSelectedInsightReportDetails({
                                            openReport: () => {
                                                let allKeys = {}
                                                Object.keys(reportData).forEach((key) => {
                                                    allKeys[key] = report?.value === key ? true : false
                                                })
                                                return allKeys
                                            },
                                        })}
                                    </div>
                                </Grid>
                            </SwiperSlide>
                        ))}
                </Swiper>
            </Grid>
        </>
    )
}
const getDMYDate = (dateTime) => {
    const newdate = new Date(dateTime)
    const d = newdate.getDate()
    const m = newdate.getMonth() + 1
    const y = newdate.getFullYear()
    const formattedate = (d <= 9 ? "0" + d : d) + "/" + (m <= 9 ? "0" + m : m) + "/" + y
    return formattedate
}
