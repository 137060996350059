import {
  post,
  get,
  postUpload,
  postUploadCsv,
  put,
  putAddNewMediaInventry,
} from "./axios";

export const postPersonas = async (data) => {
  return await post("/personas", data);
};

export const getPersonas = async () => {
  return await get("/personas");
};

export const postInventory = async (data) => {
  return await post("/mediaowner/create", data);
};

export const getMediaOwnerTitles = async (mediaOwnerId) => {
  return await get("/mediaowner/" + mediaOwnerId + "/titles");
};

export const postIMediaOwner = async (data) => {
  return await post("/mediaowner/create", data);
};

export const uploadAvatar = async (data) => {
  return await postUpload("/mediaowner/uploadAvatar", data);
};

export const uploadCsv = async (data) => {
  return await postUploadCsv("/mediaowner/uploadCsv", data);
};

export const getInsight = async () => {
  return await get("/mediaowner/meo_T7YS6ruXJagtLTmBS/insight");
};

export const putInsight = async (data) => {
  return await put("/mediaowner/updateWithInisght", data);
};

export const postMediOwnerAndInventory = async (data) => {
  return await post("/mediaowner/create", data);
};

export const getAllMediaOwners = async (userId) => {
  return await get(`/mediaowner/${userId}`);
};

export const putMediaInventry = async (mediaId, inventory) => {
  console.log("APIIIIIIIIII_Inventry", inventory);
  return await putAddNewMediaInventry(
    `/mediaowner/${mediaId}/addNewInventory`,
    inventory
  );
};

export const getMediaWithAllInventry = async (mediaId) => {
  return await get(`/mediaowner/${mediaId}/allInventories`);
  // return await get('/mediaowner/meo_ZyBXzYtA92m8KoowW/allInventories');
};
