import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Radio,
    RadioGroup,
    Select,
    TextField,
    TextareaAutosize,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import "./AddModal.css";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useParams } from "react-router-dom";
import { useAccessToken } from "../../../../Hooks/useAccessToken";
import { useLazyPostFetch } from "../../../../Hooks/usePostFetch";
import CustomDropdown from "../../../../components/CustomDropdown";
import LoaderCircle from "../../../../components/Loader/Loader";
import NewAlert from "../../../../components/NewAlert";
import ToastMessage from "../../../../components/Toast/Toast";
import { API } from "../../../../constants/index";
import MetaDataSection from "./MetaDataSection";
import TrackingPixel from "./TrackingPixel";

const useStyles = makeStyles({
    viewAbilityWrapper: {
        display: "flex",
        flexDirection: "row",
        "& .MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal": {
            maxWidth: "30%",
        },
    },
    percent: {
        margin: "auto 0",
        position: "relative",
        left: "-30px",
        borderLeft: "1px solid #c4c4c4",
        padding: "0.5em",
        color: "#838383",
    },
    second: {
        margin: "auto 0",
        position: "relative",
        borderLeft: "1px solid #c4c4c4",
        padding: "0.5em",
        left: "-62px",
        color: "#838383",
    },
    selectWrappper: {
        // display: "flex",
        // flexDirection: "row",
        // justifyContent: "space-around"
    },
});
// types of adItem
const adItemTypes = {
    image: "image",
    "html5": "rich-media",
    "Custom_HTML":"custom-html",
    "Native":"native",
    "Native_(Styled)":"native"
}

const AddModal = ({
    popParticularCampaign,
    setPopParticularCampaign,
    setPopAddCampaign,
    campaignType,
    adsCampaign,
    refetchAdItems
}) => {
    const params = useParams()
    const access_token = useAccessToken()
    const [ErrSucMsg, setErrSucMesg] = useState("");
    const [ErrSucType, setErrSucType] = useState(true);
    const [formFields, setFormFields] = useState({
        img_source_radio: "URL",
        size: "custom_size",
        tracking_pixels: [],
        metaDatas: [],
        static_weight: 1,
        native_html_template: "",
        raw_html_script: ""
    });
    const [tracking_pixel_errors, setTrackingPixelErrors] = useState([]);
    const { register, handleSubmit, errors, setValue, reset, } = useForm({
        mode: "onSubmit",
        reValidateMode: "onSubmit",
        defaultValues: {
            size_custom_width: 0,
            size_custom_height: 0
        }
    });
    const [formFieldsError, setFormFieldsError] = useState({raw_html_script: false,height_width_zero_error:false})
    const [adItemCreationError, setAdItemCreationError] = useState("")
    const [isCreatingAdItem, setIsCreatingAdItem] = useState(false)

    const handleFormFieldsChanges = (name, e) => {
        setFormFields((old_val) => ({ ...old_val, [name]: e.target.value }));
    };

    const resetAllFieldsAndErrors = () => {
        reset();
        setTrackingPixelErrors([]);
        setFormFields({
            img_source_radio: "URL",
            size: "custom_size",
            tracking_pixels: [],
            metaDatas: [],
            static_weight: 1,
            native_html_template: "",
        });
        setFormFieldsError({
            raw_html_script: false,
        })
    };

    // const {postFetchData: createNewAdItem,error}  = useLazyFormDataPostFetch({onError: (err) => {
    //     console.log(err?.response, err?.response?.data?.message)
    //     if(err?.response?.data?.message){
    //         setAdItemCreationError(err?.response?.data?.message)
    //     }
    // }})

    const onSubmit = async (data) => {
        if((data.size_custom_width == 0 && data.size_custom_height > 0) || data.size_custom_height == 0 && data.size_custom_width > 0){
            setFormFieldsError(errors => ({...errors, height_width_zero_error: true}))
            return
        }
        const meteDataFieldObj = formFields.metaDatas.map((meta) => {
            return {
                [meta.key]: meta.value
            }
        }).reduce((mapped_meta, acc) => {
            acc = {...acc, ...mapped_meta}
            return acc
        }, {})

        const formData = new FormData()

        formData.append("name", data.adItemName)
        formData.append("ad_type", adItemTypes[campaignType.type])
        formData.append("campaign_id", parseInt(params.campaignId))
        formData.append("campaign_type", params.campaignType)
        formData.append("width", Number(data.size_custom_width))
        formData.append("height", Number(data.size_custom_height))
        formData.append("weight", formFields.static_weight)
        Object.keys(meteDataFieldObj).forEach(key => {
            meteDataFieldObj[key] && formData.append(`metadata[${key}]`, meteDataFieldObj[key])
        })
        // formData.append("metadata", JSON.stringify(meteDataFieldObj))
        
        if(formFields.tracking_pixels[0].value){
            formData.append("tracking_pixel", formFields.tracking_pixels[0].value)
        }
        if(data.destinationUrl){
            formData.append("location", data.destinationUrl)
        }

        const payload = {
            ad_type: adItemTypes[campaignType.type],
        };

        if(payload.ad_type == "image"){
            if(data.alternate_text){
                formData.append("html_alt_text", data.alternate_text)
            }

            if(formFields.target_window == "_blank" || formFields.target_window == "_self"){
                formData.append("html_target", formFields.target_window)
            }else if(formFields.target_window =="Other"){
                formData.append("html_target", data.target_window__other)
            }

            if(data.Accompanied_HTML){
                formData.append("html_content_below", data.Accompanied_HTML)
            }

            if(data.img_source_url){
                formData.append("creative_url", data.img_source_url)
            }else if(data.image_source_library){
                formData.append("creative", data.image_source_library)
            }
        }
        if(payload.ad_type == "custom-html"){
            if(formFields.raw_html_script){
                formData.append("custom_html", formFields.raw_html_script)
            }else{
                setFormFieldsError((err) => ({...err, raw_html_script: true}))
                return
            }
        }
        if(payload.ad_type == "rich-media" && campaignType.type == 'html5'){
            formData.append("creative", data.upload_archive)
        }
        if(payload.ad_type == "native"){
            formData.append("template", 5668)
            formData.append("variables", JSON.stringify({6885:"A product name"}))
        }
        setIsCreatingAdItem(true)

        axios.post(`${API}/mediaPlanner/AdButler/Aditems`, formData, {
            headers: {
                Authorization: `Bearer ${access_token}`,
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if(res?.data?.status && res?.data?.id){
                // close all model and reset form after success .
                setPopAddCampaign(false)
                setPopParticularCampaign(false)

                // refetch adItems list
                refetchAdItems()
                
                resetAllFieldsAndErrors()
            }
        }).catch(err => {
            if(err?.response?.data?.message){
                setAdItemCreationError(err?.response?.data?.message)
                return 
            }
            if(err?.response?.data?.error){
                setAdItemCreationError(err?.response?.data?.error)
                return
            }
            setAdItemCreationError(err?.message)
        }).finally(() => {
            setIsCreatingAdItem(false)
        })
     
    };
    // const onSubmit = async (data) => {
    //     const meteDataFieldObj = formFields.metaDatas.map((meta) => {
    //         return {
    //             [meta.key]: meta.value
    //         }
    //     }).reduce((mapped_meta, acc) => {
    //         acc = {...acc, ...mapped_meta}
    //         return acc
    //     }, {})
    //     const formData = new FormData()
    //     formData.append("name", data.adItemName)
    //     formData.append("ad_type", adItemTypes[campaignType.type])
    //     formData.append("campaign_id", parseInt(params.campaignId))
    //     formData.append("campaign_type", params.campaignType)
    //     formData.append("width", Number(data.size_custom_width))
    //     formData.append("height", Number(data.size_custom_height))
    //     formData.append("metadata", JSON.stringify(meteDataFieldObj))
    //     formData.append("weight", formFields.static_weight)
        
    //     if(formFields.tracking_pixels[0].value){
    //         formData.append("tracking_pixel", formFields.tracking_pixels[0].value)
    //     }
    //     if(data.destinationUrl){
    //         formData.append("location", data.destinationUrl)
    //     }

    //     const payload = {
    //         name: data.adItemName,
    //         ad_type: adItemTypes[campaignType.type],
    //         campaign_id: parseInt(params.campaignId),
    //         campaign_type: params.campaignType,
    //         width: Number(data.size_custom_width),
    //         height:Number(data.size_custom_height),
    //         ...(formFields.tracking_pixels[0].value && {tracking_pixel: formFields.tracking_pixels[0].value}),
    //         metadata: meteDataFieldObj,
    //         ...(data.destinationUrl && {location: data.destinationUrl}),
    //         weight: formFields.static_weight
    //     };

    //     if(payload.ad_type == "image"){
    //         if(data.alternate_text){
    //             payload["html_alt_text"] = data.alternate_text
    //             formData.append("html_alt_text", data.alternate_text)
    //         }
    //         if(formFields.target_window == "_blank" || formFields.target_window == "_self"){
    //             payload["html_target"] = formFields.target_window
    //             formData.append("html_target", formFields.target_window)
    //         }else if(formFields.target_window =="Other"){
    //             payload["html_target"] = data.target_window__other
    //             formData.append("html_target", data.target_window__other)
    //         }
    //         if(data.Accompanied_HTML){
    //             payload["html_content_below"] = data.Accompanied_HTML
    //             formData.append("html_content_below", data.Accompanied_HTML)
    //         }
    //         if(data.img_source_url){
    //             payload["creative_url"] = data.img_source_url
    //             formData.append("creative_url", data.img_source_url)
    //             // payload["creative"] = null
    //         }else if(data.image_source_library){
    //             // payload["creative_url"] = null
    //             payload["creative"] = data.image_source_library
    //             formData.append("creative", data.image_source_library)
    //         }
    //     }
    //     if(payload.ad_type == "custom-html"){
    //         if(formFields.raw_html_script){
    //             payload["custom_html"] = formFields.raw_html_script
    //             formData.append("custom_html", formFields.raw_html_script)
    //         }else{
    //             console.log("not")
    //             setFormFieldsError((err) => ({...err, raw_html_script: true}))
    //             return
    //         }
    //     }
    //     if(payload.ad_type == "rich-media" && campaignType.type == 'html5'){
    //         payload["creative"] = data.upload_archive
    //         formData.append("creative", data.upload_archive)
    //     }

    //     console.log(data, formFields, formData);
    //     // axios.post(`${API}/mediaPlanner/AdButler/Aditems`, formData, {
    //     //     headers: {
    //     //         Authorization: `Bearer ${access_token}`,
    //     //         "Content-Type": "application/json",
    //     //     },
    //     // })
    //     // return 
    //     createNewAdItem(`${API}/mediaPlanner/AdButler/Aditems`, formData).then((res) => {
    //         console.log(res)
    //         if(!res) return
            // if(res?.status && res?.id){
            //     // close all model and reset form after success .
            //     setPopAddCampaign(false)
            //     setPopParticularCampaign(false)
            //     refetchAdItems()
            //     reset()
            //     setFormFields({
            //         img_source_radio: "URL",
            //         size: "custom_size",
            //         tracking_pixels: [],
            //         metaDatas: [],
            //         static_weight: 1,
            //         native_html_template: "",
            //         raw_html_script: ""
            //     })
            //     setFormFieldsError({
            //         raw_html_script: false,
            //     })
            // }
    //         if(!res?.status){
    //             setAdItemCreationError(res?.error)
    //         }
    //     })
    // };


    return (
        <>
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            open={popParticularCampaign}
        >
            <Card
                style={{
                    width: 700,
                    backgroundColor: "primary.dark",
                    height: "700px",
                    overflowY: "scroll",
                    "&:hover": {
                        backgroundColor: "primary.main",
                        opacity: [0.9, 0.8, 0.7],
                    },
                }}
            >
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: "red" }} aria-label="recipe">
                            +
                        </Avatar>
                    }
                    title={`Create New ${campaignType?.choose}`}
                />
                <CardContent>
                    <NewAlert msg={ErrSucMsg} isSuccess={ErrSucType} close={() => setErrSucMesg("")} />
                    {/* heading */}
                    <Typography style={{ marginBottom: "15px", fontWeight: "bold" }}>
                        {campaignType?.type == "image" && "Image Ad Item"}
                        {campaignType?.type == "html5" && "HTML5 Media Ad Item"}
                        {campaignType?.type == "Custom_HTML" && "Custom HTML / 3rd Party Tags"}
                        {campaignType?.type == "Native" && "Native Ad Item"}
                        {campaignType?.type == "Native_(Styled)" && "Native Ad Item"}
                    </Typography>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={3}>
                            {/* name */}
                            <Grid item xs={12} lg={12} md={12} sm={12}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="adItemName"
                                    label="Name"
                                    name="adItemName"
                                    inputRef={register({ required: true })}
                                    error={errors.adItemName?.type === "required"}
                                />
                                {errors.adItemName?.type === "required" && (
                                    <span style={{ color: "#f44337" }}>This field is required</span>
                                )}
                            </Grid>

                            {/* destination url */}
                            <Grid item xs={12} lg={12} md={12} sm={12}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="destinationUrl"
                                    label="Destination URL"
                                    name="destinationUrl"
                                    placeholder="https://"
                                    inputRef={register()}
                                />
                            </Grid>

                            {/* Upload Archive */}
                            {campaignType?.type === "html5" && (
                                <Grid item xs={12} lg={12} md={12} sm={12}>
                                    <FormLabel>Upload Archive</FormLabel>
                                    <TextField
                                    type="file"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        id="upload_archive"
                                        name="upload_archive"
                                        inputRef={register("upload_archive",{ required: true })}
                                        onChange={(e) => {
                                            console.log(e.target.files)
                                            setValue("upload_archive", e.target.files[0])
                                        }}
                                        error={errors.upload_archive?.type === "required"}
                                    />
                                    {errors.upload_archive?.type === "required" && (
                                        <span style={{ color: "#f44337" }}>This field is required</span>
                                    )}
                                </Grid>
                            )}

                            {/* image source */}
                            {campaignType?.type === "image" && (
                                <Grid item xs={12} lg={12} md={12} sm={12}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Image Source</FormLabel>
                                        <RadioGroup
                                            aria-label="img_source"
                                            onChange={(e) => handleFormFieldsChanges("img_source_radio", e)}
                                            value={formFields.img_source_radio}
                                        >
                                            <Grid direction="row">
                                                <FormControlLabel value="URL" control={<Radio />} label="URL" />
                                                {/* <FormControlLabel value="Library" control={<Radio />} label="Library" /> */}
                                            </Grid>
                                        </RadioGroup>
                                    </FormControl>
                                    {formFields.img_source_radio == "URL" && (
                                        <Grid item xs={12} lg={12} md={12} sm={12}>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="img_source_url"
                                                name="img_source_url"
                                                inputRef={register({ required: true })}
                                                error={errors.img_source_url?.type === "required"}
                                                placeholder="https://"
                                            />
                                            {errors.img_source_url?.type === "required" && (
                                                <span style={{ color: "#f44337" }}>This field is required</span>
                                            )}
                                        </Grid>
                                    )}
                                    {/* {formFields.img_source_radio == "Library" && (
                                        <Grid item xs={12} lg={12} md={12} sm={12} style={{marginTop:"16px"}}>
                                            <FormControl
                                                style={{ width: "100%" }}
                                            >
                                                <InputLabel>Select a creative</InputLabel>
                                                <NativeSelect variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    name="img_source_library"
                                                    label="img_source_library"
                                                    {...register("img_source_library", { required: true })}
                                                    error={errors.img_source_library?.type === "required"}
                                                    onChange={(e) => setValue("img_source_library", e.target.value)}>
                                                        <optgroup label="Brand Advance">
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                        </optgroup>
                                                </NativeSelect>
                                            </FormControl>

                                            {errors.img_source_library?.type === "required" && (
                                                <span style={{ color: "#f44337" }}>This field is required</span>
                                            )}
                                        </Grid>
                                    )} */}
                                </Grid>
                            )}

                            {/* Raw HTML/Script  */}
                            {campaignType?.type === "Custom_HTML" && (
                                <Grid item xs={12} lg={12} md={12} sm={12}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Raw HTML/Script (3rd Party Ad Code)</FormLabel>
                                    </FormControl>
                                    <Grid>
                                        <TextareaAutosize
                                            style={{ height: "100px", width: "100%", padding: "18.5px 14px" }}
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            id="raw_html_script"
                                            label="Raw HTML/Script"
                                            placeholder="(3rd Party Ad Code)"
                                            name="raw_html_script"
                                            value={formFields.raw_html_script}
                                            onChange={(e) => {
                                                e.persist()
                                                setFormFields(old => ({...old, [e.target.name]: e.target.value}))
                                            }}
                                            className="MuiFormControl-marginNormal"
                                        />
                                        {formFieldsError.raw_html_script && (
                                            <span style={{ color: "#f44337" }}>This field is required</span>
                                        )}
                                    </Grid>
                                </Grid>
                            )}

                            {/* size */}
                            {(campaignType?.type == "image" ||
                                campaignType?.type == "html5" ||
                                campaignType?.type == "Custom_HTML" ||
                                campaignType?.type == "Native") && (
                                <Grid item xs={12} lg={12} md={12} sm={12}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Size</FormLabel>
                                        <RadioGroup
                                            aria-label="size"
                                            id={"size"}
                                            value={formFields.size}
                                            onChange={(e) => {
                                                e.persist()
                                                setValue("size_custom_height", 0)
                                                setValue("size_custom_width", 0)
                                                handleFormFieldsChanges("size", e)
                                            }}
                                        >
                                            <Grid direction="row">
                                                {/* <FormControlLabel
                                                    value="preset_size"
                                                    control={<Radio />}
                                                    label="Preset Size"
                                                /> */}
                                                <FormControlLabel
                                                    value="custom_size"
                                                    control={<Radio />}
                                                    label="Custom Size"
                                                />
                                                <FormControlLabel
                                                    value="match_image_source"
                                                    control={<Radio />}
                                                    label="Match Image Source"
                                                />
                                            </Grid>
                                        </RadioGroup>
                                    </FormControl>
                                    {/* preset_size input */}
                                    {/* {formFields.size == "preset_size" && (
                                        <Grid item xs={12} lg={12} md={12} sm={12} style={{marginTop:"16px"}}>
                                            <FormControl
                                                style={{ width: "100%" }}
                                            >
                                                <InputLabel>Select preset</InputLabel>
                                                <NativeSelect variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    name="size_preset"
                                                    label="size_preset"
                                                    {...register("size_preset", { required: true })}
                                                    error={errors.size_preset?.type === "required"}
                                                    onChange={(e) => setValue("size_preset", e.target.value)}>
                                                        <optgroup label="Brand Advance">
                                                            <option value="1">1</option>
                                                            <option value="2" >2</option>
                                                            <option value="3">3</option>
                                                        </optgroup>
                                                        <optgroup label="tEST">
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                        </optgroup>
                                                </NativeSelect>
                                            </FormControl>

                                            {errors?.size_preset?.type === "required" && (
                                                <span style={{ color: "#f44337" }}>This field is required</span>
                                            )}
                                        </Grid>
                                    )} */}
                                    {formFields.size == "custom_size" && (
                                        <Grid item>
                                            {/* <FormControl style={{ display: "flex" }} fullWidth> */}
                                            <Grid item xs={12} lg={12} md={12} sm={12} style={{ display: "flex" }}>
                                                <Grid item xs={5} lg={5} md={5} sm={5}>
                                                    <TextField
                                                        type="number"
                                                        variant="outlined"
                                                        margin="normal"
                                                        label="width (px)"
                                                        defaultValue={0}
                                                        {...register("size_custom_width", { required: false })}
                                                        onChange={(e) => setValue("size_custom_width", e.target.value)}
                                                        error={errors.size_custom_width?.type === "required"}
                                                    />
                                                    {errors.size_custom_width?.type === "required" && (
                                                        <div>
                                                            <span style={{ color: "#f44337" }}>
                                                                This field is required
                                                            </span>
                                                        </div>
                                                    )}
                                                </Grid>
                                                <Grid item xs={5} lg={5} md={5} sm={5}>
                                                    <TextField
                                                        type="number"
                                                        variant="outlined"
                                                        margin="normal"
                                                        label="height (px)"
                                                        defaultValue={0}
                                                        {...register("size_custom_height", { required: false })}
                                                        onChange={(e) => setValue("size_custom_height", e.target.value)}
                                                        error={errors.size_custom_height?.type === "required"}
                                                    />
                                                    {errors.size_custom_height?.type === "required" && (
                                                        <div>
                                                            <span style={{ color: "#f44337" }}>
                                                                This field is required
                                                            </span>
                                                        </div>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            {/* </FormControl> */}
                                        </Grid>
                                    )}
                                    {formFields.size == "match_image_source" && (
                                        <div>
                                            Set the ad item dimensions to match the dimensions of the image source.
                                        </div>
                                    )}
                                </Grid>
                            )}

                            {/* target window */}
                            {campaignType?.type === "image" && (
                                <Grid item xs={12} lg={12} md={12} sm={12}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Target Window</FormLabel>
                                        <RadioGroup
                                            aria-label="Target window"
                                            id={"target_window"}
                                            onChange={(e) => handleFormFieldsChanges("target_window", e)}
                                        >
                                            <Grid direction="row">
                                                <FormControlLabel value="_blank" control={<Radio />} label="New" />
                                                <FormControlLabel value="_self" control={<Radio />} label="Same" />
                                                <FormControlLabel value="Other" control={<Radio />} label="Other" />
                                            </Grid>
                                        </RadioGroup>
                                    </FormControl>
                                    {formFields.target_window == "Other" && (
                                        <Grid item xs={12} lg={12} md={12} sm={12}>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="target_window__other"
                                                name="target_window__other"
                                                label="window"
                                                inputRef={register()}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            )}

                            {/* alternate text */}
                            {campaignType?.type === "image" && (
                                <Grid item xs={12} lg={12} md={12} sm={12}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        id="alternate_text"
                                        label="Alternate Text"
                                        name="alternate_text"
                                        inputRef={register()}
                                    />
                                </Grid>
                            )}

                            {/* tracking pixel */}
                            <TrackingPixel
                                tracking_pixels={formFields?.tracking_pixels}
                                setFormFields={setFormFields}
                                tracking_pixel_errors={tracking_pixel_errors}
                                setTrackingPixelErrors={setTrackingPixelErrors}
                            />
    
                            {/* {campaignType?.type === "Custom_HTML" && ( */}
                                {/* // <Grid item xs={12} lg={12} md={12} sm={12}> */}
                                    {/* <FormControl component="fieldset">
                                        <FormLabel component="legend">Backup Image</FormLabel>
                                        <RadioGroup
                                            aria-label="Backup Image"
                                            id={"backup_image"}
                                            value={formFields?.backup_image || ""}
                                            onChange={(e) => handleFormFieldsChanges("backup_image", e)}
                                        >
                                            <Grid direction="row">
                                                <FormControlLabel value="url" control={<Radio />} label="URL" />
                                                <FormControlLabel value="library" control={<Radio />} label="Library" />
                                            </Grid>
                                        </RadioGroup>
                                    </FormControl> */}
                                    {/* preset_size input */}
                                    {/* {formFields?.backup_image == "url" && (
                                        <Grid item xs={12} lg={12} md={12} sm={12}>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="img_source_url"
                                                label=""
                                                name="img_source_url"
                                                inputRef={register()}
                                                placeholder="https://"
                                            />
                                        </Grid>
                                    )} */}
                                {/* </Grid> */}
                            {/* )} */}

                            {/* accompanied html */}
                            {campaignType?.type === "image" && (
                                <Grid item xs={12} lg={12} md={12} sm={12}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Accompanied HTML</FormLabel>
                                    </FormControl>
                                    <Grid>
                                        <TextareaAutosize
                                            style={{ height: "100px", width: "100%", padding: "18.5px 14px" }}
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            inputRef={register("Accompanied_HTML", { required: false })}
                                            onChange={(e) => setValue("Accompanied_HTML", e.target.value)}
                                            className="MuiFormControl-marginNormal"
                                        />
                                    </Grid>
                                </Grid>
                            )}

                            {/* metadata */}
                            <MetaDataSection setFormFields={setFormFields} metaDatas={formFields.metaDatas} />

                            {/* html ad template */}
                            {(campaignType?.type === "Native" || campaignType?.type === "Native_(Styled)") && (
                                <Grid item xs={12} lg={12} md={12} sm={12}>
                                    <Typography style={{ marginBottom: "15px", marginTop: "15px", fontWeight: "bold" }}>
                                        HTML Ad Template
                                    </Typography>
                                    <Grid item xs={12} lg={12} md={12} sm={12}>
                                        <FormControl component="fieldset" variant="outlined" style={{ width: "100%" }}>
                                            <InputLabel>Template</InputLabel>
                                            <Select
                                                id="native_html_template"
                                                label="native_html_template"
                                                name="native_html_template"
                                                fullWidth
                                                {...register("native_html_template", { required: true })}
                                                onChange={(e) => setValue("native_html_template", e.target.value)}
                                            >
                                                <MenuItem value={"1"}>1</MenuItem>
                                                <MenuItem value="2">2</MenuItem>
                                                <MenuItem value="3">3</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid style={{ marginTop: "8px" }}>
                                        {errors.native_html_template?.type === "required" && (
                                            <span style={{ color: "#f44337" }}>This field is required</span>
                                        )}
                                    </Grid>
                                </Grid>
                            )}

                            {/* Weighting accordion */}
                            <Weighting setFormFields={setFormFields} static_weight={formFields.static_weight} />
                        </Grid>
                        <CardActions style={{ float: "right" }}>
                            <Button
                                variant="contained"
                                color="ternary"
                                onClick={() => {
                                    setPopAddCampaign(true);
                                    setPopParticularCampaign(false);
                                    resetAllFieldsAndErrors()
                                }}
                            >
                                Back
                            </Button>
                            <Button type="submit" variant="contained" color="primary">
                                Create Ad Item
                            </Button>
                        </CardActions>
                        {isCreatingAdItem && <LoaderCircle/>}
                    </form>
                </CardContent>
            </Card>
        </Modal>
        <ToastMessage msg={adItemCreationError} close={() => setAdItemCreationError("")} isSuccess={false}/>
        <ToastMessage msg={formFieldsError.height_width_zero_error && "Height and width value must be greater than zero."} close={() => setFormFieldsError(errors => ({...errors, height_width_zero_error: false}))} isSuccess={false}/>
        </>

    );
};

export default AddModal;

function Weighting({ static_weight, setFormFields }) {
    const classes = useStyles();
    return (
        <>
            <Accordion style={{ boxShadow: "none", display: "block", width: "100%", marginBottom: "20px" }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ border: "none" }}
                >
                    <Typography
                        className={classes.heading}
                        style={{ marginBottom: "15px", marginTop: "15px", fontWeight: "bold" }}
                    >
                        Weighting
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TextField
                        type="number"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="Static Weight"
                        name="static_weight"
                        value={static_weight}
                        onChange={(e) => {
                            e.persist();
                            setFormFields((old_val) => ({ ...old_val, static_weight: e.target.value }));
                        }}
                    />
                </AccordionDetails>
            </Accordion>
        </>
    );
}
