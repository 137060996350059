export const digitalALLOTHERMediaPlan = {
  format: null,
  content: null,
  KPI: null,
  KPI_Benchmark: null,
  buyType: null,
  unitCost: null,
  frequency: null,
  estimatedImpression: null,
  estimatedClicks: null,
};
export const digitalVideoMediaPlan = {
  format: "",
  content: "",
  KPI: null,
  KPI_Benchmark: null,
  TargetVTR: null,
  TargetCTR: null,
  buyType: null,
  unitCost: null,
  frequency: "",
  estimatedImpression: null,
  completeView: null,
  estimatedClicks: null,
};
export const oohMediaPlan = {
  media: "",
  product: null,
  channel: "",
  noOfPanels: null,
  unitCost: null,
  method: null,
  impactReachEstimated: null, //taken from mediaowner
  NetGross: null,
  Quantity: null,
};
export const printMediaPlan = {
  circulation: null,
  readership: null,
  formatSize: "",
  height: null,
  width: null,
  volumeTotalcms: null,
  colour: "",
  position: null,
  noofInsertions: null,
  insertionDate: new Date(),
  netMediaCost: null,
  unitCost: null,
};
export const radioMediaPlan = {
  adType: "",
  channel: "",
  adUnit: "",
  unitCost: null,
  method: "",
  listenerss: null,
  NetGross: null,
  Quantity: null,
};
export const televisionMediaPlan = {
  adType: "",
  channel: "",
  adUnit: "",
  unitCost: null,
  method: null,
  viewership: null,
  netGross: null,
  quantity: null,
};
