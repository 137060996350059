import { post, get } from './axios';
const relativeUrl = '/personas';

export const createPersona = async (data) => {
	return await post(relativeUrl, data);
};

export const getPersonas = async (userId) => {
	// return await get(relativeUrl);
	return await get(`/personasByUserId/${userId}`);
	
};
