import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { fade, makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";

//Text field
import TextField from "@material-ui/core/TextField";
import Pagination from "@material-ui/lab/Pagination";
import MyTable from "./mytable";

// Select
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

//Textarea
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

//Button
import Button from "@material-ui/core/Button";
import { useSelector } from "react-redux";

import Checkbox from "@material-ui/core/Checkbox";

import axios from "axios";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import NewAlert from "../../components/NewAlert";
import Modal from "../../components/modal";
import { API } from "../../constants/index";
import MainLayout from "../../layouts/newMainLayout";
import { getCsvDropdown } from "../../redux/actions/admin";
import { postMediaSentiment } from "../../redux/actions/media";
import * as actions from "../../redux/actions/user";
import StepWrapper from "./stepWrapper";

import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import AssignmentIcon from "@material-ui/icons/Assignment";

// import culturalIntell from "../../assets/Cultural-Intelligence.svg";
import { setMonth, setYear } from "date-fns";
import sentmentLogo from "../../assets/branduplift.png";
import useStyles from "../../components/StyleClasses";

import { SECRET_KEY } from "./../../constants/index";

const BrandMetrics = ({ onDone }) => {
  const initialData = {
    _id: "0",
    awareness: 0,
    consideration: 0,
    preference: 0,
    actionIntent: 0,
    adImpressions: 0,
    averageFrequency: 0,
    desktop_users: 0,
    phone_users: 0,
    tablet_users: 0,
    uniqueBrowsers: 0,
    brandUplift: 0,
  };

  const [ListDATA, setListDATA] = useState([]);
  const [ListtableData, setListTableData] = useState([]);
  const [Listpage, setListpage] = useState(1);
  const [Listpagecount, setListPageCount] = useState(0);
  const [tableLoading, setTableloading] = useState(false);

  const [DATA, setDATA] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [page, setpage] = useState(1);
  const [pagecount, setPageCount] = useState(0);
  const [tableheads, settableheads] = useState([]);

  const [demographic, setDemographic] = useState(0);
  const [industry, setIndustry] = useState(0);
  const [BrandList, setBrandList] = useState([]);
  const [brand, setbrand] = useState(0);
  const [country, setCountry] = useState(0);
  const [budget, setBudget] = useState(0);
  const [metricData, setMetricData] = useState(initialData);
  const [isUpdate, setIsUpdate] = useState(false);

  const [loading, setLoading] = useState(false);
  const [Spinner, setSpinner] = useState(false);

  const dispatch = useDispatch();

  ///////errors & successs //////////
  const [ErrSucMsg, setErrSucMesg] = useState("");
  const [ErrSucType, setErrSucType] = useState(true);
  ///////////////////////////////////

  const currentUser = useSelector((state) => state.loggedInSession);
  const dropdownData = useSelector((state) => state.adminDropDownData);
  const classes = useStyles();
  const access_token = window.localStorage.getItem(SECRET_KEY);

  const SendList = () => {
    const body = { ...metricData };
    delete body._id;
    body.demographic = demographic;
    body.industry = industry;
    //body.budget = budget;
    body.country = country;
    if (brand !== 0) body.brand = brand;

    axios
      .post(`${API}/admin/intelligence/BrandMetric`, body, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        refresh();

        if (err)
          if (res) {
            setErrSucMesg(res.data.message);
            setErrSucType(true);
          }
      })
      .catch(function (error) {
        if (error) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
        }
      });
  };

  ////////////////lists/////////////////////////
  const Listrefresh = () => {
    setTableloading(true);
    let URLL = `${API}/admin/intelligence/brandMetrics?`;
    URLL += `&page=${Listpage}`;
    axios
      .get(URLL, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (err) {
          setTableloading(false);
        } else {
          setListDATA(res.data.data);
          setListPageCount(res.data.total);
          setTableloading(false);
        }
      })
      .catch(function (error) {
        if (error) {
          setTableloading(false);
        }
      });
  };
  useEffect(() => {
    Listrefresh();
  }, [Listpage]);
  useEffect(() => {
    setListpage(1);
  }, []);
  useEffect(() => {
    if (ListDATA) {
      let td = [];
      ListDATA.forEach((e) => {
        let KeysToShow = [];
        if (e.demographic) {
          KeysToShow.splice(0, 0, e.demographic.name);
        } else {
          KeysToShow.splice(0, 0, "NA");
        }
        if (e.country) {
          KeysToShow.splice(1, 0, e.country.name);
        } else {
          KeysToShow.splice(1, 0, "NA");
        }
        if (e.industry) {
          KeysToShow.splice(1, 0, e.industry.name);
        } else {
          KeysToShow.splice(1, 0, "NA");
        }
        if (e.brand) {
          KeysToShow.splice(3, 0, e.brand.name);
        } else {
          KeysToShow.splice(3, 0, "NA");
        }
        td.push({
          data: KeysToShow,
          _id: e._id,
        });
      });
      setListTableData(td);
    }
  }, [ListDATA]);
  //////////////////////////////////////////////

  useEffect(() => {
    if (DATA) {
      let td = [];
      let th;
      if (DATA[0]) th = Object.keys(DATA[0]);
      th = th?.splice(0, 1);

      DATA.forEach((e) => {
        delete e._id;
        // delete e.brand;
        // delete e.industry;
        // delete e.ageGroup;
        let KeysToShow = Object.values(e);

        //KeysToShow.splice(0, 1);
        //KeysToShow.splice(KeysToShow.length - 2, 1);

        td.push({
          data: KeysToShow,
          _id: e._id,
        });
      });
      settableheads(th);
      setTableData(td);
    }
  }, [DATA]);

  const refresh = () => {
    if (
      demographic != 0 &&
      industry != 0 &&
      //budget != 0 &&
      country != 0
    ) {
      setIsUpdate(false);
      setLoading(true);
      let URLL = `${API}/admin/intelligence/BrandMetric?demographic=${demographic}&industry=${industry}&country=${country}`;
      // if (demographic !== 0) URLL += `demographic=${demographic}`;
      // if (industry !== 0) URLL += `&industry=${industry}`;
      // if (country !== 0) URLL += `&country=${country}`;
      if (brand != 0) URLL += `&brand=${brand}`;

      axios
        .get(URLL, {
          headers: { Authorization: `Bearer ${access_token}` },
        })
        .then((res, err) => {
          setLoading(false);
          if (err) {
          } else {
            if (Object.keys(res.data).length !== 0) {
              /// change condition later when res.data comes as empty array
              // setMetricData(res.data);
              // setIsUpdate(true);
              setDATA(res.data);
              setPageCount(res.data.total);
            } else {
              // setIsUpdate(false);
              // setMetricData(initialData);
            }
          }
        })
        .catch(function (error) {
          if (error) {
          }
        });
    }
  };
  const DeleteItem = () => {};

  useEffect(() => {
    refresh();
  }, [demographic, industry, country, brand]);

  const getBrands = () => {
    if (industry === 0) return;
    setBrandList([]);
    setSpinner(true);
    axios
      .get(`${API}/admin/brands?industry=${industry}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setBrandList(res.data);
          setSpinner(false);
        }
        if (err) {
          setSpinner(false);
        }
      })
      .catch(function (error) {
        if (error) {
          setSpinner(false);
        }
      });
  };
  useEffect(() => {
    getBrands();
  }, [industry]);

  return (
    <div className={classes.boxShadow}>
      <div className={classes.paper}>
        <NewAlert
          msg={ErrSucMsg}
          isSuccess={ErrSucType}
          close={() => setErrSucMesg("")}
        />
        <form
          className={classes.form}
          // ref={useRef()  }
          //onSubmit={handleSubmit(onSubmit)}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              fontSize: "10px",
              justifyContent: "space-around",
            }}
          >
            <img style={{ width: "17%", height: "40%" }} src={sentmentLogo} />

            <div style={{ width: "17%" }}>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
                style={{ marginTop: "10px" }}
              >
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Demographic"
                  value={demographic}
                  onChange={(e) => setDemographic(e.target.value)}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {dropdownData?.demographics?.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </FormControl>
            </div>

            {/* <div style={{ width: "17%" }}>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
                style={{ marginTop: "10px" }}
              >
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Budget"
                  value={budget}
                  onChange={(e) => setBudget(e.target.value)}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {dropdownData?.budget?.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </TextField>
              </FormControl>
            </div> */}
            <div style={{ width: "17%" }}>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
                style={{ marginTop: "10px" }}
              >
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Country"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {dropdownData?.countries?.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </FormControl>
            </div>
            <div style={{ width: "17%" }}>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
                style={{ marginTop: "10px" }}
              >
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Industry"
                  value={industry}
                  onChange={(e) => setIndustry(e.target.value)}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {dropdownData?.industries?.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </FormControl>
            </div>
            <div style={{ width: "17%" }}>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
                style={{ marginTop: "10px" }}
              >
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Brand"
                  value={brand}
                  onChange={(e) => setbrand(e.target.value)}
                  SelectProps={{
                    native: true,
                  }}
                  InputProps={
                    Spinner
                      ? {
                          startAdornment: (
                            <InputAdornment position="start">
                              <CircularProgress color="disabled" size={10} />
                            </InputAdornment>
                          ),
                        }
                      : {}
                  }
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {BrandList?.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </FormControl>
            </div>
          </div>
          <div>
            {loading ? (
              <CircularProgress />
            ) : (
              <div>
                {" "}
                <h3>Metrics Percentage</h3>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <FormControl
                    fullWidth
                    component="fieldset"
                    variant="outlined"
                    className={[
                      classes.selectControl,
                      classes.customfield,
                    ].join(" ")}
                    style={{ marginTop: "10px", width: "20%" }}
                  >
                    <TextField
                      type="number"
                      id="outlined-select-currency-native"
                      label="Awareness"
                      value={metricData.awareness}
                      onChange={(e) =>
                        setMetricData({
                          ...metricData,
                          awareness: e.target.value,
                        })
                      }
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                    ></TextField>
                  </FormControl>
                  <FormControl
                    fullWidth
                    component="fieldset"
                    variant="outlined"
                    className={[
                      classes.selectControl,
                      classes.customfield,
                    ].join(" ")}
                    style={{ marginTop: "10px", width: "20%" }}
                  >
                    <TextField
                      type="number"
                      id="outlined-select-currency-native"
                      label="Consideration"
                      value={metricData.consideration}
                      onChange={(e) =>
                        setMetricData({
                          ...metricData,
                          consideration: e.target.value,
                        })
                      }
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                    ></TextField>
                  </FormControl>
                  <FormControl
                    fullWidth
                    component="fieldset"
                    variant="outlined"
                    className={[
                      classes.selectControl,
                      classes.customfield,
                    ].join(" ")}
                    style={{ marginTop: "10px", width: "20%" }}
                  >
                    <TextField
                      type="number"
                      id="outlined-select-currency-native"
                      label="Preference"
                      value={metricData.preference}
                      onChange={(e) =>
                        setMetricData({
                          ...metricData,
                          preference: e.target.value,
                        })
                      }
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                    ></TextField>
                  </FormControl>
                  <FormControl
                    fullWidth
                    component="fieldset"
                    variant="outlined"
                    className={[
                      classes.selectControl,
                      classes.customfield,
                    ].join(" ")}
                    style={{ marginTop: "10px", width: "20%" }}
                  >
                    <TextField
                      type="number"
                      id="outlined-select-currency-native"
                      label="Action Intent"
                      value={metricData.actionIntent}
                      onChange={(e) =>
                        setMetricData({
                          ...metricData,
                          actionIntent: e.target.value,
                        })
                      }
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                    ></TextField>
                  </FormControl>
                </div>
                <hr></hr>
                <h3>Users Percentage</h3>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <FormControl
                    fullWidth
                    component="fieldset"
                    variant="outlined"
                    className={[
                      classes.selectControl,
                      classes.customfield,
                    ].join(" ")}
                    style={{ marginTop: "10px", width: "20%" }}
                  >
                    <TextField
                      type="number"
                      id="outlined-select-currency-native"
                      label="Desktop Users"
                      value={metricData.desktop_users}
                      onChange={(e) =>
                        setMetricData({
                          ...metricData,
                          desktop_users: e.target.value,
                        })
                      }
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                    ></TextField>
                  </FormControl>
                  <FormControl
                    fullWidth
                    component="fieldset"
                    variant="outlined"
                    className={[
                      classes.selectControl,
                      classes.customfield,
                    ].join(" ")}
                    style={{ marginTop: "10px", width: "20%" }}
                  >
                    <TextField
                      type="number"
                      id="outlined-select-currency-native"
                      label="Phone Users"
                      value={metricData.phone_users}
                      onChange={(e) =>
                        setMetricData({
                          ...metricData,
                          phone_users: e.target.value,
                        })
                      }
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                    ></TextField>
                  </FormControl>
                  <FormControl
                    fullWidth
                    component="fieldset"
                    variant="outlined"
                    className={[
                      classes.selectControl,
                      classes.customfield,
                    ].join(" ")}
                    style={{ marginTop: "10px", width: "20%" }}
                  >
                    <TextField
                      type="number"
                      id="outlined-select-currency-native"
                      label="Tablet Users"
                      value={metricData.tablet_users}
                      onChange={(e) =>
                        setMetricData({
                          ...metricData,
                          tablet_users: e.target.value,
                        })
                      }
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                    ></TextField>
                  </FormControl>
                </div>
                <hr></hr>
                <h3>Questions</h3>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <FormControl
                    fullWidth
                    component="fieldset"
                    variant="outlined"
                    className={[
                      classes.selectControl,
                      classes.customfield,
                    ].join(" ")}
                    style={{ marginTop: "10px", width: "20%" }}
                  >
                    <TextField
                      type="number"
                      id="outlined-select-currency-native"
                      label="Ad Impressions"
                      value={metricData.adImpressions}
                      onChange={(e) =>
                        setMetricData({
                          ...metricData,
                          adImpressions: e.target.value,
                        })
                      }
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                    ></TextField>
                  </FormControl>
                  <FormControl
                    fullWidth
                    component="fieldset"
                    variant="outlined"
                    className={[
                      classes.selectControl,
                      classes.customfield,
                    ].join(" ")}
                    style={{ marginTop: "10px", width: "20%" }}
                  >
                    <TextField
                      type="number"
                      id="outlined-select-currency-native"
                      label="Average Frequency"
                      value={metricData.averageFrequency}
                      onChange={(e) =>
                        setMetricData({
                          ...metricData,
                          averageFrequency: e.target.value,
                        })
                      }
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                    ></TextField>
                  </FormControl>
                  <FormControl
                    fullWidth
                    component="fieldset"
                    variant="outlined"
                    className={[
                      classes.selectControl,
                      classes.customfield,
                    ].join(" ")}
                    style={{ marginTop: "10px", width: "20%" }}
                  >
                    <TextField
                      type="number"
                      id="outlined-select-currency-native"
                      label="Unique Browsers"
                      value={metricData.uniqueBrowsers}
                      onChange={(e) =>
                        setMetricData({
                          ...metricData,
                          uniqueBrowsers: e.target.value,
                        })
                      }
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                    ></TextField>
                  </FormControl>
                  <FormControl
                    fullWidth
                    component="fieldset"
                    variant="outlined"
                    className={[
                      classes.selectControl,
                      classes.customfield,
                    ].join(" ")}
                    style={{ marginTop: "10px", width: "20%" }}
                  >
                    <TextField
                      type="number"
                      id="outlined-select-currency-native"
                      label="Brand Uplift"
                      value={metricData.brandUplift}
                      onChange={(e) =>
                        setMetricData({
                          ...metricData,
                          brandUplift: e.target.value,
                        })
                      }
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                    ></TextField>
                  </FormControl>
                </div>
              </div>
            )}
          </div>
          {!loading &&
            demographic != 0 &&
            industry != 0 &&
            //brand != 0 &&
            country != 0 && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <FormControl>
                  <Button
                    className={classes.addActionBtn}
                    variant="outlined"
                    style={
                      isUpdate
                        ? { backgroundColor: "#E96B43" }
                        : { backgroundColor: "#136CC3" }
                    }
                    onClick={() => SendList()}
                  >
                    ADD
                  </Button>
                </FormControl>
              </div>
            )}
          <div
            style={{ maxWidth: "900px", maxHeight: "400px", marginTop: "20px" }}
          >
            {tableData.length > 0 && (
              <span>
                Record : {tableData.length > 0 ? "" : "No Data Added!"}
              </span>
            )}
            {tableData.length > 0 && (
              <MyTable
                style={{ width: "100%" }}
                isEditable={false}
                headings={tableheads}
                listdata={tableData}
                //redirectLink={"MediaOwnerProfile?id="}
                // handleDelete={(_id) => {
                //   DeleteItem(_id);
                // }}
              />
            )}
          </div>
          {tableData.length > 0 && (
            <div>
              <span
                style={{
                  color: "#FB6D6A",
                  fontWeight: "600",
                  margin: "0",
                  fontSize: "20px",
                  marginRight: "20px",
                }}
              >
                <Pagination
                  count={Math.ceil(pagecount / 10)}
                  color="primary"
                  onChange={(e, page) => setpage(page)}
                  size="small"
                />
              </span>
              {/* <Button
                className={classes.addActionBtn}
                variant="outlined"
                style={{
                  backgroundColor: "#136CC3",
                  margin: "15px",
                }}
                onClick={() => {
                  history.go(0);
                }}
              >
                REFRESH
              </Button> */}
            </div>
          )}
          <br />
        </form>
        {
          ////////////////////////////////////////////
        }
        <div>
          {tableLoading && <CircularProgress />}
          {ListtableData.length > 0 && !tableLoading && (
            <MyTable
              style={{ width: "100%" }}
              isEditable={false}
              headings={["Demographic", "Country", "Industry", "Brand"]}
              listdata={ListtableData}
              //redirectLink={"MediaOwnerProfile?id="}
              // handleDelete={(_id) => {
              //   DeleteQuestion(_id);
              // }}
            />
          )}

          <div>
            <span
              style={{
                color: "#FB6D6A",
                fontWeight: "600",
                margin: "0",
                fontSize: "20px",
                marginRight: "20px",
              }}
            >
              <Pagination
                count={Math.ceil(Listpagecount / 10)}
                color="primary"
                onChange={(e, page) => setListpage(page)}
                size="small"
              />
            </span>
          </div>
        </div>

        {
          /////////////////////////////////////
        }
      </div>
    </div>
  );
};

export default BrandMetrics;
