import { useCallback, useEffect, useRef, useState } from "react";
import { useAccessToken } from "../Hooks/useAccessToken";

export default function useFetch(url, onSuccess) {
  const c_url = useRef(url);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const access_token = useAccessToken();

  const refetch = useCallback(() => {
    if (!access_token) return;

    const controller = new AbortController();
    (async function () {
      try {
        setFetching(true);
        const res = await fetch(c_url.current, {
          signal: controller.signal,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        });
        const data = await res.json();
        if (data) setData(data);
        // onSuccess?.(data)
      } catch (err) {
        setError(err);
      } finally {
        setFetching(false);
      }
    })();
    return () => {
      controller.abort();
    };
  }, [])


  useEffect(() => {
    return refetch()
  }, []);

  return { data, fetching, error, refetch };
}
export function useLazyFetch() {
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const access_token = useAccessToken();

  const fetchData = async (url) => {
    let data;
    if (!access_token) return;

    try {
      setFetching(true);
      const res = await fetch(url, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      data = await res.json();
      if (data) setData(data);
    } catch (err) {
      setError(err);
    } finally {
      setFetching(false);
    }
    return data;
  };

  return { data, fetching, error, fetchData, clearData: () => setData(null) };
}
