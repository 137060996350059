import React, { useState, useEffect, useRef, createRef, useMemo } from "react";
import { makeStyles, fade, withTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

//Button
import Button from "@material-ui/core/Button";

//Icon
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ControlPointTwoToneIcon from "@material-ui/icons/ControlPointTwoTone";

//TextField
import TextField from "@material-ui/core/TextField";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import MainLayout from "../../layouts/newMainLayout";
import { useSelector, useDispatch } from "react-redux";
import { listMediaOwnerTitles } from "../../redux/actions/media-owner";
import Paper from "@material-ui/core/Paper";
import StarBorderRoundedIcon from "@material-ui/icons/StarBorderRounded";
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import IconButton from "@material-ui/core/IconButton";
import StepWrapper from "./stepWrapper";
import PersonPinIcon from "@material-ui/icons/PersonPin";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  marginTop: {
    marginTop: "16px",
  },
  nextButton: {
    marginLeft: "auto",
    backgroundColor: "#1d8bf7",
    color: "white",
    display: "block",
    padding: "5px 40px",
  },
  addActionBtn: {
    padding: "10px",
    backgroundColor: "#136cc3",
    color: "white",
    fontWeight: "bold",
    borderRadius: "23px",
    textTransform: "none",
    "&:hover": {
      background: "#136cc3",
    },
  },
  customfield: {
    paddingTop: "0",
    marginTop: "0",
    "& .MuiInputLabel-root.Mui-shrink": {
      color: "red",
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.5px 14px",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "0",

      "& fieldset": {
        borderColor: "grey",
      },
      "&:hover fieldset": {},
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "green",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "green",
    },
    "& .MuiInputLabel-root.MuiInputLabel-shrink": {
      margin: "0!important",
    },
    "& .MuiInputLabel-root.MuiInputLabel-animated": {
      marginTop: "-8px",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "green",
    },
  },
}));

const InventoryCreate = () => {
  const dispatch = useDispatch();
  const searchInput = useRef(null);
  const inputRef = useRef([]);
  const history = useHistory();
  const classes = useStyles();
  const [email, setEmail] = useState([]);
  const { control, register, handleSubmit, errors } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });
  const mediaOwnerTitles = useSelector((state) => state.mediaOwnerTitles);
  const mediaOwnerTitlesSuccessfull = useSelector(
    (state) => state.mediaOwnerTitlesSuccessfull
  );
  const [mediaOwnerTitlesList, setMediaOwnerTitlesList] = useState([]);
  const loggedInSession = useSelector((state) => state.loggedInSession);
  const [mediaOwnerSelected, setMediaOwnerSelected] = useState("");

  // console.info('loggedInSession: ', loggedInSession)
  // console.info('mediaOwnerTitles: ', mediaOwnerTitles)
  useEffect(() => {
    // console.info('mediaOwnerTitles: ', mediaOwnerTitles)
    console.info("mediaOwnerSelecteds 222: ", mediaOwnerSelected);
    if (!mediaOwnerTitlesSuccessfull && mediaOwnerTitles.length === 0) {
      dispatch(listMediaOwnerTitles("loggedInSession._id"));
    } else {
      // const a = mediaOwnerTitles.map(val => {
      //     val.selected = false
      //     return val;
      // })

      // console.info('aaaaaa: ', a)

      setMediaOwnerTitlesList(mediaOwnerTitles);
    }

    // if (mediaOwnerTitles && mediaOwnerTitles.length === 0) {
    //     if(loggedInSession) {
    //         dispatch(listMediaOwnerTitles('loggedInSession._id'))
    //     }

    // } else {
    //     mediaOwnerTitlesList(mediaOwnerTitles)
    // }
  }, [mediaOwnerTitles, loggedInSession, mediaOwnerTitlesList]);

  const createMedia = () => {
    history.push("/media-owner/about");
  };

  const toggleSelectPersona = (id) => {
    const getIds = [];
    let moSelected = null;
    const newcur = mediaOwnerTitles.map((val) => {
      if (val["_id"] === id) {
        val["selected"] = !val["selected"];
      } else {
        val["selected"] = false;
      }

      if (val["selected"] === true) {
        getIds.push(val);
        moSelected = val._id;
      }
      return val;
    });

    setMediaOwnerTitlesList(newcur);
    // setPersonaInventories(getIds)
    console.info("newcur: ", newcur);
    console.info("getIds: ", getIds);
    setMediaOwnerSelected(moSelected);
    console.info("moSelected: ", moSelected);
    console.info("mediaOwnerSelecteds: ", mediaOwnerSelected);

    // dispatch(createMediaOwner(mediaOwner));
    // console.info('mediaOwnermediaOwnermediaOwner: ', mediaOwner)
  };

  const handleClick = () => {
    console.info("handleClick: ");

    dispatch({
      type: "CREATE_INVENTORY_MEDIA_OWNER_SELECTED",
      createInventoryMediaOwnerSelected: mediaOwnerSelected,
    });

    history.push("/media-owner/inventory-select");
  };

  return (
    <div>
      <StepWrapper step={1} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item md={3} sm={3} xs={3}>
              <Button
                onClick={() => createMedia()}
                className={classes.addActionBtn}
                variant="outlined"
                color="primary"
              >
                <AddCircleOutlineIcon
                  style={{ marginRight: "10px", color: "#a2e60f" }}
                />
                Create Media
              </Button>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            {mediaOwnerTitlesList &&
              mediaOwnerTitlesList.map((mo, index) => {
                return (
                  <Grid key={index} item md={3} sm={3} xs={3}>
                    <Paper>
                      <div
                        style={{
                          textAlign: "center",
                          padding: "40px 0px",
                          position: "relative",
                        }}
                      >
                        <IconButton
                          style={{
                            position: "absolute",
                            top: "-10px",
                            right: "-10px",
                          }}
                          onClick={() => toggleSelectPersona(mo._id)}
                          aria-label="open drawer"
                        >
                          {mo.selected ? (
                            <StarRoundedIcon
                              style={{ fontSize: "1.5em", color: "#e96941" }}
                            />
                          ) : (
                            <StarBorderRoundedIcon
                              style={{
                                fontSize: "1.5em",
                                color: "rgb(233 231 238)",
                              }}
                            />
                          )}
                        </IconButton>
                        <div> {mo.title.name}</div>
                        <div>
                          {/* <PersonPinIcon style={{ color: mo.avatarColor, fontSize: '12em' }} /> */}
                          <img
                            style={{ maxWidth: "100px" }}
                            src="https://brand-advance-images.s3.eu-west-2.amazonaws.com/bec5a8dad82dfd83b14ac63e69dae93d.png"
                          />
                        </div>
                      </div>
                    </Paper>
                  </Grid>
                );
              })}
          </Grid>
          <div className={classes.marginTop}>
            <Button
              onClick={handleClick}
              className={classes.nextButton}
              type="submit"
            >
              Next
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default MainLayout(InventoryCreate);
