import React, { useState, useEffect } from "react";
import MainLayout from "../../layouts/adminMainLayout";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Pagination from "@material-ui/lab/Pagination";

import CircularProgress from "@material-ui/core/CircularProgress";
//Button
import Button from "@material-ui/core/Button";

//Icon
import TextField from "@material-ui/core/TextField";

import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { useHistory } from "react-router-dom";
import useStyles from "../../components/StyleClasses";
import CustomizedTables from "../mediaOwner/mytable";
import { API, SECRET_KEY } from "../../constants/index";
import axios from "axios";

const ShowMediaPlanners = () => {
  const classes = useStyles();
  const history = useHistory();
  const [Id, setId] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [DATA, setDATA] = useState(null);
  const [loading, setloading] = useState(false);
  const [page, setpage] = useState(1);
  const [search, setSearch] = useState("");
  const access_token = window.localStorage.getItem(SECRET_KEY);
  const [pagecount, setPageCount] = useState(0);

  const getPaybackList = () => {
    setloading(true);
    setTableData([]);
    axios
      .get(`${API}/admin/PaybackCampaigns`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setloading(false);
          setDATA(res.data.data);
          setPageCount(res.data.total);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };
  useEffect(() => getPaybackList(), []);
  useEffect(() => getPaybackList(), [page]);

  var myVar;

  function myFunction() {
    myVar = setTimeout(function () {
      getPaybackList();
    }, 200);
  }

  function myStopFunction() {
    clearTimeout(myVar);
  }
  useEffect(() => myFunction(), [search]);
  useEffect(() => {
    if (DATA) {
      let td = [];
      for (let i = (page - 1) * 10; i <= page * 10; i++) {
        if (i < DATA.length) {
          let e = DATA[i];
          td.push({
            data: [e.campaignId, e.createdAt],
            _id: e._id,
          });
        }
      }
      setTableData(td);
    }
  }, [DATA]);

  const AddId = () => {
    const body = {
      campaignId: Id,
    };
    axios
      .post(`${API}/admin/PaybackCampaigns`, body, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          getPaybackList();
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };
  const handleDelete = (id) => {
    axios
      .delete(`${API}/admin/PaybackCampaigns/${id}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          getPaybackList();
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };
  return (
    <div>
      <div className={classes.boxShadow} style={{ marginTop: "40px" }}>
        <div
          style={{
            marginBottom: "20px",
          }}
        >
          <span
            style={{
              color: "#FB6D6A",
              fontWeight: "600",
              margin: "0",
              fontSize: "20px",
              marginLeft: "20px",
              display: "block",
            }}
          >
            Payback Campaigns
          </span>
          <div>
            <FormControl
              fullWidth
              component="fieldset"
              variant="outlined"
              className={[classes.selectControl, classes.customfield].join(" ")}
              style={{ marginTop: "20px" }}
            >
              <TextField
                type="number"
                fullWidth
                id="paybackInput"
                label="Campaign ID"
                variant="outlined"
                style={{
                  margin: "0 5px 5px 5px",
                  width: "45%",
                }}
                value={Id}
                onChange={(e) => {
                  setId(e.target.value);
                }}
              />

              <button
                className={classes.addActionBtn}
                onClick={(e) => AddId(e.target.value)}
                style={{ width: "80px" }}
              >
                ADD
              </button>
            </FormControl>
          </div>
        </div>
        <span
          style={{
            color: "#FB6D6A",
            fontWeight: "600",
            margin: "0",
            fontSize: "20px",
            marginRight: "20px",
          }}
        >
          <Pagination
            count={Math.ceil(pagecount / 10)}
            color="primary"
            onChange={(e, page) => setpage(page)}
            size="small"
          />
        </span>
        <div>
          <CustomizedTables
            handleDelete={(id) => handleDelete(id)}
            headings={["Campaign Id", "Created At"]}
            listdata={tableData}
            redirectLink={"MediaPlannerProfile?id="}
          />
          <div style={{ display: "flex", justifyContent: "center" }}>
            {loading && <CircularProgress />}
          </div>
        </div>
      </div>
    </div>
  );
};
export default MainLayout(ShowMediaPlanners);
