import React, { useState, useEffect } from "react";
import MainLayout from "../../layouts/newMainLayout";
import { makeStyles, fade, withTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import * as actions from "../../redux/actions/user";
import ShowAlert from "../../components/alerts";

import CircularProgress from "@material-ui/core/CircularProgress";

//Button
import Button from "@material-ui/core/Button";

//Icon
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ControlPointTwoToneIcon from "@material-ui/icons/ControlPointTwoTone";

//TextField
import TextField from "@material-ui/core/TextField";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllMediaOwner,
  listMediaOwnerTitles,
} from "../../redux/actions/media-owner";
import Paper from "@material-ui/core/Paper";
import StarBorderRoundedIcon from "@material-ui/icons/StarBorderRounded";
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import IconButton from "@material-ui/core/IconButton";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import searchMediaOwners from "../../selectors/search";
import OtherStyles from "../../components/StyleClasses";
import CustomizedTables from "./mytable";
import EditIcon from "@material-ui/icons/Edit";
import Pagination from "@material-ui/lab/Pagination";
import axios from "axios";
import { API } from "../../constants/index";
import { SECRET_KEY } from "./../../constants/index";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  addActionBtn: {
    padding: "7px",
    paddingRight: "12px",
    paddingLeft: "12px",
    backgroundColor: "#136cc3",
    color: "white",
    fontWeight: "bold",
    borderRadius: "23px",
    textTransform: "none",
    "&:hover": {
      background: "#136cc3",
    },
  },
  searchInput: {
    backgroundColor: "white",
    border: "solid 2px #E5E5E5",
    color: "black",
    borderRadius: "23px",
    padding: "7px",
    "&:focus": {
      borderRadius: "23px",
    },
  },
  customfield: {
    paddingTop: "0",
    marginTop: "10",
    backgroundColor: "#f4f4f8",
    borderRadius: "5px",
    "& .MuiInputLabel-root.Mui-shrink": {
      color: "red",
    },
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&$focused": {
      backgroundColor: "#fff",
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.5px 14px",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",

      "& fieldset": {
        borderColor: "grey",
      },
      "&:hover fieldset": {},
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "grey",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "grey",
    },
    "& .MuiInputLabel-root.MuiInputLabel-shrink": {
      margin: "0!important",
    },
    "& .MuiInputLabel-root.MuiInputLabel-animated": {
      marginTop: "-8px",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",
    },
  },
}));

const MoPublications = () => {
  const [loading, setloading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch;
  const otherClasses = OtherStyles();
  const classes = useStyles();
  const access_token = window.localStorage.getItem(SECRET_KEY);

  const [mediaList, setMediaList] = useState([]);
  const [filteredList, setfilterdList] = useState([]);

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const UserName = window.localStorage.getItem("username");

  const MediaTab = ({ title, img, _id, timer }) => {
    const [show, setshow] = useState(false);
    setTimeout(() => {
      setshow(true);
    }, timer * 150);
    let fixedTitle = title;
    if (fixedTitle.length > 15) {
      fixedTitle = fixedTitle.substring(0, 15) + "...";
    }
    return (
      <div
        style={
          show
            ? {
                boxShadow:
                  "0px 0px 10px rgba(0,0,0,0.20), 0 0 10px rgba(0,0,0,0.15)",
                borderTopLeftRadius: "16px",
                borderTopRightRadius: "16px",
                marginRight: "30px",
                marginTop: "30px",
                transform: "scale(1)",
                transition: "all 0.15s",
              }
            : { transform: "scale(0)" }
        }
      >
        <div
          style={{
            padding: "7px",
            backgroundColor: "blue",
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
            color: "white",
            textAlign: "center",
            background: "linear-gradient(to right, #9211a2, #4911a2)",
          }}
        >
          <span style={{ display: "flex", justifyContent: "space-around" }}>
            {fixedTitle}{" "}
            <EditIcon
              fontSize="small"
              onClick={() => console.log("want to edit")}
            />
          </span>
        </div>
        <div
          style={{
            padding: "10px",
            height: "250",
            width: "150",
          }}
          onClick={() => history.push(`/media-owner/singleMediaView?id=${_id}`)}
        >
          {/* <img
            alt="media.Img"
            src={img}
            style={{
              boxShadow:
                "0px 0px 10px rgba(0,0,0,0.20), 0 0 10px rgba(0,0,0,0.15)",
              width: "140px",
              display: "block",
            }}
          ></img> */}
          <div
            style={{
              boxShadow:
                "0px 0px 10px rgba(0,0,0,0.20), 0 0 10px rgba(0,0,0,0.15)",
              width: "150px",
              height: "150px",
              display: "block",
              backgroundImage: `url(${img})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              position: "relative",
              cursor: "pointer",
            }}
            //onClick={() => setOpen(true)}
          ></div>
        </div>
      </div>
    );
  };

  const getMedia = () => {
    setloading(true);
    axios
      .get(`${API}/mediaOwner/mediaPublication?name=${search}&page=${page}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setMediaList(res.data.data);
          setPageCount(res.data.total);
        }
        if (err) {
        }
        setloading(false);
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };

  useEffect(() => {
    if (page !== 1) setPage(1);
    getMedia();
  }, [search]);
  useEffect(() => {
    getMedia();
  }, [page]);
  // useEffect(() => {
  //   setfilterdList(mediaList);
  // }, [mediaList]);

  return (
    <div>
      <ShowAlert />
      <h2 style={{ color: "#4F74C9", fontWeight: "400" }}>{UserName}</h2>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          onClick={() => history.push("/media-owner/createMedia")}
          className={classes.addActionBtn}
          variant="outlined"
          color="primary"
          style={{ width: "17%" }}
        >
          <AddCircleOutlineIcon
            style={{ marginRight: "5px", color: "white" }}
          />
          Create Media
        </Button>
        <div
          className={classes.addActionBtn}
          style={{
            backgroundColor: "white",
            paddingRight: "7px",
            paddingLeft: "7px",
            width: "82%",

            boxShadow:
              "0px 0px 10px rgba(0,0,0,0.20), 0 0 10px rgba(0,0,0,0.15)",
          }}
        >
          <input
            placeholder="Search Media"
            //className={classes.addActionBtn}
            style={{
              outline: "none",
              backgroundColor: "white",
              border: "solid 2px #E5E5E5",
              color: "black",
              borderRadius: "23px",
              padding: "7px",
              width: "50%",
            }}
            onChange={(event) => {
              setSearch(event.target.value);
            }}
          ></input>
        </div>
      </div>
      <div
        style={{
          padding: "16px",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.20), 0 0 10px rgba(0,0,0,0.15)",
          borderRadius: "30px",
          backgroundColor: "white",
          marginTop: "20px",
        }}
      >
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "15%",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <div>
            <span
              style={{
                color: "#FB6D6A",
                fontWeight: "600",
                margin: "0",
                fontSize: "20px",
                marginLeft: "20px",
              }}
            >
              Media
            </span>
            <div style={{ display: "flex", padding: "30px", flexWrap: "wrap" }}>
              {mediaList.map((e, i) => (
                <MediaTab
                  title={e.name}
                  img={e.mediaImage}
                  _id={e._id}
                  timer={i}
                />
              ))}
            </div>
            {pageCount > 0 && (
              <div
                style={{
                  display: "flex",
                  marginTop: "20px",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{
                    color: "#FB6D6A",
                    fontWeight: "600",
                    margin: "0",
                    fontSize: "20px",
                    marginRight: "20px",
                  }}
                >
                  <Pagination
                    page={page}
                    count={Math.ceil(pageCount / 10)}
                    color="primary"
                    onChange={(e, page) => setPage(page)}
                    size="small"
                  />
                </span>
              </div>
            )}
          </div>
        )}
      </div>
      {/* <div
        style={{
          padding: "16px",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.20), 0 0 10px rgba(0,0,0,0.15)",
          borderRadius: "30px",
          backgroundColor: "white",
          marginTop: "20px",
        }}
      >
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span
              style={{
                color: "#FB6D6A",
                fontWeight: "600",
                margin: "0",
                fontSize: "20px",
                marginLeft: "20px",
              }}
            >
              Campaigns
            </span>
            <span
              style={{
                color: "#FB6D6A",
                fontWeight: "600",
                margin: "0",
                fontSize: "20px",
                marginRight: "20px",
              }}
            >
              <Pagination
                count={10}
                color="primary"
                onChange={(e, page) => console.log(page)}
                size="small"
              />
            </span>
          </div>

          <div
            style={{
              display: "flex",
              padding: "30px",
              justifyContent: "center",
            }}
          >
            <CustomizedTables isEditable={false} />
          </div>
        </div>
      </div> */}
    </div>
  );
};
export default MainLayout(MoPublications);
