/* eslint-disable no-unused-expressions */
import { TextField } from "@material-ui/core";
import { Box, Card, CardContent, Grid } from "@material-ui/core/";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import CropSquareOutlined from "@material-ui/icons/CropSquareOutlined";
import MinimizeOutlined from "@material-ui/icons/MinimizeOutlined";
import axios from "axios";
import L from "leaflet";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useState } from "react";
import {
  Circle,
  CircleMarker,
  MapContainer,
  Marker,
  Popup,
  TileLayer,
} from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "../../../components/StyleClasses";
import { API, SECRET_KEY } from "../../../constants/index";
import { SaveAdminDropDownData } from "../../../store/actions/admin/intelligence";
import BlackDot from "./../../../assets/black.png";
import Exclamation from "./../../../assets/exclamation mark.svg";
import "./Dashboard.css";
import CountryData from "./countrys.json";
import "./heatmap.css";

import { getPreciseDistance } from "geolib";
import jwt_decode from "jwt-decode";

import { Label } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import useFetch, { useLazyFetch } from "../../../Hooks/useFetch";
import MarkerIcon from "../../../assets/marker-icon-colored.png";
import CustomDropdown from "../../../components/CustomDropdown";
import Selection from "../../../components/Select/Selection";
import CampaignDetails from "../heatMap/CampaignDetails";
import Subscription from "../subscription";

const provider = new OpenStreetMapProvider();

const useStyless = makeStyles((theme) => ({
  creativeWrapper: {
    "& .MuiOutlinedInput-input": {
      color: "#fff",
      border: "2px solid #000",
      padding: "5.5px 14px",
    },
  },
}));

const HeatMap = (props) => {
  const {onRemoveHeatMap, handleAddNewHeatMap, mediaOwnerList} = props
  const dispatch = useDispatch();
  const classes = useStyles();
  const classess = useStyless();
  const dropdownData = useSelector((state) => state.adminDropDownData);
  const access_token = window.localStorage.getItem(SECRET_KEY);
  const getSubscription = window.localStorage.getItem("subscription");
  const decodedSubscription = getSubscription && jwt_decode(getSubscription);
  const jcdArr = ["55", "black", "disability", "lgbt", "multi", "south"];
  const { viewWidgets, callbackViewWidget } = props;

  const [loading, setLoading] = useState(false);
  const [countryForMap, setCountryForMap] = useState("");
  const [hrsForMap, setHrsForMap] = useState(0);
  const [topicForMap, setTopicForMap] = useState([]);
  const [demographicForMap, setDemographicForMap] = useState([]);
  const [demographicName, setDemographicName] = useState([]);
  const [mapData, setMapData] = useState([]);
  const [latLogPosition, setLatLogPosition] = useState([40.76846, -73.95888]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [stateListData, setStateListData] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [isLocallyStored, setIsLocallyStored] = useState(false);
  const [categoryArr, setCategoryArr] = useState([]);
  const [mapDataArr, setMapDataArr] = useState([]);
  const [cloneMapDataArr, setCloneMapDataArr] = useState([]);
  const [resetSize, setResetSize] = useState(false);
  const [campaignIdInput, setCampaignIdInput] = useState("")
  const [lineItemIdInput, setLineItemIdInput] = useState("")

  let jcdData = [];
  let acmsData = [];
  let originalMapData = [];

  useEffect(() => {
    if(campaignIdInput && lineItemIdInput){
      
    }
  }, [campaignIdInput, lineItemIdInput])

  // Addmore
  const [addmore, setAddmore] = useState([
    {
      category: "",
      keyword: "",
      subCategory: "",
      categoryList: [],
      subCategoryList: [],
      keywordList: [],
      mapData: [],
      color: "",
      selectedFrames: [],
      selectedCampaign: "",
      selectedState: "",
    },
  ]);
  // DOOH screen
  const [doohScreenFlagData, setDoohScreenFlagsData] = useState([]);
  const [doohMapJCDData, setDoohMapJCDData] = useState([]);
  const [selectedDoohScreen, setSelectedDoohScreen] = useState([]);
  const doohScreenArray = [
    { _id: "ACMS", label: "ACMS" },
    { _id: "JCDecaux", label: "JCDecaux" },
    { _id: "clear_channel", label: "Clear Channel" },
  ];

  const [count, forceUpdate] = useState(0);
  const [containerMap, setContainerMap] = useState(null);
  const [keywordsForMap, setKeywordsForMap] = useState([]);
  const [garphDataClone, setGarphDataClone] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [demoNameAcms, setDemoNameAcms] = useState([]);
  const [demoNameJdc, setDemoNameJdc] = useState([]);
  const [frameIdList, setFrameIdList] = useState([]);
  const [deleteFlag, setDeleteFlag] = useState(false);

  const {data: campaignList, error} = useFetch(`${API}/mediaPlanner/hivestack/campaigns`)
  const {data: lineItems, error: lineItemsError, fetchData: fetchLineItems} = useLazyFetch()
  const {data: lineItemsDetails, error: lineItemsDetailsError, fetchData: fetchLineItemsDetails, clearData: clearLineItemDetails} = useLazyFetch()

  let pagination = {
    limit: 1000,
    totalCount: 0,
    page: 0,
    count: 0,
  };
  let jdcPagination = {
    limit: 1000,
    totalCount: 0,
    page: 0,
    count: 0,
  };
  let acmsPagination = {
    limit: 1000,
    totalCount: 0,
    page: 0,
    count: 0,
  };

  let tiles = L.tileLayer(
    "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    {
      maxZoom: 18,
      minZoom: 3,
      position: latLogPosition,
      attribution:
        '© <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    }
  );

  useEffect(() => {
    tiles = L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      maxZoom: 18,
      minZoom: 3,
      position: latLogPosition,
      attribution:
        '© <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    });
  }, [latLogPosition]);

  useEffect(() => {
    const localdata = localStorage.getItem("heatmapData");
    if (localdata) {
      // callbackViewWidget("min");
      setIsLocallyStored(true);
      let dt = JSON.parse(localdata);
      setHrsForMap(dt.hrsForMap);
      setDoohScreenFlagsData(dt.doohScreenFlagData);
      setDoohMapJCDData(dt.doohMapJCDData);
      setDemographicForMap(dt.demographicForMap);
      setCountryForMap(dt.countryForMap);
      setCategoryArr(dt.categoryArr);
      setAddmore(dt.addmore);
      setSelectedCountry(dt.selectedCountry);
      setLatLogPosition(dt.latLogPosition);
      setSelectedDoohScreen(dt.selectedDoohScreen);
    }

    const access_token = window.localStorage.getItem(SECRET_KEY);
    if (access_token) {
      axios
        .get(`${API}/mediaPlanner/dropdowns`, {
          headers: { Authorization: `Bearer ${access_token}` },
        })
        .then((res) => {
          if (res) {
            dispatch(SaveAdminDropDownData(res.data));
          }
        });
    }
  }, []);

  // useEffect(() => {
  //   if (
  //     hrsForMap != 0 &&
  //     countryForMap !== 0 &&
  //     demographicForMap.length !== 0
  //   ) {
  //     console.log('called1');
  //     getMapData();
  //   }
  // }, [hrsForMap, countryForMap, demographicForMap]);

  useEffect(() => {
    if (
      selectedCountry &&
      Object.keys(selectedCountry).length > 0 &&
      countryForMap
    ) {
      setStateListData(Object.keys(Object.values(selectedCountry)[0]));
    }
  }, [selectedCountry]);

  // useEffect(() => {
  //   let graphArr = [];

  //   if (categoryArr.length !== 0) {
  //     if (mapData && mapData.length) {
  //       addmore?.map((ad) => {
  //         mapData?.map((gd) => {
  //           if (!graphArr.includes(gd)) {
  //             if (
  //               gd.contextual_categories.includes(ad.category) &&
  //               ad.keyword === 0
  //             ) {
  //               gd.color = ad.color;
  //               graphArr.push(gd);
  //               return;
  //             } else if (
  //               gd.contextual_categories.includes(ad.category) &&
  //               gd.contextual_keywords.includes(ad.keyword) &&
  //               ad.keyword
  //             ) {
  //               gd.color = ad.color;
  //               graphArr.push(gd);
  //             }
  //           }
  //         });
  //       });
  //     }
  //   }
  //   // console.log("graphArr", graphArr);
  //   setCloneMapDataArr(graphArr);
  // }, [addmore]);

  useEffect(() => {
    if (topicForMap.length !== 0) {
      if (mapData && mapData.length) {
        let graphArr = mapData?.filter((gd) =>
          topicForMap.includes(gd.contextual_categories)
        );
        setMapDataArr(graphArr);
      }
    } else {
      setMapDataArr(mapData);
    }
  }, [topicForMap]);

  useEffect(() => {
    let latlong = [];
    if (countryForMap) {
      CountryData &&
        CountryData.length &&
        CountryData?.map((lt) => {
          if (countryForMap.toLowerCase() === lt.country_name.toLowerCase()) {
            latlong.push(Number(lt.latitude), Number(lt.longitude));
          }
        });
      setLatLogPosition(latlong);
      if (containerMap) containerMap.map.flyTo(latlong);
      forceUpdate(count + 1);
    }
  }, [countryForMap]);

  useEffect(() => {
    let arrNames = [];
    dropdownData?.demographics.length &&
      dropdownData?.demographics?.map((dem) => {
        if (demographicForMap.includes(dem._id)) {
          let nm = capitalizeFirstLetter(dem.name);
          arrNames.push(nm);
        }
      });
    setDemographicName(arrNames);
  }, [demographicForMap]);

  useEffect(() => {
    if (!keywordsForMap) return;

    const result = mapData.filter((item) => {
      return keywordsForMap.includes(item.contextual_keywords);
    });

    setGarphDataClone(result);
  }, [keywordsForMap]);

  useEffect(() => {
    if (
      selectedDoohScreen &&
      selectedDoohScreen.length > 0 &&
      selectedDoohScreen.includes("ACMS")
    ) {
      let demoName = [];
      demographicName.filter((jc) => {
        if (!demoName.includes(jc.toLowerCase())) {
          if (jcdArr.includes(jc.toLowerCase())) {
            demoName.push(jc.toLowerCase());
          } else if (jc.toLowerCase() === "south asian") {
            demoName.push("south");
          } else {
            demoName.push(jc.toLowerCase());
          }
        }
      });
      setDemoNameAcms(demoName);
      setMapData([]);
      // setMapDataArr([]);
      // setGarphDataClone([]);
    }
    if (
      selectedDoohScreen &&
      selectedDoohScreen.length > 0 &&
      selectedDoohScreen.includes("JCDecaux")
    ) {
      let demoName = [];
      demographicName.filter((jc) => {
        if (!demoName.includes(jc.toLowerCase())) {
          switch (jc.toLowerCase()) {
            case "l g b t q+":
              demoName.push("lgbtq");
              break;
            case "south asian":
              demoName.push("south");
              break;
            case "multiple ethnic groups":
              demoName.push("multi");
              break;
            case "50+":
              demoName.push("55");
              break;
            default:
              demoName.push(jc.toLowerCase());
              break;
          }
        }
      });
      setDemoNameJdc(demoName);
      setMapData([]);

      // setMapDataArr([]);
      // setGarphDataClone([]);
    }
  }, [selectedDoohScreen, demographicName]);

  useEffect(() => {
    if (
      selectedDoohScreen.includes("JCDecaux") &&
      demoNameJdc &&
      demoNameJdc.length
    ) {
      getDoohJCDData();
    } else {
      // setDoohMapJCDData([]);
    }
  }, [demoNameJdc, selectedDoohScreen]);

  useEffect(() => {
    if (
      selectedDoohScreen.includes("ACMS") &&
      demoNameAcms &&
      demoNameAcms.length
    ) {
      getDoohScreenData();
    } else {
      // setDoohScreenFlagsData([]);
    }
  }, [demoNameAcms, selectedDoohScreen]);

  // useEffect(() => {
  //   if (!toggle) return;
  //   const addArr = [...addmore];
  //   const catArr = [];
  //   const keyArr = [];
  //   if (addArr.length) {
  //     addArr?.map((ad) => {
  //       if (!addArr.includes(ad.category) && ad.category !== 0) {
  //         catArr.push(ad.category);
  //         keyArr.push(ad.keyword);
  //         setToggle(false);
  //       }
  //     });
  //     const catArrFilter = catArr.filter(
  //       ((last) => (v) => (last = last || v))(false)
  //     );
  //     setCategoryArr(catArrFilter.length ? catArrFilter : []);
  //   }
  // }, [toggle]);

  const getMapData = (demographic = "", index = "", request = "") => {
    let localdata = localStorage.getItem("heatmapData");
    if (localdata) {
      // callbackViewWidget("");
      // return;
    }
    if (hrsForMap === 0 || countryForMap === "" || demographic === "") return;
    // if (!selectedSection.includes("heatmap")) return;

    setLoading(true);
    let endPoint = "";
    if (hrsForMap != 0) {
      endPoint += `hour=${Number(hrsForMap) === 1 ? "00" : hrsForMap - 1}`;
      if (countryForMap != 0) endPoint += `&country=${countryForMap}`;
      if (demographic) endPoint += `&demographic=${demographic._id}`;
      // if (contextualString) endPoint += `&categories=${contextualString}`;
      // if (categoryArr) endPoint += `&categories=${categoryArr}`;
    }

    axios
      .get(
        `${API}/mediaPlanner/heatmapApi/getCSVData?page=${pagination.page}&limit=${pagination.limit}&${endPoint}`,
        {
          headers: { Authorization: `Bearer ${access_token}` },
        }
      )
      .then((res) => {
        setLoading(false);
        if (res && res.data && res.data.length) {
          const addList = [...addmore];
          let response = res.data[1].contextual_categories;
          if (res.data[1].contextual_categories) {
            response.map((i) => {
              i.label = i.categoryName;
              i.value = i.categoryName;
              return i;
            });
            setCategoryList(response);
          }
          addList &&
            addList.map((al, i) => {
              addList[i].categoryList = response || [];
              let keyArr = response.filter(
                (ct) => ct.categoryName === al.category
              );
              addList[i].keywordList =
                keyArr && keyArr.length ? keyArr[0].keywords : [];
            });
          // originalMapData = [...originalMapData, ...res.data[0].data] || [];
          setAddmore(addList);
          let addmoreArr = [...addmore];
          if (index) {
            if (request == "same") {
              addmoreArr[index].mapData =
                [...addmoreArr[index].mapData, ...res.data[0].data] || [];
            } else {
              addmoreArr[index].mapData = res.data[0].data || [];
            }
          } else {
            addmoreArr[0].mapData = res.data[0].data || [];
          }
          // setMapData(originalMapData);
          // setMapDataArr(originalMapData);
          setAddmore(addmoreArr);
          setSelectedCountry(res.data[2]);

          pagination.page = pagination.limit;
          pagination.limit = 1000 + pagination.page - 1;
          pagination.totalCount = res.data[0].totalcount;
          pagination.count = pagination.count + res.data[0].count;
          if (pagination.count !== pagination.totalCount)
            getMapData(demographic, index, "same");
        }
      })
      .catch(function (error) {
        if (error) {
          setLoading(false);
        }
      });
  };

  const setCityListData = (selectedState, index) => {
    if (
      selectedCountry &&
      Object.keys(selectedCountry).length > 0 &&
      countryForMap
    ) {
      let cities = Object.values(selectedCountry)[0]?.[selectedState];
      let addmoreArr = [...addmore];
      let allCities = [];
      if (cities && cities.length) {
        for (let city of cities.sort()) {
          allCities.push({ label: city, value: city });
        }
        addmoreArr[index].cityListData = allCities;
        setAddmore(addmoreArr);
      }
    } else {
      return;
    }
  };

  const imageRender = (imgUrl, type = "") => {
    return new L.Icon({
      iconUrl: imgUrl,
      iconRetinaUrl: imgUrl,
      iconAnchor: [15, 42],
      popupAnchor: [0, -40],
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null,
      iconSize: type === "lgbtq" ? new L.Point(45, 45) : new L.Point(15, 20),
    });
  };

  const imageRenderMarker = (imgUrl, type = "") => {
    return new L.Icon({
      iconUrl: imgUrl,
      iconRetinaUrl: imgUrl,
      iconAnchor: [15, 42],
      popupAnchor: [0, -40],
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null,
      iconSize: type === "lgbtq" ? new L.Point(45, 45) : new L.Point(20, 25),
    });
  };

  const getIcon = (gdata = {}, type, name) => {
    let color = "#e70d0d";
    let imgUrl = "";
    if (type === "ACMS") {
      if (name === "black" || name === "south") {
        imgUrl = require("../../../assets/black_south_muliple.png");
        return imageRender(imgUrl);
      } else if (name === "acms") {
        imgUrl = require("../../../assets/33.svg");
        return imageRender(imgUrl);
      } else {
        imgUrl = require("../../../assets/33.svg");
        return imageRender(imgUrl);
      }
    }
    if (type === "JCDecaux") {
      if (name === "black" || name === "south" || name === "multi") {
        imgUrl = require("../../../assets/black_south_muliple.png");
        return imageRender(imgUrl);
      } else if (name === "55") {
        imgUrl = require("../../../assets/Age.png");
        return imageRender(imgUrl);
      } else if (name === "lgbtq") {
        imgUrl = require("../../../assets/Lgbt.png");
        return imageRender(imgUrl, "lgbtq");
      } else if (name === "disability") {
        imgUrl = require("../../../assets/Disability.png");
        return imageRender(imgUrl);
      } else {
        imgUrl = require("../../../assets/32.svg");
        return imageRender(imgUrl);
      }
    }
    if (type === "clear_channel") {
      imgUrl = require("../../../assets/31.svg");
      return imageRender(imgUrl);
    }
    const markerHtmlStyles = `
    background-color: ${gdata.color};
    width: 1rem;
    height: 1rem;
    display: block;
    left: -1.5rem;
    top: -1.5rem;
    position: relative;
    border-radius: 50%;
    transform: rotate(45deg);
    border: 1px solid #FFFFFF`;
    const icon1 = L.divIcon({
      className: "my-custom-pin",
      iconAnchor: [0, 24],
      labelAnchor: [-6, 0],
      popupAnchor: [0, -36],
      html: `<span style="${markerHtmlStyles}" />`,
    });

    return icon1;
  };

  const setCircleData = (index) => {
    let data = [];
    let flags_doohScreenFlagData = doohScreenFlagData;

    // if (categoryArr.length) {
    //   data = [...cloneMapDataArr];
    // } else {
    // data = [...mapDataArr];
    // }

    let addmoreArr = [...addmore];
    data = addmoreArr[index].filteredMapData;

    if (
      addmoreArr[index]?.cityLatLng?.latitude &&
      addmoreArr[index]?.cityLatLng?.langitude &&
      addmoreArr[index]?.selectedRadiusInMeter
    ) {
      addmoreArr[index].selectedCircleData =
        data && data.length
          ? data?.filter((r) => {
              let dis = getPreciseDistance(
                {
                  latitude: addmoreArr[index]?.cityLatLng?.latitude,
                  longitude: addmoreArr[index]?.cityLatLng?.langitude,
                },
                { latitude: r.latitude, longitude: r.longitude }
              );
              if (dis < addmoreArr[index].selectedRadiusInMeter) {
                return r;
              }
            })
          : [];
      addmoreArr[index].selectedCircleData.map((i) => {
        i["color"] = addmoreArr[index].color;
        return i;
      });

      flags_doohScreenFlagData =
        flags_doohScreenFlagData &&
        flags_doohScreenFlagData.length &&
        flags_doohScreenFlagData?.filter((r) => {
          let dis = getPreciseDistance(
            {
              latitude: addmoreArr[index]?.cityLatLng?.latitude,
              longitude: addmoreArr[index]?.cityLatLng?.langitude,
            },
            { latitude: r.Lat, longitude: r.Long }
          );
          if (dis < addmoreArr[index].selectedRadiusInMeter) {
            let findId = addmoreArr[index].selectedFrames.find(
              (i) => i["Frame ID"] == r["Frame ID"]
            );
            // if(!findId || !(typeof findId == 'object')) frameIds.push(r);
            if (!findId || !(typeof findId == "object"))
              addmoreArr[index].selectedFrames.push(r);
            return r;
          }
        });
    }
    // setFrameIdList(...addmoreArr[index].selectedFrames)
    // console.log('addmore', addmore);
  };

  const sendFrameIds = (index) => {
    let addmoreArr = [...addmore];
    // console.log('selectedFrames', addmoreArr[index].selectedFrames);
    axios
      .post(`${API}/mediaPlanner/intelligence/sentiments?`, {}, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res.data) {
          /// change condition later when res.data comes as empty array
        } else {
        }
      })
      .catch((error) => console.log());
  };

  const AddmoreMultipleMarkers = ({ type, addmoreData }) => {
    let flags_doohScreenFlagData = [];
    let flags_doohMapJCDData = [];
    let data = addmoreData.selectedCircleData || [];
    // if(addmoreData.category == '') data = addmoreData.mapData;
    if (addmoreData.category !== "" && !addmoreData.selectedRadiusInMeter)
      data = addmoreData.filteredMapData;
    // if (type === "keywordForMap") {
    //   data = cloneMapDataArr;
    // } else {
    //   data = mapDataArr;
    // }
    // let radius = mileToMeter(
    //   addmoreData.selectedRange ? addmoreData.selectedRange : 1
    // );
    if (
      addmoreData?.cityLatLng?.latitude &&
      addmoreData?.cityLatLng?.langitude &&
      addmoreData.selectedRadiusInMeter
    ) {
      addmoreData.noOfActiveUsers = data?.length;
      flags_doohScreenFlagData =
        doohScreenFlagData &&
        doohScreenFlagData.length &&
        doohScreenFlagData?.filter((r) => {
          let dis = getPreciseDistance(
            {
              latitude: addmoreData?.cityLatLng?.latitude,
              longitude: addmoreData?.cityLatLng?.langitude,
            },
            { latitude: r.Lat, longitude: r.Long }
          );
          if (dis < addmoreData.selectedRadiusInMeter) {
            return r;
          }
        });
      flags_doohMapJCDData =
        doohMapJCDData &&
        doohMapJCDData.length &&
        doohMapJCDData?.filter((r) => {
          let dis = getPreciseDistance(
            {
              latitude: addmoreData?.cityLatLng?.latitude,
              longitude: addmoreData?.cityLatLng?.langitude,
            },
            { latitude: r.Lat, longitude: r.Long }
          );
          if (dis < addmoreData.selectedRadiusInMeter) {
            return r;
          }
        });
    }

    return (
      <>
        {addmoreData?.cityLatLng && addmoreData.selectedRadiusInMeter > 0 && (
          <Circle
            center={[
              addmoreData.cityLatLng.latitude,
              addmoreData.cityLatLng.langitude,
            ]}
            fillColor="white"
            fillOpacity={0.5}
            zoomAndPan="50000"
            radius={addmoreData.selectedRadiusInMeter}
          />
        )}

        {selectedDoohScreen.includes("ACMS") &&
        flags_doohScreenFlagData &&
        flags_doohScreenFlagData.length ? (
          <DoohScreenMArker
            flags_doohScreenFlagData={flags_doohScreenFlagData}
          />
        ) : null}

        {selectedDoohScreen.includes("JCDecaux") &&
        flags_doohMapJCDData &&
        flags_doohMapJCDData.length ? (
          <DoohScreenJCDMarker flags_doohMapJCDData={flags_doohMapJCDData} />
        ) : null}

        {(addmoreData.selectedRadiusInMeter > 0 ||
          !addmoreData.selectedRadiusInMeter) &&
          addmoreData.category &&
          data &&
          data.length &&
          data?.map((gdata, index) => {
            // addmore.map((item) => {
            //   let con_cat = gdata.contextual_categories.split(",")[0];
            //     if (con_cat.includes(item.category) && gdata.contextual_keywords.includes(item.keyword)) {
            //       gdata.color = item.color;
            //     } else if(con_cat.includes(item.category) && !gdata.contextual_keywords.includes(item.keyword)) {
            //     gdata.color = item.color;
            //   }
            // });
            return (
              // <Marker
              //   key={index}
              //   position={[gdata.latitude, gdata.longitude]}
              // icon={getIcon(gdata)}
              //   // icon={icon}
              // >
              // <Popup
              //   className={classes.leafletPopup}
              //   // style={{backgroundColor:'#4e11a2'}}
              // >
              //   <div className={classes.popupContent}>
              //     <img
              //       src={Exclamation}
              //       style={{ float: "right" }}
              //       width="20"
              //       height="20"
              //     />
              //     <div className={classes.popupText}>
              //       <Grid container spacing={1}>
              //         <Grid item xs={4}>
              //           <b>Tending Topic:</b>
              //         </Grid>
              //         <Grid item xs={8}>
              //           <span className={classes.popupRightText}>
              //             {gdata.contextual_categories}
              //           </span>
              //         </Grid>
              //       </Grid>
              //       <Grid container spacing={1}>
              //         <Grid item xs={4}>
              //           <b>Publisher:</b>
              //         </Grid>
              //         <Grid item xs={8}>
              //           <span className={classes.popupRightText}>
              //             {gdata.publisher_id}
              //           </span>
              //         </Grid>
              //       </Grid>
              //       <Grid container spacing={1}>
              //         <Grid item xs={4}>
              //           <b>URL:</b>
              //         </Grid>
              //         <Grid item xs={8}>
              //           <span className={classes.popupRightText}>
              //             {gdata.request_url}
              //           </span>
              //         </Grid>
              //       </Grid>
              //       <Grid container spacing={1}>
              //         <Grid item xs={4}>
              //           <b>Lat:</b>
              //         </Grid>
              //         <Grid item xs={8}>
              //           <span className={classes.popupRightText}>
              //             {gdata.latitude}
              //           </span>
              //         </Grid>
              //         <Grid item xs={4}>
              //           <b>Log:</b>
              //         </Grid>
              //         <Grid item xs={8}>
              //           <span className={classes.popupRightText}>
              //             {gdata.longitude}
              //           </span>
              //         </Grid>
              //       </Grid>
              //     </div>
              //   </div>
              // </Popup>
              // </Marker>

              <CircleMarker
                center={[gdata.latitude, gdata.longitude]}
                radius={2}
                fillColor={gdata.color || addmoreData.color || "#000"}
                width={1}
                stroke={gdata.color || addmoreData.color || "#000"}
                color={gdata.color || addmoreData.color || "#000"}
                fillOpacity={0.5}
              >
                <Popup className={classes.leafletPopup}>
                  <div className={classes.popupContent}>
                    <img
                      src={Exclamation}
                      style={{ float: "right" }}
                      width="20"
                      height="20"
                    />
                    <div className={classes.popupText}>
                      <Grid container spacing={1}>
                        <Grid item xs={4}>
                          <b>Tending Topic:</b>
                        </Grid>
                        <Grid item xs={8}>
                          <span className={classes.popupRightText}>
                            {gdata.contextual_categories}
                          </span>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1}>
                        <Grid item xs={4}>
                          <b>Publisher:</b>
                        </Grid>
                        <Grid item xs={8}>
                          <span className={classes.popupRightText}>
                            {gdata.publisher_id}
                          </span>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1}>
                        <Grid item xs={4}>
                          <b>URL:</b>
                        </Grid>
                        <Grid item xs={8}>
                          <span className={classes.popupRightText}>
                            {gdata.request_url}
                          </span>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1}>
                        <Grid item xs={4}>
                          <b>Lat:</b>
                        </Grid>
                        <Grid item xs={8}>
                          <span className={classes.popupRightText}>
                            {gdata.latitude}
                          </span>
                        </Grid>
                        <Grid item xs={4}>
                          <b>Log:</b>
                        </Grid>
                        <Grid item xs={8}>
                          <span className={classes.popupRightText}>
                            {gdata.longitude}
                          </span>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Popup>
              </CircleMarker>
            );
          })}
      </>
    );
  };

  const mileToMeter = (miles) => {
    return 1609.34 * miles;
  };

  const findLatLng = async (city, state, country) => {
    const results = await provider.search({
      query: `${city},${state},${country}`,
    });
    if (results && results.length) return results[0];
    return null;
  };

  const DoohScreenMArker = ({ flags_doohScreenFlagData }) => {
    return (
      // doohScreenFlagData &&
      // doohScreenFlagData.length &&
      // doohScreenFlagData.map((doohData, index) => {
      //   return (
      //     <Marker
      //       key={index}
      //       position={[doohData.Lat, doohData.Long]}
      //       icon={getIcon({}, "ACMS", doohData.Name.toLowerCase())}
      //       clickable={false}
      //     />
      //   );
      // })
      flags_doohScreenFlagData &&
      flags_doohScreenFlagData.length &&
      flags_doohScreenFlagData.map((doohData, index) => {
        return (
          <Marker
            key={index}
            position={[doohData.Lat, doohData.Long]}
            icon={getIcon({}, "ACMS", doohData.Name.toLowerCase())}
            clickable={false}
          >
            <Popup
              className={classes.leafletPopup}
              style={{ left: "-131px!important" }}
            >
              <div className={classes.popupContent} style={{ color: "#fff" }}>
                Frame ID : {doohData["Frame ID"]}
              </div>
            </Popup>
          </Marker>
        );
      })
    );
  };

  const DoohScreenJCDMarker = ({ flags_doohMapJCDData }) => {
    return (
      flags_doohMapJCDData &&
      flags_doohMapJCDData.length &&
      flags_doohMapJCDData?.map((doohData, index) => {
        return (
          <Marker
            key={index}
            position={[doohData.Lat, doohData.Long]}
            icon={getIcon({}, "JCDecaux", doohData.Name.toLowerCase())}
            clickable={false}
          >
            <Popup
              className={classes.leafletPopup}
              style={{ left: "-131px!important" }}
            >
              <div className={classes.popupContent} style={{ color: "#fff" }}>
                Frame ID : {doohData["Frame ID"]}
              </div>
            </Popup>
          </Marker>
        );
      })
      // doohMapJCDData &&
      // doohMapJCDData.length &&
      // doohMapJCDData?.map((doohData, index) => {
      //   return (
      //     <Marker
      //       key={index}
      //       position={[doohData.Lat, doohData.Long]}
      //       icon={getIcon({}, "JCDecaux", doohData.Name.toLowerCase())}
      //       clickable={false}
      //     />
      //   );
      // })
    );
  };

  const capitalizeFirstLetter = (string) => {
    if (string == "participantDemography") string = "participantDemographics";
    let str = string.replace(/[A-Z]/g, " $&").trim();
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const getDoohScreenData = () => {
    if (isLocallyStored) return;
    setLoading(true);
    let endPoint = "";
    if (demoNameAcms.length !== 0) endPoint += `&acms_keys=${demoNameAcms}`;

    axios
      .get(
        `${API}/mediaPlanner/heatmapApi/getACMSCSVData?page=${acmsPagination.page}&limit=${acmsPagination.limit}${endPoint}`,
        {
          headers: { Authorization: `Bearer ${access_token}` },
        }
      )
      .then((res) => {
        if (res && res.data) {
          acmsPagination.page = acmsPagination.limit;
          acmsPagination.limit = 1000 + acmsPagination.page - 1;
          acmsPagination.totalCount = res.data.totalcount;
          acmsPagination.count = acmsPagination.count + res.data.count;
          acmsData = [...acmsData, ...res.data.fdata] || [];
          setDoohScreenFlagsData(acmsData);
          if (acmsPagination.count !== acmsPagination.totalCount)
            getDoohJCDData();
        }
        setLoading(false);
      })
      .catch(function (error) {
        if (error) {
          setLoading(false);
        }
      });
  };

  const getDoohJCDData = () => {
    if (isLocallyStored) return;
    setLoading(true);
    let endPoint = "";
    if (demoNameJdc.length !== 0) endPoint += `&demographic_key=${demoNameJdc}`;

    axios
      .get(
        `${API}/mediaPlanner/heatmapApi/getJCDecauxData?page=${jdcPagination.page}&limit=${jdcPagination.limit}${endPoint}`,
        {
          headers: { Authorization: `Bearer ${access_token}` },
        }
      )
      .then((res) => {
        if (res && res.data) {
          jdcPagination.page = jdcPagination.limit;
          jdcPagination.limit = 1000 + jdcPagination.page - 1;
          jdcPagination.totalCount = res.data.totalcount;
          jdcPagination.count = jdcPagination.count + res.data.count;
          jcdData = [...jcdData, ...res.data.fdata] || [];
          setDoohMapJCDData(jcdData);
          if (jdcPagination.count !== jdcPagination.totalCount)
            getDoohJCDData();
        }
        setLoading(false);
      })
      .catch(function (error) {
        if (error) {
          setLoading(false);
        }
      });
  };

  const addMoreItem = (pId) => {
    const addArr = [...addmore];
    addArr.push({
      category: "",
      keyword: "",
      subCategory: "",
      categoryList: categoryList && categoryList.length ? categoryList : [],
      subCategoryList: [],
      keywordList: [],
      color: "",
      selectedFrames: [],
    });
    setAddmore(addArr);
    setIsLocallyStored(false);
  };

  const removeItem = async (parentInd) => {
    const removeArr = [...addmore];
    if (removeArr.length) {
      removeArr.splice(parentInd, 1);
    }
    setAddmore([...removeArr]);
    setIsLocallyStored(false);
  };

  const getColor = (str, index, clr) => {
    let stringUniqueHash = [...str].reduce((acc, char) => {
      return char.charCodeAt(0) + ((acc << 5) - index);
    }, 0);
    let newColor =
      `hsl(${stringUniqueHash % 360}, 95%, 35%)` === clr
        ? `hsl(${(stringUniqueHash * 5) % 360}, 95%, 35%)`
        : `hsl(${stringUniqueHash % 360}, 95%, 35%)`;
    return newColor;
  };

  const setDataToLocal = async () => {
    let data = {};
    data.hrsForMap = hrsForMap;
    data.countryForMap = countryForMap;
    data.demographicForMap = demographicForMap;
    let dt = [...addmore];
    dt.map((i) => delete i.mapData);
    data.addmore = dt;
    data.categoryArr = categoryArr;
    data.selectedCountry = selectedCountry;
    data.latLogPosition = latLogPosition;
    data.selectedDoohScreen = selectedDoohScreen;
    data.doohScreenFlagData = doohScreenFlagData;
    data.doohMapJCDData = doohMapJCDData;
    localStorage.setItem("heatmapData", JSON.stringify(data));
    setIsLocallyStored(true);
  };

  return (
    <>
      {loading && <CircularProgress color="colorSecondary" size={40} />}
      {decodedSubscription && decodedSubscription.subscription === "paid" && (
        <div
          style={{
            justifyContent: "end",
            display: "flex",
            alignItems: "end",
            width: "inherit",
          }}
        >
          <FormControl
            fullWidth
            component="fieldset"
            variant="outlined"
            className={[classes.selectControl, classes.customfield].join(" ")}
            style={{
              width: "22%",
            }}
          >
            <InputLabel>Select DOOH...</InputLabel>
            <Select
              id="outlined-select-currency-native"
              multiple
              value={selectedDoohScreen}
              onChange={(e) => {
                setSelectedDoohScreen(e.target.value);
              }}
              variant="standard"
              renderValue={() => {
                let arrNm = [];
                let lastItem = selectedDoohScreen[selectedDoohScreen.length - 1];
                doohScreenArray?.forEach((dem) => {
                  if (selectedDoohScreen.includes(dem._id)) {
                    let nm = capitalizeFirstLetter(dem.label);
                    if (lastItem === dem._id) {
                      arrNm.push(nm);
                    } else {
                      arrNm.push(nm + " ,");
                    }
                  }
                });
                return arrNm;
              }}
              // renderValue={() => {
              //   let arrNm = [];
              //   mediaOwnerList?.forEach((dem) => {
              //     if (selectedDoohScreen.includes(dem.organization_id)) {
              //       let nm = capitalizeFirstLetter(dem.name);
              //       arrNm.push(nm + " , ");
              //     }
              //   });
              //   return arrNm;
              // }}
            >
              <MenuItem disabled value={0} key={0}>
                <em>Select DOOH...</em>
              </MenuItem>
              {doohScreenArray?.map((option) => (
                <MenuItem key={option._id} value={option._id}>
                  <Checkbox
                    style={{ display: "none" }}
                    checked={doohScreenArray.includes(option._id)}
                  ></Checkbox>
                  {option.label}
                </MenuItem>
              ))}
              {/* {mediaOwnerList?.map((option) => (
                <MenuItem key={option.organization_id} value={option.organization_id}>
                  <Checkbox
                    style={{ display: "none" }}
                    checked={mediaOwnerList.includes(option.organization_id)}
                  ></Checkbox>
                  {option.name}
                </MenuItem>
              ))} */}
            </Select>
          </FormControl>
        </div>
      )}
      <Grid item xs={12} sm={12} md={12}>
        {/* <Box className={!resetSize ? classes.cardBoxHeatmap : ""} > */}
        <Box className={classes.boxShadow} style={{ marginTop: "0px" }}>
          <Card
            style={{
              backgroundImage: "linear-gradient(#4911a2,#9211a2)",
              color: "white",
              height: !resetSize ? "530px" : "100%",
              overflow: "auto",
            }}
          >
            <CardContent>
              {!resetSize ? (
                <div
                  style={{
                    float: "right",
                    color: "white",
                    marginTop: "10px",
                    cursor: "pointer",
                  }}
                  title="Maximize"
                >
                  <CropSquareOutlined
                    onClick={() => {
                      // callbackViewWidget("max")
                      setResetSize(!resetSize);
                    }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    float: "right",
                    color: "white",
                    marginBottom: "10px",
                    cursor: "pointer",
                  }}
                  title="Manimize"
                >
                  <MinimizeOutlined
                    onClick={() => {
                      // callbackViewWidget("min")
                      setResetSize(!resetSize);
                    }}
                  />
                </div>
              )}
              {decodedSubscription && 
              decodedSubscription.subscription === "free" ? (
                <Subscription />
              ) : (
                <>
                  <FormControl
                    component="fieldset"
                    variant="outlined"
                    className={[classes.selectControl, classes.customfield].join(" ")}
                    style={{
                      marginTop: "5px",
                      marginRight: "10px",
                      width: "22%",
                      float: "right",
                    }}
                  >
                    <CustomDropdown
                      isSearchable={true}
                      isDisabled={isLocallyStored}
                      value={
                        countryForMap
                          ? [
                              {
                                label: countryForMap,
                                value: countryForMap,
                              },
                            ]
                          : []
                      }
                      onChange={async (e) => {
                        setTopicForMap([]);
                        let more = addmore;
                        more.length = 0;
                        more.push({
                          category: "",
                          keyword: "",
                          subCategory: "",
                          categoryList: [],
                          subCategoryList: [],
                          keywordList: [],
                          mapData: [],
                          color: "",
                          selectedFrames: [],
                        });
                        setAddmore(more);
                        setMapDataArr([]);
                        setCloneMapDataArr([]);
                        setCountryForMap(e ? e.value : "");
                        setKeywordsForMap([]);
                      }}
                      placeholder="Select country"
                      // options={dropdownData?.countries.map((i) => {
                      //   i.label = i.name;
                      //   i.value = i.name;
                      //   return i;
                      // })}
                      options={CountryData.map((i) => {
                        i.label = i.country_name;
                        i.value = i.country_name;
                        return i;
                      })}
                      isClearable={true}
                    />
                  </FormControl>
                  <FormControl
                    component="fieldset"
                    variant="outlined"
                    className={[classes.selectControl, classes.customfield].join(" ")}
                    style={{
                      marginTop: "5px",
                      marginRight: "10px",
                      width: "22%",
                      float: "right",
                    }}
                  >
                    <CustomDropdown
                      isSearchable={true}
                      isDisabled={isLocallyStored}
                      value={hrsForMap ? [{ label: hrsForMap, value: hrsForMap }] : []}
                      onChange={async (e) => {
                        setTopicForMap([]);
                        setHrsForMap(e ? e.value : "");
                        setMapDataArr([]);
                        setCloneMapDataArr([]);
                        let more = addmore;
                        more.length = 0;
                        more.push({
                          category: "",
                          keyword: "",
                          subCategory: "",
                          categoryList: [],
                          subCategoryList: [],
                          keywordList: [],
                          mapData: [],
                          color: "",
                          selectedFrames: [],
                        });
                        setAddmore(more);
                      }}
                      placeholder="Select duration"
                      options={[
                        { label: "1 Hr", value: 1 },
                        { label: "12 Hrs", value: 12 },
                        { label: "24 Hrs", value: 24 },
                      ]}
                      isClearable={true}
                    />
                  </FormControl>
                  <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                    <Grid item xs={!resetSize ? 6 : 12} sm={!resetSize ? 6 : 12} md={!resetSize ? 6 : 12}>
                      <Box className={!resetSize ? classes.cardBox : ""}>
                        <Card
                          style={{
                            backgroundColor: "#8f11a2",
                            color: "white",
                            height: !resetSize ? "420px" : "100%",
                            overflow: "auto",
                          }}
                        >
                          <CardContent style={{ height: "100%" }}>
                            {/* for small view */}
                            {!resetSize ? (
                              <MapContainer
                                whenCreated={(map) => setContainerMap({ map })}
                                center={latLogPosition}
                                zoom={4}
                                style={{
                                  height: "100%",
                                  width: "33vw",
                                }}
                                layers={[tiles]}
                              >
                                <TileLayer
                                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                {/* categoryArr.length && */}
                                {addmore.map((addmoreData) => (
                                  <AddmoreMultipleMarkers
                                    type={categoryArr.length ? "keywordForMap" : ""}
                                    addmoreData={addmoreData}
                                  />
                                ))}
                                <Marker
                                  position={latLogPosition}
                                  icon={imageRenderMarker(MarkerIcon)}
                                  className="markerPoint"
                                  width="20px"
                                  height="25px"
                                >
                                  <Popup>You are here</Popup>
                                </Marker>
                                {/* )} */}
                                {/* {selectedDoohScreen.length == 1 && selectedDoohScreen.includes(
                                    "ACMS"
                                ) && <DoohScreenMArker />} */}
                                {/* {selectedDoohScreen.length == 1 && selectedDoohScreen.includes(
                                    "JCDecaux"
                                ) &&
                                    doohMapJCDData &&
                                    doohMapJCDData.length && (
                                    <DoohScreenJCDMarker />
                                    )} */}
                              </MapContainer>
                            ) : null}

                            {/* for wide view */}
                            {resetSize ? (
                              <MapContainer
                                whenCreated={(map) => setContainerMap({ map })}
                                center={latLogPosition}
                                zoom={5}
                                style={{
                                  height: "90vh",
                                  // width: "90vw",
                                }}
                                layers={[tiles]}
                              >
                                <TileLayer
                                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                {/* categoryArr.length && */}
                                {addmore.map((addmoreData) => (
                                  <AddmoreMultipleMarkers
                                    type={categoryArr.length ? "keywordForMap" : ""}
                                    addmoreData={addmoreData}
                                  />
                                ))}
                                <Marker
                                  position={latLogPosition}
                                  icon={imageRenderMarker(MarkerIcon)}
                                  className="markerPoint"
                                  width="20px"
                                  height="25px"
                                >
                                  <Popup>You are here</Popup>
                                </Marker>
                                {/* )} */}
                                {/* {selectedDoohScreen.length == 1 && selectedDoohScreen.includes(
                                    "ACMS"
                                ) && <DoohScreenMArker />} */}
                                {/* {selectedDoohScreen.length == 1 && selectedDoohScreen.includes(
                                    "JCDecaux"
                                ) &&
                                    doohMapJCDData &&
                                    doohMapJCDData.length && (
                                    <DoohScreenJCDMarker />
                                    )} */}
                              </MapContainer>
                            ) : null}
                          </CardContent>
                        </Card>
                      </Box>
                    </Grid>
                    <Grid item xs={!resetSize ? 6 : 12} sm={!resetSize ? 6 : 12} md={!resetSize ? 6 : 12}>
                      <Box className={!resetSize ? classes.cardBox : ""}>
                        <Card
                          style={{
                            backgroundColor: "#8f11a2",
                            height: !resetSize ? "420px" : "600px",
                            marginTop: !resetSize ? "" : "10px",
                            overflow: "auto",
                          }}
                        >
                          <CardContent>
                            {addmore && addmore.length
                              ? addmore.map((li, index) => (
                                  <>
                                    <Grid
                                      container
                                      direction="row"
                                      justify="flex-start"
                                      alignItems="flex-start"
                                      spacing={2}
                                      style={{ position: "relative" }}
                                    >
                                      <Grid
                                        item
                                        xs={5}
                                        sm={5}
                                        md={5}
                                        style={{
                                          marginRight: "10px",
                                        }}
                                      >
                                        <FormControl
                                          fullWidth
                                          component="fieldset"
                                          variant="outlined"
                                          className={[classes.selectControl, classes.customfield].join(" ")}
                                          style={{
                                            marginTop: "5px",
                                            float: "left",
                                          }}
                                        >
                                          <CustomDropdown
                                            isSearchable={true}
                                            isDisabled={isLocallyStored}
                                            value={
                                              addmore[index].demographic
                                                ? [
                                                    {
                                                      label: addmore[index].demographic?.value,
                                                      value: addmore[index].demographic?.value,
                                                    },
                                                  ]
                                                : []
                                            }
                                            onChange={async (e) => {
                                              setStateListData([]);
                                              let addmoreArr = [...addmore];
                                              addmoreArr[index].demographic = e ? e : "";
                                              setAddmore(addmoreArr);
                                              if (e) getMapData(e, index);
                                              let demo = [];
                                              addmoreArr.map((i) => {
                                                demo.push(i.demographic._id);
                                              });
                                              setDemographicForMap(demo);
                                            }}
                                            placeholder="Demographic"
                                            options={dropdownData?.demographics?.map((i) => {
                                              i.label = i.name;
                                              i.value = i.name;
                                              return i;
                                            })}
                                            isClearable={true}
                                          />
                                        </FormControl>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={5}
                                        sm={5}
                                        md={5}
                                        style={{
                                          marginRight: "10px",
                                        }}
                                      >
                                        <FormControl
                                          fullWidth
                                          component="fieldset"
                                          variant="outlined"
                                          className={[classes.selectControl, classes.customfield].join(" ")}
                                          style={{
                                            marginTop: "5px",
                                            float: "left",
                                          }}
                                        >
                                          <CustomDropdown
                                            isSearchable={true}
                                            isDisabled={isLocallyStored}
                                            value={
                                              addmore[index].category
                                                ? [
                                                    {
                                                      label: addmore[index].category,
                                                      value: addmore[index].category,
                                                    },
                                                  ]
                                                : []
                                            }
                                            onChange={async (e) => {
                                              let addmoreArr = [...addmore];
                                              addmoreArr[index].category = e ? e.value : "";
                                              // addmoreArr[
                                              //   index
                                              // ].selectedRadiusInMeter = addmoreArr[
                                              //   index
                                              // ].selectedRadiusInMeter
                                              //   ? addmoreArr[index]
                                              //       .selectedRadiusInMeter
                                              //   : mileToMeter(10);
                                              if (e) {
                                                let subCatList = li.categoryList?.filter(
                                                  (option) => option.categoryName === e.value
                                                );
                                                let clr =
                                                  addmoreArr.length && addmoreArr.find((am) => am.category === e.value);
                                                addmoreArr[index].color = getColor(
                                                  e.value,
                                                  index,
                                                  clr ? clr.color : ""
                                                );
                                                let tempCat = [];
                                                subCatList.length
                                                  ? subCatList[0]?.subCategory?.map((i) => {
                                                      if (!tempCat.includes(i))
                                                        tempCat.push({
                                                          label: i,
                                                          value: i,
                                                        });
                                                    })
                                                  : [];
                                                addmoreArr[index].subCategoryList = tempCat;
                                                addmoreArr[index].keywordList = subCatList.length
                                                  ? subCatList[0].keywords
                                                  : [];
                                                addmoreArr[index].filteredMapData = addmoreArr[index]?.mapData
                                                  ?.filter((i) => i.contextual_categories.includes(e.value))
                                                  .map((i) => {
                                                    let obj = {};
                                                    obj.latitude = i.latitude;
                                                    obj.longitude = i.longitude;
                                                    obj.color = i.color;
                                                    obj.publisher_id = i.publisher_id;
                                                    obj.contextual_categories = i.contextual_categories;
                                                    obj.request_url = i.request_url;
                                                    return obj;
                                                  });
                                              }
                                              setAddmore(addmoreArr);
                                              setCircleData(index);
                                              setToggle(true);
                                            }}
                                            placeholder="Trend or topic"
                                            options={li.categoryList}
                                            isClearable={true}
                                          />
                                        </FormControl>
                                      </Grid>

                                      {li.subCategoryList && li.subCategoryList.length ? (
                                        <Grid
                                          item
                                          xs={5}
                                          sm={5}
                                          md={5}
                                          style={{
                                            marginRight: "10px",
                                          }}
                                        >
                                          <FormControl
                                            fullWidth
                                            component="fieldset"
                                            variant="outlined"
                                            className={[classes.selectControl, classes.customfield].join(" ")}
                                            style={{
                                              marginTop: "5px",
                                              marginleft: "5px",
                                              float: "left",
                                            }}
                                          >
                                            <CustomDropdown
                                              isSearchable={true}
                                              isDisabled={isLocallyStored}
                                              value={
                                                addmore[index].subCategory
                                                  ? [
                                                      {
                                                        label: addmore[index].subCategory,
                                                        value: addmore[index].subCategory,
                                                      },
                                                    ]
                                                  : []
                                              }
                                              onChange={async (e) => {
                                                let addmoreArr = [...addmore];
                                                addmoreArr[index].subCategory = e ? e.value : "";
                                                // addmoreArr[
                                                //   index
                                                // ].selectedRadiusInMeter = addmoreArr[
                                                //   index
                                                // ].selectedRadiusInMeter
                                                //   ? addmoreArr[index]
                                                //       .selectedRadiusInMeter
                                                //   : mileToMeter(10);
                                                setAddmore(addmoreArr);
                                                setCircleData(index);
                                              }}
                                              placeholder="Sub category"
                                              options={li.subCategoryList}
                                              isClearable={true}
                                            />
                                          </FormControl>
                                        </Grid>
                                      ) : null}

                                      {li.keywordList && li.keywordList.length ? (
                                        <Grid
                                          item
                                          xs={5}
                                          sm={5}
                                          md={5}
                                          style={{
                                            marginRight: "10px",
                                          }}
                                        >
                                          <FormControl
                                            fullWidth
                                            component="fieldset"
                                            variant="outlined"
                                            className={[classes.selectControl, classes.customfield].join(" ")}
                                            style={{
                                              marginTop: "5px",
                                              float: "left",
                                            }}
                                          >
                                            <CustomDropdown
                                              isSearchable={true}
                                              isDisabled={isLocallyStored}
                                              value={
                                                addmore[index].keyword
                                                  ? [
                                                      {
                                                        label: addmore[index].keyword,
                                                        value: addmore[index].keyword,
                                                      },
                                                    ]
                                                  : []
                                              }
                                              onChange={async (e) => {
                                                let addmoreArr = [...addmore];
                                                addmoreArr[index].keyword = e ? e.value : "";
                                                // addmoreArr[
                                                //   index
                                                // ].selectedRadiusInMeter = addmoreArr[
                                                //   index
                                                // ].selectedRadiusInMeter
                                                //   ? addmoreArr[index]
                                                //       .selectedRadiusInMeter
                                                //   : mileToMeter(10);
                                                setAddmore(addmoreArr);
                                                setCircleData(index);
                                                setToggle(true);
                                                // getMapData(
                                                //   addmore[index].demographic
                                                // );
                                              }}
                                              placeholder="Contextual key.."
                                              options={li.keywordList.map((i) => {
                                                return {
                                                  label: i,
                                                  value: i,
                                                };
                                              })}
                                              isClearable={true}
                                            />
                                          </FormControl>
                                        </Grid>
                                      ) : null}

                                      <div
                                        style={{
                                          right: "4px",
                                          position: "absolute",
                                        }}
                                      >
                                        {li?.noOfActiveUsers >= li?.activeUsers ? (
                                          <div
                                            style={{
                                              marginRight: "2px",
                                              backgroundColor: "green",
                                              display: "inline",
                                              padding: "2px 10px",
                                              borderRadius: "50%",
                                              lineHeight: "43px",
                                            }}
                                          ></div>
                                        ) : (
                                          <div
                                            style={{
                                              marginRight: "2px",
                                              backgroundColor: "red",
                                              display: "inline",
                                              padding: "2px 10px",
                                              borderRadius: "50%",
                                              lineHeight: "43px",
                                            }}
                                          ></div>
                                        )}
                                        <span style={{ color: "#fff" }}>
                                          {li?.noOfActiveUsers ? li?.noOfActiveUsers : ""}
                                        </span>
                                      </div>

                                      <Grid
                                        item
                                        xs={5}
                                        sm={5}
                                        md={5}
                                        style={{
                                          marginRight: "10px",
                                        }}
                                      >
                                        <FormControl
                                          fullWidth
                                          component="fieldset"
                                          variant="outlined"
                                          className={[classes.selectControl, classes.customfield].join(" ")}
                                          style={{
                                            marginTop: "5px",
                                            float: "left",
                                          }}
                                        >
                                          <CustomDropdown
                                            isSearchable={true}
                                            isDisabled={isLocallyStored}
                                            value={
                                              addmore[index].selectedState
                                                ? [
                                                    {
                                                      label: addmore[index].selectedState,
                                                      value: addmore[index].selectedState,
                                                    },
                                                  ]
                                                : []
                                            }
                                            onChange={async (e) => {
                                              let addmoreArr = [...addmore];
                                              addmoreArr[index].selectedState = e ? e.value : "";
                                              // addmoreArr[
                                              //   index
                                              // ].selectedRadiusInMeter = addmoreArr[
                                              //   index
                                              // ].selectedRadiusInMeter
                                              //   ? addmoreArr[index]
                                              //       .selectedRadiusInMeter
                                              //   : mileToMeter(10);
                                              setAddmore(addmoreArr);
                                              setToggle(true);
                                              setCityListData(e ? e.value : "", index);
                                              setCircleData(index);
                                            }}
                                            placeholder="Select regions"
                                            options={stateListData.map((i) => {
                                              return {
                                                label: i,
                                                value: i,
                                              };
                                            })}
                                            isClearable={true}
                                          />
                                        </FormControl>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={5}
                                        sm={5}
                                        md={5}
                                        style={{
                                          marginRight: "10px",
                                        }}
                                      >
                                        <FormControl
                                          fullWidth
                                          component="fieldset"
                                          variant="outlined"
                                          className={[classes.selectControl, classes.customfield].join(" ")}
                                          style={{
                                            marginTop: "5px",
                                            float: "left",
                                          }}
                                        >
                                          <CustomDropdown
                                            isSearchable={true}
                                            isDisabled={isLocallyStored}
                                            value={
                                              addmore[index]?.selectedCity
                                                ? [
                                                    {
                                                      label: addmore[index].selectedCity,
                                                      value: addmore[index].selectedCity,
                                                    },
                                                  ]
                                                : []
                                            }
                                            onChange={async (e) => {
                                              let addmoreArr = [...addmore];
                                              addmoreArr[index].selectedCity = e ? e.value : "";
                                              // addmoreArr[
                                              //   index
                                              // ].selectedRadiusInMeter = addmoreArr[
                                              //   index
                                              // ].selectedRadiusInMeter
                                              //   ? addmoreArr[index]
                                              //       .selectedRadiusInMeter
                                              //   : mileToMeter(10);
                                              if (e) {
                                                let loc = await findLatLng(
                                                  e.value,
                                                  addmoreArr[index].selectedState,
                                                  // selectedCountry
                                                  Object.keys(selectedCountry).toString()
                                                );
                                                console.log("country", Object.keys(selectedCountry).toString())
                                                console.log("location", loc)
                                                addmoreArr[index].cityLatLng = {
                                                  latitude: Number(loc.y),
                                                  langitude: Number(loc.x),
                                                };
                                                setLatLogPosition([Number(loc.y), Number(loc.x)]);
                                                containerMap.map.flyTo([Number(loc.y), Number(loc.x)]);
                                              }
                                              setAddmore(addmoreArr);
                                              setCircleData(index);
                                              forceUpdate(count + 1);
                                            }}
                                            placeholder="Select City"
                                            options={li.cityListData}
                                            isClearable={true}
                                          />
                                        </FormControl>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={5}
                                        sm={5}
                                        md={5}
                                        style={{
                                          marginRight: "10px",
                                        }}
                                      >
                                        <FormControl
                                          fullWidth
                                          component="fieldset"
                                          variant="outlined"
                                          className={[classes.selectControl, classes.customfield].join(" ")}
                                          style={{
                                            marginTop: "5px",
                                            float: "left",
                                          }}
                                        >
                                          <CustomDropdown
                                            isSearchable={true}
                                            isDisabled={isLocallyStored}
                                            value={
                                              addmore[index]?.selectedRange
                                                ? [
                                                    {
                                                      label: addmore[index].selectedRange,
                                                      value: addmore[index].selectedRange,
                                                    },
                                                  ]
                                                : []
                                            }
                                            onChange={async (e) => {
                                              let addmoreArr = [...addmore];
                                              addmoreArr[index].selectedRange = e ? e.value : "";
                                              addmoreArr[index].selectedRadiusInMeter = mileToMeter(e?.value);
                                              setAddmore(addmoreArr);
                                              setToggle(true);
                                              setCircleData(index);
                                            }}
                                            placeholder="Select range"
                                            options={[
                                              {
                                                label: "1 Mile",
                                                value: 1,
                                              },
                                              {
                                                label: "5 Mile",
                                                value: 5,
                                              },
                                              {
                                                label: "10 Mile",
                                                value: 10,
                                              },
                                            ]}
                                            isClearable={true}
                                          />
                                        </FormControl>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={5}
                                        sm={5}
                                        md={5}
                                        style={{
                                          marginRight: "10px",
                                        }}
                                      >
                                        <FormControl
                                          fullWidth
                                          component="fieldset"
                                          variant="outlined"
                                          className={[classes.selectControl, classes.customfield].join(" ")}
                                          style={{
                                            marginTop: "5px",
                                            float: "left",
                                          }}
                                        >
                                          <CustomDropdown
                                            isSearchable={true}
                                            isDisabled={isLocallyStored}
                                            value={
                                              addmore[index]?.activeUsers
                                                ? [
                                                    {
                                                      label: addmore[index].activeUsers,
                                                      value: addmore[index].activeUsers,
                                                    },
                                                  ]
                                                : []
                                            }
                                            onChange={async (e) => {
                                              let addmoreArr = [...addmore];
                                              addmoreArr[index].activeUsers = e ? e.value : "";
                                              setAddmore(addmoreArr);
                                              setCircleData(index);
                                              setToggle(true);
                                              // if(li?.noOfActiveUsers >= li?.activeUsers) sendFrameIds(index);
                                            }}
                                            placeholder="Select users"
                                            options={Array(100)
                                              .fill(1)
                                              .map((n, i) => ({
                                                label: n + i,
                                                value: n + i,
                                              }))}
                                            isClearable={true}
                                          />
                                        </FormControl>
                                      </Grid>

                                      {li.category !== 0 && (
                                        <Grid
                                          container
                                          direction="row"
                                          justify="flex-start"
                                          alignItems="flex-start"
                                          md={10}
                                          item
                                          style={{
                                            marginBottom: "10px",
                                          }}
                                        >
                                          <Box>
                                            <Card
                                              style={{
                                                backgroundColor: li.color,
                                                height: "30px",
                                                borderRadius: "50%",
                                                marginRight: "5px",
                                              }}
                                            >
                                              <CardContent />
                                            </Card>
                                          </Box>
                                          {li.category && (
                                            <Box
                                              borderTop={2}
                                              borderBottom={2}
                                              borderRight={2}
                                              borderLeft={2}
                                              style={{
                                                width: "50%",
                                                padding: "5px",
                                                borderColor: "#fff",
                                                marginRight: "5px",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  color: "white",
                                                }}
                                              >
                                                {li.category}
                                              </span>
                                            </Box>
                                          )}

                                          {["POS", "NEG", "NEU"].map((sen) => (
                                            <Box
                                              borderTop={2}
                                              borderBottom={2}
                                              borderRight={2}
                                              borderLeft={2}
                                              style={{
                                                width: "10%",
                                                padding: "5px",
                                                borderColor: "#fff",
                                                marginRight: "5px",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  color: "white",
                                                }}
                                              >
                                                {sen}
                                              </span>
                                            </Box>
                                          ))}
                                        </Grid>
                                      )}

                                      {li?.noOfActiveUsers >= li?.activeUsers && (
                                        <Grid className={classess.creativeWrapper} style={{ padding: "0.5rem 0" }}>
                                          <TextField
                                            id="unique code"
                                            placeholder="Event unique code"
                                            variant="outlined"
                                            style={{
                                              margin: "0.3rem",
                                              color: "#FFF",
                                            }}
                                          />
                                          <TextField
                                            id="category"
                                            placeholder="Category name"
                                            variant="outlined"
                                            style={{
                                              margin: "0.3rem",
                                              color: "#FFF",
                                            }}
                                          />
                                          <TextField
                                            id="uiname"
                                            placeholder="UI name"
                                            variant="outlined"
                                            style={{
                                              margin: "0.3rem",
                                              color: "#FFF",
                                            }}
                                          />
                                          <InputLabel
                                            id="creative-field"
                                            style={{
                                              color: "#fff",
                                              margin: "1em 0 0.3em 0",
                                            }}
                                          >
                                            Upload Creative
                                          </InputLabel>
                                          <TextField
                                            id="creative-field"
                                            type={"file"}
                                            inputProps={{
                                              accept: "image/png, image/jpg, image/jpeg",
                                            }}
                                            variant="outlined"
                                            style={{
                                              marginBottom: "1rem",
                                              marginRight: "0.3rem",
                                            }}
                                          />
                                          <button
                                            className={classes.addActionBtn}
                                            style={{
                                              width: "127px",
                                            }}
                                            // onClick={() =>
                                            //   setDataToLocal()
                                            // }
                                          >
                                            Upload creative
                                          </button>
                                        </Grid>
                                      )}

                                      {/* campaign selection */}

                                      <Grid
                                        item
                                        xs={5}
                                        sm={5}
                                        md={5}
                                        style={{
                                          marginRight: "10px",
                                        }}
                                      >
                                        <Selection
                                          value={addmore[index]?.selectedCampaign ?? []}
                                          placeholder="Select campaign"
                                          onChange={(e) => {
                                            let addmoreArr = [...addmore];
                                            addmoreArr[index].selectedCampaign = e ?? "";
                                            setAddmore(addmoreArr);
                                            setCircleData(index);
                                            setToggle(true);
                                            fetchLineItems(`${API}/mediaPlanner/hivestack/lineitems/${addmore[index]?.selectedCampaign.value}`).then((res) => {
                                              setAddmore((old_data) => {
                                                const cloned_data = [...old_data]
                                                cloned_data[index].lineItems = res
                                                return cloned_data
                                              })
                                            })
                                          }}
                                          options={campaignList?.map((camp) => ({label: camp.name, value: camp.id}))}
                                        />
                                      </Grid>

                                      {/* line-item selection */}

                                      <Grid
                                        item
                                        xs={5}
                                        sm={5}
                                        md={5}
                                        style={{
                                          marginRight: "10px",
                                        }}
                                      >
                                        <Selection
                                          value={addmore[index]?.selectedLineItem || []}
                                          onChange={(e) => {
                                            let addmoreArr = [...addmore];
                                            addmoreArr[index].selectedLineItem = e ?? "";
                                            setAddmore(addmoreArr);
                                            setCircleData(index);
                                            setToggle(true);
                                            if(addmore[index]?.selectedLineItem.value){
                                              fetchLineItemsDetails(`${API}/mediaPlanner/hivestack/lineitem/${addmore[index]?.selectedLineItem.value}`)
                                            }else{
                                              clearLineItemDetails()
                                            }
                                          }}
                                          placeholder="Select Line item"
                                          options={lineItems?.map((camp) => ({label: camp.name, value: camp.id, _id: camp.id}))}
                                        />
                                      </Grid>

                                      {/* <Grid
                                        className="campaign-id-input-container"
                                        item
                                        xs={12}
                                        sm={5}
                                        md={5}
                                        style={{
                                          marginRight: "10px",
                                        }}
                                      >
                                        <input
                                          type="text"
                                          placeholder="Campaign ID"
                                          value={campaignIdInput}
                                          onChange={(e) => setCampaignIdInput(e.target.value)}
                                        />
                                      </Grid>
                                      <Grid
                                        className="line-id-input-container"
                                        item
                                        xs={12}
                                        sm={5}
                                        md={5}
                                        style={{
                                          marginRight: "10px",
                                        }}
                                      >
                                        <input
                                          type="text"
                                          placeholder="Line Item Id"
                                          value={lineItemIdInput}
                                          onChange={(e) => setLineItemIdInput(e.target.value)}
                                        />
                                      </Grid> */}

                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        style={{
                                          marginTop: "5px",
                                          float: "right",
                                        }}
                                      >
                                        {
                                          <button
                                            className={classes.addActionBtn}
                                            style={{
                                              marginBottom: "10px",
                                              width: "70px",
                                              marginRight: "10px",
                                            }}
                                            // onClick={() => addMoreItem(index)}
                                            onClick={handleAddNewHeatMap}
                                          >
                                            +
                                          </button>
                                        }
                                        {onRemoveHeatMap && (
                                          <button
                                            className={classes.addActionBtn}
                                            style={{
                                              marginLeft: "10px",
                                              width: "70px",
                                              marginRight: "10px",
                                            }}
                                            // onClick={() => removeItem(index)}
                                            onClick={onRemoveHeatMap}
                                          >
                                            -
                                          </button>
                                        )}
                                        {addmore && addmore.length - 1 === index && (
                                          <>
                                            {!isLocallyStored && demographicForMap.length ? (
                                              <button
                                                className={classes.addActionBtn}
                                                style={{
                                                  marginBottom: "10px",
                                                  width: "70px",
                                                  marginRight: "10px",
                                                }}
                                                onClick={() => setDataToLocal()}
                                              >
                                                Save
                                              </button>
                                            ) : null}
                                            {isLocallyStored && (
                                              <button
                                                className={classes.addActionBtn}
                                                style={{
                                                  marginBottom: "10px",
                                                  width: "70px",
                                                  marginRight: "10px",
                                                }}
                                                onClick={() => {
                                                  setDeleteFlag(true);
                                                }}
                                              >
                                                Delete
                                              </button>
                                            )}
                                          </>
                                        )}
                                      </Grid>
                                    </Grid>
                                  </>
                                ))
                              : null}
                          </CardContent>
                        </Card>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              )}
            </CardContent>
          </Card>
          <CampaignDetails budget={lineItemsDetails?.budget} />
        </Box>
      </Grid>

      {/* <Grid item xs={12} sm={12} md={12}>
        <TextField
          id="outlined-select-currency-native"
          type={"file"}
          inputProps={{accept:"image/png, image/jpg, image/jpeg"}}
          variant="outlined"
        />
      </Grid> */}

      {deleteFlag && (
        <Dialog
          open={deleteFlag}
          onClose={() => {
            setDeleteFlag(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Are you sure, you want to delete?</DialogTitle>
          <DialogActions>
            <Button
              style={{ color: "black", backgroundColor: "grey" }}
              onClick={() => {
                setDeleteFlag(false);
              }}
            >
              Cancel
            </Button>
            <Button
              style={{ backgroundColor: "#4F74C9", color: "white" }}
              onClick={() => {
                localStorage.removeItem("heatmapData");
                setHrsForMap(0);
                setDemographicForMap([]);
                setCountryForMap("");
                setCategoryArr([]);
                setAddmore([
                  {
                    category: "",
                    keyword: "",
                    subCategory: "",
                    categoryList: [],
                    subCategoryList: [],
                    keywordList: [],
                    mapData: [],
                    color: "",
                    selectedFrames: [],
                  },
                ]);
                setSelectedCountry([]);
                setDeleteFlag(false);
                setIsLocallyStored(false);
              }}
              autoFocus
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default HeatMap;
