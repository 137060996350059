export const LOGIN = "LOGIN";
export const LOGGED_IN_SESSION = "LOGGED_IN_SESSION";
export const REGISTER = "REGISTER";
export const ERROR = "ERROR";
export const ALERT_ERROR = "ALERT_ERROR";
export const ALERT_SUCESS = "ALERT_SUCESS";
export const MEDIA_PLANNER_SUBSCRIPTION = 'MEDIA_PLANNER_SUBSCRIPTION';

export function login(payload) {
  return {
    type: LOGIN,
    payload,
  };
}

export function register(payload) {
  return {
    type: REGISTER,
    payload,
  };
}

export function loginSuccessfully(loggedInSession) {
  return {
    type: LOGGED_IN_SESSION,
    loggedInSession,
  };
}
export function personaDropDownData(payload) {
  return {
    type: "SAVA_PERSONA_DROPDOWN_DATA",
    payload: payload,
  };
}

export function error(error) {
  return {
    type: ERROR,
    error,
  };
}
export function alertError(error) {
  return {
    type: ALERT_ERROR,
    error,
  };
}
export function alertSucess(error) {
  return {
    type: ALERT_SUCESS,
    error,
  };
}

export function subscription(subscription) {
  return {
    type: MEDIA_PLANNER_SUBSCRIPTION,
    subscription
  };
}