import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import culturalIntell from "../assets/Cultural-Intelligence.svg";
import { API } from "../constants/index";
import axios from "axios";
import { SECRET_KEY } from "../constants/index";
import * as actions from "../redux/actions/user";

import CircularProgress from "@material-ui/core/CircularProgress";
import LoginCheck from "../layouts/loginCheck";
import { 
  Modal, 
  Typography,
  Card,
  CardHeader,
  CardContent,
}from '@material-ui/core';

import ShowAlert from "./alerts";
import NewAlert from "./NewAlert";
import { tr } from "date-fns/locale";

const useStyles = (props) =>
  makeStyles((theme) => ({
    selectControl: {
      width: "100%",
      marginBottom: "8px",
    },
    paper: {},
    form: {
      width: "100%", // Fix IE 11 issue.
    },
    customfield: {
      paddingTop: "0",
      marginTop: "0",
      "& .MuiInputLabel-root.Mui-shrink": {
        color: "red",
      },
      "& .MuiOutlinedInput-input": {
        padding: "8.5px 14px",
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: "0",

        "& fieldset": {
          borderColor: "grey",
        },
        "&:hover fieldset": {},
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
        color: props.colorTheme,
      },
      "& .MuiInputLabel-root.Mui-focused": {
        color: props.colorTheme,
      },
      "& .MuiInputLabel-root.MuiInputLabel-shrink": {
        margin: "0!important",
      },
      "& .MuiInputLabel-root.MuiInputLabel-animated": {
        marginTop: "-8px",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: props.colorTheme,
      },
    },

    boxShadow: {
      position: "relative",
      padding: "40px 100px 0",
      boxShadow: "0px 0px 20px rgba(0,0,0,0.30), 0 20px 20px rgba(0,0,0,0.22)",
      borderRadius: "30px",
      backgroundColor: "white",
      marginTop: "-30px",
    },

    actionWrapper: {
      textAlign: "right",
      padding: "12px 0 20px",
      "& p": {
        color: props.colorTheme,
        fontSize: "2em",
        margin: 0,
        marginBottom: "5px",
      },
      "& button": {
        marginLeft: "10px",
      },
    },
    joinBtn: {
      backgroundColor: props.colorTheme,
    },
    loginBtn: {
      backgroundColor: props.colorTheme,
      color: "white",
    },
    errorMsg: {
      color: "red",
      marginBottom: "8px",
      marginTop: "-4px",
      display: "block",
      fontSize: ".8em",
    },
    logo: {
      width: "200px",
      margin: "0 auto",
      display: "block",
      marginBottom: "30px",
    },
    resetPassword: {
      "&:hover": {
        color: "blue",
        cursor: "pointer",
      }
    },
    resetTextField: {
      "& .MuiInputBase-input.MuiOutlinedInput-input": {
        color: "#fff"
      }
    }
  }));

export default function MediaLoginForm(props) {
  const history = useHistory();

  if (
    window.localStorage.getItem(SECRET_KEY) !== null &&
    window.localStorage.getItem("role") !== null
  ) {
    if (window.localStorage.getItem("role") === "media_owner") {
      // history.push("/media-owner");
      LoginCheck(
        "/mediaOwner/mediaPublication?name=g",
        "/media-owner/publications",
        history
      );
    }
    if (window.localStorage.getItem("role") === "media_agency") {
      // history.push("/media-planner");
      LoginCheck("/mediaPlanner/dropdowns", "/media-planner", history);
    }
    if (window.localStorage.getItem("role") === "admin") {
      // history.push("/admin/mediaOwners");
      LoginCheck("/admin/dropdowns", "/admin/mediaOwners", history);
    }
    if (window.localStorage.getItem("role") === "company") {
      // history.push("/admin/company");
      LoginCheck("/admin/dropdowns", "/company/Brands", history);
    }
    if (window.localStorage.getItem("role") === "creativeconsultant") {
      history.push("/consultant/pending-surveys");
      let localurl = localStorage.getItem('pendingSurvey');
      if(localurl){
        history.push(localurl);
      }
      // LoginCheck("/creativeconsultant/dropdowns", "/consultant/profile", history);
    }
  }
  const dispatch = useDispatch();
  const { type } = props;
  const { control, register, handleSubmit, errors } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });
  const classes = useStyles(props)();
  const [name, setName] = useState("");
  const [mediaBuyer, setMediaBuyer] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [channel, setChannel] = useState("");
  const [errorMessaging, setErrorMessaging] = useState(null);
  const [isLogin, setIsLogin] = useState(true);
  const [open, setOpen] = useState(false)
  const [resetEmail, setResetEmail] = useState("");

  const userLoggedIn = useSelector((state) => state.loggedInSession);
  const error = useSelector((state) => state.error);

  ///////errors & successs //////////
  const [ErrSucMsg, setErrSucMesg] = useState("");
  const [ErrSucType, setErrSucType] = useState(true);
  ///////////////////////////////////
  const [Spinner, setSpinner] = useState(false);

  const redirect = (role) => {
    switch (role) {
      case "Owner":
        return "/media-owner";
      case "Planner":
        return "/media-planner";
      case "Brand":
        return "/admin";
      case "Company":
        return "/company";
      case "Consultant":
        return "/consultant/profile";
      default:
    }
  };

  useEffect(() => {
    if (
      window.localStorage.getItem(SECRET_KEY) !== null &&
      window.localStorage.getItem("role") !== null
    ) {
      if (window.localStorage.getItem("role") === "media_owner") {
        // history.push("/media-owner");
        LoginCheck(
          "/mediaOwner/mediaPublication?name=g",
          "/media-owner",
          history
        );
      }
      if (window.localStorage.getItem("role") === "media_agency") {
        // history.push("/media-planner");
        LoginCheck("/mediaPlanner/dropdowns", "/media-planner", history);
      }
      if (window.localStorage.getItem("role") === "admin") {
        // history.push("/admin/mediaOwners");
        LoginCheck("/admin/dropdowns", "/admin/mediaOwners", history);
      }
      if (window.localStorage.getItem("role") === "company") {
        // history.push("/admin/company");
        LoginCheck("/admin/dropdowns", "/company/Brands", history);
      }
      if (window.localStorage.getItem("role") === "creativeconsultant") {
        history.push("/consultant/pending-surveys");
      // LoginCheck("/creativeconsultant/dropdowns", "/consultant/profile", history);
      }
    }
  }, [userLoggedIn]);

  const onLogin = (data) => {
    // dispatch(actions.alertError(null));
    //setMessageSHOW(false);
    let loginType = "";
    setSpinner(true);
    if (data.role === "media_owner") {
      loginType = "mediaOwner";
    } else if (data.role === "media_agency") {
      loginType = "mediaPlanner";
    } else if (data.role === "admin") {
      loginType = "admin";
    } else if (data.role === "company") {
      loginType = "admin-company";
    } else if (data.role === "creativeconsultant") {
      loginType = "creativeconsultant";
    } else {
    }

    const loginAs = data.role;
    delete data.role;
    delete data.name;
    delete data.username;
    axios
      .post(`${API}/${loginType}/login`, data)
      .then((res, err) => {
        if (res) {
          const sign = require("jwt-encode");
          const subscription = sign(
            { subscription: res.data.subscription === 0 ? "free" : "paid" },
            "secret"
          );
          dispatch(
            actions.subscription(res.data.subscription === 0 ? "free" : "paid")
          );
          window.localStorage.setItem("subscription", subscription);
          window.localStorage.setItem(SECRET_KEY, res.data.token);
          window.localStorage.setItem("username", res.data.name);
          window.localStorage.setItem("companyId", res.data.companyId);
          window.localStorage.setItem("usertype", res.data.usertype);
          window.localStorage.setItem("role", loginAs);
          window.localStorage.setItem("ID", res.data._id);
          if (res.data.publisherId) {
            window.localStorage.setItem("publisherId", res.data.publisherId);
          }
          if (res.data.publisherType) {
            window.localStorage.setItem(
              "publisherType",
              res.data.publisherType
            );
          }
          setSpinner(false);
        }
      })
      .catch(function (error) {
        if (error) {
          setSpinner(false);
          setErrSucMesg(
            error.response && error.response.data && error.response.data.message
          );
          setErrSucType(false);
        }
      });
  };

  const onRegister = (data) => {
    dispatch(actions.alertError(null));
    let registerType = "";
    if (data.role === "media_owner") {
      registerType = "mediaOwner";
    } else if (data.role === "media_agency") {
      registerType = "mediaPlanner";
    } else if (data.role === "admin") {
      registerType = "admin";
    } else if (data.role === "company") {
      registerType = "company";
    } else if (data.role === "creativeconsultant") {
      registerType = "creativeconsultant";
    } else {
    }
    delete data.role;
    axios
      .post(`${API}/${registerType}/register`, data)
      .then((res, err) => {
        if (res) {
          setIsLogin(true);
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };

  const resetEmailHandler = async () => {
    if (!resetEmail && resetEmail === "") {
        setErrSucType(false)
        setErrSucMesg("Please enter valid email!")
      return 
    }
    await axios.post(`${API}/mediaPlanner/sendForgotPasswordLink`, {email: resetEmail})
    .then((res) => {
      if (res.status === 200) {
        setErrSucType(true)
        setErrSucMesg(res.data.message)
        setOpen(false)
      }
    })
    .catch(err => {
      console.log("err", err)
    })
  }

  return (
    <div className={classes.root} style={{ width: "100%", display: "flex" }}>
      <NewAlert
        msg={ErrSucMsg}
        isSuccess={ErrSucType}
        close={() => setErrSucMesg("")}
      />
      <div className={classes.boxShadow} style={{ width: "100%" }}>
        <div className={classes.paper} style={{ width: "100%" }}>
          <img className={classes.logo} src={culturalIntell} />
          {/* <Registeration /> */}
          {isLogin || props.type === "Brand" || props.type === "Consultant" ? (
            <form className={classes.form} onSubmit={handleSubmit(onLogin)}>
              <TextField
                className={classes.customfield}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                inputRef={register({ required: true })}
              />
              {errors.email?.type === "required" && (
                <span className={classes.errorMsg}>This field is required</span>
              )}
              <TextField
                className={classes.customfield}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                inputRef={register({ required: true })}
                error={
                  errors.password?.type === "required" ||
                  errors.password?.type === "validate"
                }
              />
              {errors.password?.type === "required" && (
                <span className={classes.errorMsg}>This field is required</span>
              )}

              {error !== null && (
                <span className={classes.errorMsg}>{error}</span>
              )}
              {
                props.type === "Planner" ?
                    <p><span className={classes.resetPassword} onClick={() => setOpen(!open)}>Forgotten password?</span></p>
                  : ""
              }

              <div className={classes.actionWrapper}>
                {/* <p style={{ display: "block" }}>Media {type}</p> */}
                <div
                  style={
                    props.type === "Brand"
                      ? { display: "flex", justifyContent: "flex-end" }
                      : { display: "flex", justifyContent: "flex-end" }
                  }
                >
                  {/* <Button
                    onClick={() => setIsLogin(false)}
                    variant="contained"
                    color="primary"
                    className={classes.joinBtn}
                    style={props.type === "Brand" ? { display: "none" } : {}}
                  >
                    Join
                  </Button> */}

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.loginBtn}
                  >
                    {Spinner && (
                      <CircularProgress
                        color="disabled"
                        size={10}
                        style={{ marginRight: "15px" }}
                      />
                    )}
                    login
                  </Button>
                </div>
              </div>
              <TextField
                value={props.role}
                name="role"
                type="hidden"
                id="role"
                inputRef={register()}
              />
            </form>
          ) : (
            <form className={classes.form} onSubmit={handleSubmit(onRegister)}>
              <TextField
                className={classes.customfield}
                value={name}
                onChange={(e) => setName(e.target.value)}
                variant="outlined"
                margin="normal"
                fullWidth
                id="name"
                label="Name"
                name="name"
                autoComplete="name"
                inputRef={register({ required: true })}
                error={
                  errors.name?.type === "required" ||
                  errors.name?.type === "validate"
                }
              />
              {/* {errors.name?.type === "required" && (
              <span className={classes.errorMsg}>This field is required</span>
            )}
            {errors.name?.type === "validate" && (
              <span className={classes.errorMsg}>{errorMessaging}</span>
            )} */}

              <TextField
                className={classes.customfield}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                inputRef={register({ required: true })}
                error={
                  errors.email?.type === "required" ||
                  errors.email?.type === "validate"
                }
              />
              {/* {errors.email?.type === "required" && (
              <span className={classes.errorMsg}>This field is required</span>
            )}
            {errors.email?.type === "validate" && (
              <span className={classes.errorMsg}>{errorMessaging}</span>
            )} */}
              <TextField
                className={classes.customfield}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                variant="outlined"
                margin="normal"
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                inputRef={register()}
              />
              <TextField
                className={classes.customfield}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                inputRef={register({ required: true })}
                error={
                  errors.password?.type === "required" ||
                  errors.password?.type === "validate"
                }
              />
              {/* {errors.password?.type === "required" && (
              <span className={classes.errorMsg}>This field is required</span>
            )}
            {errors.password?.type === "validate" && (
              <span className={classes.errorMsg}>{errorMessaging}</span>
            )}
            {errors !== null && (
              <span className={classes.errorMsg}>{errors}</span>
            )} */}

              <div className={classes.actionWrapper}>
                {/* <p style={{ display: "block" }}>Media {type}</p> */}
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.loginBtn}
                    onClick={() => setIsLogin(true)}
                  >
                    login
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.joinBtn}
                  >
                    Join
                  </Button>
                </div>
              </div>
              <TextField
                value={props.role}
                name="role"
                type="hidden"
                id="role"
                inputRef={register()}
              />
            </form>
          )}
        </div>
      </div>
      <Modal
        open={open}
        onClose={() => {
          setOpen(!open)
          setResetEmail("")
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{display:"flex", alignItems:"center", justifyContent:"center"}}
      >
        <Card style={{padding: "2.5em", background:"linear-gradient(to right, #9211a2, #4911a2)", border: "none", color: "#fff"}}>
          <CardHeader title="Enter your email and we'll send you link to reset your password">
          </CardHeader>
          <TextField
            value={resetEmail}
            onChange={(e) => setResetEmail(e.target.value)}
            variant="outlined"
            fullWidth
            margin="normal"
            id="resetEmail"
            label="resetEmail"
            name="resetEmail"
            autoComplete="off"
            className={classes.resetTextField}
          />
          <Button
            variant="contained"
            style={{ color: "black", backgroundColor: "grey", marginTop: "0.5rem", marginRight: "0.3rem"}}
            onClick={() => {
              setOpen(!open)
              setResetEmail("")
            }}
          >Cancel</Button>
          <Button
            variant="contained"
            color="primary" 
            onClick={resetEmailHandler}
            style={{marginTop: "0.5rem"}}
          >Send</Button>
        </Card>
      </Modal>
    </div>
  );
}
