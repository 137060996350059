import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import React, { useEffect, useState } from "react";
import useStyles from "../../components/StyleClasses";
import Layout from "../../layouts/companyMainLayout";
import StepWraper from "../mediaOwner/stepWrapper";

import LinearProgress from "@material-ui/core/LinearProgress";

import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import personaImg from "../../assets/user.png";
import CustomizedTables from "../mediaOwner/mytable";

import Highcharts, { color } from "highcharts";
import HighchartReact from "highcharts-react-official";

//Button
import Button from "@material-ui/core/Button";

//Icon
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SearchIcon from "@material-ui/icons/Search";

import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import AssignmentIcon from "@material-ui/icons/Assignment";
import BusinessIcon from "@material-ui/icons/Business";
import ControlPointTwoToneIcon from "@material-ui/icons/ControlPointTwoTone";
import DateRangeIcon from "@material-ui/icons/DateRange";
import EuroIcon from "@material-ui/icons/Euro";
import ExploreIcon from "@material-ui/icons/Explore";
import LocationOnIcon from "@material-ui/icons/LocationOn";

import InputLabel from "@material-ui/core/InputLabel";

import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
//TextField
import { file } from "@babel/types";
import { Avatar } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { enGB } from "date-fns/locale";
import { useForm } from "react-hook-form";
import { DateRangePicker, END_DATE, START_DATE } from "react-nice-dates";
import "react-nice-dates/build/style.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import UploadImage from "../../components/UploadImage";
import { API, SECRET_KEY } from "../../constants/index";

import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Papa from "papaparse";
import { channel } from "redux-saga";
import arrowImg from "../../assets/arrow.png";
import blueDummy from "../../assets/blueDummy.png";
import greenDummy from "../../assets/greenDummy.png";
import ShowAlert from "../../components/alerts";
import * as actions from "../../redux/actions/user";
import Step1 from "./step2";

import { makeStyles, withStyles } from "@material-ui/core/styles";

import jsPDF from "jspdf";
import "jspdf-autotable";

function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

function paramsToObject(entries) {
  const result = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}

const CreateCampaign = () => {
  const history = useHistory();
  const urlparams = new URLSearchParams(history.location.search);
  const paramEntries = urlparams.entries();
  const params = paramsToObject(paramEntries);
  const access_token = window.localStorage.getItem(SECRET_KEY);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [name, setName] = useState("");
  const [brand, setbrand] = useState(0);
  const [Country, setCountry] = useState([]);
  const [frequency, setFrequency] = useState(0);
  const [Industry, setIndustry] = useState(0);
  const [description, setdescription] = useState("");
  const [channel, setChannel] = useState([]);
  const [AdType, setAdType] = useState([]);
  const [Spend, setSpend] = useState("");
  const [Avtar, setAvtar] = useState(null);
  const [AvtarFile, setAvtarFile] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [selectedPersonas, setSelectedPersonas] = useState([]);

  const [countriesList, setcountriesList] = useState([]);
  const [industriesList, setindustriesList] = useState([]);
  const [channesWithAdTypesList, setchannesWithAdTypesList] = useState([]);
  const [selectedChanNames, setselectedChanNames] = useState([]);
  const [acceptedChannels, setacceptedChannels] = useState([]);
  const [adtypeList, setadtypeList] = useState([]);
  const [BrandsList, setBrandsList] = useState([]);
  const [renderCounries, setrenderCounries] = useState([]);
  const [renderChannels, setrenderChannels] = useState([]);
  const [renderAdTypes, setrenderAdTypes] = useState([]);

  const [Inventories, setInventories] = useState([]);
  const [InvDigital, setInvDigital] = useState([]);
  const [InvPrint, setInvPrint] = useState([]);
  const [InvRadio, setInvRadio] = useState([]);
  const [InvOOH, setInvOOH] = useState([]);
  const [InvTelevision, setInvTelevision] = useState([]);

  const [upliftData, setUpliftData] = useState(null);

  const [SaveLoader, setSaveLoader] = useState(false);

  const [errors, setErrors] = useState({
    name: { error: false, txt: "" },
    Industry: { error: false, txt: "" },
    brand: { error: false, txt: "" },
    Country: { error: false, txt: "" },
    frequency: { error: false, txt: "" },
    description: { error: false, txt: "" },
    channel: { error: false, txt: "" },
    AdType: { error: false, txt: "" },
    dates: { error: false, txt: "" },
    Spend: { error: false, txt: "" },
    cover: { error: false, txt: "" },
  });

  const [Spinner, setSpinner] = useState(false);

  useEffect(
    () =>
      setErrors({
        ...errors,
        cover: { error: false, txt: "" },
      }),
    [Avtar]
  );
  useEffect(
    () =>
      setErrors({
        ...errors,
        dates: { error: false, txt: "" },
      }),
    [startDate]
  );

  const getDropDown = () => {
    axios
      .get(`${API}/admin/campaign/getAddCampaignData`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setindustriesList(res.data.industries);
          setcountriesList(res.data.countries);
          setchannesWithAdTypesList(res.data.channesWithAdTypes);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };

  const getBrands = () => {
    if (Industry === 0) return;
    setBrandsList([]);
    setSpinner(true);
    axios
      .get(`${API}/admin/campaign/getBrandsWithName?industry=${Industry}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setBrandsList(res.data.data);
          setSpinner(false);
        }
        if (err) {
          setSpinner(false);
        }
      })
      .catch(function (error) {
        if (error) {
          setSpinner(false);
        }
      });
  };

  useEffect(() => getDropDown(), []);
  useEffect(() => getBrands(), [Industry]);
  useEffect(() => {
    if (channesWithAdTypesList.length < 1) return;
    let selectedChan = channesWithAdTypesList.filter((e) =>
      channel.includes(e._id)
    );
    let allAds = [];
    selectedChan.forEach((e) => e.AdTypes.forEach((e) => allAds.push(e)));
    setadtypeList(allAds);
  }, [channel]);

  useEffect(() => {
    if (countriesList.length > 1) {
      let a = countriesList.filter((o) => Country.includes(o._id));
      let b = a.map((e) => e.name + " , ");

      setrenderCounries(b.slice(0, 30)[0]);
    }
  }, [Country]);
  useEffect(() => {
    if (channesWithAdTypesList.length > 1) {
      let a = channesWithAdTypesList.filter((o) => channel.includes(o._id));
      let b = a.map((e) => e.channelName);
      //setrenderChannels(b.slice(0, 30)[0]);
      setrenderChannels(b);
      let c = b.map((e) => e.toLowerCase());
      setacceptedChannels(c);
    }
  }, [channel]);
  useEffect(() => {
    if (adtypeList.length > 1) {
      let a = adtypeList.filter((o) => AdType.includes(o._id));
      let b = a.map((e) => e.name + " , ");

      setrenderAdTypes(b.slice(0, 30)[0]);
    }
  }, [AdType]);

  function downloadDigital() {
    if (InvDigital.length <= 0) return;
    var array = [];

    InvDigital.forEach((e) => {
      let toPush = { ...e };
      let sdx = new Date(e.startDate);
      let sd =
        sdx.getUTCDate().toString() +
        "-" +
        sdx.getUTCMonth().toString() +
        "-" +
        sdx.getUTCFullYear().toString();
      let edx = new Date(e.endDate);
      let ed =
        edx.getUTCDate().toString() +
        "-" +
        edx.getUTCMonth().toString() +
        "-" +
        edx.getUTCFullYear().toString();
      toPush.startDate = sd;
      toPush.endDate = ed;
      array.push(toPush);
    });

    var csv = Papa.unparse(array);

    var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    var csvURL = null;
    if (navigator.msSaveBlob) {
      csvURL = navigator.msSaveBlob(csvData, "Digital_Inventories_Report.csv");
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }

    var tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", "Digital_Inventories_Report.csv");
    tempLink.click();
  }
  function downloadPrint() {
    if (InvPrint.length <= 0) return;
    var array = [];

    InvPrint.forEach((e) => {
      let toPush = { ...e };
      let sdx = new Date(e.startDate);
      let sd =
        sdx.getUTCDate().toString() +
        "-" +
        sdx.getUTCMonth().toString() +
        "-" +
        sdx.getUTCFullYear().toString();
      let edx = new Date(e.endDate);
      let ed =
        edx.getUTCDate().toString() +
        "-" +
        edx.getUTCMonth().toString() +
        "-" +
        edx.getUTCFullYear().toString();
      toPush.startDate = sd;
      toPush.endDate = ed;
      array.push(toPush);
    });

    var csv = Papa.unparse(array);

    var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    var csvURL = null;
    if (navigator.msSaveBlob) {
      csvURL = navigator.msSaveBlob(csvData, "Print_Inventories_Report.csv");
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }

    var tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", "Print_Inventories_Report.csv");
    tempLink.click();
  }
  function downloadRadio() {
    if (InvRadio.length <= 0) return;
    var array = [];

    InvRadio.forEach((e) => {
      let toPush = { ...e };
      let sdx = new Date(e.startDate);
      let sd =
        sdx.getUTCDate().toString() +
        "-" +
        sdx.getUTCMonth().toString() +
        "-" +
        sdx.getUTCFullYear().toString();
      let edx = new Date(e.endDate);
      let ed =
        edx.getUTCDate().toString() +
        "-" +
        edx.getUTCMonth().toString() +
        "-" +
        edx.getUTCFullYear().toString();
      toPush.startDate = sd;
      toPush.endDate = ed;
      array.push(toPush);
    });

    var csv = Papa.unparse(array);

    var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    var csvURL = null;
    if (navigator.msSaveBlob) {
      csvURL = navigator.msSaveBlob(csvData, "Radio_Inventories_Report.csv");
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }

    var tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", "Radio_Inventories_Report.csv");
    tempLink.click();
  }
  function downloadOOH() {
    if (InvOOH.length <= 0) return;
    var array = [];

    InvOOH.forEach((e) => {
      let toPush = { ...e };
      let sdx = new Date(e.startDate);
      let sd =
        sdx.getUTCDate().toString() +
        "-" +
        sdx.getUTCMonth().toString() +
        "-" +
        sdx.getUTCFullYear().toString();
      let edx = new Date(e.endDate);
      let ed =
        edx.getUTCDate().toString() +
        "-" +
        edx.getUTCMonth().toString() +
        "-" +
        edx.getUTCFullYear().toString();
      toPush.startDate = sd;
      toPush.endDate = ed;
      array.push(toPush);
    });

    var csv = Papa.unparse(array);

    var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    var csvURL = null;
    if (navigator.msSaveBlob) {
      csvURL = navigator.msSaveBlob(csvData, "OOH_Inventories_Report.csv");
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }

    var tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", "OOH_Inventories_Report.csv");
    tempLink.click();
  }
  function downloadTelevision() {
    if (InvTelevision.length <= 0) return;
    var array = [];

    InvTelevision.forEach((e) => {
      let toPush = { ...e };
      let sdx = new Date(e.startDate);
      let sd =
        sdx.getUTCDate().toString() +
        "-" +
        sdx.getUTCMonth().toString() +
        "-" +
        sdx.getUTCFullYear().toString();
      let edx = new Date(e.endDate);
      let ed =
        edx.getUTCDate().toString() +
        "-" +
        edx.getUTCMonth().toString() +
        "-" +
        edx.getUTCFullYear().toString();
      toPush.startDate = sd;
      toPush.endDate = ed;
      array.push(toPush);
    });
    var csv = Papa.unparse(array);

    var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    var csvURL = null;
    if (navigator.msSaveBlob) {
      csvURL = navigator.msSaveBlob(
        csvData,
        "Television_Inventories_Report.csv"
      );
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }

    var tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", "Television_Inventories_Report.csv");
    tempLink.click();
  }

  const downloadCSV = () => {
    downloadDigital();
    downloadPrint();
    downloadRadio();
    downloadOOH();
    downloadTelevision();
  };

  const downloadAllPdf = () => {
    var doc = new jsPDF("l", "pt", "a1");

    if (InvDigital.length > 0) {
      doc.text("Digital", 50, 20);
      let headings = Object.keys(InvDigital[0]);
      let values = [];
      InvDigital.forEach((e) => values.push(Object.values(e)));
      doc.autoTable({
        head: [headings],
        body: values,
      });
    }

    if (InvPrint.length > 0) {
      let finalY = doc.lastAutoTable.finalY; // The y position on the page
      doc.text("Print", 50, finalY !== undefined ? finalY + 20 : 20);
      let headings = Object.keys(InvPrint[0]);
      let values = [];
      InvPrint.forEach((e) => values.push(Object.values(e)));
      doc.autoTable({
        head: [headings],
        body: values,
      });
    }
    if (InvRadio.length > 0) {
      let finalY = doc.lastAutoTable.finalY; // The y position on the page
      doc.text("Radio", 50, finalY !== undefined ? finalY + 20 : 20);
      let headings = Object.keys(InvRadio[0]);
      let values = [];
      InvRadio.forEach((e) => values.push(Object.values(e)));
      doc.autoTable({
        head: [headings],
        body: values,
      });
    }
    if (InvOOH.length > 0) {
      let finalY = doc.lastAutoTable.finalY; // The y position on the page
      doc.text("OOH", 50, finalY !== undefined ? finalY + 20 : 20);
      let headings = Object.keys(InvOOH[0]);
      let values = [];
      InvOOH.forEach((e) => values.push(Object.values(e)));
      doc.autoTable({
        head: [headings],
        body: values,
      });
    }
    if (InvTelevision.length > 0) {
      let finalY = doc.lastAutoTable.finalY; // The y position on the page
      doc.text("Television", 50, finalY !== undefined ? finalY + 20 : 20);
      let headings = Object.keys(InvTelevision[0]);
      let values = [];
      InvTelevision.forEach((e) => values.push(Object.values(e)));
      doc.autoTable({
        head: [headings],
        body: values,
      });
    }

    doc.save("Inventories_Report.pdf");
  };
  const downloadPdf = () => {
    downloadAllPdf();
  };

  const getInventories = () => {
    const stringPersomnas = selectedPersonas.map((e) => e._id);
    const body = {
      startDate: new Date(convert(startDate)).getTime(),
      endDate: new Date(convert(endDate)).getTime(),
      channels: channel,
      countries: Country,
      industries: [Industry],
      personas: stringPersomnas,
      acceptedChannels: acceptedChannels,
    };
    axios
      .post(`${API}/admin/campaign/getMediaInventies`, body, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res !== undefined) {
          //setInventories(res.data);
          if (res.data.digital) {
            setInvDigital(res.data.digital);
          }
          if (res.data.print) {
            setInvPrint(res.data.print);
          }
          if (res.data.radio) {
            setInvRadio(res.data.radio);
          }
          if (res.data.ooh) {
            setInvOOH(res.data.ooh);
          }
          if (res.data.television) {
            setInvTelevision(res.data.television);
          }
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };

  const getUplift = () => {
    if (Industry === 0) return;
    axios
      .get(
        `${API}/admin/campaign/getBrandUplift?industry=${Industry}&brand=${brand}`,
        {
          headers: { Authorization: `Bearer ${access_token}` },
        }
      )
      .then((res, err) => {
        if (res) {
          setUpliftData(res.data);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };

  const save = () => {
    setSaveLoader(true);
    const formData = new FormData();
    formData.append("creator", params?.creator);
    formData.append("campaign", AvtarFile);
    formData.append("industry", Industry);
    formData.append("campaignName", name);
    //formData.append("frequency", frequency);
    formData.append("description", description);
    formData.append("brand", brand);
    formData.append("frequencyUplift", 20);
    formData.append("spendAmount", Spend);

    Country.forEach((e, i) => formData.append(`countries[${i}]`, e));
    AdType.forEach((e, i) => formData.append(`addTypes[${i}]`, e));
    channel.forEach((e, i) => formData.append(`channels[${i}]`, e));
    let invs = [];
    // Inventories.forEach((elem) =>
    //   elem.inventories.forEach((e) => invs.push(e._id))
    // );
    InvDigital.forEach((k) => invs.push({ id: k._id, chan: "Digital" }));
    InvPrint.forEach((k) => invs.push({ id: k._id, chan: "Print" }));
    InvRadio.forEach((k) => invs.push({ id: k._id, chan: "Radio" }));
    InvOOH.forEach((k) => invs.push({ id: k._id, chan: "OOH" }));
    InvTelevision.forEach((k) => invs.push({ id: k._id, chan: "Television" }));
    invs.forEach((e, i) => {
      formData.append(`inventories[${i}][channelId]`, e.id);
      formData.append(`inventories[${i}][channelType]`, e.chan);
    });

    const stringPersomnas = selectedPersonas.map((e) => e._id);
    selectedPersonas.forEach((per, i) => {
      formData.append(`personas[]`, stringPersomnas[i]);
    });

    formData.append("startDate", new Date(convert(startDate)).getTime());
    formData.append("endDate", new Date(convert(endDate)).getTime());

    axios
      .post(`${API}/admin/campaign`, formData, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res, err) => {
        if (res) {
          history.push(`/admin/manageCampaign?planer=${params?.creator}`);
          setSaveLoader(false);
        }
        if (err) {
          setSaveLoader(false);
        }
      })
      .catch(function (error) {
        if (error) {
          setSaveLoader(false);
        }
      });
  };

  const step0 = () => {
    return (
      <div className={classes.boxShadow} style={{ marginTop: "0px" }}>
        <div
          className={classes.customfield}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div style={{ width: "30%" }}>
            <h4 className={classes.redHead}>Campaign Details</h4>
            <TextField
              error={errors.name.error}
              helperText={errors.name.txt}
              fullWidth
              label="Name"
              variant="outlined"
              style={{ display: "block", marginBottom: "10px" }}
              value={name}
              onChange={(e) => {
                setErrors({
                  ...errors,
                  name: { error: false, txt: "" },
                });
                setName(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircleIcon color="disabled" />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BusinessIcon color="disabled" />
                  </InputAdornment>
                ),
              }}
              error={errors.Industry.error}
              helperText={errors.Industry.txt}
              style={{ display: "block", marginBottom: "10px" }}
              fullWidth
              select
              label="Industry"
              value={Industry}
              onChange={(e) => {
                setErrors({
                  ...errors,
                  Industry: { error: false, txt: "" },
                });
                setIndustry(e.target.value);
              }}
              SelectProps={{
                native: true,
              }}
              variant="outlined"
            >
              <option disabled key={0} value={0}>
                Select...
              </option>
              {industriesList?.map((option) => (
                <option key={option._id} value={option._id}>
                  {option.name}
                </option>
              ))}
            </TextField>
            <TextField
              InputProps={
                Spinner
                  ? {
                      startAdornment: (
                        <InputAdornment position="start">
                          <CircularProgress color="disabled" size={10} />
                        </InputAdornment>
                      ),
                    }
                  : {
                      startAdornment: (
                        <InputAdornment position="start">
                          <BusinessIcon color="disabled" size={10} />
                        </InputAdornment>
                      ),
                    }
              }
              error={errors.brand.error}
              helperText={errors.brand.txt}
              style={{ display: "block", marginBottom: "10px" }}
              fullWidth
              select
              label="Brand"
              value={brand}
              onChange={(e) => {
                setErrors({
                  ...errors,
                  brand: { error: false, txt: "" },
                });
                setbrand(e.target.value);
              }}
              SelectProps={{
                native: true,
              }}
              variant="outlined"
            >
              <option disabled key={0} value={0}>
                Select...
              </option>
              {BrandsList?.map((option) => (
                <option key={option._id} value={option._id}>
                  {option.name}
                </option>
              ))}
            </TextField>

            <FormControl
              fullWidth
              component="fieldset"
              variant="outlined"
              className={[classes.selectControl, classes.customfield].join(" ")}
            >
              <InputLabel id="demo-mutiple-checkbox-label">Country</InputLabel>
              <Select
                error={errors.Country.error}
                id="outlined-select-currency-native"
                multiple
                label="Country"
                value={Country}
                onChange={(e) => {
                  {
                    setErrors({
                      ...errors,
                      Country: { error: false, txt: "" },
                    });
                    setCountry(e.target.value);
                  }
                }}
                variant="outlined"
                renderValue={(ep) => renderCounries}
              >
                <MenuItem disabled value={0}>
                  <em>Select...</em>
                </MenuItem>
                {countriesList.map((option) => (
                  <MenuItem key={option._id} value={option._id}>
                    <Checkbox checked={Country.includes(option._id)}></Checkbox>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.Country.error && (
                <span style={{ color: "red" }}>{errors.Country.txt}</span>
              )}
            </FormControl>

            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <DateRangeIcon color="disabled" />
                  </InputAdornment>
                ),
              }}
              error={errors.frequency.error}
              helperText={errors.frequency.txt}
              style={{ display: "block", marginBottom: "10px" }}
              fullWidth
              select
              label="Frequency"
              value={frequency}
              onChange={(e) => {
                setErrors({
                  ...errors,
                  frequency: { error: false, txt: "" },
                });
                setFrequency(e.target.value);
              }}
              SelectProps={{
                native: true,
              }}
              variant="outlined"
            >
              <option disabled key={0} value={0}>
                Select...
              </option>
              {[
                "Daily",
                "Weekly",
                "Monthly",
                "Quarterly",
                "Half-Yearly",
                "Annualy",
              ].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </TextField>
            <TextField
              error={errors.description.error}
              helperText={errors.description.txt}
              fullWidth
              label="Description"
              multiline
              rows={4}
              placeholder="Description"
              variant="outlined"
              value={description}
              onChange={(e) => {
                setErrors({
                  ...errors,
                  description: { error: false, txt: "" },
                });
                setdescription(e.target.value);
              }}
            />
          </div>
          <div style={{ width: "20%" }}>
            <h4 className={classes.redHead}>Spend</h4>
            <TextField
              error={errors.Spend.error}
              helperText={errors.Spend.txt}
              fullWidth
              type="number"
              label="Spend"
              variant="outlined"
              style={{ display: "block", marginBottom: "10px" }}
              value={Spend}
              onChange={(e) => {
                setErrors({
                  ...errors,
                  Spend: { error: false, txt: "" },
                });
                setSpend(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EuroIcon color="disabled" />
                  </InputAdornment>
                ),
              }}
            />
            <h4 className={classes.redHead} style={{ marginTop: "60px" }}>
              Duration
            </h4>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onStartDateChange={setStartDate}
              onEndDateChange={setEndDate}
              minimumDate={new Date()}
              minimumLength={1}
              format="dd MMM yyyy"
              locale={enGB}
            >
              {({ startDateInputProps, endDateInputProps, focus }) => (
                <div className="date-range">
                  <TextField
                    error={errors.dates.error}
                    helperText={errors.dates.txt}
                    fullWidth
                    id="startDate"
                    label="Available From"
                    variant="outlined"
                    margin="normal"
                    onChange={(e) => setStartDate(e.target.value)}
                    className={classes.customfield}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...startDateInputProps}
                  />
                  <TextField
                    error={errors.dates.error}
                    helperText={errors.dates.txt}
                    fullWidth
                    id="endDate"
                    label="Available Upto"
                    variant="outlined"
                    margin="normal"
                    className={classes.customfield}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...endDateInputProps}
                  />
                </div>
              )}
            </DateRangePicker>
          </div>
          <div style={{ width: "20%" }}>
            <h4 className={classes.redHead}>Channel</h4>
            <FormControl
              fullWidth
              component="fieldset"
              variant="outlined"
              className={[classes.selectControl, classes.customfield].join(" ")}
            >
              <InputLabel id="demo-mutiple-checkbox-label">Channel</InputLabel>
              <Select
                error={errors.channel.error}
                id="outlined-select-currency-native"
                multiple
                label="Channel"
                value={channel}
                onChange={(e) => {
                  {
                    setErrors({
                      ...errors,
                      channel: { error: false, txt: "" },
                    });
                    setChannel(e.target.value);
                  }
                }}
                variant="outlined"
                renderValue={(ep) => renderChannels}
              >
                <MenuItem disabled value={0}>
                  <em>Select...</em>
                </MenuItem>
                {channesWithAdTypesList.map((option) => (
                  <MenuItem key={option._id} value={option._id}>
                    <Checkbox checked={channel.includes(option._id)}></Checkbox>
                    {option.channelName}
                  </MenuItem>
                ))}
              </Select>
              {errors.channel.error && (
                <span style={{ color: "red" }}>{errors.channel.txt}</span>
              )}
            </FormControl>
            <FormControl
              fullWidth
              component="fieldset"
              variant="outlined"
              className={[classes.selectControl, classes.customfield].join(" ")}
            >
              <InputLabel id="demo-mutiple-checkbox-label">Ad Type</InputLabel>
              <Select
                error={errors.AdType.error}
                id="outlined-select-currency-native"
                multiple
                label="Ad Type"
                value={AdType}
                onChange={(e) => {
                  {
                    setErrors({
                      ...errors,
                      AdType: { error: false, txt: "" },
                    });
                    setAdType(e.target.value);
                  }
                }}
                variant="outlined"
                renderValue={(ep) => renderAdTypes}
              >
                <MenuItem disabled value={0}>
                  <em>Select...</em>
                </MenuItem>
                {adtypeList.map((option) => (
                  <MenuItem key={option._id} value={option._id}>
                    <Checkbox checked={AdType.includes(option._id)}></Checkbox>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.AdType.error && (
                <span style={{ color: "red" }}>{errors.AdType.txt}</span>
              )}
            </FormControl>
          </div>

          <div style={{ width: "20%" }}>
            <h4 className={classes.redHead}>Avatar/Logo</h4>
            <UploadImage
              setAvt={setAvtar}
              Avt={Avtar}
              setAvtarFile={setAvtarFile}
            />
            {errors.cover.error && (
              <span style={{ color: "red" }}>{errors.cover.txt}</span>
            )}
            <div
              style={{
                background:
                  "transparent linear-gradient(90deg, #9838DC 0%, #3986E6 100%) 0% 0% no-repeat padding-box",
                padding: "18px",
                position: "absolute",
                bottom: "0",
                right: "0",
                width: "20%",
                clipPath: "polygon(15% 0, 0 50%, 15% 100%, 100% 100%, 100% 0)",
                marginBottom: "80px",
              }}
            >
              <span
                style={{
                  color: "white",
                  marginLeft: "20px",
                }}
              >
                Spend: {"\u20AC"} {Spend}{" "}
              </span>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <button
            className={classes.addActionBtn}
            onClick={() =>
              history.push(`manageCampaign?planer=${params.creator}`)
            }
          >
            Cancel
          </button>
          <button
            className={classes.addActionBtn}
            onClick={() => {
              if (name === "") {
                setErrors({
                  ...errors,
                  name: { error: true, txt: "please enter a name" },
                });
              } else if (Industry === 0) {
                setErrors({
                  ...errors,
                  Industry: { error: true, txt: "please select an Industry" },
                });
              } else if (brand === 0) {
                setErrors({
                  ...errors,
                  brand: { error: true, txt: "please select a Brand" },
                });
              } else if (Country.length < 1) {
                setErrors({
                  ...errors,
                  Country: { error: true, txt: "please select countries" },
                });
              } else if (frequency === 0) {
                setErrors({
                  ...errors,
                  frequency: { error: true, txt: "please select a frequency" },
                });
              } else if (description === "") {
                setErrors({
                  ...errors,
                  description: {
                    error: true,
                    txt: "please Enter a Description",
                  },
                });
              } else if (channel.length < 1) {
                setErrors({
                  ...errors,
                  channel: { error: true, txt: "please select channels" },
                });
              } else if (AdType.length < 1) {
                setErrors({
                  ...errors,
                  AdType: { error: true, txt: "please select Ad Types" },
                });
              } else if (startDate === "") {
                setErrors({
                  ...errors,
                  dates: { error: true, txt: "please select dates" },
                });
              } else if (!Spend) {
                setErrors({
                  ...errors,
                  Spend: { error: true, txt: "please enter Spend" },
                });
              } else if (!AvtarFile) {
                setErrors({
                  ...errors,
                  cover: { error: true, txt: "please select a Cover Image" },
                });
              } else {
                setStep(1);
                getUplift();
                const sc = channesWithAdTypesList.filter((e) =>
                  channel.includes(e._id)
                );
                const scNames = sc.map((e) => e.channelName);
                setselectedChanNames(scNames);
              }
            }}
          >
            Next
          </button>
        </div>
      </div>
    );
  };
  const step2 = () => {
    let tdDigital = [];
    InvDigital.forEach((e, i) => {
      tdDigital.push({
        data: [
          e.kpiBenchMark,
          e.subchannelType,
          e.mediaType,
          e.mediaFormats,
          convert(e.startDate),
          convert(e.endDate),
          e.ctr,
          e.unitCost,
          e.frequency,
        ],
        _id: i,
      });
    });
    let tdPrint = [];
    InvPrint.forEach((e, i) => {
      tdPrint.push({
        data: [
          e.audience,
          e.circulation,
          e.readership,
          e.formatSize,
          convert(e.startDate),
          convert(e.endDate),
          e.volumeTotalcms,
          e.unitCost,
          e.frequency,
        ],
        _id: i,
      });
    });
    let tdRadio = [];
    InvRadio.forEach((e, i) => {
      tdRadio.push({
        data: [
          e.channel,
          e.audience,
          e.mediaType,
          e.listenerss,
          convert(e.startDate),
          convert(e.endDate),
          e.NetGross,
          e.Quantity,
          e.netTotal,
        ],
        _id: i,
      });
    });
    let tdOOH = [];
    InvOOH.forEach((e, i) => {
      tdOOH.push({
        data: [
          e.channel,
          e.audience,
          e.mediaType,
          e.listenerss,
          convert(e.startDate),
          convert(e.endDate),
          e.NetGross,
          e.Quantity,
          e.netTotal,
        ],
        _id: i,
      });
    });
    let tdTelevision = [];
    InvTelevision.forEach((e, i) => {
      tdTelevision.push({
        data: [
          e.adUnit,
          e.audience,
          e.mediaType,
          e.rate,
          convert(e.startDate),
          convert(e.endDate),
          e.netGross,
          e.quantity,
          e.netTotal,
        ],
        _id: i,
      });
    });
    return (
      <div className={classes.boxShadow}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Input
            placeholder="Filter"
            style={{
              outline: "none",
              backgroundColor: "white",
              border: "solid 2px #E5E5E5",
              color: "black",
              borderRadius: "23px",
              paddingLeft: "5px",
              margin: "7px",
              width: "30%",
            }}
            disableUnderline
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon style={{ color: "red" }} />
              </InputAdornment>
            }
          ></Input>
          <button
            style={{
              padding: "0",
              backgroundColor: "transparent",
              border: "none",
            }}
            onClick={() => downloadCSV()}
          >
            <span style={{ marginBottom: "10px" }}>CSV</span>{" "}
            <CloudDownloadIcon width="30px" />
          </button>
          <button
            style={{
              padding: "0",
              backgroundColor: "transparent",
              border: "none",
            }}
            onClick={() => downloadPdf()}
          >
            <span style={{ marginBottom: "10px" }}>PDF</span>{" "}
            <CloudDownloadIcon width="30px" />
          </button>
        </div>
        {tdDigital.length > 0 && (
          <div>
            <h2>Digital</h2>
            <CustomizedTables
              headings={[
                "KPI",
                "Sub Channel",
                "Media Type",
                "Format",
                "Start Date",
                "End Date",
                "CTR",
                "Unit Cost",
                "Frequency",
              ]}
              listdata={tdDigital}
              handleDelete={(_id) => {
                const removed = tdDigital.filter((e) => e._id !== _id);
                setInvDigital(removed);
              }}
            />
          </div>
        )}
        {tdPrint.length > 0 && (
          <div>
            <h2>Print</h2>
            <CustomizedTables
              headings={[
                "Audience",
                "Circulatio",
                "Readership",
                "Format",
                "Start Date",
                "End Date",
                "CMS",
                "Unit Cost",
                "Frequency",
              ]}
              listdata={tdPrint}
              handleDelete={(_id) => {
                const removed = tdPrint.filter((e) => e._id !== _id);
                setInvPrint(removed);
              }}
            />
          </div>
        )}
        {tdRadio.length > 0 && (
          <div>
            <h2>Radio</h2>
            <CustomizedTables
              headings={[
                "Channel",
                "Audience",
                "Media Type",
                "Listenerss",
                "Start Date",
                "End Date",
                "Net Gross",
                "Quantity",
                "Net Total",
              ]}
              listdata={tdRadio}
              handleDelete={(_id) => {
                const removed = tdRadio.filter((e) => e._id !== _id);
                setInvRadio(removed);
              }}
            />
          </div>
        )}
        {tdOOH.length > 0 && (
          <div>
            <h2>OOH</h2>
            <CustomizedTables
              headings={[
                "Channel",
                "Audience",
                "Media Type",
                "Listenerss",
                "Start Date",
                "End Date",
                "Net Gross",
                "Quantity",
                "Net Total",
              ]}
              listdata={tdOOH}
              handleDelete={(_id) => {
                const removed = tdOOH.filter((e) => e._id !== _id);
                setInvOOH(removed);
              }}
            />
          </div>
        )}
        {tdTelevision.length > 0 && (
          <div>
            <h2>Television</h2>
            <CustomizedTables
              headings={[
                "Ad Unit",
                "Audience",
                "Media Type",
                "Rate",
                "Start Date",
                "End Date",
                "Net Gross",
                "Quantity",
                "Net Total",
              ]}
              listdata={tdTelevision}
              handleDelete={(_id) => {
                const removed = tdTelevision.filter((e) => e._id !== _id);
                setInvTelevision(removed);
              }}
            />
          </div>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <button className={classes.addActionBtn} onClick={() => setStep(1)}>
            Previous
          </button>
          <button
            className={classes.addActionBtn}
            onClick={() => {
              setStep(3);
            }}
          >
            Next
          </button>
        </div>
      </div>
    );
  };

  const step3 = () => {
    return (
      <div className={classes.boxShadow}>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div style={{ width: "75%" }}>
            <h4 className={classes.redHead}>
              Forecasted Brand Uplift Based on Persona Settings
            </h4>
            <div style={{ display: "flex", justifyContent: "space-betwen" }}>
              <div
                style={{
                  boxShadow:
                    "0px 0px 10px rgba(0,0,0,0.20), 0 0 10px rgba(0,0,0,0.15)",
                  borderRadius: "16px",
                  marginRight: "30px",
                  marginTop: "30px",
                }}
                id="dummyBlue"
              >
                <h1
                  style={{
                    fontSize: "25px",
                    fontFamily: "monospace",
                    margin: "20px 0 0 30px",
                  }}
                >
                  +{parseFloat(upliftData?.brandUplift).toFixed(2)}%
                </h1>
                <h2
                  style={{
                    fontSize: "10px",
                    fontFamily: "monospace",
                    margin: "0 0 0 30px",
                    fontWeight: "400",
                  }}
                >
                  BRAND LIFT TOTAL
                </h2>
                <h2
                  style={{
                    fontSize: "10px",
                    fontFamily: "monospace",
                    margin: "0 0 0 30px",
                    fontWeight: "400",
                  }}
                >
                  All metrics summarised
                </h2>
              </div>
              <div
                style={{
                  boxShadow:
                    "0px 0px 10px rgba(0,0,0,0.20), 0 0 10px rgba(0,0,0,0.15)",
                  borderRadius: "16px",
                  marginRight: "30px",
                  marginTop: "30px",
                }}
                id="dummygreen"
              >
                <h2
                  style={{
                    fontSize: "25px",
                    fontFamily: "monospace",
                    margin: "20px 0 0 30px",
                  }}
                >
                  +
                  {upliftData?.totalBrandUplift
                    ? parseFloat(upliftData?.totalBrandUplift).toFixed(2)
                    : "19.40"}
                  %
                </h2>
                <h2
                  style={{
                    fontSize: "10px",
                    fontFamily: "monospace",
                    margin: "0 0 0 30px",
                    fontWeight: "400",
                  }}
                >
                  TOTAL BRAND LIFT INDEX vs DATABASE
                </h2>
                <h2
                  style={{
                    fontSize: "10px",
                    fontFamily: "monospace",
                    margin: "0 0 0 30px",
                    fontWeight: "400",
                  }}
                >
                  Total Brandlift Compared to Benchmark
                </h2>
              </div>
            </div>
            <div>
              {[
                { name: "Awareness", val: upliftData?.awareness },
                { name: "Consideration", val: upliftData?.consideration },
                { name: "Preference", val: upliftData?.preference },
                { name: "Action Intent", val: upliftData?.actionIntent },
              ].map((e) => (
                <div style={{ marginTop: "30px" }}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span
                      style={{
                        marginBottom: "0",
                        color: "grey",
                        fontSize: "20px",
                      }}
                    >
                      {" "}
                      {e.name}
                    </span>
                    <span
                      style={{
                        marginBottom: "0",
                        color: "grey",
                        fontSize: "20px",
                      }}
                    >
                      {" "}
                      {e.val} % &#9650;
                    </span>
                  </div>

                  <div
                    style={{
                      backgroundColor: "grey",
                      height: "20px",
                      width: "100%",
                      borderRadius: "10px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: `rgb(${Math.random() * 255} ${
                          Math.random() * 255
                        } ${Math.random() * 255})`,
                        height: "20px",
                        width: `${e.val}%`,
                        borderRadius: "10px",
                      }}
                    ></div>
                  </div>
                </div>
              ))}
              <div style={{ marginTop: "50px" }}>
                <h2 className={classes.redHead}>Channels</h2>
                {/* <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <BusinessIcon color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                  disabled
                  style={{ display: "block", marginBottom: "10px" }}
                  label="Channel"
                  value={channel}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                ></TextField> */}
                <RadioGroup
                  aria-label="Channel"
                  name="channel"
                  value={selectedChanNames}
                >
                  {selectedChanNames.map((e) => (
                    <FormControlLabel
                      value={e}
                      control={<Radio />}
                      label={e}
                      //////change label later!!!!!!!!
                    />
                  ))}
                </RadioGroup>
                <div
                  style={{
                    background:
                      "transparent linear-gradient(90deg, #9838DC 0%, #3986E6 100%) 0% 0% no-repeat padding-box",
                    padding: "18px",
                    position: "absolute",
                    bottom: "0",
                    right: "0",
                    width: "20%",
                    clipPath:
                      "polygon(15% 0, 0 50%, 15% 100%, 100% 100%, 100% 0)",
                    marginBottom: "80px",
                  }}
                >
                  <span
                    style={{
                      color: "white",
                      marginLeft: "20px",
                    }}
                  >
                    Spend: {"&#xa3;"} {Spend}{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div style={{ width: "20%" }}>
            <span className={classes.redHead}>personas</span>
            <div
              style={{
                maxHeight: "500px",
                overflow: "auto",
                width: "100%",
              }}
            >
              {selectedPersonas.map((per) => (
                <div
                  className={classes.boxShadow}
                  style={{
                    marginTop: "10px",
                    margin: "20%",
                    textAlign: "center",
                    borderRadius: "8px",
                  }}
                >
                  <img
                    alt="media.Img"
                    src={personaImg}
                    style={{
                      width: "100%",
                      display: "block",
                      backgroundColor: `${per.col}`,
                      borderRadius: "50%",
                    }}
                  ></img>
                  <span
                    style={{
                      color: "blue",
                      fontSize: "15px",
                    }}
                  >
                    {per.name}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "40px",
          }}
        >
          <button className={classes.addActionBtn} onClick={() => setStep(2)}>
            Previous
          </button>
          {!SaveLoader && (
            <button
              className={classes.addActionBtn}
              onClick={() => save()}
              style={{ backgroundColor: "green" }}
            >
              Save
            </button>
          )}
        </div>
        {SaveLoader && <LinearProgress style={{ marginTop: "10px" }} />}
      </div>
    );
  };
  return (
    <div>
      {step === 0 && <StepWraper step={0} />}
      {step === 1 && <StepWraper step={1} />}
      {step === 2 && <StepWraper step={2} />}
      {step === 3 && <StepWraper step={3} />}
      {step === 0 && step0()}
      {step === 1 && (
        <Step1
          setStep={(ind) => {
            getInventories();
            setStep(ind);
          }}
          selectedPersonas={selectedPersonas}
          setSelectedPersonas={setSelectedPersonas}
          creator={params.creator}
        />
      )}
      {step === 2 && step2()}
      {step === 3 && step3()}
    </div>
  );
};
export default Layout(CreateCampaign);
