import React, { useState, useEffect } from "react";
import MainLayout from "../../layouts/adminMainLayout";

import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Select,
} from "@material-ui/core";
//TextField
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useStyles from "../../components/StyleClasses";
import { API, SECRET_KEY } from "../../constants/index";
import axios from "axios";

import NewAlert from "../../components/NewAlert";
import EyeIcon from "../../assets/eyePass.svg";
import viewIcon from "../../assets/visibility.svg";

import * as actions from "../../redux/actions/user";
function paramsToObject(entries) {
  const result = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}
const MediaPlannerprofile = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const urlparams = new URLSearchParams(history.location.search);
  const paramEntries = urlparams.entries();
  const params = paramsToObject(paramEntries);
  const [moID, setmoID] = useState(params?.id ? params.id : "");
  const [name, setname] = useState("");
  const [userName, setuserName] = useState("");
  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");
  const [Status, setStatus] = useState(1);
  const [companyId, setCompanyId] = useState(0);
  const [companyData, setCompanyData] = useState([]);
  const [switchItem, setSwitchItem] = useState(false);

  ///////errors & successs //////////
  const [ErrSucMsg, setErrSucMesg] = useState("");
  const [ErrSucType, setErrSucType] = useState(true);
  ///////////////////////////////////

  const [loading, setloading] = useState(false);
  const [errors, setErrors] = useState({
    userName: { error: false, txt: "" },
    name: { error: false, txt: "" },
    email: { error: false, txt: "" },
    Status: { error: false, txt: "" },
    password: { error: false, txt: "" },
  });

  const access_token = window.localStorage.getItem(SECRET_KEY);

  const saveUser = () => {
    const body = { email: email, username: userName, name: name, 
      companyId: companyId, userPassword : password
       };
    axios
      .post(`${API}/admin/mediaPlanner`, body, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setTimeout(() => {
            history.push("/admin/mediaPlanners");
          }, 1500);
          setErrSucMesg(res.data.message);
          setErrSucType(true);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.data) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
        }
      });
  };
  const updateUser = () => {
    const body = {
      email: email,
      username: userName,
      name: name,
      status: Status,
      companyId: companyId,
      userPassword: password
    };
    axios
      .put(`${API}/admin/mediaPlanner/${params?.id}`, body, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setTimeout(() => {
            history.push("/admin/mediaPlanners");
          }, 1500);
          setErrSucMesg(res.data.message);
          setErrSucType(true);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.data) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
        }
      });
  };

  const resentPassword = () => {
    const body = {
      email: email,
      username: userName,
      name: name,
      status: Status,
      companyId: companyId,
      // userPassword: passwords
    };

    axios
      .patch(`${API}/admin/mediaPlanner/resetPassword/${params?.id}`, body, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setErrSucMesg(res.data.message);
          setErrSucType(true);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.data) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
        }
      });
  };

  const getUserData = () => {
    if (params.id) {
      setloading(true);
      axios
        .get(`${API}/admin/mediaPlanner/${moID}`, {
          headers: { Authorization: `Bearer ${access_token}` },
        })
        .then((res, err) => {
          if (res) {
            setloading(false);
            setname(res.data.name);
            setuserName(res.data.username);
            setemail(res.data.email);
            setStatus(res.data.status);
            setCompanyId(res.data.companyId._id);
          }
          if (err) {
          }
        })
        .catch(function (error) {
          if (error) {
          }
        });
    }
  };
  useEffect(() => {getUserData(); getCompanyList();}, []);
  
  const getCompanyList = () => {
    setloading(true);
    axios
      .get(`${API}/admin/admin-company`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setloading(false);
          setCompanyData(res.data.data);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };
  
  const handlePasswordView = (e) => {
    e.preventDefault();
    setSwitchItem(!switchItem);
  };

  return (
    <div className={classes.boxShadow} style={{ marginTop: "40px" }}>
      <NewAlert
        msg={ErrSucMsg}
        isSuccess={ErrSucType}
        close={() => setErrSucMesg("")}
      />
      <div className={classes.customfield}>
        <span
          style={{
            color: "#FB6D6A",
            fontWeight: "600",
            margin: "0",
            fontSize: "20px",
            marginLeft: "20px",
          }}
        >
          {params.id ? "Media Planner Profile" : "Create Media Planner"}
        </span>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "20px",
            }}
          >
            <div style={{ width: "50%" }}>
              <TextField
                style={{ display: "block", marginBottom: "10px" }}
                id="companyName"
                select
                fullWidth
                label="Company"
                value={companyId}
                onChange={(e) => {
                  setCompanyId(e.target.value);
                }}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              >
                <option disabled key={0} value={0}>
                  Select...
                </option>
                {companyData.map((option) => (
                  <option key={option._id} value={option._id}>
                    {option.companyName}
                  </option>
                ))}
              </TextField>
              <TextField
                error={errors.name.error}
                helperText={errors.name.txt}
                fullWidth
                placeholder="Name"
                label="Name"
                variant="outlined"
                style={{ display: "block", marginBottom: "10px" }}
                value={name}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    name: { error: false, txt: "" },
                  });
                  setname(e.target.value);
                }}
              />
              <TextField
                error={errors.userName.error}
                helperText={errors.userName.txt}
                fullWidth
                placeholder="User Name"
                label="User Name"
                variant="outlined"
                style={{ display: "block", marginBottom: "10px" }}
                value={userName}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    userName: { error: false, txt: "" },
                  });
                  setuserName(e.target.value);
                }}
              />
              <TextField
                error={errors.email.error}
                helperText={errors.email.txt}
                fullWidth
                placeholder="Email"
                label="Email"
                autoComplete="new-email"
                variant="outlined"
                style={{ display: "block", marginBottom: "10px" }}
                value={email}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    email: { error: false, txt: "" },
                  });
                  setemail(e.target.value);
                }}
              />
              <div className={classes.showPassword}>
              <TextField
                error={errors.password.error}
                helperText={errors.password.txt}
                fullWidth
                placeholder="Password"
                label="Password"
                autoComplete="new-password"
                type={switchItem ? 'text' : 'password'}
                variant="outlined"
                style={{ display: "block", marginBottom: "10px" }}
                value={password}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    password: { error: false, txt: "" },
                  });
                  setPassword(e.target.value);
                }}
              />
              {
                password && (
                  <img
                    style={{ cursor: 'pointer' }}
                    src={!switchItem ? viewIcon : EyeIcon}
                    alt="Password"
                    className={classes.showImg}
                    onClick={(e) => handlePasswordView(e)}
                  />
                )
              }
              </div>

              {params.id && (
                <TextField
                  error={errors.Status.error}
                  helperText={errors.Status.txt}
                  style={{ display: "block", marginBottom: "10px" }}
                  select
                  fullWidth
                  label="Status"
                  value={Status}
                  onChange={(e) => {
                    setErrors({
                      ...errors,
                      Status: { error: false, txt: "" },
                    });
                    setStatus(e.target.value);
                  }}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key="active" value={1} style={{ color: "green" }}>
                    Active
                  </option>
                  <option key="active" value={0} style={{ color: "red" }}>
                    InActive
                  </option>
                </TextField>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <div style={{flex: 2}}>
                  <button
                    className={classes.addActionBtn}
                    onClick={() => history.push("/admin/mediaPlanners")}
                    style={{ backgroundColor: "grey" }}
                  >
                    Cancel
                  </button>

                </div>
                <div style={{flex: "1", display:"flex", flexDirection:"row", justifyContent: "space-around"}}>

                  {
                    params?.id ? 
                    <button
                      className={classes.addActionBtn}
                      onClick={() => {
                        if (name === "")
                          setErrors({
                            ...errors,
                            name: { error: true, txt: "please enter name" },
                          });
                        if (userName === "")
                          setErrors({
                            ...errors,
                            userName: {
                              error: true,
                              txt: "please enter user name",
                            },
                          });
                        if (email === "")
                          setErrors({
                            ...errors,
                            email: { error: true, txt: "please enter email" },
                          });
                          // if (!params.id && password === "")
                          // setErrors({
                          //   ...errors,
                          //   password: { error: true, txt: "please enter password" },
                          // });
                        if (Status === null)
                          setErrors({
                            ...errors,
                            Status: { error: true, txt: "please select Status" },
                          });

                        if (
                          name !== "" &&
                          userName !== "" &&
                          email !== "" &&
                          Status !== null
                        ) {
                          resentPassword()
                        }
                      }}
                    >
                      Reset password
                    </button> : ""
                  }
                  <button
                    className={classes.addActionBtn}
                    onClick={() => {
                      if (name === "")
                        setErrors({
                          ...errors,
                          name: { error: true, txt: "please enter name" },
                        });
                      if (userName === "")
                        setErrors({
                          ...errors,
                          userName: {
                            error: true,
                            txt: "please enter user name",
                          },
                        });
                      if (email === "")
                        setErrors({
                          ...errors,
                          email: { error: true, txt: "please enter email" },
                        });
                        if (!params.id && password === "")
                        setErrors({
                          ...errors,
                          password: { error: true, txt: "please enter password" },
                        });
                      if (Status === null)
                        setErrors({
                          ...errors,
                          Status: { error: true, txt: "please select Status" },
                        });

                      if (
                        name !== "" &&
                        userName !== "" &&
                        email !== "" &&
                        Status !== null
                      ) {
                        params.id ? updateUser() : saveUser();
                      }
                    }}
                  >
                    {params.id ? "Update" : "Create User"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default MainLayout(MediaPlannerprofile);
