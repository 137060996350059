import { Avatar, Button, Card, CardActions, CardContent, CardHeader, Checkbox, CircularProgress, Grid, Modal, TextField, Typography } from "@material-ui/core"
import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { makeStyles, withStyles } from "@material-ui/styles"
import Axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { API, SECRET_KEY } from "../../../../constants/index"
import MainLayout from "../../../../layouts/newMainLayoutPlanner"

const useTableStyles = makeStyles({
    table: {
      minWidth: 700,
      maxWidth: 2000,
    },
  })

const StyledTableRow = withStyles((theme) => ({
    root: {},
  }))(TableRow)
  
const StyledTableCell = withStyles((theme) => ({
    head: {
        color: theme.palette.common.white,
        fontSize: 12,
        paddingBottom: "2px",
        paddingTop: "2px",
    },
    body: {
        fontSize: 12,
        border: "1px solid #D2D2D2",
        color: "#4F74C9",
        paddingBottom: "2px",
        paddingTop: "2px",
    },
}))(TableCell)

const useStyles = makeStyles(() => ({
    bodyHeaderTextWrapper: {
        margin: "auto 0"
    },
    bodyHeaderText: {
        margin: "0 3rem"
    },
    bodyHeaderInput: {
        margin: "0 3rem"
    },
    bodyHeaderInputWrapper: {
        textAlign: "right",
        margin: "2rem 0"
    },
    bodyTable: {
        "& .MuiTableCell-root.MuiTableCell-body": {
            padding: "0 16px !important"
        }
    }
}))

const rowData = [
    {
        name: "DivaMagazine_300x250",
        publisher: "DIVA Magazine",
        zoneType: "Standard",
        dimension: "300x250",
        assigned: "-",
        id: 1
    },
    {
        name: "DivaMagazine_728x90",
        publisher: "DIVA Magazine",
        zoneType: "Standard",
        dimension: "728x90",
        assigned: "-",
        id: 2
    },
    {
        name: "EasternEye_728x90",
        publisher: "Eastern Eye (AMG)",
        zoneType: "Standard",
        dimension: "728x90",
        assigned: "-",
        id: 3
    },
    {
        name: "EasternEye_300X250",
        publisher: "Eastern Eye (AMG)",
        zoneType: "Standard",
        dimension: "300x250",
        assigned: "-",
        id: 4
    },                      
     {
        name: "GG2 Master Leaderboard-728x90",
        publisher: "GG2 (AMG)",
        zoneType: "Standard",
        dimension: "728x90",
        assigned: "-",
        id: 5
    },                            
    {
        name: "GG2 MPU-300x250",
        publisher: "GG2 (AMG)",
        zoneType: "Standard",
        dimension: "300x250",
        assigned: "-",
        id: 6
    },                     
    {
        name: "AsianHospitality_728x90",
        publisher: "Asian Hospitality (AMG)",
        zoneType: "Standard",
        dimension: "728x90",
        assigned: "-",
        id: 7
    },                      
    {
        name: "Asian Trader_728x90",
        publisher: "Asian Trader (AMG)",
        zoneType: "Standard",
        dimension: "728x90",
        assigned: "-",
        id: 8
    },
    {
        name: "PharmacyBusiness_728x90",
        publisher: "Pharmacy Business (AMG)",
        zoneType: "Standard",
        dimension: "728x90",
        assigned: "-",
        id: 9
    },
    {
        name: "PharmacyBusiness_300X250",
        publisher: "Pharmacy Business (AMG)",
        zoneType: "Standard",
        dimension: "300x250",
        assigned: "-",
        id: 10
    },                 
    {
        name: "Asian times_300X250",
        publisher: "Asian Times (AMG)",
        zoneType: "Standard",
        dimension: "300x250",
        assigned: "-",
        id: 11
    },              
    {
        name: "BangladeshWeekly_728x90",
        publisher: "Bangladesh Weekly (AMG)",
        zoneType: "Standard",
        dimension: "728x90",
        assigned: "-",
        id: 12
    },
    {
        name: "BangladeshWeekly_300x250",
        publisher: "Bangladesh Weekly (AMG)",
        zoneType: "Standard",
        dimension: "300x250",
        assigned: "-",
        id: 13
    },
    {
        name: "SriLankaWeekly_728x90",
        publisher: "Srilanka Weekly (AMG)",
        zoneType: "Standard",
        dimension: "728x90",
        assigned: "-",
        id: 14
    }
]

const AddZone = () => {
  const history = useHistory()
  const access_token = localStorage.getItem(SECRET_KEY)
  const label = {inputProps: { 'aria-label': 'Checkbox demo' }}
  const classesTable = useTableStyles()
  const classess = useStyles()
  const [zoneListLoading, setZoneListLoading] = useState(false)
  const [zoneList, setZoneList] = useState()
  const [rowChecked, setRowChecked] = useState()

  useEffect(() => {
    console.log("addzone")
    setZoneListLoading(true)
    Axios.get(`${API}/mediaPlanner/AdButler/getAllZones?offset=0&type=Standard`, {
        headers: {
            Authorization: `Bearer ${access_token}`
        }
    })
    .then(res => {
        setZoneListLoading(false)
        setZoneList(res.data.responseData)
        // const d = []
        // res.data.responseData.forEach(item => {
        //     setRowChecked
        // })
    })
    .catch(err => {
        setZoneListLoading(false)
        console.log("err", err)
    })
  }, [])

  const checkHandler = (index) => {
    // const d = [...rowChecked]
    // if (d.checked) {
    //     d.checked
    // }
    return
  }

  if (zoneListLoading) {
      return <CircularProgress />
  }

  return (
      <>
        <Card style={{
            width: 1200,
            backgroundColor: 'primary.dark',
            '&:hover': {
            backgroundColor: 'primary.main',
            opacity: [0.9, 0.8, 0.7]
            }}
        }>
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: "red" }} aria-label="recipe">
                        +
                    </Avatar>
                }
                title="Select Zone"
            />
            <Grid container spacing={3}>
                <Grid xs={12} sm={12} md={6} lg={6} className={classess.bodyHeaderTextWrapper}>
                    <Typography className={classess.bodyHeaderText}>Eligible Zones for Cultural Intelligence</Typography>
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6} className={classess.bodyHeaderInputWrapper}>
                    <TextField
                        className={classess.bodyHeaderInput}
                        defaultValue=""
                        variant="outlined"
                        margin="normal"
                        name="values"
                        id="values"
                        style={{ marginRight: "0 0.3rem" }}
                        placeholder="Search..."
                    />
                </Grid>
            </Grid>
            <TableContainer
                component={Paper}
                style={{ margin: "0 20px 20px 20px", maxHeight: "400px", width: "97%"}}
            >
                <Table stickyHeader className={classesTable.table} aria-label="customized table">
                    <TableHead>
                        <TableRow
                            style={{
                                background: "linear-gradient(to right, #9211a2, #4911a2)",
                            }}
                        >
                            <TableCell style={{ color: "white", background: "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))"}}></TableCell>
                            <TableCell style={{ color: "white", background: "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))"}}>Name</TableCell>
                            <TableCell style={{ color: "white", background: "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))"}}>Publisher</TableCell>
                            <TableCell style={{ color: "white", background: "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))"}}>zone Type</TableCell>
                            <TableCell style={{ color: "white", background: "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))"}}>Width</TableCell>
                            <TableCell style={{ color: "white", background: "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))"}}>Height</TableCell>
                            <TableCell style={{ color: "white", background: "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))"}}>Dimensions</TableCell>
                            {/* <TableCell style={{ color: "white", background: "linear-gradient(rgb(73, 17, 162), rgb(146, 17, 162))"}}>Assigned</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody className={classess.bodyTable}>
                        {
                            zoneList?.map(item => 
                                <TableRow>
                                    <TableCell component="th" scope="row"><Checkbox {...label} onChange={() => checkHandler(item.id)}/></TableCell>
                                    <TableCell component="th" scope="row">{item.name}</TableCell>
                                    <TableCell component="th" scope="row">{item.publisher}</TableCell>
                                    <TableCell component="th" scope="row">{item.object}</TableCell>
                                    <TableCell component="th" scope="row">{item.width}</TableCell>
                                    <TableCell component="th" scope="row">{item.height}</TableCell>
                                    <TableCell component="th" scope="row">{item.dimensions}</TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <CardActions style={{float:"right"}}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => history.push("/media-planner/campaign/report")}
                >
                    Back
                </Button>
            </CardActions>
        </Card>
      </>
  )
}

export default MainLayout(AddZone)