/* eslint-disable react-hooks/rules-of-hooks */
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MailIcon from "@material-ui/icons/Mail";
import MenuIcon from "@material-ui/icons/Menu";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import icon from "../assets/CI-Icon.svg";
import culturalIntellWhite from "../assets/CI-Logo-white.png";

import axios from "axios";
import { useDispatch } from "react-redux";
import { API } from "../constants/index";
import { SaveAdminDropDownData } from "../store/actions/admin/intelligence";

import { green } from "@material-ui/core/colors";
import * as actions from "../redux/actions/user";
import Footer from "./footer";

const drawerWidth = window.screen.width / 6;

const useStyles = (icon) =>
  makeStyles((theme) => ({
    root: {
      display: "flex",
      // width: "83%",
      // [theme.breakpoints.down("sm")]: {
      //   width: "100%",
      // },
    },
    drawer: {
      backgroundColor: "red",
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      background: "white",
      "& .MuiToolbar-regular": {
        minHeight: "50px",
      },
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      backgroundImage: `url(${icon}), linear-gradient(to right, #9211a2, #4911a2)`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      borderLeft: "10px solid #f64d0c",
      width: drawerWidth,
    },
    logoutBtn: {
      background: "#4F74C9",
      fontWeight: "400",
      color: "white",
      marginLeft: "auto",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    topicon: {
      width: "85%",
      margin: "20px auto",
    },
    bgicon: {
      position: "absolute",
      top: 0,
    },
    sideMenu: {
      "& li": {
        color: "white",
        listStyleType: "none",
        paddingBottom: "10px",
        cursor: "pointer",
        "& a": {
          color: "white",
          textDecoration: "none",
        },
      },
    },
  }));

const MainLayout = (WrappedComponent) => (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const loggedInSession = useSelector((state) => state.loggedInSession);
  const classes = useStyles(icon)();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const login = () => {
    dispatch(actions.error(null));
    dispatch(actions.loginSuccessfully(null));
    history.push("/login");
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root} style={{ backgroundColor: "#DAD8E5" }}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <nav
        className={classes.drawer}
        aria-label="mailbox folders"
        style={{ background: "red" }}
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css" style={{ background: "red" }}>
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <img className={classes.topicon} src={culturalIntellWhite} />
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css" style={{ background: "red" }}>
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <img className={classes.topicon} src={culturalIntellWhite} />
            <ul className={classes.sideMenu}>
              <li>
                <Link style={{ fontWeight: "300", color: "white" }} to="/">
                  Home
                </Link>
              </li>
            </ul>
          </Drawer>
        </Hidden>
      </nav>
      <div className={classes.toolbar} />
      <div>
        <Grid
          spacing={0}
          style={{ width: "100%", padding: "60px 30px", minHeight: "100vh" }}
        >
          <Grid item md={12}>
            <WrappedComponent {...props} />
          </Grid>
        </Grid>
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default MainLayout;
