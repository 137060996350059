import "./channel-assignment.css"

import { Button } from "@material-ui/core"
import { useMutation, useQuery } from "@tanstack/react-query"
import axios from "axios"
import moment from "moment/moment"
import React, { createContext, useContext, useEffect, useRef, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { useAccessToken } from "../../../Hooks/useAccessToken"
import { API } from "../../../constants"
import MainLayout from "../../../layouts/newMainLayoutPlanner"
import { useAppToastify } from "../../../utils/useAppToastify"
import ChannelAssignmentStep1 from "./components/Step1"
import ChannelAssignmentStep2, { KeywordsOptions } from "./components/step2/Step2"
import useSaveChannelAssignFeature from "./hooks/useSaveChannelAssign"

export const SERVE_TYPES = {
    "Fixed Delivery": "weight",
    Auction: "auction",
}

export default MainLayout(function ChannelAssignmentPage() {
    const [selectedServeType, setSelectedServeType] = useState(null)
    // const [selectedServeType, setSelectedServeType] = useState(SERVE_TYPES["Fixed Delivery"]);
    const [step, setStep] = useState(1)
    const pageParams = useParams()
    const step2StatesRef = useRef(null)
    const access_token = useAccessToken()
    const { showAppToast } = useAppToastify()
    const history = useHistory()
    const {advertiserId,campaignId,campaignType,campaignName,advertiser_name } = useParams()
    // const {isAssigningChannel,saveCampaign} = useSaveChannelAssignFeature({states: step2StatesRef,})

    // api for assigning the channel
    const { mutate: assignChannel } = useMutation({
        mutationFn: (body) => {
            return axios.post(`${API}/mediaPlanner/AdButler/placement`, body, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
        },
        mutationKey: ["assignChannel"],
        onSuccess: (res) => {
            showAppToast(res.data.message, { variant: "success" })
            history.replace(`/media-planner/campaign/advertiser/${advertiserId}/${campaignId}/${campaignType}/${campaignName}/${advertiser_name}`)
        },
        onError: (err) => {
            err?.response?.data?.message && showAppToast(err?.response?.data?.message, { variant: "error" })
            err?.message && showAppToast(err?.message, { variant: "error" })
        },
        enabled: false,
        refetchOnWindowFocus: false,
    })

    const handlePrevStep = () => {
        setStep((s) => s - 1)
    }
    const handleNextStep = () => {
        setStep((s) => s + 1)
    }

    const saveCampaign = () => {
        // console.log(step2StatesRef.current)
        const mergedStates = { ...step2StatesRef.current }

        // payload needed for assignChannel
        const newPayloads = {}

        newPayloads["weight"] = mergedStates["weight"]

        if (mergedStates["financials"]["advertiser_rates"].enableMode) {
            const advertiser_values = mergedStates["financials"]["advertiser_rates"]
            newPayloads.cost = {}
            if (advertiser_values["fixed"].checked) {
                newPayloads["cost"]["fixed_cost"] = Number(advertiser_values["fixed"].value)
            } else {
                if (advertiser_values["cpa"].checked) {
                    newPayloads["cost"]["cpa"] = Number(advertiser_values["cpa"].value)
                }
                if (advertiser_values["cpc"].checked) {
                    newPayloads["cost"]["cpc"] = Number(advertiser_values["cpc"].value)
                }
                if (advertiser_values["cpm"].checked) {
                    newPayloads["cost"]["cpm"] = Number(advertiser_values["cpm"].value)
                }
            }
        }
        if (mergedStates["financials"]["publisher_payout"]["selected_option"].value !== "none") {
            if (mergedStates["financials"]["publisher_payout"].enableMode) {
                const publisher_payout_values = mergedStates["financials"]["publisher_payout"]
                newPayloads.payout = {
                    type: publisher_payout_values.selected_option.value,
                }
                if (publisher_payout_values["fixed"].checked) {
                    newPayloads["payout"]["fixed"] = Number(publisher_payout_values["fixed"].value)
                } else {
                    if (publisher_payout_values["cpa"].checked) {
                        newPayloads["payout"]["cpa"] = Number(publisher_payout_values["cpa"].value)
                    }
                    if (publisher_payout_values["cpc"].checked) {
                        newPayloads["payout"]["cpc"] = Number(publisher_payout_values["cpc"].value)
                    }
                    if (publisher_payout_values["cpm"].checked) {
                        newPayloads["payout"]["cpm"] = Number(publisher_payout_values["cpm"].value)
                    }
                }
            }
        }
        if(mergedStates["schedule_key"]){
            newPayloads["schedule_id"] = Number(mergedStates["schedule_key"])
        }
        newPayloads["priority"] = mergedStates["priority"].value
        newPayloads["serve_method"] = selectedServeType
        newPayloads["campaign_id"] = Number(pageParams.campaignId)
        newPayloads["campaign_type"] = pageParams.campaignType
        newPayloads["placement_for"] = "channel"
        newPayloads["channel"] = Number(pageParams.selectedChannelId)
        newPayloads["delivery_method"] = mergedStates["delivery_pacing"].value

        if (mergedStates["isScheduleChecked"]) {
            newPayloads["start_date"] =
                moment(mergedStates["startDate"]).format("yyyy-MM-DD") + " " + mergedStates["startHour"].value
            newPayloads["end_date"] =
                moment(mergedStates["endDate"]).format("yyyy-MM-DD") + " " + mergedStates["endHour"].value
        }

        if (mergedStates["isQuotaChecked"]) {
            newPayloads["quota_lifetime"] = mergedStates["quota__total"]
            newPayloads["quota_type"] = mergedStates["quota__selection"].value
        }

        newPayloads["under_delivery_behaviour"] = mergedStates["under_delivery_behaviour"].value

        newPayloads["day_cap_limit"] = mergedStates["frequency_capping__daily_limit"] == 0 ? null : Number(mergedStates["frequency_capping__daily_limit"])
        newPayloads["day_cap_type"] = mergedStates["frequency_capping__daily_views_or_clicks"].value
        newPayloads["per_user_view_limit"] = mergedStates["frequency_capping__user_limit_views"] == 0 ? null : String(mergedStates["frequency_capping__user_limit_views"])
        newPayloads["per_user_view_period"] = mergedStates["frequency_capping__user_limit_days"] == 0 ? null : String(mergedStates["frequency_capping__user_limit_days"])

        if (mergedStates["isDaypartingChecked"]) {
            newPayloads["day_ranges"] = mergedStates["partingSlots"]
            newPayloads["day_parting_name"] = `${pageParams.campaignName} - ${pageParams.selectedChannelName}`
            newPayloads["day_parting_type"] = mergedStates["day_parting_type"].value
            newPayloads["day_parting"] = mergedStates["isDaypartingChecked"]
        }
        newPayloads["enable_payout"] = mergedStates["financials"]["publisher_payout"]["selected_option"].value != "none"

        newPayloads["keywords"] = mergedStates["keywords"]
        newPayloads["keywords_match_method"] = mergedStates["keywords_match_method"]
        // newPayloads["contextual_segments"] = mergedStates["contextual_segments"]?.reduce((acc, segment) => {
        //     acc.push(segment.value)
        //     return acc
        // }, [])
        // newPayloads["contextual_segments"] = [1,2]

        if ("data_key_target" in mergedStates) {
            newPayloads["data_key_target"] = mergedStates["data_key_target"]
        }

        if (mergedStates["platform_target"]?.value !== "default value") {
            newPayloads["platform_target"] = mergedStates["platform_target"].id
        }

        if (mergedStates["selected_geo_graphic"]?.value !== "default value") {
            newPayloads["geo_target"] = mergedStates["selected_geo_graphic"].value
        }

        // console.log(newPayloads)
        // return
        assignChannel(newPayloads)
    }

    return (
        <ChannelAssignmentPageContextProvider step2StatesRef={step2StatesRef} selectedServeType={selectedServeType}>
            <div style={{ marginBottom: "20px" }}>
                <span className="heading">Assign to {pageParams?.selectedChannelName}</span>

                {step == 1 && <ChannelAssignmentStep1 setSelectedServeType={setSelectedServeType} />}
                {step == 2 && <ChannelAssignmentStep2 setSelectedServeType={setSelectedServeType} />}

                <div className="pagination-btns flex justify-between">
                    <Button
                        className="prev-btn"
                        variant="contained"
                        color="primary"
                        disabled={step == 1}
                        onClick={handlePrevStep}
                    >
                        Prev
                    </Button>
                    {step <= 1 && (
                        <Button
                            className="next-btn"
                            variant="contained"
                            color="primary"
                            disabled={!selectedServeType}
                            onClick={handleNextStep}
                        >
                            Next
                        </Button>
                    )}
                    {step == 2 && (
                        <Button className="next-btn" variant="contained" color="primary" onClick={saveCampaign}>
                            Save Campaign
                        </Button>
                    )}
                </div>
            </div>
        </ChannelAssignmentPageContextProvider>
    )
})

const ChannelAssignmentPageContext = createContext({
    pacing__schedule__options: null,
    isLoadingPacingScheduleOptions: false,
    campaingListData: null,
    geoGraphicListData: null,
    targeting__keywords_select: null,
    platformTargetList: null,
    refetchPlatformTargetList: () => {},
    step2StatesRef: { current: undefined },
    refetchDataKeyTemplates: () => {},
    dataKeyTemplates: [],
    updateDataKeyTemplate: () => {},
    isUpdatingDataKeyTemplate: false,
    deleteDataKeyTemplate: () => {},
    refetchGeoGraphicListData: () => {},
    isDeletingDataKeyTemplate: false,
    selectedServeType: SERVE_TYPES["Fixed Delivery"],
    assignChannelUpdateMode: false,
})

export const useChannelAssignmentPageCtx = () => useContext(ChannelAssignmentPageContext)

export function ChannelAssignmentPageContextProvider({ children, step2StatesRef, selectedServeType,...rest }) {
    const pageParams = useParams()
    const access_token = useAccessToken()
    const [campaingListData, setCampaignListData] = useState(null)
    const [pacing__schedule__options, setPacing__Schedule__Options] = useState(null)

    const { data: campaignListQueryData, error, isLoading } = useQuery({
        queryFn: (params) => {
            return axios.get(`${API}/mediaPlanner/AdButler/getAllCampaignsList?advertiser=${pageParams.advertiserId}`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
        },
        refetchOnWindowFocus: false,
        queryKey: ["getAllCampaignList"],
        enabled: true,
        onSuccess: (data) => {
            setCampaignListData(data.data.data.map((d) => ({ name: d.name, id: d.id })))
        },
    })

    const targeting__keywords_select = KeywordsOptions

    // this still remains uncompleted.
    // const { data: targeting__keywords_select, error:targeting__keywords_select_error, isLoading: targeting__keywords_select_loading} = useQuery({
    //     queryFn: (params) => {
    //         return axios.get(`${API}/mediaPlanner/AdButler/getAllCampaignsList?advertiser=${pageParams.advertiserId}`, {
    //             headers: {
    //                 Authorization: `Bearer ${access_token}`,
    //             },
    //         });
    //     },
    //     refetchOnWindowFocus: false,
    //     queryKey: ["targeting__keywords_select"],
    //     enabled: true,
    //     onSuccess: (data) => {

    //     },
    // });

    const { data, mutate: fetchScheduleListSelection,isLoading: isLoadingPacingScheduleOptions } = useMutation({
        mutationFn: (params) => {
            return axios.post(
                `${API}/mediaPlanner/AdButler/getSchedule`,
                { campaignList: params },
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
        },
        mutationKey: ["getScheduleListForChannelAssignment"],
        onSuccess: (data) => {
            setPacing__Schedule__Options(data.data)
        },
        enabled: false,
        refetchOnWindowFocus: false,
    })

    const { data: geoGraphicListData, refetch: refetchGeoGraphicListData } = useQuery({
        queryFn: (params) => {
            return axios.get(`${API}/mediaPlanner/AdButler/getAllGeographic`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
        },
        refetchOnWindowFocus: false,
        queryKey: ["getGeoGraphicList"],
        enabled: true,
        onSuccess: (data) => {},
    })

    const { data: platformTargetList, refetch: refetchPlatformTargetList } = useQuery({
        queryFn: (params) => {
            return axios.get(`${API}/mediaPlanner/AdButler/getAllPlatformTarget`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
        },
        refetchOnWindowFocus: false,
        queryHash: ["getPlatformList"],
        queryKey: ["getPlatformList"],
        enabled: true,
        onSuccess: (data) => {},
    })

    // data key template api ---------------------------------------------------------
    // get api for DataKey template
    const { data: dataKeyTemplates, refetch: refetchDataKeyTemplates } = useQuery({
        queryFn: (params) => {
            return axios.get(`${API}/mediaPlanner/AdButler/dataKeyTargetTemplate`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
        },
        refetchOnWindowFocus: false,
        queryHash: ["getDataKeyTemplates"],
        enabled: true,
    })

    // update api for saving DataKey template
    const { mutateAsync: updateDataKeyTemplate, isLoading: isUpdatingDataKeyTemplate, reset } = useMutation({
        mutationFn: (params) => {
            return axios.post(`${API}/mediaPlanner/AdButler/dataKeyTargetTemplate`, params, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
        },
        mutationKey: ["addDataKeyTemplate"],
        onSuccess: () => {
            refetchDataKeyTemplates()
        },
        enabled: false,
        refetchOnWindowFocus: false,
    })

    // delete api for saving DataKey template
    const { isLoading: isDeletingDataKeyTemplate, mutateAsync: deleteDataKeyTemplate } = useMutation({
        mutationFn: (params) => {
            // console.log({ params })
            return axios.delete(`${API}/mediaPlanner/AdButler/dataKeyTargetTemplate/${params}`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
        },
        mutationKey: ["deleteDataKeyTemplate"],
        onSuccess: () => {
            refetchDataKeyTemplates()
        },
        enabled: false,
        refetchOnWindowFocus: false,
    })
    // ------------------------------------------------------end data key template api

    useEffect(() => {
        if (campaingListData) {
            fetchScheduleListSelection(campaingListData)
        }
    }, [campaingListData])

    return (
        <ChannelAssignmentPageContext.Provider
            value={{
                campaingListData,
                pacing__schedule__options,
                isLoadingPacingScheduleOptions,
                geoGraphicListData: geoGraphicListData?.data?.responseData,
                targeting__keywords_select: targeting__keywords_select,
                platformTargetList: platformTargetList?.data.responseData,
                refetchPlatformTargetList,
                step2StatesRef,
                refetchDataKeyTemplates,
                dataKeyTemplates: dataKeyTemplates?.data?.responseData,
                updateDataKeyTemplate,
                isUpdatingDataKeyTemplate,
                deleteDataKeyTemplate,
                isDeletingDataKeyTemplate,
                selectedServeType,
                refetchGeoGraphicListData,
                ...rest
            }}
        >
            {children}
        </ChannelAssignmentPageContext.Provider>
    )
}
