import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useForm } from "react-hook-form";
import MainLayout from "../../../layouts/newMainLayout";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import BrandMetrics from "../../branduplift/index";
//import DECA from "../../mediaOwner/mediaConsultency";
import DECA from "../../mediaOwner/decaView";
import Question from "../../mediaOwner/questionneries";
import Sentiments from "../../mediaOwner/sentimentView";
import Q1out5 from "../../mediaOwner/q1out5View";
import axios from "axios";
import { API } from "../../../constants/index";
import { SaveAdminDropDownData } from "../../../store/actions/admin/intelligence";
import { useDispatch } from "react-redux";

import { SECRET_KEY } from "../../../constants/index";
import Footer from "../../../layouts/footer";

const useStyles = makeStyles((theme) => ({
  tab1: {
    marginRight: "10px",
    color: red[500],
    "&.selected": {
      zIndex: 10,
    },
  },

  tab2: {
    marginRight: "10px",
    color: "#6a5fcd",
    "&.selected": {
      zIndex: 10,
    },
  },

  tab3: {
    color: green[500],
    "&.selected": {
      zIndex: 10,
    },
  },

  tabWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: "50px",
    "& span": {
      "border-top-right-radius": "25px",
      "border-top-left-radius": "25px",
      fontWeight: "bold",
      display: "inline-block",
      padding: "20px 30px 50px",
      backgroundColor: "white",
      position: "relative",
      cursor: "pointer",
    },
  },
}));

const OverviewIndex = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });
  const classes = useStyles();
  const [mediaState, setMediaState] = useState("Sentiments");

  const toggleMedia = (type) => {
    setMediaState(type);
  };

  useEffect(() => {
    axios
      .get(`${API}/mediaOwner/dropdowns?page=Sentiments`)
      .then((res, err) => {
        if (err) console.log("error>>>>>>>>>", err);
        if (res) {
          console.log("response>>>>>>>>>>>", res);
          dispatch(SaveAdminDropDownData(res.data));
        }
      });
  }, []);

  const ToggleMediaSwitch = () => {
    switch (mediaState) {
      //   case "brandMetrics":
      //     return <BrandMetrics />;
      case "deca":
        return <DECA />;
      //   case "questionerries":
      //     return <Question />;
      case "Q1out5":
        return <Q1out5 />;
      case "Sentiments":
        return <Sentiments />;
      // case "SentimentView":
      //   return <SentimentView onDone={this.showView} />;
      default:
      // code block
    }
  };
  const showView = () => {
    toggleMedia("SentimentView");
  };

  return (
    <div>
      <Container maxWidth="g">
        <div className={classes.tabWrapper}>
          <span
            onClick={() => toggleMedia("Sentiments")}
            className={[
              classes.tab1,
              mediaState === "Sentiments" ? "selected" : "",
            ].join(" ")}
          >
            Sentiments
          </span>
          <span
            onClick={() => toggleMedia("deca")}
            className={[
              classes.tab1,
              mediaState === "deca" ? "selected" : "",
            ].join(" ")}
          >
            Deca
          </span>
          {/* <span
            onClick={() => toggleMedia("questionerries")}
            className={[
              classes.tab1,
              mediaState === "questionerries" ? "selected" : "",
            ].join(" ")}
          >
            Media Owner Questionnerie
          </span> */}
          {/* <span
            onClick={() => toggleMedia("brandMetrics")}
            className={[
              classes.tab1,
              mediaState === "brandMetrics" ? "selected" : "",
            ].join(" ")}
          >
            BrandMetrics
          </span> */}
          <span
            onClick={() => toggleMedia("Q1out5")}
            className={[
              classes.tab1,
              mediaState === "Q1out5" ? "selected" : "",
            ].join(" ")}
          >
            1 in 5 questionnaire
          </span>
        </div>
        <ToggleMediaSwitch />
      </Container>
    </div>
  );
};

export default MainLayout(OverviewIndex);
