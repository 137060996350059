import React, { useState, useEffect, useRef, createRef, useMemo } from 'react';
import { makeStyles, fade, withTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

//Button
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper';
//Icon
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useForm } from 'react-hook-form'
import PersonPinIcon from '@material-ui/icons/PersonPin';
import { useSelector } from 'react-redux'

import { useDispatch } from 'react-redux'
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import IconButton from '@material-ui/core/IconButton'
import { useHistory, useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    addActionBtn1: {
        fontWeight: 'bold',
        color: 'white',
        backgroundColor: '#0e82f4',
        '&:hover': {
            backgroundColor: 'white',
            color: '#0e82f4',
        }
    },
    addActionBtn: {
        padding: '10px',
        paddingRight: '20px',
        backgroundColor: "#136cc3",
        color: 'white',
        fontWeight: 'bold',
        borderRadius: '23px',
        textTransform: "none",
        '&:hover': {
            background: "#136cc3",
        }
    },
    nextButton: {
        marginLeft: 'auto',
        backgroundColor: "#1d8bf7",
        color: 'white',
        display: 'block',
        padding: '5px 40px',
        marginTop: '40px'
    },

}));

const PersonaCard = ({personasList,handleShowClick}) => {

    const classes = useStyles();

    const handleClick=(index,selectedOwner)=>{
      //  handleShowClick(index,selectedOwner);
    }
    
    return (
        
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        {personasList.length > 0 && personasList.map((x, index) => (
                            
                            <Grid key={index} item md={3} sm={3} xs={3}>
                                <Paper>
                                    <div style={{ textAlign: 'center', padding: '40px 0px', position: 'relative' }}>
                                        
                                        <div>
                                            <PersonPinIcon style={{ color: '#84D0FF', fontSize: '12em' }} />
                                        </div>
                                        <span style={{ color: '#0e82f4', fontSize: '1.2em', display: 'block', fontWeight: 'bold', marginBottom: '20px' }}>{x.about.name}</span>
                                        <Button onClick={()=>handleClick(index,x)} className={classes.addActionBtn1} variant="outlined">
                                           Show Persona
                                        </Button>
                                    </div>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
       
    );
}
export default PersonaCard;