import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { fade, makeStyles, withTheme } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/newMainLayoutPlanner";

import TextareaAutosize from "@material-ui/core/TextareaAutosize";

//Button
import Button from "@material-ui/core/Button";

import Pagination from "@material-ui/lab/Pagination";

//Icon

import InputAdornment from "@material-ui/core/InputAdornment";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ControlPointTwoToneIcon from "@material-ui/icons/ControlPointTwoTone";
import DateRangeIcon from "@material-ui/icons/DateRange";

//TextField
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { StarOutlined } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import StarBorderRoundedIcon from "@material-ui/icons/StarBorderRounded";
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useStyles from "../../components/StyleClasses";
import UploadImage from "../../components/UploadImage";
import {
  getAllMediaOwner,
  listMediaOwnerTitles,
} from "../../redux/actions/media-owner";
import searchMediaOwners from "../../selectors/search";
import About from "./about";
import StepWrapper from "./stepWrapper";

import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import jwt_decode from "jwt-decode";
import templateImg from "../../assets/template.png";
import user from "../../assets/user.png";
import { API, SECRET_KEY } from "../../constants/index";
import SinglePersona from "./singlePersona";
import Subscription from './subscription';

const MoPublications = () => {
  const history = useHistory();
  const classes = useStyles();
  const [channel, setChannel] = useState("Television");
  const [frequency, setFrequency] = useState(0);
  const [personas, setPersonas] = useState([]);
  const [personasIds, setPersonasIds] = useState([]);
  //const [filteredList, setfilterdList] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [showPersona, setshowPersona] = useState(false);
  const [personaId, setPersonId] = useState("");
  const [loading, setLoading] = useState(false);
  const [isTemp, setIsTemp] = useState(false);
  const [isUseTemp, setIsUseTemp] = useState(false);

  const access_token = window.localStorage.getItem(SECRET_KEY);
  const subscription = useSelector((state) => state.subscription);
  const getSubscription = window.localStorage.getItem("subscription");
  let decodedSubscription = getSubscription && jwt_decode(getSubscription);
  
  useEffect(()=>{
    getPersonasById();
  },[])

  const getPersonasById = () => {
    setLoading(true);
    const id = window.localStorage.getItem("ID");
    // let endPoint = `${API}/mediaPlanner/persona?name=&page=1`;
    axios
      .get(`${API}/mediaPlanner/report/${id}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
          if (res && res.data && res.data.companyId) {
            setPersonasIds(res.data.companyId.personas ? res.data.companyId.personas:[]);
            setLoading(false); 
          }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };
  
  const getPersonas = () => {
    setLoading(true);
    axios
      .get(`${API}/mediaPlanner/persona?name=${search}&page=${page}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res && res.data && res.data.data.length) {
          console.log("persona data data >>>", res.data.data);
          console.log("persona list data >>>", personasIds);
          console.log("persona list data >>>", personasIds);
          const personaArr = [];
          res.data.data.map((rs)=>{
              if(personasIds.find((per)=> {if(per.persona._id === rs._id) return true})){
                personaArr.push(rs);
              }
          });
          console.log('personaslist',personaArr)
          setPersonas(personaArr);
          setPageCount(personaArr.length);
          // setPageCount(res.data.total);
        }
        if (err) {
        }
        setLoading(false);
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };

  const getPersonaTemplates = () => {
    setLoading(true);
    axios
      .get(
        `${API}/mediaPlanner/persona/PersonaTemplates?name=${search}&page=${page}`,
        {
          headers: { Authorization: `Bearer ${access_token}` },
        }
      )
      .then((res, err) => {
        if (res) {
          console.log("persona template list data >>>", res.data.data);
          setPersonas(res.data.data);
          setPageCount(res.data.total);
        }
        if (err) {
        }
        setLoading(false);
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };

  // useEffect(() => {
  //   setfilterdList(personas);
  // }, [personas]);

  useEffect(() => {
    if (page !== 1) setPage(1);
    getPersonas();
  }, [search]);
  useEffect(() => {
    getPersonas();
  }, [page,personasIds]);

  const PersonaCard = ({
    name,
    position,
    col,
    handleSelect,
    HandleVuew,
    _id,
    istemplate,
  }) => {
    let colo = "";
    if (istemplate) {
      colo = "blue";
    } else {
      colo = col;
    }

    let fixedTitle = name;
    if (fixedTitle.length > 15) {
      fixedTitle = fixedTitle.substring(0, 15) + "...";
    }
    return (
      <div
        style={{
          boxShadow: "0px 0px 10px rgba(0,0,0,0.20), 0 0 10px rgba(0,0,0,0.15)",
          borderRadius: "16px",
          marginRight: "30px",
          marginTop: "30px",
          width: "200px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
            height: "250",
            width: "150",
          }}
        >
          <img
            alt="media.Img"
            src={istemplate ? templateImg : user}
            style={{
              backgroundImage: `linear-gradient(${colo},${colo},${colo}, ${
                istemplate ? "blue" : "white"
              })`,
              width: "140px",
              height: "140px",
              display: "block",
            }}
          ></img>
          <span
            style={{ fontSize: "15px", fontWeight: "700", color: "#136CC3" }}
          >
            {fixedTitle}
          </span>
          {/* <span
            style={{ fontSize: "13px", fontWeight: "400", color: "#136CC3" }}
          >
            {name}
          </span> */}
          <Button
            style={{ backgroundColor: "#136CC3", color: "white", width: "90%" }}
            onClick={() => {
              if (istemplate) setIsTemp(true);
              setPersonId(_id);
              setshowPersona(true);
            }}
          >
            {istemplate ? "Show Template" : "Show Persona"}
          </Button>
          {istemplate && (
            <Button
              style={{
                backgroundColor: "green",
                color: "white",
                marginTop: "10px",
                width: "90%",
              }}
              onClick={() => {
                history.push(`/media-planner/createPersona?__id=${_id}`);
              }}
            >
              Use Template
            </Button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      {(decodedSubscription && decodedSubscription.subscription === "free") ?(<Subscription />): (
    <div>
      {showPersona ? (
        <div>
          <SinglePersona
            id={personaId}
            back={() => setshowPersona(false)}
            istemplate={isTemp}
          />
        </div>
      ) : (
        <div>
          <div>
            <h2 style={{ color: "#4F74C9", fontWeight: "400" }}>
              Media Personas
            </h2>
            <div
              style={{
                display: "flex",
                outline: "none",
                backgroundColor: "white",
                border: "solid 2px #E5E5E5",
                color: "black",
                borderRadius: "23px",
                width: "100%",
              }}
            >
              {/* <Button
                onClick={() => history.push("/media-planner/createPersona")}
                className={classes.addActionBtn}
                variant="outlined"
                color="primary"
                style={{
                  borderRadius: "23px",
                }}
              >
                <AddCircleOutlineIcon
                  style={{ marginRight: "5px", color: "white" }}
                />
                Create Persona
              </Button> */}

              <input
                placeholder="Search Personas"
                //className={classes.addActionBtn}
                style={{
                  outline: "none",
                  backgroundColor: "white",
                  border: "solid 2px #E5E5E5",
                  color: "black",
                  borderRadius: "23px",
                  padding: "7px",
                  paddingLeft: "22px",
                  margin: "7px",
                  width: "100%",
                }}
                onFocus={() => {
                  if (isTemp) {
                    setIsTemp(false);
                    getPersonas();
                  }
                }}
                onChange={(event) => {
                  // console.log(event.target.value);
                  // let toshow = personas.filter((e) =>
                  //   e.about.name
                  //     .toLowerCase()
                  //     .includes(event.target.value.toLowerCase())
                  // );
                  // setfilterdList(toshow);
                  setSearch(event.target.value);
                }}
                ////////////////////////////////////////////////////////////////////////////////////////
              ></input>
              {/* <Button
                onClick={() =>
                  history.push("/media-planner/createPersonaTemplate")
                }
                className={classes.addActionBtn}
                variant="outlined"
                color="primary"
                style={{
                  borderRadius: "23px",
                }}
              >
                <AddCircleOutlineIcon
                  style={{ marginRight: "5px", color: "white" }}
                />
                Create Persona Template
              </Button>
              <input
                placeholder="Search Persona Templates"
                //className={classes.addActionBtn}
                style={{
                  outline: "none",
                  backgroundColor: "white",
                  border: "solid 2px #E5E5E5",
                  color: "black",
                  borderRadius: "23px",
                  padding: "7px",
                  paddingLeft: "22px",
                  margin: "7px",
                  marginLeft: "-20px",
                  width: "35%",
                }}
                onFocus={() => {
                  if (!isTemp) {
                    setIsTemp(true);
                    getPersonaTemplates();
                  }
                }}
                onChange={(event) => {
                  // console.log(event.target.value);
                  // let toshow = personas.filter((e) =>
                  //   e.about.name
                  //     .toLowerCase()
                  //     .includes(event.target.value.toLowerCase())
                  // );
                  // setfilterdList(toshow);
                  setSearch(event.target.value);
                }}
              ></input> */}
            </div>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "15%",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  marginTop: "30px",
                  flexWrap: "wrap",
                }}
              >
                {personas.length > 0 &&
                  personas.map((e) => (
                    <PersonaCard
                      name={e.about.name}
                      col={e.avatarColor}
                      _id={e._id}
                      istemplate={isTemp}
                    />
                  ))}

                {personas.length <= 0 && <h2>No Persona Found!</h2>}
              </div>
            )}
          </div>
          {pageCount > 0 && (
            <div
              style={{
                display: "flex",
                marginTop: "20px",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  color: "#FB6D6A",
                  fontWeight: "600",
                  margin: "0",
                  fontSize: "20px",
                  marginRight: "20px",
                }}
              >
                <Pagination
                  page={page}
                  count={Math.ceil(pageCount / 10)}
                  color="primary"
                  onChange={(e, page) => setPage(page)}
                  size="small"
                />
              </span>
            </div>
          )}
        </div>
      )}
    </div>
    )}
    </div>
  );
};
export default MainLayout(MoPublications);
