/* eslint-disable no-unused-expressions */
import { fade, makeStyles, withTheme } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/newMainLayout";

import { enGB } from "date-fns/locale";
import { DateRangePicker, END_DATE, START_DATE } from "react-nice-dates";

import Grid from "@material-ui/core/Grid";

import ShowAlert from "../../components/alerts";
import * as actions from "../../redux/actions/user";

import CircularProgress from "@material-ui/core/CircularProgress";

//Button
import Button from "@material-ui/core/Button";

//Icon
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ControlPointTwoToneIcon from "@material-ui/icons/ControlPointTwoTone";
import EventIcon from "@material-ui/icons/Event";
import PaymentIcon from "@material-ui/icons/Payment";

///charts/////
import Highcharts, { color } from "highcharts";
import HighchartReact from "highcharts-react-official";
import funnel from "highcharts/modules/funnel.js";

//TextField
import { Radio } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import StarBorderRoundedIcon from "@material-ui/icons/StarBorderRounded";
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import Pagination from "@material-ui/lab/Pagination";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import OtherStyles from "../../components/StyleClasses";
import { API } from "../../constants/index";
import {
  getAllMediaOwner,
  listMediaOwnerTitles,
} from "../../redux/actions/media-owner";
import searchMediaOwners from "../../selectors/search";
import { SECRET_KEY } from "./../../constants/index";
import BallsLoading from "./MoComponents/ballsloading.js";
import PdfComp from "./MoComponents/pdfComp";
import PercentCard from "./MoComponents/percentcard";
import CustomizedTables from "./mytable";

funnel(Highcharts);

const dropdownData = {
  ReportTypes: [
    "overview",
    "publisher",
    "advertiser",
    "zone",
    "campaign",
    "ad-item",
  ],
  Publishers: [
    "Publisher 1",
    "Publisher 2",
    "Publisher 3",
    "Publisher 4",
    "Publisher 5",
  ],
  Zones: ["Zone 1", "Zone 2", "Zone 3", "Zone 4", "Zone 5"],
  Advertisers: [
    "Advertiser 1",
    "Advertiser 2",
    "Advertiser 3",
    "Advertiser 4",
    "Advertiser 5",
  ],
  Campaigns: [
    "Camapaign 1",
    "Camapaign 2",
    "Camapaign 3",
    "Camapaign 4",
    "Camapaign 5",
  ],
  AdItems: ["Ad Item 1", "Ad Item 2", "Ad Item 3", "Ad Item 4", "Ad Item 5"],
  Dates1: ["month", "year"],
  Dates2: [
    "this-month",
    "last-month",
    "last-3-months",
    "last-6-months",
    "last-12-months",
  ],
};
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  addActionBtn: {
    padding: "7px",
    paddingRight: "12px",
    paddingLeft: "12px",
    backgroundColor: "#136cc3",
    color: "white",
    fontWeight: "bold",
    borderRadius: "23px",
    textTransform: "none",
    "&:hover": {
      background: "#136cc3",
    },
  },
  searchInput: {
    backgroundColor: "white",
    border: "solid 2px #E5E5E5",
    color: "black",
    borderRadius: "23px",
    padding: "7px",
    "&:focus": {
      borderRadius: "23px",
    },
  },
  customfield: {
    paddingTop: "0",
    marginTop: "10",
    backgroundColor: "#f4f4f8",
    borderRadius: "5px",
    "& .MuiInputLabel-root.Mui-shrink": {
      color: "red",
    },

    "&$focused": {
      backgroundColor: "#fff",
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.5px 14px",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",

      "& fieldset": {
        borderColor: "grey",
      },
      "&:hover fieldset": {},
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "grey",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "grey",
    },
    "& .MuiInputLabel-root.MuiInputLabel-shrink": {
      margin: "0!important",
    },
    "& .MuiInputLabel-root.MuiInputLabel-animated": {
      marginTop: "-8px",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",
    },
  },
  FloatBox: {
    backgroundColor: "white",
    maxWidth: "30%",
    minWidth: "20%",
    height: "120px",
    margin: "20px",
    borderRadius: "10px",
  },
  smallBox: {
    backgroundImage: "linear-gradient(to right, #9211a2, #4911a2)",
    color: "white",
    borderRadius: "10px",
    width: "40%",
    height: "60px",
    marginTop: "-15px",
    textAlign: "center",
    paddingTop: "10px",
  },
  floatBoxContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

function nFormatter(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
}
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const Titlecaes = (text) => {
  let result = text.replace(/([A-Z])/g, " $1");
  result = result.replace("_", " ");
  result = result.replace("impressions", "Impressions");
  result = result.replace("C T R", "CTR");
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

const MoPublications = () => {
  const [loading, setloading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const adbutlerReportsData = useSelector((state) => state.adbutlerReportsData);
  const MoDashboardImpressions = useSelector(
    (state) => state.MoDashboardImpressions
  );
  const MoDashboardClicks = useSelector((state) => state.MoDashboardClicks);

  //const cpm = useSelector((state) => state.MoDashboardCPM);
  const payout = useSelector((state) => state.MoDashboardPayout);
  //const impressions = useSelector((state) => state.MoDashboardPayout);
  //const MoPublisherName = useSelector((state) => state.MoPublisherName);

  const MOProfile = useSelector((state) => state.MOProfileOBJ);
  const adbutlerVastReportsData = useSelector(
    (state) => state.adbutlerVastReportsData
  );
  const MoDashboardConfiguration = useSelector(
    (state) => state.MoDashboardConfiguration
  );
  const otherClasses = OtherStyles();
  const classes = useStyles();
  const access_token = window.localStorage.getItem(SECRET_KEY);

  const [ReportTypes, setReportTypes] = useState("campaign");

  const [mediaList, setMediaList] = useState([]);
  const [filteredList, setfilterdList] = useState([]);

  const [search, setSearch] = useState("");
  const [DATA, setDATA] = useState(
    adbutlerReportsData ? adbutlerReportsData : []
  );
  const [tableData, setTableData] = useState([]);
  const [tableHeads, settableHeads] = useState([]);
  const [page, setpage] = useState(1);
  const [pagecount, setPageCount] = useState(0);
  const [mediaState, setMediaState] = useState(
    MoDashboardConfiguration
      ? MoDashboardConfiguration.mediaState
      : "Statistic Reports"
  );
  const [Dates1, setDates1] = useState(
    MoDashboardConfiguration ? MoDashboardConfiguration.Dates1 : "year"
  );
  const [Dates2, setDates2] = useState(
    MoDashboardConfiguration
      ? MoDashboardConfiguration.Dates2
      : "last-12-months"
  );
  const [customdate, setcustomdate] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [Spinner, setSpinner] = useState(false);
  const [noData, setNoData] = useState(false);
  const [ChartSeries, setChartSeries] = useState();

  const graphData = useSelector((state) => state.MoDashboardGraphData);
  const [graphtoShow, setgraphtoShow] = useState(null);
  const [grapghCompanyName, setgrapghCompanyName] = useState("");
  const passBackList = useSelector((state) => state.passBackList);

  const tableStructure = {
    id: 12345,
    eligible_impressions: 2093,
    viewable_impressions: 1263,
    clicks: 0,
    CTR: 0,
    payout: 7.46,
  };
  const ConvertToReport = (e) => {
    let result = {};
    result.id = e.id;
    if (e.summary.eligible_impressions) {
      result.eligible_impressions = e.summary.eligible_impressions;
    }
    if (e.summary.viewable_impressions) {
      result.viewable_impressions = e.summary.viewable_impressions;
    }

    result.clicks = e.summary.clicks;
    if (e.summary.eligible_impressions) {
      result.CTR =
        ((e.summary.clicks / e.summary.eligible_impressions) * 100)
          .toFixed(2)
          .toString() + "%";
      if (mediaState === "Statistic Reports") {
        result.payout =
          MOProfile.currency.symbol +
          ((e.summary.eligible_impressions / 1000) * MOProfile.cpmDisplay)
            .toFixed(2)
            .toString();
      } else {
        result.payout =
          MOProfile.currency.symbol +
          ((e.summary.eligible_impressions / 1000) * MOProfile.cpmVideo)
            .toFixed(2)
            .toString();
      }
    }
    if (e.summary.viewable_impressions) {
      result.CTR =
        ((e.summary.clicks / e.summary.viewable_impressions) * 100)
          .toFixed(2)
          .toString() + "%";

      if (mediaState === "Statistic Reports") {
        result.payout =
          MOProfile.currency.symbol +
          ((e.summary.viewable_impressions / 1000) * MOProfile.cpmDisplay)
            .toFixed(2)
            .toString();
      } else {
        result.payout =
          MOProfile.currency.symbol +
          ((e.summary.viewable_impressions / 1000) * MOProfile.cpmVideo)
            .toFixed(2)
            .toString();
      }
    }
    // result.payout = MOProfile.currency.symbol + e.summary.payout.toString();

    // result.responses = e.summary.responses;
    return result;
  };
  const ConvertToVastReport = (e) => {
    let result = {
      ID: e.id,
      Impressions: e.summary.impressions,
      Clicks: e.summary.clicks,
      CTR:
        ((e.summary.clicks / e.summary.impressions) * 100)
          .toFixed(2)
          .toString() + "%",
    };
    if (mediaState === "Statistic Reports") {
      result.payout =
        MOProfile.currency.symbol +
        ((e.summary.impressions / 1000) * MOProfile.cpmDisplay)
          .toFixed(2)
          .toString();
    } else {
      result.payout =
        MOProfile.currency.symbol +
        ((e.summary.impressions / 1000) * MOProfile.cpmVideo)
          .toFixed(2)
          .toString();
    }
    return result;
  };
  const vastTableStructure = {
    id: 12345,
    impressions: 2,
    clicks: 0,
    CTR: 0,
    payout: 0,
  };

  // useEffect(() => {
  //   if (mediaState === "Statistic Reports") {
  //     setChartSeries([
  //       {
  //         name: "Display",
  //         data: [3, 4, 3, 5, 4, 6, 8, 5, 5, 7, 12, 15],
  //         color: "#00DEFE",
  //       },
  //     ]);
  //   } else {
  //     setChartSeries([
  //       {
  //         name: "Video",
  //         data: [1, 3, 4, 3, 3, 5, 4, 4, 8, 9, 11, 13],
  //         color: "#98FF98",
  //       },
  //     ]);
  //   }
  // }, [mediaState]);

  ///pdf model////
  const [openModel, setOpenModel] = useState(false);

  //////////graph/////////////////////////
  useEffect(() => {
    if (graphData) {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      let gd = { x: [], y: [] };
      graphData?.forEach((e) => {
        let sd = new Date(e.start_date);
        let month = "";
        if (Dates2 === "last-month" || Dates2 === "this-month") {
          month = sd.getDate();
        } else {
          month = monthNames[sd.getMonth()];
        }
        gd.x.push(month);
        if (MOProfile?.publisherType === "eligible") {
          if (mediaState === "Statistic Reports") {
            gd.y.push(e.eligible_impressions);
          } else {
            gd.y.push(e.impressions);
          }
        } else {
          if (mediaState === "Statistic Reports") {
            gd.y.push(e.viewable_impressions);
          } else {
            gd.y.push(e.impressions);
          }
        }
      });
      setgraphtoShow(gd);

      if (mediaState === "Statistic Reports") {
        setChartSeries([
          {
            name: "Display",
            data: gd.y,
            color: "#00DEFE",
          },
        ]);
      } else {
        setChartSeries([
          {
            name: "Video",
            data: gd.y,
            color: "#98FF98",
          },
        ]);
      }
    }
  }, [graphData]);

  useEffect(() => {
    if (!graphData) {
      let URLL = `${API}/mediaOwner/AdButler/getReports?period=month&type=publisher&preset=last-12-months&details=true`;
      axios
        .get(URLL, {
          headers: { Authorization: `Bearer ${access_token}` },
        })
        .then((res, err) => {
          if (err) {
          } else {
            if ("data" in res.data) {
              //setGraphData(res.data?.data[0]?.details);
              setgrapghCompanyName(res.data?.data[0]?.id);
              dispatch({
                type: "MO_Dashboard_Graph_Data",
                payload: res.data?.data[0]?.details,
              });
            }
          }
        })
        .catch(function (error) {
          if (error) {
          }
        });
    }
  }, []);

  const getgaphsdata = () => {
    let URLL = `${API}/mediaOwner/AdButler/getReports?type=publisher&details=true`;
    if (Dates2) URLL += `&preset=${Dates2}`;
    if (Dates2 === "last-month" || Dates2 === "this-month") {
      URLL += `&period=day`;
    } else {
      URLL += `&period=month`;
    }
    axios
      .get(URLL, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (err) {
        } else {
          if ("data" in res.data) {
            // setGraphData(res.data?.data[0]?.details);
            setgrapghCompanyName(res.data?.data[0]?.id);
            dispatch({
              type: "MO_Dashboard_Graph_Data",
              payload: res.data?.data[0]?.details,
            });
            dispatch({
              type: "MO_Dashboard_Confuguration",
              payload: {
                mediaState: mediaState,
                Dates1: Dates1,
                Dates2: Dates2,
              },
            });
          }
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };

  /////////////////////////////////////////////////

  const getInitialData = () => {
    if (!adbutlerReportsData) {
      let URLL = `${API}/mediaOwner/AdButler/getReports?type=campaign&period=month&preset=last-12-months`;

      axios
        .get(URLL, {
          headers: { Authorization: `Bearer ${access_token}` },
        })
        .then((res, err) => {
          if (err) {
          } else {
            if ("data" in res.data) {
              if (MOProfile?.cpmDisplay && MOProfile?.cpmVideo) {
                let nd = res.data.data.filter(
                  (e) => !passBackList.includes(e.id)
                );
                dispatch({
                  type: "Adbutler_Reports_Data",
                  payload: nd,
                });
                let pout = 0;
                let imp = 0;
                let clk = 0;

                nd.forEach((e) => {
                  if (MOProfile?.publisherType === "eligible") {
                    imp += e.summary.eligible_impressions;
                    if (e.summary.eligible_impressions > 0) {
                      pout +=
                        (e.summary.eligible_impressions / 1000) *
                        MOProfile?.cpmDisplay;
                    }
                  } else {
                    imp += e.summary.viewable_impressions;
                    if (e.summary.viewable_impressions > 0) {
                      pout +=
                        (e.summary.viewable_impressions / 1000) *
                        MOProfile?.cpmDisplay;
                    }
                  }

                  clk += e.summary.clicks;
                });
                dispatch({
                  type: "UPDATE_PAYOUT",
                  payload: Math.ceil(pout * 100) / 100,
                });
                dispatch({
                  type: "Mo_Dashboard_Impressions",
                  payload: nFormatter(imp),
                });
                dispatch({
                  type: "Mo_Dashboard_Clicks",
                  payload: nFormatter(clk),
                });
                let refrenece = "";

                let makeDate = new Date();
                makeDate.setMonth(makeDate.getMonth() - 12);
                let rf = monthNames[makeDate.getMonth()];
                refrenece =
                  rf +
                  "-" +
                  makeDate.getUTCFullYear().toString() +
                  " TO " +
                  monthNames[new Date().getMonth()] +
                  "-" +
                  new Date().getUTCFullYear().toString();

                dispatch({
                  type: "Invoice_Reference",
                  payload: refrenece,
                });

                setPageCount(nd.length);
              }
            } else {
              setNoData(true);
            }
          }
        })
        .catch(function (error) {
          if (error) {
          }
        });
    }
  };

  useEffect(() => {
    if (MOProfile) getInitialData();
  }, [MOProfile]);

  useEffect(() => {
    let Dynamicheads = { ...tableStructure };
    if (MOProfile?.publisherType === "eligible") {
      delete Dynamicheads.viewable_impressions;
    } else {
      delete Dynamicheads.eligible_impressions;
    }

    let heads = Object.keys(
      mediaState === "Statistic Reports" ? Dynamicheads : vastTableStructure
    );
    heads = heads.map((e) => Titlecaes(e));
    settableHeads(heads);
    if (DATA.length > 0) {
      let td = [];
      for (let i = (page - 1) * 10; i < page * 10; i++) {
        if (i < DATA.length) {
          let e = DATA[i];
          let converted;

          if (mediaState === "Statistic Reports") {
            if (MOProfile.publisherType === "eligible") {
              delete e.summary.viewable_impressions;
            } else {
              delete e.summary.eligible_impressions;
            }
            converted = ConvertToReport(e);
          } else {
            converted = ConvertToVastReport(e);
          }
          let KeysToShow = Object.values(converted);
          td.push({
            data: KeysToShow,
            _id: i,
          });
        }
      }
      setTableData(td);
    }
  }, [DATA, page]);

  useEffect(() => {
    if (DATA.length > 0) {
      let td = [];
      for (let i = 0; i < DATA.length; i++) {
        if (i < DATA.length) {
          let e = DATA[i];
          let converted;
          let cpm = 0;

          if (mediaState === "Statistic Reports") {
            if (MOProfile.publisherType === "eligible") {
              delete e.summary.viewable_impressions;
            } else {
              delete e.summary.eligible_impressions;
            }
            converted = ConvertToReport(e);
            cpm = MOProfile.cpmDisplay;
          } else {
            converted = ConvertToVastReport(e);
            cpm = MOProfile.cpmVideo;
          }
          let KeysToShow = Object.values(converted);
          td.push({
            data: KeysToShow,
            _id: i,
            cpm: cpm,
          });
        }
      }
      dispatch({ type: "Invoice_Table_Data", payload: td });
    }
  }, [DATA]);

  useEffect(() => {
    if (adbutlerReportsData) {
      let nd = adbutlerReportsData.filter((e) => !passBackList.includes(e.id));
      setDATA(nd);
      setPageCount(nd.length);
    }
  }, [adbutlerReportsData]);

  const FloatBox = ({ title, icon, value }) => {
    return (
      <div
        className={classes.FloatBox}
        style={{ display: "flex", justifyContent: "space-around" }}
      >
        <div className={classes.smallBox}>{icon}</div>
        <div style={{ textAlign: "center", width: "40%" }}>
          <span style={{ display: "block", fontWeight: 600, fontSize: "15px" }}>
            {title}
          </span>
          {value && (
            <span
              style={
                value < 10000
                  ? { fontSize: `20px`, marginTop: "30px" }
                  : { fontSize: `15px`, marginTop: "30px" }
              }
            >
              {value}
            </span>
          )}
          {!value && <BallsLoading color="black" size={0.8} />}
        </div>
      </div>
    );
  };

  const getdata = () => {
    setDATA([]);
    setTableData([]);
    settableHeads([]);
    setSpinner(true);
    setNoData(false);
    dispatch({
      type: "UPDATE_PAYOUT",
      payload: null,
    });
    dispatch({
      type: "Mo_Dashboard_Impressions",
      payload: null,
    });
    dispatch({
      type: "Mo_Dashboard_Clicks",
      payload: null,
    });

    let URLL = `${API}/mediaOwner/AdButler`;

    if (mediaState === "Statistic Reports") {
      URLL += `/getReports`;
    } else {
      URLL += `/getVASTReports`;
    }
    if (ReportTypes !== 0) URLL += `?type=${ReportTypes}`;
    //if (Dates1 !== 0) URLL += `&period=${Dates1}`;
    if (customdate) {
      let stdate = startDate.toISOString();
      let newstdate = stdate.slice(0, 10);
      newstdate += "T12:00:00-08:00";
      let entdate = endDate.toISOString();
      let newendate = entdate.slice(0, 10);
      newendate += "T12:00:00-08:00";
      URLL += `&from=${newstdate}`;
      URLL += `&to=${newendate}`;
    } else {
      if (Dates2 !== 0) URLL += `&preset=${Dates2}`;
    }
    URLL += `&period=month`;
    URLL += `&details=true`;

    axios
      .get(URLL, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (err) {
        } else {
          if ("data" in res.data) {
            if (MOProfile?.cpmDisplay && MOProfile?.cpmVideo) {
              let nd = res.data.data.filter(
                (e) => !passBackList.includes(e.id)
              );
              setDATA(nd);
              setPageCount(nd.length);

              ///////

              dispatch({
                type: "Adbutler_Reports_Data",
                payload: nd,
              });
              let pout = 0;
              let imp = 0;
              let clk = 0;

              nd.forEach((e) => {
                if (MOProfile?.publisherType === "eligible") {
                  if (mediaState === "Statistic Reports") {
                    if (e.summary.eligible_impressions > 0) {
                      pout +=
                        (e.summary.eligible_impressions / 1000) *
                        MOProfile?.cpmDisplay;
                    }
                    imp += e.summary.eligible_impressions;
                  } else {
                    if (e.summary.impressions > 0) {
                      pout +=
                        (e.summary.impressions / 1000) * MOProfile?.cpmDisplay;
                    }
                    imp += e.summary.impressions;
                  }
                } else {
                  if (mediaState === "Statistic Reports") {
                    if (e.summary.viewable_impressions > 0) {
                      pout +=
                        (e.summary.viewable_impressions / 1000) *
                        MOProfile?.cpmVideo;
                    }
                    imp += e.summary.viewable_impressions;
                  } else {
                    if (e.summary.impressions > 0) {
                      pout +=
                        (e.summary.impressions / 1000) * MOProfile?.cpmVideo;
                    }
                    imp += e.summary.impressions;
                  }
                }

                clk += e.summary.clicks;
              });
              dispatch({
                type: "UPDATE_PAYOUT",
                payload: Math.ceil(pout * 100) / 100,
              });
              dispatch({
                type: "Mo_Dashboard_Impressions",
                payload: nFormatter(imp),
              });
              dispatch({
                type: "Mo_Dashboard_Clicks",
                payload: nFormatter(clk),
              });
              if (customdate) {
                let stdate = startDate.toISOString();
                let newstdate = stdate.slice(0, 10);
                let endate = endDate.toISOString();
                let newendate = endate.slice(0, 10);
                dispatch({
                  type: "Invoice_Reference",
                  payload: newstdate + " TO " + newendate,
                });
              } else {
                let refrenece = "";
                if (Dates2 === "this-month") {
                  let rf = monthNames[new Date().getMonth()];
                  refrenece = rf + "-" + new Date().getUTCFullYear().toString();
                }
                if (Dates2 === "last-month") {
                  let makeDate = new Date();
                  makeDate.setMonth(makeDate.getMonth() - 1);
                  let rf = monthNames[makeDate.getMonth()];
                  refrenece = rf + "-" + makeDate.getUTCFullYear().toString();
                }
                if (Dates2 === "last-3-months") {
                  let makeDate = new Date();
                  makeDate.setMonth(makeDate.getMonth() - 3);
                  let rf = monthNames[makeDate.getMonth()];
                  refrenece =
                    rf +
                    "-" +
                    makeDate.getUTCFullYear().toString() +
                    " TO " +
                    monthNames[new Date().getMonth()] +
                    "-" +
                    new Date().getUTCFullYear().toString();
                }
                if (Dates2 === "last-6-months") {
                  let makeDate = new Date();
                  makeDate.setMonth(makeDate.getMonth() - 6);
                  let rf = monthNames[makeDate.getMonth()];
                  refrenece =
                    rf +
                    "-" +
                    makeDate.getUTCFullYear().toString() +
                    " TO " +
                    monthNames[new Date().getMonth()] +
                    "-" +
                    new Date().getUTCFullYear().toString();
                }
                if (Dates2 === "last-12-months") {
                  let makeDate = new Date();
                  makeDate.setMonth(makeDate.getMonth() - 12);
                  let rf = monthNames[makeDate.getMonth()];
                  refrenece =
                    rf +
                    "-" +
                    makeDate.getUTCFullYear().toString() +
                    " TO " +
                    monthNames[new Date().getMonth()] +
                    "-" +
                    new Date().getUTCFullYear().toString();
                }

                dispatch({
                  type: "Invoice_Reference",
                  payload: refrenece,
                });
              }
            }
            /////////
          } else {
            setNoData(true);
          }
        }
        setSpinner(false);
      })
      .catch(function (error) {
        if (error) {
          setSpinner(false);
        }
      });
  };

  const statisticReports = () => {
    return (
      <div
        style={{
          paddingTop: "-20px",
        }}
      >
        <div
          className={[classes.selectControl, classes.customfield].join(" ")}
          style={{}}
        >
          {/* <h4 style={{ marginTop: "-20px", color: "#4B11A2" }}>
            {mediaState === "Statistic Reports"
              ? "Display Reports"
              : "Video Reports"}
          </h4> */}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              margin: "10px 0",
              justifyContent: "space-between",
            }}
          >
            <TextField
              style={{ width: "40vw", marginRight: "10px" }}
              id="Report Type"
              select
              label="Report Type"
              value={mediaState}
              onChange={(e) => {
                setMediaState(e.target.value);
                settableHeads([]);
                setTableData([]);
                setPageCount(0);
                setpage(1);
              }}
              SelectProps={{
                native: true,
              }}
              variant="outlined"
            >
              <option key={0} value={0} disabled>
                Select...
              </option>
              <option key={1} value={"Statistic Reports"}>
                Display Reports
              </option>
              <option key={2} value={"Vast Statistic Reports"}>
                Video Reports
              </option>
            </TextField>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                margin: "10px 0",
              }}
            >
              <span>Custom</span>
              <Radio
                checked={customdate}
                onClick={() => setcustomdate(!customdate)}
              />
              <EventIcon />
              <span style={{ marginRight: "14px" }}>Dates</span>

              {customdate && (
                <DateRangePicker
                  style={{ width: "330px" }}
                  startDate={startDate}
                  endDate={endDate}
                  onStartDateChange={setStartDate}
                  onEndDateChange={setEndDate}
                  minimumDate={new Date(2020, 11, 24, 10, 33, 30, 0)}
                  minimumLength={1}
                  format="dd MMM yyyy"
                  locale={enGB}
                >
                  {({ startDateInputProps, endDateInputProps, focus }) => (
                    <div className="date-range">
                      <TextField
                        id="startDate"
                        label="Available From"
                        variant="outlined"
                        margin="normal"
                        onChange={(e) => {
                          setStartDate(e.target.value);
                        }}
                        className={classes.customfield}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        {...startDateInputProps}
                      />
                      <TextField
                        id="endDate"
                        label="Available Upto"
                        variant="outlined"
                        margin="normal"
                        className={classes.customfield}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        {...endDateInputProps}
                      />
                    </div>
                  )}
                </DateRangePicker>
              )}
              {!customdate && (
                <TextField
                  //style={{ width: "20vw" }}
                  id="Dates2"
                  select
                  label="Period"
                  value={Dates2}
                  onChange={(e) => {
                    setTableData([]);
                    setDates2(e.target.value);
                  }}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0} disabled>
                    Select...
                  </option>
                  {dropdownData?.Dates2.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </TextField>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {MOProfile?.cpmDisplay && MOProfile?.cpmVideo && (
        <div>
          {statisticReports()}
          <div className={classes.floatBoxContainer}>
            {[
              {
                title: "Payout",
                icon: <AccountBalanceWalletIcon fontSize="large" />,
                value: payout
                  ? MOProfile?.currency?.symbol.toString() + payout
                  : null,
              },
              {
                title: "Impressions",
                icon: <PaymentIcon fontSize="large" />,
                value: MoDashboardImpressions ? MoDashboardImpressions : null,
              },
              {
                title: "Clicks",
                icon: <AccountBalanceWalletIcon fontSize="large" />,
                value: MoDashboardClicks ? MoDashboardClicks : null,
              },
            ].map((e) => (
              <FloatBox title={e.title} icon={e.icon} value={e.value} />
            ))}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: "40px",
              }}
            >
              {
                <Button
                  className={classes.addActionBtn}
                  variant="outlined"
                  color="primary"
                  // style={{ marginTop: "20px" }}
                  onClick={() => {
                    getdata();
                    getgaphsdata();
                  }}
                  style={
                    customdate && (!startDate || !endDate)
                      ? { backgroundColor: "grey" }
                      : {}
                  }
                  disabled={(customdate && (!startDate || !endDate)) || !payout}
                >
                  Generate Report
                  {Spinner && (
                    <CircularProgress
                      size={10}
                      style={{ margin: "0 20px", color: "white" }}
                    />
                  )}
                </Button>
              }
              {tableData.length > 0 && (
                <Button
                  className={classes.addActionBtn}
                  variant="outlined"
                  color="primary"
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                  onClick={() => history.push("/media-owner/Invoice")}
                >
                  Generate Invoice
                </Button>
              )}
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ width: "70%" }}>
              <div>
                {graphtoShow && (
                  <HighchartReact
                    highcharts={Highcharts}
                    options={{
                      chart: {
                        borderColor:
                          "linear-gradient(to right, #9211a2, #4911a2)",
                        //borderColor: "linear-gradient(to right, #9211a2, #4911a2)",
                        borderWidth: 15,
                        borderRadius: 8,
                        type: "areaspline",
                        backgroundColor: {
                          linearGradient: [0, 0, 500, 500],
                          stops: [
                            [0, "rgba(146,17,162,1)"],
                            [1, "rgba(73, 17, 162, 1)"],
                          ],
                        },
                      },
                      title: {
                        style: { color: "#ffffff" },
                        y: 30,
                        // text: grapghCompanyName,
                        text: "Impressions",
                      },
                      legend: {
                        layout: "vertical",
                        align: "left",
                        verticalAlign: "top",
                        x: 150,
                        y: 100,
                        floating: true,
                        borderWidth: 1,
                        backgroundColor:
                          Highcharts.defaultOptions.legend.backgroundColor ||
                          "#FFFFFF",
                      },
                      xAxis: {
                        style: { padding: 0 },
                        labels: {
                          style: {
                            color: "#ffffff",
                          },
                        },
                        categories: graphtoShow.x,
                      },
                      yAxis: {
                        title: {
                          text: "value",
                          style: { color: "#ffffff" },
                        },
                        labels: {
                          style: {
                            color: "#ffffff",
                          },
                        },
                      },
                      tooltip: {
                        shared: true,
                        valueSuffix: " units",
                      },
                      credits: {
                        enabled: false,
                      },
                      plotOptions: {
                        areaspline: {
                          fillOpacity: 0.7,
                        },
                      },
                      series: ChartSeries,
                    }}
                  ></HighchartReact>
                )}
              </div>
            </div>
            <div style={{ width: "25%" }}>
              {mediaState === "Statistic Reports" && (
                <div
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, #9211a2, #4911a2)",
                    color: "white",
                    borderRadius: "10px",
                    width: "80%",
                    height: "160px",
                    textAlign: "center",
                    margin: "8px 40px",
                  }}
                >
                  <span style={{ display: "block", fontSize: "20px" }}>
                    CPM [Display]
                  </span>
                  {MOProfile && (
                    <PercentCard
                      size={100}
                      strokeWidth={15}
                      percentage={MOProfile?.cpmDisplay * 4}
                      textSize={30}
                      color="#00DEFE"
                      cpm={MOProfile?.cpmDisplay}
                      currency={MOProfile?.currency?.symbol}
                    />
                  )}
                  {!MOProfile && <BallsLoading color="white" size={0.8} />}
                </div>
              )}
              {mediaState !== "Statistic Reports" && (
                <div
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, #9211a2, #4911a2)",
                    color: "white",
                    borderRadius: "10px",
                    width: "80%",
                    height: "160px",
                    textAlign: "center",
                    margin: "8px 40px",
                  }}
                >
                  <span style={{ display: "block", fontSize: "20px" }}>
                    CPM [Video]
                  </span>
                  {MOProfile && (
                    <PercentCard
                      size={100}
                      strokeWidth={15}
                      percentage={MOProfile?.cpmVideo * 4}
                      textSize={20}
                      color="#00DEFE"
                      cpm={MOProfile.cpmVideo}
                      currency={MOProfile?.currency?.symbol}
                    />
                  )}
                  {!MOProfile && <BallsLoading color="white" size={0.8} />}
                </div>
              )}
            </div>
          </div>
          <div>
            {tableData.length > 0 && (
              <CustomizedTables
                style={{ width: "100%" }}
                isEditable={false}
                headings={tableHeads}
                listdata={tableData}
                //redirectLink={"MediaOwnerProfile?id="}
              />
            )}
            {tableData.length > 0 && (
              <div>
                <span
                  style={{
                    color: "#FB6D6A",
                    fontWeight: "600",
                    margin: "0",
                    fontSize: "20px",
                    marginRight: "20px",
                  }}
                >
                  <Pagination
                    page={page}
                    count={Math.ceil(pagecount / 10)}
                    color="primary"
                    onChange={(e, page) => setpage(page)}
                    size="small"
                  />
                </span>
              </div>
            )}
            {noData && <h5>NO DATA!</h5>}
            {/* {adbutlerReportsData && <h4>Reports</h4>}
        {adbutlerReportsData && (
          <CustomizedTables
            style={{ width: "100%" }}
            isEditable={false}
            headings={tableHeads}
            listdata={tableData}
            //redirectLink={"MediaOwnerProfile?id="}
          />
        )} */}
            {/* {tableData.length > 0 && (
          <div>
            <span
              style={{
                color: "#FB6D6A",
                fontWeight: "600",
                margin: "0",
                fontSize: "20px",
                marginRight: "20px",
              }}
            >
              <Pagination
                page={page}
                count={Math.ceil(pagecount / 10)}
                color="primary"
                onChange={(e, page) => setpage(page)}
                size="small"
              />
            </span>
          </div>
        )} */}
          </div>
        </div>
      )}
    </div>
  );
};
export default MainLayout(MoPublications);
