import React, { useState, useEffect } from "react";
import MainLayout from "../../layouts/adminMainLayout";

import CircularProgress from "@material-ui/core/CircularProgress";

//TextField
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useStyles from "../../components/StyleClasses";
import { API, SECRET_KEY } from "../../constants/index";
import axios from "axios";

import NewAlert from "../../components/NewAlert";

import * as actions from "../../redux/actions/user";
function paramsToObject(entries) {
  const result = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}
const CompanyProfile = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const urlparams = new URLSearchParams(history.location.search);
  const paramEntries = urlparams.entries();
  const params = paramsToObject(paramEntries);
  const [moID, setmoID] = useState(params?.id ? params.id : "");
  const [name, setname] = useState("");
  const [videoCPM, setvideoCPM] = useState("");
  const [displayCPM, setdisplayCPM] = useState("");
  const [skinWallpaperCPM, setskinWallpaperCPM] = useState("");
  const [richMediaCPM, setrichMediaCPM] = useState("");
  const [inventoryPercent, setinventoryPercent] = useState("");

  ///////errors & successs //////////
  const [ErrSucMsg, setErrSucMesg] = useState("");
  const [ErrSucType, setErrSucType] = useState(true);
  ///////////////////////////////////

  const [loading, setloading] = useState(false);
  const [errors, setErrors] = useState({
    videoCPM: { error: false, txt: "" },
    name: { error: false, txt: "" },
    displayCPM: { error: false, txt: "" },
    skinWallpaperCPM: { error: false, txt: "" },
    richMediaCPM: { error: false, txt: "" },
    inventoryPercent: { error: false, txt: "" },
  });

  const access_token = window.localStorage.getItem(SECRET_KEY);

  const saveUser = () => {
    const body = {
      videoCPM: videoCPM,
      displayCPM: displayCPM,
      name: name,
      skinWallpaperCPM: skinWallpaperCPM,
      richMediaCPM: richMediaCPM,
      inventoryPercent: inventoryPercent,
    };
    axios
      .post(`${API}/admin/companies`, body, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setTimeout(() => {
            history.push("/admin/Industries");
          }, 1500);
          setErrSucMesg(res.data.message);
          setErrSucType(true);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
        }
      });
  };
  const updateUser = () => {
    const body = {
      videoCPM: videoCPM,
      displayCPM: displayCPM,
      name: name,
      skinWallpaperCPM: skinWallpaperCPM,
      richMediaCPM: richMediaCPM,
      inventoryPercent: inventoryPercent,
    };
    axios
      .put(`${API}/admin/companies/${params?.id}`, body, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setTimeout(() => {
            history.push("/admin/Industries");
          }, 1500);
          setErrSucMesg(res.data.message);
          setErrSucType(true);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
        }
      });
  };

  const getUserData = () => {
    if (params.id) {
      setloading(true);
      axios
        .get(`${API}/admin/companies/${moID}`, {
          headers: { Authorization: `Bearer ${access_token}` },
        })
        .then((res, err) => {
          if (res) {
            setloading(false);
            setname(res.data.name);
            setvideoCPM(res.data.videoCPM);
            setdisplayCPM(res.data.displayCPM);
            setskinWallpaperCPM(res.data.skinWallpaperCPM);
            setrichMediaCPM(res.data.richMediaCPM);
            setinventoryPercent(res.data.inventoryPercent);
          }
          if (err) {
          }
        })
        .catch(function (error) {
          if (error) {
          }
        });
    }
  };
  useEffect(() => getUserData(), []);

  return (
    <div className={classes.boxShadow} style={{ marginTop: "40px" }}>
      <NewAlert
        msg={ErrSucMsg}
        isSuccess={ErrSucType}
        close={() => setErrSucMesg("")}
      />
      <div className={classes.customfield}>
        <span
          style={{
            color: "#FB6D6A",
            fontWeight: "600",
            margin: "0",
            fontSize: "20px",
            marginLeft: "20px",
          }}
        >
          {params.id ? "Industry Profile" : "Create Industry"}
        </span>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "20px",
            }}
          >
            <div style={{ width: "50%" }}>
              <TextField
                error={errors.name.error}
                helperText={errors.name.txt}
                fullWidth
                placeholder="Name"
                label="Name"
                variant="outlined"
                style={{ display: "block", marginBottom: "10px" }}
                value={name}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    name: { error: false, txt: "" },
                  });
                  setname(e.target.value);
                }}
              />
              <TextField
                type="number"
                error={errors.videoCPM.error}
                helperText={errors.videoCPM.txt}
                fullWidth
                placeholder="Video CPM"
                label="Video CPM"
                variant="outlined"
                style={{ display: "block", marginBottom: "10px" }}
                value={videoCPM}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    videoCPM: { error: false, txt: "" },
                  });
                  setvideoCPM(e.target.value);
                }}
              />
              <TextField
                type="number"
                error={errors.displayCPM.error}
                helperText={errors.displayCPM.txt}
                fullWidth
                placeholder="Display CPM"
                label="displayCPM"
                variant="outlined"
                style={{ display: "block", marginBottom: "10px" }}
                value={displayCPM}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    displayCPM: { error: false, txt: "" },
                  });
                  setdisplayCPM(e.target.value);
                }}
              />
              <TextField
                type="number"
                error={errors.skinWallpaperCPM.error}
                helperText={errors.skinWallpaperCPM.txt}
                fullWidth
                placeholder="Skin Wallpaper CPM"
                label="Skin Wallpaper CPM"
                variant="outlined"
                style={{ display: "block", marginBottom: "10px" }}
                value={skinWallpaperCPM}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    skinWallpaperCPM: { error: false, txt: "" },
                  });
                  setskinWallpaperCPM(e.target.value);
                }}
              />
              <TextField
                type="number"
                error={errors.richMediaCPM.error}
                helperText={errors.richMediaCPM.txt}
                fullWidth
                placeholder="Rich Media CPM"
                label="Rich Media CPM"
                variant="outlined"
                style={{ display: "block", marginBottom: "10px" }}
                value={richMediaCPM}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    richMediaCPM: { error: false, txt: "" },
                  });
                  setrichMediaCPM(e.target.value);
                }}
              />
              <TextField
                type="number"
                error={errors.inventoryPercent.error}
                helperText={errors.inventoryPercent.txt}
                fullWidth
                placeholder="Inventory Percent"
                label="Inventory Percent"
                variant="outlined"
                style={{ display: "block", marginBottom: "10px" }}
                value={inventoryPercent}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    inventoryPercent: { error: false, txt: "" },
                  });
                  setinventoryPercent(e.target.value);
                }}
              />

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <button
                  className={classes.addActionBtn}
                  onClick={() => history.push("/admin/Industries")}
                  style={{ backgroundColor: "grey" }}
                >
                  Cancel
                </button>
                <button
                  className={classes.addActionBtn}
                  onClick={() => {
                    if (name === "")
                      setErrors({
                        ...errors,
                        name: { error: true, txt: "please enter name" },
                      });
                    if (videoCPM === "")
                      setErrors({
                        ...errors,
                        videoCPM: {
                          error: true,
                          txt: "please enter user Video CPM",
                        },
                      });
                    if (displayCPM === "")
                      setErrors({
                        ...errors,
                        displayCPM: {
                          error: true,
                          txt: "please enter display CPM",
                        },
                      });

                    if (skinWallpaperCPM === "")
                      setErrors({
                        ...errors,
                        skinWallpaperCPM: {
                          error: true,
                          txt: "please enter skin/Wallpaper CPM",
                        },
                      });
                    if (richMediaCPM === "")
                      setErrors({
                        ...errors,
                        richMediaCPM: {
                          error: true,
                          txt: "please enter rich Media CPM",
                        },
                      });
                    if (inventoryPercent === "")
                      setErrors({
                        ...errors,
                        inventoryPercent: {
                          error: true,
                          txt: "please enter inventory Percent",
                        },
                      });

                    if (
                      name !== "" &&
                      videoCPM !== "" &&
                      displayCPM !== "" &&
                      richMediaCPM !== "" &&
                      skinWallpaperCPM !== "" &&
                      inventoryPercent !== ""
                    ) {
                      params.id ? updateUser() : saveUser();
                    }
                  }}
                >
                  {params.id ? "Update" : "Create Industry"}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default MainLayout(CompanyProfile);
