/* eslint-disable no-unused-expressions */
import {
  Backdrop,
  Box,
  Card,
  CardContent,
  Fade,
  Grid,
  Modal,
} from "@material-ui/core/";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import LinearProgress from "@material-ui/core/LinearProgress";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "@material-ui/core/Select";
import EditIcon from "@material-ui/icons/Edit";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useStyles from "../../components/StyleClasses";
import Layout from "../../layouts/adminMainLayout";
import StepWraper from "./stepWraperCampaign";

//Icon
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import InputAdornment from "@material-ui/core/InputAdornment";
import BusinessIcon from "@material-ui/icons/Business";

//TextField
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { enGB } from "date-fns/locale";
import { DateRangePicker } from "react-nice-dates";
import "react-nice-dates/build/style.css";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import NewAlert from "../../components/NewAlert";
import { API, SECRET_KEY } from "../../constants/index";

import { setDate } from "date-fns";
import "jspdf-autotable";
import { of } from "rxjs";

function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

function paramsToObject(entries) {
  const result = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}

const CreateCampaign = (props) => {
  const propsState = props;
  const editData = propsState.location.state
    ? propsState.location.state.data
    : "";
  const editFlag = propsState.location.state
    ? propsState.location.state.editFlag
    : "";
  const history = useHistory();
  const urlparams = new URLSearchParams(history.location.search);
  const paramEntries = urlparams.entries();
  const params = paramsToObject(paramEntries);
  const access_token = window.localStorage.getItem(SECRET_KEY);
  const classes = useStyles();
  const [step, setStep] = useState(0);
  const [name, setName] = useState("");
  const [brand, setBrand] = useState("");
  const [expectedImpressions, setExpectedImpressions] = useState(null);
  const [Country, setCountry] = useState(0);
  const [Industry, setIndustry] = useState(0);
  const [resetCountry, setResetCountry] = useState(false);
  const [resetIndustry, setResetIndustry] = useState(false);
  const [demographic, setDemographic] = useState(0);
  const [description, setdescription] = useState("");
  const [clientName, setClientName] = useState("");
  const [channel, setChannel] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [AdType, setAdType] = useState(0);
  const [countriesList, setcountriesList] = useState([]);
  const [industriesList, setindustriesList] = useState([]);
  const [channesWithAdTypesList, setchannesWithAdTypesList] = useState([]);
  const [selectedChanNames, setselectedChanNames] = useState([]);
  const [acceptedChannels, setacceptedChannels] = useState([]);
  const [adtypeList, setadtypeList] = useState([]);
  const [renderChannels, setrenderChannels] = useState([]);
  const [renderAdTypes, setrenderAdTypes] = useState([]);

  const demographics = useSelector((state) => state.dropdownData?.demographics);
  const [SaveLoader, setSaveLoader] = useState(false);
  ///////errors & successs //////////
  const [ErrSucMsg, setErrSucMesg] = useState("");
  const [ErrSucType, setErrSucType] = useState(true);
  const [toggle, setToggle] = useState(false);
  const [flag, setFlag] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [changeToggle, setChangeToggle] = useState(false);

  const [errors, setErrors] = useState({
    name: { error: false, txt: "" },
    brand: { error: false, txt: "" },
    expectedImpressions: { error: false, txt: "" },
    Industry: { error: false, txt: "" },
    Country: { error: false, txt: "" },
    demographic: { error: false, txt: "" },
    dates: { error: false, txt: "" },
    AdType: { error: false, txt: "" },
    channel: { error: false, txt: "" },
  });
  const [liftStore, setLiftStore] = useState([]);
  const [baselineStore, setBaselineStore] = useState([]);
  const [addmore, setAddmore] = useState([
    {
      section: "LIFT",
      selectedType: [],
      questionData: [
        {
          anchored_answer: "",
          check_answer: [],
          liftList: [],
          kpiType: 0,
          questionType: "",
          question: "",
          kpiErrorMsg: "",
          answers: [],
          curlyValues: [],
          curlyAns: [],
        },
      ],
    },
    {
      section: "BASELINE",
      selectedType: [],
      questionData: [
        {
          anchored_answer: "",
          baselineList: [],
          check_answer: [],
          kpiType: 0,
          questionType: "",
          question: "",
          kpiErrorMsg: "",
          answers: [],
          curlyValues: [],
          curlyAns: [],
        },
      ],
    },
  ]);

  const channelArray = [
    { _id: "digital", label: "Digital" },
    { _id: "linear_tv", label: "Linear Tv" },
  ];

  const [serverId, setServerId] = useState(0);
  const [publishersId, setPublishersId] = useState(0);
  const [renderPublishers, setRenderPublishers] = useState([]);
  const [publishers, setPublishers] = useState([]);
  const [publishersIds, setPublishersIds] = useState([]);
  const [siteId, setSiteId] = useState("[PUBLISHER_ID]");
  const [placementId, setPlacementId] = useState("[CAMPAIGN_ID]");
  const [creativeId, setCreativeId] = useState("[BANNER_ID]");
  const [mobId, setMobId] = useState("");
  const [cachebuster, setCachebuster] = useState("[timestamp]");
  const GDPR = "${GDPR}";
  const GDPR_CONSENT_508 = "${GDPR_CONSENT_508}";
  const GDPR_PD = "${GDPR_PD}";
  const URL = `https://tracker.samplicio.us/tracker/${serverId}/pixel.gif?sid=${siteId}&pid=${placementId}&crid=${creativeId}&cachebuster=${cachebuster}$gdpr=${GDPR}$gdpr_consent=${GDPR_CONSENT_508}&gdpr_pd=${GDPR_PD}`;

  const HTML_Privew = `<img src="https://tracker.samplicio.us/tracker/${serverId}/pixel.gif?sid=${siteId}&pid=${placementId}&crid=${creativeId}&cachebuster=${cachebuster}$gdpr=${GDPR}$gdpr_consent=${GDPR_CONSENT_508}&gdpr_pd=${GDPR_PD}">`;

  const [pixelErrors, setPixelErrors] = useState({
    serverId: { error: false, txt: "" },
    publishersId: { error: false, txt: "" },
    siteId: { error: false, txt: "" },
    placementId: { error: false, txt: "" },
    creativeId: { error: false, txt: "" },
    mobId: { error: false, txt: "" },
    cachebuster: { error: false, txt: "" },
    publishersIds: { error: false, txt: "" },
  });

  const numberMasking = (value) => {
    let newVal = value ? value.toString().replace(/\D/g, "") : "";
    if (newVal.length === 0) {
      newVal = "";
    }
    return newVal;
  };

  useEffect(() => {
    getDropDown();
    getCountryList();
    getIndustryList();
  }, []);

  useEffect(
    () =>
      setErrors({
        ...errors,
        dates: { error: false, txt: "" },
      }),
    [startDate]
  );

  const getDropDown = () => {
    axios
      .get(`${API}/admin/campaign/getAddCampaignData`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res) {
          setindustriesList(res.data.industries);
          setcountriesList(res.data.countries);
          setchannesWithAdTypesList(res.data.channesWithAdTypes);
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };

  const getCountryList = () => {
    axios
      .get(`${API}/admin/lucidApi//lookup/listCountryLanguages`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res) {
          setcountriesList(res.data.items);
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };

  const getIndustryList = () => {
    axios
      .get(`${API}/admin/lucidApi//lookup/listIndustries`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res) {
          setindustriesList(res.data.items);
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };

  const getLiftList = () => {
    if (Country === 0 && Industry === 0) return;
    axios
      .get(
        `${API}/admin/lucidApi/lift/${Country}/${Industry}/lookup/listTemplateQuestion`,
        {
          headers: { Authorization: `Bearer ${access_token}` },
        }
      )
      .then((res) => {
        if (res) {
          const addLiftList = [...addmore];
          addLiftList[0].questionData[0].liftList = res.data.items
            ? res.data.items
            : [];
          setAddmore(addLiftList);
          setLiftStore(res.data.items);
          if (!resetIndustry && !resetCountry) {
            getEditData();
          }
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };

  const getBaselineList = () => {
    if (Country === 0 && Industry === 0) return;
    axios
      .get(
        `${API}/admin/lucidApi/baseline/${Country}/${Industry}/lookup/listTemplateQuestion`,
        {
          headers: { Authorization: `Bearer ${access_token}` },
        }
      )
      .then((res) => {
        if (res) {
          const addBaselineList = [...addmore];
          addBaselineList[1].questionData[0].baselineList = res.data.items
            ? res.data.items
            : [];
          setAddmore(addBaselineList);
          setBaselineStore(res.data.items);
          if (!resetIndustry && !resetCountry) {
            getEditData();
          }
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };

  useEffect(() => {
    getBaselineList();
    getLiftList();
  }, [Industry, Country]);

  useEffect(() => {
    getPublishers();
  }, []);

  // useEffect(() => {
  //   if (
  //     publishersIds &&
  //     publishersIds.length &&
  //     publishers &&
  //     publishers.length
  //   ) {
  //     let a = publishers?.filter((o) => publishersIds.includes(o.id));
  //     let b = a.map((e) => e.name + " , ");
  //     let c = a.map((e) => e.id);
  //     setSiteId(c);
  //     setRenderPublishers(b);
  //     // getByPublisherId();
  //   }
  // }, [publishersIds]);

  useEffect(() => {
    if (publishers && publishers.length) {
      let a = publishers?.filter((o) => publishersId === o.id);
      if (a && a.length) {
        setSiteId(a[0].id);
      }
    }
  }, [publishersId]);

  // const getByPublisherId = () => {
  //   axios
  //     .get(`${API}/admin/AdButler/publishers/${publishersIds}`, {
  //       headers: { Authorization: `Bearer ${access_token}` },
  //     })
  //     .then((res) => {
  //       if (res) {
  //         console.log('publisher res.data',res.data);
  //       }
  //     })
  //     .catch(function (error) {
  //       if (error) {
  //       }
  //     });
  // };

  const getPublishers = () => {
    axios
      .get(`${API}/admin/AdButler/publishers?offset=0`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res) {
          setPublishers(res.data.data);
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };

  useEffect(() => {
    if (channesWithAdTypesList.length < 1) return;
    let selectedChan = channesWithAdTypesList.filter((e) =>
      channel.includes(e._id)
    );
    let allAds = [];
    selectedChan.forEach((e) => e.AdTypes.forEach((e) => allAds.push(e)));
    setadtypeList(allAds);
  }, [channel]);

  useEffect(() => {
    if (channesWithAdTypesList.length > 1) {
      let a = channesWithAdTypesList.filter((o) =>
        channel.includes(o.channelName)
      );
      let b = a.map((e) => e.channelName + " , ");
      // setrenderChannels(b.slice(0, 30)[0]);
      setrenderChannels(b);
      let c = b.map((e) => e.toLowerCase());
      setacceptedChannels(c);
    }
  }, [channel]);

  useEffect(() => {
    if (adtypeList.length) {
      let a = adtypeList.filter((o) => AdType.includes(o._id));
      let b = a.map((e) => e.name + " , ");

      setrenderAdTypes(b.slice(0, 30)[0]);
    }
  }, []);

  const save = () => {
    // if (toggle) return;
    const cid = serverId ? serverId : editData.campaign_id;
    setSaveLoader(true);
    let questionArr = [];
    let questionArr1 = [];
    let finalBaseArray = [];
    let finalListArray = [];
    addmore &&
      addmore.length &&
      addmore[0].questionData &&
      addmore[0].questionData.map((ad, i) => {
        let ansArray = [];
        finalListArray = ad.answers.map(function (obj) {
          return obj.ans;
        });
        ad.answers.map((obj,index) => {
          if (ad.check_answer.includes(obj.ans)) {
            ansArray.push(index);
          }
        });
        questionArr.push({
          question_text: ad.question,
          question_name: ad.kpiType,
          kpi_type: ad.kpiType,
          anchored_answer:
            ad.questionType === "checkbox" ? null : ad.anchored_answer,
            multi_target_option_index:
            ad.questionType === "checkbox"
              ? ansArray:[],
          question_type: ad.questionType,
          answers: finalListArray,
          order: i + 1,
        });
        questionArr[0].default = true;
      });

    addmore &&
      addmore.length &&
      addmore[1].questionData &&
      addmore[1].questionData.map((ad, i) => {
        let ansArray = [];
        finalBaseArray = ad.answers.map(function (obj) {
          return obj.ans;
        });
        ad.answers.map((obj,index) => {
          if (ad.check_answer.includes(obj.ans)) {
            ansArray.push(index);
          }
        });
        questionArr1.push({
          question_text: ad.question,
          question_name: ad.kpiType,
          kpi_type: ad.kpiType,
          anchored_answer:
            ad.questionType === "checkbox" ? null : ad.anchored_answer,
          multi_target_option_index:
            ad.questionType === "checkbox"
              ? ansArray:[],
          question_type: ad.questionType,
          answers: finalBaseArray,
          order: i + 1,
        });
        questionArr1[0].default = true;
      });
    const nm = /[,\-]/.test(name) ? name : `${name}-${demographic}`;
    const payload = {
      media_planner_id:
        history.location &&
        history.location.search &&
        history.location.search.split("=")[1],
      campaign_name: nm,
      start_date: moment(startDate).format("YYYY-MM-DD hh:mm:ss.ssssss"),
      end_date: moment(endDate).format("YYYY-MM-DD hh:mm:ss.ssssss"),
      client_name: brand,
      country_language_id: Country.toString(),
      industry_id: Industry,
      channels: channel && channel.length ? channel : [],
      // adType: AdType && AdType,
      draft_settings: {
        questions: questionArr,
        baseline_questions: questionArr1,
        allow_ip: false,
        expected_impressions_count: Number(expectedImpressions),
        expected_impressions_count_linear_tv: 1,
      },

    };

    const endPoint = serverId ? `/admin/lucidApi/${cid}` : "/admin/lucidApi/";
    const request = serverId ? axios.put : axios.post;
    request(`${API}${endPoint}`, payload, {
      headers: {
        Authorization: `Bearer ${access_token}`,
        "content-type": "application/json",
      },
    })
      .then((res) => {
        if (res) {
          setServerId(res.data.campaign_id);
          setChangeToggle(false);
          setErrSucMesg(
            res.data.message
              ? res.data.message
              : serverId
              ? "Campaign updated successfully."
              : "Campaign created successfully."
          );
          setErrSucType(true);
          // setTimeout(() => {
          //   history.push(`/admin/manageCampaign?planer=${params?.creator}`);
          // }, 1500);
          setSaveLoader(false);
        }
      })
      .catch(function (error) {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
          setSaveLoader(false);
          setChangeToggle(false);
        } else {
          setErrSucMesg(
            serverId ? "Campaign not updated" : "Campaign not created"
          );
          setErrSucType(false);
          setSaveLoader(false);
          setChangeToggle(false);
        }
      });
  };

  useEffect(() => {
    if (editData) {
      setCountry(editData.country_language_id);
      setIndustry(editData.industry_id);
    }
  }, [editData]);

  useEffect(() => {
    if (!editData) return;
    if (liftStore && baselineStore) {
      editData.baselineStore = baselineStore;
      editData.liftStore = liftStore;
      if (!resetIndustry && !resetCountry) {
        getEditData();
      }
    }
  }, [liftStore, baselineStore]);

  const getEditData = () => {
    let editAddmore = [...addmore];
    if (editData) {
      let cname = editData.campaign_name.split("-");
      setDemographic(demographic ? demographic : cname[1]);
      setName(name ? name : editData.campaign_name);
      setServerId(editData.campaign_id);
      setBrand(brand ? brand : editData.client_name);
      setIndustry(Industry ? Industry : editData.industry_id);
      setCountry(Country ? Country : editData.country_language_id);
      setChannel(channel && channel.length > 0 ? channel : editData.channels);
      setExpectedImpressions(
        editData.draft_settings
          ? editData.draft_settings.expected_impressions_count
          : 0
      );
      setStartDate(startDate ? startDate : new Date(editData.start_date));
      setEndDate(endDate ? endDate : new Date(editData.end_date));
      if (editData.draft_settings) {
        if (editAddmore[0].section === "LIFT") {
          let addArr = [];
          editData &&
            editData.draft_settings &&
            editData.draft_settings.questions &&
            editData.draft_settings.questions.length &&
            editData.draft_settings.questions.map((que, index) => {
              let a1 = [];
              let selectOp1 = [];
              que &&
                que.answers &&
                que.answers.length > 0 &&
                que.answers.map((an,index) => {
                  if(que.multi_target_option_index && que.multi_target_option_index.length && que.multi_target_option_index.includes(index)){
                    selectOp1.push(an);
                  }
                  a1.push({
                    ans: an,
                  });
                });
              addArr.push({
                anchored_answer:
                  que.question_type === "radio" ? que.anchored_answer : "",
                check_answer:
                  que.question_type === "checkbox"
                    ? selectOp1
                    : [],
                answers: a1,
                kpiType: que && que.kpi_type,
                question: que && que.question_text,
                questionType: que && que.question_type,
                liftList: editData.liftStore,
                curlyValues:
                  editData.liftStore &&
                  editData.liftStore.length &&
                  getInsideDoubleCurly(editData.liftStore[index].question_text),
                curlyAns:
                  editData.liftStore &&
                  editData.liftStore.length &&
                  editData.liftStore[index].fields.filter((q) => {
                    return getInsideDoubleCurly(
                      editData.liftStore[index].question_text
                    ).includes(que.question_text);
                  }),
              });
            });

          editAddmore[0].questionData = [...addArr];
        }
        if (editAddmore[1].section === "BASELINE") {
          let addArr1 = [];

          editData.draft_settings &&
            editData.draft_settings.baseline_questions &&
            editData.draft_settings.baseline_questions.length &&
            editData.draft_settings.baseline_questions.map((que, index) => {
              let a2 = [];
              let selectOp2 = [];

              que.answers.map((an,index) => {
                if(que.multi_target_option_index && que.multi_target_option_index.length && que.multi_target_option_index.includes(index)){
                  selectOp2.push(an);
                }
                a2.push({
                  ans: an,
                });
              });

              addArr1.push({
                anchored_answer:
                  que.question_type === "radio" ? que.anchored_answer : "",
                check_answer:
                  que.question_type === "checkbox"
                    ? selectOp2
                    : [],
                answers: a2,
                kpiType: que && que.kpi_type,
                question: que && que.question_text,
                questionType: que && que.question_type,
                baselineList: editData.baselineStore,
                curlyValues:
                  editData.baselineStore &&
                  editData.baselineStore.length &&
                  getInsideDoubleCurly(
                    editData.baselineStore[index].question_text
                  ),
                curlyAns:
                  editData.baselineStore &&
                  editData.baselineStore.length &&
                  editData.baselineStore[index].fields.filter((q) => {
                    return getInsideDoubleCurly(
                      editData.baselineStore[index].question_text
                    ).includes(que.question_text);
                  }),
              });
              editAddmore[1].questionData = [...addArr1];
            });
        }
      }
    }
  };

  const codeMasking = (value) => {
    let newVal = value;
    let newValueDash = newVal && newVal.length && newVal.split("-");

    let str = "";
    if (!newVal) {
      str = "";
      return newVal;
    } else {
      str =
        newValueDash[0] === ""
          ? setName("")
          : newValueDash[0] + "-" + demographic;
    }
    setName(str);
  };

  // useEffect(()=>{
  //   codeMasking(name);
  // },[name])

  const step0 = () => {
    return (
      <div className={classes.boxShadow} style={{ marginTop: "0px" }}>
        <NewAlert
          msg={ErrSucMsg}
          isSuccess={ErrSucType}
          close={() => setErrSucMesg("")}
        />
        <div
          className={classes.customfield}
          // style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>
            <h4 className={classes.redHead}>Brand Uplift Campaign Details</h4>
            <Grid container direction="row" spacing={2}>
              <Grid item md={4} sm={4} xs={4}>
                <TextField
                  // id="outlined-select-currency-native"
                  error={errors.demographic.error}
                  helperText={errors.demographic.txt}
                  select
                  fullWidth
                  label="Demographic"
                  value={demographic}
                  onChange={(e) => {
                    setDemographic(e.target.value);
                    setChangeToggle(true);
                    setErrors({
                      ...errors,
                      demographic: { error: false, txt: "" },
                    });
                  }}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {demographics?.map((option) => (
                    <option key={option._id} value={option.name}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={4} sm={4} xs={4}>
                <TextField
                  error={errors.name.error}
                  helperText={errors.name.txt}
                  fullWidth
                  label="Name"
                  variant="outlined"
                  style={{ display: "block", marginBottom: "10px" }}
                  value={name}
                  onChange={(e) => {
                    // let event = e.target.value;
                    // codeMasking(event);
                    setChangeToggle(true);
                    setName(e.target.value);
                    setErrors({
                      ...errors,
                      name: { error: false, txt: "" },
                    });
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircleIcon color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item md={4} sm={4} xs={4}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <BusinessIcon color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                  error={errors.Industry.error}
                  helperText={errors.Industry.txt}
                  style={{ display: "block", marginBottom: "10px" }}
                  fullWidth
                  select
                  label="Industry"
                  value={Industry}
                  onChange={(e) => {
                    setErrors({
                      ...errors,
                      Industry: { error: false, txt: "" },
                    });
                    setResetIndustry(true);
                    setChangeToggle(true);
                    onResetKpi(e);
                    setIndustry(e.target.value);
                  }}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option disabled key={0} value={0}>
                    Select...
                  </option>
                  {industriesList?.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.industry_name}
                    </option>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={2}>
              <Grid item md={4} sm={4} xs={4}>
                <TextField
                  error={errors.brand.error}
                  helperText={errors.brand.txt}
                  fullWidth
                  label="Brand"
                  variant="outlined"
                  style={{ display: "block", marginBottom: "10px" }}
                  value={brand}
                  onChange={(e) => {
                    setErrors({
                      ...errors,
                      brand: { error: false, txt: "" },
                    });
                    setBrand(e.target.value);
                    setChangeToggle(true);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <BusinessIcon color="disabled" size={10} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item md={4} sm={4} xs={4}>
                <TextField
                  error={errors.Country.error}
                  helperText={errors.Country.txt}
                  style={{ display: "block", marginBottom: "10px" }}
                  fullWidth
                  select
                  label="Country"
                  value={Country}
                  onChange={(e) => {
                    setErrors({
                      ...errors,
                      Country: { error: false, txt: "" },
                    });
                    setResetCountry(true);
                    setChangeToggle(true);
                    onResetKpi(e);
                    setCountry(e.target.value);
                  }}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option disabled key={0} value={0}>
                    Select...
                  </option>
                  {countriesList?.map((option) => (
                    <option key={option.language_id} value={option.id}>
                      {option.country_name}-{option.language_name}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={4} sm={4} xs={4}>
                <TextField
                  error={errors.expectedImpressions.error}
                  helperText={errors.expectedImpressions.txt}
                  fullWidth
                  label="Expected Impressions"
                  variant="outlined"
                  style={{ display: "block", marginBottom: "10px" }}
                  value={expectedImpressions}
                  onChange={(e) => {
                    let event = e;
                    if (
                      event.target.value.length >= 1 &&
                      event.target.value <= 999999
                    ) {
                      setFlag(true);
                    } else {
                      setFlag(false);
                    }
                    event.target.value = numberMasking(e.target.value);
                    setErrors({
                      ...errors,
                      expectedImpressions: { error: false, txt: "" },
                    });
                    setChangeToggle(true);
                    setExpectedImpressions(e.target.value);
                  }}
                />
                {flag && (
                  <span style={{ color: "red", marginBottom: "0px" }}>
                    Please enter value above 1 Million
                  </span>
                )}
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={2}>
              <Grid item md={6} sm={6} xs={6}>
                <h4 className={classes.redHead} style={{ fontSize: "17px" }}>
                  Channel
                </h4>
                <FormControl
                  fullWidth
                  component="fieldset"
                  variant="outlined"
                  className={[classes.selectControl, classes.customfield].join(
                    " "
                  )}
                >
                  <TextField
                    // error={errors.channel.error}
                    // helperText={errors.channel.txt}
                    style={{ display: "block", marginBottom: "10px" }}
                    fullWidth
                    select
                    label="Channel"
                    value={channel}
                    onChange={(e) => {
                      // setErrors({
                      //   ...errors,
                      //   channel: { error: false, txt: "" },
                      // });
                      setChannel([e.target.value]);
                      setChangeToggle(true);
                    }}
                    // SelectProps={{
                    //   native: true,
                    // }}
                    variant="outlined"
                  >
                    <option disabled key={0} value={0}>
                      Select...
                    </option>
                    {channelArray &&
                      channelArray?.map((option) => (
                        <option key={option._id} value={option._id}>
                          {option.label}
                        </option>
                      ))}
                  </TextField>

                  {/* <Select
                    id="outlined-select-currency-native"
                    label="Channel"
                    value={channel === "digital" ? "Digital":"Linear Tv"}
                    onChange={(e) => {
                      console.log('e',e.target.value);
                      {
                        setChannel(e.target.value);
                      }
                    }}
                    variant="outlined"
                    renderValue={(ep) => {console.log('ep',ep); renderChannels}}
                  >
                    <MenuItem disabled value={0}>
                      <em>Select...</em>
                    </MenuItem>
                    {[
                      {_id:"digital", label:"Digital"},
                      {_id:"linear_tv", label:"Linear Tv"}
                    ].map((option) => (
                      <MenuItem key={option._id} value={option._id}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select> */}

                  {/* <TextField
                    // error={errors.AdType.error}
                    // helperText={errors.AdType.txt}
                    style={{ display: "block", marginBottom: "10px" }}
                    fullWidth
                    select
                    label="Ad Type"
                    value={AdType}
                    onChange={(e) => {
                      // setErrors({
                      //   ...errors,
                      //   AdType: { error: false, txt: "" },
                      // });
                      setAdType(e.target.value);
                    }}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  >
                    <option disabled key={0} value={0}>
                      Select...
                    </option>
                    {adtypeList &&
                      adtypeList?.map((option) => (
                        <option key={option._id} value={option._id}>
                          {option.name}
                        </option>
                      ))}
                  </TextField> */}

                  {/* <InputLabel id="demo-mutiple-checkbox-label">
                    Channel
                  </InputLabel> */}
                </FormControl>
              </Grid>
              <Grid item md={6} sm={6} xs={6}>
                {/* <TextField
                  // error={errors.description.error}
                  // helperText={errors.description.txt}
                  fullWidth
                  label="Description"
                  multiline
                  rows={4}
                  placeholder="Description"
                  variant="outlined"
                  value={description}
                  onChange={(e) => {
                    // setErrors({
                    //   ...errors,
                    //   description: { error: false, txt: "" },
                    // });
                    setdescription(e.target.value);
                    setChangeToggle(true);
                  }}
                /> */}
              </Grid>
            </Grid>
            <h4 className={classes.redHead}>Duration</h4>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onStartDateChange={setStartDate}
              onEndDateChange={setEndDate}
              minimumDate={startDate || new Date()}
              minimumLength={1}
              format="dd MMM yyyy"
              locale={enGB}
            >
              {({ startDateInputProps, endDateInputProps, focus }) => (
                <Grid container direction="row" spacing={2}>
                  <Grid item md={6} sm={6} xs={6}>
                    <TextField
                      error={errors.dates.error}
                      helperText={errors.dates.txt}
                      fullWidth
                      id="startDate"
                      label="Available From"
                      variant="outlined"
                      margin="normal"
                      onChange={(e) => {
                        setChangeToggle(true);
                        setDate(e.target.value);
                      }}
                      className={classes.customfield}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...startDateInputProps}
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={6}>
                    <TextField
                      error={errors.dates.error}
                      helperText={errors.dates.txt}
                      fullWidth
                      id="endDate"
                      label="Available Upto"
                      variant="outlined"
                      margin="normal"
                      className={classes.customfield}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...endDateInputProps}
                    />
                  </Grid>
                </Grid>
              )}
            </DateRangePicker>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <button
            className={classes.addActionBtn}
            onClick={() =>
              history.push(`manageCampaign?planer=${params.creator}`)
            }
          >
            Cancel
          </button>
          <button
            className={classes.addActionBtn}
            onClick={() => {
              if (demographic === 0) {
                setErrors({
                  ...errors,
                  demographic: {
                    error: true,
                    txt: "please select an demographic",
                  },
                });
              } else if (name === "") {
                setErrors({
                  ...errors,
                  name: { error: true, txt: "please enter a name" },
                });
              } else if (brand === "") {
                setErrors({
                  ...errors,
                  brand: { error: true, txt: "please enter a brand" },
                });
              } else if (Industry === 0) {
                setErrors({
                  ...errors,
                  Industry: { error: true, txt: "please select an Industry" },
                });
              } else if (Country.length < 1) {
                setErrors({
                  ...errors,
                  Country: { error: true, txt: "please select countries" },
                });
              } else if (expectedImpressions === null) {
                setErrors({
                  ...errors,
                  expectedImpressions: {
                    error: true,
                    txt: "please enter a expected impressions",
                  },
                });
              }
              // else if (description === "") {
              //   setErrors({
              //     ...errors,
              //     description: {
              //       error: true,
              //       txt: "please Enter a Description",
              //     },
              //   });
              // }
              else if (startDate === "") {
                setErrors({
                  ...errors,
                  dates: { error: true, txt: "please select dates" },
                });
              } else {
                setStep(1);
                // getUplift();
                // getBasline();
                const sc = channesWithAdTypesList.filter((e) =>
                  channel.includes(e._id)
                );
                const scNames = sc.map((e) => e.channelName);
                setselectedChanNames(scNames);
              }
            }}
          >
            Next
          </button>
        </div>
      </div>
    );
  };

  const addMoreItem = (parentInd) => {
    const items = [...addmore];
    const ansArr = items[parentInd].questionData;
    // let liftListArr =
    //   items[parentInd].questionData[childInd].liftList &&
    //   items[parentInd].questionData[childInd].liftList.filter((li) => {
    //     return !items[parentInd].selectedType.includes(li.kpi_type);
    //   });

    // let baseListArr =
    // items[parentInd].questionData[childInd].baselineList &&
    // items[parentInd].questionData[childInd].baselineList.filter((li) => {
    //   return !items[parentInd].selectedType.includes(li.kpi_type);
    // });

    let liftListArr =
      liftStore &&
      liftStore.length &&
      liftStore.filter((li) => {
        return (
          ansArr &&
          ansArr.length &&
          !ansArr.find((ans) => ans.kpiType === li.kpi_type)
        );
      });
    let baseListArr =
      baselineStore &&
      baselineStore.length &&
      baselineStore.filter((li) => {
        return (
          ansArr &&
          ansArr.length &&
          !ansArr.find((ans) => ans.kpiType === li.kpi_type)
        );
      });

    if (items[parentInd].section === "LIFT") {
      ansArr.push({
        liftList: liftListArr,
        kpiType: 0,
        question: "",
        kpiErrorMsg: "",
        answers: [],
        curlyValues: [],
        curlyAns: [],
        check_answer: [],
      });
    } else {
      ansArr.push({
        baselineList: baseListArr,
        kpiType: 0,
        question: "",
        kpiErrorMsg: "",
        answers: [],
        curlyValues: [],
        curlyAns: [],
        check_answer: [],
      });
    }
    setAddmore([...items]);
  };

  const removeItem = async (parentInd, childInd) => {
    const removeArr = [...addmore];
    const removeAnsArr = removeArr[parentInd].questionData;
    if (removeAnsArr.length) {
      removeAnsArr.splice(childInd, 1);
    }
    setAddmore([...removeArr]);
  };

  const getInsideDoubleCurly = (str) =>
    str
      .split("{{")
      .filter((val) => val.includes("}}"))
      .map((val) => val.substring(0, val.indexOf("}}")));

  const setQuestionData = (type, pInd, cInd) => {
    const addmoreArr = [...addmore];

    let liftList =
      liftStore &&
      liftStore.filter((li) => {
        return li.kpi_type === type;
      });
    let baseList =
      baselineStore &&
      baselineStore.filter((bi) => {
        return bi.kpi_type === type;
      });

    if (addmoreArr[pInd].section === "LIFT") {
      addmoreArr[pInd].questionData[cInd].question = liftList[0].question_text;
      addmoreArr[pInd].questionData[cInd].questionType =
        liftList && liftList.length ? liftList[0].question_type : "";

      if (
        liftList &&
        liftList.length &&
        liftList[0].answers &&
        addmoreArr[pInd] &&
        addmoreArr[pInd].questionData[cInd]
      ) {
        addmoreArr[pInd].questionData[cInd].curlyValues =
          liftList &&
          liftList.length &&
          getInsideDoubleCurly(liftList[0].question_text),
          (addmoreArr[pInd].questionData[cInd].curlyAns =
            liftList &&
            liftList.length &&
            liftList[0].fields.filter((qt) => {
              return getInsideDoubleCurly(liftList[0].question_text).includes(
                qt.name
              );
            })),
          liftList[0].answers.map((an) => {
            addmoreArr[pInd].questionData[cInd].answers.push({
              ans: an,
            });
          });
      }
      if (
        liftList &&
        liftList.length &&
        liftList[0].question_text.search("{{") !== -1
      ) {
        changeQueLabel(pInd, cInd);
      }
    } else {
      addmoreArr[pInd].questionData[cInd].question = baseList[0].question_text;

      addmoreArr[pInd].questionData[cInd].questionType =
        baseList && baseList.length ? baseList[0].question_type : "";
      if (
        baseList &&
        baseList.length &&
        baseList[0].answers &&
        addmoreArr[pInd] &&
        addmoreArr[pInd].questionData[cInd]
      ) {
        (addmoreArr[pInd].questionData[cInd].curlyValues =
          baseList &&
          baseList.length &&
          getInsideDoubleCurly(baseList[0].question_text)),
          (addmoreArr[pInd].questionData[cInd].curlyAns =
            baseList &&
            baseList.length &&
            baseList[0].fields.filter((qt) => {
              return getInsideDoubleCurly(baseList[0].question_text).includes(
                qt.name
              );
            })),
          baseList[0].answers.map((an) => {
            addmoreArr[pInd].questionData[cInd].answers.push({
              ans: an,
            });
          });
      }
      if (
        baseList &&
        baseList.length &&
        baseList[0].question_text.search("{{") !== -1
      ) {
        changeQueLabel(pInd, cInd);
      }
    }
    setAddmore([...addmoreArr]);
  };

  const changeQueLabel = (pInd, cInd) => {
    const dummyArr = [...addmore];
    const kpiType = dummyArr[pInd].questionData[cInd].kpiType;

    let liftList =
      liftStore &&
      liftStore.filter((li) => {
        return li.kpi_type === kpiType;
      });
    let baseList =
      baselineStore &&
      baselineStore.filter((bi) => {
        return bi.kpi_type === kpiType;
      });

    let fullQuestion = "";
    let curlyAns = [];
    let curlyValues = [];
    if (dummyArr[pInd].section === "LIFT") {
      if (liftList && liftList.length) {
        fullQuestion = liftList[0].question_text;
        curlyAns = dummyArr[pInd].questionData[cInd]
          ? dummyArr[pInd].questionData[cInd].curlyAns
          : [];
        curlyValues = dummyArr[pInd].questionData[cInd]
          ? dummyArr[pInd].questionData[cInd].curlyValues
          : [];
        for (var i = 0; i < curlyValues.length; i++) {
          fullQuestion = fullQuestion.replace(
            new RegExp("{{" + curlyValues[i] + "}}", "gi"),
            curlyAns && curlyAns.length && curlyAns[i].value
          );
        }
        dummyArr[pInd].questionData[cInd].question = fullQuestion;
      }
    } else {
      if (baseList && baseList.length) {
        fullQuestion = baseList[0].question_text;
        curlyAns = dummyArr[pInd].questionData[cInd]
          ? dummyArr[pInd].questionData[cInd].curlyAns
          : [];
        curlyValues = dummyArr[pInd].questionData[cInd]
          ? dummyArr[pInd].questionData[cInd].curlyValues
          : [];
        for (var i = 0; i < curlyValues.length; i++) {
          fullQuestion = fullQuestion.replace(
            new RegExp("{{" + curlyValues[i] + "}}", "gi"),
            curlyAns && curlyAns.length && curlyAns[i].value
          );
        }
        dummyArr[pInd].questionData[cInd].question = fullQuestion;
      }
    }
    setAddmore([...dummyArr]);
  };

  const errorHandling = () => {
    let addMoreData = [...addmore];
    let valid = false;
    // save();
    if (addMoreData && addMoreData.length) {
      addMoreData.map((ad, index) => {
        ad.questionData &&
          ad.questionData.length &&
          ad.questionData.map((qd, i) => {
            if (qd.kpiType === 0) {
              addMoreData[index].questionData[i].kpiErrorMsg =
                "Please select an kpi type";
              valid = true;
              return true;
            } else {
              if (valid) return;
              valid = false;
              addMoreData[index].questionData[i].kpiErrorMsg = "";
            }
          });
      });
      setAddmore(addMoreData);
      if (
        !valid &&
        addMoreData[0].questionData &&
        addMoreData[0].questionData.length >= 1 &&
        addMoreData[1].questionData &&
        addMoreData[1].questionData.length >= 1
      ) {
        if (changeToggle) {
          save();
        }
        setStep(2);
      } else {
        setModalOpen(true);
      }
    }
  };

  const getCancelAnswer = (ques, pIdx, ansIdx) => {
    let addMoreArrs = [...addmore];
    if (addMoreArrs[pIdx].section === "LIFT") {
      addMoreArrs[pIdx].questionData &&
        addMoreArrs[pIdx].questionData.length &&
        addMoreArrs[pIdx].questionData?.map((qd) => {
          if (qd.kpiType === ques.kpiType) {
            qd.answers.splice(ansIdx, 1);
          }
        });
    } else {
      addMoreArrs[pIdx].questionData &&
        addMoreArrs[pIdx].questionData.length &&
        addMoreArrs[pIdx].questionData?.map((qd) => {
          if (qd.kpiType === ques.kpiType) {
            qd.answers.splice(ansIdx, 1);
          }
        });
    }
    setAddmore(addMoreArrs);
    setChangeToggle(true);
  };

  const step1 = () => {
    return (
      <div className={classes.boxShadow}>
        <NewAlert
          msg={ErrSucMsg}
          isSuccess={ErrSucType}
          close={() => setErrSucMesg("")}
        />
        <Dialog
          open={modalOpen}
          onClose={() => {
            setModalOpen(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Please select atleast one question from each
          </DialogTitle>
          <DialogActions>
            <button
              className={classes.addActionBtn}
              onClick={() => {
                setModalOpen(false);
              }}
            >
              Ok
            </button>
          </DialogActions>
        </Dialog>
        <Grid container direction="row" spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            {addmore && addmore.length
              ? addmore.map((li, index) => (
                  <>
                    {index > 0 && <hr />}
                    <h4 className={classes.redHead}>{li.section}</h4>
                    {li.questionData && li.questionData.length
                      ? li.questionData.map((que, i) => (
                          <div key={i}>
                            <Grid container direction="row" spacing={2}>
                              <Grid item md={2} sm={2} xs={2}>
                                <b>Question Type:</b>
                              </Grid>
                              <Grid item md={6} sm={6} xs={6}>
                                <TextField
                                  size="small"
                                  // error={li.questionData[i].kpiErrorMsg}
                                  // helperText={li.questionData[i].kpiErrorMsg}
                                  style={{
                                    display: "block",
                                    marginBottom: "10px",
                                  }}
                                  fullWidth
                                  select
                                  label="KPI Type"
                                  value={li.questionData[i].kpiType}
                                  onChange={(e) => {
                                    let addmoreArr = [...addmore];
                                    addmoreArr[index].questionData[i].kpiType =
                                      e.target.value;
                                    addmoreArr[index].selectedType.push(
                                      e.target.value
                                    );
                                    addmoreArr[index].questionData[
                                      i
                                    ].answers = [];
                                    addmoreArr[index].questionData[i].question =
                                      "";
                                    if (
                                      addmoreArr[index].questionData[i]
                                        .kpiType !== 0
                                    ) {
                                      addmoreArr[index].questionData[
                                        i
                                      ].kpiErrorMsg = "";
                                    }
                                    setQuestionData(e.target.value, index, i);
                                    setChangeToggle(true);
                                    setAddmore(addmoreArr);
                                  }}
                                  SelectProps={{
                                    native: true,
                                  }}
                                  variant="outlined"
                                >
                                  <option disabled key={0} value={0}>
                                    Select...
                                  </option>
                                  {(li.section === "LIFT"
                                    ? que.liftList
                                    : que.baselineList
                                  )?.map((option) => (
                                    <option
                                      key={option.kpi_type}
                                      value={option.kpi_type}
                                    >
                                      {option.question_name}
                                    </option>
                                  ))}
                                </TextField>
                                {addmore[index].questionData[i].kpiErrorMsg ? (
                                  <span
                                    style={{
                                      color: "red",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    {addmore[index].questionData[i].kpiErrorMsg}
                                  </span>
                                ) : null}
                              </Grid>
                            </Grid>
                            <br />
                            {addmore[index] &&
                            addmore[index].questionData[i] &&
                            addmore[index].questionData[i].kpiType !== 0 ? (
                              <>
                                <Grid container direction="row" spacing={2}>
                                  <Grid item md={2} sm={2} xs={2}>
                                    <b>Question:</b>
                                  </Grid>
                                  <Grid container md={6} sm={6} xs={6}>
                                    {que.editType == 'edit' ? 
                                    <Grid md={11} sm={11} xs={11} style={{paddingLeft: '8px', paddingRight: '4px'}}>
                                      <TextField
                                          size="small"
                                          fullWidth
                                          variant="outlined"
                                          style={{
                                            marginTop: "10px",
                                            marginBottom: "10px",
                                          }}
                                          value={que.updateQuestion}
                                           onChange={(e) => {
                                            let qs = [...addmore];
                                            qs[index].questionData[i].updateQuestion = e.target.value;
                                            setAddmore(qs);
                                          }}
                                        />
                                    </Grid>
                                      : 
                                      <Grid md={11} sm={11} xs={11} style={{paddingLeft: '8px', paddingRight: '4px'}}>
                                      {que.question}
                                      </Grid>}
                                    {que.editType == 'edit' ?
                                     <Grid md={1} sm={1} xs={1} style={{lineHeight: '55px'}}>
                                    <button 
                                      className="btn btn-primary"
                                      onClick={() => {
                                        let qs = [...addmore];
                                        qs[index].questionData[i].editType = '';
                                        qs[index].questionData[i].question = qs[index].questionData[i].updateQuestion;
                                        qs[index].questionData[i].curlyValues = [];
                                        qs[index].questionData[i].curlyAns = [];
                                        setAddmore(qs);
                                        setChangeToggle(true);
                                      }}>Save</button>
                                      </Grid> : 
                                    <Grid md={1} sm={1} xs={1}>
                                    <EditIcon
                                      fontSize="small"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        let qs = [...addmore];
                                        qs[index].questionData[i].editType = 'edit';
                                        qs[index].questionData[i].updateQuestion = qs[index].questionData[i].question
                                        setAddmore(qs);
                                      }}
                                    /></Grid>}
                                  </Grid>
                                </Grid>
                                <br />
                                <Grid container direction="row" spacing={1}>
                                  <Grid item md={2} sm={2} xs={2} />
                                  <Grid item md={6} sm={6} xs={6}>
                                    {que.curlyAns && que.curlyAns.length
                                      ? que.curlyAns.map((cu, j) => (
                                          <>
                                            <TextField
                                              size="small"
                                              fullWidth
                                              variant="outlined"
                                              value={que.curlyAns[j].value}
                                              style={{
                                                marginTop: "10px",
                                                marginBottom: "10px",
                                              }}
                                              onChange={async (e) => {
                                                let changeLabel = [...addmore];
                                                changeLabel[index].questionData[
                                                  i
                                                ].curlyAns[j].value =
                                                  e.target.value;
                                                setAddmore(changeLabel);
                                                if (
                                                  que.curlyAns[j].value === ""
                                                ) {
                                                  setToggle(true);
                                                } else {
                                                  setToggle(false);
                                                }
                                                setChangeToggle(true);
                                                await changeQueLabel(index, i);
                                              }}
                                            />
                                            {que.curlyAns[j].value === "" && (
                                              <span style={{ color: "red" }}>
                                                Please enter some text to
                                                complete the question
                                              </span>
                                            )}
                                          </>
                                        ))
                                      : null}

                                    {que.answers && que.answers.length
                                      ? que.answers.map((a, k) => (
                                          <>
                                            <Grid
                                              container
                                              direction="row"
                                              spacing={1}
                                              // className={
                                              //   que.questionType === "checkbox" ? (que.check_answer &&
                                              //   que.check_answer.length
                                              //     && que.check_answer.includes(
                                              //         a.ans
                                              //       ) ? "addmore-checkbox-checked" : "addmore-checkbox") :''}
                                            >
                                              <Grid item md={1} sm={1} xs={1}>
                                                <FormControl>
                                                  {que.questionType ===
                                                    "radio" && (
                                                    <RadioGroup
                                                      aria-labelledby="demo-radio-buttons-group-label"
                                                      name="radio-buttons-group"
                                                    >
                                                      <FormControlLabel
                                                        value={a.ans}
                                                        control={
                                                          <Radio
                                                            checked={
                                                              que.anchored_answer ===
                                                              a.ans
                                                            }
                                                            onClick={(e) => {
                                                              let changeFlag = [
                                                                ...addmore,
                                                              ];
                                                              changeFlag[
                                                                index
                                                              ].questionData[
                                                                i
                                                              ].anchored_answer =
                                                                e.target.value;
                                                              changeFlag[
                                                                index
                                                              ].questionData[
                                                                i
                                                              ].questionType =
                                                                "radio";
                                                              setAddmore(
                                                                changeFlag
                                                              );
                                                              setChangeToggle(
                                                                true
                                                              );
                                                            }}
                                                          />
                                                        }
                                                      />
                                                    </RadioGroup>
                                                  )}
                                                  {que.questionType ===
                                                    "checkbox" && (
                                                    <Checkbox
                                                      checked={
                                                        que.check_answer &&
                                                        que.check_answer.length
                                                          ? que.check_answer.includes(
                                                              a.ans
                                                            )
                                                          : false
                                                      }
                                                      onChange={(e) => {
                                                        let changeFlag = [
                                                          ...addmore,
                                                        ];
                                                        let changeLabel =
                                                          changeFlag[index]
                                                            .questionData[i];

                                                        if (
                                                          que.check_answer &&
                                                          que.check_answer
                                                            .length &&
                                                          que.check_answer.includes(
                                                            e.target.value
                                                          )
                                                        ) {
                                                          let indexValue = que.check_answer.indexOf(
                                                            e.target.value
                                                          );
                                                          que.check_answer.splice(
                                                            indexValue,
                                                            1
                                                          );
                                                        } else {
                                                          changeLabel.check_answer.push(
                                                            e.target.value
                                                          );
                                                          changeLabel.questionType =
                                                            "checkbox";
                                                        }
                                                        setAddmore(changeFlag);
                                                        setChangeToggle(true);
                                                      }}
                                                      value={a.ans}
                                                      name={a.ans}
                                                    />
                                                  )}
                                                </FormControl>
                                              </Grid>
                                              <Grid
                                                item
                                                md={11}
                                                sm={11}
                                                xs={11}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <TextField
                                                    size="small"
                                                    //   error={errors.description.error}
                                                    //   helperText={errors.description.txt}
                                                    fullWidth
                                                    label="Answer"
                                                    placeholder="answer"
                                                    variant="outlined"
                                                    value={que.answers[k].ans}
                                                    style={{
                                                      marginTop: "10px",
                                                    }}
                                                    onChange={(e) => {
                                                      let changeAns = [
                                                        ...addmore,
                                                      ];
                                                      let changeAnsValue =
                                                        changeAns[index]
                                                          .questionData[i];
                                                      if (
                                                        changeAnsValue.questionType ===
                                                        "radio"
                                                      ) {
                                                        changeAnsValue.anchored_answer =
                                                          e.target.value;
                                                      } else {
                                                        changeAnsValue
                                                          ? changeAnsValue.check_answer.push(
                                                              e.target.value
                                                            )
                                                          : "";
                                                      }
                                                      changeAnsValue.answers[
                                                        k
                                                      ].ans = e.target.value;
                                                      setAddmore(changeAns);
                                                      setChangeToggle(true);
                                                    }}
                                                  />
                                                  {que.questionType ===
                                                    "radio" &&
                                                  que.anchored_answer !==
                                                    que.answers[k].ans ? (
                                                    <span
                                                      style={{
                                                        marginLeft: "5px",
                                                      }}
                                                      onClick={() =>
                                                        getCancelAnswer(
                                                          que,
                                                          index,
                                                          k
                                                        )
                                                      }
                                                    >
                                                      X
                                                    </span>
                                                  ) : (
                                                    que.questionType ===
                                                      "checkbox" &&
                                                    !que.check_answer.includes(
                                                      que.answers[k].ans
                                                    ) && (
                                                      <span
                                                        style={{
                                                          marginLeft: "5px",
                                                        }}
                                                        onClick={() =>
                                                          getCancelAnswer(
                                                            que,
                                                            index,
                                                            k
                                                          )
                                                        }
                                                      >
                                                        X
                                                      </span>
                                                    )
                                                  )}
                                                </div>
                                              </Grid>
                                            </Grid>
                                            <br />
                                          </>
                                        ))
                                      : null}
                                  </Grid>
                                </Grid>
                                <Grid container direction="row" spacing={1}>
                                  <Grid item md={2} sm={2} xs={2} />
                                  <Grid
                                    item
                                    md={6}
                                    sm={6}
                                    xs={6}
                                    style={{ textAlign: "end" }}
                                  >
                                    {addmore[0].section === "LIFT"
                                      ? addmore[0] &&
                                        addmore[0].questionData.length <=
                                          liftStore.length - 1 && (
                                          <button
                                            className={classes.addActionBtn}
                                            style={{
                                              marginBottom: "10px",
                                              width: "70px",
                                            }}
                                            onClick={() =>
                                              addMoreItem(index, i)
                                            }
                                          >
                                            +
                                          </button>
                                        )
                                      : addmore[1] &&
                                        addmore[1].questionData.length <=
                                          baselineStore.length - 1 && (
                                          <button
                                            className={classes.addActionBtn}
                                            style={{
                                              marginBottom: "10px",
                                              width: "70px",
                                            }}
                                            onClick={() =>
                                              addMoreItem(index, i)
                                            }
                                          >
                                            +
                                          </button>
                                        )}
                                    {addmore[index] &&
                                      addmore[index].questionData.length >
                                        1 && (
                                        <button
                                          className={classes.addActionBtn}
                                          style={{
                                            marginLeft: "10px",
                                            width: "70px",
                                          }}
                                          onClick={() => {
                                            setChangeToggle(true)
                                            removeItem(index, i)
                                          }}
                                        >
                                          -
                                        </button>
                                      )}
                                  </Grid>
                                </Grid>
                              </>
                            ) : null}
                          </div>
                        ))
                      : null}
                  </>
                ))
              : null}
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "40px",
          }}
        >
          <button
            className={classes.addActionBtn}
            onClick={(e) => {
              setStep(0);
            }}
          >
            Previous
          </button>
          <button
            className={classes.addActionBtn}
            onClick={() => {
              errorHandling();
            }}
          >
            Next
          </button>
        </div>
        {SaveLoader && <LinearProgress style={{ marginTop: "10px" }} />}
      </div>
    );
  };
  const onReset = (e) => {
    e.preventDefault();
    setSiteId("[PUBLISHER_ID]");
    setPlacementId("[CAMPAIGN_ID]");
    setCachebuster("[timestamp]");
    setMobId("");
    setCreativeId("[BANNER_ID]");
    setPublishersId(0);
    setPublishersIds([]);
    setPixelErrors({
      serverId: { error: false, txt: "" },
      publishersId: { error: false, txt: "" },
      siteId: { error: false, txt: "" },
      placementId: { error: false, txt: "" },
      creativeId: { error: false, txt: "" },
      mobId: { error: false, txt: "" },
      cachebuster: { error: false, txt: "" },
      publishersIds: { error: false, txt: "" },
    });
  };

  const onResetKpi = (e) => {
    e.preventDefault();
    setAddmore([
      {
        section: "LIFT",
        selectedType: [],
        questionData: [
          {
            anchored_answer: "",
            liftList: liftStore,
            kpiType: 0,
            questionType: "",
            question: "",
            kpiErrorMsg: "",
            answers: [],
            check_answer: [],
            curlyValues: [],
            curlyAns: [],
          },
        ],
      },
      {
        section: "BASELINE",
        selectedType: [],
        questionData: [
          {
            anchored_answer: "",
            baselineList: baselineStore,
            kpiType: 0,
            questionType: "",
            question: "",
            kpiErrorMsg: "",
            answers: [],
            check_answer: [],
            curlyValues: [],
            curlyAns: [],
          },
        ],
      },
    ]);
  };

  const openIframe = (link) => {
    return (
      <Modal
        open
        // onClose={() => onClose(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Box className={classes.boxModal}>
            <iframe src={link} width="100%" height="330"></iframe>
          </Box>
        </Fade>
      </Modal>
    );
  };

  const step2 = () => {
    return (
      <div className={classes.boxShadow} style={{ marginTop: "0px" }}>
        <NewAlert
          msg={ErrSucMsg}
          isSuccess={ErrSucType}
          close={() => setErrSucMesg("")}
        />
        <div
          className={classes.customfield}
          // style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>
            <h4 className={classes.redHead}>Create Universal Pixel</h4>
            <Grid container direction="row" spacing={2}>
              <Grid item md={6} sm={6} xs={6}>
                <Box>
                  {/* <TextField
                    id="outlined-select-currency-native"
                    error={pixelErrors.publishersId.error}
                    helperText={pixelErrors.publishersId.txt}
                    select
                    fullWidth
                    label="Select Your Ad Server:"
                    value={publishersId}
                    onChange={(e) => {
                      setPublishersId(e.target.value);
                      setPixelErrors({
                        ...pixelErrors,
                        publishersId: { error: false, txt: "" },
                      });
                    }}
                    // SelectProps={{
                    //   native: true,
                    // }}
                    variant="outlined"
                  >
                    <option key={0} value={0}>
                      Select...
                    </option>
                    {publishers &&
                      publishers.length &&
                      publishers?.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                  </TextField> */}
                  {/* <a onClick={(e) => onReset(e)}>
                    <b style={{ float: "right", cursor: "pointer" }}>
                      Reset to Default Values
                    </b>
                  </a> */}
                  {/* <Grid
                    container
                    style={{ marginBottom: "10px" }}
                    direction="row"
                    spacing={2}
                  >
                    <Grid item md={4} sm={4} xs={4}>
                      <b>Publisher ID:</b>
                    </Grid>
                    <Grid item md={8} sm={8} xs={8}>
                      <FormControl
                        fullWidth
                        component="fieldset"
                        variant="outlined"
                        label="Publisher Id"
                        className={[
                          classes.selectControl,
                          classes.customfield,
                        ].join(" ")}
                      >
                        <Select
                          id="outlined-select-currency-native"
                          // label="Publisher Id"
                          value={publishersIds}
                          onChange={(e) => {
                            setPublishersIds(e.target.value);
                            setPixelErrors({
                              ...pixelErrors,
                              publishersIds: { error: false, txt: "" },
                            });
                          }}
                          variant="outlined"
                          renderValue={(ep) => renderPublishers}
                        >
                          <MenuItem disabled value={0}>
                            <em>Select...</em>
                          </MenuItem>
                          {publishers &&
                            publishers.length &&
                            publishers?.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                <Checkbox
                                  checked={publishersIds.includes(option.id)}
                                ></Checkbox>
                                {option.name}
                              </MenuItem>
                            ))}
                        </Select>
                        {pixelErrors.publishersIds &&
                          pixelErrors.publishersIds.txt && (
                            <span style={{ color: "red" }}>
                              {pixelErrors.publishersIds.txt}
                            </span>
                          )}
                      </FormControl>
                    </Grid>
                  </Grid> */}
                  <Grid
                    container
                    style={{ marginBottom: "10px", marginTop: "10px" }}
                    direction="row"
                    spacing={2}
                  >
                    <Grid item md={4} sm={4} xs={4}>
                      <b>Site ID:</b>
                    </Grid>
                    <Grid item md={8} sm={8} xs={8}>
                      <TextField
                        // error={pixelErrors.siteId.error}
                        // helperText={pixelErrors.siteId.txt}
                        fullWidth
                        // disabled
                        placeholder="[PUBLISHER_ID]"
                        label="Site ID"
                        variant="outlined"
                        style={{ display: "block", marginBottom: "10px" }}
                        value={siteId}
                        onChange={(e) => {
                          setSiteId(e.target.value);
                          // setPixelErrors({
                          //   ...pixelErrors,
                          //   siteId: { error: false, txt: "" },
                          // });
                        }}
                      />
                    </Grid>
                    <Grid item md={4} sm={4} xs={4}>
                      <b>Placement ID:</b>
                    </Grid>
                    <Grid item md={8} sm={8} xs={8}>
                      <TextField
                        // error={pixelErrors.placementId.error}
                        // helperText={pixelErrors.placementId.txt}
                        fullWidth
                        label="Placement ID"
                        placeholder="[CAMPAIGN_ID]"
                        variant="outlined"
                        style={{ display: "block", marginBottom: "10px" }}
                        value={placementId}
                        onChange={(e) => {
                          setPlacementId(e.target.value);
                          // setPixelErrors({
                          //   ...pixelErrors,
                          //   placementId: { error: false, txt: "" },
                          // });
                        }}
                      />
                    </Grid>
                    <Grid item md={4} sm={4} xs={4}>
                      <b>Creative ID:</b>
                    </Grid>
                    <Grid item md={8} sm={8} xs={8}>
                      <TextField
                        // error={pixelErrors.creativeId.error}
                        // helperText={pixelErrors.creativeId.txt}
                        fullWidth
                        label="Creavtive ID"
                        placeholder="[BANNER_ID]"
                        variant="outlined"
                        style={{ display: "block", marginBottom: "10px" }}
                        value={creativeId}
                        onChange={(e) => {
                          setCreativeId(e.target.value);
                          // setPixelErrors({
                          //   ...pixelErrors,
                          //   creativeId: { error: false, txt: "" },
                          // });
                        }}
                      />
                    </Grid>
                    {/* <Grid item md={4} sm={4} xs={4}>
                      <b>Mobile Advertising ID:</b>
                    </Grid>
                    <Grid item md={8} sm={8} xs={8}>
                      <TextField
                        error={pixelErrors.mobId.error}
                        helperText={pixelErrors.mobId.txt}
                        fullWidth
                        label="Mobile Advertising ID"
                        placeholder="[timestamp]"
                        variant="outlined"
                        style={{ display: "block", marginBottom: "10px" }}
                        value={mobId}
                        onChange={(e) => {
                          setMobId(e.target.value);
                          setPixelErrors({
                            ...pixelErrors,
                            mobId: { error: false, txt: "" },
                          });
                        }}
                      />
                    </Grid> */}
                    <Grid item md={4} sm={4} xs={4}>
                      <b>Cachebuster:</b>
                    </Grid>
                    <Grid item md={8} sm={8} xs={8}>
                      <TextField
                        // error={pixelErrors.cachebuster.error}
                        // helperText={pixelErrors.cachebuster.txt}
                        fullWidth
                        label="Cachebuster"
                        variant="outlined"
                        placeholder="[InsertCachebusterHere]"
                        // disabled
                        style={{ display: "block", marginBottom: "10px" }}
                        value={cachebuster}
                        onChange={(e) => {
                          setCachebuster(e.target.value);
                          // setPixelErrors({
                          //   ...pixelErrors,
                          //   cachebuster: { error: false, txt: "" },
                          // });
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item md={6} sm={6} xs={6}>
                <b style={{ marginLeft: "5px" }}>URL Preview:</b>
                {/* <div title="View Link" onClick={() => {
                      openIframe(URL, "url");
                    }}> */}
                <TextField
                  // error={errors.description.error}
                  // helperText={errors.description.txt}
                  fullWidth
                  label="URL preview"
                  multiline
                  rows={5}
                  placeholder="URL preview"
                  variant="outlined"
                  value={URL}
                  disabled
                  // onFocus={() => {
                  //   openIframe(URL, "url");
                  // }}
                  style={{ margin: "5px" }}
                />
                {/* </div> */}
                <b style={{ marginLeft: "5px" }}>HTML Preview:</b>
                {/* <div title="View HTML" onClick={() => {
                      openIframe(HTML_Privew, "html");
                    }}> */}
                <TextField
                  // error={errors.description.error}
                  // helperText={errors.description.txt}
                  fullWidth
                  label="HTML preview"
                  multiline
                  rows={5}
                  placeholder="HTML preview"
                  variant="outlined"
                  value={HTML_Privew}
                  // onFocus={() => {
                  //   openIframe(HTML_Privew, "html");
                  // }}
                  style={{ margin: "5px" }}
                />
                {/* </div> */}
              </Grid>
            </Grid>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <button
            className={classes.addActionBtn}
            onClick={(e) => {
              setStep(1);
              onReset(e);
            }}
          >
            Previous
          </button>
          {/* {!SaveLoader && ( */}
          <button
            className={classes.addActionBtn}
            onClick={() => {
              // if (publishersIds && publishersIds.length === 0) {
              // if (publishersId === 0) {
              //   setPixelErrors({
              //     ...pixelErrors,
              //     publishersId: {
              //       error: true,
              //       txt: "please select publisher id",
              //     },
              //   });
              // } else if (serverId === 0) {
              //   setPixelErrors({
              //     ...pixelErrors,
              //     serverId: {
              //       error: true,
              //       txt: "please select an server",
              //     },
              //   });
              // } else
              //  if (siteId === "") {
              //   setPixelErrors({
              //     ...pixelErrors,
              //     siteId: { error: true, txt: "please enter a site id" },
              //   });
              // } else if (placementId === "") {
              //   setPixelErrors({
              //     ...pixelErrors,
              //     placementId: {
              //       error: true,
              //       txt: "please enter a placement id",
              //     },
              //   });
              // } else if (creativeId === "") {
              //   setPixelErrors({
              //     ...pixelErrors,
              //     creativeId: {
              //       error: true,
              //       txt: "please enter a creative id",
              //     },
              //   });
              // }
              // else if (mobId === "") {
              //   setPixelErrors({
              //     ...pixelErrors,
              //     mobId: {
              //       error: true,
              //       txt: "please enter a mobile advertising id",
              //     },
              //   });
              // }
              //  else if (cachebuster === "") {
              //   setPixelErrors({
              //     ...pixelErrors,
              //     cachebuster: {
              //       error: true,
              //       txt: "please enter a cachebuster",
              //     },
              //   });
              // } else {
              setTimeout(() => {
                history.push(`/admin/manageCampaign?planer=${params?.creator}`);
              }, 1500);
              // save();
              // }
            }}
            style={{ backgroundColor: "green" }}
          >
            Save
          </button>
          {/* )} */}
        </div>
      </div>
    );
  };

  return (
    <div>
      {step === 0 && <StepWraper step={0} />}
      {step === 1 && <StepWraper step={1} />}
      {step === 2 && <StepWraper step={2} />}
      {step === 0 && step0()}
      {step === 1 && step1()}
      {step === 2 && step2()}
    </div>
  );
};
export default Layout(CreateCampaign);
