import React, { useState, useEffect, useRef } from "react";
import MainLayout from "../../layouts/adminMainLayout";

import Pagination from "@material-ui/lab/Pagination";

import CircularProgress from "@material-ui/core/CircularProgress";
//Button
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
//Icon

import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { useHistory } from "react-router-dom";
import useStyles from "../../components/StyleClasses";
import CustomizedTables from "../mediaOwner/mytable";
import { API, SECRET_KEY } from "../../constants/index";
import axios from "axios";
import NewAlert from "../../components/NewAlert";

const ShowSubAdmin = () => {
  const classes = useStyles();
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [DATA, setDATA] = useState(null);
  const [loading, setloading] = useState(false);
  const [page, setpage] = useState(1);
  const [search, setSearch] = useState("");
  const access_token = window.localStorage.getItem(SECRET_KEY);
  const [pagecount, setPageCount] = useState(0);
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [file, setFile] = useState(false);
  const fileInputRef = useRef();

  ///////errors & successs //////////
  const [ErrSucMsg, setErrSucMesg] = useState("");
  const [ErrSucType, setErrSucType] = useState(true);
  ///////////////////////////////////

  const getSubAdminList = () => {
    setloading(true);
    setTableData([]);
    axios
      .get(`${API}/admin/Admins?page=${page}&name=${search}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setloading(false);
          setDATA(res.data.data);
          setPageCount(res.data.total);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };
  useEffect(() => getSubAdminList(), []);
  useEffect(() => getSubAdminList(), [page]);

  var myVar;

  function myFunction() {
    myVar = setTimeout(function () {
      getSubAdminList();
    }, 200);
  }

  function myStopFunction() {
    clearTimeout(myVar);
  }
  useEffect(() => myFunction(), [search]);
  useEffect(() => {
    if (DATA) {
      let td = [];
      DATA.forEach((e,i) => {
        td.push({
          data: [
            e.name ? e.name :"",
            e.username ? e.username :"",
            e.email ? e.email : ""
          ],
          _id: e._id,
        });
      });
      setTableData(td);
    }
  }, [DATA]);

  const FileUpload = (file) => {
    const formData = new FormData();

    formData.append('csv', file);
  
    axios
      .post(`${API}/admin/Admins/upload`, formData, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res) {
          setErrSucMesg(res.data.message);
          setErrSucType(true);
          setFile(true);
        }
      })
      .catch(function (error) {
        if (error) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
          setFile(true);
        }
      });
      getSubAdminList();
  };

  // useEffect(() => {
  //   if (fileInputRef) fileInputRef.current.value = null;
  // }, [file]);

  const updateSubscriptionStatus = (value,subData) => {
    const body = {
      username: subData && subData.data.length > 0 ? subData.data[1] :"",
      name: subData && subData.data.length > 0 ? subData.data[0] :"",
      email: subData && subData.data.length > 0 ? subData.data[2] :"",
    };
    axios
      .put(`${API}/admin/Admins/${subData && subData.data.length > 0 && subData._id}`, body, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setErrSucMesg(res.data.message);
          setErrSucType(true);
          getSubAdminList();
        }
      })
      .catch(function (error) {
        if (error) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
        }
      });
  };

  const DeleteSubAdmin = () => {
    // const payload = { id: id };
    axios
      .delete(`${API}/admin/${deleteId}/deleteadmin`, {
        headers: { Authorization: `Bearer ${access_token}` },
        // data: payload,
      })
      .then((res) => {
        if (res) {
          setErrSucMesg(res.data.message);
          setErrSucType(true);
          getSubAdminList();
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.data) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
        }
      });
  };

  return (
    <div>
      <NewAlert
          msg={ErrSucMsg}
          isSuccess={ErrSucType}
          close={() => setErrSucMesg("")}
        />
         {deleteFlag && (
          <Dialog
            open={deleteFlag}
            onClose={() => {
              setDeleteFlag(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Are you sure, you want to delete sub admin?
            </DialogTitle>
            <DialogActions>
              <Button
                style={{ color: "black", backgroundColor: "grey" }}
                onClick={() => {
                  setDeleteFlag(false);
                }}
              >
                Cancel
              </Button>
              <Button
                style={{ backgroundColor: "#4F74C9", color:"white"}}
                onClick={() => {
                  setDeleteFlag(false);
                  DeleteSubAdmin();
                }}
                autoFocus
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        )}
      <h2 style={{ color: "#4F74C9", fontWeight: "400" }}>Sub Admin</h2>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          onClick={() => history.push("/admin/subAdminProfile")}
          className={classes.addActionBtn}
          variant="outlined"
          color="primary"
          style={{ width: "20%", borderRadius: "50px" }}
        >
          <AddCircleOutlineIcon
            style={{ marginRight: "5px", color: "white" }}
          />
          Create Sub Admin
        </Button>
        <div
          className={classes.addActionBtn}
          style={{
            backgroundColor: "white",
            paddingRight: "7px",
            paddingLeft: "7px",
            width: "82%",
            borderRadius: "50px",
            boxShadow:
              "0px 0px 10px rgba(0,0,0,0.20), 0 0 10px rgba(0,0,0,0.15)",
          }}
        >
        <div className="row" style={{ display: "flex", justifyContent:"between", alignItems: "center" }}>
          {/* <div className="upload-csv-file">
            <input
              type="file"
              accept=".csv"
              ref={fileInputRef}
              onChange={(e) => {
                const fileUrl = e.target.files[0];
                FileUpload(fileUrl);
              }}
            />
        </div> */}
          <input
            placeholder="Search Sub Admin"
            //className={classes.addActionBtn}
            style={{
              outline: "none",
              backgroundColor: "white",
              border: "solid 2px #E5E5E5",
              color: "black",
              borderRadius: "23px",
              padding: "7px",
              width: "50%",
              // marginLeft:"10px"
            }}
            onChange={(event) => {
              myStopFunction();
              setSearch(event.target.value);
            }}
          ></input>
        </div>
        </div>
      </div>
      <div className={classes.boxShadow} style={{ marginTop: "40px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <span
            style={{
              color: "#FB6D6A",
              fontWeight: "600",
              margin: "0",
              fontSize: "20px",
              marginLeft: "20px",
            }}
          >
            Sub Admin
          </span>
          <span
            style={{
              color: "#FB6D6A",
              fontWeight: "600",
              margin: "0",
              fontSize: "20px",
              marginRight: "20px",
            }}
          >
            <Pagination
              count={Math.ceil(pagecount / 10)}
              color="primary"
              onChange={(e, page) => setpage(page)}
              size="small"
            />
          </span>
        </div>
        <CustomizedTables
          isEditable={true}
          headings={["Name", "User Name", "Email"]}
          listdata={tableData}
          redirectLink={"subAdminProfile?id="}
          handleDelete={(_id) => {
            setDeleteFlag(true);
            setDeleteId(_id);
          }}
          // radioHandleChange={(e,subData) => {
          //   updateSubscriptionStatus(e.target.value,subData);
          // }}
        />
        <div style={{ display: "flex", justifyContent: "center" }}>
          {loading && <CircularProgress />}
        </div>
      </div>
    </div>
  );
};
export default MainLayout(ShowSubAdmin);
