import { put, call, takeLatest } from 'redux-saga/effects';
import * as actions from '../redux/actions/media-owner';
import * as inventoryActions from '../redux/actions/inventory';
import { uploadAvatar, uploadCsv, postInventory, getMediaOwnerTitles, postMediOwnerAndInventory,getAllMediaOwners } from '../services/api/media-owner';

export function* callUploadAvatarApi(param) {
  try {
    const result = yield call(uploadAvatar, param && param.avatar);
    yield put(actions.uploadSuccessfully(result.data.avatarUrl));
  } catch(error) {
    yield put(actions.error(error));
  }
}

export function* callUploadCsvApi(param) {
  try {
    const result = yield call(uploadCsv, param && param.csv);
    yield put(actions.uploadCsvSuccessfully(result.data.csvUrl));
  } catch(error) {
    yield put(actions.error(error));
  }
}

export function* uploadImage() {
  yield takeLatest(actions.UPLOAD_AVATAR, callUploadAvatarApi);
}

export function* uploadCsvSaga() {
  yield takeLatest(actions.UPLOAD_CSV, callUploadCsvApi);
}

export function* callCreateInventoryApi(param) {
  try {
    const result = yield call(postInventory, param && param.mediaOwner);
    yield put(inventoryActions.inventorySaved(true));
  } catch(error) {
    yield put(inventoryActions.error(error));
  }
}

export function* callMediaOwnerTitlesApi(param) {
  try {
    const result = yield call(getMediaOwnerTitles, param && param.mediaOwnerId);
    console.info('a10: ', result)
    yield put(actions.getMediaOwnerTitles(result));
  } catch(error) {
    yield put(actions.error(error));
  }
}

export function* createInventorySaga() {
  yield takeLatest(inventoryActions.CREATE_INVENTORY, callCreateInventoryApi);
}

export function* getMediaOwnerTitlesSaga() {
  yield takeLatest(actions.LIST_MEDIA_OWNER_TITLE, callMediaOwnerTitlesApi);
}

export function* callCreateMediaOwnerAndInventoryApi(param) {
  try {
    console.info(' it is here ', param)
    const result = yield call(postMediOwnerAndInventory, param && param.mediaOwner);
    console.info(' it is here result', result)
    yield put(actions.createMediaOwnerAndInventorySuccessfull(true));
    yield put(inventoryActions.apiMediaOwnerSaved(result.data));
  } catch (error) {
    yield put(actions.error(error));
  }
}

export function* createMediaOwnerAndInventorySaga() {
  yield takeLatest(actions.CREATE_MEDIA_OWNER_AND_INVENTORY, callCreateMediaOwnerAndInventoryApi);
}

//----------------
export function* callAllMediaOwnerAPI(param) {
  try {
    const result = yield call(getAllMediaOwners,param && param.userId);
    console.info(' HIIIIIIIIIIIIII_Media_RESULT', result.data); 
    yield put(actions.saveAllMediaOwner(result.data));
  } catch(error) {
    yield put(actions.error(error));
  }
}

export function* getAllMediaOwnersSaga() {
  yield takeLatest(actions.GET_ALL_MEDIA_OWNER, callAllMediaOwnerAPI);
}