/* eslint-disable no-unused-expressions */
import React, { useEffect, useRef, useState } from "react";
import MainLayout from "../../layouts/adminMainLayout";

import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import BusinessIcon from "@material-ui/icons/Business";

//TextField
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import useStyles from "../../components/StyleClasses";
import { API, SECRET_KEY } from "../../constants/index";

import { id } from "date-fns/locale";
import CustomSelect from "../../components/CustomSelect";
import NewAlert from "../../components/NewAlert";

import { uploadFile } from "react-s3";

// import * as actions from "../../redux/actions/user";

const config = {
  bucketName: "brand-advance-images",
  region: "eu-west-2",
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
};

function paramsToObject(entries) {
  const result = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}
const CreateCompany = () => {
  const classes = useStyles();
  const history = useHistory();
  // const dispatch = useDispatch();
  const urlparams = new URLSearchParams(history.location.search);
  const paramEntries = urlparams.entries();
  const params = paramsToObject(paramEntries);
  // const [moID, setmoID] = useState(params?.id ? params.id : "");
  const [name, setName] = useState("");
  const [brandsList, setBrandsList] = useState([]);
  const [industriesList, setIndustriesList] = useState([]);
  const [industriesIds, setIndustriesIds] = useState([]);
  const [fileUrl, setFileUrl] = useState("");
  // const [Spinner, setSpinner] = useState(false);
  const [personas, setPersonas] = useState([]);
  const [personaId, setPersonaId] = useState([]);
  const [renderPersona, setRenderPersona] = useState([]);
  const [advertiser, setAdvertiser] = useState([]);
  const [advertiserId, setAdvertiserId] = useState([]);
  const [renderAdvertiser, setRenderAdvertiser] = useState([]);
  const [campaign, setCampaign] = useState([]);
  const [campaignId, setCampaignId] = useState([]);
  const [renderCampaign, setRenderCampaign] = useState([]);
  const [reportsData, setReportsData] = useState([]);
  const [reports, setReports] = useState([]);
  const [renderReports, setRenderReports] = useState([]);
  const [companyData, setCompanyData] = useState(null);
  const [fileToggle, setFileToggle] = useState(false);
  const [reportsCurrentPage, setReportsCurrentPage] = useState(1);
  const [reportsTotalPages, setReportsTotalPages] = useState(1);
  const [initialSelectedReports, setInitialSelectedReports] = useState([]);

  ///////errors & successs //////////
  const [ErrSucMsg, setErrSucMesg] = useState("");
  const [ErrSucType, setErrSucType] = useState(true);
  ///////////////////////////////////

  const [loading, setloading] = useState(false);
  const [reLoading, setReLoading] = useState(false)
  const [errors, setErrors] = useState({
    email: { error: false, txt: "" },
    name: { error: false, txt: "" },
    password: { error: false, txt: "" },
    personaId: { error: false, txt: "" },
    reports: { error: false, txt: "" },
    advertiserId: { error: false, txt: "" },
    campaignId: { error: false, txt: ""}
  });

  const [addmore, setAddmore] = useState([
    {
      industry: 0,
      brand: [],
      Industry: { error: false, txt: "" },
      Brand: { error: false, txt: "" },
      industriesList: [],
      brandName: [],
    },
  ]);

  const access_token = window.localStorage.getItem(SECRET_KEY);

  const saveCompany = () => {
    if (personaId.length === 0 || !name || campaignId.length === 0 || advertiserId.length === 0) return;
    // validateForm();
    let personaIdArr = [];
    personaId.length &&
      personaId.map((pers) => {
        personaIdArr.push({ persona: pers });
      });
    let reportsIdArr = [];
    reports.length &&
      reports.map((rep) => {
        reportsIdArr.push({ reports: rep });
      });

    let advertiserIdArr = [];
    advertiserId.length &&
      advertiserId.map((adv) => {
        advertiser.map((item) => {
          if (item.id === adv) {
            advertiserIdArr.push({ 
              advertiser: {
                id: adv,
                name: item.name
              }
            });
          }
        })
      });
    
    let campaignIdArr = [];
    campaignId.length &&
      campaignId.map((cam) => {
        campaign.map((item) => {
          if (item.id === cam) {
            campaignIdArr.push({ 
              campaign: {
                id: cam,
                name: item.name,
                advertiser: item.advertiser
              }
            });
          }
        })
      });

    const body = {
      // email: email,
      // password: password,
      companyName: name,
      companyLogo: fileUrl,
      industries: [],
      personas: personaIdArr,
      reports: reportsIdArr,
      advertisers: advertiserIdArr,
      campaigns: campaignIdArr,
    };

    addmore &&
      addmore.map((ad, i) => {
        if (ad.industry !== 0 || ad.brand.length) {
          const brandsArr = [];
          ad.brand.map((b) => {
            brandsArr.push({ brandId: b });
          });
          body.industries.push({
            industryId: ad.industry,
            brands: brandsArr,
          });
        } else {
          body.industries = [];
        }
      });

    axios
      .post(`${API}/admin/admin-company`, body, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setTimeout(() => {
            history.push("/admin/company");
          }, 1500);
          setErrSucMesg(res.data.message);
          setErrSucType(true);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
        }
      });
  };

  const updateCompany = () => {
    if (personaId.length === 0 || !name) return;
    let personaIdArr = [];
    personaId.length &&
      personaId.map((pers) => {
        personaIdArr.push({ persona: pers });
      });
    let reportsIdArr = [];
    reports.length &&
      reports.map((rep) => {
        reportsIdArr.push({ reports: rep });
      });
    let advertiserIdArr = [];
    advertiserId.length &&
      advertiserId.map((adv) => {
        advertiser.map((item) => {
          if (item.id === adv) {
            advertiserIdArr.push({ 
              advertiser: {
                id: adv,
                name: item.name
              }
            });
          }
        })
      });

    let campaignIdArr = [];
    campaignId.length &&
      campaignId.map((cam) => {
        campaign.map((item) => {
          if (item.id === cam) {
            campaignIdArr.push({ 
              campaign: {
                id: cam,
                name: item.name,
                advertiser: item.advertiser
              }
            });
          }
        })
      }); 
    const body = {
      // email: email,
      // password: password,
      companyName: name,
      companyLogo: fileUrl,
      personas: personaIdArr,
      industries: [],
      reports: reportsIdArr,
      advertisers: advertiserIdArr,
      campaigns: campaignIdArr,
    };

    addmore &&
      addmore.map((ad, i) => {
        if (ad.industry !== 0 || ad.brand.length) {
          const brandsArray = [];
          ad.brand.map((b) => {
            brandsArray.push({ brandId: b });
          });
          body.industries.push({
            industryId: ad.industry,
            brands: brandsArray,
          });
        } else {
          body.industries = [];
        }
      });

    axios
      .put(`${API}/admin/admin-company/${params?.id}`, body, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setTimeout(() => {
            history.push("/admin/company");
          }, 1500);
          setErrSucMesg(res.data.message);
          setErrSucType(true);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
        }
      });
  };

  useEffect(() => getDropDown(), []);

  useEffect(() => getReportsData(), []);

  const getDropDown = () => {
    axios
      .get(`${API}/admin/campaign/getAddCampaignData`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res) {
          setAddmore([
            {
              industry: 0,
              brand: [],
              Industry: { error: false, txt: "" },
              Brand: { error: false, txt: "" },
              industriesList: res.data.industries,
              brandName: [],
            },
          ]);
          setIndustriesList(res.data.industries);
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };

  const getReportsData = () => {
    axios
      .get(`${API}/admin/report?page=${reportsCurrentPage}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res && res.data) {
          let dt = [...reportsData, ...res.data.data]
          setReportsData(dt);
          setReportsCurrentPage(res.data.pagination.currentPage+1);
          setReportsTotalPages(res.data.pagination.total)
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };

  const getAllReportsData = () => {
    // setReLoading(true)
    axios.get(`${API}/admin/report/all`, {
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    })
    .then((res) => {
      if (res.data.result.length > 0 && reports.length > 0) {
        let a = res.data.result.filter((o) => reports.includes(o._id));
        let b = a.map((e) => e.report_name + " , ");

        setRenderReports(b.slice(0, 30));
      }
      // setReLoading(false)
    })
  }

  // const getAllSelectedCampaign = (data) => {
  //   let d = []
  //   data.map(async item => {
  //     await axios.get(`${API}/admin/AdButler/getAllCampaigns/${item.advertiser.id}?limit=100&skip=0`, {
  //       headers: { Authorization: `Bearer ${access_token}`},
  //     })
  //     .then(response => {
  //       response.data.map(res => {
  //         d.push(res)
  //       })
  //       setCampaign([...campaign, ...d])
  //     })
  //     .catch(error => {
  //       if (error && error.response && error.response.data) {
  //         setErrSucMesg(error.response.data.message);
  //         setErrSucType(false);
  //       }
  //     })
  //   })
  // }
  const [isCampaignListLoading, setIsCampaignListLoading] = useState(false)
  const [isAdvertisementUpdating, setIsAdvertisementUpdating] = useState(false)



  const getAllSelectedCampaign = (data) => {
    let advertisers = data.map((item) => item.advertiser.id)?.toString()
    setIsCampaignListLoading(true)
    axios.post(`${API}/admin/AdButler/getAllCampaigns`, {
      "limit": 100,
      "advertiser": advertisers
    }, {
      headers: { Authorization: `Bearer ${access_token}` },
    })
      .then(response => {
        setCampaign(response.data)
      })
      .catch(error => {
        if (error && error.response && error.response.data) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
        }
      }).finally(() => {
        setIsCampaignListLoading(false)
      })
  }

  const getCompanyData = () => {
    if (params.id) {
      setloading(true);
      axios
        .get(`${API}/admin/admin-company/${params.id}`, {
          headers: { Authorization: `Bearer ${access_token}` },
        })
        .then((res) => {
          setCompanyData(res.data);
          setloading(false);
          getAllSelectedCampaign(res.data.advertisers)
        })
        .catch(function (error) {
          if (error) {
            setloading(false);
          }
        });
    }
  };
  useEffect(() => getCompanyData(), [industriesList]);

  useEffect(() => {
    if (companyData) {
      const getPersonaId = [];
      companyData.personas &&
        companyData.personas.length > 0 &&
        companyData.personas.map((p) => {
          getPersonaId.push(p.persona ? p.persona._id:'');
        });
      const getReportsId = [];
      companyData.reports &&
        companyData.reports.length > 0 &&
        companyData.reports.map((p) => {
          if (p.reports !== null) {
            getReportsId.push(p.reports._id);
          }
        });
      const getAdvertiserId = [];
      companyData.advertisers &&
        companyData.advertisers.length > 0 &&
        companyData.advertisers.map(p => {
          getAdvertiserId.push(p.advertiser.id ? p.advertiser.id : '');
        });
      const getCampaignId = [];
      companyData.campaigns &&
        companyData.campaigns.length > 0 &&
        companyData.campaigns.map(p => {
          getCampaignId.push(p.campaign.id ? p.campaign.id : '');
        });
      setloading(false);
      setName(companyData.companyName);
      setReports(companyData.reports);
      let rep = [];
      companyData?.reports?.length && companyData.reports.map(i =>{
        rep.push(i.reports)
      })
      setInitialSelectedReports(rep);
      setFileUrl(companyData.companyLogo);
      setPersonaId(getPersonaId);
      setReports(getReportsId);
      setAdvertiserId(getAdvertiserId);
      setCampaignId(getCampaignId);
      const newAddmore = [];
      if (companyData.industries && companyData.industries.length > 0) {
        companyData.industries.map((ind) => {
          const getBrandsArray = [];
          const getBrandNames = [];
          const getIndustryId = [];
          getIndustryId.push(ind.industryId._id);
          ind.brands &&
            ind.brands.length > 0 &&
            ind.brands.filter(Boolean)?.map((b) => {
              if(b.brandId) getBrandsArray.push(b.brandId._id);
              if(b.brandId) getBrandNames.push(b.brandId.name + " , ");
            });
          newAddmore.push({
            industry: getIndustryId,
            brand: getBrandsArray,
            Industry: { error: false, txt: "" },
            Brand: { error: false, txt: "" },
            industriesList: industriesList,
            brandName: getBrandNames,
          });
        });
      } else {
        newAddmore.push({
          industry: 0,
          brand: [],
          Industry: { error: false, txt: "" },
          Brand: { error: false, txt: "" },
          industriesList: industriesList,
          brandName: [],
        });
      }
      setAddmore(newAddmore);
    }
  }, [companyData]);

  const getBrands = (id) => {
    setBrandsList([]);
    // setSpinner(true);
    axios
      .get(`${API}/admin/campaign/getBrandsWithName?industry=${id}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setBrandsList(res.data.data);
          // setSpinner(false);
        }
        if (err) {
          // setSpinner(false);
        }
      })
      .catch(function (error) {
        if (error) {
          // setSpinner(false);
        }
      });
  };

  const addMoreItem = () => {
    const indList = [...industriesList];
    let indListArr = indList.filter((i) => !industriesIds.includes(i._id));
    const items = [];
    items.push({
      industry: 0,
      brand: [],
      Industry: { error: false, txt: "" },
      Brand: { error: false, txt: "" },
      industriesList: indListArr,
      brandName: [],
    });
    setAddmore([...addmore, ...items]);
  };

  const removeItem = async (index, id) => {
    let indexses = industriesIds.indexOf(id);
    industriesIds.splice(indexses, 1);
    const removeArr = [...addmore];
    if (index < addmore.length - 1) {
      removeArr.splice(index, 1);
    } else removeArr.splice(index, 1);
    setAddmore([...removeArr]);
  };

  const validateForm = () => {
    let isValid = false;
    addmore &&
      addmore.map((ad, i) => {
        // let addIndustryErrors = [...addmore];
        if (ad.industry === 0) {
          // addIndustryErrors[i].Brand.error = true;
          // addIndustryErrors[i].Brand.txt =
          //   "please select an Industry";
          //   isValid = true;
          ad.Industry.error = true;
          ad.Industry.txt = "please select an Industry";
          isValid = true;
        }
        return ad;

        // else if (!ad.brand.length < 0) {
        //   let addBrandErrors = [...addmore];
        //   addBrandErrors[i].Brand.error = true;
        //   addBrandErrors[i].Brand.txt =
        //     "please select a Brand";
        //     isValid = true;
        //   setAddmore(addBrandErrors);
        // }
      });
    setAddmore(addmore);
  };

  const FileUpload = async (imageFile) => {
    if (!imageFile) return null;
    const newImage = imageFile;
    const file = new File(
      [newImage],
      `CompanyLogo/${newImage.name.split(".")[0]}.${
        newImage.name.split(".")[1]
      }`,
      { type: newImage.type }
    );
    const imgData = await uploadFile(file, config)
      .then((data) => {
        return data.location;
      })
      .catch((err) => {
        return null;
      });
    setFileUrl(imgData);
    setFileToggle(false);
  };

  useEffect(() => getPersonas(), []);

  const getPersonas = () => {
    setloading(true);
    axios
      .get(`${API}/admin/persona?skip=0&limit=10000`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res) {
          setPersonas(res.data.data);
        }
        setloading(false);
      })
      .catch(function (error) {
        if (error && error.response && error.response.data) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
        }
      });
  };

  useEffect(() => {
    const getAdvertiser = async () => {
      setloading(true);
      console.log('access_token', access_token)
      await axios.get(`${API}/admin/AdButler/advertisers?skip=0&limit=100`, {
        headers: { Authorization: `Bearer ${access_token}`},
      })
      .then(async res => {
        if (res) {
          // console.log('advertiser data', res.data)
          if (res.data[0] === null) {
            setAdvertiser([]);
          } else {
            setAdvertiser(res.data);
          }
        }
        setloading(false);
      })
      .catch(error => {
        if (error && error.response && error.response.data) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
        }
        setloading(false);
      })
    }
    getAdvertiser()
  }, [])

  useEffect(() => {
    if (personas.length > 0 && personaId.length > 0) {
      let a = personas.filter((o) => personaId.includes(o._id));
      let b = a.map((e) => e.about.name + " , ");

      setRenderPersona(b.slice(0, 30));
    }
  }, [personaId]);

  useEffect(() => {
    if (advertiser.length > 0 && advertiserId.length > 0) {
      let a = advertiser.filter((o) => advertiserId.includes(o.id));
      let b = a.map((e) => e.name + " , ");
      setRenderAdvertiser(b.slice(0, 30));
    }
  }, [advertiserId, advertiser]);

  useEffect(() => {
    if (campaign.length > 0 && campaignId.length > 0) {
      let a = campaign.filter((o) => campaignId.includes(o.id));
      let b = a.map((e) => e.name + " , ");
      setRenderCampaign(b.slice(0, 30));
    }
  }, [campaign, campaignId])

  useEffect(() => {
    getAllReportsData()
    // if (reportsData.length > 0 && reports.length > 0) {
    //   let a = reportsData.filter((o) => reports.includes(o._id));
    //   let b = a.map((e) => e.report_name + " , ");

    //   setRenderReports(b.slice(0, 30));
    // }
  }, [reports]);

  const reportsListInnerRef = useRef();

  const onScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
       if (bottom) { 
          if(reportsTotalPages == reportsData.length) return;
           getReportsData();
       }
  };

  // const advertiserInputHandler = async (e) => {
  //   setErrors({
  //     ...errors,
  //     advertiserId: { error: false, txt: "" },
  //   });
  //   setAdvertiserId(e.target.value);
  //   // setloading(true)
  //   // Get all campaigns respect to the selected advertiser
  //   await axios.get(`${API}/admin/AdButler/getAllCampaigns/${e.target.value}?limit=100&skip=0`, {
  //     headers: { Authorization: `Bearer ${access_token}`},
  //   })
  //   .then(response => {
  //     setCampaign([...response.data])
  //     // setloading(false)
  //   })
  //   .catch(error => {
  //     if (error && error.response && error.response.data) {
  //       setErrSucMesg(error.response.data.message);
  //       setErrSucType(false);
  //     }
  //     // setloading(false)
  //   })
  //   // setloading(false);
  // }

  const advertiserInputHandler = async (e) => {
    setIsAdvertisementUpdating(true)
    setErrors({
      ...errors,
      advertiserId: { error: false, txt: "" },
    });
    setAdvertiserId(e.target.value);
    // setloading(true)
    // Get all campaigns respect to the selected advertiser
    await axios.post(`${API}/admin/AdButler/getAllCampaigns/`,{
      limit:100,
      advertiser: e.target.value?.toString()
    }, {
      headers: { Authorization: `Bearer ${access_token}`},
    })
    .then(response => {
      setCampaign([...response.data])
      // setloading(false)
    })
    .catch(error => {
      if (error && error.response && error.response.data) {
        setErrSucMesg(error.response.data.message);
        setErrSucType(false);
      }
      // setloading(false)
    }).finally(() => {
      setIsAdvertisementUpdating(false)
    })
    // setloading(false);
  }

  if (reLoading) {
    return <CircularProgress />
  }

  return (
    <div className={classes.boxShadow} style={{ marginTop: "40px" }}>
      <NewAlert
        msg={ErrSucMsg}
        isSuccess={ErrSucType}
        close={() => setErrSucMesg("")}
      />
      <div className={classes.customfield}>
        <span
          style={{
            color: "#FB6D6A",
            fontWeight: "600",
            margin: "0",
            fontSize: "20px",
            marginLeft: "20px",
          }}
        >
          {params.id ? "Update Company" : "Create Company"}
        </span>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "20px",
            }}
          >
            <div style={{ width: "50%" }}>
              {/* <TextField
                type="email"
                error={errors.email.error}
                helperText={errors.email.txt}
                fullWidth
                placeholder="Company Email"
                label="Company Email"
                variant="outlined"
                style={{ display: "block", marginBottom: "10px" }}
                value={email}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    email: { error: false, txt: "" },
                  });
                  setEmail(e.target.value);
                }}
              />
              {!params.id && (
                <TextField
                  type="password"
                  error={errors.password.error}
                  helperText={errors.password.txt}
                  fullWidth
                  placeholder="Password"
                  label="Password"
                  variant="outlined"
                  style={{ display: "block", marginBottom: "10px" }}
                  value={password}
                  onChange={(e) => {
                    setErrors({
                      ...errors,
                      password: { error: false, txt: "" },
                    });
                    setPassword(e.target.value);
                  }}
                />
              )} */}
              <TextField
                error={errors.name.error}
                helperText={errors.name.txt}
                fullWidth
                placeholder="Company Name"
                label="Company Name"
                variant="outlined"
                style={{ display: "block", marginBottom: "10px" }}
                value={name}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    name: { error: false, txt: "" },
                  });
                  setName(e.target.value);
                }}
              />
              <Grid container direction="row" spacing={2}>
                <Grid item xs={fileUrl ? 10 : 12}>
                  <div className="upload-image-file">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        FileUpload(file);
                      }}
                    />
                  </div>
                  {!fileUrl && fileToggle && (
                    <span style={{ color: "red", marginBottom: "5px" }}>
                      Please choose file
                    </span>
                  )}
                </Grid>
                {fileUrl && (
                  <Grid item md={2} sm={2} xs={2}>
                    <img src={fileUrl} style={{ height: "40px" }} />
                  </Grid>
                )}
              </Grid>
              {/* <Grid container direction="row" spacing={2}> */}
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
              >
                <InputLabel id="demo-mutiple-checkbox-label">
                  Persona
                </InputLabel>
                <Select
                  error={errors.personaId.error}
                  id="outlined-select-currency-native"
                  multiple
                  label="Persona"
                  value={personaId}
                  onChange={(e) => {
                    {
                      setErrors({
                        ...errors,
                        personaId: { error: false, txt: "" },
                      });
                      setPersonaId(e.target.value);
                    }
                  }}
                  variant="outlined"
                  renderValue={(ep) => renderPersona}
                >
                  <MenuItem disabled value={0}>
                    <em>Select...</em>
                  </MenuItem>
                  {personas.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      <Checkbox
                        checked={
                          personaId.length > 0 && personaId.includes(option._id)
                        }
                      ></Checkbox>
                      {option.about.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.personaId.error && (
                  <span style={{ color: "red" }}>{errors.personaId.txt}</span>
                )}
              </FormControl>
              {/* Add advertiser start */}
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
                style={{position:"relative"}}
              >
                <InputLabel id="demo-mutiple-checkbox-label">
                  Advertiser
                </InputLabel>
                <Select
                  error={errors.advertiserId.error}
                  id="outlined-select-currency-native"
                  multiple
                  label="Advertiser"
                  value={advertiserId}
                  onChange={advertiserInputHandler}
                  variant="outlined"
                  renderValue={(ep) => renderAdvertiser}
                >
                  <MenuItem disabled value={0}>
                    <em>Select...</em>
                  </MenuItem>
                  {advertiser.map((option, index) => (
                    <MenuItem key={index} value={option?.id}>
                      <Checkbox
                        checked={
                          advertiserId.length > 0 && advertiserId.includes(option?.id)
                        }
                      ></Checkbox>
                      {option?.name}
                    </MenuItem>
                  ))}
                </Select>
                {isAdvertisementUpdating && <CircularProgress style={{top:"unset", left:"100%",width:"25px", height:"25px"}} />}
                {errors.advertiserId.error && (
                  <span style={{ color: "red" }}>{errors.advertiserId.txt}</span>
                )}
              </FormControl>
              {/* Get campaign list start */}
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
              >
                <InputLabel id="demo-mutiple-checkbox-label">
                  Campaign
                </InputLabel>
                <Select
                  error={errors.campaignId.error}
                  id="outlined-select-currency-native"
                  multiple
                  label="Campaign"
                  value={campaignId}
                  renderValue={(ep) => renderCampaign}
                  onChange={(e) => {
                    {
                      setErrors({
                        ...errors,
                        campaignId: { error: false, txt: "" },
                      });
                      setCampaignId(e.target.value);
                    }
                  }}
                  variant="outlined"
                >
                  <MenuItem disabled value={0}>
                    <em>Select...</em>
                  </MenuItem>
                  {campaign.length > 0 && campaign.map((option, index) => (
                    <MenuItem key={index} value={option?.id}>
                      <Checkbox
                        checked={
                          campaignId.length > 0 && campaignId.includes(option?.id)
                        }
                      ></Checkbox>
                      {option?.name}
                    </MenuItem>
                  ))}
                </Select>
                {isCampaignListLoading && <CircularProgress style={{top:"unset", left:"100%",width:"25px", height:"25px"}} />}
                {errors.campaignId.error && (
                  <span style={{ color: "red" }}>{errors.campaignId.txt}</span>
                )}
              </FormControl>
              {/* Get campaign list end */}
              {/* Add advertiser end */}
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
              >
                <InputLabel id="demo-mutiple-checkbox-label">
                  DECiA Reports
                </InputLabel>
                {/* <Select
                  error={errors.reports.error}
                  id="outlined-select-currency-native"
                  multiple
                  label="DECiA Reports"
                  value={reports}
                  onChange={(e) => {
                    setReports(e.target.value);
                  }}
                  onScroll={(e) => onScroll(e)} ref={reportsListInnerRef}
                  variant="outlined"
                  renderValue={(ep) => renderReports}
                >
                  <MenuItem disabled value={0}>
                    <em>Select...</em>
                  </MenuItem>
                  {reportsData &&
                    reportsData.length &&
                    reportsData.map((option) => (
                      <MenuItem key={option._id} value={option._id}>
                        <Checkbox
                          checked={
                            reports.length > 0 && reports.includes(option._id)
                          }
                        ></Checkbox>
                        {option.report_name}
                      </MenuItem>
                    ))}
                </Select> */}
                  <CustomSelect 
                    errors={errors.reports.error}
                    label="DECiA Reports"
                    responseTotalPages={reportsTotalPages}
                    stateTotalData={reportsData.length}
                    apiCallback={()=>getReportsData()}
                    onChangeCallback={(e) => {
                      setReports(e.target.value);
                    }}
                    selectedValues={reports}
                    renderValues={renderReports}
                    data={reportsData}
                    type="report_name"
                    initialSelectedData={initialSelectedReports}
                  />
                {/* {errors.reports.error && (
                  <span style={{ color: "red" }}>{errors.reports.txt}</span>
                )} */}
              </FormControl>
              {/* <TextField
                    error={errors.reports.error}
                    helperText={errors.reports.txt}
                    style={{ display: "block", marginBottom: "10px" }}
                    fullWidth
                    select
                    label="Deca Reports"
                    value={reports}
                    onChange={(e) => {
                      setErrors({
                        ...errors,
                        reports: { error: false, txt: "" },
                      });
                      setReports(e.target.value);
                    }}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  >
                    <option disabled key={0} value={0}>
                      Select...
                    </option>
                    {reportsData?.map((option) => (
                      <option key={option._id} value={option._id}>
                        {option.name}
                      </option>
                    ))}
                  </TextField> */}
              {/* </Grid> */}
              {addmore &&
                addmore.map((a, i) => (
                  <div key={i}>
                    <TextField
                      // InputProps={
                      //   Spinner
                      //     ? {
                      //         startAdornment: (
                      //           <InputAdornment position="start">
                      //             <CircularProgress
                      //               color="disabled"
                      //               size={10}
                      //             />
                      //           </InputAdornment>
                      //         ),
                      //       }
                      //     : {
                      //         startAdornment: (
                      //           <InputAdornment position="start">
                      //             <BusinessIcon color="disabled" size={10} />
                      //           </InputAdornment>
                      //         ),
                      //       }
                      // }
                      error={a.Industry.error}
                      helperText={a.Industry.txt}
                      style={{ display: "block", marginBottom: "10px" }}
                      fullWidth
                      select
                      label="Industry"
                      value={addmore[i].industry}
                      onChange={(e) => {
                        getBrands(e.target.value);
                        const indexes = [...industriesIds];
                        indexes.push(e.target.value);
                        setIndustriesIds(indexes);
                        let addmoreArr = [...addmore];
                        addmoreArr[i].industry = e.target.value;
                        addmoreArr[i].Industry.error = false;
                        addmoreArr[i].Industry.txt = "";
                        addmoreArr[i].brand = [];
                        setAddmore(addmoreArr);
                      }}
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                    >
                      <option disabled key={0} value={0}>
                        Select...
                      </option>
                      {addmore[i].industriesList?.map((option) => (
                        <option key={option._id} value={option._id}>
                          {option.name}
                        </option>
                      ))}
                    </TextField>

                    <FormControl
                      fullWidth
                      component="fieldset"
                      variant="outlined"
                      className={[
                        classes.selectControl,
                        classes.customfield,
                      ].join(" ")}
                    >
                      <InputLabel id="demo-mutiple-checkbox-label">
                        Brand
                      </InputLabel>
                      <Select
                        error={addmore[i].Brand.error}
                        id="outlined-select-currency-native"
                        multiple
                        label="Brand"
                        value={addmore[i].brand}
                        onChange={(e) => {
                          {
                            let addmoreArr = [...addmore];
                            let a = brandsList.filter((o) => {
                              return e.target.value.includes(o._id);
                            });
                            let b = a.map((e) => e.name + " , ");
                            addmoreArr[i].brandName = b;
                            addmoreArr[i].brand = e.target.value;
                            addmoreArr[i].Brand.error = false;
                            addmoreArr[i].Brand.txt = "";
                            setAddmore(addmoreArr);
                          }
                        }}
                        variant="outlined"
                        renderValue={(ep) => addmore[i].brandName}
                      >
                        <MenuItem disabled value={0}>
                          <em>Select...</em>
                        </MenuItem>
                        {brandsList &&
                          brandsList.length > 0 &&
                          brandsList.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              <Checkbox
                                checked={addmore[i].brand.includes(option._id)}
                              ></Checkbox>
                              {option.name}
                            </MenuItem>
                          ))}
                      </Select>
                      {addmore[i].Brand.error && (
                        <span style={{ color: "red" }}>
                          {addmore[i].Brand.txt}
                        </span>
                      )}
                    </FormControl>
                    <div
                      style={{
                        textAlign: "end",
                      }}
                    >
                      <button
                        className={classes.addActionBtn}
                        style={{ marginBottom: "10px" }}
                        onClick={() => addMoreItem()}
                      >
                        +
                      </button>
                      {addmore && addmore.length > 1 && (
                        <button
                          className={classes.addActionBtn}
                          style={{ marginLeft: "10px" }}
                          onClick={() => removeItem(i, addmore[i].industry)}
                        >
                          -
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <button
                  className={classes.addActionBtn}
                  onClick={() => history.push("/admin/company")}
                  style={{ backgroundColor: "grey" }}
                >
                  Cancel
                </button>
                <button
                  className={classes.addActionBtn}
                  onClick={() => {
                    setFileToggle(true);

                    // if (email === "")
                    //   setErrors({
                    //     ...errors,
                    //     email: { error: true, txt: "please enter company email" },
                    //   });
                    // if (!params.id && password === "")
                    //   setErrors({
                    //     ...errors,
                    //     password: {
                    //       error: true,
                    //       txt: "please enter password",
                    //     },
                    //   });
                    if (name === "") {
                      setErrors({
                        ...errors,
                        name: {
                          error: true,
                          txt: "please enter company name",
                        },
                      });
                    } else if (personaId.length === 0) {
                      setErrors({
                        ...errors,
                        personaId: {
                          error: true,
                          txt: "please select persona",
                        },
                      });
                    } else if (advertiserId.length === 0) {
                      setErrors({
                        ...errors,
                        advertiserId: {
                          error: true,
                          txt: "please select advertiser",
                        },
                      });
                    } else if (campaignId.length === 0) {
                      setErrors({
                        ...errors,
                        campaignId: {
                          error: true,
                          txt: "please select campaign",
                        },
                      });
                    }
                    // else if (reports.length === 0) {
                    //   setErrors({
                    //     ...errors,
                    //     reports: {
                    //       error: true,
                    //       txt: "please select deca reports",
                    //     },
                    //   });
                    // }

                    if (
                      (!params.id && name !== "") ||
                      // && email !== "" && password !== "")
                      fileUrl !== "" ||
                      (params.id && name !== "") ||
                      (personaId.length !== 0 && advertiserId.length !== 0 && campaignId.length !== 0)
                      // || reports.length !== 0
                      // && email !== "")
                    ) {
                      params.id ? updateCompany() : saveCompany();
                    }
                  }}
                >
                  {params.id ? "Update" : "Create Company"}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default MainLayout(CreateCompany);
