/* eslint-disable no-unused-expressions */
import {
  Card,
  CardContent,
  Grid,
} from "@material-ui/core/";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import Highcharts from "highcharts";
import HighchartReact from "highcharts-react-official";
import Highcharts3d from "highcharts/highcharts-3d.js";
import React, { useEffect, useState } from "react";
import NewAlert from "../../../components/NewAlert";
import useStyles from "../../../components/StyleClasses";
import { API, SECRET_KEY } from "../../../constants/index";
Highcharts3d(Highcharts);

const CreativeConsultancyPreview = ({ selectedListID, callback, questions }) => {
  const classes = useStyles();
  const access_token = window.localStorage.getItem(SECRET_KEY);
  const [loading, setloading] = useState(false);
  const [title, setTitle] = useState('');
  const [completedSurveys, setCompletedSurveys] = useState('');
  const [samplesize, setSamplesize] = useState('');
  const [usersList, setUsersList] = useState([]);
  const [graphsData, setGraphsData] = useState({});
  const [extraDataTop, setExtraDataTop] = useState([]);
  const [extraDataBottom, setExtraDataBottom] = useState([]);

  ///////errors & successs //////////
  const [ErrSucMsg, setErrSucMesg] = useState("");
  const [ErrSucType, setErrSucType] = useState(false);

  const getSurveyData = () => {
    setloading(true);
    const id = window.localStorage.getItem("ID");
    axios
      .get(`${API}/mediaPlanner/creativeconsultancy/report/${selectedListID}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res && res.data && res?.data?.message) {
          setErrSucMesg(res.data.message);
          setTimeout(() => {
            callback();
          }, 2000)
          return;
        }
        if (res && res.data) {
          setloading(false);
          let result = res.data;
          setTitle(result?.consultancy?.title);
          setCompletedSurveys(result.consultancy.completedSurveys);
          setSamplesize(result.consultancy.samplesize);
          setUsersList(result.users);
          let gd = { ...result?.graphs_data };
          let d = [];
          questions?.map((dt, i) => {
            let v = gd?.data?.filter(j => j.qid == dt._id);
            if (v.length) {
              //assigning original question name to questions
              v[0].name = dt.name;
              d.push(v[0])
            }
          });
          gd.data = d;
          setGraphsData(gd);
          let extraTop = [];
          let extraBottom = [];
          result.users?.map(i => {
            i.question?.map(j => {
              if (j._id == '6377867a37a3d30fab563847' || j.qid == '6377867a37a3d30fab563847' || j.name.includes('Please provide and overall score between 1-10')) {
                j.user = i.name;
                extraTop.push(j)
              }
              if (j._id == '6377866837a3d30fab563846' || j.qid == '6377866837a3d30fab563846') {
                j.user = i.name;
                extraBottom.push(j)
              }
            });
          });
          setExtraDataTop(extraTop);
          setExtraDataBottom(extraBottom);
        }
      });
  };

  useEffect(() => {
    if (selectedListID) getSurveyData();
  }, [selectedListID]);

  const getGraphPerc = (dt) => {
    let total = Object.values(dt).reduce((a, c) => a + c, 0);
    let obj = {};
    Object.keys(dt).map((i, id) => {
      obj[i] = Object.values(dt).map(i => i / total)[id] * 100;
    });
    dt = Object.entries(obj);
    return dt;
  }

  const ageValues = graphsData?.agegroup_graph ? getGraphPerc(graphsData?.agegroup_graph) : [];

  const findAverage = (data) => {
    let res = data;
    if (res?.length) {
      let rv = res?.filter(v => parseInt(v.value));
      let total = rv.length ? rv?.reduce((a, c) => parseInt(a) + parseInt(c.value), 0) : 0;
      return rv.length ? (total / rv.length).toFixed(1) : 0;
    } else {
      return 0;
    }
  };

  return (
    <div>
      <NewAlert
        msg={ErrSucMsg}
        isSuccess={ErrSucType}
        close={() => setErrSucMesg("")}
      />
      <div style={{ marginTop: "30px" }}>
        <Grid item xs={12}>
          <Card
            style={{
              backgroundImage: "linear-gradient(#4911a2,#9211a2)",
              color: "white",
              overflow: "auto",
              padding: "10px",
            }}
          >
            <CardContent style={{
              marginTop: "20px 20px 0 20px",
              padding: 0
            }}>
              <div>
                <div style={{ marginTop: "10px" }}>
                  <Grid
                    container
                    spacing={2}
                    style={{ marginTop: "20px" }}
                    className={classes.cardspace}
                  >
                    <Grid item xs={4}>
                    </Grid>
                    <Grid item xs={6}>
                      <h4
                        style={{
                          marginLeft: '30px',
                        }}
                      >
                        Creative Consultancy - {title}
                      </h4>
                    </Grid>
                    <Grid item xs={1} style={{ padding: 0 }}>
                      <div className="score-box">
                        <div className="count">
                          <span style={{ color: findAverage(extraDataTop) >= 7 ? 'white' : 'red' }}>{findAverage(extraDataTop)}</span>
                        </div>
                        <div className="count-score">
                          <span>10</span>
                        </div>
                      </div>
                      <div className="title-name">
                        <h5>Score</h5>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </CardContent>
            <div className="creative-consultancy">
              <Grid
                container
                spacing={2}
                className={classes.cardspace}
              >
                <Grid container xs={11}>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={7}>
                  </Grid>
                  {/* <Grid item xs={3}></Grid>
                  <Grid item xs={1} style={{ padding: 0 }}>
                    <div className="score-box">
                      <div className="count">
                        <span>{completedSurveys}</span>
                      </div>
                      <div className="count-score">
                        <span>{samplesize}</span>
                      </div>
                    </div>
                    <div className="title-name">
                      <h5>Score</h5>
                    </div>
                  </Grid> */}
                </Grid>
                <Grid item xs={9}>
                  <Grid
                    container
                    spacing={2}
                    style={{ marginTop: "20px" }}
                    className={`${classes.cardspace} map-box`} >
                    <Grid item xs={6}>
                      <div className="card">
                        <div className="card-body" style={{ height: '300px', backgroundColor: 'transparent', padding: 0 }}>
                          <HighchartReact
                            highcharts={Highcharts}
                            containerProps={{ style: { height: "100%" } }}
                            options={{
                              chart: {
                                type: "column",
                                plotBackgroundColor: null,
                                plotBorderWidth: null,
                                borderColor: null,
                                plotShadow: false,
                                backgroundColor: {
                                  linearGradient: [133, 94, 204, 0.5],
                                  stops: [
                                    [0, 'rgba(133,94,204,0.5)'],
                                    [1, 'rgba(61,0,255,1)'],
                                  ]
                                },

                                // options3d: {
                                //   enabled: true,
                                //   alpha: 50,
                                //   beta: 0,
                                // },
                              },
                              title: {
                                text: "Age",
                                style: {
                                  fontSize: '12px',
                                  color: '#fff'
                                }
                              },
                              xAxis: {
                                categories: graphsData?.agegroup_graph ? Object.keys(graphsData?.agegroup_graph) : [],
                                // categories: ['18-34', '35-44', '45-54', '55-64', '65+', 'Multiple'],
                                crosshair: true,
                                labels: {
                                  style: {
                                    color: '#fff'
                                  }
                                }
                              },
                              yAxis: {
                                // min: 0,
                                // title: {
                                //   text: 'Hello',
                                // },
                                labels: {
                                  style: {
                                    color: '#fff'
                                  }
                                }
                              },
                              tooltip: {
                                headerFormat:
                                  '<span style="font-size:10px">{point.key}</span><table>',
                                pointFormat:
                                  '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                                  '<td style="padding:0"><b>{point.y:.1f} %</b></td></tr>',
                                footerFormat: "</table>",
                                shared: true,
                                useHTML: true,
                              },
                              plotOptions: {
                                column: {
                                  pointPadding: 0.2,
                                  borderWidth: 0
                                },
                              },
                              series: [
                                {
                                  name: "",
                                  data: ageValues ? ageValues : [],
                                  colorByPoint: true,
                                  showInLegend: false,
                                  // data: [12, 10, 9,20,25,22],
                                  // color: {
                                  //   linearGradient: [0, 0, 500, 0],
                                  //   stops: [
                                  //     [0, "#99f546"],
                                  //     [1, "#1ddfa9"],
                                  //   ],
                                  // },
                                },
                              ],
                            }}
                          ></HighchartReact>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="card">
                        <div className="card-body demographic" style={{ height: '300px', backgroundColor: 'transparent', padding: 0 }}>
                          <HighchartReact
                            containerProps={{ style: { height: "100%" } }}
                            highcharts={Highcharts}
                            options={{
                              chart: {
                                plotBackgroundColor: null,
                                plotBorderWidth: null,
                                borderColor: null,
                                plotShadow: false,
                                type: "pie",
                                backgroundColor: {
                                  linearGradient: [133, 94, 204, 0.5],
                                  stops: [
                                    [0, 'rgba(133,94,204,0.5)'],
                                    [1, 'rgba(61,0,255,1)'],
                                  ]
                                },
                                options3d: {
                                  enabled: true,
                                  alpha: 50,
                                  beta: 0,
                                },
                              },
                              title: {
                                text: "Gender",
                                style: {
                                  fontSize: '12px',
                                  color: '#fff'
                                }
                              },
                              tooltip: {
                                pointFormat: "{series.name}: <b>{point.y:.1f}%</b>",
                              },
                              accessibility: {
                                point: {
                                  valueSuffix: "%",
                                },
                              },
                              plotOptions: {
                                pie: {
                                  allowPointSelect: true,
                                  cursor: "pointer",
                                  depth: 15,
                                  dataLabels: {
                                    style: {
                                      textShadow: false,
                                      textOutline: false,
                                      color: "white",
                                    },
                                    enabled: false,
                                    format: "{point.name}",
                                  },
                                  showInLegend: true
                                },
                              },
                              series: [
                                {
                                  type: "pie",
                                  name: "Answer",
                                  colorByPoint: true,
                                  data: graphsData?.gender_graph ? getGraphPerc(graphsData?.gender_graph) : [],
                                  // data: graphsData?.gender_graph ? Object.entries(graphsData?.gender_graph) : [],
                                },
                              ],
                              responsive: {
                                rules: [{
                                  condition: {
                                    maxWidth: 500
                                  },
                                  chartOptions: {
                                    legend: {
                                      align: 'center',
                                      verticalAlign: 'bottom',
                                      layout: 'horizontal',
                                      itemStyle: {
                                        color: '#fff'
                                      }
                                    }
                                  }
                                }]
                              }
                            }}
                          ></HighchartReact>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="card">
                        <div className="card-body demographic" style={{ height: '300px', backgroundColor: 'transparent', padding: 0 }}>
                          <HighchartReact
                            containerProps={{ style: { height: "100%" } }}
                            highcharts={Highcharts}
                            options={{
                              chart: {
                                plotBackgroundColor: null,
                                plotBorderWidth: null,
                                borderColor: null,
                                plotShadow: false,
                                type: "pie",
                                backgroundColor: {
                                  linearGradient: [133, 94, 204, 0.5],
                                  stops: [
                                    [0, 'rgba(133,94,204,0.5)'],
                                    [1, 'rgba(61,0,255,1)'],
                                  ]
                                },
                                options3d: {
                                  enabled: true,
                                  alpha: 50,
                                  beta: 0,
                                },
                              },
                              title: {
                                text: "Sexual orientation",
                                style: {
                                  fontSize: '12px',
                                  color: '#fff'
                                }
                              },
                              tooltip: {
                                pointFormat: "{series.name}: <b>{point.y:.1f}%</b>",
                              },
                              accessibility: {
                                point: {
                                  valueSuffix: "%",
                                },
                              },
                              plotOptions: {
                                pie: {
                                  allowPointSelect: true,
                                  cursor: "pointer",
                                  depth: 15,
                                  dataLabels: {
                                    style: {
                                      textShadow: false,
                                      textOutline: false,
                                      color: "white",
                                    },
                                    enabled: false,
                                    align: 'right',
                                    x: -10,
                                    format: "{point.name}",
                                  },
                                  showInLegend: true
                                },
                              },
                              series: [
                                {
                                  type: "pie",
                                  name: "Answer",
                                  colorByPoint: true,
                                  data: graphsData?.sexualOrintation_graph ? getGraphPerc(graphsData?.sexualOrintation_graph) : [],
                                  // data: graphsData?.sexualOrintation_graph ? Object.entries(graphsData?.sexualOrintation_graph) : [],
                                },
                              ],
                              responsive: {
                                rules: [{
                                  condition: {
                                    maxWidth: 500
                                  },
                                  chartOptions: {
                                    legend: {
                                      align: 'center',
                                      verticalAlign: 'bottom',
                                      layout: 'horizontal',
                                      itemStyle: {
                                        color: '#fff'
                                      }
                                    }
                                  }
                                }]
                              }
                            }}
                          ></HighchartReact>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="card">
                        <div className="card-body demographic" style={{ height: '300px', backgroundColor: 'transparent', padding: 0 }}>
                          <HighchartReact
                            containerProps={{ style: { height: "100%" } }}
                            highcharts={Highcharts}
                            options={{
                              chart: {
                                plotBackgroundColor: null,
                                plotBorderWidth: null,
                                borderColor: null,
                                plotShadow: false,
                                type: "pie",
                                backgroundColor: {
                                  linearGradient: [133, 94, 204, 0.5],
                                  stops: [
                                    [0, 'rgba(133,94,204,0.5)'],
                                    [1, 'rgba(61,0,255,1)'],
                                  ]
                                },
                                options3d: {
                                  enabled: true,
                                  alpha: 50,
                                  beta: 0,
                                },
                              },
                              title: {
                                text: "Ethnicity",
                                style: {
                                  fontSize: '12px',
                                  color: '#fff'
                                }
                              },
                              tooltip: {
                                pointFormat: "{series.name}: <b>{point.y:.1f}%</b>",
                              },
                              accessibility: {
                                point: {
                                  valueSuffix: "%",
                                },
                              },
                              plotOptions: {
                                pie: {
                                  allowPointSelect: true,
                                  cursor: "pointer",
                                  depth: 15,
                                  dataLabels: {
                                    style: {
                                      textShadow: false,
                                      textOutline: false,
                                      color: "white",
                                    },
                                    enabled: false,
                                    format: "{point.name}",
                                  },
                                  showInLegend: true
                                },
                              },
                              series: [
                                {
                                  type: "pie",
                                  name: "Answer",
                                  colorByPoint: true,
                                  // data: graphsData?.demographic_graph ? Object.entries(graphsData?.demographic_graph) : [],
                                  data: graphsData?.demographic_graph ? getGraphPerc(graphsData?.demographic_graph) : [],
                                },
                              ],
                              responsive: {
                                rules: [{
                                  condition: {
                                    maxWidth: 500
                                  },
                                  chartOptions: {
                                    legend: {
                                      align: 'center',
                                      verticalAlign: 'bottom',
                                      layout: 'horizontal',
                                      itemStyle: {
                                        color: '#fff'
                                      }
                                    }
                                  }
                                }]
                              }
                            }}
                          ></HighchartReact>
                        </div>
                      </div>
                    </Grid>
                    {graphsData?.data?.map((dt) => {
                      if (Object.keys(dt.value).includes('0')) return null;
                      let total = Object.values(dt.value).reduce((a, c) => a + c, 0);
                      let obj = {};
                      Object.keys(dt.value).map((i, id) => {
                        obj[i] = Object.values(dt.value).map(i => i / total)[id] * 100;
                      });
                      dt.value = obj;
                      return (
                        <Grid item xs={6}>
                          <div className="card">
                            <div className="card-body demographic" style={{ height: '300px', backgroundColor: 'transparent', padding: 0 }}>
                              <HighchartReact
                                containerProps={{ style: { height: "100%" } }}
                                highcharts={Highcharts}
                                options={{
                                  chart: {
                                    plotBorderWidth: null,
                                    plotBackgroundColor: null,
                                    borderColor: null,
                                    plotShadow: false,
                                    type: "pie",
                                    backgroundColor: {
                                      linearGradient: [133, 94, 204, 0.5],
                                      stops: [
                                        [0, 'rgba(133,94,204,0.5)'],
                                        [1, 'rgba(61,0,255,1)'],
                                      ]
                                    },
                                    options3d: {
                                      enabled: true,
                                      alpha: 50,
                                      beta: 0,
                                    },
                                  },
                                  title: {
                                    text: dt.name,
                                    style: {
                                      fontSize: '12px',
                                      color: '#fff'
                                    }
                                  },
                                  tooltip: {
                                    useHTML: true,
                                    // headerFormat: '<span class="tooltipHeader">{point.value}</span>',
                                    pointFormat: "{series.name}: <b>{point.y:.1f}%</b>",
                                  },
                                  accessibility: {
                                    point: {
                                      valueSuffix: "%",
                                    },
                                  },
                                  plotOptions: {
                                    pie: {
                                      allowPointSelect: true,
                                      cursor: "pointer",
                                      depth: 15,
                                      dataLabels: {
                                        style: {
                                          textShadow: false,
                                          textOutline: false,
                                          color: "white",
                                        },
                                        enabled: false,
                                        format: "{point.name}",
                                      },
                                      showInLegend: true
                                    },
                                  },
                                  series: [
                                    {
                                      type: "pie",
                                      name: "Percentage",
                                      colorByPoint: true,
                                      data: dt.value ? Object.entries(dt?.value) : [],
                                    },
                                  ],
                                  // series: [
                                  //   {
                                  //     type: "pie",
                                  //     name: "Percentage",
                                  //     colorByPoint: true,
                                  //     data: dt.value ? Object.entries(dt?.value) : [],
                                  //   },
                                  // ],
                                  responsive: {
                                    rules: [{
                                      condition: {
                                        maxWidth: 500
                                      },
                                      chartOptions: {
                                        legend: {
                                          align: 'center',
                                          verticalAlign: 'bottom',
                                          layout: 'horizontal',
                                          itemStyle: {
                                            color: '#fff'
                                          }
                                        }
                                      }
                                    }]
                                  }
                                }}
                              ></HighchartReact>
                            </div>
                          </div>
                        </Grid>
                      )
                    })}
                    {extraDataBottom?.length ?
                      <Grid item xs={6}>
                        <div className="comments">
                          <p>{extraDataBottom?.[0]?.name} </p>
                          {extraDataBottom.map(e => {
                            if (e.value == '') return;
                            return (
                              <div className="card">
                                <button className="right-answer-arrow"></button>
                                <div className="card-body badge-shape2" style={{ padding: '14px', minHeight: '32px' }}>
                                  <p style={{ margin: 0 }}>{e.value}</p>
                                  {/* <h6 style={{ float: 'right' }}>-{e.user}</h6> */}
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </Grid> : null}
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <div className="id-card">
                    {usersList?.map((i) =>
                      <div className="card message-content bg-green badge-shape2 undefined mt2" style={{ marginTop: '35px' }}>
                        <div className="card-body">
                          <div className="icon">
                            <img src={i.avatar !== 'imagelink' ? i.avatar : ''} />
                          </div>
                          <Grid
                            container
                            spacing={2}
                            className={classes.cardspace}
                          >
                            <Grid item xs={12}>
                              <Grid
                                container
                                spacing={2}
                                className={classes.cardspace}
                              >
                                <Grid item xs={12}>
                                  <span className="badge rounded-pill bg-white">
                                    <p style={{ margin: '5px' }}>Age Group: <i style={{ whiteSpace: "pre-wrap", fontWeight: '400' }}>{i.agegroups.name}</i></p>
                                    <p style={{ margin: '5px' }}>Gender: <i style={{ whiteSpace: "pre-wrap", fontWeight: '400' }}>{i.gender}</i></p>
                                    <p style={{ margin: '5px' }}>Ethnicity: <i style={{ whiteSpace: "pre-wrap", fontWeight: '400' }}>{i.demographics.name}</i></p>
                                  </span>
                                </Grid>
                                {i?.question?.length ?
                                  <Grid item xs={12}>
                                    {(i?.question?.filter(i => i._id == '6377862637a3d30fab563845' || i.qid == '6377862637a3d30fab563845' || i.name.includes('Please provide further comments around any question you rated to less than 4?'))[0]?.value !== '' && i?.question?.filter(i => i._id == '6377862637a3d30fab563845' || i.qid == '6377862637a3d30fab563845' || i.name.includes('Please provide further comments around any question you rated to less than 4?'))[0]?.name) ? <h6 style={{ margin: '15px' }}>{i?.question?.filter(i => i._id == '6377862637a3d30fab563845' || i.qid == '6377862637a3d30fab563845' || i.name.includes('Please provide further comments around any question you rated to less than 4?'))[0]?.name}</h6> : null}
                                      {/* <h6 style={{margin: "0"}}>{i.question[0].name}</h6> */}
                                    {i?.question?.filter(i => i._id == '6377862637a3d30fab563845' || i.qid == '6377862637a3d30fab563845' || i.name.includes('Please provide further comments around any question you rated to less than 4?'))[0]?.value !== '' && i?.question?.filter(i => i._id == '6377862637a3d30fab563845' || i.qid == '6377862637a3d30fab563845' || i.name.includes('Please provide further comments around any question you rated to less than 4?'))[0]?.name ?
                                      <div className="comments comments-side">
                                        <div className="card" style={{ margin: '0px' }}>
                                          <button className="left-answer-arrow"></button>
                                          <div className="card-body badge-shape2 badge-shape-right" style={{ minHeight: '32px' }}>
                                            <h6 style={{ color: '#fff' }}>{i?.question?.filter(i => i._id == '6377862637a3d30fab563845' || i.qid == '6377862637a3d30fab563845' || i.name.includes('Please provide further comments around any question you rated to less than 4?'))[0]?.value}</h6>
                                            {/* <h6>{i.question[0].value}</h6> */}
                                          </div>
                                        </div>
                                      </div> : null}
                                  </Grid> : null}
                              </Grid>

                            
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>
          </Card>
        </Grid>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {loading && <CircularProgress color="#fff" />}
        </div>
      </div>
    </div>
  );
};
export default CreativeConsultancyPreview;
