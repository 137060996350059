import { useEffect, useState } from "react";

const useMultipleKpiStates = ({ selected_channel_names_arr, step }) => {
  const [kpi_states, setKpiStates] = useState([]);

  useEffect(() => {
    if (
      selected_channel_names_arr.length > 0 &&
      kpi_states.length == selected_channel_names_arr.length
    ) {
      return;
    }
    setKpiStates((old_states) => initialKpiState(selected_channel_names_arr, old_states));
  }, [selected_channel_names_arr, step]);

  const handleKpiStatesChanges = (key, new_value, index) => {
    setKpiStates((states) => {
      const copied_states = [...states];
      copied_states[index][key] = new_value;
      return [...copied_states];
    });
  };

  return { kpi_states, setKpiStates, handleKpiStatesChanges };
};
export default useMultipleKpiStates;

const initialKpiState = (selected_channel_names_arr, old_states) => {
  return selected_channel_names_arr?.map((channel_name,i) => {
    if (channel_name == "digital - video") {
      return {
        ...DIGITAL_VIDEO_MEDIA_PLAN_STATES,...old_states[i]
      };
    }
    if (
      channel_name == "digital - display" ||
      channel_name == "digital - rich media" ||
      channel_name == "digital - skins"
    ) {
      return {
        ...DIGITAL_ALL_OTHER_MEDIA_PLAN_STATES,...old_states[i]
      };
    }
    if (channel_name == "print") {
      return {
        ...PRINT_MEDIA_PLAN_STATES,...old_states[i]
      };
    }
    if (channel_name == "television") {
      return {
        ...TELEVISION_MEDIA_PLAN_STATES,...old_states[i]
      };
    }
    if (channel_name == "radio") {
      return {
        ...RADIO_MEDIA_PLAN_STATES,...old_states[i]
      };
    }
    if (channel_name == "ooh // dooh") {
      return {
        ...OOH_MEDIA_PLAN_STATES,...old_states[i]
      };
    }

    return {};
  });
};

const DIGITAL_VIDEO_MEDIA_PLAN_STATES = {
  section_heading: "Digital [Video]",
  format: "",
  content: "",
  KPI: "",
  KPI_Benchmark: "",
  TargetVTR: "",
  TargetCTR: "",
  buyType: "",
  unitCost: "",
  frequency: "",
  estimatedImpression: "",
  completeView: "",
  estimatedClicks: "",
};
const DIGITAL_ALL_OTHER_MEDIA_PLAN_STATES = {
  section_heading: "",
  format: "",
  content: "",
  KPI: "",
  KPI_Benchmark: "",
  buyType: "",
  unitCost: "",
  frequency: "",
  estimatedImpression: "",
  estimatedClicks: "",
};
const PRINT_MEDIA_PLAN_STATES = {
  section_heading: "Print",
  circulation: "",
  readership: "",
  formatSize: "",
  height: "",
  width: "",
  volumeTotalcms: "",
  colour: "",
  position: "",
  noofInsertions: "",
  insertionDate: new Date(),
  netMediaCost: "",
  unitCost: "",
};
const RADIO_MEDIA_PLAN_STATES = {
  section_heading: "Radio",
  adType: "",
  channel: "",
  adUnit: "",
  unitCost: "",
  method: "",
  listenerss: "",
  NetGross: "",
  Quantity: "",
};
const TELEVISION_MEDIA_PLAN_STATES = {
  section_heading: "Television",
  adType: "",
  channel: "",
  adUnit: "",
  unitCost: "",
  method: "",
  viewership: "",
  netGross: "",
  quantity: "",
};
const OOH_MEDIA_PLAN_STATES = {
  section_heading: "OOH",
  media: "",
  product: "",
  channel: "",
  noOfPanels: "",
  unitCost: "",
  method: "",
  impactReachEstimated: "", //taken from mediaowner
  NetGross: "",
  Quantity: "",
};
