import React, { useState, useEffect } from 'react'
import { makeStyles, fade } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { DataGrid } from '@material-ui/data-grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useHistory } from 'react-router-dom';

import MainLayout from '../../layouts/newMainLayout'
import StepWrapper from './stepWrapper'
import { getMediaPlannerInventry } from '../../redux/actions/media-planner'

const useStyles = makeStyles((theme) => ({
    boxShadow: {
        position: 'relative',
        padding: '16px',
        boxShadow: '0px 0px 20px rgba(0,0,0,0.30), 0 20px 20px rgba(0,0,0,0.22)',
        borderRadius: '30px',
        backgroundColor: 'white',
        marginTop: '20px'
    },
    pageTitle: {
        color: '#e96941',
        fontWeight: 'normal'
    },
    formTitle: {
        color: "#1d8bf7",
        fontWeight: 'normal'
    },
    paper: {
        padding: '16px',
        color: theme.palette.text.secondary,
    },
    addActionBtn: {
        padding: '10px 20px 10px 10px',
        backgroundColor: "#136cc3",
        color: 'white',
        fontWeight: 'bold',
        borderRadius: '10px',
        textTransform: "none",
        marginBottom: '20px',
        '&:hover': {
            background: "#136cc3",
        }
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
    },
    modalPaper: {
        borderRadius: '30px',
        width: '960px',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        borderColor: 'transparent',
        boxShadow: theme.shadows[5],
        padding: '20px',
        textAlign: 'center',
        '&:focus': {
            outline: 'none',
        }
    },
    gridRoot: {
        '& .font-tabular-nums': {
          fontVariantNumeric: 'tabular-nums',
        },
      },
      
    customfield: {
        paddingTop: '0',
        marginTop: '0',
        backgroundColor: '#f4f4f8',
        borderRadius: '5px',
        '& .MuiInputLabel-root.Mui-shrink': {
            color: 'red'
            // "& .Mui-shrink": {
            // 	color: 'red'
            // },
        },
        '&:hover': {
            backgroundColor: '#fff',
        },
        '&$focused': {
            backgroundColor: '#fff',
            boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            borderColor: theme.palette.primary.main,
        },
        '& .MuiOutlinedInput-input': {
            padding: '8.5px 14px',
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '5px',

            '& fieldset': {
                borderColor: 'grey',
            },
            '&:hover fieldset': {
            },
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: 'grey'
        },
        "& .MuiInputLabel-root.Mui-focused": {
            color: 'grey'
        },
        "& .MuiInputLabel-root.MuiInputLabel-shrink": {
            margin: '0!important'
        },
        "& .MuiInputLabel-root.MuiInputLabel-animated": {
            marginTop: '-8px'
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: 'grey'
        }
    },
}))


const Inventory=()=> {

    const dispatch = useDispatch()
    const { register, handleSubmit, errors, control } = useForm({ mode: 'onSubmit', reValidateMode: 'onSubmit' })
    const savedMediaPlannerId = useSelector((state) => state.savedMediaPlannerId);
    //const savedMediaPlannerId = "mep_JmM6QLTXg7AeRuMcG";
    const mediaPlannerInventory = useSelector((state) => state.mediaPlannerInventory);
    const classes = useStyles()
    const [open, setOpen] = useState(false);
    const [inventory, setInventory] = useState(null);
    const [inventoryData, setInventoryData] = useState([]);
    const [gridData, setGridData] = useState([]);
    const history = useHistory()

    const currencyFormatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
      });
      
      const usdPrice = {
        type: 'number',
        width: 100,
        valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
        cellClassName: 'font-tabular-nums',
      };

    const menuCell = {

        renderCell: () => (
            <strong>
                <FormControl style={{width: 180}}>
                    <Controller
                        as={
                            <Select>
                                <MenuItem value={''}></MenuItem>
                                <MenuItem value={'JPEG'}>JPEG</MenuItem>
                                <MenuItem value={'PNG'}>PNG</MenuItem>
                                <MenuItem value={'SVG'}>SVG</MenuItem>
                                <MenuItem value={'PDF'}>PDF</MenuItem>
                                <MenuItem value={'EPS'}>EPS</MenuItem>
                                <MenuItem value={'AI'}>AI</MenuItem>
                                <MenuItem value={'TIFFS'}>TIFFS</MenuItem>
                                <MenuItem value={'PSD'}>PSD</MenuItem>
                            </Select>
                        }
                        aria-label="Religion"
                        name={`[value][fileType]`}
                        // rules={{ required: "this is required" }}
                        control={control}
                        //onChange={(event) => setFileType(event.target.value)}
                        defaultValue={"JPEG"}
                    />
                </FormControl>

            </strong>
        ),
    };


      const rowsData = [
        {
          id: 1,
          media: "mediaYasir",
          inventory: "spokenAd",
          channel: "radio",
          startDate: "01/01/2021",
          endDate: "02/02/2021",
          unitCost: "23",
          spend: "25000"

        },
        {
          id: 2,
          media: "mediaYasir",
          inventory: "spokenAd",
          channel: "radio",
          startDate: "01/01/2021",
          endDate: "02/02/2021",
          unitCost: "23",
          spend: "25000"
        },
        {
          id: 3,
          media: "mediaYasir",
          inventory: "spokenAd",
          channel: "radio",
          startDate: "01/01/2021",
          endDate: "02/02/2021",
          unitCost: "23",
          spend: "25000"
        },
      ];

    const columns = [
        { field: 'media', headerName: 'Media', width: 150 },
      //  { field: 'inventory', headerName: 'Inventory', ...menuCell,width: 200 },
        { field: 'inventory', headerName: 'Inventory', width: 150 },
        { field: 'channel', headerName: 'Channel', width: 100 },
        { field: 'startDate', headerName: 'Start', type: 'date',width: 130, },
        { field: 'endDate', headerName: 'End', type: 'date',width: 130, },
        { field: 'unitCost',headerName: 'Unit Cost', ...usdPrice },
        { field: 'spend', headerName: 'Spend', ...usdPrice },
    ];

    console.log("savedMediaPlannerId",savedMediaPlannerId)
    useEffect(() => {
        if(!mediaPlannerInventory){
            dispatch(getMediaPlannerInventry(savedMediaPlannerId));

        }else{
            setGridData(mediaPlannerInventory);
        }    

    }, [mediaPlannerInventory])

  

    return (
        <div className={classes.boxShadow}>
            <div className={classes.paper}>
                <div style={{ height: 500, width: '100%' }} className={classes.gridRoot}>
                    <DataGrid rows={gridData} columns={columns} pageSize={9} />
                </div>
            </div>
       </div>
    )
}

export default MainLayout(Inventory);
