/* eslint-disable no-unused-expressions */
import { fade, makeStyles, withTheme } from "@material-ui/core/styles";
import React, { useCallback, useEffect, useState } from "react";
import MainLayout from "../../layouts/adminMainLayout";

import debounce from "lodash.debounce";

import CircularProgress from "@material-ui/core/CircularProgress";
//Button

//Icon

//TextField
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useStyles from "../../components/StyleClasses";
import { API, SECRET_KEY } from "../../constants/index";

import NewAlert from "../../components/NewAlert";

import { Checkbox, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import "react-nice-dates/build/style.css";
function paramsToObject(entries) {
  const result = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}

const currencyList = [
  { type: "GBP", symbol: "£" },
  { type: "USD", symbol: "$" },
  { type: "INR", symbol: "₹" },
  { type: "EUR", symbol: "€" },
];

const linkRegex = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
const MediaOwnerProfile = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const urlparams = new URLSearchParams(history.location.search);
  const paramEntries = urlparams.entries();
  const params = paramsToObject(paramEntries);
  const [moID, setmoID] = useState(params?.id ? params.id : "");
  const [name, setname] = useState("");
  const [userName, setuserName] = useState("");
  const [email, setemail] = useState("");
  const [Status, setStatus] = useState(1);
  const [publisherID, setPublisherID] = useState("");
  const [AllPublisherId, setAllPublisherId] = useState([]);
  const [publisherObj, setPublisherObj] = useState({ name: "" });
  const [Verified, setVerified] = useState([]);
  const [publisherType, setPublisherType] = useState(0);

  ///new flow details//////
  const [address, setaddress] = useState("");
  const [vatNumber, setvatNumber] = useState("");
  const [mainContactName, setmainContactName] = useState("");
  const [vatPercent, setvatPercent] = useState(0);
  const [mainContactPhoneNumber, setmainContactPhoneNumber] = useState(0);
  const [bankDetails, setbankDetails] = useState();
  const [cpmDisplay, setcpmDisplay] = useState(0);
  const [cpmVideo, setcpmVideo] = useState(0);
  const [companyName, setcompanyName] = useState("");
  const [currency, setCurrency] = useState("GBP");
  const demographics = useSelector((state) => state.dropdownData?.demographics);
  const [demographic, setDemographic] = useState([]);
  const [domain_url, setDomainUrl] = useState("")

  ///////errors & successs //////////
  const [ErrSucMsg, setErrSucMesg] = useState("");
  const [ErrSucType, setErrSucType] = useState(true);
  ///////////////////////////////////
  const [loading, setloading] = useState(false);
  const [errors, setErrors] = useState({
    userName: { error: false, txt: "" },
    name: { error: false, txt: "" },
    email: { error: false, txt: "" },
    Status: { error: false, txt: "" },
    publisherID: { error: false, txt: "" },
    publisherType: { error: false, txt: "" },
    address: { error: false, txt: "" },
    vatNumber: { error: false, txt: "" },
    mainContactName: { error: false, txt: "" },
    vatPercent: { error: false, txt: "" },
    mainContactPhoneNumber: { error: false, txt: "" },
    bankDetails: { error: false, txt: "" },
    cpmDisplay: { error: false, txt: "" },
    cpmVideo: { error: false, txt: "" },
    currency: { error: false, txt: "" },
    demographic: { error: false, txt: "" },
    domain_url: {error: false, txt: ""}
  });

  const access_token = window.localStorage.getItem(SECRET_KEY);

  const saveUser = () => {
    const demo = [];
    demographic &&
      demographic.length &&
      demographic.map((de) => demo.push(de));
    const body = {
      email: email,
      username: userName,
      name: name,
      publisherId: AllPublisherId,
      publisherType: publisherType,
      companyName: "company",
      cpmDisplay: cpmDisplay,
      cpmVideo: cpmVideo,
      demographics: demo,
      currency: currencyList.find((e) => e.type === currency),
      url: domain_url
    };
    if (address !== "") body.address = address;
    if (vatNumber !== "") body.vatNumber = vatNumber;
    if (vatPercent !== 0) body.vatPercent = vatPercent;
    if (mainContactName !== "") body.mainContactName = mainContactName;
    if (mainContactPhoneNumber !== 0)
      body.mainContactPhoneNumber = mainContactPhoneNumber;
    body.bankDetails = { acc: "test" };
    axios
      .post(`${API}/admin/mediaOwner`, body, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setTimeout(() => {
            history.push("/admin/mediaOwners");
          }, 1500);
          setErrSucMesg(res.data.message);
          setErrSucType(true);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
        }
      });
  };
  const updateUser = () => {
    const demo1 = [];
    demographic &&
      demographic.length &&
      demographic.map((de) => demo1.push(de));
    const body = {
      email: email,
      username: userName,
      name: name,
      status: Status,
      publisherId: AllPublisherId,
      publisherType: publisherType,
      companyName: "company",
      cpmDisplay: cpmDisplay,
      cpmVideo: cpmVideo,
      demographics: demo1,
      currency: currencyList.find((e) => e.type === currency),
      url: domain_url
    };
    if (address !== "") body.address = address;
    if (vatNumber !== "") body.vatNumber = vatNumber;
    if (vatPercent !== 0) body.vatPercent = vatPercent;
    if (mainContactName !== "") body.mainContactName = mainContactName;
    if (mainContactPhoneNumber !== 0)
      body.mainContactPhoneNumber = mainContactPhoneNumber;
    body.bankDetails = { acc: "test" };
    axios
      .put(`${API}/admin/mediaOwner/${params?.id}`, body, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setTimeout(() => {
            history.push("/admin/mediaOwners");
          }, 1500);
          setErrSucMesg(res.data.message);
          setErrSucType(true);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
        }
      });
  };

  const getUserData = () => {
    if (params.id) {
      setloading(true);
      axios
        .get(`${API}/admin/mediaOwner/${moID}`, {
          headers: { Authorization: `Bearer ${access_token}` },
        })
        .then((res, err) => {
          let demoArr = [];
          if (res) {
            res.data.demographics &&
              res.data.demographics.length &&
              res.data.demographics.map((dm) => {
                console.log(dm)
                demoArr.push(dm._id);
              });
            setloading(false);
            setname(res.data.name);
            setuserName(res.data.username);
            setemail(res.data.email);
            setStatus(res.data.status);
            setPublisherID(res.data.publisherId);
            setPublisherType(res.data.publisherType);
            setaddress(res.data.address);
            setvatNumber(res.data.vatNumber);
            setvatPercent(res.data.vatPercent);
            setmainContactName(res.data.mainContactName);
            setmainContactPhoneNumber(res.data.mainContactPhoneNumber);
            setcpmDisplay(res.data.cpmDisplay);
            console.log(demoArr)
            setDemographic(demoArr);
            setcpmVideo(res.data.cpmVideo);
            setbankDetails(res.data.bankDetails);
            setCurrency(res.data.currency.type);
            setDomainUrl(res.data.url)
            let xx = [];
            res.data.publisherId.forEach((e) => {
              xx.push(e);
            });
            setVerified(xx);
            setAllPublisherId(xx);
            setPublisherObj({
              name: res.data.companyName,
            });
          }
          if (err) {
          }
        })
        .catch(function (error) {
          if (error) {
          }
        });
    }
  };
  useEffect(() => getUserData(), []);

  const VerifyID = (id, i) => {
    if (id !== "")
      axios
        .get(`${API}/admin/AdButler/publishers/${id}`, {
          headers: { Authorization: `Bearer ${access_token}` },
        })
        .then((res, err) => {
          if (res) {
            if (res.data.object === "publisher") {
              // setPublisherObj(res.data);
              let vrf = [...Verified];
              vrf[i] = res.data.name;
              setVerified(vrf);
            } else if (res.data.object === "error") {
              // setVerified([...Verified]);
            }
          }
          if (err) {
          }
        })
        .catch(function (error) {
          if (error) {
          }
        });
  };

  // useEffect(() => {
  //   if (publisherID) VerifyID(publisherID);
  // }, [publisherID]);

  useEffect(() => {
    AllPublisherId.forEach((e, i) => {
      if (Verified[i] === "") VerifyID(e, i);
    });
  }, [AllPublisherId]);

  useEffect(() => {}, [Verified]);

  const capitalizeFirstLetter = (string) => {
    let str = string.replace("_", " ");
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <div className={classes.boxShadow} style={{ marginTop: "40px" }}>
      <NewAlert
        msg={ErrSucMsg}
        isSuccess={ErrSucType}
        close={() => setErrSucMesg("")}
      />
      <div className={classes.customfield}>
        <span
          style={{
            color: "#FB6D6A",
            fontWeight: "600",
            margin: "0",
            fontSize: "20px",
            marginLeft: "20px",
          }}
        >
          {params.id ? "Media Owner Profile" : "Create Media Owner"}
        </span>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "20px",
            }}
          >
            <div style={{ width: "40%" }}>
              <TextField
                error={errors.name.error}
                helperText={errors.name.txt}
                fullWidth
                placeholder="Name"
                label="Name"
                variant="outlined"
                style={{ display: "block", marginBottom: "10px" }}
                value={name}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    name: { error: false, txt: "" },
                  });
                  setname(e.target.value);
                }}
              />
              <TextField
                error={errors.userName.error}
                helperText={errors.userName.txt}
                fullWidth
                placeholder="User Name"
                label="User Name"
                variant="outlined"
                style={{ display: "block", marginBottom: "10px" }}
                value={userName}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    userName: { error: false, txt: "" },
                  });
                  setuserName(e.target.value);
                }}
              />
              <TextField
                error={errors.email.error}
                helperText={errors.email.txt}
                fullWidth
                placeholder="Email"
                label="Email"
                variant="outlined"
                style={{ display: "block", marginBottom: "10px" }}
                value={email}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    email: { error: false, txt: "" },
                  });
                  setemail(e.target.value);
                }}
              />
              <TextField
                style={{ display: "block", marginBottom: "10px" }}
                select
                fullWidth
                label="Currency"
                value={currency}
                onChange={(e) => {
                  setCurrency(e.target.value);
                }}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              >
                {currencyList.map((option) => (
                  <option key={option.type} value={option.type}>
                    {option.type + " - " + option.symbol}
                  </option>
                ))}
              </TextField>
              <TextField
                error={errors.address.error}
                helperText={errors.address.txt}
                fullWidth
                placeholder="Address"
                label="Address"
                variant="outlined"
                style={{ display: "block", marginBottom: "10px" }}
                value={address}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    address: { error: false, txt: "" },
                  });
                  setaddress(e.target.value);
                }}
              />
              <TextField
                error={errors.vatPercent.error}
                helperText={errors.vatPercent.txt}
                fullWidth
                placeholder="Vat Percent"
                label="Vat Percent"
                variant="outlined"
                style={{ display: "block", marginBottom: "10px" }}
                value={vatPercent}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    vatPercent: { error: false, txt: "" },
                  });
                  setvatPercent(e.target.value);
                }}
              />
              <TextField
                error={errors.vatNumber.error}
                helperText={errors.vatNumber.txt}
                fullWidth
                placeholder="Vat Number"
                label="Vat Number"
                variant="outlined"
                style={{ display: "block", marginBottom: "10px" }}
                value={vatNumber}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    vatNumber: { error: false, txt: "" },
                  });
                  setvatNumber(e.target.value);
                }}
              />

              <TextField
                error={errors.mainContactName.error}
                helperText={errors.mainContactName.txt}
                fullWidth
                placeholder="Main Contact Name"
                label="Main Contact Name"
                variant="outlined"
                style={{ display: "block", marginBottom: "10px" }}
                value={mainContactName}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    mainContactName: { error: false, txt: "" },
                  });
                  setmainContactName(e.target.value);
                }}
              />
              <TextField
                error={errors.mainContactPhoneNumber.error}
                helperText={errors.mainContactPhoneNumber.txt}
                fullWidth
                placeholder="Main Contact Phone Number"
                label="Main Contact Phone Number"
                variant="outlined"
                style={{ display: "block", marginBottom: "10px" }}
                value={mainContactPhoneNumber}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    mainContactPhoneNumber: { error: false, txt: "" },
                  });
                  setmainContactPhoneNumber(e.target.value);
                }}
              />
            </div>
            <div style={{ width: "40%" }}>
              <TextField
                error={errors.cpmDisplay.error}
                helperText={errors.cpmDisplay.txt}
                fullWidth
                placeholder="CPM [Display]"
                label="CPM [Display]"
                variant="outlined"
                style={{ display: "block", marginBottom: "10px" }}
                value={cpmDisplay}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    cpmDisplay: { error: false, txt: "" },
                  });
                  setcpmDisplay(e.target.value);
                }}
              />
              <TextField
                error={errors.cpmVideo.error}
                helperText={errors.cpmVideo.txt}
                fullWidth
                placeholder="CPM [Video]"
                label="CPM [Video]"
                variant="outlined"
                style={{ display: "block", marginBottom: "10px" }}
                value={cpmVideo}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    cpmVideo: { error: false, txt: "" },
                  });
                  setcpmVideo(e.target.value);
                }}
              />
              <TextField
                error={errors.publisherType.error}
                helperText={errors.publisherType.txt}
                style={{ display: "block", marginBottom: "10px" }}
                select
                fullWidth
                label="Impression Calculation"
                value={publisherType}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    publisherType: { error: false, txt: "" },
                  });
                  setPublisherType(e.target.value);
                }}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              >
                <option key="Select" value={0} disabled>
                  Select...
                </option>
                <option key="Eligible" value={"eligible"}>
                  Eligible
                </option>
                <option key="Viewable" value={"viewble"}>
                  Viewable
                </option>
              </TextField>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
              >
                {demographic && demographic.length === 0 && (
                  <InputLabel>Select demographic...</InputLabel>
                )}
                <Select
                  id="outlined-select-currency-native"
                  multiple
                  // label="Reports"
                  value={demographic}
                  onChange={(e) => {
                    setDemographic(e.target.value);
                  }}
                  variant="outlined"
                  renderValue={() => {
                    let arrNm = [];
                    demographics && demographics.length &&
                      demographics?.map((dem) => {
                        if (demographic.includes(dem._id)) {
                          let nm = capitalizeFirstLetter(dem.name);
                          arrNm.push(nm + " , ");
                        }
                      });
                    return arrNm;
                  }}
                >
                  <MenuItem disabled value={0} key={0}>
                    <em>Select demographic</em>
                  </MenuItem>
                  {demographics && demographics.length &&
                    demographics?.map((option) => (
                      <MenuItem
                        primaryText={"Select demographic"}
                        key={option._id}
                        value={option._id}
                      >
                        {demographic && demographic.length === 0 && (
                          <Checkbox
                            checked={demographic.includes(option._id)}
                          ></Checkbox>
                        )}
                        {option.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <TextField
                error={errors.domain_url.error}
                helperText={errors.domain_url.txt}
                fullWidth
                placeholder="https://"
                label="URL"
                variant="outlined"
                style={{ display: "block", marginBottom: "10px" }}
                value={domain_url}
                onChange={(e) => {
                  if(!linkRegex.test(e.target.value)){
                    setErrors({
                      ...errors,
                      domain_url: { error: true, txt: "Please enter valid url" },
                    });  
                  }else{
                    setErrors({
                      ...errors,
                      domain_url: { error: false, txt: "" },
                    });
                  }
                  setDomainUrl(e.target.value);
                }}
              />

              {/* <TextField
                // id="outlined-select-currency-native"
                error={errors.demographic.error}
                helperText={errors.demographic.txt}
                select
                fullWidth
                label="Demographic"
                value={demographic}
                style={{ display: "block", marginBottom: "10px" }}
                onChange={(e) => {
                  setDemographic(e.target.value);
                  setErrors({
                    ...errors,
                    demographic: { error: false, txt: "" },
                  });
                }}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              >
                <option key={0} value={0}>
                  Select...
                </option>
                {demographics?.map((option) => (
                  <option key={option._id} value={option._id}>
                    {option.name}
                  </option>
                ))}
              </TextField> */}
              {params.id && (
                <TextField
                  error={errors.Status.error}
                  helperText={errors.Status.txt}
                  style={{ display: "block", marginBottom: "10px" }}
                  select
                  fullWidth
                  label="Status"
                  value={Status}
                  onChange={(e) => {
                    setErrors({
                      ...errors,
                      Status: { error: false, txt: "" },
                    });
                    setStatus(e.target.value);
                  }}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key="active" value={1} style={{ color: "green" }}>
                    Active
                  </option>
                  <option key="active" value={0} style={{ color: "red" }}>
                    InActive
                  </option>
                </TextField>
              )}
              <span>No of Publisher Ids</span>
              <input
                style={{ marginLeft: "20px" }}
                type="number"
                value={AllPublisherId.length}
                onChange={(e) => {
                  if (AllPublisherId.length == 0) {
                    let xx = [];
                    for (let i = 0; i < e.target.value; i++) {
                      xx.push("");
                    }
                    setVerified(xx);
                    setAllPublisherId(xx);
                  } else if (e.target.value < AllPublisherId.length) {
                    let xx = AllPublisherId.slice(0, e.target.value);
                    setVerified(xx);
                    setAllPublisherId(xx);
                  } else {
                    let xx = [...AllPublisherId];
                    for (
                      let i = AllPublisherId.length;
                      i < e.target.value;
                      i++
                    ) {
                      xx.push("");
                    }
                    setVerified(xx);
                    setAllPublisherId(xx);
                  }
                }}
              ></input>
              <br />
              {demographic !== 0 && (
                <span style={{ color: "red" }}>
                  {AllPublisherId.length == 0
                    ? "please enter no of Publisher Ids"
                    : ""}
                </span>
              )}
              {AllPublisherId?.map((e, i) => (
                <div>
                  <TextField
                    error={Verified[i] === ""}
                    helperText={
                      Verified[i] !== "" ? "" : "please enter a valid id"
                    }
                    disabled={Verified[i] !== ""}
                    fullWidth
                    placeholder="Publisher ID"
                    label="Publisher ID"
                    variant="outlined"
                    style={{
                      display: "block",
                      marginBottom: "10px",
                      marginTop: "15px",
                    }}
                    value={AllPublisherId[i]}
                    onChange={(e) => {
                      let APID = [...AllPublisherId];
                      APID[i] = e.target.value;
                      setAllPublisherId(APID);
                    }}
                  />
                  {Verified[i] !== "" && (
                    <span
                      style={{
                        color: "green",
                      }}
                    >
                      {"Verified : "}
                      {Verified[i]}
                    </span>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
          margin: "0 5%",
        }}
      >
        <button
          className={classes.addActionBtn}
          onClick={() => history.push("/admin/mediaOwners")}
          style={{ backgroundColor: "grey" }}
        >
          Cancel
        </button>
        <button
          className={classes.addActionBtn}
          onClick={() => {
            if (name === "") {
              setErrors({
                ...errors,
                name: { error: true, txt: "please enter name" },
              });
            } else if (userName === "") {
              setErrors({
                ...errors,
                userName: {
                  error: true,
                  txt: "please enter user name",
                },
              });
            } else if (email === "") {
              setErrors({
                ...errors,
                email: { error: true, txt: "please enter email" },
              });
            } else if (address === "") {
              setErrors({
                ...errors,
                address: { error: true, txt: "please enter address" },
              });
            } else if (vatPercent === 0) {
              setErrors({
                ...errors,
                vatPercent: {
                  error: true,
                  txt: "please enter vatPercent",
                },
              });
            } else if (vatNumber === "") {
              setErrors({
                ...errors,
                vatNumber: {
                  error: true,
                  txt: "please enter vatNumber",
                },
              });
            } else if (mainContactName === "") {
              setErrors({
                ...errors,
                mainContactName: {
                  error: true,
                  txt: "please enter mainContactName",
                },
              });
            } else if (mainContactPhoneNumber === 0) {
              setErrors({
                ...errors,
                mainContactPhoneNumber: {
                  error: true,
                  txt: "please enter mainContactPhoneNumber",
                },
              });
              // } else if (bankDetails === {}) {
              //   setErrors({
              //     ...errors,
              //     bankDetails: {
              //       error: true,
              //       txt: "please enter bankDetails",
              //     },
              //   });
            } else if (cpmDisplay === 0) {
              setErrors({
                ...errors,
                cpmDisplay: {
                  error: true,
                  txt: "please enter cpmDisplay",
                },
              });
            } else if (cpmVideo === 0) {
              setErrors({
                ...errors,
                cpmVideo: {
                  error: true,
                  txt: "please enter cpmVideo",
                },
              });
              // } else if (Status === null) {
              //   setErrors({
              //     ...errors,
              //     Status: { error: true, txt: "please select Status" },
              //   });
            } else if (publisherType === 0 || publisherType === undefined) {
              setErrors({
                ...errors,
                publisherType: {
                  error: true,
                  txt: "please select a Publisher Type",
                },
              });
            } else if (demographic === 0) {
              setErrors({
                ...errors,
                demographic: {
                  error: true,
                  txt: "Please Select demographic",
                },
              });
            }else if (!domain_url){
              setErrors({
                ...errors,
                domain_url: {
                  error: true,
                  txt: "Please enter url",
                },
              });
              return 
            }
            //   else if (publisherID === "" || !Verified.includes("")) {
            //     setErrors({
            //       ...errors,
            //       publisherID: {
            //         error: true,
            //         txt: "Please Enter a Valid ID",
            //       },
            //     });
            // }
            if (
              name !== "" &&
              userName !== "" &&
              email !== "" &&
              Status !== null &&
              address !== "" &&
              vatNumber !== "" &&
              vatPercent !== 0 &&
              mainContactName !== "" &&
              mainContactPhoneNumber !== 0 &&
              // bankDetails !== {} &&
              publisherType !== 0 &&
              publisherType !== undefined &&
              cpmDisplay !== 0 &&
              cpmVideo !== 0 &&
              demographic !== 0 &&
              !Verified.includes("")
            ) {
              params.id ? updateUser() : saveUser();
            }
          }}
        >
          {params.id ? "Update" : "Create User"}
        </button>
      </div>
    </div>
  );
};
export default MainLayout(MediaOwnerProfile);
