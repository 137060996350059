import { AnimationFrameScheduler } from "rxjs/internal/scheduler/AnimationFrameScheduler";
import {
  ADMIN_RESPONSE_SUCCESS,
  SAVE_USERS,
  SAVE_CSV_DROPDWON,
  SET_REPORT_DATA
} from "../redux/actions/admin";
import { SAVE_MEDIA_OWNER_WITH_ALL_INVENTORY } from "../redux/actions/inventory";
import { MEDIA_PLANNER_SUBSCRIPTION } from "../redux/actions/user";
import { SAVE_BRAND_UPLIFT_FORECAST } from "../redux/actions/brandUplift";
import { CSV_URL } from "../redux/actions/media-owner";
import {
  SAVE_MEDIA_PLANNER,
  SAVE_MEDIA_PLANNER_FORECAST,
  SAVE_MEDIA_PLANNER_ID,
  SAVE_MEDIA_PLANNER_INVENTRY,
} from "../redux/actions/media-planner";

const initState = {
  loggedInSession: null,
  error: null,
  sucesss: null,
  alertError: null,
  alertSucess: null,
  createMedia: null,
  mediaOwner: {},
  mediaOwner1: {},
  allMediaOwners: null,
  mediaOwnerTitles: [],
  personaSaved: false,
  personas: null,
  personaSuccess: false,
  inventorySaved: null,
  uploadAvatarSuccess: null,
  avatarUrl: null,
  insight: {},
  insightSuccess: null,
  inventory: [],
  excelData: [],
  mediaOwnerSuccessfull: false,
  mediaOwnerTitlesSuccessfull: false,
  createInventoryMediaOwnerSelected: null,
  mediaOwnerWithAllInventry: null,
  apiMediaOwnerSaved: null,
  addNewMediaOwnerInvenrtySuccessfull: false,
  currentUserId: null,
  mediaPlanner: null,
  savedMediaPlannerId: null,
  forecast: null,
  mediaPlannerInventory: null,
  adminResponse: null,
  adminAllUsers: null,
  brandUpLiftForecast: null,
  dropdownData: null,
  adminDropDownData: null,
  personaDropDownData: null,
  adbutlerReportsData: null,
  adbutlerVastReportsData: null,
  MoDashboardCPM: null,
  MoDashboardPayout: null,
  MoDashboardImpressions: null,
  MoPublisherName: null,
  MoDashboardClicks: null,
  MoDashboardGraphData: null,
  invoiceRefrence: null,
  MOProfileOBJ: null,
  invoiceTableData: null,
  MoDashboardConfiguration: null,
  passBackList: null,
  subscription: 'free',
  reportData: null
};

const Reducer = (state = initState, action) => {
  console.log(state, "reducer!!!");
  console.log(action, "action!!!!");

  switch (action.type) {
    case "Pass_Back_List":
      return {
        ...state,
        passBackList: action.payload,
      };
    case "MO_Dashboard_Graph_Data":
      return {
        ...state,
        MoDashboardGraphData: action.payload,
      };
    case "MO_Dashboard_Confuguration":
      return {
        ...state,
        MoDashboardConfiguration: action.payload,
      };
    case "Invoice_Reference":
      return {
        ...state,
        invoiceRefrence: action.payload,
      };
    case "Mo_Publisher_Name":
      return {
        ...state,
        MoPublisherName: action.payload,
      };
    case "Invoice_Table_Data":
      return {
        ...state,
        invoiceTableData: action.payload,
      };
    case "MO_Profile_OBJ":
      return {
        ...state,
        MOProfileOBJ: action.payload,
      };
    case "Mo_Dashboard_Clicks":
      return {
        ...state,
        MoDashboardClicks: action.payload,
      };
    case "Mo_Dashboard_Impressions":
      return {
        ...state,
        MoDashboardImpressions: action.payload,
      };
    case "UPDATE_CPM":
      return {
        ...state,
        MoDashboardCPM: action.payload,
      };
    case "UPDATE_PAYOUT":
      return {
        ...state,
        MoDashboardPayout: action.payload,
      };
    case "Adbutler_Reports_Data":
      return {
        ...state,
        adbutlerReportsData: action.payload,
      };

    case "Adbutler_Vast_Reports_Data":
      return {
        ...state,
        adbutlerVastReportsData: action.payload,
      };
    case "SAVA_PERSONA_DROPDOWN_DATA":
      return {
        ...state,
        personaDropDownData: action.payload,
      };
    case "SAVA_ADMIN_DROPDOWN_DATA":
      return {
        ...state,
        adminDropDownData: action.payload,
      };
    case "AVATAR_URL":
      return {
        ...state,
        avatarUrl: action.avatarUrl,
      };
    case CSV_URL:
      return {
        ...state,
        csvUrl: action.csvUrl,
      };
    case "CREATE_INVENTORY_MEDIA_OWNER_SELECTED":
      return {
        ...state,
        createInventoryMediaOwnerSelected:
          action.createInventoryMediaOwnerSelected,
      };
    case "UPLOAD_AVATAR_SUCCESS":
      return {
        ...state,
        uploadAvatarSuccess: action.uploadAvatarSuccess,
      };
    case "LOGGED_IN_SESSION":
      return {
        ...state,
        loggedInSession: action.loggedInSession,
      };
    case "ERROR":
      return {
        ...state,
        error: action.error,
      };
    case "ALERT_ERROR":
      return {
        ...state,
        alertError: action.error,
      };
    case "ALERT_SUCESS":
      return {
        ...state,
        alertSucess: action.error,
      };
    case "SEND_MEDIA_CREATE":
      return {
        ...state,
        createMedia: action.createMedia,
      };
    case "CREATE_INVENTORY":
      return {
        ...state,
        inventory: action.inventory,
      };
    case "CREATE_MEDIA_OWNER":
      return {
        ...state,
        mediaOwner: action.mediaOwner,
      };
    case "CREATE_MEDIA_PLANNER":
      return {
        ...state,
        mediaPlanner: action.mediaPlanner,
      };

    case "SAVE_MEDIA_OWNER_AND_INVENTORY":
      return {
        ...state,
        mediaOwner1: action.mediaOwner1,
      };
    case "CREATE_MEDIA_OWNER_AND_INVENTORY_SUCCESSFULL":
      return {
        ...state,
        mediaOwnerSuccessfull: action.mediaOwnerSuccessfull,
      };
    case "GET_MEDIA_OWNER_TITLE":
      console.info("ttttt: ", action);

      const a = action.mediaOwnerTitles.data.map((val) => {
        val.selected = false;
        return val;
      });

      return {
        ...state,
        mediaOwnerTitles: a, //action.mediaOwnerTitles.data,
        mediaOwnerTitlesSuccessfull: true,
      };
    case "ADD_MEDIA_OWNER_ID":
      return {
        ...state,
        mediaOwner: { ...state.mediaOwner, mediaOwnerId: action.mediaOwnerId },
      };
    case "INVENTORY_SAVED":
      return {
        ...state,
        inventorySaved: action.inventorySaved,
      };
    case "SET_PERSONAS":
      return {
        ...state,
        personas: action.personas,
      };

    case "PERSONAS_RETRIEVED":
      console.info("qqqq: ", action.personas);
      return {
        ...state,
        personas: action.personas,
        personaSuccess: true,
      };

    case "PERSONA_SUCCESS":
      return {
        ...state,
        personaSuccess: action.personaSuccess,
      };

    case "PERSONA_SAVED":
      return {
        ...state,
        personaSaved: action.personaSaved,
      };
    case "SET_INSIGHT":
      return {
        ...state,
        insight: action.insight,
      };

    case "INSIGHT_SUCCESS":
      return {
        ...state,
        insightSuccess: action.insightSuccess,
      };

    //-------------Yas-------
    case "SAVE_ALL_MEDIA_OWNERS":
      return {
        ...state,
        allMediaOwners: action.allMediaOwners,
      };

    case SAVE_MEDIA_OWNER_WITH_ALL_INVENTORY:
      return {
        ...state,
        mediaOwnerWithAllInventry: action.mediaOwnerWithAllInventry,
      };

    case "API_INVENTORY_SAVED":
      return {
        ...state,
        apiMediaOwnerSaved: action.apiMediaOwnerSaved,
      };

    case SAVE_MEDIA_PLANNER:
      return {
        ...state,
        mediaPlanner: action.mediaPlanner,
      };

    case SAVE_MEDIA_PLANNER_FORECAST:
      return {
        ...state,
        forecast: action.forecast,
      };

    case SAVE_MEDIA_PLANNER_ID:
      return {
        ...state,
        savedMediaPlannerId: action.savedMediaPlannerId,
      };

    case SAVE_MEDIA_PLANNER_INVENTRY:
      return {
        ...state,
        mediaPlannerInventory: action.mediaPlannerInventory,
      };

    case SAVE_USERS:
      return {
        ...state,
        adminAllUsers: action.adminAllUsers,
      };

    case ADMIN_RESPONSE_SUCCESS:
      return {
        ...state,
        adminResponse: action.adminResponse,
      };

    case SAVE_BRAND_UPLIFT_FORECAST:
      return {
        ...state,
        brandUpLiftForecast: action.brandUpLiftForecast,
      };

    case SAVE_CSV_DROPDWON:
      return {
        ...state,
        dropdownData: action.dropdownData,
      };
    
    case MEDIA_PLANNER_SUBSCRIPTION:
      return {
        ...state,
        subscription: action.subscription,
      };
      
    case SET_REPORT_DATA:
      console.log('action.reportData', action.reportData);
      return {
        ...state,
        reportData: action.reportData,
      };

    // you can have as many case statements as you need
    default:
      return state;
  }
};

export default Reducer;
