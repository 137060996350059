import React from "react"
import { Checkbox, TextField } from "@material-ui/core"
import { SERVE_TYPES, useChannelAssignmentPageCtx } from "../../ChannelAssignmentPage"

const calculationTypes = {
    money: "money",
    percent: "percent",
}

const CheckBoxLabel = ({ htmlFor, children }) => {
    return (
        <label htmlFor={htmlFor} style={{ width: "65px" }}>
            {children}
        </label>
    )
}

export default function FinancialsRates({
    states,
    setStates,
    inputStyles,
    calculationType = "money",
    disableCpm = false,
    disableCpa = false,
    disableCpc = false,
    disableFixed = false,
    showFixedSection = true,
    handleAdvertiserRatesChanges,
    keyToUpdate,
    handleAdvertiserRateRadioChange,
    step2StatesRef,
}) {
    const { selectedServeType } = useChannelAssignmentPageCtx()
    const MoneySymbol = <span>{selectedServeType == SERVE_TYPES["Auction"] ? "USD" : "£"}</span>
    const check_box_labels = {
        cpa: `${keyToUpdate}__check_cpa`,
        cpc: `${keyToUpdate}__check_cpc`,
        cpm: `${keyToUpdate}__check_cpm`,
        fixed: `${keyToUpdate}__check_fixed`,
    }

    const updateAdvertiserRates = (object) => {
        let enable = false
        Object.keys(object).forEach((key) => {
            if (object[key].checked) {
                enable = true
                return
            }
        })
        console.log({ enable })
        step2StatesRef.current.financials[keyToUpdate].enableMode = enable
    }

    return (
        <div
            className="flex flex-col"
            style={{ marginLeft: "20px", marginTop: "20px", gap: "10px", paddingInline: "10px 30px" }}
        >
            <div className="flex flex-row align-center" style={{ gap: "10px" }}>
                <Checkbox
                    color="primary"
                    style={{ padding: "0px" }}
                    id={check_box_labels.cpm}
                    name={check_box_labels.cpm}
                    onChange={(e) => {
                        const copied_states = { ...states }
                        copied_states.financials[keyToUpdate].cpm.checked = e.target.checked
                        copied_states.financials[keyToUpdate].cpm.disabled = !e.target.checked
                        setStates(copied_states)
                        updateAdvertiserRates(copied_states.financials[keyToUpdate])
                    }}
                    checked={states.financials[keyToUpdate].cpm.checked}
                    disabled={disableCpm}
                    // disabled={states.financials[keyToUpdate].fixed.checked || disableCpm}
                />
                <CheckBoxLabel htmlFor={check_box_labels.cpm}>CPM</CheckBoxLabel>
                {calculationType == calculationTypes.money && MoneySymbol}
                <TextField
                    name="cpm"
                    value={states.financials[keyToUpdate].cpm.value}
                    onChange={handleAdvertiserRatesChanges}
                    disabled={states.financials[keyToUpdate].cpm.disabled || disableCpm}
                    variant="outlined"
                    className={inputStyles.input}
                    placeholder="0.000"
                />
                {calculationType == calculationTypes.percent && <span>%</span>}
            </div>

            <div className="flex flex-row align-center" style={{ gap: "10px" }}>
                <Checkbox
                    color="primary"
                    style={{ padding: "0px" }}
                    id={check_box_labels.cpc}
                    name={check_box_labels.cpc}
                    onChange={(e) => {
                        const copied_states = { ...states }
                        copied_states.financials[keyToUpdate].cpc.checked = e.target.checked
                        copied_states.financials[keyToUpdate].cpc.disabled = !e.target.checked
                        setStates(copied_states)
                        updateAdvertiserRates(copied_states.financials[keyToUpdate])
                    }}
                    checked={states.financials[keyToUpdate].cpc.checked}
                    disabled={disableCpc}
                    // disabled={states.financials[keyToUpdate].fixed.checked || disableCpc}
                />
                <CheckBoxLabel htmlFor={check_box_labels.cpc}>CPC</CheckBoxLabel>
                {calculationType == calculationTypes.money && MoneySymbol}
                <TextField
                    name="cpc"
                    value={states.financials[keyToUpdate].cpc.value}
                    disabled={states.financials[keyToUpdate].cpc.disabled || disableCpc}
                    variant="outlined"
                    className={inputStyles.input}
                    placeholder="0.000"
                    onChange={handleAdvertiserRatesChanges}
                />
                {calculationType == calculationTypes.percent && <span>%</span>}
            </div>

            <div className="flex flex-row align-center" style={{ gap: "10px" }}>
                <Checkbox
                    color="primary"
                    style={{ padding: "0px" }}
                    id={check_box_labels.cpa}
                    name={check_box_labels.cpa}
                    onChange={(e) => {
                        const copied_states = { ...states }
                        copied_states.financials[keyToUpdate].cpa.checked = e.target.checked
                        copied_states.financials[keyToUpdate].cpa.disabled = !e.target.checked
                        setStates(copied_states)
                        updateAdvertiserRates(copied_states.financials[keyToUpdate])
                    }}
                    checked={states.financials[keyToUpdate].cpa.checked}
                    disabled={disableCpa}
                    // disabled={states.financials[keyToUpdate].fixed.checked || disableCpa}
                />
                <CheckBoxLabel htmlFor={check_box_labels.cpa}>CPA</CheckBoxLabel>
                {calculationType == calculationTypes.money && MoneySymbol}
                <TextField
                    name="cpa"
                    value={states.financials[keyToUpdate].cpa.value}
                    onChange={handleAdvertiserRatesChanges}
                    variant="outlined"
                    className={inputStyles.input}
                    placeholder="0.000"
                    disabled={states.financials[keyToUpdate].cpa.disabled || disableCpa}
                />
                {calculationType == calculationTypes.percent && <span>%</span>}
            </div>

            {showFixedSection && (
                <>
                    <div style={{ border: "1px solid rgb(218, 218, 218)" }}></div>

                    <div className="flex flex-row align-center" style={{ gap: "10px" }}>
                        <Checkbox
                            color="primary"
                            style={{ padding: "0px" }}
                            id={check_box_labels.fixed}
                            name={check_box_labels.fixed}
                            onChange={(e) => {
                                handleAdvertiserRateRadioChange(e, keyToUpdate)

                                const copied_states = { ...states }
                                copied_states.financials[keyToUpdate].fixed.checked = e.target.checked
                                copied_states.financials[keyToUpdate].fixed.disabled = !e.target.checked

                                if (e.target.checked) {
                                    copied_states.financials[keyToUpdate].cpa.disabled = true
                                    copied_states.financials[keyToUpdate].cpm.disabled = true
                                    copied_states.financials[keyToUpdate].cpc.disabled = true
                                } else {
                                    // restore the disable state
                                    copied_states.financials[keyToUpdate].cpa.disabled = !copied_states.financials[
                                        keyToUpdate
                                    ].cpa.checked
                                    copied_states.financials[keyToUpdate].cpm.disabled = !copied_states.financials[
                                        keyToUpdate
                                    ].cpm.checked
                                    copied_states.financials[keyToUpdate].cpc.disabled = !copied_states.financials[
                                        keyToUpdate
                                    ].cpc.checked
                                }

                                setStates(copied_states)
                                updateAdvertiserRates(copied_states.financials[keyToUpdate])
                            }}
                            checked={states.financials[keyToUpdate].fixed.checked}
                            // checked={states.financials[keyToUpdate]["fixed"].checked}
                            disabled={disableFixed}
                        />
                        <CheckBoxLabel htmlFor={check_box_labels.fixed}>Fixed</CheckBoxLabel>
                        {calculationType == calculationTypes.money && MoneySymbol}
                        <TextField
                            name="fixed"
                            value={states.financials[keyToUpdate].fixed.value}
                            // disabled={disableFixed}
                            disabled={states.financials[keyToUpdate].fixed.disabled}
                            onChange={handleAdvertiserRatesChanges}
                            variant="outlined"
                            className={inputStyles.input}
                            placeholder="0.000"
                        />
                        {calculationType == calculationTypes.percent && <span>%</span>}
                    </div>
                </>
            )}
        </div>
    )
}
