import TextField from "@material-ui/core/TextField";
import React, { useEffect } from "react";
import useStyles from "../../../components/StyleClasses";

export const ThirdStep_Kpi = ({
  selectedChanNames,
  kpi_states,
  handleKpiStatesChanges,
  KpiTextFieldStyle,
  setStep,
  CHANNELS,
}) => {
  const classes = useStyles();

  useEffect(() => {
    console.log("kpi_states", kpi_states);
    console.log("selectedChanNames", selectedChanNames);
    console.log("CHANNELS", CHANNELS);
    console.log("KpiTextFieldStyle", KpiTextFieldStyle);
    console.log("setStep", setStep);
    console.log("handleKpiStatesChanges", handleKpiStatesChanges);
  }, [kpi_states]);

  return (
    <div className={classes.boxShadow} style={{ marginTop: "0px" }}>
      {selectedChanNames.map((channel_name, item_index) => {
        // if (channel_name == "digital - video") {
        if (channel_name == "social media") {
          return (
            <div>
              {
                <span className={classes.redHead}>
                  {/* {kpi_states[item_index].section_heading} */}
                  {"Social Media"}
                </span>
              }
              {
                <div
                  className={classes.customfield}
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  <TextField
                    style={KpiTextFieldStyle}
                    fullWidth
                    label="Content"
                    value={kpi_states[item_index].content}
                    onChange={(e) => {
                      e.persist();
                      handleKpiStatesChanges(
                        "content",
                        e.target.value,
                        item_index
                      );
                    }}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  />
                  <TextField
                    style={KpiTextFieldStyle}
                    fullWidth
                    label="KPI"
                    select
                    value={kpi_states[item_index].KPI}
                    onChange={(e) => {
                      e.persist();
                      handleKpiStatesChanges("KPI", e.target.value, item_index);
                    }}
                    variant="outlined"
                  >
                    <option disabled key={0} value={0}>
                      Select...
                    </option>
                    {["CTR", "Viewability", "CPC", "Reach"].map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </TextField>
                  <TextField
                    style={KpiTextFieldStyle}
                    type="number"
                    fullWidth
                    label="KPI Benchmark"
                    value={kpi_states[item_index].KPI_Benchmark}
                    onChange={(e) => {
                      e.persist();
                      handleKpiStatesChanges(
                        "KPI_Benchmark",
                        e.target.value,
                        item_index
                      );
                    }}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  />
                  <TextField
                    style={KpiTextFieldStyle}
                    fullWidth
                    label="Target VTR"
                    value={kpi_states[item_index].TargetVTR}
                    onChange={(e) => {
                      e.persist();
                      handleKpiStatesChanges(
                        "TargetVTR",
                        e.target.value,
                        item_index
                      );
                    }}
                    type="number"
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  />
                  <TextField
                    style={KpiTextFieldStyle}
                    type="number"
                    fullWidth
                    label="Target CTR"
                    value={kpi_states[item_index].TargetCTR}
                    onChange={(e) => {
                      e.persist();
                      handleKpiStatesChanges(
                        "TargetCTR",
                        e.target.value,
                        item_index
                      );
                    }}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  />
                  <TextField
                    style={KpiTextFieldStyle}
                    fullWidth
                    label="Buy Type"
                    select
                    value={kpi_states[item_index].buyType}
                    onChange={(e) => {
                      e.persist();
                      handleKpiStatesChanges(
                        "buyType",
                        e.target.value,
                        item_index
                      );
                    }}
                    variant="outlined"
                  >
                    <option disabled key={0} value={0}>
                      Select...
                    </option>
                    {["CPM", "CPC", "Net Cost"].map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </TextField>
                  <TextField
                    style={KpiTextFieldStyle}
                    type="number"
                    fullWidth
                    label="Frequency"
                    value={kpi_states[item_index].frequency}
                    onChange={(e) => {
                      e.persist();
                      handleKpiStatesChanges(
                        "frequency",
                        e.target.value,
                        item_index
                      );
                    }}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  />
                </div>
              }
            </div>
          );
        }
        if (
          channel_name == "digital - display" ||
          channel_name == "digital - rich media" ||
          channel_name == "digital - skins" ||
          channel_name == "digital/online"
        ) {
          return (
            <div>
              {
                <span className={classes.redHead}>
                  {channel_name == "digital - display" && "Digital [Display]"}
                  {channel_name == "digital - rich media" &&
                    "Digital [Rich Media]"}
                  {channel_name == "digital - skins" && "Digital [Skins]"}
                  {channel_name == "digital/online" && "Digital [Online]"}
                </span>
              }
              {
                <div
                  className={classes.customfield}
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  <TextField
                    style={KpiTextFieldStyle}
                    fullWidth
                    label="Content"
                    value={kpi_states[item_index].content}
                    onChange={(e) => {
                      e.persist();
                      handleKpiStatesChanges(
                        "content",
                        e.target.value,
                        item_index
                      );
                    }}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  />
                  <TextField
                    style={KpiTextFieldStyle}
                    fullWidth
                    label="KPI"
                    select
                    value={kpi_states[item_index].KPI}
                    onChange={(e) => {
                      e.persist();
                      handleKpiStatesChanges("KPI", e.target.value, item_index);
                    }}
                    variant="outlined"
                  >
                    <option disabled key={0} value={0}>
                      Select...
                    </option>
                    {["CTR", "Viewability", "CPC", "Reach"].map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </TextField>
                  <TextField
                    style={KpiTextFieldStyle}
                    type="number"
                    fullWidth
                    label="KPI Benchmark"
                    value={kpi_states[item_index].KPI_Benchmark}
                    onChange={(e) => {
                      e.persist();
                      handleKpiStatesChanges(
                        "KPI_Benchmark",
                        e.target.value,
                        item_index
                      );
                    }}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  />
                  <TextField
                    style={KpiTextFieldStyle}
                    fullWidth
                    label="Buy Type"
                    select
                    value={kpi_states[item_index].buyType}
                    onChange={(e) => {
                      e.persist();
                      handleKpiStatesChanges(
                        "buyType",
                        e.target.value,
                        item_index
                      );
                    }}
                    variant="outlined"
                  >
                    <option disabled key={0} value={0}>
                      Select...
                    </option>
                    {["CPM", "CPC", "Net Cost"].map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </TextField>
                  <TextField
                    style={KpiTextFieldStyle}
                    type="number"
                    fullWidth
                    label="Frequency"
                    value={kpi_states[item_index].frequency}
                    onChange={(e) => {
                      e.persist();
                      handleKpiStatesChanges(
                        "frequency",
                        e.target.value,
                        item_index
                      );
                    }}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  />
                </div>
              }
            </div>
          );
        }
        if (channel_name == "print") {
          return (
            <div>
              {<span className={classes.redHead}>{"Print"}</span>}
              {true && (
                <div
                  className={classes.customfield}
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  <TextField
                    style={KpiTextFieldStyle}
                    fullWidth
                    label="Position"
                    select
                    value={kpi_states[item_index].position}
                    onChange={(e) => {
                      e.persist();
                      handleKpiStatesChanges(
                        "position",
                        e.target.value,
                        item_index
                      );
                    }}
                    // SelectProps={{
                    //   native: true,
                    // }}
                    variant="outlined"
                  >
                    <option disabled key={0} value={0}>
                      Select...
                    </option>
                    {[
                      "Right Hand Side",
                      "Left Hand Side",
                      "Front Half",
                      "Back Half",
                      "IFC (Inside Front COver)",
                      "IBC (Inside Back Cover)",
                    ].map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </TextField>
                  <TextField
                    style={KpiTextFieldStyle}
                    type="number"
                    fullWidth
                    label="No of Insertions"
                    value={kpi_states[item_index].noofInsertions}
                    onChange={(e) => {
                      e.persist();
                      handleKpiStatesChanges(
                        "noofInsertions",
                        e.target.value,
                        item_index
                      );
                    }}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  />
                  <TextField
                    style={KpiTextFieldStyle}
                    type="date"
                    fullWidth
                    label="Insertion Date"
                    value={kpi_states[item_index].insertionDate}
                    onChange={(e) => {
                      e.persist();
                      handleKpiStatesChanges(
                        "insertionDate",
                        e.target.value,
                        item_index
                      );
                    }}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  />
                </div>
              )}
            </div>
          );
        }
        if (channel_name == "tv") {
          return (
            <div>
              {<span className={classes.redHead}>{"Television"}</span>}
              {
                <div
                  className={classes.customfield}
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  <TextField
                    style={KpiTextFieldStyle}
                    fullWidth
                    label="Channel"
                    value={kpi_states[item_index].channel}
                    onChange={(e) => {
                      handleKpiStatesChanges(
                        "channel",
                        e.target.value,
                        item_index
                      );
                    }}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  />
                  <TextField
                    style={KpiTextFieldStyle}
                    fullWidth
                    label="Method"
                    select
                    value={kpi_states[item_index].method}
                    onChange={(e) => {
                      handleKpiStatesChanges(
                        "method",
                        e.target.value,
                        item_index
                      );
                    }}
                    variant="outlined"
                  >
                    <option disabled key={0} value={0}>
                      Select...
                    </option>
                    {[
                      "Digital",
                      "Programmatic (Heat Map)",
                      "Poster - 2 week install time needed",
                    ].map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </TextField>
                  <TextField
                    type="number"
                    style={KpiTextFieldStyle}
                    fullWidth
                    label="Quantity"
                    value={kpi_states[item_index].quantity}
                    onChange={(e) => {
                      handleKpiStatesChanges(
                        "quantity",
                        e.target.value,
                        item_index
                      );
                    }}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  />
                </div>
              }
            </div>
          );
        }
        if (channel_name == "radio") {
          return (
            <div>
              {<span className={classes.redHead}>{"Radio"}</span>}
              {true && (
                <div
                  className={classes.customfield}
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  <TextField
                    type="number"
                    style={KpiTextFieldStyle}
                    fullWidth
                    label="Quantity"
                    value={kpi_states[item_index].quantity}
                    onChange={(e) => {
                      e.persist();
                      handleKpiStatesChanges(
                        "quantity",
                        e.target.value,
                        item_index
                      );
                    }}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  />
                </div>
              )}
            </div>
          );
        }
        if (channel_name == "ooh") {
          return (
            <div>
              {<span className={classes.redHead}>{"OOH"}</span>}
              {true && (
                <div
                  className={classes.customfield}
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  <TextField
                    style={KpiTextFieldStyle}
                    fullWidth
                    label="Product"
                    select
                    value={kpi_states[item_index].product}
                    onChange={(e) => {
                      e.persist();
                      handleKpiStatesChanges(
                        "product",
                        e.target.value,
                        item_index
                      );
                    }}
                    // SelectProps={{
                    //   native: true,
                    // }}
                    variant="outlined"
                  >
                    <option disabled key={0} value={0}>
                      Select...
                    </option>
                    {[
                      "High Impact Formats",
                      "Rail Stations",
                      "Underground",
                    ].map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </TextField>
                  <TextField
                    style={KpiTextFieldStyle}
                    fullWidth
                    label="Method"
                    select
                    value={kpi_states[item_index].method}
                    onChange={(e) => {
                      e.persist();
                      handleKpiStatesChanges(
                        "method",
                        e.target.value,
                        item_index
                      );
                    }}
                    variant="outlined"
                  >
                    <option disabled key={0} value={0}>
                      Select...
                    </option>
                    {[
                      "Digital",
                      "Programmatic (Heat Map)",
                      "Poster - 2 week install time needed",
                    ].map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </TextField>
                  <TextField
                    type="number"
                    style={KpiTextFieldStyle}
                    fullWidth
                    label="Quantity"
                    value={kpi_states[item_index].quantity}
                    onChange={(e) => {
                      e.persist();
                      handleKpiStatesChanges(
                        "quantity",
                        e.target.value,
                        item_index
                      );
                    }}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  />
                </div>
              )}
            </div>
          );
        }
        return null;
      })}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <button className={classes.addActionBtn} onClick={() => setStep(1)}>
          Previous
        </button>
        <button
          className={classes.addActionBtn}
          onClick={() => {
            let chanArr =
              CHANNELS &&
              CHANNELS.length &&
              CHANNELS.filter((chan) => {
                if (chan.channelName.toLowerCase() === "digital") {
                  return chan;
                }
              });
            if (chanArr.length >= 1) {
              setStep(3);
            } else {
              setStep(3);
            }
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};
