import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloudDownload from "@material-ui/icons/CloudDownload";
import Pagination from "@material-ui/lab/Pagination";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { flushSync } from "react-dom";
import { useAccessToken } from "../../../../../Hooks/useAccessToken";
import EyeIcon from "../../../../../assets/eyePass.svg";
import LoaderCircle from "../../../../../components/Loader/Loader";
import useStyles from "../../../../../components/StyleClasses";
import { API } from "../../../../../constants";
import ChartModal from "../ChartModal/ChartModal";

export default function CsvList({ shouldRefetchCsvList, setShouldFetchCsvList }) {
    const access_token = useAccessToken();
    const classes = useStyles();
    const [showChartModal, setShowChartModal] = useState(false);
    const [activeCsvId, setActiveCsvId] = useState("");
    const [pageCount, setPageCount] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const activePageRef = useRef(activePage);
    const csvIdRef = useRef(null);
    const csvNameRef = useRef(null);

    const { data, isFetching, isError, error, isLoading, refetch: refetchCsvList } = useQuery({
        queryFn: (params) => {
            return axios.get(`${API}/admin/csv/domain-results?page=${activePageRef.current}&limit=10`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            });
        },
        queryHash: "csv-lists",
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            setPageCount(data?.data?.pagination?.total);
        },
    });

    // csv file data download
    const { error: csvBufferError, refetch } = useQuery({
        queryFn: (params) => {
            return axios.get(`${API}/admin/csv/get-csv-file/${csvIdRef.current}`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            });
        },
        onSuccess: (data) => {
            downloadCsv(data.data, csvNameRef.current);
        },
        enabled: false,
        refetchOnWindowFocus: false,
        queryHash: ["csv-buffer-data"],
        queryKey: ["csv-buffer-data"],
    });

    const downloadCsv = (arrayBuffer, fileName) => {
        const blob = new Blob([arrayBuffer], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(url);
    };

    const downloadCsvHandler = (csv_id, csv_file_name) => {
        csvIdRef.current = csv_id;
        csvNameRef.current = csv_file_name.split(".csv")[0] + ".bascored.csv";
        refetch();
    };

    useEffect(() => {
        if (shouldRefetchCsvList) {
            refetchCsvList();
            setShouldFetchCsvList(false);
        }
    }, [shouldRefetchCsvList]);

    useEffect(() => {
        activePageRef.current = activePage;
    }, [activePage]);

    return (
        <>
            <div>
                <div style={{ marginTop: "40px" }}>
                    <div className={classes.customfield} style={{ display: "flex", justifyContent: "space-between" }}>
                        <span
                            style={{
                                color: "#FB6D6A",
                                fontWeight: "600",
                                margin: "0",
                                fontSize: "20px",
                                marginLeft: "20px",
                            }}
                        >
                            CSV File List
                        </span>
                        <Pagination
                            count={Math.ceil(pageCount / 10)}
                            color="primary"
                            onChange={(e, page) => {
                                flushSync(() => {
                                    activePageRef.current = page;
                                    setActivePage(page);
                                });
                                refetchCsvList();
                            }}
                            size="small"
                        />
                    </div>
                    <div
                        className={classes.customfield}
                        style={{ textAlign: isLoading ? "center" : "left", paddingRight: "20px" }}
                    >
                        {(isLoading || isFetching) && (
                            <LoaderCircle
                                style={{
                                    position: "unset",
                                    marginTop: "20px",
                                    width: "30px",
                                    height: "30px",
                                    marginLeft: "20px",
                                }}
                            />
                        )}
                        {(!isFetching || !isLoading) && data && (
                            <TableContainer component={Paper} style={{ marginLeft: "20px", marginTop: "20px" }}>
                                <Table>
                                    <TableHead>
                                        <TableRow
                                            style={{
                                                background: "linear-gradient(to right, #9211a2, #4911a2)",
                                            }}
                                        >
                                            <StyledTableCell style={{ width: "20px" }}>Id</StyledTableCell>
                                            <StyledTableCell>Name</StyledTableCell>
                                            <StyledTableCell>File name</StyledTableCell>
                                            <StyledTableCell style={{ width: "15%" }}>Created At</StyledTableCell>
                                            <StyledTableCell>View chart</StyledTableCell>
                                            <StyledTableCell>Download</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data?.data?.data?.map((csv, index) => {
                                            return (
                                                <TableRow key={csv._id}>
                                                    <StyledTableCell>{csv?.id ?? "-"}</StyledTableCell>
                                                    <StyledTableCell>{csv?.name ?? "-"}</StyledTableCell>
                                                    <StyledTableCell>{csv?.csv_file_name}</StyledTableCell>
                                                    <StyledTableCell>{csv?.createdAt} (UTC)</StyledTableCell>
                                                    <StyledTableCell width="20px">
                                                        <span
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-around",
                                                            }}
                                                        >
                                                            <img
                                                                style={{ cursor: "pointer" }}
                                                                src={EyeIcon}
                                                                onClick={() => {
                                                                    setShowChartModal(true);
                                                                    setActiveCsvId(csv._id);
                                                                }}
                                                            />
                                                        </span>
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        width="20px"
                                                        align="center"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => downloadCsvHandler(csv._id, csv.csv_file_name)}
                                                    >
                                                        <CloudDownload />
                                                    </StyledTableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                        {/* {(!isFetching || !isLoading) && data && (
                            <div style={{ marginLeft: "20px", marginTop: "20px" }}>
                                {data?.data?.data?.map((csv) => {
                                    return (
                                        <div key={csv._id}>
                                            <Link
                                                href=""
                                                onClick={() => {
                                                    setShowChartModal(true);
                                                    setActiveCsvId(csv._id);
                                                }}
                                            >
                                                {csv?.csv_file_name}
                                            </Link>
                                        </div>
                                    );
                                })}
                            </div>
                        )} */}
                    </div>
                </div>
            </div>

            <ChartModal
                open={showChartModal}
                onClose={() => {
                    setShowChartModal(false);
                    setActiveCsvId("");
                }}
                csv_id={activeCsvId}
            />
        </>
    );
}

const CsvTableHead = ["Id", "Name", "File name", "Created At", "View Chart"];
const StyledTableRow = withStyles((theme) => ({
    root: {},
}))(TableRow);
const StyledTableCell = withStyles((theme) => ({
    head: {
        color: theme.palette.common.white,
        fontSize: 12,
        paddingBottom: "2px",
        paddingTop: "2px",
    },
    body: {
        fontSize: 12,
        border: "1px solid #D2D2D2",
        color: "#4F74C9",
        paddingBottom: "2px",
        paddingTop: "2px",
    },
}))(TableCell);
