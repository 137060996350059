import { Grid, Typography } from "@material-ui/core"
import React from "react"
import Selection from "../../../../../../components/Select/Selection"
import { SERVE_TYPES, useChannelAssignmentPageCtx } from "../../../ChannelAssignmentPage"
import FinancialsRates from "../FinancialsRates"
import { PublisherPayoutOptions } from "../Step2"

export default function Financials_Section({
    states,
    setStates,
    inputStyles,
    handleAdvertiserRatesChanges,
    handlePayoutChanges,
}) {
    const { selectedServeType, step2StatesRef } = useChannelAssignmentPageCtx()
    const handleFixedDelivery__AdvertiserRateChange = (e) => {
        e.persist()
        const copied_states = { ...states }
        copied_states.financials.advertiser_rates[e.target.name].value = e.target.value
        setStates(copied_states)
    }
    const handleAdvertiserRateRadioChange = (e, key) => {
        e.persist()
        const copied_states = { ...states }

        if (selectedServeType == SERVE_TYPES["Fixed Delivery"]) {
            if (
                key == "advertiser_rates" &&
                copied_states.financials.publisher_payout.selected_option.value == "percent"
            ) {
                copied_states.financials.advertiser_rates[e.target.name.split("advertiser_rates__check_")[1]].checked =
                    e.target.checked

                copied_states.financials.publisher_payout[
                    e.target.name.split("advertiser_rates__check_")[1]
                ].checked = !e.target.checked
                if (!e.target.checked) {
                    copied_states.financials.publisher_payout[
                        e.target.name.split("advertiser_rates__check_")[1]
                    ].disabled = true
                }

                // console.log(e.target.name, key);
            }
        }
        if (selectedServeType == SERVE_TYPES["Auction"]) {
            if (
                key == "advertiser_rates" &&
                copied_states.financials.publisher_payout.selected_option.value == "percent"
            ) {
                copied_states.financials.advertiser_rates[e.target.name.split("advertiser_rates__check_")[1]].checked =
                    e.target.checked

                copied_states.financials.publisher_payout[
                    e.target.name.split("advertiser_rates__check_")[1]
                ].checked = !e.target.checked
                if (!e.target.checked) {
                    copied_states.financials.publisher_payout[
                        e.target.name.split("advertiser_rates__check_")[1]
                    ].disabled = true
                }
            }
        }
        setStates(copied_states)
    }

    return (
        <>
            <div style={{ marginTop: "20px" }} className="form-section-heading">
                <Typography>Financials</Typography>
            </div>

            <Grid container style={{ paddingInline: "10px" }}>
                <Grid item xs={5} md={5} lg={5}>
                    <div className="flex align-center" style={{ paddingTop: "9px", paddingBottom: "9px" }}>
                        <span>Advertiser Rates</span>
                    </div>
                    <FinancialsRates
                        states={states}
                        setStates={setStates}
                        step2StatesRef={step2StatesRef}
                        inputStyles={inputStyles}
                        handleAdvertiserRatesChanges={handleAdvertiserRatesChanges}
                        handleAdvertiserRateRadioChange={handleAdvertiserRateRadioChange}
                        keyToUpdate="advertiser_rates"
                        disableCpm={states.financials["advertiser_rates"].fixed.checked}
                        disableCpa={states.financials["advertiser_rates"].fixed.checked}
                        disableCpc={states.financials["advertiser_rates"].fixed.checked}
                        showFixedSection={selectedServeType == SERVE_TYPES["Fixed Delivery"]}
                    />
                </Grid>
                <Grid item xs={5} md={5} lg={5}>
                    <div className="flex align-center">
                        <span>Publisher Payout</span>
                        <Selection
                            style={{ width: "unset", margin: 0, marginLeft: "10px" }}
                            defaultValue={states.financials.publisher_payout.selected_option}
                            value={states.financials.publisher_payout.selected_option}
                            options={PublisherPayoutOptions}
                            onChange={(e) => {
                                const copied_states = { ...states }

                                // reset all of the fields and set selection_option to e.
                                copied_states.financials.publisher_payout = {
                                    selected_option: e,
                                    fixed: {
                                        checked: false,
                                        value: "",
                                        disabled: true,
                                    },
                                    cpm: {
                                        checked: false,
                                        value: "",
                                        disabled: true,
                                    },
                                    cpc: {
                                        checked: false,
                                        value: "",
                                        disabled: true,
                                    },
                                    cpa: {
                                        checked: false,
                                        value: "",
                                        disabled: true,
                                    },
                                }

                                setStates(copied_states)
                            }}
                            isClearable={false}
                        />
                    </div>
                    {/* custom rate */}
                    {states.financials.publisher_payout.selected_option.value == "rate" && (
                        <FinancialsRates
                            states={states}
                            setStates={setStates}
                            step2StatesRef={step2StatesRef}
                            inputStyles={inputStyles}
                            handleAdvertiserRatesChanges={handlePayoutChanges}
                            handleAdvertiserRateRadioChange={handleAdvertiserRateRadioChange}
                            keyToUpdate="publisher_payout"
                            disableCpa={states.financials["publisher_payout"].fixed.checked}
                            disableCpm={states.financials["publisher_payout"].fixed.checked}
                            disableCpc={states.financials["publisher_payout"].fixed.checked}
                        />
                    )}
                    {/* revenue share */}
                    {states.financials.publisher_payout.selected_option.value == "percent" && (
                        <FinancialsRates
                            states={states}
                            setStates={setStates}
                            step2StatesRef={step2StatesRef}
                            inputStyles={inputStyles}
                            handleAdvertiserRatesChanges={handlePayoutChanges}
                            handleAdvertiserRateRadioChange={handleAdvertiserRateRadioChange}
                            disableFixed={
                                selectedServeType == SERVE_TYPES["Fixed Delivery"] &&
                                !states.financials.advertiser_rates["fixed"].checked
                            }
                            disableCpa={
                                (selectedServeType == SERVE_TYPES["Fixed Delivery"] ||
                                    selectedServeType == SERVE_TYPES["Auction"]) &&
                                (!states.financials.advertiser_rates["cpa"].checked ||
                                    states.financials.advertiser_rates["cpa"].disabled)
                            }
                            disableCpm={
                                (selectedServeType == SERVE_TYPES["Fixed Delivery"] ||
                                    selectedServeType == SERVE_TYPES["Auction"]) &&
                                (!states.financials.advertiser_rates["cpm"].checked ||
                                    states.financials.advertiser_rates["cpm"].disabled)
                            }
                            disableCpc={
                                (selectedServeType == SERVE_TYPES["Fixed Delivery"] ||
                                    selectedServeType == SERVE_TYPES["Auction"]) &&
                                (!states.financials.advertiser_rates["cpc"].checked ||
                                    states.financials.advertiser_rates["cpc"].disabled)
                            }
                            // disableCpa={selectedServeType == SERVE_TYPES["Auction"] || true}
                            // disableCpc={selectedServeType == SERVE_TYPES["Auction"] || false}
                            // disableCpm={selectedServeType == SERVE_TYPES["Auction"] || false}
                            calculationType="percent"
                            keyToUpdate="publisher_payout"
                            showFixedSection={selectedServeType != SERVE_TYPES["Auction"]}
                        />
                    )}
                </Grid>
            </Grid>
        </>
    )
}
