import React, { useState, useEffect } from "react";
import { makeStyles, fade } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { useForm, Controller } from "react-hook-form";

//Radio
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

//Text field
import TextField from "@material-ui/core/TextField";

// Select
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

//Textarea
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

//Button
import Button from "@material-ui/core/Button";
import { useSelector } from "react-redux";

import Checkbox from "@material-ui/core/Checkbox";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "40px",
  },
  pageTitle: {
    color: "#e96941",
    fontWeight: "normal",
  },
  formTitle: {
    color: "#1d8bf7",
    fontWeight: "normal",
  },
  paper: {
    padding: "16px",
    color: theme.palette.text.secondary,
  },
  textarea: {
    width: "100%",
    backgroundColor: "#f4f4f8",
    borderRadius: "5px",
    padding: "14px",
    marginBottom: "20px",
  },
  selectControl: {
    marginBottom: "8px",
  },
  customfield: {
    paddingTop: "0",
    marginTop: "0",
    backgroundColor: "#f4f4f8",
    borderRadius: "5px",
    "& .MuiInputLabel-root.Mui-shrink": {
      color: "red",
      // "& .Mui-shrink": {
      // 	color: 'red'
      // },
    },
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&$focused": {
      backgroundColor: "#fff",
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.5px 14px",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",

      "& fieldset": {
        borderColor: "grey",
      },
      "&:hover fieldset": {},
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "grey",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "grey",
    },
    "& .MuiInputLabel-root.MuiInputLabel-shrink": {
      margin: "0!important",
    },
    "& .MuiInputLabel-root.MuiInputLabel-animated": {
      marginTop: "-8px",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",
    },
  },
  selectImgBtn: {
    marginTop: "10px",
    display: "block",
    margin: "0 auto",
    color: "#ba54f5",
    border: "1px solid #ba54f5",
    padding: "5px 30px",
  },
  nextButton: {
    margin: "20px auto 0",
    backgroundColor: "#1d8bf7",
    color: "white",
    display: "block",
    padding: "5px 40px",
  },
  checkNRadio: {
    padding: 0,
    "& .MuiFormLabel-root": {
      color: "#1d8bf7",
      marginBottom: "8px",
    },
    "& .MuiFormControlLabel-root": {
      "& span": {
        paddingTop: "1px",
        paddingBottom: "1px",
      },
    },
  },
}));

const InventoryForm = ({ inventory, mediaOwner, sendNewInventories }) => {
  const { register, handleSubmit, errors, control } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const [name, setName] = useState(inventory?.media);
  const [width, setWidth] = useState(inventory?.width);
  const [height, setHeight] = useState(inventory?.height);
  const [notes, setNotes] = useState(inventory?.notes);
  const [fileType, setFileType] = useState(inventory?.fileType);
  const [maxFileSize, setMaxFileSize] = useState(inventory?.maxFileSize);
  const [colorSpace, setColorSpace] = useState(inventory?.colorSpace);
  const [unitCost, setUnitCost] = useState(inventory?.unitCost);

  const dispatch = useDispatch();
  const history = useHistory();

  const classes = useStyles();

  useEffect(() => {}, []);

  const onSubmit = (value) => {
    const inventoryUpdate = Object.assign(
      {},
      mediaOwner.inventory.value[inventory.name],
      value
    );
    mediaOwner.inventory.value[inventory.name] = inventoryUpdate;
    sendNewInventories(mediaOwner);
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <form
            className={classes.form}
            // ref={useRef()  }
            onSubmit={handleSubmit(onSubmit)}
          >
            <div>
              <Grid container spacing={3}>
                <Grid item md={7} sm={7} xs={7}>
                  <TextField
                    value={name}
                    className={classes.customfield}
                    onChange={(e) => setName(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="name"
                    label="Name"
                    // name={`[name]`}
                    inputRef={register()}
                    error={
                      errors.persona &&
                      errors.persona.about &&
                      errors.persona.about.name?.type === "required"
                    }
                  />
                  <Grid container spacing={1}>
                    <Grid item md={6} sm={6} xs={6}>
                      <TextField
                        value={width}
                        onChange={(e) => setWidth(e.target.value)}
                        className={classes.customfield}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="width"
                        label="Width"
                        name={`[value][width]`}
                        inputRef={register()}
                      />
                    </Grid>
                    <Grid item md={6} sm={6} xs={6}>
                      <TextField
                        value={height}
                        onChange={(e) => setHeight(e.target.value)}
                        className={classes.customfield}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="height"
                        label="Height"
                        name={`[value][height]`}
                        inputRef={register()}
                      />
                    </Grid>
                  </Grid>
                  <Controller
                    as={<TextareaAutosize />}
                    name={`[value][notes]`}
                    control={control}
                    onChange={(event) => setNotes(event.target.value)}
                    defaultValue={notes}
                    className={classes.textarea}
                    aria-label="minimum height"
                    rowsMin={10}
                    placeholder="Notes"
                  />
                </Grid>
                <Grid item md={5} sm={5} xs={5}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={[
                      classes.selectControl,
                      classes.customfield,
                    ].join(" ")}
                  >
                    <InputLabel id="demo-simple-select-label">
                      File Type
                    </InputLabel>
                    <Controller
                      as={
                        <Select>
                          <MenuItem value={""}></MenuItem>
                          <MenuItem value={"JPEG"}>JPEG</MenuItem>
                          <MenuItem value={"PNG"}>PNG</MenuItem>
                          <MenuItem value={"SVG"}>SVG</MenuItem>
                          <MenuItem value={"PDF"}>PDF</MenuItem>
                          <MenuItem value={"EPS"}>EPS</MenuItem>
                          <MenuItem value={"AI"}>AI</MenuItem>
                          <MenuItem value={"TIFFS"}>TIFFS</MenuItem>
                          <MenuItem value={"PSD"}>PSD</MenuItem>
                        </Select>
                      }
                      aria-label="Religion"
                      name={`[value][fileType]`}
                      // rules={{ required: "this is required" }}
                      control={control}
                      onChange={(event) => setFileType(event.target.value)}
                      defaultValue={fileType}
                    />
                  </FormControl>
                  <TextField
                    value={maxFileSize}
                    className={classes.customfield}
                    onChange={(e) => setMaxFileSize(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="maxFileSize"
                    label="Max File Size"
                    name={`[value][maxFileSize]`}
                    inputRef={register()}
                  />

                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={[
                      classes.selectControl,
                      classes.customfield,
                    ].join(" ")}
                    error={Boolean(errors.ethnicity)}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Color Space
                    </InputLabel>
                    <Controller
                      as={
                        <Select>
                          <MenuItem value={""}></MenuItem>
                          <MenuItem value={"PMS"}>PMS</MenuItem>
                          <MenuItem value={"CMYK"}>CMYK</MenuItem>
                          <MenuItem value={"RGB"}>RGB</MenuItem>
                          <MenuItem value={"HEX"}>HEX</MenuItem>
                        </Select>
                      }
                      aria-label="Color Space"
                      name={`[value][colorSpace]`}
                      control={control}
                      onChange={(event) => setColorSpace(event.target.value)}
                      defaultValue={colorSpace}
                    />
                  </FormControl>
                  {/* <FormControl variant="outlined" error={Boolean(errors.description)} fullWidth> */}
                  <TextField
                    value={unitCost}
                    className={classes.customfield}
                    onChange={(e) => setUnitCost(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="unitCost"
                    label="Unit Cost"
                    name={`[value][unitCost]`}
                    inputRef={register()}
                  />
                  {/* </FormControl> */}
                  {/* <Grid> */}
                  <hr style={{ marginBottom: "20px" }} />
                  <Button className={classes.nextButton} type="submit">
                    SAVE
                  </Button>
                </Grid>
              </Grid>
            </div>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default InventoryForm;
