import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, Input, Radio, RadioGroup } from "@material-ui/core"
import { DeleteOutline } from "@material-ui/icons"
import { useMutation } from "@tanstack/react-query"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useAccessToken } from "../../../../../../../Hooks/useAccessToken"
import LoaderCircle from "../../../../../../../components/Loader/Loader"
import Selection from "../../../../../../../components/Select/Selection"
import { API } from "../../../../../../../constants"
import { useChannelAssignmentPageCtx } from "../../../../ChannelAssignmentPage"

export default function BrowserOsSection({ handleModalButtonClicks, handleStatesChanges, states }) {
    const { platformTargetList } = useChannelAssignmentPageCtx()

    return (
        <Grid container className="flex algin-center" style={{ gap: "40px", paddingInline: "10px" }}>
            <Grid item style={{ width: "20%" }}>
                <label>Browser & OS</label>
            </Grid>
            <Grid item style={{ width: "30%" }}>
                <Selection
                    options={[
                        { label: "No platform target", value: "default value" },
                        ...(platformTargetList
                            ? platformTargetList.map((target) => ({
                                  ...target,
                                  label: target.name,
                                  value: target.id,
                              }))
                            : []),
                    ]}
                    value={states.platform_target}
                    onChange={(e) => handleStatesChanges("platform_target", e)}
                    isClearable={false}
                    style={{ margin: 0 }}
                />
            </Grid>
            <Grid item className="flex align-center">
                <Button
                    variant="outlined"
                    style={{ height: "38px", background: "purple", color: "white" }}
                    onClick={() => handleModalButtonClicks("browser_os_modal")}
                >
                    <span style={{ marginRight: "5px" }}>+</span>
                    <span>Add Platform Target</span>
                </Button>
            </Grid>
        </Grid>
    )
}
export function BrowserOsSectionModal({ closeTargetingModal }) {
    const access_token = useAccessToken()
    const [disableChecks, setDisableChecks] = useState(false)
    const [showSpecificBrowserChecks, setShowSpecificBrowserChecks] = useState(false)
    const [showSpecificOsChecks, setShowSpecificOsChecks] = useState(false)
    const [showSpecificMobileContents, setShowSpecificMobileContents] = useState(false)
    const { refetchPlatformTargetList } = useChannelAssignmentPageCtx()
    const { data, mutate: addNewBrowserTarget, isLoading: isAddingTargeting, error, isSuccess } = useMutation({
        mutationFn: (body) => {
            return axios.post(`${API}/mediaPlanner/AdButler/platformTarget`, body, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
        },
        mutationKey: ["add_targeting"],
        onSuccess: (data) => {
            refetchPlatformTargetList()
            closeTargetingModal()
        },
        enabled: false,
        refetchOnWindowFocus: false,
    })

    const mobileTargets = {
        chrome: false,
        safari: false,
        firefox: false,
        opera: false,
        blackberry: false,
        "UC browser": false,
        edge: false,
        samsung: false,
        "All Others": false,
    }
    const desktopTargets = {
        chrome: false,
        safari: false,
        firefox: false,
        opera: false,
        edge: false,
        "internet explorer": false,
        "All Others": false,
    }

    const mobileOsTargets = {
        android: false,
        IOS: false,
        "blackberry OS": false,
        "windows phone": false,
        "All Others": false,
    }
    const desktopOsTargets = {
        "Mac OS X": false,
        windows: false,
        "chrome OS": false,
        ubuntu: false,
        linux: false,
        "All Others": false,
    }
    const [customMobilePattern, setCustomMobilePattern] = useState([])
    const { register, watch, errors, handleSubmit, control, setValue, formState, getValues } = useForm({
        defaultValues: {
            name: "",
            platform_radios: [{}],
            platform: "any",
            browser: "All",
            "mobile-devices": "All",
            "operating-systems": "All",
            browser_targets: {
                desktop: { ...desktopTargets },
                mobile: { ...mobileTargets },
            },
            os_targets: {
                desktop: { ...desktopOsTargets },
                mobile: { ...mobileOsTargets },
            },
            new_mobile_pattern_input: "",
        },
    })
    const platform_value = watch("platform")
    const browser_value = watch("browser")
    const os_value = watch("operating-systems")
    const mobile_value = watch("mobile-devices")

    const handleSubmitForm = (form_data) => {
        if (showSpecificBrowserChecks) {
            form_data["browser_targets"]["mobile"]["others"] = form_data["browser_targets"]["mobile"]["All Others"]
            form_data["browser_targets"]["mobile"]["uc"] = form_data["browser_targets"]["mobile"]["UC browser"]
            delete form_data["browser_targets"]["mobile"]["All Others"]
            delete form_data["browser_targets"]["mobile"]["UC browser"]

            form_data["browser_targets"]["desktop"]["others"] = form_data["browser_targets"]["desktop"]["All Others"]
            form_data["browser_targets"]["desktop"]["ie"] = form_data["browser_targets"]["desktop"]["internet explorer"]
            delete form_data["browser_targets"]["desktop"]["All Others"]
            delete form_data["browser_targets"]["desktop"]["internet explorer"]
        } else if (platform_value == "any") {
            form_data["browser_targets"] = true
        }

        if (showSpecificOsChecks) {
            form_data["os_targets"]["desktop"]["others"] = form_data["os_targets"]["desktop"]["All Others"]
            form_data["os_targets"]["desktop"]["mac"] = form_data["os_targets"]["desktop"]["Mac OS X"]
            form_data["os_targets"]["desktop"]["chromeos"] = form_data["os_targets"]["desktop"]["chrome OS"]
            delete form_data["os_targets"]["desktop"]["All Others"]
            delete form_data["os_targets"]["desktop"]["Mac OS X"]
            delete form_data["os_targets"]["desktop"]["chrome OS"]

            form_data["os_targets"]["mobile"]["others"] = form_data["os_targets"]["mobile"]["All Others"]
            form_data["os_targets"]["mobile"]["ios"] = form_data["os_targets"]["mobile"]["IOS"]
            form_data["os_targets"]["mobile"]["blackberry"] = form_data["os_targets"]["mobile"]["blackberry OS"]
            form_data["os_targets"]["mobile"]["windows_phone"] = form_data["os_targets"]["mobile"]["windows phone"]
            delete form_data["os_targets"]["mobile"]["All others"]
            delete form_data["os_targets"]["mobile"]["IOS"]
            delete form_data["os_targets"]["mobile"]["blackberry OS"]
            delete form_data["os_targets"]["mobile"]["windows phone"]
        } else if (platform_value == "any") {
            form_data["os_targets"] = true
        }
        form_data["device_targets"] = customMobilePattern.reduce((acc, pattern) => {
            acc.push(pattern.value)
            return acc
        }, [])

        delete form_data["browser"]
        delete form_data["mobile-devices"]
        delete form_data["new_mobile_pattern_input"]
        delete form_data["operating-systems"]

        // call api to add the target on db
        addNewBrowserTarget(form_data)
    }

    // disable Browser, Operating system, Mobile/Tablet devices when checked Other in Platform checkbox.
    useEffect(() => {
        setDisableChecks(platform_value != "any")
        if (platform_value != "All") {
            setValue("browser", "All")
            setValue("operating-systems", "All")
            setValue("mobile-devices", "All")
        }
    }, [platform_value])

    useEffect(() => {
        setShowSpecificBrowserChecks(browser_value == "Specific browsers")
    }, [browser_value])
    useEffect(() => {
        setShowSpecificOsChecks(os_value == "Specific operating systems")
    }, [os_value])
    useEffect(() => {
        setShowSpecificMobileContents(mobile_value == "Specific devices")
    }, [mobile_value])

    return (
        <div className="flex flex-col gap-10" style={{ height: "100%" }}>
            {/* name */}
            <Grid container style={{ padding: "10px 20px" }}>
                <Grid style={{ width: "30%" }}>
                    Target name <span className="required-star">*</span>
                </Grid>
                <Grid>
                    <Input
                        placeholder="eg. Mobile Devices"
                        name="name"
                        inputRef={register({ required: true })}
                        error={!!errors.name}
                    />
                </Grid>
            </Grid>

            {/* Platform */}
            <Grid container style={{ padding: "10px 20px" }}>
                <Grid style={{ width: "30%" }}>Platform</Grid>
                <Grid style={{ width: "70%" }}>
                    <FormControl component="fieldset">
                        <Controller
                            control={control}
                            name="platform"
                            render={(field) => {
                                return (
                                    <RadioGroup {...field} className="flex-row-imp">
                                        <FormControlLabel control={<Radio />} value="any" label="All" />
                                        <FormControlLabel control={<Radio />} value="mobile" label="Mobile only" />
                                        <FormControlLabel control={<Radio />} value="tablet" label="Tablet only" />
                                        <FormControlLabel
                                            control={<Radio />}
                                            value="mobile_and_tablet"
                                            label="Mobile & Tablet only"
                                        />
                                        <FormControlLabel control={<Radio />} value="desktop" label="Desktop only" />
                                    </RadioGroup>
                                )
                            }}
                        ></Controller>
                    </FormControl>
                </Grid>
            </Grid>

            {/* browser */}
            <Grid container style={{ padding: "10px 20px" }}>
                <Grid style={{ width: "30%" }}>Browser</Grid>
                <Grid style={{ width: "70%" }}>
                    <Box className="flex flex-col gap-10">
                        <FormControl component="fieldset">
                            <Controller
                                control={control}
                                name="browser"
                                render={(field) => {
                                    return (
                                        <RadioGroup {...field} className="flex-row-imp">
                                            <FormControlLabel
                                                control={<Radio />}
                                                value="All"
                                                label="All"
                                                disabled={disableChecks}
                                            />
                                            <FormControlLabel
                                                control={<Radio />}
                                                value="Specific browsers"
                                                label="Specific browsers"
                                                disabled={disableChecks}
                                            />
                                        </RadioGroup>
                                    )
                                }}
                            ></Controller>
                        </FormControl>

                        {showSpecificBrowserChecks && (
                            <>
                                <Box>
                                    <h4>Mobile browsers</h4>
                                    <Box>
                                        <FormControl component="fieldset" className="flex-row-imp flex-wrap">
                                            {Object.keys(mobileTargets).map((key) => {
                                                return (
                                                    <FormControlLabel
                                                        key={key}
                                                        control={
                                                            <Controller
                                                                name={`browser_targets.mobile.${key}`}
                                                                control={control}
                                                                render={(field) => {
                                                                    return (
                                                                        <Checkbox
                                                                            {...field}
                                                                            checked={field.value}
                                                                            onChange={(e) =>
                                                                                field.onChange(e.target.checked)
                                                                            }
                                                                        />
                                                                    )
                                                                }}
                                                            ></Controller>
                                                        }
                                                        label={key}
                                                        style={{ textTransform: "capitalize" }}
                                                    />
                                                )
                                            })}
                                        </FormControl>
                                    </Box>
                                </Box>
                                <Box>
                                    <h4>Desktop/Notebook browsers</h4>
                                    <Box>
                                        <FormControl component="fieldset" className="flex-row-imp flex-wrap">
                                            {Object.keys(desktopTargets).map((key) => {
                                                return (
                                                    <FormControlLabel
                                                        key={key}
                                                        control={
                                                            <Controller
                                                                name={`browser_targets.desktop.${key}`}
                                                                control={control}
                                                                render={(field) => {
                                                                    return (
                                                                        <Checkbox
                                                                            {...field}
                                                                            checked={field.value}
                                                                            onChange={(e) =>
                                                                                field.onChange(e.target.checked)
                                                                            }
                                                                        />
                                                                    )
                                                                }}
                                                            ></Controller>
                                                        }
                                                        label={key}
                                                        style={{ textTransform: "capitalize" }}
                                                    />
                                                )
                                            })}
                                        </FormControl>
                                    </Box>
                                </Box>
                            </>
                        )}
                    </Box>
                </Grid>
            </Grid>

            {/* operating systems */}
            <Grid container style={{ padding: "10px 20px" }}>
                <Grid style={{ width: "30%" }}>Operating system</Grid>
                <Grid style={{ width: "70%" }}>
                    <Box className="flex flex-col gap-10">
                        <FormControl component="fieldset">
                            <Controller
                                control={control}
                                name="operating-systems"
                                render={(field) => {
                                    return (
                                        <RadioGroup {...field} className="flex-row-imp">
                                            <FormControlLabel
                                                control={<Radio />}
                                                value="All"
                                                label="All"
                                                disabled={disableChecks}
                                            />
                                            <FormControlLabel
                                                control={<Radio />}
                                                value="Specific operating systems"
                                                label="Specific operating systems"
                                                disabled={disableChecks}
                                            />
                                        </RadioGroup>
                                    )
                                }}
                            ></Controller>
                        </FormControl>

                        {showSpecificOsChecks && (
                            <>
                                <Box>
                                    <h4>Mobile OS</h4>
                                    <Box>
                                        <FormControl component="fieldset" className="flex-row-imp flex-wrap">
                                            {Object.keys(mobileOsTargets).map((key) => {
                                                return (
                                                    <FormControlLabel
                                                        key={key}
                                                        control={
                                                            <Controller
                                                                name={`os_targets.mobile.${key}`}
                                                                control={control}
                                                                render={(field) => {
                                                                    return (
                                                                        <Checkbox
                                                                            {...field}
                                                                            checked={field.value}
                                                                            onChange={(e) =>
                                                                                field.onChange(e.target.checked)
                                                                            }
                                                                        />
                                                                    )
                                                                }}
                                                            ></Controller>
                                                        }
                                                        label={key}
                                                        style={{ textTransform: "capitalize" }}
                                                    />
                                                )
                                            })}
                                        </FormControl>
                                    </Box>
                                </Box>
                                <Box>
                                    <h4>Desktop/Notebook OS</h4>
                                    <Box>
                                        <FormControl component="fieldset" className="flex-row-imp flex-wrap">
                                            {Object.keys(desktopOsTargets).map((key) => {
                                                return (
                                                    <FormControlLabel
                                                        key={key}
                                                        control={
                                                            <Controller
                                                                name={`os_targets.desktop.${key}`}
                                                                control={control}
                                                                render={(field) => {
                                                                    return (
                                                                        <Checkbox
                                                                            {...field}
                                                                            checked={field.value}
                                                                            onChange={(e) =>
                                                                                field.onChange(e.target.checked)
                                                                            }
                                                                        />
                                                                    )
                                                                }}
                                                            ></Controller>
                                                        }
                                                        label={key}
                                                        style={{ textTransform: "capitalize" }}
                                                    />
                                                )
                                            })}
                                        </FormControl>
                                    </Box>
                                </Box>
                            </>
                        )}
                    </Box>
                </Grid>
            </Grid>

            {/* mobile devices */}
            <Grid container style={{ padding: "10px 20px" }}>
                <Grid style={{ width: "30%" }}>Mobile/Tablet devices</Grid>
                <Grid style={{ width: "70%" }}>
                    <Box className="flex flex-col gap-10">
                        <FormControl component="fieldset">
                            <Controller
                                control={control}
                                name="mobile-devices"
                                render={(field) => {
                                    return (
                                        <RadioGroup {...field} className="flex-row-imp">
                                            <FormControlLabel
                                                control={<Radio />}
                                                value="All"
                                                label="All"
                                                disabled={disableChecks}
                                            />
                                            <FormControlLabel
                                                control={<Radio />}
                                                value="Specific devices"
                                                label="Specific devices"
                                                disabled={disableChecks}
                                            />
                                        </RadioGroup>
                                    )
                                }}
                            ></Controller>
                        </FormControl>
                        {showSpecificMobileContents && (
                            <>
                                <Box className="flex gap-10">
                                    <Input name="new_mobile_pattern_input" inputRef={register()} />
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            if (!getValues("new_mobile_pattern_input")) return
                                            setCustomMobilePattern((v) => [
                                                {
                                                    id: Date.now().toString(),
                                                    value: getValues("new_mobile_pattern_input"),
                                                },
                                                ...v,
                                            ])
                                            setValue("new_mobile_pattern_input", "")
                                        }}
                                    >
                                        Add Pattern
                                    </Button>
                                </Box>
                                <Box>
                                    <ul>
                                        {customMobilePattern?.map((pattern, i) => {
                                            return (
                                                <li key={pattern.id}>
                                                    <Box className="flex align-center">
                                                        <span className="mr-5-imp">{pattern.value}</span>
                                                        <DeleteOutline
                                                            onClick={() =>
                                                                setCustomMobilePattern((patterns) =>
                                                                    patterns.filter((p) => p.id != pattern.id)
                                                                )
                                                            }
                                                            style={{ cursor: "pointer" }}
                                                            color="error"
                                                        />
                                                    </Box>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </Box>
                            </>
                        )}
                    </Box>
                </Grid>
            </Grid>

            <Box marginLeft={"auto"} className="btns-container" marginTop={"auto"} style={{ padding: "10px 20px" }}>
                <Button variant="contained" className="mr-10-imp" onClick={closeTargetingModal}>
                    Close
                </Button>
                <Button variant="contained" color="primary" onClick={handleSubmit(handleSubmitForm)}>
                    {isAddingTargeting ? (
                        <LoaderCircle style={{ position: "unset", width: "22px", height: "22px", color: "white" }} />
                    ) : (
                        "Create"
                    )}
                </Button>
            </Box>
        </div>
    )
}
