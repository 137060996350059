import { Box, Button, ButtonBase, Grid, Input, Modal, Popover, Select, TextField, Typography } from "@material-ui/core"
import {
    Add,
    AddBox,
    Check,
    ChevronLeftRounded,
    ClearRounded,
    Close,
    DeleteOutlined,
    Settings,
} from "@material-ui/icons"
import clsx from "clsx"
import cloneDeep from "lodash/cloneDeep"
import React, { useEffect, useState } from "react"
import { v4 as uuid } from "uuid"
import LoaderCircle from "../../../../../../../components/Loader/Loader"
import { useAppToastify } from "../../../../../../../utils/useAppToastify"
import { useChannelAssignmentPageCtx } from "../../../../ChannelAssignmentPage"

const NESTING_LEVEL_LIMIT = 3

export default function DataTargetSection({ states, setStates }) {
    const [showModal, setShowModal] = useState(false)

    return (
        <>
            <Grid container className="flex algin-center" style={{ gap: "40px", paddingInline: "10px" }}>
                <Grid item style={{ width: "20%" }}>
                    <p>Data Keys</p>
                </Grid>
                <Grid item style={{ width: "30%" }}>
                    <Button onClick={() => setShowModal(true)}>
                        <Settings style={{ marginRight: "5px" }} /> Manage Target
                    </Button>
                </Grid>
            </Grid>
            <DataTargetSectionModal showModal={showModal} onClose={() => setShowModal(false)} />
        </>
    )
}
export function DataTargetSectionModal({ showModal, onClose }) {
    const {
        step2StatesRef,
        updateDataKeyTemplate,
        isUpdatingDataKeyTemplate,
        deleteDataKeyTemplate,
        isDeletingDataKeyTemplate,
        dataKeyTemplates,
    } = useChannelAssignmentPageCtx()
    const initialDataKeyGroupChildren = cloneDeep({
        root: {
            element: DataKeyGroup,
            children: [],
            type: "group",
            id: 0,
            activeOperationProp: {
                value: "OR",
                checked: false,
                label: "Any", //'Any' || 'All'
                type: "Any",
            },
        },
    })
    const [dataKeyGroupChildren, setDataKeyGroupChildren] = useState({ ...initialDataKeyGroupChildren })
    const [templatePopoverAnchor, setTemplatePopoverAnchor] = useState(null)
    const [showCreateTemplateModal, setShowCreateTemplateModal] = useState(false)
    const [activeTemplate, setActiveTemplate] = useState(null)
    const { showAppToast } = useAppToastify()

    // console.log({ dataKeyTemplates })

    const resetDataKeyGroupChildren = () => {
        setDataKeyGroupChildren(initialDataKeyGroupChildren)
        handleAddRuleClick("root")
    }

    const handleAddRuleClick = (parent_id) => {
        setDataKeyGroupChildren((current_group_children) => {
            const cloned_children = { ...current_group_children }
            const id = uuid()
            const new_children = {
                id,
                element: DataRule,
                type: "rule",
                children: [],
                parent_id,
                fields: {
                    first_select: "Age",
                    second_select: "=",
                    third_input: 0,
                },
            }

            cloned_children[id] = new_children
            cloned_children[parent_id].children.push(id)

            return cloned_children
        })
    }
    const handleAddRuleClickWithProps = (ObjToUpdate, parent_id, fieldValues) => {
        const id = uuid()
        const new_children = {
            id,
            element: DataRule,
            type: "rule",
            children: [],
            parent_id,
            fields: fieldValues,
        }
        ObjToUpdate[id] = new_children
        ObjToUpdate[parent_id].children.push(id)
    }

    const handleAddDataKeyClick = (parent_id) => {
        const id = uuid()
        setDataKeyGroupChildren((current_group_children) => {
            const cloned_children = { ...current_group_children }
            const new_children = {
                id,
                element: DataKeyGroup,
                type: "group",
                children: [],
                parent_id,
                activeOperationProp: {
                    value: "OR",
                    checked: false,
                    label: "Any",
                    type: "Any",
                },
            }

            cloned_children[id] = new_children
            cloned_children[parent_id].children.push(id)

            return cloned_children
        })
        handleAddRuleClick(id)
    }
    const handleAddDataKeyClickWithProps = (ObjToUpdate, parent_id, activeOperationProp, rootId) => {
        const id = rootId ?? uuid()
        const new_children = {
            id,
            element: DataKeyGroup,
            type: "group",
            children: [],
            parent_id,
            activeOperationProp,
        }
        ObjToUpdate[id] = new_children

        if (id != "root") {
            ObjToUpdate[parent_id].children.push(id)
        }
    }

    const deleteChildren = (id) => {
        let child = []
        setDataKeyGroupChildren((children) => {
            const cloned_children = { ...children }
            child = [...cloned_children[id].children]
            delete cloned_children[id]
            return cloned_children
        })
        // need to delete all of children of the group
        child.forEach((id) => deleteChildren(id))
    }

    // console.log({ dataKeyGroupChildren })

    const removeGroup = (item_id) => {
        // delete all of its children and do it recursive
        const children = dataKeyGroupChildren[item_id].children
        children.forEach((child_id) => {
            deleteChildren(child_id)
        })
        setDataKeyGroupChildren((current_group_children) => {
            const cloned_children = { ...current_group_children }

            // delete item_id from its parent's children array
            const parent_id = cloned_children[item_id].parent_id
            cloned_children[parent_id].children.splice(cloned_children[parent_id].children.indexOf(item_id), 1)

            // delete item from obj
            delete cloned_children[item_id]

            return cloned_children
        })
    }

    const handleDataKeyFieldsChanges = (e, id) => {
        setDataKeyGroupChildren((old_group_children) => {
            const cloned_children = { ...old_group_children }
            if (e.target.name == "first_select" && e.target.value != "Age") {
                // other field value should be empty string
                cloned_children[id].fields["third_input"] = ""
            } else if (e.target.name == "first_select" && e.target.value == "Age") {
                // age field value should be number
                cloned_children[id].fields["third_input"] = 0
            }
            cloned_children[id].fields[e.target.name] = e.target.value
            return cloned_children
        })
    }
    const handleActiveOperationTypeChange = (new_type, item_id) => {
        setDataKeyGroupChildren((old_group_children) => {
            const cloned_children = { ...old_group_children }
            cloned_children[item_id].activeOperationProp = new_type
            return cloned_children
        })
    }

    const addTargets = (target, item) => {
        const new_target = []
        new_target.push(item.activeOperationProp.value)
        item.children.forEach((child_id) => {
            const child = dataKeyGroupChildren[child_id]
            if (child.type == "rule") {
                const { fields } = child
                new_target.push({ [fields.first_select]: { [fields.second_select]: fields.third_input } })
            }
            if (child.type == "group") {
                addTargets(new_target, child)
            }
        })
        target.push(new_target)
    }

    const handleGenerateDataKey = () => {
        const target = []
        // pushing 'AND' or 'ANY'
        target.push(dataKeyGroupChildren.root.activeOperationProp.value)

        // generating the desired data
        // like this
        // ["OR",{Age: {"=":0}},["AND",{Age: {"!=":100}}]]

        dataKeyGroupChildren.root.children.forEach((child_id) => {
            const child = dataKeyGroupChildren[child_id]
            if (child.type == "rule") {
                const { fields } = child
                if (fields.first_select == "Age") {
                    // if age field, then value will be converted to number from string.
                    target.push({ [fields.first_select]: { [fields.second_select]: Number(fields.third_input) } })
                } else {
                    target.push({ [fields.first_select]: { [fields.second_select]: fields.third_input } })
                }
            }
            if (child.type == "group") {
                addTargets(target, child)
            }
        })
        // console.log({ target })
        // adding target on step2StatesRef
        step2StatesRef.current = {
            ...step2StatesRef.current,
            data_key_target: { name: "", target: JSON.stringify(target) },
        }

        // close the modal
        onClose()
        return target
    }
    const [deleteTemplateId, setDeleteTemplateId] = useState(null)

    const deleteSavedTemplate = (id) => {
        setDeleteTemplateId(id)
        // reset
        if (activeTemplate?.id == id) {
            setDataKeyGroupChildren({ ...initialDataKeyGroupChildren })
            handleAddRuleClick("root")
        }
        deleteDataKeyTemplate(id)
            .then((res) => {
                showAppToast(res.data.message, { variant: "success" })
                setDeleteTemplateId(null)
            })
            .catch((err) => {
                err?.response?.data?.message && showAppToast(err?.response?.data?.message, { variant: "error" })
                err?.message && showAppToast(err?.message, { variant: "error" })
            })
    }

    const recursiveIteration = (template, temp_dataKeyGroupChildren, parent_id, level) => {
        let tmp_parent_id = parent_id
        template.forEach((item, i) => {
            if (item == "OR" || item == "AND") {
                if (level == 0 && !template["root"]) {
                    handleAddDataKeyClickWithProps(
                        temp_dataKeyGroupChildren,
                        tmp_parent_id,
                        {
                            value: item,
                            checked: false,
                            label: item == "OR" ? "Any" : "All",
                            type: item == "OR" ? "Any" : "All",
                        },
                        "root"
                    )
                } else {
                    let new_id = uuid()
                    handleAddDataKeyClickWithProps(
                        temp_dataKeyGroupChildren,
                        tmp_parent_id,
                        {
                            value: item,
                            checked: false,
                            label: item == "OR" ? "Any" : "All",
                            type: item == "OR" ? "Any" : "All",
                        },
                        new_id
                    )
                    tmp_parent_id = new_id
                }
            } else if (Array.isArray(item)) {
                recursiveIteration(item, temp_dataKeyGroupChildren, tmp_parent_id, level + 1)
            } else {
                handleAddRuleClickWithProps(
                    temp_dataKeyGroupChildren,
                    tmp_parent_id,
                    {
                        first_select: Object.keys(item)[0],
                        second_select: Object.keys(Object.values(item)[0])[0],
                        third_input: Object.values(Object.values(item)[0])[0],
                    },
                    i
                )
            }
        })
    }

    // effect starts from here

    useEffect(() => {
        // add initial children for selection
        if (showModal) {
            handleAddRuleClick("root")
        }
        // reset state when modal is closed
        if (!showModal) {
            setDataKeyGroupChildren(initialDataKeyGroupChildren)
        }
        return () => {
            // reset state component unmounts
            setDataKeyGroupChildren(initialDataKeyGroupChildren)
        }
    }, [showModal])

    // useEffect(() => {
    //     if (activeTemplate) {
    //         let temp_dataKeyGroupChildren = {}
    //         recursiveIteration(activeTemplate.target_val, temp_dataKeyGroupChildren, "root", 0)
    //         // console.log({ temp_dataKeyGroupChildren, activeTemplate })
    //         setDataKeyGroupChildren({ ...temp_dataKeyGroupChildren })
    //     }
    // }, [activeTemplate])

    useEffect(() => {
        if (!step2StatesRef.current?.data_key_target) return
        setActiveTemplate({...step2StatesRef.current?.data_key_target, target_val:JSON.parse(step2StatesRef.current?.data_key_target?.target) })
        let temp_dataKeyGroupChildren = {}
        recursiveIteration(
            JSON.parse(step2StatesRef.current?.data_key_target?.target),
            temp_dataKeyGroupChildren,
            "root",
            0
        )
        setDataKeyGroupChildren(temp_dataKeyGroupChildren)
        console.log({temp_dataKeyGroupChildren})
    }, [step2StatesRef.current?.data_key_target])

    return (
        <Modal
            open={showModal}
            onClose={onClose}
            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
            <Box className="targeting-section-modal">
                <div className="flex justify-between" style={{ padding: "10px 20px" }}>
                    <h3>Manage Data Key Rules</h3>
                    <Box className="flex">
                        <Button onClick={(e) => setTemplatePopoverAnchor(e.currentTarget)}>
                            <span>
                                <ChevronLeftRounded
                                    style={{
                                        ...(!templatePopoverAnchor
                                            ? { transform: "rotate(270deg)" }
                                            : { transform: "rotate(90deg)" }),
                                    }}
                                />
                            </span>{" "}
                            Templates
                        </Button>
                        <Popover
                            open={!!templatePopoverAnchor}
                            anchorEl={templatePopoverAnchor}
                            onClose={() => setTemplatePopoverAnchor(null)}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}
                        >
                            <Box className="flex flex-col pd-20 align-center gap-10">
                                {dataKeyTemplates?.length == 0 && (
                                    <>
                                        <Typography variant="h4" style={{ fontSize: "20px" }}>
                                            No Templates
                                        </Typography>
                                        <Typography>There are no templates yet.</Typography>
                                        <Typography>You can save your current rules as a template.</Typography>
                                    </>
                                )}
                                {dataKeyTemplates?.map((template) => {
                                    return (
                                        <Box
                                            key={template.id}
                                            className="flex flex-row justify-between"
                                            style={{ width: "100%" }}
                                        >
                                            <Button
                                                color="primary"
                                                onClick={() => setActiveTemplate(template)}
                                                style={{ flex: 1, justifyContent: "left" }}
                                            >
                                                <Typography variant="button">{template.name}</Typography>
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    if (isDeletingDataKeyTemplate) return
                                                    deleteSavedTemplate(template.id)
                                                }}
                                            >
                                                {/* only show loader for the button which is clicked. Doing id match  */}
                                                {isDeletingDataKeyTemplate && template.id == deleteTemplateId && (
                                                    <LoaderCircle
                                                        style={{ position: "unset", width: "16px", height: "16px" }}
                                                    />
                                                )}
                                                {/* showing the delete icon if not clicked and other on buttons */}
                                                {template.id != deleteTemplateId && <DeleteOutlined />}
                                            </Button>
                                        </Box>
                                    )
                                })}

                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setShowCreateTemplateModal(true)}
                                >
                                    <Add style={{ marginRight: "10px" }} /> Save current rules to new template{" "}
                                </Button>
                            </Box>
                            <Modal
                                open={showCreateTemplateModal}
                                onClose={() => setShowCreateTemplateModal(false)}
                                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                            >
                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault()
                                        if (isUpdatingDataKeyTemplate) return
                                        // if (!template_name.value) return

                                        const new_template = {
                                            name: "template_name.value",
                                            target: handleGenerateDataKey(),
                                        }
                                        updateDataKeyTemplate(new_template)
                                            .then((res) => {
                                                setShowCreateTemplateModal(false)
                                                showAppToast(res.data.message, { variant: "success" })
                                            })
                                            .catch((err) => {
                                                showAppToast(err.data.message, { variant: "error" })
                                            })
                                    }}
                                >
                                    <Box
                                        className="flex flex-col"
                                        style={{
                                            padding: "10px 20px",
                                            background: "white",
                                            width: "500px",
                                            height: "300px",
                                        }}
                                    >
                                        <Box
                                            className="flex align-center justify-between"
                                            style={{ marginBottom: "30px" }}
                                        >
                                            <Typography
                                                variant="h4"
                                                className="flex gap-10"
                                                style={{ fontSize: "20px" }}
                                            >
                                                <AddBox />
                                                Create Template
                                            </Typography>
                                            <Button
                                                className="close-button"
                                                onClick={() => setShowCreateTemplateModal(false)}
                                            >
                                                <Close />
                                            </Button>
                                        </Box>
                                        <TextField
                                            placeholder="Type in "
                                            label="Template Name"
                                            style={{ width: "100%" }}
                                            id="template_name"
                                        />
                                        <Box className="flex " style={{ marginTop: "auto", justifyContent: "end" }}>
                                            <Button variant="contained" color="primary" type="submit">
                                                {isUpdatingDataKeyTemplate && (
                                                    <>
                                                        <LoaderCircle
                                                            style={{
                                                                position: "unset",
                                                                width: "16px",
                                                                height: "16px",
                                                                color: "white",
                                                                marginRight: "10px",
                                                            }}
                                                        />
                                                        <span>Creating</span>
                                                    </>
                                                )}
                                                {!isUpdatingDataKeyTemplate && (
                                                    <>
                                                        <Check style={{ marginRight: "10px" }} />
                                                        <span>Create</span>
                                                    </>
                                                )}
                                            </Button>
                                        </Box>
                                    </Box>
                                </form>
                            </Modal>
                        </Popover>
                        <Button className="close-button" onClick={onClose}>
                            <Close />
                        </Button>
                    </Box>
                </div>
                <div className="modal-main-content" style={{ padding: "10px" }}>
                    <dataKeyGroupChildren.root.element
                        children={dataKeyGroupChildren.root.children}
                        handleAddRuleClick={handleAddRuleClick}
                        handleAddDataKeyClick={handleAddDataKeyClick}
                        dataKeyGroupChildren={dataKeyGroupChildren}
                        item_id={"root"}
                        removeGroup={removeGroup}
                        nesting_level={1}
                        handleDataKeyFieldsChanges={handleDataKeyFieldsChanges}
                        activeOperationProp={dataKeyGroupChildren.root.activeOperationProp}
                        handleActiveOperationTypeChange={handleActiveOperationTypeChange}
                    />
                </div>
                <div className="flex sticky-action">
                    <Box flex={"1"}>
                        <Button
                            onClick={() => {
                                resetDataKeyGroupChildren()
                                setActiveTemplate(null)
                            }}
                        >
                            <ClearRounded />
                            Clear Rules
                        </Button>
                    </Box>
                    <Button className="close-button" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button color="primary" variant="contained" onClick={handleGenerateDataKey}>
                        Save Changes
                    </Button>
                </div>
            </Box>
        </Modal>
    )
}

function DataKeyGroup({
    children,
    dataKeyGroupChildren,
    handleAddRuleClick,
    handleAddDataKeyClick,
    item_id,
    removeGroup,
    nesting_level,
    handleDataKeyFieldsChanges,
    activeOperationProp,
    handleActiveOperationTypeChange,
}) {
    console.log("banger", dataKeyGroupChildren)
    const OperationTypes = cloneDeep({
        Any: {
            value: "OR",
            checked: false,
            label: "Any",
            type: "Any",
        },
        All: {
            value: "AND",
            checked: false,
            label: "All",
            type: "All",
        },
    })
    const [popoverAnchor, setPopoverAnchor] = useState(null)

    return (
        <Box
            style={{
                paddingBlock: "15px",
                paddingInline: "10px",
                borderRadius: "10px",
                background: item_id != "root" && "#f3f3f3",
            }}
        >
            <Box className="flex flex-row justify-between">
                <Box className="status">
                    {Object.keys(OperationTypes).map((type) => {
                        return (
                            <ButtonBase
                                key={type}
                                onClick={() => handleActiveOperationTypeChange(OperationTypes[type], item_id)}
                                className={clsx(
                                    type == activeOperationProp.type && "active-operation",
                                    "base-button-adder"
                                )}
                            >
                                {type}
                            </ButtonBase>
                        )
                    })}
                    <span> of the following are true</span>
                </Box>
                <Box>
                    {nesting_level <= NESTING_LEVEL_LIMIT && (
                        <>
                            <Button
                                onClick={(e) => {
                                    setPopoverAnchor(e.currentTarget)
                                }}
                            >
                                <Add /> Add
                            </Button>
                            <Popover
                                open={!!popoverAnchor}
                                anchorEl={popoverAnchor}
                                onClose={() => setPopoverAnchor(false)}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                }}
                            >
                                <Box className="flex flex-col pd-10">
                                    <Button onClick={() => handleAddRuleClick(item_id)}>
                                        <Add /> Rule
                                    </Button>
                                    <Button onClick={() => handleAddDataKeyClick(item_id)}>
                                        <Add /> Group
                                    </Button>
                                </Box>
                            </Popover>
                        </>
                    )}
                    {nesting_level > NESTING_LEVEL_LIMIT && (
                        <Button onClick={() => handleAddRuleClick(item_id)}>
                            <Add />
                            Add Rule
                        </Button>
                    )}

                    {item_id != "root" && (
                        <Button
                            onClick={(e) => {
                                removeGroup(item_id)
                            }}
                        >
                            <DeleteOutlined color="error" />
                            Remove
                        </Button>
                    )}
                </Box>
            </Box>

            <Box className="flex flex-col gap-15 pd-left-30 mt-10-imp">
                {children.map((child_id) => {
                    const {
                        element: Element,
                        children: c_children,
                        type,
                        fields,
                        activeOperationProp,
                    } = dataKeyGroupChildren[child_id]
                    return (
                        <Element
                            key={child_id}
                            dataKeyGroupChildren={dataKeyGroupChildren}
                            children={c_children}
                            handleAddDataKeyClick={handleAddDataKeyClick}
                            handleAddRuleClick={handleAddRuleClick}
                            item_id={child_id}
                            removeGroup={removeGroup}
                            nesting_level={type == "group" ? nesting_level + 1 : nesting_level}
                            fields={fields}
                            handleDataKeyFieldsChanges={handleDataKeyFieldsChanges}
                            activeOperationProp={activeOperationProp}
                            handleActiveOperationTypeChange={handleActiveOperationTypeChange}
                        />
                    )
                })}
            </Box>
        </Box>
    )
}

function DataRule({ removeGroup, item_id, fields, handleDataKeyFieldsChanges }) {
    const second_select_options = cloneDeep({
        Age: {
            options: ["=", "!=", "<", "<=", ">", ">="],
        },
        Audience_Test_Sam: {
            options: ["=", "!=", "starts_with", "ends_with", "contains", "does not contain"],
        },
        hindsight_category: {
            options: ["=", "!=", "starts_with", "ends_with", "contains", "does not contain"],
        },
    })

    return (
        <Box
            className="flex flex-row gap-10 data-rule"
            style={{ background: "#fff", padding: "10px", borderRadius: "5px" }}
        >
            <Select
                style={{ width: "25%" }}
                value={fields.first_select}
                defaultValue={fields.first_select}
                name={"first_select"}
                onChange={(e) => handleDataKeyFieldsChanges(e, item_id)}
            >
                <option value="Age">Age</option>
                <option value="Audience_Test_Sam">Audience_Test_Sam</option>
                <option value="hindsight_category">hindsight_category </option>
            </Select>
            <Select
                style={{ width: "25%" }}
                value={fields.second_select}
                defaultValue={fields.second_select}
                name={"second_select"}
                onChange={(e) => handleDataKeyFieldsChanges(e, item_id)}
            >
                {second_select_options[fields.first_select].options.map((opt) => {
                    return (
                        <option value={opt} key={opt}>
                            {opt}
                        </option>
                    )
                })}
            </Select>
            <Input
                placeholder="Type in..."
                value={fields.third_input}
                name={"third_input"}
                onChange={(e) => {
                    e.persist()
                    handleDataKeyFieldsChanges(e, item_id)
                }}
            />
            <DeleteOutlined className="delete-btn" onClick={() => removeGroup(item_id)} />
        </Box>
    )
}
