import React, { useState, useEffect, useRef, createRef, useMemo } from 'react';
import { makeStyles, fade, withTheme, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { useForm, Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Highcharts from "highcharts/highstock";
import PieChart from "highcharts-react-official";
//Text field
import TextField from '@material-ui/core/TextField';

// Select

//Textarea
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

//Icon
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

//Button
import Button from '@material-ui/core/Button'

// Stepper
import StepConnector from '@material-ui/core/StepConnector';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import clsx from 'clsx';

//Add Persona
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom';
import MainLayout from '../../layouts/newMainLayout'
import StepWrapper from './stepWrapper'
import BrandStandings from '../../components/BrandStandings';
import { getForecast } from '../../redux/actions/media-planner';
import PersonaCard from '../../components/PersonaCard';
import { Radio, RadioGroup } from '@material-ui/core';

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundColor: '#0e82f4'
        // backgroundImage:'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        // boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundColor: '#0e82f4'
        // backgroundImage:
        // 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    },
});


const useStyles = makeStyles((theme) => ({
    dflex: {
        display: 'flex'
    },
    progressBar: {
        width: '100%',
        height: '15px',
        //marginLeft:'50px',
        borderRadius: '10px',
    },
    awarenessProgress: {
        borderRadius: '10px',
        height: '15px',
        backgroundColor: '#9E9D9D',
    },
    considerationProgress: {
        borderRadius: '10px',
        width: '29%',
        height: '15px',
        backgroundColor: '#369DC6',
    },
    preferenceProgress: {
        borderRadius: '10px',
        width: '29%',
        height: '15px',
        backgroundColor: '#F06D1D',
    },
    activeIntentProgress: {
        borderRadius: '10px',
        width: '29%',
        height: '15px',
        backgroundColor: '#F6B105',
    },
    center_Padding_8px: {
        alignSelf: 'center',
        paddingRight: '8px'
    },
    stepperWrapper: {
        paddingLeft: 0,
        paddingRight: 0,
        background: 'none'
    },
    root: {
        flexGrow: 1,
        padding: '60px'
    },
    pageTitle: {
        color: '#e96941',
        fontWeight: 'normal'
    },
    formTitle: {
        color: "#1d8bf7",
        fontWeight: 'normal'
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    textarea: {
        width: '100%',
        backgroundColor: '#f4f4f8',
        marginTop: '8px',
        borderRadius: '5px',
        padding: '14px'
    },
    marginBottom_10px: {
        marginBottom: '10px'
    },
    customfield: {
        paddingTop: '0',
        marginTop: '0',
        backgroundColor: '#f4f4f8',
        borderRadius: '5px',
        '& .MuiInputLabel-root.Mui-shrink': {
            color: 'red'
        },
        '&:hover': {
            backgroundColor: '#fff',
        },
        '&$focused': {
            backgroundColor: '#fff',
            boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            borderColor: theme.palette.primary.main,
        },
        '& .MuiOutlinedInput-input': {
            padding: '8.5px 14px',
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '5px',

            '& fieldset': {
                borderColor: 'grey',
            },
            '&:hover fieldset': {
            },
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: 'grey'
        },
        "& .MuiInputLabel-root.Mui-focused": {
            color: 'grey'
        },
        "& .MuiInputLabel-root.MuiInputLabel-shrink": {
            margin: '0!important'
        },
        "& .MuiInputLabel-root.MuiInputLabel-animated": {
            marginTop: '-8px'
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: 'grey'
        }
    },
    selectImgBtn: {
        marginTop: '10px',
        display: 'block',
        margin: '0 auto',
        color: '#ba54f5',
        border: '1px solid #ba54f5',
        padding: '5px 30px'
    },
    nextButton: {
        marginLeft: 'auto',
        backgroundColor: "#1d8bf7",
        color: 'white',
        display: 'block',
        padding: '5px 40px'
    },
    nextPrevious: {
        marginRight: 'auto',
        backgroundColor: "#2d9bf6",
        color: 'white',
        display: 'block',
        padding: '5px 40px'
    },
    radioControl: {
    },
    marginTop: {
        marginTop: "10px"
    },
    checkNRadio: {
        '& .MuiFormLabel-root': {
            color: '#1d8bf7',
            marginBottom: '8px'
        },
    },
    boxShadow: {
        position: 'relative',
        padding: '40px 100px 0',
        boxShadow: '0px 0px 10px rgba(0,0,0,0.10), 0 10px 10px rgba(0,0,0,0.12)',
        borderRadius: '10px',
        backgroundColor: 'white',
        marginRight: '10px',
        marginLeft: '10px'

    },


}));

const inSightCopy = {
    "data": {
        "age": [
            {
                "y": 20,
                "name": "16 (20 %)",
                "color": "#dcf293"
            },
            {
                "y": 6,
                "name": "17_20 (80 %)",
                "color": "#e234da"
            },
            {
                "y": 80,
                "name": "17_20 (80 %)",
                "color": "#e234da"
            },
            {
                "y": 80,
                "name": "17_20 (80 %)",
                "color": "#e234da"
            },
            {
                "y": 80,
                "name": "17_20 (80 %)",
                "color": "#e234da"
            }
        ],
        "gender": [
            {
                "y": 15,
                "name": "male (15 %)",
                "color": "#193793"
            },
            {
                "y": 85,
                "name": "female (85 %)",
                "color": "#899115"
            }
        ],
        "demographic": [
            {
                "y": 35,
                "name": "asian (35 %)",
                "color": "#c5b6f9"
            },
            {
                "y": 65,
                "name": "black (65 %)",
                "color": "#55e892"
            }
        ],
        "hobbiesInterest": [
            {
                "y": 83,
                "name": "Television (83 %)",
                "color": "#8de8c2"
            },
            {
                "y": 17,
                "name": "Radio (17 %)",
                "color": "#bc0801"
            }
        ]
    }
}

const Forecast = () => {
    const { register, handleSubmit, errors, control } = useForm({ mode: 'onSubmit', reValidateMode: 'onSubmit' })

    const dispatch = useDispatch()
    const history = useHistory();
    const classes = useStyles();
    const [chartType, setChartType] = useState('areaspline');
    const savedForecast = useSelector((state) => state.forecast);
    const currentUser = useSelector((state) => state.loggedInSession);
    const personas = useSelector((state) => state.personas);
    const mediaPlanner = useSelector((state) => state.mediaPlanner);


    useEffect(() => {
        console.info('savedForecast: ', savedForecast)
        if (!savedForecast) {
            dispatch(getForecast());
        }

    }, [savedForecast])

    if (!savedForecast) return null;
    const { standings } = savedForecast;
    const { personasLinked, totalSpend } = mediaPlanner;

    const activePersonas = personas.filter(item => {
        const { _id } = item
        return personasLinked.includes(_id)
    });

    console.log('Active Personas', activePersonas)
    console.log('mediaPlanner', mediaPlanner)

    const options = {
        chart: {
            type: chartType,
            spacingBottom: 0,
            spacingTop: 0,
            spacingLeft: 0,
            spacingRight: 0,
            width: null,
            height: 200
        },
        title: {
            text: '',
            align: 'center',
            //y: 340 //  this to move y-coordinate of title to desired location
        },
        series: [
            {
                data: savedForecast && savedForecast.age
            }
        ],
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false,
                }
            }
        },
    };
    const gender = {
        chart: {
            type: chartType,
            spacingBottom: 0,
            spacingTop: 0,
            spacingLeft: 0,
            spacingRight: 0,
            width: null,
            height: 200
        },
        title: {
            text: '',
            align: 'center',
        },
        series: [
            {
                data: savedForecast && savedForecast.gender
            }
        ],
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false,
                }
            }
        },
    };

    const handleBook=(e)=>{

        history.push('/media-planner/inventory')
    }

    const handlePrevious=(e)=>{

        history.push('/media-planner')
    }

    return (
        <div>
            <StepWrapper step={3} />
            <Grid container spacing={3}>
                <Grid item xs={12}>

                    <Paper style={{ padding: '20px' }} >
                        <Grid container spacing={3}>
                            <Grid className={classes.pageTitle} item md={8} sm={8} xs={12}>
                                Forcasted Brand Uplift Based on Persona Settings
                         </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item md={3} sm={6} xs={6} className={classes.boxShadow}>
                                <div style={{ fontSize: '2em', color: 'black' }}>+18.1%</div>
                                <div style={{ fontSize: '1em', color: 'black' }}>Brand Lift Total</div>
                                <div style={{ fontSize: '0.8em', color: 'black' }}>All matrics summarised</div>
                                <PieChart highcharts={Highcharts} options={options} />
                            </Grid>
                            <Grid item md={3} sm={6} xs={6} className={classes.boxShadow}>
                                <div style={{ fontSize: '2em', color: 'black' }}>+18.1%</div>
                                <div style={{ fontSize: '1em', color: 'black' }}>Total Brand Lift Index Vs DATABASE</div>
                                <div style={{ fontSize: '0.8em', color: 'black' }}>Total Brandlift compare to Benchmark</div>
                                <PieChart highcharts={Highcharts} options={gender} />
                            </Grid>
                            <Grid item md={3} sm={3} xs={3} className={classes.boxShadow}>
                                <PersonaCard personasList={activePersonas} />
                            </Grid>
                        </Grid>
                        <Grid style={{ textAlign: 'center' }} item md={8} sm={8} xs={12}>
                            <BrandStandings data={standings} />
                        </Grid>
                        <Grid  item md={8} sm={8} xs={12}>
                            
                         <TextField
                                value={totalSpend}
                                className={classes.customfield}
                              //  onChange={(e) => setLocation(e.target.value)}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="total"
                                label="Total Spend"
                                name="toataSpend"
                                // inputRef={register({ required: true })}
                              //  error={errors.name?.type === 'required'}
                            />
                        </Grid>


                        <Button className={classes.nextButton} onClick={(e)=>handleBook(e)}>Book</Button>
                        <Button className={classes.nextPrevious} onClick={(e)=>handlePrevious(e)}>Previous</Button>

                    </Paper>

                </Grid>
            </Grid>
        </div>

    );
}

export default MainLayout(Forecast)