import { Button, CircularProgress, Select } from "@material-ui/core"
import Backdrop from "@material-ui/core/Backdrop"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogTitle from "@material-ui/core/DialogTitle"
import Fade from "@material-ui/core/Fade"
import MenuItem from "@material-ui/core/MenuItem"
import Modal from "@material-ui/core/Modal"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import Pagination from "@material-ui/lab/Pagination"
import axios from "axios"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import NewAlert from "../../components/NewAlert"
import useStyles from "../../components/StyleClasses"
import { API, SECRET_KEY } from "../../constants/index"
import MainLayout from "../../layouts/adminMainLayout"
import * as actions from "../../redux/actions/user"
import CustomizedTables from "../mediaOwner/mytable"

const useStylesModal = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: "10px",
        width: "580px",
    },
}))

function Keywords() {
    const classes = useStyles()
    const classesModal = useStylesModal()
    const history = useHistory()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)

    const [tableData, setTableData] = useState([])
    const [loading, setLoading] = useState(false)
    const [page, setpage] = useState(1)
    const access_token = window.localStorage.getItem(SECRET_KEY)
    const [pagecount, setPageCount] = useState(0)
    const [deleteFlag, setDeleteFlag] = useState(false)
    const [deleteId, setDeleteId] = useState("")
    const [file, setFile] = useState(false)
    const fileInputRef = useRef()
    const [ErrSucMsg, setErrSucMesg] = useState("")
    const [ErrSucType, setErrSucType] = useState(true)
    const [modalOpen, setModalOpen] = useState(false)
    const [editModalOpen, setEditModalOpen] = useState(false)
    const [companyList, setCompanyList] = useState([])
    const [campaignCompany, setCampaignCompany] = useState("")
    const [campaignName, setCampaignName] = useState("")
    const [campaignGreenTagId, setCampaignGreenTagId] = useState("")
    const [campaignList, setCampaignList] = useState([])
    const [campaignId, setCampaignId] = useState("")

    const DropDowns = useSelector((state) => state.personaDropDownData)
    const dispatch = useDispatch()
    const [keywordList, setKeywordList] = useState([])
    const [keywordName, setKeywordName] = useState("")
    const [keywordReason, setKeywordReason] = useState("");
    const [sentenceUsage, setSentenceUsage] = useState("")
    const [keywordDemographic, setKeywordDemographic] = useState("")
    const [keywordCountry, setKeywordCountry] = useState("")
    const [keyowrdPriority, setKeywordPriority] = useState(0)

    const getDropDown = () => {
        axios
            .get(`${API}/admin/persona/getAddPersonaData`, {
                headers: { Authorization: `Bearer ${access_token}` },
            })
            .then((res, err) => {
                if (res) {
                    dispatch(actions.personaDropDownData(res.data))
                }
                if (err) {
                }
            })
            .catch(function (error) {
                if (error) {
                }
            })
    }

    useEffect(() => {
        const edit_id = queryParams.get("edit_id")
        if (edit_id && keywordList.length > 0) {
            const keywordData = keywordList.find((item) => item._id === edit_id)
            console.log(keywordData, "keywordData")
            setKeywordName(keywordData.keyword)
            setKeywordReason(keywordData.reason)
            setSentenceUsage(keywordData.sentence_usage)
            setKeywordDemographic(keywordData.demographic?._id)
            setKeywordCountry(keywordData.country._id)
            setKeywordPriority(keywordData.priority)
            setEditModalOpen(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search])

    const handleModalClose = () => {
        setKeywordName("")
        setKeywordReason("")
        setSentenceUsage("")
        setKeywordDemographic("")
        setKeywordCountry("")
        setKeywordPriority(0)
        setModalOpen(false)
    }

    const handleEditModalClose = () => {
        setKeywordName("")
        setKeywordReason("")
        setSentenceUsage("")
        setKeywordDemographic("")
        setKeywordCountry("")
        setKeywordPriority(0)
        setEditModalOpen(false)
        history.replace({
            pathname: window.location.pathname,
            search: "",
        })
    }

    const getKeywords = () => {
        setLoading(true)
        const access_token = window.localStorage.getItem(SECRET_KEY)
        axios
            .get(`${API}/admin/keywords/`, {
                headers: { Authorization: `Bearer ${access_token}` },
            })
            .then((res) => {
                console.log(res.data.data)
                // setCampaignList(res.data.campaigns)
                setKeywordList(res.data.data)
                let td = res.data.data?.map((item) => {
                    // return [item.name, item.greenTagId, item.company.companyName]
                    return {
                        data: [item.keyword, item.reason, item.sentence_usage, item.priority, item.demographic?.name, item.country.name],
                        _id: item._id,
                    }
                })
                console.log(td, "td")
                setTableData(td)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setLoading(false)
            })
    }

    const createKeyword = () => {
        const access_token = window.localStorage.getItem(SECRET_KEY)
        axios
            .post(
                `${API}/admin/keywords/create`,
                {
                    keyword: keywordName,
                    reason: keywordReason,
                    sentence_usage: sentenceUsage,
                    demographic: keywordDemographic,
                    country: keywordCountry,
                    priority: keyowrdPriority,
                },
                {
                    headers: { Authorization: `Bearer ${access_token}` },
                }
            )
            .then((res) => {
                console.log(res)
                getKeywords()
                handleModalClose()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const editKeyword = () => {
        const access_token = window.localStorage.getItem(SECRET_KEY)
        axios
            .put(
                `${API}/admin/keywords/${queryParams.get("edit_id")}`,
                {
                    keyword: keywordName,
                    reason: keywordReason,
                    sentence_usage: sentenceUsage,
                    demographic: keywordDemographic,
                    country: keywordCountry,
                    priority: keyowrdPriority,
                },
                {
                    headers: { Authorization: `Bearer ${access_token}` },
                }
            )
            .then((res) => {
                console.log(res)
                getKeywords()
                handleEditModalClose()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const deleteKeyword = () => {
        const id = deleteId
        setDeleteId("")
        const access_token = window.localStorage.getItem(SECRET_KEY)
        axios
            .post(
                `${API}/admin/keywords/delete/${id}`,
                {},
                {
                    headers: { Authorization: `Bearer ${access_token}` },
                }
            )
            .then((res) => {
                console.log(res)
                getKeywords()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        getKeywords()
        getDropDown()
    }, [])

    return (
        <div>
            <NewAlert msg={ErrSucMsg} isSuccess={ErrSucType} close={() => setErrSucMesg("")} />
            {deleteFlag && (
                <Dialog
                    open={deleteFlag}
                    onClose={() => {
                        setDeleteFlag(false)
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Are you sure, you want to delete the Keyword?</DialogTitle>
                    <DialogActions>
                        <Button
                            style={{ color: "black", backgroundColor: "grey" }}
                            onClick={() => {
                                setDeleteFlag(false)
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            style={{ backgroundColor: "#4F74C9", color: "white" }}
                            onClick={() => {
                                setDeleteFlag(false)
                                deleteKeyword()
                            }}
                            autoFocus
                        >
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            <h2 style={{ color: "#4F74C9", fontWeight: "700" }}>Keywords</h2>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                    onClick={() => setModalOpen(true)}
                    className={classes.addActionBtn}
                    variant="outlined"
                    color="primary"
                    style={{ width: "20%", borderRadius: "50px" }}
                >
                    <AddCircleOutlineIcon style={{ marginRight: "5px", color: "white" }} />
                    Add Keyword
                </Button>
            </div>
            <div className={classes.boxShadow} style={{ marginTop: "40px" }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "20px",
                    }}
                >
                    <span
                        style={{
                            color: "#FB6D6A",
                            fontWeight: "600",
                            margin: "0",
                            fontSize: "20px",
                            marginLeft: "20px",
                        }}
                    >
                        Keywords List
                    </span>
                    <span
                        style={{
                            color: "#FB6D6A",
                            fontWeight: "600",
                            margin: "0",
                            fontSize: "20px",
                            marginRight: "20px",
                        }}
                    >
                        <Pagination
                            count={Math.ceil(pagecount / 10)}
                            color="primary"
                            onChange={(e, page) => setpage(page)}
                            size="small"
                        />
                    </span>
                </div>
                <CustomizedTables
                    // radioList={["Free", "Paid"]}
                    isEditable={true}
                    headings={["Keyword Name", "Reason", "Sentence Usage", "Priority", "Demographic", "Country"]}
                    listdata={tableData}
                    redirectLink={"keywords?edit_id="}
                    handleDelete={(_id) => {
                        setDeleteFlag(true)
                        setDeleteId(_id)
                    }}
                />
                <div style={{ display: "flex", justifyContent: "center" }}>{loading && <CircularProgress />}</div>
            </div>

            {/* Create Campaign */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classesModal.modal}
                open={modalOpen}
                onClose={handleModalClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={modalOpen}>
                    <div className={classesModal.paper}>
                        <h2 className="campaign_modal_title">Create Keyword</h2>
                        <div className="campaign-modal-input-cont">
                            <TextField
                                id="campaign_title"
                                label="Keyword"
                                variant="outlined"
                                value={keywordName}
                                onChange={(e) => setKeywordName(e.target.value)}
                            />
                            <TextField
                                id="keyword_reason"
                                label="Reason"
                                variant="outlined"
                                value={keywordReason}
                                onChange={(e) => setKeywordReason(e.target.value)}
                            />
                            <TextField
                                id="campaign_campaign_id"
                                label="Sentence Usage"
                                variant="outlined"
                                multiline
                                rows={4}
                                value={sentenceUsage}
                                onChange={(e) => setSentenceUsage(e.target.value)}
                            />
                            <label htmlFor="outlined-select-demographic">Priority</label>
                            <Select
                                labelId="outlined-select-company"
                                id="outlined-select-priority"
                                label="Demographic"
                                onChange={(e) => setKeywordPriority(e.target.value)}
                                value={keyowrdPriority}
                                helperText="Please select the Demographic for this keyword"
                                variant="standard"
                            >
                              <MenuItem value={1}>
                                  Priority 1
                              </MenuItem>
                              <MenuItem value={2}>
                                  Priority 2
                              </MenuItem>
                            </Select>
                            <label htmlFor="outlined-select-demographic">Demographic</label>
                            <Select
                                labelId="outlined-select-company"
                                id="outlined-select-demographic"
                                label="Demographic"
                                onChange={(e) => setKeywordDemographic(e.target.value)}
                                value={keywordDemographic}
                                helperText="Please select the Demographic for this keyword"
                                variant="standard"
                            >
                                {DropDowns?.demographics.map((option) => (
                                    <MenuItem key={option._id} value={option._id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <label htmlFor="outlined-select-country">Country</label>
                            <Select
                                labelId="outlined-select-company"
                                id="outlined-select-country"
                                label="Country"
                                onChange={(e) => setKeywordCountry(e.target.value)}
                                value={keywordCountry}
                                helperText="Please select the Country for this keyword"
                                variant="standard"
                            >
                                {DropDowns?.countries.map((option) => (
                                    <MenuItem key={option._id} value={option._id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <div className="modal-submit-campaign">
                                    <Button
                                        variant="contained"
                                        color="default"
                                        component="span"
                                        className={`${classesModal.button} submit_campaign_btn`}
                                        onClick={() => createKeyword()}
                                    >
                                        Create
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>

            {/* Edit Keyword */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classesModal.modal}
                open={editModalOpen}
                onClose={handleEditModalClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={editModalOpen}>
                    <div className={classesModal.paper}>
                        <h2 className="campaign_modal_title">Edit Keyword</h2>
                        <div className="campaign-modal-input-cont">
                            <TextField
                                id="campaign_title"
                                label="Keyword"
                                variant="outlined"
                                value={keywordName}
                                onChange={(e) => setKeywordName(e.target.value)}
                            />
                            <TextField
                                id="keyword_reason"
                                label="Reason"
                                variant="outlined"
                                value={keywordReason}
                                onChange={(e) => setKeywordReason(e.target.value)}
                            />
                            <TextField
                                id="campaign_campaign_id"
                                label="Sentence Usage"
                                variant="outlined"
                                multiline
                                rows={4}
                                value={sentenceUsage}
                                onChange={(e) => setSentenceUsage(e.target.value)}
                            />
                            <label htmlFor="outlined-select-demographic">Priority</label>
                            <Select
                                labelId="outlined-select-company"
                                id="outlined-select-priority"
                                label="Demographic"
                                onChange={(e) => setKeywordPriority(e.target.value)}
                                value={keyowrdPriority}
                                helperText="Please select the Demographic for this keyword"
                                variant="standard"
                            >
                              <MenuItem value={1}>
                                  Priority 1
                              </MenuItem>
                              <MenuItem value={2}>
                                  Priority 2
                              </MenuItem>
                            </Select>
                            <label htmlFor="outlined-select-demographic">Demographic</label>
                            <Select
                                labelId="outlined-select-company"
                                id="outlined-select-company"
                                label="Demographic"
                                onChange={(e) => setKeywordDemographic(e.target.value)}
                                value={keywordDemographic}
                                helperText="Please select the Demographic for this keyword"
                                variant="standard"
                            >
                                {DropDowns?.demographics.map((option) => (
                                    <MenuItem key={option._id} value={option._id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <label htmlFor="outlined-select-country">Country</label>
                            <Select
                                labelId="outlined-select-company"
                                id="outlined-select-company"
                                label="Country"
                                onChange={(e) => setKeywordCountry(e.target.value)}
                                value={keywordCountry}
                                helperText="Please select the Country for this keyword"
                                variant="standard"
                            >
                                {DropDowns?.countries.map((option) => (
                                    <MenuItem key={option._id} value={option._id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <div className="modal-submit-campaign">
                                    <Button
                                        variant="contained"
                                        color="default"
                                        component="span"
                                        className={`${classesModal.button} submit_campaign_btn`}
                                        onClick={() => editKeyword()}
                                    >
                                        Update
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
            <div style={{ display: "flex", justifyContent: "center" }}>{loading && <CircularProgress />}</div>
        </div>
    )
}

export default MainLayout(Keywords)
