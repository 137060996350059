import FormControl from "@material-ui/core/FormControl";
import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/adminMainLayout";

import CircularProgress from "@material-ui/core/CircularProgress";

//Icon
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Edit from "@material-ui/icons/Edit";
import Launch from "@material-ui/icons/Launch";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { enGB } from "date-fns/locale";
import { DatePickerCalendar } from "react-nice-dates";
import "react-nice-dates/build/style.css";

//TextField
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import NewAlert from "../../components/NewAlert";
import useStyles from "../../components/StyleClasses";
import ShowAlert from "../../components/alerts";
import { API, SECRET_KEY } from "../../constants/index";

import { Backdrop, Fade, Modal } from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CustomizedTables from "../mediaOwner/mytable";

import LeftArrow from "../../assets/tag.png";
import RightArrow from "../../assets/tag2.png";

import Pagination from "@material-ui/lab/Pagination";

import Highcharts, { color } from "highcharts";
import HighchartReact from "highcharts-react-official";
import funnel from "highcharts/modules/funnel.js";
import { ConvertDigital, ConvertOOH, ConvertPrint, ConvertRadio, ConvertTelevision } from "../../constants/Inventories";
funnel(Highcharts);

function paramsToObject(entries) {
  const result = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}

const ManageCampaign = () => {
  const history = useHistory();
  const urlparams = new URLSearchParams(history.location.search);
  const paramEntries = urlparams.entries();
  const params = paramsToObject(paramEntries);
  const classes = useStyles();
  const [mediaPlanerID, setmediaPlanerID] = useState(
    params.planer ? params.planer : null
  );
  const [campaignID, setcampaignID] = useState(null);
  const [mediaPlannerList, setmediaPlannerList] = useState([]);
  const [camapaignList, setcamapaignList] = useState([]);

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const [namesearch, setNamesearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [Spinner, setSpinner] = useState(false);

  const [SelectedName, setSelectedName] = useState("");

  //campaigns
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [launchOpen, setLaunchOpen] = useState(false);
  const [extendOpen, setExtendOpen] = useState(false);
  const [datepickerOpen, setDatepickerOpen] = useState(false);
  const [slectedCamapaign, setSelectedCampaign] = useState(null);
  const [loadingCampaign, setLoadingCamapaign] = useState(false);
  const [newEndDate, setNewEndDate] = useState();
  const [campaignId, setCampaignId] = useState(null);

  ///////errors & successs //////////
  const [ErrSucMsg, setErrSucMesg] = useState("");
  const [ErrSucType, setErrSucType] = useState(true);

  //invs
  const [tableData, setTableData] = useState([]);
  const [tableHeads, setTableHeads] = useState([]);
  const [DATA, setDATA] = useState(null);
  const [InvAll, setInvAll] = useState([]);
  const [InvChannel, setInvChannel] = useState("Digital");
  const [ChanBtns, setChanBtns] = useState([]);
  const [editFormEmail, setEditFormEmail] = useState("");
  const [validatedEmail, setvalidatedEmail] = useState(false);

  const access_token = window.localStorage.getItem(SECRET_KEY);

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  useEffect(() => {
    if (validateEmail(editFormEmail)) {
      setvalidatedEmail(true);
    } else {
      setvalidatedEmail(false);
    }
  }, [editFormEmail]);

  useEffect(() => {
    // if (namesearch == null) return;
    setSpinner(true);

    axios
      .get(`${API}/admin/mediaPlanner?name=${namesearch}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res) {
          setmediaPlannerList(res.data.data);
          if (mediaPlanerID?.length > 10) {
            let moval = res.data.data.find((e) => e._id === mediaPlanerID);
            setSelectedName(moval.name);
          }
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  }, [namesearch, mediaPlanerID]);
  useEffect(() => {
    setSpinner(false);
  }, [mediaPlannerList]);

  const getTabledata = () => {
    if (mediaPlanerID === null) return;
    setLoading(true);
    setcamapaignList([]);
    // let endPoint = `/admin/lucidApi/?creator=${mediaPlanerID}&page=${page}`;
    let endPoint = `/admin/lucidApi/?page=${page}`;
    // let endPoint = `/admin/lucidApi/calculatePercentage/?page=${page}`;

    axios
      .get(`${API}${endPoint}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res) {
          setcamapaignList(res.data.items);
          setPageCount(res.data.total_items);
        }
        setLoading(false);
      })
      .catch(function (error) {
        if (error) {
          setLoading(false);
        }
      });
  };
  useEffect(() => {
    getTabledata();
  }, [mediaPlanerID, page]);

  const getSingleCamapaign = (_id) => {
    setLoadingCamapaign(true);
    axios
      .get(`${API}/admin/lucidApi/${_id}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          //setloading(false);

          setSelectedCampaign(res.data);
          setInvAll(res.data.inventories);

          // setPageCount(res.data.total);
          setLoadingCamapaign(false);
        }
        if (err) {
          setLoadingCamapaign(false);
        }
      })
      .catch(function (error) {
        if (error) {
          setLoadingCamapaign(false);
        }
      });
  };

  useEffect(() => {
    if (DATA) {
      if (InvChannel === "Digital") {
        let td = [];
        DATA.forEach((e) => {
          const converted = ConvertDigital(e);
          td.push({
            data: Object.values(converted),
            _id: e._id,
          });
        });
        setTableData(td);
        let yyy = ConvertDigital(DATA[0]);
        if (yyy) {
          let zzz = Object.keys(yyy);
          let xxx = zzz.map((e) => e.replaceAll("_", " "));
          setTableHeads(xxx);
        }
      }
      if (InvChannel === "Print") {
        let td = [];
        DATA.forEach((e) => {
          const converted = ConvertPrint(e);
          td.push({
            data: Object.values(converted),
            _id: e._id,
          });
        });
        setTableData(td);
        let yyy = ConvertPrint(DATA[0]);
        if (yyy) {
          let zzz = Object.keys(yyy);
          let xxx = zzz.map((e) => e.replaceAll("_", " "));
          setTableHeads(xxx);
        }
      }
      if (InvChannel === "Radio") {
        let td = [];
        DATA.forEach((e) => {
          const converted = ConvertRadio(e);
          td.push({
            data: Object.values(converted),
            _id: e._id,
          });
        });
        setTableData(td);
        let yyy = ConvertRadio(DATA[0]);
        if (yyy) {
          let zzz = Object.keys(yyy);
          let xxx = zzz.map((e) => e.replaceAll("_", " "));
          setTableHeads(xxx);
        }
      }
      if (InvChannel === "OOH") {
        let td = [];
        DATA.forEach((e) => {
          const converted = ConvertOOH(e);
          td.push({
            data: Object.values(converted),
            _id: e._id,
          });
        });
        setTableData(td);
        let yyy = ConvertOOH(DATA[0]);
        if (yyy) {
          let zzz = Object.keys(yyy);
          let xxx = zzz.map((e) => e.replaceAll("_", " "));
          setTableHeads(xxx);
        }
      }
      if (InvChannel === "Television") {
        let td = [];
        DATA.forEach((e) => {
          const converted = ConvertTelevision(e);
          td.push({
            data: Object.values(converted),
            _id: e._id,
          });
        });
        setTableData(td);
        let yyy = ConvertTelevision(DATA[0]);
        if (yyy) {
          let zzz = Object.keys(yyy);
          let xxx = zzz.map((e) => e.replaceAll("_", " "));
          setTableHeads(xxx);
        }
      }
    }
  }, [DATA]);

  useEffect(() => {
    if (InvAll) {
      if (InvChannel === "Digital") {
        setDATA(InvAll.Digital);
      }
      if (InvChannel === "Print") {
        setDATA(InvAll.Print);
      }
      if (InvChannel === "Radio") {
        setDATA(InvAll.Radio);
      }
      if (InvChannel === "OOH") {
        setDATA(InvAll.OOH);
      }
      if (InvChannel === "Television") {
        setDATA(InvAll.Television);
      }
      let btns = [];
      if (InvAll?.Digital?.length > 0) {
        btns.push("Digital");
      }
      if (InvAll?.Print?.length > 0) {
        btns.push("Print");
      }
      if (InvAll?.Radio?.length > 0) {
        btns.push("Radio");
      }
      if (InvAll?.OOH?.length > 0) {
        btns.push("OOH");
      }
      if (InvAll?.Television?.length > 0) {
        btns.push("Television");
      }
      setChanBtns(btns);
    }
  }, [InvAll, InvChannel]);

  const BookCampaign = () => {
    return (
      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "none",
        }}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        //className={classes.modal}
        open={open}
        fullWidth
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          {
            <div className={classes.boxShadow}>
              {!loadingCampaign && (
                <div
                  className={classes.customfield}
                  style={{
                    width: "100%",
                    padding: "20px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          boxShadow:
                            "0px 0px 10px rgba(0,0,0,0.20), 0 0 10px rgba(0,0,0,0.15)",
                          width: "150px",
                          height: "100px",
                          display: "block",
                          backgroundImage: `url(${slectedCamapaign?.image})`,
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          position: "relative",
                          cursor: "pointer",
                        }}
                      ></div>
                      <div style={{ margin: "0 20px", width: "20%" }}>
                        <h2>{slectedCamapaign?.campaignName}</h2>
                        <p>{slectedCamapaign?.description}</p>
                      </div>
                    </div>
                    <div>
                      {ChanBtns.map((e, i) => {
                        return (
                          <div key={i}>
                            <Button
                              style={
                                InvChannel === e
                                  ? {
                                      backgroundColor: "blue",
                                      color: "white",
                                      margin: "10px",
                                    }
                                  : {
                                      backgroundColor: "#f1f1f1",
                                      color: "black",
                                      margin: "10px",
                                    }
                              }
                              onClick={() => setInvChannel(e)}
                            >
                              {e}
                            </Button>
                          </div>
                        );
                      })}
                    </div>
                    <div>
                      <button
                        style={{
                          padding: "0",
                          backgroundColor: "transparent",
                          border: "none",
                          cursor: "pointer",
                        }}
                        // onClick={() => downloadAllPdf()}
                      >
                        <span style={{ marginBottom: "10px" }}>PDF</span>{" "}
                        <CloudDownloadIcon width="30px" />
                      </button>
                      <button
                        style={{
                          padding: "0",
                          backgroundColor: "transparent",
                          border: "none",
                          cursor: "pointer",
                        }}
                        // onClick={() => downloadCSV()}
                      >
                        <span style={{ marginBottom: "10px" }}>CSV</span>{" "}
                        <CloudDownloadIcon width="30px" />
                      </button>
                    </div>
                    {/* <div style={{ width: "300px" }}>
                      {slectedCamapaign?.channels.map((e) => (
                         <div key={i}>
                        <Button>{e}</Button>
                        </div>
                      ))}
                    </div> */}
                  </div>

                  {/* {[
                    {
                      name: "AWARENESS",
                      val: parseFloat(slectedCamapaign?.awareness).toFixed(2),
                    },
                    {
                      name: "CONSIDERATION",
                      val: parseFloat(slectedCamapaign?.consideration).toFixed(
                        2
                      ),
                    },
                    {
                      name: "PREFERENCE",
                      val: parseFloat(slectedCamapaign?.preference).toFixed(2),
                    },
                    {
                      name: "ACTION INTENT",
                      val: parseFloat(slectedCamapaign?.actionIntent).toFixed(
                        2
                      ),
                    },
                  ].map((e,i) => (
                    <div key={i} style={{ marginTop: "5px" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          style={{
                            marginBottom: "0",
                            color: "grey",
                            fontSize: "8px",
                          }}
                        >
                          {" "}
                          {e.name}
                        </span>
                        <span
                          style={{
                            marginBottom: "0",
                            color: "grey",
                            fontSize: "8px",
                          }}
                        >
                          {" "}
                          {e.val} % &#9650;
                        </span>
                      </div>

                      <div
                        style={{
                          backgroundColor: "grey",
                          height: "10px",
                          width: "100%",
                          borderRadius: "5px",
                          marginBottom: "20px",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: `rgb(${Math.random() * 255} ${
                              Math.random() * 255
                            } ${Math.random() * 255})`,
                            height: "10px",
                            width: `${e.val}%`,
                            borderRadius: "5px",
                          }}
                        ></div>
                      </div>
                    </div>
                  ))} */}
                  <div style={{ maxWidth: "800px", maxHeight: "400px" }}>
                    <CustomizedTables
                      isEditable={false}
                      headings={tableHeads}
                      listdata={tableData}
                    />
                  </div>

                  {slectedCamapaign?.status === 0 && (
                    <TextField
                      fullWidth
                      placeholder="Email"
                      label="Email"
                      variant="outlined"
                      style={{
                        display: "block",
                        marginBottom: "10px",
                        marginTop: "20px",
                      }}
                      value={editFormEmail}
                      onChange={(e) => {
                        setEditFormEmail(e.target.value);
                      }}
                    />
                  )}

                  {slectedCamapaign?.status === 0 && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <button
                        disabled={!validatedEmail}
                        fullWidth
                        onClick={() => {
                          //setOpen(false);
                          // BookCall();
                        }}
                        style={
                          validatedEmail
                            ? {
                                backgroundColor: "blue",
                                color: "white",
                                borderRadius: "7px",
                                padding: "10px 25px",
                                border: "none",
                                width: "20%",
                              }
                            : {
                                backgroundColor: "#f1f1f1",
                                color: "white",
                                borderRadius: "7px",
                                padding: "10px 25px",
                                border: "none",
                                width: "20%",
                              }
                        }
                      >
                        Book
                      </button>
                    </div>
                  )}
                </div>
              )}

              {loadingCampaign && <CircularProgress />}
            </div>
          }
        </Fade>
      </Modal>
    );
  };

  const MyChart = ({ totalPercentArr }) => {
    let mydata = [];
    totalPercentArr &&
      totalPercentArr.forEach((element) => {
        mydata.push({ name: element.question, y: element.lift_percentage });
      });
    return (
      <div>
        <HighchartReact
          containerProps={{
            style: {
              height: "180px",
              padding: "0",
              backgroundColor: "transparent",
            },
          }}
          highcharts={Highcharts}
          options={{
            chart: {
              backgroundColor: "rgba(0,0,0,0)",
              plotBackgroundColor: null,
              plotBorderWidth: null,
              plotShadow: false,
              type: "pie",
            },
            title: {
              text: "DEMOGRAPHICS",
              style: { fontSize: "10px", margin: "0" },
            },

            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                  enabled: false,
                  format: "<b>{point.name}",
                  distance: -100,
                  filter: {
                    property: "percentage",
                    operator: ">",
                    value: 4,
                  },
                },
              },
            },
            series: [
              {
                name: "Percent",
                colorByPoint: true,
                data: mydata,
              },
            ],
          }}
        ></HighchartReact>
      </div>
    );
  };

  const handleClose = () => {
    setAlertOpen(false);
    setLaunchOpen(false);
  };

  const handleDelete = (_id) => {
    setLoadingCamapaign(true);
    axios
      .delete(`${API}/admin/lucidApi/${_id}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res) {
          setErrSucMesg("Campaign deleted successfully.");
          setErrSucType(true);
          setLoadingCamapaign(false);
          getTabledata();
        }
      })
      .catch(function (error) {
        if (error) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
          setLoadingCamapaign(false);
        }
      });
  };

  const launchCampiagn = (_id) => {
    setLoadingCamapaign(true);
    axios
      .post(
        `${API}/admin/lucidApi/${_id}/launchCampaign/`,
        {},
        {
          headers: { Authorization: `Bearer ${access_token}` },
        }
      )
      .then((res) => {
        if (res) {
          setErrSucMesg(
            res.data
              ? res.data.detail || res.data.message
              : "Campaign is launching."
          );
          setErrSucType(true);
          setLoadingCamapaign(false);
          getTabledata();
        }
      })
      .catch(function (error) {
        if (error) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
          setLoadingCamapaign(false);
        }
      });
  };

  const editTableData = (_id) => {
    const tableData = [...camapaignList];
    let idsData =
      tableData &&
      tableData.length &&
      tableData.filter((td) => {
        return td.campaign_id === _id;
      });
    if (idsData && idsData.length) {
      history.push(`editCampaign?creator=${mediaPlanerID}`, {
        data: idsData[0],
        editFlag: true,
      });
    }
  };

  const titleCase = (str) => {
    let str1 = str.replaceAll("_", " ");
    let splitStr = str1.toLowerCase().split(" ");
    for (let i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(" ");
  };

  const getDMYDate = (dateTime) => {
    const newdate = new Date(dateTime);
    const d = newdate.getDate();
    const m = newdate.getMonth() + 1;
    const y = newdate.getFullYear();
    const formattedate =
    (d <= 9 ? '0' + d : d) + '/' + (m <= 9 ? '0' + m : m) + '/' + y;
    return formattedate;
  };

  const CampaignTab = (
    title,
    img,
    _id,
    Spend,
    channels,
    startDate,
    endDate,
    totalPercent,
    totalPercentArr,
    status,
    index
  ) => {
    let sd = getDMYDate(startDate);
    let ed = getDMYDate(endDate);

    return (
      <div
        style={{
          boxShadow: "0px 0px 10px rgba(0,0,0,0.20), 0 0 10px rgba(0,0,0,0.15)",
          borderRadius: "16px",

          marginTop: "30px",
          transition: "all 0.15s",
        }}
      >
        <div
          style={{
            padding: "7px 20px",
            backgroundColor: "blue",
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
            color: "white",
            display: "flex",
            justifyContent: "space-between",
            background: "linear-gradient(to right, #9211a2, #4911a2)",
          }}
        >
          <span title={`${title}`} className="mb-0 task-name">
            {title.substring(0, 20).concat('...')}
          </span>
          <span
            style={{
              justifyContent: "center",
              display: "contents",
              textAlign: "center",
            }}
          >
            {" "}
            {status.toUpperCase()}
            <span>
              {status === "draft" && (
                <div>
                  <span title="Delete campiagn">
                    <DeleteForeverIcon
                      style={{
                        cursor: "pointer",
                        color: "red",
                        marginRight: "5px",
                      }}
                      fontSize="small"
                      onClick={() => {
                        setAlertOpen(true);
                        setCampaignId(_id);
                      }}
                    />
                  </span>
                  <span title="Launch campiagn">
                    <Launch
                      style={{ cursor: "pointer", marginRight: "5px" }}
                      fontSize="small"
                      onClick={() => {
                        setLaunchOpen(true);
                        setCampaignId(_id);
                      }}
                    />
                  </span>
                  <span title="Edit campiagn">
                    <Edit
                      style={{ cursor: "pointer" }}
                      fontSize="small"
                      onClick={() => {
                        editTableData(_id);
                      }}
                    />
                  </span>
                </div>
              )}
            </span>
          </span>
        </div>
        <div

        // onClick={() => history.push(`/media-owner/singleMediaView?id=${_id}`)}
        >
          <div
            style={{
              boxShadow:
                "0px 0px 10px rgba(0,0,0,0.20), 0 0 10px rgba(0,0,0,0.15)",
              width: "280px",
              height: "100px",
              display: "block",
              backgroundImage: `url(${img})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              position: "relative",
              cursor: "pointer",
            }}
            // onClick={() => {
            //   getSingleCamapaign(_id);
            //   setOpen(true);
            // }}
          >
            {/* <div
              style={{
                backgroundImage: `url(${LeftArrow})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                width: "35%",
                textAlign: "center",
                color: "blue",
              }}
            >
              &#x20AC;{Spend}
            </div> */}
            <div
              style={{
                position: "absolute",
                bottom: "0",
                right: "0",
                backgroundImage: `url(${RightArrow})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                width: "60%",
                textAlign: "center",
                color: "blue",
                padding: "0 5px",
              }}
            >
              {sd}-{ed}
            </div>
          </div>
          <div
            style={{
              width: "280px",
              display: "block",
              backgroundColor: "orange",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-around",
              height: "45px",
              alignItems: "center",
            }}
          >
            {channels.map((e, i) => (
              <div key={i}>
                <span style={{ padding: "0 5px", color: "white" }}>
                  {e.name}
                </span>
              </div>
            ))}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <div style={{ width: "48%", height: "170px" }}>
            {totalPercentArr ? (
              <MyChart totalPercentArr={totalPercentArr} />
            ) : null}
          </div>
          <div style={{ width: "48%" }}>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              {/* <div style={{ width: "45%", textAlign: "center" }}>
                <span style={{ display: "block", fontWeight: "700" }}>
                  Uplift
                </span>
                <span style={{ fontWeight: "800", color: "green" }}>
                  {parseFloat(brandMetrics?.brandUplift).toFixed(2)}%
                </span>
              </div> */}
              <div style={{ width: "45%", textAlign: "center" }}>
                <span style={{ display: "block", fontWeight: "700" }}>
                  Uplift
                </span>
                <span style={{ fontWeight: "800", color: "green" }}>
                  {totalPercent ? totalPercent.toFixed(2) : "0"}%
                </span>
              </div>
            </div>

            {totalPercentArr && totalPercentArr.length > 0
              ? totalPercentArr.map((e, i) => (
                  <div key={i} style={{ marginTop: "5px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span
                        style={{
                          marginBottom: "0",
                          color: "grey",
                          fontSize: "8px",
                        }}
                      >
                        {" "}
                        {titleCase(e.question)}
                      </span>
                      <span
                        style={{
                          marginBottom: "0",
                          color: "grey",
                          fontSize: "8px",
                        }}
                      >
                        {" "}
                        {e.lift_percentage.toFixed(2)} % &#9650;
                      </span>
                    </div>

                    <div
                      style={{
                        backgroundColor: "grey",
                        height: "10px",
                        width: "100%",
                        borderRadius: "5px",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: `rgb(${Math.random() * 255} ${
                            Math.random() * 255
                          } ${Math.random() * 255})`,
                          height: "10px",
                          width: `${e.lift_percentage.toFixed(2)}%`,
                          borderRadius: "5px",
                        }}
                      ></div>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>

        <Dialog
          open={alertOpen || launchOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {alertOpen
              ? "Are you sure, you want to delete campaign?"
              : "Are you sure, you want to launch campaign?"}
          </DialogTitle>
          <DialogActions>
            <Button
              style={{ color: "black", backgroundColor: "grey" }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              style={{ backgroundColor: "#4F74C9", color: "white" }}
              onClick={() => {
                setAlertOpen(false);
                setLaunchOpen(false);
                if (alertOpen) {
                  handleDelete(campaignId);
                } else {
                  launchCampiagn(campaignId);
                }
              }}
              autoFocus
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  return (
    <div>
      <Dialog
        open={datepickerOpen}
        onClose={() => setDatepickerOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {datepickerOpen && (
            <DatePickerCalendar
              date={newEndDate}
              onDateChange={setNewEndDate}
              locale={enGB}
            />
          )}
        </DialogTitle>
      </Dialog>
      <NewAlert
        msg={ErrSucMsg}
        isSuccess={ErrSucType}
        close={() => setErrSucMesg("")}
      />
      {BookCampaign()}
      <div
        className={classes.customfield}
        style={{
          padding: "16px",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.20), 0 0 10px rgba(0,0,0,0.15)",
          borderRadius: "30px",
          backgroundColor: "white",
          marginTop: "20px",
        }}
      >
        <ShowAlert />
        <div
          style={{
            marginBottom: "15px",
          }}
        >
          <span
            style={{
              color: "#FB6D6A",
              fontWeight: "400",
              margin: "0",
              fontSize: "20px",
              marginLeft: "10px",
            }}
          >
            Campaign
          </span>
        </div>
        <div style={{ width: "40%", marginBottom: "80px" }}>
          {!mediaPlanerID && (
            <div style={{ display: "flex" }}>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                style={{ marginTop: "10px" }}
              >
                <Autocomplete
                  value={mediaPlannerList?.find((e) => e._id === params.planer)}
                  options={mediaPlannerList}
                  onChange={(e, val) => {
                    setmediaPlanerID(val?._id);
                  }}
                  getOptionLabel={(option) => option?.name}
                  style={{ marginTop: "10px", padding: "0" }}
                  getOptionSelected={(option, value) =>
                    option._id === value?._id
                  }
                  renderOption={(option) => (
                    <div style={{ padding: "0" }}>
                      <span>{option.name}</span>
                      <br></br>
                      <span
                        style={{
                          fontSize: "10px",
                          fontWeight: "100",
                          padding: "0",
                        }}
                      >
                        {option.username}
                      </span>
                    </div>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Media Planner"
                      onChange={(e) => setNamesearch(e.target.value)}
                      style={{ padding: "0" }}
                    />
                  )}
                />
              </FormControl>
              <span>
                {Spinner && (
                  <CircularProgress
                    style={{ marginTop: "30px" }}
                    color="disabled"
                    size={20}
                  />
                )}
              </span>
            </div>
          )}

          {mediaPlanerID && SelectedName.length > 0 && (
            <div>
              <span
                style={{ color: "blue", marginRight: "15px", fontSize: "25px" }}
              >
                {SelectedName}
              </span>
              {SelectedName.length > 0 && (
                <button
                  onClick={() => {
                    setPageCount(0);
                    setmediaPlanerID(null);
                    history.push("manageCampaign");
                  }}
                >
                  x
                </button>
              )}
            </div>
          )}
        </div>
        {mediaPlanerID && !loading && (
          <button
            className={classes.addActionBtn}
            style={{ marginBottom: "10px" }}
            onClick={() =>
              history.push(`createCampaign?creator=${mediaPlanerID}`)
            }
          >
            Create Campaign
          </button>
        )}
        {loading && <CircularProgress size={40} />}
        {mediaPlanerID && !loading && (
          <div
            style={{
              display: "flex",
              padding: "30px 0",
              flexWrap: "wrap",
              justifyContent: "space-around",
            }}
          >
            {camapaignList?.map((e, index) =>
              CampaignTab(
                e.campaign_name,
                e.image,
                e.campaign_id,
                e.spendAmount,
                e.channels,
                e.start_date,
                e.end_date,
                e.totalPercent,
                e.totalPercentArr,
                e.status,
                index
              )
            )}
            {camapaignList.length == 0 && <span>No Campaign Created</span>}
          </div>
        )}
        {pageCount > 0 && (
          <div
            style={{
              display: "flex",
              marginTop: "20px",
              justifyContent: "center",
            }}
          >
            <span
              style={{
                color: "#FB6D6A",
                fontWeight: "600",
                margin: "0",
                fontSize: "20px",
                marginRight: "20px",
              }}
            >
              <Pagination
                page={page}
                count={Math.ceil(pageCount / 10)}
                color="primary"
                onChange={(e, page) => setPage(page)}
                size="small"
              />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default MainLayout(ManageCampaign);
