import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { 
    Avatar, 
    Button, 
    Grid, 
    Modal, 
    Card, 
    CardActions, 
    CardContent, 
    CardHeader, 
    Checkbox, 
    FormControl,
    FormGroup,
    FormControlLabel,
    MenuItem,
    Select,
    TextField, 
    Typography,
    FormHelperText
} from "@material-ui/core"
import UsbIcon from '@material-ui/icons/Usb';
import { makeStyles } from "@material-ui/styles";

import { API } from "../../../../constants/index"
import { SECRET_KEY } from "../../../../constants/index"
import NewAlert from "../../../../components/NewAlert"

const useStyles = makeStyles({
    viewAbilityWrapper: {
        display: "flex",
        flexDirection: "row",
        "& .MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal": {
            maxWidth: "30%"
        }
    },
    percent: {
        margin: "auto 0",
        position: "relative",
        left: "-30px",
        borderLeft: "1px solid #c4c4c4",
        padding: "0.5em",
        color: "#838383"
    },
    second: {
        margin: "auto 0",
        position: "relative",
        borderLeft: "1px solid #c4c4c4",
        padding: "0.5em",
        left: "-62px",
        color: "#838383"
    },
    selectWrappper: {
        // display: "flex",
        // flexDirection: "row",
        // justifyContent: "space-around"
    }
})

const AddModal = ({popParticularCampaign, setPopParticularCampaign, setPopAddCampaign, campaignType, adsCampaign}) => {
  const classess = useStyles()

  const access_token = localStorage.getItem(SECRET_KEY)

  const [percentVisible, setPercentVisible] = useState(0)
  const [durationReq, setDurationReq] = useState(0)
  const [targetCampaign, setTargetCampaign] = useState("Standard")
  const [ErrSucMsg, setErrSucMesg] = useState("");
  const [ErrSucType, setErrSucType] = useState(true);

  const { register, handleSubmit, errors, control } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const onSubmit = async (data) => {
    let payload;
    if (campaignType.type === "vast") {
        payload = {
            name: data.campaignName,
            advertiser: adsCampaign,
            type: "VAST"
        }
    } else {
        payload = {
            name: data.campaignName,
            roadblock_tags: data.roadBlockTag.split(","),
            advertiser: adsCampaign,
            type: "Standard"
        }
    }
    await axios.post(`${API}/mediaPlanner/AdButler/createCampaign`, payload,{
        headers: { Authorization: `Bearer ${access_token}` },
    })
    .then(res => {
        setErrSucType(true)
        setErrSucMesg(res.data.message)
        setTimeout(() => {
            setPopAddCampaign(false)
            setPopParticularCampaign(false)
        }, 1500)
    })
    .catch(err => {
        console.log("err", console.log(err.response.data.message))
    })
  }

  const percentVisibleHandler = (e) => {
    setPercentVisible(e.target.value)
  }

  const durationReqHandler = (e) => {
    setDurationReq(e.target.value)
  }

  return (
    <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{display:"flex", alignItems:"center", justifyContent:"center"}}
        open={popParticularCampaign}
    >
        <Card style={{
            width: 500,
            backgroundColor: 'primary.dark',
            '&:hover': {
            backgroundColor: 'primary.main',
            opacity: [0.9, 0.8, 0.7]
            }
        }}>
            <CardHeader
                avatar={
                <Avatar sx={{ bgcolor: "red" }} aria-label="recipe">
                    +
                </Avatar>
                }
                title={`Create New ${campaignType?.chosen}`}
            />
            <CardContent>
                <NewAlert
                    msg={ErrSucMsg}
                    isSuccess={ErrSucType}
                    close={() => setErrSucMesg("")}
                />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={12} md={12} sm={12}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="campaignName"
                                label="Campaign name"
                                name="campaignName"
                                inputRef={register({ required: true })}
                                error={errors.campaignName?.type === 'required'}
                            />
                            {errors.campaignName?.type === 'required' && <span style={{color: "#f44337"}}>This field is required</span>}
                        </Grid>
                        {
                            campaignType?.type === "standard" &&
                            <>
                                <Grid item xs={12} lg={12} md={12} sm={12}>
                                    <TextField
                                        // value={unitCost}
                                        // className={classes.customfield}
                                        // onChange={(e) => setUnitCost(e.target.value)}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        id="roadBlockTag"
                                        label="Roadblock Tag"
                                        name="roadBlockTag"
                                        inputRef={register()}
                                        // error={errors.campaignName?.type === 'required'}
                                    />
                                    {/* {errors.campaignName?.type === 'required' && <span style={{color: "#f44337"}}>This field is required</span>} */}
                                </Grid>
                                <Grid item xs={12} lg={12} md={12} sm={12}>
                                    <Grid>
                                        <CardHeader
                                            title="Custom Viewability"
                                        />
                                        <FormGroup className={classess.viewAbilityWrapper}>
                                            <FormControlLabel control={<Checkbox checked={percentVisible > 0 ?? false}/>} label="Percentage Visible Required" />
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                id="visiblePercent"
                                                onChange={percentVisibleHandler}
                                            />
                                            <span className={classess.percent}>%</span>
                                        </FormGroup>
                                    </Grid>
                                    <Grid>
                                        <FormGroup className={classess.viewAbilityWrapper}>
                                            <FormControlLabel control={<Checkbox checked={parseInt(durationReq) > 0 ?? false}/>} label="Duration Required" />
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                id="durationReq"
                                                onChange={durationReqHandler}
                                            />
                                            <span className={classess.second}>second</span>
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={12} md={12} sm={12}>
                                    <Grid className={classess.selectWrappper}>
                                        <div style={{width: "155px"}}>
                                            <CardHeader
                                                title="Targeting Level"
                                            />
                                            <FormControl style={{width: "100%"}}>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="campaignLevel"
                                                    name="campaignLevel"
                                                    defaultValue={targetCampaign}
                                                    onChange={(e) => setTargetCampaign(e.target.value)}
                                                    // onChange={(e) => {
                                                    //     register("campaignLevel", {valueAs: e.target.value})
                                                    // }}
                                                    // inputRef={register({ required: true })}
                                                >
                                                    <MenuItem value="Standard">Campaign</MenuItem>
                                                    <MenuItem value="VAST">Ad Item</MenuItem>
                                                </Select>
                                                {/* {errors.campaignLevel && <FormHelperText error>Select a campaign level</FormHelperText>} */}
                                            </FormControl>
                                        </div>
                                        {/* <div style={{width: "155px"}}>
                                            <CardHeader
                                                title="Advertiser"
                                            />
                                            <FormControl style={{width: "100%"}}>
                                                <Select
                                                    labelId="advertiser-select-label"
                                                    id="advertiser"
                                                    name="advertiser"
                                                    defaultValue=""
                                                    inputRef={register({ required: true })}
                                                >
                                                    <MenuItem value="Campaign1">Campaign</MenuItem>
                                                    <MenuItem value="adItem1">Ad Item</MenuItem>
                                                </Select>
                                                {errors.advertiser && <FormHelperText error>Select an advertiser</FormHelperText>}
                                            </FormControl>
                                        </div> */}
                                    </Grid>
                                </Grid>
                            </>
                        }
                        <Grid item xs={12} lg={12} md={12} sm={12}>
                            <CardHeader
                                title="Contract"
                            />
                            <Card>
                                <CardContent>
                                    <Typography><UsbIcon size={20}/> Assign to Contract</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <CardActions style={{float:"right"}}>
                        <Button
                            variant="contained"
                            color="ternary"
                            onClick={() => {
                                setPopAddCampaign(true)
                                setPopParticularCampaign(false)
                            }}
                            >
                            Back
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            Create campaign
                        </Button>
                    </CardActions>
                </form>
            </CardContent>
        </Card>
    </Modal>
  )
}

export default AddModal