import { fork, all } from 'redux-saga/effects';
import { login, registerSaga } from './user';
import { uploadImage, uploadCsvSaga, createInventorySaga, getMediaOwnerTitlesSaga, createMediaOwnerAndInventorySaga ,getAllMediaOwnersSaga} from './media-owner';
import { createPersonaSaga, getPersonasSaga } from './persona';
import { processCsv, getAllUsers, adminUploadCsvSaga, adminGetUploadedDropsSaga } from './admin';
import { getMediaOwnerWithAllInventrySaga, addNewMediaOwnerInventrySaga } from './media-inventry';
import { createMediaPlannerSaga ,getMediaPlannerForecastSaga, getMediaPlannerInventoriesSaga } from './media-planner';
import { postBrandUpliftSaga, getBrandUpliftForecastSaga } from './brandUplift';
import { mediaQuestionSaga, mediaSentimentSaga, mediaConsultancySaga, mediaQuestion1Out5Saga } from './media';


export default function* root() {
  yield all([
    fork(login), fork(registerSaga), fork(uploadImage), //fork(uploadCsvSaga),
    fork(createPersonaSaga), fork(getPersonasSaga),
    fork(createInventorySaga), fork(getMediaOwnerTitlesSaga),
    fork(createMediaOwnerAndInventorySaga),
    fork(processCsv),fork(getAllUsers),fork(adminUploadCsvSaga),fork(adminGetUploadedDropsSaga),
    fork(getAllMediaOwnersSaga),fork(getMediaOwnerWithAllInventrySaga),fork(addNewMediaOwnerInventrySaga),
    fork(createMediaPlannerSaga),fork(getMediaPlannerForecastSaga),fork(getMediaPlannerInventoriesSaga),
    fork(postBrandUpliftSaga),fork(getBrandUpliftForecastSaga),
    fork(mediaQuestionSaga),fork(mediaSentimentSaga),fork(mediaConsultancySaga),fork(mediaQuestion1Out5Saga),

  ]);
}
