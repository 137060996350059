export const POST_QUESTION = 'POST_QUESTION';
export const POST_QUESTION1_OUT_5 = 'POST_QUESTION1_OUT_5';
export const POST_MEDIA_SENTIMENTS = 'POST_MEDIA_SENTIMENTS';
export const POST_MEDIA_CONSULTANCY = 'POST_MEDIA_CONSULTANCY';

export function postQuestion(data) {
  return {
    type: POST_QUESTION,
    data,
  };
}

export function postQestion1out5(data) {
  return {
    type: POST_QUESTION1_OUT_5,
    data,
  };
}

export function postMediaSentiment(data) {
  return {
    type: POST_MEDIA_SENTIMENTS,
    data,
  };
}

export function postMediaConsultancy(data) {
  return {
    type: POST_MEDIA_CONSULTANCY,
    data,
  };
}
