import "./csv-style.css";

import { Button, TextField, makeStyles } from "@material-ui/core";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import React, { useMemo, useState } from "react";

import { flushSync } from "react-dom";
import { useAccessToken } from "../../../Hooks/useAccessToken";
import LoaderCircle from "../../../components/Loader/Loader.js";
import useStyles from "../../../components/StyleClasses";
import ToastMessage from "../../../components/Toast/Toast";
import { API } from "../../../constants";
import AdminMainLayout from "../../../layouts/adminMainLayout";
import CsvList from "./components/CsvList/CsvList";

const useInputStyles = makeStyles((theme) => ({
    input: {
        "& .MuiInputBase-input": {
            height: "38px",
            padding: "0 8px",
            borderColor: "#2684ff",
        },
    },
    inputBorderLess: {
        "& .MuiInputBase-input": {
            height: "38px",
            padding: "0 0",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none"
        },
    },
}));


//
//
export default AdminMainLayout(function CsvUploadation() {
    const classes = useStyles();
    const inputStyles = useInputStyles();

    const access_token = useAccessToken();
    const initial_form_values = useMemo(
        () => ({
            csv_file: {
                value: "",
                required: true,
                name: "CSV File",
            },
            csv_filename: {
                value: "",
                required: true,
                name: "CSV Filename",
            },
            csv_file_id: {
                value: "",
                required: true,
                name: "CSV File Id",
            },
        }),
        []
    );
    const initial_form_errors = useMemo(
        () => ({
            csv_file: {
                error: false,
                msg: "",
            },
            csv_filename: {
                error: false,
                msg: "",
            },
            csv_file_id: {
                error: false,
                msg: "",
            },
        }),
        []
    );
    const [formFields, setFormFields] = useState(initial_form_values);
    const [formFieldsErrors, setFormFieldsErrors] = useState(initial_form_errors);
    const [shouldRefetchCsvList, setShouldFetchCsvList] = useState(false);

    const { mutate, error, data, status, isLoading, isSuccess, reset, isError } = useMutation({
        mutationFn: (fn_params) => {
            return axios.post(`${API}/admin/csv/matchDomains`, fn_params, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            });
        },
        onError: (err) => {
            console.log(err);
        },
        onSuccess: (data) => {
            setFormFields(initial_form_values);
            document.querySelector("[name=csv_file]").value = "";
            setShouldFetchCsvList(true);
        },
        onSettled: (data, err) => {
            console.log("final");
        },
    });

    // bla bla bla
    // console.log(error?.response?.data?.message)

    const handleFormChange = (key, value) => {
        setFormFields((fields) => ({
            ...fields,
            [key]: {
                ...fields[key],
                value,
            },
        }));
    };

    const handleCreateCsvFile = (e) => {
        e.preventDefault();

        console.log({formFields})


        let isErrorPresent = false;

        Object.keys(formFields).forEach((field) => {
            flushSync(() => {
                if (formFields[field].required && !formFields[field].value) {
                    setFormFieldsErrors((errors) => {
                        return {
                            ...errors,
                            [field]: {
                                msg: `${formFields[field].name} is required.`,
                                msg: errors[field].error ? errors[field].msg : `${formFields[field].name} is required.`,
                                error: true,
                            },
                        };
                    });
                    isErrorPresent = true;
                    return;
                }
                if (formFields[field].required && formFields[field].value) {
                    setFormFieldsErrors((errors) => {
                        return {
                            ...errors,
                            [field]: {
                                msg: ``,
                                error: false,
                            },
                        };
                    });
                    isErrorPresent = false;
                }
            })
            Object.keys(formFieldsErrors).forEach((key) => {
                if (formFieldsErrors[key].error) {
                    isErrorPresent = true;
                }
            });
        });
        if (isErrorPresent) {
            return;
        }


        // prepare form payload
        const formData = new FormData();
        formData.append("csv", formFields.csv_file.value);
        formData.append("name", formFields.csv_filename.value);
        formData.append("id", formFields.csv_file_id.value);

        // add api call
        mutate(formData);
    };

    return (
        <>
            <div className={classes.boxShadow} style={{ marginTop: "40px", padding: "20px", paddingRight: "40px " }}>
                <form onSubmit={handleCreateCsvFile} className="csv-upload-form">
                    <div className={classes.customfield}>
                        <span
                            style={{
                                color: "#FB6D6A",
                                fontWeight: "600",
                                margin: "0",
                                fontSize: "20px",
                                marginLeft: "20px",
                            }}
                        >
                            Upload CSV File
                        </span>
                    </div>
                    <div className="form-control csv-input-container flex align-center">
                        <h3 style={{ width: "15%" }}>Upload CSV</h3>
                        <div>
                            <TextField
                                type="file"
                                variant="outlined"
                                name="csv_file"
                                className={inputStyles.inputBorderLess}
                                onChange={(e) => {
                                    e.persist();
                                    if (!e.target.files[0]?.name.endsWith(".csv")) {
                                        setFormFieldsErrors((errros) => ({
                                            ...errros,
                                            csv_file: {
                                                error: true,
                                                msg: "CSV File extension must be '.csv'.",
                                            },
                                        }));
                                        return;
                                    }
                                    setFormFieldsErrors((errros) => ({
                                        ...errros,
                                        ...initial_form_errors,
                                    }));
                                    handleFormChange(e.target.name, e.target.files[0]);
                                }}
                            />
                            <div className="error-box">{formFieldsErrors.csv_file.msg}</div>
                        </div>
                    </div>
                    <div className="form-control csv-input-container flex align-center">
                        <h3 style={{ width: "15%" }}>name</h3>
                        <div>
                            <TextField
                                variant="outlined"
                                className={inputStyles.input}
                                value={formFields.csv_filename.value}
                                name="csv_filename"
                                onChange={(e) => {
                                    e.persist();
                                    handleFormChange(e.target.name, e.target.value);
                                }}
                            />
                            <div className="error-box">{formFieldsErrors.csv_filename.msg}</div>
                        </div>
                    </div>
                    <div className="form-control csv-input-container flex align-center">
                        <h3 style={{ width: "15%" }}>File Id</h3>
                        <div>
                            <TextField
                                variant="outlined"
                                value={formFields.csv_file_id.value}
                                className={inputStyles.input}
                                name="csv_file_id"
                                onChange={(e) => {
                                    e.persist();
                                    handleFormChange(e.target.name, e.target.value);
                                }}
                            />
                            <div className="error-box">{formFieldsErrors.csv_file_id.msg}</div>
                        </div>
                    </div>
                    <Button type="submit" className={classes.addActionBtn}>
                        {isLoading ? (
                            <LoaderCircle
                                style={{ position: "unset", width: "22px", height: "22px", color: "white" }}
                            />
                        ) : (
                            "Save"
                        )}
                    </Button>
                </form>
                {/* error */}
                <ToastMessage
                    msg={error?.response?.data?.message || error?.response?.data || error?.message}
                    isSuccess={isSuccess}
                    close={() => reset()}
                />
                {/* success */}
                <ToastMessage
                    msg={data?.data?.message || data?.data || data?.message}
                    isSuccess={isSuccess}
                    close={() => reset()}
                />
                <CsvList setShouldFetchCsvList={setShouldFetchCsvList} shouldRefetchCsvList={shouldRefetchCsvList} />
            </div>
        </>
    );
});
