import React, { useState, useEffect } from "react";
import { makeStyles, fade } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { useForm, Controller } from "react-hook-form";

//Radio
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

//Text field
import TextField from "@material-ui/core/TextField";

// Select
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

//Textarea
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

//Button
import Button from "@material-ui/core/Button";
import { useSelector } from "react-redux";

import Checkbox from "@material-ui/core/Checkbox";

import { useDispatch } from "react-redux";
import StepWrapper from "./stepWrapper";
import MainLayout from "../../layouts/newMainLayout";
import { useHistory } from "react-router-dom";
import Modal from "../../components/modal";
import { getCsvDropdown } from "../../redux/actions/admin";
import { postQuestion } from "../../redux/actions/media";
import culturalIntell from "../../assets/Cultural-Intelligence.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "40px",
  },
  pageTitle: {
    color: "#e96941",
    fontWeight: "normal",
  },
  formTitle: {
    color: "#1d8bf7",
    fontWeight: "normal",
  },
  paper: {
    padding: "16px",
    color: theme.palette.text.secondary,
  },
  boxShadow: {
    position: "relative",
    padding: "16px",
    boxShadow: "0px 0px 10px rgba(0,0,0,0.20), 0 0 10px rgba(0,0,0,0.15)",
    borderRadius: "30px",
    backgroundColor: "white",
    marginTop: "-30px",
  },
  textarea: {
    width: "100%",
    backgroundColor: "#f4f4f8",
    marginTop: "8px",
    borderRadius: "5px",
    padding: "14px",
    marginBottom: "20px",
  },
  customfield: {
    paddingTop: "0",
    marginTop: "0",
    backgroundColor: "#f4f4f8",
    borderRadius: "5px",
    "& .MuiInputLabel-root.Mui-shrink": {
      color: "red",
      // "& .Mui-shrink": {
      // 	color: 'red'
      // },
    },
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&$focused": {
      backgroundColor: "#fff",
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.5px 14px",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",

      "& fieldset": {
        borderColor: "grey",
      },
      "&:hover fieldset": {},
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "grey",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "grey",
    },
    "& .MuiInputLabel-root.MuiInputLabel-shrink": {
      margin: "0!important",
    },
    "& .MuiInputLabel-root.MuiInputLabel-animated": {
      marginTop: "-8px",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",
    },
  },
  selectImgBtn: {
    marginTop: "10px",
    display: "block",
    margin: "0 auto",
    color: "#ba54f5",
    border: "1px solid #ba54f5",
    padding: "5px 30px",
  },
  addActionBtn: {
    padding: "10px",
    width: "150px",
    backgroundColor: "#136cc3",
    color: "white",
    fontWeight: "bold",
    borderRadius: "8px",
    textTransform: "none",
    "&:hover": {
      background: "#136cc3",
    },
  },
  checkNRadio: {
    padding: 0,
    "& .MuiFormLabel-root": {
      color: "#1d8bf7",
      marginBottom: "8px",
    },
    "& .MuiFormControlLabel-root": {
      "& span": {
        paddingTop: "1px",
        paddingBottom: "1px",
      },
    },
  },
  checkNRadio2: {
    padding: 0,
    "& .MuiFormLabel-root": {
      color: "#1d8bf7",
      marginBottom: "8px",
    },
    "& .MuiFormControlLabel-root": {
      "& span": {
        paddingTop: "1px",
        paddingBottom: "1px",
      },
    },
  },
  logo: {
    width: "150px",
    margin: "0 auto",
    display: "block",
    marginBottom: "30px",
  },
}));

const print = [
  {
    question: "What is the circulation of your print and total readership1?",
  },
  {
    question: "What is the circulation of your print and total readership2?",
  },
  {
    question: "What is the circulation of your print and total readership3?",
  },
];

const tv = [
  {
    question: "TvWhat is the circulation of your print and total readership1?",
  },
  {
    question: "TvWhat is the circulation of your print and total readership2?",
  },
  {
    question: "TvWhat is the circulation of your print and total readership3?",
  },
];
const radio = [
  {
    question: "radio1?",
  },
  {
    question: "radio2?",
  },
  {
    question: "radio3?",
  },
];

const mobileApp = [
  {
    question: "mobileApp 1?",
  },
  {
    question: "mobileApp 2?",
  },
  {
    question: "mobileApp 3?",
  },
];
const social = [
  {
    question: "social 1?",
  },
  {
    question: "social 2?",
  },
  {
    question: "social 3?",
  },
];

const ooh = [
  {
    question: "ooh 1?",
  },
  {
    question: "ooh 2?",
  },
  {
    question: "ooh 3?",
  },
];

const digital = [
  {
    question: "digital 1?",
  },
  {
    question: "digital 2?",
  },
  {
    question: "digital 3?",
  },
];
const Questionneries = () => {
  const { register, handleSubmit, errors, control } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [demographic, setDemographic] = useState();
  const [industry, setIndustry] = useState();
  const [age, setAge] = useState();
  const [questions, setQuestions] = useState([]);
  const [channel, setChannel] = useState("");
  const [questionsList, setQuestionList] = useState([]);

  const dispatch = useDispatch();
  const history = useHistory();

  const currentUser = useSelector((state) => state.loggedInSession);
  const createMedia = useSelector((state) => state.createMedia);
  const personaSaved = useSelector((state) => state.personaSaved);
  const dropdownData = useSelector((state) => state.dropdownData);
  const classes = useStyles();

  useEffect(() => {
    if (!dropdownData) {
      dispatch(getCsvDropdown());
    }
  }, [dropdownData, questionsList]);

  const setChannelQuestioner = (val) => {
    setChannel(val);
    switch (val) {
      case "tv":
        return setQuestionList(tv);
      case "radio":
        return setQuestionList(radio);
      case "print":
        return setQuestionList(print);
      case "digital":
        return setQuestionList(digital);
      case "ooh":
        return setQuestionList(ooh);
      case "social":
        return setQuestionList(social);
      case "mobileApp":
        return setQuestionList(mobileApp);
      // you can have as many case statements as you need
      default:
        return false;
    }
  };

  const handleQuestion = (e, question) => {
    const answer = e.target.value;
    console.log("question", question);
    console.log("answer", answer);
  };

  const onSubmit = (data) => {
    data.industry = industry;
    data.age = age;
    data.demographic = demographic;
    data.channel = channel;
    console.log("daaaata", data);
    dispatch(postQuestion(data));
  };

  const questionnery = {
    name: "yasir2",
    top5Countries: ["pak", "england", "india"],
    genderSplit: {
      male: true,
      female: false,
      nonBinary: false,
      genderFluid: false,
      transgender: false,
      intersex: false,
      twoSpirit: false,
      nonConfirming: true,
    },
    audinceAge: {
      age_16_24: false,
      age_25_34: true,
      age_35_44: false,
      age_45_54: false,
      age_55_64: false,
      age_65: true,
    },
    ethnicity: "asian",
    channel: "digital",
    basedCountry: "pak",
    questions: [
      {
        question: "Do you like food?",
        answer: "yes, Very much",
      },
      {
        question: "Do you like food?",
        answer: "No, Very Bad",
      },
    ],
  };

  return (
    <div className={classes.boxShadow}>
      <div className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <form
              className={classes.form}
              // ref={useRef()  }
              onSubmit={handleSubmit(onSubmit)}
            >
              <div>
                <Grid container spacing={3}>
                  <Grid item md={2} sm={2} xs={2}>
                    <img className={classes.logo} src={culturalIntell} />
                  </Grid>
                  <Grid item md={2} sm={2} xs={2}>
                    <FormControl
                      fullWidth
                      component="fieldset"
                      variant="outlined"
                      className={[
                        classes.selectControl,
                        classes.customfield,
                      ].join(" ")}
                      style={{ marginTop: "10px" }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Demographic
                      </InputLabel>
                      <Select
                        aria-label="Sizes"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select1"
                        name="demographic"
                        onChange={(e) => setDemographic(e.target.value)}
                        ref={register}
                      >
                        {dropdownData?.demographic?.map((x, index) => {
                          return (
                            <MenuItem key={index} value={x.value}>
                              {x.name}
                            </MenuItem>
                          );
                        })}
                        {/* <MenuItem value={''}></MenuItem>
                                            <MenuItem value={'black'}>Black</MenuItem>
                                            <MenuItem value={'asian'}>Asian</MenuItem>
                                            <MenuItem value={'disability'}>Disability</MenuItem>
                                            <MenuItem value={'religion'}>Religion</MenuItem>
                                            <MenuItem value={'genz'}>Genz</MenuItem>
                                            <MenuItem value={'age50+'}>Age50+</MenuItem> */}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={2} sm={2} xs={2}>
                    <FormControl
                      fullWidth
                      component="fieldset"
                      variant="outlined"
                      className={[
                        classes.selectControl,
                        classes.customfield,
                      ].join(" ")}
                      style={{ marginTop: "10px" }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Select Industry
                      </InputLabel>
                      <Select
                        aria-label="Sizes"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select2"
                        name="industry"
                        onChange={(e) => setIndustry(e.target.value)}
                        ref={register}
                      >
                        {dropdownData?.industry?.map((x, index) => {
                          return (
                            <MenuItem key={index} value={x.value}>
                              {x.name}
                            </MenuItem>
                          );
                        })}
                        {/* <MenuItem value={''}></MenuItem>
                                            <MenuItem value={'automotive'}>Automotive</MenuItem>
                                            <MenuItem value={'hospital'}>Hospital</MenuItem>
                                            <MenuItem value={'electronics'}>Electronics</MenuItem> */}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={2} sm={2} xs={2}>
                    <FormControl
                      fullWidth
                      component="fieldset"
                      variant="outlined"
                      className={[
                        classes.selectControl,
                        classes.customfield,
                      ].join(" ")}
                      style={{ marginTop: "10px" }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Select Age
                      </InputLabel>
                      <Select
                        aria-label="Sizes"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select3"
                        name="age"
                        onChange={(e) => setAge(e.target.value)}
                        ref={register}
                      >
                        {dropdownData?.ageRange?.map((x, index) => {
                          return (
                            <MenuItem key={index} value={x.value}>
                              {x.name}
                            </MenuItem>
                          );
                        })}
                        {/* <MenuItem value={''}></MenuItem>
                                            <MenuItem value={'18-24'}>18-24</MenuItem>
                                            <MenuItem value={'25-34'}>25-34</MenuItem>
                                            <MenuItem value={'35-44'}>35-44</MenuItem> */}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={3} sm={3} xs={3}>
                    <Button
                      type="submit"
                      className={classes.addActionBtn}
                      variant="outlined"
                      color="primary"
                    >
                      SUBMIT
                    </Button>
                  </Grid>
                </Grid>
              </div>
              <div>
                <Grid container spacing={3}>
                  <Grid item md={3} sm={3} xs={3}>
                    <div>
                      <Grid container spacing={1}>
                        <Grid item md={12} sm={12} xs={12}>
                          <h2 className={classes.formTitle}>
                            Media Owner Top 5 Countries
                          </h2>
                          <FormControl
                            component="fieldset"
                            className={classes.checkNRadio}
                          >
                            {[
                              "Country1",
                              "Country2",
                              "Country3",
                              "Country4",
                              "Country5",
                            ].map((val, index) => {
                              const values = ["c1", "c2", "c3", "c5", "c5"];
                              return (
                                <FormControlLabel
                                  key={val}
                                  control={
                                    <Controller
                                      name={`top5Countries[${values[index]}]`}
                                      control={control}
                                      // rules={{ required: true }}
                                      render={(props) => (
                                        <TextField
                                          className={classes.customfield}
                                          variant="outlined"
                                          margin="normal"
                                          label={val}
                                          onChange={(e) =>
                                            props.onChange(e.target.value)
                                          }
                                          value={props.value}
                                          InputLabelProps={{
                                            style: { color: "grey" },
                                          }}
                                        />
                                      )} // props contains: onChange, onBlur and value
                                    />
                                  }
                                ></FormControlLabel>
                              );
                            })}
                          </FormControl>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item md={3} sm={3} xs={3}>
                    <div>
                      <Grid container spacing={1}>
                        <Grid item md={12} sm={12} xs={12}>
                          <h2 className={classes.formTitle}>
                            Media Owner Name
                          </h2>
                          <TextField
                            className={classes.customfield}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="name"
                            label="name"
                            name="name"
                            inputRef={register({ required: true })}
                            error={errors.name?.type === "required"}
                          />
                        </Grid>
                      </Grid>

                      <FormControl
                        component="fieldset"
                        error={Boolean(errors.politicalBeliefs)}
                        className={classes.checkNRadio}
                      >
                        <FormLabel component="legend">Gender Split</FormLabel>
                        {[
                          "Male",
                          "Female",
                          "Non-Binery",
                          "Gender-fluid",
                          "Transgender",
                          "Inter-sex",
                          "Two-spirit",
                          "Gender Non-Confirming",
                        ].map((val, index) => {
                          const values = [
                            "male",
                            "female",
                            "nonBinery",
                            "genderFluid",
                            "transgender",
                            "intersex",
                            "twoSpirit",
                            "nonConfirming",
                          ];
                          return (
                            <FormControlLabel
                              key={val}
                              label={val}
                              control={
                                <Controller
                                  name={`genderSplit[${values[index]}]`}
                                  control={control}
                                  defaultValue={false}
                                  // rules={{ required: true }}
                                  render={(props) => (
                                    <Checkbox
                                      onChange={(e) =>
                                        props.onChange(e.target.checked)
                                      }
                                      checked={props.value}
                                    />
                                  )} // props contains: onChange, onBlur and value
                                />
                              }
                            ></FormControlLabel>
                          );
                        })}
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item md={3} sm={3} xs={3}>
                    <div>
                      <FormControl
                        component="fieldset"
                        error={Boolean(errors.income)}
                        className={classes.checkNRadio}
                      >
                        <FormLabel component="legend">
                          Audience Ethnicity
                        </FormLabel>
                        <Controller
                          as={
                            <RadioGroup>
                              <FormControlLabel
                                value="arab"
                                control={<Radio />}
                                label="Arab"
                              />
                              <FormControlLabel
                                value="black"
                                control={<Radio />}
                                label="Black(African/Caribbean)"
                              />
                              <FormControlLabel
                                value="whiteBritish"
                                control={<Radio />}
                                label="White British"
                              />
                              <FormControlLabel
                                value="mediterranean"
                                control={<Radio />}
                                label="Mediterranean"
                              />
                              <FormControlLabel
                                value="mixed"
                                control={<Radio />}
                                label="Mixed Biracial"
                              />
                              <FormControlLabel
                                value="southAsian"
                                control={<Radio />}
                                label="South Asian"
                              />
                              <FormControlLabel
                                value="whiteOther"
                                control={<Radio />}
                                label="White Other"
                              />
                              <FormControlLabel
                                value="asianOther"
                                control={<Radio />}
                                label="Asian Other"
                              />
                              <FormControlLabel
                                value="other"
                                control={<Radio />}
                                label="Other"
                              />
                            </RadioGroup>
                          }
                          name="ethnicity"
                          control={control}
                        />
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item md={3} sm={3} xs={3}>
                    <div>
                      <FormControl
                        component="fieldset"
                        error={Boolean(errors.person && errors.person.age)}
                        className={classes.checkNRadio}
                      >
                        <FormLabel component="legend">
                          Age of Audience
                        </FormLabel>
                        {[
                          "age_16_24",
                          "age_25_34",
                          "age_35_44",
                          "age_45_54",
                          "age_55_64",
                          "age_65",
                        ].map((val) => {
                          return (
                            <FormControlLabel
                              key={val}
                              label={val}
                              control={
                                <Controller
                                  name={`audinceAge[${val}]`}
                                  control={control}
                                  defaultValue={false}
                                  render={(props) => (
                                    <Checkbox
                                      onChange={(e) =>
                                        props.onChange(e.target.checked)
                                      }
                                      checked={props.value}
                                    />
                                  )} // props contains: onChange, onBlur and value
                                />
                              }
                            ></FormControlLabel>
                          );
                        })}
                      </FormControl>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div>
                <Grid container spacing={3}>
                  <Grid item md={8} sm={8} xs={8}>
                    <h2 className={classes.formTitle}>Media Owner Channel</h2>
                    <FormControl
                      component="fieldset"
                      error={Boolean(errors.income)}
                      className={classes.checkNRadio2}
                    >
                      <RadioGroup
                        style={{ flexDirection: "row" }}
                        onChange={(e) => setChannelQuestioner(e.target.value)}
                      >
                        <FormControlLabel
                          value="print"
                          control={<Radio />}
                          label="print"
                        />
                        <FormControlLabel
                          value="radio"
                          control={<Radio />}
                          label="Radio/Podcast"
                        />
                        <FormControlLabel
                          value="degital"
                          control={<Radio />}
                          label="Degital/Online"
                        />
                        <FormControlLabel
                          value="mobileApp"
                          control={<Radio />}
                          label="Mobile App"
                        />
                        <FormControlLabel
                          value="tv"
                          control={<Radio />}
                          label="TV"
                        />
                        <FormControlLabel
                          value="ooh"
                          control={<Radio />}
                          label="OOH"
                        />
                        <FormControlLabel
                          value="social"
                          control={<Radio />}
                          label="Social Media"
                        />
                      </RadioGroup>

                      {/* <Controller
                                            as={
                                                <RadioGroup style={{ flexDirection: 'row' }} onChange={(e)=>setChannelQuestioner(e.target.value)}>
                                                    <FormControlLabel value="print" control={<Radio />} label="print" />
                                                    <FormControlLabel value="radio" control={<Radio />} label="Radio/Podcast" />
                                                    <FormControlLabel value="degital" control={<Radio />} label="Degital/Online" />
                                                    <FormControlLabel value="mobileApp" control={<Radio />} label="Mobile App" />
                                                    <FormControlLabel value="tv" control={<Radio />} label="TV" />
                                                    <FormControlLabel value="ooh" control={<Radio />} label="OOH" />
                                                    <FormControlLabel value="social" control={<Radio />} label="Social Media" />
                                                    
                                                </RadioGroup>
                                            }
                                            name="channel"
                                            aria-label="channel"
                                            // rules={{ required: "this is required" }}
                                            
                                            control={control}
                                        /> */}
                    </FormControl>
                  </Grid>
                  <Grid item md={3} sm={3} xs={3}>
                    <h2 className={classes.formTitle}>Based In Country</h2>
                    <TextField
                      className={classes.customfield}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="BasedCountry"
                      label="BasedCountry"
                      name="basedCountry"
                      inputRef={register}
                    />
                  </Grid>
                </Grid>
              </div>
              <div>
                <Grid container spacing={3}>
                  {questionsList &&
                    questionsList.map((x) => {
                      return (
                        <Grid item md={4} sm={4} xs={12}>
                          {x.question}
                          <TextField
                            className={classes.customfield}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            onChange={(e) => handleQuestion(e, x.question)}
                          />
                        </Grid>
                      );
                    })}
                </Grid>
              </div>
            </form>
          </Grid>
        </Grid>
        <Modal
          isModalOpen={modalOpen}
          redirect="/media-owner/personas"
          message="Your new persona has been saved"
        ></Modal>
      </div>
    </div>
  );
};

export default Questionneries;
