import React from "react";
import MainLayout from "../../layouts/newMainLayout";
const Faq = () => {
  return (
    <div style={{ color: "#4F74C9" }}>
      <span style={{ fontSize: "30px", display: "block" }}>
        Frequently Asked Questions
      </span>
      <span
        style={{
          marginTop: "20px",
          fontSize: "15px",
          display: "block",
          color: "#FC666F",
          fontWeight: "700",
        }}
      >
        Point One
      </span>
      <p style={{ marginTop: "0" }}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Bibendum est
        ultricies integer quis. Iaculis urna id volutpat lacus laoreet. Mauris
        vitae ultricies leo integer malesuada. Ac odio tempor orci dapibus
        ultrices in. Egestas diam in arcu cursus euismod. Dictum fusce ut
        placerat orci nulla. Tincidunt ornare massa eget egestas purus viverra
        accumsan in nisl. Tempor id eu nisl nunc mi ipsum faucibus. Fusce id
        velit ut tortor pretium.
      </p>
      <span
        style={{
          marginTop: "20px",
          fontSize: "15px",
          display: "block",
          color: "#FC666F",
          fontWeight: "700",
        }}
      >
        Point Two
      </span>
      <p style={{ marginTop: "0" }}>
        Massa ultricies mi quis hendrerit dolor magna eget. Nullam eget felis
        eget nunc lobortis. Faucibus ornare suspendisse sed nisi. Sagittis eu
        volutpat odio facilisis mauris sit amet massa. Erat velit scelerisque in
        dictum non consectetur a erat. Amet nulla facilisi morbi tempus iaculis
        urna. Egestas purus viverra accumsan in nisl. Feugiat in ante metus
        dictum at tempor commodo. Convallis tellus id interdum velit laoreet.
        Proin sagittis nisl rhoncus mattis rhoncus urna neque viverra. Viverra
        aliquet eget sit amet tellus cras adipiscing enim eu. Ut faucibus
        pulvinar elementum integer enim neque volutpat ac tincidunt. Dui
        faucibus in ornare quam.
      </p>
      <span
        style={{
          marginTop: "20px",
          fontSize: "15px",
          display: "block",
          color: "#FC666F",
          fontWeight: "700",
        }}
      >
        Point Three
      </span>
      <p style={{ marginTop: "0" }}>
        In iaculis nunc sed augue lacus viverra vitae congue. Vitae tempus quam
        pellentesque nec nam aliquam sem et. Ut morbi tincidunt augue interdum.
        Sem fringilla ut morbi tincidunt augue. Morbi enim nunc faucibus a
        pellentesque sit amet porttitor eget. In est ante in nibh mauris.
      </p>
      <span
        style={{
          marginTop: "20px",
          fontSize: "15px",
          display: "block",
          color: "#FC666F",
          fontWeight: "700",
        }}
      >
        Point Four
      </span>
      <p style={{ marginTop: "0" }}>
        Nam aliquam sem et tortor consequat id porta nibh. Diam quis enim
        lobortis scelerisque fermentum dui faucibus. Non curabitur gravida arcu
        ac. Magna fringilla urna porttitor rhoncus dolor. Aenean et tortor at
        risus viverra adipiscing. Dignissim sodales ut eu sem.
      </p>
    </div>
  );
};
export default MainLayout(Faq);
