import React from 'react';
import Select from 'react-select';

const CustomDropdown = (props) => {
  const {
    isMulti,
    onChange,
    value,
    options,
    isDisabled,
    className,
    components,
    placeholder,
    isClearable,
    isSearchable,
    containerClassName,
    imageData,
    defaultValue,
  } = props;

  return (
    <div className={containerClassName}>
      {imageData ? (
        <Select
          value={value}
          onChange={onChange}
          options={options}
          isMulti={isMulti}
          isDisabled={isDisabled}
          className={className}
          components={components}
          placeholder={placeholder}
          isClearable={isClearable}
          isSearchable={true}
          formatOptionLabel={imageData => {
           if(imageData){
             return(
              <div className="">
                <img src={imageData.value} alt="imageData-image" width="100%" height="100%"/>
                <span>{imageData.label}</span>
              </div>
             ) 
           }
          }}
        />
      ):(
        <Select
          value={value}
          onChange={onChange}
          options={options}
          isMulti={isMulti}
          isDisabled={isDisabled}
          className={className}
          components={components}
          placeholder={placeholder}
          isClearable={isClearable}
          isSearchable={true}
          defaultValue={defaultValue}
        />
      )}
    </div>
  );
};

export default CustomDropdown;
