/* eslint-disable react-hooks/rules-of-hooks */
import Highcharts, { color } from "highcharts";
import HighchartReact from "highcharts-react-official";
import React, { useEffect, useState } from "react";
import sentmentLogo from "../../assets/sentimentLogo.PNG";
import MainLayout from "../../layouts/newMainLayout";

import { fade, makeStyles } from "@material-ui/core/styles";

import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import { useDispatch, useSelector } from "react-redux";

import TextField from "@material-ui/core/TextField";

import ShowAlert from "../../components/alerts";
import * as actions from "../../redux/actions/user";

import axios from "axios";
import { API } from "../../constants/index";

import CircularProgress from "@material-ui/core/CircularProgress";

import { SECRET_KEY } from "../../constants/index";

// Select
import { Button } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import MultiSelect from "react-multi-select-component";
import useStyles from "../../components/StyleClasses";

const sentimentView = (props) => {
  const dispatch = useDispatch();

  const [demographic, setDemographic] = useState([]);
  const [industry, setIndustry] = useState(0);
  const [age, setAge] = useState([]);
  const [topic, setTopic] = useState(0);
  const [country, setCountry] = useState(0);
  const [brand, setBrand] = useState(0);
  const [pubYear, setPubYear] = useState(0);
  const [topicName, setTopicname] = useState("");
  const [countryName, setCountryname] = useState("");
  const [GraphData, setGraphData] = useState(null);
  const [BrandList, setBrandList] = useState([]);

  const [loading, setLoading] = useState(false);

  const dropdownData = useSelector((state) => state.adminDropDownData);

  const refresh = () => {
    const access_token = window.localStorage.getItem(SECRET_KEY);
    if (
      demographic != 0 &&
      industry != 0 &&
      age != 0 &&
      brand != 0 &&
      country != 0 &&
      topic != 0 &&
      pubYear != 0
    ) {
      setLoading(true);

      const body = {
        country: country,
        brand: brand,
        demographics: demographic,
        industry: industry,
        ageGroup: age,
        topic: topic,
        publishYear: parseInt(pubYear.toString()),
        countryName: countryName,
        topicName: topicName,
      };
      console.log("sending data>>>>>>>>>>>>>>>>>>>>>>>>>>>", body);
      axios
        .post(`${API}/mediaPlanner/intelligence/sentiments?`, body, {
          headers: { Authorization: `Bearer ${access_token}` },
        })
        .then((res, err) => {
          setLoading(false);
          if (err) {
            console.log(err);
          } else {
            console.log("sentiment  resp >>>>>>>>>>>>>", res);
            if (res.data.AgeGroups.names.length > 0) {
              /// change condition later when res.data comes as empty array
              setGraphData(res.data);
            } else {
              setGraphData(null);
            }
          }
        })
        .catch(function (error) {
          if (error) {
          }
        });
    }
  };

  useEffect(() => {
    if (dropdownData && topic != 0) {
      const selected = dropdownData.topics.filter((e) => e._id === topic);
      if (selected.length > 0) {
        setTopicname(selected[0].name);
      }
    }
  }, [topic]);
  useEffect(() => {
    if (dropdownData && country != 0) {
      const selected = dropdownData.countries.filter((e) => e._id === country);
      setCountryname(selected[0].name);
    }
  }, [country]);

  const getBrands = () => {
    const access_token = window.localStorage.getItem(SECRET_KEY);
    axios
      .get(`${API}/mediaPlanner/brands?industry=${industry}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setBrandList(res.data);
        }
        if (err) {
          console.log(err);
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };
  useEffect(() => {
    getBrands();
  }, [industry]);

  useEffect(() => {
    refresh();
  }, [demographic, brand, age, industry, pubYear, country, topic]);

  const classes = useStyles();
  return (
    <div className={classes.boxShadow}>
      <div className={classes.paper}>
        <ShowAlert />
        <form
          className={classes.form}
          // ref={useRef()  }
          //onSubmit={handleSubmit(onSubmit)}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              fontSize: "10px",
            }}
          >
            <img className={classes.logo} src={sentmentLogo} />
            <div style={{ width: "30%", margin: "4%" }}>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
                style={{ marginTop: "10px" }}
              >
                <InputLabel id="demo-mutiple-checkbox-label">
                  DemoGraphics
                </InputLabel>
                <Select
                  id="outlined-select-currency-native"
                  multiple
                  label="Demographic"
                  value={demographic}
                  onChange={(e) => setDemographic(e.target.value)}
                  variant="outlined"
                >
                  <MenuItem disabled value={0}>
                    <em>Select...</em>
                  </MenuItem>
                  {dropdownData?.demographics?.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
                style={{ marginTop: "10px" }}
              >
                <InputLabel id="demo-mutiple-checkbox-label">
                  Age Groups
                </InputLabel>
                <Select
                  id="outlined-select-currency-native"
                  multiple
                  label="Age Groups"
                  value={age}
                  onChange={(e) => setAge(e.target.value)}
                  variant="outlined"
                >
                  <MenuItem disabled value={0}>
                    <em>Select...</em>
                  </MenuItem>
                  {dropdownData?.ageGroups?.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
                style={{ marginTop: "10px" }}
              >
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Topic"
                  value={topic}
                  onChange={(e) => setTopic(e.target.value)}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {dropdownData?.topics?.map((option) => (
                    <option
                      key={option._id}
                      value={option._id}
                      valuename={option.name}
                    >
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </FormControl>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
                style={{ marginTop: "10px" }}
              >
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Country"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {dropdownData?.countries?.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </FormControl>
            </div>
            <div style={{ width: "30%", margin: "4%" }}>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
                style={{ marginTop: "10px" }}
              >
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Industry"
                  value={industry}
                  onChange={(e) => setIndustry(e.target.value)}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {dropdownData?.industries?.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </FormControl>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
                style={{ marginTop: "10px" }}
              >
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Brand"
                  value={brand}
                  onChange={(e) => setBrand(e.target.value)}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {BrandList.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </FormControl>

              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
                style={{ marginTop: "10px" }}
              >
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Publish Years"
                  value={pubYear}
                  onChange={(e) => setPubYear(e.target.value)}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {dropdownData?.publishYears?.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </TextField>
              </FormControl>
            </div>
          </div>
        </form>
        {loading && <CircularProgress />}
        {!loading && GraphData !== null && (
          <div>
            {" "}
            <HighchartReact
              Highcharts={Highcharts}
              options={{
                chart: {
                  type: "column",
                },
                title: {
                  text: GraphData.topicName,
                },
                subtitle: {
                  text: GraphData.countryName,
                },
                xAxis: {
                  categories: GraphData.Yearly.names,
                },
                yAxis: { title: { text: "sentiment score (-1 to 1)" } },
                credits: {
                  enabled: true,
                },
                series: [
                  {
                    name: "Yearly",
                    data: GraphData.Yearly.score,
                  },
                ],
              }}
            ></HighchartReact>
            <HighchartReact
              Highcharts={Highcharts}
              options={{
                chart: {
                  type: "column",
                },
                title: {
                  text: GraphData.topicName,
                },
                subtitle: {
                  text: GraphData.countryName,
                },
                xAxis: {
                  categories: GraphData.DemoGraphics.names,
                },
                yAxis: { title: { text: "sentiment score (-1 to 1)" } },
                credits: {
                  enabled: true,
                },
                series: [
                  {
                    name: "Demographics",
                    data: GraphData.DemoGraphics.score,
                  },
                ],
              }}
            ></HighchartReact>
            <HighchartReact
              Highcharts={Highcharts}
              options={{
                chart: {
                  type: "column",
                },
                title: {
                  text: GraphData.topicName,
                },
                subtitle: {
                  text: GraphData.countryName,
                },
                xAxis: {
                  categories: GraphData.AgeGroups.names,
                },
                yAxis: { title: { text: "sentiment score (-1 to 1)" } },
                credits: {
                  enabled: true,
                },
                series: [
                  {
                    name: "Age Groups",
                    data: GraphData.AgeGroups.score,
                  },
                ],
              }}
            ></HighchartReact>
          </div>
        )}
        {GraphData === null && "no data!"}
      </div>
    </div>
  );
};
export default sentimentView;

{
  /* <HighchartReact
            Highcharts={Highcharts}
            options={{
              chart: {
                type: "column",
              },
              title: {
                text: GraphData.topicName,
              },
              xAxis: {
                categories: GraphData.AgeGroups.names,
              },
              yAxis: { title: { text: "sentiment score (-1 to 1)" } },
              credits: {
                enabled: true,
              },
              series: [
                {
                  name: "Black",
                  data: [0.2, -0.8, 0.03],
                },
                {
                  name: "Indian",
                  data: [-0.6, -0.4, 0.8],
                },
                {
                  name: "White",
                  data: [0.03, 0.98, -0.65],
                },
                {
                  name: "Others",
                  data: [0.98, -0.65, 0.03],
                },
              ],
            }}
          ></HighchartReact> */
}
