import React, { useState, useEffect } from "react";

//Button
import Button from "@material-ui/core/Button";

//Icon

import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

//TextField
import { useHistory } from "react-router-dom";
import useStyles from "../../components/StyleClasses";
import { StarOutlined } from "@material-ui/icons";
import user from "../../assets/user.png";
import SinglePersona from "./singlePersona";

import CircularProgress from "@material-ui/core/CircularProgress";

import axios from "axios";
import { API, SECRET_KEY } from "../../constants/index";

function containsObject(obj, list) {
  return list.some((elem) => elem === obj);
}

const MoPublications = ({ setStep, selectedPersonas, setSelectedPersonas }) => {
  const history = useHistory();
  const classes = useStyles();
  const [channel, setChannel] = useState("Television");
  const [frequency, setFrequency] = useState(0);
  const [personas, setPersonas] = useState([]);
  const [filteredList, setfilterdList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [personaId, setPersonId] = useState("");
  const [showPersona, setshowPersona] = useState(false);
  const [err, setErr] = useState(false);

  const access_token = window.localStorage.getItem(SECRET_KEY);

  const getPersonas = () => {
    setLoading(true);
    const id = window.localStorage.getItem("ID");
    // let endPoint = `${API}/mediaPlanner/persona?name=&page=1`;
    axios
      .get(`${API}/mediaPlanner/report/${id}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
          if (res && res.data && res.data.companyId) {
            setPersonas(res.data.companyId.personas ? res.data.companyId.personas:[]);
            setLoading(false); 
          }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };

  useEffect(() => {
    getPersonas();
  }, []);
  useEffect(() => {
    setfilterdList(personas);
  }, [personas]);
  useEffect(() => {
    if (!showPersona) getPersonas();
  }, [showPersona]);

  const PersonaCard = ({ name, _id, col }) => {
    const idList = selectedPersonas.map((e) => e._id);
    const selected = idList.includes(_id);
    return (
      <div
        style={
          selected
            ? {
                boxShadow:
                  "0px 0px 10px rgba(0,0,0,0.5), 0 0 10px rgba(0,0,0,0.5)",
                borderRadius: "16px",
                marginRight: "30px",
                marginTop: "30px",
                width: "200px",
              }
            : {
                boxShadow:
                  "0px 0px 10px rgba(0,0,0,0.20), 0 0 10px rgba(0,0,0,0.15)",
                borderRadius: "16px",
                marginRight: "30px",
                marginTop: "30px",
                width: "200px",
              }
        }
      >
        <StarOutlined
          fontSize="large"
          border="5px black"
          style={
            selected
              ? {
                  color: "#FFC400",
                }
              : {
                  color: "grey",
                }
          }
          onClick={() => {
            if (selected) {
              const newdata = selectedPersonas.filter((e) => e._id !== _id);
              setSelectedPersonas(newdata);
            }
            if (!selected) {
              let newdata2 = [...selectedPersonas];
              newdata2.push({ name: name, col: col, _id: _id });
              setSelectedPersonas(newdata2);
            }
          }}
          // onClick={() => {
          //   console.log(_id);
          //   if (selected) {
          //     setSelectedPersonas(null);
          //   }
          //   if (!selected) {
          //     setSelectedPersonas({ name: name, col: col, _id: _id });
          //     setErr(false);
          //   }
          // }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
            height: "250",
            width: "150",
          }}
        >
          <img
            alt="media.Img"
            src={user}
            style={{
              backgroundImage: `linear-gradient(${col},${col},${col}, white)`,
              width: "140px",
              height: "140px",
              display: "block",
            }}
          ></img>
          <span
            style={{ fontSize: "15px", fontWeight: "700", color: "#136CC3" }}
          >
            {name}
          </span>
          <span
            style={{ fontSize: "13px", fontWeight: "400", color: "#136CC3" }}
          >
            {name}
          </span>
          <Button
            style={{ backgroundColor: "#136CC3", color: "white" }}
            onClick={() => {
              setPersonId(_id);
              setshowPersona(true);
            }}
          >
            Show Persona
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div>
      {loading ? (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "15%",
            }}
          >
            <CircularProgress />
          </div>
        </div>
      ) : (
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {/* <Button
              onClick={() => history.push("/media-planner/createPersona")}
              className={classes.addActionBtn}
              variant="outlined"
              color="primary"
              style={{
                borderRadius: "23px",
                width: "17%",
              }}
            >
              <AddCircleOutlineIcon
                style={{ marginRight: "5px", color: "white" }}
              />
              Create Persona
            </Button> */}
            <div
              className={classes.addActionBtn}
              style={{
                border: "solid 2px #E5E5E5",
                color: "black",
                borderRadius: "23px",
                padding: "7px",
                backgroundColor: "white",
                width: "100%",
                boxShadow:
                  "0px 0px 10px rgba(0,0,0,0.20), 0 0 10px rgba(0,0,0,0.15)",
              }}
            >
              <input
                placeholder="Search Personas"
                //className={classes.addActionBtn}
                style={{
                  outline: "none",
                  backgroundColor: "white",
                  border: "solid 2px #E5E5E5",
                  color: "black",
                  borderRadius: "23px",
                  padding: "7px",
                  width: "100%",
                }}
                onChange={(event) => {
                  let toshow = personas.filter((e) =>
                  e.persona &&  e.persona.about ? e.persona.about.name
                      .toLowerCase()
                      .includes(event.target.value.toLowerCase()) :''
                  );
                  setfilterdList(toshow);
                }}
              ></input>
            </div>
          </div>
          {showPersona ? (
            <div>
              <SinglePersona
                id={personaId}
                back={() => setshowPersona(false)}
                istemplate={false}
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                marginTop: "30px",
                flexWrap: "wrap",
              }}
            >
              {filteredList.map((e, index) => (
                <PersonaCard
                  key={index}
                  name={e.persona &&  e.persona.about ? e.persona.about.name:''}
                  col={e.persona ? e.persona.avatarColor :''}
                  _id={e.persona ? e.persona._id:''}
                  // selected={selectedPersonas?._id == e._id}
                  // selected={containsObject(
                  //   {
                  //     name: e.about.name,
                  //     col: e.avatarColor,
                  //     _id: e._id,
                  //   },
                  //   [...selectedPersonas]
                  // )}
                />
              ))}
            </div>
          )}

          {!showPersona && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <button
                className={classes.addActionBtn}
                onClick={() => setStep(0)}
              >
                Previous
              </button>
              {err && (
                <span style={{ color: "red", textAlign: "center" }}>
                  please select a persona
                </span>
              )}
              <button
                className={classes.addActionBtn}
                onClick={() => {
                  if (selectedPersonas.length < 1) {
                    setErr(true);
                  } else {
                    setStep(2);
                  }
                }}
              >
                Next
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default MoPublications;
