import React, {useState, useEffect} from "react";
import MainLayout from "../../layouts/consultantMainLayout";
import Pagination from "@material-ui/lab/Pagination";

import CircularProgress from "@material-ui/core/CircularProgress";
//Button
import Button from "@material-ui/core/Button";
import NewAlert from "../../components/NewAlert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
//Icon

import useStyles from "../../components/StyleClasses";
import CustomizedTables from "../mediaOwner/mytable";
import { API, SECRET_KEY } from "../../constants/index";
import axios from "axios";

const Accounts = () => {
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);
  const [DATA, setDATA] = useState(null);
  const [loading, setloading] = useState(false);
  const [page, setpage] = useState(1);
  const [pagecount, setPageCount] = useState(0);
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  
  ///////errors & successs //////////
  const [ErrSucMsg, setErrSucMesg] = useState("");
  const [ErrSucType, setErrSucType] = useState(true);
  
  const access_token = window.localStorage.getItem(SECRET_KEY);
  
  const getCompletedSurveyList = () => {
    setloading(true);
    setTableData([]);
    axios
      .get(`${API}/admin/creativeconsultant?page=${page}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setloading(false);
          setDATA(res.data.data);
          setPageCount(res.data.total);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };
  useEffect(() => getCompletedSurveyList(), []);
  useEffect(() => getCompletedSurveyList(), [page]);

  useEffect(() => {
    if (DATA) {
      let td = [];
      DATA.forEach((e) => {
        td.push({
          data: [
            e.name,
            e.email,
            e.country.name,
            e.demographic.name,
          ],
          _id: e._id,
        });
      });
      setTableData(td);
    }
  }, [DATA]);

  const deleteConsultant = () => {
    axios
      .delete(`${API}/admin/creativeconsultant/${deleteId}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setErrSucMesg(res.data.message);
          setErrSucType(true);
          getCompletedSurveyList();
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.data) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
          getCompletedSurveyList();
        }
      });
  };

  return (
    <div>
       <NewAlert
          msg={ErrSucMsg}
          isSuccess={ErrSucType}
          close={() => setErrSucMesg("")}
        />
          {deleteFlag && (
          <Dialog
            open={deleteFlag}
            onClose={() => {
              setDeleteFlag(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Are you sure, you want to delete Account?
            </DialogTitle>
            <DialogActions>
              <Button
                style={{ color: "black", backgroundColor: "grey" }}
                onClick={() => {
                  setDeleteFlag(false);
                }}
              >
                Cancel
              </Button>
              <Button
                style={{ backgroundColor: "#4F74C9", color:"white"}}
                onClick={() => {
                  setDeleteFlag(false);
                  deleteConsultant();
                }}
                autoFocus
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        )}
       <div style={{ display: "flex", justifyContent: "space-between" }}>
       <h3>Accounts</h3>
      </div>
      <div className={classes.boxShadow} style={{ marginTop: "10px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <span
            style={{
              color: "#FB6D6A",
              fontWeight: "600",
              margin: "0",
              fontSize: "20px",
              marginLeft: "20px",
            }}
          >
          </span>
          <span
            style={{
              color: "#FB6D6A",
              fontWeight: "600",
              margin: "0",
              fontSize: "20px",
              marginRight: "20px",
            }}
          >
            <Pagination
              count={Math.ceil(pagecount / 10)}
              color="primary"
              onChange={(e, page) => setpage(page)}
              size="small"
            />
          </span>
        </div>
        <CustomizedTables
          isEditable={true}
          headings={[
            "Report",
            "Amount",
            "Payment Pending",
            "Payment Date",
          ]}
          listdata={tableData}
          redirectLink={"createConsultant?id="}
          handleDelete={(_id) => {
            setDeleteFlag(true);
            setDeleteId(_id);
          }}
        />
        <div style={{ display: "flex", justifyContent: "center" }}>
          {loading && <CircularProgress />}
        </div>
      </div>
    </div>
  );
};
export default MainLayout(Accounts);
