import React, { useEffect, useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useSelector, useDispatch } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import * as actions from "../redux/actions/user";

const ShowAlert = () => {
  const [Shownotification, setShownotification] = useState(true);
  const dispatch = useDispatch();
  const error = useSelector((state) => state.alertError);
  const sucess = useSelector((state) => state.alertSucess);
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  // useEffect(() => {
  //   if (Shownotification) {
  //     setShownotification(false);
  //   }
  // }, [Shownotification]);
  const AlertSuccess = ({ msg }) => {
    setTimeout(() => {
      // dispatch(actions.alertSucess(null));
      setShownotification(false);
    }, 3000);
    return (
      <Snackbar open={Shownotification}>
        <Alert severity="success">
          {msg}{" "}
          <IconButton
            aria-label="close"
            color="inherit"
            style={{ padding: "2px" }}
            onClick={() => {
              //dispatch(actions.alertSucess(null));
              setShownotification(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Alert>
      </Snackbar>
    );
  };
  const AlertError = ({ msg }) => {
    // setTimeout(() => {
    //   dispatch(actions.alertError(null));
    //   setShownotification(false);
    // }, 3000);
    return (
      <Snackbar open={Shownotification}>
        <Alert severity="error">
          {msg}{" "}
          <IconButton
            aria-label="close"
            color="inherit"
            style={{ padding: "2px" }}
            onClick={() => {
              //dispatch(actions.alertError(null));
              setShownotification(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Alert>
      </Snackbar>
    );
  };
  return (
    <div>
      {/* {error && false && <AlertError msg={error} />}
      {sucess && false && <AlertSuccess msg={sucess} />} */}
    </div>
  );
};

export default ShowAlert;
