import React from "react"
import { useMutation } from "@tanstack/react-query"
import axios from "axios"
import { useHistory, useParams } from "react-router-dom"
import moment from "moment"
import { API } from "../../../../constants"
import { AuthHeader } from "../../../../constants/AuthHeader"
import { useAppToastify } from "../../../../utils/useAppToastify"
import { useChannelAssignmentPageCtx } from "../ChannelAssignmentPage"

export default function useSaveChannelAssignFeature({ states, updateMode = false, updateModeOnSuccessCb, channel_id,assigned_channel_id }) {
    const history = useHistory()
    const pageParams = useParams()
    const { advertiserId, campaignId, campaignType, campaignName, advertiser_name } = useParams()

    const { showAppToast } = useAppToastify()
    const { selectedServeType } = useChannelAssignmentPageCtx()

    // api for assigning the channel
    const { mutate: assignChannel, isLoading: isAssigningChannel } = useMutation({
        mutationFn: (body) => {
            return axios.post(`${API}/mediaPlanner/AdButler/placement`, body, {
                ...AuthHeader,
            })
        },
        mutationKey: ["assignChannel"],
        onSuccess: (res) => {
            showAppToast(res.data.message, { variant: "success" })
            if (updateMode) {
                updateModeOnSuccessCb()
            } else {
                history.replace(
                    `/media-planner/campaign/advertiser/${advertiserId}/${campaignId}/${campaignType}/${campaignName}/${advertiser_name}`
                )
            }
        },
        onError: (err) => {
            if (err?.response?.data?.message) {
                showAppToast(err?.response?.data?.message, { variant: "error" })
            } else {
                err?.message && showAppToast(err?.message, { variant: "error" })
            }
        },
        enabled: false,
        refetchOnWindowFocus: false,
    })

    // api for assigning the channel
    const { mutate: updateAssignedChannelDetails, isLoading: isUpdatingAssignChannel } = useMutation({
        mutationFn: ({assigned_channel_id, body}) => {
            return axios.put(`${API}/mediaPlanner/AdButler/placement/${assigned_channel_id}`, body, {
                ...AuthHeader,
            })
        },
        mutationKey: ["updateAssignChannelDetails"],
        onSuccess: (res) => {
            showAppToast(res.data.message, { variant: "success" })
            if (updateMode) {
                updateModeOnSuccessCb()
            } else {
                history.replace(
                    `/media-planner/campaign/advertiser/${advertiserId}/${campaignId}/${campaignType}/${campaignName}/${advertiser_name}`
                )
            }
        },
        onError: (err) => {
            if (err?.response?.data?.message) {
                showAppToast(err?.response?.data?.message, { variant: "error" })
            } else {
                err?.message && showAppToast(err?.message, { variant: "error" })
            }
        },
        enabled: false,
        refetchOnWindowFocus: false,
    })

    const saveCampaign = () => {
        const mergedStates = { ...states.current }

        // payload needed for assignChannel
        const newPayloads = {}

        newPayloads["weight"] = mergedStates["weight"]

        if (mergedStates["financials"]["advertiser_rates"].enableMode) {
            const advertiser_values = mergedStates["financials"]["advertiser_rates"]
            newPayloads.cost = {}
            if (advertiser_values["fixed"].checked) {
                newPayloads["cost"]["fixed_cost"] = Number(advertiser_values["fixed"].value)
            } else {
                if (advertiser_values["cpa"].checked) {
                    newPayloads["cost"]["cpa"] = Number(advertiser_values["cpa"].value)
                }
                if (advertiser_values["cpc"].checked) {
                    newPayloads["cost"]["cpc"] = Number(advertiser_values["cpc"].value)
                }
                if (advertiser_values["cpm"].checked) {
                    newPayloads["cost"]["cpm"] = Number(advertiser_values["cpm"].value)
                }
            }
        }
        if (mergedStates["financials"]["publisher_payout"]["selected_option"].value !== "none") {
            if (mergedStates["financials"]["publisher_payout"].enableMode) {
                const publisher_payout_values = mergedStates["financials"]["publisher_payout"]
                newPayloads.payout = {
                    type: publisher_payout_values.selected_option.value,
                }
                if (publisher_payout_values["fixed"].checked) {
                    newPayloads["payout"]["fixed"] = Number(publisher_payout_values["fixed"].value)
                } else {
                    if (publisher_payout_values["cpa"].checked) {
                        newPayloads["payout"]["cpa"] = Number(publisher_payout_values["cpa"].value)
                    }
                    if (publisher_payout_values["cpc"].checked) {
                        newPayloads["payout"]["cpc"] = Number(publisher_payout_values["cpc"].value)
                    }
                    if (publisher_payout_values["cpm"].checked) {
                        newPayloads["payout"]["cpm"] = Number(publisher_payout_values["cpm"].value)
                    }
                }
            }
        }
        if (mergedStates["schedule_key"]) {
            newPayloads["schedule_id"] = Number(mergedStates["schedule_key"])
        }
        newPayloads["priority"] = mergedStates["priority"].value
        newPayloads["serve_method"] = selectedServeType
        newPayloads["campaign_id"] = Number(pageParams.campaignId)
        newPayloads["campaign_type"] = pageParams.campaignType
        newPayloads["placement_for"] = "channel"
        if (updateMode) {
            newPayloads["channel"] = Number(channel_id)
        } else {
            newPayloads["channel"] = Number(pageParams.selectedChannelId)
        }
        newPayloads["delivery_method"] = mergedStates["delivery_pacing"].value

        if (mergedStates["isScheduleChecked"]) {
            newPayloads["start_date"] =
                moment(mergedStates["startDate"]).format("yyyy-MM-DD") + " " + mergedStates["startHour"].value
            newPayloads["end_date"] =
                moment(mergedStates["endDate"]).format("yyyy-MM-DD") + " " + mergedStates["endHour"].value
        }

        if (mergedStates["isQuotaChecked"]) {
            newPayloads["quota_lifetime"] = mergedStates["quota__total"]
            newPayloads["quota_type"] = mergedStates["quota__selection"].value
        }

        newPayloads["under_delivery_behaviour"] = mergedStates["under_delivery_behaviour"].value

        newPayloads["day_cap_limit"] =
            mergedStates["frequency_capping__daily_limit"] == 0
                ? null
                : Number(mergedStates["frequency_capping__daily_limit"])
        newPayloads["day_cap_type"] = mergedStates["frequency_capping__daily_views_or_clicks"].value
        newPayloads["per_user_view_limit"] =
            mergedStates["frequency_capping__user_limit_views"] == 0
                ? null
                : String(mergedStates["frequency_capping__user_limit_views"])
        newPayloads["per_user_view_period"] =
            mergedStates["frequency_capping__user_limit_days"] == 0
                ? null
                : String(mergedStates["frequency_capping__user_limit_days"])

        if (mergedStates["isDaypartingChecked"]) {
            newPayloads["day_ranges"] = mergedStates["partingSlots"]
            newPayloads["day_parting_name"] = `${pageParams.campaignName} - ${pageParams.selectedChannelName}`
            newPayloads["day_parting_type"] = mergedStates["day_parting_type"].value
            newPayloads["day_parting"] = mergedStates["isDaypartingChecked"]
        }
        newPayloads["enable_payout"] = mergedStates["financials"]["publisher_payout"]["selected_option"].value != "none"

        newPayloads["keywords"] = mergedStates["keywords"]
        newPayloads["keywords_match_method"] = mergedStates["keywords_match_method"]
        // newPayloads["contextual_segments"] = mergedStates["contextual_segments"]?.reduce((acc, segment) => {
        //     acc.push(segment.value)
        //     return acc
        // }, [])
        // newPayloads["contextual_segments"] = [1,2]

        if ("data_key_target" in mergedStates) {
            newPayloads["data_key_target"] = mergedStates["data_key_target"]
        }

        if (mergedStates["platform_target"]?.value !== "default value") {
            newPayloads["platform_target"] = mergedStates["platform_target"].id
        }

        if (mergedStates["selected_geo_graphic"]?.value !== "default value") {
            newPayloads["geo_target"] = mergedStates["selected_geo_graphic"].value
        }

        if (updateMode) {
            updateAssignedChannelDetails({body:newPayloads, assigned_channel_id})
        } else {
            assignChannel(newPayloads)
        }
    }

    if (updateMode) {
        return { saveCampaign, isUpdatingAssignChannel }
    } else {
        return { saveCampaign, isAssigningChannel }
    }
}
