/* eslint-disable no-unused-expressions */
import {
  Box,
  Button,
  CardContent,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core/";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import NewAlert from "../../components/NewAlert";
import useStyles from "../../components/StyleClasses";
import { API, SECRET_KEY } from "../../constants/index";
import MainLayout from "../../layouts/consultantMainLayout";
import "./consultant.css";

function paramsToObject(entries) {
  const result = {};
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
}

const SurveyQuestions = () => {
  const history = useHistory();
  const classes = useStyles();
  const [loading, setloading] = useState(false);
  const [videos, setVideo] = useState([]);
  const [images, setImage] = useState([]);
  const [title, setTitle] = useState("");
  const [sizeImg, setSizeImg] = useState("minimize");
  const [sizeVid, setSizeVid] = useState("minimize");
  const [isSurveyComplete, setIsSurveyComplete] = useState("");
  const urlparams = new URLSearchParams(history.location.search);
  const paramEntries = urlparams.entries();
  const params = paramsToObject(paramEntries);
  const [questions, setQuestions] = useState([]);
  const [index, setIndex] = useState('');

  ///////errors & successs //////////
  const [ErrSucMsg, setErrSucMesg] = useState("");
  const [ErrSucType, setErrSucType] = useState(true);
  ///////////////////////////////////

  const getSurveyQuestions = () => {
    setloading(true);
    axios
      .get(`${API}/creativeconsultant/questions/getQuestions`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          getPendingSurvey(res.data);
          setloading(false);
        }
      })
      .catch(function (error) {
        if (error) {
          setloading(false);
        }
      });
  };

  const getPendingSurvey = (originalQuestions) => {
    setloading(true);
    axios
      .get(`${API}/creativeconsultant/pendingsurveys/${params?.id}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setImage(res.data.consultancyId.picture);
          setVideo(res.data.consultancyId.video);
          setTitle(res.data.consultancyId.title);
          setIsSurveyComplete(res.data.isSurveyComplete);

          // if(res.data.consultancyId && res.data.consultancyId?.createdSurvey && res.data.consultancyId?.createdSurvey?.questions){
          //   setQuestions([...res.data.consultancyId?.createdSurvey?.questions]);
          // }
          // let exQues = [];
          // let ques = [];
          // let createdQueIds = [];
          // let createdQues = [];
          // if ((res.data.consultancyId && res.data.consultancyId?.createdSurvey && res.data.consultancyId?.createdSurvey?.questions.map(i => i.hasOwnProperty('name'))[0])) {
          //   // console.log(res.data.consultancyId?.createdSurvey && res.data.consultancyId?.createdSurvey.questions);
          //   // res.data.consultancyId?.createdSurvey?.questions.map(q =>{
          //   // if(q.mediaPlannerId){
          //   //   exQues.push(q);
          //   //   setExtraQuestions(exQues);
          //   // }else{
          //   // ques.push(q);
          //   // }
          //   // });
          //   ["6377862637a3d30fab563845",
          //     "6377866837a3d30fab563846", "6377867a37a3d30fab563847"].map(i => {
          //       if ((res.data.consultancyId?.createdSurvey.questions.findIndex(j => i == j._id || i == j.qid) == -1)) {
          //         createdQueIds.push(i);
          //       }
          //     });
          //   originalQuestions.map(i => {
          //     if (createdQueIds.includes(i._id)) {
          //       createdQues.push(i);
          //     }
          //   });
          //   setQuestions([...res.data.consultancyId?.createdSurvey?.questions, ...createdQues]);
          // } else {
          //   console.log('else originalQuestions', originalQuestions);
          //   let qIds = [];
          //   if(res.data.questions.map(i => i.hasOwnProperty('name'))[0]){
          //     ["6377862637a3d30fab563845",
          //     "6377866837a3d30fab563846", "6377867a37a3d30fab563847"].map(i => {
          //       if ((res.data.questions.findIndex(j => i == j._id || i == j.qid) == -1)) {
          //         qIds.push(i);
          //       }
          //     });

          //     originalQuestions.map(i => {
          //       if (qIds.includes(i._id)) {
          //         ques.push(i);
          //       }
          //     });
          //     console.log('[...res.data.questions, ...ques]', [...res.data.questions, ...ques]);
          //   }
          // }
          let createdQues = [];
          originalQuestions?.map((dt, i)=> {
              let v = res?.data?.questions?.filter(j => j.qid == dt._id || j._id == dt._id);
              if(v.length){
                createdQues.push(v[0])
              }
            });
          setQuestions(createdQues);
          setloading(false);
        }
      })
      .catch(function (error) {
        if (error) {
          setloading(false);
        }
      });
  };

  useEffect(() => {
    getSurveyQuestions();
  }, []);

  const access_token = window.localStorage.getItem(SECRET_KEY);

  const submit = (type) => {
    setloading(true);
    let quests = [...questions]
    let body = {
      amount: 0,
      isSurveyComplete: type,
      questions: quests
    };
    if (type == "completed") {
      body.completedDate = new Date();
    }
    axios
      .put(`${API}/creativeconsultant/pendingsurveys/${params?.id}`, body, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res.data) {
          setErrSucMesg(res.data.message);
          setErrSucType(true);
          setloading(false);
          history.push("/consultant/pending-surveys");
        }
      })
      .catch((error) => {
        if (error && error.response) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
          setloading(false);
        }
      });
  };

  const radioHandleChange = (id, value) => {
    let ques = [...questions];
    ques[id].value = value;
    setQuestions(ques);
  };

  return (
    <div>
      <NewAlert
        msg={ErrSucMsg}
        isSuccess={ErrSucType}
        close={() => setErrSucMesg("")}
      />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h3>Survey Questions</h3>
        <Button color="primary" size="small" onClick={() => history.goBack()}>Back</Button>
      </div>
      <div className={classes.boxShadow} style={{ marginTop: "10px" }}>
        <Grid item xs={12}>
          <Box className={classes.cardBox}>
            <CardContent>
              <Grid container spacing={2} className={classes.cardspace}>
                <Grid item md={12} sm={12} xs={12}>
                  <h3>
                    <u>Questionnaire: </u> {title}
                  </h3>
                </Grid>
                <Grid container md={12} sm={12} xs={12}>
                  {sizeImg !== "maximize" && videos.length ? videos.map((v, idx) => (
                    <Grid
                      item
                      md={(sizeVid == "minimize" && index !== 'v-' + idx) ? 6 : index == 'v-' + idx ? 12 : 6}
                      sm={(sizeVid == "minimize" && index !== 'v-' + idx) ? 6 : index == 'v-' + idx ? 12 : 6}
                      xs={(sizeVid == "minimize" && index !== 'v-' + idx) ? 6 : index == 'v-' + idx ? 12 : 6}
                      style={{
                        position: (sizeVid == "minimize" && index !== 'v-' + idx) ? "relative" : index == 'v-' + idx ? "absolute" : "relative",
                        top: (sizeVid == "minimize" && index !== 'v-' + idx) ? "" : index == 'v-' + idx ? "-8px" : "",
                        left: (sizeVid == "minimize" && index !== 'v-' + idx) ? "" : index == 'v-' + idx ? "-8px" : "",
                        zIndex: index == 'v-' + idx ? 99 : 1,
                      }}
                    >
                      {v ? (
                        <video
                          width={sizeVid == "minimize" && index !== 'v-' + idx ? "450" : index == 'v-' + idx ? "1010" : "450"}
                          height={sizeVid == "minimize" && index !== 'v-' + idx ? "250" : index == 'v-' + idx ? "475" : "250"}
                          controls
                          style={{ background: "#fff" }}
                        >
                          <source src={v} type="video/mp4" />
                        </video>
                      ) : null}
                      {v ? (
                        <div className="maxMin">
                          {sizeVid == "maximize" ? (
                            <span
                              className="min"
                              onClick={() => {
                                setSizeVid("minimize");
                                setIndex('');
                              }}
                            >
                              -
                            </span>
                          ) : null}
                          {sizeVid == "minimize" ? (
                            <span
                              className="max"
                              onClick={() => {
                                setSizeVid("maximize");
                                setSizeImg("minimize");
                                setIndex('v-' + idx);
                              }}
                            >
                              +
                            </span>
                          ) : null}
                        </div>
                      ) : null}
                    </Grid>
                  )) : null}
                  {sizeVid !== "maximize" && images.length ? images.map((image, idx) => (
                    <Grid
                      item
                      md={(sizeImg == 'minimize' && index !== 'i-' + idx) ? 6 : index === 'i-' + idx ? 12 : 6}
                      sm={(sizeImg == 'minimize' && index !== 'i-' + idx) ? 6 : index === 'i-' + idx ? 12 : 6}
                      xs={(sizeImg == 'minimize' && index !== 'i-' + idx) ? 6 : index === 'i-' + idx ? 12 : 6}
                      style={{
                        position: (sizeImg == 'minimize' && index !== 'i-' + idx) ? "relative" : index === 'i-' + idx ? "absolute" : "relative",
                        top: (sizeImg == 'minimize' && index !== 'i-' + idx) ? "" : index === 'i-' + idx ? "-8px" : "",
                        left: (sizeImg == 'minimize' && index !== 'i-' + idx) ? "" : index === 'i-' + idx ? "-8px" : "",
                        zIndex: index === 'i-' + idx ? 99 : 1,
                      }}
                    >
                      {image ? (
                        <img
                          src={image}
                          // width={sizeImg == "minimize" && index !== 'i-' + idx ? "450" : index === 'i-' + idx ? "1010" : "450"}
                          height={sizeImg == "minimize" && index !== 'i-' + idx ? "250" : index === 'i-' + idx ? "475" : "250"}
                        />
                      ) : null}
                      {image ? (
                        <div className="maxMin">
                          {sizeImg == "maximize" ? (
                            <span
                              className="min"
                              onClick={() => {
                                setIndex('');
                                setSizeImg("minimize");
                              }}
                            >
                              -
                            </span>
                          ) : null}
                          {sizeImg == "minimize" ? (
                            <span
                              className="max"
                              onClick={() => {
                                setSizeImg("maximize");
                                setSizeVid("minimize");
                                setIndex('i-' + idx);
                              }}
                            >
                              +
                            </span>
                          ) : null}
                        </div>
                      ) : null}
                    </Grid>
                  )) : null}
                </Grid>

                {/* These below id's are question ids for compulsory questions to filter */}

                {questions?.filter(i => !["6377862637a3d30fab563845", "6377866837a3d30fab563846", "6377867a37a3d30fab563847"].includes(i.qid || i._id)).map((quest, id) => {
                  if (quest.type == 'radio') {
                    return (<Grid item md={12} sm={12} xs={12}>
                      <h4>{quest.name}</h4>
                      <Grid container md={12} sm={12} xs={12}>
                        <Grid item md={2} sm={2} xs={2} />
                        <Grid item md={3} sm={3} xs={3}>
                          <label style={{ padding: "0 39px 0 2px" }}>1</label>
                          <label style={{ padding: "0 39px 0 2px" }}>2</label>
                          <label style={{ padding: "0 39px 0 2px" }}>3</label>
                          <label style={{ padding: "0 39px 0 2px" }}>4</label>
                          <label style={{ padding: "0 39px 0 2px" }}>5</label>
                        </Grid>
                      </Grid>
                      <Grid container md={12} sm={12} xs={12}>
                        <Grid item md={2} sm={2} xs={2}>
                          <label style={{ lineHeight: 4, fontSize: "11px" }}>
                            Strongly Disagree
                          </label>
                        </Grid>
                        <Grid item md={4} sm={4} xs={4}>
                          <RadioGroup
                            style={{ flexDirection: "row" }}
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={quest.value}
                            name={`radio-buttons-group-${quest.qid}`}
                          >
                            <FormControlLabel
                              value="1"
                              control={
                                <Radio
                                  disabled={isSurveyComplete == "completed"}
                                  checked={quest.value == "1" ? true : false}
                                  onChange={(e) => {
                                    quest.value = e.target.value;
                                    let q = [...questions]
                                    setQuestions(q);
                                    // radioHandleChange(id, e.target.value)
                                  }}
                                />
                              }
                            />
                            <FormControlLabel
                              value={2}
                              control={
                                <Radio
                                  disabled={isSurveyComplete == "completed"}
                                  checked={quest.value == "2" ? true : false}
                                  onChange={(e) => {
                                    quest.value = e.target.value;
                                    let q = [...questions]
                                    setQuestions(q);
                                    // radioHandleChange(id, e.target.value)
                                  }}
                                />
                              }
                            />
                            <FormControlLabel
                              value={3}
                              control={
                                <Radio
                                  disabled={isSurveyComplete == "completed"}
                                  checked={quest.value == 3 ? true : false}
                                  onChange={(e) => {
                                    quest.value = e.target.value;
                                    let q = [...questions]
                                    setQuestions(q);
                                    // radioHandleChange(id, e.target.value)
                                  }}
                                />
                              }
                            />
                            <FormControlLabel
                              value={4}
                              control={
                                <Radio
                                  disabled={isSurveyComplete == "completed"}
                                  checked={quest.value == 4 ? true : false}
                                  onChange={(e) => {
                                    quest.value = e.target.value;
                                    let q = [...questions]
                                    setQuestions(q);
                                    // radioHandleChange(id, e.target.value)
                                  }}
                                />
                              }
                            />
                            <FormControlLabel
                              value={5}
                              control={
                                <Radio
                                  disabled={isSurveyComplete == "completed"}
                                  checked={quest.value == 5 ? true : false}
                                  onChange={(e) => {
                                    quest.value = e.target.value;
                                    let q = [...questions]
                                    setQuestions(q);
                                    // radioHandleChange(id, e.target.value)
                                  }}
                                />
                              }
                            />
                          </RadioGroup>
                        </Grid>
                        <Grid item md={4} sm={4} xs={4}>
                          <label style={{ lineHeight: 4, fontSize: "11px" }}>
                            Strongly Agree
                          </label>
                        </Grid>
                      </Grid>
                    </Grid>)
                  }
                  if (quest.type == 'text') {
                    return (<Grid item md={12} sm={12} xs={12}>
                      <h4>{quest.name}</h4>
                      <Grid container md={8} sm={8} xs={8}>
                        <TextField
                          className={classes.customfield}
                          onChange={(e) => {
                            // let ques = [...questions];
                            // ques[id].value = e.target.value;
                            // setQuestions(ques);
                            quest.value = e.target.value;
                            let q = [...questions]
                            setQuestions(q);
                          }}
                          disabled={isSurveyComplete == 'completed'}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="search"
                          label=""
                          value={quest.value}
                        />
                      </Grid>
                    </Grid>)
                  }
                })}
                {questions?.filter(i => ["6377862637a3d30fab563845", "6377866837a3d30fab563846", "6377867a37a3d30fab563847"].includes(i.qid || i._id)).map((quest, id) => {
                  if (quest.type == 'text') {
                    return (<Grid item md={12} sm={12} xs={12}>
                      <h4>{quest.name}</h4>
                      <Grid container md={8} sm={8} xs={8}>
                        <TextField
                          className={classes.customfield}
                          onChange={(e) => {
                            // let ques = [...questions];
                            // ques[id].value = e.target.value;
                            // setQuestions(ques);
                            quest.value = e.target.value;
                            let q = [...questions]
                            setQuestions(q);
                          }}
                          disabled={isSurveyComplete == 'completed'}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="search"
                          label=""
                          value={quest.value}
                        />
                      </Grid>
                    </Grid>)
                  }
                })}
              </Grid>
              {isSurveyComplete !== "completed" && params?.id ? (
                <div
                  style={{
                    display: "flex",
                    marginTop: "40px",
                    justifyContent: "end",
                  }}
                >
                  <Grid item xs={4}></Grid>
                  <Button
                    onClick={() => submit("draft")}
                    className={classes.addActionBtn}
                    variant="outlined"
                    color="white"
                    style={{ width: "150px", borderRadius: "50px" }}
                  >
                    Save as Draft
                  </Button>
                  <Button
                    onClick={() => submit("completed")}
                    className={classes.addActionBtn}
                    variant="outlined"
                    color="white"
                    style={{
                      width: "100px",
                      borderRadius: "50px",
                      marginLeft: "10px",
                    }}
                  >
                    Save
                  </Button>
                </div>
              ) : null}
            </CardContent>
          </Box>
        </Grid>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {loading && <CircularProgress />}
        </div>
      </div>
    </div>
  );
};
export default MainLayout(SurveyQuestions);
