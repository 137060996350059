import React, { useState, useEffect } from "react";
import { makeStyles, fade } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { useForm, Controller } from "react-hook-form";

//Radio
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

//Text field
import TextField from "@material-ui/core/TextField";

// Select
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

//Textarea
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

//Button
import Button from "@material-ui/core/Button";
import { useSelector } from "react-redux";

import Checkbox from "@material-ui/core/Checkbox";

import { useDispatch } from "react-redux";
import StepWrapper from "./stepWrapper";
import MainLayout from "../../layouts/newMainLayout";
import { useHistory } from "react-router-dom";
import Modal from "../../components/modal";
import { getCsvDropdown } from "../../redux/actions/admin";
import { postMediaConsultancy } from "../../redux/actions/media";

import culturalIntell from "../../assets/Cultural-Intelligence.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "40px",
  },
  pageTitle: {
    color: "#e96941",
    fontWeight: "normal",
  },
  formTitle: {
    color: "#e96941",
    fontWeight: "normal",
  },
  paper: {
    padding: "16px",
    color: theme.palette.text.secondary,
  },
  boxShadow: {
    position: "relative",
    padding: "16px",
    boxShadow: "0px 0px 20px rgba(0,0,0,0.30), 0 20px 20px rgba(0,0,0,0.22)",
    borderRadius: "30px",
    backgroundColor: "white",
    marginTop: "-30px",
  },
  textarea: {
    width: "100%",
    backgroundColor: "#f4f4f8",
    marginTop: "8px",
    borderRadius: "5px",
    padding: "14px",
    marginBottom: "20px",
  },
  customfield: {
    paddingTop: "0",
    marginTop: "0",
    backgroundColor: "#f4f4f8",
    borderRadius: "5px",
    "& .MuiInputLabel-root.Mui-shrink": {
      color: "red",
      // "& .Mui-shrink": {
      // 	color: 'red'
      // },
    },
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&$focused": {
      backgroundColor: "#fff",
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.5px 14px",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",

      "& fieldset": {
        borderColor: "grey",
      },
      "&:hover fieldset": {},
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "grey",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "grey",
    },
    "& .MuiInputLabel-root.MuiInputLabel-shrink": {
      margin: "0!important",
    },
    "& .MuiInputLabel-root.MuiInputLabel-animated": {
      marginTop: "-8px",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",
    },
  },
  selectImgBtn: {
    marginTop: "10px",
    display: "block",
    margin: "0 auto",
    color: "#ba54f5",
    border: "1px solid #ba54f5",
    padding: "5px 30px",
  },
  addActionBtn: {
    padding: "10px",
    width: "150px",
    backgroundColor: "#136cc3",
    color: "white",
    fontWeight: "bold",
    borderRadius: "8px",
    textTransform: "none",
    "&:hover": {
      background: "#136cc3",
    },
  },
  textarea: {
    width: "100%",
    backgroundColor: "#f4f4f8",
    marginTop: "8px",
    borderRadius: "5px",
    padding: "14px",
  },
  checkNRadio: {
    padding: 0,
    "& .MuiFormLabel-root": {
      color: "#1d8bf7",
      marginBottom: "8px",
    },
    "& .MuiFormControlLabel-root": {
      "& span": {
        paddingTop: "1px",
        paddingBottom: "1px",
      },
    },
  },
  checkNRadio2: {
    padding: 0,
    "& .MuiFormLabel-root": {
      color: "#1d8bf7",
      marginBottom: "8px",
    },
    "& .MuiFormControlLabel-root": {
      "& span": {
        paddingTop: "1px",
        paddingBottom: "1px",
      },
    },
  },
  logo: {
    width: "150px",
    margin: "0 auto",
    display: "block",
    marginBottom: "30px",
  },
  customRenderfield: {
    padding: "0",
    margin: "10px",
    width: "50px",
    height: "50px",
  },
  floatingLabelFocusStyle: {
    color: "grey",
  },
}));

const MediaConsultency = () => {
  const { register, handleSubmit, errors, control } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [demographic, setDemographic] = useState();
  const [industry, setIndustry] = useState();
  const [age, setAge] = useState();

  const dispatch = useDispatch();
  const history = useHistory();

  const currentUser = useSelector((state) => state.loggedInSession);
  const dropdownData = useSelector((state) => state.dropdownData);
  const classes = useStyles();

  useEffect(() => {
    if (!dropdownData) {
      dispatch(getCsvDropdown());
    }
  }, [dropdownData]);

  const onSubmit = (data) => {
    data.demographic = demographic;
    data.ageRange = age;
    data.industry = industry;

    dispatch(postMediaConsultancy(data));
  };

  return (
    <div className={classes.boxShadow}>
      <div className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <form
              className={classes.form}
              // ref={useRef()  }
              onSubmit={handleSubmit(onSubmit)}
            >
              <div>
                <Grid container spacing={3}>
                  <Grid item md={2} sm={2} xs={2}>
                    <img className={classes.logo} src={culturalIntell} />
                  </Grid>
                  <Grid item md={2} sm={2} xs={2}>
                    <FormControl
                      fullWidth
                      component="fieldset"
                      variant="outlined"
                      className={[
                        classes.selectControl,
                        classes.customfield,
                      ].join(" ")}
                      style={{ marginTop: "10px" }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Select Age
                      </InputLabel>
                      <Select
                        aria-label="Sizes"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select1"
                        name="ageRange"
                        onChange={(e) => setAge(e.target.value)}
                        ref={register}
                      >
                        {dropdownData?.ageRange?.map((x, index) => {
                          return (
                            <MenuItem key={index} value={x.value}>
                              {x.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={2} sm={2} xs={2}>
                    <FormControl
                      fullWidth
                      component="fieldset"
                      variant="outlined"
                      className={[
                        classes.selectControl,
                        classes.customfield,
                      ].join(" ")}
                      style={{ marginTop: "10px" }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Demographic
                      </InputLabel>
                      <Select
                        aria-label="Sizes"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select1"
                        name="demographic"
                        onChange={(e) => setDemographic(e.target.value)}
                        ref={register}
                      >
                        {dropdownData?.demographic?.map((x, index) => {
                          return (
                            <MenuItem key={index} value={x.value}>
                              {x.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={2} sm={2} xs={2}>
                    <FormControl
                      fullWidth
                      component="fieldset"
                      variant="outlined"
                      className={[
                        classes.selectControl,
                        classes.customfield,
                      ].join(" ")}
                      style={{ marginTop: "10px" }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Select Industry
                      </InputLabel>
                      <Select
                        aria-label="Sizes"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select2"
                        name="industry"
                        onChange={(e) => setIndustry(e.target.value)}
                        ref={register}
                      >
                        {dropdownData?.industry?.map((x, index) => {
                          return (
                            <MenuItem key={index} value={x.value}>
                              {x.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item md={3} sm={3} xs={3}>
                    <Button
                      type="submit"
                      className={classes.addActionBtn}
                      variant="outlined"
                      color="primary"
                    >
                      SUBMIT
                    </Button>
                  </Grid>
                </Grid>
              </div>
              <div>
                <Grid container spacing={3}>
                  <Grid item md={4} sm={4} xs={4}>
                    <FormControl
                      component="fieldset"
                      className={classes.checkNRadio}
                    >
                      <FormLabel> Question 1 </FormLabel>
                      {["Answer", "Name..", "Age.."].map((val, index) => {
                        const values = ["answer", "name", "age"];
                        return (
                          <FormControlLabel
                            key={val}
                            control={
                              <Controller
                                name={`question1[${values[index]}]`}
                                control={control}
                                // rules={{ required: true }}
                                render={(props) => (
                                  <TextField
                                    className={classes.customfield}
                                    variant="outlined"
                                    margin="normal"
                                    label={val}
                                    onChange={(e) =>
                                      props.onChange(e.target.value)
                                    }
                                    value={props.value}
                                    InputLabelProps={{
                                      style: { color: "grey" },
                                    }}
                                  />
                                )} // props contains: onChange, onBlur and value
                              />
                            }
                          ></FormControlLabel>
                        );
                      })}
                    </FormControl>
                  </Grid>
                  <Grid item md={4} sm={4} xs={4}>
                    <FormControl
                      component="fieldset"
                      className={classes.checkNRadio}
                    >
                      <FormLabel> Question 2 </FormLabel>
                      {["Answer", "Name..", "Age.."].map((val, index) => {
                        const values = ["answer", "name", "age"];
                        return (
                          <FormControlLabel
                            key={val}
                            control={
                              <Controller
                                name={`question2[${values[index]}]`}
                                control={control}
                                // rules={{ required: true }}
                                render={(props) => (
                                  <TextField
                                    className={classes.customfield}
                                    variant="outlined"
                                    margin="normal"
                                    label={val}
                                    onChange={(e) =>
                                      props.onChange(e.target.value)
                                    }
                                    value={props.value}
                                    InputLabelProps={{
                                      style: { color: "grey" },
                                    }}
                                  />
                                )} // props contains: onChange, onBlur and value
                              />
                            }
                          ></FormControlLabel>
                        );
                      })}
                    </FormControl>
                  </Grid>

                  <Grid item md={4} sm={4} xs={4}>
                    <FormControl
                      component="fieldset"
                      className={classes.checkNRadio}
                    >
                      <FormLabel> Question 3 </FormLabel>
                      {["Answer", "Name..", "Age.."].map((val, index) => {
                        const values = ["answer", "name", "age"];
                        return (
                          <FormControlLabel
                            key={val}
                            control={
                              <Controller
                                name={`question3[${values[index]}]`}
                                control={control}
                                // rules={{ required: true }}
                                render={(props) => (
                                  <TextField
                                    className={classes.customfield}
                                    variant="outlined"
                                    margin="normal"
                                    label={val}
                                    onChange={(e) =>
                                      props.onChange(e.target.value)
                                    }
                                    value={props.value}
                                    InputLabelProps={{
                                      style: { color: "grey" },
                                    }}
                                  />
                                )} // props contains: onChange, onBlur and value
                              />
                            }
                          ></FormControlLabel>
                        );
                      })}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item md={4} sm={4} xs={4}>
                    <FormControl
                      component="fieldset"
                      className={classes.checkNRadio}
                    >
                      <FormLabel> Question 4 </FormLabel>
                      {["Answer", "Name..", "Age.."].map((val, index) => {
                        const values = ["answer", "name", "age"];
                        return (
                          <FormControlLabel
                            key={val}
                            control={
                              <Controller
                                name={`question4[${values[index]}]`}
                                control={control}
                                // rules={{ required: true }}
                                render={(props) => (
                                  <TextField
                                    className={classes.customfield}
                                    variant="outlined"
                                    margin="normal"
                                    label={val}
                                    onChange={(e) =>
                                      props.onChange(e.target.value)
                                    }
                                    value={props.value}
                                    InputLabelProps={{
                                      style: { color: "grey" },
                                    }}
                                  />
                                )} // props contains: onChange, onBlur and value
                              />
                            }
                          ></FormControlLabel>
                        );
                      })}
                    </FormControl>
                  </Grid>
                  <Grid item md={4} sm={4} xs={4}>
                    <FormControl
                      component="fieldset"
                      className={classes.checkNRadio}
                    >
                      <FormLabel> Question 5 </FormLabel>
                      {["Answer", "Name..", "Age.."].map((val, index) => {
                        const values = ["answer", "name", "age"];
                        return (
                          <FormControlLabel
                            key={val}
                            control={
                              <Controller
                                name={`question5[${values[index]}]`}
                                control={control}
                                // rules={{ required: true }}
                                render={(props) => (
                                  <TextField
                                    className={classes.customfield}
                                    variant="outlined"
                                    margin="normal"
                                    label={val}
                                    onChange={(e) =>
                                      props.onChange(e.target.value)
                                    }
                                    value={props.value}
                                    InputLabelProps={{
                                      style: { color: "grey" },
                                    }}
                                  />
                                )} // props contains: onChange, onBlur and value
                              />
                            }
                          ></FormControlLabel>
                        );
                      })}
                    </FormControl>
                  </Grid>

                  <Grid item md={4} sm={4} xs={4}>
                    <FormControl
                      component="fieldset"
                      className={classes.checkNRadio}
                    >
                      <FormLabel> Question 6 </FormLabel>
                      {["Answer", "Name..", "Age.."].map((val, index) => {
                        const values = ["answer", "name", "age"];
                        return (
                          <FormControlLabel
                            key={val}
                            control={
                              <Controller
                                name={`question6[${values[index]}]`}
                                control={control}
                                // rules={{ required: true }}
                                render={(props) => (
                                  <TextField
                                    className={classes.customfield}
                                    variant="outlined"
                                    margin="normal"
                                    label={val}
                                    onChange={(e) =>
                                      props.onChange(e.target.value)
                                    }
                                    value={props.value}
                                    InputLabelProps={{
                                      style: { color: "grey" },
                                    }}
                                  />
                                )} // props contains: onChange, onBlur and value
                              />
                            }
                          ></FormControlLabel>
                        );
                      })}
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
              <div>
                <Grid container spacing={3}>
                  <Grid item md={4} sm={4} xs={4}>
                    <FormControl
                      component="fieldset"
                      className={classes.checkNRadio}
                    >
                      <FormLabel> ROI REPORT </FormLabel>
                      <FormControlLabel
                        control={
                          <Controller
                            name={"roiReport[keyword]"}
                            control={control}
                            // rules={{ required: true }}
                            render={(props) => (
                              <div>
                                <TextField
                                  className={classes.customfield}
                                  variant="outlined"
                                  margin="normal"
                                  label="Keywords"
                                  onChange={(e) =>
                                    props.onChange(e.target.value)
                                  }
                                  value={props.value}
                                  InputLabelProps={{
                                    style: { color: "grey" },
                                  }}
                                />
                              </div>
                            )} // props contains: onChange, onBlur and value
                          />
                        }
                      ></FormControlLabel>
                      <FormControlLabel
                        control={
                          <Controller
                            name={"roiReport[desc]"}
                            control={control}
                            // rules={{ required: true }}
                            render={(props) => (
                              <div>
                                <TextareaAutosize
                                  className={classes.textarea}
                                  aria-label="minimum height"
                                  rowsMin={10}
                                  placeholder="description (brief)"
                                  onChange={(e) =>
                                    props.onChange(e.target.value)
                                  }
                                  value={props.value}
                                />
                              </div>
                            )} // props contains: onChange, onBlur and value
                          />
                        }
                      ></FormControlLabel>
                    </FormControl>
                  </Grid>
                  <Grid item md={4} sm={4} xs={4}>
                    <FormControl
                      component="fieldset"
                      className={classes.checkNRadio}
                    >
                      <FormLabel> AD TECH CONSIDERATION </FormLabel>
                      <FormControlLabel
                        control={
                          <Controller
                            name={"adTechConsideration[keyword]"}
                            control={control}
                            // rules={{ required: true }}
                            render={(props) => (
                              <div>
                                <TextField
                                  className={classes.customfield}
                                  variant="outlined"
                                  margin="normal"
                                  label="Keywords"
                                  onChange={(e) =>
                                    props.onChange(e.target.value)
                                  }
                                  value={props.value}
                                  InputLabelProps={{
                                    style: { color: "grey" },
                                  }}
                                />
                              </div>
                            )} // props contains: onChange, onBlur and value
                          />
                        }
                      ></FormControlLabel>
                      <FormControlLabel
                        control={
                          <Controller
                            name={"adTechConsideration[desc]"}
                            control={control}
                            // rules={{ required: true }}
                            render={(props) => (
                              <div>
                                <TextareaAutosize
                                  className={classes.textarea}
                                  aria-label="minimum height"
                                  rowsMin={10}
                                  placeholder="description (brief)"
                                  onChange={(e) =>
                                    props.onChange(e.target.value)
                                  }
                                  value={props.value}
                                />
                              </div>
                            )} // props contains: onChange, onBlur and value
                          />
                        }
                      ></FormControlLabel>
                    </FormControl>
                  </Grid>

                  <Grid item md={4} sm={4} xs={4}>
                    <FormControl
                      component="fieldset"
                      className={classes.checkNRadio}
                    >
                      <FormLabel> Media Insight </FormLabel>
                      <FormControlLabel
                        control={
                          <Controller
                            name={"mediaInsight[keyword]"}
                            control={control}
                            // rules={{ required: true }}
                            render={(props) => (
                              <div>
                                <TextField
                                  className={classes.customfield}
                                  variant="outlined"
                                  margin="normal"
                                  label="Keywords"
                                  onChange={(e) =>
                                    props.onChange(e.target.value)
                                  }
                                  value={props.value}
                                  InputLabelProps={{
                                    style: { color: "grey" },
                                  }}
                                />
                              </div>
                            )} // props contains: onChange, onBlur and value
                          />
                        }
                      ></FormControlLabel>
                      <FormControlLabel
                        control={
                          <Controller
                            name={"mediaInsight[desc]"}
                            control={control}
                            // rules={{ required: true }}
                            render={(props) => (
                              <div>
                                <TextareaAutosize
                                  className={classes.textarea}
                                  aria-label="minimum height"
                                  rowsMin={10}
                                  placeholder="description (brief)"
                                  onChange={(e) =>
                                    props.onChange(e.target.value)
                                  }
                                  value={props.value}
                                />
                              </div>
                            )} // props contains: onChange, onBlur and value
                          />
                        }
                      ></FormControlLabel>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item md={4} sm={4} xs={4}>
                    <FormControl
                      component="fieldset"
                      className={classes.checkNRadio}
                    >
                      <FormLabel> Creative Insight </FormLabel>
                      <FormControlLabel
                        control={
                          <Controller
                            name={"creativeInsight[keyword]"}
                            control={control}
                            // rules={{ required: true }}
                            render={(props) => (
                              <div>
                                <TextField
                                  className={classes.customfield}
                                  variant="outlined"
                                  margin="normal"
                                  label="Keywords"
                                  onChange={(e) =>
                                    props.onChange(e.target.value)
                                  }
                                  value={props.value}
                                  InputLabelProps={{
                                    style: { color: "grey" },
                                  }}
                                />
                              </div>
                            )} // props contains: onChange, onBlur and value
                          />
                        }
                      ></FormControlLabel>
                      <FormControlLabel
                        control={
                          <Controller
                            name={"creativeInsight[desc]"}
                            control={control}
                            // rules={{ required: true }}
                            render={(props) => (
                              <div>
                                <TextareaAutosize
                                  className={classes.textarea}
                                  aria-label="minimum height"
                                  rowsMin={10}
                                  placeholder="description (brief)"
                                  onChange={(e) =>
                                    props.onChange(e.target.value)
                                  }
                                  value={props.value}
                                />
                              </div>
                            )} // props contains: onChange, onBlur and value
                          />
                        }
                      ></FormControlLabel>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            </form>
          </Grid>
        </Grid>
        <Modal
          isModalOpen={modalOpen}
          redirect="/media-owner/personas"
          message="Your new persona has been saved"
        ></Modal>
      </div>
    </div>
  );
};

export default MediaConsultency;
