import React, { useState, useEffect } from "react";
import MainLayout from "../../layouts/adminMainLayout";
import useStyles from "../../components/StyleClasses";
import { SECRET_KEY } from "./../../constants/index";
import { useHistory } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import { API } from "../../constants/index";
import MyTable from "../mediaOwner/mytable";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import NewAlert from "../../components/NewAlert";
import Button from "@material-ui/core/Button";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

const DecaReport = () => {
  const [listDATA, setListDATA] = useState([]);
  const [listTableData, setListTableData] = useState([]);
  ///////errors & successs //////////
  const [ErrSucMsg, setErrSucMesg] = useState("");
  const [ErrSucType, setErrSucType] = useState(true);
  ///////////////////////////////////
  const classes = useStyles();
  const access_token = window.localStorage.getItem(SECRET_KEY);
  const [tableLoading, setTableLoading] = useState(false);
  const [listPage, setListPage] = useState(1);
  const [listPageCount, setListPageCount] = useState(0);
  const history = useHistory();

  ////////////////lists/////////////////////////
  const Listrefresh = () => {
    setTableLoading(true);
    setErrSucType(true);

    let URLL = `${API}/admin/report?page=${listPage}`;
    axios
      .get(URLL, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        setListDATA(res.data.data);
        setListPageCount(res.data.pagination ? res.data.pagination.total : 0);
        setListPage(res.data.pagination ? res.data.pagination.currentPage : 1);
        setTableLoading(false);
        setErrSucType(false);
      })
      .catch(function (error) {
        if (error && error.response && error.response.data) {
          setErrSucMesg(error.response.data.message);
          setTableLoading(false);
          setErrSucType(false);
        }
      });
  };
  useEffect(() => {
    Listrefresh();
  }, [listPage]);

  const templateChange = (description) => {
    const originalString = `${description}`;
    const strippedString = originalString.replace(/<\/?[^>]+(>|$)/g, "");
    const decString = strippedString.replace(/\&nbsp;/g, "");
    return decString;
  };

  const getDMYDate = (dateTime) => {
    const newdate = new Date(dateTime);
    const d = newdate.getDate();
    const m = newdate.getMonth() + 1;
    const y = newdate.getFullYear();
    const formattedate =
      (d <= 9 ? "0" + d : d) + "/" + (m <= 9 ? "0" + m : m) + "/" + y;
    return formattedate;
  };

  useEffect(() => {
    if (listDATA) {
      let td = [];
      listDATA.forEach((e) => {
        let KeysToShow = [
          e.report_name ? e.report_name : "",
          e.report_date ? getDMYDate(e.report_date) : "",
          e.report_by ? e.report_by : "NA",
          // e.executive_summary ? templateChange(e.executive_summary) : "NA",
          // e.methodology ? templateChange(e.methodology) : "NA",
          // e.discussion_and_implications
          //   ? templateChange(e.discussion_and_implications)
          //   : "NA",
        ];
        // if (e.participant_demography && e.participant_demography.length > 0) {
        //   e.participant_demography.forEach((element) => {
        //     let ansArr = "";
        //     e._qid = element._id;
        //     if (element.answers && element.answers.length) {
        //       element.answers.map((ans) => {
        //         ansArr +=
        //           ans.ans + `${element.answers.length - 1 ? ", " : " "}`;
        //       });
        //     }
        //     KeysToShow[7] = element.question ? element.question : "NA";
        //     KeysToShow[8] = ansArr ? ansArr : "NA";
        //   });
        // } else {
        //   KeysToShow[4] = "NA";
        //   KeysToShow[5] = "NA";
        //   e._qid = "";
        // }
        td.push({
          data: KeysToShow,
          _id: e._id,
          _qid: e._qid,
          questionToggle:
            e.participant_demography && e.participant_demography.length > 0
              ? true
              : false,
          otherData: e.participant_demography ? e.participant_demography : [],
        });
      });
      setListTableData(td);
    }
  }, [listDATA]);

  const DeleteReport = (id) => {
    axios
      .delete(`${API}/admin/report/${id}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res && res.data) {
          setErrSucMesg(res.data.message);
          setErrSucType(true);
          Listrefresh();
        }
      })
      .catch(function (error) {
        if (error) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
          Listrefresh();
        }
      });
  };

  return (
    <div>
      <NewAlert
        msg={ErrSucMsg}
        isSuccess={ErrSucType}
        close={() => setErrSucMesg("")}
      />
      <h2 style={{ color: "#4F74C9", fontWeight: "400" }}>DECiA Report</h2>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          onClick={() =>
            history.push({
              pathname: "/admin/createReport",
              state: { openDeciaModal: true },
            })
          }
          className={classes.addActionBtn}
          variant="outlined"
          color="primary"
          style={{ width: "20%", borderRadius: "50px" }}
        >
          <AddCircleOutlineIcon
            style={{ marginRight: "5px", color: "white" }}
          />
          Create DECiA Report
        </Button>
      </div>
      <div className={classes.boxShadow} style={{ marginTop: "40px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <span
            style={{
              color: "#FB6D6A",
              fontWeight: "600",
              margin: "0",
              fontSize: "20px",
              marginLeft: "20px",
            }}
          >
            DECiA Report
          </span>
          <span
            style={{
              color: "#FB6D6A",
              fontWeight: "600",
              margin: "0",
              fontSize: "20px",
              marginRight: "20px",
            }}
          >
            <Pagination
              count={Math.ceil(listPageCount / 10)}
              color="primary"
              page={listPage}
              onChange={(e, page) => setListPage(page)}
              size="small"
            />
          </span>
        </div>
        {listTableData.length > 0 && !tableLoading && (
          <MyTable
            style={{ width: "100%" }}
            headings={[
              "Report Name",
              "Report Date",
              "Report By",
              // "Executive Summary",
              // "Methodology",
              // "Discussion & Implications",
              // "Question",
              // "Answer",
            ]}
            listdata={listTableData}
            handleDelete={(_id) => {
              DeleteReport(_id);
            }}
            isEditable={true}
            HandleCSV={(row) => {
              let dataArr = listDATA.filter((dt) => {
                if (dt._id === row._id) return dt;
              });
              history.push({
                pathname: "/admin/createReport",
                state: { dataArr, openDeciaModal: true },
              });
            }}
            isViewable={true}
          />
        )}
        <div style={{ display: "flex", justifyContent: "center" }}>
          {tableLoading && <CircularProgress />}
        </div>
      </div>
    </div>
  );
};

export default MainLayout(DecaReport);
