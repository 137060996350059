/* eslint-disable no-unused-expressions */
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import React, { useEffect, useState } from "react";
import NewAlert from "../../components/NewAlert";
import useStyles from "../../components/StyleClasses";
import Layout from "../../layouts/newMainLayoutPlanner";
import StepWraper from "./stepWrapper";

import LinearProgress from "@material-ui/core/LinearProgress";

import personaImg from "../../assets/user.png";
import CustomizedTables from "../mediaOwner/mytable";

//Icon
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SearchIcon from "@material-ui/icons/Search";

import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import BusinessIcon from "@material-ui/icons/Business";

import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
//TextField
import Input from "@material-ui/core/Input";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { enGB } from "date-fns/locale";
import moment from "moment";
import { DateRangePicker, END_DATE, START_DATE } from "react-nice-dates";
import "react-nice-dates/build/style.css";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import UploadImage from "../../components/UploadImage";
import { API, SECRET_KEY } from "../../constants/index";
import Step5 from "./step5";

import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Papa from "papaparse";
import Step1 from "./step2";

import {
  digitalALLOTHERMediaPlan,
  digitalVideoMediaPlan,
  oohMediaPlan,
  printMediaPlan,
  radioMediaPlan,
  televisionMediaPlan,
} from "../../constants/Kpi";

import { setDate } from "date-fns";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { FourthStep } from "./components/FourthStep";
import { ThirdStep_Kpi } from "./components/ThirdStep_KPI";
import useMultipleKpiStates from "./components/hooks/useMultipleKpiStates";

function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}
function unCamelCase(str) {
  return (
    str
      // insert a space between lower & upper
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      // space before last upper in a sequence followed by lower
      .replace(/\b([A-Z]+)([A-Z])([a-z])/, "$1 $2$3")
      // uppercase the first character
      .replace(/^./, function (str) {
        return str.toUpperCase();
      })
  );
}

const numberMasking = (value) => {
  let newVal = value ? value.toString().replace(/\D/g, "") : "";
  if (newVal.length === 0) {
    newVal = "";
  }
  return newVal;
};

const KpiTextFieldStyle = {
  display: "block",
  margin: "10px",
  width: "22%",
};

const CreateCampaign = () => {
  const access_token = window.localStorage.getItem(SECRET_KEY);
  const username = window.localStorage.getItem("username");
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [KPIdigitalDisplayPlan, setKPIdigitalDsiplayPlan] = useState(
    digitalALLOTHERMediaPlan
  );
  const [KPIdigitalSkinPlan, setKPIdigitalSkinPlan] = useState(
    digitalALLOTHERMediaPlan
  );
  const [KPIdigitalRichPlan, setKPIdigitalRichPlan] = useState(
    digitalALLOTHERMediaPlan
  );
  const [KPIdigitalVideoMediaPlan, setKPIdigitalVideoMediaPlan] = useState(
    digitalVideoMediaPlan
  );

  const [KPIoohMediaPlan, setKPIoohMediaPlan] = useState(oohMediaPlan);
  const [KPIprintMediaPlan, setKPIprintMediaPlan] = useState(printMediaPlan);
  const [KPIradioMediaPlan, setKPIradioMediaPlan] = useState(radioMediaPlan);
  const [KPItelevisionMediaPlan, setKPItelevisionMediaPlan] = useState(
    televisionMediaPlan
  );
  const [step, setStep] = useState(0);
  const [name, setName] = useState("");
  const [brandName, setBrandName] = useState("");
  const [brand, setbrand] = useState(0);
  const [Country, setCountry] = useState(0);
  const [frequency, setFrequency] = useState(0);
  const [Industry, setIndustry] = useState(0);
  const [industryId, setIndustryId] = useState(0);
  const [countryId, setCountryId] = useState(0);
  const [description, setdescription] = useState("");
  const [channel, setChannel] = useState([]);
  const [AdType, setAdType] = useState([]);
  const [allAdType, setAllAdType] = useState([]);
  const [Spend, setSpend] = useState("");
  const [CHANNELS, setSelectedChannels_List] = useState([
    { channelId: 0, channelName: "", spend: 0, adtype: [] },
  ]);
  const [Avtar, setAvtar] = useState(null);
  const [AvtarFile, setAvtarFile] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currency, setCurrency] = useState("");

  const [selectedPersonas, setSelectedPersonas] = useState([]);

  const [countriesList, setcountriesList] = useState([]);
  const [industriesList, setindustriesList] = useState([]);
  const [industryListArr, setIndustryListArr] = useState([]);
  const [countryListArr, setCountryListArr] = useState([]);
  const [channesWithAdTypesList, setchannesWithAdTypesList] = useState([]);
  const [selectedChanNames, setselectedChanNames] = useState([]);
  const [acceptedChannels, setacceptedChannels] = useState([]);
  const [adtypeList, setadtypeList] = useState([]);
  const [BrandsList, setBrandsList] = useState([]);
  const [renderCounries, setrenderCounries] = useState([]);
  const [renderChannels, setrenderChannels] = useState([]);
  const [renderAdTypes, setrenderAdTypes] = useState([]);

  const [Inventories, setInventories] = useState([]);
  const [InvDigital, setInvDigital] = useState([]);
  const [InvPrint, setInvPrint] = useState([]);
  const [InvRadio, setInvRadio] = useState([]);
  const [InvOOH, setInvOOH] = useState([]);
  const [InvTelevision, setInvTelevision] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [changeToggle, setChangeToggle] = useState(false);
  const [disable, setDisable] = useState(false);

  const [upliftData, setUpliftData] = useState(null);
  // new state
  // const demographics = useSelector((state) => state.dropdownData?.demographics);
  const [ErrSucMsg, setErrSucMesg] = useState("");
  const [ErrSucType, setErrSucType] = useState(true);
  const [expectedImpressions, setExpectedImpressions] = useState(null);
  const [flag, setFlag] = useState(false);
  const [demographic, setDemographic] = useState(0);
  const [demographicData, setDemographicData] = useState([]);
  const [industriesData, setIndustriesData] = useState([]);
  const [countriesData, setCountriesData] = useState([]);
  const [campChannel, setCampChannel] = useState(0);
  const [liftStore, setLiftStore] = useState([]);
  const [baselineStore, setBaselineStore] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [countryChange, setCountryChange] = useState(false);
  const [addmore, setAddmore] = useState([
    {
      section: "LIFT",
      selectedType: [],
      questionData: [
        {
          anchored_answer: "",
          liftList: [],
          kpiType: 0,
          questionType: "",
          question: "",
          kpiErrorMsg: "",
          answers: [],
          curlyValues: [],
          curlyAns: [],
          check_answer: [],
        },
      ],
    },
    {
      section: "BASELINE",
      selectedType: [],
      questionData: [
        {
          anchored_answer: "",
          baselineList: [],
          kpiType: 0,
          questionType: "",
          kpiErrorMsg: "",
          question: "",
          answers: [],
          curlyValues: [],
          curlyAns: [],
          check_answer: [],
        },
      ],
    },
  ]);
  const [campErrors, setCampErrors] = useState({
    name: { error: false, txt: "" },
    brand: { error: false, txt: "" },
    expectedImpressions: { error: false, txt: "" },
    industryId: { error: false, txt: "" },
    countryId: { error: false, txt: "" },
    demographic: { error: false, txt: "" },
    dates: { error: false, txt: "" },
  });
  const [campaign_success_msg, setCampaignSuccessMsg] = useState(null)
  const [campaign_error_msg, setCampaignErrorMsg] = useState(null)
  const capitalizeLetter = (value) => {
    const str = value.split(" ");

    for (let i = 0; i < str.length; i++) {
      str[i] = str[i][0].toUpperCase() + str[i].substring(1);
    }

    return str.join(" ");
  };

  useEffect(() => {
    if (addmore[0].questionData[0].liftList.length !== liftStore.length && liftStore.length > 0) {
      setAddmore((prev) => {
        let prevData = [...prev];
        prevData[0].questionData[0].liftList = liftStore;
        return prevData;
      })
    }
    if (addmore[1].questionData[0].baselineList.length !== baselineStore.length && baselineStore.length > 0) {
      setAddmore((prev) => {
        let prevData = [...prev];
        prevData[1].questionData[0].baselineList = baselineStore;
        return prevData;
      })
    }
  }, [addmore]);

 

  const [SaveLoader, setSaveLoader] = useState(false);
  const MockData = () => {
    let obj = { };
    let num = 1;
    let personaNames = selectedPersonas.map((e) =>
      e.name.includes(" ") ? e.name.split(" ")[0] + " , " : e.name + " , "
    );

    if (selectedChanNames.includes("digital - display")) {
      let ChanData = CHANNELS.find((e) =>
        e.adTypeNames.includes("digital - display")
      );
      let adName = [];
      let chanwithAd = CHANNELS.filter((e) => {
        if (
          e.adTypeNames.includes("digital - display") &&
          !adName.includes("Display")
        )
          adName.push("Display");
      });
      obj.DigitalDisplay = {
        id: num,
        Campaign: name,
        Client: "Brand Advance",
        Dates: ChanData ? convert(startDate) + " to " + convert(endDate) : "",
        Budget:
          currency + ChanData ? ChanData.spend && ChanData.spend.toString() : "",
        CampaignType: "Direct",
        ClientLead: "",
        CreatedBy: capitalizeLetter(username),
        CampaignManager: "",
        mainTable: {
          Dates: ChanData ? convert(startDate) + " to " + convert(endDate) : "",
          MediaType: ChanData.channelName,
          Formats: adName,
          Channel: renderChannels.toString(),
          // Channel: personaNames,
          GeoTargeting: countryListArr.map((e) => {
            if (Country === e._id) {
              return e.name;
            }
          }),
          Content: KPIdigitalDisplayPlan.content,
          MeasureOfSuccessKpi: KPIdigitalDisplayPlan.KPI,
          BuyType: KPIdigitalDisplayPlan.buyType,
          UnitCost: currency + CPMStatic.toString(),
          Frequency: KPIdigitalDisplayPlan.frequency,
          Impressions: Math.ceil((ChanData.spend / CPMStatic) * 1000),
          Clicks: Math.ceil(
            KPIdigitalDisplayPlan.KPI_Benchmark *
              (ChanData.spend / CPMStatic) *
              10
          ),
          MediaCostNet: currency + ChanData.spend.toString(),
        },
      };
      num++;
    }
    if (selectedChanNames.includes("digital - skins")) {
      let ChanData = CHANNELS.find((e) =>
        e.adTypeNames.includes("digital - skins")
      );
      let adName = [];
      let chanwithAd = CHANNELS.filter((e) => {
        if (
          e.adTypeNames.includes("digital - skins") &&
          !adName.includes("Skins")
        )
          adName.push("Skins");
      });
      obj.DigitalSkins = {
        id: num,
        Campaign: name,
        Client: "Brand Advance",
        Dates: ChanData ? convert(startDate) + " to " + convert(endDate) : "",
        Budget:
        currency + ChanData ? ChanData.spend && ChanData.spend.toString() : "",
        CampaignType: "Direct",
        ClientLead: "",
        CreatedBy: capitalizeLetter(username),
        CampaignManager: "",
        mainTable: {
          Dates: ChanData ? convert(startDate) + " to " + convert(endDate) : "",
          MediaType: ChanData.channelName,
          Formats: adName,
          Channel: renderChannels.toString(),
          // Channel: personaNames,
          GeoTargeting: countryListArr.map((e) => {
            if (Country === e._id) {
              return e.name;
            }
          }),
          Content: KPIdigitalSkinPlan.content,
          MeasureOfSuccessKpi: KPIdigitalSkinPlan.KPI,
          BuyType: KPIdigitalSkinPlan.buyType,
          UnitCost: currency + CPMStatic.toString(),
          Frequency: KPIdigitalSkinPlan.frequency,
          Impressions: Math.ceil((ChanData.spend / CPMStatic) * 1000),
          Clicks: Math.ceil(
            KPIdigitalSkinPlan.KPI_Benchmark * (ChanData.spend / CPMStatic) * 10
          ),
          MediaCostNet: currency + ChanData.spend.toString(),
        },
      };
      num++;
    }
    if (selectedChanNames.includes("digital - rich media")) {
      let ChanData = CHANNELS.find((e) =>
        e.adTypeNames.includes("digital - rich media")
      );
      let adName = [];
      let chanwithAd = CHANNELS.filter((e) => {
        if (
          e.adTypeNames.includes("digital - rich media") &&
          !adName.includes("Rich Media")
        )
          adName.push("Rich Media");
      });
      obj.DigitalRichMedia = {
        id: num,
        Campaign: name,
        Client: "Brand Advance",
        Dates: ChanData ? convert(startDate) + " to " + convert(endDate) : "",
        Budget: currency + ChanData ? ChanData.spend.toString() : "",
        CampaignType: "Direct",
        ClientLead: "",
        CreatedBy: capitalizeLetter(username),
        CampaignManager: "",
        mainTable: {
          Dates: ChanData ? convert(startDate) + " to " + convert(endDate) : "",
          MediaType: ChanData.channelName,
          Formats: adName,
          Channel: renderChannels.toString(),
          // Channel: personaNames,
          GeoTargeting: countryListArr.map((e) => {
            if (Country === e._id) {
              return e.name;
            }
          }),
          Content: KPIdigitalRichPlan.content,
          MeasureOfSuccessKpi: KPIdigitalRichPlan.KPI,
          BuyType: KPIdigitalRichPlan.buyType,
          UnitCost: currency + CPMStatic.toString(),
          Frequency: KPIdigitalRichPlan.frequency,
          Impressions: Math.ceil((ChanData.spend / CPMStatic) * 1000),
          Clicks: Math.ceil(
            KPIdigitalRichPlan.KPI_Benchmark * (ChanData.spend / CPMStatic) * 10
          ),
          MediaCostNet: currency + ChanData.spend.toString(),
        },
      };
      num++;
    }
    if (selectedChanNames.includes("digital - video")) {
      let ChanData = CHANNELS.find((e) =>
        e.adTypeNames.includes("digital - video")
      );
      let adName = [];
      let chanwithAd = CHANNELS.filter((e) => {
        if (
          e.adTypeNames.includes("digital - video") &&
          !adName.includes("Video")
        )
          adName.push("Video");
      });
      obj.DigitalVideo = {
        id: num,
        Campaign: name,
        Client: "Brand Advance",
        Dates: ChanData ? convert(startDate) + " to " + convert(endDate) : "",
        Budget: currency + ChanData ? ChanData.spend.toString() : "",
        CampaignType: "Direct",
        ClientLead: "",
        CreatedBy: capitalizeLetter(username),
        CampaignManager: "",
        mainTable: {
          Dates: ChanData ? convert(startDate) + " to " + convert(endDate) : "",
          MediaType: ChanData.channelName,
          Formats: adName,
          Channel: renderChannels.toString(),
          // Channel: personaNames,
          GeoTargeting: countryListArr.map((e) => {
            if (Country === e._id) {
              return e.name;
            }
          }),
          Content: KPIdigitalVideoMediaPlan.content,
          MeasureOfSuccessKpi: KPIdigitalVideoMediaPlan.KPI,
          BuyType: KPIdigitalVideoMediaPlan.buyType,
          CTR: KPIdigitalVideoMediaPlan.TargetCTR,
          UnitCost: currency + CPMVideoStatic.toString(),
          Frequency: KPIdigitalVideoMediaPlan.frequency,
          Impressions: Math.ceil((ChanData.spend / CPMVideoStatic) * 1000),
          CompleteViews: Math.ceil(
            KPIdigitalVideoMediaPlan.TargetVTR *
              (ChanData.spend / CPMVideoStatic) *
              10
          ),
          Clicks: Math.ceil(
            KPIdigitalVideoMediaPlan.TargetCTR *
              (ChanData.spend / CPMVideoStatic) *
              10
          ),
          MediaCostNet: currency + ChanData.spend.toString(),
        },
      };
      num++;
    }
    if (selectedChanNames.includes("print")) {
      let ChanData = CHANNELS.find((e) => e.channelName === "Print");
      let chanwithAd = channesWithAdTypesList.find(
        (e) => e.channelName === "Print"
      );
      obj.Print = {
        id: num,
        Campaign: name,
        Client: "Brand Advance",
        Dates: ChanData ? convert(startDate) + " to " + convert(endDate) : "",
        Budget: currency + ChanData ? ChanData.spend.toString() : "",
        CampaignType: "Direct",
        ClientLead: "",
        CreatedBy: capitalizeLetter(username),
        CampaignManager: "",
        mainTable: {
          Dates: ChanData ? convert(startDate) + " to " + convert(endDate) : "",
          MediaType: ChanData.channelName,
          FormatSize: chanwithAd.AdTypes.map((e) => {
            if (ChanData.adtype.includes(e._id)) {
              return e.name + " , ";
            }
          }),
          Audience: personaNames,
          GeoCountry: countryListArr.map((e) => {
            if (Country === e._id) {
              return e.name;
            }
          }),
          Position: KPIprintMediaPlan.position,
          UnitCost: currency + CPMStatic.toString(),
          NoOfInsertions: KPIprintMediaPlan.noofInsertions,
          insertionDate: KPIprintMediaPlan.insertionDate,
          MediaCostNet: currency + ChanData.spend.toString(),
        },
      };
      num++;
    }
    if (selectedChanNames.includes("television")) {
      let ChanData = CHANNELS.find((e) => e.channelName === "Television");
      let chanwithAd = channesWithAdTypesList.find(
        (e) => e.channelName === "Television"
      );
      obj.Television = {
        id: num,
        Campaign: name,
        Client: "Brand Advance",
        Dates: ChanData ? convert(startDate) + " to " + convert(endDate) : "",
        Budget: currency + ChanData ? ChanData.spend.toString() : "",
        CampaignType: "Direct",
        ClientLead: "",
        CreatedBy: capitalizeLetter(username),
        CampaignManager: "",
        mainTable: {
          Dates: ChanData ? convert(startDate) + " to " + convert(endDate) : "",
          MediaType: ChanData.channelName,
          AdType: chanwithAd.AdTypes.map((e) => {
            if (ChanData.adtype.includes(e._id)) {
              return e.name + " , ";
            }
          }),
          Audience: personaNames,
          GeoCountry: countryListArr.map((e) => {
            if (Country === e._id) {
              return e.name;
            }
          }),
          Channel: KPItelevisionMediaPlan.channel,
          UnitCost: currency + CPMStatic.toString(),
          Method: KPItelevisionMediaPlan.method,
          quantity: KPItelevisionMediaPlan.quantity,
          MediaCostNet: currency + ChanData.spend.toString(),
        },
      };
      num++;
    }
    if (selectedChanNames.includes("ooh // dooh")) {
      let ChanData = CHANNELS.find((e) => e.channelName === "OOH // DOOH");
      let chanwithAd = channesWithAdTypesList.find(
        (e) => e.channelName === "OOH // DOOH"
      );
      obj.OOH = {
        id: num,
        Campaign: name,
        Client: "Brand Advance",
        Dates: ChanData ? convert(startDate) + " to " + convert(endDate) : "",
        Budget: currency + ChanData ? ChanData.spend.toString() : "",
        CampaignType: "Direct",
        ClientLead: "",
        CreatedBy: capitalizeLetter(username),
        CampaignManager: "",
        mainTable: {
          Dates: ChanData ? convert(startDate) + " to " + convert(endDate) : "",
          MediaType: ChanData.channelName,
          AdType: chanwithAd.AdTypes.map((e) => {
            if (ChanData.adtype.includes(e._id)) {
              return e.name + " , ";
            }
          }),
          Audience: personaNames,
          GeoCountry: countryListArr.map((e) => {
            if (Country === e._id) {
              return e.name;
            }
          }),
          Product: KPIoohMediaPlan.product,
          UnitCost: currency + CPMStatic.toString(),
          Method: KPIoohMediaPlan.method,
          quantity: KPIoohMediaPlan.quantity,
          MediaCostNet: currency + ChanData.spend.toString(),
        },
      };
      num++;
    }
    if (selectedChanNames.includes("radio")) {
      let ChanData = CHANNELS.find((e) => e.channelName === "Radio");
      let chanwithAd = channesWithAdTypesList.find(
        (e) => e.channelName === "Radio"
      );
      obj.Radio = {
        id: num,
        Campaign: name,
        Client: "Brand Advance",
        Dates: ChanData ? convert(startDate) + " to " + convert(endDate) : "",
        Budget: currency + ChanData ? ChanData.spend.toString() : "",
        CampaignType: "Direct",
        ClientLead: "",
        CreatedBy: capitalizeLetter(username),
        CampaignManager: "",
        mainTable: {
          Dates: ChanData ? convert(startDate) + " to " + convert(endDate) : "",
          MediaType: ChanData.channelName,
          AdType: chanwithAd.AdTypes.map((e) => {
            if (ChanData.adtype.includes(e._id)) {
              return e.name + " , ";
            }
          }),
          Audience: personaNames,
          GeoCountry: countryListArr.map((e) => {
            if (Country === e._id) {
              return e.name;
            }
          }),
          UnitCost: currency + CPMStatic.toString(),
          quantity: KPIradioMediaPlan.quantity,
          MediaCostNet: currency + ChanData.spend.toString(),
        },
      };
      num++;
    }

    return obj;
  };
  const MockData2 = () => {
    let table_data_arr = []
    let num = 1

    let selected_persona_names = selectedPersonas.map(person => person.name.includes(" ") ? (person.name.split(" ")[0] + " , ") : (person.name + " , ") )
    const GeoTargeting = countryListArr.map((e) => {
      if (Country === e._id) {
        return e.name;
      }
    }).filter(Boolean)[0]
    console.log("selectedChanNames", selectedChanNames)
    selectedChanNames.forEach((channel_name, i) => {
      if(channel_name === "digital - display" || channel_name === "digital/online"){
        const selected_channel_data = CHANNELS.find((channel, ind) => channel.adTypeNames.includes("digital - display") || channel.adTypeNames.includes("digital/online"))
        const adName = []
        console.log("CHANNELS_TEST", CHANNELS)
        CHANNELS.forEach(channel => {
          if(channel.adTypeNames.includes("digital/online") &&  !adName.includes("Digital/Online")){
            adName.push("Digital")
          }
        })
        let filteredAdtypes = channesWithAdTypesList.filter(e => e.channelName.toLowerCase() === channel_name.toLowerCase())
        console.log("filteredAdtypes", filteredAdtypes, channel_name, channesWithAdTypesList)
        CHANNELS.forEach(channel => {
          if (channel.Formats && channel.Formats.length > 0) {
            channel.Formats.forEach(format => {
              let adType = filteredAdtypes[0].AdTypes.find(e => e._id === format)
              if (adType) {
                adName.push(adType.name)
              }
            })
          }
          })
          

        console.log("adName", adName)
        const channel_table_data = {
          id: i,
          Campaign: name,
          Client: "Brand Advance",
          Dates: selected_channel_data ? `${convert(startDate)} to ${convert(endDate)}` : "",
          currency_symbol: currency,
          Budget: `${selected_channel_data ? selected_channel_data.spend: ""}`,
          CampaignType: "Direct",
          ClientLead: "",
          CreatedBy: capitalizeLetter(username),
          CampaignManager: "",
          mainTable: {
            Dates: selected_channel_data ? convert(startDate) + " to " + convert(endDate) : "",
            MediaType: selected_channel_data.channelName,
            Formats: adName,
            Channel: "Digital", // renderChannels.toString(),
            // Channel: personaNames,
            GeoTargeting,
            Content: kpi_states[i].content,
            MeasureOfSuccessKpi: kpi_states[i].KPI,
            BuyType: kpi_states[i].buyType,
            UnitCost: currency + CPMStatic.toString(),
            Frequency: kpi_states[i].frequency,
            Impressions: Math.ceil((selected_channel_data.spend / CPMStatic) * 1000),
            Clicks: Math.ceil(
              kpi_states[i].KPI_Benchmark *
                (selected_channel_data.spend / CPMStatic) *
                10
            ),
            MediaCostNet: currency + selected_channel_data.spend.toString(),
          },
        }
        table_data_arr.push(channel_table_data)
        
      }
      if(channel_name == "digital - skins"){
        const selected_channel_data = CHANNELS.find((channel, ind) => channel.adTypeNames.includes("digital - skins"))
        const adName = []
        CHANNELS.forEach(channel => {
          if(channel.adTypeNames.includes("digital - skins") &&  !adName.includes("Skins")){
            adName.push("Skins")
          }
        })
        const channel_table_data = {
          id: i,
          Campaign: name,
          Client: "Brand Advance",
          Dates: selected_channel_data ? `${convert(startDate)} to ${convert(endDate)}` : "",
          currency_symbol: currency,
          Budget: `${selected_channel_data ? selected_channel_data.spend: ""}`,
          CampaignType: "Direct",
          ClientLead: "",
          CreatedBy: capitalizeLetter(username),
          CampaignManager: "",
          mainTable: {
            Dates: selected_channel_data ? convert(startDate) + " to " + convert(endDate) : "",
            MediaType: selected_channel_data.channelName,
            Formats: adName,
            Channel: renderChannels.toString(),
            // Channel: personaNames,
            GeoTargeting,
            Content: kpi_states[i].content,
            MeasureOfSuccessKpi: kpi_states[i].KPI,
            BuyType: kpi_states[i].buyType,
            UnitCost: currency + CPMStatic.toString(),
            Frequency: kpi_states[i].frequency,
            Impressions: Math.ceil((selected_channel_data.spend / CPMStatic) * 1000),
            Clicks: Math.ceil(
              kpi_states[i].KPI_Benchmark *
                (selected_channel_data.spend / CPMStatic) *
                10
            ),
            MediaCostNet: currency + selected_channel_data.spend.toString(),
          },
        }
        table_data_arr.push(channel_table_data)
      }
      if(channel_name == "digital - rich media"){
        const selected_channel_data = CHANNELS.find((channel, ind) => channel.adTypeNames.includes("digital - rich media"))
        const adName = []
        CHANNELS.forEach(channel => {
          if(channel.adTypeNames.includes("digital - rich media") &&  !adName.includes("Rich Media")){
            adName.push("Rich Media")
          }
        })
        const channel_table_data = {
          id: i,
          Campaign: name,
          Client: "Brand Advance",
          Dates: selected_channel_data ? `${convert(startDate)} to ${convert(endDate)}` : "",
          currency_symbol: currency,
          Budget: `${selected_channel_data ? selected_channel_data.spend: ""}`,
          CampaignType: "Direct",
          ClientLead: "",
          CreatedBy: capitalizeLetter(username),
          CampaignManager: "",
          mainTable: {
            Dates: selected_channel_data ? convert(startDate) + " to " + convert(endDate) : "",
            MediaType: selected_channel_data.channelName,
            Formats: adName,
            Channel: renderChannels.toString(),
            // Channel: personaNames,
            GeoTargeting,
            Content: kpi_states[i].content,
            MeasureOfSuccessKpi: kpi_states[i].KPI,
            BuyType: kpi_states[i].buyType,
            UnitCost: currency + CPMStatic.toString(),
            Frequency: kpi_states[i].frequency,
            Impressions: Math.ceil((selected_channel_data.spend / CPMStatic) * 1000),
            Clicks: Math.ceil(
              kpi_states[i].KPI_Benchmark *
                (selected_channel_data.spend / CPMStatic) *
                10
            ),
            MediaCostNet: currency + selected_channel_data.spend.toString(),
          },
        }
        table_data_arr.push(channel_table_data)
      }
      if (channel_name == "digital - video") {
        let selected_channel_data = CHANNELS.find((e) =>
          e.adTypeNames.includes("digital - video")
        );
        let adName = [];
        CHANNELS.forEach(channel => {
          if(channel.adTypeNames.includes("digital - vidoe") &&  !adName.includes("Video")){
            adName.push("Video")
          }
        })
        const channel_table_data = {
          id: num,
          Campaign: name,
          Client: "Brand Advance",
          Dates: selected_channel_data ? convert(startDate) + " to " + convert(endDate) : "",
          currency_symbol: currency,
          Budget: selected_channel_data ? selected_channel_data.spend.toString() : "",
          CampaignType: "Direct",
          ClientLead: "",
          CreatedBy: capitalizeLetter(username),
          CampaignManager: "",
          mainTable: {
            Dates: selected_channel_data ? convert(startDate) + " to " + convert(endDate) : "",
            MediaType: selected_channel_data.channelName,
            Formats: adName,
            Channel: renderChannels.toString(),
            // Channel: personaNames,
            GeoTargeting: GeoTargeting,
            Content: kpi_states[i].content,
            MeasureOfSuccessKpi: kpi_states[i].KPI,
            BuyType: kpi_states[i].buyType,
            CTR: kpi_states[i].TargetCTR,
            UnitCost: currency + CPMVideoStatic.toString(),
            Frequency: kpi_states[i].frequency,
            Impressions: Math.ceil((selected_channel_data.spend / CPMVideoStatic) * 1000),
            CompleteViews: Math.ceil(
              kpi_states[i].TargetVTR *
                (selected_channel_data.spend / CPMVideoStatic) *
                10
            ),
            Clicks: Math.ceil(
              kpi_states[i].TargetCTR *
                (selected_channel_data.spend / CPMVideoStatic) *
                10
            ),
            MediaCostNet: currency + selected_channel_data.spend.toString(),
          },
        };
        table_data_arr.push(channel_table_data)
      }
      if (channel_name == "print") {
        const selected_channel_data = CHANNELS.find((channel, ind) => (ind == i) && channel.channelName == "Print")
        let chanwithAd = channesWithAdTypesList.find(
          (e) => e.channelName === "Print"
        );
        const channel_table_data = {
          id: i,
          Campaign: name,
          Client: "Brand Advance",
          Dates: selected_channel_data ? convert(startDate) + " to " + convert(endDate) : "",
          currency_symbol: currency,
          Budget: selected_channel_data ? selected_channel_data.spend.toString() : "",
          CampaignType: "Direct",
          ClientLead: "",
          CreatedBy: capitalizeLetter(username),
          CampaignManager: "",
          mainTable: {
            Dates: selected_channel_data ? convert(startDate) + " to " + convert(endDate) : "",
            MediaType: selected_channel_data.channelName,
            FormatSize: chanwithAd.AdTypes.filter(Boolean).map((e, i, array) => {
              if (selected_channel_data.adtype.includes(e._id)) {
                  return e.name + " , ";
              }
            }).filter(Boolean),
            Audience: selected_persona_names,
            GeoCountry: GeoTargeting,
            Position: kpi_states[i].position,
            UnitCost: currency + CPMStatic.toString(),
            NoOfInsertions: kpi_states[i].noofInsertions,
            insertionDate: kpi_states[i].insertionDate,
            MediaCostNet: currency + selected_channel_data.spend.toString(),
          },
        };
        table_data_arr.push(channel_table_data)
      }
      if (channel_name == "tv") {
        const selected_channel_data = CHANNELS.find((channel, ind) => (ind == i) && channel.channelName == "TV")
        let chanwithAd = channesWithAdTypesList.find(
          (e) => e.channelName === "TV"
        );
        const channel_table_data = {
          id: i,
          Campaign: name,
          Client: "Brand Advance",
          Dates: selected_channel_data ? convert(startDate) + " to " + convert(endDate) : "",
          currency_symbol: currency,
          Budget: selected_channel_data ? selected_channel_data.spend.toString() : "",
          CampaignType: "Direct",
          ClientLead: "",
          CreatedBy: capitalizeLetter(username),
          CampaignManager: "",
          mainTable: {
            Dates: selected_channel_data ? convert(startDate) + " to " + convert(endDate) : "",
            MediaType: selected_channel_data.channelName,
            AdType: chanwithAd.AdTypes.map((e, i, array) => {
              if (selected_channel_data.adtype.includes(e._id)) {
                  return e.name + " , ";
              }
            }).filter(Boolean),
            Audience: selected_persona_names,
            GeoCountry: GeoTargeting,
            Channel: kpi_states[i].channel,
            UnitCost: currency + CPMStatic.toString(),
            Method: kpi_states[i].method,
            quantity: kpi_states[i].quantity,
            MediaCostNet: currency + selected_channel_data.spend.toString(),
          },
        };
        table_data_arr.push(channel_table_data)
      }
      if (channel_name == "ooh") {
        const selected_channel_data = CHANNELS.find((channel, ind) => (ind == i) && channel.channelName == "OOH")
        let chanwithAd = channesWithAdTypesList.find(
          (e) => e.channelName === "OOH"
        );
        const channel_table_data = {
          id: i,
          Campaign: name,
          Client: "Brand Advance",
          Dates: selected_channel_data ? convert(startDate) + " to " + convert(endDate) : "",
          currency_symbol: currency,
          Budget: selected_channel_data ? selected_channel_data.spend.toString() : "",
          CampaignType: "Direct",
          ClientLead: "",
          CreatedBy: capitalizeLetter(username),
          CampaignManager: "",
          mainTable: {
            Dates: selected_channel_data ? convert(startDate) + " to " + convert(endDate) : "",
            MediaType: selected_channel_data.channelName,
            AdType: chanwithAd.AdTypes.map((e, i, array) => {
              if (selected_channel_data.adtype.includes(e._id)) {
                  return e.name + " , ";
              }
            }).filter(Boolean),
            Audience: selected_persona_names,
            GeoCountry: GeoTargeting,
            Product: kpi_states[i].product,
            UnitCost: currency + CPMStatic.toString(),
            Method: kpi_states[i].method,
            quantity: kpi_states[i].quantity,
            MediaCostNet: currency + selected_channel_data.spend.toString(),
          },
        };
        table_data_arr.push(channel_table_data)
      }
      if(channel_name == "Radio"){
        const selected_channel_data = CHANNELS.find((channel, ind) => (ind == i) && channel.channelName == "Radio")
        let chanwithAd = channesWithAdTypesList.find(
          (e) => e.channelName === "Radio"
        );
        const channel_table_data = {
          id: num,
          Campaign: name,
          Client: "Brand Advance",
          Dates: selected_channel_data ? convert(startDate) + " to " + convert(endDate) : "",
          currency_symbol: currency,
          Budget: selected_channel_data ? selected_channel_data.spend.toString() : "",
          CampaignType: "Direct",
          ClientLead: "",
          CreatedBy: capitalizeLetter(username),
          CampaignManager: "",
          mainTable: {
            Dates: selected_channel_data ? convert(startDate) + " to " + convert(endDate) : "",
            MediaType: selected_channel_data.channelName,
            AdType: chanwithAd.AdTypes.map((e, i, array) => {
              if (selected_channel_data.adtype.includes(e._id)) {
                  return e.name + " , ";
              }
            }).filter(Boolean),
            Audience: selected_persona_names,
            GeoCountry: GeoTargeting,
            UnitCost: currency + CPMStatic.toString(),
            quantity: kpi_states[i].quantity,
            MediaCostNet: currency + selected_channel_data.spend.toString(),
          },
        };
        table_data_arr.push(channel_table_data)
      }
    })
    return table_data_arr
  }

  const {kpi_states, handleKpiStatesChanges} = useMultipleKpiStates({selected_channel_names_arr: selectedChanNames, step: step})

  let CPMStatic = 15; ////////////////////////////////////////////////////////////////
  let percentStatic = 12;
  let CPMVideoStatic = 25;
  const [errors, setErrors] = useState({
    name: { error: false, txt: "" },
    Industry: { error: false, txt: "" },
    brand: { error: false, txt: "" },
    Country: { error: false, txt: "" },
    frequency: { error: false, txt: "" },
    description: { error: false, txt: "" },
    channel: { error: false, txt: "" },
    AdType: { error: false, txt: "" },
    dates: { error: false, txt: "" },
    Spend: { error: false, txt: "" },
    cover: { error: false, txt: "" },
  });

  const [Spinner, setSpinner] = useState(false);

  const UpdateChannelnames = () => {
    let copied_channels = [...CHANNELS];
    let selected_channels_list = [];
    let names = [];

    copied_channels?.forEach((channel, index) => {
      let selected_channel_adTypes_list = channesWithAdTypesList.find(
        (ell) => ell._id == channel.channelId
      );
      const filtered_adTypes_list = selected_channel_adTypes_list?.AdTypes?.filter((ad) => {
        if (channel.adtype.includes(ad._id)) return ad.name;
      });
      selected_channels_list.push(selected_channel_adTypes_list.channelName?.toLowerCase() || "")

      // define empty array
      channel.adTypeNames = []
      
      if (selected_channels_list[index] === "digital") {
        filtered_adTypes_list.forEach((item, ind) => {
          // if(!names.includes(`${selected_channels_list[index]} - ${item.name.toLowerCase()}`)){
            names.push(
              `${selected_channels_list[index]} - ${item.name.toLowerCase()}`
              );
              channel.adTypeNames.push(`${selected_channels_list[index].toLowerCase()} - ${item.name.toLowerCase()}`)
          // }
        });
      } else {
          names.push(selected_channels_list[index])
          channel.adTypeNames.push(selected_channels_list[index].toLowerCase())
      }
    });

    // copied_channels?.forEach((channel, i) => {
    //   let selected_channel_adTypes_list = channesWithAdTypesList.find(
    //     (ell) => ell._id == channel.channelId
    //   );
    //   console.log({ x: selected_channel_adTypes_list });
    //   let filtered_adType_for_selected_channel = selected_channel_adTypes_list?.AdTypes?.filter((ad) => {
    //     if (channel.adtype.includes(ad._id)) return ad.name;
    //   });
    //   if (
    //     channel && selected_channel_adTypes_list
    //   ) {
    //     copied_channels[i].channelName =
    //       selected_channel_adTypes_list.channelName;
    //     selected_channels_list.push(
    //       selected_channel_adTypes_list.channelName
    //         ? selected_channel_adTypes_list.channelName.toLowerCase()
    //         : ""
    //     );
    //   }
    //   console.log({filtered_adType_for_selected_channel, selected_channels_list})
    //   if (filtered_adType_for_selected_channel && filtered_adType_for_selected_channel.length > 0) {
        // if (selected_channels_list[i] === "digital") {
        //   filtered_adType_for_selected_channel.forEach((ynm, i) => {
        //     names.push(
        //       `${selected_channels_list[i]} - ${ynm.name.toLowerCase()}`
        //     );
        //   });
    //     } else {
    //       names.push(selected_channels_list[i])
    //     }
    //   }
    //   copied_channels[i].adTypeNames = names;
    // });

    setSelectedChannels_List(copied_channels);
    setselectedChanNames(names);
  };

  useEffect(() => {
    let x = 0;
    CHANNELS.forEach((e) => {
      x += e.spend;
    });
    setSpend(x);
  }, [CHANNELS]);

  useEffect(
    () =>
      setErrors({
        ...errors,
        cover: { error: false, txt: "" },
      }),
    [Avtar]
  );
  useEffect(
    () =>
      setErrors({
        ...errors,
        dates: { error: false, txt: "" },
      }),
    [startDate]
  );

  const getDropDown = () => {
    axios
      .get(`${API}/mediaPlanner/campaign/getAddCampaignData`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setindustriesList(res.data.industries);
          setcountriesList(res.data.countries);
          setchannesWithAdTypesList(res.data.channesWithAdTypes);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };

  const getDropDownData = () => {
    setSpinner(true);
    setDemographicData([]);

    axios
      .get(`${API}/mediaPlanner/dropdowns`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res) {
          setSpinner(false);
          setDemographicData(res.data.demographics);
        }
      })
      .catch(function (error) {
        if (error) {
          setSpinner(false);
        }
      });
  };

  const getBrands = () => {
    if (Industry === 0) return;
    setBrandsList([]);
    setSpinner(true);
    axios
      .get(
        `${API}/mediaPlanner/campaign/getBrandsWithName?industry=${Industry}`,
        {
          headers: { Authorization: `Bearer ${access_token}` },
        }
      )
      .then((res, err) => {
        if (res) {
          setBrandsList(res.data.data);
          setSpinner(false);
        }
        if (err) {
          setSpinner(false);
        }
      })
      .catch(function (error) {
        if (error) {
          setSpinner(false);
        }
      });
  };

  useEffect(() => getDropDown(), []);
  useEffect(() => getDropDownData(), []);
  useEffect(() => getBrands(), [Industry]);
  useEffect(() => {
    if (channesWithAdTypesList.length > 0) return;
    let selectedChan = channesWithAdTypesList.filter((e) =>
      channel.includes(e._id)
    );
    let allAds = [];
    selectedChan.forEach((e) => e.AdTypes.forEach((e) => allAds.push(e)));
    setadtypeList(allAds);
  }, [channel]);

  // useEffect(() => {
  //   if (countriesList.length > 0) {
  //     let a = countriesList.filter((o) => Country.includes(o._id));
  //     let b = a.map((e) => e.name + " , ");

  //     setrenderCounries(b.slice(0, 30));
  //   }
  // }, [Country]);
  useEffect(() => {
    if (BrandsList.length > 0) {
      let a = BrandsList.filter((o) => o._id === brand);
      if (a && a.length) {
        setBrandName(a[0].name);
      }
    }
  }, [brand]);

  useEffect(() => {
    if (channesWithAdTypesList.length > 0) {
      let a = channesWithAdTypesList.filter((o) => channel.includes(o._id));
      let b = a.map((e) => e.channelName);
      //setrenderChannels(b.slice(0, 30)[0]);
      setrenderChannels(b);
      let typeArr = [];
      let c = CHANNELS.map((e, i) => {
        if (
          CHANNELS[i].adtype.length &&
          !typeArr.includes(CHANNELS[i].adtype[0])
        ) {
          typeArr.push(CHANNELS[i].adtype[0]);
          setAllAdType(...allAdType, typeArr);
        } else {
          typeArr.splice(i, 1);
          setAllAdType(typeArr);
        }
        if (e.channelName) e.channelName.toLowerCase();
      });
      setacceptedChannels(c);
    }
  }, [CHANNELS]);
  useEffect(() => {
    if (adtypeList.length > 0) {
      let a = adtypeList.filter((o) => AdType.includes(o._id));
      let b = a.map((e) => e.name + " , ");
      setrenderAdTypes(b.slice(0, 30)[0]);
    }
  }, [CHANNELS]);

  const renderAdTypesDisplay = (channelList, channelIds, channelTypes) => {
    let list = [];

    channelList
      .find((e) => e._id === channelIds)
      .AdTypes.map((op) => {
        channelTypes.filter((c) => {
          if (c === op._id) {
            list.push(`${op.name} ,`);
          }
        });
      });
    return list;
  };

  function downloadDigital() {
    if (InvDigital.length <= 0) return;
    var array = [];

    InvDigital.forEach((e) => {
      let toPush = { ...e };
      let sdx = new Date(e.startDate);
      let sd =
        sdx.getUTCDate().toString() +
        "-" +
        sdx.getUTCMonth().toString() +
        "-" +
        sdx.getUTCFullYear().toString();
      let edx = new Date(e.endDate);
      let ed =
        edx.getUTCDate().toString() +
        "-" +
        edx.getUTCMonth().toString() +
        "-" +
        edx.getUTCFullYear().toString();
      toPush.startDate = sd;
      toPush.endDate = ed;
      array.push(toPush);
    });

    var csv = Papa.unparse(array);

    var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    var csvURL = null;
    if (navigator.msSaveBlob) {
      csvURL = navigator.msSaveBlob(csvData, "Digital_Inventories_Report.csv");
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }

    var tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", "Digital_Inventories_Report.csv");
    tempLink.click();
  }
  function downloadPrint() {
    if (InvPrint.length <= 0) return;
    var array = [];

    InvPrint.forEach((e) => {
      let toPush = { ...e };
      let sdx = new Date(e.startDate);
      let sd =
        sdx.getUTCDate().toString() +
        "-" +
        sdx.getUTCMonth().toString() +
        "-" +
        sdx.getUTCFullYear().toString();
      let edx = new Date(e.endDate);
      let ed =
        edx.getUTCDate().toString() +
        "-" +
        edx.getUTCMonth().toString() +
        "-" +
        edx.getUTCFullYear().toString();
      toPush.startDate = sd;
      toPush.endDate = ed;
      array.push(toPush);
    });

    var csv = Papa.unparse(array);

    var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    var csvURL = null;
    if (navigator.msSaveBlob) {
      csvURL = navigator.msSaveBlob(csvData, "Print_Inventories_Report.csv");
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }

    var tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", "Print_Inventories_Report.csv");
    tempLink.click();
  }
  function downloadRadio() {
    if (InvRadio.length <= 0) return;
    var array = [];

    InvRadio.forEach((e) => {
      let toPush = { ...e };
      let sdx = new Date(e.startDate);
      let sd =
        sdx.getUTCDate().toString() +
        "-" +
        sdx.getUTCMonth().toString() +
        "-" +
        sdx.getUTCFullYear().toString();
      let edx = new Date(e.endDate);
      let ed =
        edx.getUTCDate().toString() +
        "-" +
        edx.getUTCMonth().toString() +
        "-" +
        edx.getUTCFullYear().toString();
      toPush.startDate = sd;
      toPush.endDate = ed;
      array.push(toPush);
    });

    var csv = Papa.unparse(array);

    var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    var csvURL = null;
    if (navigator.msSaveBlob) {
      csvURL = navigator.msSaveBlob(csvData, "Radio_Inventories_Report.csv");
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }

    var tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", "Radio_Inventories_Report.csv");
    tempLink.click();
  }
  function downloadOOH() {
    if (InvOOH.length <= 0) return;
    var array = [];

    InvOOH.forEach((e) => {
      let toPush = { ...e };
      let sdx = new Date(e.startDate);
      let sd =
        sdx.getUTCDate().toString() +
        "-" +
        sdx.getUTCMonth().toString() +
        "-" +
        sdx.getUTCFullYear().toString();
      let edx = new Date(e.endDate);
      let ed =
        edx.getUTCDate().toString() +
        "-" +
        edx.getUTCMonth().toString() +
        "-" +
        edx.getUTCFullYear().toString();
      toPush.startDate = sd;
      toPush.endDate = ed;
      array.push(toPush);
    });

    var csv = Papa.unparse(array);

    var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    var csvURL = null;
    if (navigator.msSaveBlob) {
      csvURL = navigator.msSaveBlob(csvData, "OOH_Inventories_Report.csv");
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }

    var tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", "OOH_Inventories_Report.csv");
    tempLink.click();
  }
  function downloadTelevision() {
    if (InvTelevision.length <= 0) return;
    var array = [];

    InvTelevision.forEach((e) => {
      let toPush = { ...e };
      let sdx = new Date(e.startDate);
      let sd =
        sdx.getUTCDate().toString() +
        "-" +
        sdx.getUTCMonth().toString() +
        "-" +
        sdx.getUTCFullYear().toString();
      let edx = new Date(e.endDate);
      let ed =
        edx.getUTCDate().toString() +
        "-" +
        edx.getUTCMonth().toString() +
        "-" +
        edx.getUTCFullYear().toString();
      toPush.startDate = sd;
      toPush.endDate = ed;
      array.push(toPush);
    });

    var csv = Papa.unparse(array);

    var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    var csvURL = null;
    if (navigator.msSaveBlob) {
      csvURL = navigator.msSaveBlob(
        csvData,
        "Television_Inventories_Report.csv"
      );
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }

    var tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", "Television_Inventories_Report.csv");
    tempLink.click();
  }

  const downloadCSV = () => {
    downloadDigital();
    downloadPrint();
    downloadRadio();
    downloadOOH();
    downloadTelevision();
  };

  const downloadAllPdf = () => {
    var doc = new jsPDF("l", "pt", "a1");
    if (InvDigital.length > 0) {
      doc.text("Digital", 50, 20);
      let headings = Object.keys(InvDigital[0]);
      let values = [];
      InvDigital.forEach((e) => values.push(Object.values(e)));
      doc.autoTable({
        head: [headings],
        body: values,
      });
    }

    if (InvPrint.length > 0) {
      let finalY = doc.lastAutoTable.finalY; // The y position on the page
      doc.text("Print", 50, finalY !== undefined ? finalY + 20 : 20);
      let headings = Object.keys(InvPrint[0]);
      let values = [];
      InvPrint.forEach((e) => values.push(Object.values(e)));
      doc.autoTable({
        head: [headings],
        body: values,
      });
    }
    if (InvRadio.length > 0) {
      let finalY = doc.lastAutoTable.finalY; // The y position on the page
      doc.text("Radio", 50, finalY !== undefined ? finalY + 20 : 20);
      let headings = Object.keys(InvRadio[0]);
      let values = [];
      InvRadio.forEach((e) => values.push(Object.values(e)));
      doc.autoTable({
        head: [headings],
        body: values,
      });
    }
    if (InvOOH.length > 0) {
      let finalY = doc.lastAutoTable.finalY; // The y position on the page
      doc.text("OOH", 50, finalY !== undefined ? finalY + 20 : 20);
      let headings = Object.keys(InvOOH[0]);
      let values = [];
      InvOOH.forEach((e) => values.push(Object.values(e)));
      doc.autoTable({
        head: [headings],
        body: values,
      });
    }
    if (InvTelevision.length > 0) {
      let finalY = doc.lastAutoTable.finalY; // The y position on the page
      doc.text("Television", 50, finalY !== undefined ? finalY + 20 : 20);
      let headings = Object.keys(InvTelevision[0]);
      let values = [];
      InvTelevision.forEach((e) => values.push(Object.values(e)));
      doc.autoTable({
        head: [headings],
        body: values,
      });
    }

    doc.save("Inventories_Report.pdf");
  };
  const downloadPdf = () => {
    downloadAllPdf();
  };

  const getInventories = () => {
    if (selectedPersonas && selectedPersonas.length === 0) return;
    const stringPersomnas = selectedPersonas.map((e) => e._id);
    let staticChan = selectedChanNames.map((e, i, arr) => {
      if (e.includes("digital")) {
        return "digital";
      } else {
        return e;
      }
    });
    let chanArr = staticChan.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
    const body = {
      startDate: new Date(convert(startDate)).getTime(),
      // startDate: 1630391786624,
      //new Date(convert(startDate)).getTime(),
      endDate: new Date(convert(endDate)).getTime(),
      countries: [Country],
      personas: stringPersomnas,
      acceptedChannels: chanArr,
    };
    axios
      .post(`${API}/mediaPlanner/campaign/getMediaInventies`, body, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res !== undefined) {
          //setInventories(res.data);
          if (res.data.digital) {
            setInvDigital(res.data.digital);
          }
          if (res.data.print) {
            setInvPrint(res.data.print);
          }
          if (res.data.radio) {
            setInvRadio(res.data.radio);
          }
          if (res.data.ooh) {
            setInvOOH(res.data.ooh);
          }
          if (res.data.television) {
            setInvTelevision(res.data.television);
          }
        }
        if (err) {
          console.error(err);
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };

  const getUplift = () => {
    if (Industry === 0) return;
    axios
      .get(`${API}/mediaPlanner/campaign/getBrandUplift?industry=${Industry}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setUpliftData(res.data);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };

  const save = () => {
    setSaveLoader(true);
    const formData = new FormData();
    formData.append("campaign", AvtarFile);
    formData.append("industry", Industry);
    formData.append("campaignName", name);
    //formData.append("frequency", frequency);
    formData.append("description", description);
    formData.append("brand", brand);
    formData.append("frequencyUplift", 20);
    formData.append("spendAmount", Spend);
    formData.append("currency", "GBP");
    formData.append(`countries[0]`, Country);
    // Country.forEach((e, i) => formData.append(`countries[${i}]`, e));
    CHANNELS.forEach((e, i) => formData.append(`addTypes[${i}]`, e.adtype));
    // AdType.forEach((e, i) => formData.append(`addTypes[${i}]`, e));
    CHANNELS.forEach((e, i) => formData.append(`channels[${i}]`, e.channelId));
    let invs = [];
    // Inventories.forEach((elem) =>
    //   elem.inventories.forEach((e) => invs.push(e._id))
    // );
    InvDigital.forEach((k, i) => {
      if (i < 10) invs.push({ id: k._id, chan: "Digital" });
    });
    InvPrint.forEach((k, i) => {
      if (i < 10) invs.push({ id: k._id, chan: "Print" });
    });
    InvRadio.forEach((k, i) => {
      if (i < 10) invs.push({ id: k._id, chan: "Radio" });
    });
    InvOOH.forEach((k, i) => {
      if (i < 10) invs.push({ id: k._id, chan: "OOH" });
    });
    InvTelevision.forEach((k, i) => {
      if (i < 10) invs.push({ id: k._id, chan: "Television" });
    });
    invs.forEach((e, i) => {
      formData.append(`inventories[${i}][channelId]`, e.id);
      formData.append(`inventories[${i}][channelType]`, e.chan);
    });

    const stringPersomnas = selectedPersonas.map((e) => e._id);
    selectedPersonas.forEach((per, i) => {
      formData.append(`personas[]`, stringPersomnas[i]);
    });

    formData.append("startDate", new Date(convert(startDate)).getTime());
    formData.append("endDate", new Date(convert(endDate)).getTime());

    CHANNELS.forEach((e, i) => {
      formData.append(
        `channelsWithSpendAndAdType[${i}][channelId]`,
        e.channelId
      );
      if (e.channelName.includes("igital")) {
        formData.append(
          `channelsWithSpendAndAdType[${i}][channelName]`,
          "Digital"
        );
      } else {
        formData.append(
          `channelsWithSpendAndAdType[${i}][channelName]`,
          e.channelName
        );
      }

      formData.append(`channelsWithSpendAndAdType[${i}][spend]`, e.spend);
      e.adtype.forEach((element, index) => {
        formData.append(
          `channelsWithSpendAndAdType[${i}][adtype][${index}]`,
          element
        );
      });
    });
    // formData.append("tableData", JSON.stringify(MockData2()))
    const channelData = MockData2()
    if(channelData.length > 0){
      channelData.forEach((channel, i) => {
        formData.append(`channelsData[${i}]`, JSON.stringify(channel))
      })
    }
    // return 
    axios
      .post(`${API}/mediaPlanner/campaign`, formData, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res, err) => {
        if (res) {
          setCampaignSuccessMsg("Campaign created successfully.")
          history.push("/media-planner/campaign");
        }
      })
      .catch(function (error) {
        console.log(error)
        if(error?.response?.data?.message){
          setCampaignErrorMsg(error.response.data.message)
        }else{
          setCampaignErrorMsg("Something went wrong.")
        }
      }).finally(() => setSaveLoader(false));
  };

  const changeChannel = (value) => {
    let arr = channesWithAdTypesList.filter((e) => {
      return e._id === value;
    });
    if (arr && arr.length) {
      if (arr[0].channelName.toLowerCase() === "digital") {
        setDisable(true);
      } else {
        setDisable(false);
      }
    }
  };
  const step0 = () => {
    return (
      <div className={classes.boxShadow} style={{ marginTop: "0px" }}>
        <div
          className={classes.customfield}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div style={{ width: "27%" }}>
            <h4 className={classes.redHead}>Campaign Details</h4>
            <TextField
              error={errors.name.error}
              helperText={errors.name.txt}
              fullWidth
              label="Name"
              variant="outlined"
              style={{ display: "block", marginBottom: "10px" }}
              value={name}
              onChange={(e) => {
                setErrors({
                  ...errors,
                  name: { error: false, txt: "" },
                });
                setName(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircleIcon color="disabled" />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BusinessIcon color="disabled" />
                  </InputAdornment>
                ),
              }}
              error={errors.Industry.error}
              helperText={errors.Industry.txt}
              style={{ display: "block", marginBottom: "10px" }}
              fullWidth
              select
              label="Industry"
              value={Industry}
              onChange={(e) => {
                setErrors({
                  ...errors,
                  Industry: { error: false, txt: "" },
                });
                setChangeToggle(true);
                setIndustry(e.target.value);
              }}
              SelectProps={{
                native: true,
              }}
              variant="outlined"
            >
              <option disabled key={0} value={0}>
                Select...
              </option>
              {industryListArr &&
                industryListArr?.map((option) => (
                  <option key={option._id} value={option._id}>
                    {option.name}
                  </option>
                ))}
            </TextField>
            <TextField
              InputProps={
                Spinner
                  ? {
                      startAdornment: (
                        <InputAdornment position="start">
                          <CircularProgress color="disabled" size={10} />
                        </InputAdornment>
                      ),
                    }
                  : {
                      startAdornment: (
                        <InputAdornment position="start">
                          <BusinessIcon color="disabled" size={10} />
                        </InputAdornment>
                      ),
                    }
              }
              // error={errors.brand.error}
              // helperText={errors.brand.txt}
              style={{ display: "block", marginBottom: "10px" }}
              fullWidth
              select
              label="Brand"
              value={brand}
              onChange={(e) => {
                setErrors({
                  ...errors,
                  brand: { error: false, txt: "" },
                });
                setbrand(e.target.value);
              }}
              SelectProps={{
                native: true,
              }}
              variant="outlined"
            >
              <option disabled key={0} value={0}>
                Select...
              </option>
              {BrandsList?.map((option) => (
                <option key={option._id} value={option._id}>
                  {option.name}
                </option>
              ))}
            </TextField>

            <FormControl
              fullWidth
              component="fieldset"
              variant="outlined"
              className={[classes.selectControl, classes.customfield].join(" ")}
            >
              <TextField
                error={errors.Country.error}
                helperText={errors.Country.txt}
                style={{ display: "block", marginBottom: "10px" }}
                fullWidth
                select
                label="Country"
                value={Country}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    Country: { error: false, txt: "" },
                  });
                  setCountryChange(true);
                  setCountry(e.target.value);
                }}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              >
                <option disabled key={0} value={0}>
                  Select...
                </option>
                {countryListArr &&
                  countryListArr?.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
              </TextField>
            </FormControl>

            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onStartDateChange={setStartDate}
              onEndDateChange={setEndDate}
              minimumDate={new Date()}
              minimumLength={1}
              format="dd MMM yyyy"
              locale={enGB}
            >
              {({ startDateInputProps, endDateInputProps, focus }) => (
                <div className="date-range">
                  <TextField
                    error={errors.dates.error}
                    helperText={errors.dates.txt}
                    fullWidth
                    id="startDate"
                    label="Available From"
                    variant="outlined"
                    margin="normal"
                    onChange={(e) => setDate(e.target.value)}
                    className={classes.customfield}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...startDateInputProps}
                  />
                  <TextField
                    error={errors.dates.error}
                    helperText={errors.dates.txt}
                    fullWidth
                    id="endDate"
                    label="Available Upto"
                    variant="outlined"
                    margin="normal"
                    className={classes.customfield}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...endDateInputProps}
                  />
                </div>
              )}
            </DateRangePicker>
            <TextField
              error={errors.description.error}
              helperText={errors.description.txt}
              fullWidth
              label="Description"
              multiline
              rows={3}
              placeholder="Description"
              variant="outlined"
              value={description}
              onChange={(e) => {
                setErrors({
                  ...errors,
                  description: { error: false, txt: "" },
                });
                setdescription(e.target.value);
              }}
            />
          </div>
          <div style={{ width: "48%" }}>
            <span className={classes.redHead}>Channels</span>
            <div style={{ display: "flex" }}>
              {CHANNELS.length < 8 && (
                <button
                  className={classes.addActionBtn}
                  style={{ marginRight: "20px" }}
                  onClick={() => {
                    let arr = [...CHANNELS];
                    arr.push({
                      channelId: 0,
                      channelName: "",
                      spend: 0,
                      adtype: [],
                    });
                    setSelectedChannels_List(arr);
                  }}
                >
                  ADD
                </button>
              )}
              {CHANNELS.length > 1 && (
                <button
                  className={classes.addActionBtn}
                  style={{ marginRight: "20px", backgroundColor: "#F64D0C" }}
                  onClick={() => {
                    let arr = [...CHANNELS];
                    arr.pop();
                    setSelectedChannels_List(arr);
                  }}
                >
                  Remove
                </button>
              )}
            </div>
            {CHANNELS.map((e, i) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: "10px",
                }}
              >
                <div style={{ width: "32%" }}>
                  <FormControl
                    fullWidth
                    component="fieldset"
                    variant="outlined"
                    className={[
                      classes.selectControl,
                      classes.customfield,
                    ].join(" ")}
                  >
                    <TextField
                      style={{ display: "block", marginBottom: "10px" }}
                      fullWidth
                      select
                      label="Channel"
                      value={CHANNELS[i].channelId}
                      onChange={(e) => {
                        let Copied_Channels = [...CHANNELS];
                        let value = e.target.value;
                        Copied_Channels[i].channelId = value;
                        Copied_Channels[i].adtype = [];

                        let filtered_ad_types = channesWithAdTypesList.filter((e) => {
                          return e._id === value;
                        });
                        Copied_Channels[i].channelName = filtered_ad_types.length > 0 
                          ? filtered_ad_types[0].channelName
                          : "";
                        // let newAdtype = [];
                        // Chan.map((ch)=>{
                        //   if(ch.channelName.toLowerCase() === 'digital' && ch.adtype.length >= 1){
                        //     ch.adtype.map((obj) => {
                        //       if(allAdType.includes(obj)) newAdtype.push(obj)
                        //     });
                        //   }
                        // });
                        // console.log('CHANNELS newAdtype',newAdtype);
                        // setAllAdType(newAdtype);
                        changeChannel(value);
                        setChannel(value);
                        setSelectedChannels_List(Copied_Channels);
                      }}
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                    >
                      <option disabled key={0} value={0}>
                        Select...
                      </option>
                      {channesWithAdTypesList?.map((option) => {
                        return (
                          <option
                            key={option._id}
                            value={option._id}
                          >
                            {option.channelName}
                          </option>
                        );
                      })}
                    </TextField>
                  </FormControl>
                </div>
                {CHANNELS[i].channelId && channesWithAdTypesList ? (
                  <div style={{ width: "32%" }}>
                    <FormControl
                      fullWidth
                      component="fieldset"
                      variant="outlined"
                      className={[
                        classes.selectControl,
                        classes.customfield,
                      ].join(" ")}
                    >
                      <InputLabel id="demo-mutiple-checkbox-label">
                        Ad Type
                      </InputLabel>
                      <Select
                        // error={errors.AdType.error}
                        multiple
                        label="Ad Type"
                        value={CHANNELS[i].adtype}
                        onChange={(e) => {                          
                            let copied_channels = [...CHANNELS];
                            copied_channels[i].adtype = e.target.value;
                            copied_channels[i].Formats = e.target.value;
                            setSelectedChannels_List(copied_channels);
                            console.log("cp_channel", {copied_channels})
                            // update the Formats in MockData2 according to the row of channels list
                            // const channelData = MockData2()
                            // console.log("channelData", {channelData})
                            // if(channelData.length > 0){
                            //   channelData.forEach((channel, i) => {
                            //     if(channel.channelId === copied_channels[i].channelId){
                            //       channel.Formats = e.target.value
                            //     }
                            //   })
                            // }
                        }}
                        variant="outlined"
                        renderValue={() =>
                          renderAdTypesDisplay(
                            channesWithAdTypesList,
                            CHANNELS[i].channelId,
                            CHANNELS[i].adtype
                          )
                        }
                      >
                        <MenuItem disabled value={0}>
                          <em>Select...</em>
                        </MenuItem>
                        {channesWithAdTypesList
                          .find((e) => e._id === CHANNELS[i].channelId)
                          .AdTypes.map((option) => (
                            <MenuItem
                              disabled={
                                disable &&
                                CHANNELS[i].channelName.toLowerCase() ===
                                  "digital"
                                  ? CHANNELS[i].adtype.length > 0
                                    ? !CHANNELS[i].adtype.includes(option._id)
                                    : allAdType.includes(option._id)
                                  : false
                              }
                              key={option._id}
                              value={option._id}
                            >
                              <Checkbox
                                checked={CHANNELS[i].adtype.includes(
                                  option._id
                                )}
                                value={option._id}
                                onChange={(e) => {
                                  if (disable) {
                                    let typeArr = [...allAdType];
                                    if (e.target.checked) {
                                      typeArr.push(e.target.value);
                                      setAllAdType(typeArr);
                                    } else {
                                      let indexValue = typeArr.indexOf(
                                        e.target.value
                                      );
                                      typeArr.splice(indexValue, 1);
                                      setAllAdType(typeArr);
                                    }
                                  }
                                }}
                                disabled={
                                  disable &&
                                  CHANNELS[i].channelName.toLowerCase() ===
                                    "digital"
                                    ? CHANNELS[i].adtype.length > 0
                                      ? !CHANNELS[i].adtype.includes(option._id)
                                      : allAdType.includes(option._id)
                                    : false
                                }
                              ></Checkbox>
                              {option.name}
                            </MenuItem>
                          ))}
                      </Select>
                      {errors.AdType.error && (
                        <span style={{ color: "red" }}>
                          {errors.AdType.txt}
                        </span>
                      )}
                    </FormControl>
                  </div>
                ) : null}
                {CHANNELS[i].channelId && channesWithAdTypesList ? (
                  <div style={{ width: "32%" }}>
                    <div style={{ display: "flex", justifyContent: "between" }}>
                      <TextField
                        style={{ display: "block", marginRight: "10px" }}
                        fullWidth
                        select
                        value={currency}
                        onChange={(e) => {
                          setCurrency(e.target.value);
                        }}
                        variant="outlined"
                      >
                        {[
                          "$",
                          "£",
                          "€",
                        ].map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </TextField>
                      <TextField
                        // error={errors.Spend.error}
                        // helperText={errors.Spend.txt}
                        fullWidth
                        type="number"
                        label="Spend"
                        variant="outlined"
                        style={{ display: "block", marginBottom: "10px" }}
                        value={CHANNELS[i].spend}
                        onChange={(e) => {
                          // setErrors({
                          //   ...errors,
                          //   Spend: { error: false, txt: "" },
                          // });
                          let Chan = [...CHANNELS];
                          Chan[i].spend = parseInt(e.target.value);
                          setSelectedChannels_List(Chan);
                        }}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            ))}
          </div>

          <div style={{ width: "20%" }}>
            <h4 className={classes.redHead}>Avatar/Logo</h4>
            <UploadImage
              setAvt={setAvtar}
              Avt={Avtar}
              setAvtarFile={setAvtarFile}
            />
            {errors.cover.error && (
              <span style={{ color: "red" }}>{errors.cover.txt}</span>
            )}
            <div
              style={{
                background:
                  "transparent linear-gradient(90deg, #9838DC 0%, #3986E6 100%) 0% 0% no-repeat padding-box",
                padding: "18px",
                position: "absolute",
                bottom: "0",
                right: "0",
                width: "20%",
                clipPath: "polygon(15% 0, 0 50%, 15% 100%, 100% 100%, 100% 0)",
                marginBottom: "80px",
              }}
            >
              <span
                style={{
                  color: "white",
                  marginLeft: "20px",
                }}
              >
                Spend: {currency} {Spend}{" "}
              </span>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <button
            className={classes.addActionBtn}
            onClick={() => history.push("/media-planner/campaign")}
          >
            Cancel
          </button>
          <button
            className={classes.addActionBtn}
            // onClick={()=>{
              // UpdateChannelnames();
              //   setStep(1);
              //   getUplift();
              // }}
              onClick={() => {
              if (name === "") {
                setErrors({
                  ...errors,
                  name: { error: true, txt: "please enter a name" },
                });
              } else if (Industry === 0) {
                setErrors({
                  ...errors,
                  Industry: { error: true, txt: "please select an Industry" },
                });
              } else if (brand === 0) {
                setErrors({
                  ...errors,
                  brand: { error: true, txt: "please select a Brand" },
                });
              } else if (Country.length < 1) {
                setErrors({
                  ...errors,
                  Country: { error: true, txt: "please select countries" },
                });
              }
              // else if (frequency === 0) {
              //   setErrors({
              //     ...errors,
              //     frequency: { error: true, txt: "please select a frequency" },
              //   });
              // }
              else if (description === "") {
                setErrors({
                  ...errors,
                  description: {
                    error: true,
                    txt: "please Enter a Description",
                  },
                });
              } else if (startDate === "") {
                setErrors({
                  ...errors,
                  dates: { error: true, txt: "please select dates" },
                });
              } else if (!AvtarFile) {
                setErrors({
                  ...errors,
                  cover: { error: true, txt: "please select a Cover Image" },
                });
              } else {
                UpdateChannelnames();
                setStep(1);
                getUplift();
                setChangeToggle(false);
                setCountryChange(false);
                // const sc = channesWithAdTypesList.filter((e) =>
                //   channel.includes(e._id)
                // );
                // const scNames = sc.map((e) => e.channelName);
                // setselectedChanNames(scNames);
              }
            }}
          >
            Next
          </button>
        </div>
      </div>
    );
  };
  const step2 = () => {
    let tdDigital = [];
    let DigitalOverAll = 0;
    let digitaOverAllToShow = 0;

    InvDigital.forEach((e, i) => {
      let total =
        Math.ceil((e.estimatedImpression / 1000) * CPMStatic * 100) / 100;
      DigitalOverAll += total;
      if (
        DigitalOverAll <= CHANNELS.find((e) => e.channelName == "Digital").spend
      ) {
        digitaOverAllToShow = DigitalOverAll;
        // tdDigital.push({
        //   data: [
        //     e.media.name,
        //     convert(e.startDate),
        //     convert(e.endDate),
        //     e.mediaType,
        //     e.format,
        //     e.audience,
        //     e.country,
        //     KPIdigitalALLOTHERMediaPlan.content,
        //     KPIdigitalALLOTHERMediaPlan.KPI,
        //     KPIdigitalALLOTHERMediaPlan.KPI_Benchmark,
        //     KPIdigitalALLOTHERMediaPlan.buyType,
        //     CPMStatic,
        //     KPIdigitalALLOTHERMediaPlan.frequency,
        //     e.estimatedImpression,
        //     KPIdigitalALLOTHERMediaPlan.KPI_Benchmark * e.estimatedImpression,
        //     total,
        //   ],
        //   _id: e._id,
        // });
      }
    });
    let tdPrint = [];
    let PrintOverAll = 0;
    let printOverAllToShow = 0;
    InvPrint.forEach((e, i) => {
      let total =
        Math.ceil(((e.unitCost * (100 + percentStatic)) / 100) * 100) / 100;
      PrintOverAll += total;
      if (
        PrintOverAll <= CHANNELS.find((e) => e.channelName == "Print").spend
      ) {
        printOverAllToShow = PrintOverAll;
        tdPrint.push({
          data: [
            e.media.name,
            convert(e.startDate),
            convert(e.endDate),
            e.audience,
            e.circulation,
            e.readership,
            "Half Page",
            e.heightCm,
            e.widthCm,
            "N/A",
            "N/A",
            KPIprintMediaPlan.position,
            KPIprintMediaPlan.noofInsertions,
            KPIprintMediaPlan.insertionDate,
            total,
            total * KPIprintMediaPlan.noofInsertions,
          ],
          _id: e._id,
        });
      }
    });
    let tdRadio = [];
    let RadioOverAll = 0;
    let RadioOverAllToShow = 0;
    InvRadio.forEach((e, i) => {
      let total =
        Math.ceil(((e.unitCost * (100 + percentStatic)) / 100) * 100) / 100;
      RadioOverAll += total;
      if (
        RadioOverAll <= CHANNELS.find((e) => e.channelName == "Radio").spend
      ) {
        RadioOverAllToShow = RadioOverAll;
        tdRadio.push({
          data: [
            e.media.name,
            convert(e.startDate),
            convert(e.endDate),
            e.mediaType,
            e.audience,
            e.adType,
            "N/A",
            "N/A",
            total,
            "N/A",
            e.listenerss,
            total,
            KPIradioMediaPlan.quantity,
            "N/A",
            total * KPIradioMediaPlan.quantity,
          ],
          _id: e._id,
        });
      }
    });
    let tdOOH = [];
    InvOOH.forEach((e, i) => {
      tdOOH.push({
        data: [
          e.channel,
          e.audience,
          e.mediaType,
          e.listenerss,
          convert(e.startDate),
          convert(e.endDate),
          e.NetGross,
          e.Quantity,
          e.netTotal,
        ],
        _id: i,
      });
    });
    let tdTelevision = [];
    InvTelevision.forEach((e, i) => {
      tdTelevision.push({
        data: [
          e.adUnit,
          e.audience,
          e.mediaType,
          e.rate,
          convert(e.startDate),
          convert(e.endDate),
          e.netGross,
          e.quantity,
          e.netTotal,
        ],
        _id: i,
      });
    });
    return (
      <div className={classes.boxShadow} style={{ maxwidth: "76vw" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            maxwidth: "76vw",
          }}
        >
          <Input
            placeholder="Filter"
            style={{
              outline: "none",
              backgroundColor: "white",
              border: "solid 2px #E5E5E5",
              color: "black",
              borderRadius: "23px",
              paddingLeft: "5px",
              margin: "7px",
              width: "30%",
            }}
            disableUnderline
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon style={{ color: "red" }} />
              </InputAdornment>
            }
          ></Input>
          <button
            style={{
              padding: "0",
              backgroundColor: "transparent",
              border: "none",
            }}
            onClick={() => downloadCSV()}
          >
            <span style={{ marginBottom: "10px" }}>CSV</span>{" "}
            <CloudDownloadIcon width="30px" />
          </button>
          <button
            style={{
              padding: "0",
              backgroundColor: "transparent",
              border: "none",
            }}
            onClick={() => downloadPdf()}
          >
            <span style={{ marginBottom: "10px" }}>PDF</span>{" "}
            <CloudDownloadIcon width="30px" />
          </button>
        </div>
        {tdDigital.length > 0 && (
          <div style={{ maxwidth: "76vw" }}>
            <h2>
              Digital total = £{Math.ceil(digitaOverAllToShow * 100) / 100}
            </h2>
            <CustomizedTables
              headings={[
                "mediaOwnerName",
                "startDate",
                "endDate",
                "mediaType",
                "format",
                "audience",
                "country",
                "content",
                "KPI",
                "KPI_Benchmark",
                "buyType",
                "unitCost",
                "frequency",
                "estimatedImpression",
                "estimatedClicks",
                "netCost",
              ]}
              listdata={tdDigital}
              handleDelete={(_id) => {
                const removed = InvDigital.filter((e) => e._id !== _id);
                setInvDigital(removed);
              }}
            />
          </div>
        )}
        {tdPrint.length > 0 && (
          <div style={{ maxwidth: "76vw" }}>
            <h2>Print total = £{Math.ceil(printOverAllToShow * 100) / 100}</h2>
            <CustomizedTables
              headings={[
                "mediaOwnerName",
                "startDate",
                "endDate",
                "audience",
                "circulation",
                "readership",
                "formatSize",
                "height",
                "width",
                "volumeTotalcms",
                "colour",
                "position",
                "noofInsertions",
                "insertionDate",
                "unitCost",
                "netMediaCost",
              ]}
              listdata={tdPrint}
              handleDelete={(_id) => {
                const removed = tdPrint.filter((e) => e._id !== _id);
                setInvPrint(removed);
              }}
            />
          </div>
        )}
        {tdRadio.length > 0 && (
          <div style={{ maxwidth: "76vw" }}>
            <h2>Radio total = £{Math.ceil(RadioOverAllToShow * 100) / 100}</h2>
            <CustomizedTables
              headings={[
                "mediaOwnerName",
                "startDate",
                "endDate",
                "mediaType",
                "audience",
                "adType",
                "channel",
                "adUnit",
                "unitCost",
                "method",
                "listenerss",
                "NetGross",
                "Quantity",
                "additionalCost",
                "netTotal",
              ]}
              listdata={tdRadio}
              handleDelete={(_id) => {
                const removed = tdRadio.filter((e) => e._id !== _id);
                setInvRadio(removed);
              }}
            />
          </div>
        )}
        {tdOOH.length > 0 && (
          <div style={{ maxwidth: "76vw" }}>
            <h2>OOH</h2>
            <CustomizedTables
              headings={[
                "mediaOwnerName",
                "startDate",
                "endDate",
                "name",
                "media",
                "mediaType",
                "audience",
                "product",
                "channel",
                "noOfPanels",
                "unitCost",
                "method",
                "impactReachEstimated", //taken from mediaowner
                "NetGross",
                "Quantity",
                "additionalCost",
                "netTotal",
              ]}
              listdata={tdOOH}
              handleDelete={(_id) => {
                const removed = tdOOH.filter((e) => e._id !== _id);
                setInvOOH(removed);
              }}
            />
          </div>
        )}
        {tdTelevision.length > 0 && (
          <div style={{ maxwidth: "76vw" }}>
            <h2>Television</h2>
            <CustomizedTables
              headings={[
                "mediaOwnerName",
                "startDate",
                "endDate",
                "mediaType",
                "audience",
                "adType",
                "channel",
                "adUnit",
                "unitCost",
                "method",
                "viewership",
                "netGross",
                "quantity",
                "additionalCost",
                "netTotal",
              ]}
              listdata={tdTelevision}
              handleDelete={(_id) => {
                const removed = tdTelevision.filter((e) => e._id !== _id);
                setInvTelevision(removed);
              }}
            />
          </div>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <button className={classes.addActionBtn} onClick={() => setStep(2)}>
            Previous
          </button>
          <button
            className={classes.addActionBtn}
            onClick={() => {
              setStep(3);
            }}
          >
            Next
          </button>
        </div>
      </div>
    );
  };

  // const stepKpi = () => {
  //   return (
  //     <div className={classes.boxShadow} style={{ marginTop: "0px" }}>
  //       {selectedChanNames.map((channel_name, item_index) => {
  //         if (channel_name == "digital - video") {
  //           return (
  //             <div>
  //               {
  //                 <span className={classes.redHead}>
  //                   {kpi_states[item_index].section_heading}
  //                 </span>
  //               }
  //               {
  //                 <div
  //                   className={classes.customfield}
  //                   style={{
  //                     display: "flex",
  //                     flexWrap: "wrap",
  //                   }}
  //                 >
  //                   <TextField
  //                     style={KpiTextFieldStyle}
  //                     fullWidth
  //                     label="Content"
  //                     value={kpi_states[item_index].content}
  //                     onChange={(e) => {
  //                       e.persist();
  //                       handleKpiStatesChanges(
  //                         "content",
  //                         e.target.value,
  //                         item_index
  //                       );
  //                     }}
  //                     SelectProps={{
  //                       native: true,
  //                     }}
  //                     variant="outlined"
  //                   />
  //                   <TextField
  //                     style={KpiTextFieldStyle}
  //                     fullWidth
  //                     label="KPI"
  //                     select
  //                     value={kpi_states[item_index].KPI}
  //                     onChange={(e) => {
  //                       e.persist();
  //                       handleKpiStatesChanges(
  //                         "KPI",
  //                         e.target.value,
  //                         item_index
  //                       );
  //                     }}
  //                     variant="outlined"
  //                   >
  //                     <option disabled key={0} value={0}>
  //                       Select...
  //                     </option>
  //                     {["CTR", "Viewability", "CPC", "Reach"].map((option) => (
  //                       <option key={option} value={option}>
  //                         {option}
  //                       </option>
  //                     ))}
  //                   </TextField>
  //                   <TextField
  //                     style={KpiTextFieldStyle}
  //                     type="number"
  //                     fullWidth
  //                     label="KPI Benchmark"
  //                     value={kpi_states[item_index].KPI_Benchmark}
  //                     onChange={(e) => {
  //                       e.persist();
  //                       handleKpiStatesChanges(
  //                         "KPI_Benchmark",
  //                         e.target.value,
  //                         item_index
  //                       );
  //                     }}
  //                     SelectProps={{
  //                       native: true,
  //                     }}
  //                     variant="outlined"
  //                   />
  //                   <TextField
  //                     style={KpiTextFieldStyle}
  //                     fullWidth
  //                     label="Target VTR"
  //                     value={kpi_states[item_index].TargetVTR}
  //                     onChange={(e) => {
  //                       e.persist();
  //                       handleKpiStatesChanges(
  //                         "TargetVTR",
  //                         e.target.value,
  //                         item_index
  //                       );
  //                     }}
  //                     type="number"
  //                     SelectProps={{
  //                       native: true,
  //                     }}
  //                     variant="outlined"
  //                   />
  //                   <TextField
  //                     style={KpiTextFieldStyle}
  //                     type="number"
  //                     fullWidth
  //                     label="Target CTR"
  //                     value={kpi_states[item_index].TargetCTR}
  //                     onChange={(e) => {
  //                       e.persist();
  //                       handleKpiStatesChanges(
  //                         "TargetCTR",
  //                         e.target.value,
  //                         item_index
  //                       );
  //                     }}
  //                     SelectProps={{
  //                       native: true,
  //                     }}
  //                     variant="outlined"
  //                   />
  //                   <TextField
  //                     style={KpiTextFieldStyle}
  //                     fullWidth
  //                     label="Buy Type"
  //                     select
  //                     value={kpi_states[item_index].buyType}
  //                     onChange={(e) => {
  //                       e.persist();
  //                       handleKpiStatesChanges(
  //                         "buyType",
  //                         e.target.value,
  //                         item_index
  //                       );
  //                     }}
  //                     variant="outlined"
  //                   >
  //                     <option disabled key={0} value={0}>
  //                       Select...
  //                     </option>
  //                     {["CPM", "CPC", "Net Cost"].map((option) => (
  //                       <option key={option} value={option}>
  //                         {option}
  //                       </option>
  //                     ))}
  //                   </TextField>
  //                   <TextField
  //                     style={KpiTextFieldStyle}
  //                     type="number"
  //                     fullWidth
  //                     label="Frequency"
  //                     value={kpi_states[item_index].frequency}
  //                     onChange={(e) => {
  //                       e.persist();
  //                       handleKpiStatesChanges(
  //                         "frequency",
  //                         e.target.value,
  //                         item_index
  //                       );
  //                     }}
  //                     SelectProps={{
  //                       native: true,
  //                     }}
  //                     variant="outlined"
  //                   />
  //                 </div>
  //               }
  //             </div>
  //           );
  //         }
  //         if (
  //           channel_name == "digital - display" ||
  //           channel_name == "digital - rich media" ||
  //           channel_name == "digital - skins"
  //         ) {
  //           return (
  //             <div>
  //               {
  //                 <span className={classes.redHead}>
  //                   {channel_name == "digital - display" && "Digital [Display]"} 
  //                   {channel_name == "digital - rich media" && "Digital [Rich Media]"} 
  //                   {channel_name == "digital - skins" && "Digital [Skins]"} 
  //                 </span>
  //               }
  //               {
  //                 <div
  //                   className={classes.customfield}
  //                   style={{
  //                     display: "flex",
  //                     flexWrap: "wrap",
  //                   }}
  //                 >
  //                   <TextField
  //                     style={KpiTextFieldStyle}
  //                     fullWidth
  //                     label="Content"
  //                     value={kpi_states[item_index].content}
  //                     onChange={(e) => {
  //                       e.persist();
  //                       handleKpiStatesChanges(
  //                         "content",
  //                         e.target.value,
  //                         item_index
  //                       );
  //                     }}
  //                     SelectProps={{
  //                       native: true,
  //                     }}
  //                     variant="outlined"
  //                   />
  //                   <TextField
  //                     style={KpiTextFieldStyle}
  //                     fullWidth
  //                     label="KPI"
  //                     select
  //                     value={kpi_states[item_index].KPI}
  //                     onChange={(e) => {
  //                       e.persist();
  //                       handleKpiStatesChanges(
  //                         "KPI",
  //                         e.target.value,
  //                         item_index
  //                       );
  //                     }}
  //                     // SelectProps={{
  //                     //   native: true,
  //                     // }}
  //                     variant="outlined"
  //                   >
  //                     <option disabled key={0} value={0}>
  //                       Select...
  //                     </option>
  //                     {["CTR", "Viewability", "CPC", "Reach"].map((option) => (
  //                       <option key={option} value={option}>
  //                         {option}
  //                       </option>
  //                     ))}
  //                   </TextField>
  //                   <TextField
  //                     style={KpiTextFieldStyle}
  //                     type="number"
  //                     fullWidth
  //                     label="KPI Benchmark"
  //                     value={kpi_states[item_index].KPI_Benchmark}
  //                     onChange={(e) => {
  //                       e.persist();
  //                       handleKpiStatesChanges(
  //                         "KPI_Benchmark",
  //                         e.target.value,
  //                         item_index
  //                       );
  //                     }}
  //                     SelectProps={{
  //                       native: true,
  //                     }}
  //                     variant="outlined"
  //                   />
  //                   <TextField
  //                     style={KpiTextFieldStyle}
  //                     fullWidth
  //                     label="Buy Type"
  //                     select
  //                     value={kpi_states[item_index].buyType}
  //                     onChange={(e) => {
  //                       e.persist();
  //                       handleKpiStatesChanges(
  //                         "buyType",
  //                         e.target.value,
  //                         item_index
  //                       );
  //                     }}
  //                     // SelectProps={{
  //                     //   native: true,
  //                     // }}
  //                     variant="outlined"
  //                   >
  //                     <option disabled key={0} value={0}>
  //                       Select...
  //                     </option>
  //                     {["CPM", "CPC", "Net Cost"].map((option) => (
  //                       <option key={option} value={option}>
  //                         {option}
  //                       </option>
  //                     ))}
  //                   </TextField>
  //                   <TextField
  //                     style={KpiTextFieldStyle}
  //                     type="number"
  //                     fullWidth
  //                     label="Frequency"
  //                     value={kpi_states[item_index].frequency}
  //                     onChange={(e) => {
  //                       e.persist();
  //                       handleKpiStatesChanges(
  //                         "frequency",
  //                         e.target.value,
  //                         item_index
  //                       );
  //                     }}
  //                     SelectProps={{
  //                       native: true,
  //                     }}
  //                     variant="outlined"
  //                   />
  //                 </div>
  //               }
  //             </div>
  //           );
  //         }
  //         if(channel_name == "print"){
  //           return (
  //             <div>
  //           {<span className={classes.redHead}>{"Print"}</span>}
  //           {true && (
  //             <div
  //               className={classes.customfield}
  //               style={{
  //                 display: "flex",
  //                 flexWrap: "wrap",
  //               }}
  //             >
  //               <TextField
  //                 style={KpiTextFieldStyle}
  //                 fullWidth
  //                 label="Position"
  //                 select
  //                 value={kpi_states[item_index].position}
  //                 onChange={(e) => {
  //                   e.persist();
  //                       handleKpiStatesChanges(
  //                         "position",
  //                         e.target.value,
  //                         item_index
  //                       );
  //                 }}
  //                 // SelectProps={{
  //                 //   native: true,
  //                 // }}
  //                 variant="outlined"
  //               >
  //                 <option disabled key={0} value={0}>
  //                   Select...
  //                 </option>
  //                 {[
  //                   "Right Hand Side",
  //                   "Left Hand Side",
  //                   "Front Half",
  //                   "Back Half",
  //                   "IFC (Inside Front COver)",
  //                   "IBC (Inside Back Cover)",
  //                 ].map((option) => (
  //                   <option key={option} value={option}>
  //                     {option}
  //                   </option>
  //                 ))}
  //               </TextField>
  //               <TextField
  //                 style={KpiTextFieldStyle}
  //                 type="number"
  //                 fullWidth
  //                 label="No of Insertions"
  //                 value={kpi_states[item_index].noofInsertions}
  //                 onChange={(e) => {
  //                   e.persist();
  //                       handleKpiStatesChanges(
  //                         "noofInsertions",
  //                         e.target.value,
  //                         item_index
  //                       );
  //                 }}
  //                 SelectProps={{
  //                   native: true,
  //                 }}
  //                 variant="outlined"
  //               />
  //               <TextField
  //                 style={KpiTextFieldStyle}
  //                 type="date"
  //                 fullWidth
  //                 label="Insertion Date"
  //                 value={kpi_states[item_index].insertionDate}
  //                 onChange={(e) => {
  //                   e.persist();
  //                       handleKpiStatesChanges(
  //                         "insertionDate",
  //                         e.target.value,
  //                         item_index
  //                       );
  //                 }}
  //                 SelectProps={{
  //                   native: true,
  //                 }}
  //                 variant="outlined"
  //               />
  //             </div>
  //           )}
  //         </div>
  //           )
  //         }
  //         if(channel_name == "television"){
  //             return <div>
  //               {<span className={classes.redHead}>{"Television"}</span>}
  //               {(
  //                 <div
  //                   className={classes.customfield}
  //                   style={{
  //                     display: "flex",
  //                     flexWrap: "wrap",
  //                   }}
  //                 >
  //                   <TextField
  //                     style={KpiTextFieldStyle}
  //                     fullWidth
  //                     label="Channel"
  //                     value={kpi_states[item_index].channel}
  //                     onChange={(e) => {
  //                       handleKpiStatesChanges(
  //                         "channel",
  //                         e.target.value,
  //                         item_index
  //                       );
  //                     }}
  //                     SelectProps={{
  //                       native: true,
  //                     }}
  //                     variant="outlined"
  //                   />
  //                   <TextField
  //                     style={KpiTextFieldStyle}
  //                     fullWidth
  //                     label="Method"
  //                     select
  //                     value={kpi_states[item_index].method}
  //                     onChange={(e) => {
  //                       handleKpiStatesChanges(
  //                         "method",
  //                         e.target.value,
  //                         item_index
  //                       );
  //                     }}
  //                     variant="outlined"
  //                   >
  //                     <option disabled key={0} value={0}>
  //                       Select...
  //                     </option>
  //                     {[
  //                       "Digital",
  //                       "Programmatic (Heat Map)",
  //                       "Poster - 2 week install time needed",
  //                     ].map((option) => (
  //                       <option key={option} value={option}>
  //                         {option}
  //                       </option>
  //                     ))}
  //                   </TextField>
  //                   <TextField
  //                     type="number"
  //                     style={KpiTextFieldStyle}
  //                     fullWidth
  //                     label="Quantity"
  //                     value={kpi_states[item_index].quantity}
  //                     onChange={(e) => {
  //                       handleKpiStatesChanges(
  //                         "quantity",
  //                         e.target.value,
  //                         item_index
  //                       );
  //                     }}
  //                     SelectProps={{
  //                       native: true,
  //                     }}
  //                     variant="outlined"
  //                   />
  //                 </div>
  //               )}
  //             </div>
  //         }
  //         if(channel_name == "radio"){
  //             return <div>
  //               {<span className={classes.redHead}>{"Radio"}</span>}
  //               {true && (
  //                 <div
  //                   className={classes.customfield}
  //                   style={{
  //                     display: "flex",
  //                     flexWrap: "wrap",
  //                   }}
  //                 >
  //                   <TextField
  //                     type="number"
  //                     style={KpiTextFieldStyle}
  //                     fullWidth
  //                     label="Quantity"
  //                     value={kpi_states[item_index].quantity}
  //                     onChange={(e) => {
  //                       e.persist();
  //                       handleKpiStatesChanges(
  //                         "quantity",
  //                         e.target.value,
  //                         item_index
  //                       );
  //                     }}
  //                     SelectProps={{
  //                       native: true,
  //                     }}
  //                     variant="outlined"
  //                   />
  //                 </div>
  //               )}
  //             </div>
  //         }
  //         if(channel_name == "ooh // dooh"){
  //             return <div>
  //               {<span className={classes.redHead}>{"OOH"}</span>}
  //               {true && (
  //                 <div
  //                   className={classes.customfield}
  //                   style={{
  //                     display: "flex",
  //                     flexWrap: "wrap",
  //                   }}
  //                 >
  //                   <TextField
  //                     style={KpiTextFieldStyle}
  //                     fullWidth
  //                     label="Product"
  //                     select
  //                     value={kpi_states[item_index].product}
  //                     onChange={(e) => {
  //                       e.persist();
  //                       handleKpiStatesChanges(
  //                         "product",
  //                         e.target.value,
  //                         item_index
  //                       );
  //                     }}
  //                     // SelectProps={{
  //                     //   native: true,
  //                     // }}
  //                     variant="outlined"
  //                   >
  //                     <option disabled key={0} value={0}>
  //                       Select...
  //                     </option>
  //                     {["High Impact Formats", "Rail Stations", "Underground"].map(
  //                       (option) => (
  //                         <option key={option} value={option}>
  //                           {option}
  //                         </option>
  //                       )
  //                     )}
  //                   </TextField>
  //                   <TextField
  //                     style={KpiTextFieldStyle}
  //                     fullWidth
  //                     label="Method"
  //                     select
  //                     value={kpi_states[item_index].method}
  //                     onChange={(e) => {
  //                       e.persist();
  //                       handleKpiStatesChanges(
  //                         "method",
  //                         e.target.value,
  //                         item_index
  //                       );
  //                     }}
  //                     // SelectProps={{
  //                     //   native: true,
  //                     // }}
  //                     variant="outlined"
  //                   >
  //                     <option disabled key={0} value={0}>
  //                       Select...
  //                     </option>
  //                     {[
  //                       "Digital",
  //                       "Programmatic (Heat Map)",
  //                       "Poster - 2 week install time needed",
  //                     ].map((option) => (
  //                       <option key={option} value={option}>
  //                         {option}
  //                       </option>
  //                     ))}
  //                   </TextField>
  //                   <TextField
  //                     type="number"
  //                     style={KpiTextFieldStyle}
  //                     fullWidth
  //                     label="Quantity"
  //                     value={kpi_states[item_index].quantity}
  //                     onChange={(e) => {
  //                       e.persist();
  //                       handleKpiStatesChanges(
  //                         "quantity",
  //                         e.target.value,
  //                         item_index
  //                       );
  //                     }}
  //                     SelectProps={{
  //                       native: true,
  //                     }}
  //                     variant="outlined"
  //                   />
  //                 </div>
  //               )}
  //             </div>
  //         }
  //         return null
  //       })}
  //       {
  //         /////display video
  //       }
  //       {/* {selectedChanNames.includes("digital - video") && (
  //         <div>
  //           {<span className={classes.redHead}>{"Digital [Video]"}</span>}
  //           {true && (
  //             <div
  //               className={classes.customfield}
  //               style={{
  //                 display: "flex",
  //                 flexWrap: "wrap",
  //               }}
  //             >
  //               <TextField
  //                 style={KpiTextFieldStyle}
  //                 fullWidth
  //                 label="Content"
  //                 value={KPIdigitalVideoMediaPlan.content}
  //                 onChange={(e) => {
  //                   setKPIdigitalVideoMediaPlan({
  //                     ...KPIdigitalVideoMediaPlan,
  //                     content: e.target.value,
  //                   });
  //                 }}
  //                 SelectProps={{
  //                   native: true,
  //                 }}
  //                 variant="outlined"
  //               />
  //               <TextField
  //                 style={KpiTextFieldStyle}
  //                 fullWidth
  //                 label="KPI"
  //                 select
  //                 value={KPIdigitalVideoMediaPlan.KPI}
  //                 onChange={(e) => {
  //                   setKPIdigitalVideoMediaPlan({
  //                     ...KPIdigitalVideoMediaPlan,
  //                     KPI: e.target.value,
  //                   });
  //                 }}
  //                 // SelectProps={{
  //                 //   native: true,
  //                 // }}
  //                 variant="outlined"
  //               >
  //                 <option disabled key={0} value={0}>
  //                   Select...
  //                 </option>
  //                 {["CTR", "Viewability", "CPC", "Reach"].map((option) => (
  //                   <option key={option} value={option}>
  //                     {option}
  //                   </option>
  //                 ))}
  //               </TextField>
  //               <TextField
  //                 style={KpiTextFieldStyle}
  //                 type="number"
  //                 fullWidth
  //                 label="KPI Benchmark"
  //                 value={KPIdigitalVideoMediaPlan.KPI_Benchmark}
  //                 onChange={(e) => {
  //                   setKPIdigitalVideoMediaPlan({
  //                     ...KPIdigitalVideoMediaPlan,
  //                     KPI_Benchmark: e.target.value,
  //                   });
  //                 }}
  //                 SelectProps={{
  //                   native: true,
  //                 }}
  //                 variant="outlined"
  //               />
  //               <TextField
  //                 style={KpiTextFieldStyle}
  //                 fullWidth
  //                 label="Target VTR"
  //                 value={KPIdigitalVideoMediaPlan.TargetVTR}
  //                 onChange={(e) => {
  //                   setKPIdigitalVideoMediaPlan({
  //                     ...KPIdigitalVideoMediaPlan,
  //                     TargetVTR: e.target.value,
  //                   });
  //                 }}
  //                 type="number"
  //                 SelectProps={{
  //                   native: true,
  //                 }}
  //                 variant="outlined"
  //               />
  //               <TextField
  //                 style={KpiTextFieldStyle}
  //                 type="number"
  //                 fullWidth
  //                 label="Target CTR"
  //                 value={KPIdigitalVideoMediaPlan.TargetCTR}
  //                 onChange={(e) => {
  //                   setKPIdigitalVideoMediaPlan({
  //                     ...KPIdigitalVideoMediaPlan,
  //                     TargetCTR: e.target.value,
  //                   });
  //                 }}
  //                 SelectProps={{
  //                   native: true,
  //                 }}
  //                 variant="outlined"
  //               />
  //               <TextField
  //                 style={KpiTextFieldStyle}
  //                 fullWidth
  //                 label="Buy Type"
  //                 select
  //                 value={KPIdigitalVideoMediaPlan.buyType}
  //                 onChange={(e) => {
  //                   setKPIdigitalVideoMediaPlan({
  //                     ...KPIdigitalVideoMediaPlan,
  //                     buyType: e.target.value,
  //                   });
  //                 }}
  //                 // SelectProps={{
  //                 //   native: true,
  //                 // }}
  //                 variant="outlined"
  //               >
  //                 <option disabled key={0} value={0}>
  //                   Select...
  //                 </option>
  //                 {["CPM", "CPC", "Net Cost"].map((option) => (
  //                   <option key={option} value={option}>
  //                     {option}
  //                   </option>
  //                 ))}
  //               </TextField>
  //               <TextField
  //                 style={KpiTextFieldStyle}
  //                 type="number"
  //                 fullWidth
  //                 label="Frequency"
  //                 value={KPIdigitalVideoMediaPlan.frequency}
  //                 onChange={(e) => {
  //                   setKPIdigitalVideoMediaPlan({
  //                     ...KPIdigitalVideoMediaPlan,
  //                     frequency: e.target.value,
  //                   });
  //                 }}
  //                 SelectProps={{
  //                   native: true,
  //                 }}
  //                 variant="outlined"
  //               />
  //             </div>
  //           )}
  //         </div>
  //       )} */}

  //       {
  //         /////////display other
  //       }
  //       {/* {selectedChanNames.includes("digital - display") && (
  //         <div>
  //           {<span className={classes.redHead}>{"Digital [Display]"}</span>}
  //           {true && (
  //             <div
  //               className={classes.customfield}
  //               style={{
  //                 display: "flex",
  //                 flexWrap: "wrap",
  //               }}
  //             >
  //               <TextField
  //                 style={KpiTextFieldStyle}
  //                 fullWidth
  //                 label="Content"
  //                 value={KPIdigitalDisplayPlan.content}
  //                 onChange={(e) => {
  //                   setKPIdigitalDsiplayPlan({
  //                     ...KPIdigitalDisplayPlan,
  //                     content: e.target.value,
  //                   });
  //                 }}
  //                 SelectProps={{
  //                   native: true,
  //                 }}
  //                 variant="outlined"
  //               />
  //               <TextField
  //                 style={KpiTextFieldStyle}
  //                 fullWidth
  //                 label="KPI"
  //                 select
  //                 value={KPIdigitalDisplayPlan.KPI}
  //                 onChange={(e) => {
  //                   setKPIdigitalDsiplayPlan({
  //                     ...KPIdigitalDisplayPlan,
  //                     KPI: e.target.value,
  //                   });
  //                 }}
  //                 // SelectProps={{
  //                 //   native: true,
  //                 // }}
  //                 variant="outlined"
  //               >
  //                 <option disabled key={0} value={0}>
  //                   Select...
  //                 </option>
  //                 {["CTR", "Viewability", "CPC", "Reach"].map((option) => (
  //                   <option key={option} value={option}>
  //                     {option}
  //                   </option>
  //                 ))}
  //               </TextField>
  //               <TextField
  //                 style={KpiTextFieldStyle}
  //                 type="number"
  //                 fullWidth
  //                 label="KPI Benchmark"
  //                 value={KPIdigitalDisplayPlan.KPI_Benchmark}
  //                 onChange={(e) => {
  //                   setKPIdigitalDsiplayPlan({
  //                     ...KPIdigitalDisplayPlan,
  //                     KPI_Benchmark: e.target.value,
  //                   });
  //                 }}
  //                 SelectProps={{
  //                   native: true,
  //                 }}
  //                 variant="outlined"
  //               />
  //               <TextField
  //                 style={KpiTextFieldStyle}
  //                 fullWidth
  //                 label="Buy Type"
  //                 select
  //                 value={KPIdigitalDisplayPlan.buyType}
  //                 onChange={(e) => {
  //                   setKPIdigitalDsiplayPlan({
  //                     ...KPIdigitalDisplayPlan,
  //                     buyType: e.target.value,
  //                   });
  //                 }}
  //                 // SelectProps={{
  //                 //   native: true,
  //                 // }}
  //                 variant="outlined"
  //               >
  //                 <option disabled key={0} value={0}>
  //                   Select...
  //                 </option>
  //                 {["CPM", "CPC", "Net Cost"].map((option) => (
  //                   <option key={option} value={option}>
  //                     {option}
  //                   </option>
  //                 ))}
  //               </TextField>
  //               <TextField
  //                 style={KpiTextFieldStyle}
  //                 type="number"
  //                 fullWidth
  //                 label="Frequency"
  //                 value={KPIdigitalDisplayPlan.frequency}
  //                 onChange={(e) => {
  //                   setKPIdigitalDsiplayPlan({
  //                     ...KPIdigitalDisplayPlan,
  //                     frequency: e.target.value,
  //                   });
  //                 }}
  //                 SelectProps={{
  //                   native: true,
  //                 }}
  //                 variant="outlined"
  //               />
  //             </div>
  //           )}
  //         </div>
  //       )} */}
  //       {/* {selectedChanNames.includes("digital - rich media") && (
  //         <div>
  //           {<span className={classes.redHead}>{"Digital [Rich Media]"}</span>}
  //           {true && (
  //             <div
  //               className={classes.customfield}
  //               style={{
  //                 display: "flex",
  //                 flexWrap: "wrap",
  //               }}
  //             >
  //               <TextField
  //                 style={KpiTextFieldStyle}
  //                 fullWidth
  //                 label="Content"
  //                 value={KPIdigitalRichPlan.content}
  //                 onChange={(e) => {
  //                   setKPIdigitalRichPlan({
  //                     ...KPIdigitalRichPlan,
  //                     content: e.target.value,
  //                   });
  //                 }}
  //                 SelectProps={{
  //                   native: true,
  //                 }}
  //                 variant="outlined"
  //               />
  //               <TextField
  //                 style={KpiTextFieldStyle}
  //                 fullWidth
  //                 label="KPI"
  //                 select
  //                 value={KPIdigitalRichPlan.KPI}
  //                 onChange={(e) => {
  //                   setKPIdigitalRichPlan({
  //                     ...KPIdigitalRichPlan,
  //                     KPI: e.target.value,
  //                   });
  //                 }}
  //                 // SelectProps={{
  //                 //   native: true,
  //                 // }}
  //                 variant="outlined"
  //               >
  //                 <option disabled key={0} value={0}>
  //                   Select...
  //                 </option>
  //                 {["CTR", "Viewability", "CPC", "Reach"].map((option) => (
  //                   <option key={option} value={option}>
  //                     {option}
  //                   </option>
  //                 ))}
  //               </TextField>
  //               <TextField
  //                 style={KpiTextFieldStyle}
  //                 type="number"
  //                 fullWidth
  //                 label="KPI Benchmark"
  //                 value={KPIdigitalRichPlan.KPI_Benchmark}
  //                 onChange={(e) => {
  //                   setKPIdigitalRichPlan({
  //                     ...KPIdigitalRichPlan,
  //                     KPI_Benchmark: e.target.value,
  //                   });
  //                 }}
  //                 SelectProps={{
  //                   native: true,
  //                 }}
  //                 variant="outlined"
  //               />
  //               <TextField
  //                 style={KpiTextFieldStyle}
  //                 fullWidth
  //                 label="Buy Type"
  //                 select
  //                 value={KPIdigitalRichPlan.buyType || ""}
  //                 onChange={(e) => {
  //                   setKPIdigitalRichPlan({
  //                     ...KPIdigitalRichPlan,
  //                     buyType: e.target.value,
  //                   });
  //                 }}
  //                 // SelectProps={{
  //                 //   native: true,
  //                 // }}
  //                 variant="outlined"
  //               >
  //                 <option disabled key={0} value={0}>
  //                   Select...
  //                 </option>
  //                 {["CPM", "CPC", "Net Cost"].map((option) => (
  //                   <option key={option} value={option}>
  //                     {option}
  //                   </option>
  //                 ))}
  //               </TextField>
  //               <TextField
  //                 style={KpiTextFieldStyle}
  //                 type="number"
  //                 fullWidth
  //                 label="Frequency"
  //                 value={KPIdigitalRichPlan.frequency}
  //                 onChange={(e) => {
  //                   setKPIdigitalRichPlan({
  //                     ...KPIdigitalRichPlan,
  //                     frequency: e.target.value,
  //                   });
  //                 }}
  //                 SelectProps={{
  //                   native: true,
  //                 }}
  //                 variant="outlined"
  //               />
  //             </div>
  //           )}
  //         </div>
  //       )} */}
  //       {/* {selectedChanNames.includes("digital - skins") && (
  //         <div>
  //           {
  //             <span className={classes.redHead}>
  //               {"Digital [Skin / Wall Paper]"}
  //             </span>
  //           }
  //           {true && (
  //             <div
  //               className={classes.customfield}
  //               style={{
  //                 display: "flex",
  //                 flexWrap: "wrap",
  //               }}
  //             >
  //               <TextField
  //                 style={KpiTextFieldStyle}
  //                 fullWidth
  //                 label="Content"
  //                 value={KPIdigitalSkinPlan.content}
  //                 onChange={(e) => {
  //                   setKPIdigitalSkinPlan({
  //                     ...KPIdigitalSkinPlan,
  //                     content: e.target.value,
  //                   });
  //                 }}
  //                 SelectProps={{
  //                   native: true,
  //                 }}
  //                 variant="outlined"
  //               />
  //               <TextField
  //                 style={KpiTextFieldStyle}
  //                 fullWidth
  //                 label="KPI"
  //                 select
  //                 value={KPIdigitalSkinPlan.KPI}
  //                 onChange={(e) => {
  //                   setKPIdigitalSkinPlan({
  //                     ...KPIdigitalSkinPlan,
  //                     KPI: e.target.value,
  //                   });
  //                 }}
  //                 // SelectProps={{
  //                 //   native: true,
  //                 // }}
  //                 variant="outlined"
  //               >
  //                 <option disabled key={0} value={0}>
  //                   Select...
  //                 </option>
  //                 {["CTR", "Viewability", "CPC", "Reach"].map((option) => (
  //                   <option key={option} value={option}>
  //                     {option}
  //                   </option>
  //                 ))}
  //               </TextField>
  //               <TextField
  //                 style={KpiTextFieldStyle}
  //                 type="number"
  //                 fullWidth
  //                 label="KPI Benchmark"
  //                 value={KPIdigitalSkinPlan.KPI_Benchmark}
  //                 onChange={(e) => {
  //                   setKPIdigitalSkinPlan({
  //                     ...KPIdigitalSkinPlan,
  //                     KPI_Benchmark: e.target.value,
  //                   });
  //                 }}
  //                 SelectProps={{
  //                   native: true,
  //                 }}
  //                 variant="outlined"
  //               />
  //               <TextField
  //                 style={KpiTextFieldStyle}
  //                 fullWidth
  //                 label="Buy Type"
  //                 select
  //                 value={KPIdigitalSkinPlan.buyType}
  //                 onChange={(e) => {
  //                   setKPIdigitalSkinPlan({
  //                     ...KPIdigitalSkinPlan,
  //                     buyType: e.target.value,
  //                   });
  //                 }}
  //                 // SelectProps={{
  //                 //   native: true,
  //                 // }}
  //                 variant="outlined"
  //               >
  //                 <option disabled key={0} value={0}>
  //                   Select...
  //                 </option>
  //                 {["CPM", "CPC", "Net Cost"].map((option) => (
  //                   <option key={option} value={option}>
  //                     {option}
  //                   </option>
  //                 ))}
  //               </TextField>
  //               <TextField
  //                 style={KpiTextFieldStyle}
  //                 type="number"
  //                 fullWidth
  //                 label="Frequency"
  //                 value={KPIdigitalSkinPlan.frequency}
  //                 onChange={(e) => {
  //                   setKPIdigitalSkinPlan({
  //                     ...KPIdigitalSkinPlan,
  //                     frequency: e.target.value,
  //                   });
  //                 }}
  //                 SelectProps={{
  //                   native: true,
  //                 }}
  //                 variant="outlined"
  //               />
  //             </div>
  //           )}
  //         </div>
  //       )} */}

  //       {
  //         /////////Print
  //       }
  //       {/* {selectedChanNames.includes("print") && (
  //         <div>
  //           {<span className={classes.redHead}>{"Print"}</span>}
  //           {true && (
  //             <div
  //               className={classes.customfield}
  //               style={{
  //                 display: "flex",
  //                 flexWrap: "wrap",
  //               }}
  //             >
  //               <TextField
  //                 style={KpiTextFieldStyle}
  //                 fullWidth
  //                 label="Position"
  //                 select
  //                 value={KPIprintMediaPlan.position}
  //                 onChange={(e) => {
  //                   setKPIprintMediaPlan({
  //                     ...KPIprintMediaPlan,
  //                     position: e.target.value,
  //                   });
  //                 }}
  //                 // SelectProps={{
  //                 //   native: true,
  //                 // }}
  //                 variant="outlined"
  //               >
  //                 <option disabled key={0} value={0}>
  //                   Select...
  //                 </option>
  //                 {[
  //                   "Right Hand Side",
  //                   "Left Hand Side",
  //                   "Front Half",
  //                   "Back Half",
  //                   "IFC (Inside Front COver)",
  //                   "IBC (Inside Back Cover)",
  //                 ].map((option) => (
  //                   <option key={option} value={option}>
  //                     {option}
  //                   </option>
  //                 ))}
  //               </TextField>
  //               <TextField
  //                 style={KpiTextFieldStyle}
  //                 type="number"
  //                 fullWidth
  //                 label="No of Insertions"
  //                 value={KPIprintMediaPlan.noofInsertions}
  //                 onChange={(e) => {
  //                   setKPIprintMediaPlan({
  //                     ...KPIprintMediaPlan,
  //                     noofInsertions: e.target.value,
  //                   });
  //                 }}
  //                 SelectProps={{
  //                   native: true,
  //                 }}
  //                 variant="outlined"
  //               />
  //               <TextField
  //                 style={KpiTextFieldStyle}
  //                 type="date"
  //                 fullWidth
  //                 label="Insertion Date"
  //                 value={KPIprintMediaPlan.insertionDate}
  //                 onChange={(e) => {
  //                   setKPIprintMediaPlan({
  //                     ...KPIprintMediaPlan,
  //                     insertionDate: e.target.value,
  //                   });
  //                 }}
  //                 SelectProps={{
  //                   native: true,
  //                 }}
  //                 variant="outlined"
  //               />
  //             </div>
  //           )}
  //         </div>
  //       )} */}
  //       {
  //         /////////television
  //       }
  //       {/* {selectedChanNames.includes("television") && (
  //         <div>
  //           {<span className={classes.redHead}>{"Television"}</span>}
  //           {true && (
  //             <div
  //               className={classes.customfield}
  //               style={{
  //                 display: "flex",
  //                 flexWrap: "wrap",
  //               }}
  //             >
  //               <TextField
  //                 style={KpiTextFieldStyle}
  //                 fullWidth
  //                 label="Channel"
  //                 value={KPItelevisionMediaPlan.channel}
  //                 onChange={(e) => {
  //                   setKPItelevisionMediaPlan({
  //                     ...KPItelevisionMediaPlan,
  //                     channel: e.target.value,
  //                   });
  //                 }}
  //                 SelectProps={{
  //                   native: true,
  //                 }}
  //                 variant="outlined"
  //               />
  //               <TextField
  //                 style={KpiTextFieldStyle}
  //                 fullWidth
  //                 label="Method"
  //                 select
  //                 value={KPItelevisionMediaPlan.method}
  //                 onChange={(e) => {
  //                   setKPItelevisionMediaPlan({
  //                     ...KPItelevisionMediaPlan,
  //                     method: e.target.value,
  //                   });
  //                 }}
  //                 // SelectProps={{
  //                 //   native: true,
  //                 // }}
  //                 variant="outlined"
  //               >
  //                 <option disabled key={0} value={0}>
  //                   Select...
  //                 </option>
  //                 {[
  //                   "Digital",
  //                   "Programmatic (Heat Map)",
  //                   "Poster - 2 week install time needed",
  //                 ].map((option) => (
  //                   <option key={option} value={option}>
  //                     {option}
  //                   </option>
  //                 ))}
  //               </TextField>
  //               <TextField
  //                 type="number"
  //                 style={KpiTextFieldStyle}
  //                 fullWidth
  //                 label="Quantity"
  //                 value={KPItelevisionMediaPlan.quantity}
  //                 onChange={(e) => {
  //                   setKPItelevisionMediaPlan({
  //                     ...KPItelevisionMediaPlan,
  //                     quantity: e.target.value,
  //                   });
  //                 }}
  //                 SelectProps={{
  //                   native: true,
  //                 }}
  //                 variant="outlined"
  //               />
  //             </div>
  //           )}
  //         </div>
  //       )} */}
  //       {
  //         /////////OOH
  //       }
  //       {/* {selectedChanNames.includes("ooh // dooh") && (
  //         <div>
  //           {<span className={classes.redHead}>{"OOH"}</span>}
  //           {true && (
  //             <div
  //               className={classes.customfield}
  //               style={{
  //                 display: "flex",
  //                 flexWrap: "wrap",
  //               }}
  //             >
  //               <TextField
  //                 style={KpiTextFieldStyle}
  //                 fullWidth
  //                 label="Product"
  //                 select
  //                 value={KPIoohMediaPlan.product}
  //                 onChange={(e) => {
  //                   setKPIoohMediaPlan({
  //                     ...KPIoohMediaPlan,
  //                     product: e.target.value,
  //                   });
  //                 }}
  //                 // SelectProps={{
  //                 //   native: true,
  //                 // }}
  //                 variant="outlined"
  //               >
  //                 <option disabled key={0} value={0}>
  //                   Select...
  //                 </option>
  //                 {["High Impact Formats", "Rail Stations", "Underground"].map(
  //                   (option) => (
  //                     <option key={option} value={option}>
  //                       {option}
  //                     </option>
  //                   )
  //                 )}
  //               </TextField>
  //               <TextField
  //                 style={KpiTextFieldStyle}
  //                 fullWidth
  //                 label="Method"
  //                 select
  //                 value={KPIoohMediaPlan.method}
  //                 onChange={(e) => {
  //                   setKPIoohMediaPlan({
  //                     ...KPIoohMediaPlan,
  //                     method: e.target.value,
  //                   });
  //                 }}
  //                 // SelectProps={{
  //                 //   native: true,
  //                 // }}
  //                 variant="outlined"
  //               >
  //                 <option disabled key={0} value={0}>
  //                   Select...
  //                 </option>
  //                 {[
  //                   "Digital",
  //                   "Programmatic (Heat Map)",
  //                   "Poster - 2 week install time needed",
  //                 ].map((option) => (
  //                   <option key={option} value={option}>
  //                     {option}
  //                   </option>
  //                 ))}
  //               </TextField>
  //               <TextField
  //                 type="number"
  //                 style={KpiTextFieldStyle}
  //                 fullWidth
  //                 label="Quantity"
  //                 value={KPIradioMediaPlan.quantity}
  //                 onChange={(e) => {
  //                   setKPIradioMediaPlan({
  //                     ...KPIradioMediaPlan,
  //                     quantity: e.target.value,
  //                   });
  //                 }}
  //                 SelectProps={{
  //                   native: true,
  //                 }}
  //                 variant="outlined"
  //               />
  //             </div>
  //           )}
  //         </div>
  //       )} */}
  //       {
  //         /////////Radio
  //       }
  //       {/* {selectedChanNames.includes("radio") && (
  //         <div>
  //           {<span className={classes.redHead}>{"Radio"}</span>}
  //           {true && (
  //             <div
  //               className={classes.customfield}
  //               style={{
  //                 display: "flex",
  //                 flexWrap: "wrap",
  //               }}
  //             >
  //               <TextField
  //                 type="number"
  //                 style={KpiTextFieldStyle}
  //                 fullWidth
  //                 label="Quantity"
  //                 value={KPIradioMediaPlan.quantity}
  //                 onChange={(e) => {
  //                   setKPIradioMediaPlan({
  //                     ...KPIradioMediaPlan,
  //                     quantity: e.target.value,
  //                   });
  //                 }}
  //                 SelectProps={{
  //                   native: true,
  //                 }}
  //                 variant="outlined"
  //               />
  //             </div>
  //           )}
  //         </div>
  //       )} */}

  //       <div
  //         style={{
  //           display: "flex",
  //           justifyContent: "space-between",
  //           marginTop: "20px",
  //         }}
  //       >
  //         <button className={classes.addActionBtn} onClick={() => setStep(1)}>
  //           Previous
  //         </button>
  //         <button
  //           className={classes.addActionBtn}
  //           onClick={() => {
  //             let chanArr =
  //               CHANNELS &&
  //               CHANNELS.length &&
  //               CHANNELS.filter((chan) => {
  //                 if (chan.channelName.toLowerCase() === "digital") {
  //                   return chan;
  //                 }
  //               });
  //             if (chanArr.length >= 1) {
  //               setStep(3);
  //             } else {
  //               setStep(5);
  //             }
  //           }}
  //         >
  //           Next
  //         </button>
  //       </div>
  //     </div>
  //   );
  // };

  const step6 = () => {
    return (
      <div className={classes.boxShadow} style={{marginTop: "0px"}}>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div style={{ width: "75%" }}>
            <h4 className={classes.redHead}>
              Forecasted Brand Uplift Based on Persona Settings
            </h4>
            <div style={{ display: "flex", justifyContent: "space-betwen" }}>
              <div
                style={{
                  boxShadow:
                    "0px 0px 10px rgba(0,0,0,0.20), 0 0 10px rgba(0,0,0,0.15)",
                  borderRadius: "16px",
                  marginRight: "30px",
                  marginTop: "30px",
                }}
                id="dummyBlue"
              >
                <h1
                  style={{
                    fontSize: "25px",
                    fontFamily: "monospace",
                    margin: "20px 0 0 30px",
                  }}
                >
                  +
                  {parseFloat(upliftData?.brandUplift)
                    ? parseFloat(upliftData?.brandUplift).toFixed(2)
                    : 0}
                  %
                </h1>
                <h2
                  style={{
                    fontSize: "10px",
                    fontFamily: "monospace",
                    margin: "0 0 0 30px",
                    fontWeight: "400",
                  }}
                >
                  BRAND LIFT TOTAL
                </h2>
                <h2
                  style={{
                    fontSize: "10px",
                    fontFamily: "monospace",
                    margin: "0 0 0 30px",
                    fontWeight: "400",
                  }}
                >
                  All metrics summarised
                </h2>
              </div>
              {/* <div
                style={{
                  boxShadow:
                    "0px 0px 10px rgba(0,0,0,0.20), 0 0 10px rgba(0,0,0,0.15)",
                  borderRadius: "16px",
                  marginRight: "30px",
                  marginTop: "30px",
                }}
                id="dummygreen"
              >
                <h2
                  style={{
                    fontSize: "25px",
                    fontFamily: "monospace",
                    margin: "20px 0 0 30px",
                  }}
                >
                  +
                  {upliftData?.totalBrandUplift
                    ? parseFloat(upliftData?.totalBrandUplift).toFixed(2)
                    : "19.40"}
                  %
                </h2>
                <h2
                  style={{
                    fontSize: "10px",
                    fontFamily: "monospace",
                    margin: "0 0 0 30px",
                    fontWeight: "400",
                  }}
                >
                  TOTAL BRAND LIFT INDEX vs DATABASE
                </h2>
                <h2
                  style={{
                    fontSize: "10px",
                    fontFamily: "monospace",
                    margin: "0 0 0 30px",
                    fontWeight: "400",
                  }}
                >
                  Total Brandlift Compared to Benchmark
                </h2>
              </div> */}
            </div>
            <div>
              {[
                {
                  name: "Awareness",
                  val: upliftData?.awareness
                    ? upliftData?.awareness.toFixed(2)
                    : 0,
                },
                {
                  name: "Consideration",
                  val: upliftData?.consideration
                    ? upliftData?.consideration.toFixed(2)
                    : 0,
                },
                {
                  name: "Preference",
                  val: upliftData?.preference
                    ? upliftData?.preference.toFixed(2)
                    : 0,
                },
                {
                  name: "Action Intent",
                  val: upliftData?.actionIntent
                    ? upliftData?.actionIntent.toFixed(2)
                    : 0,
                },
              ].map((e) => (
                <div style={{ marginTop: "30px" }}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span
                      style={{
                        marginBottom: "0",
                        color: "grey",
                        fontSize: "20px",
                      }}
                    >
                      {" "}
                      {e.name}
                    </span>
                    <span
                      style={{
                        marginBottom: "0",
                        color: "grey",
                        fontSize: "20px",
                      }}
                    >
                      {" "}
                      {e.val} % &#9650;
                    </span>
                  </div>

                  <div
                    style={{
                      backgroundColor: "grey",
                      height: "20px",
                      width: "100%",
                      borderRadius: "10px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: `rgb(${Math.random() * 255} ${
                          Math.random() * 255
                        } ${Math.random() * 255})`,
                        height: "20px",
                        width: `${e.val}%`,
                        borderRadius: "10px",
                      }}
                    ></div>
                  </div>
                </div>
              ))}
              <div style={{ marginTop: "50px" }}>
                <h2 className={classes.redHead}>Channels</h2>
                {/* <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <BusinessIcon color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                  disabled
                  style={{ display: "block", marginBottom: "10px" }}
                  label="Channel"
                  value={channel}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                ></TextField> */}
                <RadioGroup
                  aria-label="Channel"
                  name="channel"
                  value={selectedChanNames}
                >
                  {selectedChanNames.map((e) => (
                    <FormControlLabel
                      checked={true}
                      value={e}
                      control={<Radio />}
                      label={e}
                      //////change label later!!!!!!!!
                    />
                  ))}
                </RadioGroup>
                <div
                  style={{
                    background:
                      "transparent linear-gradient(90deg, #9838DC 0%, #3986E6 100%) 0% 0% no-repeat padding-box",
                    padding: "18px",
                    position: "absolute",
                    bottom: "0",
                    right: "0",
                    width: "20%",
                    clipPath:
                      "polygon(15% 0, 0 50%, 15% 100%, 100% 100%, 100% 0)",
                    marginBottom: "80px",
                  }}
                >
                  <span
                    style={{
                      color: "white",
                      marginLeft: "20px",
                    }}
                  >
                    Spend: £ {Spend}{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div style={{ width: "20%" }}>
            <span className={classes.redHead}>personas</span>
            <div
              style={{
                maxHeight: "500px",
                overflow: "auto",
                width: "100%",
              }}
            >
              {selectedPersonas.map((per) => (
                <div
                  className={classes.boxShadow}
                  style={{
                    marginTop: "10px",
                    margin: "20%",
                    textAlign: "center",
                    borderRadius: "8px",
                  }}
                >
                  <img
                    alt="media.Img"
                    src={personaImg}
                    style={{
                      width: "100%",
                      display: "block",
                      backgroundColor: `${per.col}`,
                      borderRadius: "50%",
                    }}
                  ></img>
                  <span
                    style={{
                      color: "blue",
                      fontSize: "15px",
                    }}
                  >
                    {per.name}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "40px",
          }}
        >
          <button className={classes.addActionBtn} onClick={() => setStep(5)}>
            Previous
          </button>
          {!SaveLoader && (
            <button
              className={classes.addActionBtn}
              onClick={() => {
                save();
                let chanArr =
                  CHANNELS &&
                  CHANNELS.length &&
                  CHANNELS.filter((chan) => {
                    if (chan.channelName.toLowerCase() === "digital") {
                      return chan;
                    }
                  });
                if (chanArr.length >= 1) {
                  saveLift();
                }
              }}
              style={{ backgroundColor: "green" }}
            >
              Save
            </button>
          )}
        </div>
        {SaveLoader && <LinearProgress style={{ marginTop: "10px" }} />}
      </div>
    );
  };

  const onResetKpi = (e) => {
    e.preventDefault();
    setAddmore([
      {
        section: "LIFT",
        selectedType: [],
        questionData: [
          {
            anchored_answer: "",
            liftList: liftStore,
            kpiType: 0,
            questionType: "",
            question: "",
            kpiErrorMsg: "",
            answers: [],
            check_answer: [],
            curlyValues: [],
            curlyAns: [],
          },
        ],
      },
      {
        section: "BASELINE",
        selectedType: [],
        questionData: [
          {
            anchored_answer: "",
            baselineList: baselineStore,
            kpiType: 0,
            questionType: "",
            question: "",
            kpiErrorMsg: "",
            answers: [],
            check_answer: [],
            curlyValues: [],
            curlyAns: [],
          },
        ],
      },
    ]);
  };

  useEffect(() => {
    industryListArr &&
      industryListArr?.map((option) => {
        if (option._id === Industry) {
          setIndustryId(option.id);
        } else if (option.id === Number(industryId)) {
          setIndustry(option._id);
        }
      });
  }, [changeToggle]);

  useEffect(() => {
    countryListArr &&
      countryListArr?.map((option) => {
        if (option._id === Country) {
          setCountryId(option.id);
        } else if (option.id === Number(countryId)) {
          setCountry(option._id);
        }
      });
  }, [countryChange]);

  // step 4
  useEffect(() => {
    getCountryList();
    getIndustryList();
  }, []);

  const getCountryList = () => {
    axios
      .get(`${API}/mediaPlanner/lucidApi/lookup/listCountryLanguages`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res) {
          setCountriesData(res.data.items);
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };

  const getIndustryList = () => {
    axios
      .get(`${API}/mediaPlanner/lucidApi/lookup/listIndustries`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res && res.data && res.data.items) {
          setIndustriesData(res.data.items);
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };

  const getLiftList = () => {
    if (countryId === 0 && industryId === 0) return;
    axios
      .get(
        `${API}/mediaPlanner/lucidApi/lift/${countryId}/${industryId}/lookup/listTemplateQuestion`,
        {
          headers: { Authorization: `Bearer ${access_token}` },
        }
      )
      .then((res) => {
        if (res) {
          const addLiftList = [...addmore];
          let questionsList = res.data.items || [];
          let englishList = [4, 5, 1, 6, 12, 16, 11, 2];
          if (!englishList.includes(countryId)) {
            axios.get(`${API}/mediaPlanner/lucidApi/lift/1/${industryId}/lookup/listTemplateQuestion`, {
              headers: { Authorization: `Bearer ${access_token}` },
            }).then((res) => {
              if (res) {
                questionsList = [...questionsList, ...res.data.items.map((item) => {
                  return {
                    ...item,
                    question_name: item.question_name + ' (English)',
                    kpi_type: item.kpi_type + '_en',
                  }
                })];
                addLiftList[0].questionData[0].liftList = questionsList;
                setAddmore(addLiftList);
                setLiftStore(questionsList);
              }
            })
          } else {
            addLiftList[0].questionData[0].liftList = questionsList;
            setAddmore(addLiftList);
            setLiftStore(questionsList);
          }
          // addLiftList[0].questionData[0].liftList = res.data.items
          //   ? res.data.items
          //   : [];
          // setAddmore(addLiftList);
          // setLiftStore(res.data.items);
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };

  const getBaselineList = () => {
    if (countryId === 0 && industryId === 0) return;
    axios
      .get(
        `${API}/mediaPlanner/lucidApi/baseline/${countryId}/${industryId}/lookup/listTemplateQuestion`,
        {
          headers: { Authorization: `Bearer ${access_token}` },
        }
      )
      .then((res) => {
        if (res) {
          const addBaselineList = [...addmore];
          let questionsList = res.data.items || [];
          let englishList = [4, 5, 1, 6, 12, 16, 11, 2];
          console.log("baseline Question country :::", countryId)
          if (!englishList.includes(countryId)) {
            axios.get(`${API}/mediaPlanner/lucidApi/baseline/1/${industryId}/lookup/listTemplateQuestion`, {
              headers: { Authorization: `Bearer ${access_token}` },
            }).then((res) => {
              if (res) {
                questionsList = [...questionsList, ...res.data.items.map((item) => {
                  return {
                    ...item,
                    question_name: item.question_name + ' (English)',
                    kpi_type: item.kpi_type + '_en',
                  }
                })];
                addBaselineList[1].questionData[0].baselineList = questionsList;
                setAddmore(addBaselineList);
                setBaselineStore(questionsList);
              }
            })
          } else {
            addBaselineList[1].questionData[0].baselineList = questionsList;
            setAddmore(addBaselineList);
            setBaselineStore(questionsList);
        }
      }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };

  useEffect(() => {
    getBaselineList();
    getLiftList();
  }, [countryId, industryId]);

  useEffect(() => {
    let industryArr = [...industriesList];
    let industriesDataArr = [...industriesData];
    let industriesListArr = [];
    industriesDataArr &&
      industriesDataArr.length &&
      industriesDataArr.map((ind, i) => {
        industryArr.map((item, index) => {
          if(item.name.toLowerCase() ===  ind.industry_name.toLowerCase()) {
            industriesListArr.push({
              name: ind.industry_name,
              id: ind.id,
              _id: industryArr[index]._id,
            });
          }
        })
      });
    setIndustryListArr(industriesListArr);
  }, [industriesData]);

  useEffect(() => {
    let countryList = [...countriesList];
    let countriesDataArr = [...countriesData];
    console.log("countriesDataArr :::", countriesDataArr)

    let countriesListArr = [];
    countriesDataArr &&
      countriesDataArr.length &&
      countriesDataArr.map((ind, i) => {
        if (
          countryList.find(
            (cf) => cf.name.toLowerCase() === ind.country_name.toLowerCase()
          )
        ) {
          countriesListArr.push({
            name: ind.country_name,
            language_name: ind.language_name,
            id: ind.id,
            _id:
              countryList && countryList.length && countryList[i]
                ? countryList[i]._id
                : "",
          });
        }
      });
    setCountryListArr(countriesListArr);
  }, [countriesData]);

  const saveLift = () => {
    const mid = window.localStorage.getItem("ID");
    if (toggle) return;
    // const cid = editData.campaign_id;
    setSaveLoader(true);
    let questionArr = [];
    let questionArr1 = [];
    let finalBaseArray = [];
    let finalListArray = [];
    addmore &&
      addmore.length &&
      addmore[0].questionData &&
      addmore[0].questionData.map((ad, i) => {
        finalListArray = ad.answers.map(function (obj) {
          return obj.ans;
        });

        questionArr.push({
          question_text: ad.question,
          question_name: ad.kpiType,
          kpi_type: ad.kpiType,
          anchored_answer:
            ad.questionType === "checkbox"
              ? ad.check_answer.join()
              : ad.anchored_answer,
          question_type: ad.questionType,
          answers: finalListArray,
          order: i + 1,
        });
        questionArr[0].default = true;
      });

    addmore &&
      addmore.length &&
      addmore[1].questionData &&
      addmore[1].questionData.map((ad, i) => {
        finalBaseArray = ad.answers.map(function (obj) {
          return obj.ans;
        });
        questionArr1.push({
          question_text: ad.question,
          question_name: ad.kpiType,
          kpi_type: ad.kpiType,
          anchored_answer:
            ad.questionType === "checkbox"
              ? ad.check_answer.join()
              : ad.anchored_answer,
          question_type: ad.questionType,
          answers: finalBaseArray,
          order: i + 1,
        });
        questionArr1[0].default = true;
      });
    const nm = /[,\-]/.test(name) ? name : `${name}-${demographic}`;
    const payload = {
      media_planner_id: mid,
      campaign_name: nm,
      start_date: moment(startDate).format("YYYY-MM-DD hh:mm:ss.ssssss"),
      end_date: moment(endDate).format("YYYY-MM-DD hh:mm:ss.ssssss"),
      client_name: brandName,
      country_language_id: countryId.toString(),
      industry_id: industryId.toString(),
      channels: [campChannel ? campChannel.toLowerCase() : "digital"],
      draft_settings: {
        questions: questionArr,
        baseline_questions: questionArr1,
        allow_ip: false,
        expected_impressions_count: Number(expectedImpressions),
        expected_impressions_count_linear_tv: 1,
      },
    };
    // const endPoint = editFlag ? `/admin/lucidApi/${cid}` : "/admin/lucidApi/";
    // const request = editFlag ? axios.put : axios.post;
    const endPoint = "/mediaPlanner/lucidApi/";
    const request = axios.post;
    request(`${API}${endPoint}`, payload, {
      headers: {
        Authorization: `Bearer ${access_token}`,
        "content-type": "application/json",
      },
    })
      .then((res) => {
        if (res) {
          setErrSucMesg(
            // res.data.message
            //   ? res.data.message
            //   : editFlag
            //   ? "Campaign updated successfully."
            //   : "Campaign created successfully."
            res.data.message
              ? res.data.message
              : "Campaign created successfully."
          );
          setErrSucType(true);
          // setTimeout(() => {
          //   history.push(`/media-planner`);
          // }, 1500);
          setSaveLoader(false);
        }
      })
      .catch(function (error) {
        if (error && error.response) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
          setSaveLoader(false);
        }
      });
  };

  const addMoreItem = (parentInd) => {
    const items = [...addmore];
    const ansArr = items[parentInd].questionData;
    // let liftListArr =
    //   items[parentInd].questionData[childInd].liftList &&
    //   items[parentInd].questionData[childInd].liftList.filter((li) => {
    //     return !items[parentInd].selectedType.includes(li.kpi_type);
    //   });
    // let baseListArr =
    //   items[parentInd].questionData[childInd].baselineList &&
    //   items[parentInd].questionData[childInd].baselineList.filter((li) => {
    //     return !items[parentInd].selectedType.includes(li.kpi_type);
    //   });

    let liftListArr =
      liftStore &&
      liftStore.length &&
      liftStore.filter((li) => {
        return (
          ansArr &&
          ansArr.length &&
          !ansArr.find((ans) => ans.kpiType === li.kpi_type)
        );
      });
    let baseListArr =
      baselineStore &&
      baselineStore.length &&
      baselineStore.filter((li) => {
        return (
          ansArr &&
          ansArr.length &&
          !ansArr.find((ans) => ans.kpiType === li.kpi_type)
        );
      });

    if (items[parentInd].section === "LIFT") {
      ansArr.push({
        liftList: liftListArr,
        kpiType: 0,
        question: "",
        questionType: "",
        kpiErrorMsg: "",
        answers: [],
        curlyValues: [],
        curlyAns: [],
        check_answer: [],
      });
    } else {
      ansArr.push({
        baselineList: baseListArr,
        kpiType: 0,
        question: "",
        questionType: "",
        kpiErrorMsg: "",
        answers: [],
        curlyValues: [],
        curlyAns: [],
        check_answer: [],
      });
    }
    setAddmore([...items]);
  };

  const removeItem = async (parentInd, childInd) => {
    const removeArr = [...addmore];
    const removeAnsArr = removeArr[parentInd].questionData;
    if (removeAnsArr.length) {
      removeAnsArr.splice(childInd, 1);
    }
    setAddmore([...removeArr]);
  };

  const getInsideDoubleCurly = (str) =>
    str
      .split("{{")
      .filter((val) => val.includes("}}"))
      .map((val) => val.substring(0, val.indexOf("}}")));

  const setQuestionData = (type, pInd, cInd) => {
    const addmoreArr = [...addmore];

    let liftList =
      liftStore &&
      liftStore.filter((li) => {
        return li.kpi_type === type;
      });
    let baseList =
      baselineStore &&
      baselineStore.filter((bi) => {
        return bi.kpi_type === type;
      });

    if (addmoreArr[pInd].section === "LIFT") {
      addmoreArr[pInd].questionData[cInd].question = liftList[0].question_text;
      addmoreArr[pInd].questionData[cInd].questionType =
        liftList && liftList.length ? liftList[0].question_type : "";

      if (
        liftList &&
        liftList.length &&
        liftList[0].answers &&
        addmoreArr[pInd] &&
        addmoreArr[pInd].questionData[cInd]
      ) {
        (addmoreArr[pInd].questionData[cInd].curlyValues =
          liftList &&
          liftList.length &&
          getInsideDoubleCurly(liftList[0].question_text)),
          (addmoreArr[pInd].questionData[cInd].curlyAns =
            liftList &&
            liftList.length &&
            liftList[0].fields.filter((qt) => {
              return getInsideDoubleCurly(liftList[0].question_text).includes(
                qt.name
              );
            })),
          liftList[0].answers.map((an) => {
            addmoreArr[pInd].questionData[cInd].answers.push({
              ans: an,
            });
          });
      }
      if (
        liftList &&
        liftList.length &&
        liftList[0].question_text.search("{{") !== -1
      ) {
        changeQueLabel(pInd, cInd);
      }
    } else {
      addmoreArr[pInd].questionData[cInd].question = baseList[0].question_text;

      addmoreArr[pInd].questionData[cInd].questionType =
        baseList && baseList.length ? baseList[0].question_type : "";
      if (
        baseList &&
        baseList.length &&
        baseList[0].answers &&
        addmoreArr[pInd] &&
        addmoreArr[pInd].questionData[cInd]
      ) {
        (addmoreArr[pInd].questionData[cInd].curlyValues =
          baseList &&
          baseList.length &&
          getInsideDoubleCurly(baseList[0].question_text)),
          (addmoreArr[pInd].questionData[cInd].curlyAns =
            baseList &&
            baseList.length &&
            baseList[0].fields.filter((qt) => {
              return getInsideDoubleCurly(baseList[0].question_text).includes(
                qt.name
              );
            })),
          baseList[0].answers.map((an) => {
            addmoreArr[pInd].questionData[cInd].answers.push({
              ans: an,
            });
          });
      }
      if (
        baseList &&
        baseList.length &&
        baseList[0].question_text.search("{{") !== -1
      ) {
        changeQueLabel(pInd, cInd);
      }
    }
    setAddmore([...addmoreArr]);
  };

  const changeQueLabel = (pInd, cInd) => {
    const dummyArr = [...addmore];
    const kpiType = dummyArr[pInd].questionData[cInd].kpiType;

    let liftList =
      liftStore &&
      liftStore.filter((li) => {
        return li.kpi_type === kpiType;
      });
    let baseList =
      baselineStore &&
      baselineStore.filter((bi) => {
        return bi.kpi_type === kpiType;
      });

    let fullQuestion = "";
    let curlyAns = [];
    let curlyValues = [];
    if (dummyArr[pInd].section === "LIFT") {
      if (liftList && liftList.length) {
        fullQuestion = liftList[0].question_text;
        curlyAns = dummyArr[pInd].questionData[cInd].curlyAns;
        curlyValues = dummyArr[pInd].questionData[cInd].curlyValues;
        for (var i = 0; i < curlyValues.length; i++) {
          fullQuestion = fullQuestion.replace(
            new RegExp("{{" + curlyValues[i] + "}}", "gi"),
            curlyAns && curlyAns.length && curlyAns[i].value
          );
        }
        dummyArr[pInd].questionData[cInd].question = fullQuestion;
      }
    } else {
      if (baseList && baseList.length) {
        fullQuestion = baseList[0].question_text;
        curlyAns = dummyArr[pInd].questionData[cInd].curlyAns;
        curlyValues = dummyArr[pInd].questionData[cInd].curlyValues;
        for (var i = 0; i < curlyValues.length; i++) {
          fullQuestion = fullQuestion.replace(
            new RegExp("{{" + curlyValues[i] + "}}", "gi"),
            curlyAns && curlyAns.length && curlyAns[i].value
          );
        }
        dummyArr[pInd].questionData[cInd].question = fullQuestion;
      }
    }
    setAddmore([...dummyArr]);
  };

  const errorHandling = () => {
    let addMoreData = [...addmore];
    let valid = false;
    // save();
    if (addMoreData && addMoreData.length) {
      addMoreData.map((ad, index) => {
        ad.questionData &&
          ad.questionData.length &&
          ad.questionData.map((qd, i) => {
            if (qd.kpiType === 0) {
              addMoreData[index].questionData[i].kpiErrorMsg =
                "Please select an kpi type";
              valid = true;
              return true;
            } else {
              if (valid) return;
              valid = false;
              addMoreData[index].questionData[i].kpiErrorMsg = "";
            }
          });
      });
      setAddmore(addMoreData);
      if (
        !valid &&
        addMoreData[0].questionData &&
        addMoreData[0].questionData.length >= 1 &&
        addMoreData[1].questionData &&
        addMoreData[1].questionData.length >= 1
      ) {
        setStep(5);
      } else {
        setModalOpen(true);
      }
    }
  };

  const getCancelAnswer = (ques, pIdx, ansIdx) => {
    let addMoreArrs = [...addmore];
    if (addMoreArrs[pIdx].section === "LIFT") {
      addMoreArrs[pIdx].questionData &&
        addMoreArrs[pIdx].questionData.length &&
        addMoreArrs[pIdx].questionData?.map((qd) => {
          if (qd.kpiType === ques.kpiType) {
            qd.answers.splice(ansIdx, 1);
          }
        });
    } else {
      addMoreArrs[pIdx].questionData &&
        addMoreArrs[pIdx].questionData.length &&
        addMoreArrs[pIdx].questionData?.map((qd) => {
          if (qd.kpiType === ques.kpiType) {
            qd.answers.splice(ansIdx, 1);
          }
        });
    }
    setAddmore(addMoreArrs);
  };

  const step4 = () => {
    return (
      // add margintop 0 important
      <div className={classes.boxShadow} style={{marginTop: "0px"}}>
        <NewAlert
          msg={ErrSucMsg}
          isSuccess={ErrSucType}
          close={() => setErrSucMesg("")}
        />
        <Dialog
          open={modalOpen}
          onClose={() => {
            setModalOpen(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Please select atleast one question from each
          </DialogTitle>
          <DialogActions>
            <button
              className={classes.addActionBtn}
              onClick={() => {
                setModalOpen(false);
              }}
            >
              Ok
            </button>
          </DialogActions>
        </Dialog>
        <Grid container direction="row" spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            {addmore && addmore.length
              ? addmore.map((li, index) => (
                  <>
                    {index > 0 && <hr />}
                    <h4 className={classes.redHead}>{li.section}</h4>
                    {li.questionData && li.questionData.length
                      ? li.questionData.map((que, i) => (
                          <div key={i}>
                            <Grid container direction="row" spacing={2}>
                              <Grid item md={2} sm={2} xs={2}>
                                <b>Question Type:</b>
                              </Grid>
                              <Grid item md={6} sm={6} xs={6}>
                                <TextField
                                  size="small"
                                  // error={li.questionData[i].kpiErrorMsg}
                                  // helperText={li.questionData[i].kpiErrorMsg}
                                  style={{
                                    display: "block",
                                    marginBottom: "10px",
                                  }}
                                  fullWidth
                                  select
                                  label="KPI Type"
                                  value={li.questionData[i].kpiType}
                                  onChange={(e) => {
                                    let addmoreArr = [...addmore];
                                    addmoreArr[index].questionData[i].kpiType =
                                      e.target.value;
                                    // addmoreArr[index].selectedType.push(
                                    //   e.target.value
                                    // );
                                    addmoreArr[index].selectedType[i] = e.target.value;
                                    addmoreArr[index].questionData[
                                      i
                                    ].answers = [];
                                    addmoreArr[index].questionData[i].question =
                                      "";
                                    if (
                                      addmoreArr[index].questionData[i]
                                        .kpiType !== 0
                                    ) {
                                      addmoreArr[index].questionData[
                                        i
                                      ].kpiErrorMsg = "";
                                    }
                                    setQuestionData(e.target.value, index, i);
                                    setAddmore(addmoreArr);
                                  }}
                                  SelectProps={{
                                    native: true,
                                  }}
                                  variant="outlined"
                                >
                                  <option disabled key={0} value={0}>
                                    Select...
                                  </option>
                                  {(li.section === "LIFT"
                                    ? que.liftList
                                    : que.baselineList
                                  )?.map((option) => (
                                    <option
                                      key={option.kpi_type}
                                      value={option.kpi_type}
                                    >
                                      {option.question_name}
                                    </option>
                                  ))}
                                </TextField>
                                {addmore[index].questionData[i].kpiErrorMsg ? (
                                  <span
                                    style={{
                                      color: "red",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    {addmore[index].questionData[i].kpiErrorMsg}
                                  </span>
                                ) : null}
                              </Grid>
                            </Grid>
                            <br />
                            {addmore[index] &&
                            addmore[index].questionData[i] &&
                            addmore[index].questionData[i].kpiType !== 0 ? (
                              <>
                                <Grid container direction="row" spacing={2}>
                                  <Grid item md={2} sm={2} xs={2}>
                                    <b>Question:</b>
                                  </Grid>
                                  <Grid item md={6} sm={6} xs={6}>
                                    {que.question}
                                  </Grid>
                                </Grid>
                                <br />
                                <Grid container direction="row" spacing={1}>
                                  <Grid item md={2} sm={2} xs={2} />
                                  <Grid item md={6} sm={6} xs={6}>
                                    {que.curlyAns && que.curlyAns.length
                                      ? que.curlyAns.map((cu, j) => (
                                          <>
                                            <TextField
                                              size="small"
                                              fullWidth
                                              variant="outlined"
                                              value={que.curlyAns[j].value}
                                              style={{
                                                marginTop: "10px",
                                                marginBottom: "10px",
                                              }}
                                              onChange={async (e) => {
                                                let changeLabel = [...addmore];
                                                changeLabel[index].questionData[
                                                  i
                                                ].curlyAns[j].value =
                                                  e.target.value;
                                                setAddmore(changeLabel);
                                                if (
                                                  que.curlyAns[j].value === ""
                                                ) {
                                                  setToggle(true);
                                                } else {
                                                  setToggle(false);
                                                }
                                                await changeQueLabel(index, i);
                                              }}
                                            />
                                            {que.curlyAns[j].value === "" && (
                                              <span style={{ color: "red" }}>
                                                Please enter some text to
                                                complete the question
                                              </span>
                                            )}
                                          </>
                                        ))
                                      : null}

                                    {que.answers && que.answers.length
                                      ? que.answers.map((a, k) => (
                                          <>
                                            <Grid
                                              container
                                              direction="row"
                                              spacing={1}
                                            >
                                              <Grid item md={1} sm={1} xs={1}>
                                                <FormControl>
                                                  {que.questionType ===
                                                    "radio" && (
                                                    <RadioGroup
                                                      aria-labelledby="demo-radio-buttons-group-label"
                                                      name="radio-buttons-group"
                                                    >
                                                      <FormControlLabel
                                                        value={a.ans}
                                                        control={
                                                          <Radio
                                                            checked={
                                                              que.anchored_answer ===
                                                              a.ans
                                                            }
                                                            onClick={(e) => {
                                                              let changeFlag = [
                                                                ...addmore,
                                                              ];
                                                              changeFlag[
                                                                index
                                                              ].questionData[
                                                                i
                                                              ].anchored_answer =
                                                                e.target.value;
                                                              changeFlag[
                                                                index
                                                              ].questionData[
                                                                i
                                                              ].questionType =
                                                                "radio";
                                                              setAddmore(
                                                                changeFlag
                                                              );
                                                            }}
                                                          />
                                                        }
                                                      />
                                                    </RadioGroup>
                                                  )}
                                                  {que.questionType ===
                                                    "checkbox" && (
                                                    <Checkbox
                                                      checked={
                                                        que.check_answer &&
                                                        que.check_answer.length
                                                          ? que.check_answer.includes(
                                                              a.ans
                                                            )
                                                          : false
                                                      }
                                                      onChange={(e) => {
                                                        let changeFlag = [
                                                          ...addmore,
                                                        ];
                                                        let changeLabel =
                                                          changeFlag[index]
                                                            .questionData[i];

                                                        if (
                                                          que.check_answer &&
                                                          que.check_answer
                                                            .length &&
                                                          que.check_answer.includes(
                                                            e.target.value
                                                          )
                                                        ) {
                                                          let indexValue = que.check_answer.indexOf(
                                                            e.target.value
                                                          );
                                                          que.check_answer.splice(
                                                            indexValue,
                                                            1
                                                          );
                                                        } else {
                                                          changeLabel.check_answer.push(
                                                            e.target.value
                                                          );
                                                          changeLabel.questionType =
                                                            "checkbox";
                                                        }
                                                        setAddmore(changeFlag);
                                                      }}
                                                      value={a.ans}
                                                      name={a.ans}
                                                    />
                                                  )}
                                                </FormControl>
                                              </Grid>
                                              <Grid
                                                item
                                                md={11}
                                                sm={11}
                                                xs={11}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <TextField
                                                    size="small"
                                                    //   error={errors.description.error}
                                                    //   helperText={errors.description.txt}
                                                    fullWidth
                                                    label="Answer"
                                                    placeholder="answer"
                                                    variant="outlined"
                                                    value={que.answers[k].ans}
                                                    style={{
                                                      marginTop: "10px",
                                                    }}
                                                    onChange={(e) => {
                                                      let changeAns = [
                                                        ...addmore,
                                                      ];
                                                      let changeAnsValue =
                                                        changeAns[index]
                                                          .questionData[i];
                                                      if (
                                                        changeAnsValue.questionType ===
                                                        "radio"
                                                      ) {
                                                        changeAnsValue.anchored_answer =
                                                          e.target.value;
                                                      } else {
                                                        changeAnsValue.check_answer.push(
                                                          e.target.value
                                                        );
                                                      }
                                                      changeAnsValue.answers[
                                                        k
                                                      ].ans = e.target.value;
                                                      setAddmore(changeAns);
                                                    }}
                                                  />
                                                  {que.questionType ===
                                                    "radio" &&
                                                  que.anchored_answer !==
                                                    que.answers[k].ans ? (
                                                    <span
                                                      style={{
                                                        marginLeft: "5px",
                                                      }}
                                                      onClick={() =>
                                                        getCancelAnswer(
                                                          que,
                                                          index,
                                                          k
                                                        )
                                                      }
                                                    >
                                                      X
                                                    </span>
                                                  ) : (
                                                    que.questionType ===
                                                      "checkbox" &&
                                                    !que.check_answer.includes(
                                                      que.answers[k].ans
                                                    ) && (
                                                      <span
                                                        style={{
                                                          marginLeft: "5px",
                                                        }}
                                                        onClick={() =>
                                                          getCancelAnswer(
                                                            que,
                                                            index,
                                                            k
                                                          )
                                                        }
                                                      >
                                                        X
                                                      </span>
                                                    )
                                                  )}
                                                </div>
                                              </Grid>
                                            </Grid>
                                            <br />
                                          </>
                                        ))
                                      : null}
                                  </Grid>
                                </Grid>
                                <Grid container direction="row" spacing={1}>
                                  <Grid item md={2} sm={2} xs={2} />
                                  <Grid
                                    item
                                    md={6}
                                    sm={6}
                                    xs={6}
                                    style={{ textAlign: "end" }}
                                  >
                                    {addmore[0].section === "LIFT"
                                      ? addmore[0] &&
                                        addmore[0].questionData.length <=
                                          liftStore.length - 1 && (
                                          <button
                                            className={classes.addActionBtn}
                                            style={{
                                              marginBottom: "10px",
                                              width: "70px",
                                            }}
                                            onClick={() =>
                                              addMoreItem(index, i)
                                            }
                                          >
                                            +
                                          </button>
                                        )
                                      : addmore[1] &&
                                        addmore[1].questionData.length <=
                                          baselineStore.length - 1 && (
                                          <button
                                            className={classes.addActionBtn}
                                            style={{
                                              marginBottom: "10px",
                                              width: "70px",
                                            }}
                                            onClick={() =>
                                              addMoreItem(index, i)
                                            }
                                          >
                                            +
                                          </button>
                                        )}
                                    {addmore[index] &&
                                      addmore[index].questionData.length >
                                        1 && (
                                        <button
                                          className={classes.addActionBtn}
                                          style={{
                                            marginLeft: "10px",
                                            width: "70px",
                                          }}
                                          onClick={() => {
                                            removeItem(index, i);
                                            console.log("index Remove", i, "---", index)
                                            setAddmore((prev) => {
                                              if (i === 0) {
                                                return [...prev]
                                              } else {
                                                prev[index]['questionData'] = prev[index]['questionData'].filter((item, ind) => ind !== i);
                                                prev[index]['selectedType'] = prev[index]['selectedType'].filter((item, ind) => ind !== i);
                                                return [...prev];
                                              }
                                            })
                                          }}
                                        >
                                          -
                                        </button>
                                      )}
                                  </Grid>
                                </Grid>
                              </>
                            ) : null}
                          </div>
                        ))
                      : null}
                  </>
                ))
              : null}
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "40px",
          }}
        >
          <button
            className={classes.addActionBtn}
            onClick={() => {
              setStep(3);
            }}
          >
            Previous
          </button>
          <button
            className={classes.addActionBtn}
            onClick={() => {
              errorHandling();
            }}
          >
            Next
          </button>
        </div>
        {SaveLoader && <LinearProgress style={{ marginTop: "10px" }} />}
      </div>
    );
  };

  return (
    <div style={{ maxWidth: "77vw" }}>
      {step === 0 && <StepWraper step={0} />}
      {step === 1 && <StepWraper step={1} />}
      {step === 2 && <StepWraper step={2} />}
      {step === 3 && <StepWraper step={3} />}
      {step === 4 && <StepWraper step={4} />}
      {step === 5 && <StepWraper step={5} />}
      {step === 6 && <StepWraper step={6} />}
      {step === 0 && step0()}
      {step === 1 && (
        <Step1
          setStep={(ind) => {
            getInventories();
            setStep(ind);
          }}
          selectedPersonas={selectedPersonas}
          setSelectedPersonas={setSelectedPersonas}
        />
      )}

      {step === 2 && <ThirdStep_Kpi CHANNELS={CHANNELS} KpiTextFieldStyle={KpiTextFieldStyle} handleKpiStatesChanges={handleKpiStatesChanges} kpi_states={kpi_states} selectedChanNames={selectedChanNames} setStep={setStep}   />}
      {/* {step === 2 && stepKpi()} */}
      {/* {step === 3 && <Step3 />} */}
      {step === 3 && (
        <FourthStep
          channesWithAdTypesList={channesWithAdTypesList}
          setCampChannel={setCampChannel}
          setCampErrors={setCampErrors}
          setDemographic={setDemographic}
          setErrSucMesg={setErrSucMesg}
          setStep={setStep}
          ErrSucMsg={ErrSucMsg}
          ErrSucType={ErrSucType}
          campErrors={campErrors}
          setFlag={setFlag}
          setExpectedImpressions={setExpectedImpressions}
          demographic={demographic}
          demographicData={demographicData}
          industryId={industryId}
          industryListArr={industryListArr}
          brandName={brandName}
          countryId={countryId}
          countryListArr={countryListArr}
          expectedImpressions={expectedImpressions}
          flag={flag}
          campChannel={campChannel}
          description={description}
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={setStartDate}
          onEndDateChange={setEndDate}
          enGB={enGB}
          errors={errors}
          numberMasking={numberMasking}
          setName={setName}
          setBrandName={setBrandName}
          campName={name}
          setCountryId={setCountryId}
          setIndustryId={setIndustryId}
          setChangeToggle={setChangeToggle}
          onResetKpi={onResetKpi}
          setCountryChange={setCountryChange}
          setdescription={setdescription}
          channel={channel}
          Country={Country}
        />
      )}
      {step === 4 && step4()}
      {step === 5 && (
        <Step5
          setStep={setStep}
          CHANNELS={CHANNELS}
          data={MockData2()}
          downloadPdfCallback={() => downloadPdf()}
          savefn={save}
          saveLiftfn={saveLift}
        />
      )}
      {step === 6 && step6()}
      <NewAlert
        close={() => setCampaignSuccessMsg(null)}
        msg={campaign_success_msg}
        isSuccess={true}
      />
      <NewAlert
        close={() => setCampaignErrorMsg(null)}
        msg={campaign_error_msg}
        isSuccess={false}
      />
    </div>
  );
};
export default Layout(CreateCampaign);













// const Step3 = memo(() => {
//   useEffect(() => {
//     channesWithAdTypesList.forEach(option => {
//       if(option._id == channel){
//         setCampChannel(option.channelName)
//       }
//     })
//   }, [])
//   return (
//     <div className={classes.boxShadow} style={{ marginTop: "0px" }}>
//       <NewAlert
//         msg={ErrSucMsg}
//         isSuccess={ErrSucType}
//         close={() => setErrSucMesg("")}
//       />
//       <div
//         className={classes.customfield}
//         // style={{ display: "flex", justifyContent: "space-between" }}
//       >
//         <div>
//           <h4 className={classes.redHead}>Brand Uplift Campaign Details</h4>
//           <Grid container direction="row" spacing={2}>
//             <Grid item md={4} sm={4} xs={4}>
//               <TextField
//                 // id="outlined-select-currency-native"
//                 error={campErrors.demographic.error}
//                 helperText={campErrors.demographic.txt}
//                 select
//                 fullWidth
//                 label="Demographic"
//                 value={demographic}
//                 onChange={(e) => {
//                   setDemographic(e.target.value);
//                   setCampErrors({
//                     ...campErrors,
//                     demographic: { error: false, txt: "" },
//                   });
//                 }}
//                 SelectProps={{
//                   native: true,
//                 }}
//                 variant="outlined"
//               >
//                 <option key={0} value={0}>
//                   Select...
//                 </option>
//                 {demographicData?.map((option) => (
//                   <option key={option._id} value={option.name}>
//                     {option.name}
//                   </option>
//                 ))}
//               </TextField>
//             </Grid>
//             <Grid item md={4} sm={4} xs={4}>
//               <TextField
//                 error={campErrors.name.error}
//                 helperText={campErrors.name.txt}
//                 fullWidth
//                 label="Name"
//                 variant="outlined"
//                 style={{ display: "block", marginBottom: "10px" }}
//                 value={name}
//                 onChange={(e) => {
//                   setName(e.target.value);
//                   setCampErrors({
//                     ...campErrors,
//                     name: { error: false, txt: "" },
//                   });
//                 }}
//                 InputProps={{
//                   startAdornment: (
//                     <InputAdornment position="start">
//                       <AccountCircleIcon color="disabled" />
//                     </InputAdornment>
//                   ),
//                 }}
//               />
//             </Grid>
//             <Grid item md={4} sm={4} xs={4}>
//               <TextField
//                 InputProps={{
//                   startAdornment: (
//                     <InputAdornment position="start">
//                       <BusinessIcon color="disabled" />
//                     </InputAdornment>
//                   ),
//                 }}
//                 error={campErrors.industryId.error}
//                 helperText={campErrors.industryId.txt}
//                 style={{ display: "block", marginBottom: "10px" }}
//                 fullWidth
//                 select
//                 label="Industry"
//                 value={industryId}
//                 onChange={(e) => {
//                   setCampErrors({
//                     ...campErrors,
//                     industryId: { error: false, txt: "" },
//                   });
//                   setIndustryId(e.target.value);
//                   setChangeToggle(true);
//                   onResetKpi(e);
//                 }}
//                 SelectProps={{
//                   native: true,
//                 }}
//                 variant="outlined"
//               >
//                 <option disabled key={0} value={0}>
//                   Select...
//                 </option>
//                 {industryListArr &&
//                   industryListArr?.map((option) => (
//                     <option key={option.id} value={option.id}>
//                       {option.name}
//                     </option>
//                   ))}
//               </TextField>
//             </Grid>
//           </Grid>
//           <Grid container direction="row" spacing={2}>
//             <Grid item md={4} sm={4} xs={4}>
//               <TextField
//                 error={campErrors.brand.error}
//                 helperText={campErrors.brand.txt}
//                 fullWidth
//                 label="Brand"
//                 variant="outlined"
//                 style={{ display: "block", marginBottom: "10px" }}
//                 value={brandName}
//                 onChange={(e) => {
//                   setCampErrors({
//                     ...campErrors,
//                     brand: { error: false, txt: "" },
//                   });
//                   setBrandName(e.target.value);
//                 }}
//                 InputProps={{
//                   startAdornment: (
//                     <InputAdornment position="start">
//                       <BusinessIcon color="disabled" size={10} />
//                     </InputAdornment>
//                   ),
//                 }}
//               />
//             </Grid>
//             <Grid item md={4} sm={4} xs={4}>
//               <TextField
//                 error={campErrors.countryId.error}
//                 helperText={campErrors.countryId.txt}
//                 style={{ display: "block", marginBottom: "10px" }}
//                 fullWidth
//                 select
//                 label="Country"
//                 value={countryId}
//                 onChange={(e) => {
//                   setCampErrors({
//                     ...campErrors,
//                     countryId: { error: false, txt: "" },
//                   });
//                   setCountryId(e.target.value);
//                   setCountryChange(true);
//                   onResetKpi(e);
//                 }}
//                 SelectProps={{
//                   native: true,
//                 }}
//                 variant="outlined"
//               >
//                 <option disabled key={0} value={0}>
//                   Select...
//                 </option>
//                 {countryListArr &&
//                   countryListArr?.map((option) => (
//                     <option key={option.id} value={option.id}>
//                       {option.name}-{option.language_name}
//                     </option>
//                   ))}
//               </TextField>
//             </Grid>
//           </Grid>
//           <Grid container direction="row" spacing={2}>
//             <Grid item md={6} sm={6} xs={6}>
//               <TextField
//                 error={campErrors.expectedImpressions.error}
//                 helperText={campErrors.expectedImpressions.txt}
//                 fullWidth
//                 label="Expected Impressions"
//                 variant="outlined"
//                 style={{ display: "block", marginBottom: "10px" }}
//                 value={expectedImpressions}
//                 onChange={(e) => {
//                   let event = e;
//                   if (
//                     event.target.value.length >= 1 &&
//                     event.target.value <= 999999
//                   ) {
//                     setFlag(true);
//                   } else {
//                     setFlag(false);
//                   }
//                   event.target.value = numberMasking(e.target.value);
//                   setCampErrors({
//                     ...campErrors,
//                     expectedImpressions: { error: false, txt: "" },
//                   });
//                   setExpectedImpressions(e.target.value);
//                 }}
//               />
//               {flag && (
//                 <span style={{ color: "red", marginBottom: "0px" }}>
//                   Please enter value above 1 Million
//                 </span>
//               )}
//               <h4 className={classes.redHead}>Channel</h4>
//               <FormControl
//                 fullWidth
//                 component="fieldset"
//                 variant="outlined"
//                 className={[classes.selectControl, classes.customfield].join(
//                   " "
//                 )}
//               >
//                 <TextField
//                   // error={errors.Country.error}
//                   // helperText={errors.Country.txt}
//                   style={{ display: "block", marginBottom: "10px" }}
//                   fullWidth
//                   select
//                   label="Channel"
//                   value={campChannel}
//                   onChange={(e) => {
//                     // setErrors({
//                     //   ...errors,
//                     //   Country: { error: false, txt: "" },
//                     // });
//                     setCampChannel(e.target.value);
//                   }}
//                   // SelectProps={{
//                   //   native: true,
//                   // }}
//                   variant="outlined"
//                 >
//                   <option disabled key={0} value={0}>
//                     Select...
//                   </option>
//                   {channesWithAdTypesList.map((option) => (
//                     <option key={option._id} value={option.channelName}>
//                       {option.channelName}
//                     </option>
//                   ))}
//                 </TextField>
//               </FormControl>
//               {/* 
//               <FormControl
//                 fullWidth
//                 component="fieldset"
//                 variant="outlined"
//                 className={[classes.selectControl, classes.customfield].join(
//                   " "
//                 )}
//               >
//                 <InputLabel id="demo-mutiple-checkbox-label">
//                   Channel
//                 </InputLabel>
//                 <Select
//                   id="outlined-select-currency-native"
//                   multiple
//                   label="Channel"
//                   value={channel}
//                   onChange={(e) => {
//                     {
//                       setChannel(e.target.value);
//                     }
//                   }}
//                   variant="outlined"
//                   renderValue={(ep) => renderChannels}
//                 >
//                   <MenuItem disabled value={0}>
//                     <em>Select...</em>
//                   </MenuItem>
//                   {channesWithAdTypesList.map((option) => (
//                     <MenuItem key={option._id} value={option.channelName}>
//                       <Checkbox
//                         checked={channel.includes(option.channelName)}
//                       ></Checkbox>
//                       {option.channelName}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormControl> */}
//             </Grid>
//             <Grid item md={6} sm={6} xs={6}>
//               <TextField
//                 // error={errors.description.error}
//                 // helperText={errors.description.txt}
//                 fullWidth
//                 label="Description"
//                 multiline
//                 rows={4}
//                 placeholder="Description"
//                 variant="outlined"
//                 value={description}
//                 onChange={(e) => {
//                   // setErrors({
//                   //   ...errors,
//                   //   description: { error: false, txt: "" },
//                   // });
//                   setdescription(e.target.value);
//                 }}
//               />
//             </Grid>
//           </Grid>

//           <h4 className={classes.redHead}>Duration</h4>
//           <DateRangePicker
//             startDate={startDate}
//             endDate={endDate}
//             onStartDateChange={setStartDate}
//             onEndDateChange={setEndDate}
//             minimumDate={new Date()}
//             minimumLength={1}
//             format="dd MMM yyyy"
//             locale={enGB}
//           >
//             {({ startDateInputProps, endDateInputProps, focus }) => (
//               <Grid container direction="row" spacing={2}>
//                 <Grid item md={6} sm={6} xs={6}>
//                   <TextField
//                     error={errors.dates.error}
//                     helperText={errors.dates.txt}
//                     fullWidth
//                     id="startDate"
//                     label="Available From"
//                     variant="outlined"
//                     margin="normal"
//                     onChange={(e) => setDate(e.target.value)}
//                     className={classes.customfield}
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                     {...startDateInputProps}
//                   />
//                 </Grid>
//                 <Grid item md={6} sm={6} xs={6}>
//                   <TextField
//                     error={errors.dates.error}
//                     helperText={errors.dates.txt}
//                     fullWidth
//                     id="endDate"
//                     label="Available Upto"
//                     variant="outlined"
//                     margin="normal"
//                     className={classes.customfield}
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                     {...endDateInputProps}
//                   />
//                 </Grid>
//               </Grid>
//             )}
//           </DateRangePicker>
//         </div>
//       </div>
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "space-between",
//           marginTop: "20px",
//         }}
//       >
//         <button className={classes.addActionBtn} onClick={() => setStep(2)}>
//           Previous
//         </button>
//         <button
//           className={classes.addActionBtn}
//           // onClick={()=>{
//           //   setStep(4);
//           // }}
//           onClick={() => {
//             if (demographic === 0) {
//               setCampErrors({
//                 ...campErrors,
//                 demographic: {
//                   error: true,
//                   txt: "please select an demographic",
//                 },
//               });
//             } else if (name === "") {
//               setCampErrors({
//                 ...campErrors,
//                 name: { error: true, txt: "please enter a name" },
//               });
//             } else if (brandName === "") {
//               setCampErrors({
//                 ...campErrors,
//                 brand: { error: true, txt: "please enter a brand" },
//               });
//             } else if (industryId === 0) {
//               setCampErrors({
//                 ...campErrors,
//                 industryId: { error: true, txt: "please select an Industry" },
//               });
//             } else if (Country === 0) {
//               setCampErrors({
//                 ...campErrors,
//                 countryId: { error: true, txt: "please select countries" },
//               });
//             } else if (expectedImpressions === null) {
//               setCampErrors({
//                 ...campErrors,
//                 expectedImpressions: {
//                   error: true,
//                   txt: "please enter a expected impressions",
//                 },
//               });
//             }
//             // else if (description === "") {
//             //   setCampErrors({
//             //     ...campErrors,
//             //     description: {
//             //       error: true,
//             //       txt: "please Enter a Description",
//             //     },
//             //   });
//             // }
//             else if (startDate === "") {
//               setCampErrors({
//                 ...campErrors,
//                 dates: { error: true, txt: "please select dates" },
//               });
//             } else {
//               setStep(4);
//               // getUplift();
//               // getBasline();
//             }
//           }}
//         >
//           Next
//         </button>
//       </div>
//     </div>
//   );
// });