/* eslint-disable react-hooks/rules-of-hooks */
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import HomeRounded from "@material-ui/icons/HomeRounded";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import icon from "../assets/CI-Icon.svg";
import culturalIntellWhite from "../assets/CI-Logo-white.png";
import LoginCheck from "./loginCheck";

import axios from "axios";
import { useDispatch } from "react-redux";
import { API, SECRET_KEY } from "../constants/index";
import { SaveAdminDropDownData } from "../store/actions/admin/intelligence";

import { green } from "@material-ui/core/colors";
import NewAlert from "../components/NewAlert";
import * as actions from "../redux/actions/user";
import AccountMenu from "./avatar.js";
import Footer from "./footer";

// import CampaignButton from "../assets/campaignButton.png"
import { Autocomplete } from "@material-ui/lab";
import HeatMapLogo from "../assets/Brand Advance Group-02.png";
import CreativeChecker from "../assets/Creative-Checker-New.png";
import CampaignButton from "../assets/brand.png";

import brandAdvanceCTV from "../assets/logos/brandAdvanceCTVNew.png";
import brandAdvanceCreative from "../assets/logos/brandAdvanceCreative.png";
import Influencer from "../assets/logos/brandAdvanceInfluencer.png";
import brandAdvanceSSP from "../assets/logos/brandAdvanceSSPNew.png";
import culturalIntelligenceLogo from "../assets/logos/culturalIntelligenceLogo.png";
import heatMapLogo from "../assets/logos/heatMapLogo.png";
import CampaignLogo from "../assets/logos/mediaPlanLogo.png";
import personasLogo from "../assets/logos/personasLogo.png";
import qnaLogo from "../assets/logos/qnaLogo.png";

const drawerWidth = window.screen.width / 6;

const useStyles = (icon) =>
  makeStyles((theme) => ({
    root: {
      display: "flex",
      minHeight: "100%",
      // width: "83%",
      // [theme.breakpoints.down("sm")]: {
      //   width: "100%",
      // },
    },
    drawer: {
      backgroundColor: "red",
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      background: "white",
      "& .MuiToolbar-regular": {
        minHeight: "50px",
      },
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      backgroundImage: `url(${icon}), linear-gradient(to right, #9211a2, #4911a2)`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      borderLeft: "10px solid #f64d0c",
      width: drawerWidth,
      "&.MuiDrawer-paperAnchorDockedLeft": {
        border: "none",
      },
    },
    content: {},
    logoutBtn: {
      background: "#e96941",
      fontWeight: "400",
      color: "black",
      marginLeft: "auto",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    topicon: {
      width: "85%",
      margin: "20px auto",
    },
    bgicon: {
      position: "absolute",
      top: 0,
    },
    sideMenu: {
      "& li": {
        color: "white",
        listStyleType: "none",
        paddingBottom: "10px",
        margin: "0 auto",
        cursor: "pointer",
        "& a": {
          color: "white",
          textDecoration: "none",
        },
      },
    },
    mainContent: {
      // width: "calc(100% - 240px)",
      width: `calc(100% - ${drawerWidth}px)`,
      margin: "0 auto",
      display: "flex",
      flexDirection: "column"
    },
    campaignBtn: {
      width: "150px",
      height: "57px",
      background: "#bebebe",
      padding: "0.5rem",
      borderRadius: "30px",
      cursor: "pointer",
      "&:hover": {
        background: "#fff",
        boxShadow: "5px 5px 10px #c916df, -5px -5px 10px #6b26d9",
      },
    },
    campaignBtnSelected: {
      width: "150px",
      height: "57px",
      borderRadius: "30px",
      padding: "0.5em",
      cursor: "pointer",
      background: "#fff",
      boxShadow: "5px 5px 10px #c916df, -5px -5px 10px #6b26d9",
    },
    heatBtn: {
      width: "150px",
      height: "55px",
      background: "#bebebe",
      padding: "0.5rem",
      borderRadius: "30px",
      cursor: "pointer",
      "&:hover": {
        background: "#fff",
        boxShadow: "5px 5px 10px #c916df, -5px -5px 10px #6b26d9",
      },
    },
    heatBtnSelected: {
      width: "150px",
      height: "55px",
      borderRadius: "30px",
      padding: "0.5em",
      cursor: "pointer",
      background: "#fff",
      boxShadow: "5px 5px 10px #c916df, -5px -5px 10px #6b26d9",
    },
    creativeBtn: {
      width: "150px",
      background: "#bebebe",
      padding: "0.5rem",
      borderRadius: "30px",
      cursor: "pointer",
      "&:hover": {
        background: "#fff",
        boxShadow: "5px 5px 10px #c916df, -5px -5px 10px #6b26d9",
      },
    },
    creativeBtnSelected: {
      width: "150px",
      borderRadius: "30px",
      padding: "0.5em",
      cursor: "pointer",
      background: "#fff",
      boxShadow: "5px 5px 10px #c916df, -5px -5px 10px #6b26d9",
    },
    selectedSection: {
      background: "#fff",
      boxShadow: "5px 5px 10px #c916df, -5px -5px 10px #6b26d9",
      borderRadius: "15px",
    },
    hoverSection: {
      "&:hover": {
        transition: "0.5s",
        background: "#fff",
        boxShadow: "5px 5px 10px #c916df, -5px -5px 10px #6b26d9",
        borderRadius: "15px",
      },
    },
    componentWrapper: {
      width: "100%",
      padding: "60px 30px",
      minHeight: "92.5vh",
    },
    wrapperBG: {
      background: "#4911a2",
    },
  }));

const MainLayout = (WrappedComponent) => (props) => {
  const history = useHistory();
  const currentPath = history.location.pathname;
  // if (
  //   window.localStorage.getItem("role") !== "media_agency" ||
  //   window.localStorage.getItem(SECRET_KEY) === null
  // ) {
  //   history.push("/");
  // }
  //const { window } = props;
  const username = window.localStorage.getItem("username");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  const loggedInSession = useSelector((state) => state.loggedInSession);
  const classes = useStyles(icon)();

  LoginCheck("/mediaPlanner/dropdowns", null, history);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const logout = () => {
    window.localStorage.setItem(SECRET_KEY, null);
    window.localStorage.setItem("role", null);
    localStorage.removeItem("sections");
    dispatch(actions.error(null));
    dispatch(actions.loginSuccessfully(null));
    setTimeout(() => {
      history.push("/");
    }, 500);
  };

  // const container =
  //   window !== undefined ? () => window().document.body : undefined;

  let page = "Home";
  let loc = history.location.pathname;
  if (loc.includes("FAQ")) {
    page = "FAQ";
  }
  if (loc.includes("personas")) {
    page = "personas";
  }
  if (loc.includes("intelligence")) {
    page = "intelligence";
  }
  if (loc.includes("creative-consultancy")) {
    page = "creative-consultancy";
  }
  if (loc.includes("/campaign/report")) {
    page = "campaignReport";
  }
  if (loc.includes("heatMap")) {
    page = "heatMap";
  }
  if (loc.includes("influencer")) {
    page = "influencer";
  }
  // if (loc.includes("talkwalker")) {
  //   page = "talkwalker";
  // }

  return (
    <div className={classes.root}>
      <NewAlert />
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <div
          style={{
            display: "flex",
            padding: "2px",
            justifyContent: "space-between",
          }}
        >
          <Button
            style={{
              minWidth: "0px",
              height: "40px",
              marginTop: "3px",
              background: "#9211a2",
            }}
            onClick={() => {
              history.push("/media-planner");
            }}
          >
            <HomeRounded
              style={{ height: "30px", width: "30px", color: "#ffffff" }}
            />
          </Button>
          <AccountMenu handleLogout={logout} name={username} />
          {/* <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton> */}

          {/* <Button
            //onClick={logout}
            style={{
              background: "white",
              fontWeight: "400",
              color: "#DC37C6",
              marginRight: "10px",
            }}
          >
            {username}
          </Button> */}
          {/* <Button
            onClick={logout}
            style={{
              background: "#DC37C6",
              fontWeight: "400",
              color: "white",
            }}
          >
            Logout
          </Button> */}
        </div>
      </AppBar>
      <nav
        className={classes.drawer}
        aria-label="mailbox folders"
        style={{ background: "red" }}
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css" style={{ background: "red" }}>
          <Drawer
            //container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <img className={classes.topicon} src={culturalIntellWhite} />
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css" style={{ background: "red" }}>
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <img className={classes.topicon} src={culturalIntellWhite} />

            <ul
              className={`${classes.sideMenu} sidebar-logo-list`}
              style={{
                display: currentPath === "/media-planner" ? "none" : "block",
              }}
            >
              <li
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  margin: "0 0.3rem",
                }}
              >
                <Link to="/media-planner/campaign">
                  <img
                    src={CampaignLogo}
                    alt="Campaign Logo"
                    width="100%"
                    className={
                      currentPath === "/media-planner/campaign"
                        ? classes.selectedSection
                        : classes.hoverSection
                    }
                  />
                </Link>
                <Link to="/media-planner/personas">
                  <img
                    src={personasLogo}
                    alt="personas Logo"
                    width="100%"
                    className={
                      currentPath === "/media-planner/personas"
                        ? classes.selectedSection
                        : classes.hoverSection
                    }
                  />
                </Link>
              </li>
              <li
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  margin: "0 0.3rem",
                }}
              >
                <Link to="/media-planner/heatMap">
                  <img
                    src={heatMapLogo}
                    alt={"heatMap Logo"}
                    width="100%"
                    className={
                      currentPath === "/media-planner/heatMap"
                        ? classes.selectedSection
                        : classes.hoverSection
                    }
                  />
                </Link>
                <Link
                  to={{
                    pathname: "/media-planner/intelligence",
                  }}
                >
                  <img
                    src={culturalIntelligenceLogo}
                    alt="personas Logo"
                    width="100%"
                    className={
                      currentPath === "/media-planner/intelligence"
                        ? classes.selectedSection
                        : classes.hoverSection
                    }
                  />
                </Link>
              </li>
              <li
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  margin: "0 0.3rem",
                }}
              >
                <Link to="/media-planner/campaign/report">
                  <img
                    src={brandAdvanceSSP}
                    alt={"brandAdvanceSSP Logo"}
                    width="100%"
                    className={
                      currentPath === "/media-planner/campaign/report"
                        ? classes.selectedSection
                        : classes.hoverSection
                    }
                  />
                </Link>
                {/* <Link to="/media-planner">  */}
                {/* /media-planner/campaign/ctv */}
                  {/* <img
                    src={brandAdvanceCTV}
                    alt={"brandAdvanceCTV Logo"}
                    width="100%"
                    className={
                      currentPath === "/media-planner/campaign/ctv"
                        ? classes.selectedSection
                        : classes.hoverSection
                    }
                  />
                </Link> */}
              {/* </li>
              <li
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  margin: "0 0.3rem",
                }}
              > */}
                <Link
                  to={{
                    pathname: "/media-planner/creative-consultancy",
                  }}
                >
                  <img
                    src={brandAdvanceCreative}
                    alt={"Creative consultancy"}
                    width="100%"
                    className={
                      currentPath === "/media-planner/creative-consultancy"
                        ? classes.selectedSection
                        : classes.hoverSection
                    }
                  />
                </Link>
                
              </li>
              <li
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  margin: "0 0.3rem"
                }}
              >
                <Link
                  to={{
                    pathname: "/media-planner/influencer", // /media-planner/influencer
                  }}
                >
                  <img
                    src={Influencer}
                    alt={"Brand Advance Influencer"}
                    width="100%"
                    className={
                      currentPath === "/media-planner/influencer"
                        ? classes.selectedSection
                        : classes.hoverSection
                    }
                  />
                </Link>
                <Link
                  to={{
                    pathname: "/media-planner/campaign/report",
                  }}
                >
                  <img
                    src={brandAdvanceCTV}
                    alt={"brandAdvanceCTV Logo"}
                    width="100%"
                    className={
                      currentPath === "/media-planner/campaign/report"
                        ? classes.selectedSection
                        : classes.hoverSection
                    }
                  />
                </Link>
              </li>
              <li
                style={{
                  textAlign: "center",
                  fontSize: "24px",
                  position: "relative",
                  bottom: "-110px",
                }}
              >
                <Link
                  style={
                    page === "FAQ"
                      ? { fontWeight: "700", color: "white" }
                      : { fontWeight: "300", color: "#E2DEEA" }
                  }
                  to="/media-planner/FAQ"
                >
                  FAQ
                </Link>
              </li>
              {/* <li>
                <Link
                  style={
                    page === "talkwalker"
                      ? { fontWeight: "700", color: "white" }
                      : { fontWeight: "300", color: "#E2DEEA" }
                  }
                  to="/media-planner/talkwalker"
                >
                  Talkwalker
                </Link>
              </li> */}
            </ul>
          </Drawer>
        </Hidden>
      </nav>
      <div className={classes.toolbar} />
      <div className={classes.mainContent}>
        <Grid
          spacing={0}
          className={
            currentPath === "/media-planner" ||
            currentPath === "/media-planner/campaign"
              ? `${classes.componentWrapper} ${classes.wrapperBG}`
              : classes.componentWrapper
          }
          style={{height:"100%"}}
        >
          <Grid item md={12}>
            <WrappedComponent {...props} />
          </Grid>
        </Grid>
        <Footer />
      </div>
    </div>
  );
};

export default MainLayout;
