import React, { useState, useEffect } from 'react'
import { makeStyles, fade } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'

import Button from '@material-ui/core/Button'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { DataGrid } from '@material-ui/data-grid';
import CreateIcon from '@material-ui/icons/Create';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import InventoryForm from '../../../components/InventoryForm'
import { useHistory } from 'react-router-dom';
import { BorderAll } from '@material-ui/icons'
import { getMediaOwnerWithAllInvenrty } from '../../../redux/actions/inventory'

const useStyles = makeStyles((theme) => ({
    boxShadow: {
        position: 'relative',
        padding: '16px',
        boxShadow: '0px 0px 20px rgba(0,0,0,0.30), 0 20px 20px rgba(0,0,0,0.22)',
        borderRadius: '30px',
        backgroundColor: 'white',
        marginTop: '-30px'
    },
    pageTitle: {
        color: '#e96941',
        fontWeight: 'normal'
    },
    formTitle: {
        color: "#1d8bf7",
        fontWeight: 'normal'
    },
    paper: {
        padding: '16px',
        color: theme.palette.text.secondary,
    },
    addActionBtn: {
        padding: '10px 20px 10px 10px',
        backgroundColor: "#136cc3",
        color: 'white',
        fontWeight: 'bold',
        borderRadius: '10px',
        textTransform: "none",
        marginBottom: '20px',
        '&:hover': {
            background: "#136cc3",
        }
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
    },
    modalPaper: {
        borderRadius: '30px',
        width: '960px',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        borderColor: 'transparent',
        boxShadow: theme.shadows[5],
        padding: '20px',
        textAlign: 'center',
        '&:focus': {
            outline: 'none',
        }
    },
    gridRoot: {
        '& .font-tabular-nums': {
          fontVariantNumeric: 'tabular-nums',
        },
      },
      
    customfield: {
        paddingTop: '0',
        marginTop: '0',
        backgroundColor: '#f4f4f8',
        borderRadius: '5px',
        '& .MuiInputLabel-root.Mui-shrink': {
            color: 'red'
            // "& .Mui-shrink": {
            // 	color: 'red'
            // },
        },
        '&:hover': {
            backgroundColor: '#fff',
        },
        '&$focused': {
            backgroundColor: '#fff',
            boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            borderColor: theme.palette.primary.main,
        },
        '& .MuiOutlinedInput-input': {
            padding: '8.5px 14px',
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '5px',

            '& fieldset': {
                borderColor: 'grey',
            },
            '&:hover fieldset': {
            },
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: 'grey'
        },
        "& .MuiInputLabel-root.Mui-focused": {
            color: 'grey'
        },
        "& .MuiInputLabel-root.MuiInputLabel-shrink": {
            margin: '0!important'
        },
        "& .MuiInputLabel-root.MuiInputLabel-animated": {
            marginTop: '-8px'
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: 'grey'
        }
    },
}))


export default function Inventory() {

    const dispatch = useDispatch()
    const { register, handleSubmit, errors, control } = useForm({ mode: 'onSubmit', reValidateMode: 'onSubmit' })
    const mediaOwner = useSelector((state) => state.mediaOwner);
    const mediaOwner1 = useSelector((state) => state.mediaOwner1);
    const mediaOwnerWithAllInventry = useSelector((state) => state.mediaOwnerWithAllInventry);
    const apiMediaOwnerSaved=useSelector(state=>state.apiMediaOwnerSaved);
    const classes = useStyles()
    const [open, setOpen] = useState(false);
    const [inventory, setInventory] = useState(null);
    const [inventoryData, setInventoryData] = useState([]);

    const [onLoadInventry, setOnLoadInventry]=useState(false);
    const history = useHistory();


      const rowsData = [
        {
          id: 1,
          media: "mediaYasir",
          inventory: "spokenAd",
          channel: "radio",
          volume: "200x200",
          unitCost: "23",
          
        },
        {
          id: 2,
          media: "mediaYasir",
          inventory: "spokenAd",
          channel: "radio",
          volume: "200x200",
          unitCost: "23",
          
        },
        {
          id: 3,
          media: "mediaYasir",
          inventory: "spokenAd",
          channel: "radio",
          volume: "200x200",
          unitCost: "23",
          
        },
      ];

    const columns = [
        { field: 'media', headerName: 'Media', width: 150 },
        { field: 'inventory', headerName: 'Inventory',width: 200 },
        { field: 'channel', headerName: 'Channel', width: 100 },
        { field: 'volume',headerName: 'Volume', width: 100 },
        { field: 'unitCost',headerName: 'Unit Cost'},
       


        // { field: '', headerName: <CreateIcon style={{ cursor: 'pointer', color: '#ccc' }} />,
        //     sortable: false,
        //     width: 60,
        //     renderCell: (params) => (
        //         <CreateIcon onClick={() => handleEdit(params)} style={{ cursor: 'pointer', color: '#ccc' }} />
        //     ),
        // },

        // {
        //     field: '',headerName: <CloseIcon style={{ cursor: 'pointer', color: 'red' }} />,
        //     sortable: false,
        //     width: 60,
        //     renderCell: (params) => (
        //         <CloseIcon style={{ cursor: 'pointer', color: 'red' }} />
        //     ),
        // },
    ];

    const handleEdit = (param) => {
        setInventory(param.data)
        setOpen(true);
    }

    const deleteSector = (id) => {
        console.log(id, 'the id')
    }

    const handleOpen = () => {
        console.log('mediaOwner1.mediaOwner', mediaOwner1.mediaOwner)

        // if(mediaOwner1.mediaOwner === undefined) {
        //  history.push('/media-owner/inventory-create');
        // } else {
        //     setInventory(null)
        //     setOpen(true);
        // }
        history.push('/media-owner/inventory');

        
    };

    const handleClose = () => {
        setOpen(false);
    };

    console.log('mediaOwner1111yyyyyyyyyyy', mediaOwner1);
    console.log('apiMediaOwnerSaved',apiMediaOwnerSaved);
    
    useEffect(() => {
        // console.log(mediaOwner, 'the media owner refrsh')
        // console.log('mediaOwner1', mediaOwner1)

        // if(mediaOwner1 && mediaOwner1.mediaOwner) {
        //     console.log('mediaOwner1.invt', mediaOwner1)
        //     setInventoryMap(mediaOwner1)
        // }

        if(!onLoadInventry){
            getMediaOwnerWithAllInventryDispatch();
            setOnLoadInventry(true)
        }else{
            setInventoryDataForGrid(mediaOwnerWithAllInventry);
        }
        

    }, [mediaOwner, mediaOwner1,mediaOwnerWithAllInventry])

    const getMediaOwnerWithAllInventryDispatch=()=>{
        const { mediaOwnerId }= apiMediaOwnerSaved;

        dispatch(getMediaOwnerWithAllInvenrty(mediaOwnerId));

    }
    //getMediaOwnerWithAllInventryDispatch();

    const onSubmit = insight => {
        console.log(insight, 'form data')

    }

    // const setInventoryMap = (mediaOwner) => {
    //     console.log(mediaOwner, 'mediaOwner')
    //     const inventoryMap = [];
    //     Object.entries(mediaOwner.inventory?.value).map((val, index) => {
    //         if (val[1].checked) {
    //             inventoryMap.push({
    //                 media: mediaOwner.profile.title.name,
    //                 channel: mediaOwner.profile.channel.name,
    //                 inventory: val[1].name,
    //                 id: index,
    //                 name: val[0],
    //                 unitCost: val[1]?.value?.unitCost || 0,
    //                 colorSpace: val[1]?.value?.colorSpace || '',
    //                 fileType: val[1]?.value?.fileType || '',
    //                 maxFileSize: val[1]?.value?.maxFileSize || '',
    //                 notes: val[1]?.value?.notes || '',
    //                 height: val[1]?.value?.height || '',
    //                 width: val[1]?.value?.width || ''
    //             })
    //         }
    //     })
    //     setInventoryData(inventoryMap)
    // }


    const setInventoryMap = (mediaOwner) => {
        console.log(mediaOwner, 'mediaOwner')
        const inventoryMap = [];
        mediaOwner.mediaOwner.inventory.map((val, index) => {
           // if (val[1].checked) {
                inventoryMap.push({
                    id: index,
                    media: mediaOwner.mediaOwner.profile.title.name,
                    channel: val.channel,
                    inventory: val.data.name,
                    volume:val.data.volume,
                    unitCost: val.data.unitCost,
                })
           // }
        })
        setInventoryData(inventoryMap)
    }

    const setInventoryDataForGrid = (mediaOwnerWithAllInventry) => {
        console.log("YAHOOOOOOO_ DATA", mediaOwnerWithAllInventry)
        const {mediaOwner , inventories}=mediaOwnerWithAllInventry;
        const inventoryMap = [];
        inventories && inventories.map((val, index) => {
           // if (val[1].checked) {
                inventoryMap.push({
                    id: index,
                    media: mediaOwner?.title.name,
                    channel: val?.channel,
                    inventory: ` ${val?.data?.name} (${val.data?.sizes})`,
                    volume:val.data?.volume,
                    unitCost: val?.data?.unitCost,
                })
           // }
        })
        setInventoryData(inventoryMap)
    }

    const sendNewInventories = (mediaOwnerNew) => {

        setInventoryMap(mediaOwnerNew)
        dispatch({
            type: 'CREATE_MEDIA_OWNER',
            mediaOwner: mediaOwnerNew
        })
        setOpen(false);
    }


    return (
        <div className={classes.boxShadow}>
            <div className={classes.paper}>
                <Button onClick={() => handleOpen()} className={classes.addActionBtn} variant="outlined" color="primary">
                    <AddCircleOutlineIcon style={{ marginRight: '10px', color: '#a2e60f' }} />
                        Create Inventory
                </Button>
                <div style={{ height: 400, width: '100%' }} className={classes.gridRoot}>
                    <DataGrid rows={inventoryData} columns={columns} pageSize={9} />
                </div>
            </div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                fullWidth
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.modalPaper}>
                        <InventoryForm sendNewInventories={sendNewInventories} inventory={inventory} mediaOwner={mediaOwner} />
                    </div>
                </Fade>
            </Modal>
        </div>
    )
}

