import React, { useEffect } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const ToastMessage = ({ msg, isSuccess, close }) => {
    useEffect(() => {
        let id = setTimeout(() => {
            if (msg?.length > 0) {
                close();
            }
        }, 2500);
        return () => clearTimeout(id);
    }, [msg]);

    return (
        <div>
            {msg?.length > 0 && (
                <Snackbar open={msg?.length > 0}>
                    <MuiAlert elevation={6} variant="filled" severity={isSuccess ? "success" : "error"}>
                        {msg ? msg : ""}{" "}
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            style={{ padding: "2px" }}
                            onClick={() => close()}
                        >
                            <CloseIcon />
                        </IconButton>
                    </MuiAlert>
                </Snackbar>
            )}
        </div>
    );
};

export default ToastMessage;
