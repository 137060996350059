import { Box, Checkbox, Grid, TextField, Typography } from "@material-ui/core"
import { Info, Warning } from "@material-ui/icons"
import { enGB } from "date-fns/locale"
import React from "react"
import { DateRangePicker } from "react-nice-dates"
import LoaderCircle from "../../../../../../components/Loader/Loader"
import Selection from "../../../../../../components/Select/Selection"
import useStyles from "../../../../../../components/StyleClasses"
import { SERVE_TYPES, useChannelAssignmentPageCtx } from "../../../ChannelAssignmentPage"
import DayPartingTimeSelection from "../DayPartingTimeSelection"
import {
    DayPartingOptions,
    DeliveryPacingOptions,
    HoursArr,
    InputField,
    PriorityOptions,
    QuotaSelectOptions,
    SelectionField,
    UnderDeliveryBehaviourOptions,
    useInputStyles,
} from "../Step2"

export default function Pacing_Schedule_Section({ states, handleStatesChanges, setStates }) {
    const {
        pacing__schedule__options,
        selectedServeType,
        step2StatesRef,
        isLoadingPacingScheduleOptions,
    } = useChannelAssignmentPageCtx()
    const classes = useStyles()
    const styles = useInputStyles()

    return (
        <>
            <div style={{ marginTop: "20px" }} className="form-section-heading">
                <Typography>Pacing & Schedule</Typography>
            </div>
            <div className="flex flex-col gap-15" style={{ paddingInline: "10px" }}>
                <SelectionField
                    label={"Priority"}
                    options={PriorityOptions}
                    isClearable={false}
                    defaultValue={states.priority}
                    value={states.priority}
                    onChange={(e) => handleStatesChanges("priority", e)}
                    style={{ margin: 0 }}
                />
                <div className="flex align-center" style={{ gap: "40px" }}>
                    <Grid item style={{ width: "20%" }}>
                        <label>Schedule</label>
                    </Grid>
                    <Grid item xs={5}>
                        <Box className="flex flex-row align-center gap-10">
                            <select
                                className="MuiFormControl-root makeStyles-selectControl-45 makeStyles-customfield-46 MuiFormControl-fullWidth"
                                style={{
                                    border: "1px solid hsl(0, 0%, 70%)",
                                    height: "38px",
                                    paddingLeft: "10px",
                                    background: "#fff",
                                    flexDirection: "unset",
                                    margin: 0,
                                }}
                                value={states.schedule_key}
                                onChange={(e) => {
                                    handleStatesChanges("schedule_key", e.target.value)
                                }}
                            >
                                <optgroup label="Default Options">
                                    <option value="new">Create a new schedule from inputs.</option>
                                </optgroup>

                                <optgroup label="-- Existing Campaign Schedules --"></optgroup>
                                {pacing__schedule__options?.campaignListSchedule?.map((opt, i) => {
                                    return (
                                        <optgroup label={opt.name} key={i}>
                                            {<option value={opt.schedule}>{opt.campaign}</option>}
                                        </optgroup>
                                    )
                                })}

                                <optgroup label="-- Existing Channel Schedules --"></optgroup>
                                {pacing__schedule__options?.channelListSchedule?.map((opt, i) => {
                                    return (
                                        <optgroup label={opt.name} key={i}>
                                            {<option value={opt.schedule}>{opt.campaign}</option>}
                                        </optgroup>
                                    )
                                })}
                            </select>
                            {isLoadingPacingScheduleOptions && (
                                <span>
                                    <LoaderCircle
                                        style={{
                                            position: "unset",
                                            width: "20px",
                                            height: "20px",
                                        }}
                                    />
                                </span>
                            )}
                        </Box>
                    </Grid>
                </div>

                {/* <SelectionField
                    label={"Schedule"}
                    options={DeliveryPacingOptions}
                    isClearable={false}
                    defaultValue={states.delivery_pacing}
                    value={states.delivery_pacing}
                    onChange={(e) => handleStatesChanges("delivery_pacing", e)}
                /> */}
                <SelectionField
                    label={"Delivery Pacing"}
                    options={DeliveryPacingOptions}
                    isClearable={false}
                    defaultValue={states.delivery_pacing}
                    value={states.delivery_pacing}
                    onChange={(e) => handleStatesChanges("delivery_pacing", e)}
                    style={{ margin: 0 }}
                />
                {selectedServeType == SERVE_TYPES["Fixed Delivery"] && (
                    <InputField
                        label={"Weight"}
                        type="number"
                        value={states.weight}
                        onChange={(e) =>
                            e.target.valueAsNumber >= 0 && handleStatesChanges("weight", e.target.valueAsNumber)
                        }
                        required
                    />
                )}
                {selectedServeType == SERVE_TYPES["Auction"] && (
                    <Grid
                        container
                        className="flex align-center"
                        style={{ gap: "40px", flexWrap: "nowrap", alignItems: "baseline" }}
                    >
                        <Grid item style={{ width: "20%" }}>
                            <label>
                                Bid Price <span className="required-star">*</span>
                            </label>
                        </Grid>
                        <Grid item className="flex flex-col gap-10">
                            <div className="flex gap-10">
                                <div className="flex align-center gap-10">
                                    <span>USD</span>
                                    <TextField
                                        value={"0.00"}
                                        placeholder="0.00"
                                        disabled
                                        variant="outlined"
                                        className={styles.input}
                                    />
                                </div>
                                <div className="flex align-center gap-10">
                                    <span>CPM</span>
                                    <Info titleAccess='The Bid Price is calculated by a combination of CPM settings in the "Financials" section below.' />
                                </div>
                            </div>
                            <div className="flex gap-10 align-center">
                                <Warning color="error" />
                                <Typography color="error">
                                    {" "}
                                    Bid price must be a positive number greater than zero.
                                </Typography>
                            </div>
                            <div className="flex gap-10 align-center">
                                <Typography style={{ background: "#eee", padding: "5px 10px", fontSize: "14px" }}>
                                    Auction bids are currently only available in United States Dollars (USD). <br /> All
                                    financial components have been swapped to USD.
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                )}

                <div className="flex align-center" style={{ gap: "40px" }}>
                    <Grid item style={{ width: "20%" }}>
                        <label>Schedule</label>
                    </Grid>
                    <Grid item xs={5}>
                        <Checkbox
                            color="primary"
                            style={{ paddingLeft: 0, paddingBlock: 0 }}
                            onChange={(e) => {
                                e.persist()
                                setStates((prev_states) => ({ ...prev_states, isScheduleChecked: e.target.checked }))
                            }}
                            checked={states.isScheduleChecked}
                            id="schedule_check"
                        />{" "}
                        <label htmlFor="schedule_check">Enable schedule</label>
                    </Grid>
                </div>
                {states.isScheduleChecked && (
                    <div className="flex align-center" style={{ gap: "40px" }}>
                        <Grid item style={{ width: "20%" }}></Grid>
                        <Grid item md={5} sm={5} xs={5}>
                            <DateRangePicker
                                startDate={states.startDate}
                                endDate={states.endDate}
                                onStartDateChange={(date) =>
                                    setStates((prev_states) => ({ ...prev_states, startDate: date }))
                                }
                                onEndDateChange={(date) =>
                                    setStates((prev_states) => ({ ...prev_states, endDate: date }))
                                }
                                minimumDate={states.startDate || new Date()}
                                minimumLength={1}
                                format="dd MMM yyyy"
                                locale={enGB}
                            >
                                {({ startDateInputProps, endDateInputProps, focus }) => {
                                    return (
                                        <>
                                            <div
                                                className="flex align-center justify-center"
                                                style={{ marginBottom: "20px" }}
                                            >
                                                <label htmlFor="" style={{ marginRight: "20px" }}>
                                                    Start
                                                </label>
                                                <TextField
                                                    fullWidth
                                                    id="startDate"
                                                    label="Available From"
                                                    variant="outlined"
                                                    margin="normal"
                                                    onChange={(e) => {
                                                        setStates((prev_states) => ({
                                                            ...prev_states,
                                                            startDate: e.target.value,
                                                        }))
                                                    }}
                                                    className={classes.customfield}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    {...startDateInputProps}
                                                    style={{ marginRight: "10px" }}
                                                />
                                                <Selection
                                                    options={HoursArr}
                                                    // options={HoursArr.map((hour) => ({ label: hour, value: hour }))}
                                                    style={{ marginTop: 0 }}
                                                    placeholder="Select time"
                                                    value={states.startHour}
                                                    onChange={(e) => {
                                                        setStates((prev_states) => ({ ...prev_states, startHour: e }))
                                                    }}
                                                />
                                            </div>
                                            <div
                                                className="flex align-center justify-center"
                                                style={{ marginBottom: "20px" }}
                                            >
                                                <label htmlFor="" style={{ marginRight: "20px" }}>
                                                    End
                                                </label>
                                                <TextField
                                                    //   error={errors.dates.error}
                                                    //   helperText={errors.dates.txt}
                                                    fullWidth
                                                    id="startDate"
                                                    label="Available From"
                                                    variant="outlined"
                                                    margin="normal"
                                                    onChange={(e) => {
                                                        setStates((prev_states) => ({
                                                            ...prev_states,
                                                            endDate: e.target.value,
                                                        }))
                                                    }}
                                                    className={classes.customfield}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    {...endDateInputProps}
                                                    style={{ marginRight: "10px" }}
                                                />
                                                <Selection
                                                    options={HoursArr}
                                                    // options={HoursArr.map((hour) => ({ label: hour, value: hour }))}
                                                    style={{ marginTop: 0 }}
                                                    placeholder="Select time"
                                                    onChange={(e) => {
                                                        setStates((prev_states) => ({ ...prev_states, endHour: e }))
                                                    }}
                                                    value={states.endHour}
                                                />
                                            </div>
                                        </>
                                    )
                                }}
                            </DateRangePicker>
                        </Grid>
                    </div>
                )}

                {/* quota */}
                <div className="flex align-center" style={{ gap: "40px" }}>
                    <Grid item style={{ width: "20%" }}>
                        <label>Quota</label>
                    </Grid>
                    <Grid item xs={5}>
                        <Checkbox
                            color="primary"
                            style={{ paddingLeft: 0, paddingBlock: 0 }}
                            onChange={(e) => {
                                e.persist()
                                setStates((prev_states) => ({ ...prev_states, isQuotaChecked: e.target.checked }))
                            }}
                            checked={states.isQuotaChecked}
                            id="quota_check"
                        />{" "}
                        <label htmlFor="quota_check">Enable quota</label>
                    </Grid>
                </div>
                {states.isQuotaChecked && (
                    <div className="flex align-center" style={{ gap: "40px" }}>
                        <div style={{ width: "20%" }}></div>

                        <div className="flex flex-col" style={{ gap: "5px", justifyContent: "end" }}>
                            <div className=" flex flex-row align-center">
                                <span style={{ opacity: 0, marginRight: "5px" }}>+</span>
                                <InputField
                                    label={"shown"}
                                    value={states.quota__shown}
                                    disabled
                                    labelPosition={"right"}
                                    labelStyles={{ marginLeft: "0px" }}
                                    type="number"
                                />
                            </div>
                            <div className="  flex flex-row align-center">
                                <span style={{ marginRight: "5px" }}>+</span>
                                <InputField
                                    label={"remaining"}
                                    value={states.quota__remaining}
                                    onChange={(e) => handleStatesChanges("quota__remaining", e.target.valueAsNumber)}
                                    labelPosition={"right"}
                                    labelStyles={{ marginLeft: "0px" }}
                                    type="number"
                                />
                            </div>
                            <div style={{ border: "1px solid #dadada" }}></div>
                            <div className=" flex flex-row align-center">
                                <span style={{ marginRight: "5px" }}>=</span>
                                <div style={{ gap: "40px" }} className="flex flex-row align-center">
                                    <InputField
                                        label={"remaining"}
                                        value={states.quota__total}
                                        onChange={(e) => handleStatesChanges("quota__total", e.target.valueAsNumber)}
                                        labelPosition={"hide"}
                                        labelStyles={{ marginLeft: "0px" }}
                                        type="number"
                                    />
                                    <Selection
                                        style={{ width: "unset", marginTop: "0", marginBottom: 0 }}
                                        options={QuotaSelectOptions}
                                        defaultValue={states.quota__selection}
                                        value={states.quota__selection}
                                        onChange={(e) => handleStatesChanges("quota__selection", e)}
                                        isClearable={false}
                                    />
                                    total
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <SelectionField
                    label={"Under Delivery Behaviour"}
                    options={UnderDeliveryBehaviourOptions}
                    defaultValue={states.under_delivery_behaviour}
                    onChange={(e) => handleStatesChanges("under_delivery_behaviour", e)}
                    isClearable={false}
                    style={{ margin: 0 }}
                />

                {/* daytime parting */}
                <div className="flex align-center" style={{ gap: "40px" }}>
                    <Grid item style={{ width: "20%" }}>
                        <label>Dayparting</label>
                    </Grid>
                    <Grid item xs={5} style={{ marginBottom: "10px" }}>
                        <Checkbox
                            color="primary"
                            style={{ paddingLeft: 0, paddingBlock: 0 }}
                            onChange={(e) => {
                                e.persist()
                                setStates((prev_states) => ({ ...prev_states, isDaypartingChecked: e.target.checked }))
                            }}
                            checked={states.isDaypartingChecked}
                            id="dayparting_check"
                        />{" "}
                        <label htmlFor="dayparting_check">Serve during specific days and hours</label>
                    </Grid>
                </div>
                {states.isDaypartingChecked && (
                    <>
                        <SelectionField
                            options={DayPartingOptions}
                            value={states.day_parting_type}
                            defaultValue={states.day_parting_type}
                            onChange={(e) => handleStatesChanges("day_parting_type", e)}
                            isClearable={false}
                            style={{ margin: 0 }}
                        />
                        <DayPartingTimeSelection step2StatesRef={step2StatesRef} />
                    </>
                )}
            </div>
        </>
    )
}
