import React, { useState, useEffect } from "react";
import MainLayout from "../../layouts/adminMainLayout";

import Pagination from "@material-ui/lab/Pagination";

import CircularProgress from "@material-ui/core/CircularProgress";
//Button
import Button from "@material-ui/core/Button";
import NewAlert from "../../components/NewAlert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
//Icon

import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { useHistory } from "react-router-dom";
import useStyles from "../../components/StyleClasses";
import CustomizedTables from "../mediaOwner/mytable";
import { API, SECRET_KEY } from "../../constants/index";
import axios from "axios";

const Company = () => {
  const classes = useStyles();
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [DATA, setDATA] = useState(null);
  const [loading, setloading] = useState(false);
  const [page, setpage] = useState(1);
  const [search, setSearch] = useState("");
  const access_token = window.localStorage.getItem(SECRET_KEY);
  const [pagecount, setPageCount] = useState(0);
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [deleteId, setDeleteId] = useState("");

 ///////errors & successs //////////
 const [ErrSucMsg, setErrSucMesg] = useState("");
 const [ErrSucType, setErrSucType] = useState(true);

  const getCompanyList = () => {
    setloading(true);
    setTableData([]);
    axios
      .get(`${API}/admin/admin-company?page=${page}${search ? '&name='+search : ''}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setloading(false);
          setDATA(res.data.data);
          setPageCount(res.data.total);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };
  useEffect(() => getCompanyList(), []);
  useEffect(() => getCompanyList(), [page]);

  var myVar;

  function myFunction() {
    myVar = setTimeout(function () {
      getCompanyList();
    }, 200);
  }

  function myStopFunction() {
    clearTimeout(myVar);
  }
  useEffect(() => myFunction(), [search]);
  useEffect(() => {
    if (DATA) {
      let td = [];
      DATA.forEach((e) => {
        td.push({
          data: [
            e._id,
            e.companyName,
          ],
          _id: e._id,
        });
      });
      setTableData(td);
    }
  }, [DATA]);

  const DeleteCompany = () => {
    // const payload = { id: id };
    axios
      .delete(`${API}/admin/admin-company/${deleteId}`, {
        headers: { Authorization: `Bearer ${access_token}` },
        // data: payload,
      })
      .then((res, err) => {
        if (res) {
          setErrSucMesg(res.data.message);
          setErrSucType(true);
          getCompanyList();
        }
      })
      .catch(function (error) {
        if (error && error.response && error.response.data) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
          getCompanyList();
        }
      });
  };

  return (
    <div>
       <NewAlert
          msg={ErrSucMsg}
          isSuccess={ErrSucType}
          close={() => setErrSucMesg("")}
        />
          {deleteFlag && (
          <Dialog
            open={deleteFlag}
            onClose={() => {
              setDeleteFlag(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Are you sure, you want to delete Company?
            </DialogTitle>
            <DialogActions>
              <Button
                style={{ color: "black", backgroundColor: "grey" }}
                onClick={() => {
                  setDeleteFlag(false);
                }}
              >
                Cancel
              </Button>
              <Button
                style={{ backgroundColor: "#4F74C9", color:"white"}}
                onClick={() => {
                  setDeleteFlag(false);
                  DeleteCompany();
                }}
                autoFocus
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        )}
      <h2 style={{ color: "#4F74C9", fontWeight: "400" }}>Company</h2>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          onClick={() => history.push("/admin/createcompany")}
          className={classes.addActionBtn}
          variant="outlined"
          color="primary"
          style={{ width: "20%", borderRadius: "50px" }}
        >
          <AddCircleOutlineIcon
            style={{ marginRight: "5px", color: "white" }}
          />
          Create Company
        </Button>
        <div
          className={classes.addActionBtn}
          style={{
            backgroundColor: "white",
            paddingRight: "7px",
            paddingLeft: "7px",
            width: "82%",
            borderRadius: "50px",
            boxShadow:
              "0px 0px 10px rgba(0,0,0,0.20), 0 0 10px rgba(0,0,0,0.15)",
          }}
        >
          <input
            placeholder="Search Company"
            //className={classes.addActionBtn}
            style={{
              outline: "none",
              backgroundColor: "white",
              border: "solid 2px #E5E5E5",
              color: "black",
              borderRadius: "23px",
              padding: "7px",
              width: "50%",
            }}
            onChange={(event) => {
              myStopFunction();
              setSearch(event.target.value);
            }}
          ></input>
        </div>
      </div>
      <div className={classes.boxShadow} style={{ marginTop: "40px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <span
            style={{
              color: "#FB6D6A",
              fontWeight: "600",
              margin: "0",
              fontSize: "20px",
              marginLeft: "20px",
            }}
          >
            Company
          </span>
          <span
            style={{
              color: "#FB6D6A",
              fontWeight: "600",
              margin: "0",
              fontSize: "20px",
              marginRight: "20px",
            }}
          >
            <Pagination
              count={Math.ceil(pagecount / 10)}
              color="primary"
              onChange={(e, page) => setpage(page)}
              size="small"
            />
          </span>
        </div>
        <CustomizedTables
          isEditable={true}
          headings={[
            "Company Id",
            "Company Name",
          ]}
          listdata={tableData}
          redirectLink={"createcompany?id="}
          handleDelete={(_id) => {
            setDeleteFlag(true);
            setDeleteId(_id);
          }}
        />
        <div style={{ display: "flex", justifyContent: "center" }}>
          {loading && <CircularProgress />}
        </div>
      </div>
    </div>
  );
};
export default MainLayout(Company);
