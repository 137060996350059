import React from "react";
import { Button, FormControl, FormLabel, Grid, TextField } from "@material-ui/core";
import { Add, Delete } from "@material-ui/icons";
import useEffectOnce from "../../../../Hooks/useEffectOnce";

export default function MetaDataSection({ metaDatas, setFormFields }) {
    const addNewMetaData = (index) => {
        const new_meta_datas = [...metaDatas];
        new_meta_datas[index] = {
            id: Date.now().toString(),
            value: "",
            key: "",
            index,
        };
        setFormFields((old_val) => ({
            ...old_val,
            metaDatas: new_meta_datas,
        }));
    };

    const deleteMetaData = (meta_id, index) => {
        if (index == 0) return;
        const new_meta_datas = [...metaDatas].filter((item) => item.id != meta_id);
        setFormFields((old_val) => ({ ...old_val, metaDatas: new_meta_datas }));
    };

    const handleChange = (key, value, id) => {
        const new_meta_datas = [...metaDatas].map((item) => {
            if (item.id == id) {
                return {
                    ...item,
                    [key]: value,
                };
            }
            return item;
        });

        setFormFields((old_val) => ({ ...old_val, metaDatas: new_meta_datas }));
    };

    useEffectOnce(() => {
        if (metaDatas.length == 0) {
            addNewMetaData(metaDatas.length);
        }
    });

    return (
        <Grid item xs={12} lg={12} md={12} sm={12}>
            <FormControl component="fieldset">
                <FormLabel component="legend">Metadata</FormLabel>
            </FormControl>
            <Grid style={{ display: "flex", marginTop: "10px" }} className="mb--10">
                <Grid item xs={4} md={4} sm={4}>
                    <div>Key</div>
                </Grid>
                <Grid item xs={8} md={8} sm={8}>
                    <div>Value</div>
                </Grid>
            </Grid>
            <Grid>
                {metaDatas?.map((meta, i) => {
                    return (
                        <>
                            <Grid key={meta.id} style={{ display: "flex", alignItems: "center" }}>
                                <Grid item style={{ flex: 1, display: "flex" }}>
                                    <Grid item xs={4} md={4} sm={4} style={{ marginRight: "10px" }}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            label=""
                                            value={meta?.key}
                                            onChange={(e) => handleChange("key", e.target.value, meta?.id)}
                                            placeholder="Type in key..."
                                        />
                                    </Grid>
                                    <Grid item xs={8} md={8} sm={8}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            label=""
                                            value={meta?.value}
                                            onChange={(e) => handleChange("value", e.target.value, meta?.id)}
                                            placeholder="Type in value..."
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Button onClick={() => deleteMetaData(meta.id, i)} style={{background:"none"}}>
                                        <Delete />
                                    </Button>
                                </Grid>
                            </Grid>
                        </>
                    );
                })}
            </Grid>
            <Grid>
                <Button onClick={() => addNewMetaData(metaDatas?.length)}>
                    <Add style={{ marginRight: "10px" }} /> Add Another Meta Key
                </Button>
            </Grid>
        </Grid>
    );
}
