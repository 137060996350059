/* eslint-disable no-unused-expressions */
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Fade,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField
} from "@material-ui/core/";
import CircularProgress from "@material-ui/core/CircularProgress";
import React, { useEffect, useRef, useState } from "react";
import MainLayout from "../../../layouts/newMainLayoutPlanner";

import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import axios from "axios";
import { deleteFile, uploadFile } from "react-s3";
import removeIcon from "../../../assets/add-btn-icon.png";
import CustomDropdown from "../../../components/CustomDropdown";
import NewAlert from "../../../components/NewAlert";
import useStyles from "../../../components/StyleClasses";
import UndoAlert from "../../../components/UndoAlert";
import { API, SECRET_KEY } from "../../../constants/index";
import "./creativeConsultancy.css";
import CreativeConsultancyPreview from "./creativeConsultancyPreview";
import SurveyList from "./creativeSurveyLisiting";
import PreviewSurveyQuestions from "./previewSurvey";

const config = {
  bucketName: "brand-advance-images",
  region: "eu-west-2",
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
};

let timer = null;

const CreativeConsultancy = () => {
  const access_token = window.localStorage.getItem(SECRET_KEY);
  const classes = useStyles();
  const [loading, setloading] = useState(false);
  const [showPage, setShowPage] = useState("setup");
  const [title, setTitle] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [country, setCountry] = useState([]);
  const [oldCountry, setOldCountry] = useState([]);
  const [demographicList, setDemographicList] = useState([]);
  const [demographic, setDemographic] = useState([]);
  const [oldDemographic, setOldDemographic] = useState([]);
  const [ageGroup, setAgeGroup] = useState([]);
  const [age, setAge] = useState([]);
  const [oldAge, setOldAge] = useState([]);
  const [sampleSize, setSampleSize] = useState([]);
  const [fileUrl, setFileUrl] = useState([]);
  const [fileUrlOriginal, setFileUrlOriginal] = useState([]);
  const [videoUrl, setVideoUrl] = useState([]);
  const [videoUrlOriginal, setVideoUrlOriginal] = useState([]);
  const [selectedListID, setSelectedListID] = useState(null);
  const [count, forceUpdate] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [updatedQuestions, setUpdatedQuestions] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState(["6377862637a3d30fab563845",
    "6377866837a3d30fab563846", "6377867a37a3d30fab563847"]);
  const [selectedOldQuestions, setSelectedOldQuestions] = useState(["6377862637a3d30fab563845",
    "6377866837a3d30fab563846", "6377867a37a3d30fab563847"]);
  const [newQuestion, setNewQuestion] = useState({
    name: '',
    value: '',
    flag: 'NA',
    type: '',
  });
  const [selectedNewQuestions, setSelectedNewQuestions] = useState([]);
  const [selectedNewExisQuestions, setSelectedNewExisQuestions] = useState([]);
  const [newQuestions, setNewQuestions] = useState([]);

  ///////errors & successs //////////
  const [ErrSucMsg, setErrSucMesg] = useState("");
  const [ErrSucType, setErrSucType] = useState(true);
  const [openUndo, setOpenUndo] = useState(false);
  const [undoCalled, setUndoCalled] = useState(false);

  ///////////////////////////////////
  const [open, setOpen] = useState(false);
  const [openQuestions, setOpenQuestions] = useState(false);
  const [selectedApiQuestions, setSelectedApiQuestions] = useState([]);
  const [allQuestions, setAllQuestions] = useState([])

  const fileRef = useRef(null);
  const videoRef = useRef(null);

  const getDropDownData = () => {
    axios
      .get(`${API}/mediaPlanner/dropdowns`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res) {
          setCountryList(res.data.countries);
          setDemographicList(res.data?.demographics);
          setAgeGroup(res.data?.ageGroups);
        }
      })
      .catch(function (error) {
        if (error) {
        }
      });
  };

  const getConsultancyData = () => {
    axios
      .get(`${API}/mediaPlanner/creativeconsultancy/${selectedListID}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res && res.data) {
          let result = res.data;
          let countries = [];
          let demoList = [];
          let AgeList = [];
          result.country.map((i) => {
            countries.push({
              label: i.country.name,
              value: i.country.name,
              ...i.country,
            });
          });
          result.demographic.map((i) => {
            demoList.push({
              label: i.demographic.name,
              value: i.demographic.name,
              ...i.demographic,
            });
          });
          result.ageGroup.map((i) => {
            AgeList.push({
              label: i.ageGroup.name,
              value: i.ageGroup.name,
              ...i.ageGroup,
            });
          });
          setTitle(result.title);
          setSampleSize({ label: result.samplesize, value: result.samplesize });
          setCountry(countries);
          setOldCountry(countries);
          setDemographic(demoList);
          setOldDemographic(demoList);
          setAge(AgeList);
          setOldAge(AgeList);
          setFileUrl(result.picture);
          setFileUrlOriginal(result.picture);
          setVideoUrl(result.video);
          setVideoUrlOriginal(result.video);

          let que = [];
          // if(result.questions.map(i=> i.hasOwnProperty('name'))[0]){
          //   result.questions.map(j =>{
          //     que.push(j._id);
          //   });
          //   setSelectedQuestions([...que]);
          //   setSelectedOldQuestions([...que]);
          //   setSelectedNewQuestions([...que]);
          //   setSelectedNewExisQuestions([...que]);
          // }else{
          if (result?.createdSurvey) {
            setSelectedApiQuestions(result?.createdSurvey?.questions);
            result?.createdSurvey?.questions.map(i => {
              if (i.qid) {
                que.push(i.qid);
              } else {
                que.push(i._id);
              }
            });
            setSelectedQuestions([...selectedQuestions, ...que]);
            setSelectedOldQuestions([...selectedQuestions, ...que]);
            setSelectedNewQuestions([...selectedQuestions, ...que]);
            setSelectedNewExisQuestions([...selectedQuestions, ...que]);
          }
          // else{
          //   setSelectedQuestions([...selectedQuestions, ...result.questions]);
          //   setSelectedOldQuestions([...selectedQuestions, ...result.questions]);
          //   setSelectedNewQuestions([...selectedQuestions, ...result.questions]);
          //   setSelectedNewExisQuestions([...selectedQuestions, ...result.questions]);
          // }
          // }
        }
      });
  };

  const getSurveyQuestions = () => {
    setloading(true);
    axios
      .get(`${API}/mediaPlanner/questions/getQuestions`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          let filterData = res.data.filter(i => !i.mediaPlannerId);
          setQuestions(filterData);
          setUpdatedQuestions(filterData);
          setAllQuestions(res.data);
          setloading(false);
        }
      })
      .catch(function (error) {
        if (error) {
          setloading(false);
        }
      });
  }

  const getNewSurveyQuestions = () => {
    setloading(true);
    const id = window.localStorage.getItem("ID");
    axios
      .get(`${API}/mediaPlanner/questions/getQuestions?mediaPlannerId=${id}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setNewQuestions(res.data);
          setloading(false);
        }
      })
      .catch(function (error) {
        if (error) {
          setloading(false);
        }
      });
  }

  // const getReportsData = () => {
  //   const id = window.localStorage.getItem("ID");
  //   axios
  //     .get(`${API}/mediaPlanner/report/${id}`, {
  //       headers: { Authorization: `Bearer ${access_token}` },
  //     })
  //     .then((res) => {
  //       if (res && res.data && res.data.companyId) {
  //         setReportsList(
  //           res.data.companyId.reports ? res.data.companyId.reports : []
  //         );
  //       }
  //     });
  // };

  useEffect(() => {
    // const [index, setIndex] = useState('');
    if (selectedListID) getConsultancyData();
  }, [selectedListID]);

  useEffect(() => {
    getDropDownData();
    getSurveyQuestions();
    getNewSurveyQuestions();
  }, []);

  const submit = () => {
    if (
      title == "" ||
      country.length == 0 ||
      demographic.length == 0 ||
      age.length == 0 ||
      sampleSize.length == 0 ||
      (selectedQuestions.length == 0 && selectedNewQuestions.length == 0) ||
      (fileUrl == "" && videoUrl == "")
    ) {
      setErrSucMesg("Please fill required fields!");
      setErrSucType(false);
      return;
    }
    setloading(true);
    const id = window.localStorage.getItem("ID");
    let demographics = [];
    let countries = [];
    let ageGroups = [];
    demographic.map((i) => {
      demographics.push({ demographic: i._id });
    });
    country.map((i) => {
      countries.push({ country: i._id });
    });
    age.map((i) => {
      ageGroups.push({ ageGroup: i._id });
    });
    let question = [];
    questions.map(i => {
      if (selectedQuestions.includes(i._id)) {
        question.push(i);
      }
    })
    newQuestions.map(i => {
      if (selectedNewQuestions.includes(i._id)) {
        question.push(i);
      }
    });
    let body = {
      title,
      demographic: demographics,
      country: countries,
      ageGroup: ageGroups,
      samplesize: sampleSize.value.toString(),
      picture: fileUrl,
      video: videoUrl,
      mediaplanner: id,
      questions: question
    };

    if (selectedListID) {
      if (selectedApiQuestions.length !== question.length) {
        setErrSucMesg('These changes will affect only on incompleted surveys!');
        setErrSucType(true);
      }
      body._id = selectedListID;
      axios
        .put(`${API}/mediaPlanner/creativeconsultancy`, body, {
          headers: { Authorization: `Bearer ${access_token}` },
        })
        .then((res) => {
          if (res.data) {
            setTimeout(() => {
              setErrSucMesg("Please allow 24 hours for report to be generated");
              setErrSucType(true);
            }, 2000)
            setTitle("");
            setCountry([]);
            setDemographic([]);
            // setReport([]);
            setAge([]);
            setSampleSize([]);
            setFileUrl([]);
            setFileUrlOriginal([]);
            setVideoUrl([]);
            setVideoUrlOriginal([]);
            setSelectedQuestions([]);
            setSelectedNewQuestions([]);
            setSelectedListID(null);
            setloading(false);
          }
        })
        .catch((error) => {
          if (error && error.response) {
            setErrSucMesg(error.response.data.message);
            setErrSucType(false);
            setloading(false);
          }
        });
    } else {
      axios
        .post(`${API}/mediaPlanner/creativeconsultancy`, body, {
          headers: { Authorization: `Bearer ${access_token}` },
        })
        .then((res) => {
          if (res.data) {
            setErrSucMesg(res.data.message);
            setErrSucType(true);
            setTitle("");
            setCountry([]);
            setDemographic([]);
            setAge([]);
            setSampleSize([]);
            setFileUrl([]);
            setFileUrlOriginal([]);
            setVideoUrl([]);
            setVideoUrlOriginal([]);
            setSelectedQuestions([]);
            setSelectedNewQuestions([]);
            setloading(false);
          }
        })
        .catch((error) => {
          if (error && error.response) {
            setErrSucMesg(error.response.data.message);
            setErrSucType(false);
            setloading(false);
          }
        });
    }
  };

  const fileUpload = async (imageFile) => {
    setloading(true);
    let files = [...fileUrl];
    if (!imageFile) return null;
    for (let i = 0; i < imageFile.length; i++) {
      if (files.findIndex((f) => f.name === imageFile[i].name) === -1) {
        const file = new File(
          [imageFile[i]],
          `surveyImages/${imageFile[i].name.split(".")[0] + new Date().getTime()}.${imageFile[i].name.split(".")[1]
          }`,
          { type: imageFile[i].type }
        );
        const imgData = await uploadFile(file, config)
          .then((data) => {
            setloading(false);
            return data.location;
          })
          .catch((err) => {
            setloading(false);
            return null;
          });
        files.push(imgData);
      }
    }
    setFileUrl(files);
    setFileUrlOriginal(files);
  };

  const fileVideoUpload = async (videoFile) => {
    setloading(true);
    let files = [...videoUrl];
    if (!videoFile) return null;
    for (let i = 0; i < videoFile.length; i++) {
      if (files.findIndex((f) => f.name === videoFile[i].name) === -1) {
        const file = new File(
          [videoFile[i]],
          `surveyVideos/${videoFile[i].name.split(".")[0] + new Date().getTime()}.${videoFile[i].name.split(".")[1]
          }`,
          { type: videoFile[i].type }
        );
        const videoData = await uploadFile(file, config)
          .then((data) => {
            setloading(false);
            return data.location;
          })
          .catch((err) => {
            setloading(false);
            return null;
          });
        files.push(videoData);
      }
    }
    setVideoUrl(files);
    setVideoUrlOriginal(files);
  };

  const removeFile = async (file, type, idx) => {
    let filename = file.split("/");
    let len = filename.length - 1;
    await deleteFile(
      type == "image"
        ? "surveyImages/" + filename[len]
        : "surveyVideos/" + filename[len],
      config
    )
      .then((data) => {
        // setErrSucMesg(data.message);
        if (type === "image") {
          fileRef.current.value = ''
          // let files = [...fileUrl];
          // files.splice(idx, 1);
          // setFileUrl(files);
        }
        if (type === "video") {
          videoRef.current.value = ''
          // let files = [...videoUrl];
          // files.splice(idx, 1);
          // setVideoUrl(files);
        }
      })
      .catch((err) => {
        return null;
      });
  };

  const resetData = () => {
    setSelectedListID(null);
    setTitle("");
    setDemographic([]);
    setOldDemographic([]);
    setCountry([]);
    setOldCountry([]);
    setAge([]);
    setOldAge([]);
    setSampleSize([]);
    setFileUrl([]);
    setFileUrlOriginal([]);
    setVideoUrl([]);
    setVideoUrlOriginal([]);
    setSelectedQuestions(["6377862637a3d30fab563845",
      "6377866837a3d30fab563846", "6377867a37a3d30fab563847"]);
    setSelectedOldQuestions(["6377862637a3d30fab563845",
      "6377866837a3d30fab563846", "6377867a37a3d30fab563847"]);
    setSelectedNewQuestions([]);
    setSelectedNewExisQuestions([]);
  };

  const submitNewQuestion = () => {
    const id = window.localStorage.getItem("ID");
    if (newQuestion.name.trim() === '' || newQuestion.type === '') return;
    let body = { ...newQuestion };
    body.mediaPlannerId = id;
    axios
      .post(`${API}/mediaPlanner/questions`, body, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res.data) {
          setErrSucMesg("New Question added!");
          setNewQuestion({ ...newQuestion, name: '' });
          setErrSucType(true);
          setloading(false);
          setOpen(false);
          getNewSurveyQuestions();
        }
      })
      .catch((error) => {
        if (error && error.response) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
          setloading(false);
        }
      });
  }

  useEffect(() => {
    if (undoCalled) {
      setFileUrl(fileUrlOriginal);
      setVideoUrl(videoUrlOriginal);
      setUndoCalled(false);
    }
  }, [undoCalled]);

  return (
    <div>
      <NewAlert
        msg={ErrSucMsg}
        isSuccess={ErrSucType}
        close={() => setErrSucMesg("")}
      />
      <UndoAlert
        isOpen={openUndo}
        undoCallback={() => {
          setUndoCalled(true);
          clearTimeout(timer);
          setOpenUndo(false);
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <Button
          onClick={() => {
            setShowPage("setup");
            setSelectedListID(null);
            resetData();
          }}
          className={classes.addActionBtn}
          variant="outlined"
          color="primary"
          style={{ width: "20%", borderRadius: "50px" }}
        >
          <AddCircleOutlineIcon
            style={{ marginRight: "5px", color: "white" }}
          />
          Setup Survey
        </Button>
        <Button
          onClick={() => {
            setShowPage("list");
            resetData();
            // setSelectedListID(null);
          }}
          className={classes.addActionBtn}
          variant="outlined"
          color="primary"
          style={{ width: "20%", borderRadius: "50px" }}
        >
          Surveys
        </Button>
      </div>
      {showPage == "setup" ? (
        <div
          className={classes.boxShadow}
          style={{ marginTop: "30px", maxHeight: "650px" }}
        >
          <Grid item xs={12}>
            <Box className={classes.cardBox} style={{ maxHeight: "650px" }}>
              <Card
                style={{
                  backgroundImage: "linear-gradient(#4911a2,#9211a2)",
                  color: "white",
                  overflow: "auto",
                  padding: "2px 42px",
                  height: "565px",
                }}
              >
                <CardContent>
                  <div
                    style={{
                      marginTop: "20px",
                    }}
                  >
                    <h4
                      style={{
                        marginTop: 0,
                        textAlign: "center",
                      }}
                    >
                      Creative Consultancy
                    </h4>
                    <div style={{ marginTop: "10px" }}>
                      <Grid
                        container
                        spacing={2}
                        style={{ marginTop: "20px" }}
                        className={classes.cardspace}
                      >
                        <Grid item xs={6}>
                          <TextField
                            className={classes.customfield}
                            onChange={(e) => setTitle(e.target.value)}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="search"
                            label="Creative Title"
                            value={title}
                          // disabled={selectedListID}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        style={{ marginTop: "20px" }}
                        className={classes.cardspace}
                      >
                        <Grid item md={3} sm={3} xs={3}>
                          <label>Demographic</label>
                          <CustomDropdown
                            isMulti={true}
                            containerClassName="mt-2 dropdownIndex"
                            isSearchable={true}
                            value={demographic}
                            onChange={async (e) => {
                              let v = oldDemographic.filter(x => !e.includes(x));
                              let dt = [...v, ...e];
                              setDemographic(dt);
                            }}
                            placeholder="Select..."
                            options={demographicList.map((i) => {
                              i.label = i.name;
                              i.value = i.name;
                              return i;
                            })}
                            isClearable={true}
                          // isDisabled={selectedListID}
                          />
                        </Grid>
                        <Grid item md={3} sm={3} xs={3}>
                          <label>Country</label>
                          <CustomDropdown
                            isMulti={true}
                            containerClassName="mt-2"
                            isSearchable={true}
                            value={country}
                            onChange={async (e) => {
                              let v = oldCountry.filter(x => !e.includes(x));
                              let dt = [...v, ...e];
                              setCountry(dt);
                            }}
                            placeholder="Select..."
                            options={countryList.map((i) => {
                              i.label = i.name;
                              i.value = i.name;
                              return i;
                            })}
                            isClearable={true}
                          // isDisabled={selectedListID}
                          />
                        </Grid>
                        <Grid item md={3} sm={3} xs={3}>
                          <label>Age</label>
                          <CustomDropdown
                            isMulti={true}
                            value={age}
                            containerClassName="mt-2"
                            isSearchable={true}
                            onChange={async (e) => {
                              let v = oldAge.filter(x => !e.includes(x));
                              let dt = [...v, ...e];
                              setAge(dt);
                            }}
                            placeholder="Select"
                            options={ageGroup.map((i) => {
                              i.label = i.name;
                              i.value = i.name;
                              return i;
                            })}
                            isClearable={true}
                          // isDisabled={selectedListID}
                          />
                        </Grid>
                        <Grid item md={3} sm={3} xs={3}>
                          <label>Sample Size</label>
                          <CustomDropdown
                            isSearchable={true}
                            containerClassName="mt-2"
                            onChange={async (e) => {
                              setSampleSize(e);
                            }}
                            value={sampleSize}
                            placeholder="Select..."
                            options={[
                              {
                                label: "5",
                                value: 5,
                              },
                              {
                                label: "10",
                                value: 10,
                              },
                              {
                                label: "15",
                                value: 15,
                              },
                              {
                                label: "20",
                                value: 20,
                              },
                              {
                                label: "25",
                                value: 25,
                              },
                              {
                                label: "30",
                                value: 30,
                              },
                            ]}
                            isClearable={true}
                            isDisabled={selectedListID}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        style={{ marginTop: "20px" }}
                        className={classes.cardspace}
                      >
                        <Grid
                          item
                          md={6}
                          sm={6}
                          xs={6}
                          className="image-wrapper"
                        >
                          <Button
                            className={classes.addActionBtn}
                            variant="outlined"
                            // disabled={selectedListID}
                            color="white"
                            style={{ width: "200px", borderRadius: "50px", background: openUndo ? '#627ec7' : '#2753c6' }}
                          >
                            <input
                              multiple
                              type="file"
                              accept="image/*"
                              className="image-btn"
                              ref={fileRef}
                              disabled={openUndo}
                              onChange={(e) => {
                                const file = e.target.files;
                                fileUpload(file);
                                forceUpdate(count + 1);
                              }}
                            />
                            Upload Picture
                          </Button>
                        </Grid>
                        <Grid
                          item
                          md={6}
                          sm={6}
                          xs={6}
                          style={{ position: "relative" }}
                        >
                          <Button
                            className={classes.addActionBtn}
                            variant="outlined"
                            color="white"
                            // disabled={selectedListID}
                            style={{ width: "200px", borderRadius: "50px", background: openUndo ? '#627ec7' : '#2753c6' }}
                          >
                            <input
                              multiple
                              type="file"
                              accept="video/mp4,video/x-m4v,video/*"
                              className="image-btn"
                              ref={videoRef}
                              disabled={openUndo}
                              onChange={(e) => {
                                const file = e.target.files;
                                fileVideoUpload(file);
                                forceUpdate(count + 1);
                              }}
                            />
                            Upload Video
                          </Button>
                        </Grid>
                        <Grid container md={6} sm={6} xs={6}>
                          {fileUrl && fileUrl.length ? fileUrl.map((fl, idx) =>
                            <Grid item md={3} sm={3} xs={3} spacin={2} key={`${idx}#`} style={{ position: 'relative', marginLeft: '8px', margin: '16px' }}>
                              <img
                                className="image-uploaded"
                                src={fl}
                                style={{ height: "240px" }}
                              />
                              {(
                                <a
                                  href="javascript:void(0)"
                                  className="close-rotate"
                                >
                                  <img
                                    src={removeIcon}
                                    alt=""
                                    className="remove-img"
                                    style={{top: 0}}
                                    onClick={() => {
                                      setOpenUndo(true);
                                      let files = [...fileUrl];
                                      files.splice(idx, 1);
                                      setFileUrl(files);
                                      timer = setTimeout(() => {
                                        if (!undoCalled) {
                                          removeFile(fl, "image", idx);
                                          let Ofiles = [...fileUrlOriginal];
                                          Ofiles.splice(idx, 1);
                                          setFileUrlOriginal(Ofiles);
                                          setOpenUndo(false);
                                        }
                                      }, 1500);
                                    }}
                                  />
                                </a>
                              )}
                            </Grid>
                          ) : null}
                        </Grid>
                        <Grid container md={6} sm={6} xs={6}>
                          {videoUrl && videoUrl.length ? videoUrl.map((fl, idx) =>
                            <Grid item md={3} sm={3} xs={3} spacin={2} key={`${idx}#`} style={{ position: 'relative', marginLeft: '8px', margin: '16px' }}>
                              <video
                                controls
                                style={{ height: "95px", width: "120px" }}
                              >
                                <source src={fl} type="video/mp4" />
                              </video>
                              {(
                                <a
                                  href="javascript:void(0)"
                                  className="close-rotate"
                                >
                                  <img
                                    src={removeIcon}
                                    alt=""
                                    className="remove-img remove-vid"
                                    onClick={() => {
                                      setOpenUndo(true);
                                      let files = [...videoUrl];
                                      files.splice(idx, 1);
                                      setVideoUrl(files);
                                      timer = setTimeout(() => {
                                        if (!undoCalled) {
                                          removeFile(fl, "video", idx)
                                          let Ofiles = [...videoUrlOriginal];
                                          Ofiles.splice(idx, 1);
                                          setVideoUrlOriginal(Ofiles);
                                          setOpenUndo(false);
                                        }
                                      }, 1500);
                                    }}
                                  />
                                </a>
                              )}
                            </Grid>
                          ) : null}
                        </Grid>
                      </Grid>
                      <Grid
                        container md={12} sm={12} xs={12} style={{ marginTop: "20px" }}>
                        <Grid item md={6} sm={6} xs={6}>
                          <label>Select Fix Questions</label>
                          <FormControl
                            fullWidth
                            component="fieldset"
                            variant="outlined"
                            className={[classes.selectControl, classes.customfield].join(
                              " "
                            )}
                          >
                            {/* {questions && questions.length === 0 && (
                              <InputLabel>Select questions</InputLabel>
                            )} */}
                            <Select
                              id="outlined-select-currency-native"
                              multiple
                              value={selectedQuestions}
                              onChange={(e) => {
                                let v = selectedOldQuestions.filter(x => !e.target.value.includes(x));
                                let dt = [...v, ...e.target.value];
                                setSelectedQuestions(dt);
                              }}
                              variant="outlined"
                              renderValue={() => {
                                let arrNm = [];
                                questions && questions.length &&
                                  questions?.map((dem) => {
                                    if (selectedQuestions.includes(dem._id)) {
                                      arrNm.push(dem.name + " , ");
                                    }
                                  });
                                return arrNm;
                              }}
                            >
                              <MenuItem disabled value={0} key={0}>
                                <em>Select questions</em>
                              </MenuItem>
                              {questions && questions.length &&
                                questions?.map((option) => (
                                  <MenuItem
                                    primaryText={"Select questions"}
                                    key={option._id}
                                    value={option._id}
                                  >
                                    {selectedQuestions && selectedQuestions.length === 0 && (
                                      <Checkbox
                                        checked={selectedQuestions.includes(option._id)}
                                      ></Checkbox>
                                    )}
                                    {option.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item md={6} sm={6} xs={6} style={{ marginTop: "20px", paddingLeft: '30px' }}>
                          {/* <Button
                            className={classes.addActionBtn}
                            variant="outlined"
                            color="white"
                            style={{ width: "200px", borderRadius: "50px" }}
                            onClick={() => setOpenQuestions(true)}
                          >
                            Edit Questions
                          </Button> */}
                        </Grid>
                        <Grid item md={6} sm={6} xs={6}>
                          <label>Select Created Questions</label>
                          <FormControl
                            fullWidth
                            component="fieldset"
                            variant="outlined"
                            className={[classes.selectControl, classes.customfield].join(
                              " "
                            )}
                          >
                            {/* {newQuestions && newQuestions.length === 0 && (
                              <InputLabel>Select questions</InputLabel>
                            )} */}
                            <Select
                              id="outlined-select-currency-native"
                              multiple
                              value={selectedNewQuestions}
                              onChange={(e) => {
                                let v = selectedNewExisQuestions.filter(x => !e.target.value.includes(x));
                                let dt = [...v, ...e.target.value];
                                setSelectedNewQuestions(dt);
                              }}
                              variant="outlined"
                              renderValue={() => {
                                let arrNm = [];
                                newQuestions && newQuestions.length &&
                                  newQuestions?.map((dem) => {
                                    if (selectedNewQuestions.includes(dem._id)) {
                                      arrNm.push(dem.name + " , ");
                                    }
                                  });
                                return arrNm;
                              }}
                            >
                              <MenuItem disabled value={0} key={0}>
                                <em>Select questions</em>
                              </MenuItem>
                              {newQuestions && newQuestions.length &&
                                newQuestions?.map((option) => (
                                  <MenuItem
                                    primaryText={"Select questions"}
                                    key={option._id}
                                    value={option._id}
                                  >
                                    {selectedNewQuestions && selectedNewQuestions.length === 0 && (
                                      <Checkbox
                                        checked={selectedNewQuestions.includes(option._id)}
                                      ></Checkbox>
                                    )}
                                    {option.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item md={6} sm={6} xs={6} style={{ marginTop: "20px", paddingLeft: '30px' }}>
                          <Button
                            className={classes.addActionBtn}
                            variant="outlined"
                            // disabled={selectedListID}
                            color="white"
                            style={{ width: "200px", borderRadius: "50px" }}
                            onClick={() => setOpen(true)}
                          >
                            Create New Question
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid
                        item md={12} sm={12} xs={12}
                        style={{
                          display: "flex",
                          marginTop: "40px",
                          justifyContent: "end",
                        }}
                      >
                        <Button
                          onClick={() => submit()}
                          className={classes.addActionBtn}
                          variant="outlined"
                          color="white"
                          disabled={openUndo || loading}
                          style={{
                            width: "100px",
                            borderRadius: "50px",
                            height: "40px",
                            marginTop: "19px",
                          }}
                        >
                          {selectedListID ? 'Update' : 'Submit'}
                        </Button>
                      </Grid>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Box>
          </Grid>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {loading && <CircularProgress color="white" />}
          </div>
        </div>
      ) : null}
      {showPage == "list" ? (
        <SurveyList
          callback={(id, type) => {
            if (type !== "edit") {
              setShowPage(type);
              setSelectedListID(id);
            }
            // if (type == "Preview Survey") {
            // }
            // if (type == "Preview Report") {
            //   setShowPage("Preview Report");
            //   setSelectedListID(id);
            // }
            if (type == "edit") {
              setShowPage("setup");
              setSelectedListID(id);
            }
          }}
        />
      ) : null}
      {showPage == "Preview Report" && selectedListID ? (
        <CreativeConsultancyPreview questions={allQuestions} selectedListID={selectedListID} callback={() => setShowPage("list")} />
      ) : null}
      {showPage == "Preview Survey" && selectedListID ? (
        <PreviewSurveyQuestions selectedListID={selectedListID} />
      ) : null}

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.boxShadow}>
            <div
              className={classes.customfield}
              style={{
                width: "50%",
                padding: "50px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                position: "absolute",
                left: "35%",
                marginTop: "15%",
              }}
            >
              <TextField
                fullWidth
                label={"Question"}
                variant="outlined"
                style={{ display: "block", marginBottom: "10px" }}
                value={newQuestion.name}
                onChange={(e) => {
                  setNewQuestion({ ...newQuestion, name: e.target.value });
                }}
              />
              <CustomDropdown
                isSearchable={true}
                containerClassName="mt-2"
                value={newQuestion?.type ? [{ label: newQuestion.type, value: newQuestion.type }] : []}
                onChange={(e) => {
                  setNewQuestion({ ...newQuestion, type: e.value });
                }}
                placeholder="Type"
                options={[
                  {
                    label: "scale",
                    value: 'radio',
                  },
                  {
                    label: "text",
                    value: 'text',
                  },
                ]}
                isClearable={true}
              // isDisabled={selectedListID}
              />
              <div style={{
                textAlign: 'center'
              }}>
                <button
                  onClick={() => {
                    submitNewQuestion();
                  }}
                  style={{
                    backgroundColor: "blue",
                    color: "white",
                    borderRadius: "7px",
                    padding: "10px 25px",
                    border: "none",
                    marginTop: '20px',
                    width: '150px'
                  }}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>

      <Modal
        open={openQuestions}
        onClose={() => setOpenQuestions(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openQuestions}>
          <div className={classes.boxShadow}>
            <div
              className={classes.customfield}
              style={{
                width: "50%",
                padding: "50px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                position: "absolute",
                left: "30%",
                marginTop: "5%",
                overflow: 'auto'
              }}
            >
              <div style={{
                maxHeight: '400px'
              }}>
                {updatedQuestions?.map(q => (
                  <TextField
                    fullWidth
                    label={"Question"}
                    variant="outlined"
                    style={{ display: "block", marginBottom: "10px" }}
                    value={q.name}
                    onChange={(e) => {
                      let que = [...updatedQuestions];
                      que['name'] = e.target.value;
                      setUpdatedQuestions(que);
                    }}
                  />
                ))}
                <div style={{
                  textAlign: 'center'
                }}>
                  <button
                    onClick={() => {
                      setQuestions(updatedQuestions);
                    }}
                    style={{
                      backgroundColor: "blue",
                      color: "white",
                      borderRadius: "7px",
                      padding: "10px 25px",
                      border: "none",
                      marginTop: '20px',
                      marginBottom: '20px',
                      width: '150px'
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
export default MainLayout(CreativeConsultancy);
