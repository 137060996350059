import { Button, CircularProgress } from "@material-ui/core"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"
import MenuItem from "@material-ui/core/MenuItem"
import Modal from "@material-ui/core/Modal"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"
import CloudUploadIcon from "@material-ui/icons/CloudUpload"
import SaveIcon from "@material-ui/icons/Save"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min"
import { API, SECRET_KEY } from "../../../constants/index"
import MainLayout from "../../../layouts/newMainLayoutPlanner"
import "./influencer.css"

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: "10px",
        width: "580px",
    },
    button: {
        margin: theme.spacing(1),
    },
}))

const getCampaign = (campaign_id) => {
    const access_token = window.localStorage.getItem(SECRET_KEY)
    return axios.get(`${API}/mediaPlanner/influencer/get-campaign/${campaign_id}`, {
        headers: { Authorization: `Bearer ${access_token}` },
    })
}

const getContents = (campaign_id) => {
    const access_token = window.localStorage.getItem(SECRET_KEY)
    return axios.get(`${API}/mediaPlanner/influencer/get-contents/${campaign_id}`, {
        headers: { Authorization: `Bearer ${access_token}` },
    })
}

const approveContent = (content_id) => {
    const access_token = window.localStorage.getItem(SECRET_KEY)
    return axios.post(
        `${API}/mediaPlanner/influencer/approve-content/${content_id}`,
        {},
        {
            headers: { Authorization: `Bearer ${access_token}` },
        }
    )
}

const rejectContent = (content_id, rejectNote) => {
    const access_token = window.localStorage.getItem(SECRET_KEY)
    return axios.post(
        `${API}/mediaPlanner/influencer/reject-content/${content_id}`,
        { rejection_note: rejectNote },
        {
            headers: { Authorization: `Bearer ${access_token}` },
        }
    )
}

const Influencer_campaignMP = () => {
    const history = useHistory()
    const classes = useStyles()
    const campaign_id = useParams().campaign_id
    const [loading, setLoading] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [campaignData, setCampaignData] = useState([])
    const [uploadData, setUploadData] = useState({
        title: "",
        description: "",
        file: "",
    })
    const [contentList, setContentList] = useState([])
    const [rejectionModalOpen, setRejectionModalOpen] = useState(false)
    const [rejectionNote, setRejectionNote] = useState("")
    const [currentRejectionId, setCurrentRejectionId] = useState("")
    const [approvalModalOpen, setApprovalModalOpen] = useState(false)
    const [currentApprovalId, setCurrentApprovalId] = useState("")
    const [commentList, setCommentList] = useState([])
    const [comment, setComment] = useState("")
    const [commentContentId, setCommentContentId] = useState("")

    const handleModalOpen = (contentId) => {
        setCommentContentId(contentId)
        getContentComments(contentId)
        setModalOpen(true)
    }

    const handleModalClose = () => {
        setModalOpen(false)
    }

    const handleModalChange = (e, fn, entity) => {
        if (entity === "file") {
            console.log(e.target.files[0])
            fn({ ...uploadData, [entity]: e.target.files[0] })
        } else {
            fn({ ...uploadData, [entity]: e.target.value })
        }
    }

    const getContentComments = (content_id) => {
        const access_token = window.localStorage.getItem(SECRET_KEY)
        axios
            .get(`${API}/mediaPlanner/influencer/get-content-comments/${content_id}`, {
                headers: { Authorization: `Bearer ${access_token}` },
            })
            .then((res) => {
                console.log(res)
                setCommentList(res.data.comments)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const handleAddComment = () => {
        const access_token = window.localStorage.getItem(SECRET_KEY)
        const userId = localStorage.getItem("ID")
        axios
            .post(
                `${API}/mediaPlanner/influencer/add-content-comment/${commentContentId}`,
                { comment, user: userId },
                {
                    headers: { Authorization: `Bearer ${access_token}` },
                }
            )
            .then((res) => {
                console.log(res)
                getContentComments(commentContentId)
                setComment("")
            })
            .catch((err) => {
                console.log(err)
                setComment("")
            })
    }

    const handleApproveContent = (content_id) => {
        approveContent(content_id)
            .then((res) => {
                setApprovalModalOpen(false)
                setLoading(true)
                getContents(campaign_id)
                    .then((res) => {
                        console.log(res)
                        setContentList(res.data.influencerContents)
                        setLoading(false)
                    })
                    .catch((err) => {
                        setLoading(false)
                        console.log(err)
                    })
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                console.log(err)
            })
    }

    const handleRejectContent = (content_id) => {
        rejectContent(content_id, rejectionNote)
            .then((res) => {
                setRejectionModalOpen(false)
                setLoading(true)
                getContents(campaign_id)
                    .then((res) => {
                        console.log(res)
                        setContentList(res.data.influencerContents)
                        setLoading(false)
                    })
                    .catch((err) => {
                        setLoading(false)
                        console.log(err)
                    })
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                console.log(err)
            })
    }

    useEffect(() => {
        setLoading(true)
        getCampaign(campaign_id)
            .then((res) => {
                setCampaignData(res.data.influencerCampaign)
                getContents(campaign_id)
                    .then((res) => {
                        console.log(res)
                        setContentList(res.data.influencerContents)
                        setLoading(false)
                    })
                    .catch((err) => {
                        setLoading(false)
                        console.log(err)
                    })
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                console.log(err)
            })
    }, [campaign_id])

    useEffect(() => {
        if (modalOpen === false) {
            setUploadData({
                title: "",
                description: "",
                file: "",
            })
        }
    }, [modalOpen])

    return (
        <div>
            <div className="head-section">
                <div className="back_btn_cont">
                    <Button
                        variant="contained"
                        className="back_btn"
                        onClick={() => history.push("/media-planner/influencer")}
                    >
                        &#8592;
                    </Button>
                    <h1 className="title">{campaignData.title}</h1>
                </div>
                <h3>
                    Description: <span className="card_contents">{campaignData.description}</span>
                </h3>
                <h3>
                    Company:{" "}
                    <span className="card_contents">
                        {campaignData.companies?.map((company) => company.companyName).join(", ")}
                    </span>
                </h3>
            </div>
            <div className="influencer-body">
                <div className="campaigns-list">
                    {contentList.map((item) => {
                        return (
                            <div key={item._id} className="video_cards">
                                <iframe
                                    title="vimeo-player"
                                    src={item.video_uri}
                                    width="100%"
                                    height="60%"
                                    allowFullScreen
                                    allow="autoplay; fullscreen; picture-in-picture"
                                ></iframe>
                                <div className="video_content">
                                    <h3 className="campaign_title">
                                        Title:&nbsp;&nbsp;<span className="card_contents">{item.title}</span>
                                    </h3>
                                    <h3 className="campaign_desc">
                                        Description:&nbsp;&nbsp;
                                        <span className="card_contents">{item.description}</span>
                                    </h3>
                                    <h3 className="campaign_desc">
                                        Status:&nbsp;&nbsp;<span className="card_contents">{item.status}</span>
                                    </h3>
                                    {item.status === "rejected" ? (
                                        <h3 className="campaign_desc">
                                            Rejection Note:&nbsp;&nbsp;
                                            <span className="card_contents">{item.rejection_note}</span>
                                        </h3>
                                    ) : (
                                        <h3 className="campaign_desc">
                                            Note:&nbsp;&nbsp;<span className="card_contents">-</span>
                                        </h3>
                                    )}
                                    <div className="video_action_btn_cont">
                                        {/* approve reject and comment action buttons */}
                                        {item.status === "pending" && (
                                            <Button
                                                className="campaign_view_btn content_approve_btn"
                                                variant="contained"
                                                onClick={() => {
                                                    setApprovalModalOpen(true)
                                                    setCurrentApprovalId(item._id)
                                                }}
                                            >
                                                Approve
                                            </Button>
                                        )}
                                        <Button
                                            className="campaign_view_btn content_comment_btn"
                                            variant="contained"
                                            onClick={() => {
                                                // setCommentContentId(item._id)
                                                handleModalOpen(item._id)
                                            }}
                                        >
                                            Comments
                                        </Button>
                                        {item.status === "pending" && (
                                            <Button
                                                className="campaign_view_btn content_reject_btn"
                                                variant="contained"
                                                // onClick={() => handleRejectContent(item._id)}
                                                onClick={() => {
                                                    setRejectionModalOpen(true)
                                                    setCurrentRejectionId(item._id)
                                                }}
                                            >
                                                Reject
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>

            {/* Create Campaign Modal */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modalOpen}
                onClose={handleModalClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={modalOpen}>
                    <div className={classes.paper}>
                        <h2 className="campaign_modal_title">Comments</h2>
                        <div className="comment-list-cont">
                            {commentList.map((item, index) => {
                                return (
                                    <div key={index} className="comment-list-item">
                                        <h3 className="comment-list-item-user">
                                            {item.user?.name || "Admin"}:{" "}
                                            <span className="comment-list-item-comment">{item.comment}</span>{" "}
                                        </h3>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="campaign-modal-input-cont">
                            <TextField
                                id="campaign_title"
                                label="Add Comment"
                                variant="outlined"
                                onChange={(e) => {
                                    setComment(e.target.value)
                                }}
                                value={comment}
                            />
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <div className="modal-submit-comment">
                                    <Button
                                        variant="contained"
                                        color="default"
                                        component="span"
                                        className={`${classes.button} submit_campaign_btn modal-submit-btn`}
                                        onClick={() => {
                                            handleAddComment()
                                        }}
                                    >
                                        Add Comment
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>

            {/* Rejection Modal */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={rejectionModalOpen}
                onClose={() => {
                    setRejectionModalOpen(false)
                    setCurrentRejectionId("")
                }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={rejectionModalOpen}>
                    <div className={classes.paper}>
                        <h2 className="campaign_modal_title">Reject the Content</h2>
                        <div className="campaign-modal-input-cont">
                            <TextField
                                id="campaign_title"
                                label="Rejection Note"
                                variant="outlined"
                                required
                                onChange={(e) => setRejectionNote(e.target.value)}
                            />
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <div className="modal-submit-comment">
                                    <Button
                                        variant="contained"
                                        color="default"
                                        component="span"
                                        className={`${classes.button} submit_campaign_btn content_reject_btn`}
                                        disabled={rejectionNote === ""}
                                        // onClick={() => uploadVideo()}
                                        onClick={() => handleRejectContent(currentRejectionId)}
                                    >
                                        Reject
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>

            {/* Approval Modal */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={approvalModalOpen}
                onClose={() => {
                    setApprovalModalOpen(false)
                    setCurrentApprovalId("")
                }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={approvalModalOpen}>
                    <div className={classes.paper}>
                        <h2 className="campaign_modal_title">Approve the Content</h2>
                        <div className="campaign-modal-input-cont">
                            <h3 className="campaign_desc">
                                Note:{" "}
                                <span className="card_contents">
                                    Please make sure to verify the content before approving.
                                </span>
                            </h3>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <div className="modal-submit-comment">
                                    <Button
                                        variant="contained"
                                        color="default"
                                        component="span"
                                        className={`${classes.button} submit_campaign_btn content_approve_btn`}
                                        onClick={() => handleApproveContent(currentApprovalId)}
                                    >
                                        Approve
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>

            <div style={{ display: "flex", justifyContent: "center" }}>{loading && <CircularProgress />}</div>
        </div>
    )
}

export default MainLayout(Influencer_campaignMP)
