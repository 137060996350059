import { put, call, takeLatest } from 'redux-saga/effects';
import * as actions from '../redux/actions/media-planner';
import { postCreate, getforecast, getInventories } from '../services/api/media-planner';

export function* callCreateMediaPlannerApi(param) {
  try {
    const result = yield call(postCreate, param && param.mediaPlanner);
    console.log("RESULT_MEDIA_PLANNER",result.data.id);
    yield put(actions.saveMediaPlannerID(result.data.id));
  } catch(error) {
    yield put(actions.error(error));
  }
}

export function* createMediaPlannerSaga() {
  yield takeLatest(actions.CREATE_MEDIA_PLANNER, callCreateMediaPlannerApi);
}

export function* callGetMediaPlannerForecastApi(param) {
  try {
    const result = yield call(getforecast, param && param.mediaPlanner);
    console.log("RESULT_FORECAST",result.data);
    yield put(actions.saveForecast(result.data));
  } catch(error) {
    yield put(actions.error(error));
  }
}

export function* getMediaPlannerForecastSaga() {
  yield takeLatest(actions.GET_MEDIA_PLANNER_FORECAST, callGetMediaPlannerForecastApi);
}


export function* callGetMediaPlannerInventoriesApi(param) {
  try {
    const result = yield call(getInventories, param && param.mediaPlannerId);
    console.log("RESULT_INVENTRY",result.data);
    yield put(actions.saveMediaPlannerInventry(result.data));
  } catch(error) {
    yield put(actions.error(error));
  }
}

export function* getMediaPlannerInventoriesSaga() {
  yield takeLatest(actions.GET_MEDIA_PLANNER_INVENTRY, callGetMediaPlannerInventoriesApi);
}

