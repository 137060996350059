import React, { useState, useEffect, useRef } from "react";
import { makeStyles, fade } from "@material-ui/core/styles";

import Highcharts, { color } from "highcharts";
import HighchartReact from "highcharts-react-official";
import funnel from "highcharts/modules/funnel.js";

import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import MyTable from "./mytable";
import Pagination from "@material-ui/lab/Pagination";

//Text field
import TextField from "@material-ui/core/TextField";

// Select
import InputLabel from "@material-ui/core/InputLabel";

//Button
import Button from "@material-ui/core/Button";
import { useSelector } from "react-redux";

import Checkbox from "@material-ui/core/Checkbox";

import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch } from "react-redux";

import { useHistory } from "react-router-dom";
import Q1in5Img from "../../assets/Q1in5.PNG";
import { API } from "../../constants/index";
import axios from "axios";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";

import useStyles from "../../components/StyleClasses";

import { SECRET_KEY } from "./../../constants/index";
import NewAlert from "../../components/NewAlert";

funnel(Highcharts);

// const DeletePopUp = (open, handleClose, handleAction) => {
//   return (
//     <Dialog open={open}>
//       <DialogTitle>Delete Question</DialogTitle>
//       <Button onClick={() => handleClose()}>Cancel</Button>
//       <Button onClick={() => handleAction()}>Delete</Button>
//     </Dialog>
//   );
// };

const Question1 = () => {
  const [ques, setques] = useState({
    question: "",
    answers: [{ ans: "", percent: 0, id: Math.random() }],
    id: Math.random(),
    saved: false,
    fromBE: false,
    updating: false,
    chartType: "BAR",
  });
  const [answeredQues, setAnsweredQues] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [demographic, setDemographic] = useState(0);
  const [industry, setIndustry] = useState(0);
  const [ageGroup, setAgeGroup] = useState(0);
  const [country, setCountry] = useState(0);
  const [section, setSection] = useState('');
  const [insight, setInsight] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [file, setFile] = useState(false);
  const fileInputRef = useRef();

  const [duplicateToggle, setDuplicateToggle] = useState(false);
  const [editToggle, setEditToggle] = useState(false);
  const [duplicateRecord, setDuplicateRecord] = useState({});

  ///////errors & successs //////////
  const [ErrSucMsg, setErrSucMesg] = useState("");
  const [ErrSucType, setErrSucType] = useState(true);
  ///////////////////////////////////

  const [ListDATA, setListDATA] = useState([]);
  const [ListtableData, setListTableData] = useState([]);
  const [Listpage, setListpage] = useState(1);
  const [Listpagecount, setListPageCount] = useState(0);
  const [tableLoading, setTableloading] = useState(false);
  const [keyword, setKeyword] = useState("");

  // const { register, handleSubmit, errors, control } = useForm({
  //   mode: "onSubmit",
  //   reValidateMode: "onSubmit",
  // });

  // const [modalOpen, setModalOpen] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const currentUser = useSelector((state) => state.loggedInSession);
  const dropdownData = useSelector((state) => state.adminDropDownData);
  const classes = useStyles();
  const access_token = window.localStorage.getItem(SECRET_KEY);
  const usertype = window.localStorage.getItem("usertype");

  const SendList = () => {
    const body = {
      demographic: demographic,
      industry: industry,
      country: country,
      ageGroup: ageGroup,
      type: section.toLowerCase(),
      questions: [...answeredQues],
    };
    body.questions.forEach((element) => {
      delete element.id;
      delete element._id;
      delete element.saved;
      delete element.fromBE;
      delete element.updating;
      if (section === "Qualitative") {
        delete element.chartType;
      }
      element.answers.forEach((e) => {
        delete e.id;
      });
    });
   
    axios
      .post(`${API}/admin/intelligence/addOneFiveInsight`, body, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res) {
          refreshList();
          Listrefresh();
          setAnsweredQues([]);
          setIsAdding(false);
          setIsEditing(false);
          setErrSucMesg(res.data.message);
          setErrSucType(true);
          setques({
            question: "",
            answers: [{ ans: "", percent: 0, id: Math.random() }],
            id: Math.random(),
            saved: false,
            fromBE: false,
            updating: false,
            chartType: "BAR",
          });
        }
      })
      .catch(function (error) {
        if (error) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
        }
      });
  };
  ////////////////lists/////////////////////////
  const Listrefresh = () => {
    setTableloading(true);
    let URLL = `${API}/admin/intelligence/OneFiveInsights?page=${
      (keyword !== "" && section != '')? 1 : Listpage
    }`;
    if (industry != 0) URLL += `&industry=${industry}`;
    if (demographic != 0) URLL += `&demographic=${demographic}`;
    if (country != 0) URLL += `&country=${country}`;
    if (ageGroup != 0) URLL += `&ageGroup=${ageGroup}`;
    if (keyword !== "") URLL += `&keyword=${keyword}`;
    if (section != '') URLL += `&type=${section.toLowerCase()}`;

    axios
      .get(URLL, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        setListDATA(res.data.data);
        setListPageCount(res.data.pagination ? res.data.pagination.total : 0);
        setListpage(res.data.pagination ? res.data.pagination.currentPage : 1);
        setTableloading(false);
      })
      .catch(function (error) {
        if (error) {
          setTableloading(false);
        }
      });
  };
  useEffect(() => {
    Listrefresh();
  }, [Listpage, section]);
  
  useEffect(() => {
    Listrefresh();
  }, [demographic, industry, ageGroup, country]);

  useEffect(() => {
    if(keyword == '') return;
    const delayTime = setTimeout(() => {
      Listrefresh();
    }, 1000);
    return () => clearTimeout(delayTime);
  }, [keyword]);

  useEffect(() => {
    setListpage(1);
  }, []);

  useEffect(() => {
    if (ListDATA) {
      let td = [];
      ListDATA.forEach((e) => {
        let KeysToShow = [
          e.demographic ? e.demographic.name : "",
          e.industry ? e.industry.name : "",
          e.country ? e.country.name : "NA",
          e.ageGroup ? e.ageGroup.name : "NA",
        ];
        if (e.questions.length > 0) {
          e.questions.forEach((element) => {
            let ansArr = "";
            e._qid = element._id;
            if (element.answers && element.answers.length) {
              element.answers.map((ans) => {
                ansArr +=
                  ans.ans + `${element.answers.length - 1 ? ", " : " "}`;
              });
            }
            KeysToShow[4] = element.question ? element.question : "NA";
            KeysToShow[5] = ansArr ? ansArr : "NA";
          });
        } else {
          KeysToShow[4] = "NA";
          KeysToShow[5] = "NA";
          e._qid = "";
        }
        td.push({
          data: KeysToShow,
          _id: e._id,
          _qid: e._qid,
          questionToggle: e.questions.length > 0 ? true : false,
          otherData: e.questions ? e.questions : [],
        });
      });
      setListTableData(td);
    }
  }, [ListDATA]);
  //////////////////////////////////////////////
  const refreshList = () => {
    if (
      demographic != 0 &&
      industry != 0 &&
      country != 0 &&
      ageGroup != 0 &&
      section != 0 &&
      keyword !== ""
    ) {
      setLoading(true);

      axios
        .get(
          `${API}/admin/intelligence/OneFiveInsight?demographic=${demographic}&industry=${industry}&country=${country}&ageGroup=${ageGroup}&keyword=${keyword}&type=${section}`,
          {
            headers: { Authorization: `Bearer ${access_token}` },
          }
        )
        .then((res, err) => {
          setLoading(false);
          if (err) {
          } else {
            setInsight(
              res.data?.data[0]?.questions ? res.data?.data[0]?.questions : []
            );
          }
        })
        .catch(function (error) {
          if (error) {
          }
        });
    }
  };

  const DeleteQuestion = (id) => {
    const payload = { id: [id] };
    axios
      .delete(`${API}/admin/intelligence/OneFiveQuestion`, {
        headers: { Authorization: `Bearer ${access_token}` },
        data: payload,
      })
      .then((res) => {
        if (res && res.data) {
          setErrSucMesg(res.data.message);
          setErrSucType(true);
          refreshList();
          Listrefresh();
          setAnsweredQues([]);
          setIsAdding(false);
          setIsEditing(false);
          setques({
            question: "",
            answers: [{ ans: "", percent: 0, id: Math.random() }],
            id: Math.random(),
            saved: false,
            fromBE: false,
            updating: false,
            chartType: "BAR",
          });
        }
      })
      .catch(function (error) {
        if (error) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
          refreshList();
          Listrefresh();
          setAnsweredQues([]);
          setIsAdding(false);
          setIsEditing(false);
          setques({
            question: "",
            answers: [{ ans: "", percent: 0, id: Math.random() }],
            id: Math.random(),
            saved: false,
            fromBE: false,
            updating: false,
            chartType: "BAR",
          });
        }
      });
  };
  const UpdateQuestion = () => {
    const body = {};
    body.questions = [{ ...ques }];
    body.questions[0].answers = [...ques.answers];
    delete body.questions[0].id;
    body.questions[0].answers.forEach((e) => delete e.id);
    delete body.questions[0].saved;
    delete body.questions[0].fromBE;
    delete body.questions[0].updating;
    axios
      .put(`${API}/admin/intelligence/OneFiveQuestions`, body, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res) {
          setques({
            question: "",
            answers: [{ ans: "", percent: 0, id: Math.random() }],
            id: Math.random(),
            saved: false,
            fromBE: false,
            updating: false,
            chartType: "BAR",
          });
          setIsEditing(false);
          refreshList();
          setErrSucMesg(res.data.message);
          setErrSucType(true);
        }
      })
      .catch(function (error) {
        if (error) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
        }
      });
  };
  const EditQuestion = () => {
    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={true}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <DialogTitle>Edit Question</DialogTitle>
        <div
          style={{ display: "flex", justifyContent: "center", width: "70vw" }}
        >
          {MyChatBox(ques)}
        </div>
      </Dialog>
    );
  };

  useEffect(() => {
    refreshList();
    setAnsweredQues([]);
    setIsAdding(false);
  }, [demographic, industry, country, ageGroup, keyword]);

  const removeMyChatBox = (id) => {
    const filteredQues = answeredQues.filter((e) => e.id !== id);
    setAnsweredQues(filteredQues);
  };

  const DuplicateQuestion = (row) => {
    let duplicateData = {};
    let questionArr = [];
    let dataArr = ListDATA.filter((dt) => {
      if (dt._id === row._id) return dt;
    });
    if (dataArr.length) {
      duplicateData.ageGroup = dataArr[0].ageGroup
        ? dataArr[0].ageGroup._id
        : "";
      duplicateData.demographic = dataArr[0].demographic
        ? dataArr[0].demographic._id
        : "";
      duplicateData.industry = dataArr[0].industry
        ? dataArr[0].industry._id
        : "";
      duplicateData.country = dataArr[0].country ? dataArr[0].country._id : "";
      row.otherData &&
        row.otherData.length &&
        row.otherData.map((rw) => {
          questionArr.push({
            chartType: rw.chartType,
            question: rw.question,
            answers: rw.answers,
          });
        });
      duplicateData.questions = questionArr;
    }
    axios
      .post(`${API}/admin/intelligence/addOneFiveInsight`, duplicateData, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setErrSucMesg(res.data.message);
          setErrSucType(true);
          refreshList();
          Listrefresh();
        }
      })
      .catch(function (error) {
        if (error) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
        }
      });
  };

  const modalToShow = (element) => {
    if (element.chartType === "PYR") {
      return (
        <div>
          <HighchartReact
            highcharts={Highcharts}
            options={{
              chart: {
                type: "pyramid",
              },
              title: {
                overflow: "auto",
                text: element.question,
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    enabled: true,
                    format: "<b>{point.name}</b> ({point.y:,.0f})",
                    softConnector: true,
                  },
                  width: "90%",
                  height: "90%",
                },
              },
              legend: {
                enabled: false,
              },
              series: [
                {
                  name: element.question,
                  data: element.answers.map((e) => [
                    e.ans,
                    parseInt(e.percent),
                  ]),
                },
              ],

              responsive: {
                rules: [
                  {
                    condition: {
                      maxWidth: 500,
                    },
                    chartOptions: {
                      plotOptions: {
                        series: {
                          dataLabels: {
                            inside: true,
                          },
                          width: "90%%",
                          height: "90%",
                        },
                      },
                    },
                  },
                ],
              },
            }}
          ></HighchartReact>
        </div>
      );
    } else if (element.chartType === "BAR") {
      return (
        <div>
          <HighchartReact
            highcharts={Highcharts}
            options={{
              chart: {
                type: "column",
              },
              title: {
                text: element.question,
              },
              xAxis: {
                categories: element.answers.map((e) => e.ans),
                crosshair: true,
              },
              yAxis: {
                min: 0,
                title: {
                  text: "Percentage of Answeres",
                },
              },
              tooltip: {
                headerFormat:
                  '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                  '<td style="padding:0"><b>{point.y:.1f} %</b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
              },
              plotOptions: {
                column: {
                  pointPadding: 0.2,
                  borderWidth: 0,
                },
              },
              series: [
                {
                  name: "Answers",
                  data: element.answers.map((e) => parseInt(e.percent)),
                },
              ],
            }}
          ></HighchartReact>
        </div>
      );
    } else if (element.chartType === "PIE") {
      let myData = [];
      element.answers.forEach((obj) => {
        myData.push({ name: obj.ans, y: parseInt(obj.percent) });
      });

      return (
        <div>
          <HighchartReact
            highcharts={Highcharts}
            options={{
              chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: "pie",
              },
              title: {
                text: element.question,
              },
              tooltip: {
                pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
              },
              accessibility: {
                point: {
                  valueSuffix: "%",
                },
              },
              plotOptions: {
                pie: {
                  allowPointSelect: true,
                  cursor: "pointer",
                  dataLabels: {
                    enabled: true,
                    format: "<b>{point.name}</b>: {point.percentage:.1f} %",
                  },
                },
              },
              series: [
                {
                  name: "Answer",
                  colorByPoint: true,
                  data: myData,
                },
              ],
            }}
          ></HighchartReact>
        </div>
      );
    }
  };

  const FileUpload = (file) => {
    setFileLoading(true);
    const formData = new FormData();

    formData.append("csv", file);

    axios
      .post(`${API}/admin/intelligence/csvUploadOneFive`, formData, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res) => {
        if (res) {
          setFileLoading(false);
          setErrSucMesg(res.data.message);
          setErrSucType(true);
          // refresh();
          refreshList();
          setFile(true);
        }
      })
      .catch(function (error) {
        setFileLoading(false);
        if (error) {
          setErrSucMesg(error.response.data.message || "File is not uploaded");
          setErrSucType(false);
          setFile(true);
        }
      });
    refreshList();
  };

  useEffect(() => {
    if (fileInputRef) fileInputRef.current.value = null;
  }, [file]);

  const MyChatBox = (element) => {
    const removeAns = (id) => {
      const filteredAns = ques.answers.filter((e) => e.id !== id);
      setques({ ...ques, answers: filteredAns });
    };
    const MyAns = (ele, i) => {
      return (
        <div
          style={{
            width: "100%",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            right: "0",
            justifyContent: "center",
            display: "flex",
            paddingBottom: "5px",
          }}
        >
          <textarea
            disabled={element.saved}
            value={ele.ans}
            placeholder={`answer${i}...`}
            style={{
              width: "75%",
              paddingTop: "10px",
              textAlign: "center",
              fontSize: "10px",
              outline: "none",
            }}
            onChange={(event) => {
              let anslist = [...ques.answers];
              anslist[i].ans = event.target.value;
              setques({ ...ques, answers: anslist });
            }}
          ></textarea>
          {section === "Quantitative" && (
            <input
              disabled={element.saved}
              type="number"
              value={ele.percent}
              style={{ width: "15%" }}
              placeholder="xx%"
              onChange={(event) => {
                let anslist = [...ques.answers];
                anslist[i].percent = event.target.value;
                setques({ ...ques, answers: anslist });
              }}
            />
          )}
          {i === ques.answers.length - 1 && i !== 0 && (
            <button onClick={() => removeAns(ele.id)}>x</button>
          )}
        </div>
      );
    };

    return (
      <div
        style={{
          width: "90%",
          display: "flex",
          marginRight: "10px",
          marginLeft: "10px",
        }}
      >
        <div
          style={{
            width: `${section === "Quantitative" ? "45%" : "90%"}`,
            marginBottom: "80px",
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            padding: "10px",
          }}
        >
          {!element.fromBE && !element.updating && (
            <button
              onClick={() => {
                setIsEditing(false);
                element.saved
                  ? removeMyChatBox(element.id)
                  : setIsAdding(false);
              }}
            >
              x
            </button>
          )}

          <InputLabel
            style={{
              textAlign: "center",
              marginBottom: "5px",
            }}
          >
            <textarea
              style={{ overflow: "auto" }}
              disabled={element.saved}
              value={element.question}
              placeholder={"Type Question..."}
              rows={2}
              onChange={(event) =>
                setques({ ...ques, question: event.target.value })
              }
            ></textarea>
          </InputLabel>
          {section === "Quantitative" && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <TextField
                disabled={element.saved}
                select
                label="Chart Type"
                value={element.chartType}
                onChange={(e) =>
                  setques({ ...ques, chartType: e.target.value })
                }
                SelectProps={{
                  native: true,
                }}
                variant="standard"
              >
                <option key="BAR" value="BAR">
                  BAR
                </option>
                <option key="PIE" value="PIE">
                  PIE
                </option>
                <option key="PYR" value="PYR">
                  PYRAMID
                </option>
              </TextField>
            </div>
          )}
          {element.answers.map((e, i) => MyAns(e, i))}
          {
            /////////select chart type ///////////////////////////////////////
          }

          {!element.saved && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <Button
                className={classes.addActionBtn}
                style={{ width: "80px", height: "40px" }}
                variant="outlined"
                onClick={() => {
                  let newAnsList = [...ques.answers];
                  newAnsList.push({ ans: "", percent: 0, id: Math.random() });
                  setques({ ...ques, answers: newAnsList });
                }}
              >
                +
              </Button>
            </div>
          )}
          {!element.fromBE && !element.updating && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              {element.saved ? (
                <Button
                  className={classes.addActionBtn}
                  style={{ width: "80px", height: "40px" }}
                  variant="outlined"
                >
                  Saved
                </Button>
              ) : (
                <Button
                  className={classes.addActionBtn}
                  style={{ width: "80px", height: "40px" }}
                  variant="outlined"
                  onClick={() => {
                    setAnsweredQues([
                      { ...ques, saved: true },
                      ...answeredQues,
                    ]);
                    setIsAdding(false);

                    setques({
                      question: "",
                      answers: [{ ans: "", percent: 0, id: Math.random() }],
                      id: Math.random(),
                      saved: false,
                      fromBE: false,
                      updating: false,
                      chartType: "BAR",
                    });
                  }}
                  disabled={
                    section === "Qualitative"
                      ? ques.question.length === 0
                      : ques.question.length === 0 ||
                        ques.answers[ques.answers.length - 1].percent === 0
                  }
                  // style={
                  //   ques.question.length === 0 ||
                  //   ques.answers[ques.answers.length - 1].percent === 0
                  //     ? {
                  //         color: "white",
                  //         opacity: "0.6",
                  //       }
                  //     : { color: "white", opaccity: "1" }
                  // }
                >
                  Save
                </Button>
              )}
            </div>
          )}

          {
            /////////update phase////////////////////////////////////////
            element.updating && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  marginTop: "10px",
                }}
              >
                <Button
                  className={classes.addActionBtn}
                  style={{
                    width: "80px",
                    height: "40px",
                    backgroundColor: "white",
                    color: "black",
                  }}
                  variant="outlined"
                  onClick={() => {
                    setques({
                      question: "",
                      answers: [{ ans: "", percent: 0, id: Math.random() }],
                      id: Math.random(),
                      saved: false,
                      fromBE: false,
                      updating: false,
                      chartType: "BAR",
                    });
                    setIsEditing(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className={classes.addActionBtn}
                  style={{
                    width: "80px",
                    height: "40px",
                  }}
                  variant="outlined"
                  onClick={() => UpdateQuestion()}
                >
                  Update
                </Button>
              </div>
            )
          }
          {element.fromBE && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                marginTop: "10px",
              }}
            >
              <Button
                className={classes.addActionBtn}
                style={{
                  width: "80px",
                  height: "40px",
                  backgroundColor: "#E16D17",
                }}
                variant="outlined"
                onClick={() => {
                  let toEdit = { ...element };
                  toEdit.answers.forEach((e) => (e.id = Math.random()));
                  toEdit.saved = false;
                  toEdit.fromBE = false;
                  toEdit.updating = true;
                  setques(toEdit);
                  setIsEditing(true);
                }}
              >
                Edit
              </Button>
              <Button
                className={classes.addActionBtn}
                style={{
                  width: "80px",
                  height: "40px",
                  backgroundColor: "#F44C3C",
                }}
                variant="outlined"
                onClick={() => setOpenPopUp(true)}
              >
                Delete
              </Button>
              {openPopUp && (
                <Dialog open={true}>
                  <DialogTitle>Delete Question</DialogTitle>
                  <p style={{ paddingRight: "10px", paddingLeft: "10px" }}>
                    Are you sure you want to delete this question permanently?
                  </p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      marginBottom: "10px",
                    }}
                  >
                    <Button
                      className={classes.addActionBtn}
                      style={{
                        width: "80px",
                        height: "40px",
                        backgroundColor: "white",
                        color: "black",
                      }}
                      variant="outlined"
                      onClick={() => {
                        setOpenPopUp(false);
                      }}
                    >
                      Cancel
                    </Button>
                    {console.log('element',element)}
                    <Button
                      className={classes.addActionBtn}
                      style={{
                        width: "80px",
                        height: "40px",
                        backgroundColor: "#F44C3C",
                      }}
                      variant="outlined"
                      onClick={() => DeleteQuestion(element._id, "OneFiveId")}
                    >
                      Delete
                    </Button>
                  </div>
                </Dialog>
              )}
            </div>
          )}
        </div>
        {section === "Quantitative" && (
          <div
            style={{
              width: "45%",
              marginBottom: "80px",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              padding: "10px",
            }}
          >
            {element.saved ? modalToShow(element) : modalToShow(ques)}
          </div>
        )}
      </div>
    );
  };

  const getEdit = () => {
    let len;
    let ls = ListtableData.filter((s) => {
      if (s.questionToggle === true) return s;
    });
    if (ls.length > 0) {
      len = true;
    } else {
      len = false;
    }
    return len;
  };

  return (
    <div className={classes.boxShadow}>
      <div className={classes.paper}>
        <NewAlert
          msg={ErrSucMsg}
          isSuccess={ErrSucType}
          close={() => setErrSucMesg("")}
        />
        {isEditing && EditQuestion()}

        <form
          className={classes.form}
          // // ref={useRef()  }
          // onSubmit={handleSubmit(onSubmit)}
          onSubmit={(e)=>e.preventDefault()}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              fontSize: "10px",

              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "16%", margin: "2%" }}>
              <img style={{ width: "80%" }} src={Q1in5Img} />
            </div>
            <div style={{ width: "19%", marginRight: "10px" }}>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
              >
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Demographic"
                  // value={currency}
                  onChange={(e) => setDemographic(e.target.value)}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {dropdownData?.demographics?.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </FormControl>
            </div>
            <div style={{ width: "19%", marginRight: "10px" }}>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
              >
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Industry"
                  // value={currency}
                  onChange={(e) => setIndustry(e.target.value)}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {dropdownData?.industries?.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </FormControl>
            </div>
            <div style={{ width: "19%", marginRight: "10px" }}>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
              >
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Country"
                  // value={currency}
                  onChange={(e) => setCountry(e.target.value)}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {dropdownData?.countries?.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </FormControl>
            </div>
            <div style={{ width: "19%" }}>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
              >
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Age Group"
                  // value={currency}
                  onChange={(e) => setAgeGroup(e.target.value)}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {dropdownData?.ageGroups?.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </FormControl>
            </div>
            <div style={{ width: "19%" }}>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
              >
                <div className="upload-csv-file-input" style={{}}>
                  <input
                    type="file"
                    accept=".csv"
                    ref={fileInputRef}
                    onChange={(e) => {
                      const file = e.target.files[0];
                      FileUpload(file);
                    }}
                  />
                </div>
              </FormControl>
            </div>
            <div style={{ width: "19%" }}>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
              >
                <TextField
                  id="outlined-select-currency-native"
                  fullWidth
                  // label="Name"
                  variant="outlined"
                  placeholder="Search"
                  // style={{ display: "block", marginBottom: "10px" }}
                  value={keyword}
                  onChange={(e) => {
                    setKeyword(e.target.value);
                    setListpage(1);
                  }}
                />
              </FormControl>
            </div>
            <div style={{ width: "19%" }}>
              <FormControl
                fullWidth
                component="fieldset"
                variant="outlined"
                className={[classes.selectControl, classes.customfield].join(
                  " "
                )}
              >
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Section"
                  // value={section}
                  onChange={(e) => setSection(e.target.value)}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  <option key={0} value={0}>
                    Select...
                  </option>
                  {["Quantitative", "Qualitative"]?.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </TextField>
              </FormControl>
            </div>
            <div style={{ marginTop: "15px" }}>
              {(loading || fileLoading) && <CircularProgress />}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            {insight.map((element) => {
              element.id = element._id;
              element.saved = true;
              element.fromBE = true;
              return MyChatBox(element);
            })}
            {answeredQues.map((element) => MyChatBox(element))}
            {isAdding && MyChatBox(ques)}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "10px",
                marginTop: "10px",
              }}
            >
              {demographic != 0 &&
                industry != 0 &&
                country != 0 &&
                ageGroup != 0 &&
                section != 0 && (
                  <FormControl>
                    <Button
                      disabled={isAdding}
                      style={
                        isAdding
                          ? {
                              color: "white",
                              opacity: "0.6",
                            }
                          : { color: "white", opaccity: "1" }
                      }
                      className={classes.addActionBtn}
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        setIsAdding(true);
                      }}
                    >
                      Add Question
                    </Button>
                  </FormControl>
                )}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "50px",
              // marginTop: "50px",
            }}
          >
            {answeredQues.length !== 0 && (
              <FormControl>
                {!editToggle && (
                  <Button
                    // style={
                    //   answeredQues.length === 0
                    //     ? {
                    //         color: "white",
                    //         opacity: "0.6",
                    //       }
                    //     : { color: "white", opaccity: "1" }
                    // }
                    className={classes.addActionBtn}
                    variant="outlined"
                    color="primary"
                    onClick={() => SendList()}
                  >
                    SUBMIT
                  </Button>
                )}
                <Button
                  className={classes.addActionBtn}
                  variant="outlined"
                  color="primary"
                  style={{ marginTop: "10px" }}
                  onClick={() => {
                    setAnsweredQues([]);
                    setEditToggle(false);
                  }}
                >
                  Cancel
                </Button>
              </FormControl>
            )}
          </div>
        </form>
        {
          ////////////////////////////////////////////
          <Dialog open={duplicateToggle}>
            <DialogTitle style={{ justifyContent: "center", display: "flex" }}>
              Duplicate Question
            </DialogTitle>
            <p style={{ paddingRight: "10px", paddingLeft: "10px" }}>
              Are you sure, you want to add duplicate question?
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                marginBottom: "10px",
              }}
            >
              <Button
                className={classes.addActionBtn}
                style={{
                  width: "80px",
                  height: "40px",
                  backgroundColor: "white",
                  color: "black",
                }}
                variant="outlined"
                onClick={() => {
                  setDuplicateToggle(false);
                }}
              >
                Cancel
              </Button>
              <Button
                className={classes.addActionBtn}
                style={{
                  width: "80px",
                  height: "40px",
                  backgroundColor: "#F44C3C",
                }}
                variant="outlined"
                onClick={() => {
                  setDuplicateToggle(false);
                  DuplicateQuestion(duplicateRecord);
                }}
              >
                Duplicate
              </Button>
            </div>
          </Dialog>
        }
          {tableLoading && <CircularProgress />}
          {ListtableData.length > 0 && !tableLoading && (
            <MyTable
              style={{ width: "100%" }}
              headings={[
                "Demographic",
                "Industry",
                "Country",
                "Age Group",
                "Question",
                "Answer",
              ]}
              listdata={ListtableData}
              //redirectLink={"MediaOwnerProfile?id="}
              handleDelete={usertype === "superadmin" ? (_id, row) => {
                DeleteQuestion(_id);
              } : null}
              handleDuplicate={(row) => {
                setDuplicateToggle(true);
                setDuplicateRecord(row);
              }}
              isEditable={getEdit()}
              HandleCSV={(row) => {
                let dataArr = ListDATA.filter((dt) => {
                  if (dt._id === row._id) return dt;
                });

                if (dataArr && dataArr.length) {
                  setEditToggle(true);
                  // setDemographic(dataArr[0].demographic ? dataArr[0].demographic._id:'');
                  // setCountry(dataArr[0].country ? dataArr[0].country._id:'');
                  // setAgeGroup(dataArr[0].ageGroup ? dataArr[0].ageGroup._id:'');
                  // setIndustry(dataArr[0].industry ? dataArr[0].industry._id:'');
                  dataArr[0].questions &&
                    dataArr[0].questions.map((q, i) => {
                      q.fromBE = true;
                      q.saved = true;
                      q.updating = false;
                    });
                  setAnsweredQues(dataArr[0].questions);
                }
              }}
            />
          )}

          <div>
            <span
              style={{
                color: "#FB6D6A",
                fontWeight: "600",
                margin: "0",
                fontSize: "20px",
                marginRight: "20px",
              }}
            >
              {ListDATA.length ? (
              <Pagination
                count={Math.ceil(Listpagecount / 10)}
                color="primary"
                page={Listpage}
                onChange={(e, page) => setListpage(page)}
                size="small"
              />
              ):null}
            </span>
          </div>

        {
          /////////////////////////////////////
        }
        {/* <Modal
          isModalOpen={modalOpen}
          redirect="/media-owner/personas"
          message="Your new persona has been saved"
        ></Modal> */}
      </div>
    </div>
  );
};

export default Question1;
