import React, { useEffect, useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const NewAlert = ({ msg, isSuccess, close }) => {
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  setTimeout(() => {
    if (msg?.length > 0) {
      close();
    }
  }, 2500);

  return (
    <div>
      {msg?.length > 0 && (
        <Snackbar open={msg?.length > 0}>
          <Alert severity={isSuccess ? "success" : "error"}>
            {msg ? msg : ""}{" "}
            <IconButton
              aria-label="close"
              color="inherit"
              style={{ padding: "2px" }}
              onClick={() => close()}
            >
              <CloseIcon />
            </IconButton>
          </Alert>
        </Snackbar>
      )}
    </div>
  );
};

export default NewAlert;
