/* eslint-disable no-unused-expressions */
import React from "react";

import Highcharts, { color, keys } from "highcharts";
import HighchartReact from "highcharts-react-official";

export const getChartData = (ele, key, pId, type, ques) => {
    let keyType = ques[type];
    let myData = [];
    const getColor = (str, index) => {
      let stringUniqueHash = [...str].reduce((acc, char) => {
        return char.charCodeAt(0) + ((acc << 5) - index);
      }, 0);
      let newColor = `hsl(${stringUniqueHash % 360}, 95%, 35%)`;
      return newColor;
    };

    key?.answers?.forEach((obj, i) => {
      myData.push({
        name: obj.ans,
        y: parseFloat(obj.percent),
        color: getColor(obj.ans, i),
      });
    });
    return (
      <div style={{ width: "100%" }}>
        {key?.chartType === "PYR" ? (
          <HighchartReact
            highcharts={Highcharts}
            options={{
              chart: {
                type: "pyramid",
              },
              title: {
                overflow: "auto",
                text: key.question,
              },
              colors: [
                "#99f546",
                "#B5927B",
                "#6a1c1c",
                "#f53232",
                "#1ddfa9",
                "#1f639f",
                "#851f9f",
                "#e74513",
                "#e94b0f",
                "#3988e9",
                "#dd1f3a",
              ],
              plotOptions: {
                series: {
                  dataLabels: {
                    enabled: true,
                    format: "<b>{point.name}</b> ({point.y:,.0f})",
                    softConnector: true,
                  },
                  width: "90%",
                  height: "90%",
                },
              },
              legend: {
                enabled: false,
              },
              series: [
                {
                  name: key.question,
                  data: key.answers.map((e) => [e.ans, parseFloat(e.percent)]),
                },
              ],

              responsive: {
                rules: [
                  {
                    condition: {
                      maxWidth: 500,
                    },
                    chartOptions: {
                      plotOptions: {
                        series: {
                          dataLabels: {
                            inside: true,
                          },
                          width: "90%%",
                          height: "90%",
                        },
                      },
                    },
                  },
                ],
              },
            }}
          ></HighchartReact>
        ) : null}
        {key?.chartType === "BAR" ? (
          <HighchartReact
            highcharts={Highcharts}
            options={{
              chart: {
                type: "column",
              },
              title: {
                text: key.question,
              },
              xAxis: {
                categories: key.answers.map((e) => e.ans),
                crosshair: true,
              },
              yAxis: {
                min: 0,
                title: {
                  text: type,
                },
              },
              tooltip: {
                headerFormat:
                  '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                  '<td style="padding:0"><b>{point.y:.1f} %</b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
              },
              plotOptions: {
                column: {
                  pointPadding: 0.2,
                  borderWidth: 0,
                },
              },
              series: [
                {
                  name: "Answers",
                  data: key.answers.map((e) => parseFloat(e.percent)),
                  color: {
                    linearGradient: [0, 0, 500, 0],
                    stops: [
                      [0, "#99f546"],
                      [1, "#1ddfa9"],
                    ],
                  },
                },
              ],
            }}
          ></HighchartReact>
        ) : null}
        {key?.chartType === "PIE" ? (
          <HighchartReact
            highcharts={Highcharts}
            options={{
              chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: "pie",
              },
              title: {
                text: key.question,
              },
              tooltip: {
                pointFormat: "{series.name}: <b>{point.y:.1f}%</b>",
              },
              accessibility: {
                point: {
                  valueSuffix: "%",
                },
              },
              plotOptions: {
                pie: {
                  allowPointSelect: true,
                  cursor: "pointer",
                  dataLabels: {
                    enabled: true,
                    format: "<b>{point.name}</b>: {point.y:.1f} %",
                  },
                },
              },
              series: [
                {
                  name: "Answer",
                  colorByPoint: true,
                  data: myData,
                  color: {
                    linearGradient: [0, 0, 500, 0],
                    stops: [
                      [0, "#99f546"],
                      [1, "#1ddfa9"],
                    ],
                  },
                },
              ],
            }}
          ></HighchartReact>
        ) : null}
      </div>
    );
  };