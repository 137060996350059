import React, { useState, useEffect, useRef, createRef, useMemo } from 'react';
import { makeStyles, fade, withTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

//Button
import Button from '@material-ui/core/Button'

//Icon
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ControlPointTwoToneIcon from '@material-ui/icons/ControlPointTwoTone';

//TextField
import TextField from '@material-ui/core/TextField'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom';
import MainLayout from '../../layouts/newMainLayout'
import { useSelector, useDispatch } from 'react-redux'
import Highcharts from "highcharts/highstock";
import PieChart from "highcharts-react-official";import Paper from '@material-ui/core/Paper';
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import BlockIcon from '@material-ui/icons/Block';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import IconButton from '@material-ui/core/IconButton'
import StepWrapper from './stepWrapper'
import PersonPinIcon from '@material-ui/icons/PersonPin';
import { DataGrid } from '@material-ui/data-grid';
import { Backdrop, Container, Fade, FormControl, InputLabel, MenuItem, Modal, Select } from '@material-ui/core';
import { searchUsers } from '../../selectors/search';
import { postBrandUplift, getForecast } from '../../redux/actions/brandUplift';
import { getCsvDropdown } from '../../redux/actions/admin';
import culturalIntell from '../../assets/Cultural-Intelligence.svg'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    addActionBtn: {
        padding: '10px',
        width: '150px',
        backgroundColor: "#136cc3",
        color: 'white',
        fontWeight: 'bold',
        borderRadius: '8px',
        textTransform: "none",
        '&:hover': {
            background: "#136cc3",
        }
    },
    customfield: {
        paddingTop: '0',
        marginTop: '10',
        backgroundColor: '#f4f4f8',
        borderRadius: '5px',
        '& .MuiInputLabel-root.Mui-shrink': {
            color: 'red'
        },
        '&:hover': {
            backgroundColor: '#fff',
        },
        '&$focused': {
            backgroundColor: '#fff',
            boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            borderColor: theme.palette.primary.main,
        },
        '& .MuiOutlinedInput-input': {
            padding: '8.5px 14px',
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '5px',

            '& fieldset': {
                borderColor: 'grey',
            },
            '&:hover fieldset': {
            },
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: 'grey'
        },
        "& .MuiInputLabel-root.Mui-focused": {
            color: 'grey'
        },
        "& .MuiInputLabel-root.MuiInputLabel-shrink": {
            margin: '0!important'
        },
        "& .MuiInputLabel-root.MuiInputLabel-animated": {
            marginTop: '-8px'
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: 'grey'
        }
    },

    gridRoot: {
        height: '400px',
        width: '100%',
        padding: '10px',
        '& .font-tabular-nums': {
            fontVariantNumeric: 'tabular-nums',
        },
    },
    selectControl: {
        marginBottom: '8px'
    },
    progressBar: {
        display:'flex',
        width: '100%',
        height: '100px',
        border:'2px solid #000',
        textAlign:'center',
        color:'#fff',
        fontSize:'20px',
        alignItems: 'center',
             

    },
    progressBarNew:{
        width: '100%',
        height: '10px',
        border:'2px solid #000',
        textAlign:'center',
        color:'#fff',
        fontSize:'20px',
    },
    progress: {
        height: '10px',
    },

    progressroot: {
        alignSelf: 'center',
    },
    logo: {
        width: '200px',
        margin: '0 auto',
        display: 'block',
        marginBottom: '30px'
    },
    boxShadow: {
        position: 'relative',
        padding: '40px 100px 0',
        boxShadow: '0px 0px 10px rgba(0,0,0,0.10), 0 10px 10px rgba(0,0,0,0.12)',
        borderRadius: '10px',
        backgroundColor: 'white',
        marginRight: '10px',
        marginLeft: '10px'

    },
    awarenessProgress: {
        marginRight:'5px',
        width: '100%',
        height: '100px',
        backgroundColor: '#9E9D9D',
        color: '#9E9D9D',
    },
    considerationProgress: {
        marginRight:'5px',
        width: '100%',
        height: '100px',
        backgroundColor: '#369DC6',
        color: '#369DC6',
    },
    preferenceProgress: {
        marginRight:'5px',
        width: '100%',
        height: '100px',
        backgroundColor: '#F06D1D',
        color: '#F06D1D',
    },
    activeIntentProgress: {
        marginRight:'5px',
       // borderRadius: '10px',
       marginRight:'5px',
        width: '100%',
        height: '100px',
        color:"#F6B105",
        backgroundColor: '#F6B105',
    },
}));

const inSightCopy = {
    "result": {
        "age": [
            {
                "y": 20,
                "name": "16 (20 %)",
                "color": "#dcf293"
            },
            {
                "y": 6,
                "name": "17_20 (80 %)",
                "color": "#e234da"
            },
            {
                "y": 80,
                "name": "17_20 (80 %)",
                "color": "#e234da"
            },
            {
                "y": 80,
                "name": "17_20 (80 %)",
                "color": "#e234da"
            },
            {
                "y": 80,
                "name": "17_20 (80 %)",
                "color": "#e234da"
            }
        ],
        "gender": [
            {
                "y": 15,
                "name": "male (15 %)",
                "color": "#193793"
            },
            {
                "y": 85,
                "name": "female (85 %)",
                "color": "#899115"
            }
        ],
        "demographic": [
            {
                "y": 35,
                "name": "asian (35 %)",
                "color": "#c5b6f9"
            },
            {
                "y": 65,
                "name": "black (65 %)",
                "color": "#55e892"
            }
        ],
        "hobbiesInterest": [
            {
                "y": 83,
                "name": "Television (83 %)",
                "color": "#8de8c2"
            },
            {
                "y": 17,
                "name": "Radio (17 %)",
                "color": "#bc0801"
            }
        ]
    }
}
const colors=["#9E9D9D","#369DC6","#F06D1D","#F6B105"];

const QuestionneriesStats = () => {

    const dispatch = useDispatch()
    const searchInput = useRef(null);
    const inputRef = useRef([]);
    const history = useHistory();
    const classes = useStyles();
    const [demographic, setDemographic] = useState();
    const [industry, setIndustry] = useState();
    const [ageRange, setAgeRange] = useState();
    const [awareness, setAwareness] = useState('30');
    const [actionIntent, setActionIntent] = useState('10');
    const [preference, setPreference] = useState("20");
    const [consideration, setConsideration] = useState('40');
    const [budget, setBudget] = useState();
    const brandForecast = useSelector((state) => state.brandUpLiftForecast);
    const dropdownData = useSelector((state) => state.dropdownData);
    const { control, register, handleSubmit, errors } = useForm({ mode: 'onSubmit', reValidateMode: 'onSubmit' })
    const savedForecast = {
        chart: {
            type: 'pie',
            spacingBottom: 0,
            spacingTop: 0,
            spacingLeft: 0,
            spacingRight: 0,
            width: null,
            height: 200
        },
        title: {
            text: 'Age Group',
            align: 'center',
        },
        series: [
            {
                data: inSightCopy && inSightCopy.result && inSightCopy.result.gender
            }
        ],
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false,
                }
            }
        },
    };
    useEffect(() => {

        console.log('brandForecast',brandForecast);
        if (!dropdownData) {
            dispatch(getCsvDropdown())
        }
        if(!brandForecast){
            handleClick();
        }
    }, [brandForecast,dropdownData])

    const handleClick = () => {
        console.log('clicked');
        const data = {
            demographic,
            industry  
        }

        dispatch(getForecast(data));
    }

    
    return (
        <div>

            <Grid container spacing={3} >
                <Grid item xs={12} >
                    <Grid container spacing={3} >
                        <Grid item md={2} sm={2} xs={2} >
                            <img className={classes.logo} src={culturalIntell} />

                        </Grid>
                        <Grid item md={2} sm={2} xs={2} >
                            <FormControl fullWidth component="fieldset" variant="outlined" className={[classes.selectControl, classes.customfield].join(' ')} style={{ marginTop: '10px' }}>

                                <InputLabel id="demo-simple-select-label">Select Demographic</InputLabel>
                                <Select
                                    aria-label="Sizes"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={demographic}
                                    onChange={(e) => setDemographic(e.target.value)}
                                >
                                    {dropdownData?.demographic?.map((x, index) => {
                                                return <MenuItem key={index} value={x.value}>{x.name}</MenuItem>
                                            })}

                                </Select>
                            </FormControl>

                        </Grid>
                        <Grid item md={2} sm={2} xs={2} >
                            <FormControl fullWidth component="fieldset" variant="outlined" className={[classes.selectControl, classes.customfield].join(' ')} style={{ marginTop: '10px' }}>

                                <InputLabel id="demo-simple-select-label">Select Age</InputLabel>
                                <Select
                                    aria-label="Sizes"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={ageRange}
                                    onChange={(e) => setAgeRange(e.target.value)}
                                >
                                    {dropdownData?.ageRange?.map((x, index) => {
                                                return <MenuItem key={index} value={x.value}>{x.name}</MenuItem>
                                            })}

                                </Select>
                            </FormControl>

                        </Grid>

                        <Grid item md={2} sm={2} xs={2} >
                            <FormControl fullWidth component="fieldset" variant="outlined" className={[classes.selectControl, classes.customfield].join(' ')} style={{ marginTop: '10px' }}>

                                <InputLabel id="demo-simple-select-label">Select Industry</InputLabel>
                                <Select
                                    aria-label="Sizes"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={industry}
                                    onChange={(e) => setIndustry(e.target.value)}
                                >
                                    {dropdownData?.industry?.map((x, index) => {
                                                return <MenuItem key={index} value={x.value}>{x.name}</MenuItem>
                                            })}

                                </Select>
                            </FormControl>

                        </Grid>
                        <Grid item md={2} sm={2} xs={2}>
                            <Button onClick={(e) => handleClick(e)} className={classes.addActionBtn} variant="outlined" color="primary">
                                REFRESH
                            </Button>
                        </Grid>


                    </Grid>
                    <Grid container  spacing={1} style={{ marginLeft: "30px", alignItems: 'center' }}>
                       
                        <Grid item md={3} sm={3} xs={3} style={{backgroundColor:'white'}} >
                            <PieChart highcharts={Highcharts} options={savedForecast} />
                        </Grid>
                        <Grid item md={2} sm={2} xs={2} style={{ marginLeft: '5px' }}>
                            <div className={classes.progressBarNew}>
                                <div className={classes.progress} style={{ width: '100px', backgroundColor: colors[0] }}></div>
                            </div>
                            <div style={{ marginBottom: '5px' }}>
                                <span className={classes.considerationProgress} >---</span> Consideration
                            </div>
                            <div style={{ marginBottom: '5px' }}>
                                <span className={classes.preferenceProgress} >---</span> Prefrence
                            </div>
                            <div >
                                <span className={classes.activeIntentProgress} >---</span> Action Intent
                            </div>

                        </Grid>
                    </Grid>
                    <Grid container  spacing={1} style={{ marginLeft: "30px", alignItems: 'center' }}>
                       
                       <Grid item md={3} sm={3} xs={3} style={{backgroundColor:'white'}} >
                           <PieChart highcharts={Highcharts} options={savedForecast} />
                       </Grid>
                                      
                   </Grid>
                    </Grid>
            </Grid>

        </div>
    );
}

export default QuestionneriesStats