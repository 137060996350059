import React, { useState, useEffect } from "react";
import { makeStyles, fade } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { useForm, Controller } from "react-hook-form";

//Radio
import FormControl from "@material-ui/core/FormControl";

//Text field
import TextField from "@material-ui/core/TextField";

// Select
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

//Textarea
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

//Button
import Button from "@material-ui/core/Button";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getPersonas } from "../redux/actions/persona";
import { FormControlLabel, FormLabel } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "40px",
  },
  pageTitle: {
    color: "#e96941",
    fontWeight: "normal",
  },
  formTitle: {
    color: "#1d8bf7",
    fontWeight: "normal",
  },
  paper: {
    padding: "16px",
    color: theme.palette.text.secondary,
  },
  textarea: {
    width: "100%",
    backgroundColor: "#f4f4f8",
    borderRadius: "5px",
    padding: "14px",
    marginBottom: "20px",
  },
  selectControl: {
    marginBottom: "8px",
  },
  customfield: {
    paddingTop: "0",
    marginTop: "0",
    backgroundColor: "#f4f4f8",
    borderRadius: "5px",
    "& .MuiInputLabel-root.Mui-shrink": {
      color: "red",
      // "& .Mui-shrink": {
      // 	color: 'red'
      // },
    },
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&$focused": {
      backgroundColor: "#fff",
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.5px 14px",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",

      "& fieldset": {
        borderColor: "grey",
      },
      "&:hover fieldset": {},
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "grey",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "grey",
    },
    "& .MuiInputLabel-root.MuiInputLabel-shrink": {
      margin: "0!important",
    },
    "& .MuiInputLabel-root.MuiInputLabel-animated": {
      marginTop: "-8px",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",
    },
  },
  selectImgBtn: {
    marginTop: "10px",
    display: "block",
    margin: "0 auto",
    color: "#ba54f5",
    border: "1px solid #ba54f5",
    padding: "5px 30px",
  },
  nextButton: {
    margin: "20px auto 0",
    backgroundColor: "#1d8bf7",
    color: "white",
    display: "block",
    padding: "5px 40px",
  },
  checkNRadio: {
    padding: 0,
    "& .MuiFormLabel-root": {
      color: "#1d8bf7",
      marginBottom: "8px",
    },
    "& .MuiFormControlLabel-root": {
      "& span": {
        paddingTop: "1px",
        paddingBottom: "1px",
      },
    },
  },
}));

const InventoryPopup = ({
  inventory,
  submitInventory,
  selectedInventoryItem,
  selectedPersonas,
}) => {
  const { register, handleSubmit, errors, control } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const [name, setName] = useState(inventory?.media);
  const [notes, setNotes] = useState("");
  const [sizes, setSizes] = useState("");
  const [maxFileSize, setMaxFileSize] = useState(inventory?.maxFileSize);
  const [unitCost, setUnitCost] = useState(inventory?.unitCost);
  const [volume, setVolume] = useState(inventory?.volume);

  const personasStore = useSelector((state) => state.personas);

  const dispatch = useDispatch();
  const history = useHistory();

  const classes = useStyles();
  const [personasList, setPersonasList] = useState([]);
  const [personasSelectedList, setPersonasSelectedList] = useState([]);

  console.info("sizessssssss: ", sizes);
  const mediaOwner = useSelector((state) => state.mediaOwner);
  const [channel, setChannel] = useState(mediaOwner.profile.channel.name);

  const getPersonasDispatch = () => {
    dispatch(getPersonas());
  };

  const onSubmit = (value) => {
    console.info("onSubmitEnter: ");
    // const inventoryUpdate = Object.assign({}, mediaOwner.inventory.value[inventory.name], value)
    // mediaOwner.inventory.value[inventory.name] = inventoryUpdate
    // sendNewInventories(mediaOwner)
    // const inventoryUpdate = Object.assign({}, inventory.value[inventory.name], value)

    const inventoryItem = {
      channel,
      channelItem: selectedInventoryItem.val,
      personas: personasSelectedList,
      data: {
        name,
        sizes,
        notes,
        maxFileSize,
        volume,
        unitCost,
      },
    };

    console.info("inventoryItem: ", inventoryItem);
    submitInventory({ inventoryItem });
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <form
            className={classes.form}
            // ref={useRef()  }
            onSubmit={handleSubmit(onSubmit)}
          >
            <div>
              <Grid container spacing={3}>
                <div
                  style={{
                    color: "#0e82f4",
                    fontWeight: "bold",
                    textAlign: "center",
                    padding: "40px 0px",
                    position: "relative",
                  }}
                >
                  <span>{channel}</span> &nbsp; : : &nbsp;
                  <span>{selectedInventoryItem.val}</span>{" "}
                </div>
              </Grid>
              <br />
              <Grid container spacing={3}>
                <Grid item md={7} sm={7} xs={7}>
                  <TextField
                    value={name}
                    className={classes.customfield}
                    onChange={(e) => setName(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="name"
                    label="Name"
                    // name={`${name}`}
                    inputRef={register()}
                    error={
                      errors.persona &&
                      errors.persona.about &&
                      errors.persona.about.name?.type === "required"
                    }
                  />
                  <Grid container spacing={1}>
                    <FormControl
                      fullWidth
                      component="fieldset"
                      variant="outlined"
                      className={[
                        classes.selectControl,
                        classes.customfield,
                      ].join(" ")}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Sizes
                      </InputLabel>
                      <Select
                        aria-label="Sizes"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={sizes}
                        onChange={(e) => setSizes(e.target.value)}
                      >
                        <MenuItem value={""}></MenuItem>
                        <MenuItem value={"100x100"}>100x100</MenuItem>
                        <MenuItem value={"200x200"}>200x200</MenuItem>
                        <MenuItem value={"300x300"}>300x300</MenuItem>
                        <MenuItem value={"400x400"}>400x400</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Controller
                    as={<TextareaAutosize />}
                    name={`[value][notes]`}
                    control={control}
                    onChange={(event) => setNotes(event.target.value)}
                    defaultValue={notes}
                    className={classes.textarea}
                    aria-label="minimum height"
                    rowsMin={10}
                    placeholder="Notes"
                  />
                </Grid>
                <Grid item md={5} sm={5} xs={5}>
                  <TextField
                    value={volume}
                    className={classes.customfield}
                    onChange={(e) => setVolume(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="volume"
                    label="Volume"
                    name={`[value][volume]`}
                    inputRef={register()}
                  />

                  {/* <TextField
                                        value={maxFileSize}
                                        className={classes.customfield}
                                        onChange={(e) => setMaxFileSize(e.target.value)}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        id="maxFileSize"
                                        label="Max File Size"
                                        name={`[value][maxFileSize]`}
                                        inputRef={register()}
                                    /> */}

                  <TextField
                    value={unitCost}
                    className={classes.customfield}
                    onChange={(e) => setUnitCost(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="unitCost"
                    label="Unit Cost"
                    name={`[value][unitCost]`}
                    inputRef={register()}
                  />

                  <hr style={{ marginBottom: "20px" }} />
                  <Button className={classes.nextButton} type="submit">
                    SAVE
                  </Button>
                </Grid>
              </Grid>
            </div>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default InventoryPopup;
