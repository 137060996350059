import { post, get } from "./axios";
import { SECRET_KEY } from "../../constants";

export async function getCurrentUser() {
  try {
    const token = getToken();
    if (!token) {
      return Promise.resolve(null);
    }

    const res = await get("/users/current", true);

    return res.data;
  } catch (err) {
    await handleError(err);
  }
}

export const registerApi = async (data) => {
  const response = await post("/register", data);
  window.localStorage.setItem(SECRET_KEY, response.data.secret);

  if (response.data.secret) {
    return getCurrentUser();
  }
  return response;
};
///create diffrent for media owner & planner////////////
export const loginApi = async (data) => {
  console.log("login data is >>>>>>>>>>>>>>>>>", data);
  ///use role for differenciate between admin & others///////
  if (data.role === "media_owner") {
    console.log("logging as media Owner");
    delete data.role;
    delete data.name;
    const response = await post("/mediaOwner/login", data);
    console.log("token is >>>>>>>>>>", response.data.token);

    window.localStorage.setItem(SECRET_KEY, response.data.token);
    if (response.data.token) {
      return getCurrentUser();
    }

    return response;
  } else if (data.role === "admin") {
    console.log("logging as admin");
    delete data.role;
    delete data.name;
    const response = await post("/admin/login", data);
    console.log("token is >>>>>>>>>>", response.data.token);
    window.localStorage.setItem(SECRET_KEY, response.data.token);
    if (response.data.token) {
      return getCurrentUser();
    }

    return response;
  } else if (data.role === "media_agency") {
    console.log("logging as media Owner");
    delete data.role;
    delete data.name;
    const response = await post("/mediaPlanner/login", data);
    console.log("token is >>>>>>>>>>", response.data.token);

    window.localStorage.setItem(SECRET_KEY, response.data.token);
    if (response.data.token) {
      return getCurrentUser();
    }

    return response;
  } else if (data.role === "company") {
    console.log("logging as company");
    delete data.role;
    delete data.name;
    const response = await post("/admin-company/login", data);
    console.log("token is >>>>>>>>>>", response.data.token);
    window.localStorage.setItem(SECRET_KEY, response.data.token);
    if (response.data.token) {
      return getCurrentUser();
    }

    return response;
  } else if (data.role === "creativeconsultant") {
    console.log("logging as consultant");
    delete data.role;
    delete data.name;
    const response = await post("/creativeconsultant/login", data);
    console.log("token is >>>>>>>>>>", response.data.token);
    window.localStorage.setItem(SECRET_KEY, response.data.token);
    if (response.data.token) {
      return getCurrentUser();
    }

    return response;
  }
};

export function logout() {
  window.localStorage.removeItem(SECRET_KEY);
  window.location.href = "/";
}

export function getToken() {
  return window.localStorage.getItem(SECRET_KEY);
}

export async function handleError(err) {
  if (err && err.response && err.response.status === 401) {
    await logout();
  }
}
