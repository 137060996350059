
import React, { useState, useEffect, useRef, createRef, useMemo } from 'react';
import { makeStyles, fade, withTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

//Button
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper';
//TextField
import { useForm, Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'
import TextField from '@material-ui/core/TextField';

import { useDispatch } from 'react-redux'
import MainLayout from '../../layouts/newMainLayout'
import StepWrapper from './stepWrapper'
import { useHistory } from 'react-router-dom';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useLocation } from 'react-router-dom';
import { getPersonas, personaSaved } from '../../redux/actions/persona';
import IconButton from '@material-ui/core/IconButton';
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import InventoryPopup from '../../components/InventoryPopup';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';

const print = [{
    name: 'Inside_front_cover(IFC)',
    value: 'Inside front cover(IFC)'
},
{
    name: 'Outside back cover(OBC)',
    value: 'Outside back cover(OBC)'
},
{
    name: 'Inside_Back _cover(IBC)',
    value: 'Inside Back cover(IBC)'
},
{
    name: 'Quarter_page',
    value: '1 / 4 page'
},
{
    name: 'Half_page_vertical',
    value: '1 / 2 page vertical'
},
{
    name: 'Half_page_landscape',
    value: '1 / 2 page landscape'
},
{
    name: 'Single_page',
    value: 'Single page'
},
{
    name: 'Double_page_spread',
    value: 'Double page spread'
},
{
    name: 'Fold_out_cover',
    value: 'Fold out cover'
},
{
    name: 'Sponsored_articles',
    value: 'Sponsored articles'
},
{
    name: 'Flyers',
    value: 'Flyers'
},
{
    name: 'Stickers',
    value: 'Stickers'
},
{
    name: 'Promotional_Products',
    value: 'Promotional Products'
}]


const radio = [
    {
        name: 'Jingle',
        value: 'Jingle'
    },
    {
        name: 'Spoken_ad',
        value: 'Spoken ad'
    },
    {
        name: 'Channel_Sponsor',
        value: 'Channel Sponsor'
    },
    {
        name: 'Show_Sponsor',
        value: 'Show Sponsor'
    },
    {
        name: 'Segment_Sponsor',
        value: 'Segment Sponsor'
    },
    {
        name: 'Live_read',
        value: 'Live read'
    },
]

const television = [
    {
        name: 'ident',
        value: 'ident'
    },
    {
        name: 'Product_Placement',
        value: 'Product Placement'
    },
    {
        name: 'Channel_Sponsor',
        value: 'Channel Sponsor'
    },
    {
        name: 'Show_Sponsor',
        value: 'Show Sponsor'
    },
    {
        name: 'Segment_Sponsor',
        value: 'Segment Sponsor'
    },
    {
        name: 'Ten_Second_Ad',
        value: '10 Second Ad'
    },
    {
        name: 'Thirty_Second_Ad',
        value: '30 Second Ad'
    },
    {
        name: 'Sixty_Second_Ad',
        value: '60 Second Ad'
    },
    {
        name: 'Full_ad_break_takeover',
        value: 'Full ad break takeover'
    },
]

const ooh = [
    {
        name: 'Digital_screens',
        value: 'Digital screens'
    },
    {
        name: 'Billboards',
        value: 'Billboards'
    },
    {
        name: 'Posters',
        value: 'Posters'
    },
    {
        name: 'Public_transport',
        value: 'Public transport'
    },
    {
        name: 'Street_furniture',
        value: 'Street furniture'
    },
    {
        name: 'Escalator_Treads',
        value: 'Escalator Treads'
    },
    {
        name: 'Point_of_Sale',
        value: 'Point of Sale'
    },
    {
        name: 'Vehicle_Wraps',
        value: 'Vehicle Wraps'
    },
    {
        name: 'Lift_Graphics',
        value: 'Lift Graphics'
    },
    {
        name: 'Floor_Graphics',
        value: 'Floor Graphics'
    },
    {
        name: 'Bus_Ads',
        value: 'Bus Ads'
    },
    {
        name: 'Scaffolding_Wraps',
        value: 'Scaffolding Wraps'
    },
    {
        name: 'Aerial_Banners',
        value: 'Aerial Banners'
    },
]

const social = [
    {
        name: 'Sponsored_Posts',
        value: 'Sponsored Posts'
    },
    {
        name: 'Sponsored_Stories',
        value: 'Sponsored Stories'
    },
    {
        name: 'Live_streams',
        value: 'Live streams'
    },
    {
        name: 'Affiliate_Links',
        value: 'Affiliate Links'
    },
    {
        name: 'Email_Blast',
        value: 'Email Blast'
    },
    {
        name: 'Whatsapp_mailing_list',
        value: 'Whatsapp mailing list'
    },
    {
        name: 'Branded_filters',
        value: 'Branded filters'
    },
    {
        name: 'Sponsored_Videos',
        value: 'Sponsored Videos'
    },
]

const digital = [
    {
        name: 'Display(IAB_Sizes)',
        value: 'Display (IAB Sizes)'
    },
    {
        name: 'Video_In_Slide',
        value: 'Video In Slide'
    },
    {
        name: 'Video_In_Content',
        value: 'Video In Content'
    },
    {
        name: 'Video_Pre_roll',
        value: 'Video Pre roll'
    },
    {
        name: 'Native_Content',
        value: 'Native Content'
    },
    {
        name: 'Text_ad',
        value: 'Text ad'
    },
    {
        name: 'Livestreams',
        value: 'Livestreams'
    },
    {
        name: 'Paid_google_search',
        value: 'Paid google search'
    },
    {
        name: 'Spotify_ad',
        value: 'Spotify ad'
    },
]

const partnerships = [
    {
        name: 'Influencer_Partnership',
        value: 'Influencer Partnership'
    },
    {
        name: 'Celebrity_Partnership',
        value: 'Celebrity Partnership'
    },
    {
        name: 'Brand_Partnerships',
        value: 'Brand Partnerships'
    },
    {
        name: 'Shared_stores',
        value: 'Shared stores'
    },
    {
        name: 'Pop_up_stores',
        value: 'Pop-up stores'
    },
    {
        name: 'Licensing',
        value: 'Licensing'
    },
    {
        name: 'Takeovers',
        value: 'Takeovers'
    },
]

const useStyles = makeStyles((theme) => ({
    pageTitle: {
        color: '#e96941',
        fontWeight: 'normal'
    },
    nextButton: {
        marginLeft: 'auto',
        backgroundColor: "#1d8bf7",
        color: 'white',
        display: 'block',
        padding: '5px 40px'
    },
    checkNRadio: {
        padding: 0,
        '& .MuiFormLabel-root': {
            color: '#1d8bf7',
            marginBottom: '8px',
            textTransform: 'capitalize',
        },
        '& .MuiFormControlLabel-root': {
            '& span': {
                paddingTop: '1px',
                paddingBottom: '1px',
            }
        }
    },
    boxShadow: {
        position: 'relative',
        padding: '16px',
        boxShadow: '0px 0px 20px rgba(0,0,0,0.30), 0 20px 20px rgba(0,0,0,0.22)',
        borderRadius: '30px',
        backgroundColor: 'white',
        marginTop: '-30px'
    },
    pageTitle: {
        color: '#e96941',
        fontWeight: 'normal'
    },
    formTitle: {
        color: "#1d8bf7",
        fontWeight: 'normal'
    },
    paper: {
        padding: '16px',
        color: theme.palette.text.secondary,
    },
    addActionBtn: {
        padding: '10px 20px 10px 10px',
        backgroundColor: "#136cc3",
        color: 'white',
        fontWeight: 'bold',
        borderRadius: '10px',
        textTransform: "none",
        marginBottom: '20px',
        '&:hover': {
            background: "#136cc3",
        }
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
    },
    modalPaper: {
        borderRadius: '30px',
        width: '960px',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        borderColor: 'transparent',
        boxShadow: theme.shadows[5],
        padding: '20px',
        textAlign: 'center',
        '&:focus': {
            outline: 'none',
        }
    },

}));

const InventorySelect = () => {
    const initialInventory = {
        name: '',
        width: '',
        height: '',
        notes: '',
        fileType: '',
        maxFileSize: '',
        colorSpace: '',
        dpi: '',
        unitCost: '',
    }

    const location = useLocation();

    console.info('loc loc: ', location)
    //const ids = []
    // location.state.map(val => {
    //     ids.push(val._id)
    // })

    // const personas = []
    // location.state.map(val => {
    //     personas.push({ name: val.about?.name, avatarColor: val.avatarColor })
    // })

    const dispatch = useDispatch()
    const history = useHistory()

    const classes = useStyles();

    const { control, register, handleSubmit, errors } = useForm({ mode: 'onSubmit', reValidateMode: 'onSubmit' })

    const mediaOwner = useSelector((state) => state.mediaOwner);
    const inventorySaved = useSelector((state) => state.inventorySaved);
    const [inventoryList, setInventoryList] = useState([])
    const personasStore = useSelector((state) => state.personas);
    const [personasList, setPersonasList] = useState([])
    
    const [selectedOptionItem, setSelectedOptionItem] = useState({})
    const [selectedOptions, setSelectedOptions] = useState([])
    const [open, setOpen] = useState(false);
    const [inventoryItem, setInventoryItem] = useState(initialInventory);
    const [allInventories, setAllInventories] = useState([]);
    const createInventoryMediaOwnerSelected = useSelector((state) => state.createInventoryMediaOwnerSelected);
    const [channel, setChannel] = useState('radio');

    useEffect(() => {
        console.info('createInventoryMediaOwnerSelected: ', createInventoryMediaOwnerSelected)
        if (!createInventoryMediaOwnerSelected) {
            history.push('/media-owner/inventory-create')
        }  
        setInventory(channel)
    }, [createInventoryMediaOwnerSelected])

    const getPersonasDispatch = () => {

        dispatch(getPersonas())
      }

    useEffect(() => {

        console.info('aaaaaaaaaaaaaaaaaaaaaaaaa')
        // if (mediaOwner.profile === undefined) {
        //     history.push('/media-owner/about')
        // } else {
        //     setChannel(mediaOwner.profile.channel.name)
        //     setInventory(mediaOwner.profile.channel.name)
        //     // if (inventorySaved) {
        //     //     history.push('/media-owner/confirmation', personas)
        //     //}
        // }

        if(mediaOwner.inventoryConfirmed) {

        }

        console.info('husnain2 allInventories : ', allInventories)
        console.info('husnain2 mediaOwner: ', mediaOwner)


    }, [mediaOwner, inventorySaved, allInventories])

    const onSubmit = (data) => {
        // data.inventory.name = channel;
        // const inventory = data.inventory;
        // mediaOwner.inventory = inventory
       // mediaOwner.personasLinked = ids
        const wrapper = {
            mediaOwner: {
                profile: mediaOwner.profile,
                personasLinked: mediaOwner.personasLinked,
                inventory: allInventories
            },
            inventoryConfirmed: true
        }

        // if()

        console.info('result oooooooo: ', mediaOwner)
        dispatch({
            type: 'SAVE_MEDIA_OWNER_AND_INVENTORY',
            mediaOwner1: wrapper
        })

       //// history.push('/media-owner/confirmation', personas)
    }

    const setInventory = (val) => {
        switch (val) {
            case 'television':
                return setInventoryList(television)
            case 'radio':
                return setInventoryList(radio)
            case 'print':
                return setInventoryList(print)
            case 'digital':
                return setInventoryList(digital)
            case 'ooh':
                return setInventoryList(ooh)
            case 'social':
                return setInventoryList(social)
            case 'partnership':
                return setInventoryList(partnerships)
            // you can have as many case statements as you need
            default:
                return false
        }
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = (value, val) => {

        if(value) {
          setOpen(true);
        } else {
            console.info('allInventories: ', allInventories)
            const result = allInventories.filter(x => x.channel === channel && x.channelItem != val);

            setAllInventories(result);
        }
    };

    
    const submitInventory = (data) => {


    console.info('rrr: ', data)
    console.info('allInventories: ', allInventories)
    let allInventories1 =[...allInventories, data.inventoryItem];
    console.info('allInventories aa: ', allInventories1)

    let found = false
        for (let i=0; i<allInventories.length; i++) {

            console.info('allInventories bb: ', allInventories[i])
            console.info('data.inventoryItem bb: ', data.inventoryItem)
            if(allInventories[i].channel === channel && allInventories[i].channelItem === data.inventoryItem.channelItem) {
               
                found = true;
                allInventories[i].personas = data.inventoryItem.personas;
                allInventories[i].data =  data.inventoryItem.data;

                console.info('data.inventoryItem cc: ', allInventories[i])
            }
       }

       if(!found) {
             console.info('submit allInventories1: ', allInventories1)
        setAllInventories([...allInventories, data.inventoryItem])
        console.info('submit allInventories: ', allInventories)
       }


       console.info('husnain allInventories : ', allInventories)
       console.info('husnain mediaOwner: ', mediaOwner)



     
        // setInventoryMap(mediaOwnerNew)
        // dispatch({
        //     type: 'CREATE_MEDIA_OWNER',
        //     mediaOwner: mediaOwnerNew
        // })
        setOpen(false);
    }


    // const mediaOwnerNew = mediaOwner;
    // mediaOwnerNew.inventory = allInventories

    // dispatch({
    //     type: 'CREATE_MEDIA_OWNER',
    //     mediaOwner: mediaOwnerNew
    // });

    console.info('rrr sssssssss: ', allInventories)
    console.info('rrr sssssssss medieOwner: ', mediaOwner)



    return (
        <div>
            <StepWrapper step={2} />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper style={{ padding: '16px' }}>
                        <h1 className={classes.pageTitle}>Inventory</h1>
                        <form
                            className={classes.form}
                            onSubmit={handleSubmit(onSubmit)}>
                            <FormControl component="fieldset" error={Boolean(errors.inventory && errors.inventory.value)} className={classes.checkNRadio}>
                                <FormLabel component="legend">{channel}</FormLabel>
                                {inventoryList.map(val => {
                                    return (
                                        <div key={val.name}>
                                            <FormControlLabel
                                                // key={val.name}
                                                label={val.value}
                                                control={<Controller
                                                    name={`inventory[value][${val.name}][checked]`}
                                                    control={control}
                                                    defaultValue={false}
                                                    // rules={{ required: true }}
                                                    render={props =>
                                                        <Checkbox
                                                            onClick={()=> {setSelectedOptionItem({name: val.name, val: val.value}); handleOpen(!props.value, val.value) }}
                                                            onChange={e => props.onChange(e.target.checked)}
                                                            checked={props.value}
                                                        />
                                                    } // props contains: onChange, onBlur and value
                                                />}
                                            >
                                            </FormControlLabel>
                                            <TextField
                                                type="hidden"
                                                value={val.value}
                                                name={`inventory[value][${val.name}][name]`}
                                                id="role"
                                                inputRef={register()}
                                            />
                                        </div>
                                    )
                                })}
                            </FormControl>
                            
                            {/* <Modal
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                className={classes.modal}
                                fullWidth
                                open={open}
                                onClose={handleClose}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                    timeout: 500,
                                }}
                            >
                            <Fade in={open}>
                                <div className={classes.modalPaper}>
                                    <InventoryPopup selectedPersonas={personasList} selectedInventoryItem={selectedOptionItem} submitInventory={submitInventory} inventory={inventoryItem}/>
                                </div>
                            </Fade>
                        </Modal> */}

                            <Grid container spacing={3}>
                        {/* {personasList.length > 0 && personasList.map((val, index) => (
                            // Object.keys(val.persona).length > 0 &&
                            <Grid key={index} item md={3} sm={3} xs={3}>
                                <Paper>
                                    <div style={{ textAlign: 'center', padding: '40px 0px', position: 'relative' }}>
                                        {mediaOwner.profile == null ? null  : 
                                            <IconButton style={{
                                                position: 'absolute',
                                                top: '-10px',
                                                right: '-10px',
                                            }}
                                                onClick={() => toggleSelectPersona(val._id, selectedOptionItem)}
                                                aria-label="open drawer">

                                                {val.selected ? <StarRoundedIcon style={{ fontSize: '1.5em', color: '#e96941' }} /> :
                                                    <StarBorderRoundedIcon style={{ fontSize: '1.5em', color: 'rgb(233 231 238)' }} />}
                                            </IconButton>
                                        }
                                        <div>
                                            <PersonPinIcon style={{ color: val.avatarColor, fontSize: '12em' }} />
                                        </div>
                                        <span style={{ color: '#0e82f4', fontSize: '1.2em', display: 'block', fontWeight: 'bold', marginBottom: '20px' }}>{val.about.name}</span>
                                        <Button className={classes.addActionBtn1} variant="outlined">
                                            Show Persona
                                            </Button>
                                    </div>
                                </Paper>
                            </Grid>
                        ))} */}
                    </Grid>

                            <Button className={classes.nextButton} type="submit">Next</Button>
                        </form>

                        <Modal
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                className={classes.modal}
                                fullWidth
                                open={open}
                                onClose={handleClose}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                    timeout: 500,
                                }}
                            >
                            <Fade in={open}>
                                <div className={classes.modalPaper}>
                                    <InventoryPopup selectedPersonas={personasList} selectedInventoryItem={selectedOptionItem} submitInventory={submitInventory} inventory={inventoryItem}/>
                                </div>
                            </Fade>
                        </Modal>

                    </Paper>
                </Grid>
            </Grid>
        </div >
    );
}
export default MainLayout(InventorySelect);
