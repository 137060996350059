import Grid from "@material-ui/core/Grid";
import { fade, makeStyles, withTheme } from "@material-ui/core/styles";
import React, { createRef, useEffect, useMemo, useRef, useState } from "react";

//Button
import Button from "@material-ui/core/Button";

//Icon
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ControlPointTwoToneIcon from "@material-ui/icons/ControlPointTwoTone";

//TextField
import {
  Backdrop,
  Fade,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { DataGrid } from "@material-ui/data-grid";
import BlockIcon from "@material-ui/icons/Block";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import StarBorderRoundedIcon from "@material-ui/icons/StarBorderRounded";
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import culturalIntell from "../../assets/Cultural-Intelligence.svg";
import Modal from "../../components/modal";
import MainLayout from "../../layouts/newMainLayout";
import { getCsvDropdown } from "../../redux/actions/admin";
import { postBrandUplift } from "../../redux/actions/brandUplift";
import {
  getAllMediaOwner,
  listMediaOwnerTitles,
} from "../../redux/actions/media-owner";
import { searchUsers } from "../../selectors/search";
import StepWrapper from "../mediaOwner/stepWrapper";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  addActionBtn: {
    padding: "10px",
    width: "150px",
    backgroundColor: "#136cc3",
    color: "white",
    fontWeight: "bold",
    borderRadius: "8px",
    textTransform: "none",
    "&:hover": {
      background: "#136cc3",
    },
  },
  customfield: {
    paddingTop: "0",
    marginTop: "10",
    backgroundColor: "#f4f4f8",
    borderRadius: "5px",
    "& .MuiInputLabel-root.Mui-shrink": {
      color: "red",
    },
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&$focused": {
      backgroundColor: "#fff",
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.5px 14px",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",

      "& fieldset": {
        borderColor: "grey",
      },
      "&:hover fieldset": {},
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "grey",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "grey",
    },
    "& .MuiInputLabel-root.MuiInputLabel-shrink": {
      margin: "0!important",
    },
    "& .MuiInputLabel-root.MuiInputLabel-animated": {
      marginTop: "-8px",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",
    },
  },

  gridRoot: {
    height: "400px",
    width: "100%",
    padding: "10px",
    "& .font-tabular-nums": {
      fontVariantNumeric: "tabular-nums",
    },
  },
  selectControl: {
    marginBottom: "8px",
  },
  progressBar: {
    width: "100%",
    height: "5px",
  },
  progress: {
    height: "5px",
  },
  progressroot: {
    alignSelf: "center",
  },
  logo: {
    width: "150px",
    margin: "0 auto",
    display: "block",
    marginBottom: "30px",
  },
  boxShadow: {
    position: "relative",
    padding: "16px",
    boxShadow: "0px 0px 10px rgba(0,0,0,0.20), 0 0 10px rgba(0,0,0,0.15)",
    borderRadius: "30px",
    backgroundColor: "white",
    marginTop: "-30px",
  },
  paper: {
    padding: "16px",
    color: theme.palette.text.secondary,
  },
  pageTitle: {
    color: "#e96941",
    fontWeight: "normal",
  },
}));

const BrandUplift = () => {
  const dispatch = useDispatch();
  const searchInput = useRef(null);
  const inputRef = useRef([]);
  const history = useHistory();
  const classes = useStyles();
  const [demographic, setDemographic] = useState();
  const [industry, setIndustry] = useState();
  const [awareness, setAwareness] = useState("33");
  const [actionIntent, setActionIntent] = useState("33");
  const [preference, setPreference] = useState("33");
  const [consideration, setConsideration] = useState("33");
  const [budget, setBudget] = useState();
  const dropdownData = useSelector((state) => state.dropdownData);
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className={classes.boxShadow} style={{ height: "600px" }}>
      <div className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item md={3} sm={3} xs={3}>
                <img className={classes.logo} src={culturalIntell} />
              </Grid>
              <Grid item md={3} sm={3} xs={3}>
                <FormControl
                  fullWidth
                  component="fieldset"
                  variant="outlined"
                  className={[classes.selectControl, classes.customfield].join(
                    " "
                  )}
                  style={{ marginTop: "10px" }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Select Demographic
                  </InputLabel>
                  <Select
                    aria-label="Sizes"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={demographic}
                    onChange={(e) => setDemographic(e.target.value)}
                  >
                    {dropdownData?.demographic?.map((x, index) => {
                      return (
                        <MenuItem key={index} value={x.value}>
                          {x.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={3} sm={3} xs={3}>
                <FormControl
                  fullWidth
                  component="fieldset"
                  variant="outlined"
                  className={[classes.selectControl, classes.customfield].join(
                    " "
                  )}
                  style={{ marginTop: "10px" }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Select Industry
                  </InputLabel>
                  <Select
                    aria-label="Sizes"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={industry}
                    onChange={(e) => setIndustry(e.target.value)}
                  >
                    {dropdownData?.industry?.map((x, index) => {
                      return (
                        <MenuItem key={index} value={x.value}>
                          {x.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={3} sm={3} xs={3}>
                <Button
                  // onClick={(e) => handleClick(e)}
                  className={classes.addActionBtn}
                  variant="outlined"
                  color="primary"
                >
                  Submit..
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              style={{ marginLeft: "30px", alignItems: "center" }}
            >
              <Grid item md={1} sm={2} xs={2}>
                Awareness
              </Grid>
              <Grid item md={1} sm={1} xs={1}>
                <TextField
                  value={awareness}
                  className={classes.customfield}
                  onChange={(e) => setAwareness(e.target.value)}
                  variant="outlined"
                  margin="normal"
                  id="0"
                />
              </Grid>
              <Grid item md={1} sm={2} xs={2}>
                Consideration
              </Grid>
              <Grid item md={1} sm={1} xs={1}>
                <TextField
                  value={consideration}
                  className={classes.customfield}
                  onChange={(e) => setConsideration(e.target.value)}
                  variant="outlined"
                  margin="normal"
                  id="0"
                />
              </Grid>
              <Grid item md={1} sm={2} xs={2}>
                Prefrence
              </Grid>
              <Grid item md={1} sm={1} xs={1}>
                <TextField
                  value={preference}
                  className={classes.customfield}
                  onChange={(e) => setPreference(e.target.value)}
                  variant="outlined"
                  margin="normal"
                  id="0"
                />
              </Grid>
              <Grid item md={1} sm={2} xs={2}>
                Action Intent
              </Grid>
              <Grid item md={1} sm={1} xs={1}>
                <TextField
                  value={actionIntent}
                  className={classes.customfield}
                  onChange={(e) => setActionIntent(e.target.value)}
                  variant="outlined"
                  margin="normal"
                  id="0"
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={3} sm={3} xs={3} style={{ marginLeft: "30px" }}>
                <FormControl
                  fullWidth
                  component="fieldset"
                  variant="outlined"
                  className={[classes.selectControl, classes.customfield].join(
                    " "
                  )}
                  style={{ marginTop: "10px" }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Select Budget
                  </InputLabel>
                  <Select
                    aria-label="Sizes"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={budget}
                    onChange={(e) => setBudget(e.target.value)}
                  >
                    <MenuItem value={""}></MenuItem>
                    <MenuItem value={"1-10k"}>1-10k</MenuItem>
                    <MenuItem value={"11-20k"}>11-20k</MenuItem>
                    <MenuItem value={"21-30k"}>21-30k</MenuItem>
                    <MenuItem value={"31-40k"}>31-40k</MenuItem>
                    <MenuItem value={"41-50k"}>41-50k</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Modal
          isModalOpen={modalOpen}
          message="Your data Successfully Submitted"
        ></Modal>
      </div>
    </div>
  );
};

export default BrandUplift;
