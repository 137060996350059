import React, { useState, useEffect } from "react";
import MainLayout from "../../../layouts/adminMainLayout";
import { useSelector } from "react-redux";

import CircularProgress from "@material-ui/core/CircularProgress";

//TextField
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
import useStyles from "../../../components/StyleClasses";
import { API, SECRET_KEY } from "../../../constants/index";
import axios from "axios";

import NewAlert from "../../../components/NewAlert";
import CustomDropdown from "../../../components/CustomDropdown";
import EyeIcon from "../../../assets/eyePass.svg";
import viewIcon from "../../../assets/visibility.svg";

function paramsToObject(entries) {
  const result = {};
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
}

const CreateConsultant = () => {
  const classes = useStyles();
  const history = useHistory();
  const urlparams = new URLSearchParams(history.location.search);
  const paramEntries = urlparams.entries();
  const params = paramsToObject(paramEntries);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUserName] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [country, setCountry] = useState(null);
  const [demographic, setDemographic] = useState(null);
  const [ageGroup, setAgeGroup] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [gender, setGender] = useState(null);
  const [avatarData, setAvatarData] = useState([]);
  const [switchItem, setSwitchItem] = useState(false);
  const [genderList, setGenderList] = useState([]);
  const [sexualOrientation, setSexualOrientation] = useState([]);
  const [selectedSexuality, setSelectedSexuality] = useState([]);

  ///////errors & successs //////////
  const [ErrSucMsg, setErrSucMesg] = useState("");
  const [ErrSucType, setErrSucType] = useState(true);
  ///////////////////////////////////

  const [loading, setloading] = useState(false);
  const [errors, setErrors] = useState({
    name: { error: false, txt: "" },
    email: { error: false, txt: "" },
    username: { error: false, txt: "" },
    userPassword: { error: false, txt: "" },
    country: { error: false, txt: "" },
    demographic: { error: false, txt: "" },
    ageGroup: { error: false, txt: "" },
    avatar: { error: false, txt: "" },
    gender: { error: false, txt: "" },
  });

  const access_token = window.localStorage.getItem(SECRET_KEY);
  const dropdownData = useSelector((state) => state.dropdownData);

  const validateEmail = email => {
    var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const saveConsultant = () => {
    if (!name || !email || !userPassword) return;
    const body = {
      name,
      email,
      username,
      userPassword,
      country: country?._id,
      demographic: demographic?._id,
      ageGroup: ageGroup?._id,
      avatar: avatar?.value,
      gender: gender?.value,
      sexualOrientation: selectedSexuality?.value
    };
    axios
      .post(`${API}/admin/creativeconsultant`, body, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setTimeout(() => {
            history.push("/admin/consultants");
          }, 1500);
          setErrSucMesg(res.data.message);
          setErrSucType(true);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
        }
      });
  };

  const updateConsultant = () => {
    if (!name || !email) return;
    const body = {
      name,
      email,
      username,
      userPassword,
      country: country?._id,
      demographic: demographic?._id,
      ageGroup: ageGroup?._id,
      avatar: avatar?.value,
      gender: gender?.value,
      sexualOrientation: selectedSexuality?.value
    };
    axios
      .put(`${API}/admin/creativeconsultant/${params?.id}`, body, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
      .then((res, err) => {
        if (res) {
          setTimeout(() => {
            history.push("/admin/consultants");
          }, 1500);
          setErrSucMesg(res.data.message);
          setErrSucType(true);
        }
        if (err) {
        }
      })
      .catch(function (error) {
        if (error) {
          setErrSucMesg(error.response.data.message);
          setErrSucType(false);
        }
      });
  };

 
  useEffect(() => {
    getSexualOrientationData();
    getAvatar();
    if (params.id) getConsultantData();
  }, []);

  const getSexualOrientationData = () => {
      setloading(true);
      axios
        .get(`${API}/admin/creativeconsultant/getCreativeDropdown`, {
          headers: { Authorization: `Bearer ${access_token}` },
        })
        .then((res) => {
          setloading(false);
          if(res && res.data){
            let ar = [];
            let gen = [];
            res.data.sexuality.map(i =>{
              ar.push({ label: i.name, value: i.name })
            });
            res.data.gender.map(i =>{
              gen.push({ label: i.name, value: i.name })
            });
            setSexualOrientation(ar);
            setGenderList(gen);
          }
        })
        .catch(function (error) {
          if (error) {
            setloading(false);
          }
        });
  };

  const getConsultantData = () => {
    if (params.id) {
      setloading(true);
      axios
        .get(`${API}/admin/creativeconsultant/${params.id}`, {
          headers: { Authorization: `Bearer ${access_token}` },
        })
        .then((res) => {
          setloading(false);
          setName(res.data.name);
          setEmail(res.data.email);
          setUserName(res.data.username);
          setGender({ label: res.data.gender, value: res.data.gender });
          setAvatar({ label: res.data.avatar, value: res.data.avatar });
          setSelectedSexuality({ label: res.data.sexualOrientation, value: res.data.sexualOrientation });
          setAgeGroup({
            label: res.data.ageGroup.name,
            value: res.data.ageGroup._id,
            _id: res.data.ageGroup._id,
          });
          setDemographic({
            label: res.data.demographic.name,
            value: res.data.demographic._id,
            _id: res.data.demographic._id,
          });
          setCountry({
            label: res.data.country.name,
            value: res.data.country._id,
            _id: res.data.country._id,
          });
        })
        .catch(function (error) {
          if (error) {
            setloading(false);
          }
        });
    }
  };

  const getAvatar = () => {
    const avatarArr = [];
    for (let i = 1; i <= 80; i++) {
      const avatar = require(`../../../assets/avatars_new/${i}.svg`);
      avatarArr.push({
        label: `Avatar${i}`,
        value: avatar,
      });
    }
    setAvatarData(avatarArr);
  };

  return (
    <div className={classes.boxShadow} style={{ marginTop: "40px" }}>
      <NewAlert
        msg={ErrSucMsg}
        isSuccess={ErrSucType}
        close={() => setErrSucMesg("")}
      />
      <div className={classes.customfield}>
        <span
          style={{
            color: "#FB6D6A",
            fontWeight: "600",
            margin: "0",
            fontSize: "20px",
            marginLeft: "20px",
          }}
        >
          {params.id ? "Update Consultant" : "Create Consultant"}
        </span>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "20px",
            }}
          >
            <div style={{ width: "50%" }}>
              <TextField
                error={errors.name.error}
                helperText={errors.name.txt}
                fullWidth
                placeholder="Name"
                label="Name"
                required
                variant="outlined"
                style={{ display: "block", marginBottom: "10px" }}
                value={name}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    name: { error: false, txt: "" },
                  });
                  setName(e.target.value);
                }}
              />
              <TextField
                error={errors.email.error}
                helperText={errors.email.txt}
                fullWidth
                placeholder="Email"
                label="Email"
                required
                variant="outlined"
                style={{ display: "block", marginBottom: "10px" }}
                value={email}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    email: { error: false, txt: "" },
                  });
                  setEmail(e.target.value);
                }}
              />
              <TextField
                error={errors.username.error}
                helperText={errors.username.txt}
                fullWidth
                placeholder="User Name"
                label="User Name"
                variant="outlined"
                style={{ display: "block", marginBottom: "10px" }}
                value={username}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    username: { error: false, txt: "" },
                  });
                  setUserName(e.target.value);
                }}
              />
              <div style={{position: 'relative'}}>
                <TextField
                  error={errors.userPassword.error}
                  helperText={errors.userPassword.txt}
                  fullWidth
                  placeholder="Password"
                  autoComplete="new-password"
                  type={switchItem ? "text" : "password"}
                  label="Password"
                  variant="outlined"
                  style={{ display: "block", marginBottom: "10px" }}
                  value={userPassword}
                  onChange={(e) => {
                    setErrors({
                      ...errors,
                      userPassword: { error: false, txt: "" },
                    });
                    setUserPassword(e.target.value);
                  }}
                />
                {userPassword && (
                  <img
                    style={{ cursor: "pointer" }}
                    src={!switchItem ? viewIcon : EyeIcon}
                    alt="Password"
                    className={classes.showImg}
                    onClick={(e) => setSwitchItem(!switchItem)}
                  />
                )}
              </div>
              <CustomDropdown
                isSearchable={true}
                containerClassName="mt-3 dropdown-color"
                onChange={async (e) => {
                  setGender(e);
                }}
                value={gender}
                placeholder="Gender"
                options={genderList}
                isClearable={true}
              />
              <CustomDropdown
                isSearchable={true}
                containerClassName="mt-3 dropdown-color"
                onChange={async (e) => {
                  setCountry(e);
                }}
                value={country}
                placeholder="Country"
                options={dropdownData?.countries?.map((i) => {
                  i.label = i.name;
                  i.value = i.name;
                  return i;
                })}
                isClearable={true}
              />
              <CustomDropdown
                isSearchable={true}
                containerClassName="mt-3 dropdown-color"
                onChange={async (e) => {
                  setDemographic(e);
                }}
                value={demographic}
                placeholder="Demographic"
                options={dropdownData?.demographics?.map((i) => {
                  i.label = i.name;
                  i.value = i.name;
                  return i;
                })}
                isClearable={true}
              />
              <CustomDropdown
                isSearchable={true}
                containerClassName="mt-3 dropdown-color"
                onChange={async (e) => {
                  setAgeGroup(e);
                }}
                value={ageGroup}
                placeholder="Age Group"
                options={dropdownData?.ageGroups?.map((i) => {
                  i.label = i.name;
                  i.value = i.name;
                  return i;
                })}
                isClearable={true}
              />
              <CustomDropdown
                isSearchable={true}
                containerClassName="mt-3 dropdown-color"
                onChange={async (e) => {
                  setSelectedSexuality(e);
                }}
                value={selectedSexuality}
                placeholder="Sexual Orientation"
                options={sexualOrientation}
                isClearable={true}
              />
              <CustomDropdown
                isSearchable={true}
                containerClassName="mt-3 dropdown-color"
                onChange={async (e) => {
                  setAvatar(e);
                }}
                placeholder="Avatars"
                options={avatarData}
                imageData={avatarData}
                isClearable={true}
              />
              {avatar ? (
                <div className="icon">
                  <img src={avatar.value} width={50} height={50} />
                </div>
              ) : null}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <button
                  className={classes.addActionBtn}
                  onClick={() => history.push("/admin/consultants")}
                  style={{ backgroundColor: "grey" }}
                >
                  Cancel
                </button>
                <button
                  className={classes.addActionBtn}
                  onClick={() => {
                    if (name === "") {
                      setErrors({
                        ...errors,
                        name: {
                          error: true,
                          txt: "please enter name",
                        },
                      });
                      return;
                    } if (email === "") {
                      setErrors({
                        ...errors,
                        email: {
                          error: true,
                          txt: "please enter email",
                        },
                      });
                      return;
                    } if (email) {
                      let em = validateEmail(email);
                      if(!em) {
                        setErrors({
                          ...errors,
                          email: { error: true, txt: "Invalid email" },
                        });
                        return;
                      }
                    } 
                    // if (username === "") {
                    //   setErrors({
                    //     ...errors,
                    //     username: {
                    //       error: true,
                    //       txt: "please enter username",
                    //     },
                    //   });
                    //   return;
                    // }
                    // if (
                    //   (!params.id && name !== "" && email !== '' ) ||
                    //   (params.id && name !== "" && email !== '')
                    // ) {
                    // }
                    params.id ? updateConsultant() : saveConsultant();
                  }}
                >
                  {params.id ? "Update Consultant" : "Create Consultant"}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default MainLayout(CreateConsultant);
