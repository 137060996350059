import React from "react";
import { CircularProgress } from "@material-ui/core";

// if want to use without (position:absolute, top and left property), then pass <position: "unset"> in the style props
export default function LoaderCircle({ style, className, ...rest }) {
    const styles = {
        ...(style?.top ? { top: style.top } : (style?.position != 'unset' && { top: `calc(50% - ${style?.height || "40px"})` })),
        ...(style?.left ? { left: style.left } : (style?.position != 'unset' && { left: `calc(50% - ${style?.width || "40px"})` })),
        ...style,
    };
    return <CircularProgress size={style?.width} style={styles} className={className} {...rest} />;
}