import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { API, SECRET_KEY } from '../../../constants';
import MainLayout from "../../../layouts/newMainLayoutPlanner";

import HeatmapComp from "../IntelligenceDashboard/heatmap";

const HeatMap = () => {
  const [renderTimeHeatMap, setRenderTimeHeatMap] = useState([{comp: HeatmapComp, id: 0}])
  const [mediaOwnerList, setMediaOwnerList] = useState([])

  const handleRemove = (id) => {
    const filteredHeatMaps = renderTimeHeatMap.filter(heat => heat.id != id)
    setRenderTimeHeatMap(filteredHeatMaps)
  }
  const handleAddNewHeatMap = () => {
    setRenderTimeHeatMap((old_heat_maps) => {
      const new_heat_maps = [...old_heat_maps, {id: old_heat_maps.length, comp: HeatmapComp}]
      return new_heat_maps
    })
  }
  useEffect(() => {
    const access_token = window.localStorage.getItem(SECRET_KEY);
    if(!access_token) return
    async function fetchMediaOwnerList(){
      try{
        const {data} = await axios
        .get(`${API}/mediaPlanner/mediaOwners`, {
          headers: { Authorization: `Bearer ${access_token}` },
        })
        if(data){
          setMediaOwnerList(data)
        }
      }catch(err){
        console.log(err)
      }finally{   
      }
    }
    fetchMediaOwnerList()
  }, [])
  return (
    <>
    {
      renderTimeHeatMap.map((heat) => {
        if(heat.id == 0){
          return <div style={{paddingTop: "20px"}}><heat.comp key={heat.id} handleAddNewHeatMap={handleAddNewHeatMap} mediaOwnerList={mediaOwnerList}/></div>
        }
        return <div style={{paddingTop: "20px"}}><heat.comp key={heat.id} onRemoveHeatMap={() => handleRemove(heat.id)} handleAddNewHeatMap={handleAddNewHeatMap} mediaOwnerList={mediaOwnerList}/></div>
      })
    }
    {/* <Heatmap /> */}
    {/* <Heatmap /> */}
    {/* <Heatmap /> */}
    </>
  )
}

export default MainLayout(HeatMap)