import React, { useState, useEffect, useRef, createRef, useMemo } from "react";
import { makeStyles, fade, withTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

//Button
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
//Icon
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { useForm } from "react-hook-form";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import { useSelector } from "react-redux";

import { useDispatch } from "react-redux";
import StarBorderRoundedIcon from "@material-ui/icons/StarBorderRounded";
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import IconButton from "@material-ui/core/IconButton";
import MainLayout from "../../layouts/newMainLayoutPlanner";
import StepWrapper from "./stepWrapper";
import { useHistory, useLocation } from "react-router-dom";
import { personaSaved, getPersonas } from "../../redux/actions/persona";
import { searchPersonas } from "../../selectors/search";
import { TextField } from "@material-ui/core";
import {
  createMediaPlanner,
  saveMediaPlanner,
} from "../../redux/actions/media-planner";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  addActionBtn1: {
    fontWeight: "bold",
    color: "white",
    backgroundColor: "#0e82f4",
    "&:hover": {
      backgroundColor: "white",
      color: "#0e82f4",
    },
  },
  addActionBtn: {
    padding: "10px",
    paddingRight: "20px",
    backgroundColor: "#136cc3",
    color: "white",
    fontWeight: "bold",
    borderRadius: "23px",
    textTransform: "none",
    "&:hover": {
      background: "#136cc3",
    },
  },
  nextButton: {
    marginLeft: "auto",
    backgroundColor: "#1d8bf7",
    color: "white",
    display: "block",
    padding: "5px 40px",
    marginTop: "40px",
  },
  customfield: {
    paddingTop: "0",
    marginTop: "10",
    backgroundColor: "#f4f4f8",
    borderRadius: "5px",
    "& .MuiInputLabel-root.Mui-shrink": {
      color: "red",
    },
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&$focused": {
      backgroundColor: "#fff",
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.5px 14px",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",

      "& fieldset": {
        borderColor: "grey",
      },
      "&:hover fieldset": {},
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "grey",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "grey",
    },
    "& .MuiInputLabel-root.MuiInputLabel-shrink": {
      margin: "0!important",
    },
    "& .MuiInputLabel-root.MuiInputLabel-animated": {
      marginTop: "-8px",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",
    },
  },
}));

const Personas = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const classes = useStyles();
  const personas = useSelector((state) => state.personas);
  const personaSuccess = useSelector((state) => state.personaSuccess);
  const [personasList, setPersonasList] = useState([]);
  const [personaInventories, setPersonaInventories] = useState([]);
  const mediaPlanner = useSelector((state) => state.mediaPlanner);
  const currentUser = useSelector((state) => state.loggedInSession);
  const savedMediaPlannerId = useSelector((state) => state.savedMediaPlannerId);

  useEffect(() => {
    console.info("persoass: ", personas);
    console.info("mediaPlannerEEEE", mediaPlanner);
    if (!personaSuccess && !personas) {
      dispatch(getPersonas(currentUser?._id));
    } else {
      setPersonasList(personas);
    }
    if (savedMediaPlannerId) {
      history.push("/media-planner/forecast");
    }
  }, [personas, currentUser, savedMediaPlannerId]);

  const getPersonasDispatch = () => {
    dispatch(getPersonas("userId"));
  };

  const setPersonasNull = () => {
    dispatch({
      type: "PERSONA_SUCCESS",
      personaSuccess: null,
    });
  };

  //----------------------
  const location = useLocation();

  const GoToUrl = (type) => {
    let currentPath = location.pathname;
    let splitedPath = currentPath.split("/")[1];
    console.log("splitedPath", splitedPath);
    let url = `/${splitedPath}/${type}`;
    console.log("splitedPath", url);
    return url;
  };

  const goToAddPersona = () => {
    getPersonasDispatch();
    setPersonasNull();

    history.push(GoToUrl("personas/create"));
    // history.push('/media-owner/personas/create', personaInventories);
  };

  const goToForecast = () => {
    dispatch(createMediaPlanner(mediaPlanner)); //createInDatabase
    setPersonasNull();
    //history.push('/media-planner/forecast');
  };

  const toggleSelectPersona = (id) => {
    const getIds = [];
    const personalSelected = [];
    const newcur = personasList.map((val) => {
      if (val["_id"] === id) {
        val["selected"] = !val["selected"];
      }
      if (val["selected"] === true) {
        getIds.push(val);
        personalSelected.push(val._id);
      }
      return val;
    });

    setPersonasList(newcur);
    setPersonaInventories(getIds);
    console.info("newcur: ", newcur);
    console.info("getIds: ", getIds);
    mediaPlanner.personasLinked = personalSelected;

    dispatch(saveMediaPlanner(mediaPlanner)); //saveToStore
    // dispatch(createMediaPlanner(mediaPlanner));//createInDatabase
    console.info("mediaPlanner", mediaPlanner);
    history.push("/media-planner/personas");
  };
  const handleChange = (e) => {
    console.log("searchEvent", e.target.value);
    setPersonasList(searchPersonas(personas, e.target.value));
  };

  return (
    <div>
      <StepWrapper step={1} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item md={3} sm={3} xs={3}>
              <Button
                onClick={() => goToAddPersona()}
                className={classes.addActionBtn}
                variant="outlined"
                color="primary"
              >
                <AddCircleOutlineIcon
                  style={{ marginRight: "10px", color: "#a2e60f" }}
                />
                Create Persona
              </Button>
            </Grid>
          </Grid>
          <Grid item md={6} sm={6} xs={6}>
            <TextField
              style={{ display: "none" }}
              className={classes.customfield}
              onChange={(e) => handleChange(e)}
              variant="outlined"
              margin="normal"
              fullWidth
              id="search"
              label="Search By Name"
            />
          </Grid>
          <Grid container spacing={3}>
            {personasList.length > 0 &&
              personasList.map((val, index) => (
                // Object.keys(val.persona).length > 0 &&
                <Grid key={index} item md={3} sm={3} xs={3}>
                  <Paper>
                    <div
                      style={{
                        textAlign: "center",
                        padding: "40px 0px",
                        position: "relative",
                      }}
                    >
                      {mediaPlanner == null ? null : (
                        <IconButton
                          style={{
                            position: "absolute",
                            top: "-10px",
                            right: "-10px",
                          }}
                          onClick={() => toggleSelectPersona(val._id)}
                          aria-label="open drawer"
                        >
                          {val.selected ? (
                            <StarRoundedIcon
                              style={{ fontSize: "1.5em", color: "#e96941" }}
                            />
                          ) : (
                            <StarBorderRoundedIcon
                              style={{
                                fontSize: "1.5em",
                                color: "rgb(233 231 238)",
                              }}
                            />
                          )}
                        </IconButton>
                      )}
                      <div>
                        <PersonPinIcon
                          style={{ color: val.avatarColor, fontSize: "12em" }}
                        />
                      </div>
                      <span
                        style={{
                          color: "#0e82f4",
                          fontSize: "1.2em",
                          display: "block",
                          fontWeight: "bold",
                          marginBottom: "20px",
                        }}
                      >
                        {val.about.name}
                      </span>
                      <Button
                        className={classes.addActionBtn1}
                        variant="outlined"
                      >
                        Show Persona
                      </Button>
                    </div>
                  </Paper>
                </Grid>
              ))}
          </Grid>
          {personaInventories.length > 0 && (
            <Button
              onClick={goToForecast}
              className={classes.nextButton}
              type="submit"
            >
              Next
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
};
export default MainLayout(Personas);
