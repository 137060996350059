import { put, call, takeLatest } from 'redux-saga/effects';
import * as actions from '../redux/actions/persona';
import { createPersona, getPersonas } from '../services/api/persona';

function getRecentTen(response) {
  const sortedActivities = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
  const first10 = sortedActivities.slice(0, 10)
  first10.map(val => {
      val.selected = false
      return
  })

  return first10;
}

export function* callCreatePersonaApi(param) {
  try {
    const result = yield call(createPersona, param && param.persona);
    yield put(actions.personaSaved(true));
  } catch(error) {
    yield put(actions.error(error));
  }
}

export function* callGetPersonasApi(param) {
  try {
    const result = yield call(getPersonas, param && param.userId);
    const data = getRecentTen(result);
    yield put(actions.personasRetrieved(data));
  } catch(error) {
    yield put(actions.error(error));
  }
}

export function* createPersonaSaga() {
  yield takeLatest(actions.CREATE_PERSONA, callCreatePersonaApi);
}

export function* getPersonasSaga() {
  yield takeLatest(actions.GET_PERSONAS, callGetPersonasApi);
}
