import React, { useState, useEffect } from "react";
import {
  Backdrop,
  Fade,
  Modal,
  FormGroup,
  Box,
  Checkbox,
  FormControlLabel,
  FormControl,
  Button,
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import useStyles from "../../../components/StyleClasses";
import "./Dashboard.css";

const IntelligenceModal = ({ callback, onClose }) => {
  const classes = useStyles();
  const category = [
    // { name: "Brand Sentiment", value: "brand_sentiment" },
    // { name: "Cultural Insights", value: "cultural_insights" },
    { name: "Community Intelligence (Real-Time)", value: "talkwalker" },
    // { name: "Quant/Qual Research", value: "quant" },
    // { name: "Campaign Metrics", value: "campaign_metrics" },
    // { name: "Heatmap", value: "heatmap" },
    { name: "DECiA Reports", value: "deca_reports" },
    { name: "Diversity Reach - Global Map", value: "global_map"}
    // { name: "All", value: "all" },
  ];
  const [check, setCheck] = useState([]);

  const allSelectedValue = (checkValue) => {
    let checkArr = [...check];
    if (checkValue) {
      category.map((c) => {
        if (!checkArr.includes(c)) checkArr.push(c.value);
      });
      setCheck(checkArr);
    } else {
      setCheck([]);
    }
  };

  useEffect(() => {
    const getSections = localStorage.getItem("sections");
    let sectArr = getSections && getSections.split(',');
    if (getSections && getSections.length) setCheck(sectArr);
  }, []);

  return (
    <div>
      <Modal
        // aria-labelledby="transition-modal-title"
        // aria-describedby="transition-modal-description"
        open
        onClose={() => onClose(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Box className={classes.boxModal}>
          <Close style={{float:'right'}} onClick={() => onClose(false)} />
            <FormControl className="select-checkbox-modal" component="fieldset" style={{ marginTop: "10px" }}>
              <FormGroup>
                <h2>Select the category to view widgets.</h2>
                {category.map((option) => (
                  <FormControlLabel
                  className="border-widget-btn"
                    control={
                      <Checkbox
                        checked={check.includes(option.value)}
                        onChange={(e) => {
                          // if (
                          //   (e.target.checked && option.value === "all") ||
                          //   (e.target.checked === false &&
                          //     option.value === "all")
                          // )
                          // if
                          //   (e.target.checked) {
                          //   allSelectedValue(e.target.checked);
                          // } else {
                          let newArr = [...check];
                          let indexValue = newArr.indexOf(e.target.value);
                          // e.target.checked
                          // ? newArr.push(option.value)
                          // : (newArr = newArr.filter((e, index) => {
                          //     if (
                          //       option.value !== "all" &&
                          //       e.value === "all"
                          //     ) {
                          //       newArr.splice(index, 1);
                          //     } else {
                          //       e != option.value;
                          //     }
                          //   }));

                          e.target.checked
                            ? newArr.push(option.value)
                            : newArr.splice(indexValue, 1);
                          setCheck(newArr);
                          // }
                        }}
                        name={option.value}
                        value={option.value}
                      />
                    }
                    label={option.name}
                  />
                ))}

                <Button
                  style={{ color: "white", border: "1px solid" }}
                  onClick={() => {
                    callback(check);
                    onClose(false);
                    localStorage.setItem("sections", check);
                  }}
                >
                  Go ahead
                </Button>
              </FormGroup>
            </FormControl>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default IntelligenceModal;
