import { Grid, TextField, Typography } from "@material-ui/core"
import React from "react"
import Selection from "../../../../../../components/Select/Selection"
import { FrequencyCappingDailyViews } from "../Step2"

export default function Frequency_Clapping_Section({ inputStyles, states, handleStatesChanges }) {
    return (
        <>
            <div style={{ marginTop: "20px" }} className="form-section-heading">
                <Typography>Frequency Capping</Typography>
            </div>

            <div className="flex flex-col gap-15" style={{ paddingInline: "10px" }}>
                <Grid container className="align-center">
                    <Grid item xs={10} md={4} lg={4}>
                        <Typography>Per User Limit</Typography>
                    </Grid>
                    <Grid item xs={10} md={6} lg={6}>
                        <div className="flex align-center">
                            <Typography>Limit individuals to </Typography>
                            <TextField
                                value={states.frequency_capping__user_limit_views}
                                onChange={(e) =>
                                    handleStatesChanges("frequency_capping__user_limit_views", e.target.value)
                                }
                                variant="outlined"
                                className={inputStyles.input}
                                placeholder="0"
                                style={{ width: "100px", marginInline: "5px" }}
                            />
                            views every
                            <TextField
                                value={states.frequency_capping__user_limit_days}
                                onChange={(e) =>
                                    handleStatesChanges("frequency_capping__user_limit_days", e.target.value)
                                }
                                variant="outlined"
                                className={inputStyles.input}
                                placeholder="0"
                                style={{ width: "100px", marginInline: "5px" }}
                            />
                            days.
                        </div>
                    </Grid>
                </Grid>
                <Grid container className="align-center">
                    <Grid item xs={10} md={4} lg={4}>
                        <Typography>Daily Limit</Typography>
                    </Grid>
                    <Grid item xs={10} md={6} lg={6}>
                        <div className="flex align-center">
                            <Typography>Limit delivery to </Typography>
                            <TextField
                                value={states.frequency_capping__daily_limit}
                                onChange={(e) => handleStatesChanges("frequency_capping__daily_limit", e.target.value)}
                                variant="outlined"
                                className={inputStyles.input}
                                placeholder="0"
                                style={{ width: "100px", marginInline: "5px" }}
                            />
                            <Selection
                                options={FrequencyCappingDailyViews}
                                defaultValue={states.frequency_capping__daily_views_or_clicks}
                                value={states.frequency_capping__daily_views_or_clicks}
                                isClearable={false}
                                style={{ width: "100px", margin: "0" }}
                                onChange={(e) => handleStatesChanges("frequency_capping__daily_views_or_clicks", e)}
                            />
                            per day.
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}
