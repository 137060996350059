/* eslint-disable react-hooks/rules-of-hooks */
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import HomeIcon from "@material-ui/icons/Home";
import MailIcon from "@material-ui/icons/Mail";
import MenuIcon from "@material-ui/icons/Menu";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { API, SECRET_KEY } from "../constants/index";

import { Icon } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import icon from "../assets/CI-Icon.svg";
import culturalIntellWhite from "../assets/CI-Logo-white.png";
import * as actions from "../redux/actions/user";
import Footer from "./footer";
import LoginCheck from "./loginCheck";

import axios from "axios";
import NewAlert from "../components/NewAlert";
import AccountMenu from "./avatar.js";

const drawerWidth = window.screen.width / 6;

const useStyles = (icon) =>
  makeStyles((theme) => ({
    root: {
      display: "flex",
      // width: "83%",
      // [theme.breakpoints.down("sm")]: {
      //   width: "100%",
      // },
    },
    drawer: {
      backgroundColor: "red",
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      background: "white",
      "& .MuiToolbar-regular": {
        minHeight: "50px",
      },
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      backgroundImage: `url(${icon}), linear-gradient(to right, #9211a2, #4911a2)`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      borderLeft: "10px solid #f64d0c",
      width: drawerWidth,
    },
    content: {},
    logoutBtn: {
      background: "#e96941",
      fontWeight: "400",
      color: "white",
      marginLeft: "auto",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    topicon: {
      width: "85%",
      margin: "20px auto",
    },
    bgicon: {
      position: "absolute",
      top: 0,
    },
    sideMenu: {
      "& li": {
        color: "white",
        listStyleType: "none",
        paddingBottom: "10px",
        cursor: "pointer",
        "& a": {
          color: "white",
          textDecoration: "none",
        },
      },
    },
  }));

const ConsultantMainLayout = (WrappedComponent) => (props) => {
  const history = useHistory();
  if (
    window.localStorage.getItem("role") !== "creativeconsultant" ||
    window.localStorage.getItem(SECRET_KEY) === null
  ) {
    history.push("/");
  }
  // const { window } = props;

  const username = window.localStorage.getItem("username");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  const loggedInSession = useSelector((state) => state.loggedInSession);
  const classes = useStyles(icon)();
  const access_token = window.localStorage.getItem(SECRET_KEY);

//   LoginCheck("/creativeconsultant/dropdowns", null, history);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const logout = () => {
    window.localStorage.removeItem('pendingSurvey');
    window.localStorage.setItem(SECRET_KEY, null);
    window.localStorage.setItem("role", null);
    dispatch(actions.error(null));
    dispatch(actions.loginSuccessfully(null));
    setTimeout(() => {
      history.push("/");
    }, 500);
  };

//   useEffect(() => {
//     axios
//       .get(`${API}/creativeconsultant/dropdowns`, {
//         headers: {
//           Authorization: `Bearer ${access_token}`,
//         },
//       })
//       .then((res, err) => {
//         if (res) {
//           dispatch({ type: "SAVE_CSV_DROPDWON", dropdownData: res.data });
//         }
//         if (err) {
//         }
//       })
//       .catch(function (error) {
//         if (error) {
//         }
//       });
//   });
  // const container =
  //   window !== undefined ? () => window().document.body : undefined;

  let page = "owner";
  let loc = history.location.pathname;
  if (loc.includes("profile")) {
    page = "profile";
  }
  if (loc.includes("pending-surveys")) {
    page = "pending-surveys";
  }
  if (loc.includes("completed-surveys")) {
    page = "completed-surveys";
  }
  if (loc.includes("accounts")) {
    page = "accounts";
  }

  return (
    <div className={classes.root} style={{ backgroundColor: "#F4F4F8" }}>
      <NewAlert />
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <div
          style={{
            display: "flex",
            padding: "2px",
            justifyContent: "flex-end",
          }}
        >
          <AccountMenu handleLogout={logout} name={username} />
          {/* <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton> */}
          {/* <Button
            //onClick={logout}
            style={{
              background: "white",
              fontWeight: "400",
              color: "#DC37C6",
              marginRight: "10px",
            }}
          >
            {username}
          </Button>
          <Button
            onClick={logout}
            style={{
              background: "#DC37C6",
              fontWeight: "400",
              color: "white",
            }}
          >
            Logout
          </Button> */}
        </div>
      </AppBar>
      <nav
        className={classes.drawer}
        aria-label="mailbox folders"
        style={{ background: "red" }}
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css" style={{ background: "red" }}>
          <Drawer
            //container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <img className={classes.topicon} src={culturalIntellWhite} />
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css" style={{ background: "red" }}>
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <img className={classes.topicon} src={culturalIntellWhite} />

            <ul className={classes.sideMenu}>
              <li>
                <Link
                  style={
                    page === "profile"
                      ? { fontWeight: "700", color: "white" }
                      : { fontWeight: "300", color: "#E2DEEA" }
                  }
                  to="/consultant/profile"
                >
                  Profile
                </Link>
              </li>
              <li>
                <Link
                  style={
                    page === "pending-surveys"
                      ? { fontWeight: "700", color: "white" }
                      : { fontWeight: "300", color: "#E2DEEA" }
                  }
                  to="/consultant/pending-surveys"
                >
                  Pending Surveys
                </Link>
              </li>
              <li>
                <Link
                  style={
                    page === "completed-surveys"
                      ? { fontWeight: "700", color: "white" }
                      : { fontWeight: "300", color: "#E2DEEA" }
                  }
                  to="/consultant/completed-surveys"
                >
                  Completed Surveys
                </Link>
              </li>
              <li>
                <Link
                  style={
                    page === "accounts"
                      ? { fontWeight: "700", color: "white" }
                      : { fontWeight: "300", color: "#E2DEEA" }
                  }
                  to="/consultant/accounts"
                >
                  Accounts
                </Link>
              </li>
            </ul>
          </Drawer>
        </Hidden>
      </nav>
      <div className={classes.toolbar} />
      <div style={{ width: "100%" }}>
        <Grid
          spacing={0}
          style={{ width: "100%", padding: "60px 30px", minHeight: "91vh", background: "#F4F4F8"}}
        >
          <Grid item md={12}>
            <WrappedComponent {...props} />
          </Grid>
        </Grid>
        <Footer />
      </div>
    </div>
  );
};

export default ConsultantMainLayout;
