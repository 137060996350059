export const ERROR = 'ERROR';
export const CREATE_INVENTORY = 'CREATE_INVENTORY';
export const INVENTORY_SAVED = 'INVENTORY_SAVED';
export const GET_MEDIA_OWNER_WITH_ALL_INVENTORY = 'GET_MEDIA_OWNER_WITH_ALL_INVENTORY';
export const SAVE_MEDIA_OWNER_WITH_ALL_INVENTORY = 'SAVE_MEDIA_OWNER_WITH_ALL_INVENTORY';
export const ADD_NEW_MEDIA_OWNER_INVENTRY = 'ADD_NEW_MEDIA_OWNER_INVENTRY';

export function createInventory(mediaOwner) {
  return {
    type: CREATE_INVENTORY,
    mediaOwner,
  };
}

export function inventorySaved(inventorySaved) {
  return {
    type: INVENTORY_SAVED,
    inventorySaved,
  };
}

export function apiMediaOwnerSaved(apiMediaOwnerSaved) {
  return {
    type: 'API_INVENTORY_SAVED',
    apiMediaOwnerSaved,
  };
}

export function getMediaOwnerWithAllInvenrty(mediaOwnerId) {
  return {
    type: GET_MEDIA_OWNER_WITH_ALL_INVENTORY,
    mediaOwnerId
  };
}

export function addNewMediaOwnerWithInvenrtyAction (mediaOwnerId,inventoriesList){
  return {
    type: ADD_NEW_MEDIA_OWNER_INVENTRY,
    mediaOwnerId,
    inventoriesList
  };
}

export function saveMediaOwnerWithAllInventry(mediaOwnerWithAllInventry) {
  return {
    type: SAVE_MEDIA_OWNER_WITH_ALL_INVENTORY,
    mediaOwnerWithAllInventry
  };
}

export function error(error) {
  return {
    type: ERROR,
    error
  };
}
