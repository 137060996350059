export const PROCESS_CSV = 'PROCESS_CSV';
export const PROCESS_CSV_SUCCESSFULL = 'PROCESS_CSV_SUCCESSFULL';
export const SAVE_CSV_DROPDWON = 'SAVE_CSV_DROPDWON';
export const GET_CSV_DROPDWON = 'GET_CSV_DROPDWON';
export const GET_USERS = 'GET_USERS';
export const SAVE_USERS = 'SAVE_USERS';
export const ADMIN_RESPONSE_SUCCESS="RESPONSE_SUCCESS"
export const SET_REPORT_DATA="SET_REPORT_DATA"

export function processCsv() {
  return {
    type: PROCESS_CSV
  };
}


export function processCsvSuccessfully(processCsvSuccessfull) {
  return {
    type: PROCESS_CSV_SUCCESSFULL,
    processCsvSuccessfull,
  };
}

export function getUsers(role) {
  return {
    type: GET_USERS,
    role,
  };
}

export function saveUsers(adminAllUsers) {
  return {
    type: SAVE_USERS,
    adminAllUsers,
  };
}

export function saveDropdown(dropdownData) {
  return {
    type: SAVE_CSV_DROPDWON,
    dropdownData,
  };
}

export function saveAdminResponse(adminResponse) {
  return {
    type: ADMIN_RESPONSE_SUCCESS,
    adminResponse,
  };
}

export function getCsvDropdown() {
  return {
    type: GET_CSV_DROPDWON
  };
}

