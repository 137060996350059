import React from "react"
import {ReactComponent as Budget} from "../../../assets/budget.svg"
import {ReactComponent as Ad_Spend} from "../../../assets/ad_spend.svg"
import {ReactComponent as Impressions_Delivered} from "../../../assets/impressions_delivered.svg"
import {ReactComponent as Cpm} from "../../../assets/cpm.svg"
import {ReactComponent as Pacing_expected} from "../../../assets/pacing_expected.svg"
import {ReactComponent as Pacing_even} from "../../../assets/pacing_even.svg"
import {ReactComponent as Progress} from "../../../assets/progress.svg"

export default function CampaignDetails({budget}){
    const IconsList = [
        {
            id: 0,
            icon: Budget,
            label: "budget",
            budget
        },
        {
            id: 1,
            icon: Ad_Spend,
            label: "ad spend"
        },
        {
            id: 2,
            icon: Impressions_Delivered,
            label: "impressions delivered"
        },
        {
            id: 3,
            icon: Cpm,
            label: "CPM"
        },
        {
            id: 4,
            icon: Pacing_expected,
            label: "Pacing vs. Expected"
        },
        {
            id: 5,
            icon: Pacing_even,
            label: "Pacing vs. Even"
        },
        {
            id: 6,
            icon: Progress,
            label: "progress"
        },
    ]
    return (
        <div className="campaign-box">
        <div className="campaign-list">
          <ul>
            {/* <li>
              <div className="campaign-box-content">
                <div className="icon">
                  <Budget />
                </div>
                <div className="txt">
                  <div className="value-container">-</div>
                  <div className="value-label">budget </div>
                </div>
              </div>
            </li> */}
            {
                IconsList.map(icon => {
                    return (
                        <li key={icon.id}>
                            <div className="campaign-box-content">
                                <div className="icon">
                                <icon.icon />
                                </div>
                                <div className="txt">
                                <div className="value-container">£{icon?.budget ?? 0.00}</div>
                                <div className="value-label">{icon.label}</div>
                                </div>
                            </div>
                        </li>
                    )
                })
            }
          </ul>
        </div>
      </div>
    )
}